import { DomSanitizer } from "@angular/platform-browser";
import { ContactPerson } from "../customer/ContactPerson";
import { Customer } from "../customer/Customer";
import { Transaction } from "../transaction/Transaction";

export class Offer {
    public pdf: any;
    public transactions: Transaction[] = [];

    constructor(
        public offer_id: number,
        public customer: Customer,
        public contact_person: ContactPerson,
        public title: string,
        public offer_date: number,
        public offer_validity: number,
        public status: OfferStatus,
        public show_price_overview: boolean,
        public show_brutto_price: boolean,
        public offer_items: OfferItem[],
        public special_conditions?: string,
        public payment_conditions?: string,
        public hour_rate?: number,
        public performance_period?: PerformancePeriod
    ) { }

    getJSON(): any {
      let items: any[] = [];
      this.offer_items.forEach(item => {
        let descriptions: any[] = [];
        item.offer_descriptions.forEach(des => {
          descriptions.push({
            title: des.title,
            sort_value: des.sort_value,
            description: des.description
          })
        });
        items.push({
          item_id: item.item_id,
          is_total_price: item.is_total_price,
          price: item.price,
          total_discount: item.total_discount,
          price_based: item.price_based,
          base_value: item.base_value,
          offer_descriptions: descriptions
        })
      });
      let body = {
        offer_id: this.offer_id,
        customer: {
          customer_id: this.customer.id
        },
        contact_person: {
          contact_person_id: this.contact_person.id
        },
        title: this.title,
        offer_date: this.offer_date,
        offer_validity: this.offer_validity,
        status: this.status,
        show_price_overview: this.show_price_overview,
        show_brutto_price: this.show_brutto_price,
        offer_items: items,
      }
      return body;
    }

    public getCreateSignatureJSON(): any {
      return {
        "signature_document" : {
          "typ" : 3,
          "reference_id" : this.offer_id,
          "authorization_number": "---",
          "authorization_authority": "---",
          "authorization_date": new Date().getTime(),
          "authorization_groups" : "---",
          "trip_description": "Bezirk Krems & Umgebung"
       }
      }
    }
}

export class OfferItem {
    constructor(
        public item_id: number,
        public offer_descriptions: OfferDescription[],
        public is_total_price: boolean,
        public price: number,
        public total_discount: number,
        public price_based: string,
        public base_value: string
    ){}
}

export enum OfferStatus {
    DRAFT = 0,
    SENT = 1,
    ACCEPTED = 2,
    REJECTED = 3,
    EXPIRED = 4,
}

export class PerformancePeriod {
    constructor(
        public start_date: number,
        public end_date: number,
    ) {}
}

export class OfferDescription {
    constructor(
        public title: string,
        public sort_value: number,
        public description?: string
    ) {}
}
