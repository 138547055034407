<div class="car_create_page_wrapper">
    <div id="heading">
        <div class="heading-txt">
            <div class="title">Zweispurig</div>
            <a href="https://www.zweispurig.at/" target="_blank" class="info">zweispurig.at</a>
        </div>
        <a href="https://www.zweispurig.at/" target="_blank" class="icon-link">
            <img id="zweispurig" src="../../../../../assets/logo-zweispurig.png">
        </a>
    </div>
    <div id="content">
        <div id="container1">
            <div class="title-content">Ausstattung</div>
            <div id="checkbox-container">
                <input type="checkbox" class="checkbox" name="equipment">
                <label for="equipment" class="label-for-checkbox">Ausstattung Zweispurig A-Z</label>
            </div>
            <div id="checkbox-container">
                <input type="checkbox" class="checkbox" name="equipment">
                <label for="equipment" class="label-for-checkbox">Ausstattung Zweispurig A-Z</label>
            </div>
            <div id="checkbox-container">
                <input type="checkbox" class="checkbox" name="equipment">
                <label for="equipment" class="label-for-checkbox">Ausstattung Zweispurig A-Z</label>
            </div>
            <div id="checkbox-container">
                <input type="checkbox" class="checkbox" name="equipment">
                <label for="equipment" class="label-for-checkbox">Ausstattung Zweispurig A-Z</label>
            </div>
            <div id="checkbox-container">
                <input type="checkbox" class="checkbox" name="equipment">
                <label for="equipment" class="label-for-checkbox">Ausstattung Zweispurig A-Z</label>
            </div>
        </div>
        <div id="container2">
            <div class="title-content">Fahrzeugdaten</div>
            <select class="dropdown">
                <option disabled selected>Zusatzdaten Dropdown</option>
                <option>Zusatz 1</option>
                <option>Zusatz 2</option>
                <option>Zusatz 3</option>
            </select><br>
            <select class="dropdown">
                <option disabled selected>Zusatzdaten Dropdown</option>
                <option>Zusatz 1</option>
                <option>Zusatz 2</option>
                <option>Zusatz 3</option>
            </select><br>
            <select class="dropdown">
                <option disabled selected>Zusatzdaten Dropdown</option>
                <option>Zusatz 1</option>
                <option>Zusatz 2</option>
                <option>Zusatz 3</option>
            </select><br>
            <input type="text" class="inputfield" placeholder="Zusatzdaten Textfeld"><br>
            <input type="text" class="inputfield" placeholder="Zusatzdaten Textfeld"><br>
        </div>
        <div id="container3">
            <div class="title-content">Andere Daten</div>
            <input type="text" class="inputfield" placeholder="Preis">
            <select class="dropdown">
                <option disabled selected>Zusatzdaten Dropdown</option>
                <option>Zusatz 1</option>
                <option>Zusatz 2</option>
                <option>Zusatz 3</option>
            </select>
            <div id="checkbox-container">
                <input type="checkbox" class="checkbox" name="equipment">
                <label for="equipment" class="label-for-checkbox">Ausstattung A-Z</label>
            </div>
            <div id="checkbox-container">
                <input type="checkbox" class="checkbox" name="equipment">
                <label for="equipment" class="label-for-checkbox">Ausstattung A-Z</label>
            </div>
        </div>
    </div>
</div>