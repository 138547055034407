import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Attachment, AttachmentHelper, AttachmentType } from 'src/app/buisness-object/attachment/Attachment';
import { CloneObject } from 'src/app/helpers/CloneObject';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-dialog-doc-upload',
  templateUrl: './dialog-doc-upload.component.html',
  styleUrl: './dialog-doc-upload.component.css'
})
export class DialogDocUploadComponent implements OnInit {

  @Input() attachment: Attachment = null;
  @Input() isCreate: boolean = true;
  @Input() showCategories: boolean = false;
  @Output() uploadAbortEmitter = new EventEmitter<any>();
  @Output() dialogCloseEmitter = new EventEmitter<any>();
  @Output() uploadDocumentEmitter = new EventEmitter<Attachment>();

  public documentMimeTyp: string = "";
  public documentTitle: string = "";
  public categories: any = [];
  public selectedCategory: any;
  public form: FormGroup;
  public submitted = false;
  public categoryName;

  constructor(
    private formBuilder: FormBuilder,
    private enumService: EnumService,
    private attachmentService: AttachmentService
  ){}

  ngOnInit() {
    this.categoryName = this.getEnumCatogoryName();
    this.form = this.formBuilder.group({
      title: [this.attachment.filename, Validators.required],
      category: [this.attachment.category, this.showCategories ? Validators.required : null]
    })
    if(this.attachment){
      this.documentTitle = this.attachment.filename;
      this.documentMimeTyp = this.attachment.mimetyp;
    }
  }

  getEnumCatogoryName(): string {
    switch (this.attachment.typ) {
      case AttachmentType.VEHICLE: return 'attachment_category_vehicle';
      default: return '';
    }
  }

  selectionSubmit() {
    this.submitted = true;
    if(this.form.invalid){
      return;
    }
    this.attachment.filename = this.form.get('title').value;
    this.uploadDocumentEmitter.emit(this.attachment);
  }

  updateAttachment(){
    this.submitted = true;
    if(this.form.invalid){
      return;
    }
    let toUpdateAttachment = CloneObject.deepCopy(this.attachment);
    toUpdateAttachment.filename = this.form.get('title').value;
    toUpdateAttachment.category = this.form.get('category').value;
    this.attachmentService.updateAttachment(toUpdateAttachment).subscribe((result) => {
      if(result){
        if((this.attachment.category != toUpdateAttachment.category) || toUpdateAttachment.filename != this.attachment.filename) {
          this.attachmentService.notifyCategoryChange({file: result, oldCategory: this.attachment.category, newCategory: toUpdateAttachment.category});
        }
        this.uploadDocumentEmitter.emit();
      }
    })
  }
}
