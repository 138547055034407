import { Component, Input, OnInit } from '@angular/core';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';

@Component({
  selector: 'app-stand-time',
  templateUrl: './stand-time.component.html',
  styleUrls: ['./stand-time.component.css']
})
export class StandTimeComponent implements OnInit {
  @Input() vehicle: Vehicle;

  constructor() { }

  ngOnInit(): void {
  }

}
