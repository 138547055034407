import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Attachment, AttachmentCategory, AttachmentHelper, AttachmentType } from 'src/app/buisness-object/attachment/Attachment';
import { Picture } from 'src/app/buisness-object/picture/Picture';
import { RentForm } from 'src/app/buisness-object/rent/form/RentForm';
import { Rent } from 'src/app/buisness-object/rent/Rent';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { RentService } from 'src/app/service/rent/rent.service';


@Component({
  selector: 'app-dialog-miete-document',
  templateUrl: './dialog-miete-document.component.html',
  styleUrl: './dialog-miete-document.component.css'
})
export class DialogMieteDocumentComponent implements OnInit {

  public obj: Rent;
  public filesAmount;
  public onloadSrc = [];
  public token: string;
  public receive: string;
  public isReceive = true;
  public validToken = true;
  public submitted = false;
  public form: FormGroup;
  public success = false;
  public swap: string;
  public currentImages: string[] = [];;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private rentService: RentService,
  ){}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      "mileage": [null, Validators.required],
      "damage": [null, Validators.required],
      "images": [null, Validators.required],
    })
    this.isReceive = this.route.snapshot.url.some(segment => segment.path === 'receive');

    this.route.queryParams.subscribe(params => {
      this.token = params['token']; 
      if(!this.token || this.token?.length < 10){
        this.validToken = false;
      }
    });
  }

  invalidForm(): boolean {
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return true;
    }
    if(this.form.get('images').value == null){
      return true;
    }
    return false;
  }

  async onFileDropped(data: any) {
    if(!data) return;
    for(let file of data.data){
      const src = file.content;
      this.currentImages.push(src);
      this.form.get('images').setValue(this.currentImages);
    }
  }

  async uploadFile(event: any) {
    if(event.target.files && event.target.files.length != 0){
      for(let i = 0; i < event.target.files.length; i++){
         await this.readFile(event.target.files[i]);
      }
      this.form.get('images').setValue(this.currentImages);
      event.target.value = '';
    }
  }

  async readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        const base64String = reader.result as string;
        this.currentImages.push(base64String);
        resolve(true);
      };
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  selectionDeleteImage(img: any) {
    const index = this.currentImages.indexOf(img);
    if(index > -1) {
      this.currentImages.splice(index, 1);
      this.form.get('images').setValue(this.currentImages);
    }
  }

  submit() {
    this.submitted = true;
    if(this.invalidForm()){
      return
    }
    let json = {
      "mileage": this.form.get('mileage').value,
      "damages": this.form.get('damage').value,
      "images": this.form.get('images').value,
    }
    this.createRentOrderWeb(json);
  }

  createRentOrderWeb(json: any): void {
    this.rentService.updateRentOrderWeb(this.token, json).subscribe((result) => {
      if(result) {
        this.success = true;
      } else {
        this.validToken = false;
      }
    })
  }
}
