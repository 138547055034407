import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Rent } from 'src/app/buisness-object/rent/Rent';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { RentService } from 'src/app/service/rent/rent.service';

@Component({
  selector: 'app-dialog-miete-send-email',
  templateUrl: './dialog-miete-send-email.component.html',
  styleUrl: './dialog-miete-send-email.component.css'
})

export class DialogMieteSendEmailComponent implements OnInit{
  @Input() obj: Rent;
  @Output() selectionCloseEmitter = new EventEmitter();
  @Output() selectionSubmitEmailEmitter = new EventEmitter();

  public form: FormGroup;
  public submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private rentService : RentService
  ){}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      "vault_number": [null, Validators.required],
      "key_code": [null, Validators.required],
    })
  }

  invalidForm(): boolean {
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return true;
    }
    return false;
  }

  submit() {
    this.submitted = true;
    if(this.invalidForm()){
      return;
    }

    let json = {
      "vault_number": this.form.get('vault_number').value,
      "key_code": this.form.get('key_code').value
    }
    this.sendEmail(json);
  }

  sendEmail(json: any): void {
    this.rentService.sendEmail(json, this.obj.rent_id).subscribe((result) => {
      if(result){
        this.selectionSubmitEmailEmitter.emit();
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'E-Mail wurde gesendet.',
          success: true
        });
      }
    })
  }
  
}
