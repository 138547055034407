import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { EnumService } from 'src/app/service/enum/enum.service';
import { LoginService } from 'src/app/service/login/login.service';
import { PermissionService } from 'src/app/service/permission/permission.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { ReloadDataHandler } from 'src/app/utils/ReloadDataHandler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public submitted = false;
  public accessDenied = false;
  public showPassword = false;
  public showForgotPasswordForm = false;
  public sendMailSuccess = false;
  public version = environment.VERSION;

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    if(!this.showForgotPasswordForm) this.onSubmit();
    else this.sendMail();
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private loginService: LoginService,
    private vService: VehicleService,
    private eService: EnumService,
    private permissionService: PermissionService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      username_reset: ['', [Validators.required]],
    });
  }

  async onSubmit() {
    this.submitted = true;
    if(!this.loginForm.controls['username'].valid 
      && !this.loginForm.controls['password'].valid){
      return;
    }
    const access = await firstValueFrom(this.loginRequest(this.loginForm.controls['username'].value, this.loginForm.controls['password'].value));
    if(access){
      const permisson_group_name = localStorage.getItem('permission_group_name');
      if(permisson_group_name == null){
        await firstValueFrom(this.getPermissionGroups());
      }
      this.eService.updateEnums();
      new ReloadDataHandler().activateReloadLoop([this.vService]);
      this.router.navigate(['cars']);
    } else {
      this.accessDenied = true;
    }
  }

  loginRequest(username, password): Observable<boolean> {
    return this.loginService.login(username, password).pipe(
      map((result) => {
        return result;
      }),catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }

  getPermissionGroups(): Observable<boolean> {
    return this.permissionService.getPermissionGroups().pipe(
      map((result) => {
        if(result){
          const user_permission_group_id = Number(localStorage.getItem('permission_group_id'));
          for(let permission of result){
            if(permission.permission_group_id == user_permission_group_id){
              localStorage.setItem('permission_group_name', permission.name);
              break;
            }
          }
        }
        return true;
      }),catchError((error) => {
        return of(false);
      })
    );
  }

  forgotPasswordAction() {
    this.loginForm.reset();
    this.submitted = false;
    this.showForgotPasswordForm = true;
  }

  sendMail() {
    this.submitted = true;
    if(this.loginForm.controls['username_reset'].invalid){
      return;
    } else {
      this.loginService.sendResetPassword(this.loginForm.controls['username_reset'].value).subscribe((response) => {
        if(response){
          this.sendMailSuccess = true;
          this.submitted = false;
        }
      })
    }
  }

  cancel() {
    this.loginForm.reset();
    this.submitted = false;
    this.accessDenied = false;
    this.showForgotPasswordForm = false;
    this.sendMailSuccess = false;
  }

  goToLogin() {
    this.cancel();
  }
}
