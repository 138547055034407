import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';


@Component({
  selector: 'app-dropdown-container',
  templateUrl: './dropdown-container.component.html',
  styleUrls: ['./dropdown-container.component.css'],
  animations: [
    trigger('rotate', [
      state('default', style({
        transform: 'rotate(0deg)'
      })),
      state('rotated', style({
        transform: 'rotate(180deg)'
      })),
      transition('default => rotated', [
        animate('0.2s'),
      ]),
      transition('rotated => default', [
        animate('0.2s')
      ]),
    ]),
  ]
})
export class DropdownContainerComponent implements OnInit, OnDestroy {
  public openDropdown = false;
  @Input() placeholder: string;
  @Input() placeholderItalic: boolean;
  @Input() textTransform: string;
  @Input() options: string[];
  @Input() borderBottomColor: string;
  @Input() dropOptionHover: string;
  @Output() selectEmitter = new EventEmitter<string>()
  @ViewChild('dropdownInputContainer') dropdownInputContainer: ElementRef;
  @ViewChild('dropdownInput') dropdownInput: ElementRef;
  @ViewChild('dropdownImg') dropdownImg: ElementRef;
  @ViewChild('dropdownOptionsContainer') dropdownOptionsContainer: ElementRef;
  public listenerFn: () => void;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    this.setCloseListener();
  }

  ngOnDestroy() {
    if(this.listenerFn){
      this.listenerFn();
    }
  }

  setCloseListener() {
    this.listenerFn = this.renderer.listen('window','click', (e:Event) => {
      if(this.openDropdown){
        if(e.target !== this.dropdownInputContainer.nativeElement && 
          e.target !== this.dropdownImg.nativeElement && 
          e.target !== this.dropdownInput.nativeElement && 
          e.target != this.dropdownOptionsContainer.nativeElement){
          this.openDropdown = false;
        }
      }
    });
  }
}
