<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button class="button-icon-start" (click)="selectionBack()">
      <img class="button-icon-start-img" src="./assets/back.svg"/>
      <span class="button-icon-start-span">Zurück</span>
    </button>
    <h2>Probefahrten-Formular #{{obj.test_drive_id}}</h2>
  </div>
  <div class="header-button-wrapper">
    <!-- <button class="button_type_2" (click)="shwoActions = !shwoActions" appDropdown [show]="shwoActions" (changedValueEmitter)="shwoActions = $event">
      Aktionen
      <img src="./assets/dropdown-arrow-blue.svg">
      <div *ngIf="shwoActions">
        <button *ngIf="obj?.status != DriveStatus.ABGSCHLOSSEN" (click)="selectionEditEmitter.emit(obj); shwoActions = false">Bearbeiten</button>
      </div>
    </button> -->
  </div>
</div>

<div class="body-base-style">
  <div class="btn-tab-wrapper">
    <button class="btn-tab btn-tab-first" [class.btn-tab-active]="showTab == tabView.Details" (click)="null">Details</button>
    <button *ngIf="false" class="btn-tab btn-last" [class.btn-tab-active]="showTab == tabView.Tickets" (click)="null">Tickets</button>
  </div>
  <div id="drive-details-body" *ngIf="showTab == tabView.Details">
    <div id="header-column" class="overview_data_box_vertical">
      <div class="overview_record_vertical">
        <h3>Fahrzeug</h3>
        <div>
          <label class="overview_record_lbl">{{obj.vehicle.brand.makeName}} {{obj.vehicle.model.name}}</label>
          <label class="lbl_italic">Fahrzeug-ID: {{obj.vehicle.externalId}}</label>
        </div>
      </div>
      <div class="overview_record_vertical" *ngIf="obj.customer">
        <h3>Kunde</h3>
        <div>
          <label class="overview_record_lbl">{{getInputValue(obj.customer.getFullName())}}</label>
          <label class="lbl_italic">Kunden-ID: {{obj.customer.id}}</label>
        </div>
      </div>
      <div class="overview_record_vertical" *ngIf="obj.employee">
        <h3>Mitarbeiter</h3>
        <div>
          <label class="overview_record_lbl">{{getInputValue(obj.employee.getFullName())}}</label>
          <label class="lbl_italic">Mitarbeiter-ID: {{obj.employee.id}}</label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Status</h3>
        <div>
          <label id="label-drive-status" class="overview_record_lbl">{{obj.getDriveStatus()}}</label>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="overview_data_box">
        <h3>Fahrzeug</h3>
        <div class="overview_record">
          <label class="overview_record_lbl">Marke</label>
          <label class="overview_record_value">{{obj.vehicle.brand.makeName}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Model</label>
          <label class="overview_record_value">{{obj.vehicle.model.name}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl" style="align-self: flex-start;">Version</label>
          <label class="overview_record_value" style="max-width: 270px; text-align: end;">{{obj.vehicle.model.version}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">KFZ-Typ</label>
          <label class="overview_record_value">{{obj.vehicle.bodyTyp.name}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Hauptfarbe</label>
          <label class="overview_record_value">{{getInputValue(obj.vehicle.bodyColor.name)}}</label>
       </div>
       <div class="overview_record" *ngIf="obj.vehicle.category.id != 1">
          <label class="overview_record_lbl">Erstzulassung</label>
          <label class="overview_record_value">{{getInputValueDate(obj.vehicle.firstRegistrationDate)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Kilometerstand</label>
          <label class="overview_record_value">{{obj.vehicle.mileage_internal ? getInputValueKM(obj.vehicle.mileage_internal) : getInputValueKM(obj.vehicle.mileageKm)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">FZG-Identifizierungsnr.</label>
          <label class="overview_record_value">{{getInputValue(obj.vehicle.fin_number)}}</label>
        </div>
      </div>
      <div class="overview_data_box">
        <h3>Details zur Probefahrt</h3>
        <div class="overview_record">
          <label class="overview_record_lbl">Kennzeichen</label>
          <label class="overview_record_value">{{plateValue}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Start</label>
          <label class="overview_record_value">{{getInputValueDateTime(obj.start_time)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Ende</label>
          <label class="overview_record_value">{{getInputValueDateTime(obj.end_time)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Ziel der Probefahrt</label>
          <label class="overview_record_value">{{obj.trip_description}}</label>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="overview_data_box" *ngIf="obj.customer">
        <h3>Hauptfahrer</h3>
        <div class="overview_record">
          <label class="overview_record_lbl">Anrede</label>
          <label class="overview_record_value">{{salutationValue}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Vorname</label>
          <label class="overview_record_value">{{getInputValue(obj.customer.contactPerson.firstName)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Nachname</label>
          <label class="overview_record_value">{{getInputValue(obj.customer.contactPerson.lastName)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Straße, Nr.</label>
          <label class="overview_record_value">{{getInputValue(obj.customer.address.street)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">PLZ</label>
          <label class="overview_record_value">{{getInputValue(obj.customer.address.postalCode)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Ort</label>
          <label class="overview_record_value">{{getInputValue(obj.customer.address.city)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">E-Mail</label>
          <label class="overview_record_value">{{getInputValue(obj.customer.contactPerson.email)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Telefon</label>
          <label class="overview_record_value">{{getInputValue(obj.customer.contactPerson.phone)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Geburtstdatum</label>
          <label class="overview_record_value">{{getInputValueDate(obj.customer.contactPerson.birthdate)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Führerscheinnummer</label>
          <label class="overview_record_value">{{getInputValue(obj.authorization_number)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Führerschein Behörde</label>
          <label class="overview_record_value">{{getInputValue(obj.authorization_authority)}}</label>
        </div>
      </div>
      <div class="overview_data_box" *ngIf="obj.employee">
        <h3>Hauptfahrer</h3>
        <div class="overview_record">
          <label class="overview_record_lbl">Vorname</label>
          <label class="overview_record_value">{{getInputValue(obj.employee.firstName)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Nachname</label>
          <label class="overview_record_value">{{getInputValue(obj.employee.lastName)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">E-Mail</label>
          <label class="overview_record_value">{{getInputValue(obj.employee.email)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Führerscheinnummer</label>
          <label class="overview_record_value">{{getInputValue(obj.authorization_number)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Führerschein Behörde</label>
          <label class="overview_record_value">{{getInputValue(obj.authorization_authority)}}</label>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="overview_data_box">
        <h3>Anhänge</h3>
          <app-files
            [obj]="obj"
            (successDeleteEmitter)="obj.attachments = $event"
          ></app-files>
        <!-- <div [attr.id]="'file_'+attachment.attachment_id" class="file" *ngFor="let attachment of obj.attachments">
          <div class="file-inner" (click)="openRe(bill.bill_id)">
            <div class="file-img-teal">
              <img src="../../../../assets/edit-grey.svg">
            </div>
            <div class="file-info-wrapper">
              <label>{{attachment.filename}}</label>
            </div>
          </div>
          <app-list-action-container
            [actions]="['download']"
            [index]="index"
            [maxIndex]="100"
            [object]="bill"
            [listItemId]="'attachment-wrapper_'+attachment.attachment_id"
            (selectionDownloadEmitter)="openAttachment(attachment.attachment_id)"
          ></app-list-action-container>
        </div> -->
      </div>
    </div>
  </div>

</div>
