<div class="list_element list_hover_element">
  <div class="lbl_list_data list_item_pointer_event" (click)="openDetailsEmitter.emit(document)">{{document.offer_id ? document.offer_id : document.purchase_contract_id}}</div>
  <div class="lbl_list_data list_item_pointer_event" (click)="openDetailsEmitter.emit(document)">{{getDocCustomerName()}}</div>
  <div class="lbl_list_data list_item_pointer_event" (click)="openDetailsEmitter.emit(document)">{{document.title}}</div>
  <div class="lbl_list_data">{{getTyp(document)}}</div>
  <div [ngClass]="getStatusColor(document)">{{getStatus(document)}}</div>
  <div class="lbl_list_data">{{document.bill_date ? (document.bill_date | date: 'dd. MMM yyyy') : (document.offer_date | date: 'dd. MMM yyyy')}}</div>
  <div class="list_element_action_container">
    <!-- <button class="btn_list_details" (click)="openDetailsEmitter.emit(document)">
        <img class="btn_list_details_icon" src="../../../../../assets/eye.svg">
        <span>Details</span>
    </button> -->
    <div #action_container class="btn_action_wrapper">
      <img #dropdownImg class="btn_actions" src="../../../../../assets/navdots.svg" (click)="showActions = !this.showActions" appDropdown [show]="showActions" (changedValueEmitter)="showActions = $event"  [ngClass]="showActions ? 'btn_actions_active' : ''">
        <div class="dropdown_actions_container" *ngIf="showActions">
            <div class="option" (click)="openDetailsEmitter.emit(document)">
              <div class="list_action_value_lbl">Details</div>
              <img class="list_action_value_icon" src="../../../../../assets/eye-white.svg" >
            </div>
            <div *ngIf="document.status != 1 && document.status != 2" class="option" (click)="openEditEmitter.emit(document)">
                <div class="list_action_value_lbl">Bearbeiten</div>
                <img class="list_action_value_icon" src="../../../../../assets/edit.svg" >
            </div>
            <!-- <div class="option" (click)="signPDFEmitter.emit(document)">
              <div class="list_action_value_lbl">App Signatur</div>
              <img class="list_action_value_icon" src="../../../../../assets/edit.svg" >
            </div> -->
            <div *ngIf="document.status != 0 && document.status != 1" class="option" [ngClass]="showActionsMoveDeep ? 'list_action_option_active' : ''" (click)="showActionsMoveDeep = !showActionsMoveDeep">
                <div class="list_action_value_lbl" >Verschieben</div>
                <img class="list_action_value_icon" src="../../../../../assets/document.svg" >
            </div>
            <div class="option" (click)="openPdfEmitter.emit(document)">
                <div class="list_action_value_lbl" >PDF Export</div>
                <img class="list_action_value_icon" src="../../../../../assets/document.svg" >
            </div>
            <div class="option" (click)="duplicateEmitter.emit(document)">
              <div class="list_action_value_lbl" >Duplizieren</div>
              <img class="list_action_value_icon" src="../../../../../assets/document.svg" >
          </div>
            <div class="option" (click)="deleteEmitter.emit(document)">
                <div class="list_action_value_lbl" >Löschen</div>
                <img class="list_action_value_icon" src="../../../../../assets/delete.svg">
            </div>
        </div>
        <div class="list_action_value_container_deep" *ngIf="showActionsMoveDeep">
          <div *ngIf="document.status != 0" class="option" (click)="moveToAction(document, 0)">
              <div class="list_action_value_lbl">Entwürfe</div>
              <img class="list_action_value_icon" src="../../../../../assets/edit.svg" >
          </div>
          <!-- <div class="option" (click)="moveToAction(document, 2)">
            <div class="list_action_value_lbl">Aktzeptiert</div>
            <img class="list_action_value_icon" src="../../../../../assets/edit.svg" >
          </div> -->
        </div>
      </div>
  </div>
</div>
