import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-price-sheets-create-vorkalkulation-new',
  templateUrl: './price-sheets-create-vorkalkulation-new.component.html',
  styleUrl: './price-sheets-create-vorkalkulation-new.component.css'
})
export class PriceSheetsCreateVorkalkulationNewComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Output() onPriceSheetChangesEmitter = new EventEmitter<undefined>();
  @Output() selectionEditSteuersatzAcceptedEmitter = new EventEmitter<undefined>();
  @Output() addReparaturEmitter = new EventEmitter<undefined>();
  @Output() removeReparaturEmitter = new EventEmitter<number>();
  public dialogQuerySubsription: Subscription;
  public dialogValueSubscription: Subscription;
  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public isHoedl = true;
  constructor(
    private dService: DialogService,
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
    this.isHoedl = authService.isHoedl();
  }

  ngOnInit(): void {
    this.subscribeToDialogs();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromDialogs();
  }

  changeExportPriceState() {
    this.form.controls['export_price_checked'].setValue(!this.form.controls['export_price_checked'].value);
    this.form.controls["export_price"].setValue(this.form.controls["export_price_calculated"].value);
  }

  editSteuersatz() {
    this.dService.openQuery(
      {
        title: 'Steuersatz tauschen?',
        message: 'Sind Sie sicher, dass Sie den Steuersatz tauschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Steuersatz tauschen',
        typ: 'change_steuersatz',
      }
    );
  }

  isNoVA(): boolean {
     return this.form.controls['vehicle_imported'].value == 1;
  }

  editValueWithDialog(data: any, identifier: string) {
    let title = "";
    if(identifier == "zinssatz_vor") title = "Zinssatz tauschen?";
    else if(identifier == "garantie_von_einkaufspreis") title = "Garantie in % vom Einkaufspreis tauschen?";
    else if(identifier == "standzeitkosten_je_tag_vor") title = "Standzeitkosten je Tag tauschen?";
    this.dService.openValue(
      {
        title: title,
        form_value: data,
        btn_cancel_txt: "Abbrechen",
        btn_submit_txt: "Speichern",
        identifier: identifier
      }
    );
  }

  setSteuersatz(value: number) {
    this.form.controls['steuersatz'].setValue(value);
    this.form.controls['steuersatz_nach'].setValue(value);
  }

  subscribeToDialogs() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'change_steuersatz') this.selectionEditSteuersatzAcceptedEmitter.emit();
      }
    });
    this.dialogValueSubscription = this.dService.closeDialogValue$.subscribe((value) => {
      if(value){
        if(value.identifier == 'zinssatz_vor') this.form.controls['zinssatz_vor'].setValue(value.submit_value);
        else if(value.identifier == 'garantie_von_einkaufspreis') this.form.controls['garantie_von_einkaufspreis'].setValue(value.submit_value);
        else if(value.identifier == 'standzeitkosten_je_tag_vor') this.form.controls['standzeitkosten_je_tag_vor'].setValue(value.submit_value);
      }
    })
  }

  unsubscribeFromDialogs() { 
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.dialogValueSubscription) this.dialogValueSubscription.unsubscribe();
  }

}
