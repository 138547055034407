<div class="list_container">
  <div id="list-header">
    <div id="list-header-submenus">
      <div id="sub-menu-wrapper">
        <button
          class="sub-menu-btn"
          [class.sub-menu-btn-active]="selectedTab == driveTab.Current"
          (click)="selectionTabEmitter.emit(driveTab.Current)">
          Aktuelle
        </button>
        <button
          class="sub-menu-btn"
          [class.sub-menu-btn-active]="selectedTab == driveTab.Past"
          (click)="selectionTabEmitter.emit(driveTab.Past)">
          Historie
        </button>
      </div>
    </div>
    <div class="header_list_row_1">
      <div></div>
      <div class="page_wrapper">
        <div id="count">{{pageInfo}}</div>
        <div id="arrows" *ngIf="amount > maxAmountListPage">
          <img *ngIf="sliceFrom == 0" class="icon_arrow_left" src="../../../../../assets/arrowlight.svg">
          <img *ngIf="sliceFrom != 0" class="icon_arrow_left list_arrow_active" src="../../../../../assets/arrow.svg" (click)="pageEmitter.emit(false)">
          <img *ngIf="sliceTo == amount" class="icon_arrow_right" src="../../../../../assets/arrowlight.svg">
          <img *ngIf="sliceTo != amount"  class="icon_arrow_right list_arrow_active" src="../../../../../assets/arrow.svg" (click)="pageEmitter.emit(true)">
        </div>
      </div>
    </div>
    <div class="header_list_row_2">
      <div class="header_list_row_2_inner">
        <label *ngIf="false" class="lbl_filter_value"><button (click)="removeFilterEmitter.emit('customer')">&#10005;</button>{{filter.customer.getName()}}</label>
      </div>
      <div></div>
      <!-- <div class="header_list_row_2_inner">
        <label *ngIf="true" class="lbl_filter_value">Nächste Fahrt<img (click)="null" [src]="true ? '../../../../../assets/sortup.svg' : '../../../../../assets/sortdown.svg'"></label>
      </div> -->
    </div>
  </div>
  <div *ngIf="drives.length != 0" class="list_title">
    <div class="lbl_list_title">Datum</div>
    <div class="lbl_list_title">Uhrzeit</div>
    <div class="lbl_list_title">Dauer</div>
    <div class="lbl_list_title">Status</div>
    <div class="lbl_list_title">Fahrzeug</div>
    <div class="lbl_list_title">Kunde/Mitarbeiter</div>
    <div class="lbl_list_title">Typ</div>
    <div class="lbl_list_title" *ngIf="!authService.isHoedl()">Signatur</div>
    <div></div>
  </div>
  <div id="listcontainer" class="list_element_container" [class.list_element_conatiner_empty]="drives.length == 0">
    <div class="list_no_elements lbl_regular_italic_light_color" *ngIf="drives.length == 0">Keine Fahrten vorhanden.</div>
    <div *ngFor="let drive of drives | slice:sliceFrom:sliceTo">
      <app-list-drives-element
        [drive]="drive"
        (openDetailsEmitter)="openDetailsEmitter.emit($event)"
        (openEditEmitter)="openEditEmitter.emit($event)"
        (deleteEmitter)="deleteEmitter.emit($event)"
        (openCreatePdfEmitter)="openCreatePdfEmitter.emit($event)"
        (selectionPDFEmitter)="selectionPDFEmitter.emit($event)"
        (selectionPseudoSignatureEmitter)="selectionPseudoSignatureEmitter.emit($event)"
      ></app-list-drives-element>
    </div>
  </div>
  <div class="list_footer"></div>
</div>
