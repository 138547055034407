<app-collapsible [isCollapsible]="!editMode" [isContentVisible]="true" *ngIf="obj">
  <h3 collapsible-header class="overview_data_box_lbl_title">Fahrzeuge</h3>
  <div collapsible-content>
    <label *ngIf="obj.vehicles?.length == 0">Keine Fahrzeuge zugeordnet.</label>
    <div *ngFor="let obj of obj.vehicles; let i = index"
      [attr.id]="'vehicle_'+obj.id"
      class="customer-car-list-element"
      [class.customer-car-list-element-border]="!isLast">
      <div class="customer-vehicles-content-wrapper" (click)="selectionDetails(obj)">
        <img src="./assets/carmenu-light-color.svg">
        <label>{{obj.getVehicleName()}}</label>
      </div>
      <app-list-action-container
        [actions]="['details']"
        [index]="index"
        [maxIndex]="100"
        [object]="obj"
        [isListView]="false"
        [listItemId]="'customer-vehicle_'+obj.id"
        (selectionOpenDetailsEmitter)="selectionDetails($event)"
        (selectionDeleteEmitter)="seletionDelete($event)"
      ></app-list-action-container>
    </div>
  </div>
</app-collapsible>
