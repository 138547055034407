import { BillItem } from "../BillItem";

export class BillItemFactory {
  static jsonFactory(rawBody: any): BillItem[] {
    const data: BillItem[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): BillItem {
    const netto = rawPart.price / (1+(rawPart.tax_rate/100))
    const obj = new BillItem(
      rawPart.item_id,
      rawPart.title,
      rawPart.quantity,
      netto,
      rawPart.tax_rate,
      rawPart.discount,
      rawPart.base_value,
    )
    return obj;
  }
}
