import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';


@Component({
  selector: 'app-dialog-tyres-vehicle-installation',
  templateUrl: './dialog-tyres-vehicle-installation.component.html',
  styleUrl: './dialog-tyres-vehicle-installation.component.css'
})
export class DialogTyresVehicleInstallationComponent implements OnInit {
  @Input() autoCompleteVehicle: Vehicle;
  @Input() vehicles: Vehicle[] = [];
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() selectionSubmitEmitter = new EventEmitter<Vehicle>();
  public vehicleValues = [];
  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ){}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      vehicle: [this.autoCompleteVehicle, Validators.required],
    });
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
  }

  getDropdownValuesVehicles(vehicles: Vehicle[]): any[] {
    let values = [];
    for(let value of vehicles){
      values.push({
        id: value.id,
        name: value.getVehicleName()
      })
    }
    return values;
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'vehicle'){
      let index = this.vehicles.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.get('vehicle').setValue(this.vehicles[index]);
      }
    }
  }

  submit() {
    this.selectionSubmitEmitter.emit(this.form.get('vehicle').value);
  }
}
