<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button type="button" class="button-icon-start" (click)="selectionCancel()">
      <img class="button-icon-start-img" src="./assets/cancle.svg"/>
      <span class="button-icon-start-span">Abbrechen</span>
    </button>
    <h2>#{{obj.bill_id}} Kaufvertragsrechnung bearbeiten</h2>
  </div>
  <div class="header-button-wrapper">
    <button type="button" class="btn_submit" [disabled]="false" (click)="selectionSave()">Speichern</button>
  </div>
</div>

<form [formGroup]="form" id="contract-create-body" class="body-base-style" *ngIf="form">
  <div class="column">
    <form [formGroup]="customerForm" class="overview_data_box">
      <h3>Rechnungsempfänger</h3>
      <app-dropdown-multiple
        [title]="'Anrede*'"
        [formcontrol]="customerForm.controls['salutation']"
        [headerText]="salutationValues[customerForm.controls['salutation']?.value-1]"
        [values]="salutationValues"
        (submitValueEmitter)="customerForm.controls['salutation'].setValue($event)"
      ></app-dropdown-multiple>
      <app-dropdown-strings
        [title]="'Titel'"
        [placeholder]="'---'"
        [formcontrol]="customerForm.controls['title']"
        [headerText]="customerForm.controls['title'].value"
        [values]="titleValues"
        (submitValueEmitter)="customerForm.controls['title'].setValue($event)"
      ></app-dropdown-strings>
      <div class="input_wrapper">
        <label>Vorname*</label>
        <input type="text" placeholder="Vorname" formControlName="firstName" [class.input_invalid]="submitted && customerForm.controls['firstName'].errors">
      </div>
      <div class="input_wrapper">
        <label>Nachname*</label>
        <input type="text" placeholder="Nachname" formControlName="lastName" [class.input_invalid]="submitted && customerForm.controls['lastName'].errors">
      </div>
      <div class="input_wrapper">
        <label>Geburtsdatum</label>
        <input #inputDate1 type="date" placeholder="date" [max]="today" formControlName="birthdate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && customerForm.controls['birthdate'].errors">
      </div>
      <app-dropdown-multiple
        [title]="'Kundentyp'"
        [formcontrol]="customerForm.get('typ')"
        [headerText]="typValues[customerForm.get('typ').value-1]"
        [values]="typValues"
        (submitValueEmitter)="customerForm.get('typ').setValue($event)"
      ></app-dropdown-multiple>
      <div class="input_wrapper" *ngIf="customerForm.controls['typ'].value == 1">
        <label>Firmenname*</label>
        <input type="text" placeholder="Firmenname" formControlName="companyName" [class.input_invalid]="submitted && customerForm.controls['companyName'].errors">
      </div>
      <div class="input_wrapper" *ngIf="customerForm.controls['typ'].value == 1">
        <label>ATU</label>
        <input type="text" placeholder="ATU" formControlName="atuNum" [class.input_invalid]="submitted && customerForm.controls['atuNum'].errors">
      </div>
      <div class="input_wrapper">
        <label>Straße, Nr*</label>
        <input type="text" placeholder="Straße" formControlName="street" [class.input_invalid]="submitted && customerForm.controls['street'].errors">
      </div>
      <div class="input_wrapper">
        <label>PLZ*</label>
        <input type="number" placeholder="PLZ" formControlName="postalCode" [class.input_invalid]="submitted && customerForm.controls['postalCode'].errors">
      </div>
      <div class="input_wrapper">
        <label>Ort*</label>
        <input type="text" placeholder="Ort" formControlName="city" [class.input_invalid]="submitted && customerForm.controls['city'].errors">
      </div>
      <app-dropdown-strings
        [title]="'Land'"
        [placeholder]="'Land'"
        [formcontrol]="customerForm.controls['country']"
        [headerText]="customerForm.controls['country'].value"
        [values]="countries"
        [isOptional]="true"
        (submitValueEmitter)="customerForm.controls['country'].setValue($event);"
      ></app-dropdown-strings>
      <div class="input_wrapper">
        <label>E-Mail*</label>
        <input type="text" placeholder="E-Mail" formControlName="email" [class.input_invalid]="submitted && customerForm.controls['email'].errors">
      </div>
      <div class="input_wrapper">
        <label>Telefon*</label>
        <input type="text" placeholder="Telefon" formControlName="phone" [class.input_invalid]="submitted && customerForm.controls['phone'].errors">
      </div>
      <app-dropdown-simple *ngIf="authService.isHoedl()"
        [title]="'Politisch Exponierte Person*'"
        [formcontrol]="customerForm.controls['is_pep']"
        (submitValueEmitter)="customerForm.controls['is_pep'].setValue($event)"
      ></app-dropdown-simple>
    </form>
     <!--Sonderkonditionen-->
     <div class="overview_data_box">
      <label class="overview_data_box_lbl_title">Sonderkonditionen</label>
      <textarea
        appTextfieldAutoresize
        style="height: 23px;"
        type="text"
        maxlength="400"
        placeholder="---"
        formControlName="custom_text"
        class="textField"
        [class.input_invalid]="submitted && form.controls['custom_text'].errors"></textarea>
      <label class="overview_record_lbl">({{400 - (form.controls['custom_text'].value ? form.controls['custom_text'].value.length : 0)}} Zeichen übrig)</label>
    </div>
  </div>
  <div class="column">
    <div class="overview_data_box">
      <h3>Fahrzeug</h3>
      <div class="input_wrapper">
        <label>Co2-Emissionen *</label>
        <input type="number" [min]="0" placeholder="---" formControlName="vehicle_co2" [class.input_invalid]="submitted && form.get('vehicle_co2').invalid">
      </div>
      <app-dropdown-multiple
        [title]="'Steuersatz *'"
        [formcontrol]="form.get('steuersatz')"
        [headerText]="getSteuersatz(form.get('steuersatz').value)"
        [values]="steuerseatze"
        [submitted]="submitted"
        (submitValueEmitter)="form.get('steuersatz').setValue($event)"
      ></app-dropdown-multiple>
      <app-dropdown-simple
        [title]="'Import *'"
        [formcontrol]="form.get('vehicle_imported')"
        [isOptional]="false"
        [submitted]="submitted"
        (submitValueEmitter)="form.get('vehicle_imported').setValue($event)"
      ></app-dropdown-simple>
      <div *ngIf="authService.isHoedl() || authService.isWaymarkUser()" class="input_wrapper">
        <label class="overview_record_lbl">Einkaufspreis in € *</label>
        <input type="number" placeholder="Einkaufspreis in €" formControlName="einkaufspreis_brutto" [class.input_invalid]="submitted && form.get('einkaufspreis_brutto').invalid">
      </div>
      <div *ngIf="authService.isHoedl() || authService.isWaymarkUser()" class="input_wrapper">
        <label class="overview_record_lbl">Einkaufsdatum *</label>
        <input #inputDate type="date" placeholder="date" [max]="maxDate" formControlName="einkaufsdatum" [class.date-input--has-value]="inputDate.value != null" [class.input_invalid]="submitted && form.get('einkaufsdatum').invalid">
      </div>
    </div>
  </div>
  <div class="column">

  </div>
</form>
