import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrl: './collapsible.component.css'
})
export class CollapsibleComponent implements OnInit {
  @Input() isCollapsible: boolean = false;
  @Input() isContentVisible: boolean = true;

  ngOnInit(): void {
    // if(this.isCollapsible) {
    //   this.isContentVisible = true;
    // } else {
    //   this.isContentVisible = true;
    // }
  }

  toggleContent() {
    if (this.isCollapsible) {
      this.isContentVisible = !this.isContentVisible;
    }
  }
}
