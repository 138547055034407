import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-details-bill-document',
  templateUrl: './details-bill-document.component.html',
  styleUrls: ['./details-bill-document.component.css']
})
export class DetailsBillDocumentComponent implements OnInit {
  @Input() document: any;
  public grandTotal = 0;

  constructor() { }

  ngOnInit(): void {
    for(let item of this.document){
      this.grandTotal =+ item.price;
    }
  }

}
