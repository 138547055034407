import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { AttachmentType } from 'src/app/buisness-object/attachment/Attachment';
import { Kaufvertrag, KaufvertragStatus } from 'src/app/buisness-object/document/Kaufvertrag';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { MasterMenu } from 'src/app/buisness-object/menu/EnumMasterMenu';
import { MainMenu } from 'src/app/buisness-object/menu/MainMenu';
import { MainMenuFactory } from 'src/app/buisness-object/menu/MainMenuFactory';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { SubMenuColumn } from 'src/app/buisness-object/menu/SubMenuColumn';
import { SubMenuFilter, SubMenuOperator } from 'src/app/buisness-object/menu/SubMenuFilter';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { Zusatzprodukt } from 'src/app/buisness-object/zusatzprodukt/Zusatzprodukt';
import { CloneObject } from 'src/app/helpers/CloneObject';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { DocumentService } from 'src/app/service/document/document.service';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { LoadingService } from 'src/app/service/loading/loading.service';
import { LoginService } from 'src/app/service/login/login.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { DynamicListHelper } from 'src/app/utils/DynamicListHelper';


export enum DocumentenView {
  DokumentListe = 1,
  DokumentKaufVertragErstellen = 2,
  DokumentKaufvertragDetails = 3
}

export enum DokumentTyp {
  Kaufvertrag = 3,
  Angebot = 2,
  Probefahrt = 4,
  Datenschutz = 1,
  Bill = 5,
}

export enum PaymentStatus {
  NichtBezahlt = 'Nicht bezahlt',
  AnzahlungBezahlt = 'Anzahlung bezahlt',
  Bezahlt = 'Bezahlt',
}

@Component({
  selector: 'app-dokumente',
  templateUrl: './dokumente.component.html',
  styleUrl: './dokumente.component.css'
})
export class DokumenteComponent implements OnInit, OnDestroy {

  public showDropdownMain = false;

  public mainMenus: MainMenu[] = [];
  public _activeSubMenu: BehaviorSubject<SubMenu> = new BehaviorSubject<SubMenu>(null);
  public activeSubMenu$ = this._activeSubMenu.asObservable();
  public activeSubMenu: SubMenu;
  public _activeSortingColumn: BehaviorSubject<SubMenuColumn> = new BehaviorSubject<SubMenuColumn>(null);
  public activeSortingColumn$ = this._activeSortingColumn.asObservable();
  public activeSortingColumn: SubMenuColumn;

  public DokumentenView = DocumentenView;
  public showView: DocumentenView = DocumentenView.DokumentListe;

  public kaufvertraege: Kaufvertrag[] = [];
  public zusatzprodukte: Zusatzprodukt[] = [];
  public _objects: any[] = [];
  public _objectsFiltered: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public _objectsFiltered$ = this._objectsFiltered.asObservable();
  public activeFilter: any[] = [];
  public activeFilterOutside: any[] = [];
  public activeFilterInside: any[] = [];
  public searchValue;
  public searchTimeout: any;
  public pageHandler: PageHandler;
  public dynamicListLastPosition;

  public employees: Employee[] = [];
  public paymenStatus = [PaymentStatus.NichtBezahlt, PaymentStatus.AnzahlungBezahlt, PaymentStatus.Bezahlt];
  public signatureObject: Kaufvertrag;

  public selectedDocument: any;
  public tempObject: any;
  public dialogQuerySubsription: Subscription;
  public dialogChangeStatusSubsription: Subscription;
  public signatureLoaderSubscription: Subscription;
  public showDialogReceiveFunding = false;

  constructor(
    private loginService: LoginService,
    private documentService: DocumentService,
    private emeployeeService: EmployeeService,
    private customerService: CustomerService,
    private vehicleService: VehicleService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private activateRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private location: Location,
    private enumService: EnumService
  ){}

  ngOnInit(): void {
    this.getData();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.searchTimeout) clearTimeout(this.searchTimeout);
    if(this.dialogChangeStatusSubsription) this.dialogChangeStatusSubsription.unsubscribe();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  getData() {
    this.loadingService.startLoading();
    forkJoin({
      kaufvertraege: this.documentService.getKaufvertraege(),
      employees: this.emeployeeService.getEmployees(),
      customers: this.customerService.getCustomer(),
      vehicles: this.vehicleService.getVehicle(),
      additional_products: this.documentService.getAdditionalProducts(),
    }).subscribe((result) => {
      if(result){
        this.zusatzprodukte = result.additional_products;
        this.kaufvertraege = result.kaufvertraege;
        for(let kaufvertrag of this.kaufvertraege){
          let zusatzProduktIds = kaufvertrag.selektierteZusatzprodukte.map((z) => z.zusatzprodukt_id);
          kaufvertrag.verfuegbareZusatzprodukte = this.zusatzprodukte.filter((z) => !zusatzProduktIds.includes(z.zusatzprodukt_id));
        }
        this.employees = result.employees;
        const employeeMap = new Map(result.employees.map(employee => [employee.id, employee]));
        for(let kaufvertrag of this.kaufvertraege) {
          kaufvertrag.signer_employee = employeeMap.get(kaufvertrag.employee_id) || null;
          kaufvertrag.creator_employee = employeeMap.get(kaufvertrag.creator_employee_id) || null;
        }
        this._objects = this.kaufvertraege;
        this.setUpMenu();
        this.activeSubMenu$.subscribe((active) => {
          if(active){
            this.activeSubMenu = active;
            this.activeFilter = [];
            this.activeFilterInside = [];
            this.activeFilterOutside = [];
            this.activeSortingColumn = null;
            this.rootFilter();
            this.setUpSearch();
          }
        });
        this.checkRoute();
        this.loadingService.stopLoading();
      }
    })
  }

  //dynamic menu

  setUpMenu() {
    let raw: any = JSON.parse(localStorage.getItem('menus'));
    if(raw == undefined || raw == null ){
      this.loginService.logout();
      return;
    }
    this.mainMenus = MainMenuFactory.jsonFactoryMainMenus(raw, MasterMenu.PURCHASECONTRACT);
    for(let i = 0; i < this.mainMenus.length; i++){
      if(i == 0) this.mainMenus[i].setActive(true);
      this.mainMenus[i].sub_menus[0]?.setActive(true);
    }
    for(let mainMenu of this.mainMenus){
      mainMenu.active$.subscribe((active) => {
        if(active){
          for(let otherMainMenu of this.mainMenus){
            if(otherMainMenu.main_menu_id != mainMenu.main_menu_id){
              otherMainMenu.setActive(false);
            }
          }
          if(mainMenu.sub_menus.length > 0){
            for(let otherSubMenu of mainMenu.sub_menus){
              if(otherSubMenu.getActive()){
                this._activeSubMenu.next(otherSubMenu);
              }
            }
          }
        }
      });
      for(let subMenu of mainMenu.sub_menus){
        subMenu.active$.subscribe((active) => {
          if(active){
            for(let otherSubMenu of mainMenu.sub_menus){
              if(otherSubMenu != subMenu){
                otherSubMenu.setActive(false);
              }
            }
            this._activeSubMenu.next(subMenu);
          }
        });
      }
    }
    this._activeSubMenu.next(this.mainMenus[0]?.sub_menus[0]);
  }

  rootFilter() {
    let activeSubMenu = this.mainMenus.filter((m) => m.getActive())[0].sub_menus.filter((s) => s.getActive())[0];
    if(activeSubMenu?.filter && activeSubMenu.filter.length > 0) {
      let sumFilter: any[] = activeSubMenu.filter;
      if(this.activeFilter.length > 0) {
        sumFilter = [sumFilter].concat([new SubMenuOperator("&&")]).concat(this.activeFilter);
      }
      this._objectsFiltered.next(this._objects.filter((obj) => this.solveFilter(sumFilter, obj)));
    } else if(this.activeFilter.length > 0) {
      this._objectsFiltered.next(this._objects.filter((obj) => this.solveFilter(this.activeFilter, obj)));
    } else {
      this._objectsFiltered.next(this._objects.filter((obj) => true));
    }
    this.applySearch(this._objectsFiltered.getValue());
    this.rootSorting();
    this.setPages();
  }

  solveFilter(originalFilters: any[], object: any): boolean {
    let filters = JSON.parse(JSON.stringify(originalFilters));
    for(let i = 0; i < filters.length; i++) {
      if(filters[i].data_key) {
        filters[i] = this.objectIsValidForFilter(filters[i], object);
      } else if(Array.isArray(filters[i])) {
        filters[i] = this.solveFilter(filters[i], object);
      }
    }
    for(let i = 0; i < filters.length; i++) {
      if(filters[i].operator && filters[i].operator == '&&') {
        filters[i + 1] = filters[i -1] && filters[i + 1];
        filters.splice(i - 1, 2);
        i = i - 1;
      }
    }
    for(let i = 0; i < filters.length; i++) {
      if(filters[i].operator && filters[i].operator == '||') {
        filters[i + 1] = filters[i -1] || filters[i + 1];
        filters.splice(i - 1, 2);
        i = i - 1;
      }
    }
    return filters[0];
  }

  objectIsValidForFilter(filter: SubMenuFilter, object: any): boolean {
    let value: any = this.getObjectValueFromDataKey(filter.data_key, object);
    if(filter.data_typ == "NUMBER" || filter.data_typ == "DATE") {
      return this.compareNumber(value, filter);
    } else if(filter.data_typ == "BOOLEAN" || filter.data_typ == "BOOLEAN_NULL") {
      return this.compareBoolean(value, filter);
    } else if(filter.data_typ == "ENUM" || filter.data_typ == "ENUM_TAG") {
      return this.compareEnum(value, filter);
    } else {
      return this.compareString(value, filter);
    }
  }
  compareNumber(value: any, filter: SubMenuFilter): boolean {
    if(filter.raw_value == "NULL") {
      if(filter.operator == "<>") {
        return value != undefined && value != null;
      } else if(filter.operator == "=") {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }
    let numberValue = Number(value);
    let rawValue;
    //Special date condition
    if(filter.raw_value && String(filter.raw_value).includes("NOW-")){
      let miliseconds = Number(filter.raw_value.split("-")[1]);
      if(!isNaN(miliseconds)){
        rawValue = new Date().getTime()-miliseconds;
      }
    }
    if(rawValue == undefined || rawValue == null) rawValue = Number(filter.raw_value);
    if(Number.isNaN(numberValue)) {
      return false;
    }
    if(filter.operator == "<>") {
      return numberValue != rawValue;
    } else if(filter.operator == "=") {
      return numberValue == rawValue;
    } else if (filter.operator == ">") {
      return numberValue > rawValue;
    } else if (filter.operator == ">=") {
      return numberValue >= rawValue;
    } else if (filter.operator == "<") {
      return numberValue < rawValue;
    } else if(filter.operator == "<=") {
      return numberValue <= rawValue;
    } else {
      return false;
    }
  }
  compareBoolean(value: any, filter: SubMenuFilter): boolean {
    if(filter.raw_value == "NULL") {
      if(filter.operator == "<>") {
        return value != undefined && value != null;
      } else if(filter.operator == "=") {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }
    let numberValue = value == null ? null: (value == "true" || value == "1" || value == true || value == 1);
    let rawValue = filter.raw_value == null ? null: (filter.raw_value == "true" || filter.raw_value == "1");
    if(filter.operator == "<>") {
      return numberValue != rawValue;
    } else if(filter.operator == "=") {
      return numberValue == rawValue;
    } else {
      return false;
    }
  }
  compareEnum(value: any, filter: SubMenuFilter): boolean {
    if(filter.raw_value == "NULL") {
      if(filter.operator == "<>") {
        return value != undefined && value != null;
      } else if(filter.operator == "=") {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }
    let id: any = null;
    if(typeof value === 'object') {
      id = value == null ? null: value.id;
    } else {
      id = value;
    }
    let rawValue = filter.raw_value;
    if(filter.operator == "<>") {
      return id != rawValue;
    } else if(filter.operator == "=") {
      return id == rawValue;
    } else {
      return false;
    }
  }
  compareString(value: any, filter: SubMenuFilter): boolean {
    if(filter.operator == '<>') {
      if(filter.raw_value == "NULL") {
        return value != undefined && value != null;
      } else {
        return String(value) != filter.raw_value;
      }
    } else if(filter.operator == '=') {
      if(filter.raw_value == "NULL") {
        return value == undefined || value == null;
      } else {
        return String(value).toLowerCase()?.includes(filter.raw_value?.toLowerCase());
      }
    } else {
      console.log("Warning: Wrong operator in filter");
      return false;
    }
  }

  getObjectValueFromDataKey(data_key: string, obj: any): any {
    let value;
    let keys = data_key.split('.');
    value = obj;
    for(let i = 0; i < keys.length; i++){
      value = value[keys[i]];
      if(value == undefined || value == null){
        break;
      }
      if(typeof value === 'function'){
        const v = value.bind(obj)();
        return v;
      }
    }
    return value;
  }

  applySearch(objects: any[]) {
    if(this.searchValue && this.searchValue.length > 0){
      let temp = [];
      temp = objects.filter((obj) => {
        return obj.vehicle?.externalId?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              obj.vehicle?.brand.makeName.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              obj.vehicle?.model.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              obj.vehicle?.fin_number?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              obj.customer?.contactPerson.firstName?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              obj.customer?.contactPerson.firstName?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              (obj.customer?.contactPerson.firstName.toLowerCase()+' '+obj.customer?.contactPerson.lastName.toLowerCase())?.includes(this.searchValue.toLowerCase()) ||
              obj.customer?.companyName?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              obj.purchase_contract_id?.toString().includes(this.searchValue) ||
              obj.custom_bill_ids?.includes(Number(this.searchValue));
      });
      this._objectsFiltered.next(temp);
    }
  }

  setUpSearch() {
    setTimeout(() => {
      const searchInput = document.getElementById('ab-search-list-input') as HTMLInputElement;
      if(searchInput != null){
        if(this.searchValue != null){
          searchInput.value = this.searchValue;
          this.rootFilter();
        }
        searchInput.addEventListener('input', (event: InputEvent) => {
          if(this.searchTimeout) clearTimeout(this.searchTimeout);
          this.searchTimeout = setTimeout(() => {
            this.searchValue = searchInput.value;
            this.rootFilter();
          }, 500);
        });
      }
      const searchInputReset = document.getElementById('ab-search-list-input-reset') as HTMLInputElement;
      if(searchInputReset){
        searchInputReset.addEventListener('click', (event: InputEvent) => {
          if(this.searchValue != null){
            const searchInput = document.getElementById('ab-search-list-input') as HTMLInputElement;
            if(searchInput){
              searchInput.value = null;
              this.searchValue = null;
              this.rootFilter();
            }
          }
        });
      }
    }, 200);
  }

  pageHandling(next: boolean) {
    this.pageHandler.action(next);
  }

  rootSorting() {
    let activeSub = this._activeSubMenu.getValue();
    if(this.activeSortingColumn == null && activeSub != null){
      for(let c of this._activeSubMenu.getValue().columns){
        if(c.sub_menu_column_id == activeSub.default_sort_column_id){
          this.activeSortingColumn = c;
          this.activeSortingColumn.sortingActive = true;
          this.activeSortingColumn.ascend = activeSub.default_sort_direction == 'ASC' ? true : false;
          break;
        }
      }
      if(this.activeSortingColumn == null){
        let index = this.activeSubMenu.columns.findIndex((c) => c.data_key == 'externalId');
        if(index > -1) this.activeSortingColumn = this.activeSubMenu.columns[index];
        else {
          this.activeSortingColumn = new SubMenuColumn(0,"id",0,"document",0,"id",false,false,1,null,null,null,null,"NUMBER",null);
        }
        this.activeSortingColumn.sortingActive = true;
        this.activeSortingColumn.ascend = false;
      }
    }
    if(this.activeSortingColumn != null && this.activeSortingColumn.sortingActive){
      this._objectsFiltered.getValue().sort((a,b) => {
        if (this.activeSortingColumn.sort_function) {
          if(this.activeSortingColumn.ascend) {
            if(this.activeSortingColumn.sort_function == 'getAustriaDataExtractStatus'){
              return a[this.activeSortingColumn.sort_function](b, this.enumService) * -1;
            } else {
              return a[this.activeSortingColumn.sort_function](b) * -1;
            }
          } else {
            if(this.activeSortingColumn.sort_function == 'getAustriaDataExtractStatus'){
              return a[this.activeSortingColumn.sort_function](b, this.enumService);
            } else {
              return a[this.activeSortingColumn.sort_function](b);
            }
          }
        }
        let value1;
        let value2;
        if(typeof a[this.activeSortingColumn.data_key] == 'function'){
          value1 = a[this.activeSortingColumn.data_key]();
          value2 = b[this.activeSortingColumn.data_key]();
        } else if(this.activeSortingColumn.data_key.includes('.')) {
          let deepObjKey = this.activeSortingColumn.data_key.split('.')[0];
          let deepFunctionORPara = this.activeSortingColumn.data_key.split('.')[1];
          let deepValueA = a[deepObjKey];
          let deepValueB = b[deepObjKey];
          if(deepValueA && typeof deepValueA[deepFunctionORPara] == 'function'){
            value1 = deepValueA[deepFunctionORPara]();
            value2 = deepValueB[deepFunctionORPara]();
          } else {
            value1 = deepValueA[deepFunctionORPara];
            value2 = deepValueB[deepFunctionORPara];
          }
        } else {
          value1 = this.getValueFromObjKey(this.activeSortingColumn.data_key, a);
          value2 = this.getValueFromObjKey(this.activeSortingColumn.data_key, b);
        }
        if(value1 == null) return this.activeSortingColumn.ascend ? -1 : 1;
        if(value2 == null) return this.activeSortingColumn.ascend ? 1 : -1;
        if(this.activeSortingColumn.data_typ == "STRING"){
          if(value1 != null && value2 != null && isNaN(value1) && isNaN(value2)){
            return this.activeSortingColumn.ascend ? value1?.localeCompare(value2 ? value2 : '') : value2?.localeCompare(value1 ? value1 : '');
          }
        }
        return this.activeSortingColumn.ascend ? (value1 - value2) : (value2 - value1);
      });
    }
  }

  getValueFromObjKey(data_key: string, obj: any): any {
    let keys = data_key.split('.');
    let value = obj;
    for(let i = 0; i < keys.length; i++){
      value = value[keys[i]];
      if(value == undefined || value == null){
        break;
      }
    }
    return value;
  }

  applySorting(column: SubMenuColumn) {
    //for reference
    if(this.activeSortingColumn.sub_menu_column_id != column.sub_menu_column_id){
      this.activeSortingColumn.sortingActive = false;
      this.activeSortingColumn.ascend = false;
    }
    this.activeSortingColumn = column;
    this.activeSortingColumn.setSortActive();
    this.rootSorting();
  }

  setPages() {
    this.pageHandler = new PageHandler(this._objectsFiltered.getValue().length);
  }

  customFilterOutside(filterValue: any) {
    this.activeFilterOutside = filterValue;
    if(this.activeFilterInside.length > 0 && this.activeFilterOutside.length > 0){
      this.activeFilter = this.activeFilterInside.concat([new SubMenuOperator("&&")]).concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    } else {
      this.activeFilter = this.activeFilterInside.concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    }
    this.rootFilter();
  }

  customFilter(filterValue: any) {
    this.activeFilterInside = filterValue;
    if(this.activeFilterInside.length > 0 && this.activeFilterOutside.length > 0){
      this.activeFilter = this.activeFilterInside.concat([new SubMenuOperator("&&")]).concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    } else {
      this.activeFilter = this.activeFilterInside.concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    }
    this.rootFilter();
  }

  //component specific

  checkRoute() {
    this.activateRoute.url?.subscribe(url => {
      if(url){
        let params = this.activateRoute.snapshot.queryParams;
        const action = url[1]?.path;
        const typ = params['typ'];
        if(typ == DokumentTyp.Kaufvertrag){
          if(action == 'create' && typ == DokumentTyp.Kaufvertrag){
            this.selectionCreateContract();
          } else {
            const purchaseContractId = params['purchaseContractId'];
            const obj = this._objects.find((p) => p.purchase_contract_id == purchaseContractId);
            this.selectionOpenDetails(obj);
          }
        }
      }
    });
  }

  selectionDetails(obj: any) {
    let queryParams = {};
    if(obj instanceof Kaufvertrag){
      queryParams = {
        'typ': DokumentTyp.Kaufvertrag,
        'purchaseContractId': obj.purchase_contract_id
      }
    }
    if(Object.keys(queryParams).length > 0) {
      const queryString = new URLSearchParams(queryParams).toString();
      const newUrl = `/documents?${queryString}`;
      this.location.go(newUrl);
      this.selectionOpenDetails(obj);
    }
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete_kaufvertrag') this.deleteKaufvertag();
        if(value.typ == 'storno_kaufvertrag') this.stornoKaufvertrag(value.submit_value);
        if(value.typ == 'renew_kaufvertrag') this.renewKaufvertrag(value.submit_value);
      }
    });
    this.dialogChangeStatusSubsription = this.dialogService.closeDialogEnum$.subscribe((value) => {
      if(value){
        if(value.typ == 'change_payment_status_contract'){
          this.changePaymentStatus(value.obj, value.submit_value);
        } else if(value.typ == 'change_contract_finanzsperrauskunft'){
          this.changeFinanzsperrauskunft(value.obj, value.submit_value);
        } else if(value.typ == 'change_contract_finanzsperrauskunft_status'){
          this.changeFinanzsperrauskunftStatus(value.obj, value.submit_value);
        } else if(value.typ == 'change_contract_austrian_data_extract'){
          this.changeAustrianDataExtract(value.obj, value.submit_value);
        }
      }
    });
    this.signatureLoaderSubscription = this.dialogService.closeDialogLoaderSignature$.subscribe((value) => {
      if(value){
        this.signatureObject.vertrag_status = KaufvertragStatus.SIGNED;
        let employee_id = Number(localStorage.getItem('employee_id'));
        this.signatureObject.employee_id = employee_id;
        const employeeMap = new Map(this.employees.map(employee => [employee.id, employee]));
        this.signatureObject.signer_employee = employeeMap.get(this.signatureObject.employee_id) || null;
        this.signatureObject.creator_employee = employeeMap.get(this.signatureObject.creator_employee_id) || null;
        this.updateDocumentLocal(this.signatureObject, this.showView == this.DokumentenView.DokumentListe ? 'update_list' : 'update');
      }
    })
  }

  mergeKaufvertragData(obj: Kaufvertrag) {
    const employeeMap = new Map(this.employees.map(employee => [employee.id, employee]));
    obj.signer_employee = employeeMap.get(obj.employee_id) || null;
    obj.creator_employee = employeeMap.get(obj.creator_employee_id) || null;
    return obj;
  }

  selectionCreateContract() {
    this.showView = DocumentenView.DokumentKaufVertragErstellen;
    this.dynamicListLastPosition = DynamicListHelper.getScrollPosition();
  }

  createKaufvertragSuccess(kaufvertrag: Kaufvertrag) {
    const employee = this.employees.find((e) => e.id == kaufvertrag.creator_employee_id);
    if(employee){
      kaufvertrag.creator_employee = employee;
    }
    this.updateDocumentLocal(kaufvertrag, 'create');
  }
  updateKaufvertragSuccess(kaufvertrag: Kaufvertrag){
    this.updateDocumentLocal(kaufvertrag, 'update');
  }

  updateDocumentLocal(obj: any, updateTyp: string) {
    if(updateTyp == 'create'){
      if(obj instanceof Kaufvertrag){
        this._objects.push(obj);
        this.selectedDocument = obj;
        this.showView = DocumentenView.DokumentKaufvertragDetails;
      }
    } else if(updateTyp == 'delete'){
      if(obj instanceof Kaufvertrag){
        this.deleteObjLocalArray(obj);
        this.showView = DocumentenView.DokumentListe;
      }
    } else if(updateTyp == 'update'){
      if(obj instanceof Kaufvertrag){
        this.selectedDocument = CloneObject.deepCopy(obj);
        this.updateLocalArrays(obj);
        this.signatureObject = null;
        this.showView = DocumentenView.DokumentKaufvertragDetails;
      }
    } else if(updateTyp == 'update_list'){
      if(obj instanceof Kaufvertrag){
        this.updateLocalArrays(obj);
        this.selectedDocument = null;
        this.signatureObject = null;
        this.tempObject = null;
        this.showView = DocumentenView.DokumentListe;
      }
    }
    this.rootFilter();
  }

  updateLocalArrays(obj) {
    if(obj instanceof Kaufvertrag){
      let index = this.kaufvertraege.findIndex((v) => v.purchase_contract_id == obj.purchase_contract_id);
      if(index > -1) this.kaufvertraege[index] = obj;
      let index2 = this._objects.findIndex((v) => v.purchase_contract_id == obj.purchase_contract_id);
      if(index2 > -1) this._objects[index2] = obj;
    }
  }

  deleteObjLocalArray(obj) {
    if(obj instanceof Kaufvertrag){
      let index1 = this.kaufvertraege.findIndex((v) => v.purchase_contract_id == obj.purchase_contract_id);
      if(index1 > -1) this.kaufvertraege.splice(index1,1);
      let index2 = this._objects.findIndex((v) => v.purchase_contract_id == obj.purchase_contract_id);
      if(index2 > -1) this._objects.splice(index2,1);
    }
  }

  selectionOpenDetails(document: any){
    this.selectedDocument = document;
    if(this.authService.isSinhuber()) {
      if(document instanceof Kaufvertrag){
        this.showView = DocumentenView.DokumentKaufvertragDetails;
      }
      return;
    }
    this.documentService.getLinkedObjects(AttachmentType.PURCHASE_CONTRACT, document.purchase_contract_id).subscribe((result) => {
      if(result){
        this.selectedDocument.bills = result.bills;
        if(document instanceof Kaufvertrag){
          this.showView = DocumentenView.DokumentKaufvertragDetails;
        }
      }
    })
    this.dynamicListLastPosition = DynamicListHelper.getScrollPosition();
  }

  selectionEdit(document?: any) {
    if(document!= null) this.selectedDocument = document;
    if(this.selectedDocument instanceof Kaufvertrag){
      this.showView = DocumentenView.DokumentKaufVertragErstellen;
    }
  }

  selectionClose() {
    if(this.selectedDocument && this.showView == this.DokumentenView.DokumentKaufVertragErstellen){ //if edit view
      this.showView = this.DokumentenView.DokumentKaufvertragDetails;
      return;
    }
    this.selectedDocument = null;
    this.location.replaceState('/documents');
    this.setUpSearch();
    this.showView = this.DokumentenView.DokumentListe;
    DynamicListHelper.scrollToPosition(this.dynamicListLastPosition);
  }

  selectionDelete(document?: any) {
    if(document) this.selectedDocument = document;
    if(this.selectedDocument instanceof Kaufvertrag){
      this.openDialogDeleteContract();
    }
  }

  openDialogDeleteContract() {
    this.dialogService.openQuery(
      {
        title: 'Dokument löschen',
        message: 'Sind sie sicher, dass Sie dieses Dokument unwiderruflich löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete_kaufvertrag',
        submit_value: this.selectedDocument.purchase_contract_id,
      }
    );
  }

  deleteKaufvertag() {
    this.documentService.deleteContract(this.selectedDocument.purchase_contract_id).subscribe((success) => {
      if(success){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Kaufvertrag wurde gelöscht.',
          success: true
        });
        this.updateDocumentLocal(this.selectedDocument, 'delete');
      }
    })
  }

  selectionPseudoSignature(obj: Kaufvertrag){
    this.updateDocumentLocal(obj, 'update');
  }

  selectionUploadDocumentAction(object: any, triggerInput: boolean) {
    if(triggerInput){
      this.tempObject = object;
      const inputFile = document.getElementById('input-file') as HTMLInputElement;
      if(inputFile){
        inputFile.click();
      }
    } else {
      this.selectionUploadDocument(DokumentTyp.Kaufvertrag,object.purchase_contract_id, null);
    }
  }

  async uploadFile(event: any) {
    if(event.target.files && event.target.files.length != 0){
      let data = await this.readFile(event.target.files[0]);
      if(data){
        this.selectionUploadDocument(3,this.tempObject.purchase_contract_id, data.pdf);
      }
      event.target.value = '';
    }
  }
  async readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        let data = {
          name: file.name.split('.')[0],
          format: file.name.split('.')[1],
          pdf: event.target.result.split(',')[1]
        }
        resolve(data)
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(null);
      }
    })
  }

  selectionUploadDocument(typ: number, purchase_contract_id: number, pdf: string){
    this.documentService.uploadPDF(typ, purchase_contract_id, pdf).subscribe((success) => {
      if(success){
        let doc;
        let message;
        if(typ == DokumentTyp.Kaufvertrag){
          let index = this._objects.findIndex((d) => d.purchase_contract_id == purchase_contract_id);
          if(index == -1) return;
          doc = this._objects[index];
          doc.vertrag_status = KaufvertragStatus.SIGNED;
          const userID = Number(localStorage.getItem('employee_id'));
          if(userID){
            const employee = this.employees.find((e) => e.id == userID);
            if(employee){
              doc.employee_id = userID;
              doc.signer_employee = employee;
            }
          }
          message = 'Kaufvertrag wurde signiert.';
        }
        this.updateDocumentLocal(doc, this.showView == this.DokumentenView.DokumentListe ? 'update_list' : 'update');
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: message,
          success: true
        });
      }
    });
  }

  openDialogStorno(document: Kaufvertrag) {
    this.dialogService.openQuery(
      {
        title: 'Vertrag stornieren',
        message: 'Sind sie sicher, dass Sie den Vertrag stornieren möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Stornieren',
        typ: 'storno_kaufvertrag',
        submit_value: document,
      }
    );
  }

  stornoKaufvertrag(document: Kaufvertrag) {
    this.documentService.cancelKaufvertrag(document).subscribe((result) => {
      if(result){
        document.vertrag_status = KaufvertragStatus.CANCELED;
        this.documentService.getLinkedObjects(AttachmentType.PURCHASE_CONTRACT, document.purchase_contract_id).subscribe((result) => {
          if(result){
            document.bills = result.bills;
            this.updateDocumentLocal(document, 'update');
            this.dialogService.showNotification({
              title: 'Erfolgreich',
              message: 'Kaufvertrag wurde storniert.',
              success: true
            });
          }
        })
      }
    });
  }

  selectionAnzahlungKassieren(kaufvertag: Kaufvertrag) {
    this.documentService.createPurchaseContractPayment(kaufvertag.purchase_contract_id).subscribe((result) => {
      if(result){
        this.updateDocumentLocal(result, "update");
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Anzahlung kassiert.',
          success: true
        });
      }
    })
  }

  selectionEndzahlungKassieren(kaufvertag: Kaufvertrag) {
    this.documentService.createPurchaseContractPayment(kaufvertag.purchase_contract_id).subscribe((result) => {
      if(result){
        this.updateDocumentLocal(result, "update");
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Endzahlung kassiert.',
          success: true
        });
      }
    })
  }

  getContractPDFUnsignedSigned(data: any) {
    this.documentService.getContractPDFUnsignedSigned(data.unsigned, data.document.purchase_contract_id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true })
      }
    })
  }

  selectionMoveContract(data: any){
    let contract = data.contract;
    let move = data.move;
    if(contract != null && move == 'archiv'){
      this.documentService.archiveContract(contract).subscribe((result) => {
        if(result){
          result.vertrag_status = KaufvertragStatus.ARCHIVED;
          this.updateDocumentLocal(result, this.showView == DocumentenView.DokumentListe ? 'update_list' : 'update');
        }
      })
    }
  }

  openDialogRenew(document: Kaufvertrag) {
    this.dialogService.openQuery(
      {
        title: 'Vertrag neu ausstellen',
        message: 'Sind sie sicher, dass Sie den Vertrag neu ausstellen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Neu ausstellen',
        typ: 'renew_kaufvertrag',
        submit_value: document,
      }
    );
  }

  renewKaufvertrag(document: Kaufvertrag) {
    let newDocument = CloneObject.deepCopy(document);
    this.documentService.cancelKaufvertrag(document).subscribe((resultStorno) => {
      if(resultStorno){
        document.vertrag_status = KaufvertragStatus.CANCELED;
        let index = this._objects.findIndex((v) => v.purchase_contract_id == document.purchase_contract_id);
        if(index > -1){
          this._objects[index] = document;
        }
        this.documentService.createKaufvertrag(newDocument, this.zusatzprodukte).subscribe((resultNew) => {
          if(resultNew){
            resultNew.vehicle = newDocument.vehicle;
            resultNew.customer = newDocument.customer;
            resultNew.verfuegbareZusatzprodukte = newDocument.verfuegbareZusatzprodukte
            this.dialogService.showNotification({
              title: 'Erfolgreich',
              message: 'Kaufvertrag neu erstellt.',
              success: true
            });
            this.createKaufvertragSuccess(resultNew);
            setTimeout(() => {
              this.selectionEdit();
            }, 100);
          }
        });
      }
    });
  }

  selectionRestore(obj: any){
    if(obj instanceof Kaufvertrag){
      this.documentService.restoreContract(obj).subscribe((result) => {
        if(result){
          obj.vertrag_status = KaufvertragStatus.OPEN;
          this.updateDocumentLocal(obj, 'update_list');
          this.dialogService.showNotification({
            title: 'Erfolgreich',
            message: 'Kaufvertrag wurde wiederhergestellt.',
            success: true
          });
        }
      })
    }
  }

  selectionChangePaymentStatus(obj: any) {
    if(obj instanceof Kaufvertrag){
      this.dialogService.openEnum(
        {
          title: 'Status ändern',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Status ändern',
          typ: 'change_payment_status_contract',
          enums: this.paymenStatus,
          selected_value: obj.payment_status,
          colors: ['#D84949','#D2B836','#6A9E18'],
          obj: obj,
        }
      );
    }
  }

  changePaymentStatus(obj: any, submitValue: number) {
    let status = 0;
    if(this.paymenStatus[submitValue] == PaymentStatus.NichtBezahlt) status = 0;
    else if(this.paymenStatus[submitValue] == PaymentStatus.AnzahlungBezahlt) status = 1;
    else if(this.paymenStatus[submitValue] == PaymentStatus.Bezahlt) status = 2;
    obj.payment_status = status;
    this.documentService.setKaufvertragPaymentStatus(obj).subscribe((result) => {
      if(result){
        this.updateDocumentLocal(obj, 'update_list');
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Status geändert',
          success: true
        });
        this.activeSubMenu.setActive(true);
      }
    })
  }

  selectionAppSignature(obj: any){
    if(obj instanceof Kaufvertrag){
      this.documentService.appSignaturePurchaseContract(obj).subscribe((result) => {
        if(result){
          this.signatureObject = obj;
          this.dialogService.openLoaderSignature({document_typ: 3}); //3 Kaufvertrag
        }
      });
    }
  }

  selectionReceiveFunding(obj: Kaufvertrag) {
    if(obj){
      this.selectedDocument = obj;
    }
    this.showDialogReceiveFunding = true;
  }

  selectionCloseReceiveFundingDialog() {
    this.showDialogReceiveFunding = false;
  }

  selectionSubmitReceiveFunding(obj: Kaufvertrag) {
    this.showDialogReceiveFunding = false;
    this.updateDocumentLocal(obj, this.showView == DocumentenView.DokumentKaufvertragDetails ? 'update' : 'update_list');
  }

  selectionChangeFinanzsperrauskunft(obj: Kaufvertrag) {
    let finanzsperrauskunftValues = this.enumService.getEnumValues('finanzsperrauskunft');
    this.dialogService.openEnum(
      {
        title: 'Typenschein ändern',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Speichern',
        typ: 'change_contract_finanzsperrauskunft',
        enums: finanzsperrauskunftValues,
        selected_value: obj.vehicle.finanzsperrauskunft-1,
        colors: ['#597CF1'],
        obj: obj,
      }
    );
  }


  changeFinanzsperrauskunft(obj: any, submitValue: number) {
    const enumValue = submitValue+1;
    obj.vehicle.finanzsperrauskunft = enumValue;
    this.updateVehicle(obj);
  }

  selectionChangeFinanzsperrauskunftStatus(obj: Kaufvertrag) {
    let finanzsperrauskunftStatusValues = this.enumService.getEnumValues('finanzsperrauskunft_status');
    this.dialogService.openEnum(
      {
        title: 'Finanzsperrauskunft',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Speichern',
        typ: 'change_contract_finanzsperrauskunft_status',
        enums: finanzsperrauskunftStatusValues,
        selected_value: obj.vehicle.finanzsperrauskunft_status-1,
        colors: ['#597CF1'],
        obj: obj,
      }
    );
  }

  changeFinanzsperrauskunftStatus(obj: Kaufvertrag, submitValue: number) {
    const enumValue = submitValue+1;
    obj.vehicle.finanzsperrauskunft_status = enumValue;
    this.updateVehicle(obj);
  }

  selectionChangeAustrianDataExtract(obj: Kaufvertrag) {
    let austrianDataExtractValues = this.enumService.getEnumValues('austrian_data_extract');
    this.dialogService.openEnum(
      {
        title: 'Österr. Datenauszug',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Speichern',
        typ: 'change_contract_austrian_data_extract',
        enums: austrianDataExtractValues,
        selected_value: obj.vehicle.austrian_data_extract-1,
        colors: ['#597CF1'],
        obj: obj,
      }
    );
  }

  changeAustrianDataExtract(obj: Kaufvertrag, submitValue: number) {
    const enumValue = submitValue+1;
    obj.vehicle.austrian_data_extract = enumValue;
    this.updateVehicle(obj);
  }

  updateVehicle(contract: Kaufvertrag) {
    this.vehicleService.getSingleVehicle(contract.vehicle.id).subscribe((vehicle) => {
      if(vehicle) {
        let temp = CloneObject.deepCopy(contract.vehicle);
        contract.vehicle = vehicle;
        contract.vehicle.finanzsperrauskunft = temp.finanzsperrauskunft;
        contract.vehicle.finanzsperrauskunft_status = temp.finanzsperrauskunft_status;
        contract.vehicle.austrian_data_extract = temp.austrian_data_extract;
        this.vehicleService.updateVehicle(contract.vehicle).subscribe((result) => {
          if(result){
            contract.vehicle = result;
            contract = CloneObject.deepCopy(contract);
            this.updateDocumentLocal(contract, this.showView == DocumentenView.DokumentListe ? 'update_list' : 'update');
            this.dialogService.showNotification({
              title: 'Erfolgreich',
              message: 'Datensatz geändert',
              success: true
            });
            this.activeSubMenu.setActive(true);
          }
        })
      } else {
        this.vehicleService.updateSavedVehicle(contract.vehicle, contract.saved_vehicle_id).subscribe((result) => {
          if(result){
            contract.vehicle = result;
            contract = CloneObject.deepCopy(contract);
            this.updateDocumentLocal(contract, this.showView == DocumentenView.DokumentListe ? 'update_list' : 'update');
            this.dialogService.showNotification({
              title: 'Erfolgreich',
              message: 'Datensatz geändert',
              success: true
            });
            this.activeSubMenu.setActive(true);
          }
        });
      }
    })
  }
}
