<div id="overview">
    <div id="container1">
        <div id="name-container">
            <div class="name">remove.bg</div>
            <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
            </label>
        </div>
        <div id="image-container">
            <div id="help-container">
                <img class="img-help" src="../../../../../../assets/icon-info-white.png">
                <div class="help-txt">Wenn Sie mit remove.bg ein Titelbild wählen, wird dieses als konvertiert gezählt.</div>
            </div>
            <img id="cover" src="../../../../../../assets/placeholder-cover.png">
            <div id="button-container">
                <button id="choose">Titelbild wählen</button>
                <button id="delete">Löschen</button>
            </div>
        </div>
        <button id="search-documents" (click)="selectFile.click()">Dateien auswählen</button>
        <input #selectFile type="file" accept="image/*" capture="environment" (change)="uploadFile($event)" style="display: none;">
    </div>
    <div id="container2">
        <img class="img-car active" src="../../../../../../assets/placeholder-car-9.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-16.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-17.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-7.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-14.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-13.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-8.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-6.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-5.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-4.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-12.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-11.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-10.png">
        <img class="img-car" src="../../../../../../assets/placeholder-car-15.png">
    </div>
</div>
