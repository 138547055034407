export enum TypId {
  "Intern" = 1,
  "Fahrzeug" = 2,
  "Kunde" = 3,
  "Document" = 4,
  "Probefahrt" = 5,
}

export enum Urgency {
  "später erledigen" = 0,
  "erledigen" = 1,
  "sofort erledigen" = 2,
}

export enum PaperStatus {
  VALUE1 = "Typenschein nicht eingegangen",
  VALUE2 = "Santander",
  VALUE3 = "Easyleasing",
  VALUE4 = "Erste Bank Leasing",
  VALUE5 = "Lagernd",
  VALUE6 = "Versendet"
}

export enum Ersatzteile {
  VALUE1 = "bestellt",
  VALUE2 = "lagernd",
  VALUE3 = "Bestellung offen",
}

export enum RepairStatus {
  VALUE1 = "erledigt",
  VALUE2 = "notwendig",
  VALUE3 = "nicht notwendig ",
}

export enum LastRoute {
  VEHICLES = "vehicleId",
  CUSTOMERS = "customerId",
}

export enum TypenscheinBank {
  VALUE1 = "Ja",
  VALUE2 = "Nein",
  VALUE3 = "Nicht notwendig",
}

export enum Damages {
  VALUE1 = "Ja",
  VALUE2 = "Nein",
  VALUE3 = "Behoben",
  VALUE4 = "Unbekannt"
}

export enum Aufbereitung {
  VALUE1 = "Komplettaufbereitung",
  VALUE2 = "Schnellreinigung",
  VALUE3 = "erledigt",
  VALUE4 = "nacharbeiten",
  VALUE5 = "nicht notwendig ",
}

export enum AufbereitungZustaendigkeit {
  VALUE1 = "Peter Güttler",
  VALUE2 = "Wolfgang Brunner",
  VALUE3 = "Extern",
}

// export enum AnkaufstestDurchfuehrung {
//   VALUE1 = "durchführen",
//   VALUE2 = "erledigt",
// }

export enum Ankaufstest {
  VALUE1 = "durchführen",
  VALUE2 = "erledigt",
}

export enum Lackierung {
  VALUE1 = "erledigt",
  VALUE2 = "notwendig ",
  VALUE3 = "nicht notwendig ",
}

export enum Zustand {
  VALUE1 = "BESONDERES GUT",
  VALUE2 = "GUT ",
  VALUE3 = "GENÜGEND FAHRBEREIT",
  VALUE4 = "DEFEKT ",
}

export enum Finanzierungsgesellschaft {
  VALUE1 = "Easyleasing St. Pölten",
  VALUE2 = "Santander",
  VALUE4 = "Erste Bank Leasing",
}

// export enum Finanzierungsart {
//   VALUE1 = "offen",
//   VALUE2 = "bar",
//   VALUE3 = "Überweisung",
//   VALUE4 = "Leasing",
//   VALUE5 = "Kredit",
// }

export enum Finanzierungszusage {
  VALUE1 = "erhalten",
  VALUE2 = "offen",
}

export enum Fahrzeuglieferanten {
  VALUE1 = "Vehicle Trading",
  VALUE2 = "Audi Leasing",
  VALUE3 = "VW Leasing",
  VALUE4 = "Seat Leasing",
  VALUE5 = "Skoda Leasing",
  VALUE7 = "ALD Automotive",
  VALUE8 = "Raiffeisen Leasing",
  VALUE9 = "Autorola",
  VALUE10 = "Auto1.com",
  VALUE11 = "BMW Austria Leasing",
  VALUE12 = "easyleasing",
  VALUE13 = "LeasePlan",
  VALUE14 = "Flottenmanagement"
}



//Steuersätze
export enum Steuersatz {
  VALUE1 = "Umsatzsteuer",
  VALUE2 = "Differenzsteuer",
  VALUE3 = "Umsatzsteuer BRD (ab März 2014)",
  VALUE4 = "Umsatzsteuer BRD (bis Februar 2014)",
}
export enum SteuersatzStandard {
  VALUE1 = "Umsatzsteuer",
  VALUE2 = "Differenzsteuer",
}
export enum SteuersatzValue {
  UMSATZSTEUER = 1,
  DIFFERENZSTEUER = 2,
  UMSATZSTEUER_BRD_M2014 = 3,
  UMSATZSTEUER_BRDF_2014 = 4
}
const steuersatz_keys = ['UMSATZSTEUER','DIFFERENZSTEUER','UMSATZSTEUER_BRD_M2014','UMSATZSTEUER_BRDF_2014'];
type steuersatz_key = typeof steuersatz_keys[number];
type STEUERSATZKEYS<ValueType> = Record<steuersatz_key, ValueType>;
export const STEUERSATZVALUES: STEUERSATZKEYS<number> = {
  UMSATZSTEUER: 20,
  DIFFERENZSTEUER: 0,
  UMSATZSTEUER_BRD_M2014: 19,
  UMSATZSTEUER_BRDF_2014: 19,
}
