<form class="contract_create_page_wrapper" [formGroup]="form">
  <div class="lbl_bold_light_color">Neuer Kaufvertag</div>
  <div class="page_body">
    <div class="column">
      <div class="list_title_lbl" style="margin-bottom: 20px;">Fahrzeug</div>
      <app-dropdown-objects-search
          [title]="'Fahrzeug *'"
          [placeholder]="'Fahrzeug'"
          [headervalue]="form.controls.vehicle.value ? form.controls.vehicle.value.getVehicleName() : ''"
          [formcontrol]="form.controls.vehicle"
          [values]="vehicleValues"
          [submitted]="submitted"
          (submitValueEmitter)="selectionValueFromDropdown('vehicle', $event)"
      ></app-dropdown-objects-search>
      <div *ngIf="selectedVehicle" id="vehilce_overview_container">
        <div class="overview_record">
          <label class="overview_record_lbl">Marke</label>
          <label class="overview_record_value">{{getInputValue(selectedVehicle.brand.makeName)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Modell</label>
          <label class="overview_record_value">{{getInputValue(selectedVehicle.model.name)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">KFZ-Typ</label>
          <label class="overview_record_value">{{getInputValue(selectedVehicle.bodyTyp.name)}}</label>
        </div>
        <div class="overview_record" style="margin-bottom: 15px;">
          <label class="overview_record_lbl">Farbe</label>
          <label class="overview_record_value">{{getInputValue(selectedVehicle.bodyColor.name)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Vorbesitzer</label>
          <label class="overview_record_value">{{getInputValueNumber(selectedVehicle.previousOwners)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Erstzulassung</label>
          <label class="overview_record_value">{{selectedVehicle.firstRegistrationDate | timestampFormat}}</label>
        </div>
        <div class="overview_record" style="margin-bottom: 15px;">
          <label class="overview_record_lbl">Kilometerstand</label>
          <label class="overview_record_value">{{(getInputValue(selectedVehicle.mileageKm) | numberFormat) +" km"}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">Motornummer</label>
          <label class="overview_record_value">{{getInputValue(selectedVehicle.engineNumber)}}</label>
        </div>
        <div class="overview_record">
          <label class="overview_record_lbl">FZG-Identifizierungsnr.</label>
          <label class="overview_record_value">{{getInputValue(selectedVehicle.fin_number)}}</label>
        </div>
      </div>
    </div>
    <div class="column" >
      <div class="list_title_lbl" style="margin-bottom: 20px;">Registrierung</div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Erstregistrierung im Ausland</label>
        <input #inputDate type="date" placeholder="date" [min]="maxDate" formControlName="foreign_registration_date" [class.date-input--has-value]="inputDate.value != null" [class.input_invalid]="submitted && form.controls.foreign_registration_date.errors">
      </div>
      <div class="lbl_regular" style="margin-top: 20px;">Dokument Typ</div>
      <div class="radio_block">
        <div class="radio_container" (click)="f.registration_document.setValue(1)">
          <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="f.registration_document.value == 1 ? 'radio_icon_inner_active' : ''"></div></div>
          <label class="lbl_info">Typenschein</label>
        </div>
        <div class="radio_container" (click)="f.registration_document.setValue(2)">
          <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="f.registration_document.value == 2 ? 'radio_icon_inner_active' : ''"></div></div>
          <label class="lbl_info">Genehmigung für Zulassung</label>
        </div>
        <div class="radio_container" (click)="f.registration_document.setValue(3)">
          <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="f.registration_document.value == 3 ? 'radio_icon_inner_active' : ''"></div></div>
          <label class="lbl_info">Einzelgenehmigung des Landeshauptmannes</label>
        </div>
      </div>
      <div class="lbl_regular" [ngStyle]="{'opacity': f.birthdate.value ? '1' : '0'}">Dokument Zustand</div>
      <div class="radio_block">
        <div *ngIf="f.registration_document.value" class="radio_container" (click)="f.is_original_document.setValue(true)">
          <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="f.is_original_document.value ? 'radio_icon_inner_active' : ''"></div></div>
          <label class="lbl_info">Original</label>
        </div>
        <div *ngIf="f.registration_document.value" class="radio_container" (click)="f.is_original_document.setValue(false)"  style="margin-bottom: 15px;">
          <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="!f.is_original_document.value ? 'radio_icon_inner_active' : ''"></div></div>
          <label class="lbl_info">Kopie</label>
        </div>
      </div>
    </div>
  </div>
</form>
