import { Customer } from "../customer/Customer";
import { Employee } from "../employee/Employee";
import { User } from "../user/User";
import { Vehicle } from "../vehicle/Vehicle";
import { Message } from "./Message";

export class Thread {
    // public messages: Message[] = [];
    public companyName: string;
    public customer: Customer;
    public employee: Employee;
    public vehicle: Vehicle;
    public user: User;
    public archived = false;
    constructor(
        public threadId: number,
        public locationId: number,
        public creator: number,
        public typ: number,
        public status: number,
        public urgency: number,
        public notifyOpen: boolean,
        public createdDate: number,
        public typId: number,
        public resolved: number,
        public messages: Message[]
    ){}

    getTypName(): string {
      switch(this.typ) {
        case 0: return 'Intern'; break;
        case 1: return 'Fahrzeug'; break;
        case 2: return 'Kunde'; break;
        default: return ''; break;
      }
    }

    getTicketTitle(el): string {
      let cutLength = 200;
      if(el.offsetWidth < 1300) cutLength = 150;
      else if(el.offsetWidth < 1000) cutLength = 100;
      this.messages.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
      let rows = this.messages[0].message.split('<br>');
      let rowLength = rows[0].length;
      if(rowLength < cutLength) cutLength = rowLength;
      let txt = this.cutString(this.messages[0].message, cutLength);
      return txt
    }

    cutString(str: string, len?: number): string {
      if(str){
        if(len){
          if(str.length > len){
            return str.slice(0,len) + ' ...'
          } else {
            return str;
          }
        } else {
          return str;
        }
      } else {
          return '';
      }
    }
}
