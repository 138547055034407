<div id="chat_container">
  <div id="chat_inner_wrapper">
    <img id="chat_icon" src="../../../../assets/chaticon.png">
    <div id="chat_txt_container">
      <div class="lbl_chat">Sie möchten noch eine <span style="font-weight: bold;">Schnittstelle hinzufügen?</span></div>
      <div class="lbl_chat">Schreiben Sie uns!</div>
      <div class="btn_submit"  onclick="location.href='mailto:office@auto-base.at'">E-Mail verfassen</div>
    </div>
  </div>
  <div id="circle_one"></div>
  <div id="circle_two"></div>
</div>
