<div class="select_container">
    <div class="input_container">
        <div *ngIf="valueSelected" class="lbl_title">{{title}}</div>
        <div #dropdownInputContainer class="input_dropdown_container" [ngClass]="disabled ? 'dropdown_container_disabled' : ''" [ngClass]="invalid ? 'input_dropdown_container_invalid' : ''" [ngStyle]="{'border-color': openDropdown ? borderBottomColor : ''}" (click)="disabled ? '' : openDropdown = !openDropdown">
            <div #dropdownInput class="dropdown_input_base" [ngClass]="invalid ? 'dropdown_input_invalid' : (valueSelected ? 'input_has_value' : 'input_default')" [ngStyle]="{'color': disabled ? '#aaaaaa63' : ''}">{{placeholder}}</div>
            <img #dropdownImg class="dropdown_arrow" src="../../../../assets/arrow-down.png" [class.drop-arrow-rotated]="openDropdown">
        </div>
    </div>
    <div #dropdownOptionsContainer id="dropdown_container" [@openDrop]="openDropdown ? 'open' : 'close'">
        <div id="dropdown_inner_container" >
            <div  [ngClass]="dropOptionHover == 'dark' ? 'dropbox_value_dark' : 'dropbox_value_blue'" *ngFor="let opt of options" (click)="setOption(opt)">{{opt}}</div>
        </div>
    </div>
</div>
