<div class="dropdown_filter_container" [ngClass]="showFilter ? 'dropdown_filter_container_open' : ''">
  <div class="lbl_list_title">FILTER</div>
  <div #dropdownOne class="dropdown_wrapper" >
    <div class="lbl_regular_small" [ngStyle]="{'opacity': tempFilter.customer ? '1' : '0'}">Kunde</div>
    <div class="dropdown_input_header" (click)="showDropdownOne = !showDropdownOne">
      <input #input_1 class="dropdown_input" type="text" [placeholder]="!tempFilter.customer ? 'Kunde' : ''" [value]="!tempFilter.customer ? '' : tempFilter.customer.typ == 1 ? tempFilter.customer.companyName : (tempFilter.customer.contactPerson.firstName + ' ' + tempFilter.customer.contactPerson.lastName)" (input)="filterCustomers(input_1.value)">
      <img class="dropdown_input_header_icon" [ngClass]="showDropdownOne ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
    </div>
    <div class="dropdown_input_body" *ngIf="showDropdownOne">
      <div *ngFor="let customer of customersFiltered" [id]="'dropdown_value_'+customer.id" class="dropdown_data_body_element" (click)="handleDropdownSelection(customer)">{{customer.getName()}}</div>
    </div>
  </div>
  <!-- <div class="date_container">
    <div style="width: 100%;">
      <div class="lbl_regular" [ngStyle]="{'opacity': tempFilter.start_date != null ? '1' : '0'}">Von in TT.MM.JJJJ</div>
      <div appCustomDateFormat2 class="date_input_wrapper">
          <input #dateInput1 class="inputfield" [ngStyle]="{'width': '100%'}" placeholder="Von in TT.MM.JJJJ" type="text" maxlength="10" [matDatepicker]="picker1" [class.inputfield_invalid]="!IsValidDateInput(dateInput1.value)" [(ngModel)]="tempFilter.start_date"
            onkeypress="IsCharDate(event)">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1
                          id="datepicker_filter"
          ></mat-datepicker>
      </div>
    </div>
    <div style="width: 100%;">
      <div class="lbl_regular" [ngStyle]="{'opacity': tempFilter.end_date != null ? '1' : '0'}">Bis in TT.MM.JJJJ</div>
      <div appCustomDateFormat2 class="date_input_wrapper">
          <input #dateInput2 class="inputfield" [ngStyle]="{'width': '100%'}" placeholder="Bis in TT.MM.JJJJ" type="text" maxlength="10" [matDatepicker]="picker2" [ngClass]="false ? 'inputfield_invalid' : ''" [(ngModel)]="tempFilter.end_date">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2
                          id="datepicker_filter"
          ></mat-datepicker>
      </div>
    </div>
  </div> -->
  <div class="footer" [ngStyle]="{'justify-content': !filterInUse ? 'flex-end' : 'space-between'}">
    <div *ngIf="filterInUse" class="btn_menu_information" (click)="resetFilter()">Zurücksetzen</div>
    <div class="btn_submit_dark" (click)="submitFilter()">Filtern</div>
  </div>
</div>

