export class PageHandler {
    public sliceFrom: number;
    public sliceTo: number;
    public rest = 0;
    public maxElementsInList: number = 50;

    constructor(
        public countOfObjs: number
    ){
        this.setPageValue();
    }

    setPageValue(){
        if(this.countOfObjs <= this.maxElementsInList){
          this.sliceTo = this.countOfObjs;
        }else{
          this.sliceTo = this.maxElementsInList;
        }
        this.sliceFrom = 0;
        this.rest = this.countOfObjs % this.sliceTo;
      }

      next(){
        if(this.sliceTo < this.countOfObjs){
           this.sliceFrom += this.maxElementsInList;
           this.sliceTo += this.maxElementsInList;
           if(this.sliceTo > this.countOfObjs){
             this.sliceTo = this.countOfObjs;
           }
        }
      }

      previous(){
        if(this.sliceFrom != 0 ){
          if(this.sliceTo == this.countOfObjs){
            this.sliceTo -= (this.countOfObjs % this.maxElementsInList);
            if(this.sliceTo == this.countOfObjs){
              this.sliceTo -= this.maxElementsInList;
            }
            this.sliceFrom -= this.maxElementsInList;
          } else if(this.sliceFrom > 0){
            this.sliceFrom -= this.maxElementsInList;
            this.sliceTo -= this.maxElementsInList;
          }
        }
      }

      action(next: boolean) {
        if(next){
          this.next()
        } else {
          this.previous();
        }
      }

      getLabel(): string {
          let from = "";
          if(this.countOfObjs == 0){
              from = "0";
          } else {
              from = String(this.sliceFrom + 1);
          }
          return from + '-' + this.sliceTo + ' von ' + this.countOfObjs;
      }
}
