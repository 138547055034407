<div id="signature_loader" *ngIf="show">
  <div id="signature_loader_waiting" *ngIf="!sucess">
    <div class="loader"></div>
    <label style="margin-bottom: 20px;">Signieren Sie das Dokument in der App</label>
    <button *ngIf="running_signature_id" class="btn_cancel" (click)="abort()">Abbrechen</button>
  </div>
  <div id="signature_loader_success" *ngIf="sucess">
    <img src="assets/successparty.svg">
    <label>{{getSuccessText()}}</label>
  </div>
</div>
