<div class="page-container">
  <div class="page-header">
    <div class="info_btn_wrapper">
      <div class="btn_menu_information" [ngClass]="true ? 'btn_menu_information_active' : ''" (click)="null">Fahrzeuge</div>
    </div>
  </div>
  <div class="page-body">
    <form [formGroup]="form">
      <div class="overview_data_box_lbl_title">Preisblatt</div>
      <div class="page-body-package">
        <div *ngFor="let setting of settings">
          <div *ngIf="showValuePricesheet(setting.name)">
            <div class="overview_data_form">
              <div class="input_wrapper">
                <label class="overview_record_lbl">{{setting.label}}</label>
                <input type="number" class="overview_input" placeholder="---" min="0" step="1" formControlName="{{setting.name}}"  [class.input_invalid]="submitted && form.controls[setting.name].errors">
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="list_footer"></div>
</div>
