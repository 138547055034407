import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-create-bill-document-element',
  templateUrl: './create-bill-document-element.component.html',
  styleUrls: ['./create-bill-document-element.component.css']
})
export class CreateBillDocumentElementComponent implements OnInit {
  @Input() submitted: boolean;
  @Input() index: number;
  @Input() form: UntypedFormGroup;
  @Output() removeEmitter = new EventEmitter<number>();
  @Output() submitEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  get f() {
    return this.form.controls;
  }


}
