<div class="overview_data_box">
    <img id="zweispurig" src="../../../../../assets/logo-zweispurig-little.png">
    <div id="heading">
        <div class="overview_data_box_lbl_title">Zweispurig</div>
        <div id="switch-container">
            <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
            </label>
        </div>
        <a href="https://www.zweispurig.at/" target="_blank" class="info">zweispurig.at</a>
    </div>
    <div class="overview_record">
        <div class="overview_record_lbl">Datenqualität</div>
        <div class="overview_record_value">value</div>
    </div>
    <div class="overview_data_box_lbl_inner_title">Antrieb</div>
    <div class="overview_record">
        <div class="overview_record_lbl">Datenqualität</div>
        <div class="overview_record_value">value</div>
    </div>
    <div class="overview_record">
        <div class="overview_record_lbl">Datenqualität</div>
        <div class="overview_record_value">value</div>
    </div>
    <div class="overview_data_box_lbl_inner_title">Ausstattung</div>
    <div class="equipment">
        <img class="icon_check" src="../../../../../assets/icon-listitem-blue.png">
        <div class="overview_record_value">Austattung A-Z</div>
    </div>
</div>


<!-- <div class="overview_single_data_box">
    <img id="zweispurig" src="../../../../../assets/logo-zweispurig-little.png">
    <div id="heading">
        <div class="title">Zweispurig</div>
        <div id="switch-container">
            <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
            </label>
        </div>
        <a href="https://www.zweispurig.at/" target="_blank" class="info">zweispurig.at</a>
    </div>
    <div id="content">
        <div id="txt-quality">Datenqualität</div>
        <button id="check">Prüfen</button>
        <div id="data">97%</div>
    </div>
</div> -->