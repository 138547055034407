import { StoragePlace } from "./StoragePlace";

export class StorageLocation {
  constructor(
    public storage_location_id: number,
    public location_id: number,
    public location_name: string,
    public storage_places: StoragePlace[],
  ){}
}

export class StorageLocationPlace {
  constructor(
    public storage_location_place_id: number,
    public storage_location_place_name: string,
  ){}
}
