import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampFormat'
})
export class TimestampFormatPipe implements PipeTransform {

  transform(value: number): string {
    const datePipe = new DatePipe('de-DE');
    const date = new Date(value);
    if(isNaN(date.getTime())){
      return '---';
    } else {
      const formattedDate = datePipe.transform(value, 'dd.MM.yyyy');
      return formattedDate || '---';
    }
  }
}
