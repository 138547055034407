export class PermissionGroup {
    
    constructor(
        public permission_group_id: number,
        public location_id: number,
        public name: string,
        public is_assignable: boolean
    ){}
}

export class PermissionGroupFactory {

    static jsonFactory(rawBody: any): PermissionGroup[] {
      const data: PermissionGroup[] = [];
      for(let rawPart of rawBody){
        data.push(this.jsonFactoryOne(rawPart));
      }
      return data;
    }
  
    static jsonFactoryOne(raw: any): PermissionGroup {
        return new PermissionGroup(
            raw.permission_group_id,
            raw.location_id,
            raw.name,
            raw.is_assignable,
        );
    }
  }
  
