import { formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';

@Component({
  selector: 'app-identification-container',
  templateUrl: './identification-container.component.html',
  styleUrls: ['./identification-container.component.css'],
  animations: [DropdownIconArrowAnimation]
})
export class IdentificationContainerComponent implements OnInit {
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public showDropdownThree = false;
  public showDropdownFour = false;
  public maxDate;
  public paperStatuse: string[] = [];
  @Input() newVehicle: Vehicle;
  @Input() formGroup: UntypedFormGroup;
  @Input() submitted: boolean;

  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  @ViewChild('dropdownTwo') dropdownTwo: ElementRef;
  @ViewChild('dropdownFour') dropdownFour: ElementRef;
  public listener: () => void;

  constructor(
    private renderer: Renderer2,
    private vService: VehicleService,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    let today = new Date();
    this.maxDate = formatDate(today,'yyyy-MM-dd','de');
    this.paperStatuse = this.vService.getPaperStatuse();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.f.idNummer.setValue(this.newVehicle.externalId);
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window', 'click', (e: Event) => {
      if(this.showDropdownOne && !this.dropdownOne.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.dropdownTwo.nativeElement.contains(e.target)) this.showDropdownTwo = false;
      if(this.showDropdownFour && !this.dropdownFour.nativeElement.contains(e.target)) this.showDropdownFour = false;
    })
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  get f() {
    return this.formGroup.controls;
  }

  closeDatePicker(eventData: any, dp?:any) {
    this.f.lastService.setValue(eventData);
    dp.close();
  }
  closeDatePicker2(eventData: any, dp?:any) {
    this.f.lastBeltService.setValue(eventData);
    dp.close();
  }
  closeDatePicker3(eventData: any, dp?:any) {
    this.f.nextInspection.setValue(eventData);
    dp.close();
  }
}
