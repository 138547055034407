<div id="dialog_window">
  <div id="dialog_container" appDropdown>
      <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
      <h1>Import-Bericht</h1>
      <div id="dialog_conainter_body" class="body">
        <label>{{vehicleBCAErrors.length-(totalNumber==0?1:0)}} von {{totalNumber}} Fahrzeugen wurden importiert</label>
        <div>
          <h3>Fehlermeldungen</h3>
          <div id="vehicle-error-list">
            <div class="vehicle-error-list-element" *ngFor="let error of vehicleBCAErrors">
              <label *ngIf="error.vin">VIN {{error.vin}}</label>
              <label>{{error.error_message}}</label>
              <button *ngIf="error.vin" class="btn_label_blue" (click)="copyVIN(error)">{{error.copy_text}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog_btn_container">
          <button class="btn_submit_dark" (click)="selectionCloseEmitter.emit()">Schließen</button>
      </div>
  </div>
</div>
