<form [formGroup]="form" [attr.id]="'create-position_'+index" class="position-element">
  <label>POS {{index+1}}</label>
  <textarea
   appTextfieldAutoresize
   type="text"
   placeholder="---"
   formControlName="title"
   [class.input_invalid]="submitted && form.get('title').invalid"
  ></textarea>
  <input type="number" min="1" step="1"  placeholder="---" formControlName="quantity" [class.input_invalid]="submitted && form.get('quantity').invalid">
  <input type="number" step="0.01" placeholder="Nettopreis" formControlName="price" [class.input_invalid]="submitted && form.get('price').invalid">
  <app-dropdown-standard
    [formControl]="form.get('tax_rate')"
    [selectedValue]="selectedTax"
    [values]="taxes"
    [suffix]="'%'"
    (submitValueEmitter)="selectionTax($event)"
  ></app-dropdown-standard>
  <button *ngIf="index > 0" class="btn_lbl_action" (click)="removeElementEmitter.emit(index)"><span>&#8722;</span></button>
</form>
