import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Rent } from 'src/app/buisness-object/rent/Rent';
import { LoginService } from '../login/login.service';
import { DialogService } from '../dialog/dialog.service';
import { environment } from 'src/environments/environment';
import { RentFactory } from 'src/app/buisness-object/rent/factory/RentFactory';
import { error } from 'console';

@Injectable({
  providedIn: 'root'
})
export class RentService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) {}


  public getRents(): Observable<Rent[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
     const observable = this.http.get(environment.api + '/back/rent_order', { headers });
     return observable.pipe(
       map((data: any) => {
         const objects = RentFactory.jsonFactory(data.rent_orders);
         return objects;
       }),catchError(error => {
         if(error.status == 403){
           this.lService.logout();
         } else {
           console.log(error);
           this.dService.showNotification({
             title: 'Fehler',
             message: 'Fehlermeldung: ' + error.error.error_message,
             success: false
           });
         }
         return [];
       })
     );
  }


  public createRent(rent: Rent): Observable<Rent> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = rent.getJSON();
    const observable = this.http.post(environment.api + '/back/rent_order', body, { headers });
    return observable.pipe(
      map((data: any) => {
        const rent = RentFactory.jsonFactoryOne(data.rent_order);
        return rent;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }


  updateRent(rent: Rent): Observable<Rent> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = rent.getJSON();
    const observable = this.http.put(environment.api + '/back/rent_order', body, { headers });
    return observable.pipe(
      map((data: any) => {
        const rent = RentFactory.jsonFactoryOne(data.rent_order);
        return rent;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public deleteRent(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.delete(environment.api + '/back/rent_order/' + id, { headers });
    return observable.pipe(
      map((data: any) => {
        return data;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  getRentPdf(id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable =  this.http.get(environment.api + '/back/rent_order/pdf/'+ id, { headers, responseType: 'arraybuffer' });
    return observable.pipe(
      map((data: any) => {
        return data;
      }),
      catchError(error => {
        console.log(error)
        return of([]);
      })
    );
  }

  public updateRentCustomerWeb(token: string, json: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });
     const observable = this.http.put(environment.api + '/web/rent_order/customer', json, { headers });
     return observable.pipe(
       map((data: any) => {
         return data;
       }),catchError(error => {
         if(error.status == 403){
          return of(false);
         } else {
           console.log(error);
           this.dService.showNotification({
             title: 'Fehler',
             message: 'Fehlermeldung: ' + error.error.error_message,
             success: false
           });
         }
         return of(false);
       })
     );
  }

  public sendRentOrderContract(rent_id: number): Observable<Rent[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.post(environment.api + '/back/rent_order/mail/contract/'+ rent_id, {}, { headers });
    return observable.pipe(
      map((data: any) => {
        return data;
      }), catchError(error => {
        if(error.status == 403){
          return of(false);
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }

  public sendRentOrderMail(rent_id: number): Observable<Rent[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.post(environment.api + '/back/rent_order/mail/'+ rent_id, {}, { headers });
    return observable.pipe(
      map((data: any) => {
        return data;
      }), catchError(error => {
        if(error.status == 403){
          return of(false);
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }

  public updateRentOrderWeb(token: string, json: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });
     const observable = this.http.put(environment.api + '/web/rent_order', json, { headers });
     return observable.pipe(
       map((data: any) => {
         return data;
       }),catchError(error => {
         if(error.status == 403){
          return of(false);
         } else {
           console.log(error);
           this.dService.showNotification({
             title: 'Fehler',
             message: 'Fehlermeldung: ' + error.error.error_message,
             success: false
           });
         }
         return of(false);
       })
     );
  }

  public sendEmail(json: any, rent_id: number): Observable<Rent[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.post(environment.api + '/back/rent_order/mail/safe/'+ rent_id, json, { headers });
    return observable.pipe(
      map((data: any) => {
        return data;
      }), catchError(error => {
        if(error.status == 403){
          return of(false);
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }
}
   