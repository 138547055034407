<div id="dialog_window">
    <div id="dialog_container">
      <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
        <h1>Miete stornieren</h1>
        <div id="dialog_conainter_body" class="body">
          Sind sie sicher, dass Sie den Vertrag stornieren möchten?
        </div>

        <div class="dialog_btn_container">
          <button class="btn_label" (click)="selectionCloseEmitter.emit()">Abbrechen</button>
          <button class="btn_submit_dark" (click)="selectionCancelEmitter.emit()">Stornieren</button>
        </div>
    </div>
</div>
