import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { VehicleBodyColor } from 'src/app/buisness-object/vehicle/basic/VehicleBodyColor';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { FilterVehicle } from 'src/app/helpers/FilterVehicle';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { Subscription } from 'rxjs';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { validateDateInput, validateNumberOnlyInteger } from 'src/app/validators/CustomValidators';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() showFilter: boolean;
  @Input() form: FormGroup;
  @Input() vehicles: Vehicle[];
  @Input() sortObject: any;
  @Output() filterDetailEmitter = new EventEmitter<undefined>();
  @Output() resetFilterEmitter = new EventEmitter<undefined>();
  @Output() sortActionEmitter = new EventEmitter<undefined>();
  public brands: VehicleBrand[] = [];
  public bodyColors: VehicleBodyColor[] = [];
  public models: VehicleModel[] = [];
  public brandsFiltered: VehicleBrand[] = [];
  public modelsFiltered: VehicleModel[] = [];
  public colorsFiltered: VehicleBodyColor[] = [];
  public submitted = false;
  public maxDate_1 = new Date();
  public maxDate_2 = new Date();
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public showDropdownThree = false;
  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  @ViewChild('dropdownTwo') dropdownTwo: ElementRef;
  @ViewChild('dropdownThree') dropdownThree: ElementRef;
  public listenerOnClick: () => void;
  public formSub: Subscription;
  public tempForm: FormGroup;

  constructor(
    private renderer: Renderer2,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getFilterValues();
    if(this.form.controls.brand.value){
      this.models = this.form.controls.brand.value.models;
      this.modelsFiltered = this.form.controls.brand.value.models;
    }
    if(this.form.controls.firstregistration_date_to.value){
      this.maxDate_1 = new Date(this.form.controls.firstregistration_date_to.value.valueOf());
    }
    this.copyForm();
  }

  ngAfterViewInit(): void {
    this.listenerOnClick = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropdownOne && !this.dropdownOne.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.dropdownTwo.nativeElement.contains(e.target)) this.showDropdownTwo = false;
      if(this.showDropdownThree && !this.dropdownThree.nativeElement.contains(e.target)) this.showDropdownThree = false;
    })
    this.formSub = this.form.get('firstregistration_date_to').valueChanges.subscribe(value => {
      let date = new Date(value?.valueOf);
      if(date){
        this.maxDate_1 = date;
      } else {
        this.maxDate_1 = new Date();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['showFilter']){
      this.copyForm();
    }
  }

  ngOnDestroy(): void {
    if(this.listenerOnClick) this.listenerOnClick();
  }

  copyForm() {
    this.tempForm = this.formBuilder.group({
      brand: [this.form.controls['brand'].value],
      model: [this.form.controls['model'].value],
      color: [this.form.controls['color'].value],
      mileage_from: [this.form.controls['mileage_from'].value, validateNumberOnlyInteger],
      mileage_to: [this.form.controls['mileage_to'].value, validateNumberOnlyInteger],
      firstregistration_date_from: [this.form.controls['firstregistration_date_from'].value, validateDateInput],
      firstregistration_date_to: [this.form.controls['firstregistration_date_to'].value, validateDateInput],
      percent_typ: [this.form.controls['percent_typ'].value],
      is_set: [this.form.controls['is_set'].value],
    })
  }

  getFilterValues(){
    for(let vehicle of this.vehicles){
      let i = this.brands.findIndex(brand => brand.id == vehicle.brand.id);
      if(i < 0) this.brands.push(vehicle.brand);
      else {
        let iModel = this.brands[i].models.findIndex(model => model.id == vehicle.model.id);
        if(iModel < 0) this.brands[i].models.push(vehicle.model);
      }
      let j = this.bodyColors.findIndex(color => color.id == vehicle.bodyColor.id);
      if(j < 0) this.bodyColors.push(vehicle.bodyColor);
    }
    for(let brand of this.brands){
      brand.models.sort((a,b) => a.name.localeCompare(b.name))
    }
    this.brandsFiltered = this.brands;
    this.colorsFiltered = this.bodyColors;
  }

  filterBrands(value: string) {
    if(value && value.length > 0){
      this.form.controls.brand.setValue(null);
      this.brandsFiltered = [];
      this.brandsFiltered = this.brands.filter((brand) => {
        return brand.makeName.toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.brandsFiltered = this.brands;
    }
  }

  setBrand(brand: VehicleBrand) {
    this.tempForm.controls.brand.setValue(brand);
    this.tempForm.controls.model.setValue(null)
    this.models = brand.models;
    this.modelsFiltered = brand.models;
  }

  filterModels(value: string) {
    if(value && value.length > 0){
      this.form.controls.model.setValue(null);
      this.modelsFiltered = [];
      this.modelsFiltered = this.models.filter((model) => {
        return model.name.toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.modelsFiltered = this.models;
    }
  }

  filterColors(value: string) {
    if(value && value.length > 0){
      this.tempForm.controls.color.setValue(null);
      this.colorsFiltered = [];
      this.colorsFiltered = this.bodyColors.filter((color) => {
        return color.name.toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.colorsFiltered = this.bodyColors;
    }
  }

  closeDatePickerOne(eventData: any, dp?:any) {
    this.tempForm.controls.firstregistration_date_from.setValue(eventData);
    dp.close();
  }
  closeDatePickerTwo(eventData: any, dp?:any) {
    this.tempForm.controls.firstregistration_date_to.setValue(eventData);
    this.maxDate_1 = new Date(this.tempForm.controls.firstregistration_date_to.value.valueOf());
    dp.close();
  }

  get filteringSet() {
    if(this.form.controls.brand?.value ||
      this.form.controls.model?.value ||
      this.form.controls.color?.value ||
      this.form.controls.mileage_from?.value ||
      this.form.controls.mileage_to?.value ||
      this.form.controls.firstregistration_date_from?.value ||
      this.form.controls.firstregistration_date_to?.value ||
      this.form.controls.percent_typ?.value)
      return true;
    else false;
  }

  setFormValues() {
    this.form.controls['brand'].setValue(this.tempForm.controls['brand'].value);
    this.form.controls['model'].setValue(this.tempForm.controls['model'].value);
    this.form.controls['color'].setValue(this.tempForm.controls['color'].value);
    this.form.controls['mileage_from'].setValue(this.tempForm.controls['mileage_from'].value);
    this.form.controls['mileage_to'].setValue(this.tempForm.controls['mileage_to'].value);
    this.form.controls['firstregistration_date_from'].setValue(this.tempForm.controls['firstregistration_date_from'].value);
    this.form.controls['firstregistration_date_to'].setValue(this.tempForm.controls['firstregistration_date_to'].value);
    this.form.controls['percent_typ'].setValue(this.tempForm.controls['percent_typ'].value);
  }

  submitFilter() {
    this.setFormValues();
    this.filterDetailEmitter.emit();
  }
  resetFilter() {
    this.resetFilterEmitter.emit();
  }
}
