import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Message } from 'src/app/buisness-object/message/Message';
import { Thread } from 'src/app/buisness-object/message/Thread';

@Component({
  selector: 'app-messages-detail',
  templateUrl: './messages-detail.component.html',
  styleUrls: ['./messages-detail.component.css']
})
export class MessagesDetailComponent implements OnInit, AfterViewChecked {
  public messageTxt = '';
  public firstOpened = true;
  @Input() thread: Thread;
  @Input() employees: Employee[];
  @Input() userId: number;
  @Output() resolveEmitter = new EventEmitter<Thread>();
  @Output() sendEmitter = new EventEmitter<string>();

  @ViewChild('textArea') textArea: ElementRef;

  // @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
  //   this.submit();
  // }

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    if(!this.firstOpened){
      this.scrollToMessage(this.thread.messages[this.thread.messages.length - 1].messageId.toString());
    }
  }

  scrollToMessage(divMsgId: string) {
    const elementLIst = document.querySelectorAll('#msg_' + divMsgId);
    const element = elementLIst[0] as HTMLElement;
    if(element){
      element.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }

  mergeData() {
    for(let msg of this.thread.messages){
      for(let employee of this.employees){
        if(msg.employeeId == employee.id){
          msg.employee = employee;
        }
      }
    }
  }

  getTyp(typ: number): string {
    switch (typ) {
      case 1: return 'Intern'; break;
      case 2: return 'Kunde'; break;
      case 3: return 'Fahrzeug'; break;
      case 4: return 'Document'; break;
      case 5: return 'Probefahrt'; break;
      default: return '-'; break;
    }
  }

  getUrgency(urgency: number): string {
    switch (urgency) {
      case 0: return 'später erledigen'; break;
      case 1: return 'erledigen'; break;
      case 2: return 'sofort erledigen'; break;
      default: '---'; break;
    }
  }

  resolve(thread: Thread) {
    this.resolveEmitter.emit(thread);
  }

  getPosition(e, value: string) {
    this.messageTxt = value;
    let start = e.target.selectionStart;
    let end = e.target.selectionEnd;
  }

  getMessage(msg: Message): string {
    return msg.getMessageText(this.employees);
  }

  submit(msg){
    this.sendEmitter.emit(msg);
    this.firstOpened = false;
    // if(this.messageTxt.length > 0){
    //   this.firstOpened = false;
    //   this.sendEmitter.emit(this.messageTxt);
    //   // let textinput = document.getElementById('textinput');
    //   // if(textinput) textinput.innerHTML = '';
    //   // this.messageTxt = "";
    // }
  }

  inputAction(txt: string) {
    this.messageTxt = txt;
  }
}
