<div class="page_body">
    <div class="list_title">
      <label>Position</label>
      <label>Preis/Stück & Rabatte</label>
    </div>
    <div id="price_list" *ngIf="isLoaded">
      <app-create-offer-page-two-item-element *ngFor="let item of form.controls.offer_items.value; let i = index"
        [isEdit]="form.controls['offer_id'].value != 0"
        [form]="item"
        [index]="i"
        [vehicles]="vehicles"
        [amount]="form.controls.offer_items.value.length"
        [submitted]="submittedItem"
        (removeEmitter)="removeEmitter.emit($event)"
      ></app-create-offer-page-two-item-element>
      <div class="btn_action"(click)="addPrice()">&#43;</div>
    </div>
</div>
