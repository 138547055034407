import { SelektiertesZusatzprodukt } from "../SelektiertesZusatzprodukt";

export class SelektiertesZusatzproduktFactory {
    static jsonFactory(rawZusatzprodukteObject: any): SelektiertesZusatzprodukt[] {
        let zusatzprodukte: SelektiertesZusatzprodukt[] = [];
        let rawZusatzprodukte = rawZusatzprodukteObject.services;
        if(rawZusatzprodukte != null){
          for(let zusatzprodukt of rawZusatzprodukte){
            zusatzprodukte.push(this.jsonFactoryOne(zusatzprodukt));
        }
        }
        return zusatzprodukte;
    }

    static jsonFactoryOne(rawZusatzprodukt: any): SelektiertesZusatzprodukt {
        let selektiertesZusatzprodukt = new SelektiertesZusatzprodukt(
            rawZusatzprodukt.service_id,
            rawZusatzprodukt.category,
            rawZusatzprodukt.description ? (rawZusatzprodukt.category + " - " + rawZusatzprodukt.description) : rawZusatzprodukt.category,
            rawZusatzprodukt.editable,
            rawZusatzprodukt.price
        );
        selektiertesZusatzprodukt.preis = rawZusatzprodukt.price;
        selektiertesZusatzprodukt.preis_selektiert = rawZusatzprodukt.custom_price ? rawZusatzprodukt.custom_price : rawZusatzprodukt.price;
        selektiertesZusatzprodukt.name_selektiert = rawZusatzprodukt.custom_description;
        return selektiertesZusatzprodukt;
    }
}
