export enum SortTyp {
  PREIS = 1,
  KM = 2,
  ID = 3,
  MARKE = 4,
  MODELL = 5,
  DATEN = 6,
  FARBE = 7,
  EZ = 8,
  BEARBEITET = 9,
  TREIBSTOFF = 10,
  ANTRIEB = 11,
  PS = 12,
  KAUFDATUM = 13,
  VERKAUFSDATUM = 14,
  EINKAUFSPREIS = 15,
  VERKAUFSPREIS = 16,
  STANDZEIT = 17
}
