<div [attr.id]="'submenu-column-action-container_'+columnId" class="submenu-column-action-container" [class.list-action-color]="isListView && (index%2==0)">
  <img class="submenu-column-action-btn" [class.submenu-column-action-btn-open]="showActions" src="./assets/navdots.svg" (click)="selectionActions()">
  <div *ngIf="showActions"
    [attr.id]="'submenu-column-action-box_'+columnId"
    class="submenu-column-action-box"
    appDropdown [show]="showActions"
    (changedValueEmitter)="closeActions()">
    <app-btn-action *ngFor="let action of actions" style="width: 100%;"
      [action]="action"
      [object]="object"
      (selectionOpenDetailsEmitter)="selectionOpenDetailsEmitter.emit($event)"
      (selectionOpenDetailsEditEmitter)="selectionOpenDetailsEditEmitter.emit($event)"
      (selectionDeleteEmitter)="selectionDeleteEmitter.emit($event)"
      (selectionMoveEmitter)="selectionMoveEmitter.emit($event)"
      (selectionPrintEmitter)="selectionPrintEmitter.emit($event)"
      (selectionEtikettEmitter)="selectionEtikettEmitter.emit($event)"
      (selectionSellEmitter)="selectionSellEmitter.emit($event)"
      (selectionDokumentEmitter)="selectionDokumentEmitter.emit($event)"
      (selectionCopyEmitter)="selectionCopyEmitter.emit($event)"
      (selectionUploadDocumentEmitter)="selectionUploadDocumentEmitter.emit($event)"
      (selectionPseudoSignatureEmitter)="selectionPseudoSignatureEmitter.emit($event)"
      (selectionCreateKaufvertragEmitter)="selectionCreateKaufvertragEmitter.emit($event)"
      (selectionArchivEmitter)="selectionArchivEmitter.emit($event)"
      (selectionDownloadEmitter)="selectionDownloadEmitter.emit($event)"
      (selectionPDFCreateEmitter)="selectionPDFCreateEmitter.emit($event)"
      (selectionPDFOpenEmitter)="selectionPDFOpenEmitter.emit($event)"
      (selectionTestDrivePDFSignEmitter)="selectionTestDrivePDFSignEmitter.emit($event)"
      (selectionContractPDFUnsigendSignedEmitter)="selectionContractPDFUnsigendSignedEmitter.emit($event)"
      (selectionRestoreEmitter)="selectionRestoreEmitter.emit($event)"
      (selectionChangeStatusEmitter)="selectionChangeStatusEmitter.emit($event)"
      (selectionChangePaymentStatusEmitter)="selectionChangePaymentStatusEmitter.emit($event)"
      (selectionAppSignatureEmitter)="selectionAppSignatureEmitter.emit($event)"
      (receiveFundingEmitter)="receiveFundingEmitter.emit($event)"
      (createCreditEmitter)="createCreditEmitter.emit($event)"
      (changeFinanzsperrauskunftEmitter)="changeFinanzsperrauskunftEmitter.emit($event)"
      (changeFinanzsperrauskunftStatusEmitter)="changeFinanzsperrauskunftStatusEmitter.emit($event)"
      (changeAustrianDataExtractEmitter)="changeAustrianDataExtractEmitter.emit($event)"
    ></app-btn-action>
  </div>
</div>
