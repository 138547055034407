<div [ngClass]="{'dropdown_component_wrapper' : title!=null && showLabel==true }" *ngIf="formcontrol">
  <div *ngIf="title && showLabel" class="overview_record_lbl" [ngClass]="{ overivew_recorrd_bold : (authService.isStandard() && title == 'Kunde') }">{{title}}</div>
  <div class="dropdown_button">
      <div class="dropdown_button_header" [class.dropdown_disabled]="disabled"  [class.dropdown_button_invalid]="submitted && formcontrol.invalid" (click)="show = !show" appDropdown [show]="show" (changedValueEmitter)="show = $event">
        <input [attr.id]="inputId" #input class="dropdown_button_header_input" [placeholder]="placeholder" (input)="searchValues(input.value); setInputValue(input.value)"/>
        <img class="dropdown_button_header_img" [ngClass]="show ? 'dropdown_button_header_img_active' : ''" src="../../../../assets/arrow-dark.svg">
     </div>
      <div #dropdownBox class="dropdown_button_box" *ngIf="show">
          <label *ngFor="let value of valuesFiltered; let i = index" (click)="submit(value); show = false">{{value.name}}</label>
      </div>
  </div>
</div>
