import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelektiertesZusatzprodukt } from 'src/app/buisness-object/zusatzprodukt/SelektiertesZusatzprodukt';
import { selektierteZusatzproduktForm } from 'src/app/buisness-object/zusatzprodukt/SelektiertesZusatzproduktForm';
import { Zusatzprodukt } from 'src/app/buisness-object/zusatzprodukt/Zusatzprodukt';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-zusatzprodukte',
  templateUrl: './zusatzprodukte.component.html',
  styleUrl: './zusatzprodukte.component.css'
})
export class ZusatzprodukteComponent extends BaseComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() zusatzprodukte: Zusatzprodukt[];
  @Input() submitted: boolean;
  public show = -1;
  constructor(
    public authService: AuthenticationService,
    private eService: EnumService,
    public formBuilder: FormBuilder
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
  }

  addAdditionalService() {
    this.form.controls["selektierte_zusatzprodukte"].value.push(
      selektierteZusatzproduktForm.getSelektiertesZusatproduktForm(this.formBuilder, new SelektiertesZusatzprodukt(
        0, 
        null,
        null,
        null,
        null,
      ))
    );
  }

  submit(indexZusatzprodukt, indexSelektiertesZusatzprodukt) {
    let zusatzprodukt = this.form.controls["verfuegbare_zusatzprodukte"].value[indexSelektiertesZusatzprodukt].controls;
    let selektiertesZusatzprodukt = this.form.controls["selektierte_zusatzprodukte"].value[indexZusatzprodukt];
    let previousId = selektiertesZusatzprodukt.controls["zusatzprodukt_id"].value;
    selektiertesZusatzprodukt.controls["zusatzprodukt_id"].setValue(zusatzprodukt["zusatzprodukt_id"].value);
    selektiertesZusatzprodukt.controls["name"].setValue(zusatzprodukt.name.value);
    selektiertesZusatzprodukt.controls["name_editierbar"].setValue(zusatzprodukt.name_editierbar.value);
    selektiertesZusatzprodukt.controls["preis_selektiert"].setValue(zusatzprodukt.preis.value);
    this.form.controls["verfuegbare_zusatzprodukte"].value.splice(indexSelektiertesZusatzprodukt, 1);
    if(previousId != 0) {
      let zusatzprodukt = this.zusatzprodukte.find(zusatzprodukt => zusatzprodukt.zusatzprodukt_id == previousId);
      let value = this.form.controls["verfuegbare_zusatzprodukte"].value;
      value.push(selektierteZusatzproduktForm.getZusatproduktForm(this.formBuilder, zusatzprodukt!));
      value = value.sort((v1: FormGroup, v2: FormGroup) => v1.controls["name"].value > v2.controls["name"].value ? 1 : -1)
      this.form.controls["verfuegbare_zusatzprodukte"].setValue(value);
    }
    this.show = -1;
  }

  removeAdditionalService(selektierte_zusatzprodukt: FormGroup) {
    let id = selektierte_zusatzprodukt.controls["zusatzprodukt_id"].value;
    let selektierte_zusatzprodukte: FormGroup[] = this.form.controls["selektierte_zusatzprodukte"].value;
    let index = selektierte_zusatzprodukte.indexOf(selektierte_zusatzprodukt);
    selektierte_zusatzprodukte.splice(index, 1);
    if(id != 0) {
      let zusatzprodukt = this.zusatzprodukte.find(zusatzprodukt => zusatzprodukt.zusatzprodukt_id == id);
      let value = this.form.controls["verfuegbare_zusatzprodukte"].value;
      value.push(selektierteZusatzproduktForm.getZusatproduktForm(this.formBuilder, zusatzprodukt!));
      value = value.sort((v1: FormGroup, v2: FormGroup) => v1.controls["name"].value > v2.controls["name"].value ? 1 : -1)
      this.form.controls["verfuegbare_zusatzprodukte"].setValue(value);
    }
    this.form.controls["selektierte_zusatzprodukte"].setValue(selektierte_zusatzprodukte);
  }
}
