import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { OpenCloseDropDown } from 'src/app/helpers/Animations';

@Component({
  selector: 'app-dropdown-date-container',
  templateUrl: './dropdown-date-container.component.html',
  styleUrls: ['./dropdown-date-container.component.css'],
  animations: [OpenCloseDropDown]
})
export class DropdownDateContainerComponent implements OnInit, AfterViewInit {
  public showDropdown = false;
  public valueSelected = false;
  public title = '';
  public placeholderTemp = '';
  public days: number[] = [];
  public months = [1,2,3,4,5,6,7,8,9,10,11,12];
  public years: number[] = [];
  public selectedYear: number = 0;
  public selectedMonth: number = 0;
  public selectedDay: number = 0;
  public inView = false;
  public dateSetted = false;
  public scrollYearTemp = 0;
  @Input() placeholder: string;
  @Input() placeholderItalic: boolean;
  @Input() textTransform: string;
  @Input() options: string[];
  @Input() borderBottomColor: string;
  @Input() dropOptionHover: string;
  @Input() invalid: boolean;
  @Input() value: string;
  @Input() isBirthdate: boolean;
  @Output() selectEmitter = new EventEmitter<number>()
  @ViewChild('inputContainer') inputContainer: ElementRef;
  @ViewChild('dropInput') dropInput: ElementRef;
  @ViewChild('dropContainer') dayContainer: ElementRef;
  @ViewChild('titleYear') titleYear: ElementRef;
  @ViewChild('titleMonth') titleMonth: ElementRef;
  @ViewChild('titleDay') titleDay: ElementRef;
  @ViewChild('scrollYears') scrollYears: ElementRef;
  @ViewChild('scrollMonths') scrollMonths: ElementRef;
  @ViewChild('scrollDays') scrollDays: ElementRef;
  public listenerFn: () => void;
  public listenerScroll: () => void;

  public scrollViewBox: HTMLElement;
  public element: HTMLElement;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    this.placeholderTemp = this.placeholder;
    this.title = this.placeholderTemp;
    if(this.value){
      this.dateSetted = true;
      this.valueSelected = true;
      let date = new Date(this.value);
      if(this.isBirthdate){
        this.placeholder = date.getDate() + ' / ' + (date.getMonth() + 1) + ' / ' + date.getFullYear();
      } else {
        this.placeholder = (date.getMonth() + 1) + ' / ' + date.getFullYear();
      }
    }
    this.setYearsArray();
  }

  ngOnDestroy() {
    if(this.listenerFn){
      this.listenerFn();
    }
    window.removeEventListener('scroll', this.scrollYears.nativeElement, true);
  }

  ngAfterViewInit() {
    //this.setCloseListener();
    //this.scrollIntoView(1993);
    //window.addEventListener('scroll', this.scrollingYears, true);
  }

  scrollIntoView(year: number) {
    this.scrollViewBox = document.getElementById('scroll_box_year');
    this.element = document.getElementById('year' + 1993);

    let height =


    window.addEventListener('scroll', this.isInViewport, true);



    // const elementList = document.querySelectorAll('#year'+ year);
    // const element = elementList[0] as HTMLElement;
    // if(element){
    //   element.scrollIntoView({behavior: 'smooth', block: 'center'});
    // }
  }

  isInViewport = (e:Event): void =>  {
    if(this.element){
      let position = this.element.getBoundingClientRect();
      let box = this.scrollViewBox.getBoundingClientRect();
      let boxHeight = this.scrollViewBox.clientHeight;
      let boxTop = this.scrollViewBox.scrollTop;
      let boxBottom = boxTop + boxHeight;

      let elTop = this.element.offsetTop - this.scrollViewBox.offsetTop;
      let elBottom = elTop + this.element.clientHeight;


      if(elTop >= 0 && elBottom <= boxHeight){
      }
      // if(position.top >= this.scrollViewBox.offsetTop &&
      //   position.bottom <= box.bottom){
      //   }
    }


    // for(let year of this.years){
    //   if(year != this.scrollYearTemp){
    //     this.scrollYearTemp = year;
    //     let element = document.querySelectorAll('#year'+ year);
    //     let el = element[0] as HTMLElement;
    //     // if(el && el.offsetParent !== null){
    //     //   el.scrollIntoView({behavior: 'smooth', block: 'center'});

    //     //   break;
    //     // }
    //   }
    // }
  }

  //https://www.javascripttutorial.net/dom/css/check-if-an-element-is-visible-in-the-viewport/

  setScrollListener(){

    //window.scrollBy(0,-5);
    // let observer = new IntersectionObserver(function(entries) {
    //   let doc = document.getElementById(entries[0].target.id);
    //   if(entries[0].isIntersecting){
    //     doc.scrollIntoView({behavior: 'smooth'});
    //     doc.style.fontSize = '22px';
    //   } else {
    //     // doc.srol({behavior: 'smooth', block: 'center'});
    //     //doc.style.fontSize = '16px';
    //   }
    // }, { threshold: [1] });
    // for(let year of this.years){
    //   observer.observe(document.querySelector('#year'+ year));
    // }
  }

  setCloseListener() {
    this.listenerFn = this.renderer.listen('window','click', (e:Event) => {
      if(this.showDropdown){
        if(e.target != this.dayContainer.nativeElement && e.target != this.titleYear.nativeElement && e.target != this.titleMonth.nativeElement &&
            e.target != this.titleDay.nativeElement && e.target != this.scrollYears.nativeElement && e.target != this.scrollMonths.nativeElement &&
            e.target != this.scrollDays.nativeElement && e.target != this.inputContainer.nativeElement && e.target != this.dropInput.nativeElement){
            for(let child of this.scrollYears.nativeElement.children){
              if(e.target != child){
                this.showDropdown = false;
                break;
              }
            }
            for(let child of this.scrollMonths.nativeElement.children){
              if(e.target != child){
                this.showDropdown = false;
                break;
              }
            }
            for(let child of this.scrollDays.nativeElement.children){
              if(e.target != child){
                this.showDropdown = false;
                break;
              }
            }
            this.showDropdown = false;
        }
      }
    });
  }

  ifNoValueChosen(): boolean {
    if(this.placeholder == this.placeholderTemp || this.placeholder == '0 / 0 / 0' || this.placeholder == '0 / 0'){
      return true;
    }
    return false;
  }

  openDropdown() {
    this.showDropdown = !this.showDropdown;
    if(!this.dateSetted){
      this.placeholder = this.isBirthdate ? '0 / 0 / 0' : '0 / 0';
    }
  }

  setYearsArray(){
    let currentYear = new Date().getFullYear();
    for(let i = currentYear; i >= 1920; i--){
      this.years.push(i);
    }
    let dayOfMonth = new Date(this.selectedYear, 1, 0).getDate();
      this.days = [];
      for(let i = 1; i <= dayOfMonth; i++){
        this.days.push(i);
    }
  }

  setYear(year: number) {
    this.selectedYear = year;
    this.setDateTxt();
    this.setDaysArray();
    this.valueSelected = true;
  }

  setMonth(month: number) {
    this.selectedMonth = month;
    this.setDateTxt();
    this.valueSelected = true;
    if(this.isBirthdate){
      this.setDaysArray();
    } else {
      let date = new Date(this.selectedYear, this.selectedMonth, 1);
      this.selectEmitter.emit(date.getTime());
      this.showDropdown = false;
      this.dateSetted = true;
    }
  }

  setDaysArray() {
    if(this.selectedYear != 0 && this.selectedMonth != 0) {
      let dayOfMonth = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
      this.days = [];
      for(let i = 1; i <= dayOfMonth; i++){
        this.days.push(i);
      }
    }
  }

  setDay(day: number) {
    this.valueSelected = true;
    this.selectedDay = day;
    this.setDateTxt();
    let date = new Date(this.selectedYear, this.selectedMonth, this.selectedDay);
    this.selectEmitter.emit(date.getTime());
    this.showDropdown = false;
    this.dateSetted = true;
  }

  setDateTxt() {
    if(this.isBirthdate){
      this.placeholder = this.selectedDay + ' / '+ this.selectedMonth + ' / ' + this.selectedYear;
    } else {
      this.placeholder = this.selectedMonth + ' / ' + this.selectedYear;
    }
  }
}
