export class ContactPerson {
    constructor(
        public id: number,
        public firstName: string,
        public lastName: string,
        public email: string,
        public phone: string,
        public title?: string,
        public salutation?: number,
        public birthdate?: number,
        public is_pep?: boolean,
    ){}
}
