import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';

@Component({
  selector: 'app-attachments-container',
  templateUrl: './attachments-container.component.html',
  styleUrls: ['./attachments-container.component.css']
})
export class AttachmentsContainerComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() vehicle: Vehicle;
  @Input() showDelete: boolean = true;
  public onloadSrc: any[] = [];
  public uploadProgress = 0;
  public currentFile = 0;
  public filesAmount = 0;
  public sources: {
    name: string,
    format: string,
    data: string
  }[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  async uploadFile(event: any) {
    this.onloadSrc = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
        await this.readFile(event.target.files[i])
      }
      event.target.value = '';
    }
  }
  async readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.sources.push({
          name: file.name.split('.')[0],
          format: file.name.split('.')[1],
          data: event.target.result.split(',')[1]
        })
        this.form.controls.attachments.setValue(this.sources)
        resolve(true)
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  // async onFileDropped(data: any) {
  //   if(data){
  //     this.sources.push({
  //       name: data.name.split('.')[0],
  //       format: data.name.split('.')[1],
  //       data: data.data.split(',')[1]
  //     })
  //   }
  // }

  async onFileDropped(data: any) {
    if(data?.data && data?.data[0]){
      this.sources.push({
        name: data?.data[0].name.split('.')[0],
        format: data?.data[0].name.split('.')[1],
        data: data?.data[0].content.split(',')[1]
      })
    }
  }

  removeAttachement(index: number) {
    this.sources.splice(index, 1);
    this.form.controls.attachments.setValue(this.sources);
  }
}
