import { Component, Input, OnInit } from '@angular/core';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';

@Component({
  selector: 'app-insert',
  templateUrl: './insert.component.html',
  styleUrls: ['./insert.component.css']
})
export class InsertComponent implements OnInit {
  @Input() vehicle: Vehicle;

  constructor() { }

  ngOnInit(): void {
  }

}
