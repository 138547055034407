export class Setting {
  public calculated_value;
  constructor(
    public setting_default_value_id: number,
    public category: string,
    public category_name: string,
    public name: string,
    public label: string,
    public value: number,
    public typ: string,
  ){}
}
