import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interfaces',
  templateUrl: './interfaces.component.html',
  styleUrls: ['./interfaces.component.css']
})
export class InterfacesComponent implements OnInit {
  public interfaces: {
    customer_id: number,
    interface_id: number
    interface_name: string,
    status: number,
    url: string,
    data_qualtity: number,
    last_check: number,
    last_sync: number
  }[] = [
    {
      customer_id: 1,
      interface_id: 1,
      interface_name: 'Meine Website',
      status: 3,
      url: Number(localStorage.getItem("location_id")) == 13 ? 'auto-sinhuber.at' : 'auto-beispiel.at',
      data_qualtity: 100,
      last_check: new Date().getTime(),
      last_sync: new Date().getTime(),
    },
    {
      customer_id: 1,
      interface_id: 2,
      interface_name: 'Zweispurig',
      status: 3,
      url: 'zweispurig.at',
      data_qualtity: 100,
      last_check: new Date().getTime(),
      last_sync: new Date().getTime(),
    },
    {
      customer_id: 1,
      interface_id: 3,
      interface_name: 'AutoScout24',
      status: 3,
      url: 'autoscout24.at',
      data_qualtity: 100,
      last_check: new Date().getTime(),
      last_sync: new Date().getTime(),
    },
    {
      customer_id: 1,
      interface_id: 4,
      interface_name: 'Car4You',
      status: 3,
      url: 'car4you.at',
      data_qualtity: 100,
      last_check: new Date().getTime(),
      last_sync: new Date().getTime(),
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
