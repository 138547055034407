<form class="page_body" [formGroup]="form">
  <div class="input_wrapper" id="betreff">
    <label class="overview_record_lbl">Betreff *</label>
    <input type="text" placeholder="Betreff" formControlName="title" [class.input_invalid]="submitted && form.controls.title.errors">
  </div>
  <div class="column">
    <app-dropdown-objects-search
        [title]="'Kunde *'"
        [placeholder]="'Kunde'"
        [headervalue]="form.controls.customer?.value?.getName()"
        [formcontrol]="form.controls.customer"
        [values]="customerValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('customer', $event)"
    ></app-dropdown-objects-search>
    <div class="btn_submit_dark" *ngIf="!form.controls.customer.value" (click)="goToCustomerCreateAction()">Neuen Kunden anlegen</div>
    <div *ngIf="form.controls.customer.value" id="customer-overview">
      <app-dropdown-multiple
        [title]="'Status'"
        [formcontrol]="form.controls.customer"
        [headerText]="statusValues[form.controls.customer.value.status-1]"
        [values]="statusValues"
        (submitValueEmitter)="form.controls.customer.value.status = $event"
      ></app-dropdown-multiple>
      <div class="overview_record">
        <div class="overview_record_lbl">Typ</div>
        <div class="overview_record_value">{{form.controls.customer.value.typ == 1 ? 'Privat' : 'Firma'}}</div>
      </div>
      <div class="overview_record" *ngIf="form.controls.customer.value.typ == 2 && form.controls.customer.value.companyName">
        <div class="overview_record_lbl">Firmenname</div>
        <div class="overview_record_value">{{form.controls.customer.value.companyName}}</div>
      </div>
      <div class="overview_record" *ngIf="form.controls.customer.value.typ == 1">
        <div class="overview_record_lbl">Name</div>
        <div class="overview_record_value">{{form.controls.customer.value.contactPerson.title + ' ' +form.controls.customer.value.contactPerson.firstName + ' ' + form.controls.customer.value.contactPerson.lastName}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">E-Mail</div>
        <div class="overview_record_value">{{form.controls.customer.value.contactPerson.email}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">Straße, Nr</div>
        <div class="overview_record_value">{{form.controls.customer.value.address.street}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">PLZ</div>
        <div class="overview_record_value">{{form.controls.customer.value.address.postalCode}}</div>
      </div>
      <div class="overview_record" style="margin-bottom: 15px;">
        <div class="overview_record_lbl">Ort</div>
        <div class="overview_record_value">{{form.controls.customer.value.address.city}}</div>
      </div>
      <div class="btn_submit_dark"*ngIf="form.controls.customer.value" (click)="goToCustomerEditAction()">Bearbeiten</div>
    </div>
  </div>
  <div class="column">
    <div class="input_wrapper">
      <div class="overview_record_lbl">Angebotsgültigkeit *</div>
      <input #inputDate1 type="date" placeholder="date" [min]="minDate" formControlName="offer_validity" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls.offer_validity.errors">
    </div>
    <app-dropdown-simple
      [title]="'Brutto Preis anzeigen'"
      [formcontrol]="form.controls.show_brutto_price"
      (submitValueEmitter)="form.controls.show_brutto_price.setValue($event)"
    ></app-dropdown-simple>
  </div>
</form>
