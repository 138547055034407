import { VehicleBodyColor } from "../buisness-object/vehicle/basic/VehicleBodyColor";
import { VehicleBrand } from "../buisness-object/vehicle/basic/VehicleBrand";

export class FilterVehicle {
  public brand: VehicleBrand;
  public color: VehicleBodyColor;
  public mileage_from: number;
  public mileage_to: number;
  public registrationDate: any;
  public percent_value: number;
  constructor(){
    this.brand = null;
    this.color = null;
    this.mileage_from = null;
    this.mileage_to = null;
    this.registrationDate = null;
    this.percent_value = null;
  }

  reset() {
    this.brand = null;
    this.color = null;
    this.mileage_from = null;
    this.mileage_to = null;
    this.registrationDate = null;
    this.percent_value = null;
  }
}
