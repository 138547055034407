<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button class="button-icon-start" (click)="selectionCloseEmitter.emit()">
      <img class="button-icon-start-img" src="./assets/back.svg"/>
      <span class="button-icon-start-span">Zurück</span>
    </button>
    <h2>{{obj.getTypeAndNameAndVehicle()}}</h2>
  </div>

  <div class="header-button-wrapper">

      <button class="button_type_2" (click)="shwoActions = !shwoActions" appDropdown [show]="shwoActions" (changedValueEmitter)="shwoActions = $event">
        Aktionen
        <img src="./assets/dropdown-arrow-blue.svg">
        <div *ngIf="shwoActions">
          <!-- <button *ngIf="obj.rent_status != 2 && obj.rent_status != 3 && obj.rent_status != 4" (click)="selectionEditEmitter.emit(obj); shwoActions = false">Bearbeiten</button> -->
          <app-restricted-button
            *ngIf="obj.rent_status != 2 && obj.rent_status != 3 && obj.rent_status != 4"
            [name]="'Bearbeiten'"
            [access]="'rent_view'"
            [isSecondary]="true"
            [isDropdownButton]="true"
            (clickEmitter)="selectionEditEmitter.emit(obj); shwoActions = false">
          </app-restricted-button>
          <!-- SHOW IF AUTHORIZATION DATA AVAILABLE -->
          <!-- <button *ngIf="obj.main_driver?.authorization_number && obj.main_driver?.authorization_authority && obj.main_driver?.authorization_date && obj.main_driver?.authorization_groups"
              (click)="selectionSendDocument(obj.rent_id); 
              shwoActions = false"
          >Dokument erneut senden</button> -->

          <app-restricted-button
            *ngIf="obj.main_driver?.authorization_number && obj.main_driver?.authorization_authority && obj.main_driver?.authorization_date && obj.main_driver?.authorization_groups"
            [name]="'Dokument erneut senden'"
            [access]="'rent_view'"
            [isSecondary]="true"
            [isDropdownButton]="true"
            (clickEmitter)="selectionSendDocument(obj.rent_id); shwoActions = false">
          </app-restricted-button>

          <!-- <button *ngIf="obj.rent_status == 0 || obj.rent_status == 1" (click)="selectionSendMail(obj.rent_id); shwoActions = false">Übergabe senden</button> -->

          <app-restricted-button
            *ngIf="obj.rent_status == 0 || obj.rent_status == 1"
            [name]="'Übergabe senden'"
            [access]="'rent_view'"
            [isSecondary]="true"
            [isDropdownButton]="true"
            (clickEmitter)="selectionSendMail(obj.rent_id); shwoActions = false">
          </app-restricted-button>
 

          <!-- <button *ngIf="obj.rent_status == 2 || obj.rent_status == 3" (click)="selectionSendMail(obj.rent_id); shwoActions = false">Rücknahme senden</button> -->
          <app-restricted-button
            *ngIf="obj.rent_status == 2 || obj.rent_status == 3"
            [name]="'Rücknahme senden'"
            [access]="'rent_view'"
            [isSecondary]="true"
            [isDropdownButton]="true"
            (clickEmitter)="selectionSendMail(obj.rent_id); shwoActions = false">
          </app-restricted-button>

          <!-- <button *ngIf="false" (click)="selectionCancelRentEmitter.emit(); shwoActions = false">Miete stornieren</button> -->
          <!-- <button *ngIf="obj.rent_status == 0 || obj.rent_status == 1" (click)="selectionSendEmailEmitter.emit(); shwoActions = false">Tresordaten senden</button> -->

          <app-restricted-button
            *ngIf="obj.rent_status == 0 || obj.rent_status == 1"
            [name]="'Tresordaten senden'"
            [access]="'rent_view'"
            [isSecondary]="true"
            [isDropdownButton]="true"
            (clickEmitter)="selectionSendEmailEmitter.emit(); shwoActions = false">
          </app-restricted-button>
        </div>
      </button>

      <!-- <button class="btn_submit" *ngIf="obj.rent_status == 0 || obj.rent_status == 1" (click)="selectionReceiveEmitter.emit(obj)">Übergabe</button> -->
      <app-restricted-button
        *ngIf="obj.rent_status == 0 || obj.rent_status == 1"
        [name]="'Übergabe'"
        [access]="'rent_view'"
        [isPrimary]="true"
        (clickEmitter)="selectionReceiveEmitter.emit(obj)">
      </app-restricted-button>

      <!-- <button class="btn_submit" *ngIf="obj.rent_status == 2 || obj.rent_status == 3" (click)="selectionReturnEmitter.emit(obj)">Rücknahme</button> -->
      <app-restricted-button
        *ngIf="obj.rent_status == 2 || obj.rent_status == 3"
        [name]="'Rücknahme'"
        [access]="'rent_view'"
        [isPrimary]="true"
        (clickEmitter)="selectionReturnEmitter.emit(obj)">
      </app-restricted-button>

      <button *ngIf="false" class="button_type_2" (click)="shwoEmail = !shwoEmail">
        E-Mail senden
        <img src="./assets/dropdown-arrow-blue.svg">
        <div *ngIf="shwoEmail">
          <button (click)="shwoEmail = false">Dokument senden</button>
          <button (click)="shwoEmail = false">Erinnerung senden</button>
        </div>
      </button>
  </div>
</div>

<div class="body-base-style">
  <div id="drive-details-body">

    <div class="column">

      <app-collapsible [isCollapsible]>
        <div collapsible-content>
          <div class="overview_record">
            <h3>Miet-Typ</h3>
            <label class="overview_record_value" 
            [class.lbl_color_wrapper_pink]="this.obj.rent_typ == 1" 
            [class.lbl_color_wrapper_violet]="this.obj.rent_typ == 2"
            [class.lbl_color_wrapper_cyan]="this.obj.rent_typ == 3"
            >
              {{getInputValue(rentTypValue)}}
            </label> 
            <h3>Status</h3>
            <label class="overview_record_value" 
            [class.lbl_color_wrapper_yellow]="this.obj.rent_status == 1"
            [class.lbl_color_wrapper_gray]="this.obj.rent_status == 2" 
            [class.lbl_color_wrapper_brown]="this.obj.rent_status == 3"
            [class.lbl_color_wrapper_gray_status_returned]="this.obj.rent_status == 4"
            [class.lbl_color_wrapper_gray_status_storno]="this.obj.rent_status == 5">
              {{getInputValue(rentOrderStatusValue)}}
            </label> 
          </div>
        </div>
      </app-collapsible>


      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Dokumente & Verträge</h3>
        <div collapsible-content>
          <div class="file">
            <div class="file-inner" (click)="openPDF(obj.rent_id)">
              <div class="file-img-teal">
                <img src="./assets/document-grey.svg">
              </div>
              <div class="file-info-wrapper">
                <label class="overview_record_lbl">{{filename}}</label>
              </div>
            </div>
          </div>
        </div>
      </app-collapsible>

      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Übergabe an den Kunden</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Kilometerstand</label>
            <label class="overview_record_value">{{getInputValue(obj.handover_mileage)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Vorschäden</label>
            <label class="overview_record_value">{{getInputValue(obj.handover_damage)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Tankinhalt</label>
            <label class="overview_record_value">{{getInputValue(obj.handover_fuel_content)}}</label> 
          </div>
          <!-- <div class="overview_record">
            <label class="overview_record_lbl">Freigegeben am</label>
            <label class="overview_record_value">{{getInputValueDate(obj.start_release_date)}}</label> 
          </div> -->
        </div>
      </app-collapsible>

      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Bilddokumentation Übergabe</h3>
        <div collapsible-content>
          <app-miete-images
            [obj]="obj"
            [isHandover]="true"
          ></app-miete-images>
          <div  class="overview_record_lbl" *ngIf="obj.rent_status == 0 || obj.rent_status == 1">Derzeit keine Bilder verfügbar.</div>
        </div>
      </app-collapsible>

      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Rückgabe vom Kunden</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Kilometerstand</label>
            <label class="overview_record_value">{{getInputValue(obj.return_mileage)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Gefahrene Kilometer</label>
            <label class="overview_record_value">{{getInputValue(obj.driven_km)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Schäden</label>
            <label class="overview_record_value">{{getInputValue(obj.return_damage)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Tankinhalt</label>
            <label class="overview_record_value">{{getInputValue(obj.return_fuel_content)}}</label> 
          </div>
          <!-- <div class="overview_record">
            <label class="overview_record_lbl">Freigegeben am</label>
            <label class="overview_record_value">{{getInputValueDate(obj.end_release_date)}}</label> 
          </div> -->
        </div>
      </app-collapsible>

      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Bilddokumentation Rückgabe</h3>
        <div collapsible-content>
          <app-miete-images
            [obj]="obj"
            [isHandover]="false"
          ></app-miete-images>
          <div  class="overview_record_lbl" *ngIf="obj.rent_status == 1 || obj.rent_status == 2 || obj.rent_status == 3">Derzeit keine Bilder verfügbar.</div>
        </div>
      </app-collapsible>

    </div>

    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Hauptfahrer</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Vorname</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.contactPerson.firstName)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Nachname</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.contactPerson.lastName)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Straße, NR.</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.address.street)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">PLZ</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.address.postalCode)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Ort</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.address.city)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Telefon</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.contactPerson.phone)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">E-Mail</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.contactPerson.email)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerscheinnummer</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.authorization_number)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerschein Behörde</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.authorization_authority)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kopie Führerschein</label>
            <label class="overview_record_value">---</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Geburtsdatum</label>
            <label class="overview_record_value">{{getInputValueDate(obj.main_driver?.contactPerson.birthdate)}}</label> 
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Fahrzeug</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Marke</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.brand.makeName)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Modell</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.model.name)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">FIN</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.fin_number)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kennzeichen</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.license_plate)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Treibstoff</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.fuel.name)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Vorschäden</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.damages)}}</label> 
          </div>

        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Zweitfahrer</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Vorname</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.contactPerson.firstName)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Nachname</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.contactPerson.lastName)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Straße, NR.</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.address.street)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">PLZ</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.address.postalCode)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Ort</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.address.city)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Telefon</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.contactPerson.phone)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">E-Mail</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.contactPerson.email)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerscheinnummer</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.authorization_number)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerschein Behörde</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.authorization_authority)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kopie Führerschein</label>
            <label class="overview_record_value">---</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Geburtsdatum</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.contactPerson.birthdate)}}</label> 
          </div>
        </div>
      </app-collapsible>
    </div>

    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Mietumfang</h3>
        <div collapsible-content>
          <div *ngIf="obj.rent_typ != 1" class="overview_record">
            <label class="overview_record_lbl">Beginn</label>
            <label class="overview_record_lbl" [appFormatValue]="'dateTime'" [value]="obj.rental_start_date"></label>
          </div>

          <div *ngIf="obj.rent_typ == 1" class="overview_record">
            <label class="overview_record_lbl">Beginn</label>
            <label class="overview_record_lbl" [appFormatValue]="'date'" [value]="obj.rental_start_date"></label>
          </div>

          <div *ngIf="obj.rent_typ != 1" class="overview_record">
            <label class="overview_record_lbl">Ende</label>
            <label class="overview_record_lbl" [appFormatValue]="'dateTime'" [value]="obj.rental_end_date"></label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Dauer</label>
            <label class="overview_record_lbl" [appFormatValue]="'string'" [value]="obj.rent_duration"></label>
          </div>
          <div *ngIf="obj.rent_typ == 1" class="overview_record">
            <label class="overview_record_lbl">KM-Begrenzung</label>
            <label class="overview_record_value">{{getInputValue(obj.km_limit)}}</label> 
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Miete</h3>
        <div collapsible-content>
          <div *ngIf="obj.rent_typ != 1" class="overview_record">
            <label class="overview_record_lbl">Preis je Tag</label>
            <label class="overview_record_lbl" [appFormatValue]="'currency'" [value]="obj.rent_price_eur"></label>
          </div>
          <div *ngIf="obj.rent_typ == 1" class="overview_record">
            <label class="overview_record_lbl">Preis je Monat</label>
            <label class="overview_record_lbl" [appFormatValue]="'currency'" [value]="obj.rent_price_eur"></label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Miete in € (brutto)</label>
            <label class="overview_record_value">{{getInputValueCurrency(obj.total_price)}}</label>
          </div>
          <div class="overview_record" *ngIf="false">
            <label class="overview_record_lbl">Beisatz Miete</label>
            <label class="overview_record_value">{{getInputValue(obj.rent_add_on)}}</label> 
          </div>
          <div *ngIf="false" class="overview_record">
            <label class="overview_record_lbl">Kosten je Mehrkilometer in €</label>
            <label class="overview_record_value">{{getInputValueCurrency(obj.additional_km_price)}}</label> 
          </div>
          <div *ngIf="false" class="overview_record">
            <label class="overview_record_lbl">Versicherungspauschale</label>
            <label class="overview_record_value">{{getInputValue(obj.insurance_flat_rate)}}</label> 
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Kaution</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Kaution in €</label>
            <label class="overview_record_value">{{getInputValue(obj.deposit_eur)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kaution</label>
            <label class="overview_record_value">{{getInputValue(obj.deposit_time_unit)}}</label> 
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Sonderkonditionen</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">---</label>
          </div>
        </div>
      </app-collapsible>
    </div>

  </div>
</div>

