import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-drive-container',
  templateUrl: './drive-container.component.html',
  styleUrls: ['./drive-container.component.css']
})
export class DriveContainerComponent implements OnInit {
  public fuelTyp = ['Diesel','Benzin','Elektro'];
  public driveTyp = ['Vorderrad','Hinterrad'];
  public gearTyp = ['Schaltgetriebe','Automatik','Doppelkupplungsgetriebe'];
  public gears = [1,2,3,4,5,6,7,8,9,10];
  public transmissionTyp = ['Vorderrad','Hinterrad'];
  public consumption = ['Verbrauch 1','Verbrauch 2'];
  public efficiency = ['Effizienz 1','Effizienz 2'];
  public emissionStandard = ['Norm 1','Norm 2'];
  public dropDownWith = '14.5%';
  @Input() submitted: boolean;
  @Input() formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  get f() {
    return this.formGroup.controls;
  }

  setFuelTyp(name: string) {

  }

  setDriveTyp(name: string) {

  }

  setGears(name: string) {

  }

  setGearsTyp(name: string) {

  }

}
