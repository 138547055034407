import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css']
})
export class ImageSliderComponent implements OnInit, OnDestroy {
  public show = false;
  public base64Array: string[] = [];
  public startIndex = 0;
  public sliderSliceFrom = 0;
  public sliderSliceTo = 0;
  public subscription: Subscription;
  public animationRunning = false;
  public animationTime = 200;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openImageSlider_$.subscribe((data: any) => {
      if(data){
        this.base64Array = data.base64Array;
        this.startIndex = data.startIndex;
        if(this.startIndex > -1){
          this.sliderSliceFrom = this.startIndex;
          this.sliderSliceTo = this.startIndex + 1;
        }
        this.show = true;
        this.setListener();
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setListener() {
    setTimeout(() => {
      let slider_container = document.getElementById("slider_container");
      if(slider_container){
        slider_container.addEventListener("wheel", (event: any) => {
          if(event.deltaY > 0 && this.sliderSliceTo != this.base64Array.length){
            if(!this.animationRunning) this.nextPicture();
          } else if(event.deltaY <= 0 && this.sliderSliceFrom != 0) {
            if(!this.animationRunning) this.previousPicture();
          }
        });
      }
    }, 100);
  }

  nextPicture() {
    this.animationRunning = true;
    if(this.sliderSliceTo != this.base64Array.length){
      // this.sliderSliceFrom++;
      // this.sliderSliceTo++;
      let test_image = document.getElementById("test_image");
      test_image?.classList.remove("slide_left_out_animation");
      test_image?.classList.remove("slide_right_in_animation");
      test_image?.classList.remove("slide_right_out_animation");
      test_image?.classList.remove("slide_left_in_animation");
      if(test_image) {
        test_image.classList.add("slide_left_out_animation");
        setTimeout(() => {
          this.sliderSliceFrom++;
          this.sliderSliceTo++;
          test_image = document.getElementById("test_image");
          if(test_image) {
            test_image.classList.add("slide_right_in_animation");
            this.animationRunning = false;
          } else {
            this.animationRunning = false;
          }
        }, this.animationTime);
      } else {
        this.animationRunning = false;
      }
    }
  }

  previousPicture() {
    this.animationRunning = true;
    if(this.sliderSliceFrom != 0){
      // this.sliderSliceFrom--;
      // this.sliderSliceTo--;
      let test_image = document.getElementById("test_image");
      test_image?.classList.remove("slide_left_out_animation");
      test_image?.classList.remove("slide_right_in_animation");
      test_image?.classList.remove("slide_right_out_animation");
      test_image?.classList.remove("slide_left_in_animation");
      if(test_image) {
        test_image.classList.add("slide_right_out_animation");
        setTimeout(() => {
          this.sliderSliceFrom--;
          this.sliderSliceTo--;
          test_image = document.getElementById("test_image");
          test_image?.classList.remove("slide_right_out_animation");
          if(test_image) {
            test_image.classList.add("slide_left_in_animation");
            this.animationRunning = false;
          } else {
            this.animationRunning = false;
          }
        }, this.animationTime);
      } else {
        this.animationRunning = false;
      }
    }
  }

  closeSlider() {
    this.show = false;
  }
}
