import { UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Subscription } from 'rxjs';
import { Console } from 'console';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';

@Component({
  selector: 'app-create-contract-document',
  templateUrl: './create-contract-document.component.html',
  styleUrls: ['./create-contract-document.component.css']
})
export class CreateContractDocumentComponent implements OnInit {
  @Input() formContract: UntypedFormGroup;
  @Input() pageContract: number;
  @Input() customers: Customer[];
  @Input() submitted: boolean;
  @Output() changePageEmitter = new EventEmitter<boolean>();
  @Output() formCustomerValueChangedEmitter = new EventEmitter<undefined>();
  @Output() createContractEmitter = new EventEmitter<boolean>();
  public isEdit = false;
  public showTerms = false;
  public showDialog = false;
  public subscription: Subscription;
  public vehicles: Vehicle[] = [];

  constructor(
    private dService: DialogService,
    private vService: VehicleService
  ) { }

  ngOnInit(): void {
    this.getDataserverside();
    if(this.formContract?.valid) this.isEdit = true;
    this.subscription = this.dService.closeDialogCreateContract$.subscribe((value) => {
      if(value){
        this.createContractEmitter.emit(value.sign);
      }
    })
  }

  getDataserverside() {
    this.vService.getVehicle(false).subscribe((vehicles) => {
      if(vehicles){
        this.vehicles = vehicles;
        this.sort();
      }
    })
  }

  sort() {
    this.vehicles.sort((a,b) => {
      if(a.brand.makeName > b.brand.makeName) return 1;
      if(a.brand.makeName < b.brand.makeName) return -1;
      return 0;
    });
  }

  acceptTerms() {
    this.changePageEmitter.emit(true);
  }

  cancel() {
    this.showTerms = false;
  }

  changePage(next: boolean) {
    if(next && this.pageContract == 5){
      this.dService.openCreateContract({is_edit: this.isEdit});
    } else {
      this.changePageEmitter.emit(next);
    }
  }
}
