import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.css']
})
export class SortComponent implements OnInit, OnChanges {
  @Input() showSort: boolean;
  @Input() sortObject: any;
  @Output() sortEmitter = new EventEmitter<undefined>();
  @Output() resetSortEmitter = new EventEmitter<undefined>();
  public sortingSet = false;
  tempSortObject: any;
  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['sortObject']){
      this.copyObject();
    }
  }

  copyObject() {
    this.tempSortObject = {
      sort_after_value: this.sortObject.sort_after_value,
      sort_asc: this.sortObject.sort_asc,
      price_des: this.sortObject.price_des,
      km_des: this.sortObject.km_des,
    }
  }

  applyObject() {
    this.sortObject.sort_after_value = this.tempSortObject.sort_after_value;
    this.sortObject.sort_asc = this.tempSortObject.sort_asc;
    this.sortObject.price_des = this.tempSortObject.price_des;
    this.sortObject.km_des = this.tempSortObject.km_des;
  }

  submitSorting() {
    this.applyObject();
    this.sortEmitter.emit();
  }
}
