
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BaseComponent } from '../../BaseComponent';
import { EnumFactory } from 'src/app/buisness-object/menu/EnumData';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-conflict-dialog',
  templateUrl: './conflict-dialog.component.html',
  styleUrl: './conflict-dialog.component.css'
})
export class ConflictDialogComponent extends BaseComponent implements OnChanges {
  @Input() show: boolean = false;
  @Input() conflicts: any[];
  @Output() submitEventEmitter = new EventEmitter<boolean>();

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    const sidebar = document.getElementById("sidebar");
    /*if(sidebar){
      sidebar.style.zIndex = "0 !important";
      sidebar.setAttribute("z-index","0 !important");
    }*/
    // this.conflicts = [
    //   {
    //     "name": "mileage_in_km",
    //     "old_typ": "number",
    //     "old_value": 149011,
    //     "transaction_typ": "update",
    //     "typ": "number",
    //     "value": 149001,
    //     "selected": "value",
    //   },
    //   {
    //     "name": "previous_owners",
    //     "old_typ": "number",
    //     "old_value": 3,
    //     "transaction_typ": "update",
    //     "typ": "number",
    //     "value": 2,
    //     "selected": "value",
    //   },
    //   {
    //     "name": "color_code",
    //     "old_typ": "string",
    //     "old_value": "YYY",
    //     "transaction_typ": "update",
    //     "typ": "string",
    //     "value": "XXX",
    //     "selected": "value",
    //   },
    //   {
    //     "name": "color_id",
    //     "compare": [
    //       {
    //         "name": "color_id",
    //         "old_typ": "number",
    //         "old_value": 4,
    //         "transaction_typ": "update",
    //         "typ": "number",
    //         "value": 7,
    //       }
    //     ],
    //     "selected": "value",
    //   }
    // ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["conflicts"]){
      if(this.conflicts != null){
        for(let con of this.conflicts){
          con.selected = "value";
        }
      }
    }
  }

  getConflictValue(conflict: any, key: string) {
    if(conflict.compare == null){
      if(conflict.old_typ === "string") return this.getInputValue(conflict[key]);
      if(conflict.old_typ === "number") return this.getInputValueNumberNoDicis(conflict[key]);
      if(conflict.old_typ === "date") return this.getInputValueDate(conflict[key]);
      if(conflict.old_typ === "enum") return this.getEnumValue(conflict.name, conflict[key]);
    } else {
      if(conflict.compare.length > 0){
        if(conflict.compare[0].old_typ === "string") return this.getInputValue(conflict.compare[0][key]);
        if(conflict.compare[0].old_typ === "number") return this.getInputValueNumberNoDicis(conflict.compare[0][key]);
        if(conflict.compare[0].old_typ === "date") return this.getInputValueDate(conflict.compare[0][key]);
        if(conflict.old_typ === "enum") return this.getEnumValue(conflict.name, conflict[key]);
      }
    }
    return "---";
  }

  getEnumValue(enumName: string, rawValue: number): any {
    let rawEnums: any = JSON.parse(localStorage.getItem('enums'));
    if(rawEnums == null || rawEnums.length == 0 || rawValue == null || enumName == null) return '---';
    let enums = EnumFactory.jsonFactoryEnumData(rawEnums);
    let index = enums.findIndex((value) => value.name === enumName);
    if(index > -1){
      let enumData = enums[index];
      let enumValue = enumData.values.find((value) => value.raw_value == rawValue);
      return enumValue ? enumValue.value : '---';
    }
    return '---';
  }

  selectionConflict(conflict: any){
    if(conflict.selected == "value") conflict.selected = "old_value"
    else conflict.selected = "value"
  }

  cancel() {
    this.submitEventEmitter.emit(false);
    this.conflicts = null;
  }

  submit() {
    this.submitEventEmitter.emit(true);
  }
}
