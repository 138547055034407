import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dropdown-simple',
  templateUrl: './dropdown-simple.component.html',
  styleUrls: ['./dropdown-simple.component.css']
})
export class DropdownSimpleComponent implements OnInit {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() formcontrol: FormControl;
  @Input() values: string[];
  @Input() isOptional: boolean;
  @Output() submitValueEmitter = new EventEmitter<boolean>();

  public show = false;

  constructor() { }

  ngOnInit(): void {
    if(this.values && this.values.length != 2){
      this.values = null;
    }
  }

  getHeaderValue(): string {
    if(this.values == null){
      return this.formcontrol.value == true ? 'Ja' : (this.formcontrol.value == false ? 'Nein' : '---')
    } else {
      return this.formcontrol.value == true ? this.values[0] : (this.formcontrol.value == false ? this.values[1] : '---');
    }
  }
}
