<div>
  <app-topbar-drives
    [surface]="surface"
    [tab]="tab"
    [driveTyp]="driveTyp"
    (openCreateEmitter)="openCreate()"
    (changeTabEmitter)="changeTab($event)"
    (saveEmitter)="saveDriveNew()"
    (cancelEmitter)="cancelCreate()"
    (backEmitter)="backAction()"
    (searchEmitter)="searchAction($event)"
    (selectionCreateInternDriveEmitter)="selectionCreateInternDrive()"
  ></app-topbar-drives>
  <app-list-drives *ngIf="surface == 1"
    [drives]="drivesFiltered"
    [pageInfo]="pageInfo"
    [sliceFrom]="sliceFrom"
    [sliceTo]="sliceTo"
    [filter]="filter"
    [amount]="drivesFiltered.length"
    [selectedTab]="selectedTab"
    (pageEmitter)="pageHandling($event)"
    (openDetailsEmitter)="openDetails($event)"
    (openEditEmitter)="editDocument($event)"
    (deleteEmitter)="deleteAction($event)"
    (openCreatePdfEmitter)="openCreatePdf($event)"
    (selectionPDFEmitter)="selectionSignedPDF($event)"
    (selectionTabEmitter)="selectionTab($event)"
    (selectionPseudoSignatureEmitter)="selectionPseudoSignature($event)"
  ></app-list-drives>
  <app-calender-drives *ngIf="surface == 2"
    [drives]="drives"
    [vehicles]="vehicles"
    [showTimeValue]="false"
    (selectionDriveEmitter)="openDetails($event)"
  ></app-calender-drives>
  <app-create-drive *ngIf="surface == 3 && authService.isSinhuber()"
    [form]="form"
    [submitted]="submitted"
    [pageDrive]="pageDrive"
    [vehicles]="vehicles"
    [drives]="drives"
    [customers]="customers"
    [employees]="employees"
    [driveTyp]="driveTyp"
    (changePageEmitter)="nextPage($event)"
  ></app-create-drive>
  <app-create-drive-new *ngIf="surface == 3 && !authService.isSinhuber()"
  [form]="formNew"
  [submitted]="submitted"
  [pageDrive]="pageDrive"
  [vehicles]="vehicles"
  [drives]="drives"
  [customers]="customers"
  [employees]="employees"
  [driveTyp]="driveTyp"
  (createDriveEmitter)="createDriveNew($event)"
></app-create-drive-new>
  <app-drive-details *ngIf="surface == 4"
    [drive]="selectedDrive"
  ></app-drive-details>
  <app-dialog-pdf-drive *ngIf="showPdfDialog"
    [drive]="selectedDrive"
    (cancelEmitter)="cancelPdfCreate()"
    (createEmitter)="createPdfCreate($event)"
  ></app-dialog-pdf-drive>
</div>
