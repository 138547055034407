<form class="contract_create_page_wrapper" [formGroup]="form">
  <div class="lbl_bold_light_color">Neuer Kaufvertag</div>
  <div class="page_body">
    <div class="column">
      <div class="flex_title">
        <div class="list_title_lbl">Fahrzeugzustand</div>
        <div class="btn_cancel" (click)="openInfoCondition()">Info-Blatt öffnen</div>
      </div>
      <div id="wrapper-conditions">
        <app-dropdown-multiple
          [title]="'Mechanischer Zustand'"
          [formcontrol]="f.mechanical_condition"
          [headerText]="mechanical_conditionValue"
          [values]="conditionValues"
          [submitted]="submitted"
          (submitValueEmitter)="f.mechanical_condition.setValue($event); getEnumDataOnChange()"
        ></app-dropdown-multiple>
        <app-dropdown-multiple
          [title]="'Karosserie'"
          [formcontrol]="f.body_condition"
          [headerText]="body_conditionValue"
          [values]="conditionValues"
          [submitted]="submitted"
          (submitValueEmitter)="f.body_condition.setValue($event); getEnumDataOnChange()"
        ></app-dropdown-multiple>
        <app-dropdown-multiple
          [title]="'Lack'"
          [formcontrol]="f.paint_condition"
          [headerText]="paint_conditionValue"
          [values]="conditionValues"
          [submitted]="submitted"
          (submitValueEmitter)="f.paint_condition.setValue($event); getEnumDataOnChange()"
        ></app-dropdown-multiple>
        <app-dropdown-multiple
          [title]="'Innenraum/Sonstiges'"
          [formcontrol]="f.interior_condition"
          [headerText]="interior_conditionValue"
          [values]="conditionValues"
          [submitted]="submitted"
          (submitValueEmitter)="f.interior_condition.setValue($event); getEnumDataOnChange()"
        ></app-dropdown-multiple>
        <app-dropdown-multiple
          [title]="'Elektrik/Elektronik'"
          [formcontrol]="f.electronic_condition"
          [headerText]="electronic_conditionValue"
          [values]="conditionValues"
          [submitted]="submitted"
          (submitValueEmitter)="f.electronic_condition.setValue($event); getEnumDataOnChange()"
        ></app-dropdown-multiple>
      </div>
    </div>
    <div class="column">
      <div class="list_title_lbl" style="margin-bottom: 40px;">Ankaufsüberprüfung</div>
      <div class="flex" style="height: 28px;">
        <label class="overview_record_lbl">Gesamtzustand</label>
        <div [ngClass]="getColor()">{{f.total_condition.value ? f.total_condition.value : '---'}}</div>
      </div>
      <div class="flex" style="margin-bottom: 15px;">
        <label class="overview_record_lbl">Kunde wünscht eine <span style="font-weight: bold;">Ankaufsüberprüfung</span></label>
        <div class="slider_container" style="margin-right: 10px;" (click)="f.purchase_test_wish.setValue(!f.purchase_test_wish.value)">
          <div class="slider_still" [ngClass]="f.purchase_test_wish.value ? 'slider_still_on' : ''"></div>
          <div class="slider_move" [ngClass]="f.purchase_test_wish.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div *ngIf="f.purchase_test_wish.value">
        <div class="input_wrapper" style="margin-bottom: 12px;">
          <div class="overview_record_lbl">Datum</div>
          <input #inputDate1 type="date" placeholder="date" [min]="minDate" formControlName="purchase_test_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls.purchase_test_date.errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Prüfer</label>
          <input type="text" placeholder="Prüfer" formControlName="purchase_tester" [class.input_invalid]="submitted && form.controls.purchase_tester.errors">
        </div>
      </div>
    </div>
  </div>
</form>
