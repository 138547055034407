<div class="list_element list_hover_element" [ngClass]="resolveThread ? 'element_hidden' : 'element_visible'" [ngStyle]="{'background-color': thread.notifyOpen ? 'var(color-9-light)' : ''}">
  <div class="element_body">
      <div class="user_initials">{{getInitials(thread.employee)}}</div>
      <div #element>
        <div class="list_element_header">
          <div *ngIf="!thread.archived" class="priority_container">
            <div class="lbl_regular_italic_light_color" [ngStyle]="{'color': thread.urgency == 0 ? 'var(--color-green)' : (thread.urgency == 1 ? 'var(--color-orange)' : 'var(--color-red)')}">{{getUrgency(thread.urgency)}}</div>
            <div class="circle" [ngStyle]="{'background-color': thread.urgency == 0 ? 'var(--color-green)' : (thread.urgency == 1 ? 'var(--color-orange)' : 'var(--color-red)')}"></div>
          </div>
          <label [ngClass]="thread.typ == 1 ? 'lbl_color_wrapper_violet' : (thread.typ == 2 ? 'lbl_color_wrapper_brown' : 'lbl_color_wrapper_cyan')">{{thread.getTypName()}}</label>
        </div>
        <div class="lbl_regular lbl_message" [innerHTML]="thread.getTicketTitle(element)"></div>
        <div class="lbl_light_wrapper">
          <div class="lbl_light_wrapper_inner" *ngIf="thread.typ != 0">
            <label class="lbl_regular_italic_light_color">Zuordnung: </label>
            <label class="lbl_regular_italic_light_color">{{thread.typ == 1 ? thread.vehicle?.getDescription(100) : (thread.typ == 2 ? thread.customer?.getName() : '---')}}</label>
          </div>
          <div class="lbl_light_wrapper_inner">
            <label class="lbl_regular_italic_light_color">Ersteller: </label>
            <label class="lbl_regular_italic_light_color">{{thread.employee?.firstName}} {{thread.employee?.lastName}}, {{thread.createdDate | date: 'dd.MM.YYYY, HH:mm'}}</label>
          </div>
        </div>
        <div class="element_body_inner_flex_buttons">
          <div id="btn_details" class="action" style="margin-right: 0;" (click)="openDetails(thread)">
            <img class="icon" [ngStyle]="{'width': '10px'}" src="../../../../../assets/details-eye-blue.svg">
            <div class="action_lbl">Details ansehen</div>
          </div>
          <div *ngIf="!thread.archived && thread.creator == userId" class="action" (click)="resolve(thread)">
              <img class="icon" src="../../../../../assets/lock-blue.svg">
              <div class="action_lbl">Auflösen</div>
          </div>
       </div>
      </div>
  </div>
</div>
