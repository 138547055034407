import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from '../../BaseComponent';
import { formatDate } from '@angular/common';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { PriceSheet } from 'src/app/buisness-object/pricesheet/PriceSheet';
import { Subscription } from 'rxjs';
import { PriceSheetCalculater } from 'src/app/buisness-object/pricesheet/PriceSheetCalculater';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { PriceSheetForm } from 'src/app/buisness-object/pricesheet/form/PriceSheetForm';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-price-sheets-create-nackalkulation',
  templateUrl: './price-sheets-create-nackalkulation.component.html',
  styleUrls: ['./price-sheets-create-nackalkulation.component.css']
})
export class PriceSheetsCreateNackalkulationComponent extends BaseComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() priceSheet: PriceSheet;
  @Input() customers: Customer[];
  @Input() employees: Employee[];
  @Output() onPriceSheetChangesEmitter = new EventEmitter<undefined>();
  @Output() selectionEditSteuersatzAcceptedEmitter = new EventEmitter<undefined>();
  @Output() addReparaturEmitter = new EventEmitter<undefined>();
  @Output() removeReparaturEmitter = new EventEmitter<number>();
  public dialogQuerySubsription;
  public dialogValueSubscription;
  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public inputTimeout;
  public formSub: Subscription;
  public customerValues = [];
  public employeeValues = [];

  public finanzierungszusagenValues = [];
  public finanzierungszusagenValue;
  public finanzierungsartenValues = [];
  public finanzierungsartenValue;
  public finanzierungsgesellschaftenValues = [];
  public finanzierungsgesellschaftenValue;
  public setAutoProvision = true;

  constructor(
    private dService: DialogService,
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    let provisionKeys = ['verkaufspreis_brutto','verkaufsdatum','employee_id'];
    for(let key of provisionKeys){
      if(this.form.controls[key].value == null){
        this.setAutoProvision = true;
        break;
      }
    }
    if(this.form.controls['abzug_fahrzeug_provision'].value != null) this.setAutoProvision = false;
    if(!this.authService.isHoedl()) this.setAutoProvision = false;
    if(this.setAutoProvision) this.form.controls['abzug_fahrzeug_provision'].setValue(PriceSheetForm.getProvision(this.form));
    for(let key of provisionKeys){
      this.form.controls[key].valueChanges.subscribe((changes) => {
        if(this.setAutoProvision){
          this.form.controls['abzug_fahrzeug_provision'].setValue(PriceSheetForm.getProvision(this.form));
        }
      });
    }

    this.finanzierungszusagenValues = this.getEnumValues('finanzierungszusage');
    this.finanzierungszusagenValue = this.getEnumValue('finanzierungszusage', this.priceSheet.finanzierungszusage);
    this.finanzierungsartenValues = this.getEnumValues('finanzierungsart');
    this.finanzierungsartenValue = this.getEnumValue('finanzierungsart', this.priceSheet.finanzierungsart);
    this.finanzierungsgesellschaftenValues = this.getEnumValues('finanzierungsgesellschaft');
    this.finanzierungsgesellschaftenValue = this.getEnumValue('finanzierungsgesellschaft', this.priceSheet.finanzierungsgesellschaft);
    if(!this.form.controls["nachkalkulation"].value){
      if(this.form.controls["steuersatz"].value == 3 || this.form.controls["steuersatz"].value == 4){ // Ausland 19%
        if(this.form.controls["verkaufspreis_brutto"].value == null){
          this.form.controls["verkaufspreis_brutto"].setValue(this.form.controls["minimaler_verkaufpreis"].value);
        }
      }
    }
    this.dialogSubscription();
    this.customerValues = this.getDropdownValuesCustomer(this.customers);
    this.employeeValues = this.getDropdownValuesEmployee(this.employees);
  }

  getEnumDataOnChange() {
    this.finanzierungszusagenValue = this.getEnumValue('finanzierungszusage', this.form.controls['finanzierungszusage'].value);
    this.finanzierungsgesellschaftenValue = this.getEnumValue('finanzierungsgesellschaft', this.form.controls['finanzierungsgesellschaft'].value);
    this.finanzierungsartenValue = this.getEnumValue('finanzierungsart', this.form.controls['finanzierungsart'].value);
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.dialogValueSubscription) this.dialogValueSubscription.unsubscribe();
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    if(this.formSub) this.formSub.unsubscribe();
  }

  isVomAusland(): boolean {
    return PriceSheetCalculater.isVomAusland(this.form, this.authService.isStandard());
  }

  dialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'change_steuersatz') this.selectionEditSteuersatzAcceptedEmitter.emit();
      }
    });
    this.dialogValueSubscription = this.dService.closeDialogValue$.subscribe((value) => {
      if(value){
        if(value.identifier == 'zinssatz_nach') this.form.controls['zinssatz_nach'].setValue(value.submit_value);
        else if(value.identifier == 'standzeitkosten_je_tag_nach') this.form.controls['standzeitkosten_je_tag_nach'].setValue(value.submit_value);
      }
    });
  }

  selectionEditValue(data: any, identifier: string) {
    let title = "";
    if(identifier == "zinssatz_nach") title = "Zinssatz tauschen?";
    else if(identifier == "standzeitkosten_je_tag_nach") title = "Standzeitkosten je Tag tauschen?";
    this.dService.openValue(
      {
        title: title,
        form_value: data,
        btn_cancel_txt: "Abbrechen",
        btn_submit_txt: "Speichern",
        identifier: identifier
      }
    );
  }

  selectionEditSteuersatz() {
    this.dService.openQuery(
      {
        title: 'Steuersatz tauschen?',
        message: 'Sind Sie sicher, dass Sie den Steuersatz tauschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Steuersatz tauschen',
        typ: 'change_steuersatz',
      }
    );
  }

  getDropdownValuesCustomer(customers: Customer[]): any[] {
    let values = [];
    for(let value of customers){
      values.push({
        id: value.id,
        name: value.contactPerson.firstName + ' ' + value.contactPerson.lastName + (value.companyName ? ' - ' + value.companyName : '')
      })
    }
    return values;
  }

  getDropdownValuesEmployee(employees: Employee[]): any[] {
    let values = [];
    for(let value of employees){
      values.push({
        id: value.id,
        name: value.firstName + ' ' + value.lastName
      })
    }
    return values;
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'customer'){
      let index = this.customers.findIndex(obj => obj.id == id);
      if(index > -1) this.form.controls["customer"].setValue(this.customers[index]);
    }
    if(value === 'employee'){
      let index = this.employees.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls["employee"].setValue(this.employees[index]);
        this.form.controls["employee_id"].setValue(this.employees[index].id);
      }
    }
  }
}
