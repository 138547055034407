export class SubMenuFilter {

  constructor(
    public data_object: string,
    public calculated: number,
    public data_key: string,
    public operator: string,
    public data_typ: string,
    public raw_value: string,
  ) { }
}

export class SubMenuOperator {

  constructor(
    public operator: string,
  ) { }
}
