import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-edit-description',
  templateUrl: './edit-description.component.html',
  styleUrls: ['./edit-description.component.css']
})
export class EditDescriptionComponent implements OnInit, AfterViewInit {
  @Input() form: FormGroup;
  @ViewChild('textField_1') textField_1: ElementRef;
  @ViewChild('textField_2') textField_2: ElementRef;
  @ViewChild('textField_3') textField_3: ElementRef;
  @ViewChild('textField_4') textField_4: ElementRef;
  public textOne: string;
  public maxChar = 5000;
  public inseratDisable = false;
  public optionsButtons;
  public formatButtons;



  constructor(
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.inseratDisable = !this.authService.isHoedlAdmin() || this.form.controls["inserat_checked"].value;
  }

  ngAfterViewInit(): void {
    this.textField_3.nativeElement.innerText = this.form.controls["internal_description"].value;
    this.textField_4.nativeElement.innerText = this.form.controls["accounting_description"].value;
  }

  onPaste(event, num) {
    event.preventDefault();
    var clipboardData, pastedData;

    // Stop data actually being pasted into div
    event.stopPropagation();
    event.preventDefault();

    // Get pasted data via clipboard API
    clipboardData = event.clipboardData;
    pastedData = clipboardData.getData('Text');
    while(pastedData.includes("\r\n\r\n")){
      pastedData = pastedData.replace("\r\n\r\n","\r\n");
    }
    if(num == 1) {
      // this.textField_1.nativeElement.innerText = pastedData;
      // this.form.controls.description.setValue(pastedData);
    } else if(num == 2) {
      this.textField_2.nativeElement.innerText = pastedData;
      this.form.controls.addEquipment.setValue(pastedData);
    }
    else if(num == 3) {
      this.textField_3.nativeElement.innerText = pastedData;
      this.form.controls.internal_description.setValue(pastedData);
    }
    else if(num == 4) {
      this.textField_4.nativeElement.innerText = pastedData;
      this.form.controls.accounting_description.setValue(pastedData);
    }
  }

  // onInput(event, textField: number) {
  //   this.form.controls.description.setValue(event.target.innerHTML);
  //   if(this.textFormat){
  //     if(this.textFormat.style == TextStyle.BOLD){
  //       this.handleStyleBold(event);
  //     }
  //   } else {
  //     const node = this.getNodeAtCursorPosition();
  //     if(node.nodeName === "STRONG"){
  //     }
  //     if(event.data == "&nbsp;"){
  //       const txt = event.target.innerHTML;
  //       // if(event.target.innerText.substring(txt) == ""){

  //       // }
  //     }
  //   }
  // }

  // stringIsClosingTag(string): boolean {
  //   return string.startsWith("</") && string.endsWith(">");
  // }
  // selectionTextStyle(style: TextStyle, element: HTMLElement){
  //   const selection = window.getSelection();
  //   let cursorePosition = 0;
  //   if(selection.rangeCount > 0){
  //     cursorePosition = this.calculateCursorPosition();
  //   }
  //   this.textFormat = {
  //     style: style,
  //     cursorPosition: cursorePosition,
  //     active: this.textFormat?.active ? !this.textFormat.active : true,
  //     text: ""
  //   }
  //   if(this.isTextSelected()){
  //     this.markTextAsBold(element);
  //     this.textFormat = undefined;
  //     return;
  //   } else {
  //     if(this.textFormat.active){
  //       //this.setCursorPosition(element, this.textFormat.cursorPosition);
  //     } else {
  //       const node = this.getNodeAtCursorPosition();
  //       //this.setCursorePositionAfterUse(node);
  //       this.stopTextStyle(node);
  //       this.textFormat = undefined;
  //     }
  //   }
  // }

  // stopTextStyle(node) {
  //   if(node != null){
  //     if(node.nextSibling != null){
  //       if(node.nextSibling.nodeValue[0] && node.nextSibling.nodeValue[0] != " "){ //&nbsp;
  //         node.nextSibling.nodeValue = "\u00A0"+node.nextSibling.nodeValue.substring(1, node.nextSibling.nodeValue.length);
  //       } else {
  //         node.nextSibling.nodeValue = node.nextSibling.nodeValue;
  //       }
  //     } else if(node.nextSibling != null) {
  //       node.nextSibling.nodeValue = "\u00A0";
  //     }
  //   }
  // }

  // setCursorePositionAfterUse(node) {
  //   if(node != null){
  //     const selection = window.getSelection();
  //     if(node.nextSibling != null){
  //       if(node.nextSibling.nodeValue[0] != " "){
  //         node.nextSibling.nodeValue = "\u00A0"+node.nextSibling.nodeValue;
  //       } else {
  //         node.nextSibling.nodeValue = node.nextSibling.nodeValue;
  //       }
  //     } else {
  //       //node.nextSibling.nodeValue = "\u00A0";
  //     }
  //     const range = document.createRange();
  //     range.setStart(node.nextSibling,1);
  //     range.collapse(true);
  //     selection.removeAllRanges();
  //     selection.addRange(range);
  //   }
  // }

  // calculateCursorPosition(): number {
  //   const selection = window.getSelection();
  //   const range = selection.getRangeAt(0);
  //   const preCaretRange = range.cloneRange();
  //   let contenteditable_one = document.getElementById("contenteditable_one"); // TODO: ID dynamisch machen
  //   preCaretRange.selectNodeContents(contenteditable_one);
  //   preCaretRange.setEnd(range.endContainer, range.endOffset);
  //   const cursorPosition = preCaretRange.toString().length;
  //   preCaretRange.detach();
  //   return cursorPosition
  // }

  // setCursorPositionEndOfNode(node) {
  //   const range = document.createRange();
  //   const selection = window.getSelection();
  //   range.selectNodeContents(node);
  //   range.collapse(false);
  //   selection.removeAllRanges();
  //   selection.addRange(range);
  // }

  // setCursorPosition(element, position) {
  //   element.focus();
  //   const range = document.createRange();
  //   const sel = window.getSelection();
  //   const cursorNode = sel.getRangeAt(0).commonAncestorContainer;
  //   range.setStart(cursorNode, position);
  //   range.collapse(true);
  //   sel.removeAllRanges();
  //   sel.addRange(range);
  // }

  // handleStyleBold(event) {
  //   event.preventDefault();
  //   event.stopImmediatePropagation();
  //   const cursorPosition = this.calculateCursorPosition();
  //   if(cursorPosition >= this.textFormat.cursorPosition){
  //     let text = event.target.innerText;
  //     this.textFormat.text = this.textFormat.text + event.data;
  //     let strongText = text.substring(this.textFormat.cursorPosition, this.textFormat.cursorPosition+this.textFormat.text.length);
  //     text = text.substring(0, this.textFormat.cursorPosition) + text.substring(this.textFormat.cursorPosition+this.textFormat.text.length, text.length);
  //     //event.target.innerText = text;
  //     const strongNode = document.createElement("strong");
  //     strongNode.textContent = strongText;
  //     this.insertNodeAtPosition(event.target.firstChild ? event.target.firstChild : event.target, strongNode, this.textFormat.cursorPosition);
  //     this.setCursorPositionEndOfNode(strongNode);
  //   } else  {
  //     this.textFormat = undefined;
  //   }
  // }


  // getNodeAtCursorPosition(): Node {
  //   const selection = window.getSelection();
  //   if(selection.rangeCount > 0){
  //     const range = selection.getRangeAt(0);
  //     const containerNode = range.startContainer;
  //     const offset = range.startOffset;
  //     if(containerNode.nodeType == Node.TEXT_NODE){
  //       const parentElement = containerNode.parentElement;
  //     } else {
  //     }
  //     return containerNode;
  //   }
  //   return null;
  // }

  // insertNodeAtPosition(element, node, offset) {
  //   const range = document.createRange();
  //   const sel = window.getSelection();
  //   range.setStart(element, offset);
  //   sel.removeAllRanges();
  //   sel.addRange(range);
  //   range.insertNode(node);
  // }

  // //Funktionale Methode
  // markTextAsBold(element: HTMLElement) {
  //   const selection = window.getSelection();
  //   if(selection.rangeCount > 0){

  //     //save cursor position
  //     const range = selection.getRangeAt(0);
  //     const cursorPosition = this.calculateCursorPosition();

  //     //create bold text
  //     const boldText = document.createElement("strong");
  //     boldText.appendChild(document.createTextNode(range.toString()));
  //     range.deleteContents();
  //     range.insertNode(boldText);
  //     this.stopTextStyle(boldText);
  //   }
  // }

  // isTextSelected(): boolean {
  //   return window.getSelection().rangeCount > 1;
  // }
}

// export enum TextStyle {
//   BOLD,
// }

