import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, } from '@angular/forms';
import { RentTyp, RentTypLabels } from 'src/app/buisness-object/rent/Rent';
import { RentForm } from 'src/app/buisness-object/vehicle/form/RentForm';
import { RentPrice } from 'src/app/buisness-object/vehicle/RentPrice';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { getEnumFromLabel, getLabelFromEnumValue, getEnumLabelValues } from 'src/app/utils/EnumHandler';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-rent',
  templateUrl: './rent.component.html',
  styleUrl: './rent.component.css'
})
export class RentComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() editMode: boolean;
  public rentTypValue;
  public rentTypValues = [];
  public showSubscriptions = false;
  public rents: RentPrice[] = [];

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private formBuilder: FormBuilder
  ){
    super(authService, eService);
  }


  ngOnInit(): void {
    // this.rentValues = this.getEnumValues('rent_typ');
    // this.rentValue = this.getEnumValue('rent_typ', this.vehicle.rent_typ);
    this.rentTypValue = getLabelFromEnumValue(this.form.get('rent_typ').value, RentTypLabels);
    this.rentTypValues = getEnumLabelValues(RentTyp, RentTypLabels);
    if(this.form.get('rent_typ').value == RentTyp.MIETABO){
      this.rents = this.vehicle.rents;
      this.showSubscriptions = true;
    } else {
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 6, 1000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 6, 2000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 6, 3000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 6, 4000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 12, 1000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 12, 2000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 12, 3000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 12, 4000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 18, 1000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 18, 2000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 18, 3000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 18, 4000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 24, 1000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 24, 2000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 24, 3000))
      this.rents.push(new RentPrice(0,this.vehicle.id, null, 24, 4000))
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.editMode){
      if(this.editMode == true){
        if(this.rentTypValue == RentTyp.MIETABO){
          this.rents = this.vehicle.rents;
        }
        for(let rent of this.rents){
          this.form.get('rent_forms').value.push(RentForm.getFormGroup(this.formBuilder, rent));
        }
      }
      this.rentTypValue = getLabelFromEnumValue(this.vehicle.rent_typ, RentTypLabels);
    }
    if(changes.vehicle){
      this.rentTypValue = getLabelFromEnumValue(this.vehicle.rent_typ, RentTypLabels);
    }
  }

  selectionRentTyp(typ: string) {
    this.form.controls['rent_typ'].setValue(getEnumFromLabel(typ, RentTypLabels));
    this.rentTypValue = typ;
    if(this.rentTypValue == getLabelFromEnumValue(RentTyp.MIETABO, RentTypLabels)){
      this.showSubscriptions = true;
    } else this.showSubscriptions = false;
  }

  showRentPricePerDay(): boolean {
    return this.rentTypValue == getLabelFromEnumValue(RentTyp.KURZMIETE, RentTypLabels) || this.rentTypValue == getLabelFromEnumValue(RentTyp.SPEZIAL, RentTypLabels)
  }
}
