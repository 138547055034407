import { VehicleBrand } from "../VehicleBrand";
import { VehicleModelFactoy } from "./VehicleModelFactoy";

export class VehicleBrandFactory {
    static jsonFactory(rawData: any): VehicleBrand[] {
        let dataAry: VehicleBrand[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehicleBrand {
        let object = new VehicleBrand(
            rawData.make_id,
            rawData.name ? rawData.name : rawData.make_name,
            rawData.models ? VehicleModelFactoy.jsonFactory(rawData.models) : []
        );
        return object;
    }
}
