import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Employee } from 'src/app/buisness-object/employee/Employee';

@Component({
  selector: 'app-list-admin-element',
  templateUrl: './list-admin-element.component.html',
  styleUrls: ['./list-admin-element.component.css']
})
export class ListAdminElementComponent implements OnInit {
  public showDropdown = false;
  @ViewChild('dropdownImg') dropdownImg: ElementRef;
  @ViewChild('dropdownOptions') dropdownOptions: ElementRef;
  @Input() employee: Employee;
  @Output() openDetailsEmitter = new EventEmitter<Employee>();
  @Output() openEditEmitter = new EventEmitter<Employee>();
  @Output() deleteEmitter = new EventEmitter<Employee>();

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    this.setCloseListener();
  }

  setIcon():string {
    if(this.showDropdown){
      return '../../../../../assets/icon-more-white.png';
    }
    return '../../../../../assets/icon-more.png'
  }

  getInitials(): string {
    return this.employee.firstName.substring(0,1) + this.employee.lastName.substring(0,1);
  }

  setCloseListener() {
    this.renderer.listen('window','click', (e:Event) => {
      if(this.showDropdown){
        if(e.target != this.dropdownImg.nativeElement && e.target != this.dropdownOptions.nativeElement){
          this.showDropdown = false;
        }
      }
    });
  }
}
