import { VehicleBodyColor } from "../VehicleBodyColor";

export class VehicleBodyColorFactory {
    static jsonFactory(rawData: any): VehicleBodyColor[] {
        let dataAry: VehicleBodyColor[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehicleBodyColor {
        let object = new VehicleBodyColor(
            rawData.body_color_id,
            rawData.body_color_name,
        );
        return object;
    }
}