<div id="dialog_window" *ngIf="show">
  <div id="dialog_container">
    <div id="dialog_close_icon" (click)="show = false">&#10005;</div>
    <h1>Kaufvertrag erstellen</h1>
      <div id="dialog_conainter_body">
        <label>Sie haben die Möglichkeit den Vertrag im Anschluss zu signieren, oder die Vertragsunterschirft zu einem späteren Zeitpunkt durchzuführen.</label>
      </div>
      <div class="dialog_btn_container">
        <button class="btn_label" (click)="show=false">Abbrechen</button>
        <div class="btn_container_inner">
          <button class="btn_submit_dark" (click)="onSubmit(false)">{{isEdit ? "Speichern" : "Erstellen"}}</button>
          <button class="btn_submit_dark" (click)="onSubmit(true)">{{isEdit ? "Speichern" : "Erstellen"}} und signieren</button>
        </div>
      </div>
  </div>
</div>
