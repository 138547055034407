import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Rent, RentOrderStatus } from "../Rent";
import { DateHelper } from "src/app/utils/DateHelper";
import { CustomerForm } from "../../customer/form/CustomerForm";
import { VehicleForm } from "../../vehicle/form/VehicleForm";
import { validateDateIsNotBeforeOtherDate } from "src/app/validators/CustomValidators";

export class RentForm {

  public static getForm(formBuilder: FormBuilder, obj?: Rent): FormGroup {
    let form = formBuilder.group({
      rent_id: [obj && obj.rent_id ? obj.rent_id : null],
      rent_typ:  [obj && obj.rent_typ ? obj.rent_typ : null, Validators.required],
      rent_price_eur: [obj && obj.rent_price_eur ? obj.rent_price_eur : null],
      rent_status: [obj && obj.rent_status ? obj.rent_status : RentOrderStatus.RECEIVE],
      handover_mileage: [obj && obj.handover_mileage ? obj.handover_mileage : 0, Validators.required],
      return_mileage: [obj && obj.return_mileage ? obj.return_mileage : 0, Validators.required],
      driven_km: [obj && obj.driven_km ? obj.driven_km : null],
      handover_damage: [obj && obj.handover_damage ? obj.handover_damage : '---', Validators.required],
      return_damage: [obj && obj.return_damage ? obj.return_damage : '---', Validators.required],
      handover_fuel_content: [obj && obj.handover_fuel_content ? obj.handover_fuel_content : 'Voll', Validators.required],
      return_fuel_content: [obj && obj.return_fuel_content ? obj.return_fuel_content : 'Voll', Validators.required],
      start_release_date: [obj ? DateHelper.getConvertedDateForInputFull(obj.start_release_date) : null],
      end_release_date: [obj ? DateHelper.getConvertedDateForInputFull(obj.end_release_date) : null],
      rental_start_date: [obj ? DateHelper.getConvertedDateForInputFull(obj.rental_start_date) : null, Validators.required],
      rental_start_time: [obj ? DateHelper.getConvertedTimeForInputFull(obj.rental_start_date) : null, Validators.required],
      rental_end_date: [obj ? DateHelper.getConvertedDateForInputFull(obj.rental_end_date) : null, validateDateIsNotBeforeOtherDate('rental_start_date')],
      rental_end_time: [obj ? DateHelper.getConvertedTimeForInputFull(obj.rental_end_date) : null, Validators.required],
      km_limit: [obj && obj.km_limit ? obj.km_limit : null],
      price_time_unit: [obj && obj.price_time_unit ? obj.price_time_unit : 'Tag'],
      rent_add_on: [obj && obj.rent_add_on ? obj.rent_add_on : null],
      additional_km_price: [obj && obj.additional_km_price ? obj.additional_km_price : null],
      insurance_flat_rate: [obj && obj.insurance_flat_rate ? obj.insurance_flat_rate : null],
      deposit_eur: [obj && obj.deposit_eur ? obj.deposit_eur : null],
      deposit_time_unit: [obj && obj.deposit_time_unit ? obj.deposit_time_unit : null],
      main_driver_id: [obj && obj.main_driver_id ? obj.main_driver_id: null],
      second_driver_id: [obj && obj.second_driver_id ? obj.second_driver_id : null],
      location_id: [obj && obj.location_id? obj.location_id : Number(localStorage.getItem('location_id'))],
      vehicle_id: [obj && obj.vehicle_id ? obj.vehicle_id : null],

      main_driver: [obj && obj.main_driver ? obj.main_driver : null, Validators.required],
      main_driver_form: [CustomerForm.getCustomerForm(formBuilder, obj?.main_driver), [Validators.required]],

      second_driver: [obj && obj.second_driver ? obj.second_driver : null],
      second_driver_form: [CustomerForm.getCustomerForm(formBuilder, obj?.second_driver)],

      vehicle: [obj && obj.vehicle ? obj.vehicle : null, Validators.required],
      vehicle_form: [obj && obj.vehicle ? VehicleForm.getVehicleForm(formBuilder, obj?.vehicle) : null],

      rent_duration: [obj && obj.rent_duration ? obj.rent_duration : null]
    })

    return form;
}

public static getObject(form: FormGroup): Rent {
    let main_driver = CustomerForm.createObject(form.get('main_driver_form').value);
    let second_driver = CustomerForm.createObject(form.get('second_driver_form').value);
    let vehicle = VehicleForm.createObject(form.get('vehicle_form').value);
    
    let obj = new Rent(
      form.get('rent_id').value,
      form.get('rent_typ').value,
      form.get('rent_price_eur').value,
      form.get('rent_status').value,
      form.get('handover_mileage').value,
      form.get('return_mileage').value,
      form.get('handover_damage').value,
      form.get('return_damage').value,
      form.get('handover_fuel_content').value,
      form.get('return_fuel_content').value,
      DateHelper.convertDateStringToTimestamp(form.get('start_release_date').value),
      DateHelper.convertDateStringToTimestamp(form.get('end_release_date').value),
      DateHelper.convertDateStringToTimestamp(form.get('rental_start_date').value + ' ' + form.get('rental_start_time').value),
      DateHelper.convertDateStringToTimestamp(form.get('rental_end_date').value + ' ' + form.get('rental_end_time').value),
      form.get('km_limit').value,
      form.get('price_time_unit').value,
      form.get('rent_add_on').value,
      form.get('additional_km_price').value,
      form.get('insurance_flat_rate').value,
      form.get('deposit_eur').value,
      form.get('deposit_time_unit').value,
      main_driver.id,
      form.get('location_id').value,
      form.get('vehicle_id').value,
      second_driver.id,
    );
   
    return obj;
  }

  public static getFormExchange(formBuilder: FormBuilder): FormGroup {
    let form = formBuilder.group({
      mileage: [null, Validators.required],
      damage: [null, Validators.required],
    })
    return form;
  }
}