import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { VehicleFactory } from 'src/app/buisness-object/vehicle/factory/VehicleFactory';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class VinService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService,
    private authService: AuthenticationService
  ) { }

  public getVehicleByVin(vin: string): Observable<Vehicle> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/vehicle/vin/'+vin, { headers });
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleFactory.jsonFactoryOne(rawData.vehicle, this.authService.mandantId$.getValue() != 2);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }
}
