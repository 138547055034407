<app-collapsible [isCollapsible]="!editMode && viewAuth.view.collapsible" [isContentVisible]="collapsed" *ngIf="!editMode">
  <h3 collapsible-header class="overview_data_box_lbl_title">Typenschein</h3>
  <div collapsible-content>
    <div class="overview_record" *ngIf="showValue('paper_status')">
      <label class="overview_record_lbl">{{changeValueName('paper_status')}}</label>
      <label class="overview_record_value">{{paperStatusValue}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('finanzsperrauskunft')">
      <label class="overview_record_lbl">{{changeValueName('finanzsperrauskunft')}}</label>
      <label class="overview_record_value">{{finanzsperrauskunft}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('finanzsperrauskunft_beantragt')">
      <label class="overview_record_lbl">Finanzsperrauskunft beantragt</label>
      <label class="overview_record_value">{{getInputValueDate(vehicle.finanzsperrauskunft_beantragt)}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('type_certificate_versendet')">
      <label class="overview_record_lbl">Typenschein versendet an Kunden</label>
      <label class="overview_record_value">{{vehicle.type_certificate_versendet ? (vehicle.type_certificate_versendet | date: 'dd.MM.yyyy') : "---"}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('type_certificate_eingestockt')">
      <label class="overview_record_lbl">Typenschein eingestockt am</label>
      <label class="overview_record_value">{{getInputValueDate(vehicle.type_certificate_eingestockt)}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('type_certificate_ausgestockt')">
      <label class="overview_record_lbl">Finanzierung ausgelaufen</label>
      <label class="overview_record_value">{{getInputValueDate(vehicle.type_certificate_ausgestockt)}}</label>
    </div>

    <div class="overview_record" *ngIf="showValue('finanzsperrauskunft_status')">
      <label class="overview_record_lbl">Finanzsperrauskunft</label>
      <label [class.lbl_color_wrapper_red]="vehicle.finanzsperrauskunft_status == 2">{{finanzsperrauskunftStatusValue}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('type_certificate_is_sent')">
      <label class="overview_record_lbl">Typenschein gesendet</label>
      <label class="overview_record_value">{{getBooleanStringValue(vehicle.type_certificate_is_sent)}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('coc')">
      <label class="overview_record_lbl">CoC Papier</label>
      <label class="overview_record_value">{{cocValue}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('austrian_data_extract')">
      <label class="overview_record_lbl">Österr. Datenauszug</label>
      <label class="overview_record_value">{{austrianDataExtractValue}}</label>
    </div>
  </div>
</app-collapsible>

<form *ngIf="editMode && viewAuth.edit.name" [formGroup]="form" class="overview_data_box">
  <div class="overview_data_box_lbl_title">Typenschein</div>
  <app-dropdown-multiple
    *ngIf="showValue('paper_status')"
    [title]="changeValueName('paper_status')"
    [formcontrol]="form.controls['paper_status']"
    [headerText]="paperStatusValue"
    [values]="paperStatusValues"
    [isOptional]="true"
    (submitValueEmitter)="form.controls['paper_status'].setValue($event); getEnumDataOnChange();"
  ></app-dropdown-multiple>
  <div class="input_wrapper" *ngIf="showValue('type_certificate_eingestockt')">
    <label class="overview_record_lbl">Typenschein eingestockt am</label>
    <input #inputDate1
      type="date"
      [max]="today"
      formControlName="type_certificate_eingestockt"
      [class.date-input--has-value]="inputDate1.value != null"
      [class.input_invalid]="submitted && form.controls['type_certificate_eingestockt'].errors">
  </div>
  <div class="input_wrapper" *ngIf="showValue('type_certificate_ausgestockt')">
    <label class="overview_record_lbl">Finanzierung ausgelaufen</label>
    <input #inputDate2
      type="date"
      [max]="today"
      formControlName="type_certificate_ausgestockt"
      [class.date-input--has-value]="inputDate2.value != null"
      [class.input_invalid]="submitted && form.controls['type_certificate_ausgestockt'].errors">
  </div>
  <app-dropdown-multiple
    *ngIf="showValue('finanzsperrauskunft')"
    [title]="'Standort Typenschein'"
    [formcontrol]="form.controls['finanzsperrauskunft']"
    [headerText]="finanzsperrauskunft"
    [values]="finanzsperrauskunftValues"
    [isOptional]="true"
    (submitValueEmitter)="form.controls['finanzsperrauskunft'].setValue($event); getEnumDataOnChange();"
  ></app-dropdown-multiple>
  <app-dropdown-simple
    *ngIf="showValue('type_certificate_is_sent')"
    [title]="'Typenschein gesendet'"
    [formcontrol]="form.get('type_certificate_is_sent')"
    [isOptional]="true"
    (submitValueEmitter)="form.get('type_certificate_is_sent').setValue($event)"
  ></app-dropdown-simple>
  <app-dropdown-multiple
    *ngIf="showValue('coc')"
    [title]="'CoC Papier'"
    [formcontrol]="form.controls['coc']"
    [headerText]="cocValue"
    [values]="cocValues"
    [isOptional]="true"
    (submitValueEmitter)="form.controls['coc'].setValue($event); getEnumDataOnChange();"
  ></app-dropdown-multiple>
  <app-dropdown-multiple
    *ngIf="showValue('austrian_data_extract')"
    [title]="'Österr. Datenauszug'"
    [formcontrol]="form.controls['austrian_data_extract']"
    [headerText]="austrianDataExtractValue"
    [values]="austrianDataExtractValues"
    [isOptional]="true"
    (submitValueEmitter)="form.controls['austrian_data_extract'].setValue($event); getEnumDataOnChange();"
  ></app-dropdown-multiple>
</form>

