import { UntypedFormGroup } from '@angular/forms';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { BaseComponent } from 'src/app/view/BaseComponent';
import { formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-contract-page-three',
  templateUrl: './contract-page-three.component.html',
  styleUrls: ['./contract-page-three.component.css']
})
export class ContractPageThreeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;


  public mechanical_conditionValue;
  public body_conditionValue;
  public paint_conditionValue;
  public interior_conditionValue;
  public electronic_conditionValue;
  public conditionValues = [];
  public minDate = formatDate(new Date(),'yyyy-MM-dd','de');

  constructor(
    private dService: DialogService,
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.conditionValues = this.getEnumValues('mechanical_condition');
    this.mechanical_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["mechanical_condition"].value);
    this.body_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["body_condition"].value);
    this.paint_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["paint_condition"].value);
    this.interior_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["interior_condition"].value);
    this.electronic_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["electronic_condition"].value);
    this.getOverallCondition();
  }


  getEnumDataOnChange() {
    this.mechanical_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["mechanical_condition"].value);
    this.body_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["body_condition"].value);
    this.paint_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["paint_condition"].value);
    this.interior_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["interior_condition"].value);
    this.electronic_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["electronic_condition"].value);
    this.getOverallCondition();
  }

  ngAfterViewInit(): void {
    this.form.controls["mechanical_condition"].valueChanges.subscribe((changes) => { this.getOverallCondition(); });
    this.form.controls["body_condition"].valueChanges.subscribe((changes) => { this.getOverallCondition(); });
    this.form.controls["paint_condition"].valueChanges.subscribe((changes) => { this.getOverallCondition(); });
    this.form.controls["interior_condition"].valueChanges.subscribe((changes) => { this.getOverallCondition(); });
    this.form.controls["electronic_condition"].valueChanges.subscribe((changes) => { this.getOverallCondition(); });
  }

  ngOnDestroy(): void {

  }

  get f() {
    return this.form.controls;
  }

  getCondition(condition: number): string {
    if(condition){
      return this.conditionValues[condition-1];
    }
    return 'Bitte wählen...'
  }

  getOverallCondition() {
    let average = (this.f.mechanical_condition.value +
                  this.f.body_condition.value +
                  this.f.paint_condition.value +
                  this.f.interior_condition.value +
                  this.f.electronic_condition.value) / this.conditionValues.length;
    if(average < 2) this.f.total_condition.setValue('Klasse 1');
    else if(average < 3) this.f.total_condition.setValue('Klasse 2');
    else if(average < 4) this.f.total_condition.setValue('Klasse 3');
    else if(average < 5) this.f.total_condition.setValue('Klasse 4');
    else if(average >= 5) this.f.total_condition.setValue('Klasse 5');
  }

  getColor(): string {
    if(this.f.total_condition.value == 'Klasse 1') return 'lbl_color_wrapper_green';
    if(this.f.total_condition.value == 'Klasse 2') return 'lbl_color_wrapper_green';
    if(this.f.total_condition.value == 'Klasse 3') return 'lbl_color_wrapper_yellow';
    if(this.f.total_condition.value == 'Klasse 4') return 'lbl_color_wrapper_orange';
    if(this.f.total_condition.value == 'Klasse 5') return 'lbl_color_wrapper_red';
  }

  openInfoCondition() {
    this.dService.openContractCarCondition(undefined);
  }
}
