import { Drive } from 'src/app/buisness-object/drives/Drive';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-list-drives-element',
  templateUrl: './list-drives-element.component.html',
  styleUrls: ['./list-drives-element.component.css']
})
export class ListDrivesElementComponent implements OnInit {
  @Input() drive: Drive;
  public showActions = false;
  @ViewChild('action_container') action_container: ElementRef;
  public listener: () => void;

  @Output() openDetailsEmitter = new EventEmitter<Drive>();
  @Output() deleteEmitter = new EventEmitter<Drive>();
  @Output() openCreatePdfEmitter = new EventEmitter<Drive>();
  @Output() selectionPDFEmitter = new EventEmitter<Drive>();
  @Output() selectionPseudoSignatureEmitter = new EventEmitter<Drive>();

  constructor(
    private renderer: Renderer2,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  getListElement(): string {
    return !this.authService.isHoedl() ? "list_element" : "list_element_hoedl";
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showActions){
        if(!this.action_container.nativeElement.contains(e.target)){
          this.showActions = false;
        }
      }
    })
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  // getDuration(start: number, end: number): number {
  //   let s = new Date(start);
  //   let e = new Date(end);
  //   let sMin = (s.getHours() * 60) + s.getMinutes();
  //   let eMin = (e.getHours() * 60) + e.getMinutes();
  //   return eMin - sMin;
  // }

  setIcon():string {
    if(this.showActions){
      return '../../../../../assets/icon-more-white.png';
    }
    return '../../../../../assets/icon-more.png'
  }

  public cutString(str: string, len: number): string {
    if(str){
        if(str.length > len){
            return str.slice(0,len) + ' ...'
        } else {
            return str;
        }
    } else {
        return '';
    }
  }
}
