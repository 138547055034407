import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { OverviewConfiguration } from 'src/app/buisness-object/overview/OverviewConfiguration';
import { VehicleBodyColor } from 'src/app/buisness-object/vehicle/basic/VehicleBodyColor';
import { VehicleBodyTyp } from 'src/app/buisness-object/vehicle/basic/VehicleBodyTyp';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { VehicleDrive } from 'src/app/buisness-object/vehicle/basic/VehicleDrive';
import { VehicleFuel } from 'src/app/buisness-object/vehicle/basic/VehicleFuel';
import { VehicleTransmission } from 'src/app/buisness-object/vehicle/basic/VehicleTransmission';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { BaseComponent } from '../../BaseComponent';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-overview-container',
  templateUrl: './overview-container.component.html',
  styleUrls: ['./overview-container.component.css']
})
export class OverviewContainerComponent extends BaseComponent implements OnInit  {
  @Input() activeSubMenu: SubMenu;
  @Input() selectedVehicle: Vehicle;
  @Input() vehicleDataInWork: boolean;
  @Input() submitted: boolean;
  @Input() brands: VehicleBrand[];
  @Input() drives: VehicleDrive[];
  @Input() fuels: VehicleFuel[];
  @Input() transmissions: VehicleTransmission[];
  @Input() bodyColors: VehicleBodyColor[];
  @Input() bodyTyps: VehicleBodyTyp[];
  @Input() categories: VehicleCategory[];
  @Input() editMode: boolean;
  @Input() formGroupVehicle: FormGroup;
  @Input() formGroupPriceSheet: FormGroup;
  @Input() selectedDetailsTab: number;
  @Input() updateSuccess: BehaviorSubject<boolean>;
  @Output() updateVehicleTitle = new EventEmitter<string>();

  public ersatzTeileValues = [];
  public ersatzTeileValue;
  public imageComponentDesign = 1;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.imageComponentDesign = this.getImageComponentDesign();
    this.ersatzTeileValues = this.getEnumValues('ersatzteile');
    this.ersatzTeileValue = this.getEnumValue('ersatzteile', this.selectedVehicle?.ersatzteile);
  }

  getEnumDataOnChange() {
    this.ersatzTeileValue = this.getEnumValue('ersatzteile', this.formGroupVehicle?.controls['ersatzteile'].value);
  }

  allowToSeeColumn(screen: number, column_id: number): boolean {
    let auths = this.authService.getViewAuth().filter((auth) => auth.id == this.activeSubMenu.sub_menu_id);
    if(auths.length > 0){
      let auth = auths[0];
      if(auth.screens.length > screen && this.authService.getViewRepresentations().length > screen) {
        let views = this.editMode ? auth.screens[screen].edit : auth.screens[screen].view;
        let viewRep = this.authService.getViewRepresentations()[screen][column_id];
        for(let view of views) {
          if(viewRep.includes(view.name)) {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  allowToSeeDeep(screen: number, boxName: string|undefined): boolean {
    let auths = this.authService.getViewAuth().filter((auth) => auth.id == this.activeSubMenu.sub_menu_id);
    let isFallback = false;
    let fallBackAuth = this.authService.getFallbackViewAuth();
    if(auths.length == 0){
      auths = [fallBackAuth];
      isFallback = true;
    };
    if(auths.length > 0){
      let auth = auths[0];
      if(auth.screens.length > screen) {
        let views = this.editMode? auth.screens[screen].edit : auth.screens[screen].view;
        if(views.filter((v) => v.name == boxName).length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  allowToEdit():boolean {

    if(this.editMode && this.activeSubMenu.sub_menu_id == 11){
      return true;
    }
    return false;
  }

  getImageComponentDesign(): number {
    return 1; // default Reihe mit 3 Bildern
  }
}
