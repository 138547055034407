import { environment } from 'src/environments/environment';
import { Employee } from './../../buisness-object/employee/Employee';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { Thread } from 'src/app/buisness-object/message/Thread';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Message } from 'src/app/buisness-object/message/Message';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { LastRoute } from 'src/app/helpers/Enums';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit, OnDestroy {
  public threads: Thread[] = [];
  public threadsFiltered: Thread[] = [];
  public threadsArchived: Thread[] = [];
  public employees: Employee[] = [];
  public customers: Customer[] = [];
  public userId: number;
  public currentUser: Employee;
  public threadForm: UntypedFormGroup;
  public selectedThread: Thread;
  public submitted = false;
  public pageHandler: PageHandler;
  public pageInfo: string;
  public sliceTo: number;
  public sliceFrom: number;
  public surface = 1;
  public showArchived = false;
  public routeSubscription: Subscription;
  public dialogQuerySubsription: Subscription;
  public routeParamSub: Subscription;
  public customerId: number;
  public isLoaded = false;
  public vehicles: Vehicle[] = [];
  public redirectVehicleId: number;
  public redirectTo: any;
  public redirectId: number;
  public reloadInterval: any;
  public filter = {
    users: [],
    user: undefined,
    vehicles: [],
    vehicle: undefined,
    customers: [],
    customer: undefined,
    urgency: undefined,
    typ: undefined,
    is_set: false,
    tab: 1
  }
  public sortObject = {
    sort_after_value: 1,
    sort_asc: false,
    sorting_is_set: false
  }

  constructor(
    private tService: ThreadService,
    private eService: EmployeeService,
    private cService: CustomerService,
    private formBuilder: UntypedFormBuilder,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private dService: DialogService,
    private locationRoute: Location,
    private vService: VehicleService
  ) { }

  ngOnInit(): void {
    this.userId = Number(localStorage.getItem('employee_id'));
    this.getDataServerside();
    this.buildFormGroup();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.reloadInterval) clearInterval(this.reloadInterval);
  }

  getDataServerside() {
    forkJoin({
      openThreads: this.tService.getOpenThreads(),
      employees: this.eService.getEmployeesServerside(),
      customers: this.cService.getCustomer(),
      archivedThreads: this.tService.getArchived(),
      vehicles: this.vService.getVehicle()
    }).subscribe((result) => {
      if(result){
        this.employees = result.employees;
        this.customers = result.customers;
        this.vehicles = result.vehicles;
        for(let e of this.employees){
            if(e?.id == this.userId){
              this.currentUser = e;
              break;
            }
        }
        this.mergeData(result.openThreads.concat(result.archivedThreads)).then((mergedThreads) => {
          if(mergedThreads){
            this.threads = mergedThreads;
            this.threadsFiltered = mergedThreads;
            this.getFilterValues(this.threads);
            this.applyFilter();
            this.setPages();
            this.setRouteListener();
            this.isLoaded = true;
          }
        });
      }
    })
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_create') this.cancelCreate();
      }
    });
  }

  async mergeData(threads: Thread[]): Promise<Thread[]> {
    return new Promise<any>((resolve, reject) => {
      if(threads.length >= 0){
        for(let t of threads){
          for(let e of this.employees){
            if(t.creator == e.id){
              t.employee = e;
            }
            for(let msg of t.messages){
              if(msg.employeeId == e.id){
                msg.employee = e;
              }
            }
          }
          if(t.typ == 1){
            for(let vehicle of this.vehicles){
              if(t.typId == vehicle.id){
                t.vehicle = vehicle;
                break;
              }
            }
          }
          if(t.typ == 2){
            for(let customer of this.customers){
              if(t.typId == customer.id){
                t.customer = customer;
                break;
              }
            }
          }
        }
      }
      resolve(threads);
    })
  }

  async getFilterValues(threads: Thread[]) {
    this.filter.users = [];
    this.filter.customers = [];
    this.filter.vehicles = [];
    for(let t of threads){
      let indexU = this.filter.users.findIndex(u => u?.id == t.employee?.id);
      if(indexU == -1) this.filter.users.push(t?.employee);
      if(t.vehicle){
        let indexV = this.filter.vehicles.findIndex(v => v?.id == t.vehicle?.id);
        if(indexV == -1) this.filter.vehicles.push(t?.vehicle);
      }
      if(t.customer){
        let indexC = this.filter.customers.findIndex(c => c?.id == t.customer?.id);
        if(indexC == -1) {
          this.filter.customers.push(t?.customer);
        }
      }
    }
  }

  mergeEmployee(thread: Thread): Thread {
    for(let e of this.employees){
      if(e.id == thread.creator){
        thread.employee = e;
        break;
      }
    }
    return thread;
  }

  applySorting() {
    if(this.sortObject.sort_after_value == 1){
      this.threadsFiltered.sort((a,b) => {
        if(a.createdDate > b.createdDate) return this.sortObject.sort_asc ? 1 : -1;
        if(a.createdDate < b.createdDate) return this.sortObject.sort_asc ? -1 : 1;
        return 0;
      })
    }
  }

  buildFormGroup() {
    this.threadForm = this.formBuilder.group({
      typ: [null, Validators.required],
      typ_object: [null],
      message: ['', Validators.required],
    })
  }

  setPages() {
    this.pageHandler = new PageHandler(this.threadsFiltered.length);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }

  pageHandling(next: boolean) {
    this.pageHandler.action(next);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }

  scrollTo() {
    const elementList = document.querySelectorAll('#listcontainer');
    const element = elementList[0] as HTMLElement;
    if(element){
      element.scrollTop = 0;
    }
  }

  // async filterThreads() {
  //   this.threadsFiltered = [];
  //   switch (filter) {
  //     case 1: this.threadsFiltered = this.threads.filter((thread) => {return thread.status == 0});  this.showArchived = false; break;
  //     case 2: this.threadsFiltered = this.threads.filter((thread) => {return thread.creator == this.currentUser.id && thread.resolved == 0;}); this.showArchived = false; break;
  //     case 3: this.threadsFiltered = this.threadsArchived; this.showArchived = true; break;
  //     default: break;
  //   }
  //   await this.getFilterValues(this.threadsFiltered);
  //   if(this.filterDetail.is_set) this.applyFilter(false);
  //   this.setPages();
  //   //this.scrollTo();
  // }

  async applyFilter() {
    this.threadsFiltered = [];
    this.threadsFiltered = this.threads.filter(thread => {
      let found = true;
      if(!this.includesTab(thread)) found = false;
      if(!this.includesTyp(thread)) found = false;
      if(!this.includesUser(thread)) found = false;
      if(!this.includesVehicle(thread)) found = false;
      if(!this.includesCustomer(thread)) found = false;
      if(!this.includesUrgency(thread)) found = false;
      return found;
    });
    this.applySorting();
    this.setPages();
  }

  includesTab(thread: Thread): boolean {
    if(!this.filter.tab) return true;
    else if(this.filter.tab == 3 && thread.archived) return true;
    else if(this.filter.tab == 2 && !thread.archived && thread.creator == this.currentUser.id) return true;
    else if(this.filter.tab == 1 && !thread.archived) return true;
    else return false;
  }
  includesTyp(thread: Thread): boolean {
    if(!this.filter.typ) return true;
    else if(thread.typ == this.filter.typ-1) return true;
    else return false;
  }
  includesUser(thread: Thread): boolean {
    if(!this.filter.user) return true;
    else if(thread.creator == this.filter.user.id) return true;
    else return false;
  }
  includesVehicle(thread: Thread): boolean {
    if(this.filter.typ == 2){
      if(!this.filter.vehicle) return true;
      else if(thread.vehicle?.id == this.filter.vehicle.id) return true;
      else return false;
    } else return true;
  }
  includesCustomer(thread: Thread): boolean {
    if(this.filter.typ == 3){
      if(!this.filter.customer) return true;
      else if(thread.customer?.id == this.filter.customer.id) return true;
      else return false;
    } else return true;
  }
  includesUrgency(thread: Thread): boolean {
    if(!this.filter.urgency) return true;
    else if(thread.urgency == this.filter.urgency-1) return true;
    else return false;
  }

  removeFilterValue(str: string) {
    if(str === 'user') this.filter.user = null;
    else if(str === 'vehicle') this.filter.vehicle = null;
    else if(str === 'customer') this.filter.customer = null;
    else if(str === 'urgency') this.filter.urgency = null;
    else if(str === 'typ') this.filter.typ = null;
    this.applyFilter();
  }

  searchThreads(value: string) {
    // let threads: Thread[] = [];
    // if(this.filter == 1){
    //   threads = this.threads.filter((thread) => {return thread.status == 0});
    // } else if(this.filter == 2){
    //   threads = this.threads.filter((thread) => {return thread.creator == this.currentUser.id && thread.resolved == 0;});
    // } else if(this.filter == 3){
    //   threads = this.threadsArchived;
    // }
    let tempThreads = this.threadsFiltered;
    this.threadsFiltered = tempThreads.filter((thread) => {
      if(value && value.length > 0){
        this.threadsFiltered = [];
        return thread.employee.firstName.toLowerCase().includes(value.toLowerCase()) ||
              thread.employee.lastName.toLowerCase().includes(value.toLowerCase()) ||
              thread.customer?.companyName.toLowerCase().includes(value.toLowerCase()) ||
              thread.customer?.contactPerson.firstName.toLowerCase().includes(value.toLowerCase()) ||
              thread.customer?.contactPerson.lastName.toLowerCase().includes(value.toLowerCase()) ||
              (thread.customer?.contactPerson.firstName.toLowerCase() + ' ' + thread.customer?.contactPerson.lastName.toLowerCase()).includes(value.toLowerCase());
      } else {
        return tempThreads;
      }
    });
    this.setPages();
  }

  openCreate(typ_id?: number) {
    if(typ_id){
      this.customerId = typ_id;
      this.threadForm.controls.typ_object.setValue(typ_id);
    }
    //this.locationRoute.replaceState('/messages/create');
    this.surface = 2;
  }

  cancelCreateAction() {
    this.dService.openQuery(
      {
        title: 'Vorgang abbrechen',
        message: 'Sind Sie sicher, dass Sie den Vorgang abbrechen möchten?',
        btn_cancel_txt: 'Nein',
        btn_submit_txt: 'Ja',
        typ: 'cancel_create',
        submit_value: 0,
      }
    );
  }

  cancelCreate() {
    this.selectedThread = null;
    this.customerId = null;
    this.submitted = false;
    //this.locationRoute.replaceState('/messages');
    this.surface = 1;
  }

  closeDetails() {
    if(this.redirectTo == LastRoute.VEHICLES){
      this.router.navigate(['cars'], { queryParams: { vehicleId: this.redirectId } });
      this.redirectId = null;
      this.redirectTo = null;
    } else if(this.redirectTo == LastRoute.CUSTOMERS){
      this.router.navigate(['customers'], { queryParams: { customerId: this.redirectId } });
      this.redirectId = null;
      this.redirectTo = null;
    } else {
      this.selectedThread = null;
      this.customerId = null;
      this.submitted = false;
      this.locationRoute.replaceState('/messages')
      this.surface = 1;
    }
  }

  createThread() {
    this.submitted = true;
    if(this.threadForm.valid){
      if((this.threadForm.controls.typ.value == 1 || this.threadForm.controls.typ.value == 2) && !this.threadForm.controls.typ_object.value){
        return;
      }
    } else {
      return;
    }
    let thread = new Thread(
      0,
      Number(localStorage.getItem('location_id')),
      this.currentUser.id,
      this.threadForm.controls.typ.value,
      0,
      0,
      true,
      new Date().getTime(),
      this.threadForm.controls.typ_object.value ? this.threadForm.controls.typ_object.value : 0,
      0,
      [new Message(0,this.currentUser.id,this.threadForm.controls.message.value,new Date().getTime())]
    )
    this.tService.createThread(thread).subscribe((t) => {
      if(t){
        if(t.typ == 1){
          for(let v of this.vehicles){
            if(v.id == t.typId){
              t.vehicle = v;
              break;
            }
          }
        }
        else if(t.typ == 2){
          for(let c of this.customers){
            if(c.id == t.typId){
              t.customer = c;
              break;
            }
          }
        }
        t = this.mergeEmployee(t);
        this.threads.push(t);
        this.threadsFiltered = this.threads;
        this.applyFilter();
        this.setPages();
        this.submitted = false;
        this.threadForm.reset();
        this.customerId = null;
        if(this.redirectTo == LastRoute.VEHICLES){
          this.router.navigate(['cars', this.redirectId, this.selectedThread.threadId])
          this.redirectId = null;
          this.redirectTo = null;
        } else if(this.redirectTo == LastRoute.CUSTOMERS){
          this.router.navigate(['customers', this.redirectId])
          this.redirectId = null;
          this.redirectTo = null;
        }
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Ticket erstellt.',
          success: true
        });
        this.surface = 1;
      }
    })
  }

  resolveThread(thread: Thread) {
    this.tService.resolveThread(thread.threadId).subscribe((t) => {
      if(t){
        this.router.navigate(['messages']);
        let index = this.threads.findIndex(t => t.threadId == thread.threadId);
        if(index >= 0){
          thread.resolved = 1;
          this.threads.splice(index,1);
          this.threadsFiltered = this.threads;
          this.threadsArchived.push(thread);
          this.applyFilter();
          this.tService.notify_request$.next(this.tService.notify_request$.getValue() - 1)
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Ticket aufgelöst.',
            success: true
          });
          this.surface = 1;
        }
      }
    })
  }

  setRouteListener() {
    let params = this.activateRoute.snapshot.queryParams;
    const ticketId = params['ticketId'];
    const vehicleId = params['vehicleId'];
    const customerId = params['customerId'];
    if(ticketId){
      this.redirectId = vehicleId ? vehicleId : customerId;
      this.redirectTo = vehicleId ? LastRoute.VEHICLES : LastRoute.CUSTOMERS;
      for(let t of this.threads){
        if(t.threadId == ticketId){
          this.openDetails(t);
          break;
        }
      };
    }
    this.routeSubscription = this.activateRoute.url.subscribe((url) => {
          switch (url[1]?.path) {
            case 'create':
              if(this.activateRoute.snapshot.queryParams.customerId) {
                this.redirectTo = LastRoute.CUSTOMERS;
                this.redirectId = this.activateRoute.snapshot.queryParams.customerId;
                this.openCreate(this.activateRoute.snapshot.queryParams.customerId);
              } else if(this.activateRoute.snapshot.queryParams.vehicleId) {
                this.redirectTo = LastRoute.VEHICLES;
                this.redirectId = this.activateRoute.snapshot.queryParams.vehicleId;
                this.openCreate(this.activateRoute.snapshot.queryParams.vehicleId);
              } break;
            case undefined:
              if(this.activateRoute.snapshot.queryParams.threadId){
                          let found = false;
                          for(let data of this.threads){
                            if(data.threadId == this.activateRoute.snapshot.queryParams.threadId){
                              this.openDetails(data);
                              found = true;
                              break;
                            }
                          };
                          if(!found){
                            for(let data of this.threadsArchived){
                              if(data.threadId == this.activateRoute.snapshot.queryParams.threadId){
                                this.openDetails(data);
                                found = true;
                                break;
                              }
                            };
                          }

                        };
                        break;
            default: break;
        }
    });
  }

  // routeListener() {
  //   this.subRout = this.route.params.subscribe((params) => {
  //     if(params.threadId){
  //       if(params.threadId.includes('create')){
  //         this.customerId = Number(params.threadId.split(':')[1]);
  //         this.openCreate();
  //       } else {
  //         let threads: Thread[] = this.threads.concat(this.threadsArchived);
  //         for(const thread of threads){
  //           if(thread.threadId == Number(params.threadId)){
  //             this.selectedThread = thread;
  //             this.surface = 3;
  //             break;
  //           }
  //         }
  //       }
  //     } else {
  //       this.selectedThread = null;
  //     }
  //   })
  // }

  openDetails(thread: Thread) {
    this.selectedThread = thread;
    this.surface = 3;
    this.locationRoute.replaceState('/messages?threadId='+thread.threadId);
    //this.router.navigate(['messages/' + thread.threadId]);
  }

  createMessage(msg: string) {
    this.tService.createMesaage(msg, this.selectedThread.threadId).subscribe((thread) => {
      if(thread){
        let newMsg = thread.messages[thread.messages.length - 1];
        newMsg.employee = this.currentUser;
        this.selectedThread.messages.push(thread.messages[thread.messages.length - 1])
        // let index = this.threads.findIndex(t => t.threadId == thread.threadId);
        // if(index > -1){
        //   thread = this.mergeData([thread])[0];
        //   this.threads[index] = thread;
        //   this.selectedThread = thread;
        //   this.applyFilter();

        // }
        const content = document.getElementById('contenteditable');
        if(content) content.innerText = '';
      }
    })
  }

  resetFilter() {
    this.filter.user = null;
    this.filter.customer = null;
    this.filter.vehicle = null;
    this.filter.typ = null;
    this.filter.urgency = null;
    this.applyFilter();
  }
}
