import { Component, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-element-interface',
  templateUrl: './element-interface.component.html',
  styleUrls: ['./element-interface.component.css']
})
export class ElementInterfaceComponent implements OnInit {
  @Input() interface: any;
  public auth = Number(localStorage.getItem('auth'));
  public location_id = Number(localStorage.getItem('location_id'))

  constructor(
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  getLogo(): string {
    switch (this.interface.interface_id) {
      case 1: return './assets/logo-dark.png'; break;
      case 2: return './assets/zweispurig-logo.png'; break;
      case 3: return './assets/auto24-logo.svg'; break;
      case 4: return './assets/c4y-logo.png'; break;
      default: return ''; break;
    }
  }
}
