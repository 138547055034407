import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BaseBoxComponent } from 'src/app/view/BaseBoxComponent';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css']
})
export class DescriptionComponent extends BaseBoxComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() vehicle: Vehicle;
  @Input() formGroupVehicle: UntypedFormGroup;
  @Input() submitted: boolean;

  @ViewChild('textField') textField: ElementRef;
  @ViewChild('textField_2') textField_2: ElementRef;
  @ViewChild('textField_3') textField_3: ElementRef;
  @ViewChild('textField_4') textField_4: ElementRef;
  @ViewChild('description') description: ElementRef;
  @ViewChild('description_2') description_2: ElementRef;
  @ViewChild('description_3') description_3: ElementRef;
  @ViewChild('description_4') description_4: ElementRef;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }


  ngOnInit(): void {
    this.viewTitle = "description";
    this.init();
  }

  ngAfterViewInit(): void {
    if(this.description && this.description_2){
      this.description.nativeElement.innerHTML = this.vehicle.description ? this.vehicle.description : '---';
      this.description_2.nativeElement.innerText = this.vehicle.internal_description ? this.vehicle.internal_description : '---';
      if(this.description_3) this.description_3.nativeElement.innerText = this.vehicle.accounting_description ? this.vehicle.accounting_description : '---';
      if(this.description_4) this.description_4.nativeElement.innerHTML = this.vehicle.addEquipment ? this.vehicle.addEquipment : '---';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if(this.editMode && this.textField){
        this.textField.nativeElement.innerText = this.vehicle.description ? this.vehicle.description : '';
        this.textField_2.nativeElement.innerText = this.vehicle.internal_description ? this.vehicle.internal_description : '';
        if(this.textField_3) this.textField_3.nativeElement.innerText = this.vehicle.accounting_description ? this.vehicle.accounting_description : '';
        if(this.textField_4) this.textField_4.nativeElement.innerText = this.vehicle.addEquipment ? this.vehicle.addEquipment : '';
      } else {
        this.description.nativeElement.innerHTML = this.vehicle.description ? this.vehicle.description : '---';
        this.description_2.nativeElement.innerText = this.vehicle.internal_description ? this.vehicle.internal_description : '---';
        if(this.description_3) this.description_3.nativeElement.innerText = this.vehicle.accounting_description ? this.vehicle.accounting_description : '---';
        if(this.description_4) this.description_4.nativeElement.innerHTML = this.vehicle.addEquipment ? this.vehicle.addEquipment : '---';
      }
    }, 200);
  }
}
