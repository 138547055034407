import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class LastUpdateService {

  constructor(private http: HttpClient, private lService: LoginService, private notifyService: DialogService) { }

  getLastUpdate(type: number): Observable<number> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/update/' + type, {headers});
    return observable.pipe(
      map((rawTimestamp: any) => {
        return Number(rawTimestamp.last_updated);
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.notifyService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(0);
      })
    );
  }
}
