import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({name: 'safeHtmlTxt'})
export class SafeHtmlTxt implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer
    ){}

    transform(txt: string) {
        return this.sanitizer.bypassSecurityTrustHtml(txt);
    }
}