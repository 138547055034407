import { formatDate } from '@angular/common';
import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DropdownHighlighter } from 'src/app/buisness-object/dropdown/DropdownHighlighter';
import { VehicleBodyColor } from 'src/app/buisness-object/vehicle/basic/VehicleBodyColor';
import { VehicleBodyTyp } from 'src/app/buisness-object/vehicle/basic/VehicleBodyTyp';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { VehicleDrive } from 'src/app/buisness-object/vehicle/basic/VehicleDrive';
import { VehicleFuel } from 'src/app/buisness-object/vehicle/basic/VehicleFuel';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { VehicleTransmission } from 'src/app/buisness-object/vehicle/basic/VehicleTransmission';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-basic-data-container',
  templateUrl: './basic-data-container.component.html',
  styleUrls: ['./basic-data-container.component.css'],
  animations: [DropdownIconArrowAnimation]
})
export class BasicDataContainerComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  public models: VehicleModel[] = [];
  public powerInHp = true;
  public multiplierPower = 1;
  public titleArray: string[] = ['','',''];
  public counterVersion = 80;
  public maxDate;
  public minDate;

  public showDropdownOne = false;
  public showDropdownTwo = false;
  public showDropdownThree = false;
  public showDropdownFour = false;
  public showDropdownFive = false;
  public showDropdownSix = false;
  public showDropdownSeven = false;
  public showDropdownEight = false;
  public showDropdownNine = false;

  public selectedBrand: string;
  public selectedModel: string;
  public selectedDrive: string;
  public selectedFuel: string;
  public selectedBodyTyp: string;
  public selectedColor: string;
  public selectedCategory: string;
  public selectedTransmission: string;

  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  @ViewChild('dropdownTwo') dropdownTwo: ElementRef;
  @ViewChild('dropdownThree') dropdownThree: ElementRef;
  @ViewChild('dropdownFour') dropdownFour: ElementRef;
  @ViewChild('dropdownFive') dropdownFive: ElementRef;
  @ViewChild('dropdownSix') dropdownSix: ElementRef;
  @ViewChild('dropdownSeven') dropdownSeven: ElementRef;
  @ViewChild('dropdownEight') dropdownEight: ElementRef;
  @ViewChild('dropdownNine') dropdownNine: ElementRef;
  public listenerOnClick: () => void;
  public listenerOnKeydown: () => void;
  public highlighter: DropdownHighlighter = new DropdownHighlighter();
  public keyDownItem: HTMLElement;

  @Input() currentPageCreate: number;
  @Input() formGroup: FormGroup;
  @Input() submitted: boolean;
  @Input() brands: VehicleBrand[];
  @Input() drives: VehicleDrive[];
  @Input() fuels: VehicleFuel[];
  @Input() transmissions: VehicleTransmission[];
  @Input() bodyColors: VehicleBodyColor[];
  @Input() bodyTyps: VehicleBodyTyp[];
  @Input() categories: VehicleCategory[];

  @Output() changePageEmitter = new EventEmitter<number>();
  @Output() updateVehicleTitle = new EventEmitter<string>();

  public brandValues = [];
  public modelValues = [];
  public bodytypValues = [];
  public categorieValues = [];
  public fuelsValues = [];
  public drivesValues = [];
  public transmissionsValues = [];
  public bodyColorValues = [];

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    let date = new Date();
    date.setMonth(date.getMonth() - 24);
    this.maxDate = formatDate(date,'yyyy-MM-dd','de');
    if(this.f.brand.valid){
      this.titleArray[0] = this.f.brand.value.makeName + ', ';
      this.titleArray[1] = this.f.model.value.name  + ', ';
      this.titleArray[2] = this.f.model.value.version;
      if(this.f.brand.value.models.length == 1){
        for(let brand of this.brands){
          if(this.f.brand.value.id == brand.id){
            this.models = brand.models;
            break;
          }
        }
      } else {
        this.models = this.f.brand.value.models;
      }
    }
    this.setDropdownValues();
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'brand'){
      let index = this.brands.findIndex(v => v.id == id);
      if(index > -1) this.setBrand(this.brands[index]);
    } else if(value === 'model'){
      let index = this.models.findIndex(v => v.id == id);
      if(index > -1) this.setModel(this.models[index]);
    } else if(value === 'bodytyp'){
      let index = this.bodyTyps.findIndex(v => v.id == id);
      if(index > -1) this.f.bodyTyp.setValue(this.bodyTyps[index]);
    } else if(value === 'category'){
      let index = this.categories.findIndex(v => v.id == id);
      if(index > -1){
        this.f.category.setValue(this.categories[index]);
        this.f.firstRegistrationDate.setValue(null);
        this.calcRegistrationDate();
      }
    } else if(value === 'fuel'){
      let index = this.fuels.findIndex(v => v.id == id);
      if(index > -1) this.f.fuel.setValue(this.fuels[index]);
    } else if(value === 'drive'){
      let index = this.drives.findIndex(v => v.id == id);
      if(index > -1) this.f.drive.setValue(this.drives[index]);
    } else if(value === 'transmission'){
      let index = this.transmissions.findIndex(v => v.id == id);
      if(index > -1) this.f.transmission.setValue(this.transmissions[index]);
    } else if(value === 'bodyColor'){
      let index = this.bodyColors.findIndex(v => v.id == id);
      if(index > -1) this.f.bodyColor.setValue(this.bodyColors[index]);
    }
  }

  setDropdownValues() {
    for(let v of this.fuels){
      this.fuelsValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.drives){
      this.drivesValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.transmissions){
      this.transmissionsValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.brands){
      this.brandValues.push({
        id: v.id,
        name: v.makeName
      })
    }
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.bodyTyps){
      this.bodytypValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.categories){
      this.categorieValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.bodyColors){
      this.bodyColorValues.push({
        id: v.id,
        name: v.name
      })
    }
  }

  highlightElement(dropdownValues: any[], key: string) {
    for(let value of dropdownValues){
      if(value.makeName[0].toLowerCase() == key){
        if(this.keyDownItem){
          this.keyDownItem.className = 'dropdown_data_body_element';
        }
        this.keyDownItem = document.getElementById('dropdown_value_' + value.id);
        if(this.keyDownItem){
          this.keyDownItem.className ='dropdown_data_body_element_hover_status';
          this.keyDownItem.scrollIntoView({block: "start"});
        }
        break;
      }
    }
  }

  onMouseEnterDropdown() {
    if(this.keyDownItem){
      this.keyDownItem.className = 'dropdown_data_body_element';
      this.keyDownItem = null;
    }
  }

  getDotIcon(page: number): string {
    if(page == (this.currentPageCreate - 1)){
      return './assets/dot_full.png';
    }
    return './assets/dot_empty.png';
  }

  nextPage() {
    this.changePageEmitter.emit(this.currentPageCreate += 1);
  }

  fillFormGroup() {
  }

  get f() {
    return this.formGroup.controls;
  }

  setBrand(brand: VehicleBrand) {
    this.f.brand.setValue(brand);
    this.models = brand.models;
    this.titleArray[0] = brand.makeName + ', ';
    this.titleArray[1] = '';
    this.modelValues = [];
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
    this.f.model.setValue(null)
    this.updateVehicleTitle.emit(this.titleArray[0] + this.titleArray[1] + this.titleArray[2]);
  }

  setModel(model: VehicleModel) {
    this.f.model.setValue(model);
    this.titleArray[1] = model.name + ', ';
    this.updateVehicleTitle.emit(this.titleArray[0] + this.titleArray[1] + this.titleArray[2]);
  }

  setVersionTitle() {
    let count = 80 - this.f.version?.value.length;
    this.counterVersion = count >= 0 ? count : 0;
    if(this.counterVersion >= 0){
      this.counterVersion = 80 - this.f.version?.value.length;
      this.titleArray[2] = this.f.version?.value;
      this.updateVehicleTitle.emit(this.titleArray[0] + this.titleArray[1] + this.titleArray[2]);
    }
  }

  setDrive(drive: VehicleDrive) {
    this.f.drive.setValue(drive);
  }

  setTransmission(transmission: VehicleTransmission) {
    this.f.transmission.setValue(transmission);
  }

  setFuel(fuel: VehicleFuel) {
    this.f.fuel.setValue(fuel);
  }

  setBodyTyp(typ: VehicleBodyTyp) {
    this.f.bodyTyp.setValue(typ);
  }

  setCategory(cat: VehicleCategory) {
    this.f.category.setValue(cat);
    this.calcRegistrationDate()
  }

  setColor(color: VehicleBodyColor) {
    this.f.bodyColor.setValue(color);
  }

  setAccident(name: string) {
    if(name == 'Ja'){
      this.f.unfall.setValue(true);
    } else {
      this.f.unfall.setValue(false);
    }
  }

  setManufacturDate(date: number) {
    this.f.manufactureDate.setValue(date);
  }

  setRegistrationDate(date: number) {
    this.f.firstRegistrationDate.setValue(date);
  }

  changePowervalue() {
    this.f.powerTyp.setValue(!this.f.powerTyp.value);
  }

  closeDatePicker(eventData: any, dp?:any) {
    this.f.firstRegistrationDate.setValue(eventData);
    dp.close();
  }

  calcRegistrationDate() {
    let date = new Date();
    if(this.f.category.value.id == 1){
      this.f.firstRegistrationDate.setValue(null);
    } else if(this.f.category.value.id == 6){
      date.setMonth(date.getMonth() - 12);
      this.maxDate = formatDate(date,'yyyy-MM-dd','de');
    } else if(this.f.category.value.id == 3) {
      date.setMonth(date.getMonth() - 24);
      this.maxDate = formatDate(date,'yyyy-MM-dd','de');
    } else {
      date = new Date(0);
      this.minDate = formatDate(date,'yyyy-MM-dd','de');
    }
  }
}
