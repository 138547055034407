import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Thread } from 'src/app/buisness-object/message/Thread';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { environment } from 'src/environments/environment';
import { EnumHelper } from 'src/app/helpers/EnumHelper';
import { TypId, Urgency } from 'src/app/helpers/Enums';

@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.css']
})
export class MessagesListComponent implements OnInit {
  @Input() threads: Thread[];
  @Input() employees: Employee[];
  @Input() pageInfo: string;
  @Input() pageHandler: PageHandler;
  @Input() sliceFrom: number;
  @Input() sliceTo: number;
  @Input() filter: any;
  @Input() sortObject: any;
  @Input() userId: number;
  @Output() pageEmitter = new EventEmitter<boolean>();
  @Output() resolveEmitter = new EventEmitter<Thread>();
  @Output() openDetailsEmitter = new EventEmitter<Thread>();
  @Output() removeFilterEmitter = new EventEmitter<string>();
  @Output() applySortEmitter = new EventEmitter<undefined>();
  public maxAmountListPage = environment.max_amount_list_page;
  public enumHelper = new EnumHelper();

  constructor(
  ) { }

  ngOnInit(): void {
  }

  getEnumValueUr(value) {
    return this.enumHelper.getEnumValue(Urgency,value-1)
  }
  getEnumValueTyp(value) {
    return this.enumHelper.getEnumValue(TypId,value-1)
  }
}
