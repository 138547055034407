import { VehicleEquipment } from "./optional/VehicleEquipment";
import { VehicleDrive } from "./basic/VehicleDrive";
import { VehicleBrand } from "./basic/VehicleBrand";
import { VehicleFuel } from "./basic/VehicleFuel";
import { VehicleBodyColor } from "./basic/VehicleBodyColor";
import { VehicleBodyTyp } from "./basic/VehicleBodyTyp";
import { VehicleCategory } from "./basic/VehicleCategory";
import { VehicleTransmission } from "./basic/VehicleTransmission";
import { VehicleConsumption } from "./VehicleConsumption";
import { VehicleMaintenance } from "./VehicleMaintenance";
import { VehicleModel } from "./basic/VehicleModel";
import { VehiclePicture } from "./optional/VehiclePicture";
import { VehicleUpholstery } from "./optional/VehicleUpholstery";
import { VehicleUpholsteryColor } from "./optional/VehicleUpholsteryColor";
import { VehicleEnviromentLabel } from "./optional/VehicleEnviromentLabel";
import { Transaction } from "../transaction/Transaction";
import { Thread } from "../message/Thread";
import { Attachment } from "../attachment/Attachment";
import { PriceSheet } from "../pricesheet/PriceSheet";
import { AuthenticationService } from "src/app/service/authentication/authentication.service";
import { FormGroup } from "@angular/forms";
import { SubMenuColumn } from "../menu/SubMenuColumn";
import { PriceSheetCalculater } from "../pricesheet/PriceSheetCalculater";
import { Customer } from "../customer/Customer";
import { RentPrice } from "./RentPrice";
import { Picture } from "../picture/Picture";
import { AUSTRIANDATAEXTRACT } from "../document/Kaufvertrag";
import { EnumService } from "src/app/service/enum/enum.service";

export class Vehicle {
    public pictures: Picture[] = [];
    public thumbnail: string;
    public transactions: Transaction[] = [];
    public threads: Thread[] = [];
    public attachments: Attachment[] = [];
    public priceSheet: PriceSheet;
    public chatgpt_flag = false;
    public steuersatz_pricesheet;
    public einkaufspreis_pricesheet;
    public performance: number;
    public sprit: number;
    public lieferant_extern: number;
    public einkaufsdatum: number;
    public vehicle_supplier: string;
    public vehicle_imported: boolean;
    public pictures_count = 0;
    public as_id: number;
    public mobile_id: string;
    public isReady: boolean = false;
    public rents: RentPrice[] = [];
    public rent_typ: number;
    public coc: number;
    public austrian_data_extract: number;
    public rent_price_pro_tag: number;
    public export_price_checked: boolean = false;
    public export_price_enabled: boolean = false;
    public last_price_change: number;
    public purchase_standing_time: number;
    public sinhuber_standing_time: number;
    constructor(
        public id: number,
        public locationId: number,
        public brand: VehicleBrand,
        public model: VehicleModel,
        public price: number,
        public fuel: VehicleFuel,
        public powerPs: number,
        public powerKw: number,
        public bodyColor: VehicleBodyColor,
        public bodyTyp: VehicleBodyTyp,
        public category: VehicleCategory,
        public mileageKm: number,
        public unfall: boolean,
        public numOfDoors: number,
        public numOfSeats: number,
        public transmission: VehicleTransmission,
        public drive: VehicleDrive,
        public manufactureDate: number,
        public firstRegistrationDate: number,
        public previousOwners: number,
        public status: number,
        public ccm?: number,
        public co2Emission?: number,
        public numOfGears?: number,
        public numOfCylinders?: number,
        public emptyWeight?: number,
        public fullWeight?: number,
        public lastUpdated?: Number,
        public created?: number,
        public externalId?: string,
        public warrantyExists?: boolean,
        public warrantyDuration?: number,
        public upholstery?: VehicleUpholstery,
        public upholsteryColor?: VehicleUpholsteryColor,
        public paintType?: string,
        public alloyWheelInches?: number,
        public originalMarket?: string,
        public nonSmoking?: boolean,
        public consumption?: VehicleConsumption,
        public maintenance?: VehicleMaintenance,
        public enviromentLbl?: VehicleEnviromentLabel,
        public dealerPrice?: number,
        public offerRef?: string,
        public addEquipment?: string,
        public description?: string,
        public engineNumber?: string,
        public equipments?: VehicleEquipment[],
        public fin_number?: string,
        public guarantee?: boolean,
        public nova?: number,
        public vat_reportable?: boolean,
        public leasable?: boolean,
        public rent_price?: boolean,
        public luggage_space?: number,
        public license_plate?: string,
        public internal_description?: string,
        public color_code?: string,
        public color_designation?: string,
        public winter_tyres?: boolean,
        public summer_tyres?: boolean,
        public keys_available?: string,
        public keys_number?: number,
        public damages?: number,
        public repair_status?: number,
        public paper_status?: number,
        public website_online?: boolean,
        public platform_online?: boolean,
        public standing_time?: number,
        public last_movement?: number,

        public handing_over_date?: number,
        public customer_id?: number,

        public accounting_description?: string,
        public winter_diesel?: boolean,
        public winter_tyres_description?: string,
        public summer_tyres_description?: string,
        public purchase_date?: number, // nicht benötigt / genutzt
        public is_ersatzauto?: boolean,
        public issued_ersatzauto?: number,
        public supplier?: string,
        public delivery_date?: number,
        public planed_repair_date?: number,
        public type_certificate_angefordert?: number,
        public type_certificate_eingestockt?: number,
        public type_certificate_ausgestockt?: number,
        public type_certificate_versendet?: number,
        public type_certificate_an_bank?: any,
        public type_certifcate_bank_erhalten?: boolean,
        public finanzsperrauskunft?: number,

        public garantie_bis?: number,
        public garantie_km?: number,
        public garantie_description?: string,
        public freigeschaltet_am_web?: number,
        public freigeschaltet_am_plattform?: number,

        public lackierung_notwendig?: number,
        public aufbereitung?: number,
        public aufbereitung_zustaendigkeit?: number,
        public ankaufstest?: number,
        public ankaufstest_durchfuehrung?: number,

        public eurotax_code?: string,

        public sonderausstattung_beschreibung?: string,
        public ersatzteile?: number,
        public naechstes_service?: number,
        public eingangsdatum?: number,
        public endreinigungsdatum?: number,

        public vehicle_serialnumber?: string,

        public datenblatt_angefordert_am?: number,
        public fahrzeug_eingestockt_am?: number,
        public co2_checked?: boolean,
        public first_registration_checked?: boolean,
        public ankaufstest_info_description?: string,
        public werkstatt_info_description?: string,

        public inserat_checked?: boolean,
        public inserat_info?: string,
        public mobile_online?: boolean,
        public export_price?: number,
        public mileage_internal?: number,
        public felgen_aufpreis?: number,

        public price_checked?: boolean,
        public aufbereitung_info?: string,
        public dellen_info?: string,
        public letzte_inventur?: number,
        public is_vermittlung?: boolean,
        public transport_beantragt?: boolean,
        public transport_date?: number,
        public spedition?: string,
        public envkv_class?: string,
        public pickerl_valid?: number,
        public finanzsperrauskunft_beantragt?: number,
        public display_keys_number?: number,
        public digital_keys_number?: number,
        public fahrwerk_aufpreis?: number,

        public electrical_distance?: number,
        public electrical_distance_checked?: boolean,

        public einkauf_vertragsnummer?: string,
        public verkauf_vertragsnummer?: string,

        public trailer_load_braked?: number,
        public hail_damage?: number,

        public zubau?: number,
        public abwertung?: number,

        public type_certificate_is_sent?: boolean,

        public is_camper?: boolean,

        public finanzsperrauskunft_status?: number,
        public bca_import?: boolean,

        public photo_aufbereitung?: boolean
    ){
      this.setStandingTime();
      this.performance = this.getStatus();
      this.purchase_standing_time = this.getPurchaseStandzeit();
      this.sinhuber_standing_time = this.getSinhuberStandingTime();
    }

    getNovaSatzValue(): number {
      const co2_emission = this.co2Emission;
      let datum = null;
      if(this.category.id == 1) { //neuwagen
        datum = new Date().getFullYear()+"";
      } else {
        datum = new Date(this.firstRegistrationDate).getFullYear()+'';
      }
      if(datum == null) return null;
      const ez_year = datum ? Number(datum.slice(0,4)) : null;
      if(ez_year){
       let NOVABerechnung = PriceSheetCalculater.getNOVABerechnung(ez_year);
       if(NOVABerechnung.date == '1.1.2013') return null;
       if(NOVABerechnung){
        const novaSatz = PriceSheetCalculater.getNovaSatz(co2_emission, NOVABerechnung);
        return novaSatz;
       }
      }
      return null;
    }

    setStandingTime() {
      if(this.last_movement){
        let delta = Math.abs((new Date().getTime() - this.last_movement) / 1000);
        this.standing_time = Math.floor(delta / 86400);
      }
    }

    getStatus(): number {
      let mandant_id = Number(localStorage.getItem("mandant_id"));
      let notRelevantProp = 32; //not relevatn amount of data for 100%
      //TODO: Muss überarbeitet werden der Graus
      if(mandant_id == 2){ // Sinhuber
        notRelevantProp = 41;
      }
      if(mandant_id == 7) { // Hoedl
        notRelevantProp += 38;
      }
      let properties = Object.keys(this).length - notRelevantProp;
      let filledData = 0;
      Object.entries(this).forEach((key,value) => {
        if(key[1] != null) filledData++;
      });
      let percentage = 100;
      if(this.pictures_count == 0) {
        percentage = percentage - 40;
      } else if(this.pictures_count == 1) {
        percentage = percentage - 30;
      } else if(this.pictures_count < 8) {
        percentage = percentage - (8 - this.pictures_count) * (30.0 / 14.0);
      }
      if(this.equipments.length < 20) {
        percentage = percentage - (15 - this.equipments.length) * (20.0 / 20.0);
      }
      if(filledData < properties) {
        percentage = percentage - (25 - filledData / properties * 25);
      }
      if(this.co2Emission) {
        percentage = percentage - 7.5;
      }
      if(!this.model.name || this.model.name == "Sonstige") {
        percentage = percentage - 7.5;
      }
      return Math.round(percentage);
    }

    public getPickerlValid(): number {
      const millisecondsInOneYear = 365*24*60*60*1000;
      let threeYearsCondition = this.firstRegistrationDate + (millisecondsInOneYear*3);
      if(threeYearsCondition > new Date().getTime()){
        return threeYearsCondition;
      }
      let twoYearsCondition = threeYearsCondition + (millisecondsInOneYear*2);
      if(twoYearsCondition > new Date().getTime()){
        return twoYearsCondition;
      }
      let oneYearCondition = twoYearsCondition + millisecondsInOneYear;
      return oneYearCondition;
    }

    public getBrandName(len?: number) {
      if(len){
        if(this.brand.makeName.length > len){
          return this.brand.makeName.slice(0,len) + ' ...'
        } else {
            return this.brand.makeName;
        }
      } else {
        return this.brand.makeName;
      }
    }

    public getBrandVersion(len?: number) {
      let text = this.model.name + ', ' + this.model.version;
      if(len && text.length > len){
        return text.slice(0,len) + ' ...'
      }
      return text;
    }

    public getDescription(len?: number): string {
      let description = this.brand.makeName + ', ' + this.model.name + ', ' + this.model.version;
      if(len){
        if(description.length > len){
          return description.slice(0,len) + ' ...'
        } else {
            return description;
        }
      } else {
        return description;
      }
    }

    public getShortDescription(len?: number): string {
      let description = this.brand.makeName + ', ' + this.model.name;
      if(len){
        if(description.length > len){
          return description.slice(0,len) + ' ...'
        } else {
            return description;
        }
      } else {
        return description;
      }
    }

    public getCustomerName(customers: Customer[]): string {
      if(this.customer_id == null) return '---';
      for(let customer of customers){
        if(customer.id == this.customer_id){
          return customer.getFullName();
        }
      }
      return '---';
    }

    public getPurchaseStandzeit(): number {
      if(this.purchase_date) return null;
      if(this.freigeschaltet_am_plattform == null) return null;
      const date = new Date(this.freigeschaltet_am_plattform);
      let today = new Date();
      if(this.purchase_date) {
        today = new Date(this.purchase_date);
      }
      date.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      const differenceInMilliseconds = today.getTime() - date.getTime();
      const daysDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
      return daysDifference;
    }

    public getSinhuberStandingTime(): number {
      if(this.priceSheet == null) return null;
      if(this.priceSheet.anpreisungsdatum == null) return null;
      const date = new Date(this.priceSheet.anpreisungsdatum);
      let today = new Date();
      if(this.priceSheet?.verkaufsdatum) {
        today = new Date(this.priceSheet.verkaufsdatum);
      }
      date.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      const differenceInMilliseconds = today.getTime() - date.getTime();
      const daysDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
      return daysDifference;
    }

    public setDataFromPricesheet(pricesheet: PriceSheet) {
      this.dealerPrice = pricesheet.minimaler_verkaufpreis;
      this.price = pricesheet.preis_anschreiben;
      this.paper_status = pricesheet.paper_status;
      this.finanzsperrauskunft = pricesheet.finanzsperrauskunft;
      this.finanzsperrauskunft_beantragt = pricesheet.finanzsperrauskunft_beantragt;
      this.type_certificate_versendet = pricesheet.type_certificate_versendet;
      this.co2Emission = pricesheet.co2_emission;
      this.co2_checked = pricesheet.co2_checked;
      this.zubau = pricesheet.zubau;
      this.abwertung = pricesheet.abwertung;
    }

    getJSON(authService: AuthenticationService): any {
      let equipments: any[] = [];
      if(this.equipments){
        this.equipments.forEach(element => {
          equipments.push({
            "eq_id": element.eq_id
          })
        });
      }
      let rents = [];
      if(this.rent_typ == 1){
        for(let rent of this.rents){
          rents.push(rent.getJSON());
        }
      } else if(this.rent_typ == 2 || this.rent_typ == 3) {
        rents.push({
          "vehicle_id": this.id,
          "rent_price": this.rent_price_pro_tag,
          "duration": null,
          "mileage": null,
        })
      }
      let body = {
          "vehicle_id": this.id ? this.id : null,
          "location_id": this.locationId,
          "website_online" : this.website_online,
          "platform_online" : this.platform_online,
          "brand" : {
              "make" : {
                  "make_id": this.brand.id,
                  "make_name": this.brand.makeName
              },
              "model" : {
                  "model_id": this.model.id,
                  "model_name": this.model.name
              },
              "version": this.model.version
          },
          "price": this.price,
          "fuel": {
              "fuel_id": this.fuel.id,
              "name" : this.fuel.name
          },
          "power_ps": this.powerPs,
          "power_kw": this.powerKw,
          "body_color": {
              "body_color_id": this.bodyColor.id,
              "color_id": this.bodyColor.id,
              "name": this.bodyColor.name,
          },
          "body_typ": {
              "body_typ_id": this.bodyTyp.id,
              "name": this.bodyTyp.name
          },
          "category": {
              "category_id": this.category.id,
              "name": this.category.name
          },
          "mileage_in_km": this.mileageKm,
          "accident_free": !this.unfall,
          "number_of_doors": this.numOfDoors,
          "number_of_seats": this.numOfSeats,
          "transmission": {
              "transmission_id": this.transmission.id,
              "name": this.transmission.name
          },
          "drive": {
              "drive_id": this.drive.id,
              "name": this.drive.name
          },
          "status": this.status,
          "external_id": this.externalId,
          "first_registration": this.firstRegistrationDate,
          "ccm": this.ccm,
          "warranty_exists": this.warrantyExists,
          "warranty_duration": this.warrantyDuration,
          "previous_owners": this.previousOwners,
          "upholstery": {
              "upholstery_id" : this.upholstery ? this.upholstery.id : null,
              "name" : this.upholstery ? this.upholstery.name : null,
          },
          "upholstery_color": {
              "upholstery_color_id" : this.upholsteryColor ? this.upholsteryColor.id : null,
              "name" : this.upholsteryColor ? this.upholsteryColor.name : null,
          },
          "paint_type": this.paintType,
          "alloy_wheel_inches": this.alloyWheelInches,
          "color_code" : this.color_code,
          "color_designation" : this.color_designation,
          "created": this.created,
          "last_updated": this.lastUpdated,
          "original_market": this.originalMarket,
          "non_smoking": this.nonSmoking,
          "empty_weight": this.emptyWeight,
          "total_weight": this.fullWeight,
          "luggage_space" : this.luggage_space,
          "number_of_gears": this.numOfGears,
          "number_of_cylinders": this.numOfCylinders,
          "consumption" : {
              "combined": this.consumption?.combined,
              "urban": this.consumption?.urban,
              "extra_urban": this.consumption?.extraUrban,
          },
          "maintenance": {
              "next_inspection": this.maintenance?.nextInspection,
              "last_belt_service": this.maintenance?.lastBeltService,
              "last_service": this.maintenance?.lastService,
              "service_history": this.maintenance?.serviceHistory,
          },
          "vat_reportable" : this.vat_reportable,
          "guarantee" : this.guarantee,
          "leasable" : this.leasable,
          "co2_emission": this.co2Emission,
          "environment_label": {
              "environment_label_id" : this.enviromentLbl ? this.enviromentLbl.id : null,
              "name" : this.enviromentLbl ? this.enviromentLbl.name : null,
          },
          "dealer_price": this.dealerPrice,
          "rent_price" : this.rent_price,
          "nova" : this.nova,
          "offer_ref": this.offerRef,
          "motor_nr" : this.engineNumber,
          "fin_nr": this.fin_number,
          "add_equipment": this.addEquipment,
          "description": this.description,
          "equipments" : equipments,
          "internal_description" : this.internal_description,
          "summer_tyres": this.summer_tyres,
          "winter_tyres": this.winter_tyres,
          "keys_available": this.keys_available,
          "keys_number": this.keys_number,
          "damages": this.damages,
          "repair_status": this.repair_status,
          "paper_status": this.paper_status,
          "license_plate" : this.license_plate,
          // "purchase_date" : this.purchase_date,
          "handing_over_date" : this.handing_over_date,
          "customer_id" : this.customer_id,
          "accounting_description" : this.accounting_description,
          "winter_diesel": this.winter_diesel,
          "winter_tyres_description": this.winter_tyres_description,
          "summer_tyres_description": this.summer_tyres_description,
          "is_ersatzauto": this.is_ersatzauto,
          "issued_ersatzauto": this.issued_ersatzauto,
          "supplier": this.supplier,
          "delivery_date" : this.delivery_date,
          "planed_repair_date" : this.planed_repair_date,
          "type_certificate_angefordert" : this.type_certificate_angefordert,
          "type_certificate_eingestockt" : this.type_certificate_eingestockt,
          "type_certificate_ausgestockt" : this.type_certificate_ausgestockt,
          "type_certificate_versendet" : this.type_certificate_versendet,
          "type_certificate_an_bank" : this.type_certificate_an_bank,
          "type_certifcate_bank_erhalten" : this.type_certifcate_bank_erhalten,
          "finanzsperrauskunft" : this.finanzsperrauskunft,
          "last_movement" : this.last_movement,

          "garantie_bis" : this.garantie_bis,
          "garantie_km" : this.garantie_km,
          "garantie_description" : this.garantie_description,

          "lackierung_notwendig" : this.lackierung_notwendig,
          "aufbereitung" : this.aufbereitung,
          "aufbereitung_zustaendigkeit" : this.aufbereitung_zustaendigkeit,
          "ankaufstest" : this.ankaufstest,
          "ankaufstest_durchfuehrung" : this.ankaufstest_durchfuehrung,
          "ersatzteile" : this.ersatzteile,
          "eurotax_code" : this.eurotax_code,
          "eingangsdatum" : this.eingangsdatum,
          "endreinigungsdatum" : this.endreinigungsdatum,
          "naechstes_service" : this.naechstes_service,
          "chatgpt_flag" : authService?.mandantId$?.getValue() != 2 ? this.chatgpt_flag : undefined, //sinhuber
          "datenblatt_angefordert_am" : this.datenblatt_angefordert_am,
          "fahrzeug_eingestockt_am" : this.fahrzeug_eingestockt_am,
          "co2_checked" : this.co2_checked,
          "first_registration_checked" : this.first_registration_checked,
          "ankaufstest_info_description" : this.ankaufstest_info_description,
          "werkstatt_info_description" : this.werkstatt_info_description,
          "inserat_checked" : this.inserat_checked,
          "inserat_info" : this.inserat_info,
          "mobile_online" : this.mobile_online,
          "export_price" : this.export_price,
          "export_price_checked" : this.export_price_checked,
          "export_price_enabled": this.export_price_enabled,
          "mileage_internal" : this.mileage_internal,
          "felgen_aufpreis" : this.felgen_aufpreis,
          "price_checked" : this.price_checked,
          "aufbereitung_info" : this.aufbereitung_info,
          "dellen_info" : this.dellen_info,
          "is_vermittlung" : this.is_vermittlung,
          "transport_beantragt" : this.transport_beantragt,
          "transport_date" : this.transport_date,
          "spedition" : this.spedition,
          "envkv_class" : this.envkv_class,
          "pickerl_valid" : this.pickerl_valid,
          "finanzsperrauskunft_beantragt" : this.finanzsperrauskunft_beantragt,
          "display_keys_number" : this.display_keys_number,
          "digital_keys_number" : this.digital_keys_number,
          "fahrwerk_aufpreis" : this.fahrwerk_aufpreis,
          "electrical_distance" : this.electrical_distance,
          "electrical_distance_checked" : this.electrical_distance_checked,
          "einkauf_vertragsnummer" : this.einkauf_vertragsnummer,
          "verkauf_vertragsnummer" : this.verkauf_vertragsnummer,
          "trailer_load_braked" : this.trailer_load_braked,
          "hail_damage" : this.hail_damage,
          "zubau" : this.zubau,
          "abwertung" : this.abwertung,
          "type_certificate_is_sent" : this.type_certificate_is_sent,

          "rent_typ" : this.rent_typ,
          "rents" : rents,
          "coc" : this.coc,
          "austrian_data_extract" : this.austrian_data_extract,
          "is_camper" : this.is_camper,
          "preisblatt": this.priceSheet?.getJSON2(),
          "bca_import": false,
          "photo_aufbereitung": this.photo_aufbereitung,
          "finanzsperrauskunft_status": this.finanzsperrauskunft_status,
      }
      return body;
    }

    mergeChatgbtEquipments(form: FormGroup): VehicleEquipment[] {
      let temp: VehicleEquipment[] = [];
      for(let value of form.controls["equipments"].value){
        temp.push(new VehicleEquipment(value.eq_id, value.eq_name, null, null, null));
      }
      for(let eq of temp){
        let index = this.equipments.findIndex(element => element.eq_id == eq.eq_id);
        if(index == -1){
          this.equipments.push(eq);
        }
      }
      return this.equipments;
    }

    getId(): string {
      let id = this.id.toString();
      try{
        const mandantId = Number.parseInt(localStorage.getItem('mandant_id'));
        if(mandantId == 2 && this.externalId && this.externalId != ''){
          id = this.externalId;
        } else if(mandantId == 7 && this.externalId && this.externalId != ''){
          id = this.externalId;
        }
      } catch(e) {}
      return id;
    }

    getVehicleName(): string {
      let name = this.brand.makeName + ', ' + this.model.name + ', ' + this.model.version;
      try{
        const mandantId = Number.parseInt(localStorage.getItem('mandant_id'));
        if(mandantId == 2 || mandantId == 7 && this.externalId && this.externalId != ''){
          name = this.externalId + ' - ' + name;
        } else {
          name = this.id + ' - ' + name;
        }
      } catch(e) {}
      return name;
    };

  getMenuData(subMenuColumn: SubMenuColumn) {
    if(subMenuColumn.data_key == null){
      return '---';
    }
    if(subMenuColumn.data_key == 'thumbnail'){
      return 'test';
    }
    return this[subMenuColumn.data_key];
  }

  getUstVor(): number {
    let value = (this.priceSheet?.steuersatz == 3 || this.priceSheet?.steuersatz == 4) ? (this.priceSheet?.entgelt_ohne_nova * 0.2) : this.priceSheet?.ust_vor;
    return value;
  }

  getKeys(): string {
    return (this.keys_number != null ? this.keys_number:'-') + (this.display_keys_number != null ? ('/'+this.display_keys_number):'/-') + (this.digital_keys_number != null ? '/'+this.digital_keys_number:'/-');
  }

  getVehicleMakeAndModelName(): string {
    return this.brand.makeName + ' ' + this.model.name;
  }

  getVehicleMakeAndVersionName(): string {
    return this.brand.makeName + ' ' + this.model.version;
  }

  getVehicleModelAndVersionName(): string {
    if(this.model.version.startsWith(this.model.name)) {
      return this.model.version;
    }
    return this.model.name + ' ' + this.model.version;
  }

  getOnlineSeit(): number {
    if(this.freigeschaltet_am_plattform == null) return undefined; //platform_online
    const date = new Date(this.freigeschaltet_am_plattform);
    const today = new Date();
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    const differenceInMilliseconds = today.getTime() - date.getTime();
    const daysDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
    return daysDifference;
  }


  getLastPriceChange(): number {
    if(this.last_price_change == null) return undefined; //platform_online
    const date = new Date(this.last_price_change);
    const today = new Date();
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    const differenceInMilliseconds = today.getTime() - date.getTime();
    const daysDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
    return daysDifference;
  }

  getPriceSheetSteuersatz(): string {
    switch(this.priceSheet?.steuersatz){
      case 1: return '20%';
      case 2: return '0%';
      case 3: return '19%';
      default: return '---';
    }
  }

  getGesamtkosten(): number {
    return (this.priceSheet.einkaufspreis_brutto?this.priceSheet.einkaufspreis_brutto:0) +
    (this.priceSheet.sprit?this.priceSheet.sprit:0) +
    (this.priceSheet.lieferant_extern?this.priceSheet.lieferant_extern:0);
  }

  getAustriaDataExtractStatus(enumService: EnumService): string {
    if(!this.priceSheet?.vehicle_imported){
      return enumService.getEnumValue('austrian_data_extract', AUSTRIANDATAEXTRACT.NICHT_NOTWENDIG);
    }
    return enumService.getEnumValue('austrian_data_extract', this.austrian_data_extract);
  }

  getInventurWert(): number {
    let value;
    if(this.priceSheet){

    }
    return null;
  }

  getInventurWertNachAbwertung(): number {

    return null;
  }
}
