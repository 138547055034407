<div class="data_container">
    <div class="lbl_bold_light_color">Detailsansicht</div>
    <div class="body">
      <div class="column">
        
      <div class="column">
        
      </div>
    </div>
</div>
  