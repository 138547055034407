import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-bill-page-two',
  templateUrl: './create-bill-page-two.component.html',
  styleUrls: ['./create-bill-page-two.component.css']
})
export class CreateBillPageTwoComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;

  @Output() addPriceEmitter = new EventEmitter<undefined>();
  constructor() { }

  ngOnInit(): void {
  }

  addPrice() {
    this.addPriceEmitter.emit();
  }
}
