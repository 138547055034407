import { Directive, ElementRef, Renderer2, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {
  @Input() show!: boolean;
  @Input() excludingElementIds!: string[];
  @Output() changedValueEmitter = new EventEmitter<boolean>();
  private listener!: () => void;
  public excludingElements: any[] = [];
  public firstClick = true;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if(this.excludingElementIds){
      for(let el of this.excludingElementIds){
        this.excludingElements.push(document.getElementById(el));
      }
    }
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      this.checkExcludesInTarget(e.target).then((inTarget) => {
        let className: string;
        if(e.target instanceof HTMLElement){
          className = e.target.className;
        }
        if((!this.firstClick && this.show
          && !this.elementRef.nativeElement?.contains(e.target))){
          this.changedValueEmitter.emit(false);
        } else {
          this.firstClick = false;
        }
      })
    });
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  async checkExcludesInTarget(target): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      setTimeout(() => {
        if(this.excludingElements){
          for(let e of this.excludingElements){//mat-datepicker-content
            let ele = e as HTMLElement;
            if(ele?.id == 'datepicker_filter'){
              for(let i = 0; i < 20; i++){
                let datepicker = document.getElementById('cdk-overlay-'+i);
                if(datepicker){
                  return resolve(true);
                }
              }
            } else {
              if(e?.contains(target)){
                return resolve(true);
              }
            }
          }
        }
        return resolve(false);
      }, 0);
    })
  }
}
