import { formatDate } from "@angular/common";
import { FormControl } from "@angular/forms";

export class DateHelper {

  public static getConvertedDateForInputFull(timestamp: number): string {
    if(timestamp){
      return formatDate(new Date(timestamp),'yyyy-MM-dd', 'de')
    }
    return null;
  }

    public static getConvertedTimeForInputFull(timestamp: number): string {
    if(timestamp){
      return formatDate(new Date(timestamp),'HH:mm', 'de')
    }
    return null;
  }

  public static convertDateStringToTimestamp(date: string): number {
    if(date){
      let d = new Date(date);
      return d.getTime();
    } else return null;
  }

  public static validDatePast(c: FormControl) {
    if(c.value == null || c.value?.length == 0) return null;
    else {
      return c.value.length == 10 && this.convertDateStringToTimestamp(c.value) <= new Date().getTime() ? null : {
        validDatePast: {
          valid: false
        }
      };
    }
  }
}
