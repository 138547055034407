<div class="overview_data_box">
  <form [formGroup]="form" *ngIf="form" id="customer-box-form">
    <div id="customer-box-title-wrapper">
      <h3>Kunde</h3>
      <button *ngIf="this.form.get('customer').value" class="btn_label_blue" (click)="selectionChange()">{{buttonText}}</button>
    </div>
    <app-dropdown-objects-search *ngIf="!showEdit && form"
      [title]="'Kunde *'"
      [placeholder]="'Kunde'"
      [headervalue]="form.get('customer').value?.getName()"
      [formcontrol]="form.get('customer')"
      [values]="customerValues"
      [submitted]="submitted"
      [showLabel]="false"
      (submitValueEmitter)="setValueFromDropdown('customer', $event)"
    ></app-dropdown-objects-search>
  </form>
  <form [formGroup]="formCustomer" *ngIf="showEdit" id="customer-box-form">
    <app-dropdown-enum
        [name]="'Anrede*'"
        [placeholder]="'---'"
        [control]="formCustomer.get('salutation')"
        [enumName]="'salutation'"
        [submitted]="submitted"
      ></app-dropdown-enum>
      <app-dropdown-strings
        [title]="'Titel *'"
        [placeholder]="'---'"
        [formcontrol]="formCustomer.get('title')"
        [headerText]="formCustomer.get('title').value"
        [values]="titleValues"
        (submitValueEmitter)="formCustomer.get('title').setValue($event)"
      ></app-dropdown-strings>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Vorname *</div>
        <input type="text" placeholder="---" formControlName="firstName" [class.input_invalid]="submitted && formCustomer.get('firstName').errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Nachname *</div>
        <input type="text" placeholder="Nachname" formControlName="lastName" [class.input_invalid]="submitted && formCustomer.get('lastName').errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Geburtsdatum</div>
        <input #inputDate1 type="date" placeholder="date" [max]="today" formControlName="birthdate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && formCustomer.get('birthdate').errors">
      </div>
      <app-dropdown-multiple
        [title]="'Kundentyp'"
        [formcontrol]="formCustomer.get('typ')"
        [headerText]="typValues[formCustomer.get('typ').value-1]"
        [values]="typValues"
        (submitValueEmitter)="formCustomer.get('typ').setValue($event)"
      ></app-dropdown-multiple>
      <div class="input_wrapper" *ngIf="formCustomer.controls['typ'].value == 1">
        <label class="overview_record_lbl">Firmenname *</label>
        <input type="text" placeholder="Firmenname" formControlName="companyName" [class.input_invalid]="submitted && formCustomer.get('companyName').errors">
      </div>
      <div class="input_wrapper" *ngIf="formCustomer.controls['typ'].value == 1">
        <label class="overview_record_lbl">ATU</label>
        <input type="text" placeholder="ATU" formControlName="atuNum" [class.input_invalid]="submitted && formCustomer.get('atuNum').errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Straße, Nr *</label>
        <input type="text" placeholder="Straße" formControlName="street" [class.input_invalid]="submitted && formCustomer.get('street').errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">PLZ *</label>
        <input type="text" placeholder="PLZ" formControlName="postalCode" [class.input_invalid]="submitted && formCustomer.get('postalCode').errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Ort *</label>
        <input type="text" placeholder="Ort" formControlName="city" [class.input_invalid]="submitted && formCustomer.get('city').errors">
      </div>
      <app-dropdown-strings
        [title]="'Land'"
        [placeholder]="'Land'"
        [formcontrol]="formCustomer.controls['country']"
        [headerText]="formCustomer.controls['country'].value"
        [values]="countries"
        [isOptional]="true"
        (submitValueEmitter)="formCustomer.controls['country'].setValue($event);"
      ></app-dropdown-strings>
      <div class="input_wrapper">
        <label class="overview_record_lbl">E-Mail *</label>
        <input type="text" placeholder="E-Mail" formControlName="email" [class.input_invalid]="submitted && formCustomer.get('email').errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Telefon</label>
        <input type="text" placeholder="Telefon" formcontrolName="phone" [class.input_invalid]="submitted && formCustomer.get('phone').errors">
      </div>
      <app-dropdown-simple *ngIf="authService.isHoedl()"
        [title]="'Politisch Exponierte Person*'"
        [formcontrol]="formCustomer.get('is_pep')"
        (submitValueEmitter)="formCustomer.get('is_pep').setValue($event)"
      ></app-dropdown-simple>
  </form>
</div>
