<!-- <div class="topbar">
  <div class="topbar_inner" *ngIf="surface == 1 || surface == 2">
    <div class="topbar_flex">
      <button class="button_type_1" (click)="showDropdown = !showDropdown" appDropdown [show]="showDropdown" (changedValueEmitter)="showDropdown = $event">
        Neu
        <div *ngIf="showDropdown">
          <button (click)="openCreateEmitter.emit(1)">Probefahrt</button>
          <button (click)="selectionCreateInternDriveEmitter.emit()">Interne Fahrt</button>
        </div>
      </button>
      <div class="topbar_tab_wrapper">
        <button [class.button_tap_active]="tab == 1" (click)="changeTabEmitter.emit(1);">Liste<span *ngIf="tab == 1"></span></button>
        <button [class.button_tap_active]="tab == 2" (click)="changeTabEmitter.emit(2);">Kalender<span *ngIf="tab == 2"></span></button>
      </div>
    </div>
      <div *ngIf="tab == 1" class="input_dropdown_container">
        <input #search class="input_search" type="text" placeholder="Suche" (input)="searchEmitter.emit(search.value);">
      </div>
  </div>
  <div class="topbar_inner" *ngIf="surface == 3">
      <div class="btn_cancel_container" (click)="cancelEmitter.emit()">
          <img class="icon_cancel" src="../../../../assets/cancle.svg">
          <button id="btn-cancel">Abbrechen</button>
      </div>
  </div>
  <div class="topbar_inner" *ngIf="surface == 4">
    <div class="btn_cancel_container" (click)="backEmitter.emit()">
        <img class="icon_cancel" src="../../../../assets/back.svg">
        <button class="btn_cancel">Zurück</button>
    </div>
    <div class="btn_container">
    </div>
  </div>
  <div class="topbar_inner" *ngIf="false">
      <div class="btn_cancel_container" (click)="backEmitter.emit()">
          <img class="icon_cancel" src="../../../../assets/back.svg">
          <button class="btn_cancel">Zurück</button>
      </div>
      <div class="btn_container">
        <button class="btn_submit" style="margin-right: 20px;" (click)="editCustomerEmitter.emit()">PDF EXPORT</button>
        <button class="btn_white" (click)="editEmitter.emit()">Bearbeiten</button>
      </div>
  </div>
</div> -->

<div class="page-menu-bar" style="grid-template-columns: 70px 60% 1fr !important;" *ngIf="surface == 1 || surface == 2">
  <!-- <button class="button_type_1" (click)="showDropdown = !showDropdown" appDropdown [show]="showDropdown" (changedValueEmitter)="showDropdown = $event">
    Neu
    <div *ngIf="showDropdown">
      <button (click)="openCreateEmitter.emit(1); showDropdown = false;">Probefahrt</button>
      <button (click)="selectionCreateInternDriveEmitter.emit(); showDropdown = false;">Interne Fahrt</button>
    </div>
  </button> -->
  <button
    (click)="showDropdown = !showDropdown"
    appDropdown
    [show]="showDropdown"
    class="button_type_1"
    (changedValueEmitter)="showDropdown = $event">
    Neu
    <div *ngIf="showDropdown">
      <app-restricted-button
        [name]="'Probefahrt'"
        [access]="'test_drive_edit'"
        [isPrimary]="true"
        [isDropdownButton]="true"
        (clickEmitter)="openCreateEmitter.emit(1)">
      </app-restricted-button>
      <app-restricted-button
        [name]="'Interne Probefahrt'"
        [access]="'test_drive_edit'"
        [isPrimary]="true"
        [isDropdownButton]="true"
        (clickEmitter)="selectionCreateInternDriveEmitter.emit()">
      </app-restricted-button>
    </div>
  </button>
  <div class="topbar_tab_wrapper">
    <button [class.button_tap_active]="tab == 1" (click)="changeTabEmitter.emit(1);">Liste<span *ngIf="tab == 1"></span></button>
    <button *ngIf="authSerive.isSinhuber()" [class.button_tap_active]="tab == 2" (click)="changeTabEmitter.emit(2);">Kalender<span *ngIf="tab == 2"></span></button>
  </div>
  <input *ngIf="tab == 1" #search class="input_search" type="text" placeholder="Suche" (input)="searchEmitter.emit(search.value);">
</div>
<div class="page-menu-bar" *ngIf="surface == 3">
    <div class="btn_cancel_container" (click)="cancelEmitter.emit()">
        <img class="icon_cancel" src="../../../../assets/cancle.svg">
        <button id="btn-cancel">Abbrechen</button>
    </div>
    <h2>{{driveTyp == 1 ? 'Probefahrt ertellen' : 'Interne Fahrt ertellen'}}</h2>
    <button *ngIf="!authSerive.isSinhuber()" class="btn_submit" (click)="saveEmitter.emit()">Einplanen</button>
</div>
<div class="page-menu-bar" *ngIf="surface == 4">
  <div class="btn_cancel_container" (click)="backEmitter.emit()">
      <img class="icon_cancel" src="../../../../assets/back.svg">
      <button class="btn_cancel">Zurück</button>
  </div>
  <div class="btn_container">
    <!-- <button class="btn_submit" style="margin-right: 20px;" (click)="editCustomerEmitter.emit()">Termin verschieben</button> -->
  </div>
</div>
<div class="page-menu-bar" *ngIf="false">
  <div class="btn_cancel_container" (click)="backEmitter.emit()">
      <img class="icon_cancel" src="../../../../assets/back.svg">
      <button class="btn_cancel">Zurück</button>
  </div>
  <div class="btn_container">
    <button class="btn_submit" style="margin-right: 20px;" (click)="editCustomerEmitter.emit()">PDF EXPORT</button>
    <button class="btn_white" (click)="editEmitter.emit()">Bearbeiten</button>
  </div>
</div>
