import { DropdownHighlighter } from 'src/app/buisness-object/dropdown/DropdownHighlighter';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { VehicleUpholstery } from 'src/app/buisness-object/vehicle/optional/VehicleUpholstery';
import { VehicleUpholsteryColor } from 'src/app/buisness-object/vehicle/optional/VehicleUpholsteryColor';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-additional-container',
  templateUrl: './additional-container.component.html',
  styleUrls: ['./additional-container.component.css'],
  animations: [DropdownIconArrowAnimation]
})
export class AdditionalContainerComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() submitted: boolean;

  public upholsteries: VehicleUpholstery[] = [];
  public upholsteryColors: VehicleUpholsteryColor[] = [];
  public highlighter = new DropdownHighlighter();
  public repairStatuse: string[] = [];
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public showDropdownThree = false;
  public showDropdownFour = false;
  public showDropdownFive = false;
  public showDropdownSix = false;
  public showDropdownSeven = false;
  public showDropdownEight = false;
  public showDropdownNine = false;
  public showDropdownTen = false;
  public showDropdownEleven = false;
  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  @ViewChild('dropdownTwo') dropdownTwo: ElementRef;
  @ViewChild('dropdownThree') dropdownThree: ElementRef;
  @ViewChild('dropdownFour') dropdownFour: ElementRef;
  @ViewChild('dropdownFive') dropdownFive: ElementRef;
  @ViewChild('dropdownSix') dropdownSix: ElementRef;
  @ViewChild('dropdownSeven') dropdownSeven: ElementRef;
  @ViewChild('dropdownEight') dropdownEight: ElementRef;
  @ViewChild('dropdownNine') dropdownNine: ElementRef;
  @ViewChild('dropdownTen') dropdownTen: ElementRef;
  @ViewChild('dropdownEleven') dropdownEleven: ElementRef;
  public listener: () => void;
  public listenerOnKeydown: () => void;

  public upholsteryValues = [];
  public upholsteryColorValues = [];

  constructor(
    private vService: VehicleService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window', 'click', (e: Event) => {
      if(this.showDropdownOne && !this.dropdownOne.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.dropdownTwo.nativeElement.contains(e.target)) this.showDropdownTwo = false;
      if(this.showDropdownThree && !this.dropdownThree.nativeElement.contains(e.target)) this.showDropdownThree = false;
      if(this.showDropdownFour && !this.dropdownFour.nativeElement.contains(e.target)) this.showDropdownFour = false;
      if(this.showDropdownFive && !this.dropdownFive.nativeElement.contains(e.target)) this.showDropdownFive = false;
      if(this.showDropdownSix && !this.dropdownSix.nativeElement.contains(e.target)) this.showDropdownSix = false;
      if(this.showDropdownSeven && !this.dropdownSeven.nativeElement.contains(e.target)) this.showDropdownSeven = false;
      if(this.showDropdownEight && !this.dropdownEight.nativeElement.contains(e.target)) this.showDropdownEight = false;
      if(this.showDropdownNine && !this.dropdownNine.nativeElement.contains(e.target)) this.showDropdownNine = false;
      if(this.showDropdownTen && !this.dropdownTen.nativeElement.contains(e.target)) this.showDropdownTen = false;
      if(this.showDropdownEleven && !this.dropdownEleven.nativeElement.contains(e.target)) this.showDropdownEleven = false;
    });
    this.listenerOnKeydown = this.renderer.listen('window','keydown',(e: KeyboardEvent) => {
      if(this.showDropdownOne) this.highlighter.highlightElement(this.upholsteries, e.key);
      if(this.showDropdownTwo) this.highlighter.highlightElement(this.upholsteryColors, e.key);
    });
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
    if(this.listenerOnKeydown) this.listenerOnKeydown();
  }

  get f() {
    return this.formGroup?.controls;
  }

  getDataServerside() {
    forkJoin([
      this.vService.getUpholsteries(),
      this.vService.getUpholsteryColors(),
    ]).subscribe(([upholsteries, colors]:[VehicleUpholstery[], VehicleUpholsteryColor[]]) => {
      if(upholsteries && colors){
        this.upholsteries = this.sortArray(upholsteries);
        this.upholsteryColors = this.sortArray(colors);
        this.repairStatuse = this.vService.getRepairStatuse();
        this.setDropdownValues();
      }
    })
  }

  setDropdownValues() {
    this.upholsteryValues = [];
    this.upholsteryColorValues = [];
    for(let v of this.upholsteries){
      this.upholsteryValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.upholsteryColors){
      this.upholsteryColorValues.push({
        id: v.id,
        name: v.name
      })
    }
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'upholstery'){
      let index = this.upholsteries.findIndex(v => v.id == id);
      if(index > -1) this.f.upholstery.setValue(this.upholsteries[index]);
    } else if(value === 'upholsteryColor'){
      let index = this.upholsteryColors.findIndex(v => v.id == id);
      if(index > -1) this.f.upholsteryColor.setValue(this.upholsteryColors[index]);
    }
  }

  sortArray(data: any[]): any[] {
    data.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    for(let i = 0; i < data.length; i++){
      if(data[i].name == 'Sonstige'){
        data.push(data[i]);
        data.splice((i),1);
      }
    }
    return data;
  }
}
