<div (window:resize)="onScreenSizeChange()">
    <app-topbar
        [surface]="surface"
        [selectedDetailsTab]="selectedDetailsTab"
        [accessEdit]="accessEdit"
        [currentPageCreate]="currentPageCreate"
        [vehicleTitle]="vehicleTitle"
        [vehicleSubTitle]="vehicleSubTitle"
        [filter]="filter"
        [editMode]="editMode"
        [vehicles]="vehicles"
        [filterForm]="filterForm"
        [sortObject]="sortObject"
        [selectedVehicle]="selectedVehicle"
        [surfacePriceSheet]="surfacePriceSheet"
        [editTyp]="editTyp"
        [vinFlag]="vinFlag"
        [isNewPriceSheet]="isNewPriceSheet"
        [mainMenus]="mainMenus"
        [initialMenuName]="initialMenuName"

        [activeSubMenu$]="activeSubMenu$"
        [activeFilter]="activeFilter"
        [employees]="employees"

        (showCreateEmitter)="openCreate()"
        (closeDetailsEmitter)="closeDetails()"
        (cancelCreateEmitter)="cancelCreateAction()"
        (cancelEditEmitter)="selectionCancelEditVehicle()"
        (updateVehicleEmitter)="validateInputUpdate()"
        (searchEmitter)="null"
        (filterEmitter)="filterDetails()"
        (selectionEditVehicle)="openEdit($event)"
        (saveAsDraftEmitter)="saveAsDraft()"
        (filterDetailEmitter)="filterDetails()"
        (resetFilterEmitter)="resetFilterEmitter()"
        (sortEmitter)="sortEmitter()"
        (resetSortEmitter)="resetSortEmitter()"
        (selectionCreateDokumentEmitter)="selectionCreateDocument($event)"
        (selectionKalkulation)="selectionKalkulation($event)"
        (selectionPriceSheetCancel)="selectionPriceSheetCancel()"
        (selectionPriceSheetSave)="selectionPriceSheetSave()"
        (selectionEditDescrtiption)="selectionEditDescrtiption()"
        (selectionEtikettenDruckEmitter)="selectionEtikettenDruck()"
        (selectionPrintPreisblatt)="selectionPrintPricesheet()"
        (selectionPrintEmitter)="openPdfDialog($event)"
        (showPreisblattEmitter)="showPreisblatt()"
    ></app-topbar>
    <!-- <div *ngIf="listLoading" id="list-loader"></div> -->
    <app-list-container *ngIf="dataReady == true && surface == allSurfaces.LIST"
        [accessEdit]="accessEdit"
        [objectTyp]="'vehicle'"
        [objects]="_vehiclesFiltered.getValue()"
        [showInformation]="showInformation"
        [pageHandler]="pageHandler"
        [filter]="filter"
        [sortObject]="sortObject"
        [filterForm]="filterForm"
        [mainMenus]="mainMenus"
        [activeSubMenu$]="activeSubMenu$"
        [activeSortingColumn]="activeSortingColumn"
        [activeFilter]="activeFilter"
        [employees]="employees"
        (pageEmitter)="pageHandling($event)"
        (openEditEmitter)="openEditFromList($event)"
        (deleteEmitter)="deleteAction($event)"
        (moveEmitter)="moveVehicleAction($event)"
        (printPdfEmitter)="openPdfDialog($event)"
        (printEtikettEmitter)="printEtikett($event)"
        (sellEmitter)="openSellDialog($event)"
        (sortEmitter)="sortEmitter()"
        (removeFilterValueEmitter)="removeFilterValue($event)"
        (selectionCreateDokumentEmitter)="selectionCreateDocument($event)"
        (selectionCopyEmitter)="selectionCopyVehicle($event)"
        (applySortingEmitter)="applySorting($event)"
        (applySpecialFilter)="applySpecialFilter($event)"
        (customFilterEmitter)="customFilter($event)"
        (customFilterOutsideEmitter)="customFilterOutside($event)"
        (changeListTabEmitter)="showInformation = $event"
        (listLoadingEmitter)="listLoading = false"
        (selectionCreateKaufvertragEmitter)="selectionCreateKaufvertrag($event)"
        (calculatePricesheetEmitter)="calculatePricesheetListItem($event)"
        (selectionArchivEmitter)="selectionArchiv($event)"
        (selectionBillImportEmitter)="selectionBillImport($event)"
        (createCreditEmitter)="selectionCreateCredit($event)"
    ></app-list-container>
    <app-create-container *ngIf="surface == allSurfaces.CREATE"
        [currentPageCreate]="currentPageCreate"
        [formGroupVehicle]="formGroupVehicle"
        [submitted]="submitted"
        [brands]="brands"
        [drives]="drives"
        [bodyColors]="bodyColors"
        [fuels]="fuels"
        [bodyTyps]="bodyTyps"
        [transmissions]="transmissions"
        [categories]="categories"
        [bodyTypOptions]="bodyTypOptions"
        [fuelOptions]="fuelOptions"
        [colorOptions]="colorOptions"
        [driveOptions]="driveOptions"
        [brandOptions]="brandOptions"
        [categoryOptions]="categoryOptions"
        [transmissionOptions]="transmissionOptions"
        [equipments]="equipments"
        [newVehicle]="selectedVehicle"
        (changePageEmitter)="validateInput($event)"
        (updateVehicleTitle)="vehicleTitle = $event"
        (createVehicleEmitter)="createVehicle(1)"
        (vinEmitter)="getVehicleByVin($event)"
        (saveAsDraftEmitter)="saveAsDraft()"
        (selectionBCAImportEmitter)="selectionBCAImport($event)"
    ></app-create-container>
    <div id="details-page" *ngIf="surface == allSurfaces.DETAIL || surface == allSurfaces.TICKET || surface == allSurfaces.KALKULATION || surface == allSurfaces.DOCUMENT">
      <div id="overview-header">
        <div class="overview-header-column" *ngIf="!(surfacePriceSheet != 3)">
          <div class="tab-buttons-wrapper">
            <app-restricted-button
              [name]="'Standard'"
              [access]="'vehicle_view'"
              [isTabButton]="true"
              [isTabButtonActive]="selectedDetailsTab == 1"
              [disabled]="surfacePriceSheet != 3 || editMode"
              (clickEmitter)="changeDetailsTab(1)">
            </app-restricted-button>
            <app-restricted-button
              [name]="'Verkauf'"
              [access]="'vehicle_view'"
              [isTabButton]="true"
              [isTabButtonActive]="selectedDetailsTab == 2"
              [disabled]="surfacePriceSheet != 3 || editMode"
              (clickEmitter)="changeDetailsTab(2)">
            </app-restricted-button>
            <app-restricted-button
              [name]="'Preiskalkulation'"
              [access]="['price_calc_new','price_calc_old']"
              [isTabButton]="true"
              [isTabButtonActive]="selectedDetailsTab == 3"
              [disabled]="editMode"
              (clickEmitter)="changeDetailsTab(3)">
            </app-restricted-button>
            <app-restricted-button
              [name]="'Dokumente'"
              [access]="'vehicle_view'"
              [isTabButton]="true"
              [isTabButtonActive]="selectedDetailsTab == 5"
              [disabled]="surfacePriceSheet != 3 || editMode"
              (clickEmitter)="changeDetailsTab(5)">
            </app-restricted-button>
            <app-restricted-button
              [name]="'Tickets'"
              [access]="'tickets_view'"
              [isTabButton]="true"
              [isTabButtonActive]="selectedDetailsTab == 4"
              [disabled]="surfacePriceSheet != 3 || editMode"
              (clickEmitter)="changeDetailsTab(4)">
            </app-restricted-button>
          </div>
        </div>
        <div class="overview-header-column" *ngIf="surfacePriceSheet == 3">
          <button id="btn-next" [disabled]="editMode" class="btn-tab btn-tab-first" [class.btn-tab-disabled]="!showPreviousBtnDetails" (click)="selectionPreviousVehicle()">Vorheriges Fahrzeug</button>
          <button id="btn-previous" [disabled]="editMode" class="btn-tab btn-tab-last" [class.btn-tab-disabled]="!showNextBtnDetails" (click)="selectionNextVehicle()">Nächstes Fahrzeug</button>
        </div>
      </div>
      <app-overview-container *ngIf="surface == allSurfaces.DETAIL && editTyp == 1"
          [activeSubMenu]="_activeSubMenu.getValue()"
          [selectedDetailsTab]="selectedDetailsTab"
          [selectedVehicle]="selectedVehicle"
          [formGroupVehicle]="formGroupVehicle"
          [formGroupPriceSheet]="pricesheetForm"
          [editMode]="editMode"
          [submitted]="submitted"
          [screenSize]="screenSize"
          [updateSuccess]="updateSuccess"
          (updateVehicleTitle)="vehicleTitle = $event"
      ></app-overview-container>
      <app-overview-messages *ngIf="surface == allSurfaces.TICKET"
          [vehicle]="selectedVehicle"
      ></app-overview-messages>
      <app-edit-description *ngIf="surface == allSurfaces.DETAIL && editTyp == 2 && formGroupVehicle"
          [form]="formGroupVehicle"
      ></app-edit-description>
      <app-price-sheet *ngIf="surface == allSurfaces.KALKULATION && surfacePriceSheet"
          [vehicle]="selectedVehicle"
          [surface]="surfacePriceSheet"
          [form]="pricesheetForm"
          [formVehicle]="formGroupVehicle"
          [submitted]="submitted"
          [customers]="customers"
          [employees]="employees"
          (onPriceSheetChangesEmitter)="onPriceSheetChanges()"
          (selectionEditSteuersatzAcceptedVorEmitter)="selectionEditSteuersatzAcceptedVor()"
          (selectionEditSteuersatzAcceptedNachEmitter)="selectionEditSteuersatzAcceptedNach()"
          (addReparaturVorEmitter)="addReparaturVor()"
          (removeReparaturVorEmitter)="removeReparaturVor($event)"
          (addReparaturNachEmitter)="addReparaturNach()"
          (removeReparaturNachEmitter)="removeReparaturNach($event)"
      ></app-price-sheet>
      <app-document-view *ngIf="surface == allSurfaces.DOCUMENT" [obj]="selectedVehicle"></app-document-view>
    </div>
</div>
<app-conflict-dialog
  [show]="updateConflicts != null"
  [conflicts]="updateConflicts"
  (submitEventEmitter)="submitConflicts($event)"
></app-conflict-dialog>
<app-dialog-bca-import-errors *ngIf="showDialogBCAImport"
  [vehicleBCAErrors]="vehicleBCAErrors"
  [vehicleBCASTotalNumber]="vehicleBCASTotalNumber"
  (selectionCloseEmitter)="selectionCloseBCAImportErrorsDialog()"
></app-dialog-bca-import-errors>
<app-dialog-pdf-print *ngIf="showDialogPrint"
  [vehicleId]="dialogPrintPDFVehicleId"
  (selectionCloseEmitter)="showDialogPrint = false"
  (selectionPrintWeb)="selectionPrintWebPricesheet($event)"
  (selectionPrint)="selectionPrintPricesheet($event)"
></app-dialog-pdf-print>
