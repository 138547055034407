import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.css']
})
export class ViewDocumentComponent implements OnInit {
  @Input() document: any;
  @Input() isCreate: boolean;

  public objectUrl: any;

  @Output() sendEmitter = new EventEmitter<any>();
  @Output() saveAsDraftEmitter = new EventEmitter<any>();

  constructor(
    private domsanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    const blob = new Blob([this.document.pdf], { type: 'application/pdf' });
    const objectUrl = window.URL.createObjectURL(blob);
    document.querySelector("iframe").src = objectUrl;

    // const iframe = document.createElement('iframe');
    // iframe.src = objectUrl;
    // document.body.appendChild(iframe);
    // iframe.style.display = 'none';

    // iframe.onload = function() {
    //   setTimeout(function() {
    //     iframe.focus();
    //     iframe.contentWindow.print();
    //   }, 1);
    // };
    // let file = new Blob([this.document.pdf], {type: 'application/pdf'});
    // var fileURL = URL.createObjectURL(file);
    // window.open(fileURL);
    //return this.domsanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
  }
}
