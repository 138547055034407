<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button class="button-icon-start" (click)="selectionCloseEmitter.emit()">
      <img class="button-icon-start-img" src="./assets/back.svg"/>
      <span class="button-icon-start-span">Zurück</span>
    </button>
    <h2>#{{obj?.id}} {{obj.getFullName()}}</h2>
  </div>
  <div class="header-button-wrapper">
    <!-- <button class="button_type_2" (click)="shwoActions = !shwoActions" appDropdown [show]="shwoActions" (changedValueEmitter)="shwoActions = $event">
      Aktionen
      <img src="./assets/dropdown-arrow-blue.svg">
      <div *ngIf="shwoActions">
        <button
          (click)="selectionEditEmitter.emit(obj); shwoActions = false">
          Bearbeiten
        </button>
      </div>
    </button> -->
    <app-restricted-button
      [name]="'Bearbeiten'"
      [access]="'user_edit'"
      [isPrimary]="true"
      (clickEmitter)="selectionEditEmitter.emit(obj)">
    </app-restricted-button>
    <!-- <button class="btn_submit" (click)="selectionEditEmitter.emit(obj)">Bearbeiten</button> -->
  </div>
</div>
<div class="body-base-style">
  <!-- <div class="btn-tab-wrapper">
    <button class="btn-tab btn-tab-first" (click)="null">Vorherige Reifen</button>
    <button class="btn-tab btn-tab-last" (click)="null">Nächste Reifen</button>
  </div> -->
  <div id="drive-details-body">
    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Stammdaten</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Mitarbieiter-ID</label>
            <label class="overview_record_value">{{obj.id}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Anrede</label>
            <label class="overview_record_value">{{getInputValue(salutation)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Titel</label>
            <label class="overview_record_value">{{getInputValue(obj.title)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Vorname</label>
            <label class="overview_record_value">{{obj.firstName}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Nachname</label>
            <label class="overview_record_value">{{obj.lastName}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Geburtsdatum</label>
            <label class="overview_record_value">{{getInputValueDate(obj.birthdate)}}</label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Kontakt und Anschrift</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Straße, Nr.</label>
            <label class="overview_record_value">{{getInputValue(obj.address?.street)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">PLZ</label>
            <label class="overview_record_value">{{getInputValue(obj.address?.postalCode)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Ort</label>
            <label class="overview_record_value">{{getInputValue(obj.address?.city)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Land</label>
            <label class="overview_record_value">{{getInputValue(obj.address?.country)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">E-Mail</label>
            <label class="overview_record_value">{{getInputValue(obj.email)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Telefon</label>
            <label class="overview_record_value">{{getInputValue(obj.phone)}}</label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Führerschein</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerschein-Nummer</label>
            <label class="overview_record_value">{{getInputValue(obj.authorization_number)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerschein-Behörde</label>
            <label class="overview_record_value">{{getInputValue(obj.authorization_authority)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerschein-Datum</label>
            <label class="overview_record_value">{{getInputValueDate(obj.authorization_date)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerschein-Gruppen</label>
            <label class="overview_record_value">{{getInputValue(obj.authorization_groups)}}</label>
          </div>
        </div>
      </app-collapsible>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Systemrelevante Informationen</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Berechtigung</label>
            <label class="overview_record_value">{{getInputValue(obj.permission_group?.name)}}</label>
          </div>
          <div class="overview_record">
            <label>Status</label>
            <label [appFormatValue]="'string'" [value]="obj.active?'Aktiv':'Inaktiv'" [ngClass]="aktiveColorClass"></label>
          </div>
          <!-- <div class="overview_record">
            <label class="overview_record_lbl">Planungsfarbe</label>
            <div class="tag-input" [ngStyle]="{'background-color': obj.planning_color}"></div>
          </div> -->
          <div class="overview_record">
            <label class="overview_record_lbl">Username</label>
            <label class="overview_record_value">{{getInputValue(obj.username)}}</label>
          </div>
        </div>
      </app-collapsible>
    </div>
    <div class="column">
      <app-transactions
        [obj]="obj"
      ></app-transactions>
      <!--app-collapsible [isCollapsible]>
        <h3 collapsible-header>Historie</h3>
        <div collapsible-content>
          <div class="overview_files">
            <div class="file" *ngFor="let trans of transactions; let last = last">
              <div class="file-inner" (click)="null">
                <div class="file-img" [ngStyle]="{'background-color': TransactionHelper.getTransactionColor(trans.dataTyp)}">
                  <img [src]="TransactionHelper.getTransactionIcon(trans.dataTyp)">
                </div>
                <div class="file-info-wrapper">
                  <label>{{getInputValue(trans.text)}}</label>
                  <label class="italic-small">---</label>
                </div>
              </div>
              <div class="file-date-wrapper">
                <label>{{getInputValueDateTime(trans.timestamp)}}</label>
              </div>
            </div>
          </div>
        </div>
      </app-collapsible-->
    </div>
  </div>
</div>
