type Enum = { [s: string]: (string|number) };
export class EnumHelper {
    public getRawValue<T extends Enum>(enumSrc: T, id: number): (string|number) {
        if(id == undefined) {
            return undefined;
        }
        let value = Object.values(enumSrc)[id];
        return value;
    }
    public getEnumValue<T extends Enum>(enumSrc: T, id: number): T[keyof T] {
        let key = Object.keys(enumSrc)[id];
        let value = <any> enumSrc[key];
        return value;
    }
    public getRawValueList<T extends Enum>(enumSrc: T): (string|number)[] {
        let values = Object.values(enumSrc);
        return values;
    }
    public getEnumValueList<T extends Enum>(enumSrc: T): T[keyof T][] {
        let keys = Object.keys(enumSrc)
        let values: T[keyof T][] = [];
        for(let key of keys) {
            values.push(<any> enumSrc[key]);
        }
        return values;
    }
    public getIndex<T extends Enum>(enumSrc: T, e: T[keyof T]): number {
        let values = Object.values(enumSrc);
        for(let i = 0; i < values.length; i++) {
            if(values[i] == e) {
                return i
            }
        }
        return -1;
    }
}

