<div id="dialog_window">
  <div id="dialog_container">
    <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
    <h1 >E-Mail zur Selbstständigen Abholung Versenden</h1>
    <div id="dialog_conainter_body" class="body">
      <label>
          Bitte geben Sie die Tresornummer und den Schlüsselcode ein, um dem Kunden eine E-Mail mit den Informationen für die selbstständige Abholung zu senden.
      </label>
      <form [formGroup]="form">
        <div class="form-wrapper">
            <label class="overview_record_lbl">Tresornummer*</label>
            <input type="text" placeholder="---" formControlName="vault_number" [class.input_invalid]="submitted && form.get('vault_number').errors">
            <label class="overview_record_lbl">Schlüsselcode*</label>
            <input type="text" placeholder="---" formControlName="key_code" [class.input_invalid]="submitted && form.get('key_code').errors">
        </div>
      </form>
    </div>
    <div class="dialog_btn_container">
      <button class="btn_label" (click)="selectionCloseEmitter.emit()">Abbrechen</button>
      <button class="btn_submit_dark" (click)="submit()">Senden</button>
    </div>
  </div>
</div>