import { Form, FormGroup } from "@angular/forms";
import { EnumHelper } from "src/app/helpers/EnumHelper";
import { STEUERSATZVALUES, SteuersatzValue } from "src/app/helpers/Enums";
import { PriceSheetForm } from "./form/PriceSheetForm";
import { Reparatur } from "./PriceSheet";
import { DialogService } from "src/app/service/dialog/dialog.service";
import { VehicleFuel } from "../vehicle/basic/VehicleFuel";
import { get } from "http";

/*
  PKW:
  Der CO2-Abzugsbetrag wird jährlich um den Wert 5 gesenkt.
  Der Malus-Grenzwert wird jährlich um den Wert 15 abgesenkt.
  Der Malus-Betrag wird jährlich um den Wert 10 erhöht.
  Der Höchststeuersatz wird jährlich um 10 Prozentpunkte erhöht.
*/
const NOVABERECHNUNG_2024 = {
  date: "1.1.2024",
  co2_abzugsbetrag: 97, // g/km
  divider: 5,
  g_grenzwert: 155, // g/km
  zusatz_pro_g: 80, // euro
  bonus_betraege: { // einmaliger Abzug euro
    benzin: 350,
    diesel: 350,
  },
  hoeststeuersatz: 80, // prozent
}
const NOVABERECHNUNG_2023 = {
  date: "1.1.2023",
  co2_abzugsbetrag: 102,
  divider: 5,
  g_grenzwert: 170,
  zusatz_pro_g: 70,
  bonus_betraege: {
    benzin: 350,
    diesel: 350,
  },
  hoeststeuersatz: 70,
}
const NOVABERECHNUNG_2022 = {
  date: "1.1.2022",
  co2_abzugsbetrag: 107,
  divider: 5,
  g_grenzwert: 185,
  zusatz_pro_g: 60,
  bonus_betraege: {
    benzin: 350,
    diesel: 350,
  },
  hoeststeuersatz: 60,
}
const NOVABERECHNUNG_2021 = {
  date: "1.1.2021",
  co2_abzugsbetrag: 112,
  divider: 5,
  g_grenzwert: 200,
  zusatz_pro_g: 50,
  bonus_betraege: {
    benzin: 350,
    diesel: 350,
  },
  hoeststeuersatz: 50,
}

const NOVABERECHNUNG_2020 = {
  date: "1.1.2020",
  co2_abzugsbetrag: 115,
  divider: 5,
  g_grenzwert: 275,
  zusatz_pro_g: 40,
  bonus_betraege: {
    benzin: 350,
    diesel: 350,
  },
  hoeststeuersatz: 32,
}
const NOVABERECHNUNG_2016 = {
  date: "1.1.2016",
  co2_abzugsbetrag: 90,
  divider: 5,
  g_grenzwert: 250,
  zusatz_pro_g: 20,
  bonus_betraege: {
    benzin: 300,
    diesel: 300,
  },
  hoeststeuersatz: 50,
}
const NOVABERECHNUNG_2015 = {
  date: "1.1.2015",
  co2_abzugsbetrag: 90,
  divider: 5,
  g_grenzwert: 250,
  zusatz_pro_g: 20,
  bonus_betraege: {
    benzin: 400,
    diesel: 400,
  },
  hoeststeuersatz: 50,
}
const NOVABERECHNUNG_2014 = {
  date: "1.3.2014",
  co2_abzugsbetrag: 90,
  divider: 5,
  g_grenzwert: 250,
  zusatz_pro_g: 20,
  bonus_betraege: {
    benzin: 450,
    diesel: 350,
  },
  hoeststeuersatz: 50,
}
const NOVABERECHNUNG_BIS_2013 = {
  date: "1.1.2013",
  co2_abzugsbetrag: 95,
  divider: 5,
  g_grenzwert: 150,
  g_grenzwert_2: 170,
  g_grenzwert_3: 210,
  zusatz_pro_g: 25,
  bonus_betraege: {
    benzin: 300,
    diesel: 300,
  },
  hoeststeuersatz: 50,
}

const GEBRAUCHT = {
  monate: 6,
  km: 6000,
}

//ab NOVABERECHNUNG_2014 ohne 20% ust  bzw ohne Novazuschlag


export class PriceSheetCalculater {

  static calculateNova(co2_emission: number, vk_netto: number, ez_year: number, fuel_typ: VehicleFuel, form: FormGroup): number {
    const novaSatz = form.controls["nova_satz"].value;
    if(ez_year){
      let NOVABerechnung = this.getNOVABerechnung(Number(ez_year));
      if(NOVABerechnung && co2_emission){
        let nova = (vk_netto * (novaSatz/100));
        if(co2_emission > NOVABerechnung.g_grenzwert){
          for(let i = co2_emission; i <= NOVABerechnung.g_grenzwert; i++){
            nova += NOVABerechnung.zusatz_pro_g;
          }
        }
        return Number(nova.toFixed(2));
      } else {
        return null;
      }
    }
  }
  static getNovaSatz(co2_emission: number, NOVABerechnung: any): number {
    let satz = Math.round((co2_emission - NOVABerechnung.co2_abzugsbetrag) / NOVABerechnung.divider);
    if(satz > NOVABerechnung.hoeststeuersatz) {
      satz = NOVABerechnung.hoeststeuersatz;
    }
    return satz < 0 ? 0 : satz;
  }
  static getNovaSatzValue(form: FormGroup, isStandard: boolean): number {
    const co2_emission = form.controls["co2_emission"].value;
    let datum = null;
    if(form.controls["steuersatz"].value == 3 || form.controls["steuersatz"].value == 4) {
      if(form.controls["fahrzeug_typ"].value.id == 1) { //neuwagen
        datum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
      } else {
        datum = form.controls["ez"].value;
      }
    } else {
      if(isStandard) {
        if(form.controls["vehicle_imported"].value == 1 && form.controls["fahrzeug_typ"].value.id == 1) { //import
          datum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
        } else if(form.controls["vehicle_imported"].value == 1) { //gebraucht
          datum = form.controls["ez"].value;
        }
      } else {
        if(form.controls["fahrzeug_typ"].value.id == 1) { //neuwagen
          datum = new Date().getFullYear() + "";
        }
      }

    }
    const ez_year = datum ? Number(datum.slice(0,4)) : null;
    if(ez_year){
      let NOVABerechnung = this.getNOVABerechnung(ez_year);
     if(NOVABerechnung){
      const novaSatz = this.getNovaSatz(co2_emission, NOVABerechnung);
      return novaSatz;
     }
    }
    return null;
  }

  static getNOVABerechnung(ez_year: number): any {
    switch (ez_year) {
      case 2024: return NOVABERECHNUNG_2024;  break;
      case 2023: return NOVABERECHNUNG_2023;  break;
      case 2022: return NOVABERECHNUNG_2022;  break;
      case 2021: return NOVABERECHNUNG_2021;  break;
      case 2020: return NOVABERECHNUNG_2020;  break;
      case 2019:
      case 2018:
      case 2017:
      case 2016: return NOVABERECHNUNG_2016;  break;
      case 2015: return NOVABERECHNUNG_2015;  break;
      case 2014: return NOVABERECHNUNG_2014;  break;
      default: break;
    }
    if(ez_year < 2014){
      return NOVABERECHNUNG_BIS_2013;
    }
    return null;
  }

  static setSteuersatzNachkalulation(form: FormGroup) {
    let steuersatz = form.controls["steuersatz"].value;
    if(steuersatz == 1) form.controls["steuersatz_nach"].setValue(1);
    else if(steuersatz == 2) form.controls["steuersatz_nach"].setValue(2);
    else if(steuersatz == 3 || steuersatz == 4) form.controls["steuersatz_nach"].setValue(1);
  }

  static getNovaVor(form: FormGroup, isStandard: boolean): number {
    if(isStandard) {
      const fahrzeug_typ = form.controls["fahrzeug_typ"].value;
      if(fahrzeug_typ?.id == 1){ //Wenn Steuersatz 20% & Neuwagen
        const co2_emission = form.controls["co2_emission"].value;
        const vk_netto = form.controls["verkaufspreis_netto_vor"].value;
        const fuel_typ = form.controls["fuel_typ"].value;
        const datum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, form);
          return nova;
        } else return null;
      } else if(form.controls["vehicle_imported"].value == 0 && fahrzeug_typ?.id != 1) { //Wenn Steuersatz 20% & nicht Neuwagen
        return 0;
      } else if(form.controls["vehicle_imported"].value == 1) {
        const co2_emission = form.controls["co2_emission"].value;
        const vk_netto = form.controls["verkaufspreis_netto_vor"].value;
        const fuel_typ = form.controls["fuel_typ"].value;
        let datum;
        if(fahrzeug_typ?.id == 1){ //neuwagen
          datum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
        } else {
          datum = form.controls["ez"].value;
        }
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, form);
          return nova;
        }
        return null;
      }
      return 0;
    } else {
      const fahrzeug_typ = form.controls["fahrzeug_typ"].value;
      if(form.controls["steuersatz"].value == 1 && fahrzeug_typ?.id == 1){ //Wenn Steuersatz 20% & Neuwagen
        const co2_emission = form.controls["co2_emission"].value;
        const vk_netto = form.controls["verkaufspreis_netto_vor"].value;
        const fuel_typ = form.controls["fuel_typ"].value;
        const datum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, form);
          return nova;
        } else return null;
      } else if(form.controls["steuersatz"].value == 1 && fahrzeug_typ?.id != 1) { //Wenn Steuersatz 20% & nicht Neuwagen
        return 0;
      } else if(form.controls["steuersatz"].value == 2 && fahrzeug_typ?.id != 1) { //Diff Steuersatz 0% & nicht Neuwagen
        return 0;
      } else if(form.controls["steuersatz"].value == 3 || form.controls["steuersatz"].value == 4) { //Wenn vom Ausland BRD (Steuersatz 19%)
        const co2_emission = form.controls["co2_emission"].value;
        const vk_netto = form.controls["verkaufspreis_netto_vor"].value;
        const fuel_typ = form.controls["fuel_typ"].value;
        let datum;
        if(fahrzeug_typ?.id == 1){ //neuwagen
          datum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
        } else {
          datum = form.controls["ez"].value;
        }
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, form);
          return nova;
        }
        return null;
      }
      return 0;
    }
  }

  static getNovaNach(form: FormGroup, isStandard: boolean): number {
    if(isStandard) {
      const fahrzeug_typ = form.controls["fahrzeug_typ"].value;
      if(fahrzeug_typ?.id == 1){ //Wenn Steuersatz 20% & Neuwagen
        const co2_emission = form.controls["co2_emission"].value;
        const vk_netto = form.controls["verkaufspreis_netto"].value;
        const fuel_typ = form.controls["fuel_typ"].value;
        const datum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, form);
          return nova;
        } else return null;
      } else if(form.controls["vehicle_imported"].value == 0 && fahrzeug_typ?.id != 1) { //Wenn Steuersatz 20% & nicht Neuwagen
        return 0;
      }else if(form.controls["vehicle_imported"].value == 1) { //Wenn vom Ausland BRD (Steuersatz 19%)
        const co2_emission = form.controls["co2_emission"].value;
        const vk_netto = form.controls["verkaufspreis_netto"].value;
        const fuel_typ = form.controls["fuel_typ"].value;
        let datum;
        if(fahrzeug_typ?.id == 1){ //neuwagen
          datum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
        } else {
          datum = form.controls["ez"].value;
        }
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, form);
          return nova;
        }
        return null;
      }
      return 0;
    } else {
      const fahrzeug_typ = form.controls["fahrzeug_typ"].value;
      if(form.controls["steuersatz"].value == 1 && fahrzeug_typ?.id == 1){ //Wenn Steuersatz 20% & Neuwagen
        const co2_emission = form.controls["co2_emission"].value;
        const vk_netto = form.controls["verkaufspreis_netto"].value;
        const fuel_typ = form.controls["fuel_typ"].value;
        const datum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, form);
          return nova;
        } else return null;
      } else if(form.controls["steuersatz"].value == 1 && fahrzeug_typ?.id != 1) { //Wenn Steuersatz 20% & nicht Neuwagen
        return 0;
      } else if(form.controls["steuersatz"].value == 2 && fahrzeug_typ?.id != 1) { //Diff Steuersatz 0% & nicht Neuwagen
        return 0;
      } else if(form.controls["steuersatz"].value == 3 || form.controls["steuersatz"].value == 4) { //Wenn vom Ausland BRD (Steuersatz 19%)
        const co2_emission = form.controls["co2_emission"].value;
        const vk_netto = form.controls["verkaufspreis_netto"].value;
        const fuel_typ = form.controls["fuel_typ"].value;
        let datum;
        if(fahrzeug_typ?.id == 1){ //neuwagen
          datum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
        } else {
          datum = form.controls["ez"].value;
        }
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, form);
          return nova;
        }
        return null;
      }
      return 0;
    }
  }

  static getEKNetto(form: FormGroup): number {
    const steuer = STEUERSATZVALUES[new EnumHelper().getEnumValue(SteuersatzValue,form.controls["steuersatz"].value-1)];
    const ek_brutto = form.controls["einkaufspreis_brutto"].value;
    const ek_netto = (ek_brutto / ((100+steuer)/100));
    return Number(ek_netto.toFixed(2));
  }
  static getEKButto(form: FormGroup): number {
    const steuer = STEUERSATZVALUES[new EnumHelper().getEnumValue(SteuersatzValue,form.controls["steuersatz"].value-1)];
    const ek_netto = form.controls["einkaufspreis_netto"].value;
    const ek_brutto = ek_netto * ((steuer/100)+1);
    return Number(ek_brutto.toFixed(2));
  }
  static getVKBruttoVor(form: FormGroup): number {
    return form.controls["minimaler_verkaufpreis"].value;
  }
  static getVKBrutto(form: FormGroup): number {
    return form.controls["verkaufspreis_brutto"].value;
    const steuer = STEUERSATZVALUES[new EnumHelper().getEnumValue(SteuersatzValue,form.controls["steuersatz_nach"].value-1)];
    const vk_netto = form.controls["verkaufspreis_netto"].value;
    if(steuer != 0) {
      let brutto = vk_netto * ((steuer/100)+1);
      return Number(brutto.toFixed(2));
    } else {
      const ust = form.controls["ust"].value;
      if(ust){
        const vk_brutto = vk_netto + ust;
        return Number(vk_brutto.toFixed(2));
      } else return null;

    };
  }
  static getVKBruttoForeignLand(form: FormGroup): number {
    const summe_nova = form.controls["summe_nova"].value;
    const ohne_nova_inkl_ust = form.controls["ohne_nova_inkl_ust"].value;
    let vk_brutto = summe_nova + ohne_nova_inkl_ust;
    return Number(vk_brutto.toFixed(2));
  }
  static getVKNetto(form: FormGroup): number {
    if(form.controls["steuersatz"].value == 1){
      const steuerSatzValue = 20;
      let vk_brutto = this.getVKBrutto(form)
      if(vk_brutto) {
        const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
        return Number(vk_netto.toFixed(2));
      } else {
        return undefined;
      }
    } else if(form.controls["steuersatz"].value == 2){
      return null;
    } else if(form.controls["steuersatz"].value == 3 || form.controls["steuersatz"].value == 4){
      const steuerSatzValue = 19;
      let vk_brutto = this.getVKBrutto(form);
      if(vk_brutto) {
        const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
        return Number(vk_netto.toFixed(2));
      }
    }
    return null;
  }

  static getVKNettoVorStandard(form: FormGroup): number {
    let vk_brutto = form.controls["preis_anschreiben"].value;
    let nova_total = form.controls["summe_nova_vor"].value;
    let ust = form.controls["ust_vor"].value;
    let vk_netto = vk_brutto - nova_total - ust;
    return Number(vk_netto.toFixed(2));
  }

  static getVKNettoVor(form: FormGroup, isStandard: boolean): number {
    if(isStandard) {
      if(form.controls["steuersatz"].value == 1) {
        const steuerSatzValue = 20;
        let vk_brutto = this.getVKBruttoVor(form);
        if(vk_brutto) {
          const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
          return Number(vk_netto.toFixed(2));
        }
      } else {
        return null;
      }
    } else {
      if(form.controls["steuersatz"].value == 1){
        const steuerSatzValue = 20;
        let vk_brutto = this.getVKBruttoVor(form)
        if(vk_brutto) {
          const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
          return Number(vk_netto.toFixed(2));
        } else {
          return 0;
        }
      } else if(form.controls["steuersatz"].value == 2){
        return null;
      } else if(form.controls["steuersatz"].value == 3 || form.controls["steuersatz"].value == 4){
        const steuerSatzValue = 19;
        let vk_brutto = this.getVKBruttoVor(form);
        if(vk_brutto) {
          const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
          return Number(vk_netto.toFixed(2));
        }
      }
      return null;
    }

  }

  static getVKNettoNachStandard(form: FormGroup): number {
    let vk_brutto = form.controls["verkaufspreis_brutto"].value;
    let nova_total = form.controls["summe_nova"].value;
    let ust = form.controls["ust"].value;
    let vk_netto = vk_brutto - nova_total - ust;
    return Number(vk_netto.toFixed(2));
  }

  static getVKNettoNach(form: FormGroup): number {
    if(form.controls["steuersatz"].value == 1){
      const steuerSatzValue = 20;
      let vk_brutto = this.getVKBrutto(form)
      if(vk_brutto) {
        const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
        return Number(vk_netto.toFixed(2));
      } else {
        return 0;
      }
    } else if(form.controls["steuersatz"].value == 2){
      return null;
    } else if(form.controls["steuersatz"].value == 3 || form.controls["steuersatz"].value == 4){
      const steuerSatzValue = 19;
      let vk_brutto = this.getVKBrutto(form);
      if(vk_brutto) {
        const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
        return Number(vk_netto.toFixed(2));
      }
    }
    return null;
  }

  static getUSTVorStandard(form: FormGroup): number {
    let vk_brutto = form.controls["preis_anschreiben"].value;
    let ek_netto = form.controls["einkaufspreis_netto"].value;
    let nova_total = form.controls["summe_nova_vor"].value;
    if(form.controls["steuersatz"].value == 1){
      const ust = (vk_brutto - nova_total) / 6;
      return Number(ust.toFixed(2));
    } else {
      const ust = (vk_brutto - nova_total - ek_netto) / 6;
      if(ust < 0) return 0;
      return Number(ust.toFixed(2));
    }
  }

  static getPredictedVkBrutto(form: FormGroup): number {
    let ek_netto = form.controls["einkaufspreis_netto"].value;

    return 0;
  }

  static getGuVExportVorUst(form: FormGroup): number {
    let export_price = form.controls["export_price"].value;
    if(form.controls["steuersatz"].value == 1){
        return Number((export_price/1.2*0.2).toFixed(2));
    } else if(form.controls["steuersatz"].value == 2){
        let ek_netto = form.controls["einkaufspreis_netto"].value;
        if(export_price && ek_netto){
          if(ek_netto > export_price) return 0;
          const ust = (export_price - ek_netto)/6;
          return Number(ust.toFixed(2));
        }
    }
    return null;
  }

  static getUSTVor(form: FormGroup, isStandard: boolean): number {
    let vk_brutto = form.controls["minimaler_verkaufpreis"].value;
    if(form.controls["steuersatz"].value == 1){
      if(isStandard) {
        let vk_netto = form.controls["verkaufspreis_netto_vor"].value;
        if(form.controls["vehicle_imported"].value == 1) {
          let malusBonus = form.controls["malus_bonus"].value;
          const ust = vk_brutto - vk_netto + malusBonus - form.controls["nova_vor"].value;
          return Number(ust.toFixed(2));
        } else {
          const ust = vk_brutto - vk_netto;
          return Number(ust.toFixed(2));
        }
      } else {
        let vk_netto = form.controls["verkaufspreis_netto_vor"].value;
        const ust = vk_brutto - vk_netto;
        return Number(ust.toFixed(2));
      }
    } else if(form.controls["steuersatz"].value == 2){
      if(isStandard) {
        let ek_netto = form.controls["einkaufspreis_netto"].value;
        if(form.controls["vehicle_imported"].value == 1) {
          let malusBonus = form.controls["malus_bonus"].value;
          if(vk_brutto && ek_netto){
            const ust = (vk_brutto - ek_netto)/6  + malusBonus - form.controls["nova_vor"].value;
            return Number(ust.toFixed(2));
          }
        } else {
          if(vk_brutto && ek_netto){
            const ust = (vk_brutto - ek_netto)/6;
            return Number(ust.toFixed(2));
          }
        }

      } else {
        let ek_netto = form.controls["einkaufspreis_netto"].value;
        if(vk_brutto && ek_netto){
          const ust = (vk_brutto - ek_netto)/6;
          return Number(ust.toFixed(2));
        }
      }

    }
    return null;
  }

  static getUSTNach(form: FormGroup): number {
    let vk_brutto = form.controls["verkaufspreis_brutto"].value;
    if(form.controls["steuersatz"].value == 1){
      let vk_netto = form.controls["verkaufspreis_netto"].value;
      const ust = vk_brutto - vk_netto;
      return Number(ust.toFixed(2));
    } else if(form.controls["steuersatz"].value == 2){
      let ek_netto = form.controls["einkaufspreis_netto"].value;
      if(vk_brutto && ek_netto){
        const ust = (vk_brutto - ek_netto)/6;
        return Number(ust.toFixed(2));
      }
    }
    return null;
  }

  static getUSTNachStandard(form: FormGroup): number {
    let vk_brutto = form.controls["verkaufspreis_brutto"].value;
    let ek_netto = form.controls["einkaufspreis_netto"].value;
    let nova_total = form.controls["summe_nova"].value;
    if(form.controls["steuersatz"].value == 1){
      const ust = (vk_brutto - nova_total) / 6;
      return Number(ust.toFixed(2));
    } else {
      const ust = (vk_brutto -nova_total - ek_netto) / 6;
      if(ust < 0) return 0;
      return Number(ust.toFixed(2));
    }
  }

  static getVorSteuerDifferenz(form: FormGroup) {
    const ek_brutto = form.controls["einkaufspreis_brutto"].value;
    const ek_netto = form.controls["einkaufspreis_netto"].value;
    const vorsteuer = ek_brutto - ek_netto;
    return Number(vorsteuer.toFixed(2));
  }
  static getVorSteuerDifferenzNach(form: FormGroup) {
    const ek_brutto = form.controls["minimaler_verkaufpreis"].value;
    const ek_netto = form.controls["verkaufspreis_netto"].value;
    const vorsteuer = ek_brutto - ek_netto;
    return Number(vorsteuer.toFixed(2));
  }
  static getAufschlag(form: FormGroup): number {
    const entgelt_ohne_nova = form.controls["entgelt_ohne_nova"].value;
    const ek_netto = form.controls["einkaufspreis_netto"].value;
    const aufschlag = entgelt_ohne_nova - ek_netto;
    return aufschlag;
  }
  static getMalusBonus(form: FormGroup, isStandard: boolean): number {
    const fahrzeug_typ = form.controls["fahrzeug_typ"].value;
    const fuel_typ = form.controls["fuel_typ"].value;
    if(!this.isVomAusland(form, isStandard) && fahrzeug_typ?.id == 2){ // 2 = gebraucht
      return 0;
    } else {
      const ez = form.controls["ez"].value;
      const hereinnahmedatum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
      if(!this.isVomAusland(form, isStandard)){
        if(ez){
          const year = ez.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung == null) { return null;}
          if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
          else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
          else return null;
        } else if(hereinnahmedatum) {
          const year = hereinnahmedatum.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ?.id == 1 || fuel_typ?.id == 8) return NOVABerechnung.bonus_betraege.benzin; //1 = benzin / 8 = benzin hybrid
            else if(fuel_typ?.id == 2 || fuel_typ?.id == 9) return NOVABerechnung.bonus_betraege.diesel; //2 = diesel / 8 = diesel hybrid
            else return null;
          } else return null;
        }
      } else {
        if(ez) {
          const year = ez.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
            else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
            else return null;
          } else return null;
        }
      }
    }
    return 0;
  }

  static malusBonusIsCalculatable(form: FormGroup, isStandard: boolean): boolean {
    if(form.controls["malus_bonus_nach"].value != null) {
      return false;
    }
    if(form.controls["malus_bonus_changed"].value == true) {
      return false;
    }
    const fahrzeug_typ = form.controls["fahrzeug_typ"].value;
    const fuel_typ = form.controls["fuel_typ"].value;
    if(!this.isVomAusland(form, isStandard) && fahrzeug_typ?.id == 2){ // 2 = gebraucht
      return true;
    } else {
      const ez = form.controls["ez"].value;
      const hereinnahmedatum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
      if(!this.isVomAusland(form, isStandard)){
        if(ez){
          const year = ez.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
          else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
          else return true;
        } else if(hereinnahmedatum) {
          const year = hereinnahmedatum.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ?.id == 1 || fuel_typ?.id == 8) return NOVABerechnung.bonus_betraege.benzin; //1 = benzin / 8 = benzin hybrid
            else if(fuel_typ?.id == 2 || fuel_typ?.id == 9) return NOVABerechnung.bonus_betraege.diesel; //2 = diesel / 8 = diesel hybrid
            else return true;
          } else return true;
        }
      } else {
        if(ez) {
          const year = ez.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
            else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
            else return true;
          } else return true;
        }
      }
    }
    return false;
  }

  static getMalusBonusNach(form: FormGroup, isStandard: boolean): number {
    if(form.controls["malus_bonus_nach"].value != null) {
      return form.controls["malus_bonus_nach"].value;
    }
    const fahrzeug_typ = form.controls["fahrzeug_typ"].value;
    const fuel_typ = form.controls["fuel_typ"].value;
    if(!this.isVomAusland(form, isStandard) && fahrzeug_typ?.id == 2){ // 2 = gebraucht
      return 0;
    } else {
      const ez = form.controls["ez"].value;
      const hereinnahmedatum = form.controls["hereinnahmedatum"].value ? form.controls["hereinnahmedatum"].value : (new Date().getFullYear() + "");
      if(!this.isVomAusland(form, isStandard)){
        if(ez){
          const year = ez.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
          else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
          else return 0;
        } else if(hereinnahmedatum) {
          const year = hereinnahmedatum.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ?.id == 1 || fuel_typ?.id == 8) return NOVABerechnung.bonus_betraege.benzin; //1 = benzin / 8 = benzin hybrid
            else if(fuel_typ?.id == 2 || fuel_typ?.id == 9) return NOVABerechnung.bonus_betraege.diesel; //2 = diesel / 8 = diesel hybrid
            else return 0;
          } else return 0;
        }
      } else {
        if(ez) {
          const year = ez.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
            else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
            else return 0;
          } else return 0;
        }
      }
    }
    if(form.controls["malus_bonus_changed"].value == true) {
      return form.controls["malus_bonus_nach"].value;
    }
    return null;
  }

  static getVKNettoSpecificumAuslandVor(form: FormGroup): number {
    let vkBrutto = this.getVKBruttoVor(form);
    const malus_bonus = form.controls["malus_bonus"].value;
    const steuersatz = 0.2;
    const novaSatz = form.controls["nova_satz"].value / 100;
    return (vkBrutto + malus_bonus) / (1 + steuersatz + novaSatz);
  }

  static getVKNettoSpecificumAuslandNach(form: FormGroup): number {
    let vkBrutto = this.getVKBrutto(form);
    const malus_bonus = form.controls["malus_bonus_nach"].value;
    const steuersatz = 0.2;
    const novaSatz = form.controls["nova_satz"].value / 100;
    return (vkBrutto + malus_bonus) / (1 + steuersatz + novaSatz);
  }

  static getSummeNovaVor(form: FormGroup): number {
    const nova = form.controls["nova_vor"].value;
    const malus_bonus = form.controls["malus_bonus"].value;
    if(nova - malus_bonus > 0) {
      return nova - malus_bonus;
    }
    return 0;
  }
  static getSummeNovaNach(form: FormGroup): number {
    const nova = form.controls["nova"].value;
    const malus_bonus = form.controls["malus_bonus_nach"].value;
    if(nova - malus_bonus > 0) {
      return nova - malus_bonus;
    }
    return 0;
    //return nova - malus_bonus;
  }

  static getGuVExportVor(form: FormGroup, isStandard: boolean): number {
    let entgelt_ohne_nova = 0;
    entgelt_ohne_nova =  form.controls["export_price"].value;
    let ust = form.controls["guv_export_ust_vor"].value;
    if(form.controls["vehicle_imported"].value != 1 && form.controls["nova_verguetung"].value > 0) {
      entgelt_ohne_nova = entgelt_ohne_nova - ust + form.controls["nova_verguetung"].value;
    } else {
      entgelt_ohne_nova = entgelt_ohne_nova - ust;
    }

    let ek_netto = form.controls["einkaufspreis_netto"].value;
    const beschaffungskosten_gesamt = form.controls["beschaffungskosten_gesamt"].value;
    const aufbereitung = form.controls["aufbereitung"].value;
    const reparatur_summe_gesamt = this.getReparaturGesamt(form.controls["reparaturen"].value);
    const zinsen_vor = form.controls["zinsen_vor"].value;
    const garantie = form.controls["garantie_vorkalkulation"].value;
    const standzeitkosten_vor = form.controls["standzeitkosten_vor"].value;
    const fixkosten_pro_fahrzeug_bei_70 = form.controls["fixkosten_pro_fahrzeug_bei_70"].value;
    const gw_aufschlag = form.controls["gw_aufschlag"].value;
    const fahrzeug_provision = form.controls["fahrzeug_provision"].value;
    const finanzierung = form.controls["finanzierung"].value;
    const garantie_vor = form.controls["garantie_vor"].value;
    const abzug_fahrzeug_provision = form.controls["abzug_fahrzeug_provision"].value;
    const abzug_finanzierung = form.controls["abzug_finanzierung"].value;

    let guv = 0;
    guv = (entgelt_ohne_nova -
      ek_netto -
      beschaffungskosten_gesamt -
      aufbereitung -
      reparatur_summe_gesamt -
      zinsen_vor -
      garantie -
      standzeitkosten_vor -
      fixkosten_pro_fahrzeug_bei_70 -
      gw_aufschlag -
      garantie_vor) +
      fahrzeug_provision +
      finanzierung -
      abzug_fahrzeug_provision -
      abzug_finanzierung;
    return Number(guv.toFixed(2));
  }

  static getGuVVor(form: FormGroup, isStandard: boolean): number {
    //let entgelt_ohne_nova = !isNaN(form.controls["entgelt_ohne_nova"].value) ? form.controls["entgelt_ohne_nova"].value : null;
    let entgelt_ohne_nova = 0;
    if(isStandard) {
      entgelt_ohne_nova =  form.controls["verkaufspreis_netto_vor"].value;
    } else {
      entgelt_ohne_nova =  form.controls["entgelt_ohne_nova_vor"].value;
    }

    let ek_netto = form.controls["einkaufspreis_netto"].value;
    const beschaffungskosten_gesamt = form.controls["beschaffungskosten_gesamt"].value;
    const aufbereitung = form.controls["aufbereitung"].value;
    const reparatur_summe_gesamt = this.getReparaturGesamt(form.controls["reparaturen"].value);
    const zinsen_vor = form.controls["zinsen_vor"].value;
    const garantie = form.controls["garantie_vorkalkulation"].value;
    const standzeitkosten_vor = form.controls["standzeitkosten_vor"].value;
    const fixkosten_pro_fahrzeug_bei_70 = form.controls["fixkosten_pro_fahrzeug_bei_70"].value;
    const gw_aufschlag = form.controls["gw_aufschlag"].value;
    const fahrzeug_provision = form.controls["fahrzeug_provision"].value;
    const finanzierung = form.controls["finanzierung"].value;
    const garantie_vor = form.controls["garantie_vor"].value;
    const abzug_fahrzeug_provision = form.controls["abzug_fahrzeug_provision"].value;
    const abzug_finanzierung = form.controls["abzug_finanzierung"].value;

    let guv = 0;
    guv = (entgelt_ohne_nova -
      ek_netto -
      beschaffungskosten_gesamt -
      aufbereitung -
      reparatur_summe_gesamt -
      zinsen_vor -
      garantie -
      standzeitkosten_vor -
      fixkosten_pro_fahrzeug_bei_70 -
      gw_aufschlag -
      garantie_vor) +
      fahrzeug_provision +
      finanzierung -
      abzug_fahrzeug_provision -
      abzug_finanzierung;
    return Number(guv.toFixed(2));
  }

  static getGuVNach(form: FormGroup, isStandard: boolean): number {
    let entgelt_ohne_nova = form.controls["entgelt_ohne_nova"].value;
    let ek_netto = form.controls["einkaufspreis_netto"].value;
    const beschaffungskosten_gesamt = form.controls["beschaffungskosten_gesamt"].value;
    const aufbereitung = form.controls["aufbereitung"].value;
    const aufbereitung_nachkalkulation = form.controls["aufbereitung_nachkalkulation"].value;
    const reparatur_summe_gesamt = this.getReparaturGesamt(form.controls["reparaturen"].value);
    const garantie = form.controls["garantie"].value;
    const fixkosten_pro_fahrzeug_bei_70 = form.controls["fixkosten_pro_fahrzeug_bei_70"].value;
    const gw_aufschlag = form.controls["gw_aufschlag"].value;

    let zinsen_nach = form.controls["zinsen_nach"].value;
    let standzeitkosten_nach = form.controls["standzeitkosten_nach"].value;
    const reparatur_summe_gesamt_vorjahr = this.getReparaturGesamt(form.controls["reparaturen_vorjahr"].value);
    const garantie_intern = form.controls["garantie_intern"].value;
    const garantie_extern = form.controls["garantie_extern"].value;
    const fahrzeug_provision = form.controls["fahrzeug_provision"].value;
    const finanzierung = form.controls["finanzierung"].value;
    const abzug_fahrzeug_provision = form.controls["abzug_fahrzeug_provision"].value;
    const abzug_finanzierung = form.controls["abzug_finanzierung"].value;
    const garantie_vor = form.controls["garantie_vor"].value;

    entgelt_ohne_nova =  form.controls["entgelt_ohne_nova"].value;

    let ek_butto = form.controls["einkaufspreis_brutto"].value;
    let steuer_nach = STEUERSATZVALUES[new EnumHelper().getEnumValue(SteuersatzValue,form.controls["steuersatz_nach"].value-1)];
    ek_netto = (ek_butto/(100+steuer_nach))*100;

    let standzeit_geld = form.controls["standzeit_geld"].value;
    let zinssatz_nach = form.controls["zinssatz_nach"].value;
    zinsen_nach = (ek_netto * standzeit_geld * zinssatz_nach)/36000;

    let standzeitkosten_je_tag_nach =  form.controls["standzeitkosten_je_tag_nach"].value;
    let N46 = standzeitkosten_nach*standzeitkosten_je_tag_nach;

    let guv = 0;
    if(isStandard) {
      entgelt_ohne_nova = form.controls["verkaufspreis_netto"].value;
    }
    guv = (entgelt_ohne_nova -
      ek_netto -
      aufbereitung_nachkalkulation -
      beschaffungskosten_gesamt -
      //reparatur_summe_gesamt -
      garantie -
      fixkosten_pro_fahrzeug_bei_70 -
      gw_aufschlag -
      zinsen_nach -
      standzeitkosten_nach -
      reparatur_summe_gesamt_vorjahr -
      garantie_intern -
      garantie_extern -
      garantie_vor -
      abzug_fahrzeug_provision -
      abzug_finanzierung) +
      fahrzeug_provision +
      finanzierung;
    if(entgelt_ohne_nova != null && entgelt_ohne_nova != 0) {
      return Number(guv.toFixed(2));
    } else {
      return null;
    }

  }
  static getGUVNachAusland(form: FormGroup, isStandard: boolean): number {
    let entgelt_ohne_nova = form.controls["entgelt_ohne_nova"].value;
    let ek_netto = form.controls["einkaufspreis_netto"].value;
    const beschaffungskosten_gesamt = form.controls["beschaffungskosten_gesamt"].value;
    const aufbereitung = form.controls["aufbereitung"].value;
    const aufbereitung_nachkalkulation = form.controls["aufbereitung_nachkalkulation"].value;
    const garantie = form.controls["garantie"].value;
    const fixkosten_pro_fahrzeug_bei_70 = form.controls["fixkosten_pro_fahrzeug_bei_70"].value;
    const gw_aufschlag = form.controls["gw_aufschlag"].value;

    let zinsen_nach = form.controls["zinsen_nach"].value;
    let standzeitkosten_nach = form.controls["standzeitkosten_nach"].value;
    const reparatur_summe_gesamt_vorjahr = this.getReparaturGesamt(form.controls["reparaturen_vorjahr"].value);
    const garantie_intern = form.controls["garantie_intern"].value;
    const garantie_extern = form.controls["garantie_extern"].value;
    const fahrzeug_provision = form.controls["fahrzeug_provision"].value;
    const finanzierung = form.controls["finanzierung"].value;
    const abzug_fahrzeug_provision = form.controls["abzug_fahrzeug_provision"].value;
    const abzug_finanzierung = form.controls["abzug_finanzierung"].value;
    let ek_brutto = form.controls["einkaufspreis_brutto"].value;
    let steuer_vor = STEUERSATZVALUES[new EnumHelper().getEnumValue(SteuersatzValue,form.controls["steuersatz"].value-1)];
    ek_netto = (ek_brutto/(100+steuer_vor))*100;

    let standzeit_geld = form.controls["standzeit_geld"].value;
    let zinssatz_nach = form.controls["zinssatz_nach"].value;
    zinsen_nach = (ek_netto * standzeit_geld * zinssatz_nach)/36000;

    let standzeitkosten_je_tag_nach =  form.controls["standzeitkosten_je_tag_nach"].value;
    let N46 = standzeitkosten_nach*standzeitkosten_je_tag_nach;

    let guv = 0;
    if(isStandard) {
      entgelt_ohne_nova = form.controls["verkaufspreis_netto"].value;
    }
    guv = (entgelt_ohne_nova -
      ek_netto -
      aufbereitung_nachkalkulation -
      beschaffungskosten_gesamt -
      //reparatur_summe_gesamt -
      garantie -
      fixkosten_pro_fahrzeug_bei_70 -
      gw_aufschlag -
      zinsen_nach -
      standzeitkosten_nach -
      reparatur_summe_gesamt_vorjahr -
      garantie_intern -
      garantie_extern) +
      fahrzeug_provision +
      finanzierung -
      abzug_fahrzeug_provision -
      abzug_finanzierung;
    return Number(guv.toFixed(2));
  }

  static getOhneNovaUSTVor(form: FormGroup): number {
    const ust = 20;
    const entgelt_ohne_nova = form.controls["entgelt_ohne_nova_vor"].value;
    let value = entgelt_ohne_nova * ((ust/100)+1);
    return Number(value.toFixed(2));
  }
  static getOhneNovaUSTNach(form: FormGroup): number {
    const ust = 20;
    const entgelt_ohne_nova = form.controls["entgelt_ohne_nova"].value;
    let value = entgelt_ohne_nova * ((ust/100)+1);
    return Number(value.toFixed(2));
  }
  static getBeschaffungskostenGesamt(form: FormGroup): number {
    const sprit = form.controls["sprit"].value;
    const flug_bahn = form.controls["flug_bahn"].value;
    const gebuehren = form.controls["gebuehren"].value;
    const lieferant_extern = form.controls["lieferant_extern"].value;
    const uebernachtung = form.controls["uebernachtung"].value;
    let gesamt =
      sprit +
      flug_bahn +
      gebuehren +
      lieferant_extern +
      uebernachtung;
    return gesamt;
  }
  static getGesamtNeupreis(form: FormGroup): number {
    const neu_preis = form.controls["neu_preis"].value;
    const sonderaustattung = form.controls["sonderaustattung"].value;
    if(!neu_preis && !sonderaustattung) return null;
    let gesamt =
      neu_preis +
      sonderaustattung;
    return gesamt;
  }
  static getReparaturColumnGesamt(forms: FormGroup[]): any {
    let az = 0;
    let mat = 0;
    let reifen = 0;
    let lack = 0;
    let typ = 0;
    for(let form of forms){
      az += form.controls["az"].value;
      mat += form.controls["mat"].value;
      reifen += form.controls["reifen"].value;
      lack += form.controls["lackierung"].value;
      typ += form.controls["typisierung"].value;
    }
    return {
      az: az,
      mat: mat,
      reifen: reifen,
      lack: lack,
      typ: typ,
    }
  }
  static getReparaturGesamt(forms: FormGroup[]): number {
    let az = 0;
    let mat = 0;
    let reifen = 0;
    let lack = 0;
    let typ = 0;
    for(let form of forms){
      az += form.controls["az"].value;
      mat += form.controls["mat"].value;
      reifen += form.controls["reifen"].value;
      lack += form.controls["lackierung"].value;
      typ += form.controls["typisierung"].value;
    }
    return az + mat + reifen + lack + typ;
  }

  static getZinsenVor(form: FormGroup): number {
    const einkaufspreis_netto = form.controls["einkaufspreis_netto"].value;
    const standzeit_plan_tage = form.controls["standzeit_plan_tage"].value;
    const zinssatz_vor = form.controls["zinssatz_vor"].value;
    if(einkaufspreis_netto && standzeit_plan_tage && zinssatz_vor){
      let zinsen = (einkaufspreis_netto * standzeit_plan_tage * zinssatz_vor) / 36000;
      return Number(zinsen.toFixed(2));
    } else {
      return null;
    }
  }
  static getZinsenNach(form: FormGroup): number {
    const einkaufspreis_netto = form.controls["einkaufspreis_netto"].value;
    const standzeit_geld = form.controls["standzeit_geld"].value;
    const zinssatz_nach = form.controls["zinssatz_nach"].value;
    if(einkaufspreis_netto && standzeit_geld && zinssatz_nach){
      let zinsen = (einkaufspreis_netto * standzeit_geld * zinssatz_nach) / 36000;
      return Number(zinsen.toFixed(2));
    } else {
      return null;
    }
  }
  static getGarantieVor(form: FormGroup, isStandard: boolean): number {
    let verkaufspreis_brutto = 0;
    if(isStandard) {
      verkaufspreis_brutto = form.controls["preis_anschreiben"].value;
    } else {
      verkaufspreis_brutto = this.getVKBruttoVor(form);
    }
    const garantie_von_einkaufspreis = form.controls["garantie_von_einkaufspreis"].value;
    if(verkaufspreis_brutto && garantie_von_einkaufspreis){
      let garantie = (verkaufspreis_brutto * garantie_von_einkaufspreis)-verkaufspreis_brutto;
      return Number(garantie.toFixed(3));
    } else {
      return null;
    }
  }

  static getGarantieNach(form: FormGroup): number {
    const verkaufspreis_brutto = this.getVKBrutto(form);
    const garantie_von_einkaufspreis = form.controls["garantie_von_einkaufspreis"].value;
    if(verkaufspreis_brutto && garantie_von_einkaufspreis){
      let garantie = (verkaufspreis_brutto * garantie_von_einkaufspreis)-verkaufspreis_brutto;
      return Number(garantie.toFixed(3));
    } else {
      return null;
    }
  }

  static getStandzeitkostenVor(form: FormGroup): number {
    const standzeit_plan_tage = form.controls["standzeit_plan_tage"].value;
    const standzeitkosten_je_tag_vor = form.controls["standzeitkosten_je_tag_vor"].value;
    let kosten = standzeit_plan_tage * standzeitkosten_je_tag_vor;
    return Number(kosten.toFixed(2));
  }
  static getStandzeitPlatz(form: FormGroup): number {
    const hereinnahmedatum = form.controls["hereinnahmedatum"].value;
    const verkaufsdatum = form.controls["verkaufsdatum"].value;
    if(hereinnahmedatum && verkaufsdatum){
      const days = Math.ceil((PriceSheetForm.getTimestampFromInputDate(verkaufsdatum) - PriceSheetForm.getTimestampFromInputDate(hereinnahmedatum)) / (1000 * 3600 * 24));
      return days;
    }
    return null;
  }
  static getStandzeitPlatzStandard(form: FormGroup): number {
    const hereinnahmedatum = form.controls["einkaufsdatum"].value;
    const verkaufsdatum = form.controls["verkaufsdatum"].value;
    if(hereinnahmedatum && verkaufsdatum){
      const days = Math.ceil((PriceSheetForm.getTimestampFromInputDate(verkaufsdatum) - PriceSheetForm.getTimestampFromInputDate(hereinnahmedatum)) / (1000 * 3600 * 24));
      return days;
    }
    return null;
  }

  static getStandzeitGeld(form: FormGroup): number {
    const zahldatum = form.controls["zahldatum"].value;
    const geldeingang = form.controls["geldeingang"].value;
    if(zahldatum && geldeingang){
      const days = Math.ceil((PriceSheetForm.getTimestampFromInputDate(geldeingang) - PriceSheetForm.getTimestampFromInputDate(zahldatum)) / (1000 * 3600 * 24));
      return days;
    }
    return null;
  }
  static getStandzeitkostenNach(form: FormGroup): number {
    const standzeit_platz = form.controls["standzeit_platz"].value;
    const standzeitkosten_je_tag_nach = form.controls["standzeitkosten_je_tag_nach"].value;
    let kosten = standzeit_platz * standzeitkosten_je_tag_nach;
    return Number(kosten.toFixed(2));
  }
  static getAufschlageVerkaufVor(form: FormGroup): number {
    const entgelt_ohne_nova = form.controls["entgelt_ohne_nova_vor"].value;
    let ek_netto = form.controls["einkaufspreis_netto"].value;
    return entgelt_ohne_nova - ek_netto;
  }

  static getAufschlageVerkaufNach(form: FormGroup): number {
    const entgelt_ohne_nova = form.controls["entgelt_ohne_nova"].value;
    if(entgelt_ohne_nova != 0 && entgelt_ohne_nova != null){
      let ek_netto = form.controls["einkaufspreis_netto"].value;
      return entgelt_ohne_nova - ek_netto;
    } else {
      return null;
    }

  }

  static getEntGeltOhneNovaVor(form: FormGroup, isStandard: boolean): number {
    if(form.controls["steuersatz"].value == 1){
      let vk_netto = form.controls["verkaufspreis_netto_vor"].value;
      const malus_bonus = form.controls["malus_bonus"].value;
      const nova = form.controls["nova_vor"].value;
      if(isStandard && form.controls["vehicle_imported"].value == 1) {
        return vk_netto;
      } else {
        let summeNova = nova - malus_bonus;
        if(summeNova > 0) {
          return vk_netto + malus_bonus - nova;
        } else {
          return vk_netto;
        }
      }
    } else if(form.controls["steuersatz"].value == 2) {
      let vk_brutto = form.controls["minimaler_verkaufpreis"].value;
      const ust = form.controls["ust_vor"].value;
      if(isStandard && form.controls["vehicle_imported"].value == 1) {
        const malu_bonus = form.controls["malus_bonus"].value;
        const entgelt = (vk_brutto - ust) / 1;
        return Number(entgelt.toFixed(2));
      } else {
        const malu_bonus = form.controls["malus_bonus"].value;
        const entgelt = (vk_brutto - ust - malu_bonus) / 1;
        return Number(entgelt.toFixed(2));
      }

    } else {
      //TODO: vk inkorrekt
      const vk_netto = form.controls["verkaufspreis_netto_vor"].value;
      return vk_netto;
    }
  }

  static getEntGeltOhneNovaNach(form: FormGroup, isStandard: boolean): number {
    const malus_bonus = form.controls["malus_bonus_nach"].value;
    if(form.controls["steuersatz"].value == 1){
      let vk_netto = form.controls["verkaufspreis_netto"].value;
      const nova = form.controls["nova"].value;
      if(isStandard && form.controls["vehicle_imported"].value == 1) {
        return vk_netto;
      } else {
        return vk_netto - malus_bonus - nova;
      }
    } else if(form.controls["steuersatz"].value == 2) {
      let vk_brutto = form.controls["verkaufspreis_brutto"].value;
      const ust = form.controls["ust"].value;
      const entgelt = (vk_brutto - ust - malus_bonus) / 1;
      return Number(entgelt.toFixed(2));
    } else {
      //TODO: vk inkorrekt
      const vk_netto = form.controls["verkaufspreis_netto"].value;
      return vk_netto;
    }
  }

  static isVomAusland(form: FormGroup, isStandard: boolean): boolean {
    if(isStandard) {
      if(form.controls["vehicle_imported"].value == 1) return true;
      else return false;
    } else {
      if(form.controls['steuersatz'].value == 3 || form.controls['steuersatz'].value == 4) return true;
      else return false;
    }
  }

}
