import { VehiclePicture } from "../VehiclePicture";

export class VehiclePictureFactory {
    static jsonFactory(rawData: any): VehiclePicture[] {
        let dataAry: VehiclePicture[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehiclePicture {
        let pic = new VehiclePicture(
            rawData.picture_id,
            rawData.position,
            rawData.vehicle_id,
            rawData.src
        );
        return pic;
    }
}