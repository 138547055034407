import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Thread } from 'src/app/buisness-object/message/Thread';

@Component({
  selector: 'app-messages-list-element',
  templateUrl: './messages-list-element.component.html',
  styleUrls: ['./messages-list-element.component.css']
})
export class MessagesListElementComponent implements OnInit {
  public resolveThread = false;
  @Input() thread: Thread;
  @Input() employees: Employee[];
  @Input() filter: any;
  @Input() userId: number;
  @Input() last: boolean;
  @Output() resolveEmitter = new EventEmitter<Thread>();
  @Output() openDetailsEmitter = new EventEmitter<Thread>();

  constructor() { }

  ngOnInit(): void {}

  getTyp(typ: number): string {
    switch (typ) {
      case 1: return 'Intern'; break;
      case 2: return 'Kunde'; break;
      case 3: return 'Fahrzeug'; break;
      case 4: return 'Document'; break;
      case 5: return 'Probefahrt'; break;
      default: return '-'; break;
    }
  }

  getInitials(employee: Employee): string {
    return employee?.firstName.substring(0,1) + employee?.lastName.substring(0,1);
  }

  getUrgency(urgency: number): string {
    switch (urgency) {
      case 0: return 'später erledigen'; break;
      case 1: return 'erledigen'; break;
      case 2: return 'sofort erledigen'; break;
      default: '---'; break;
    }
  }

  resolve(thread: Thread) {
    this.resolveThread = true;
    setTimeout(() => {
      this.resolveEmitter.emit(thread);
    }, 700);
  }

  openDetails(thread: Thread) {
    thread.notifyOpen = false;
    this.openDetailsEmitter.emit(thread);
  }
}
