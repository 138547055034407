import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SelektiertesZusatzprodukt } from "./SelektiertesZusatzprodukt";
import { Zusatzprodukt } from "./Zusatzprodukt";

export class selektierteZusatzproduktForm {

    public static getSelektiertesZusatproduktForm(formBuilder: FormBuilder, selektiertesZusatzprodukt: SelektiertesZusatzprodukt): FormGroup {
      let form = formBuilder.group({
        zusatzprodukt_id: [selektiertesZusatzprodukt ? selektiertesZusatzprodukt.zusatzprodukt_id : null, Validators.min(1)],
        kategorie: [selektiertesZusatzprodukt ? selektiertesZusatzprodukt.kategorie : null],
        name: [selektiertesZusatzprodukt ? selektiertesZusatzprodukt.name : null],
        name_editierbar: [selektiertesZusatzprodukt ? selektiertesZusatzprodukt.name_editierbar : null],
        preis_selektiert: [selektiertesZusatzprodukt ? selektiertesZusatzprodukt.preis_selektiert : null, [Validators.required, Validators.min(1)]],
        name_selektiert: [selektiertesZusatzprodukt ? selektiertesZusatzprodukt.name_selektiert : null],
        preis: [selektiertesZusatzprodukt ? selektiertesZusatzprodukt.preis : null],
      });
      return form;
    }

    public static getZusatproduktForm(formBuilder: FormBuilder, zusatzprodukt: Zusatzprodukt): FormGroup {
      let form = formBuilder.group({
        zusatzprodukt_id: [zusatzprodukt ? zusatzprodukt.zusatzprodukt_id : null],
        kategorie: [zusatzprodukt ? zusatzprodukt.kategorie : null],
        name: [zusatzprodukt ? zusatzprodukt.name : null],
        name_editierbar: [zusatzprodukt ? zusatzprodukt.name_editierbar : null],
        preis: [zusatzprodukt ? zusatzprodukt.preis : null],
      });
      return form;
    }
}