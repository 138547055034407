import { Component, EventEmitter, OnInit, Output, Input, SimpleChanges, OnChanges } from '@angular/core';
import { BaseComponent } from '../../BaseComponent';
import { Transaction } from 'src/app/buisness-object/transaction/Transaction';
import { TransactionHelper } from 'src/app/helpers/TransactionHelper';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { Rent,  RentOrderStatusLabels, RentTypLabels } from 'src/app/buisness-object/rent/Rent';
import { RentService } from 'src/app/service/rent/rent.service';
import { FormGroup } from '@angular/forms';
import { getLabelFromEnumValue, } from 'src/app/utils/EnumHandler';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-miete-details',
  templateUrl: './miete-details.component.html',
  styleUrl: './miete-details.component.css'
})
export class MieteDetailsComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() obj: Rent;
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() selectionEditEmitter = new EventEmitter<any>();
  @Output() selectionReturnEmitter = new EventEmitter<any>();
  @Output() selectionReceiveEmitter = new EventEmitter<any>();
  @Output() selectionCancelRentEmitter = new EventEmitter();
  @Output() selectionSendEmailEmitter = new EventEmitter();

  public rent_typ;
  public rent_status;
  public shwoActions = false;
  public shwoEmail = false;
  public showDropDown = false;
  public form: FormGroup;
  public rentTypValue;
  public rentOrderStatusValue;

  rents: Rent[] = [];
  filename: string = 'Vertrag.pdf';

  ngOnInit(): void {
    this.getRentEnumLabelValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes){
      this.getRentEnumLabelValues();
    }
  }

  getRentEnumLabelValues(): void {
    this.rentTypValue = getLabelFromEnumValue(this.obj.rent_typ, RentTypLabels);
    this.rentOrderStatusValue = getLabelFromEnumValue(this.obj.rent_status, RentOrderStatusLabels);
  }

  public transactions: Transaction[] = [];
  public TransactionHelper = TransactionHelper;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private rentService: RentService,
    private dialogService: DialogService,
  ){
    super(authService, eService);
  }

  openPDF(id: number) {
    this.rentService.getRentPdf(id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true });
      }
    });
  }

  selectionSendDocument(id: number) {
    this.rentService.sendRentOrderContract(id).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Dokument wurde gesendet.',
          success: true
        });
      }
    })
  }

  selectionSendMail(id: number) {
    this.rentService.sendRentOrderMail(id).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'E-Mail wurde gesendet.',
          success: true
        });
      }
    })
  }

}
