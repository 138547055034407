import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-car-online',
  templateUrl: './dialog-car-online.component.html',
  styleUrls: ['./dialog-car-online.component.css']
})
export class DialogCarOnlineComponent implements OnInit {
  public show = false;
  public title: string;
  public message: string;
  public btnCancelTxt: string;
  public btnSubmitTxt: string;
  public submitValue: any;
  public typ: any;
  public subscription: Subscription;
  public form: FormGroup;
  public price;

  constructor(
    private dialogService: DialogService,
    private formBuilder: FormBuilder
  ) {
    this.subscription = this.dialogService.openDialogQueryCarOnline$.subscribe((data: any) => {
      this.show = true;
      this.title = data.title;
      this.message = data.message;
      this.btnCancelTxt = data.btn_cancel_txt;
      this.btnSubmitTxt = data.btn_submit_txt;
      this.submitValue = data.submit_value;
      this.typ = data.typ;
      this.price = data.submit_value.price;
      this.form = this.formBuilder.group({
        export_web: [true],
        export_plattform: [true],
        export_mobile: [false],
        price: [this.price ? this.price : null]
      })
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  disabled(): boolean {
    if((!this.form.controls['export_web'].value && !this.form.controls['export_plattform'].value) || !this.form.controls['price'].value) return true;
    else return false;
  }

  onSubmit(){
    this.show = false;
    this.dialogService.closeQueryCarOnline(
      {
        submit_value: this.submitValue,
        typ: this.typ,
        export_web: this.form.controls['export_web'].value,
        export_platform: this.form.controls['export_web'].value,
        export_mobile: this.form.controls["export_mobile"].value,
        price: this.form.controls['price'].value,
      }
    );
  }
}
