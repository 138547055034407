<div *ngIf="pageLoaded">
  <app-topbar-documents
    [surface]="surface"
    [tab]="tab"
    [selectedDocument]="selectedDoc"
    [contract]="selectedContract"
    [filter]="filter"
    [sortObject]="sortObject"
    [documents]="tabDocuments"
    (changeTabEmitter)="changeTab($event)"
    (openCreateEmitter)="openCreate($event)"
    (saveEmitter)="createBillAction()"
    (cancelEmitter)="cancelCreateAction()"
    (backEmitter)="backAction()"
    (editEmitter)="editDocument()"
    (saveAsDraftEmitter)="saveAsDraft()"
    (searchEmitter)="searchAction($event)"
    (pdfExportEmitter)="openPdf(selectedDoc)"
    (applyFilterEmitter)="applyFilter()"
    (resetFilterEmitter)="resetFilterEmitter()"
    (applySortEmitter)="applySorting()"
  ></app-topbar-documents>
  <div class="main-view">
    <app-list-documents *ngIf="surface == 1"
      [documents]="tabDocumentsFiltered"
      [pageInfo]="pageInfo"
      [sliceFrom]="sliceFrom"
      [sliceTo]="sliceTo"
      [amount]="tabDocumentsFiltered.length"
      [tab]="tab"
      [filter]="filter"
      [sortObject]="sortObject"
      (pageEmitter)="pageHandling($event)"
      (openDetailsEmitter)="openDetails($event)"
      (openEditEmitter)="editDocument($event)"
      (deleteEmitter)="deleteAction($event)"
      (openPdfEmitter)="openPdf($event)"
      (moveToEmitter)="moveTo($event)"
      (openContractEditEmitter)="openEditContract($event)"
      (deleteContractEmitter)="deleteContractAction($event)"
      (openContractPdfEmitter)="openContractPdf($event)"
      (duplicateEmitter)="duplicateOffer($event)"
      (removeFilterEmitter)="removeFilterValue($event)"
      (signPDFEmitter)="signPDF($event)"
      (openContractUploadEmitter)="uploadFile($event)"
      (applySortEmitter)="applySorting()"
    ></app-list-documents>
    <app-create-offer-document *ngIf="surface == 2"
      [selectedDoc]="selectedDoc"
      [form]="formOffer"
      [customers]="customers"submittedItem
      [position]="position"
      [submitted]="submitted"
      [submittedItem]="submittedItem"
      [page]="pageOffer"
      [isEdit]="isEdit"
      (addPriceEmitter)="addPriceToOffer()"
      (removeEmitter)="removeOfferItem($event)"
      (changePageEmitter)="changePageOffer($event)"
    ></app-create-offer-document>
    <app-details-offer-document *ngIf="surface == 22"
      [document]="selectedDoc"
      (sendDocumentEmitter)="sendOffer(selectedDoc)"
    ></app-details-offer-document>
    <app-view-document *ngIf="surface == 100"
      [document]="selectedDoc"
      [isCreate]="isCreate"
      (sendEmitter)="sendOffer($event)"
      (saveAsDraftEmitter)="backAction()"
    ></app-view-document>
    <app-create-bill-document *ngIf="surface == 3"
      [selectedDoc]="selectedDoc"
      [form]="formBill"
      [customers]="customers"
      [position]="position"
      [submitted]="submitted"
      [page]="pageBill"
      [submittedBillPrice]="submittedBillPrice"
      (addPriceEmitter)="addPriceToBill()"
      (removePriceEmitter)="removePrice($event)"
      (changePageEmitter)="changePageBill($event)"
    ></app-create-bill-document>
    <app-details-bill-document *ngIf="surface == 33"
      [document]="selectedDoc"
    ></app-details-bill-document>
    <!-- <app-create-offer-bill-document *ngIf="surface == 2"
      [selectedDoc]="selectedDoc"
      [form]="formBill"
      [customers]="customers"
      [position]="position"
      [submitted]="submitted"
      [submittedBillPrice]="submittedBillPrice"
      (addPriceEmitter)="addPriceToBill()"
      (removePriceEmitter)="removePrice($event)"
    ></app-create-offer-bill-document> -->
    <!-- <app-details-offer-bill-document *ngIf="surface == 22"
      [document]="selectedDoc"
    ></app-details-offer-bill-document> -->
    <app-create-contract-document *ngIf="surface == 4"
      [formContract]="formContract"
      [pageContract]="pageContract"
      [customers]="customers"
      [submitted]="submitted"
      (changePageEmitter)="nextPageContract($event)"
      (createContractEmitter)="createContract($event)"
      (formCustomerValueChangedEmitter)="this.formContractCustomerChanged = true"
    ></app-create-contract-document>
    <!-- <app-upload-document *ngIf="surface == 4"
    ></app-upload-document> -->
  </div>
</div>
