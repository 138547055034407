import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Offer } from 'src/app/buisness-object/document/Offer';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { IsValidDateInput } from 'src/app/validators/CustomValidators';

declare function IsCharDate(): any;

@Component({
  selector: 'app-documents-filter',
  templateUrl: './documents-filter.component.html',
  styleUrls: ['./documents-filter.component.css']
})
export class DocumentsFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() showFilter: boolean;
  @Input() filter: any;
  @Input() documents: any[];
  @Output() filterDetailEmitter = new EventEmitter<undefined>();
  @Output() resetFilterEmitter = new EventEmitter<undefined>();
  public filterInUse = false;
  public customers: Customer[] = [];
  public customersFiltered: Customer[] = [];
  public submitted = false;
  public showDropdownOne = false;
  public showDropdownTwo = false;
  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  @ViewChild('dropdownTwo') dropdownTwo: ElementRef;
  public listenerOnClick: () => void;
  public IsValidDateInput;
  public tempFilter;

  constructor(
  ) { }

  ngOnInit(): void {
    this.IsValidDateInput = IsValidDateInput;
    if(this.filter.customer) this.filterInUse = true;
    this.getFilterValues();
    this.copyFilter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['filter'] || changes['documents']){
      if(this.filter.customer) this.filterInUse = true;
      else this.filterInUse = false;
      this.getFilterValues();
      this.copyFilter();
    }
  }

  ngOnDestroy(): void {
    if(this.listenerOnClick) this.listenerOnClick();
  }

  copyFilter() {
    this.tempFilter = {
      customer: this.filter.customer,
      start_date: this.filter.start_date,
      end_date: this.filter.end_date,
      is_set: this.filter.is_set
    }
  }

  applyFilter() {
    this.filter.customer = this.tempFilter.customer;
    this.filter.start_date = this.tempFilter.start_date;
    this.filter.end_date = this.tempFilter.end_date;
    this.filter.is_set = this.tempFilter.is_set;
  }

  handleDropdownSelection(customer){
    this.tempFilter.customer = customer;
    setTimeout(() => {
      this.showDropdownOne = false
    }, 30);
  }

  getFilterValues(){
    this.customers = [];
    for(let doc of this.documents){
      let index = this.customers.findIndex(customer => customer?.id == doc.customer?.id);
      if(index == -1 && doc.customer) this.customers.push(doc.customer);
    }
    this.customersFiltered = this.customers;
  }

  filterCustomers(value: string) {
    if(value && value.length > 0){
      this.customersFiltered = [];
      this.customersFiltered = this.customers.filter((customer) => {
        if(customer.typ == 1){
          return customer.companyName.toLowerCase().includes(value.toLowerCase());
        } else if(customer.typ == 2){
          return customer.contactPerson.firstName.toLowerCase().includes(value.toLowerCase()) ||
                customer.contactPerson.lastName.toLowerCase().includes(value.toLowerCase()) ||
                (customer.contactPerson.firstName + ' ' + customer.contactPerson.lastName).toLowerCase().includes(value.toLowerCase());
        }
      })
    } else {
      this.customersFiltered = this.customers;
    }
  }

  submitFilter() {
    this.filterInUse = true;
    this.applyFilter();
    this.filterDetailEmitter.emit();
  }
  resetFilter() {
    this.showFilter = false;
    this.resetFilterEmitter.emit();
  }
}
