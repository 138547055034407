import { UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { element } from 'protractor';
import { VehicleEquipment } from 'src/app/buisness-object/vehicle/optional/VehicleEquipment';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';

@Component({
  selector: 'app-equipment-container',
  templateUrl: './equipment-container.component.html',
  styleUrls: ['./equipment-container.component.css']
})
export class EquipmentContainerComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @Input() vehicleTitle: string;
  @Input() formGroup: UntypedFormGroup;

  public equipments: VehicleEquipment[] = [];
  public categories: any[] = [];
  public equipmentsCat1: VehicleEquipment[] = [];
  public equipmentsCat2: VehicleEquipment[] = [];
  public equipmentsCat3: VehicleEquipment[] = [];
  public equipmentsCat4: VehicleEquipment[] = [];
  public equipmentsCat5: VehicleEquipment[] = [];
  public selectedEquipments: VehicleEquipment[] = [];

  @ViewChild('grid') grid: ElementRef;

  constructor(
    private vService: VehicleService
  ) { }

  ngOnInit(): void {
    this.getEquiptment();
  }

  ngAfterViewChecked(): void {
    this.resizeAllGridItems();
  }

  ngAfterViewInit(): void {
    window.addEventListener('resize', () => {
      this.resizeAllGridItems();
   });
  }

  getEquiptment() {
    this.vService.getEquipment().subscribe((equipments) => {
      if(equipments){
        this.equipments = equipments;
        for(let eq of this.equipments){
          let index = this.categories.findIndex(cat => cat.category_id == eq.category_id);
          if(index == -1){
            this.categories.push({
              category_id: eq.category_id,
              category_name: eq.category_name,
              equipments: [
                {
                  eq_id: eq.eq_id,
                  eq_name: eq.eq_name,
                  is_selected: false
                }
              ]
            });
          } else {
            let i = this.formGroup.controls.equipments.value.findIndex(e => e.eq_id == eq.eq_id)
            this.categories[index].equipments.push({
              eq_id: eq.eq_id,
              eq_name: eq.eq_name,
              is_selected: i > -1
            })
          }
        }
      }
    })
  }

  isSelected(equipment: any): boolean {
    for(let e of this.formGroup.controls.equipments.value){
      if(equipment.eq_id == e.eq_id){
        return true;
      }
    }
    return false;
  }

  addEquipment(equipment: any) {
    equipment.is_selected = !equipment.is_selected;
    if(equipment.is_selected){
      this.formGroup.controls.equipments.value.push(equipment)
    } else {
      let index = this.formGroup.controls.equipments.value.findIndex(e => e.eq_id == equipment.eq_id);
      if(index > -1){
        this.formGroup.controls.equipments.value.splice(index, 1);
      }
    }
  }

  resizeGridItem(item){
    let grid = document.getElementById("grid");
    let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    let innerItems = item.querySelectorAll('.checkbox_container');
    let totalHeight = 0;

    for (let i of innerItems) {
        totalHeight += i.getBoundingClientRect().height;
    }

    let rowSpan = Math.ceil((totalHeight + rowGap * (innerItems.length - 1)) / (rowHeight + rowGap));
    item.style.gridRowEnd = "span " + rowSpan;

    // let rowSpan = 0;
    // for(let i of innerItems){
    //   rowSpan += Math.ceil((i.getBoundingClientRect().height+rowGap)/(rowHeight+rowGap))
    // }
    // item.style.gridRowEnd = "span "+rowSpan;
  }

  resizeAllGridItems(){
    const allItems = document.querySelectorAll(".category_container");
    for(var i = 0; i < allItems.length; i++){
      this.resizeGridItem(allItems[i]);
    }
  }
}
