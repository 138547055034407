<div id="dialog_window" *ngIf="show">
  <div id="dialog_container">
      <div class="lbl_bold">Fahrzeugzustände</div>
      <div id="car_condition_info_body">
        <div class="car_condition_body_column">
          <div class="car_condition_body_item car_condition_body_column_row_1" style="justify-content: center">
            <label class="">Beurteilung</label>
          </div>
          <div class="car_condition_body_item">
            <h3>Besonders gut</h3>
            <label class="">(Klasse 1)</label>
          </div>
          <div class="car_condition_body_item">
            <h3>Gut</h3>
            <label class="">(Klasse 2)</label>
          </div>
          <div class="car_condition_body_item">
            <h3>Genügend fahrbereit</h3>
            <label class="">(Klasse 3)</label>
          </div>
          <div class="car_condition_body_item car_condition_body_item_big">
            <h3>Defekt</h3>
            <label class="">(Klasse 4)</label>
          </div>
        </div>
        <div class="car_condition_body_column">
          <div class="car_condition_body_item car_condition_body_column_row_1">
            <h3>A</h3>
            <label class="">Mech. Zustand</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Einwandfrei ohne Verschleißerscheinungen. Planmäßig gewartet.</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Geringe Verschleißerscheinungen. Kein Reparaturbedarf. Kleinere Einstellarbeiten oder Inspektionen erforderlich</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Mittlerem Kilometerstand entsprechende Reparaturen oder Wartungsarbeiten erforderlich.</label>
          </div>
          <div class="car_condition_body_item car_condition_body_item_big">
            <label class="">Größerer Reparaturen oder Überholarbeiten erforderlich. Verkehrssicherheit nicht gegeben. Nicht fahrbereit.</label>
          </div>
        </div>
        <div class="car_condition_body_column">
          <div class="car_condition_body_item car_condition_body_column_row_1">
            <h3>B</h3>
            <label class="">Karosserie</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Gänzlich unbeschädigt. Keine Beulen. Keine Kratzer. Keine Roststellen.</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Kleine Beulen oder Kratzer. Geringe Steinschlagschäden.</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Beulen und Kratzer. Leichte Blechschäden. Diverse Roststellen. Frühere Unfallschäden behoben, aber Spuren sichtbar. Unpassendes Zubehör montiert.</label>
          </div>
          <div class="car_condition_body_item car_condition_body_item_big">
            <label class="">Große Unfallschäden. Starke Durchrostungen. Beschädigung an tragenden Teilen. Verkehrssicherheit nicht gegeben.</label>
          </div>
        </div>
        <div class="car_condition_body_column">
          <div class="car_condition_body_item car_condition_body_column_row_1">
            <h3>C</h3>
            <label class="">Lack</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Originallack neuwertig konserviert. Hochglanz ohne Flecken oder Kratzspuren.</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Originallack oder gute Neulackierung. Kleine Kratzer oder Mattstellen im Decklack. Vereinzelte Steinschlagschäden ausgebessert.</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Matter, korrodierter Lack oder schlechte Lakierung. Ausbesserungen erforderlich. Roststellen, erhebliche Steinschlagschäden.</label>
          </div>
          <div class="car_condition_body_item car_condition_body_item_big">
            <label class="">Neulackierung notwendig. Große Roststellen oder Rostflecken. Diverse farbfalsche Nachlackierungen.</label>
          </div>
        </div>
        <div class="car_condition_body_column">
          <div class="car_condition_body_item car_condition_body_column_row_1">
            <h3>D</h3>
            <label class="">Innenraum/Sonstiges</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Reifenabnützung bis 40%. Original-Dimension. Original-Schließsystem und Betriebsanleitung vorhanden. Keine Abnützungsspuren.</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Reifenabnützung bis 60%. Original-Dimension. Original Schließsystem und Betriebasnleitung vorhanden. Geringe Abnützungsspuren.</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Reifenabnuützung bis 80%. Funktionsfähgiges Schliesßsystem und Betriebsanleitung vorhanden. Deutliche Abnützungsspuren. Spuren von Wassereintritt. Originalradio fehlt.</label>
          </div>
          <div class="car_condition_body_item car_condition_body_item_big">
            <label class="">Reifenabnützung bis 100%. Unpassende Dimension oder stark einseitig abgefahren. Spuren von Gewalteinwirkung. Schließsystem unvollständig. Reparatur erforderlich. Beschädigung durch Wassereintritt.</label>
          </div>
        </div>
        <div class="car_condition_body_column">
          <div class="car_condition_body_item car_condition_body_column_row_1">
            <h3>E</h3>
            <label class="">Elektrische und elektronische Ausrüstung</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Einwandfrei. ohne Störungen.</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Akkumulator für den Antrieb innerhalb der Garantiezeit und Komfortelektronik funktionstüchtig.</label>
          </div>
          <div class="car_condition_body_item">
            <label class="">Akkumulator für den Antrieb oder Komfortelektronik mit eingeschränkter Leistungsfähigkeit.</label>
          </div>
          <div class="car_condition_body_item car_condition_body_item_big">
            <label class="">Sicherheitsrelevante Bauteile defekt.</label>
          </div>
        </div>
      </div>
      <div class="btn_container">
          <div class="btn_label" (click)="show=false">Schließen</div>
      </div>
  </div>
</div>
