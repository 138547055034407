import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { EmployeeFactory } from 'src/app/buisness-object/employee/factory/EmployeeFactory';
import { MessageError } from 'src/app/helpers/MessageError';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LastUpdateService } from '../last-update/last-update.service';
import { LoginService } from '../login/login.service';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  public employees: Employee[] = [];
  public timestamp: (number|undefined) = null;
  public type = 1;

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private uService: LastUpdateService,
    private dService: DialogService
  ) { }

  public getEmployees(): Observable<Employee[]> {
    this.uService.getLastUpdate(this.type).subscribe((timestamp) => {
      if(timestamp != undefined){
        if(this.timestamp){
          if(timestamp != 0 && this.timestamp < timestamp){
            return this.getEmployeesServerside();
          } else {
            return of(this.employees);
          }
        } else {
          return this.getEmployeesServerside();
        }
      } else {
        return of(this.employees);
      }
    });
    return this.getEmployeesServerside();
  }

  public getEmployeesServerside(): Observable<Employee[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/employee', { headers });
    return observable.pipe(
      map((rawEmployees: any) => {
        const employees: Employee[] = EmployeeFactory.jsonFactory(rawEmployees.employees);
        this.employees = employees;
        return employees;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    )
  }

  public updateIfNeeded(): Observable<Employee[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/employee/update', { headers });
    return observable.pipe(
      map((rawUpdate: any) => {
        if(rawUpdate.last_updated && Number(rawUpdate.last_updated) <= this.timestamp) {
          return this.employees;
        } else {
          this.getEmployeesServerside().subscribe((employees) => {
            return employees;
          })
        }
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
          return this.getEmployeesServerside();
        }
        return [];
      })
    );
  }

  public updateEmployee(e: Employee): Observable<Employee> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = e.getJSON();
    const observable = this.http.put(environment.api + '/back/employee', body, { headers });
    return observable.pipe(
      map((rawEmployee: any) => {
        const employee = EmployeeFactory.jsonFactoryOne(rawEmployee);
        employee.permission_group = e.permission_group;
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Mitarbeiter aktualisiert.',
          success: true
        });
        return employee;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createEmployee(employee: Employee): Observable<Employee> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = employee.getJSON();
    const observable = this.http.post(environment.api + '/back/employee', body , { headers });
    return observable.pipe(
      map((rawEmployee: any) => {
        const employee = EmployeeFactory.jsonFactoryOne(rawEmployee);
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Mitarbeiter angelegt.',
          success: true
        });
        return employee;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public deleteEmployee(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.delete(environment.api + '/back/employee/'+id, { headers });
    return observable.pipe(
      map((rawUpdate: any) => {
        return true;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }

  public updateEmployeePassword(employeeId: number, newPassword: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = {
      'new_password': newPassword
    }
    const observable = this.http.put(environment.api + '/back/employee/'+employeeId+'/password', body, { headers });
    return observable.pipe(
      map((rawResult: any) => {
        return true;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }
}

