import { VehicleFuel } from "../vehicle/basic/VehicleFuel";

export class NovaCalculator {
    public RECHTSLAGE_JULI_2021 = [new Date(2021, 6, 1, 4, 0, 0, 0)];
    public RECHTSLAGE_JAN_2020_JUNI_2021 = [new Date(2020, 0, 1, 4, 0, 0, 0), new Date(2021, 6, 1, 23, 0, 0, 0)];
    public RECHTSLAGE_MAERZ_2014_DEZ_2020 = [new Date(2014, 2, 1, 4, 0, 0, 0), new Date(2019, 11, 31, 23, 0, 0, 0)];
    public RECHTSLAGE_JULI_2008_FEB_2014 = [new Date(2008, 6, 1, 4, 0, 0, 0), new Date(2014, 1, 28, 23, 0, 0, 0)];
    public RECHTSLAGE_JUNI_1996_JUNI_2008 = [new Date(1996, 5, 1, 4, 0, 0, 0), new Date(2008, 5, 30, 23, 0, 0, 0)];
    public RECHTSLAGE_JAN_1994_Mai_1996 = [new Date(1994, 0, 1, 4, 0, 0, 0), new Date(1996, 4, 31, 23, 0, 0, 0)];
    public RECHTSLAGE_JAN_1992_DEZ_1993 = [new Date(1992, 0, 1, 4, 0, 0, 0), new Date(1993, 11, 31, 23, 0, 0, 0)];
    public RECHTSLAGE_BIS_DEZ_1991 = [new Date(1800, 0, 1, 4, 0, 0, 0), new Date(1991, 11, 31, 23, 0, 0, 0)];
    constructor(
        public verkaufspreis_brutto: number,
        public steuersatz: number,
        public nova_tatbetand: number,
        public fahrzeugtyp: NovaFahrzeugtyp,
        public co2_emission: number,
        public fuel: VehicleFuel,
        public consumption: number,
        public power_kw: number,
        public partikel_in_range?: boolean,
        public nox_in_range?: boolean,
        public erstzulassung?: number,
        public neupreis?: number
        ) {
    }

    calcNovaTotal(): number {
        let total = Number((this.calcNovaGrundbetrag() + this.calcMalus() + this.calcErhoehungsbetrag() + this.calcNoxBonus() + this.calcAbzugsposten() + this.calcNoxMalus()).toFixed(2));
        if(total > 0) {
            return total;
        }
        return 0;
    }

    calcBreakEvenVK(ek_preis: number) {
        let novaDate = this.getNovaDate();
        let novaSatzMultiplier = (this.isInRange(novaDate, this.RECHTSLAGE_JAN_1994_Mai_1996) || this.isInRange(novaDate, this.RECHTSLAGE_JUNI_1996_JUNI_2008) || this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014)) ? 1.2 : 1;
        let multiplier = (novaDate > new Date(2009, 5, 17, 23, 0, 0, 0) && novaDate < new Date(2014, 2, 1, 2, 0, 0 ,0)) ? 1.2 : 1;
        let k1 = (1 + this.steuersatz /100 + this.calcNovaSatz()*novaSatzMultiplier / 100);
        let k2 = 0;
        if(this.isInRange(novaDate, this.RECHTSLAGE_JAN_1994_Mai_1996) || this.isInRange(novaDate, this.RECHTSLAGE_JUNI_1996_JUNI_2008) || this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014)) {
            k2 = 0.2;
        } 
        //TODO Math Min
        let k3 = 0;
        let k4 = this.calcNovaSatz()/100.0;
        let c1 = (this.calcNoxBonus() + this.calcMalus() + this.calcAbzugsposten());
        if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014)) {
            c1 = c1 + this.calcNoxMalus();
        }
        c1 = (c1 * multiplier) / k1;
        let c2 = this.calcMalus();
        let c3 = 0;
        if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014)) {
            if(novaDate > new Date(2009, 5, 17, 23, 0, 0, 0)) {
                c3 = this.calcNoxBonus() + this.calcMalus() + this.calcAbzugsposten() + this.calcNoxMalus();
            }
        }
        let c4 = this.calcNoxBonus();
        let c5 = this.calcAbzugsposten();
        let c6 = this.calcNoxMalus();

        let sumC =  - c1*k4 - c1*k2*k4 + c2 + c3*k2 +c4+c5+c6;
        let calcValue = (ek_preis + sumC) * k1 / (k1 - k4 - k4 * k2);
        let innerCalc = new NovaCalculator(calcValue, this.steuersatz, this.nova_tatbetand, this.fahrzeugtyp, this.co2_emission, this.fuel, this.consumption, this.power_kw, this.partikel_in_range, this.nox_in_range, this.erstzulassung, this.neupreis);
        if(innerCalc.calcNovaTotal() > 0) {
            return Number(calcValue.toFixed(2));
        } else {
            return ek_preis;
        }
    }

    test() {

    }

    

    calcNovaGrundbetrag(): number {
        let novaDate = this.getNovaDate();
        let netto = 0;
        if(this.isInRange(novaDate, this.RECHTSLAGE_JAN_1994_Mai_1996) || this.isInRange(novaDate, this.RECHTSLAGE_JUNI_1996_JUNI_2008)) {
            netto = (this.verkaufspreis_brutto - this.calcNoxBonus()) / (1 + this.steuersatz /100 + this.calcNovaSatz()*1.2 / 100);
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014)) {
            let multiplier = novaDate > new Date(2009, 5, 17, 23, 0, 0, 0) ? 1.2 : 1;
            netto = (this.verkaufspreis_brutto - (this.calcNoxBonus() + this.calcMalus() + this.calcNoxMalus() + this.calcAbzugsposten()) * multiplier) / (1 + this.steuersatz /100 + this.calcNovaSatz()*1.2 / 100);
        } else {
            netto = (this.verkaufspreis_brutto - (this.calcMalus() + this.calcAbzugsposten())) / (1 + this.steuersatz /100 + this.calcNovaSatz() / 100);
        }
        if(netto < 0) {
            return 0;
        }
        return Number((netto * (this.calcNovaSatz() / 100)).toFixed(2));
    }

    calcNovaSatz(): number {
        switch(this.fahrzeugtyp) {
            case NovaFahrzeugtyp.N1:
                return this.calcNovaSatzN1();
            case NovaFahrzeugtyp.MOTORRAD:
                return this.calcNovaSatzMotorrad();
            case NovaFahrzeugtyp.PKW:
                return this.calcNovaSatzPKW();
        }
    }

    calcAbzugsposten(): number {
        let novaDate = this.getNovaDate();
        if(this.fahrzeugtyp == NovaFahrzeugtyp.N1) {
            if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2021)) {
                return this.calculateAbschlag(-350);
            } else {
                return 0
            }
        }
        if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2021)) {
            return this.calculateAbschlag(-350);
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JAN_2020_JUNI_2021)) {
            return this.calculateAbschlag(-350);
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_MAERZ_2014_DEZ_2020)) {
            if(novaDate.getFullYear() == 2014) {
                return this.fuel.id == 1 ? this.calculateAbschlag(-350) : this.calculateAbschlag(-450);
            } else if(novaDate.getFullYear() == 2015) {
                return this.calculateAbschlag(-400);
            } else {
                return this.calculateAbschlag(-300);
            }
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014)) {
            if(this.co2_emission < 120 && (this.fuel.id == 1 || this.fuel.id == 2)) {
                return this.calculateAbschlag(-300);
            } else {
                return 0;
            }
        }else if(this.isInRange(novaDate, this.RECHTSLAGE_JAN_1992_DEZ_1993)) {
            return 0;
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_BIS_DEZ_1991)) {
            return 0;
        } else {
            return 0;
        }
    }

    calculateAbschlag(value: number): number {
        if(this.neupreis != undefined) {
            return value - value * (this.neupreis - this.verkaufspreis_brutto)/this.neupreis;
        } else {
            return this.calculateAchtel(value);
        }
    }

    calcMalus() {
        switch(this.fahrzeugtyp) {
            case NovaFahrzeugtyp.N1:
                return this.calcMalusN1();
            case NovaFahrzeugtyp.MOTORRAD:
                return this.calcMalusMotorrad();
            case NovaFahrzeugtyp.PKW:
                return this.calcMalusPKW();
        }
    }

    calcErhoehungsbetrag() {
        let novaDate = this.getNovaDate();
        let summeAbzug = 0;
        let multiplier = 0;
        if(this.isInRange(novaDate, this.RECHTSLAGE_JAN_1994_Mai_1996) || this.isInRange(novaDate, this.RECHTSLAGE_JUNI_1996_JUNI_2008) || this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014)) {
            multiplier = 0.2;
        }
        if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014)) {
            if(novaDate > new Date(2009, 5, 17, 23, 0, 0, 0)) {
                summeAbzug = this.calcNoxBonus() + this.calcMalus() + this.calcAbzugsposten() + this.calcNoxMalus();
            }
        }
        return  Number(((this.calcNovaGrundbetrag() + summeAbzug)*multiplier).toFixed(2));
    }

    calcNoxMalus() {
        let novaDate = this.getNovaDate();
        if(this.isInRange(novaDate, this.RECHTSLAGE_JUNI_1996_JUNI_2008) && (this.nox_in_range == undefined || this.nox_in_range == false) && this.fuel.id == 2) {
            if(novaDate > new Date(2005, 6, 1, 4, 0, 0, 0) && novaDate < new Date(2006, 5, 30, 23, 0, 0, 0)) {
                if(this.power_kw >= 80) {
                    let netto = (this.verkaufspreis_brutto) / (1 + this.steuersatz /100 + this.calcNovaSatz()*1.2 / 100);
                    return this.calculateAbschlag(Math.min((netto * (0.0075)), 150));
                } else {
                    return 0;
                }
            } else if(novaDate > new Date(2006, 5, 30, 23, 0, 0, 0)) {
                let netto = (this.verkaufspreis_brutto) / (1 + this.steuersatz /100 + this.calcNovaSatz()*1.2 / 100);
                return this.calculateAbschlag(Math.min((netto * (0.015)), 300));
            } else {
                return 0;
            }
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014) && (this.nox_in_range == undefined || this.nox_in_range == false) && this.fuel.id == 2) {
                return this.calculateAbschlag(300);
        } else {
            return 0;
        }
    }

    calcNoxBonus() {
        let novaDate = this.getNovaDate();
        if(this.isInRange(novaDate, this.RECHTSLAGE_JUNI_1996_JUNI_2008) && (this.nox_in_range == true) && this.fuel.id == 2) {
            if(novaDate > new Date(2005, 6, 1, 4, 0, 0, 0) && novaDate < new Date(2006, 5, 30, 23, 0, 0, 0)) {
                if(this.power_kw >= 80) {
                    return this.calculateAbschlag(-300);
                } else {
                    return 0;
                }
            } else if(novaDate > new Date(2006, 5, 30, 23, 0, 0, 0)) {
                return this.calculateAbschlag(-300);
            } else {
                return 0;
            }
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014)) {
            if((this.nox_in_range == true && this.partikel_in_range == true) && this.fuel.id == 2) {
                return this.calculateAbschlag(-200);
            } else if((this.partikel_in_range == true) && this.fuel.id == 1) {
                return this.calculateAbschlag(-200);
            } else if(this.fuel.id != 1 && this.fuel.id != 2) {
                return this.calculateAbschlag(-500);
            } else {
                return 0
            }
         } else {
            return 0;
        }
    }

    private calcMalusPKW() {
        let novaDate = this.getNovaDate();
        if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2021)) {
            let co2 = this.co2_emission;
            let grenzwert = 200;
            let yearsAfterStart = novaDate.getFullYear() - 2021;
            let malusBetrag = 50;
            if(yearsAfterStart > 3) {
                grenzwert = grenzwert - 3 * 15 ;
                malusBetrag = malusBetrag + 3 * 10;
            } else {
                grenzwert = grenzwert - yearsAfterStart * 15;
                malusBetrag = malusBetrag + yearsAfterStart * 10;
            }
            let valueToCalcuate = (co2 - grenzwert) * malusBetrag;
            if(valueToCalcuate < 0) {
                return 0;
            }
            return this.calculateAbschlag(valueToCalcuate);
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JAN_2020_JUNI_2021)) {
            let co2 = this.co2_emission;
            let grenzwert = 275;
            let malusBetrag = 40;
            let valueToCalcuate = (co2 - grenzwert) * malusBetrag;
            if(valueToCalcuate < 0) {
                return 0;
            }
            return this.calculateAbschlag(valueToCalcuate);
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_MAERZ_2014_DEZ_2020)) {
            let co2 = this.co2_emission;
            let grenzwert = 250;
            let malusBetrag = 20;
            let valueToCalcuate = (co2 - grenzwert) * malusBetrag;
            if(valueToCalcuate < 0) {
                return 0;
            }
            return this.calculateAbschlag(valueToCalcuate);
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014)) {
            if([2008, 2009].includes(novaDate.getFullYear())) {
                let co2_emission = this.co2_emission;
                let grenzwert = 180;
                let malusBetrag = 25;
                let valueToCalcuate = (co2_emission - grenzwert) * malusBetrag;
                if(valueToCalcuate < 0) {
                    return 0;
                }
                return this.calculateAbschlag(valueToCalcuate);
            } else if(novaDate.getFullYear() == 2010 || (novaDate.getFullYear() == 2011 && novaDate.getMonth() < 2)) {
                let co2_emission = this.co2_emission;
                let grenzwert = 160;
                let malusBetrag = 25;
                let valueToCalcuate = (co2_emission - grenzwert) * malusBetrag;
                if(valueToCalcuate < 0) {
                    return 0;
                }
                return this.calculateAbschlag(valueToCalcuate);
            } else if(novaDate.getFullYear() == 2012 || (novaDate.getFullYear() == 2011 && novaDate.getMonth() >= 2)) {
                let co2_emission = this.co2_emission;
                let grenzwert = 160;
                let malusBetrag = 25;
                let grenzwert2 = 180;
                let grenzwert3 = 220;
                let valueToCalcuate = (co2_emission - grenzwert) * malusBetrag;
                if(valueToCalcuate < 0) {
                    valueToCalcuate = 0;
                }
                let valueToCalcuate2 = (co2_emission - grenzwert2) * malusBetrag;
                if(valueToCalcuate2 < 0) {
                    valueToCalcuate2 = 0;
                }
                let valueToCalcuate3 = (co2_emission - grenzwert3) * malusBetrag;
                if(valueToCalcuate3 < 0) {
                    valueToCalcuate3 = 0;
                }
                return this.calculateAbschlag(valueToCalcuate +valueToCalcuate2 + valueToCalcuate3);
            } else {
                let co2_emission = this.co2_emission;
                let grenzwert = 150;
                let malusBetrag = 25;
                let grenzwert2 = 170;
                let grenzwert3 = 210;
                let valueToCalcuate = (co2_emission - grenzwert) * malusBetrag;
                if(valueToCalcuate < 0) {
                    valueToCalcuate = 0;
                }
                let valueToCalcuate2 = (co2_emission - grenzwert2) * malusBetrag;
                if(valueToCalcuate2 < 0) {
                    valueToCalcuate2 = 0;
                }
                let valueToCalcuate3 = (co2_emission - grenzwert3) * malusBetrag;
                if(valueToCalcuate3 < 0) {
                    valueToCalcuate3 = 0;
                }
                return this.calculateAbschlag(valueToCalcuate +valueToCalcuate2 + valueToCalcuate3);
            }
        } else {
            return 0;
        }
    }

    private calculateAchtel(value: number): number {
        if(this.isNew()) {
            return value;
        } else {
            let ezDate = new Date(this.erstzulassung!);
            let novaDate = new Date(this.nova_tatbetand);
            let yearDistance = novaDate.getFullYear() - ezDate.getFullYear();
            if(yearDistance <= 0) {
                return value;
            }
            if(yearDistance >= 8) {
                yearDistance = 8;
            }
            if(ezDate.getMonth() > novaDate.getMonth()) {
                return Number((value * ((8 - (yearDistance - 1)) / 8)).toFixed(2));
            } else if(ezDate.getMonth() == novaDate.getMonth() && ezDate.getDate() > novaDate.getDate()) {
                return Number((value * ((8 - (yearDistance - 1)) / 8)).toFixed(2));;
            } else {
                if(yearDistance == 8) {
                    return Number((value * ((8 - (yearDistance - 1)) / 8)).toFixed(2));
                }
                return Number((value * ((8 - yearDistance) / 8)).toFixed(2));
            }
        }
    }

    private calcMalusN1() {
        let novaDate = this.getNovaDate();
        if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2021)) {
            let co2 = this.co2_emission;
            let grenzwert = 253;
            let yearsAfterStart = novaDate.getFullYear() - 2021;
            let malusBetrag = 50;
            if(yearsAfterStart > 3) {
                grenzwert = grenzwert - 3 * 15 ;
                malusBetrag = malusBetrag + 3 * 10;
            } else {
                grenzwert = grenzwert - yearsAfterStart * 15;
                malusBetrag = malusBetrag + yearsAfterStart * 10;
            }
            let valueToCalcuate = (co2 - grenzwert) * malusBetrag;
            if(valueToCalcuate < 0) {
                return 0;
            }
            return this.calculateAbschlag(valueToCalcuate);
        } else {
            return 0;
        }
    }

    private calcMalusMotorrad() {
        return 0;
    }

    private isNew(): boolean {
        return this.erstzulassung == undefined || this.erstzulassung == null;
    }

    public getNovaDate(): Date {
        let novaDate = 0;
        if(this.isNew()) {
            novaDate = this.nova_tatbetand;
        } else {
            novaDate = this.erstzulassung!;
        }

        let date = new Date(novaDate);
        date.setHours(12);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    private isInRange(date: Date, range: Date[]): boolean {
        if(date >= range[0]) {
            if(range.length == 1) {
                return true;
            } else if(date < range[1]) {
                return true;
            }
        }
        return false;
    }

    private calcNovaSatzPKW(): number {
        let novaDate = this.getNovaDate();
        if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2021)) {
            let co2 = this.co2_emission;
            let abzugsbetrag = 112;
            let yearsAfterStart = novaDate.getFullYear() - 2021;
            if(yearsAfterStart > 3) {
                abzugsbetrag = abzugsbetrag - 3 * 5 - (yearsAfterStart - 3) * 3;
            } else {
                abzugsbetrag = abzugsbetrag - yearsAfterStart * 5;
            }
            let valueToCalcuate = Math.round((co2 - abzugsbetrag) / 5);
            let maxValue = Math.min(50 + yearsAfterStart * 10, 80);
            if(maxValue < valueToCalcuate) {
                return maxValue;
            } else if (valueToCalcuate < 0) {
                return 0;
            }
            return valueToCalcuate;
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JAN_2020_JUNI_2021)) {
            let co2 = this.co2_emission;
            let abzugsbetrag =  novaDate.getFullYear() == 2020 ? 115 : 112;
            let valueToCalcuate = Math.round((co2 - abzugsbetrag) / 5);
            if(32 < valueToCalcuate) {
                return 32;
            } else if (valueToCalcuate < 0) {
                return 0;
            }
            return valueToCalcuate;
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_MAERZ_2014_DEZ_2020)) {
            let co2 = this.co2_emission;
            let abzugsbetrag =  90;
            let valueToCalcuate = Math.round((co2 - abzugsbetrag) / 5);
            if(32 < valueToCalcuate) {
                return 32;
            } else if (valueToCalcuate < 0) {
                return 0;
            }
            return valueToCalcuate;
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2008_FEB_2014)) {
            let consumption = this.consumption;
            if(this.fuel.id == 2) {
                consumption = consumption! - 2;
            } else {
                consumption = consumption! - 3;
            }
            let valueToCalcuate = Math.round(consumption * 2);
            if(16 < valueToCalcuate) {
                return 16;
            } else if (valueToCalcuate < 0) {
                return 0;
            }
            return valueToCalcuate;
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JUNI_1996_JUNI_2008)) {
            let consumption = this.consumption;
            if(this.fuel.id == 2) {
                consumption = consumption! - 2;
            } else {
                consumption = consumption! - 3;
            }
            let valueToCalcuate = Math.round(consumption * 2);
            if(16 < valueToCalcuate) {
                return 16;
            } else if (valueToCalcuate < 0) {
                return 0;
            }
            return valueToCalcuate;
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JAN_1994_Mai_1996)) {
            let consumption = this.consumption;
            if(this.fuel.id == 2) {
                consumption = consumption! - 2;
            } else {
                consumption = consumption! - 3;
            }
            let valueToCalcuate = Math.round(consumption * 2);
            if(14 < valueToCalcuate) {
                return 14;
            } else if (valueToCalcuate < 0) {
                return 0;
            }
            return valueToCalcuate;
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JAN_1992_DEZ_1993)) {
            let consumption = this.consumption;
            if(this.fuel.id == 2) {
                consumption = consumption! - 2;
            } else {
                consumption = consumption! - 3;
            }
            let valueToCalcuate = Math.round(consumption * 2);
            if(14 < valueToCalcuate) {
                return 14;
            } else if (valueToCalcuate < 0) {
                return 0;
            }
            return valueToCalcuate;
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_BIS_DEZ_1991)) {
            return 0;
        } else {
            return 0;
        }
    }

    private calcNovaSatzN1(): number {
        let novaDate = this.getNovaDate();
        if(this.isInRange(novaDate, this.RECHTSLAGE_JULI_2021)) {
            let co2 = this.co2_emission;
            let abzugsbetrag = 165;
            let yearsAfterStart = novaDate.getFullYear() - 2021;
            if(yearsAfterStart > 3) {
                abzugsbetrag = abzugsbetrag - 3 * 5 - (yearsAfterStart - 3) * 3;
            } else {
                abzugsbetrag = abzugsbetrag - yearsAfterStart * 5;
            }
            let valueToCalcuate = Math.round((co2 - abzugsbetrag) / 5);
            let maxValue = Math.min(50 + yearsAfterStart * 10, 80);
            if(maxValue < valueToCalcuate) {
                return maxValue;
            } else if (valueToCalcuate < 0) {
                return 0;
            }
            return valueToCalcuate;
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_JAN_2020_JUNI_2021)) {
            return 0;
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_MAERZ_2014_DEZ_2020)) {
            return 0;
        } else if(this.isInRange(novaDate, this.RECHTSLAGE_BIS_DEZ_1991)) {
            return 0;
        } else {
            return 0;
        }
    }

    private calcNovaSatzMotorrad(): number {
        return 0;
    }
}

export enum NovaFahrzeugtyp {
    PKW = 1,
    N1 = 2,
    MOTORRAD = 3
}
