import { Subject } from "rxjs";
import { LoadingService } from "../service/loading/loading.service";
import { ReloadServiceService } from "../service/reloadService/reload-service.service";

export class ReloadDataHandler {

  private reloadService: ReloadServiceService;

  constructor() {
    this.reloadService = new ReloadServiceService();
  }

  public activateReloadLoop(services: any[]) {
    this.reloadService.activateReloadLoop(services);
  }

  public deactivateReloadLoop(services: any[]) {
    this.reloadService.deactivateReloadLoop(services);
  }
}
