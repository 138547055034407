import { Component, Input, OnInit } from '@angular/core';
import { Record } from 'src/app/buisness-object/overview/Record';

@Component({
  selector: 'app-overview-record-elements-container',
  templateUrl: './overview-record-elements-container.component.html',
  styleUrls: ['./overview-record-elements-container.component.css']
})
export class OverviewRecordElementsContainerComponent implements OnInit {
  public records: Record[] = [new Record('Label','Value','Placeholder',1),new Record('Label','Value','Placeholder',1),new Record('Label','Value','Placeholder',1)]; 

  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
