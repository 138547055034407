<div id="drive-container">
    <div class="title">Antrieb</div>
    <div class="info">Audi A4 Avant 2,0 TDI ***AKTION*** Virtual Cockpit Kombi</div>
    <div id="input-container">
        <div id="container1">
            <app-dropdown-select-container [placeholder]="'Treibstoffart *'" [value]="f.fuelTyp.value" [dropDownWith]="dropDownWith" [options]="fuelTyp" [borderBottomColor]="'#597CF1'" [dropOptionHover]="'blue'" (selectEmitter)="setFuelTyp($event)" [invalid]="submitted && f.fuelTyp.errors"></app-dropdown-select-container>
            <app-dropdown-select-container [placeholder]="'Antriebsart'" [value]="f.driveTyp.value" [dropDownWith]="dropDownWith" [options]="driveTyp" [borderBottomColor]="'#597CF1'" [dropOptionHover]="'blue'" (selectEmitter)="setDriveTyp($event)" [invalid]="submitted && f.driveTyp.errors"></app-dropdown-select-container>
            <app-dropdown-select-container [placeholder]="'Gänge'" [value]="f.gears.value" [dropDownWith]="dropDownWith" [options]="gears" [borderBottomColor]="'#597CF1'" [dropOptionHover]="'blue'" (selectEmitter)="setGears($event)" [invalid]="false"></app-dropdown-select-container>
            <app-dropdown-select-container [placeholder]="'Getriebeart *'" [value]="f.gearTyp.value" [dropDownWith]="dropDownWith" [options]="gearTyp" [borderBottomColor]="'#597CF1'" [dropOptionHover]="'blue'" (selectEmitter)="setGearsTyp($event)" [invalid]="submitted && f.gearTyp.errors"></app-dropdown-select-container>
            <input type="number" class="inputfield" [ngClass]="submitted && f.power.errors ? 'inputfield_invalid' : ''" placeholder="Leistung PS / KW *" formControlName="power" (ngModel)="f.power.value">
        </div>
        <div id="container2">
            <input type="number" class="inputfield" [ngClass]="submitted && f.power.errors ? 'inputfield_invalid' : ''" placeholder="Hubraum" formControlName="power" (ngModel)="f.power.value">
            <app-dropdown-select-container [placeholder]="'Verbrauch I / A / K *'" [value]="f.gearTyp.value" [dropDownWith]="dropDownWith" [options]="consumption" [borderBottomColor]="'#597CF1'" [dropOptionHover]="'blue'" (selectEmitter)="setGearsTyp($event)" [invalid]="submitted && f.gearTyp.errors"></app-dropdown-select-container>
            <div id="numbers">
                <input type="number" class="inputfield" [ngClass]="submitted && f.power.errors ? 'inputfield_invalid' : ''" placeholder="NoVA" formControlName="power" (ngModel)="f.power.value">
                <input type="number" class="inputfield" [ngClass]="submitted && f.power.errors ? 'inputfield_invalid' : ''" placeholder="Nox" formControlName="power" (ngModel)="f.power.value">
                <input type="number" class="inputfield" [ngClass]="submitted && f.power.errors ? 'inputfield_invalid' : ''" placeholder="Ndec" formControlName="power" (ngModel)="f.power.value">
                <input type="number" class="inputfield" [ngClass]="submitted && f.power.errors ? 'inputfield_invalid' : ''" placeholder="Wltp" formControlName="power" (ngModel)="f.power.value">
            </div>
        </div>
        <div id="container3">
            <app-dropdown-select-container [placeholder]="'Effizienz'" [value]="f.gearTyp.value" [dropDownWith]="dropDownWith" [options]="efficiency" [borderBottomColor]="'#597CF1'" [dropOptionHover]="'blue'" (selectEmitter)="setGearsTyp($event)" [invalid]="submitted && f.gearTyp.errors"></app-dropdown-select-container>
            <app-dropdown-select-container [placeholder]="'Abgasnorm'" [value]="f.gearTyp.value" [dropDownWith]="dropDownWith" [options]="emissionStandard" [borderBottomColor]="'#597CF1'" [dropOptionHover]="'blue'" (selectEmitter)="setGearsTyp($event)" [invalid]="submitted && f.gearTyp.errors"></app-dropdown-select-container>
        </div>
    </div>
</div>
