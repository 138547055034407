import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Kaufvertrag } from 'src/app/buisness-object/document/Kaufvertrag';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { DocumentService } from 'src/app/service/document/document.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-dialog-receive-funding',
  templateUrl: './dialog-receive-funding.component.html',
  styleUrl: './dialog-receive-funding.component.css'
})
export class DialogReceiveFundingComponent implements OnInit {
  @Input() obj: Kaufvertrag;
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() selecteionSumbitEmitter = new EventEmitter<Kaufvertrag>();
  public form: FormGroup;
  public finanzierungsgesellschaftValues = [];
  public finanzierungsgesellschaftValue;
  public submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private documentService: DocumentService,
    private dialogService: DialogService,
    private enumService: EnumService
  ){}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      finanzierungsgsellschaft: [null, Validators.required]
    })
    this.finanzierungsgesellschaftValues = this.enumService.getEnumValues('finanzierungsgesellschaft');
  }

  selectionFinanzierungsgesellschaft(value) {

    this.form.get('finanzierungsgsellschaft').setValue(value);
    this.finanzierungsgesellschaftValue = this.enumService.getEnumValue('finanzierungsgesellschaft', this.form.controls['finanzierungsgsellschaft'].value);
  }

  selectionSubmit() {
    this.submitted == true;
    if(this.form.invalid){
      return;
    }
    this.obj.finanzierungsgesellschaft = this.form.get('finanzierungsgsellschaft').value;
    this.documentService.updateKaufvertrag(this.obj).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Finanzierungsgesellschaft aktualisiert',
          success: true
        });
        this.selecteionSumbitEmitter.emit(result);
      }
    })
  }
}

