import { VehicleModel } from "./VehicleModel";

export class VehicleBrand {
    // public models: VehicleModel[] = [];
    // public model: VehicleModel;
    constructor(
        public id: number,
        public makeName: string,
        public models: VehicleModel[]
    ){}
}
