import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css']
})
export class UploadDocumentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  async onFileDropped(data: string) {

  }

  async uploadFile(event: any): Promise<any> {

  }
}
