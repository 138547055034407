import { CustomerFactory } from "../../customer/factory/CustomerFactory";
import { VehicleBrandFactory } from "../../vehicle/basic/factory/VehicleBrandFactory";
import { VehicleModelFactoy } from "../../vehicle/basic/factory/VehicleModelFactoy";
import { VehicleFactory } from "../../vehicle/factory/VehicleFactory";
import { VehicleStatus } from "../Kaufvertrag";
import { OldVehicle, SalesContract } from "../SalesContract";

export class SalesContractFactory {
  static jsonFactory(rawBody: any, chatgpt_flag_allowed: boolean): SalesContract[] {
    const data: SalesContract[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart, chatgpt_flag_allowed));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any, chatgpt_flag_allowed: boolean): SalesContract {
        const obj = new SalesContract(
          rawPart.purchase_contract_id,
          rawPart.customer_id,
          rawPart.vehicle_id,
          rawPart.purchase_date,
          rawPart.handing_over_date,
          rawPart.price,
          rawPart.registration_document,
          rawPart.is_original_document,
          SalesContractVehicleStatusFactory.jsonFactoryOne(rawPart.vehicle_status),
          rawPart.payment_condition,
          rawPart.status,
          rawPart.foreign_registration_date,
          rawPart.purchase_test_date,
          rawPart.purchase_tester,
          rawPart.warranty_period,
          rawPart.deposit,
          SalesContractOldVehicleFactory.jsonFactoryOne(rawPart.old_vehicle),
          rawPart.special_conditions,
          rawPart.finanzierungsgesellschaft,
          rawPart.finanzierungsart
        )
        if(rawPart.customer) obj.customer = CustomerFactory.jsonFactoryOne(rawPart.customer);
        if(rawPart.vehicle) obj.vehicle = VehicleFactory.jsonFactoryOne(rawPart.vehicle, chatgpt_flag_allowed);
        return obj;
  }

}

export class SalesContractVehicleStatusFactory {
  static jsonFactoryOne(rawPart: any): VehicleStatus {
        return new VehicleStatus(
          rawPart.mechanical_condition,
          rawPart.body_condition,
          rawPart.paint_condition,
          rawPart.interior_condition,
          rawPart.electronic_condition,
        )
  }
}

export class SalesContractOldVehicleFactory {
  static jsonFactoryOne(rawPart: any): OldVehicle {
        return new OldVehicle(
          VehicleBrandFactory.jsonFactoryOne(rawPart.brand.make),
          VehicleModelFactoy.jsonFactoryOne(rawPart.brand.model),
          rawPart.registration_document,
          rawPart.mileage,
          rawPart.price,
          rawPart.registration_date,
          rawPart.previous_owner,
          rawPart.vin_number,
          rawPart.mileage_to_drive,
        )
  }
}
