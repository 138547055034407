<button
    *ngIf="permitted"
    [class.restricted-button]="!isTabButton"
    [class.restricted-primary]="isPrimary"
    [class.restricted-secondary]="isSecondary"
    [class.restricted-button-tab]="isTabButton"
    [class.restricted-button-tab-active]="isTabButtonActive"
    [class.restricted-button-list-action]="isListAction"
    [class.restricted-button-dropdown]="isDropdownButton"
    [disabled]="disabled"
    (click)="selection()">
    {{name}}
    <img *ngIf="isListAction" [src]="isListActionIcon">
</button>
