import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { CustomerFactory } from 'src/app/buisness-object/customer/factory/CustomerFactory';
import { Salutation } from 'src/app/buisness-object/customer/Salutation';
import { Title } from 'src/app/buisness-object/customer/Title';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})

export class CustomerService {

  public customers$ = new BehaviorSubject<any>(0);

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) {}

  public getCustomer(): Observable<Customer[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/customer', { headers });
    return observable.pipe(
      map((rawCustomers: any) => {
        const customers = CustomerFactory.jsonFactory(rawCustomers.customes);
        customers.sort((a,b) => -(a.lastUpdated - b.lastUpdated));
        this.customers$.next(customers);
        return customers;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error?.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createCustomer(customer: Customer): Observable<Customer> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = customer.getJSON();
    const observable = this.http.post(environment.api + '/back/customer', body, { headers });
    return observable.pipe(
      map((rawCustomer: any) => {
        const customer = CustomerFactory.jsonFactoryOne(rawCustomer.customer);
        return customer;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  updateCustomer(customer: Customer): Observable<Customer> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = customer.getJSON();
    const observable = this.http.put(environment.api + '/back/customer', body, { headers });
    return observable.pipe(
      map((rawCustomer: any) => {
        const customer = CustomerFactory.jsonFactoryOne(rawCustomer.customer);
        return customer;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  updateSavedCustomer(customer: Customer, savedId: number): Observable<Customer> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = customer.getJSON();
    body.saved_id = savedId;
    const observable = this.http.put(environment.api + '/back/customer/saved', body, { headers });
    return observable.pipe(
      map((rawCustomer: any) => {
        const customer = CustomerFactory.jsonFactoryOne(rawCustomer.customer);
        return customer;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public deleteCustomer(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.delete(environment.api + '/back/customer/' + id, { headers });
    return observable.pipe(
      map((rawCustomer: any) => {
        return rawCustomer;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getTitles(): Observable<Title[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    // const observable = this.http.get(environment.api + '/back/customer', { headers });
    // return observable.pipe(
    //   map((rawCustomers: any) => {
    //     const customers = CustomerFactory.jsonFactory(rawCustomers.customes);
    //     return customers;
    //   }),catchError(error => {
    //     if(error.status == 403){
    //       this.lService.logout();
    //     } else {
    //       this.notifyService.showFailure('Fehlermeldung: ' + error.error.error_message, 'Fehler');
    //     }
    //     return [];
    //   })
    // );
    //['Kein Titel','BA','BSc','BEd','Ing.','Dipl.-Ing','Dr.','Mag.','MA','MBA','MEd','MSc','Prof.'];
    return of(
      [
        new Title(1,'Kein Titel'),
        new Title(2,'Prof.'),
        new Title(3,'Mag.'),
        new Title(4,'Dr.'),
        new Title(5,'Ing.'),
        new Title(6,'Dipl.-Ing.'),
        new Title(7,'BSc.'),
        new Title(8,'BEd.'),
        new Title(9,'MBA.'),
        new Title(10,'MA.'),
        new Title(11,'MEd.'),
        new Title(12,'MSc.'),
      ]
    )
  }

  public getSalutations(): Observable<Salutation[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    // const observable = this.http.get(environment.api + '/back/customer', { headers });
    // return observable.pipe(
    //   map((rawCustomers: any) => {
    //     const customers = CustomerFactory.jsonFactory(rawCustomers.customes);
    //     return customers;
    //   }),catchError(error => {
    //     if(error.status == 403){
    //       this.lService.logout();
    //     } else {
    //       this.notifyService.showFailure('Fehlermeldung: ' + error.error.error_message, 'Fehler');
    //     }
    //     return [];
    //   })
    // );
    //['Kein Titel','BA','BSc','BEd','Ing.','Dipl.-Ing','Dr.','Mag.','MA','MBA','MEd','MSc','Prof.'];
    return of(
      [new Salutation(1,'Frau'),new Salutation(2,'Herr'),new Salutation(3,'Diverse'),new Salutation(5,'Familie'),new Salutation(4,'Keine Angaben')]
    )
  }

  public getDataPrivacy(id: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/customer/dataprivacy/' + id, {headers, responseType: 'arraybuffer'});
    return observable.pipe(
      map((result: any) => {
        // this.openPdfNewTab(result);
        return result;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        }  else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  openPdfNewTab(data: any) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.click();
  }
}
