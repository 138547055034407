import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../BaseComponent';
import { Rent, RentTyp, RentTypLabels } from 'src/app/buisness-object/rent/Rent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { RentForm } from 'src/app/buisness-object/rent/form/RentForm';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { RentService } from 'src/app/service/rent/rent.service';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { VehicleForm } from 'src/app/buisness-object/vehicle/form/VehicleForm';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { VehicleFuel } from 'src/app/buisness-object/vehicle/basic/VehicleFuel';
import { getEnumFromLabel, getLabelFromEnumValue, getEnumLabelValues } from 'src/app/utils/EnumHandler';
import { formatDate } from '@angular/common';
import { DateHelper } from 'src/app/utils/DateHelper';

@Component({
  selector: 'app-miete-create',
  templateUrl: './miete-create.component.html',
  styleUrl: './miete-create.component.css'
})
export class MieteCreateComponent extends BaseComponent implements OnInit {

  @Input() obj: Rent;
  @Input() vehicles: Vehicle[];
  @Input() type: number;
  @Output() selectionCancelEmitter = new EventEmitter<any>();
  @Output() updateSuccessEmitter = new EventEmitter<any>();
  @Output() createSuccessEmitter = new EventEmitter<any>();

  public form: FormGroup;
  public customers: Customer[];
  public submitted = false;
  public locationValues = [];
  public salutationValue;
  public titleValues;
  public salutationValues = [];
  public mainDriverChanged = false;
  public secondDriverChanged = false;
  public vehicleChanged = false;
  public vehicleValues = [];
  public mainDriverValues = [];
  public brands = [];
  public models = [];
  public fuels = [];
  public brandValues = [];
  public modelValues = [];
  public fuelsValues= [];
  public rentTypValue;
  public rentTypValues = [];
  public minDate;
  public durationValues = [{id: 0, name: '6 Monate'}, {id: 1, name: '12 Monate'}, {id: 2, name: '18 Monate'}, {id: 3, name: '24 Monate'}];
  public kmValues = [{id: 0, name: 1000}, {id: 1, name: 2000}, {id: 2, name: 3000}, {id: 3, name: 4000}];

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private formBuilder: FormBuilder,
    private rentService: RentService,
    private dialogService: DialogService,
    private customerService: CustomerService,
    private vehicleService: VehicleService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.setup();
    this.mainDriverChangeListener();

    let date = new Date();
    this.minDate = formatDate(date,'yyyy-MM-dd','de');
  }

  selectionCancel() {
    this.selectionCancelEmitter.emit();
  }

  getDataServerside(): Observable<any> {
    return forkJoin({
      customers: this.customerService.getCustomer(),
      brands: this.vehicleService.getBrands(),
      fuels: this.vehicleService.getFuels()
    }).pipe(
      tap((result) => {
        if(result){
          this.customers = result.customers;
          this.brands = result.brands;
          this.fuels = result.fuels;
        }
      }),catchError((error) => {
        console.error(error);
        return of([]);
      })
    );
  }

  get formVehicle() {
    return this.form?.get('vehicle_form').value;
  }

  get formMainDriver() {
    return this.form?.get('main_driver_form').value;
  }

  async setup() {
    await firstValueFrom(this.getDataServerside());
    this.form = RentForm.getForm(this.formBuilder, this.obj);
    this.rentTypValue = getLabelFromEnumValue(this.form.get('rent_typ').value, RentTypLabels);
    this.rentTypValues = getEnumLabelValues(RentTyp, RentTypLabels);
    this.setDropdownValues();
    let t = this.enumService.getEnums('rent_typ');
    if(this.obj){
      for(let i = 0 ; i < t.length; i++){
        if(t[i].value == this.obj.rent_typ){
          this.form.get('rent_typ').setValue(t[i].raw_value)
        }
      }
    }
    this.salutationValues = this.enumService.getEnumValues('salutation');
    this.vehicleValues = this.getDropdownValuesVehiclesWithPrice(this.vehicles);
    this.mainDriverValues = this.getDropdownValuesCustomer(this.customers);
  }


    getDropdownValuesVehiclesWithPrice(vehicles: Vehicle[]): any[] {
      let values = [];
      let mietAboValues = [];
      for(let value of vehicles){
          if(value.rent_price_pro_tag){
            values.push({
              id: value.id,
              name: value.getVehicleName()
            })
          }
        else if(!value.rent_price_pro_tag && value.rent_typ == 1){
            mietAboValues.push({
              id: value.id,
              name: value.getVehicleName()
            })
        }
      }
      if(this.type == 1){
        return mietAboValues;
      } else {
        return values;
      }
    }

    getDropdownValuesVehicleRents(): any[] {
      let values = [];
      let vehicle = this.form.controls['vehicle'].value;
      for(let rent of vehicle.rents)
      {
        if(rent.duration){
          values.push({
            id: rent.rent_price_id,
            name: rent.duration
          })
        }
      }
      return values;
    }

    selectionRentTyp(typ: string) {
      this.form.controls['rent_typ'].setValue(Number(getEnumFromLabel(typ, RentTypLabels)));
      this.rentTypValue = typ;
    }

   mainDriverChangeListener() {
      if(this.obj){
        this.form?.get('main_driver_form').value.valueChanges.subscribe((value) => {
          this.mainDriverChanged = true;
        })
      }
    }

  async selectionCreateRent() {
    this.submitted = true;
    if(this.invalidForm()) return;
    let rent = RentForm.getObject(this.form);
    let main_driver = this.form.get('main_driver').value;
    if(this.mainDriverChanged){
      main_driver = CustomerForm.createObject(this.form.get('main_driver_form').value);
      main_driver = await this.customerService.updateCustomer(main_driver).toPromise();
    }
    rent.main_driver = main_driver;
    rent.main_driver_id = main_driver.id;
    this.createRent(rent);
  }

  createRent(rent: Rent) {
    this.rentService.createRent(rent).subscribe((result) => {
      if(result){
        result.vehicle = rent.vehicle;
        result.main_driver = rent.main_driver;
        result.second_driver = rent.second_driver;
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Miete wurde erstellt.',
          success: true
        });

        this.createSuccessEmitter.emit(result);
      }
    })
  }

  async selectionUpdateRent() {
    this.submitted = true;
    if(this.invalidForm()) return;
    let rent = RentForm.getObject(this.form);
    let main_driver = this.form.get('main_driver').value;
    main_driver = CustomerForm.createObject(this.form.get('main_driver_form').value);
    main_driver = await this.customerService.updateCustomer(main_driver).toPromise();
    rent.main_driver = main_driver;
    rent.main_driver_id = main_driver.id;
    this.updateRent(rent);
  }

  updateRent(rent: Rent) {
    this.rentService.updateRent(rent).subscribe((result) => {
      if(result){
        result.vehicle = rent.vehicle;
        result.main_driver = rent.main_driver;
        result.second_driver = rent.second_driver;
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Miete wurde aktualisiert.',
          success: true
        });
        this.updateSuccessEmitter.emit(result);
      }
    })
  }

  invalidForm(): boolean {
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return true;
    }else if(this.formMainDriver.invalid){
      ErrorHelper.showFormError(this.formMainDriver);
      return true;
    } else if(this.formVehicle.invalid){
      ErrorHelper.showFormError(this.formVehicle);
      return true;
    }
  }

  setDropdownValues() {
    this.brandValues = [];
    this.modelValues = [];
    this.fuelsValues = [];
    for(let v of this.brands){
      this.brandValues.push({
        id: v.id,
        name: v.makeName
      })
    }
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.fuels){
      this.fuelsValues.push({
        id: v.id,
        name: v.name
      })
    }
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'vehicle') {
      let index = this.vehicles.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['vehicle'].setValue(this.vehicles[index]);
        this.form.controls['vehicle_id'].setValue(this.vehicles[index].id);
        this.form.controls['rent_price_eur'].setValue(this.vehicles[index].rent_price_pro_tag);
        this.form.controls['rent_typ'].setValue(this.vehicles[index].rent_typ);
        this.form.get('vehicle_form').setValue(VehicleForm.getVehicleForm(this.formBuilder, this.vehicles[index]));
      } 
    } else if(value === 'main_driver') {
      let index = this.customers.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.get('main_driver').setValue(this.customers[index]);
        this.form.get('main_driver_form').setValue(CustomerForm.getCustomerForm(this.formBuilder, this.customers[index]));
        this.form?.get('main_driver_form').value.valueChanges.subscribe((value) => {
          if(value){
            this.mainDriverChanged = true;
          }
        })
      }
    } else if(value === 'brand'){
      let index = this.brands.findIndex(v => v.id == id);
      if(index > -1) this.setBrand(this.brands[index]);
    } else if(value === 'model'){
      let index = this.models.findIndex(v => v.id == id);
      if(index > -1) this.setModel(this.models[index]);
    } else if(value === 'fuel'){
      let index = this.fuels.findIndex(v => v.id == id);
      if(index > -1) this.setFuel(this.fuels[index]);
    } else if(value === 'rent_duration'){
      let index = this.durationValues.findIndex(v => v.id == id);
      if(index > -1) this.form.get('rent_duration').setValue(this.durationValues[index].name);
    } else if(value === 'km_limit'){
      let index = this.kmValues.findIndex(km => km.id == id);
      if(index > -1) this.form.get('km_limit').setValue(this.kmValues[index].name);
    }

    if(this.form.get('km_limit').value && this.form.get('rent_duration').value && this.form.get('vehicle').value) {
        let rent_price = this.getRentPrice(this.form.get('rent_duration').value, this.form.get('km_limit').value, this.form.get('vehicle').value);
        this.form.controls['rent_price_eur'].setValue(rent_price);
      }

    if(this.form.get('km_limit').value && this.form.get('rent_duration').value && this.form.get('rental_start_date').value) {

      const end_date = this.calcEndDate(this.form.get('rent_duration').value, this.form.get('rental_start_date').value);
      this.form.controls['rental_end_date'].setValue(DateHelper.getConvertedDateForInputFull(end_date));
      this.form.controls['rental_start_time'].setValue(DateHelper.getConvertedTimeForInputFull(this.form.get('rental_start_date').value));
      this.form.controls['rental_end_time'].setValue(DateHelper.getConvertedTimeForInputFull(end_date));
    }
  }

  calcEndDate(rent_duration: string, rent_start_date: number) {
    const parts = rent_duration.split(" ");
    const numberPart = parseInt(parts[0], 10);

    let start = new Date(rent_start_date);
    return start.setMonth(start.getMonth() + numberPart)
  }
  
  getRentPrice(rent_duration: string, km_limit: any, vehicle: Vehicle): number {
      const durationMap = {
          '6 Monate': 0,
          '12 Monate': 4,
          '18 Monate': 8,
          '24 Monate': 12,
      };
      
      const kmLimitMap = {
          1000: 0,
          2000: 1,
          3000: 2,
          4000: 3,
      };
  
      const durationOffset = durationMap[rent_duration];
      const kmLimitOffset = kmLimitMap[km_limit];
  
      if (durationOffset !== undefined && kmLimitOffset !== undefined) {
          const index = durationOffset + kmLimitOffset;
          return vehicle.rents[index].rent_price;
      }
  
      return 0;
  }

  setBrand(brand: VehicleBrand) {
    this.formVehicle.get('brand').setValue(brand);
    this.models = brand.models;
    this.modelValues = [];
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
    this.formVehicle.get('model').setValue(null);
  }

  setModel(model: VehicleModel) {
    this.formVehicle.get('model').setValue(model);
  }

  setFuel(fuel: VehicleFuel) {
    this.formVehicle.get('fuel').setValue(fuel);
  }
}
