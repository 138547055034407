<div class="car_create_page_wrapper">
    <div class="title">Basisdaten</div>
    <form id="input-container" [formGroup]="formGroup" autocomplete="off">
        <div id="container1">
          <div class="block">
            <h3 class="inner_title">Preis</h3>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Preis in €</label>
              <input type="number" placeholder="Preis in €" formControlName="price" [class.input_invalid]="submitted && f['price'].errors">
            </div>
            <div class="input_wrapper" *ngIf="authService.showCreateValue('rent_price')">
              <label class="overview_record_lbl">Mietpreis pro Monat in €</label>
              <input type="number" placeholder="Mietpreis pro Monat in €" formControlName="rent_price" [class.input_invalid]="submitted && f['rent_price'].errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Händlerpreis in €</label>
              <input type="number" placeholder="Händlerpreis in €" formControlName="dealerPrice" [class.input_invalid]="submitted && f['dealerPrice'].errors">
            </div>
            <app-dropdown-multiple
              [title]="'Steuersatz'"
              [formcontrol]="f['steuersatz_pricesheet']"
              [headerText]="getSteuersatz(f['steuersatz_pricesheet'].value)"
              [values]="steuerseatze.slice(0,2)"
              [submitted]="submitted"
              (submitValueEmitter)="f['steuersatz_pricesheet'].setValue($event)"
            ></app-dropdown-multiple>
            <div *ngIf="authService.showCreateValue('einkaufspreis_brutto')" class="input_wrapper">
              <label class="overview_record_lbl">Einkaufspreis in € *</label>
              <input type="number" placeholder="Einkaufspreis in €" formControlName="einkaufspreis_pricesheet" [class.input_invalid]="submitted && f['einkaufspreis_pricesheet'].errors">
            </div>
            <div *ngIf="authService.showCreateValue('einkaufsdatum')" class="input_wrapper">
              <label class="overview_record_lbl">Einkaufsdatum *</label>
              <input #inputDate2 type="date" placeholder="date" [max]="maxDate" formControlName="einkaufsdatum" [class.date-input--has-value]="inputDate2.value != null" [class.input_invalid]="submitted && f['einkaufsdatum'].errors">
            </div>
            <div *ngIf="authService.showCreateValue('sprit')" class="input_wrapper">
              <label class="overview_record_lbl">Transportkosten in €</label>
              <input type="number" placeholder="Transportkosten in €" formControlName="sprit" [class.input_invalid]="submitted && f['sprit'].errors">
            </div>
            <div *ngIf="authService.showCreateValue('lieferant_extern')" class="input_wrapper">
              <label class="overview_record_lbl">Handlingkosten in €</label>
              <input type="number" placeholder="Handlingkosten in €" formControlName="lieferant_extern" [class.input_invalid]="submitted && f['lieferant_extern'].errors">
            </div>
            <div *ngIf="authService.showCreateValue('zubau')" class="input_wrapper">
              <label class="overview_record_lbl">Zubau in €</label>
              <input type="number" placeholder="Zubau in €" formControlName="zubau" [class.input_invalid]="submitted && f['zubau'].errors">
            </div>
            <div *ngIf="authService.showCreateValue('vehicle_supplier')" class="input_wrapper">
              <div class="overview_record_lbl">Verkäufer</div>
              <input type="text" placeholder="Verkäufer" formControlName="vehicle_supplier"  [class.input_invalid]="submitted && f['vehicle_supplier'].errors">
            </div>
            <div *ngIf="authService.showCreateValue('felgen_aufpreis')" class="input_wrapper">
              <div class="overview_record_lbl">Aufpreis Felgen</div>
              <input type="number" placeholder="Aufpreis Felgen" formControlName="felgen_aufpreis"  [class.input_invalid]="submitted && f['felgen_aufpreis'].errors">
            </div>
            <div *ngIf="authService.showCreateValue('fahrwerk_aufpreis')" class="input_wrapper">
              <div class="overview_record_lbl">Aufpreis Fahrwerk</div>
              <input type="number" placeholder="Aufpreis Fahrwerk" formControlName="fahrwerk_aufpreis"  [class.input_invalid]="submitted && f['fahrwerk_aufpreis'].errors">
            </div>
          </div>
        </div>
        <div id="container2">
          <div class="block">
            <h3 class="inner_title">Technische Daten</h3>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Hubraum in ccm</label>
              <input type="number" placeholder="Hubraum in ccm" formControlName="ccm" [class.input_invalid]="submitted && f['ccm'].errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Gänge</label>
              <input type="number" placeholder="Gänge" formControlName="gears" [class.input_invalid]="submitted && f['gears'].errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Zylinder</label>
              <input type="number" placeholder="Zylinder" formControlName="cylinder" [class.input_invalid]="submitted && f['cylinder'].errors">
            </div>
            <div class="input_container">
              <div class="power_header">
                  <div class="slider_grid">
                      <div class="switch_lbl">
                          <div *ngIf="f.powerTyp.value == 1" [ngStyle]="{'display': 'flex'}"><div [ngStyle]="{'color': '#2C2C2C'}">PS</div>&nbsp;/ KW</div>
                          <div *ngIf="f.powerTyp.value == 2" [ngStyle]="{'display': 'flex'}">PS /&nbsp;<div [ngStyle]="{'color': '#2C2C2C'}">KW</div></div>
                      </div>
                      <label class="switch" [ngStyle]="{'cursor': 'unset'}">
                          <input type="checkbox" [checked]="f.powerTyp.value == 2" (click)="changePowervalue()">
                          <span class="slider round"></span>
                      </label>
                  </div>
              </div>
              <div *ngIf="f.powerTyp.value == 1" class="input_wrapper">
                <label class="overview_record_lbl">Leistung (PS) *</label>
                <input type="number" placeholder="Leistung" formControlName="power" [class.input_invalid]="submitted && f['power'].errors">
              </div>
              <div *ngIf="f.powerTyp.value == 2" class="input_wrapper">
                <label class="overview_record_lbl">Leistung (KW) *</label>
                <input type="number" placeholder="Leistung" formControlName="power" [class.input_invalid]="submitted && f['power'].errors">
              </div>
              <!-- <input type="number" class="inputfield" placeholder="Leistung *" pattern="\d+" step="1" min="1" formControlName="power" (ngModel)="f.power.value" [ngClass]="submitted && f.power.errors ? 'inputfield_invalid' : ''"> -->
           </div>
          </div>
        </div>
        <div id="container3">
          <div class="block">
            <h3 class="inner_title">Verbrauch</h3>
            <div class="input_wrapper">
              <label class="overview_record_lbl">CO&#8322; Emissionen (g/km)</label>
              <input type="number" placeholder="CO&#8322; Emissionen (g/km)" formControlName="co2" [class.input_invalid]="submitted && f['co2'].errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Verbrauch kombiniert in l/100km</label>
              <input type="number" placeholder="Verbrauch kombiniert in l/100km" formControlName="consumption" [class.input_invalid]="submitted && f['consumption'].errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">{{getUrbanText()}}</label>
              <input type="number" [placeholder]="getUrbanText()" formControlName="consumptionUrban" [class.input_invalid]="submitted && f['consumptionUrban'].errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">{{getLandText()}}</label>
              <input type="number" [placeholder]="getLandText()" formControlName="consumptionExtraUrban" [class.input_invalid]="submitted && f['consumptionExtraUrban'].errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">NoVA in %</label>
              <input type="number" placeholder="NoVA in %" formControlName="nova" [class.input_invalid]="submitted && f['nova'].errors">
            </div>
            <!-- <div #dropdownOne class="dropdown_wrapper">
                <div class="lbl_regular_small" [ngStyle]="{'opacity': f.enviromentLbl.value != null ? '1' : '0'}">Schadstoffklasse</div>
                <div class="dropdown_header_data" [ngClass]="submitted && f.enviromentLbl.errors ? 'dropdown_header_data_invalid' : ''" (click)="showDropdownOne = !showDropdownOne">
                    <div class="dropdown_header_data_lbl" [ngClass]="f.enviromentLbl.value == null ? 'dropdown_header_lbl_placeholder' : ''">{{f.enviromentLbl.value ? f.enviromentLbl.value.name : 'Schadstoffklasse'}}</div>
                    <img class="dropdown_header_icon" [ngClass]="showDropdownOne ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg" [class.drop-arrow-rotated]="showDropdownOne">
                </div>
                <div class="dropdown_data_body" *ngIf="showDropdownOne">
                    <div *ngFor="let label of enviromentLables" class="dropdown_data_body_element" (click)="f.enviromentLbl.setValue(label); showDropdownOne = false">{{label.name}}</div>
                </div>
            </div> -->
            <app-dropdown-objects
              [title]="'Schadstoffklasse'"
              [placeholder]="'Schadstoffklasse'"
              [headervalue]="f['enviromentLbl'].value?.name"
              [formcontrol]="f['enviromentLbl']"
              [values]="environmentLabelValues"
              [submitted]="submitted"
              (submitValueEmitter)="setValueFromDropdown('environmentLabels', $event)"
            ></app-dropdown-objects>
          </div>
        </div>
        <div id="container4">
          <div class="block">
            <h3 class="inner_title">Fahrzeughistorie</h3>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Kilometerstand (km) *</label>
              <input type="number" placeholder="Kilometerstand (km)" formControlName="mileageKm" [class.input_invalid]="submitted && f['mileageKm'].errors">
            </div>
            <div class="input_wrapper" *ngIf="authService.showCreateValue('mileage_internal')">
              <label class="overview_record_lbl">Kilometerstand intern (km)</label>
              <input type="number" placeholder="Kilometerstand intern (km)" formControlName="mileage_internal" [class.input_invalid]="submitted && f['mileage_internal'].errors">
            </div>
            <app-dropdown-simple
              [title]="'Import'"
              [formcontrol]="f['vehicle_imported']"
              [isOptional]="false"
              (submitValueEmitter)="f['vehicle_imported'].setValue($event)"
            ></app-dropdown-simple>
            <!-- <div class="input_container">
                <div class="lbl_regular_small" [ngStyle]="{'opacity': f.preOwners.value != null ? '1' : '0'}">Vorbesitzer</div>
                <input type="number" class="inputfield" [ngClass]="submitted && f.preOwners.errors ? 'inputfield_invalid' : ''" pattern="\d+" step="1" min="1" placeholder="Vorbesitzer" formControlName="preOwners" (ngModel)="f.preOwners.value">
            </div> -->
            <div class="input_wrapper">
              <label class="overview_record_lbl">Vorbesitzer</label>
              <input type="number" placeholder="Vorbesitzer" formControlName="preOwners" [class.input_invalid]="submitted && f['preOwners'].errors">
            </div>
          </div>
        </div>
    </form>
</div>
