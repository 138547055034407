import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-start-container',
  templateUrl: './start-container.component.html',
  styleUrls: ['./start-container.component.css']
})
export class StartContainerComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() createChoiseEmitter = new EventEmitter<number>();
  @Output() vinEmitter = new EventEmitter<string>();
  @Output() selectionBCAImportEmitter = new EventEmitter<any>();

  public submitted = false;
  public vinEnabled = false;
  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public file;

  constructor(
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  vinRequest(vin: string) {
    this.submitted = true;
    if(vin.length > 8){
      this.form.controls["category"].setValue(new VehicleCategory(2,"Gebrauchtwagen"));
      this.vinEmitter.emit(vin);
    }
  }

  createManuell() {
    this.createChoiseEmitter.emit(1);
  }

  selectionBCAImport() {
    this.selectionBCAImportEmitter.emit(this.file);
  }

  async onFileDropped(data: any) {
    const file = data?.data[0];
    const format = file?.name?.split('.')[1];
    if(file && format == 'pdf'){
      this.file = {
        name: file.name.split('.')[0],
        format: format,
        data: file.content.split(',')[1]
      }
    }
  }


  async uploadFile(event: any): Promise<any> {
    if(event.target.files && event.target.files.length != 0){
      const name = event.target.files[0].name;
      const data = await this.readFiles(event.target.files[0]);
      this.file = {
        name: name.split('.')[0],
        format:  name.split('.')[1],
        data: data.split(',')[1]
      }
    }
  }

  async readFiles(files: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        const src = event.target.result as string;
        resolve(src);
      }
      reader.onerror = (event: any) => {
        reject(false);
      }
      reader.readAsDataURL(files);
    })
  }
}
