<div id="login-form">
  <div *ngIf="!tokenExpired && !invalidToken" class="title">Neues Passwort</div>
  <div *ngIf="invalidToken" class="title">Not Authorized</div>
  <div *ngIf="tokenExpired" class="expired_container">
    <div class="expired_num">401</div>
    <div class="expired_lbl">Link abgelaufen.</div>
  </div>
  <div *ngIf="!tokenExpired && !invalidToken">
    <form [formGroup]="form" id="form" (ngSubmit)="onSubmit()">
      <div *ngIf="!showSuccess" class="form">
        <div>
          <div class="form-group">
            <div class="password-container">
              <input formControlName="password" type="{{showPassword_1 ? 'text' : 'password'}}" class="inputfield" placeholder="Passwort" (ngModelChange)="submitted = false">
              <img (click)="showPassword_1 = !showPassword_1" class='eye-img' [src]="showPassword_1 ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
            </div>
          </div>
          <div class="form-group">
            <div class="password-container">
              <input formControlName="password_confirm" type="{{showPassword_2 ? 'text' : 'password'}}" class="inputfield" placeholder="Passwort wiederholen" (ngModelChange)="submitted = false">
              <img (click)="showPassword_2 = !showPassword_2" class='eye-img' [src]="showPassword_2 ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
            </div>
          </div>
          <div class="invalid-access-feedback" [ngStyle]="{'opacity': submitted ? 1 : 0}">{{getInvalidText()}}</div>
        </div>
        <div id="btn-login-container">
            <input class="btn_submit_dark" type="submit" value="Speichern">
        </div>
      </div>
      <div *ngIf="showSuccess" class="form">
        <div>
          <div [ngStyle]="{'margin-bottom': '10px'}">Passwort wurde geändert.</div>
        </div>
        <div id="btn-login-container">
          <div class="btn_submit_dark" (click)="goToLogin()">Zum Login</div>
        </div>
      </div>
    </form>
  </div>
</div>
