import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-pdf-download',
  templateUrl: './dialog-pdf-download.component.html',
  styleUrls: ['./dialog-pdf-download.component.css']
})
export class DialogPdfDownloadComponent implements OnInit, OnDestroy {
  public show = false;
  public typs: string[] = [];
  private id: number;
  public subscription: Subscription;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openDialogChoosePdf$.subscribe((data: any) => {
      if(data){
        this.show = true;
        this.typs = data.typs;
        this.id = data.id;
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  submit(submit_value: number) {
    this.dialogService.closeChoosePdf(
      {
        submit_value: submit_value,
        id: this.id
      }
    );
    this.show = false;
  }
}
