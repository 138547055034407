import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocationFactory } from 'src/app/buisness-object/location/factory/LocationFactory';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { Location } from '../../buisness-object/location/Location';
import { NotificationService } from '../notification/notification.service';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  public locations$  = new BehaviorSubject<any>(0);

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) { }

  public getLocations(reload?: boolean): Observable<Location[]> {
    if(!reload && this.locations$.getValue()){
      return this.locations$.getValue();
    } else {
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('token')
      });
      const observable = this.http.get(environment.api + '/back/locations', { headers });
      return observable.pipe(
        map((rawLocations: any) => {
          const locations: Location[] = LocationFactory.jsonFactory(rawLocations.locations);
          this.locations$.next(locations);
          return locations;
        }),
        catchError(error => {
          console.log(error)
          if(error.status == 403){
            this.lService.logout();
          } else {
            this.dService.showNotification({
              title: 'Fehler',
              message: 'Fehlermeldung: ' + error.error.error_message,
              success: false
            });
          }
          return [];
        })
      );
    }
  }
}
