
import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { DragAndDrop } from 'src/app/helpers/DragAndDrop';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.css']
})

//https://web.dev/drag-and-drop/
//https://www.toptal.com/angular-js/top-18-most-common-angularjs-developer-mistakes

export class DragAndDropComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public items: any[] = ['1. Box','2. Box','3. Box','4. Box','5. Box','6. Box',];

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    DragAndDrop.registerHandler('.drag_item_container .drag_item');
  }

  ngAfterViewChecked(): void {
    this.items = DragAndDrop.changeDropItem(this.items);
  }

  getArray(i: number): any[] {
    return new Array(i);
  }
}



  // registerHandler() {
  //   let items = document.querySelectorAll('.drag_item_container .drag_item');
  //   if(items){
  //     items.forEach(item => {
  //       item.addEventListener('dragstart', this.handleDragStart, false);
  //       item.addEventListener('dragover', this.handleDragOver, false);
  //       item.addEventListener('dragenter', this.handleDragEnter, false);
  //       item.addEventListener('dragleave', this.handleDragLeave, false);
  //       item.addEventListener('dragend', this.handleDragEnd, false);
  //       item.addEventListener('drop', this.handleDrop, false);
  //     });
  //   }
  // }

  // handleDragStart(e: DragEvent) {
  //   let item = e.target as HTMLElement;
  //   let itemIndex = item.attributes.getNamedItem('id').value;
  //   GlobalVariables.drag_item_drag = itemIndex;
  //   GlobalVariables.drag_item_drop = null;
  //   e.dataTransfer.effectAllowed = 'move';
  //   item.style.backgroundColor = 'blue';
  // }
  // handleDragOver(e: DragEvent) {
  //   if(e.preventDefault) e.preventDefault();
  //   e.dataTransfer.dropEffect = 'move';
  //   return false;
  // }
  // handleDragEnter(e: DragEvent) {
  //   let item = e.target as HTMLElement;
  //   item.classList.add('drag_over_item')
  // }
  // handleDragLeave(e: DragEvent) {
  //   let item = e.target as HTMLElement;
  //   item.classList.remove('drag_over_item')
  // }
  // handleDrop(e: DragEvent) {
  //   if(e.stopPropagation) e.stopPropagation();
  //   let item = e.target as HTMLElement;
  //   item.style.backgroundColor = 'lightblue';
  //   let dropItemIndex = item.attributes.getNamedItem('id').value;
  //   if(GlobalVariables.drag_item_drag && GlobalVariables.drag_item_drag !== dropItemIndex){
  //     GlobalVariables.drag_item_drop = dropItemIndex;
  //     GlobalVariables.drag_active = true;
  //   }
  //   return false;
  // }
  // handleDragEnd(e: DragEvent) {
  //   let item = e.target as HTMLElement;
  //   item.style.backgroundColor = 'lightblue';
  //   let items = document.querySelectorAll('.drag_item_container .drag_item');
  //   if(items){
  //     items.forEach(item => {
  //       item.classList.remove('drag_over_item')
  //     });
  //   }
  // }

  // changeDropItem() {
  //   if(GlobalVariables.drag_active && GlobalVariables.drag_item_drag && GlobalVariables.drag_item_drop && this.items){
  //     let temp = this.items[GlobalVariables.drag_item_drag];
  //     this.items[GlobalVariables.drag_item_drag] = this.items[GlobalVariables.drag_item_drop];
  //     this.items[GlobalVariables.drag_item_drop] = temp;
  //     GlobalVariables.drag_active = false;
  //   }
  // }





// export function registerHandler() {
//   document.addEventListener('DOMContentLoaded', (event) => {
//     let dragSrcEl: any;
//     function handleDragStart(e: DragEvent) {
//       dragSrcEl = this;
//       e.dataTransfer.effectAllowed = 'move';
//       this.style.backgroundColor = 'blue';
//     }
//     function handleDragOver(e: DragEvent) {
//       if(e.preventDefault) e.preventDefault();
//       e.dataTransfer.dropEffect = 'move';
//       return false;
//     }
//     function handleDragEnter(e: DragEvent) {
//       this.classList.add('drag_over_item')
//     }
//     function handleDragLeave(e: DragEvent) {
//       this.classList.remove('drag_over_item')
//     }
//     function handleDrop(e: DragEvent) {
//       if(e.stopPropagation) e.stopPropagation();
//       this.style.backgroundColor = 'lightblue';
//       if(dragSrcEl && dragSrcEl !== this){
//       }
//       return false;
//     }
//     function handleDragEnd(e: DragEvent) {
//       let item = e.target as HTMLElement;
//       item.style.backgroundColor = 'lightblue';
//       let items = document.querySelectorAll('.drag_item_container .drag_item');
//       if(items){
//         items.forEach(item => {
//           item.classList.remove('drag_over_item')
//         });
//       }
//     }
//     let items = document.querySelectorAll('.drag_item_container .drag_item');
//     if(items){
//       items.forEach(item => {
//         item.addEventListener('dragstart', handleDragStart, false);
//         item.addEventListener('dragover', handleDragOver, false);
//         item.addEventListener('dragenter', handleDragEnter, false);
//         item.addEventListener('dragleave', handleDragLeave, false);
//         item.addEventListener('dragend', handleDragEnd, false);
//         item.addEventListener('drop', handleDrop, false);
//       });
//     }
//   })
// }






// let dragSrcEl: any;

//       function handleDragStart(e: DragEvent) {
//         e.dataTransfer.effectAllowed = 'move';
//         this.style.backgroundColor = 'blue';
//       }
//       function handleDragOver(e: DragEvent) {
//         if(e.preventDefault) e.preventDefault();
//         e.dataTransfer.dropEffect = 'move';
//         return false;
//       }
//       function handleDragEnter(e: DragEvent) {
//         this.classList.add('drag_over_item')
//       }
//       function handleDragLeave(e: DragEvent) {
//         this.classList.remove('drag_over_item')
//       }
//       function handleDrop(e: DragEvent) {
//         if(e.stopPropagation) e.stopPropagation();
//         this.style.backgroundColor = 'lightblue';
//         // let dropItemIndex = item.attributes.getNamedItem('id').value;
//         if(this.dragItem && this.dragItem !== dropItemIndex){
//         }
//         return false;
//       }
//       function handleDragEnd(e: DragEvent) {
//         let item = e.target as HTMLElement;
//         item.style.backgroundColor = 'lightblue';
//         let items = document.querySelectorAll('.drag_item_container .drag_item');
//         if(items){
//           items.forEach(item => {
//             item.classList.remove('drag_over_item')
//           });
//         }
//       }
