<div id="dialog_window">
  <div id="dialog_container" appDropdown>
      <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
      <h1>Reifen montieren?</h1>
      <div id="dialog_conainter_body">
        <label>Bitte wählen Sie das Fahrzeug, auf dem die Reifen montiert werden:</label>
        <app-dropdown-objects-search
          [placeholder]="'---'"
          [headervalue]="form.get('vehicle').value?.getVehicleName()"
          [formcontrol]="form.get('vehicle')"
          [values]="vehicleValues"
          [submitted]="false"
          [showLabel]="false"
          (submitValueEmitter)="setValueFromDropdown('vehicle',$event)"
        ></app-dropdown-objects-search>
      </div>
      <div class="dialog_btn_container">
          <button class="btn_label" (click)="selectionCloseEmitter.emit()">Abbrechen</button>
          <button [disabled]="form.invalid" class="btn_submit_dark" (click)="submit()">Auslagern</button>
      </div>
  </div>
</div>
