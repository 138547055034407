<div *ngIf="show" class="custom-filter-container">
  <div class="custom-filter-background-container"></div>
  <div class="custom-filter-inner-container" >
    <h3 id="custon-filter-heading">Konflikte festgestellt</h3>
    <label id="conflict-info">Dieser Datensatz wurde in der Zwischenzeit bereits bearbeitet.<br>Möchten Sie Ihre Daten übernehmen?</label>
    <div id="conflict-body">
      <div class="conflict-body-column">
        <h4></h4>
        <h4>Meine Änderungen</h4>
        <h4>Externe Änderungen</h4>
      </div>
      <div *ngFor="let conflict of conflicts" class="conflict-body-column">
        <label>{{conflict.name}}</label>
        <button type="button" class="btn-conflict btn-conflict-activ" [disabled]="true">{{getConflictValue(conflict, "value")}}</button>
        <button type="button" class="btn-conflict" [disabled]="true">{{getConflictValue(conflict, "old_value")}}</button>
      </div>
    </div>
    <div class="custom-filter-button-container">
        <button class="btn_label" type="button" (click)="cancel()">Abbrechen</button>
        <button class="btn_submit_dark" type="button" (click)="submit()">Übernehmen</button>
    </div>
  </div>
</div>
