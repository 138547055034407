import { environment } from 'src/environments/environment';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { DocumentService } from 'src/app/service/document/document.service';
import { forkJoin, Subscription } from 'rxjs';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { OldVehicle, SalesContract } from 'src/app/buisness-object/document/SalesContract';
import { Offer, OfferDescription, OfferItem } from 'src/app/buisness-object/document/Offer';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, formatDate } from '@angular/common';
import { TransactionService } from 'src/app/service/transaction/transaction.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { LastRoute } from 'src/app/helpers/Enums';
import { validDateFuture, validDatePast } from 'src/app/validators/CustomValidators';
import { FileHandler } from 'src/app/utils/FileHandler';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { VehicleStatus } from 'src/app/buisness-object/document/Kaufvertrag';
import { DokumentTyp } from '../dokumente/dokumente.component';
import { BillItem } from 'src/app/buisness-object/document/BillItem';
import { Bill } from 'src/app/buisness-object/document/Bill';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  public surface = 1;
  public tab = 1;
  // public filter = 2;
  public offers: Offer[] = [];
  public offersFiltered: Offer[] = [];
  public contracts: SalesContract[] = [];
  public contractsFiltered: SalesContract[] = [];
  public tabDocuments: any[] = [];
  public tabDocumentsFiltered: any[] = [];
  public customers: Customer[] = [];
  public pageHandler: PageHandler;
  public pageInfo: string;
  public sliceTo: number;
  public sliceFrom: number;
  public submitted = false;
  public isEdit = false;
  public isCreate = false;
  public selectedDoc: any;
  public selectedContract: SalesContract;
  public dialogQuerySubsription: Subscription;
  public signatureLoaderSubscription: Subscription;
  public routeSubscription: Subscription;
  public filter = {
    customer: undefined,
    start_date: undefined,
    end_date: undefined,
    is_set: false
  }
  public sortObject = {
    sort_after_value: 1,
    sort_asc: false,
    sorting_is_set: true,
  }
  //Offer
  public formOffer: UntypedFormGroup;
  public submittedItem = false;
  public pageOffer = 1;
  //Bill
  public formBill: UntypedFormGroup;
  public position = 0;
  public pageBill = 1;
  //Contract
  public formContract: UntypedFormGroup;
  public formContractCustomerChanged = false;
  public sumittedContract = false;
  public pageContract = 1;
  public pageLoaded = false;
  public lastRoute: any;
  public redirectId;

  constructor(
    private documentService: DocumentService,
    private customerService: CustomerService,
    private transactionService: TransactionService,
    private dService: DialogService,
    private formBuilder: UntypedFormBuilder,
    private activateRoute: ActivatedRoute,
    private locationRoute: Location,
    private vService: VehicleService,
    private cService: CustomerService,
    private router: Router,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
    this.createFormOffer();
    this.createFormBill();
    this.createFormContract();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
    if(this.signatureLoaderSubscription) this.signatureLoaderSubscription.unsubscribe();
  }

  getDataServerside() {
    forkJoin({
      one: this.documentService.getOfferDocuments(),
      two: this.documentService.getSalesContracts(),
      three: this.customerService.getCustomer(),
      vehicles: this.vService.getVehicle(),
    }).subscribe((result) => {
      if(result){
        this.offers = result.one;
        this.contracts = result.two;
        this.customers = result.three;
        for(let contract of this.contracts){
          for(let customer of this.customers){
            if(contract.customer_id == customer.id){
              contract.customer = customer;
              break;
            }
          }
        }
        for(let contract of this.contracts){
          for(let vehicle of result.vehicles){
            if(contract.vehicle_id == vehicle.id){
              contract.vehicle = vehicle;
              break;
            }
          }
        }
        this.changeTab();
        this.routeListener();
      }
    });
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete_offer') this.deleteOffer(value.submit_value);
        if(value.typ == 'cancel_create') this.cancelCreate();
        if(value.typ == 'delete_contract') this.deleteContract(value.submit_value);
        if(value.typ == 'sent_offer') this.createOfferAction();
      }
    });
    this.signatureLoaderSubscription = this.dService.closeDialogLoaderSignature$.subscribe((value) => {
      if(value){
        this.getDataServerside();
      }
    })
  }

  async routeListener() {
    this.pageLoaded = false;
    this.routeSubscription = this.activateRoute.url.subscribe((url) => {
      if(url[1]?.path){
        switch (url[1].path) {
          case 'create': this.openCreate(this.activateRoute.snapshot.queryParams); break;
          case 'edit': for(let doc of this.offers){
                          if(doc.offer_id == this.activateRoute.snapshot.queryParams.documentId){
                            this.editDocument(doc);
                            break;
                          }
                       };
                       break;
          default: break;
        }
      } else {
        this.pageLoaded = true;
      }
    });
  }

  setPages(amount: number) {
    this.pageHandler = new PageHandler(amount);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }
  pageHandling(next: boolean) {
    this.pageHandler.action(next);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }

  changeTab(tab?: number) {
    if(tab){
      this.tab = tab;
    }
    if(this.tab == 1){
      this.tabDocuments = this.offers;
    } else if(this.tab == 2){
      this.tabDocuments = this.contracts;
    }
    this.tabDocumentsFiltered = this.tabDocuments;
    this.setPages(this.tabDocumentsFiltered.length);
    this.applyFilter();
  }

  sendOffer(document: any) {
    if(!document){
      document = this.selectedDoc;
    }
    this.formOffer.controls.status.setValue(1);
    this.showDialogSend();
  }
  showDialogSend() {
    this.dService.openQuery(
      {
        title: 'Dokument absenden?',
        message: 'Sind sie sicher, das Sie das Anegobt absenden möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Senden',
        typ: 'sent_offer',
        submit_value: null,
      }
    );
  }

  /*************** Document offer ******************/
  createFormOffer(offer?: Offer) {
    this.formOffer = this.formBuilder.group({
      offer_id: [offer ? offer.offer_id : 0],
      customer: [offer ? offer.customer : null, Validators.required],
      contact_person: [offer ? offer.contact_person : null, Validators.required],
      title: [offer ? offer.title : null, Validators.required],
      offer_date: [offer ? offer.offer_date : null, [Validators.required]],

      status: [offer ? offer.status : 0, Validators.required],offer_validity: [offer && offer.offer_validity ? this.getConvertedDateForInputFull(offer.offer_validity) : null, [Validators.required, validDateFuture]],
      show_price_overview: [offer ? offer.show_price_overview : false],
      show_brutto_price: [offer ? offer.show_brutto_price : false, Validators.required],
      offer_items: [offer ? offer.offer_items : null, Validators.required],
      special_conditions: [offer ? offer.special_conditions : null],
      payment_conditions: [offer ? offer.payment_conditions : null],
      hour_rate: [offer ? offer.hour_rate : null],
      performance_period: [offer ? offer.performance_period : null]
    });
    if(offer && offer.offer_items.length > 0){
      let itemsForms = [];
      let pos= 0;
     for(let item of offer.offer_items){
      pos = pos + 10;
      itemsForms.push(this.createFormOfferItem(pos, item))
     }
    }
  }
  createFormOfferItem(pos: number, item?: OfferItem): FormGroup {
    let itemForm = this.formBuilder.group({
      item_id: [pos, Validators.required],
      is_total_price: [item ? item.is_total_price : true, Validators.required],
      price: [item ? item.price : null, Validators.required],
      total_discount: [item ? item.total_discount : null],
      price_based: [item ? item.price_based : 'Stk.'],
      base_value: [item ? item.base_value : null, Validators.required],
      offer_descriptions: [item ? item.offer_descriptions : [], Validators.required],
      vehicle_selected: [false],
      vehicle: [null]
    })
    // itemForm.controls.offer_descriptions.setValue([]);
    itemForm.controls.offer_descriptions.value.push(this.createFormOfferDescription((item && item.offer_descriptions) ? item.offer_descriptions[0] : undefined));
    return itemForm;
  }
  createFormOfferDescription(offerDescription?: OfferDescription): UntypedFormGroup {
    return this.formBuilder.group({
      title: [offerDescription ? offerDescription.title : null, Validators.required],
      sort_value: [offerDescription ? offerDescription.sort_value : 1, Validators.required],
      description: [offerDescription ? offerDescription.description : ''],
    })
  }
  changePageOffer(next: boolean) {
    if(next){
      this.submitted = true;
      if(environment.developer){
        this.pageOffer++;
      } else {
        if(this.pageOffer == 1 && !this.isValidOfferPageOne()) return;
        if(this.pageOffer == 2 && !this.isValidOfferPageTwo()) return;
        this.submitted = false;
        this.submittedItem = false;
        this.pageOffer++;
      }
    } else {
      this.pageOffer--;
    }
  }
  isValidOfferPageOne(): boolean {
    if(this.formOffer.controls.customer.valid && this.formOffer.controls.contact_person.valid &&
      this.formOffer.controls.title.valid && this.formOffer.controls.offer_validity.valid &&
      this.formOffer.controls.show_brutto_price.valid){
        return true;
    } else {
      return false;
    }
  }
  isValidOfferPageTwo(): boolean {
    this.submittedItem = true;
    for(let form of this.formOffer.controls.offer_items.value){
      if(form.invalid){
        return;
      } else if(form.controls.vehicle_selected.value && !form.controls.vehicle.value) {
        return;
      } else {
        for(let formDescription of form.controls.offer_descriptions.value){
          if(formDescription.invalid) return;
        }
      }
    }
    this.createOfferAction();
  }
  addPriceToOffer() {
    this.submittedItem = true;
    for(let form of this.formOffer.controls.offer_items.value){
      if(form.invalid) return;
    }
    this.formOffer.controls.offer_items.value.push(this.createFormOfferItem(this.position ? (this.position += 10) : (this.formOffer.controls.offer_items.value.length * 10 + 10)));
    this.submittedItem = false;
    const list = document.getElementById('price_list_element_create');
    if(list){
      setTimeout(() => {
        list.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
      }, 150);
    }
  }
  removeOfferItem(index: number) {
    this.formOffer.controls.offer_items.value.splice(index,1);
    let pos = 0;
    for(let form of this.formOffer.controls.offer_items.value){
      form.controls.item_id.setValue(pos+=10);
    }
    this.position = pos;
  }
  createOfferAction() {
    let items: OfferItem[] = [];
    for(let form of this.formOffer.controls.offer_items.value){
      let descriptions: OfferDescription[] = [];
      form.controls.offer_descriptions.value.forEach(des => {
        descriptions.push(new OfferDescription(
          des.controls.title.value,
          1,
          des.controls.description.value
        ))
      });
      items.push(new OfferItem(
        form.controls.item_id.value,
        descriptions,
        form.controls.is_total_price.value,
        form.controls.price.value,
        form.controls.total_discount.value ? form.controls.total_discount.value : 0,
        form.controls.price_based.value,
        'x '+form.controls.base_value.value,
      ));
    }
    let document = new Offer(
      this.selectedDoc ? this.selectedDoc.offer_id : 0,
      this.formOffer.controls.customer.value,
      this.formOffer.controls.contact_person.value,
      this.formOffer.controls.title.value,
      new Date().getTime(),
      this.formOffer.controls.offer_validity.value ? this.getTimestampFromInputDate(this.formOffer.controls.offer_validity.value) : null,
      this.formOffer.controls.status.value,
      this.formOffer.controls.show_price_overview.value,
      this.formOffer.controls.show_brutto_price.value,
      items,
    )
    if(document.offer_id == 0){
      this.createOffer(document);
    } else {
      this.updateOffer(document);
    }
  }

  createOffer(offer: Offer) {
    this.customerService.updateCustomer(offer.customer).subscribe((result) => {
      if(result){
        this.documentService.createOffer(offer).subscribe((doc) => {
          if(doc){
            doc.pdf = this.getOfferPdf(doc.offer_id);
            this.offers.unshift(doc);
            this.changeTab(this.tab);
            this.setPages(this.tabDocumentsFiltered.length);
            this.submitted = false;
            this.submittedItem = false;
            this.selectedDoc = doc
            this.dService.showNotification({
              title: 'Erfolgreich',
              message: 'Dokument wurde erstellt.',
              success: true
            });
          }
        })
      }
    })
  }
  updateOffer(offer: Offer) {
    this.documentService.updateOffer(offer).subscribe((doc) => {
      if(doc){
        let index = this.tabDocuments.findIndex(d => d.offer_id == doc.offer_id);
        if(index > -1) this.tabDocuments[index] = doc;
        this.tabDocumentsFiltered = this.tabDocuments;
        this.changeTab(this.tab);
        this.selectedDoc = doc;
        this.submitted = false;
        this.submittedItem = false;
        this.isEdit = false;
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: doc.status == 1 ? 'Dokument wurde abgesendet.' : 'Dokument wurde aktualisiert.',
          success: true
        });
        this.surface = 1;
      }
    })
  }
  saveAsDraft(document?: any) {
    if(document instanceof Offer){
      this.formOffer.controls.status.setValue(0);
      this.createOfferAction();
    } else if(document instanceof Bill){

    }
  }
  getOfferPdf(id: number): any {
    this.documentService.getOfferPdf(id).subscribe((pdf) => {
      if(pdf){
        this.selectedDoc.pdf = pdf;
        if(this.pageOffer == 2){
          this.surface = 22;
        }
        this.dService.openAttachementViewer({pdf: pdf, isArrayBuffer: true});
        return pdf;
      }
    })
  }
  deleteAction(doc: Offer) {
    this.dService.openQuery(
      {
        title: 'Dokument wirklich löschen?',
        message: 'Sind sie sicher, dass Sie das Dokument unwiderruflich löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete_offer',
        submit_value: doc.offer_id,
      }
    );
  }
  deleteOffer(id: number) {
    this.documentService.deleteOffer(id).subscribe((success) => {
      if(success){
        let index = this.tabDocuments.findIndex(doc => doc.offer_id == id);
        if(index > -1) this.tabDocuments.splice(index,1);
        this.tabDocumentsFiltered = this.tabDocuments;
        this.changeTab(this.tab);
        this.setPages(this.tabDocumentsFiltered.length);
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Dokument wurde gelöscht.',
          success: true
        });
      }
    })
  }
  fillOfferForm() {
    this.formOffer.controls.offer_id.setValue(this.selectedDoc.offer_id);
    this.formOffer.controls.customer.setValue(this.selectedDoc.customer);
    this.formOffer.controls.contact_person.setValue(this.selectedDoc.contact_person);
    this.formOffer.controls.title.setValue(this.selectedDoc.title);
    this.formOffer.controls.offer_date.setValue(this.selectedDoc.offer_date);
    this.formOffer.controls.offer_validity.setValue(moment(new Date(this.selectedDoc.offer_validity)));
    // naechstes_service: [vehicle && vehicle.naechstes_service ? this.getConvertedDateForInputFull(vehicle.naechstes_service) : null],
    // form.controls.naechstes_service.value ? this.getTimestampFromInputDate(form.controls.naechstes_service.value) : null,
    this.formOffer.controls.offer_validity.setValue(this.selectedDoc.offer_validity ? this.getConvertedDateForInputFull(this.selectedDoc.offer_validity) : null);
    this.formOffer.controls.status.setValue(this.selectedDoc.status);
    this.formOffer.controls.show_brutto_price.setValue(this.selectedDoc.show_brutto_price);
    this.formOffer.controls.offer_items.setValue([]);
    this.position = this.selectedDoc.offer_items[this.selectedDoc.offer_items.length - 1].position;
    for(let item of this.selectedDoc.offer_items){
      let form = this.createFormOfferItem(item.item_id);
      let description: any[] = [];
      for(let des of item.offer_descriptions){
        let desForm = this.createFormOfferDescription();
        desForm.controls.title.setValue(des.title);
        desForm.controls.sort_value.setValue(des.sort_value);
        desForm.controls.description.setValue(des.description);
        description.push(desForm)
      }
      form.controls.offer_descriptions.setValue(description);
      form.controls.is_total_price.setValue(item.is_total_price);
      form.controls.price.setValue(item.price);
      form.controls.total_discount.setValue(item.total_discount);
      form.controls.price_based.setValue(item.price_based);
      form.controls.base_value.setValue(item.base_value.split(' ')[1]);
      this.formOffer.controls.offer_items.value.push(form);
    }
  }

  getConvertedDateForInputFull(timestamp: number): string {
    if(timestamp){
      return formatDate(new Date(timestamp),'yyyy-MM-dd', 'de')
    }
    return null;
  }
  getTimestampFromInputDate(date: string): number {
    if(date){
      let d = new Date(date);
      return d.getTime();
    } else return null;
  }



  /*************** Document bill ******************/
  createFormBill() {
    this.formBill = this.formBuilder.group({
      bill_id: [0],
      customer: [null, Validators.required],
      contact_person: [null, Validators.required],
      title: [null, Validators.required],
      bill_date: [null],
      status: [null],
      show_price_overview: [null],
      show_brutto_price: [null],
      bill_items: [null, Validators.required],
      special_conditions: [null],
      payment_conditions: [null],
      performance_period: [null],
      offer: [null],
    });
  }
  createFromBillItem(pos: number): UntypedFormGroup {
    return this.formBuilder.group({
      item_id: [pos, Validators.required],
      title: [null, Validators.required],
      is_total_price: [null],
      price: [null, Validators.required],
      total_discount: [null],
      price_based: [null],
      base_value: [null, Validators.required]
    })
  }
  fillBillForm() {
    this.formBill.controls.bill_id.setValue(this.selectedDoc.bill_id);
    this.formBill.controls.customer.setValue(this.selectedDoc.customer);
    this.formBill.controls.contact_person.setValue(this.selectedDoc.contact_person);
    this.formBill.controls.title.setValue(this.selectedDoc.title);
    this.formBill.controls.offer_date.setValue(this.selectedDoc.offer_date);
    this.formBill.controls.offer_validity.setValue(this.selectedDoc.offer_validity);
    this.formBill.controls.show_price_overview.setValue(this.selectedDoc.show_price_overview);
    this.formBill.controls.show_brutto_price.setValue(this.selectedDoc.show_brutto_price);
    this.position = this.selectedDoc.offer_items[this.selectedDoc.offer_items.length - 1].position;
    for(let item of this.selectedDoc.offer_items){
      let form = this.createFormOfferItem(item.position);
      form.controls.offer_descriptions.setValue(item.offer_descriptions);
      form.controls.is_total_price.setValue(item.is_total_price);
      form.controls.price.setValue(item.price);
      form.controls.total_discount.setValue(item.total_discount);
      form.controls.price_based.setValue(item.price_based);
      this.formBill.controls.base_value.value.push(form);
    }
    this.formBill.controls.special_conditions.setValue(this.selectedDoc.special_conditions);
    this.formBill.controls.payment_conditions.setValue(this.selectedDoc.payment_conditions);
    this.formBill.controls.hour_rate.setValue(this.selectedDoc.hour_rate);
    this.formBill.controls.performance_period.setValue(this.selectedDoc.performance_period);
  }

  addPriceToBill() {
    this.submittedItem = true;
    for(let form of this.formBill.controls.bill_items.value){
      if(form.invalid) return;
    }
    this.formBill.controls.bill_items.value.push(this.createFromBillItem(this.position += 10));
    this.submittedItem = false;
    const list = document.getElementById('price_list_element_create');
    if(list){
      setTimeout(() => {
        list.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
      }, 150);
    }
  }
  removePrice(index: number) {
    this.formBill.controls.bill_items.value.splice(index,1);
    let pos = 0;
    for(let form of this.formBill.controls.bill_items.value){
      form.controls.item_id.setValue(pos+=10);
    }
  }
  changePageBill(next: boolean) {
    if(next){
      this.submitted = true;
      if(environment.developer){
        this.pageBill++;
      } else {
        if(this.pageBill == 1 && !this.isValidBillPageOne()) return;
        if(this.pageBill == 2 && !this.isValidBillPageTwo()) return;
        this.submitted = false;
        this.submittedItem = false;
        this.pageBill++;
      }
    } else {
      this.pageBill--;
    }
  }
  isValidBillPageOne(): boolean {
    if(this.formBill.controls.customer.valid && this.formBill.controls.contact_person.valid
      && this.formBill.controls.title.valid){
        return true;
    } else {
      return false;
    }
  }
  isValidBillPageTwo(): boolean {
    this.submittedItem = true;
    let billItems: BillItem[] = [];
    for(let form of this.formBill.controls.bill_items.value){
      if(form.invalid){
        return;
      } else {
        billItems.push(new BillItem(
          form.controls.item_id.value,
          form.controls.title.value,
          form.controls.is_total_price.value,
          form.controls.price.value,
          form.controls.total_discount.value,
          form.controls.price_based.value,
          form.controls.base_value.value,
        ))
      }
    }
    this.createBillAction();
  }
  createBillAction() {
    // let document = new Bill(
    //   this.formBill.controls.bill_id.value,
    //   this.formBill.controls.customer.value,
    //   this.formBill.controls.contact_person.value,
    //   this.formBill.controls.title.value,
    //   new Date().getTime(),
    //   this.formBill.controls.status.value,
    //   this.formBill.controls.bill_items.value,
    //   this.formBill.controls.special_conditions.value,
    //   this.formBill.controls.payment_conditions.value,
    //   this.formBill.controls.performance_period.value,
    //   this.formBill.controls.offer.value
    // )
    // if(!this.selectedDoc && !document.bill_id){
    //   this.createBill(document);
    // } else {
    //   this.updateBill(document);
    // }
  }
  createBill(bill: Bill) {
    this.documentService.createBill(bill).subscribe((doc) => {
      if(doc){
        this.tabDocuments.unshift(doc);
        this.tabDocumentsFiltered = this.tabDocuments;
        this.changeTab(this.tab);
        this.setPages(this.tabDocumentsFiltered.length);
        this.submitted = false;
        this.submittedItem = false;
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Dokument wurde erstellt.',
          success: true
        });
        this.surface = 1;
      }
    })
  }
  updateBill(bill: Bill) {
    this.documentService.updateBill(bill).subscribe((doc) => {
      if(doc){
        let index = this.tabDocuments.findIndex(d => d.bill_id == doc.bill_id);
        if(index > -1) this.tabDocuments[index] = doc;
        this.tabDocumentsFiltered = this.tabDocuments;
        this.selectedDoc = doc;
        this.submitted = false;
        this.submittedItem = false;
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Dokument wurde gespeichert.',
          success: true
        });
        this.surface = 22;
      }
    })
  }

   /*************** Document Contract ******************/
   createFormContract(contract?: SalesContract) {
    this.formContract = this.formBuilder.group({
      purchase_contract_id: [contract ? contract.purchase_contract_id : 0],

      customer: [contract ? contract.customer : null, Validators.required],
      customerId: [null],
      firstName: [contract ? contract.customer.contactPerson.firstName : null, Validators.required],
      lastName: [contract ? contract.customer.contactPerson.lastName : null, Validators.required],
      title: [contract ? contract.customer.contactPerson.title : null],
      salutation: [contract ? contract.customer.contactPerson.salutation : null, Validators.required],
      birthdate: [contract && contract.customer.contactPerson.birthdate ? this.getConvertedDateForInputFull(contract.customer.contactPerson.birthdate) : null, [validDatePast]],
      email: [contract ? contract.customer.contactPerson.email : null, [Validators.required,Validators.email]],
      phone: [contract ? contract.customer.contactPerson.phone : null, Validators.required],
      street: [contract ? contract.customer.address.street : null, Validators.required],
      postalCode: [contract ? contract.customer.address.postalCode : null, [Validators.required, Validators.min(1)]],
      city: [contract ? contract.customer.address.city : null, [Validators.required]],
      country: [contract ? contract.customer.address.country : null],
      is_company: [contract ? contract.customer.typ == 1 : false],
      company_name: [contract ? contract.customer.companyName : null],
      atu: [contract ? contract.customer.atuNum : null],

      vehicle: [contract ? contract.vehicle : null, Validators.required],
      vehicleId: [null],

      purchase_date: [contract ? contract.purchase_date : new Date().getTime(), Validators.required],
      handing_over_date: [contract ?  this.getConvertedDateForInputFull(contract.handing_over_date) : null],
      price: [contract ? contract.price : null, Validators.required],
      registration_document: [contract ? contract.registration_document : 1, Validators.required],
      is_original_document: [contract ? contract.is_original_document : true, Validators.required],
      mechanical_condition: [contract ? contract.vehicle_status.mechanical_condition : 1, Validators.required],
      body_condition: [contract ? contract.vehicle_status.body_condition : 1, Validators.required],
      paint_condition: [contract ? contract.vehicle_status.paint_condition : 1, Validators.required],
      interior_condition: [contract ? contract.vehicle_status.interior_condition : 1, Validators.required],
      electronic_condition: [contract ? contract.vehicle_status.electronic_condition : 1, Validators.required],
      payment_condition: [contract ? contract.payment_condition : 1, Validators.required],
      total_condition: [null],

      //foreign_registration_date: [contract ? moment.unix(contract.payment_condition / 1000) : null],
      foreign_registration_date: [contract ? this.getConvertedDateForInputFull(contract.foreign_registration_date) : null, [validDatePast]],
      purchase_test_wish: [contract ? (contract.purchase_test_date || contract.purchase_tester ? true : false) : false],
      purchase_test_date: [contract ? this.getConvertedDateForInputFull(contract.purchase_test_date) : null],
      purchase_tester: [contract ? contract.purchase_tester : null],
      warranty_period: [contract ? contract.warranty_period : null],
      deposit: [contract ? contract.deposit : null],

      old_brand: [contract && contract.old_vehicle ? contract.old_vehicle.brand : null],
      old_model: [contract && contract.old_vehicle ? contract.old_vehicle.model : null],
      old_registration_document: [contract && contract.old_vehicle ? contract.old_vehicle.registration_document : 1],
      old_mileage: [contract && contract.old_vehicle ? contract.old_vehicle.mileage : null],
      old_price: [contract && contract.old_vehicle ? contract.old_vehicle.price : null],
      old_registration_date: [contract && contract.old_vehicle ? this.getConvertedDateForInputFull(contract.old_vehicle.registration_date) : null],
      old_previous_owner: [contract && contract.old_vehicle ? contract.old_vehicle.previous_owner : null],
      old_vin_number: [contract && contract.old_vehicle ? contract.old_vehicle.vin_number : null],
      old_mileage_to_drive: [contract && contract.old_vehicle ? contract.old_vehicle.mileage_to_drive : null],

      special_conditions: [contract ? contract.special_conditions : null],

      finanzierungsgesellschaft: [contract ? contract.finanzierungsgesellschaft : null],
      finanzierungsart: [contract ? contract.finanzierungsart : null],
    });
    if(this.formContract.controls['is_company'].value == true){
      this.formContract.controls['company_name'].addValidators(Validators.required);
      this.formContract.controls['company_name'].updateValueAndValidity();
    }
  }
  nextPageContract(next: boolean) {
    if(next){
      this.submitted = true;
      if(environment.developer){
        this.pageContract++;
      } else {
        if(this.pageContract == 1 && !this.validContractPageOne()) return;
        if(this.pageContract == 2 && !this.validContractPageTwo()) return;
        if(this.pageContract == 3 && !this.validContractPageThree()) return;
        if(this.pageContract == 4 && !this.validContractPageFour()) return;
        this.submitted = false;
        this.pageContract++;
      }
    } else {
      this.pageContract--;
    }
  }

  validContractPageOne(): boolean {
    if(this.formContract.controls.customer.valid && this.formContract.controls.firstName.valid &&
      this.formContract.controls.lastName.valid && this.formContract.controls.title.valid &&
      this.formContract.controls.salutation.valid && ( this.formContract.controls.company_name.valid) &&
      this.formContract.controls.email.valid && this.formContract.controls.phone.valid &&
      this.formContract.controls.street.valid && this.formContract.controls.postalCode.valid &&
      this.formContract.controls.city.valid) return true;
    else return false;
  }
  validContractPageTwo(): boolean {
    if(this.formContract.controls.vehicle.valid) return true;
    else return false;
  }
  validContractPageThree(): boolean {
    if(this.formContract.controls.mechanical_condition.valid && this.formContract.controls.body_condition.valid &&
      this.formContract.controls.paint_condition.valid && this.formContract.controls.interior_condition.valid &&
      this.formContract.controls.electronic_condition.valid){
      return true;
    }
  }
  validContractPageFour(): boolean {
    if(this.formContract.controls.warranty_period.valid && this.formContract.controls.price.valid &&
      this.formContract.controls.handing_over_date.valid && this.formContract.controls.payment_condition.valid) return true;
    else return false;
  }
  validContractPageFive(): boolean {
    return true;
  }

  createContract(sign: boolean) {
    if(this.formContract.invalid){
      ErrorHelper.showFormError(this.formContract)
      return;
    }
    let vehiclStatus = new VehicleStatus(
      this.formContract.controls.mechanical_condition.value,
      this.formContract.controls.body_condition.value,
      this.formContract.controls.paint_condition.value,
      this.formContract.controls.interior_condition.value,
      this.formContract.controls.electronic_condition.value,
    );
    let oldVehicle = new OldVehicle(
      this.formContract.controls.old_brand.value,
      this.formContract.controls.old_model.value,
      this.formContract.controls.old_registration_document.value,
      this.formContract.controls.old_mileage.value,
      this.formContract.controls.old_price.value,
      this.formContract.controls.old_registration_date.value ? this.getTimestampFromInputDate(this.formContract.controls.old_registration_date.value) : null,
      this.formContract.controls.old_previous_owner.value,
      this.formContract.controls.old_vin_number.value,
      this.formContract.controls.old_mileage_to_drive.value,
    );
    let contract = new SalesContract(
      this.formContract.controls.purchase_contract_id.value,
      this.formContract.controls.customer.value.id,
      this.formContract.controls.vehicle.value.id,
      this.formContract.controls.purchase_date.value ? this.getTimestampFromInputDate(this.formContract.controls.purchase_date.value) : null,
      this.formContract.controls.handing_over_date.value ? this.getTimestampFromInputDate(this.formContract.controls.handing_over_date.value) : null,
      this.formContract.controls.price.value,
      this.formContract.controls.registration_document.value,
      this.formContract.controls.is_original_document.value,
      vehiclStatus,
      this.formContract.controls.payment_condition.value,
      0,
      this.formContract.controls.foreign_registration_date.value ? this.getTimestampFromInputDate(this.formContract.controls.foreign_registration_date.value) : null,
      this.formContract.controls.purchase_test_date.value ? this.getTimestampFromInputDate(this.formContract.controls.purchase_test_date.value) : null,
      this.formContract.controls.purchase_tester.value,
      this.formContract.controls.warranty_period.value,
      this.formContract.controls.deposit.value,
      oldVehicle,
      this.formContract.controls.special_conditions.value,
    );
    contract.customer = this.formContract.controls.customer.value;
    contract.vehicle = this.formContract.controls.vehicle.value;
    contract.finanzierungsgesellschaft = this.formContract.controls["finanzierungsgesellschaft"].value;
    contract.finanzierungsart = this.formContract.controls["finanzierungsart"].value;
    if(!this.selectedContract){
      this.documentService.createContract(contract).subscribe((result) => {
        if(result){
          this.contracts.unshift(result);
          this.resetAllForms();
          this.changeTab(2);
          this.selectedContract = null;
          this.formContract = null;
          this.pageContract = 1;
          this.surface = 1;
          if(!sign){
            this.openContractPdf(result);
            this.dService.showNotification({
              title: 'Erfolgreich',
              message: 'Dokument wurde erstellt.',
              success: true
            });
          } else {
            this.signPDF(result);
          }
        }
      })
    } else {
      this.documentService.updateContract(contract).subscribe((result) => {
        if(result){
          if(this.formContractCustomerChanged){
            let customer = contract.customer;
            customer.contactPerson.firstName = this.formContract.controls.firstName.value;
            customer.contactPerson.lastName = this.formContract.controls.lastName.value;
            customer.contactPerson.title = this.formContract.controls.title.value;
            customer.contactPerson.salutation = this.formContract.controls.salutation.value;
            customer.contactPerson.birthdate = this.formContract.controls.birthdate.value ? this.getTimestampFromInputDate(this.formContract.controls.birthdate.value) : null;
            customer.contactPerson.email = this.formContract.controls.email.value;
            customer.contactPerson.phone = this.formContract.controls.phone.value;
            customer.address.street = this.formContract.controls.street.value;
            customer.address.postalCode = this.formContract.controls.postalCode.value.toString();
            customer.address.city = this.formContract.controls.city.value;
            customer.address.country = this.formContract.controls.country.value;
            customer.companyName = this.formContract.controls.company_name.value;
            customer.atuNum = this.formContract.controls.atu.value;
            this.cService.updateCustomer(customer).subscribe((result) => {
              this.formContractCustomerChanged = false;
              if(result){
                contract.customer = result;
                let index = this.customers.findIndex(c => c.id == result.id);
                if(index > -1) this.customers[index] = result;
                this.updateLocalContract(contract, sign);
              }
            })
          } else {
            this.updateLocalContract(contract, sign);
          }
        }
      })
    }
  }

  updateLocalContract(contract: SalesContract, sign: boolean) {
    let index = this.contracts.findIndex(d => d.purchase_contract_id == contract.purchase_contract_id);
    if(index > -1) this.contracts[index] = contract;
    this.tabDocuments = this.contracts;
    this.tabDocumentsFiltered = this.contracts;
    this.applySorting();
    this.selectedContract = null;
    this.formContract = null;
    this.pageContract = 1;
    this.surface = 1;
    if(!sign){
      this.openContractPdf(contract);
      this.dService.showNotification({
        title: 'Erfolgreich',
        message: 'Dokument wurde aktualisiert.',
        success: true
      });
    } else {
      this.signPDF(contract);
    }
  }


  openCreate(params: any) {
    this.resetAllForms();
    let value;
    if(Number(params?.typ)) value = Number(params.typ);
    else value = params;
    this.redirectId = null;
    switch (value) {
      case 2:
        this.formOffer.controls.offer_items.setValue([]);
        this.formOffer.controls.offer_items.value.push(this.createFormOfferItem(this.position += 10));
        this.formOffer.controls.status.setValue(0);
        this.formOffer.controls.offer_id.setValue(0);
        this.formOffer.controls.show_price_overview.setValue(false);
        this.formOffer.controls.show_brutto_price.setValue(false);
        if(params.customerId){
          this.lastRoute = LastRoute.CUSTOMERS;
          this.redirectId = params.customerId;
          for(let customer of this.customers){
            if(customer.id == params.customerId){
              this.formOffer.controls.customer.setValue(customer);
              this.formOffer.controls.contact_person.setValue(customer.contactPerson);
              this.locationRoute.replaceState('/documents/create?typ='+params.typ+(params.customerId ? ('&customerId'+params.customerId) : ''));
              this.pageOffer = 1;
              this.surface = 2;
              this.pageLoaded = true;
              break;
            }
          }
        } else if(params.vehicleId){
          this.lastRoute = LastRoute.VEHICLES;
          this.redirectId = params.vehicleId;
          this.vService.getVehicle().subscribe((response) => {
            if(response){
              for(let vehicle of response){
                if(vehicle.id == params.vehicleId){
                  this.formOffer.controls.title.setValue(vehicle.brand.makeName + ', ' + vehicle.model.name + ', ' + vehicle.model.version);
                  if(vehicle.customer_id){
                    for(let c of this.customers){
                      if(c.id == vehicle.customer_id){
                        this.formOffer.controls.customer.setValue(c);
                        break;
                      }
                    }
                  }
                  break;
                }
              }
              this.pageOffer = 1;
              this.surface = 2;
              this.pageLoaded = true;
            }
          })
        } else {
          this.pageOffer = 1;
          this.surface = 2;
          this.pageLoaded = true;
        }
        break;
      case 3:
        this.formBill.controls.bill_items.setValue([]);
        this.formBill.controls.bill_items.value.push(this.createFromBillItem(this.position += 10));
        this.surface = 3;
        break;
      case 4:
        this.createFormContract();
        if(params.vehicleId){
          this.formContract.controls.vehicleId.setValue(Number(params.vehicleId));
          this.lastRoute = LastRoute.VEHICLES;
          this.redirectId = params.vehicleId;
        }
        if(params.customerId){
          this.formContract.controls.customerId.setValue(Number(params.customerId));
          this.lastRoute = LastRoute.CUSTOMERS;
          this.redirectId = params.customerId;
        }
        this.surface = 4;
        this.pageLoaded = true;
        break;
      default: break;
    }
    this.isCreate = true;
  }

  cancelCreateAction() {
    this.dService.openQuery(
      {
        title: 'Vorgang abbrechen',
        message: 'Sind Sie sicher, dass Sie den Vorgang abbrechen möchten?',
        btn_cancel_txt: 'Nein',
        btn_submit_txt: 'Ja',
        typ: 'cancel_create',
        submit_value: 0,
      }
    );
  }

  cancelCreate() {
    if(this.lastRoute == LastRoute.VEHICLES){
      this.router.navigate(['cars'], { queryParams: { vehicleId: this.redirectId } });
    } else if(this.lastRoute == LastRoute.CUSTOMERS){
      this.router.navigate(['customers'], { queryParams: { customerId: this.redirectId } });
    } else {
      this.resetAllForms();
      this.locationRoute.replaceState('/documents');
      this.pageContract = 1;
      this.createFormContract();
      if(this.selectedDoc && this.selectedDoc?.offer_id != 0){
        this.surface = 22;
      } else {
        this.selectedDoc = null;
        this.surface = 1;
      }
    }
    this.lastRoute = null;
    this.redirectId = null;
  }

  resetAllForms() {
    this.formOffer.reset();
    this.formBill.reset();
    this.submitted = false;
    this.submittedItem = false;
    this.isEdit = false;
    this.isCreate = false;
    this.position = 0;
  }

  openDetails(document: any) {
    if(document instanceof Offer){
      this.getTransactionsOffer(document);
    }
    if(document instanceof Bill){
      this.selectedDoc = document;
      this.surface = 33;
    }
    this.locationRoute.replaceState('/documents?documentsId='+document.offer_id);
  }

  getTransactionsOffer(document: Offer) {
    this.transactionService.getTransactions('document', document.offer_id).subscribe((response) => {
      if(response){
        document.transactions = response;
        this.selectedDoc = document;
        this.fillOfferForm();
        this.surface = 22;
      }
    })
  }

  openPdf(document: any) {
    if(document instanceof Offer){
      this.selectedDoc = document;
      this.selectedDoc.pdf = this.getOfferPdf(document.offer_id);
    }
  }

  moveTo(document: any) {
    this.updateOffer(document);
  }

  backAction() {
    this.selectedDoc = null;
    this.surface = 1;
    this.locationRoute.replaceState('/documents');
  }

  editDocument(doc?: any) {
    if(!doc){
      doc = this.selectedDoc;
    }
    if(doc instanceof Offer){
      this.selectedDoc = doc;
      this.fillOfferForm();
      this.surface = 2;
    }
    if(doc instanceof Bill){
      this.selectedDoc = doc;
      this.fillBillForm()
      this.surface = 3;
    }
    this.isEdit = true;
    this.pageOffer = 1;
    this.pageBill = 1;
    this.pageContract = 1;
    this.locationRoute.replaceState('/documents/edit?documentId='+doc.offer_id);
  }

  searchAction(value: string) {
    if(value && value.length > 0){
      if(this.tab == 1){
        this.tabDocumentsFiltered = [];
        this.tabDocumentsFiltered = this.offers.filter((doc) => {
          return doc.customer.companyName?.toLowerCase().includes(value.toLowerCase()) ||
                doc.title.toLowerCase().includes(value.toLowerCase()) ||
                doc.contact_person.firstName.toLowerCase().includes(value.toLowerCase()) ||
                doc.contact_person.lastName.toLowerCase().includes(value.toLowerCase()) ||
                (doc.customer.contactPerson.firstName + ' ' + doc.customer.contactPerson.lastName).toLowerCase().includes(value.toLowerCase());
        })
      } else if(this.tab == 2){
        this.tabDocumentsFiltered = [];
        this.tabDocumentsFiltered = this.contracts.filter((doc) => {
          return doc.vehicle?.externalId?.toLowerCase().includes(value.toLowerCase()) ||
                doc.vehicle?.brand.makeName.toLowerCase().includes(value.toLowerCase()) ||
                doc.vehicle?.model.name.toLowerCase().includes(value.toLowerCase()) ||
                doc.vehicle?.fin_number?.toLowerCase().includes(value.toLowerCase()) ||
                doc.customer?.contactPerson.firstName?.toLowerCase().includes(value.toLowerCase()) ||
                doc.customer?.contactPerson.firstName?.toLowerCase().includes(value.toLowerCase()) ||
                (doc.customer?.contactPerson.firstName.toLowerCase()+' '+doc.customer?.contactPerson.lastName.toLowerCase())?.includes(value.toLowerCase()) ||
                doc.customer?.companyName?.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      this.changeTab(this.tab);
    }
    this.setPages(this.tabDocumentsFiltered.length);
  }

  validateDateInput(c: UntypedFormControl): any {
    const date_regex = /^\d{2}.\d{2}.\d{4}$/;
    if(c.value && c.value._f == 'DD.MM.YYYY'){
      if(date_regex.test(c.value._i)){
        let today = new Date();
        let values = c.value._i.split('.');
        let input = new Date(values[2],(Number(values[1])-1),Number(values[0]));
        if(input.getTime() > today.getTime()){
          return null;
        }
      }
    } else if(c.value && moment.isMoment(c.value)){
      if(new Date().getTime() <= c.value.valueOf()){
        return null;
      }
    } else if(c.value == null){
      return null;
    }
    return {
      validateDateInput: {
        valid: false
      }
    }
  }
  validateDateInputLong(c: UntypedFormControl): any {
    const date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if(c.value && c.value._f == 'DD/MM/YYYY'){
      if(date_regex.test(c.value._i)){
        let today = new Date();
        let values = c.value._i.split('/');
        let input = new Date(values[2],(Number(values[1])-1),Number(values[0]));
        if(input.getTime() < today.getTime()){
          return null;
        }
      }
    } else if(c.value && moment.isMoment(c.value)){
      return null;
    } else if(c.value == null){
      return null;
    }
    return {
      validateDateInput: {
        valid: false
      }
    }
  }

  includesCustomer(obj: any): boolean {
    if(!this.filter.customer) return true;
    else {
      if(obj.customer?.id == this.filter.customer.id) return true;
      else false;
    }
  }
  includesDate(obj: any): boolean {
    if(!this.filter.start_date && !this.filter.end_date) return true;
    else if(this.filter.start_date && this.filter.end_date) {
      if(obj instanceof Offer){
        if(obj.offer_date >= this.filter.start_date.valueOf()  && obj.offer_date <= this.filter.end_date.valueOf()) return true;
      } else if(obj instanceof SalesContract) {
        if(obj.purchase_date >= this.filter.start_date.valueOf()  && obj.purchase_date <= this.filter.end_date.valueOf()) return true;
      }
    } else if(this.filter.start_date && !this.filter.end_date){
      if(obj instanceof Offer){
        if(obj.offer_date >= this.filter.start_date.valueOf()) return true;
      } else if(obj instanceof SalesContract) {
        if(obj.purchase_date >= this.filter.start_date.valueOf()) return true;
      }
    } else if(!this.filter.start_date && this.filter.end_date){
      if(obj instanceof Offer){
        if(obj.offer_date <= this.filter.end_date.valueOf()) return true;
      } else if(obj instanceof SalesContract) {
        if(obj.purchase_date <= this.filter.end_date.valueOf()) return true;
      }
    } else return false;
  }

  applyFilter() {
    if(this.tab == 1){
      this.tabDocumentsFiltered = [];
      this.tabDocumentsFiltered = this.offers.filter(bill => {
        let found = true;
        if(!this.includesCustomer(bill)) found = false;
        if(!this.includesDate(bill)) found = false;
        return found;
      })
    } else if(this.tab == 2){
      this.tabDocumentsFiltered = this.contracts.filter(contract => {
        let found = true;
        if(!this.includesCustomer(contract)) found = false;
        if(!this.includesDate(contract)) found = false;
        return found;
      });
    }
    if(this.filter.customer){
      this.filter.is_set = true;
    } else this.filter.is_set = false;
    this.applySorting();
    this.setPages(this.tabDocumentsFiltered.length);
  }

  removeFilterValue(str: string) {
    this.filter = {
      customer: str === 'customer' ? null : this.filter.customer,
      start_date: null,
      end_date: null,
      is_set: false
    }
    this.applyFilter();
  }

  resetFilterEmitter() {
    this.filter = {
      customer: null,
      start_date: null,
      end_date: null,
      is_set: false
    }
    this.applyFilter();
  }

  openContractPdf(contract: SalesContract) {
    this.documentService.getContractPDF(contract.purchase_contract_id).subscribe((result) => {
      if(result){
        this.dService.openAttachementViewer({ pdf: result, isArrayBuffer: true })
        //this.print(result);
      }
    })
  }

  // print(pdf: string) {
  //   const blob = new Blob([pdf], { type: 'application/pdf' });
  //   const objectUrl = URL.createObjectURL(blob);
  //   const iframe = document.createElement('iframe');
  //   iframe.src = objectUrl;
  //   document.body.appendChild(iframe);
  //   iframe.style.display = 'none';
  //   iframe.onload = function() {
  //     setTimeout(function() {
  //       iframe.focus();
  //       iframe.contentWindow.print();
  //     }, 1);
  //   };
  // }

  openEditContract(contract: SalesContract) {
    this.selectedContract = contract;
    this.createFormContract(contract);
    this.surface = 4;
  }

  deleteContractAction(contract: SalesContract) {
    if(contract){
      this.dService.openQuery(
        {
          title: 'Dokument löschen',
          message: 'Sind sie sicher, dass Sie diess Dockument unwiderruflich löschen möchten?',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Löschen',
          typ: 'delete_contract',
          submit_value: contract.purchase_contract_id,
        }
      );
    }
  }
  deleteContract(id: number) {
    this.documentService.deleteContract(id).subscribe((success) => {
      if(success){
        let index = this.contracts.findIndex(d => d.purchase_contract_id == id);
          if(index > -1) this.contracts.splice(index,1);
          this.tabDocuments = this.contracts;
          this.tabDocumentsFiltered = this.contracts;
          this.setPages(this.tabDocumentsFiltered.length);
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Dokument wurde entfernt.',
            success: true
          });
      }
    })
  }

  duplicateOffer(offer: Offer) {
    this.selectedDoc = JSON.parse(JSON.stringify(offer));
    this.selectedDoc.offer_id = 0;
    this.selectedDoc.status = 0;
    this.fillOfferForm();
    this.pageOffer = 1;
    this.surface = 2;
    this.pageLoaded = true;
  }

  applySorting() {
    this.sortObject.sorting_is_set = true;
    if(this.tab == 1) {
      if(this.sortObject.sort_after_value == 1){
        this.tabDocumentsFiltered.sort((a,b) => {
          if(a.offer_date > b.offer_date) return this.sortObject.sort_asc ? 1 : -1;
          if(a.offer_date < b.offer_date) return this.sortObject.sort_asc ? -1 : 1;
          return 0;
        })
      } else if(this.sortObject.sort_after_value == 2){
        this.tabDocumentsFiltered.sort((a,b) => {
          if(a.status > b.status) return this.sortObject.sort_asc ? 1 : -1;
          if(a.status < b.status) return this.sortObject.sort_asc ? -1 : 1;
          return 0;
        })
      }
    } else if(this.tab == 2){
      if(this.sortObject.sort_after_value == 1){
        this.tabDocumentsFiltered.sort((a,b) => {
          if(a.purchase_date > b.purchase_date) return this.sortObject.sort_asc ? 1 : -1;
          if(a.purchase_date < b.purchase_date) return this.sortObject.sort_asc ? -1 : 1;
          return 0;
        })
      } else if(this.sortObject.sort_after_value == 2){
        this.tabDocumentsFiltered.sort((a,b) => {
          if(a.status > b.status) return this.sortObject.sort_asc ? 1 : -1;
          if(a.status < b.status) return this.sortObject.sort_asc ? -1 : 1;
          return 0;
        })
      }
    }
  }

  resetSortEmitter() {
    this.sortObject.sort_after_value = 1;
    this.sortObject.sort_asc = false;
    this.sortObject.sorting_is_set = false;
    this.applySorting();
  }

  signPDF(contract: any){
    this.documentService.createSignatureDocument(contract).subscribe((result) => {
      if(result){
        let typ;
        if(contract instanceof SalesContract) typ = DokumentTyp.Kaufvertrag; //3
        else if(contract instanceof Offer) typ = DokumentTyp.Angebot; //2
        else typ = 0;
        this.dService.openLoaderSignature({document_typ: typ});
      }
    });
  }

  uploadFile(event: any): void  {
    FileHandler.triggerFileUpload('single', ['application/pdf']).then((files: any[]) => {
      if(files){
        for(let file of files){

        }
      }
    });
  }
}
