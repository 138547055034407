<div class="date_container">
    <div class="input_container">
        <div *ngIf="valueSelected" class="lbl_title">{{title}}</div>
        <div #inputContainer class="input_dropdown_container" [ngClass]="invalid ? 'input_dropdown_container_inavlid' : ''" [ngStyle]="{'border-color': showDropdown ? borderBottomColor : ''}" (click)="showDropdown = !showDropdown">
            <div #dropInput class="dropdown_input_default" [ngClass]="ifNoValueChosen() ? '' : 'dropdown_input'" [ngClass]="invalid ? 'dropdown_input_invalid' : ''">{{ifNoValueChosen() ? placeholderTemp : placeholder}}</div>
            <img class="dropdown_arrow" src="../../../../assets/arrow-down.png" [class.drop-arrow-rotated]="showDropdown" (click)="showDropdown = !showDropdown">
        </div>
    </div>
    <div #dropContainer class="dropdown_container_date" [@openDrop]="showDropdown ? 'open' : 'close'" [ngClass]="isBirthdate ? 'dropdown_container_birthdate' : 'dropdown_container_date'">
        <div class="drop_container_title">
            <div #titleYear class="dropbox_title">Jahr</div>
        </div>
        <div class="drop_container_title">
            <div #titleMonth class="dropbox_title">Monat</div>
        </div>
        <div *ngIf="isBirthdate"  class="drop_container_title">
            <div #titleDay class="dropbox_title">Tag</div>
        </div>
        <div #scrollYears id="scroll_box_year" class="scroll_container">
            <div  class="scroll_inner_container">
                <div [id]="'year'+year" class="dropbox_value" *ngFor="let year of years" (click)="setYear(year)">{{year}}</div>
            </div>
        </div>
        <div #scrollMonths class="scroll_container">
            <div class="scroll_inner_container">
                <div class="dropbox_value" *ngFor="let month of months" (click)="setMonth(month)">{{month}}</div>
            </div>
        </div>
        <div #scrollDays *ngIf="isBirthdate" class="scroll_container">
            <div class="scroll_inner_container">
                <div class="dropbox_value" *ngFor="let day of days" (click)="setDay(day)">{{day}}</div>
            </div>
        </div>
    </div>
</div>
