<div class="dropdown_component_wrapper" *ngIf="formcontrol">
  <div class="overview_record_lbl">{{title}}</div>
  <div class="dropdown_button">
      <div class="dropdown_button_header" [class.dropdown_disabled]="disabled" [class.dropdown_button_invalid]="submitted && formcontrol.invalid" (click)="show = !show" appDropdown [show]="show" (changedValueEmitter)="show = $event">
          <div class="dropdown_header_data_lbl_overview" [ngClass]="formcontrol.value == null || !isValidObjectId() ? 'dropdown_header_lbl_placeholder' : ''">{{formcontrol.value == null || !isValidObjectId() ? placeholder : headervalue}}</div>
          <img class="dropdown_button_header_img" [ngClass]="show ? 'dropdown_button_header_img_active' : ''" src="../../../../assets/arrow-dark.svg">
      </div>
      <div #dropdownBox class="dropdown_button_box" *ngIf="show">
          <label *ngFor="let value of values; let i = index" (click)="submit(value); show = false">{{value.name}}</label>
      </div>
  </div>
</div>
