<div id="sidebar">
    <div>
      <img id="logo" src="../../../assets/logomenu.svg" (click)="openWebsite()">
      <div id="menu_item_wrapper">
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 1" (click)="reload('/dashboard')"  *ngIf="false">
          <svg xmlns="http://www.w3.org/2000/svg" width="25.918" height="20.976" viewBox="0 0 25.918 20.976">
            <path class="menu_item_svg" d="M186.706,497.191h-.134a6.69,6.69,0,0,0-.206-.962c-1.015-3.437-1.666-6.339-9.381-6.339h-2.638c-7.715,0-8.366,2.9-9.38,6.339a7.776,7.776,0,0,0-.218.962h-.161a1.9,1.9,0,0,0-1.9,1.9.523.523,0,0,0,.522.522h1.123a1.965,1.965,0,0,0-.365.953v9.778a.522.522,0,0,0,.522.522h4.284a.523.523,0,0,0,.522-.522V508.55h12.74v1.794a.523.523,0,0,0,.522.522h4.283a.523.523,0,0,0,.523-.522v-9.778a1.712,1.712,0,0,0-.432-.953h1.151a.523.523,0,0,0,.522-.522A1.9,1.9,0,0,0,186.706,497.191Zm-20.181-.5.077-.263c.929-3.166,1.442-4.91,7.744-4.91h2.638c6.3,0,6.814,1.744,7.744,4.91l.077.263a1.011,1.011,0,0,1-.97,1.3H167.5a1.011,1.011,0,0,1-.97-1.3Zm1.845,8.109h-.312c-2.343,0-2.648-.415-2.648-.776a2.407,2.407,0,0,1,.018-.3c.238-1.879,1.947-2.544,3.539-1.517,0,0,2.506,1.424,2.362,2.268C171.33,504.585,170.958,504.755,168.37,504.8Zm11.523-.326c-.143-.844,2.363-2.268,2.363-2.268,1.592-1.027,3.3-.363,3.538,1.517a2.4,2.4,0,0,1,.019.3c0,.361-.306.776-2.648.776h-.312C180.266,504.755,179.893,504.585,179.893,504.472Z" transform="translate(-162.687 -489.89)" fill="#2c2c2c"/>
          </svg>
          <label>Dashboard</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 2" (click)="reload('/cars')"  *ngIf="authService.accessVia('vehicle_view')">
          <svg xmlns="http://www.w3.org/2000/svg" width="25.918" height="20.976" viewBox="0 0 25.918 20.976">
            <path class="menu_item_svg" d="M186.706,497.191h-.134a6.69,6.69,0,0,0-.206-.962c-1.015-3.437-1.666-6.339-9.381-6.339h-2.638c-7.715,0-8.366,2.9-9.38,6.339a7.776,7.776,0,0,0-.218.962h-.161a1.9,1.9,0,0,0-1.9,1.9.523.523,0,0,0,.522.522h1.123a1.965,1.965,0,0,0-.365.953v9.778a.522.522,0,0,0,.522.522h4.284a.523.523,0,0,0,.522-.522V508.55h12.74v1.794a.523.523,0,0,0,.522.522h4.283a.523.523,0,0,0,.523-.522v-9.778a1.712,1.712,0,0,0-.432-.953h1.151a.523.523,0,0,0,.522-.522A1.9,1.9,0,0,0,186.706,497.191Zm-20.181-.5.077-.263c.929-3.166,1.442-4.91,7.744-4.91h2.638c6.3,0,6.814,1.744,7.744,4.91l.077.263a1.011,1.011,0,0,1-.97,1.3H167.5a1.011,1.011,0,0,1-.97-1.3Zm1.845,8.109h-.312c-2.343,0-2.648-.415-2.648-.776a2.407,2.407,0,0,1,.018-.3c.238-1.879,1.947-2.544,3.539-1.517,0,0,2.506,1.424,2.362,2.268C171.33,504.585,170.958,504.755,168.37,504.8Zm11.523-.326c-.143-.844,2.363-2.268,2.363-2.268,1.592-1.027,3.3-.363,3.538,1.517a2.4,2.4,0,0,1,.019.3c0,.361-.306.776-2.648.776h-.312C180.266,504.755,179.893,504.585,179.893,504.472Z" transform="translate(-162.687 -489.89)" fill="#2c2c2c"/>
          </svg>
          <label>Fahrzeuge</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 3" (click)="reload('/customers')"  *ngIf="authService.accessVia('customer_view')">
          <svg  xmlns="http://www.w3.org/2000/svg" width="23.085" height="23.477" viewBox="0 0 23.085 23.477">
            <g id="Gruppe_601" data-name="Gruppe 601" transform="translate(5.861)">
              <circle class="menu_item_svg" id="Ellipse_14" data-name="Ellipse 14" cx="5.681" cy="5.681" r="5.681" fill="#2c2c2c"/>
            </g>
            <g id="Gruppe_602" data-name="Gruppe 602" transform="translate(0 14.757)">
              <path class="menu_item_svg" data-name="Pfad 544" d="M230.739,577.916a.572.572,0,0,0,.565-.628c-.4-4.783-5.417-8.092-11.54-8.092s-11.136,3.308-11.54,8.092a.572.572,0,0,0,.564.628Z" transform="translate(-208.221 -569.197)" fill="#2c2c2c"/>
            </g>
          </svg>
          <label>Kunden</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 5" (click)="reload('/documents')"  *ngIf="authService.accessVia('purchase_contract_view')">
          <svg xmlns="http://www.w3.org/2000/svg" width="13.323" height="14.487" viewBox="0 0 13.323 14.487">
            <g id="document" transform="translate(-23.52 -129.848)">
              <path class="menu_item_svg" data-name="Pfad 545" d="M216.581,756.985V744.627a1.491,1.491,0,0,1,.444-1.064h-.678a1.326,1.326,0,0,0-1.233,1.4v11.682a1.326,1.326,0,0,0,1.233,1.4h.678A1.492,1.492,0,0,1,216.581,756.985Z" transform="translate(-191.594 -613.715)" fill="#f2f2f2"/>
              <path class="menu_item_svg" data-name="Pfad 546" d="M232.779,749.168l-4.812-5.473a.375.375,0,0,0-.281-.132h-4.371a1.326,1.326,0,0,0-1.233,1.4v11.682a1.326,1.326,0,0,0,1.233,1.4h8.348a1.326,1.326,0,0,0,1.233-1.4v-7.16A.484.484,0,0,0,232.779,749.168Zm-2.218,5.9h-6.146a.485.485,0,0,1,0-.969h6.146a.485.485,0,1,1,0,.969Zm0-2.931h-6.146a.485.485,0,0,1,0-.969h6.146a.485.485,0,1,1,0,.969Z" transform="translate(-196.053 -613.715)" fill="#2C2C2C"/>
            </g>
          </svg>
          <label>Dokumente</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 13" (click)="reload('/rent')"  *ngIf="authService.accessVia('rent_view');">
          <svg xmlns="http://www.w3.org/2000/svg" width="22.549" height="22.549" viewBox="0 0 22.549 22.549">
            <path class="menu_item_svg" id="rent" d="M20.672,0H19.325a2.794,2.794,0,0,0-1.99.825l-8,7.994a7.053,7.053,0,1,0,4.394,4.394l2.252-2.252V7.505H18.8V4.691h3.331a2.794,2.794,0,0,0,.421-1.467V1.876A1.876,1.876,0,0,0,20.672,0ZM5.192,18.763A1.407,1.407,0,1,1,6.6,17.356a1.407,1.407,0,0,1-1.407,1.407" transform="translate(0 0)" fill="#2c2c2c"/>
          </svg>
          <label>Miete</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 11" (click)="reload('/accounting')"  *ngIf="authService.accessAccountingMenu()">
          <svg xmlns="http://www.w3.org/2000/svg" width="25.528" height="21.273" viewBox="0 0 25.528 21.273">
            <path class="menu_item_svg" d="M22.337,6.255H5.318A3.2,3.2,0,0,1,2.941,5.192,3.186,3.186,0,0,1,5.318,4.127H24.464a1.064,1.064,0,0,0,0-2.127H5.318A5.319,5.319,0,0,0,0,7.318V17.955a5.319,5.319,0,0,0,5.318,5.318H22.337a3.191,3.191,0,0,0,3.191-3.191V9.446A3.191,3.191,0,0,0,22.337,6.255Zm-1.064,9.573a1.064,1.064,0,0,1,0-2.127A1.064,1.064,0,0,1,21.273,15.828Z" transform="translate(0 -2)" fill="#2c2c2c"/>
          </svg>
          <label>Buchhaltung</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 10" (click)="reload('/drives')" *ngIf="authService.accessVia('test_drive_view')">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.309" height="24.508" viewBox="0 0 21.309 24.508">
            <g id="planning" transform="translate(-128.846 -497.522)">
              <path class="menu_item_svg" id="Pfad_993" data-name="Pfad 993" d="M7.11,5.264A1.192,1.192,0,0,1,5.919,4.073V1.191a1.191,1.191,0,1,1,2.383,0V4.073A1.192,1.192,0,0,1,7.11,5.264" transform="translate(126.452 497.522)" fill="#2c2c2c"/>
              <path class="menu_item_svg" id="Pfad_994" data-name="Pfad 994" d="M27.046,5.264a1.192,1.192,0,0,1-1.191-1.191V1.191a1.191,1.191,0,1,1,2.383,0V4.073a1.192,1.192,0,0,1-1.191,1.191" transform="translate(118.391 497.522)" fill="#2c2c2c"/>
              <path class="menu_item_svg" id="Pfad_995" data-name="Pfad 995" d="M27.046,7.018a1.192,1.192,0,0,0,1.191-1.191V4.338H25.855V5.827a1.192,1.192,0,0,0,1.191,1.191" transform="translate(118.391 495.768)" fill="#2c2c2c"/>
              <path class="menu_item_svg" id="Pfad_996" data-name="Pfad 996" d="M7.11,7.018A1.192,1.192,0,0,0,8.3,5.827V4.338H5.919V5.827A1.192,1.192,0,0,0,7.11,7.018" transform="translate(126.452 495.768)" fill="#2c2c2c"/>
              <path class="menu_item_svg" id="Pfad_997" data-name="Pfad 997" d="M19.3,4.338h-.923V5.827a1.787,1.787,0,1,1-3.574,0V4.338H6.5V5.827a1.787,1.787,0,1,1-3.574,0V4.338H2.008A2.011,2.011,0,0,0,0,6.347V24.254a2.01,2.01,0,0,0,2.008,2.008H19.3a2.01,2.01,0,0,0,2.008-2.008V6.347A2.011,2.011,0,0,0,19.3,4.338m.221,19.916a.224.224,0,0,1-.221.221H2.008a.224.224,0,0,1-.221-.221v-13.8H19.522Z" transform="translate(128.846 495.768)" fill="#2c2c2c"/>
            </g>
          </svg>
          <label>PROBEFAHRT</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 12" (click)="reload('/tires')" *ngIf="authService.accessVia('tire_view')">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.848" height="21.777" viewBox="0 0 21.848 21.777">
            <path class="menu_item_svg" d="M10.924,0A10.936,10.936,0,0,0,0,10.924c.546,14.474,21.3,14.468,21.848,0A10.936,10.936,0,0,0,10.924,0Zm0,11.834a3.717,3.717,0,0,0-.769.091,3.345,3.345,0,0,0-.406-1.449,3.277,3.277,0,0,0,.646-.33,3.294,3.294,0,0,0,.529-.464,2.913,2.913,0,0,0,1.175.791,3.345,3.345,0,0,0-.406,1.449,3.717,3.717,0,0,0-.769-.088ZM16.412,7.7,13.828,8.781a1.457,1.457,0,0,1-1.994-1.35V4.624A6.378,6.378,0,0,1,16.412,7.7Zm-6.4-3.078V7.431A1.457,1.457,0,0,1,8.024,8.784l-2.6-1.06a6.372,6.372,0,0,1,4.592-3.1ZM4.74,9.412l2.394.977a1.937,1.937,0,0,1,.841,2.949l-1.59,2.047A6.372,6.372,0,0,1,4.74,9.412Zm3.1,7.08,1.632-2.109a1.869,1.869,0,0,1,2.92.01l1.62,2.1a6.4,6.4,0,0,1-6.175,0Zm7.625-1.1-1.578-2.047a1.936,1.936,0,0,1,.847-2.964L17.1,9.39a6.372,6.372,0,0,1-1.645,6Z" fill="#2c2c2c"/>
          </svg>
          <label>LAGER</label>
        </div>
        <!-- <div class="menu_item" [class.menu_item_active]="selectedMenue == 9" (click)="reload('/messages')"  *ngIf="authService.accessVia('ticket_view')">
          <div *ngIf="getNotify() != 0" id="menu_notify">{{getNotify()}}</div>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22.695" height="22.695" viewBox="0 0 22.695 22.695">
              <path class="menu_item_svg" data-name="Pfad 1021" d="M11.347,0h0A11.345,11.345,0,0,0,3.059,19.095L.121,21.989a.405.405,0,0,0,.284.693H10.635c.024,0,.046,0,.069-.007.213.012.427.019.643.019A11.347,11.347,0,0,0,11.347,0M7.485,14.106a.91.91,0,0,1-.2.3.907.907,0,0,1-1.483-.3.92.92,0,0,1,.2-.992.919.919,0,0,1,.3-.2,1.138,1.138,0,0,1,.173-.055.908.908,0,0,1,.819.251.923.923,0,0,1,.269.641.906.906,0,0,1-.074.35m8.562.562H10.526a.91.91,0,0,1,0-1.82h5.521a.91.91,0,1,1,0,1.82m0-5.277h-9.4a.91.91,0,0,1,0-1.82h9.4a.91.91,0,1,1,0,1.82" transform="translate(0 0)" fill="#2c2c2c"/>
          </svg>
          <label>NACHRICHTEN</label>
        </div> -->
      </div>
    </div>

    <div id="menu_footer">
      <button class="btn-gradient" *ngIf="authService.accessVia(['user_view','settings_view'])"
        (click)="showActions2 = !showActions2"
        appDropdown
        [show]="showActions2"
        (changedValueEmitter)="showActions2 = $event">
        OPTIONEN
        <div class="action-container-1" *ngIf="showActions2">
          <button class="button-label-white" *ngIf="authService.accessVia('settings_view')" (click)="reload('/settings')">Einstellungen</button>
          <button class="button-label-white" *ngIf="authService.accessVia('user_view')" (click)="reload('/users')">User-Verwaltung</button>
          <button class="button-label-white" *ngIf="authService.accessVia('location_view')" (click)="reload('/locations')">Standorte</button>
          <button class="button-label-white" *ngIf="false" (click)="null">Support kontaktieren</button>
        </div>
      </button>
      <button class="btn-black"
        (click)="showActions = !showActions"
        appDropdown
        [show]="showActions"
        (changedValueEmitter)="showActions = $event">
        {{user.f[0] + user.l[0]}}
        <div class="action-container-2" *ngIf="showActions">
          <div class="user_info_wrapper">
            <label class="initials">{{user.f[0]}}{{user.l[0]}}</label>
            <div>
              <label>{{user.f}} {{user.l}}</label>
              <label>{{locationName}} · {{permission_group_name}}</label>
            </div>
          </div>
          <button class="button-label-white" (click)="changePasswortAction()">Passwort ändern</button>
          <button class="button-label-white" (click)="logoutAction()">Abmelden</button>
        </div>
      </button>
      <label class="lbl_regular_italic_light_color">V.{{versionsNr}}</label>
    </div>

</div>
<app-dialog-change-password></app-dialog-change-password>
