import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { BaseComponent } from 'src/app/view/BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BaseBoxComponent } from 'src/app/view/BaseBoxComponent';

@Component({
  selector: 'app-interface-overview',
  templateUrl: './interface-overview.component.html',
  styleUrls: ['./interface-overview.component.css'],
  animations: [ DropdownIconArrowAnimation ]
})
export class InterfaceOverviewComponent extends BaseBoxComponent implements OnInit, AfterViewInit {
  @Input() vehicle: Vehicle;
  @Input() submitted: boolean;
  @Input() formGroupVehicle: FormGroup;
  public showDropdownOne = false;
  public showDropdownTwo = false;
  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  @ViewChild('dropdownTwo') dropdownTwo: ElementRef;
  public shop_link: string|undefined = localStorage.getItem('shop_link');
  public listener: () => void;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService,
    private renderer: Renderer2
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.viewTitle = "interface-overview";
    super.init();
  }

  get f() {
    return this.formGroupVehicle.controls;
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window', 'click', (e: Event) => {
      if(this.showDropdownOne && !this.dropdownOne.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.dropdownTwo.nativeElement.contains(e.target)) this.showDropdownTwo = false;
    });
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }
}
