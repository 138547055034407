import { DropdownIconArrowAnimation } from './../../../../helpers/Animations';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { BaseComponent } from 'src/app/view/BaseComponent';
import { formatDate } from '@angular/common';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BaseBoxComponent } from 'src/app/view/BaseBoxComponent';

@Component({
  selector: 'app-additional',
  templateUrl: './additional.component.html',
  styleUrls: ['./additional.component.css'],
  animations: [ DropdownIconArrowAnimation ]
})
export class AdditionalComponent extends BaseBoxComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Input() formGroupVehicle: UntypedFormGroup;
  @Input() submitted: boolean;
  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public minDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public keyValues = [];
  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.viewTitle = "additional";
    super.init();
  }

  get f() {
    return this.formGroupVehicle.controls;
  }
}
