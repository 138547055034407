import { VehicleRessource } from "../VehicleRessource";

export class VehicleEnvironmentLabelFactory {
    static jsonFactory(rawData: any): VehicleRessource[] {
        let dataAry: VehicleRessource[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehicleRessource {
        let data = new VehicleRessource(
            rawData.environment_label_id,
            rawData.name ? rawData.name : rawData.environment_label_name,
        );
        return data;
    }
}
