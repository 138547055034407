<div id="footer-container">
  <div id="page-counter">
      <div *ngFor="let page of pagesTotalAry">
          <img class="img_dot" [src]="getDotIcon(page)">
      </div>
  </div>
  <div class="btn_container_pager">
      <img *ngIf="currentPageCreate != 1" id="btn_previous" src="../../../../../assets/back-dark.svg" (click)="previousPage()">
      <div class="btn_submit_dark" (click)="nextPage()">{{currentPageCreate == 5 ? (!isEdit ? 'Dokument erstellen' : 'Dokument speichern') : 'Weiter'}}</div>
  </div>
</div>
