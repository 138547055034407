import { VehicleCategory } from "../VehicleCategory";

export class VehicleCategoryFactory {
    static jsonFactory(rawData: any): VehicleCategory[] {
        let dataAry: VehicleCategory[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehicleCategory {
        let object = new VehicleCategory(
            rawData.category_id,
            rawData.category_name,
        );
        return object;
    }
}