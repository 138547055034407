import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';

@Component({
  selector: 'app-messages-topbar',
  templateUrl: './messages-topbar.component.html',
  styleUrls: ['./messages-topbar.component.css'],
  animations: DropdownIconArrowAnimation
})
export class MessagesTopbarComponent implements OnInit {
  @Input() surface: number;
  @Input() filter: any;
  @Input() sortObject: any;
  @Output() filterEmitter = new EventEmitter<number>();
  @Output() openCreateEmitter = new EventEmitter<undefined>();
  @Output() cancelCreateEmitter = new EventEmitter<undefined>();
  @Output() backEmitter = new EventEmitter<undefined>();
  @Output() searchEmitter = new EventEmitter<string>();
  @Output() applyFilterEmitter = new EventEmitter<undefined>();
  @Output() resetFilterEmitter = new EventEmitter<undefined>();
  public showFilterDetail = false;
  public showSort = false;

  @HostListener('document:keydown.escape', ['$event'])onEnterHandler(event: KeyboardEvent) {
    if(this.surface == 2 || this.surface == 3) this.cancelCreateEmitter.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

  openFilter() {
    // if(!this.filterDetail.is_set){
    //   this.resetFilterEmitter.emit();
    // }
    // this.showFilterDetail = !this.showFilterDetail;
  }
}
