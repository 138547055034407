import { RentPrice } from "../RentPrice";

export class RentPriceFactory {

  static jsonFactory(rawData: any): RentPrice[] {
    let dataAry: RentPrice[] = [];
    for(let data of rawData){
        dataAry.push(this.jsonFactoryOne(data));
    }
    return dataAry;
  }

  static jsonFactoryOne(raw: any): RentPrice {
    return new RentPrice(
      raw.rent_price_id,
      raw.vehicle_id,
      raw.rent_price,
      raw.duration,
      raw.mileage,
    );
  }
}
