<div id="dialog_window" (window:resize)="onScreenSizeChange()">
  <form id="dialog_container" (ngSubmit)="onSubmit()">
      <div class="lbl_bold">{{selectedEntrieDay.day | date: 'EEEE, dd.MM.yyyy'}}</div>
      <div class="picker_container">
        <div class="column">
          <div class="title_timer lbl_list_title">START</div>
          <div id="inner_container">
            <div class="value_container">
                <div #hContainer class="scroll_box" (scroll)="handleHHScroll($event, 1)">
                    <div *ngFor="let item of hhArray; let i = index;" id="{{'hh' + i}}" class="item_hour"  (click)="scrollTo(i, 'hh', 1)">{{item > 9 ? item : '0'+item}}</div>
                </div>
            </div>
            <div class="timer_focus_container">
              <div class="border"></div>
              <div class="hour_spacer_sign">:</div>
              <div class="border"></div>
            </div>
            <div class="value_container">
                <div #mContainer class="scroll_box" (scroll)="handleMMScroll($event, 1)">
                    <div *ngFor="let item of mmArray; let i = index;" id="{{'mm' + i}}" class="item_min" (click)="scrollTo(i, 'mm', 1)">{{item > 9 ? item : '0'+item}}</div>
                </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="title_timer lbl_list_title">ENDE</div>
          <div id="inner_container">
            <div class="value_container">
                <div #hContainer_2 class="scroll_box" (scroll)="handleHHScroll($event, 2)">
                    <div *ngFor="let item of hhArray_2; let i = index;" id="{{'hh_2' + i}}" class="item_hour"  (click)="scrollTo(i, 'hh_2', 2)">{{item > 9 ? item : '0'+item}}</div>
                </div>
            </div>
            <div class="timer_focus_container_2">
              <div class="border"></div>
              <div class="hour_spacer_sign">:</div>
              <div class="border"></div>
            </div>
            <div class="value_container">
                <div #mContainer_2 class="scroll_box" (scroll)="handleMMScroll($event, 2)">
                    <div *ngFor="let item of mmArray_2; let i = index;" id="{{'mm_2' + i}}" class="item_min" (click)="scrollTo(i, 'mm_2', 2)">{{item > 9 ? item : '0'+item}}</div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div>{{selectedStartTime}}</div>
      <div>{{selectedEndTime}}</div> -->
      <div class="error_time_container">
        <div style="height: 25px;"></div>
        <!-- <label [ngStyle]="{'visibility': timePickerError ? 'visible' : 'hidden'}" class="lbl_error">Zeitraum ist belegt oder ungültig.</label> -->
      </div>
      <div class="btn_container">
          <div class="btn_label" (click)="cancelEmitter.emit()">Abbrechen</div>
          <input class="btn_submit_dark" type="submit" value="Hinzufügen">
      </div>
  </form>
</div>
