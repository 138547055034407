<div class="page">
  <div *ngIf="showView == view.LIST">
    <div class="header-base-style">
      <div class="header-inner-wrapper">
        <!-- <button class="button_type_1" (click)="showDropdownMainButton = !showDropdownMainButton" appDropdown [show]="showDropdownMainButton" (changedValueEmitter)="showDropdownMainButton = $event">
          Neu
          <div *ngIf="showDropdownMainButton">
            <button (click)="selectionCreate('customer'); showDropdownMainButton = false;">Probefahrt</button>
            <button (click)="selectionCreate('intern'); showDropdownMainButton = false;">Interne Probefahrt</button>
          </div>
        </button> -->
        <button
          (click)="showDropdownMainButton = !showDropdownMainButton"
          appDropdown
          [show]="showDropdownMainButton"
          class="button_type_1"
          (changedValueEmitter)="showDropdownMainButton = $event">
          Neu
          <div *ngIf="showDropdownMainButton">
            <app-restricted-button
              [name]="'Probefahrt'"
              [access]="'test_drive_edit'"
              [isPrimary]="true"
              [isDropdownButton]="true"
              (clickEmitter)="selectionCreate('customer')">
            </app-restricted-button>
            <app-restricted-button
              [name]="'Interne Probefahrt'"
              [access]="'test_drive_edit'"
              [isPrimary]="true"
              [isDropdownButton]="true"
              (clickEmitter)="selectionCreate('intern')">
            </app-restricted-button>
          </div>
        </button>
        <div id="main-menu-wrapper">
          <button *ngFor="let mainMenu of mainMenus"
            [class.main-menu-btn-selected]="mainMenu.getActive()"
            (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive())">
            {{mainMenu.name}}
          </button>
        </div>
      </div>
      <div>
      </div>
    </div>
    <app-list-container *ngIf="_activeSubMenu.getValue()"
        [objectTyp]="'test_drive'"
        [objects]="_objectsFiltered.getValue()"
        [pageHandler]="pageHandler"
        [filter]="filter"
        [mainMenus]="mainMenus"
        [activeSubMenu$]="activeSubMenu$"
        [activeSortingColumn]="activeSortingColumn"
        (pageEmitter)="pageHandling($event)"
        (applySortingEmitter)="applySorting($event)"
        (openEditEmitter)="selectionEdit($event)"
        (deleteEmitter)="selectionDelete($event)"
        (openDetailsEmitter)="selectionDetails($event)"
        (selectionPDFCreateEmitter)="openCreatePdf($event)"
        (selectionPDFOpenEmitter)="openCreatePdf($event)"
        (selectionTestDrivePDFSignEmitter)="selectionSignedPDF($event)"
      ></app-list-container>
  </div>

<app-test-drive-create *ngIf="showView == view.CREATE"
  [typ]="createTyp"
  [vehicles]="vehicles"
  [employees]="employees"
  [customers]="customers"
  (selectionCancelEmitter)="selectionCloseDetails()"
  (createSuccessEmitter)="createSuccess($event)"
></app-test-drive-create>

<app-test-drive-details *ngIf="showView == view.DETAILS"
  [obj]="selectedObject"
  (selectionBackEmitter)="selectionCloseDetails()"
  (selectionEditEmitter)="selectionEdit($event)"
></app-test-drive-details>

<app-dialog-pdf-drive *ngIf="showDialogCreatePDF"
  [drive]="selectedObject"
  (cancelEmitter)="cancelPdfCreate()"
  (createEmitter)="createPdfCreate($event)"
></app-dialog-pdf-drive>
