import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-element-input',
  templateUrl: './element-input.component.html',
  styleUrls: ['./element-input.component.css']
})
export class ElementInputComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() placeholder: string

  constructor() { }

  ngOnInit(): void {
  }
}
