<div class="car_create_page_wrapper">
    <div class="title">Ausstattung</div>
    <div class="content">
      <div #grid id="grid">
        <div *ngFor="let category of categories" class="category_container">
          <div class="inner_title">{{category.category_name}}</div>
          <div *ngFor="let equipment of category.equipments" class="checkbox_container" (click)="addEquipment(equipment)">
            <input type="checkbox" class="checkbox" name="equipment" [checked]="equipment.is_selected">
            <label for="equipment" class="checkbox_lbl">{{equipment.eq_name}}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- <div id="help-container">
        <img id="img-help" src="../../../../../assets/icon-info.png">
        <div class="help-txt">Weitere Ausstattungs-Möglichkeiten finden Sie bei den individuellen Schnittstellen.</div>
    </div> -->
</div>
