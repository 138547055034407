export class SubMenuColumnTag {
  constructor(
    public tag_typ: string,
    public tag_data_typ: string,
    public possible_values: SubMenuColumnTagPossibleValues[],
    public possible_ranges: SubMenuColumnTagPossibleRange[],
  ){}
}

export class SubMenuColumnTagPossibleValues {
  constructor(
    public value: string,
    public color: string,
  ){}
}

export class SubMenuColumnTagPossibleRange {
  constructor(
    public smaller_than: number,
    public bigger_than: string,
    public color: string,
  ){}
}
