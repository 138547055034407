import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-admin-title',
  templateUrl: './list-admin-title.component.html',
  styleUrls: ['./list-admin-title.component.css']
})
export class ListAdminTitleComponent implements OnInit {
  @Input() isTitle: boolean;
  @Input() pageInfo: string;
  @Input() sliceFrom: number;
  @Input() sliceTo: number;
  @Input() amount: number;
  @Output() pageEmitter = new EventEmitter<boolean>();
  public maxAmountListPage = environment.max_amount_list_page;

  constructor() { }

  ngOnInit(): void {}
}
