import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { EnumService } from 'src/app/service/enum/enum.service';
import { countries } from '../BaseComponent';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-basic-customer-create',
  templateUrl: './basic-customer-create.component.html',
  styleUrl: './basic-customer-create.component.css'
})
export class BasicCustomerCreateComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() customers: Customer[] = [];
  @Input() additionalRequiredValues: string[] = [];
  public customerValues = [];
  public titleValues = [];
  public typValues = ["Firma","Privat"];
  public statusValues = ["Kunde","Interessent"];
  public countries = countries;
  public showEdit = false;
  public formSubscription: Subscription;
  public buttonText = 'Kunde ändern';

  constructor(
    private enumService: EnumService,
    private formBuilder: FormBuilder,
    public authService: AuthenticationService,
    private cdr: ChangeDetectorRef
  ){}

  ngOnInit(): void {
    this.setUp();
  }

  ngOnDestroy(): void {
    if(this.formSubscription) this.formSubscription.unsubscribe();
  }

  get formCustomer () {
    return this.form?.get('customer_form').value;
  }

  setUp() {
    const customer = this.form.get('customer').value;
    if(customer){
      this.setCustomerForm(customer);
    }
    this.customerValues = this.getDropdownValuesCustomer(this.customers);
    this.titleValues = this.enumService.getEnumValues('title');
  }

  setAdditionalRequiredValues(formGroup: FormGroup) {
    if(formGroup){
      if(this.additionalRequiredValues && this.additionalRequiredValues.length > 0){
        for(let value of this.additionalRequiredValues){
          const control = formGroup.get(value);
          if(control){
            control.setValidators(Validators.required);
            control.updateValueAndValidity();
          }
        }
      }
    }
  }

  getDropdownValuesCustomer(customers: Customer[]): any[] {
    let values = [];
    for(let value of customers){
      values.push({
        id: value.id,
        name: value.contactPerson.firstName + ' ' + value.contactPerson.lastName + (value.companyName ? ' - ' + value.companyName : '')
      })
    }
    return values;
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'customer'){
      const customer = this.customers.find(obj => obj.id == id);
      if(customer){
        this.form.controls['customer'].setValue(customer);
        this.setCustomerForm(customer);
        this.showEdit = true;
      }
    }
  }

  setCustomerForm(customer: Customer) {
    const customerForm = CustomerForm.getCustomerForm(this.formBuilder, customer);
    this.setAdditionalRequiredValues(customerForm);
    this.formSubscription = customerForm.valueChanges.subscribe((change) => {
      if(change) {
        this.form.get('customer_changed').setValue(true);
        if(this.formSubscription){
          this.formSubscription.unsubscribe();
        }
      }
    });
    this.form.get('customer_changed').setValue(false);
    this.form.get('customer_form').setValue(customerForm);
  }

  selectionChange() {
    if(this.form.get('customer').value && this.showEdit){
      this.selectionCancelEdit();
      this.buttonText = 'Abbrechen';
    } else if(!this.showEdit){
      this.selectionCancelChange();
      this.buttonText = 'Kunde ändern';
    }
  }

  selectionCancelEdit() {
    this.form.get('customer_form').setValue(null);
    this.form.get('customer_changed').setValue(false);
    this.showEdit = false;
  }

  selectionCancelChange() {
    this.form.get('customer').setValue(null);
    this.form.get('customer_form').setValue(null);
    this.form.get('customer_changed').setValue(false);
    this.showEdit = false;
  }
}
