<form class="drive_create_page_wrapper view" [formGroup]="form">
  <div id="calendar-header" class="header">
    <app-dropdown-multiple
      [title]="'Kennzeichen *'"
      [formcontrol]="form.controls['license_plate_id']"
      [headerText]="selectedPlateValue"
      [values]="platesValues"
      [submitted]="submitted"
      (submitValueEmitter)="setPlate($event);"
    ></app-dropdown-multiple>
    <app-dropdown-objects-search
      [title]="'Fahrzeug *'"
      [placeholder]="'Fahrzeug'"
      [headervalue]="form.controls['vehicle'].value ? form.controls.vehicle.value.getVehicleName() : ''"
      [formcontrol]="form.controls['vehicle']"
      [values]="vehicleValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('vehicle', $event)"
    ></app-dropdown-objects-search>
    <app-dropdown-objects-search *ngIf="form.controls['drive_typ'].value == 2"
      [title]="'Mitarbeiter *'"
      [placeholder]="'Mitarbeiter'"
      [headervalue]="form.controls['employee'].value ? form.controls['employee'].value.getFullName() : ''"
      [formcontrol]="form.controls['employee']"
      [values]="employeeValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('employee', $event)"
    ></app-dropdown-objects-search>
    <div class="input_wrapper" *ngIf="form.controls['drive_typ'].value == 2">
      <label class="overview_record_lbl">Örtlich (von/nach) *</label>
      <input type="text" placeholder="Fahrtangaben" formControlName="trip_description" [class.input_invalid]="submitted && form.controls['trip_description'].errors">
    </div>
  </div>
  <div id="calender-body" class="body">
    <app-calendar *ngIf="heightCalender && form.controls['license_plate_id'].valid && form.controls['vehicle'].valid" style="height: 100%;     display: flex;
    flex-direction: column;"
        [form]="form"
        [entries]="toShowEntries"
        [showTimeValue]="false"
        [disabled]="false"
        [showWeek]="true"
        [isCreate]="true"
        [tempScreenHeight]="heightCalender"
        [plate]="getEnumValue('license_plate_id', form.controls['license_plate_id'].value)"
        (newEntrieTimeEmitter)="addTime($event)"
    ></app-calendar>
  </div>
</form>
