<div class="element">
  <div class="element_inner_wrapper">
    <div class="element_header" *ngIf="auth != 10">
      <div *ngIf="location_id == 13">
        <img class="logo" [src]="getLogo()">
      </div>
      <div *ngIf="location_id != 13">
        <img *ngIf="this.interface.interface_id != 1" class="logo" [src]="getLogo()">
        <label *ngIf="this.interface.interface_id == 1" class="lbl_regular_thick">Ihr Logo</label>
      </div>
    </div>
    <div class="flex">
      <div class="lbl_bold">{{interface.interface_name}}</div>
      <div [ngClass]="interface.is_active == 1 ? 'lbl_color_wrapper_green' : (interface.is_active == 2 ? 'lbl_color_wrapper_orange' :'lbl_color_wrapper_red')">{{interface.status == 1 ? 'Aktiv' : (interface.status == 2 ? 'Pending' : 'Inaktiv')}}</div>
    </div>
    <div class="lbl_regular" style="margin-bottom: 10px;">{{interface.url}}</div>
    <div class="flex" style="justify-content: flex-start; margin-bottom: 15px;">
      <img class="emoji" src="../../../../assets/smile.svg">
      <div class="flex_exam">
        <div class="lbl_regular_thick">Datenqualtität</div>
        <div class="lbl_regular_green">{{interface.data_qualtity}}%</div>
        <div class="lbl_regular_italic_light_color">Letzte Prüfung: {{interface.last_check | date: 'dd.MM.yyyy'}}</div>
      </div>
    </div>
  </div>

  <div class="lbl_regular_italic_light_color">Letzte Synchronisation: {{interface.last_sync | date: 'dd.MM.yyyy'}}</div>
  <!-- <div class="btn_submit_dark">Prüfen</div> -->
</div>

