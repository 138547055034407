import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
import { VehiclePicture } from 'src/app/buisness-object/vehicle/optional/VehiclePicture';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {
  public fileOver: boolean;

  @HostBinding('class.fileover') fileover: boolean;
  @Output() fileDroppedEmitter = new EventEmitter<{data: any[]}>();
  // @Output() fileDroppedEmitter = new EventEmitter<{name: string, data: string}>();

  constructor() { }

  //Dragover listener
  @HostListener('dragover', ['$event'])onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event'])onDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  //Drop listener
  @HostListener('drop', ['$event'])public onDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = false;
    const files = e.dataTransfer.files;
    if(files.length > 0 ){
      this.processFile(files);
    }
  }

  async processFile(files: File[]) {
    const data = [];
    try {
      for(let file of files){
        const content = await this.readFileAsync(file);
        data.push({name: file.name, content: content});
      }
      data.sort((a, b) => a.name.localeCompare(b.name));
      this.fileDroppedEmitter.emit({data: data});
    } catch (error) {
      console.error(error);
    }
  }

  readFileAsync(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      }
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
}
