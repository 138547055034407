import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.css']
})
export class ResetPasswordFormComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public showSuccess = false;
  public showPassword_1 = false;
  public showPassword_2 = false;
  public submitted = false;
  public invalidToken = false;
  public tokenExpired = false;
  public routeUrl: string;
  public token: string = '';
  public routeSubscription: Subscription;

  constructor(
    private loginService: LoginService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: [null,[Validators.required, Validators.minLength(8)]],
      password_confirm: [null,[Validators.required, Validators.minLength(8)]]
    });
    this.routeUrl = this.activateRoute.snapshot.url[0].path;
    this.routeSubscription = this.activateRoute.queryParams.subscribe((params) => {
      if(!params.token){
        this.invalidToken = true;
      } else {
        this.token = params.token;
      }
    })
  }

  ngOnDestroy(): void {
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
  }

  onSubmit() {
    this.submitted = true;
    if(this.form.invalid || this.form.controls.password.value != this.form.controls.password_confirm.value){
      return;
    } else {
      this.loginService.resetPassword(this.token, this.form.controls.password.value).subscribe((response) => {
        if(response){
          this.showSuccess = true;
        } else {
          this.tokenExpired = true;
        }
      })
    }
  }

  getInvalidText(): string {
    if(!this.form.controls.password.value || !this.form.controls.password_confirm.value) return 'Eingabe fehlt.';
    else if(this.form.controls.password?.value < 8 || this.form.controls.password_confirm?.value.length < 8) return 'Passwort muss mindestens aus 8 Zeichen bestehen.';
    else if(this.form.controls.password?.value != this.form.controls.password_confirm?.value) return 'Passwörter stimmen nicht überein.';
    else return '';
  }

  goToLogin() {
    this.router.navigate(['login'])
  }
}
