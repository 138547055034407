import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Bill } from 'src/app/buisness-object/document/Bill';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';

@Component({
  selector: 'app-create-bill-document',
  templateUrl: './create-bill-document.component.html',
  styleUrls: ['./create-bill-document.component.css'],
  animations: [DropdownIconArrowAnimation]
})
export class CreateBillDocumentComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() page: number;
  @Input() customers: Customer[];
  @Input() driveTyp: number; // Über Formgroup
  @Input() selectedDoc: Bill;
  @Input() submittedBillPrice: boolean;
  @Input() position: number;
  @Input() billCreatePage: number;

  @Output() changePageEmitter = new EventEmitter<boolean>();

  public showDropdown_1 = false;
  public isEdit = false;

  @ViewChild('dropdown') dropdown: ElementRef;
  public listener: () => void;

  @Output() addPriceEmitter = new EventEmitter<undefined>();
  @Output() removePriceEmitter = new EventEmitter<number>();

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    if(this.selectedDoc){
      this.isEdit = true;
    }
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window', 'click', (e:Event) => {
      if(this.showDropdown_1 && !this.dropdown.nativeElement.contains(e.target)) this.showDropdown_1 = false;
    })
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  getTitle(typ: number): string {
    if(typ == 1) return 'Neues Angebot';
    if(typ == 2) return 'Neue Rechnung';
  }
}
