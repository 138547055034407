import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { DropdownIconArrowAnimation } from './../../../../helpers/Animations';
import { Component, Input, OnInit, Renderer2, ViewChild, ElementRef, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BaseComponent, countries } from 'src/app/view/BaseComponent';
import { formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-contract-page-one',
  templateUrl: './contract-page-one.component.html',
  styleUrls: ['./contract-page-one.component.css'],
  animations: [DropdownIconArrowAnimation]
})
export class ContractPageOneComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() customers: Customer[];
  @Input() submitted: boolean;
  @Output() formCustomerValueChangedEmitter = new EventEmitter<undefined>();

  public customerForm: UntypedFormGroup;
  public showDropdownOne = false;
  public customersFiltered: Customer[] = [];
  public selectedCustomer: Customer;
  public maxDate: Date;
  public dialogQuerySubsription: Subscription;
  public customerValues = [];
  public countries = countries;

  @ViewChild('input_customer') input_customer: ElementRef;
  public listener: () => void;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private router: Router,
    private dialogService: DialogService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    let date = new Date();
    this.maxDate = new Date(date.getFullYear() - 14, date.getMonth(), date.getDate());
    this.customersFiltered = this.customers;
    if(this.form.controls.customer.value){
      this.selectedCustomer = this.form.controls.customer.value;
      //this.fillForm(this.selectedCustomer);
    } else if(this.form.controls.customerId.value){
      for(let customer of this.customers){
        if(customer.id == this.form.controls.customerId.value){
          this.selectedCustomer = customer;
          this.form.controls.customer.setValue(customer);
          this.fillForm(this.selectedCustomer);
        }
      }
    }
    this.setDialogSubscription();
    this.customerValues = this.getDropdownValuesCustomer(this.customers);

    this.form.get('firstName').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
    this.form.get('lastName').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
    this.form.get('birthdate').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
    this.form.get('title').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
    this.form.get('salutation').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
    this.form.get('email').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
    this.form.get('phone').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
    this.form.get('street').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
    this.form.get('city').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
    this.form.get('country').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
    this.form.get('company_name').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
    this.form.get('atu').valueChanges.subscribe((c) => { this.formCustomerValueChangedEmitter.emit(); });
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    if(this.listener) this.listener;
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'go_to_customer_create') this.gotoCustomerCreate();
      }
    });
  }

  fillForm(customer: Customer) {
    this.form.controls.salutation.setValue(customer.contactPerson.salutation);
    this.form.controls.firstName.setValue(customer.contactPerson.firstName);
    this.form.controls.lastName.setValue(customer.contactPerson.lastName);
    this.form.controls.title.setValue(customer.contactPerson.title);
    this.form.controls.birthdate.setValue(this.getConvertedDateForInputFull(customer.contactPerson.birthdate));
    this.form.controls.email.setValue(customer.contactPerson.email);
    this.form.controls.phone.setValue(customer.contactPerson.phone);
    this.form.controls.street.setValue(customer.address.street);
    this.form.controls.postalCode.setValue(customer.address.postalCode);
    this.form.controls.city.setValue(customer.address.city);
    this.form.controls.country.setValue(customer.address.country);
    this.form.controls.is_company.setValue(customer.typ == 1);
    this.form.controls.company_name.setValue(customer.companyName);
    this.form.controls.atu.setValue(customer.atuNum);
    if(this.form.controls['is_company'].value == true){
      this.form.controls['company_name'].addValidators(Validators.required);
      this.form.controls['company_name'].updateValueAndValidity();
    } else {
      this.form.controls['company_name'].removeValidators(Validators.required);
      this.form.controls['company_name'].updateValueAndValidity();
    }
  }

  filterCustomers(value: string) {
    this.selectedCustomer = null;
    if(value && value.length > 0){
      this.selectedCustomer = null;
      this.form.controls.customer.setValue(null);
      this.customersFiltered = [];
      this.customersFiltered = this.customers.filter((customer) => {
        return customer.contactPerson.firstName.toLowerCase().includes(value.toLowerCase()) ||
               customer.contactPerson.lastName.toLowerCase().includes(value.toLowerCase()) ||
               (customer.contactPerson.firstName.toLowerCase() + ' ' + customer.contactPerson.lastName.toLowerCase()).includes(value.toLowerCase()) ||
               customer.companyName.toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.customersFiltered = this.customers;
    }
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'customer'){
      let index = this.customers.findIndex(obj => obj.id == id);
      if(index > -1) this.selectionCustomer(this.customers[index]);
    }
  }

  selectionCustomer(customer: Customer) {
    this.selectedCustomer = customer;
    this.form.controls.customer.setValue(customer);
    this.fillForm(customer);
  }

  isUnderAge(date: any): boolean {
    if(date && date.valueOf() >= this.maxDate.getTime()) return true;
    else return false;
  }

  goToCustomerCreateAction() {
    this.dialogService.openQuery(
      {
        title: 'Zu "Kunden anlegen" navigieren',
        message: 'Der Vorang wird abgebrochen und die Daten gehen verloren.',
        btn_cancel_txt: 'Nein',
        btn_submit_txt: 'Ja',
        typ: 'go_to_customer_create',
        submit_value: null,
      }
    );
  }
  gotoCustomerCreate() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['customers/create']));
  }

  getConvertedDateForInputFull(timestamp: number): string {
    if(timestamp){
      return formatDate(new Date(timestamp),'yyyy-MM-dd', 'de')
    }
    return null;
  }

  getTimestampFromInputDate(date: string): number {
    if(date){
      let d = new Date(date);
      return d.getTime();
    } else return null;
  }
}
