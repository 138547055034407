import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PriceSheet, Reparatur } from 'src/app/buisness-object/pricesheet/PriceSheet';
import { PriceSheetForm } from 'src/app/buisness-object/pricesheet/form/PriceSheetForm';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { PriceSheetFactory } from 'src/app/buisness-object/pricesheet/factory/PriceSheetFactory';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { VehicleForm } from 'src/app/buisness-object/vehicle/form/VehicleForm';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-price-sheet',
  templateUrl: './price-sheet.component.html',
  styleUrls: ['./price-sheet.component.css']
})
export class PriceSheetComponent implements OnInit, OnChanges {
  @Input() vehicle: Vehicle;
  @Input() customers: Customer[];
  @Input() employees: Employee[];
  @Input() surface: number;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Output() onPriceSheetChangesEmitter = new EventEmitter<undefined>();
  @Output() selectionEditSteuersatzAcceptedVorEmitter = new EventEmitter<undefined>();
  @Output() selectionEditSteuersatzAcceptedNachEmitter = new EventEmitter<undefined>();
  @Output() addReparaturVorEmitter = new EventEmitter<undefined>();
  @Output() removeReparaturVorEmitter = new EventEmitter<number>();
  @Output() addReparaturNachEmitter = new EventEmitter<undefined>();
  @Output() removeReparaturNachEmitter = new EventEmitter<number>();
  public isVorkalkulation = true;
  constructor(public authService: AuthenticationService) { }

  ngOnInit(): void {
    this.handleSurface();
  }

  ngOnChanges(change): void {

    if(change['surface']){
      this.handleSurface();
    }
  }

  handleSurface() {
    if(this.surface == 1){
      this.isVorkalkulation = true;
    } else if (this.surface == 2){
      this.isVorkalkulation = false;
    }
  }
}
