<div class="overview_container">
  <div class="data_container">
    <div class="body">
      <div class="lbl_bold_light_color">{{isEdit ? (isEdit && form.controls.offer_id.value == 0 ? 'Angebot duplizieren' :'Angebot bearbeiten') : 'Angebot erstellen'}}</div>
      <app-create-offer-page-one *ngIf="page == 1" class="body"
        [form]="form"
        [submitted]="submitted"
        [customers]="customers"
        [isEdit]="isEdit"
      ></app-create-offer-page-one>
      <app-create-offer-page-two *ngIf="page == 2" class="body"
        [form]="form"
        [submitted]="submitted"
        [submittedItem]="submittedItem"
        (addPriceEmitter)="addPriceEmitter.emit()"
        (removeEmitter)="removeEmitter.emit($event)"
      ></app-create-offer-page-two>
    </div>
    <app-create-offer-navigation
      [currentPageCreate]="page"
      [isEdit]="isEdit"
      (changePageEmitter)="changePageEmitter.emit($event)"
    ></app-create-offer-navigation>
  </div>
</div>
