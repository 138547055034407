import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-discription-container',
  templateUrl: './discription-container.component.html',
  styleUrls: ['./discription-container.component.css']
})
export class DiscriptionContainerComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() submitted: boolean;

  constructor() { }

  ngOnInit(): void {
    if(this.formGroup.controls.description.value){
      document.getElementById('textField').innerText = this.formGroup.controls.description.value;
      document.getElementById('textField_2').innerText = this.formGroup.controls.internal_description.value;
    }
  }
}
