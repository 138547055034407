<div class="dropdown_filter_container" [ngClass]="showFilter ? 'dropdown_filter_container_open' : ''">
  <div class="lbl_list_title">FILTER</div>
  <div #dropdown_1 class="dropdown_wrapper" *ngIf="tempFilter.filter != 2">
    <div class="lbl_regular_small" [ngStyle]="{'opacity': tempFilter.user ? '1' : '0'}">Ersteller</div>
    <div class="dropdown_input_header" (click)="showDropdownOne = !showDropdownOne">
      <input #input_1 class="dropdown_input" type="text" [placeholder]="!tempFilter.user ? 'Ersteller' : ''" [value]="tempFilter.user ? (tempFilter.user.firstName + ' ' + tempFilter.user.lastName) : ''" (input)="filterUser(input_1.value)">
      <img class="dropdown_input_header_icon" [ngClass]="showDropdownOne ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
    </div>
    <div class="dropdown_input_body" *ngIf="showDropdownOne">
      <div *ngFor="let user of filteredUsers" class="dropdown_data_body_element" (click)="tempFilter.user = user">{{user.firstName + ' ' + user.lastName}}</div>
      <div *ngIf="filteredUsers.length == 0" class="dropdown_data_body_element">Keine Ersteller</div>
    </div>
  </div>
  <div class="lbl_regular_small" style="margin-top: 20px;">Dringlichkeit</div>
  <div class="percent_wrapper">
    <div class="lbl_color_wrapper_green" [ngClass]="tempFilter.urgency == 1 ? 'lbl_color_wrapper_green_active' : ''" (click)="tempFilter.urgency = 1">sofort erledigen</div>
    <div class="lbl_color_wrapper_orange" [ngClass]="tempFilter.urgency == 2 ? 'lbl_color_wrapper_orange_active' : ''" (click)="tempFilter.urgency = 2">erledigen</div>
    <div class="lbl_color_wrapper_red" [ngClass]="tempFilter.urgency == 3 ? 'lbl_color_wrapper_red_active' : ''" (click)="tempFilter.urgency = 3">später erledigen</div>
  </div>
  <div class="lbl_regular_small">Typ</div>
  <div class="percent_wrapper">
    <div class="lbl_color_wrapper_cyan" [ngClass]="tempFilter.typ == 1 ? 'lbl_color_wrapper_cyan_active' : ''" (click)="tempFilter.typ = 1">Intern</div>
    <div class="lbl_color_wrapper_violet" [ngClass]="tempFilter.typ == 2 ? 'lbl_color_wrapper_violet_active' : ''" (click)="tempFilter.typ = 2">Fahrzeug</div>
    <div class="lbl_color_wrapper_brown" [ngClass]="tempFilter.typ == 3 ? 'lbl_color_wrapper_brown_active' : ''" (click)="tempFilter.typ = 3">Kunde</div>
  </div>
  <div #dropdown_2 class="dropdown_wrapper" *ngIf="tempFilter.typ == 3">
    <div class="lbl_regular_small" [ngStyle]="{'opacity': tempFilter.customer ? '1' : '0'}">Kunde</div>
    <div class="dropdown_input_header" (click)="showDropdownTwo = !showDropdownTwo">
      <input #input_2 class="dropdown_input" type="text" [placeholder]="!tempFilter.customer ? 'Kunde' : ''" [value]="tempFilter.customer ? tempFilter.customer?.getName() : ''" (input)="filterCustomer(input_2.value)">
      <img class="dropdown_input_header_icon" [ngClass]="showDropdownTwo ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
    </div>
    <div class="dropdown_input_body" *ngIf="showDropdownTwo">
      <div *ngFor="let customer of filteredCustomers" class="dropdown_data_body_element" (click)="tempFilter.customer = customer; tempFilter.vehicle = undefined">{{customer.getName()}}</div>
      <div *ngIf="filteredCustomers.length == 0" class="dropdown_data_body_element">Keine Kunden</div>
    </div>
  </div>
  <div #dropdown_3 class="dropdown_wrapper" *ngIf="tempFilter.typ == 2">
    <div class="lbl_regular_small" [ngStyle]="{'opacity': tempFilter.vehicle ? '1' : '0'}">Fahrzeug</div>
    <div class="dropdown_input_header" (click)="showDropdownThree = !showDropdownThree">
      <input #input_3 class="dropdown_input" type="text" [placeholder]="!tempFilter.vehicle ? 'Fahrzeug' : ''" [value]="tempFilter.vehicle ? tempFilter.vehicle?.getDescription(40) : ''" (input)="filterVehicle(input_3.value)">
      <img class="dropdown_input_header_icon" [ngClass]="showDropdownThree ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
    </div>
    <div class="dropdown_input_body" *ngIf="showDropdownThree">
      <div *ngFor="let vehicle of filteredVehicles" class="dropdown_data_body_element" (click)="tempFilter.vehicle = vehicle; tempFilter.customer = undefined">{{vehicle?.getDescription(32)}}</div>
      <div *ngIf="filteredVehicles.length == 0" class="dropdown_data_body_element">Keine Fahrzeuge</div>
    </div>
  </div>
  <div class="footer" [ngStyle]="{'justify-content': !filterIsSet() ? 'flex-end' : 'space-between'}">
    <div *ngIf="filterIsSet()" class="btn_menu_information" (click)="resetFilter()">Zurücksetzen</div>
    <div class="btn_submit_dark" (click)="submitFilter()">Filtern</div>
  </div>
</div>
