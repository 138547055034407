import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageLocation } from 'src/app/buisness-object/tires/StorageLocation';
import { StoragePlace } from 'src/app/buisness-object/tires/StoragePlace';

@Component({
  selector: 'app-dialog-tyres-choose-location',
  templateUrl: './dialog-tyres-choose-location.component.html',
  styleUrl: './dialog-tyres-choose-location.component.css'
})
export class DialogTyresChooseLocationComponent implements OnInit {
  @Input() locations: StorageLocation[] = [];
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() submitLocationEmitter = new EventEmitter<any>();
  public form: FormGroup;
  public placeValues = [];
  public showNoLocationText = false;

  constructor(
    private formBuilder: FormBuilder,
  ){}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      location: [this.locations[0], Validators.required],
      place: [null]
    })
    this.setPlaces();
  }

  setPlaces() {
    this.placeValues = [];
    if(this.form.get('location').value == null){
      this.showNoLocationText = true;
      return;
    }
    for(let place of this.form.get('location').value.storage_places){
      if(place.is_free) {
        this.placeValues.push({
          id: place.storage_place_id,
          name: place.place_name
        })
      }
    }
  }

  selectionLocation(location: Location) {
    this.form.get('location').setValue(location);
    this.form.get('place').setValue(null);
    this.setPlaces();
  }

  selectionPlace(place: StoragePlace){
    this.form.get('place').setValue(place);
  }

  selectionSubmit() {
    let location = this.form.get('location').value;
    this.submitLocationEmitter.emit(location);
  }
}

