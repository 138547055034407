import { DatePipe } from '@angular/common';
import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { DateHelper } from 'src/app/utils/DateHelper';
var moment = require('moment');


//https://embed.plnkr.co/HbZeiM/
/* Calendar
    Übergabe von

*/

class CalendarDay {
  constructor(
    public day: Date,
    public entries: any[],
    public is_today?: boolean,
  ){}
}


interface Day {
  day: Date,
  entries: Entry[],
  is_today?: boolean,
  z_index?: number
}
interface Entry {
  id: number,
  start_time: number,
  end_time: number,
  position: EntryPosition,
  is_new: boolean,
  subject: string,
  is_customer: boolean,
}
interface EntryPosition {
  column: number,
  row: number,
  row_span: number,
  width: number,
  z_index: number
}

      // id: entrie.test_drive_id,
      // start_time: entrie.start_time,
      // end_time: entrie.end_time,
      // position: {
      //   column: 1,
      //   row: row,
      //   row_span: rowSpan
      // },
      // is_new: entrie.is_new,
      // subject: entrie.subject

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})

export class CalendarComponent implements OnInit, AfterContentChecked, AfterViewInit, OnChanges {
  @Input() entries: any[];
  @Input() showTimeValue: boolean;
  @Input() showWeek: boolean;
  @Input() disabled: boolean;
  @Input() isCreate: boolean;
  @Input() form: UntypedFormGroup;
  @Input() tempScreenHeight: number;
  @Input() headerHeight: number;
  @Input() plate: string;
  @Output() selectionEntryEmitter = new EventEmitter<any>();
  @Output() newEntrieTimeEmitter = new EventEmitter<any>();

  public filledCalenderWeek: Day[] = [];


  public start_time = 8;                    //Start time of day (08:00)
  public end_time = 20;                     //End time of day (20:00)
  public minimum_min = 15;                  //Minmin of avaiable space of an entrie
  //public entrie_start_position = 8 - 476; //Top start value of day (where entrie beginn at height of start_time) in px
  public entrie_start_position = 8 + 476;   //Top start value of day (where entrie beginn at height of start_time) in px
  public height_row = 36;                   //Height of row (hours) in px + 1px border-bottom
  public border_thickness = 2;              //Border of entrie (upper + lower)
  public day_header_height =  49;           //Day height - border (1px)
  public calender_pos_top = 0;
  public screenHeight = 0;

  public grid_max_column = 3;
  public grid_entries: any[] = [];

  public today = new Date();
  public calendarWeek: CalendarDay[] = [];
  public weekNumber = 0;
  public hours: number[] = [];

  public showTimePicker = false;
  public selectedEntrieDay: CalendarDay;
  //public selectedDate = moment().toDate();
  public minDate;
  public maxEntrieRow = 3;
  public amoutnOfEntryColumns = 8;

  public columnWidth: number;
  public columnHeight: number;

  public timePickerError = false;
  public timePickerHour = 8;

  public newAddedEntry: Drive;
  public showEntries = false;
  public isBuild = false;

  public calenderIsRendered = false;

  public screenSize;
  public firstInit = true;

  constructor(
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.handleScreenSize();
    if(this.isCreate) this.minDate = new Date();
    this.filledCalenderWeek = this.buildWeek(this.today.getDay());
    this.getHours();
    if(this.showWeek){
      for(let day of this.filledCalenderWeek){
        if(day.is_today){
          this.selectedEntrieDay = day;
          break;
        }
      }
    }
  }

  ngAfterContentChecked(): void {
    if(this.showWeek && this.calenderIsRendered){
      this.setHTMLElementsValue();
      this.createGridCalenderday();
    }
  }

  ngAfterViewInit(): void {
    const dateInput = document.getElementById('input-date') as HTMLInputElement;
    if(dateInput) {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const todayString = `${year}-${month}-${day}`;
      dateInput.value = todayString;
    }
    if(this.isCreate){
      this.setHTMLElementsValue();
    }
    this.calenderIsRendered = true;
    this.setHTMLElementsValue();
    setTimeout(() => {
      this.createGridCalenderday();
    }, 100);
  }

  handleScreenSize() {
    this.screenSize = window.innerWidth;
    if(this.screenSize <= 1420){
      this.day_header_height = 34;
    } else {
      this.day_header_height = 49;
    }
    const calendarHEader = document.getElementById('calendar-header');
    if(calendarHEader){
      this.headerHeight = calendarHEader.offsetHeight;
    }
    // const calendar = document.getElementById('calendar');
    // if(calendar){
    //   this.screenHeight = calendar.offsetHeight - this.day_header_height - this.headerHeight;
    // }
    //this.screenHeight = this.screenHeight - this.day_header_height - this.headerHeight;
  }

  onScreenSizeChange() {
    this.handleScreenSize();
    this.setHTMLElementsValue();
  }

  setHTMLElementsValue() {
    //let screenHeight = window.innerHeight;
    // screenHeight = (screenHeight / (this.isCreate ? 2.2 : 1.7)) - this.day_header_height;
    // const calendar = document.getElementById('calendar');
    // const header = document.getElementById('calendar-header');
    // if(calendar && header){
    //   if(this.firstInit){
    //     if(!this.headerHeight) this.headerHeight = header.offsetHeight;
    //     this.screenHeight = calendar.offsetHeight - this.day_header_height - this.headerHeight;
    //     this.firstInit = false;
    //   }
    // }
    this.screenHeight = this.tempScreenHeight - this.day_header_height - this.headerHeight;
    this.height_row = this.screenHeight / ((this.end_time - this.start_time) + 1);
    let kw_container = document.getElementById('kw_container');
    if(kw_container){
      kw_container.style.height = this.day_header_height+'px';
    }
    let hourRows = document.querySelectorAll('.hour_row');
    if(hourRows){
      for(let i = 0; i < hourRows.length; i++){
        let r = hourRows[i] as HTMLElement;
        r.style.height = this.height_row+'px';
      }
    }
    let dayHeaders = document.querySelectorAll('.day_header');
    if(dayHeaders){
      for(let i = 0; i < dayHeaders.length; i++){
        let r = dayHeaders[i] as HTMLElement;
        r.style.height = this.day_header_height+'px';
      }
    }
    let hour_row_day = document.querySelectorAll('.hour_row_day');
    if(hour_row_day){
      for(let i = 0; i < hour_row_day.length; i++){
        let r = hour_row_day[i] as HTMLElement;
        r.style.height = this.height_row+'px';
      }
    }
    let dayColumns = document.querySelectorAll('.day_column');
    if(dayColumns){
      for(let i = 0; i < dayColumns.length; i++){
        let c = dayColumns[i] as HTMLElement;
        c.style.height = this.screenHeight+'px';
      }
    }
    let grid_entries = document.querySelectorAll('.grid_entries');
    if(grid_entries){
      for(let i = 0; i < grid_entries.length; i++){
        let c = grid_entries[i] as HTMLElement;
        c.style.top = this.day_header_height+'px';
      }
    }
  }

  createGridCalenderday(): Promise<void> {
    return new Promise<any>((resolve, reject) => {
      let column = document.getElementsByClassName('title_day_column');
      if(column){
        this.columnWidth = column[0].clientWidth;
        this.columnHeight = column[0].clientHeight;
      }
      let calendar = document.getElementById('calendar');
      let calendar_header = document.getElementById('calendar_header');
      this.calender_pos_top = calendar.offsetTop + calendar_header.offsetHeight + this.day_header_height - 1;
      let grids = document.getElementsByClassName('grid_entries');
      let day_header = document.getElementsByClassName('day_header')[0];
      for(let i = 0; i < grids.length; i++){
        let element = grids[i] as HTMLElement;
        element.style.gridTemplateRows = 'repeat('+((this.end_time - this.start_time + 1)*4)+','+((this.height_row + 1) / 4)+'px)';
        element.style.top = calendar_header.offsetHeight + day_header.clientHeight  + 'px';
        element.style.width = (this.columnWidth - 30) + 'px';
      }
      this.isBuild = true;
      resolve(true);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['entries']){
      this.filledCalenderWeek = this.buildWeek(this.today.getDay());
    }
  }

  isPastDate(date: Date, time?: number): boolean {
    if(date){
      let today = this.today;
      date.setHours(0,0,0,0);
      today.setHours(0,0,0,0);
      if(date.getTime() < today.getTime()){
        return true;
      } else if(date.getTime() > today.getTime()) {
        return false;
      } else if(date.getTime() == today.getTime() && time) {
        today = new Date();
        if(time <= today.getHours()){
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  buildWeek(weekDay: number, date?: Date): Day[] {
    let week = this.buildPreviousWeekDays(weekDay, date ? date : this.today).concat(this.buildNextWeekDays(weekDay, date ? date : this.today));
    this.weekNumber = this.getWeek(date ? date : this.today);
    if(this.entries) this.sortEntries();
    else this.entries = [];
    week = this.buildWeekEntries(week);
    return week;
  }


  getDayEntrieByDate(date: Date): any {
    for(let day of this.calendarWeek){
      if(day.day.getDate() == date.getDate() && day.day.getMonth() == date.getMonth() && day.day.getFullYear() == date.getFullYear()){
        day.is_today = true;
        return day;
      }
    }
    return null;
  }

  sortEntries() {
    this.entries = this.entries.sort((a,b) => {
      if(a.start_time > b.start_time) return 1;
      if(a.start_time < b.start_time) return -1;
      return;
    })
  }

  formGridEntries() {
    for(let c of this.calendarWeek){
      c.entries = [];
    }
    for(let entrie of this.entries){
      for(let i = 0; i < this.calendarWeek.length; i++){
        let entrieStartTime = new Date(entrie.start_time);
        if(entrieStartTime.getFullYear() == this.calendarWeek[i].day.getFullYear() &&
           entrieStartTime.getMonth() == this.calendarWeek[i].day.getMonth() &&
           entrieStartTime.getDate() == this.calendarWeek[i].day.getDate()){
            let column = this.getEntrieColumn(this.calendarWeek[i], entrie);
            let row = this.getEntrieRow(entrie);
            let rowSpan = this.getEntrieRowSpan(entrie);
            this.createGridEntrie(i, entrie, column, row, rowSpan + row);
          }
      }
    }
  }

  buildWeekEntries(week: Day[]): Day[] {
    for(let day of week){
      day.entries = [];
    }
    let z_entrie_index = 0;
    for(let entrie of this.entries){
      z_entrie_index++;
      let z_day_index = week.length;
      for(let i = 0; i < week.length; i++){
        z_day_index--;
        week[i].z_index = z_day_index*100;
        let entrieStartTime = new Date(entrie.start_time);
        if(entrieStartTime.getFullYear() == week[i].day.getFullYear() &&
           entrieStartTime.getMonth() == week[i].day.getMonth() &&
           entrieStartTime.getDate() == week[i].day.getDate()){
            let column = this.getEntrieColumn(week[i], entrie);
            let row = this.getEntrieRow(entrie);
            let rowSpan = this.getEntrieRowSpan(entrie);
            let width = 3;
            week[i].entries.push(this.creatVisualEntrie(entrie, column, row, rowSpan + row, width, z_entrie_index));
          }
      }
    }
    return week;
  }
  creatVisualEntrie(drive: any, column: number, row: number, rowSpan: number, width: number, index: number): Entry {
    const position: EntryPosition = {
      column: column,
      row: row,
      row_span: rowSpan,
      width: 1,
      z_index: index * 100
    }
    let subject = drive.customer ? drive.customer.getName() : 'Mitarbeiter';
    const entry: Entry = {
      id: drive.test_drive_id,
      start_time: drive.start_time,
      end_time: drive.end_time,
      position: position,
      is_new: drive.is_new,
      subject: subject,
      is_customer: drive.customer != null,
    }
    return entry;
  }

  isTimeInTime(oneStart: number, oneEnd: number, twoStart: number, twoEnd: number): boolean {
    if(oneStart <= twoStart && oneEnd >= twoEnd) return true;
    else return false;
  }
  isTimeInTimeAndOver(oneStart: number, oneEnd: number, twoStart: number, twoEnd: number): boolean {
    if(oneStart <= twoStart && oneEnd <= twoEnd) return true;
    else return false;
  }
  isTimeInTimeAndUnder(oneStart: number, oneEnd: number, twoStart: number, twoEnd: number): boolean {
    if(oneStart >= twoStart && oneEnd >= twoEnd) return true;
    else return false;
  }
  noOverlapseTime(oneEnd: number, twoStart: number): boolean {
    if(oneEnd <= twoStart) return true;
    else return false;
  }

  getEntrieColumn(calenderDay: CalendarDay, entrie: any): number {
    let amountColumns = 1;
    let tempColumn = 1;
    for(let i = (calenderDay.entries.length - 1); i >= 0; i--){
      if(!this.noOverlapseTime(calenderDay.entries[i].end_time, entrie.start_time)){
        //if overlapse
        tempColumn = calenderDay.entries[i].position.column + 1;
        let posPossible = false;
        for(let j = i; i >= 0; i--){
          if(!this.noOverlapseTime(calenderDay.entries[j].end_time, entrie.start_time) && calenderDay.entries[j].position.column == (tempColumn-1)){
            //if overlapse
            if(tempColumn == 1){
              break;
            } else {
              tempColumn--;
              posPossible = false;
            }
          } else {
            tempColumn = calenderDay.entries[j].position.column;
            posPossible = true;
            break;
          }
        }
        if(!posPossible) tempColumn--;
        break;
      }
    }
    amountColumns = tempColumn;
    return amountColumns;


  }

  getEntrieRow(entrie: any): number {
    let hour = new Date(entrie.start_time).getHours() - 8; //8 Start hour
    let mins = new Date(entrie.start_time).getMinutes();
    let units = (hour * 4) + (mins / 15);
    return units;
  }

  getEntrieRowSpan(entrie: any): number {
    let start = new Date(entrie.start_time);
    let startTimeInMin = (start.getHours() * 60) + start.getMinutes();
    let end = new Date(entrie.end_time);
    let endTimeInMin = (end.getHours() * 60) + end.getMinutes();
    let currency = endTimeInMin - startTimeInMin;
    let units = Math.round(currency/this.minimum_min);
    return units + 1;
  }

  createGridEntrie(index: number, entrie: any, column: number, row: number, rowSpan: number) {
    this.calendarWeek[index].entries.push({
      id: entrie.test_drive_id,
      start_time: entrie.start_time,
      end_time: entrie.end_time,
      position: {
        column: 1,
        row: row,
        row_span: rowSpan
      },
      is_new: entrie.is_new,
      subject: entrie.subject
    });
  }

  renderEntries() {
    for(let c of this.calendarWeek){
      c.entries = [];
    }
    for(let entrie of this.entries){
      for(let i = 0; i < this.calendarWeek.length; i++){
        let entrieStartTime = new Date(entrie.start_time);
        if(this.datesSameDay(entrieStartTime, this.today)){

        }
      }
    }
  }

  datesSameDay(date_1: Date, date_2: Date): boolean {
    if(date_1.getFullYear() == date_2.getFullYear() &&
      date_1.getMonth() == date_2.getMonth() &&
      date_1.getDate() == date_2.getDate()){
        return true;
    }
    return false;
  }

  isFreePlace(calendarDay: CalendarDay, hour: number): boolean {
    for(let entry of this.entries){
      let entrieStartDate = new Date(entry.start_time);
      let entrieEndDate = new Date(entry.end_time);
      if(this.datesSameDay(entrieEndDate, calendarDay.day)){
        if(hour >= entrieStartDate.getHours() && hour < entrieEndDate.getHours()){
          return false;
        }
      }
    }
    return true;
  }

  checkRowCounter(entrie: any, index: number, place: number, width_typ: number, amountOfEntrie: number) {
    let heightOccupied = 0;
    for(let e of this.calendarWeek[index].entries){
      if(e.position.place == place){
        heightOccupied += e.position.height;
      }
    }
    if(heightOccupied < this.getTopPosition(entrie.start_time)){
      this.createEntrie(index, entrie, place, width_typ, amountOfEntrie);
    } else {
    }
  }

  checkIfAnyEntrieOverlays(entrie: any, index: number, amountOfEntrie: number): boolean {
    for(let e of this.calendarWeek[index].entries){
      if(e.position.place > 1 && e.end_time >= entrie.start_time){
        if(e.position.place < 4){
          this.createEntrie(index, entrie, 2, e.position.place + 1, amountOfEntrie);
        }
        return true;
      }
    }
    return false;
  }

  createEntrie(index: number, entrie: any, withTyp: number, place: number, amountOfEntrie: number) {
    this.calendarWeek[index].entries.push({
      id: entrie.id,
      start_time: entrie.start_time,
      end_time: entrie.end_time,
      position: {
        width_typ: withTyp,
        place: place,
        height: this.getEntrieHeight(entrie.start_time, entrie.end_time)
      },
      amount_of_entries: amountOfEntrie,
      is_new: entrie.is_new,
      subject: entrie.subject
    });
  }

  getEntrieWidth(entrie: any): string {
    let width = (this.columnWidth / entrie.position.width_typ) - 4;
    if(!isNaN(width)) return width + 'px';
    return '0px';
  }

  getEntrieWidthDayView(entrie: any): string {
    //return '100%'
    return 'calc(78.6% / '+entrie.position.width_typ+')';
  }

  getEntrieTransform(entrie: any): string {
    return 'translate('+(this.columnWidth / entrie.position.width_typ) * (entrie.position.place - 1)+'px, 0px)';
  }

  getEntrieTransformDayView(entrie: any): string {
    return 'translate('+(100 * (entrie.position.place - 1))+'%, 0px)';
  }

  async changeWeek(dateString: any) {
    let date = new Date(DateHelper.convertDateStringToTimestamp(dateString));
    this.isBuild = false;
    let weekDay = date.getDay();
    this.filledCalenderWeek = this.buildWeek(weekDay, date);
    setTimeout(() => {
      this.createGridCalenderday();
    }, 50);
  }


  buildPreviousWeekDays(dayOfWeek: number, date: Date): Day[] {
    let previousDaysOfWeek: Day[] = [];
    dayOfWeek = dayOfWeek == 0 ? 7 : dayOfWeek;
    if(dayOfWeek > 0){
      for(let i = (dayOfWeek - 1); i >= 0; i--){
        let d = date ? new Date(date.getTime()) : new Date();
        d.setDate(d.getDate() - i);
        let isToday = this.datesSameDay(d,this.today);
        const day: Day = {
          day: d,
          entries: [],
          is_today: isToday
        }
        previousDaysOfWeek.push(day);
      }
    }
    return previousDaysOfWeek;
  }
  buildNextWeekDays(dayOfWeek: number, date: Date): Day[] {
    let nextDaysOfWeek: Day[] = [];
    dayOfWeek = dayOfWeek == 0 ? 7 : dayOfWeek;
    let diff = 7 - dayOfWeek;
    if(diff <= 7){
      for(let i = 1; i <= diff; i++){
        let d = date ? new Date(date.getTime()) : new Date();
        d.setDate(d.getDate() + i);
        let isToday = this.datesSameDay(d,this.today);
        const day: Day = {
          day: d,
          entries: [],
          is_today: isToday
        }
        nextDaysOfWeek.push(day);
      }
    }
    return nextDaysOfWeek;
  }

  getWeek(date: Date): number {
    let onejan = new Date(date.getFullYear(), 0, 1);
    let week = Math.ceil((((date.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
    return week;
  }

  getHours() {
    for(let i = this.start_time; i <= this.end_time; i++){
      this.hours.push(i);
    }
  }

  getTopPosition(startTime: number, index?: number): number {
    let hour = new Date(startTime).getHours();
    let mins = new Date(startTime).getMinutes();
    if(hour >= this.start_time){
      let multiplicater = hour - this.start_time;
      let pixelHour = this.height_row * multiplicater;
      let pixelMin = ((mins * 100) / 60) * (this.height_row / 100);
      let top = pixelHour + pixelMin + this.calender_pos_top;
      return top
    }
    return (this.entrie_start_position + this.calender_pos_top);
  }

  getPositionStart(startTime: number, index: number): string {
    let hour = new Date(startTime).getHours();
    let mins = new Date(startTime).getMinutes();
    if(hour >= this.start_time){
      let multiplicater = hour - this.start_time;
      let pixelHour = this.height_row * multiplicater;
      let pixelMin = ((mins * 100) / 60) * (this.height_row / 100);
      let top = pixelHour + pixelMin + this.entrie_start_position;
      if(index != 0){
        let previousTop = 0;
        for(let i = (index-1); i >= 0; i--){
          let previousEntrie = document.getElementById('entrie_'+(index-1));
          let style = previousEntrie.style.cssText.split(' ');
          let value = Number(style[style.length -1].slice(0,-3));
          previousTop += value + this.border_thickness;
        }
        top = top - previousTop;
        return top + 'px';
      } else {
        return top + 'px';
      }
    }
    return this.entrie_start_position + 'px'
  }

  getEntrieHeight(startTime: number, endTime: number): number {
    let start = new Date(startTime);
    let startTimeInMin = (start.getHours() * 60) + start.getMinutes();
    let end = new Date(endTime);
    let endTimeInMin = (end.getHours() * 60) + end.getMinutes();
    let currency = endTimeInMin - startTimeInMin;
    let units = Math.round(currency/this.minimum_min);
    let height = ((this.height_row / 4) * units) - this.border_thickness;
    return height;
  }

  async nextWeek(){
    this.isBuild = false;
    let lastWeekDay = this.filledCalenderWeek[this.filledCalenderWeek.length - 1].day;
    this.filledCalenderWeek = [];
    for(let i = 1; i <= 7; i++){
      let d = new Date(lastWeekDay.getTime() + ((24 * 60 * 60 * 1000) * i));
      let isToday = this.datesSameDay(d,this.today);
      const day: Day = {
        day: d,
        entries: [],
        is_today: isToday
      }
      this.filledCalenderWeek.push(day);
    }
    this.weekNumber = this.getWeek(this.filledCalenderWeek[0].day);
    this.filledCalenderWeek = this.buildWeekEntries(this.filledCalenderWeek);
    setTimeout(() => {
      this.createGridCalenderday();
    }, 50);
  }

  async previousWeek(){
    this.isBuild = false;
    let firstWeekDay = this.filledCalenderWeek[0].day;
    this.filledCalenderWeek = [];
    for(let i = 7; i >= 1; i--){
      let d = new Date(firstWeekDay.getTime() - ((24 * 60 * 60 * 1000) * i));
      let isToday = this.datesSameDay(d,this.today);
      const day: Day = {
        day: d,
        entries: [],
        is_today: isToday
      }
      this.filledCalenderWeek.push(day);
    }
    this.weekNumber = this.getWeek(this.filledCalenderWeek[0].day);
    this.filledCalenderWeek = this.buildWeekEntries(this.filledCalenderWeek);
    setTimeout(() => {
      this.createGridCalenderday();
    }, 50);
  }


  heightAvailable(entrie: any): boolean {
    let height = this.getEntrieHeight(entrie.start_time, entrie.end_time);
    return height > 26;
  }

  isOdd(num: number): boolean {
    return (num % 2) == 0;
  }

  addEntrie(calenderDay: CalendarDay, hour: number) {
    this.selectedEntrieDay = calenderDay;
    this.timePickerHour = hour;
    this.showTimePicker = true;
  }

  cancelPicker() {
    this.selectedEntrieDay = null;
    this.showTimePicker = false;
  }

  submitTimePicker(result: any) {
    let entry = new Drive(
      0,
      1,
      result.start_time,
      result.end_time - result.start_time,
      this.form.controls.vehicle.value.id,
      0,
      1, //plate
      [],
      result.start_time,
      result.end_time,
      ''
    )
    entry.is_new = true;
    if(this.newAddedEntry){
      let index = this.entries.findIndex(e => e.test_drive_id == this.newAddedEntry.test_drive_id);
      if(index > -1){
        this.entries.splice(index, 1);
        this.newAddedEntry = null;
      }
      let index2 = this.entries.findIndex(e => e.test_drive_id == 0);
      if(index2 > -1){
        this.entries.splice(index2, 1);
      }
    }
    this.newAddedEntry = entry;
    this.form.controls.start_time.setValue(this.newAddedEntry.start_time);
    this.form.controls.end_time.setValue(this.newAddedEntry.end_time);
    this.entries.push(entry);
    this.sortEntries();
    this.filledCalenderWeek = this.buildWeekEntries(this.filledCalenderWeek);
    this.timePickerError = false;
    this.showTimePicker = false;
    // if(!this.pickedTimeIsPast(entry.start_time) && !this.pickedTimeOverlays(entry)){
    //   if(this.newAddedEntry){
    //     let index = this.entries.findIndex(e => e.test_drive_id == this.newAddedEntry.test_drive_id);
    //     if(index > -1){
    //       this.entries.splice(index, 1);
    //       this.newAddedEntry = null;
    //     }
    //     let index2 = this.entries.findIndex(e => e.test_drive_id == 0);
    //     if(index2 > -1){
    //       this.entries.splice(index2, 1);
    //     }
    //   }
    //   this.newAddedEntry = entry;
    //   this.form.controls.start_time.setValue(this.newAddedEntry.start_time);
    //   this.form.controls.end_time.setValue(this.newAddedEntry.end_time);
    //   this.entries.push(entry);
    //   this.sortEntries();
    //   this.filledCalenderWeek = this.buildWeekEntries(this.filledCalenderWeek);
    //   this.timePickerError = false;
    //   this.showTimePicker = false;
    // } else {
    //   this.timePickerError = true;
    // }
  }

  pickedTimeIsPast(time: number): boolean {
    if(new Date(time).getTime() <= new Date().getTime()){
      return true;
    }
    return false;
  }

  pickedTimeOverlays(entry: Drive): boolean {
    for(let drive of this.entries){
      if(this.datesSameDay(new Date(entry.start_time), new Date(drive.start_time))){
        if(entry.start_time > drive.start_time && entry.end_time > drive.end_time){
          return false;
        } else if(entry.start_time < drive.start_time && entry.end_time < drive.end_time){
          return false;
        } else if(entry.end_time >= drive.start_time){
          return true;
        } else if(entry.start_time <= drive.end_time && entry.start_time >= drive.startTime){
          return true;
        }
      }
    }
    return false;
  }

  removeEntrie(entry: Drive, index_day: number) {
    let i = this.calendarWeek[index_day]?.entries.findIndex(e => e.test_drive_id == entry.test_drive_id);
    if(i > -1) this.calendarWeek[index_day].entries.splice(i,1)
    let j = this.entries.findIndex(e => e.test_drive_id == entry.test_drive_id);
    if(j > -1) this.entries.splice(j,1)
    //TODO: Löschvorgang
    this.sortEntries();
    this.formGridEntries();
  }

  getEntrieLbl(entrie: any): string { //{{showTimeValue ? (entrie.position.width_typ == 1 ? ((entrie.start_time | date: 'HH:mm')+'-'+(entrie.end_time | date: 'HH:mm')) : (entrie.position.place == 4 ? '+1' : '')) : (entrie.position.width_typ == 1 ? entrie.subject : '')}}
    if(this.showTimeValue){
      if(entrie.position.width_typ == 1) return this.datePipe.transform(entrie.start_time, 'HH:mm') + '-' + this.datePipe.transform(entrie.end_time, 'HH:mm');
      else if(entrie.position.place == 3) return '+'+ entrie.amount_of_entries;
      else return entrie.subject.slice(0,entrie.position.width_typ == 2 ? 10 : 4) + '...';
    } else {
      if(entrie.position.width_typ == 1) return entrie.subject;
      else if(entrie.position.place == 3) return '+' + entrie.amount_of_entries;
      else return entrie.subject.slice(0,entrie.position.width_typ == 2 ? 10 : 4) + '...';
    }
  }


  selectionEntry(entry: any) {
    this.selectionEntryEmitter.emit(entry);
  }
}



 // for(let i = 0; i < calenderDay.entries.length; i++){
    //   if(this.isTimeInTime(calenderDay.entries[i].start_time, calenderDay.entries[i].end_time, entrie.start_time, entrie.end_time)){
    //     // 1)
    //     if(i != 0 && calenderDay.entries[i].position.column == 1) {
    //       let tempColumn = 2;
    //       for(let j = i; j >= 0; j--){
    //         if(!this.noOverlapseTime(calenderDay.entries[j].end_time, entrie.start_time)){
    //           tempColumn = calenderDay.entries[j].position.column + 1;
    //         }
    //       }
    //       amountColumns = tempColumn;
    //     } else if(i != 0) {
    //       amountColumns = calenderDay.entries[i].position.column + 1;
    //       for(let j = i; j >= 0; j--){
    //         if(this.noOverlapseTime(calenderDay.entries[j].end_time, entrie.start_time)){
    //           amountColumns = calenderDay.entries[j].position.column;
    //           break;
    //         }
    //       }
    //     }
    //   } else if(this.isTimeInTimeAndOver(calenderDay.entries[i].start_time, calenderDay.entries[i].end_time, entrie.start_time, entrie.end_time)){
    //     // 2)
    //     if(i != 0 && calenderDay.entries[i].position.column == 1) {
    //       let tempColumn = 2;
    //       for(let j = i; j >= 0; j--){
    //         if(!this.noOverlapseTime(calenderDay.entries[j].end_time, entrie.start_time)){
    //           tempColumn = calenderDay.entries[j].position.column + 1;
    //         }
    //       }
    //       amountColumns = tempColumn;
    //     } else if(i != 0) {
    //       amountColumns = calenderDay.entries[i].position.column + 1;
    //       let possiblePlace;
    //       for(let j = i; j >= 0; j--){
    //         if(entrie.test_drive_id == 77){
    //         }
    //         if(this.noOverlapseTime(calenderDay.entries[j].end_time, entrie.start_time)){
    //           possiblePlace = calenderDay.entries[j].position.column;
    //           if(entrie.test_drive_id == 77){
    //           }
    //         }
    //       }

    //       if(possiblePlace) amountColumns = possiblePlace;
    //     }
    //   } else if(this.isTimeInTimeAndUnder(calenderDay.entries[i].start_time, calenderDay.entries[i].end_time, entrie.start_time, entrie.end_time)){
    //     // 3)
    //     if(i != 0 && calenderDay.entries[i].position.column == 1) {
    //       let tempColumn = 2;
    //       for(let j = i; j >= 0; j--){
    //         if(!this.noOverlapseTime(calenderDay.entries[j].end_time, entrie.start_time)){
    //           tempColumn = calenderDay.entries[j].position.column  + 1;
    //         }
    //       }
    //       amountColumns = tempColumn;
    //     } else if(i != 0) {
    //       amountColumns = calenderDay.entries[i].position.column + 1;
    //       for(let j = i; j >= 0; j--){
    //         if(this.noOverlapseTime(calenderDay.entries[j].end_time, entrie.start_time)){
    //           amountColumns = calenderDay.entries[i].position.column;
    //           break;
    //         }
    //       }
    //     }
    //   }
    // }

    // for(let e of calenderDay.entries){
    //   if(e.start_time <= entrie.start_time && e.end_time >= entrie.end_time){ //
    //     if(e.position.column != this.amoutnOfEntryColumns){

    //       amountColumns = e.position.column + 1;
    //     }
    //     else amountColumns = this.amoutnOfEntryColumns;
    //   }
    //   else if(e.start_time <= entrie.start_time && e.end_time >= entrie.start_time){
    //     if(e.position.column != this.amoutnOfEntryColumns) amountColumns = e.position.column + 1;
    //     else amountColumns = this.amoutnOfEntryColumns;
    //   }
    //   else if(e.start_time >= entrie.start_time && e.end_time >= entrie.end_time){
    //     if(e.position.column != this.amoutnOfEntryColumns) amountColumns = e.position.column + 1;
    //     else amountColumns = this.amoutnOfEntryColumns;
    //   }
    //   if(amountColumns == this.amoutnOfEntryColumns){ break}
    // }
    // return amountColumns;

    //alt
    // let amountColumns = 1;
    // for(let e of calenderDay.entries){
    //   if(e.start_time <= entrie.start_time && e.end_time >= entrie.end_time){
    //     if(e.position.column != 4) amountColumns = e.position.column + 1;
    //     else amountColumns = 4;
    //   }
    //   else if(e.start_time <= entrie.start_time && e.end_time >= entrie.start_time){
    //     if(e.position.column != 4) amountColumns = e.position.column + 1;
    //     else amountColumns = 4;
    //   }
    //   else if(e.start_time >= entrie.start_time && e.end_time >= entrie.end_time){
    //     if(e.position.column != 4) amountColumns = e.position.column + 1;
    //     else amountColumns = 4;
    //   }
    //   if(amountColumns == 4){ break}
    // }
    // return amountColumns;



// @Input() entries: any[];
//   @Input() showTimeValue: boolean;
//   @Input() showWeek: boolean;

//   public start_time = 8;                    //Start time of day (08:00)
//   public end_time = 20;                     //End time of day (20:00)
//   public minimum_min = 15;                  //Minmin of avaiable space of an entrie
//   //public entrie_start_position = 8 - 476;   //Top start value of day (where entrie beginn at height of start_time) in px
//   public entrie_start_position = 8 + 476;   //Top start value of day (where entrie beginn at height of start_time) in px
//   public height_row = 30;                   //Height of row (hours) in px
//   public border_thickness = 2;              //Border of entrie (upper + lower)
//   public day_header_height =  49;
//   public calender_pos_top = 0;

//   public grid_max_column = 3;
//   public grid_entries: any[] = [];

//   public today = new Date();
//   public calendarWeek: CalendarDay[] = [];
//   public weekNumber = 0;
//   public hours: number[] = [];

//   public showTimePicker = false;
//   public selectedEntrieDay: CalendarDay;
//   public selectedDate = moment().toDate();
//   public minDate = new Date();
//   public maxEntrieRow = 3;

//   public columnWidth: number;
//   public columnHeight: number;

//   @Output() newEntrieTimeEmitter = new EventEmitter<any>();

//   constructor(
//     private datePipe: DatePipe
//   ) { }

//   ngOnInit(): void {
//     this.getCurrentWeek();
//     this.getHours();
//     this.selectedEntrieDay = this.getDayEntrieByDate(this.today);
//   }

//   ngAfterContentChecked(): void {

//   }

//   ngAfterViewInit(): void {
//     let column = document.getElementsByClassName('title_day_column');
//     if(column){
//       this.columnWidth = column[0].clientWidth;
//       this.columnHeight = column[0].clientHeight;
//     }
//     let calendar = document.getElementById('calendar');
//     let calendar_header = document.getElementById('calendar_header');
//     this.calender_pos_top = calendar.offsetTop + calendar_header.offsetHeight + this.day_header_height -1;
//     let grids = document.getElementsByClassName('grid_entries');
//     let title = document.getElementsByClassName('day_header')[0];
//     if(title) this.day_header_height = title.clientHeight;
//     // let body = document.getElementsByClassName('body')[0];
//     for(let i = 0; i < grids.length; i++){
//       let element = grids[i] as HTMLElement;
//       element.style.gridTemplateRows = 'repeat('+((this.end_time - this.start_time)*4 + 4)+','+(36 / 4)+'px)';
//       element.style.height = this.columnHeight - this.day_header_height + 'px';
//       element.style.top = 348 + 'px';
//       element.style.width = this.columnWidth + 'px';
//     }
//   }

//   ngOnChanges(changes: SimpleChanges): void {
//   }

//   getCurrentWeek() {
//     let currentDay = this.today.getDay();
//     this.calendarWeek = this.getPreviousWeekDays(currentDay).concat(this.getNextWeekDays(currentDay))
//     this.weekNumber = this.getWeek(this.today);
//     this.sortEntries();
//     //this.formWeekEntries();
//     this.formGridEntries();
//   }

//   getDayEntrieByDate(date: Date): any {
//     for(let day of this.calendarWeek){
//       if(day.day.getDate() == date.getDate() && day.day.getMonth() == date.getMonth() && day.day.getFullYear() == date.getFullYear()){
//         return day;
//       }
//     }
//     return null;
//   }

//   sortEntries() {
//     this.entries = this.entries.sort((a,b) => {
//       if(a.start_time > b.start_time) return 1;
//       if(a.start_time < b.start_time) return -1;
//       return;
//     })
//   }

//   formGridEntries() {
//     for(let c of this.calendarWeek){
//       c.entries = [];
//     }
//     for(let entrie of this.entries){
//       for(let i = 0; i < this.calendarWeek.length; i++){
//         let entrieStartTime = new Date(entrie.start_time);
//         if(entrieStartTime.getFullYear() == this.calendarWeek[i].day.getFullYear() &&
//            entrieStartTime.getMonth() == this.calendarWeek[i].day.getMonth() &&
//            entrieStartTime.getDate() == this.calendarWeek[i].day.getDate()){
//             let column = this.getEntrieColumn(this.calendarWeek[i], entrie);
//             let row = this.getEntrieRow(entrie);
//             let rowSpan = this.getEntrieRowSpan(entrie);
//             this.createGridEntrie(i, entrie, column, row, rowSpan + row);
//           }
//       }
//     }
//   }

//   getEntrieColumn(calenderDay: CalendarDay, entrie: any): number {
//     let amountColumns = 1;
//     for(let e of calenderDay.entries){
//       if(e.start_time <= entrie.start_time && e.end_time >= entrie.end_time){
//         if(e.position.column != 4) amountColumns = e.position.column + 1;
//         else amountColumns = 4;
//       }
//       else if(e.start_time <= entrie.start_time && e.end_time >= entrie.start_time){
//         if(e.position.column != 4) amountColumns = e.position.column + 1;
//         else amountColumns = 4;
//       }
//       else if(e.start_time >= entrie.start_time && e.end_time >= entrie.end_time){
//         if(e.position.column != 4) amountColumns = e.position.column + 1;
//         else amountColumns = 4;
//       }
//       if(amountColumns == 4){ break}
//     }
//     return amountColumns;
//   }

//   getEntrieRow(entrie: any): number {
//     let hour = new Date(entrie.start_time).getHours() - 8; //8 Start hour
//     let mins = new Date(entrie.start_time).getMinutes();
//     let units = (hour * 4) + (mins / 15);
//     return units;
//   }

//   getEntrieRowSpan(entrie: any): number {
//     let start = new Date(entrie.start_time);
//     let startTimeInMin = (start.getHours() * 60) + start.getMinutes();
//     let end = new Date(entrie.end_time);
//     let endTimeInMin = (end.getHours() * 60) + end.getMinutes();
//     let currency = endTimeInMin - startTimeInMin;
//     let units = Math.round(currency/this.minimum_min);
//     return units + 1;
//   }

//   createGridEntrie(index: number, entrie: any, column: number, row: number, rowSpan: number) {
//     this.calendarWeek[index].entries.push({
//       id: entrie.id,
//       start_time: entrie.start_time,
//       end_time: entrie.end_time,
//       position: {
//         column: column,
//         row: row,
//         row_span: rowSpan
//       },
//       is_new: entrie.is_new,
//       subject: entrie.subject
//     });
//   }

//   formWeekEntries() {
//     for(let c of this.calendarWeek){
//       c.entries = [];
//     }
//     for(let entrie of this.entries){
//       for(let i = 0; i < this.calendarWeek.length; i++){
//         let entrieStartTime = new Date(entrie.start_time);
//         if(entrieStartTime.getFullYear() == this.calendarWeek[i].day.getFullYear() &&
//            entrieStartTime.getMonth() == this.calendarWeek[i].day.getMonth() &&
//            entrieStartTime.getDate() == this.calendarWeek[i].day.getDate()){
//             let amountEntries = this.calendarWeek[i].entries.length;
//             if(amountEntries > 0){
//               if(this.calendarWeek[i].entries[amountEntries-1].end_time <= entrie.start_time){
//                 //this.createEntrie(i, entrie, 1, 1);
//                 this.checkRowCounter(entrie, i, 1, 1, 1)
//               } else if(this.calendarWeek[i].entries[amountEntries-1].start_time <= entrie.start_time && (this.calendarWeek[i].entries[amountEntries-1].end_time - 10000) > entrie.start_time){
//                 if(this.calendarWeek[i].entries[amountEntries-1].position.width_typ == 1){
//                   this.calendarWeek[i].entries[amountEntries-1].position.width_typ = 2;
//                   this.calendarWeek[i].entries[amountEntries-1].position.place = 1;
//                   //this.createEntrie(i, entrie, 2, 2);
//                   this.checkRowCounter(entrie, i, 2, 2, 1)
//                 } else if(this.calendarWeek[i].entries[amountEntries-1].position.width_typ == 2) {
//                   this.calendarWeek[i].entries[amountEntries-2].position.width_typ = 3;
//                   this.calendarWeek[i].entries[amountEntries-2].position.place = 1;
//                   this.calendarWeek[i].entries[amountEntries-1].position.width_typ = 3;
//                   this.calendarWeek[i].entries[amountEntries-1].position.place = 2;
//                   //this.createEntrie(i, entrie, 3, 3);
//                   this.checkRowCounter(entrie, i, 3, 3, 1)
//                 } else if(this.calendarWeek[i].entries[amountEntries-1].position.place == 2) {
//                   // this.calendarWeek[i].entries[amountEntries-3].position.width_typ = 4;
//                   // this.calendarWeek[i].entries[amountEntries-3].position.place = 1;
//                   // this.calendarWeek[i].entries[amountEntries-2].position.width_typ = 4;
//                   // this.calendarWeek[i].entries[amountEntries-2].position.place = 2;
//                   // this.calendarWeek[i].entries[amountEntries-1].position.width_typ = 4;
//                   // this.calendarWeek[i].entries[amountEntries-1].position.place = 3;
//                   //this.createEntrie(i, entrie, 4, 4);
//                   let amountOfEntrieInColumn = 0;
//                   for(let k = i; k > i - 6; i--){
//                     if(this.calendarWeek[i].entries[k]?.position.width_typ == 3) amountOfEntrieInColumn++;
//                   }
//                   this.checkRowCounter(entrie, i, 3, 3, amountOfEntrieInColumn);
//                 }
//               } else {
//                 //this.createEntrie(i, entrie, 1, 1);
//               }
//             } else {
//               this.createEntrie(i, entrie, 1, 1, 1);
//             }
//         }
//       }
//     }
//   }

//   checkRowCounter(entrie: any, index: number, place: number, width_typ: number, amountOfEntrie: number) {
//     let heightOccupied = 0;
//     for(let e of this.calendarWeek[index].entries){
//       if(e.position.place == place){
//         heightOccupied += e.position.height;
//       }
//     }
//     if(heightOccupied < this.getTopPosition(entrie.start_time)){
//       this.createEntrie(index, entrie, place, width_typ, amountOfEntrie);
//     } else {
//     }
//   }

//   checkIfAnyEntrieOverlays(entrie: any, index: number, amountOfEntrie: number): boolean {
//     for(let e of this.calendarWeek[index].entries){
//       if(e.position.place > 1 && e.end_time >= entrie.start_time){
//         if(e.position.place < 4){
//           this.createEntrie(index, entrie, 2, e.position.place + 1, amountOfEntrie);
//         }
//         return true;
//       }
//     }
//     return false;
//   }

//   createEntrie(index: number, entrie: any, withTyp: number, place: number, amountOfEntrie: number) {
//     this.calendarWeek[index].entries.push({
//       id: entrie.id,
//       start_time: entrie.start_time,
//       end_time: entrie.end_time,
//       position: {
//         width_typ: withTyp,
//         place: place,
//         height: this.getEntrieHeight(entrie.start_time, entrie.end_time)
//       },
//       amount_of_entries: amountOfEntrie,
//       is_new: entrie.is_new,
//       subject: entrie.subject
//     });
//   }

//   getEntrieWidth(entrie: any): string {
//     let width = (this.columnWidth / entrie.position.width_typ) - 4;
//     if(!isNaN(width)) return width + 'px';
//     return '0px';
//   }

//   getEntrieWidthDayView(entrie: any): string {
//     return 'calc(78.6% / '+entrie.position.width_typ+')';
//   }

//   getEntrieTransform(entrie: any): string {
//     return 'translate('+(this.columnWidth / entrie.position.width_typ) * (entrie.position.place - 1)+'px, 0px)';
//   }

//   getEntrieTransformDayView(entrie: any): string {
//     return 'translate('+(100 * (entrie.position.place - 1))+'%, 0px)';
//   }

//   changeWeek() {
//     let weekDay = this.selectedDate.day();
//     this.calendarWeek = this.getPreviousWeekDays(weekDay, this.selectedDate.toDate()).concat(this.getNextWeekDays(weekDay, this.selectedDate.toDate()))
//     this.weekNumber = this.getWeek(this.selectedDate.toDate());
//     this.formWeekEntries();
//   }

//   getPreviousWeekDays(dayOfWeek: number, date?: Date): CalendarDay[] {
//     let lastDays: CalendarDay[] = [];
//     dayOfWeek = dayOfWeek == 0 ? 7 : dayOfWeek;
//     if(dayOfWeek > 0){
//       for(let i = (dayOfWeek - 1); i >= 0; i--){
//         let day = date ? new Date(date.getTime()) : new Date();
//         day.setDate(day.getDate() - i);
//         lastDays.push(new CalendarDay(day,[]));
//       }
//     }
//     return lastDays;
//   }

//   getNextWeekDays(dayOfWeek: number, date?: Date): CalendarDay[] {
//     let nextDays: CalendarDay[] = [];
//     dayOfWeek = dayOfWeek == 0 ? 7 : dayOfWeek;
//     let diff = 7 - dayOfWeek;
//     if(diff <= 7){
//       for(let i = 1; i <= diff; i++){
//         let day = date ? new Date(date.getTime()) : new Date();
//         day.setDate(day.getDate() + i)
//         nextDays.push(new CalendarDay(day,[]));
//       }
//     }
//     return nextDays;
//   }

//   getWeek(date: Date): number {
//     let onejan = new Date(date.getFullYear(), 0, 1);
//     let week = Math.ceil((((date.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
//     return week;
//   }

//   getHours() {
//     for(let i = this.start_time; i <= this.end_time; i++){
//       this.hours.push(i);
//     }
//   }

//   getTopPosition(startTime: number, index?: number): number {
//     let hour = new Date(startTime).getHours();
//     let mins = new Date(startTime).getMinutes();
//     if(hour >= this.start_time){
//       let multiplicater = hour - this.start_time;
//       let pixelHour = this.height_row * multiplicater;
//       let pixelMin = ((mins * 100) / 60) * (this.height_row / 100);
//       let top = pixelHour + pixelMin + this.calender_pos_top;
//       return top
//     }
//     return (this.entrie_start_position + this.calender_pos_top);
//   }

//   getPositionStart(startTime: number, index: number): string {
//     let hour = new Date(startTime).getHours();
//     let mins = new Date(startTime).getMinutes();
//     if(hour >= this.start_time){
//       let multiplicater = hour - this.start_time;
//       let pixelHour = this.height_row * multiplicater;
//       let pixelMin = ((mins * 100) / 60) * (this.height_row / 100);
//       let top = pixelHour + pixelMin + this.entrie_start_position;
//       if(index != 0){
//         let previousTop = 0;
//         for(let i = (index-1); i >= 0; i--){
//           let previousEntrie = document.getElementById('entrie_'+(index-1));
//           let style = previousEntrie.style.cssText.split(' ');
//           let value = Number(style[style.length -1].slice(0,-3));
//           previousTop += value + this.border_thickness;
//         }
//         top = top - previousTop;
//         return top + 'px';
//       } else {
//         return top + 'px';
//       }
//     }
//     return this.entrie_start_position + 'px'
//   }

//   getEntrieHeight(startTime: number, endTime: number): number {
//     let start = new Date(startTime);
//     let startTimeInMin = (start.getHours() * 60) + start.getMinutes();
//     let end = new Date(endTime);
//     let endTimeInMin = (end.getHours() * 60) + end.getMinutes();
//     let currency = endTimeInMin - startTimeInMin;
//     let units = Math.round(currency/this.minimum_min);
//     let height = ((this.height_row / 4) * units) - this.border_thickness;
//     return height;
//   }

//   nextWeek(){
//     let lastWeekDay = this.calendarWeek[this.calendarWeek.length - 1].day;
//     this.calendarWeek = [];
//     for(let i = 1; i <= 7; i++){
//       let day = new Date(lastWeekDay.getTime() + ((24 * 60 * 60 * 1000) * i));
//       this.calendarWeek.push(new CalendarDay(day,[]));
//     }
//     this.weekNumber = this.getWeek(this.calendarWeek[0].day);
//     this.formWeekEntries();
//   }

//   previousWeek(){
//     let firstWeekDay = this.calendarWeek[0].day;
//     this.calendarWeek = [];
//     for(let i = 7; i >= 1; i--){
//       let day = new Date(firstWeekDay.getTime() - ((24 * 60 * 60 * 1000) * i));
//       this.calendarWeek.push(new CalendarDay(day,[]));
//     }
//     this.weekNumber = this.getWeek(this.calendarWeek[0].day);
//     this.formWeekEntries();
//   }

//   heightAvailable(entrie: any): boolean {
//     let height = this.getEntrieHeight(entrie.start_time, entrie.end_time);
//     return height > 26;
//   }

//   isOdd(num: number): boolean {
//     return (num % 2) == 0;
//   }

//   addEntrie(calenderDay: CalendarDay, hour: number) {
//     this.selectedEntrieDay = calenderDay;
//     this.selectedEntrieDay.day.setHours(hour);
//     this.showTimePicker = true;
//   }

//   cancelPicker() {
//     this.selectedEntrieDay = null;
//     this.showTimePicker = false;
//   }

//   submitTimePicker(result: any) {
//     let entrie = new Drive(
//       0,
//       undefined,
//       result.start_time,
//       result.end_time,
//       undefined,
//       'image',
//       1,
//       1,
//       'Probefahrt'
//     )
//     entrie.is_new = true;
//     this.entries.push(entrie);
//     this.sortEntries();
//     this.formWeekEntries();
//     this.showTimePicker = false;
//     this.newEntrieTimeEmitter.emit({
//       start_time: result.start_time,
//       end_time: result.end_time,
//     })
//   }

//   removeEntrie(entries: any) {
//     let index = entries.findIndex(e => e.id == 0);
//     if(index > -1) entries.splice(index,1)
//     this.sortEntries();
//     this.formWeekEntries();
//   }

//   getEntrieLbl(entrie: any): string { //{{showTimeValue ? (entrie.position.width_typ == 1 ? ((entrie.start_time | date: 'HH:mm')+'-'+(entrie.end_time | date: 'HH:mm')) : (entrie.position.place == 4 ? '+1' : '')) : (entrie.position.width_typ == 1 ? entrie.subject : '')}}
//     if(this.showTimeValue){
//       if(entrie.position.width_typ == 1) return this.datePipe.transform(entrie.start_time, 'HH:mm') + '-' + this.datePipe.transform(entrie.end_time, 'HH:mm');
//       else if(entrie.position.place == 3) return '+'+ entrie.amount_of_entries;
//       else return entrie.subject.slice(0,entrie.position.width_typ == 2 ? 10 : 4) + '...';
//     } else {
//       if(entrie.position.width_typ == 1) return entrie.subject;
//       else if(entrie.position.place == 3) return '+' + entrie.amount_of_entries;
//       else return entrie.subject.slice(0,entrie.position.width_typ == 2 ? 10 : 4) + '...';
//     }
//   }

//   nextDay() {

//   }

//   previousDay() {

//   }

