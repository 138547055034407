import { VehicleUpholstery } from "../VehicleUpholstery";

export class VehicleUpholsteryFactory {
    static jsonFactory(rawData: any): VehicleUpholstery[] {
        let dataAry: VehicleUpholstery[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehicleUpholstery {
        let data = new VehicleUpholstery(
            rawData.upholstery_id,
            rawData.name ? rawData.name : rawData.upholstery_name,
        );
        return data;
    }
}
