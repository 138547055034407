import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BaseComponent } from '../../BaseComponent';
import { PriceSheet } from 'src/app/buisness-object/pricesheet/PriceSheet';
import { FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Subscription } from 'rxjs';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-price-sheets-details',
  templateUrl: './price-sheets-details.component.html',
  styleUrls: ['./price-sheets-details.component.css']
})
export class PriceSheetsDetailsComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() priceSheet: PriceSheet;
  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @ViewChild('gf_info') gf_info: ElementRef;
  public dialogQuerySubsription: Subscription
  public paperStatusValue;
  public finanzsperrauskunftValue;
  public finanzierungsgesellschaftValue;
  public finanzierungszusageValue;
  public speditionValue;
  public standardNew = false;
  constructor(
    private dService: DialogService,
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
    this.standardNew = this.authService.isHoedl();
  }

  ngOnInit(): void {
    this.paperStatusValue = this.getEnumValue('paper_status', this.vehicle.paper_status);
    this.finanzsperrauskunftValue = this.getEnumValue('finanzsperrauskunft', this.vehicle.finanzsperrauskunft);
    this.finanzierungsgesellschaftValue = this.getEnumValue('finanzierungsgesellschaft', this.vehicle.priceSheet.finanzierungsgesellschaft);
    this.finanzierungszusageValue = this.getEnumValue('finanzierungszusage', this.vehicle.priceSheet.finanzierungszusage);
  }

  ngAfterViewInit(): void {
    if(this.gf_info) this.gf_info.nativeElement.innerHTML = this.priceSheet.gf_info ? this.priceSheet.gf_info : '---';
  }

  showRepUndFahrzeugkosten() {
    return this.showValuePricesheet('aufbereitung') || 
           this.showValuePricesheet('reparaturen') || 
           this.showValuePricesheet('reparatur_dauer_tage') ||
           this.showValuePricesheet('reparatur_summe_gesamt');
  }

  ngOnChanges(): void {
  }

  openReparaturDialog() {
    this.dService.openReparatur(
      {
        price_sheet: this.priceSheet,
        is_nachkalkulation: false
      }
    );
  }

  openReparaturNachDialog() {
    this.dService.openReparatur(
      {
        price_sheet: this.priceSheet,
        is_nachkalkulation: true
      }
    );
  }
}
