import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { BaseComponent } from '../../BaseComponent';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VehicleStatus } from '../../list-action-container/btn-action/btn-action.component';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-calender-drives',
  templateUrl: './calender-drives.component.html',
  styleUrls: ['./calender-drives.component.css'],
  animations: [DropdownIconArrowAnimation]
})
export class CalenderDrivesComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() drives: Drive[];
  @Input() vehicles: Vehicle[];
  @Input() selectedDrive: Drive;
  @Output() changeShownDataEmitter = new EventEmitter<number>();
  @Output() selectionDriveEmitter = new EventEmitter<Drive>();

  public showInformationTab = 2;
  public vehiclesFiltered: Vehicle[] = [];
  public vehiclesDropdown: Vehicle[] = [];
  public selectedVehicle: Vehicle;
  public selectedPlate = 0;
  public toShowEntries: Drive[] = [];
  @ViewChild('input_vehicle') input_vehicle: ElementRef;
  @ViewChild('dropdownTwo') dropdownTwo: ElementRef;
  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public listener: () => void;

  public platesValues = [];
  public selectedPlateValue = [];
  public vehicleValues = [];

  public filterForm: FormGroup;
  public heightCalender;


  constructor(
    private vService: VehicleService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      license_plate_id: [null],
      vehicle: [null]
    });
    this.platesValues = this.getEnumValues('license_plate_id');
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.filterForm.controls['license_plate_id'].value);
    if(this.authService.isSinhuber()){
      this.vehicles = this.vehicles.filter((v) => {
        if(v.externalId == "9606") return true;
        return !v.customer_id && v.status != VehicleStatus.CustomerCar && (v.priceSheet?.al_re_datum == null || v.priceSheet?.al_re_datum > new Date().getTime());
      });
    } else if(this.authService.isStandard()){
      this.vehicles = this.vehicles.filter((v) => {
        return !v.customer_id && v.status != VehicleStatus.CustomerCar;
      });
    }
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
    this.toShowEntries = this.drives;
    this.getDropdownVehicles();
    if(this.selectedDrive){
      for(let vehicle of this.vehicles){
        if(this.selectedDrive.vehicle_id == vehicle.id){
          this.setVehicle(vehicle);
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const calenderBody = document.getElementById('calender-body');
      if(calenderBody){
        this.heightCalender = calenderBody.offsetHeight - 20;
      }
    }, 200);
    if(this.selectedDrive) this.input_vehicle.nativeElement.value = this.selectedVehicle.getDescription(35);
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showDropdownOne && !this.dropdownOne.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.dropdownTwo.nativeElement.contains(e.target)) this.showDropdownTwo = false;
    })
  }

  setPlate(value) {
    this.filterForm.controls['license_plate_id'].setValue(value);
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.filterForm.controls['license_plate_id'].value);
    this.filterPlate(this.filterForm.controls['license_plate_id'].value)
  }


  setValueFromDropdown(value: string, id: number) {
    if(value === 'vehicle'){
      let index = this.vehicles.findIndex(obj => obj.id == id);
      if(index > -1){
        this.filterForm.controls['vehicle'].setValue(this.vehicles[index]);
        this.setVehicle(this.vehicles[index]);
      }
    }
  }

  getDropdownVehicles() {
    this.vehiclesDropdown = []
    for(let d of this.drives){
      let index = this.vehiclesDropdown.findIndex(v => v.id == d.vehicle_id);
      if(index == -1 && d.vehicle) this.vehiclesDropdown.push(d.vehicle);
    }
    this.vehiclesFiltered = this.vehiclesDropdown;
  }

  changeTab(tab: number) {
    this.showInformationTab = tab;
  }

  sort() {
    this.vehicles.sort((a,b) => {
      if(a.brand.makeName > b.brand.makeName) return 1;
      if(a.brand.makeName < b.brand.makeName) return -1;
      return 0;
    })
  }

  filterVehicles(value: string) {
    if(value && value.length > 0){
      this.selectedVehicle = null;
      this.toShowEntries = [];
      this.vehiclesFiltered = [];
      this.vehiclesFiltered = this.vehiclesDropdown.filter((vehicle) => {
        return vehicle.brand.makeName.toLowerCase().includes(value.toLowerCase()) ||
               vehicle.model.name.toLowerCase().includes(value.toLowerCase()) ||
               vehicle.model.version.toLowerCase().includes(value.toLowerCase()) ||
               vehicle.fin_number?.toLowerCase().includes(value.toLowerCase()) ||
               vehicle.vehicle_serialnumber?.toLowerCase().includes(value.toLowerCase()) ||
               (vehicle.brand.makeName.toLowerCase() + ' ' + vehicle.model.name.toLowerCase() + ' ' + vehicle.model.version.toLowerCase()).includes(value.toLowerCase());
      })
    } else {
      this.vehiclesFiltered = this.vehiclesDropdown;
    }
  }

  setVehicle(vehicle: Vehicle) {
    this.selectedDrive = null;
    this.toShowEntries = [];
    this.selectedVehicle = vehicle;
    if(this.selectedVehicle) this.getThumbnail();
    this.toShowEntries = this.getDrives();
    if(this.input_vehicle && this.selectedVehicle) this.input_vehicle.nativeElement.value = this.selectedVehicle.getDescription(25);
    else if(this.input_vehicle) this.input_vehicle.nativeElement.value = '';
  }

  getThumbnail() {
    if(this.selectedVehicle.pictures.length > 0){
      this.vService.getVehicleThumbnail(this.selectedVehicle.pictures[0].picture_id).subscribe((result) => {
        if(result){
          this.selectedVehicle.thumbnail = 'data:image/png;base64,'+result.trim();
          this.toShowEntries = this.getDrives();
        }
      })
    }
  }

  getDrives(): Drive[] {
    let drives = [];
    for(let drive of this.drives){
      if(this.selectedVehicle){
        if(this.selectedPlate == 0 && drive.vehicle_id == this.selectedVehicle.id){
          drives.push(drive);
        } else if(drive.license_plate_id == this.selectedPlate && drive.vehicle_id == this.selectedVehicle.id){
          drives.push(drive);
        }
      } else if(this.selectedPlate == 0){
        drives.push(drive);
      } else if(drive.license_plate_id == this.selectedPlate){
        drives.push(drive);
      }
    }
    return drives;
  }

  selectionDrive(entry: any) {
    for(let drive of this.drives){
      if(drive.test_drive_id == entry.id){
        this.selectionDriveEmitter.emit(drive);
        break;
      }
    }
  }

  filterPlate(id: number) {
    this.selectedPlate = id;
    this.toShowEntries = this.getDrives();
  }

  removeCar() {
    this.setVehicle(null)
  }

  getSelectedPlate(): string {
    switch (this.selectedPlate) {
      case 0: return 'ALLE'; break;
      case 1: return 'KR-ESJ4'; break;
      case 2: return 'KR-13WR'; break;
      case 3: return 'BEISPIELKENNZEICHEN'; break;
      default: return '---'; break;
    };
  }
}
