import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';

@Component({
  selector: 'app-kunden-vehicles-box',
  templateUrl: './kunden-vehicles-box.component.html',
  styleUrl: './kunden-vehicles-box.component.css'
})
export class KundenVehiclesBoxComponent implements OnInit, OnDestroy {
  @Input() obj: Customer;
  public dialogQuerySubsription: Subscription;

  constructor(
    private vehicleService: VehicleService,
    private router: Router,
    private dialogService: DialogService
  ){}

  ngOnInit(): void {
    this.setDialogSubscription();
    this.vehicleService.getVehicleByCustomer(this.obj.id).subscribe((vehicles) => {
      if(vehicles){
        this.obj.vehicles = vehicles;
      }
    })
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete_customer_car' && value.submit_value != null) this.deleteCustomerCar(value.submit_value);
      }
    });
  }

  selectionDetails(obj) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['cars/'], { queryParams: { carId: obj.id } }));
  }

  seletionDelete(obj) {
    this.dialogService.openQuery(
      {
        title: 'Fahrzeug entfernen',
        message: 'Sind sie sicher, dass Sie dieses Fahrzeug vom Kunden entfernen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Entfernen',
        typ: 'delete_customer_car',
        submit_value: obj,
      }
    );
  }

  deleteCustomerCar(obj){
    obj.customer_id = null;
    obj.status = 1;
    this.vehicleService.updateVehicle(obj).subscribe((result) => {
      if(result){
        let index = this.obj.vehicles.findIndex(v => v.id == result.id);
        if(index > -1) this.obj.vehicles.splice(index,1);
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Fahrzeug vom Kunden entfernt.',
          success: true
        });
      }
    })
  }
}
