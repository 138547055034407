<form class="contract_create_page_wrapper" [formGroup]="form">
  <div class="lbl_bold_light_color">Neuer Kaufvertag</div>
  <div class="page_body">
    <div class="column">
      <app-dropdown-objects-search
          [title]="'Kunde *'"
          [placeholder]="'Kunde'"
          [headervalue]="form.controls.customer?.value?.getName()"
          [formcontrol]="form.controls.customer"
          [values]="customerValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('customer', $event)"
      ></app-dropdown-objects-search>
      <div class="btn_submit_dark" style="margin-top: 15px;" *ngIf="!selectedCustomer" (click)="goToCustomerCreateAction()">Neuen Kunden anlegen</div>
      <div *ngIf="selectedCustomer" class="column">
        <div class="input_wrapper">
          <label class="overview_record_lbl">Strasse, Nr *</label>
          <input type="text" placeholder="Strasse, Nr" formControlName="street" [class.input_invalid]="submitted && form.controls.street.errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">PLZ *</label>
          <input type="text" placeholder="PLZ" formControlName="postalCode" [class.input_invalid]="submitted && form.controls.postalCode.errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Ort *</label>
          <input type="text" placeholder="Ort" formControlName="city" [class.input_invalid]="submitted && form.controls.city.errors">
        </div>
        <div class="input_wrapper" *ngIf="form.controls.is_company.value == true">
          <label class="overview_record_lbl">Firmenname *</label>
          <input type="text" placeholder="Firmenname" formControlName="company_name" [class.input_invalid]="submitted && form.controls.company_name.errors">
        </div>
        <div class="input_wrapper" *ngIf="form.controls.is_company.value == true">
          <label class="overview_record_lbl">ATU</label>
          <input type="text" placeholder="ATU" formControlName="atu" [class.input_invalid]="submitted && form.controls.atu.errors">
        </div>
        <!-- <app-dropdown-strings
          [title]="'Land'"
          [placeholder]="'Land'"
          [formcontrol]="form.controls['country']"
          [headerText]="form.controls['country'].value"
          [values]="countries"
          [isOptional]="true"
          (submitValueEmitter)="form.controls['country'].setValue($event);"
        ></app-dropdown-strings> -->
      </div>
    </div>
    <div class="column" *ngIf="selectedCustomer">
      <div class="input_wrapper">
        <label class="overview_record_lbl">E-Mail *</label>
        <input type="text" placeholder="E-Mail" formControlName="email" [class.input_invalid]="submitted && form.controls.email.errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Telefon *</label>
        <input type="text" placeholder="Telefon" formControlName="phone" [class.input_invalid]="submitted && form.controls.phone.errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Geburtsdatum</div>
        <input #inputDate1 type="date" placeholder="date" [min]="minDate" formControlName="birthdate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls.birthdate.errors">
      </div>
    </div>
  </div>
</form>

