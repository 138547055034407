import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.css']
})
export class PdfViewComponent implements OnInit {
  @Input() pdf: any;

  public objectUrl: any;

  constructor(
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    const blob = new Blob([this.pdf], { type: 'application/pdf' });
    const objectUrl = window.URL.createObjectURL(blob);
    document.querySelector("iframe").src = objectUrl;
  }
}
