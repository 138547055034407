import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { VehicleBodyColor } from 'src/app/buisness-object/vehicle/basic/VehicleBodyColor';
import { VehicleBodyTyp } from 'src/app/buisness-object/vehicle/basic/VehicleBodyTyp';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { VehicleDrive } from 'src/app/buisness-object/vehicle/basic/VehicleDrive';
import { VehicleFuel } from 'src/app/buisness-object/vehicle/basic/VehicleFuel';
import { VehicleTransmission } from 'src/app/buisness-object/vehicle/basic/VehicleTransmission';
import { VehicleEquipment } from 'src/app/buisness-object/vehicle/optional/VehicleEquipment';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';

@Component({
  selector: 'app-create-container',
  templateUrl: './create-container.component.html',
  styleUrls: ['./create-container.component.css']
})
export class CreateContainerComponent implements OnInit {
  public pagesTotalAry = [1,2,3,4,5,6,7,8];

  @Input() brands: VehicleBrand[] = [];
  @Input() drives: VehicleDrive[] = [];
  @Input() fuels: VehicleFuel[] = [];
  @Input() bodyColors: VehicleBodyColor[] = [];
  @Input() bodyTyps: VehicleBodyTyp[] = [];
  @Input() categories: VehicleCategory[] = [];
  @Input() transmissions: VehicleTransmission[] = [];
  @Input() equipments: VehicleEquipment[] = [];

  @Input() currentPageCreate: number;
  @Input() submitted: boolean;
  @Input() newVehicle: Vehicle;

  @Input() formGroupVehicle: UntypedFormGroup;

  @Output() changePageEmitter = new EventEmitter<number>();
  @Output() createVehicleEmitter = new EventEmitter<undefined>();
  @Output() vinEmitter = new EventEmitter<string>();
  @Output() updateVehicleTitle = new EventEmitter<string>();
  @Output() saveAsDraftEmitter = new EventEmitter<boolean>();
  @Output() selectionBCAImportEmitter = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {}

  changePage(num: number) {
    this.changePageEmitter.emit(num);
  }
}
