import { PriceSheet, Reparatur } from "../PriceSheet";

export class PriceSheetFactory {
  static jsonFactoryOne(raw: any): PriceSheet {
    let gw_repa = ReparaturFactory.jsonFactory(raw.gw_gar_reparatur);
      const obj = new PriceSheet(
        raw.price_sheet_id,
        raw.vehicle_id,

        raw.guv_vorkalkulation,
        raw.guv_nachkalkulation,
        raw.aufschlag,

        raw.steuersatz,
        raw.steuersatz_nach,
        raw.einkaufspreis_brutto,
        raw.vorsteuer,
        raw.vorsteuer_nach,
        raw.verkaufspreis_brutto,
        raw.ohne_nova_inkl_ust,
        raw.ust,

        raw.kosten_lt_vorkalkulation,
        raw.preis_anschreiben,
        raw.minimaler_verkaufpreis,
        raw.zugaben,
        raw.zubehoer,

        raw.neu_preis,
        raw.sonderaustattung,
        raw.gesamt_neupreis,

        raw.einkaufspreis_netto,
        raw.einkaufsdatum,
        raw.eurotax,
        raw.hereinnahmedatum,
        raw.zahldatum,
        raw.anpreisungsdatum,
        raw.standzeit_plan_tage,

        raw.beschaffungskosten,
        raw.sprit,
        raw.flug_bahn,
        raw.gebuehren,
        raw.lieferant_extern,
        raw.uebernachtung,
        raw.beschaffungskosten_gesamt,

        raw.aufbereitung,
        raw.reparatur_dauer_tage,
        raw.reparatur_summe_gesamt,
        ReparaturFactory.jsonFactory(raw.reparaturen),
        null,
        ReparaturFactory.jsonFactory(raw.reparaturen_vorjahr),
        gw_repa && gw_repa.length > 0 ? gw_repa[0] : null,

        raw.zinssatz_vor,
        raw.zinsen_vor,
        raw.zinssatz_nach,
        raw.zinsen_nach,
        raw.garantie_von_einkaufspreis,
        raw.garantie,
        raw.standzeitkosten_je_tag_vor,
        raw.standzeitkosten_vor,
        raw.fixkosten_pro_fahrzeug_bei_70,
        raw.gw_aufschlag,

        raw.verkaufspreis_netto,
        raw.verkaufsdatum,
        raw.al_re_datum,
        raw.geldeingang,
        raw.standzeit,
        raw.standzeit_platz,
        raw.standzeit_geld,

        raw.entgelt_ohne_nova,
        raw.nova,
        raw.co2_emission,
        raw.malus_bonus,
        raw.summe_nova,

        raw.reparaturen_anzahl,

        raw.zinsen_effektiv,
        raw.garantie_intern,
        raw.garantie_extern,
        raw.standzeitkosten_je_tag_nach,
        raw.standzeitkosten_nach,
        raw.gewaehrleistung_garantie_reparaturen,

        raw.fahrzeug_provision,
        raw.finanzierung,

        null,
        null,
        null,
        null,
        raw.finanzierungsgesellschaft,
        raw.finanzierungsart,
        raw.fzg_export,
        raw.finanzierungszusage,
        raw.fahrzeuginzahlungnahme,
        raw.eingang_anzahlung,
        raw.eingang_anzahlung_description,
        raw.customer_id,
        raw.customer_name,
        raw.employee_id,

        raw.vehicle_serialnumber,
        raw.invoice_checked,
        raw.vehicle_supplier,
        raw.garantie_vor,
        raw.abzug_fahrzeug_provision,
        raw.abzug_finanzierung,

        raw.vk_nachkalkulation,
        raw.vk_nachkalkulation_haendler,
        raw.verkaufspreis_netto,
        raw.nova_vor,
        null,
        null,
        null,
        null,
        raw.aufbereitung_nachkalkulation,
        null,
        null,
        null,
        null,
        raw.rechnungsdatum_vor,
        raw.sent_to_bank,
        raw.gf_info,
        raw.einstockung_bei,
        raw.vk_vor_checked,
        null,
        null,
        null,
        null,
        null,
        raw.malus_bonus_nach,
        raw.vehicle_imported,
        null,
        null,
        null,
        raw.finanzsperrauskunft_beantragt,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      );
      if(obj.verkaufspreis_brutto){
        obj.nachkalkulation = true;
      }
      obj.setBeschaffungskostenGesamt();
      //obj.fahrzeug_provision = obj.getProvision();
      return obj;
  }
}

export class ReparaturFactory {
  static jsonFactory(raw: any): Reparatur[] {
    let objs = [];
    if(raw){
      for(let r of raw){
        objs.push(this.jsonFactoryOne(r));
      }
    }
    return objs;
}
  static jsonFactoryOne(raw: any): Reparatur {
      const obj = new Reparatur(
        raw.name,
        raw.renr,
        raw.az,
        raw.mat,
        raw.reifen,
        raw.lackierung,
        raw.typisierung,
      );
      return obj;
  }
}
