import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topbar-interface',
  templateUrl: './topbar-interface.component.html',
  styleUrls: ['./topbar-interface.component.css']
})
export class TopbarInterfaceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
