import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, AfterViewInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Role } from 'src/app/buisness-object/employee/Role';
import { Location } from 'src/app/buisness-object/location/Location';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.css']
})
export class CreateEmployeeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() isEdit: boolean;
  @Input() locations: Location[];
  @Input() roles: string[];
  public showDropdownOne = false;
  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  public listenerOnClick: () => void;
  public locationValues = [];
  public showCreateNewPasswort = false;

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.setDropdownValues();
  }

  ngAfterViewInit(): void {
    this.listenerOnClick = this.renderer.listen('window','click',(e:Event) => {
      if(this.showDropdownOne && !this.dropdownOne.nativeElement.contains(e.target)) this.showDropdownOne = false;
    })
  }

  ngOnDestroy(): void {
    if(this.listenerOnClick) this.listenerOnClick();
  }

  setDropdownValues() {
    for(let v of this.locations){
      this.locationValues.push({
        id: v.locationId,
        name: v.name
      })
    }
  }
  setValueFromDropdown(value: string, id: number) {
    if(value === 'location'){
      let index = this.locationValues.findIndex(v => v.id == id);
      if(index > -1) this.form.controls["location"].setValue(this.locations[index]);
    }
  }
}
