import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseComponent, countries } from '../../BaseComponent';
import { Customer, CustomerTyp } from 'src/app/buisness-object/customer/Customer';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-kunde-create',
  templateUrl: './kunde-create.component.html',
  styleUrl: './kunde-create.component.css'
})
export class KundeCreateComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() obj: Customer;
  @Input() employees: Employee[];
  @Output() createSuccessEmitter = new EventEmitter<any>();
  @Output() updateSuccessEmitter = new EventEmitter<any>();
  @Output() selectionCancelEmitter = new EventEmitter<any>();
  public form: FormGroup;
  public customers: Customer[] = [];
  public submitted = false;
  public formValueChanged = false;
  public typValues = ["Firma","Privat"];
  public statusValues = ["Kunde","Interessent"];
  public titleValues = [];
  public employeeValues = [];
  public countries = countries;
  public dialogQuerySubsription: Subscription;
  public formOnChangeSubscription: Subscription;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private dialogService: DialogService,
  ){
    super(authService, eService);
  }


  ngOnInit(): void {
    this.setUp();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setUp() {
    this.titleValues = this.enumService.getEnumValues('title');
    this.employeeValues = this.getDropdownValuesEmployee(this.employees);
    this.form = CustomerForm.getCustomerForm(this.formBuilder, this.obj);
    // this.form.get('typ').valueChanges.subscribe((change) => {
    //   if(change){
    //     if(change == CustomerTyp.COMPANY){
    //       this.form.get('companyName').addValidators(Validators.required);
    //     } else {
    //       this.form.get('companyName').setValidators(null);
    //     }
    //     this.form.get('companyName').updateValueAndValidity({ emitEvent: false });
    //   }
    // })
    this.formOnChangeSubscription = this.form.valueChanges.subscribe((change) => {
      if(change){
        this.formValueChanged = true;
        this.formOnChangeSubscription.unsubscribe();
      }
    })
  }

  selectionSave() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    let customer = CustomerForm.createObject(this.form);
    this.customerService.updateCustomer(customer).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Kunde aktualisiert.',
          success: true
        });
        result.employee = customer.employee;
        this.updateSuccessEmitter.emit(result);
      }
    });
  }

  selectionCreate() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    let customer = CustomerForm.createObject(this.form);
    this.customerService.createCustomer(customer).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Kunde angelegt.',
          success: true
        });
        result.employee = customer.employee;
        this.createSuccessEmitter.emit(result);
      }
    });
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_create_customer') this.selectionCancelEmitter.emit();
      }
    });
  }

  selectionCancel() {
    if(this.formValueChanged){
      this.dialogService.openQuery(
        {
          title: 'Bearbeitung abbrechen',
          message: 'Sind sie sicher, dass Sie die Bearbeitung abbrechen möchten? Alle Änderungen gehen dadurch verloren.',
          btn_cancel_txt: 'Weiter bearbeiten',
          btn_submit_txt: 'Beenden',
          typ: 'cancel_create_customer',
          submit_value: null,
        }
      );
    } else {
      this.selectionCancelEmitter.emit();
    }
  }

  getDropdownValuesEmployee(employees: Employee[]): any[] {
    let values = [];
    for(let value of employees){
      values.push({
        id: value.id,
        name: value.firstName + ' ' + value.lastName
      })
    }
    return values;
  }

  setValueEmployeeFromDropdown(id) {
    let index = this.employees.findIndex((e) => e.id == id);
    if(index > -1) {
      this.form.get('employee').setValue(this.employees[index]);
    }
  }
}
