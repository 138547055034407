import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { SalesContract } from 'src/app/buisness-object/document/SalesContract';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-documents',
  templateUrl: './list-documents.component.html',
  styleUrls: ['./list-documents.component.css']
})
export class ListDocumentsComponent implements OnInit {
  @Input() documents: any[];
  @Input() pageInfo: string;
  @Input() sliceFrom: number;
  @Input() sliceTo: number;
  @Input() amount: number;
  @Input() tab: number;
  @Input() filter: any;
  @Input() sortObject: any;

  @Output() pageEmitter = new EventEmitter<boolean>();
  @Output() openDetailsEmitter = new EventEmitter<any>();
  @Output() openEditEmitter = new EventEmitter<any>();
  @Output() deleteEmitter = new EventEmitter<any>();
  @Output() openPdfEmitter = new EventEmitter<any>();
  @Output() moveToEmitter = new EventEmitter<any>();
  @Output() duplicateEmitter = new EventEmitter<any>();
  @Output() removeFilterEmitter = new EventEmitter<string>();
  @Output() applySortEmitter = new EventEmitter<undefined>();

  @Output() openContractPdfEmitter = new EventEmitter<SalesContract>();
  @Output() openContractEditEmitter = new EventEmitter<SalesContract>();
  @Output() deleteContractEmitter = new EventEmitter<SalesContract>();
  @Output() signPDFEmitter = new EventEmitter<any>();
  @Output() openContractUploadEmitter = new EventEmitter<any>();

  public maxAmountListPage = environment.max_amount_list_page;

  constructor() { }

  ngOnInit(): void {}

}
