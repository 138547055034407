import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BaseBoxComponent } from 'src/app/view/BaseBoxComponent';

@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.css'],
  animations: [DropdownIconArrowAnimation],
})
export class ConditionComponent extends BaseBoxComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @Input() submitted: boolean;

  today = formatDate(new Date(),'yyyy-MM-dd','de');

  public paperStatusValue;
  public paperStatusValues = [];
  public finanzsperrauskunft;
  public finanzsperrauskunftValues = [];
  public cocValue;
  public cocValues = [];
  public austrianDataExtractValue;
  public austrianDataExtractValues = [];
  public finanzsperrauskunftStatusValue;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.viewTitle = "condition";
    super.init();
    this.paperStatusValue = this.getEnumValue('paper_status', this.vehicle.paper_status);
    this.paperStatusValues = this.getEnumValues('paper_status');
    this.finanzsperrauskunft = this.getEnumValue('finanzsperrauskunft', this.vehicle.finanzsperrauskunft);
    this.finanzsperrauskunftValues = this.getEnumValues('finanzsperrauskunft');
    this.cocValue = this.getEnumValue('coc', this.vehicle.coc);
    this.cocValues = this.getEnumValues('coc');
    this.austrianDataExtractValue = this.getEnumValue('austrian_data_extract', this.vehicle.austrian_data_extract);
    this.austrianDataExtractValues = this.getEnumValues('austrian_data_extract');
    this.finanzsperrauskunftStatusValue = this.getEnumValue('finanzsperrauskunft_status', this.vehicle.finanzsperrauskunft_status);
  }


  getEnumDataOnChange() {
    this.paperStatusValue = this.getEnumValue('paper_status', this.form.controls['paper_status'].value);
    this.finanzsperrauskunft = this.getEnumValue('finanzsperrauskunft', this.form.controls['finanzsperrauskunft'].value);
    this.cocValue = this.getEnumValue('coc', this.form.controls['coc'].value);
    this.austrianDataExtractValue = this.getEnumValue('austrian_data_extract', this.form.controls['austrian_data_extract'].value);
  }

  get f() {
    return this.form.controls;
  }
}
