<div class="list_container">
    <div id="list_element_container">
        <app-list-locations-title
          [isTitle]="true"
          [pageInfo]="pageInfo"
          [sliceTo]="sliceTo"
          [sliceFrom]="sliceFrom"
          [amount]="locations.length"
          (pageEmitter)="pageEmitter.emit($event)"
        ></app-list-locations-title>
        <div class="list_element_container">
            <app-list-locations-element *ngFor="let location of locations | slice:sliceFrom:sliceTo" [location]="location" (openDetailsEmitter)="openDetailsEmitter.emit()"></app-list-locations-element>
        </div>
        <div class="list_footer"></div>
    </div>
</div>
