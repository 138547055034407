<div class="list_container">
    <div class="overview_data_box_lbl_title">Offene Tickets</div>
    <div class="messages_container">
        <div *ngIf="vehicle.threads.length == 0">Keine offenen Tickets vorhanden</div>
        <div class="message_wrapper" *ngFor="let thread of vehicle.threads">
            <div class="user_initials_message">{{thread.employee.getInitials()}}</div>
            <div>
                <div class="lbl_regular_italic_light_color lbl_user">{{thread.employee.firstName + ' ' + thread.employee.firstName}}</div>
                <div class="message_box">
                    <div class="message_box_header">
                        <div class="lbl_regular_italic_light_color">{{thread.createdDate | date: 'dd.MM.YYYY, HH:mm'}}</div>
                        <div class="message_title">
                            <div class="lbl_regular_italic_light_color">{{thread.messages.length - 1}} {{(thread.messages.length - 1) == 1 ? 'Kommentar' : 'Kommentare'}}</div>
                            <div [ngClass]="thread.resolved == 0 ? 'lbl_color_wrapper_red' : 'lbl_color_wrapper_green'">{{thread.resolved == 0 ? 'Offen' : 'Erledigt'}}</div>
                        </div>
                    </div>
                    <div class="message_txt" [innerHTML]="thread.messages[0].getMessageText(employees)"></div>
                    <div class="footer">
                        <div *ngIf="thread.creator == userId" class="action" (click)="resolve(thread)">
                            <img class="icon" src="../../../../../assets/lock-blue.svg">
                            <div class="lbl_regular_color">Auflösen</div>
                        </div>
                        <div class="action" (click)="goToThread(thread)">
                            <img class="icon" [ngStyle]="{'width': '12px'}" src="../../../../../assets/details-eye-blue.svg">
                            <div class="lbl_regular_color">Zum Ticket</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="write_wrapper">
        <div class="write_message_container">
            <textarea #textArea class="input_box" placeholder="Text eingeben.."></textarea>
            <img class="btn_submit" src="../../../../assets/send.svg">
        </div>
    </div> -->
</div>

