export class EnumData {

  constructor(
    public name: string,
    public values: EnumValue[]
  ){}
}

export class EnumValue {

  constructor(
    public raw_value: number,
    public value: string,
    public sort_value: number
  ){}
}

export class EnumFactory {

  static jsonFactoryEnumData(raws: any): EnumData[] {
    const objects: EnumData[] = [];
    for(let raw of raws){
      objects.push(this.jsonFactoryOneEnumData(raw));
    }
    return objects;
  }

  static jsonFactoryOneEnumData(raw: any): EnumData {
      let menu = new EnumData(
          raw.name,
          this.jsonFactoryEnumValue(raw.values),
      );
      return menu;
  }

  static jsonFactoryEnumValue(raws: any): EnumValue[] {
    const objects: EnumValue[] = [];
    for(let raw of raws){
      objects.push(this.jsonFactoryOneEnumValue(raw));
    }
    return objects;
  }

  static jsonFactoryOneEnumValue(raw: any): EnumValue {
    let menu = new EnumValue(
      raw.raw_value,
      raw.value,
      raw.sort_value,
    );
    return menu;
  }
}
