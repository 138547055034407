import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from 'src/app/buisness-object/location/Location';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LocationService } from 'src/app/service/location/location.service';
import { LoginService } from 'src/app/service/login/login.service';
import { PermissionService } from 'src/app/service/permission/permission.service';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { ReloadDataHandler } from 'src/app/utils/ReloadDataHandler';
import { environment } from 'src/environments/environment';

const menuMap = {
  '/dashboard': 1,
  '/cars': 2,
  '/customers': 3,
  '/interfaces': 4,
  '/documents': 5,
  '/advertising': 6,
  '/admin': 7,
  '/material': 8,
  '/messages': 9,
  '/drives': 10,
  '/accounting': 11,
  '/tires': 12,
  '/rent': 13
};

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public selectedMenue = 2;
  public subRout: Subscription;
  public dialogQuerySubsription: Subscription;
  public dialogChangePasswordSubscription: Subscription;
  public routerSub: Subscription;
  public versionsNr: string;
  public showActions = false;
  public showActions2 = false;
  public locationName: string = localStorage.getItem('location_name');
  public location_id = Number(localStorage.getItem('location_id'));
  public permission_group_name = localStorage.getItem('permission_group_name');
  public user = JSON.parse(localStorage.getItem('user'));

  constructor(
    private loginService: LoginService,
    private router: Router,
    private dService: DialogService,
    private tService: ThreadService,
    public authService: AuthenticationService,
    private activateRoute: ActivatedRoute,
    private vService: VehicleService,
    private permissionService: PermissionService
  ) { }

  ngOnInit(): void {
    this.setMenue(this.router.url);
    this.setDialogSubscription();
    this.setRouteListener();
    this.versionsNr = environment.VERSION;

    //this.tService.getOpenThreads().subscribe((result) => {});
    // this.locationService.getLocations(true).subscribe((result) => {
    //   this.locations = result;
    //   let i = this.locations.findIndex(l => l.locationId == this.location_id);
    //   if(i > -1) this.location = this.locations[i];
    // });

  }

  ngOnDestroy(): void {
    if(this.subRout) this.subRout.unsubscribe();
    if(this.routerSub) this.routerSub.unsubscribe();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.dialogChangePasswordSubscription) this.dialogChangePasswordSubscription.unsubscribe();
  }

  setRouteListener() {
    this.routerSub = this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd){
        this.setMenue(event.url);
      }
    })
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value && value.typ == 'logout') this.logout();
      else if(value && value.typ == 'cancel_create'){
        this.reloadRouter(value.submit_value);
      }
    });
    this.dialogChangePasswordSubscription = this.dService.closeDialogPassword$.subscribe((value) => {
      if(value) this.changePassword(value.new_password);
    })
  }

  logout() {
    new ReloadDataHandler().deactivateReloadLoop([this.vService]);
    this.loginService.logout();
  }

  setMenue(url: string) {
    this.selectedMenue = 0;
    for(const [path, menu] of Object.entries(menuMap)) {
      if(url.includes(path)) {
        this.selectedMenue = menu;
        break;
      }
    }
  }

  reload(page: string) {
    this.reloadRouter(page);
  }

  reloadRouter(page: string) {
    if(this.router.url == page || this.router.url.includes(page)){
      this.router.navigateByUrl('/login', {skipLocationChange: true}).then(() => this.router.navigate([page]));
    } else {
      var url = this.router.createUrlTree([], { relativeTo: this.activateRoute }).toString();
      this.router.navigateByUrl(page);
    }
  }

  getIcon(menu: number): string {
    switch (menu) {
      case 1: if(menu == this.selectedMenue){ return './assets/dashboard-active.png'; } return './assets/dashboard.png'; break;
      case 2: if(menu == this.selectedMenue){ return './assets/carmenuactive.svg'; } return './assets/carmenu.svg'; break;
      case 3: if(menu == this.selectedMenue){ return './assets/user-lila.svg'; } return './assets/user.svg'; break;
      case 4: if(menu == this.selectedMenue){ return './assets/interfaces-active.png'; } return './assets/interfaces.png'; break;
      case 5: if(menu == this.selectedMenue){ return './assets/document-menu-blue.svg'; } return './assets/document-menu.svg'; break;
      case 6: if(menu == this.selectedMenue){ return './assets/advertising-active.png'; } return './assets/advertising.png'; break;
      case 7: if(menu == this.selectedMenue){ return './assets/administratives-lila.svg'; } return './assets/administratives.svg'; break;
      case 8: if(menu == this.selectedMenue){ return './assets/material.png'; } return './assets/material.png'; break;
      case 9: if(menu == this.selectedMenue){ return './assets/nachrichten-lila.svg'; } return './assets/nachrichten.svg'; break;
      case 10: if(menu == this.selectedMenue){ return './assets/calenderactive.svg'; } return './assets/calender.svg'; break;
      case 11: if(menu == this.selectedMenue){ return './assets/billingactive.svg'; } return './assets/billing.svg'; break;
      case 12: if(menu == this.selectedMenue){ return './assets/tireactive.svg'; } return './assets/tire.svg'; break;
      case 13: if(menu == this.selectedMenue){ return './assets/rentactive.svg'; } return './assets/rent.svg'; break;
      default: break;
    }
  }

  logoutAction() {
    this.dService.openQuery(
      {
        title: 'Abmelden ?',
        message: '',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Ja',
        typ: 'logout',
        submit_value: null,
      }
    );
  }

  changePasswortAction() {
    this.dService.openPasswordDialog(null)
  }

  changePassword(newPassword: string) {

  }

  openWebsite() {
    window.open('http://auto-base.at/', '_blank').focus();
  }

  getNotify(): number {
    return this.tService.notify_request$.getValue();
  }

  openMail() {
    var email = document.createElement("a");
    email.href = "mailto:support@waymark.at";
    email.click();
  }
}
