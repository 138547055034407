<div id="dialog_container" *ngIf="!validToken">
  <h1>404 Keine Berechtigung</h1>
</div>

<div id="dialog_window">
  <div id="dialog_container"  *ngIf="!success && validToken">
    <h1 *ngIf="isReceive">Übergabe Bilder hochladen</h1>
    <h1 *ngIf="!isReceive">Rückgabe Bilder hochladen</h1>
    <div id="dialog_conainter_body" class="body">
      <label *ngIf="isReceive">
            Bitte kontrollieren Sie die folgenden Parameter, bevor Sie das Fahrzeug an den Kunden übergeben.
            Laden Sie zusätzliche Bilder des Fahrzeugs, sowie das unterschriebene Rücknahmeprotokoll hoch.
      </label>
      <label *ngIf="!isReceive">
          Bitte kontrollieren Sie die folgenden Parameter, bevor Sie das Fahrzeug vom Kunden übernehmen. 
          Laden Sie zusätzliche Bilder des Fahrzeugs, sowie das unterschriebene Rücknahmeprotokoll hoch.
      </label>

      <form [formGroup]="form">
        <div class="form-wrapper">
          <h3>Übgabe-Parameter</h3>
          <div class="input_wrapper">
            <label class="overview_record_lbl">Kilometerstand*</label>
            <input type="number" placeholder="---" formControlName="mileage" [class.input_invalid]="submitted && form.get('mileage').errors">
          </div>
          <div class="input_wrapper" *ngIf="isReceive">
            <label class="overview_record_lbl">Vorschäden*</label>
            <input type="text" placeholder="---" formControlName="damage" [class.input_invalid]="submitted && form.get('damage').errors">
          </div>
          <div class="input_wrapper" *ngIf="!isReceive">
            <label class="overview_record_lbl">Schäden*</label>
            <input type="text" placeholder="---" formControlName="damage" [class.input_invalid]="submitted && form.get('damage').errors">
          </div>
        </div>
      </form>

      <h3>Bilder</h3>
      <!-- HERE IS THE IMAGE OVERVIEW -->
      <div class="overview-images-wrapper">
        <div class="pic_container">
          <div class="pic_item" *ngFor="let img of currentImages;">
            <img [src]="img" class="img_car"/>
            
            <!-- TRASH ICON -->
            <div class="image_remove_icon" (click)="selectionDeleteImage(img)">
              <label class="lbl_hover">Löschen</label>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 11.959 15.159">
                <path id="Linie_2" d="M122.766,591.4l-4.176-.022v-.38a.9.9,0,0,0-1.808,0v.37l-4.167-.022h-.005a.9.9,0,0,0,0,1.808h.042l.465,10.954a1.114,1.114,0,0,0,1.08,1.145h6.991a1.115,1.115,0,0,0,1.08-1.145l.462-10.9h.037a.9.9,0,0,0,0-1.808Zm-6.575,11.161h-.026a.543.543,0,0,1-.542-.516l-.332-6.931a.542.542,0,1,1,1.083-.052l.332,6.931A.542.542,0,0,1,116.191,602.558Zm3.58-.56a.542.542,0,0,1-.541.516H119.2a.542.542,0,0,1-.516-.568l.332-6.931a.542.542,0,1,1,1.083.052Z" transform="translate(-111.706 -590.09)" fill="#2c2c2c"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
          
      <!-- HERE IS THE DROP ZONE -->
      <div id="drop-zone" [class.input_invalid]="submitted && this.currentImages == null"
        appDnd (fileDroppedEmitter)="onFileDropped($event)"
        (click)="selectFileDropZone.click()">
        <img src="./assets/upload.svg">
        <label>Klicken Sie oder ziehen Sie Dateien hier her, um sie hochzuladen</label>
        <input #selectFileDropZone
          type="file"
          accept="image/*"
          (change)="uploadFile($event)"
          multiple="true"
          style="display: none;"
          capture="environment">
      </div>
    </div>
      <!-- HERE ARE THE BUTTONS -->
    <div class="dialog_btn_container">
      <button class="btn_submit_dark" (click)="submit()">Hochladen</button>
    </div>
  </div>
</div>

<div id="dialog_container" *ngIf="success">
  <h1>Ihre Daten wurden übermittelt. Danke.</h1>
</div>