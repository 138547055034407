import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-topbar-admin',
  templateUrl: './topbar-admin.component.html',
  styleUrls: ['./topbar-admin.component.css']
})
export class TopbarAdminComponent implements OnInit {
  public showDropdown = false;
  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('dropdownOptions') dropdownOptions: ElementRef;
  @Input() surface: number;
  @Input() isEdit: boolean;
  @Input() settingsChanged: boolean;
  @Output() changeSurfaceEmitter = new EventEmitter<number>();
  @Output() saveEmployeeEmitter = new EventEmitter<undefined>();
  @Output() openCreateEmitter = new EventEmitter<undefined>();
  @Output() cancleCreateEmitter = new EventEmitter<undefined>();
  @Output() createEmployeeEmitter = new EventEmitter<undefined>();
  @Output() editEmployeeEmitter = new EventEmitter<undefined>();
  @Output() searchEmitter = new EventEmitter<string>();
  @Output() settingsVerwerfenEmitter = new EventEmitter<undefined>();
  @Output() settingsSaveEmitter = new EventEmitter<undefined>();
  @HostListener('document:keydown.escape', ['$event'])onEscHandler(event: KeyboardEvent) {
    if(this.surface == 2){
      this.changeSurfaceEmitter.emit(3);
    } else if(this.surface == 3) {
      this.changeSurfaceEmitter.emit(1);
    }
  }
  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    if(this.surface == 2) this.saveEmployeeEmitter.emit();
  }

  constructor(
    private renderer: Renderer2,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.setCloseListener();
  }

  setCloseListener() {
    this.renderer.listen('window','click', (e:Event) => {
      if(this.showDropdown){
        if(e.target !== this.dropdown.nativeElement && e.target != this.dropdownOptions.nativeElement){
          this.showDropdown = false;
        }
      }
    });
  }
}
