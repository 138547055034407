import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Bill } from 'src/app/buisness-object/document/Bill';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';

@Component({
  selector: 'app-create-offer-document',
  templateUrl: './create-offer-document.component.html',
  styleUrls: ['./create-offer-document.component.css']
})
export class CreateOfferDocumentComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() page: number;
  @Input() customers: Customer[];
  @Input() driveTyp: number; // Über Formgroup
  @Input() selectedDoc: Bill;
  @Input() submittedItem: boolean;
  @Input() position: number;
  @Input() billCreatePage: number;

  @Output() changePageEmitter = new EventEmitter<boolean>();

  public isEdit = false;

  @Output() addPriceEmitter = new EventEmitter<undefined>();
  @Output() removeEmitter = new EventEmitter<number>();

  constructor(
  ) { }

  ngOnInit(): void {
    if(this.selectedDoc){
      this.isEdit = true;
    }
  }
}
