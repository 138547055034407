import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoadingService } from "./loading.service";
import { Router } from "@angular/router";

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor{
    activeRequest = 0;

    constructor(
      private loadingScreenService: LoadingService,
      private router: Router
    ){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const skipLoading = request.headers.get('skip-loading-animation') == 'true';
      if(!skipLoading && this.activeRequest === 0) {
        this.loadingScreenService.startLoading();
      }
      if(!skipLoading) {
        this.activeRequest++;
      }
      return next.handle(request).pipe(
          finalize(() => {
              if(!skipLoading) {
                  this.activeRequest--;
              }
              if(this.activeRequest === 0) {
                  this.loadingScreenService.stopLoading();
              }
          })
      );
    }
}
