import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { getKaufvertragStatusClass, Kaufvertrag, KaufvertragFinanzierungsart, KaufvertragStatus, KaufvertragStatusLabels, VehicleStatus } from 'src/app/buisness-object/document/Kaufvertrag';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { BaseComponent } from '../../BaseComponent';
import { DocumentService } from 'src/app/service/document/document.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Zusatzprodukt } from 'src/app/buisness-object/zusatzprodukt/Zusatzprodukt';
import { EnumService } from 'src/app/service/enum/enum.service';
import { getLabelFromEnumValue } from 'src/app/utils/EnumHandler';
import { Router } from '@angular/router';
import { BaseBoxComponent } from '../../BaseBoxComponent';
import { DokumentTyp } from '../dokumente.component';
import { Employee } from 'src/app/buisness-object/employee/Employee';

export enum KaufvertragDetailsTab {
  Details = 1,
  Tickets = 2
}

@Component({
  selector: 'app-dokumente-kaufvertrag-details',
  templateUrl: './dokumente-kaufvertrag-details.component.html',
  styleUrl: './dokumente-kaufvertrag-details.component.css'
})
export class DokumenteKaufvertragDetailsComponent extends BaseBoxComponent implements OnInit, OnChanges {
  @Input() document: Kaufvertrag;
  @Input() zusatzprodukte: Zusatzprodukt[];
  @Input() employees: Employee[] = [];
  @Output() selectionBackEmitter = new EventEmitter<undefined>();
  @Output() selectionEditEmitter = new EventEmitter<undefined>();
  @Output() selectionUploadDocumentEmitter = new EventEmitter<any>();
  @Output() selectionPseudoSignatureEmitter = new EventEmitter<any>();
  @Output() selectionContractStornoEmitter = new EventEmitter<any>();
  @Output() selectionAnzahlungKassierenEmitter = new EventEmitter<any>();
  @Output() selectionEndzahlungKassierenEmitter = new EventEmitter<any>();
  @Output() selectionContractPDFUnsigendSignedEmitter = new EventEmitter<any>();
  @Output() selectionContractRenewEmitter = new EventEmitter<any>();
  @Output() selectionAppSignatureEmitter = new EventEmitter<any>();
  @Output() selectionReceiveFundingEmitter = new EventEmitter<any>();
  @Output() changeFinanzsperrauskunftEmitter = new EventEmitter<any>();
  @Output() changeAustrianDataExtractEmitter = new EventEmitter<any>();
  public tabView = KaufvertragDetailsTab;
  public showTab: KaufvertragDetailsTab = KaufvertragDetailsTab.Details;
  public registrationDocumentValue;
  public tradeInVehicleRegistrationDocumentValue;
  public paymentConditionValue
  public downPaymentConditionValue;
  public finanzierungsartenValue;
  public finanzierungsgesellschaftValue;
  public finanzsperrauskunftStatusValue;
  public salutationValue;
  public titleValue;
  public mechanical_conditionValue;
  public body_conditionValue;
  public paint_conditionValue;
  public interior_conditionValue;
  public electronic_conditionValue;
  public total_condition;
  public finanzsperrauskunftValue;
  public shwoActions = false;
  public shwoActions2 = false;
  public shwoActions3 = false;
  public kaufvertragStatus = KaufvertragStatus;
  public signedStatusLabel;
  public signedStatusClass;
  public showReceiveFundingButton = false;
  public showAdditionalDocuments = false;
  constructor(
    public authService: AuthenticationService,
    private eService: EnumService,
    private documentService: DocumentService,
    private dialogService: DialogService,
    private router: Router
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    super.init();
    this.setUp();
    this.showAdditionalDocuments = this.authService.accessVia('garantievertrag') || this.authService.accessVia('uebergabeformular') || this.authService.accessVia('werkstattauftrag');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes &&
      changes['document'] &&
      (
        changes['document'].currentValue.vertrag_status != changes['document'].previousValue?.vertrag_status ||
        changes['document'].currentValue.purchase_contract_id != changes['document'].previousValue?.purchase_contract_id ||
        changes['document'].currentValue.employee_id != changes['document'].previousValue?.employee_id
      )){
      this.setUp();
    }
  }

  setUp() {
    this.signedStatusLabel = getLabelFromEnumValue(this.document.vertrag_status, KaufvertragStatusLabels);
    this.signedStatusClass = getKaufvertragStatusClass(this.document.vertrag_status);
    this.registrationDocumentValue = this.getEnumValue('registration_document', this.document.registration_document);
    this.paymentConditionValue = this.getEnumValue('payment_condition', this.document.payment_condition);
    this.downPaymentConditionValue = this.getEnumValue('payment_condition', this.document.down_payment_condition);
    this.mechanical_conditionValue = this.getEnumValue('mechanical_condition', this.document.vehicle_status.mechanical_condition);
    this.body_conditionValue = this.getEnumValue('mechanical_condition', this.document.vehicle_status.body_condition);
    this.paint_conditionValue = this.getEnumValue('mechanical_condition', this.document.vehicle_status.paint_condition);
    this.interior_conditionValue = this.getEnumValue('mechanical_condition', this.document.vehicle_status.interior_condition);
    this.electronic_conditionValue = this.getEnumValue('mechanical_condition', this.document.vehicle_status.electronic_condition);
    this.finanzierungsartenValue = this.getEnumValue('finanzierungsart', this.document.finanzierungsart);
    this.finanzierungsgesellschaftValue = this.getEnumValue('finanzierungsgesellschaft', this.document.finanzierungsgesellschaft);
    this.tradeInVehicleRegistrationDocumentValue = this.getEnumValue('registration_document', this.document.trade_in_vehicle?.registration_document);
    this.total_condition = this.document.vehicle_status.getOverallCondition();
    this.showReceiveFundingButton = this.getShowReceiveFundingButton();
    this.finanzsperrauskunftStatusValue = this.getEnumValue('finanzsperrauskunft_status', this.document.vehicle.finanzsperrauskunft_status);
  }

  getShowReceiveFundingButton(): boolean {
    if(this.document.vertrag_status == KaufvertragStatus.SIGNED &&
      this.document.finanzierungsart == KaufvertragFinanzierungsart.FINANZIERUNGUEBERBANK &&
      this.document.finanzierungsgesellschaft == null && this.authService.isHoedl()
    ){
      return true;
    }
    return false;
  }

  getConditionColor(value: string) {
    return VehicleStatus.getConditionColor(value);
  }

  selectionBack() {
    this.selectionBackEmitter.emit();
  }

  selectionEdit() {
    this.selectionEditEmitter.emit();
  }

  openPDF() {
    this.documentService.getContractPDF(this.document.purchase_contract_id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true })
      }
    })
  }

  openRe(id: number) {
    this.documentService.getRePdf(id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true });
      }
    });
  }

  openWerkstattPDF() {
    this.documentService.getPdf('/vehicle/werkstattauftrag/',this.document.vehicle.id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true })
      }
    })
  }


  openGarantiePDF() {
    this.documentService.getPdf('/contract/garantie/',this.document.purchase_contract_id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true })
      }
    })
  }

  openUebergabeFormularPDF() {
    this.documentService.getPdf('/contract/uebergabe/',this.document.purchase_contract_id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true })
      }
    })
  }

  signPDF(contract: any){
    this.documentService.createSignatureDocument(contract).subscribe((result) => {
      if(result){
        this.dialogService.openLoaderSignature({document_typ: 3});
      }
    });
  }

  hasGarantie() {
    return this.document.selektierteZusatzprodukte.findIndex((s) => s.zusatzprodukt_id == 2) != -1;
  }

  selectionContractPDFUnsigned(){
    this.selectionContractPDFUnsigendSignedEmitter.emit({unsigned: true, document: this.document});
  }
  selectionContractPDFSigned(){
    this.selectionContractPDFUnsigendSignedEmitter.emit({unsigned: false, document: this.document});
  }

  navigateToVehicle() {
    const url = `${window.location.origin}/#/cars?carId=${this.document.vehicle?.id}`;
    window.open(url, '_blank');
  }

  navigateToCustomer() {
     const url = `${window.location.origin}/#/customers?customerId=${this.document.customer_id}`;
     window.open(url, '_blank');
  }

  selectionPseudoSignature(){
    this.documentService.uploadPDF(DokumentTyp.Kaufvertrag, this.document.purchase_contract_id, null).subscribe((success) => {
      if(success){
        this.document.vertrag_status = KaufvertragStatus.SIGNED;
        const userID = Number(localStorage.getItem('employee_id'));
        if(userID){
          const employee = this.employees.find((e) => e.id == userID);
          if(employee){
            this.document.employee_id = userID;
            this.document.signer_employee = employee;
          }
        }
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Kaufvertrag wurde signiert.',
          success: true
        });
        this.setUp();
        this.selectionPseudoSignatureEmitter.emit(document);
      }
    });
  }
}
