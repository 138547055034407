import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageLocation } from 'src/app/buisness-object/tires/StorageLocation';
import { StoragePlace } from 'src/app/buisness-object/tires/StoragePlace';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { TireService } from 'src/app/service/tire/tire.service';

@Component({
  selector: 'app-dialog-tyres-storage-space',
  templateUrl: './dialog-tyres-storage-space.component.html',
  styleUrl: './dialog-tyres-storage-space.component.css'
})
export class DialogTyresStorageSpaceComponent implements OnInit {
  @Input() obj: Tire;
  @Input() locations: StorageLocation[] = [];
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() successChangeStorageEmitter = new EventEmitter<Tire>();
  public form: FormGroup;
  public placeValues = [];

  constructor(
    private formBuilder: FormBuilder,
    private tireService: TireService,
    private dialogService: DialogService
  ){}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      location: [this.locations[0], Validators.required],
      place: [null, Validators.required]
    })
    this.setPlaces();
  }

  setPlaces() {
    this.placeValues = [];
    for(let place of this.form.get('location').value.storage_places){
      if(place.is_free) {
        this.placeValues.push({
          id: place.storage_place_id,
          name: place.place_name
        })
      }
    }
  }

  selectionLocation(location: Location) {
    this.form.get('location').setValue(location);
    this.form.get('place').setValue(null);
    this.setPlaces();
  }

  selectionPlace(place: StoragePlace){
    this.form.get('place').setValue(place);
  }

  selectionSubmit() {
    let location =this.form.get('location').value;
    let storage_place_id = this.form.get('place').value;
    let place = location.storage_places.find((p) => p.storage_place_id === storage_place_id);
    this.obj.storage_place = place;
    this.obj.storage_place_id = storage_place_id
    this.obj.vehicle = null;
    this.obj.vehicle_id = null;
    this.tireService.updateTire(this.obj).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Lagerplatz wurde geändert.',
          success: true
        });
        this.successChangeStorageEmitter.emit(result);
      }
    })
  }
}
