import { StoragePlace } from "../StoragePlace";

export class StoragePlaceFactory {

  static jsonFactory(rawBody: any): StoragePlace[] {
    const data: StoragePlace[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): StoragePlace {
      const obj = new StoragePlace(
        rawPart.storage_place_id,
        rawPart.storage_location,
        rawPart.place_name,
        rawPart.is_free,
      )
      return obj;
  }
}
