import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Customer, CustomerStatus } from 'src/app/buisness-object/customer/Customer';
import { Bill } from 'src/app/buisness-object/document/Bill';
import { Kaufvertrag, KaufvertragFinanzierungsart, KaufvertragStatus } from 'src/app/buisness-object/document/Kaufvertrag';
import { LedgerEntry, LedgerEntryStatus } from 'src/app/buisness-object/document/LedgerEntry';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Rent } from 'src/app/buisness-object/rent/Rent';
import { Tire, TireStatus } from 'src/app/buisness-object/tires/Tire';
import { VehicleEnviromentLabel } from 'src/app/buisness-object/vehicle/optional/VehicleEnviromentLabel';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';

export enum VehicleStatus {
  Archiv = 10,
  Draft = 2,
  Published = 1,
  CustomerCar = 3
}

@Component({
  selector: 'app-btn-action',
  templateUrl: './btn-action.component.html',
  styleUrl: './btn-action.component.css'
})
export class BtnActionComponent implements OnInit {
  @Input() action: string;
  @Input() object: any;
  @Output() selectionOpenDetailsEmitter = new EventEmitter<any>();
  @Output() selectionOpenDetailsEditEmitter = new EventEmitter<any>();
  @Output() selectionDeleteEmitter = new EventEmitter<any>();
  @Output() selectionMoveEmitter = new EventEmitter<any>();
  @Output() selectionPrintEmitter = new EventEmitter<any>();
  @Output() selectionEtikettEmitter = new EventEmitter<any>();
  @Output() selectionSellEmitter = new EventEmitter<any>();
  @Output() selectionDokumentEmitter = new EventEmitter<any>();
  @Output() selectionCopyEmitter = new EventEmitter<any>();
  @Output() selectionUploadDocumentEmitter = new EventEmitter<any>();
  @Output() selectionPseudoSignatureEmitter = new EventEmitter<any>();
  @Output() selectionCreateKaufvertragEmitter = new EventEmitter<any>();
  @Output() selectionArchivEmitter = new EventEmitter<any>();
  @Output() selectionDownloadEmitter = new EventEmitter<any>();
  @Output() selectionPDFCreateEmitter = new EventEmitter<any>();
  @Output() selectionPDFOpenEmitter = new EventEmitter<any>();
  @Output() selectionTestDrivePDFSignEmitter = new EventEmitter<any>();
  @Output() selectionContractPDFUnsigendSignedEmitter = new EventEmitter<any>();
  @Output() selectionRestoreEmitter = new EventEmitter<any>();
  @Output() selectionChangeStatusEmitter = new EventEmitter<any>();
  @Output() selectionChangePaymentStatusEmitter = new EventEmitter<any>();
  @Output() selectionAppSignatureEmitter = new EventEmitter<any>();
  @Output() receiveFundingEmitter = new EventEmitter<any>();
  @Output() createCreditEmitter = new EventEmitter<any>();
  @Output() changeFinanzsperrauskunftEmitter = new EventEmitter<any>();
  @Output() changeFinanzsperrauskunftStatusEmitter = new EventEmitter<any>();
  @Output() changeAustrianDataExtractEmitter = new EventEmitter<any>();
  public text: string;
  public icon: string;
  public actionDisabled = false;
  public permissions = [];
  public permissionsView: string[] = [
    'vehicle_view',
    'customer_view',
    'purchase_contract_view',
    'ledger_view',
    'bill_view',
    'test_drive_view',
    'tire_view',
    'user_view',
    'settings_view',
  ];
  public permissionsEdit: string[] = [
    'vehicle_edit',
    'customer_edit',
    'purchase_contract_edit',
    'ledger_edit',
    'bill_edit',
    'test_drive_edit',
    'tire_edit',
    'user_edit',
    'custom_bill_edit',
    'settings_edit',
  ];
  public permissionsDelete: string[] = [
    'admin',
    'vehicle_delete',
    'customer_delete',
    'purchase_contract_delete',
    'ledger_delete',
    'bill_delete',
    'test_drive_delete',
    'tire_delete',
    'user_delete',
    'custom_bill_delete'
  ];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    switch (this.action) {
      case 'details':
        this.text = 'Details';
        this.icon = './assets/eye-white.svg';
        this.permissions = this.permissionsView;
        break;
      case 'edit':
        this.text = 'Bearbeiten';
        this.icon = './assets/edit.svg';
        this.permissions = this.permissionsEdit;
        if(this.object instanceof LedgerEntry){
          if(this.object.ledger_entry_status != LedgerEntryStatus.OFFEN){
            this.actionDisabled = true;
          }
        }
        if(this.object instanceof Bill){
          if(this.object.in_accounting){
            this.actionDisabled = true;
          }
        }
        break;
      case 'delete':
        this.text = 'Löschen';
        this.icon = './assets/delete.svg';
        this.permissions = this.permissionsDelete;
        if(this.object instanceof Kaufvertrag){
          if(this.object.vertrag_status == KaufvertragStatus.SIGNED){
            this.actionDisabled = true;
          }
        } else if(this.object instanceof LedgerEntry){
          if(this.object.ledger_entry_status != LedgerEntryStatus.OFFEN
            && this.object.ledger_entry_status != LedgerEntryStatus.SYSTEMEINTRAG){
            this.actionDisabled = true;
          }
        } else if(this.object instanceof Employee){
          this.actionDisabled = true;
        } if(this.object instanceof Rent){
          const email = localStorage.getItem('email');
          if(email.includes('@waymark.at')){
            return;
          } else {
            this.actionDisabled = true
          }
        }
        break;
      case 'archiv':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Vehicle){
          if(this.object.status == VehicleStatus.Draft) this.text = 'Archivieren';
          else if(this.object.status == VehicleStatus.Archiv) this.text = 'Wiederherstellen';
          else this.actionDisabled = true;
        } else if(this.object instanceof Kaufvertrag){
          if(this.object.vertrag_status == KaufvertragStatus.OPEN) this.text = 'Archivieren';
          else this.actionDisabled = true;
        } else if(this.object instanceof Tire){
          if(this.object.status != TireStatus.ARCHIVED) this.text = 'Archivieren';
          else this.actionDisabled = true;
        } else if(this.object instanceof Customer){
          if(this.object.status != CustomerStatus.ARCHIVED) this.text = 'Archivieren';
          else this.actionDisabled = true;
        } else {
          this.actionDisabled = true;
        }
        this.icon = './assets/direction.svg'
        break;
      case 'publish':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Vehicle){
          if(this.object.status == VehicleStatus.Draft) this.text = 'Veröffentlichen';
          else if(this.object.status == VehicleStatus.Archiv) this.text = 'Wiederherstellen';
          else this.actionDisabled = true;
        } if(this.object instanceof Tire){

        } else {
          this.actionDisabled = true;
        }
        this.icon = './assets/direction.svg'
        break;
      case 'restore':
        this.permissions = this.permissionsEdit;
        this.text = 'Wiederherstellen';
        this.icon = './assets/direction.svg';
        if(this.object instanceof Tire && this.object.status != TireStatus.ARCHIVED){
          this.actionDisabled = true;
        } else if(this.object instanceof Customer && this.object.status != CustomerStatus.ARCHIVED){
          this.actionDisabled = true;
        } else if(this.object instanceof Kaufvertrag && this.object.vertrag_status != KaufvertragStatus.ARCHIVED){
          this.actionDisabled = true;
        }
        break;
      case 'sell':
        this.permissions = this.permissionsEdit;
        this.text = "Verkaufen"
        this.icon = './assets/direction.svg';
        break;
      case 'print':
        this.permissions = this.permissionsView;
        this.text = 'PDF Druck';
        this.icon = './assets/print.svg';
        break;
      case 'print-etikett':
        this.permissions = this.permissionsView;
        this.text = 'Etiketten Druck';
        this.icon = './assets/print.svg';
        break;
      case 'document':
        this.permissions = this.permissionsView;
        this.text = 'Dokument erstellen';
        this.icon = './assets/document.svg';
        break;
      case 'copy':
        this.permissions = this.permissionsView;
        this.text = 'Fahrzeug kopieren';
        this.icon = './assets/copy.svg';
        break;
      case 'upload-kaufvertrag':
        this.permissions = this.permissionsEdit;
        this.text = 'Signiertes Dokument hochladen';
        this.icon = './assets/document.svg';
        if(this.object instanceof Kaufvertrag){
          if(this.object.vertrag_status == KaufvertragStatus.SIGNED) this.actionDisabled = true;
        }
        break;
      case 'pseudo-singature-kaufvertrag':
        this.permissions = this.permissionsEdit;
        this.text = 'Dokument signieren';
        this.icon = './assets/document.svg';
        if(this.object instanceof Kaufvertrag){
          if(this.object.vertrag_status == KaufvertragStatus.SIGNED) this.actionDisabled = true;
        }
        break;
      case 'create-purchase-contract':
        this.permissions = ['purchase_contract_edit'];
        this.text = 'Kaufvertrag erstellen';
        this.icon = './assets/document.svg';
        break;
      case 'download':
        this.permissions = this.permissionsView;
        if(this.object instanceof Bill || this.object.attachment_id){
          this.text = 'Herunterladen';
        }
        this.icon = './assets/download-white.svg';
        break;
      case 'pdf-create':
        this.permissions = this.permissionsView;
        if(this.object instanceof Drive){
          if(this.object.status_signiert == 0){
            if(this.object.customer_id != null) this.text = 'PDF erstellen';
            else this.text = 'PDF öffnen'
          } else {
            this.actionDisabled = true;
          }
        }
        this.icon = './assets/download-white.svg';
        break;
      case 'pdf-open':
        this.permissions = this.permissionsView;
        if(this.object instanceof Drive){
          this.text = 'PDF öffnen';
          if(this.object.status_signiert == 1) {
            this.text = 'PDF öffnen';
          } else {
            this.actionDisabled = true;
          }
        }
        this.icon = './assets/download-white.svg';
        break;
      case 'pdf-sign':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Drive){
          this.text = 'PDF signieren';
        }
        this.icon = './assets/download-white.svg';
        break;
      case 'purchase-contract-pdf-unsigned':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Vehicle){
          this.text = 'Interne Probefahrt erstellen';
        } else {
          this.actionDisabled = true
        }
        this.icon = './assets/direction.svg';
        break;
      case 'test-drive-create-customer':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Vehicle){
          this.text = 'Probefahrt erstellen';
        } else {
          this.actionDisabled = true
        }
        this.icon = './assets/direction.svg';
        break;
      case 'test-drive-create-intern':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Vehicle){
          this.text = 'Interne Probefahrt erstellen';
        } else {
          this.actionDisabled = true
        }
        this.icon = './assets/direction.svg';
        break;
      case 'tire-change-storage':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Vehicle){
          this.text = 'Interne Probefahrt erstellen';
        } else {
          this.actionDisabled = true
        }
        this.icon = './assets/direction.svg';
        break;
      case 'tire-installation':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Vehicle){
          this.text = 'Interne Probefahrt erstellen';
        } else {
          this.actionDisabled = true
        }
        this.icon = './assets/direction.svg';
        break;
      case 'popup-change-status':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Kaufvertrag && this.object.vertrag_status == KaufvertragStatus.SIGNED && true){
          this.text = 'Status ändern';
        } else {
          this.actionDisabled = true;
        }
        this.icon = './assets/direction.svg';
        break;
      case 'app-signature':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Kaufvertrag && this.object.vertrag_status == KaufvertragStatus.OPEN){
          this.text = 'App Signatur';
        } else {
          this.actionDisabled = true;
        }
        this.icon = './assets/direction.svg';
        break;
      case 'receive-funding':
           this.permissions = this.permissionsEdit;
        this.icon = './assets/direction.svg';
        if(this.object instanceof Kaufvertrag &&
          this.object.vertrag_status == KaufvertragStatus.SIGNED &&
          this.object.finanzierungsart == KaufvertragFinanzierungsart.FINANZIERUNGUEBERBANK &&
          this.object.finanzierungsgesellschaft == null){
          this.text = 'Finanzierung erhalten';
        } else {
          this.actionDisabled = true;
        }
        break;
      case 'credit':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Vehicle){
          this.text = 'Gutschrift';
        } else {
          this.actionDisabled = true;
        }
        this.icon = './assets/direction.svg';
        break;
      case 'change_finanzsperrauskunft':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Kaufvertrag){
            this.text = 'Typenschein ändern';
        } else {
            this.actionDisabled = true;
        }
        this.icon = './assets/direction.svg';
        break;
      case 'change_finanzsperrauskunft_status':
        this.permissions = this.permissionsEdit;
        if(this.object instanceof Kaufvertrag){
            this.text = 'Finanzsperrauskunft ändern';
        } else {
            this.actionDisabled = true;
        }
        this.icon = './assets/direction.svg';
        break;
       case 'change_austrian_data_extract':
        this.permissions = this.permissionsEdit;
          if(this.object instanceof Kaufvertrag){
              this.text = 'Datenauszug ändern';
          } else {
              this.actionDisabled = true;
          }
          this.icon = './assets/direction.svg';
          break;
      default: this.actionDisabled = true; break;
    }
  }

  selectionAction() {
    switch (this.action) {
      case 'details':
        this.selectionOpenDetailsEmitter.emit(this.object);
        break;
      case 'sell':
        this.selectionSellEmitter.emit(this.object);
        break;
      case 'edit':
        this.selectionOpenDetailsEditEmitter.emit(this.object);
        break;
      case 'delete':
        this.selectionDeleteEmitter.emit(this.object);
        break;
      // case 'move':
      //   this.selectionMoveEmitter.emit(this.getMoveEmitterData());
      //   break;
      case 'print':
        this.selectionPrintEmitter.emit(this.object);
        break;
      case 'print-etikett':
        this.selectionEtikettEmitter.emit(this.object);
        break;
      case 'document':
        this.selectionDokumentEmitter.emit(this.object);
        break;
      case 'copy':
        this.selectionCopyEmitter.emit(this.object);
        break;
      case 'upload-kaufvertrag':
        this.selectionUploadDocumentEmitter.emit(this.object);
        break;
      case 'pseudo-singature-kaufvertrag':
        this.selectionPseudoSignatureEmitter.emit(this.object);
        break;
      case 'create-purchase-contract':
        this.selectionCreateKaufvertragEmitter.emit(this.object);
        break;
      case 'archiv':
        if(this.object instanceof Vehicle){
          //archiv, status = 10, platform_online = null, website_online = null, customer_id = null
          //entwurf, status = 2, platform online = null, website = null, customer = null
          let status = this.object.status == VehicleStatus.Archiv ? VehicleStatus.Draft : VehicleStatus.Archiv;
          if(this.text == 'Archivieren') status = VehicleStatus.Archiv;
          else if(this.text == 'In Entwürfe verschieben') status = VehicleStatus.Draft;
          this.object.platform_online = false;
          this.object.website_online = false;
          this.object.mobile_online = false;
          this.object.freigeschaltet_am_web = null;
          this.object.freigeschaltet_am_plattform = null;
          this.object.customer_id = null;
          this.selectionMoveEmitter.emit({ vehicle: this.object, status: status});
        } else if(this.object instanceof Kaufvertrag) {
          this.selectionMoveEmitter.emit({ contract: this.object, move: 'archiv'});
        } else if(this.object instanceof Tire) {
          this.selectionArchivEmitter.emit(this.object);
        } else if(this.object instanceof Customer) {
          this.selectionArchivEmitter.emit(this.object);
        }
        break;
      case 'publish':
        let s;
        if(this.text == 'Veröffentlichen'){
          s = VehicleStatus.Published;
        } else if(this.text == 'In Entwürfe verschieben') {
          s = VehicleStatus.Draft;
          this.object.platform_online = false;
          this.object.website_online = false;
          this.object.mobile_online = false;
          this.object.freigeschaltet_am_web = null;
          this.object.freigeschaltet_am_plattform = null;
          this.object.customer_id = null;
          this.object.customer = null;
        }
        this.selectionMoveEmitter.emit({ vehicle: this.object, status: s});
        break;
      case 'restore':
        this.selectionRestoreEmitter.emit(this.object);
        break;
      case 'download':
        this.selectionDownloadEmitter.emit(this.object);
        break;
      case 'pdf-create':
        this.selectionPDFCreateEmitter.emit(this.object);
        break;
      case 'pdf-open':
        this.selectionPDFOpenEmitter.emit(this.object);
        break;
      case 'pdf-sign':
        this.selectionTestDrivePDFSignEmitter.emit(this.object);
        break;
      case 'pdf-unsigned':
        this.selectionContractPDFUnsigendSignedEmitter.emit({unsigned: true, document: this.object});
        break;
      case 'test-drive-create-customer':
        if(this.object instanceof Vehicle){
          this.router.navigate(['drives/create'], { queryParams: { vehicleId: this.object.id, typ: 1 } })
        }
        break;
      case 'test-drive-create-intern':
        if(this.object instanceof Vehicle){
          this.router.navigate(['drives/create'], { queryParams: { vehicleId: this.object.id, typ: 2 } })
        }
        break;
      case 'popup-change-status':
        if(this.object instanceof Kaufvertrag){
          this.selectionChangePaymentStatusEmitter.emit(this.object);
        }
      break;
      case 'app-signature':
        if(this.object instanceof Kaufvertrag){
          this.selectionAppSignatureEmitter.emit(this.object);
        }
      case 'receive-funding':
        if(this.object instanceof Kaufvertrag){
          this.receiveFundingEmitter.emit(this.object);
        }
      break;
      case 'credit':
        if(this.object instanceof Vehicle){
          this.createCreditEmitter.emit(this.object);
        }
      break;

      case 'tire-change-storage': break;

      case 'tire-installation': break;

      case 'tire-installation': break;

      case 'change_finanzsperrauskunft':
        if(this.object instanceof Kaufvertrag){
          this.changeFinanzsperrauskunftEmitter.emit(this.object);
        }
        break;
      case 'change_finanzsperrauskunft_status':
        if(this.object instanceof Kaufvertrag){
          this.changeFinanzsperrauskunftStatusEmitter.emit(this.object);
        }
        break;
      case 'change_austrian_data_extract':
        if(this.object instanceof Kaufvertrag){
          this.changeAustrianDataExtractEmitter.emit(this.object);
        }
        break;

      default: break;
    }
  }


  getMoveEmitterData() {
    let s = 1;
    if(this.object.status == 3) {
      s = 2;
    } else if(this.object.platform_online){
      s = 2; //-> Entwurf
    } else if(!this.object.platform_online){
      s = 1 //-> Veröffentlichen
    }
    /*if(this.vehicle.status != 3 && !this.vehicle.customer_id){
      s = 3 //-> Archivieren
    }
    if(this.vehicle.status == 3){
      s = 2 //-> Wiederherstellen
    }*/
    return {vehicle: this.object, status: s};
  }
}
