<div id="dialog_window">
    <form id="dialog_container" [formGroup]="form">
        <div class="lbl_bold">PDF erstellen</div>
        <div id="dialog_body">
            <div class="inner_title">Lenkberechtigung</div>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Nummer *</div>
              <input type="text" placeholder="Nummer" formControlName="authorization_number" [class.input_invalid]="submitted && form.controls.authorization_number.errors">
            </div>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Behörde *</div>
              <input type="text" placeholder="Behörde" formControlName="authorization_authority" [class.input_invalid]="submitted && form.controls.authorization_authority.errors">
            </div>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Gültig bis (TT.MM.JJJJ)</div>
              <input #inputDate1 type="date" placeholder="date" formControlName="authorization_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls.authorization_date.errors">
            </div>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Gruppe(n) *</div>
              <input type="text" placeholder="Gruppe(n)" formControlName="authorization_groups" [class.input_invalid]="submitted && form.controls.authorization_groups.errors">
            </div>
            <div class="inner_title" style="margin-top: 30px">Fahrtangaben</div>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Örtlich (von/nach) *</div>
              <input type="text" placeholder="Örtlich (von/nach)" formControlName="trip_description" [class.input_invalid]="submitted && form.controls.trip_description.errors">
            </div>
        </div>
        <div class="btn_container">
          <div class="btn_label" (click)="cancelEmitter.emit()">Abbrechen</div>
          <div class="btn_container_inner">
            <input class="btn_submit_dark" type="button" value="Erstellen" (click)="onSubmit(false)">
            <input *ngIf="authService.isSinhuber()" class="btn_submit_dark" type="button" value="Erstellen und signieren" (click)="onSubmit(true)">
          </div>
        </div>
    </form>
  </div>
