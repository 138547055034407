<div id="page-menu-bar" class="header-base-style" *ngIf="surface == allSurfaces.LIST">
  <app-restricted-button
    [name]="'Neu'"
    [access]="'vehicle_edit'"
    [isPrimary]="true"
    (clickEmitter)="showCreateEmitter.emit(2)">
  </app-restricted-button>
  <div id="main-menu-wrapper" *ngIf="!showMainMenuAsDropdown">
    <button *ngFor="let mainMenu of mainMenus"
      [class.main-menu-btn-selected]="mainMenu.getActive()"
      (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive())">
      {{mainMenu.name}}
    </button>
  </div>

  <button id="main-menu-wrapper-dropdown" class="button_type_2" *ngIf="showMainMenuAsDropdown"
    (click)="shwoMainMenuDropdown = !shwoMainMenuDropdown"
    appDropdown
    [show]="shwoMainMenuDropdown"
    (changedValueEmitter)="shwoMainMenuDropdown = $event"
    style="max-width: 210px;">
    {{initialMenuName}}
    <img src="./assets/dropdown-arrow-blue.svg">
    <div *ngIf="shwoMainMenuDropdown">
      <button *ngFor="let mainMenu of mainMenus"
        [class.menu-button-dropdown]="showMainMenuAsDropdown"
        (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive()); shwoMainMenuDropdown = false; initialMenuName = mainMenu.name">
        {{mainMenu.name}}
      </button>
    </div>
  </button>
  <div></div>
</div>

<div class="topbar_inner" *ngIf="surface == allSurfaces.CREATE">
  <div class="btn_cancel_container" (click)="cancelCreateEmitter.emit()" *ngIf="!editMode">
      <img class="icon_action" src="./assets/cancle.svg">
      <button type="button" id="btn-cancel">Abbrechen</button>
  </div>
  <input *ngIf="currentPageCreate != 1" [disabled]="true" class="input_title" placeholder="Marke, Modell, Ausführung" [(ngModel)]="vehicleTitle">
  <button *ngIf="currentPageCreate >= 4 && !vinFlag" class="btn_submit" (click)="saveAsDraftEmitter.emit()">Entwurf speichern</button>
</div>

<div class="topbar_inner" *ngIf="(surface == allSurfaces.DETAIL || surface == allSurfaces.TICKET || surface == allSurfaces.KALKULATION || surface == allSurfaces.DOCUMENT)">
  <div class="btn_cancel_container" (click)="closeDetailsEmitter.emit(1)" *ngIf="!editMode && (surfacePriceSheet != 1 && surfacePriceSheet != 2)">
      <img class="icon_action" src="./assets/back.svg">
      <button id="btn-cancel">Zurück</button>
  </div>
  <div class="btn_cancel_container" (click)="cancelEdit()" *ngIf="editMode && (surfacePriceSheet != 1 && surfacePriceSheet != 2)">
    <img class="icon_action" src="./assets/cancle.svg">
    <button id="btn-cancel">Abbrechen</button>
  </div>
  <div class="btn_cancel_container" (click)="selectionPriceSheetCancel.emit()" *ngIf="surface == allSurfaces.KALKULATION && (surfacePriceSheet == 1 || surfacePriceSheet == 2)">
    <img class="icon_action" src="./assets/cancle.svg">
    <button id="btn-cancel">Abbrechen</button>
  </div>

  <div class="vehicle_title_container">
    <div *ngIf="authService.isSinhuber()">
      <input class="input_title" [disabled]="true" placeholder="Marke, Modell, Ausführung" [(ngModel)]="vehicleTitle">
      <input class="input_sub_title" [disabled]="true" [(ngModel)]="vehicleSubTitle">
    </div>
    <div *ngIf="!authService.isSinhuber()" id="vehicle-title-wrapper">
      <label class="">{{selectedVehicle.externalId ? selectedVehicle.externalId : selectedVehicle.id}}</label>
      <div>
        <label>{{vehicleTitle}}</label>
        <label>{{vehicleSubTitle}}</label>
      </div>
    </div>
  </div>

  <div *ngIf="editMode && editTyp==2" class="btn_white" (click)="showPreisblatt()">Vorschau</div>
  <div *ngIf="editMode" class="btn_submit" (click)="updateVehicle()">Speichern</div>
  <div *ngIf="!editMode" class="btn_container">
     <button class="btn_white" *ngIf="editMode && (surface == 3 || surface == 4)" (click)="editVehicle(false)">Abbrechen</button>
     <app-restricted-button
        *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2 || selectedDetailsTab == 5)  && showEdit"
        [name]="'Fahrzeug bearbeiten'"
        [access]="'vehicle_edit'"
        [isSecondary]="true"
        (clickEmitter)="selectionEditVehicle.emit(true)">
      </app-restricted-button>
      <app-restricted-button
        *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2 || selectedDetailsTab == 5) && showEditDescription"
        [name]="'Beschreibung bearbeiten'"
        [access]="'vehicle_edit'"
        [isSecondary]="true"
        (clickEmitter)="selectionEditDescrtiption.emit(true)">
      </app-restricted-button>
      <button
        *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2 || selectedDetailsTab == 5)"
        (click)="shwoActions = !shwoActions"
        appDropdown
        [show]="shwoActions"
        class="button_type_2 button_blue"
        (changedValueEmitter)="shwoActions = $event">
        Erstellen
        <img src="./assets/arrow-light.svg">
        <div *ngIf="shwoActions && (selectedDetailsTab == 1 || selectedDetailsTab == 2 || selectedDetailsTab == 5)">
          <app-restricted-button
            *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2 || selectedDetailsTab == 5) && authService.isHoedl()"
            [name]="'Preisblatt'"
            [access]="'vehicle_edit'"
            [isSecondary]="true"
            [isDropdownButton]="true"
            (clickEmitter)="selectionPrintPreisblatt.emit()">
          </app-restricted-button>
          <app-restricted-button
            *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2)"
            [name]="'Etikett'"
            [access]="'label_print'"
            [isSecondary]="true"
            [isDropdownButton]="true"
            (clickEmitter)="selectionEtikettenDruckEmitter.emit()">
          </app-restricted-button>
          <app-restricted-button
            *ngIf="authService.isStandard()"
            [name]="'PDF-Druck'"
            [access]="'vehicle_edit'"
            [isSecondary]="true"
            [isDropdownButton]="true"
            (clickEmitter)="selectionPrintEmitter.emit(selectedVehicle)">
          </app-restricted-button>
          <app-restricted-button
            [name]="'Kaufvertrag erstellen'"
            [access]="'vehicle_edit'"
            [isSecondary]="true"
            [isDropdownButton]="true"
            (clickEmitter)="goToCreateDocument(4)">
          </app-restricted-button>
        </div>
      </button>

     <div style="display: flex; column-gap: 15px;">
      <app-restricted-button
        *ngIf="surface == 5 && surfacePriceSheet == 3"
        [name]="!authService.isHoedl() ? 'Vorkalkulation' : 'Planrechnung bearbeiten'"
        [access]="'user_edit'"
        [isSecondary]="true"
        (clickEmitter)="selectionKalkulation.emit(1)">
      </app-restricted-button>
      <app-restricted-button
        *ngIf="surface == 5 && surfacePriceSheet == 3 && !authService.isHoedl()"
        [name]="!authService.isHoedl() ? 'Nachkalkulation' : 'Istrechnung bearbeiten'"
        [access]="'user_edit'"
        [isSecondary]="true"
        (clickEmitter)="selectionKalkulation.emit(2)">
      </app-restricted-button>
      <app-restricted-button
        *ngIf="surface == 5 && (surfacePriceSheet == 1 || surfacePriceSheet == 2)"
        [name]="'Speichern'"
        [access]="'user_edit'"
        [isPrimary]="true"
        (clickEmitter)="selectionPriceSheetSave.emit(surfacePriceSheet)">
      </app-restricted-button>
     </div>
  </div>
</div>
