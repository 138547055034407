import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { DocumentService } from 'src/app/service/document/document.service';

@Component({
  selector: 'app-attachment-viewer',
  templateUrl: './attachment-viewer.component.html',
  styleUrls: ['./attachment-viewer.component.css']
})
export class AttachmentViewerComponent implements OnInit, OnDestroy {
  public subscription: Subscription;
  public show = false;
  public pdf: any;
  public mime_typ: any;
  public customeAction: any;

  constructor(
    private dialogService: DialogService,
    private documentService: DocumentService
  ) {
    this.subscription = this.dialogService.openAttachmentViewer_$.subscribe((data: any) => {
      if(data){
        this.show = true;
        this.pdf = data.pdf;
        this.mime_typ = data.mime_typ;
        this.customeAction = data.custome_action;
        if(this.pdf != null){
          setTimeout(() => {
            this.setData(data.isArrayBuffer, this.mime_typ);
          }, 200);
        }
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setData(isArrayBuffer: boolean, mimeTyp: string) {
    let arraybuffer;
    if(isArrayBuffer){
      arraybuffer = this.pdf
    } else {
      arraybuffer = this.documentService.base64ToArrayBuffer(this.pdf);
    }
    mimeTyp = mimeTyp ? mimeTyp.toLowerCase().trim() : 'pdf';
    let type = 'application/pdf';
    if(mimeTyp == 'docx') type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    const blob = new Blob([arraybuffer], { type: type });
    const objectUrl = window.URL.createObjectURL(blob);
    const attachmentViewer = document.getElementById("attachment-viewer");
    if(attachmentViewer){
      attachmentViewer.querySelector("iframe").setAttribute("src", objectUrl);
    }
  }

  submit() {
    this.dialogService.closeAttachementViewer({
      submit_value: this.customeAction
    });
  }
}
