import { Customer } from "../customer/Customer";
import { BillItem } from "./BillItem";
import { Kaufvertrag } from "./Kaufvertrag";

export enum PaymentMethod {
  CASH = 1,
  BANK_TRANSFER = 2,
  FINANCING = 3
}

export const PaymentMethodLabels: { [key in PaymentMethod]: string } = {
  [PaymentMethod.CASH]: 'Bar',
  [PaymentMethod.BANK_TRANSFER]: 'Überweisung',
  [PaymentMethod.FINANCING]: 'Finanzierung'
};

export enum BillStatus {
  DRAFT = 0,
  READY = 1,
  SENT = 2,
  WAITING = 3,
  PAID = 4,
  CANCELED = 5,
  STORNO = 6,
}

export const BillStatusLabels: { [key in BillStatus]: string } = {
  [BillStatus.DRAFT]: 'Offen',
  [BillStatus.READY]: 'Offen',
  [BillStatus.SENT]: 'Offen',
  [BillStatus.WAITING]: 'Offen',
  [BillStatus.PAID]: 'Bezahlt',
  [BillStatus.CANCELED]: 'Canceled',
  [BillStatus.STORNO]: 'Storniert',
};

// export function getBillStatusLabelArray(): string[] {
//   return Object.values(BillStatusLabels);
// }

export function getBillStatusLabelStyle(status: BillStatus): string {
  switch (status) {
    case BillStatus.DRAFT: return 'lbl_color_wrapper_red'; break;
    case BillStatus.READY: return 'lbl_color_wrapper_red'; break;
    case BillStatus.SENT: return 'lbl_color_wrapper_red'; break;
    case BillStatus.WAITING: return 'lbl_color_wrapper_red'; break;
    case BillStatus.PAID: return 'lbl_color_wrapper_green'; break;
    case BillStatus.CANCELED: return 'lbl_color_wrapper_gray'; break;
    case BillStatus.STORNO: return 'lbl_color_wrapper_gray'; break;
    default: return ''; break;
  }
}

export enum BillType {
  VEHICLE = 1,
  SIMPLE = 2
}

export class Bill {
  public customer: Customer;
  public bill_recipient: Customer;
  public purchase_contract: Kaufvertrag;
  public total_netto: number;
  public total_brutto: number;
  constructor(
    public bill_id: number,
    public custom_bill_id: number,
    public type: number,
    public bill_date: number,
    public location_id: number,
    public customer_id: number,
    public payment_method: number,
    public status: number,
    public in_accounting: boolean,
    public bill_items: BillItem[],
    public purchase_contract_id: number,
    public subject: string,
    public saved_bill_recipient_id: number,
    public reference_bill_id: number,
    public is_storno_bill: boolean,
    public custom_text: string,
  ){
  }

  getFileName(): string {
    if(this.reference_bill_id == null) return 'Rechnung M'+this.custom_bill_id;
    else return 'Storno M'+this.custom_bill_id;
  }

  getTotalBrutto(): number {
    if(this.purchase_contract != null){
      return this.purchase_contract.getVetragssumme();
    } else {
      let value = 0;
      for(let item of this.bill_items){
        value+=item.price;
      }
      return value;
    }
  }

  getStatus(): string {
    if(this.is_storno_bill) return 'Storno-RE';
    else {
      switch (this.status) {
        case BillStatus.DRAFT: return 'Offen'; break;
        case BillStatus.READY: return 'Offen'; break;
        case BillStatus.SENT: return 'Offen'; break;
        case BillStatus.WAITING: return 'Offen'; break;
        case BillStatus.PAID: return 'Bezahlt'; break;
        case BillStatus.CANCELED: return 'Canceled'; break;
        case BillStatus.STORNO: return 'Storniert'; break;
        default: return '---'; break;
      }
    }
  }

  getStatusStyle(): string {
    if(this.is_storno_bill) return 'lbl_color_wrapper_gray';
    else {
      return getBillStatusLabelStyle(this.status);
    }
  }

  getJSON(): any {
    let billItemsJson = [];
    for(let item of this.bill_items){
      billItemsJson.push(item.getJSON());
    }
    return  {
      bill_id: this.bill_id,
      custom_bill_id: this.custom_bill_id,
      type: this.type,
      bill_date: this.bill_date,
      location_id: this.location_id,
      customer_id: this.customer_id,
      payment_method: this.payment_method,
      status: this.status,
      in_accounting: this.in_accounting,
      bill_item: billItemsJson,
      purchase_contract_id: this.purchase_contract_id,
      subject: this.subject,
      saved_bill_recipient_id: this.saved_bill_recipient_id,
      reference_bill_id: this.reference_bill_id,
      is_storno_bill: this.is_storno_bill,
      custom_text: this.custom_text,
      customer: this.customer?.getJSON(),
      bill_recipient: this.bill_recipient?.getJSON(),
      purchase_contract: this.purchase_contract?.getJSON(),
    }
  }
}
