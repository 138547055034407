import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { DriveForm } from 'src/app/buisness-object/drives/form/DriveForm';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { DateHelper } from 'src/app/utils/DateHelper';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { DrivesService } from 'src/app/service/drives/drives.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Observable, Subscription } from 'rxjs';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { catchError, map } from 'rxjs/operators';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { VehicleForm } from 'src/app/buisness-object/vehicle/form/VehicleForm';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';

@Component({
  selector: 'app-test-drive-create',
  templateUrl: './test-drive-create.component.html',
  styleUrl: './test-drive-create.component.css'
})
export class TestDriveCreateComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() object: Drive;
  @Input() typ: string;
  @Input() vehicles: Vehicle[];
  @Input() employees: Employee[];
  @Input() drives: Drive[];
  @Input() customers: Customer[];

  public form: FormGroup;
  public vehicleValues = [];
  public platesValues = [];
  public selectedPlateValue = [];
  public selectedVehicle: Vehicle;
  public customerValues = [];
  public employeeValues = [];
  public submitted = false;
  public formValuesChanged = false;
  public formValuesChangedCustomer = false;
  public dialogQuerySubsription: Subscription;

  @Output() selectionCancelEmitter = new EventEmitter<undefined>();
  @Output() createSuccessEmitter = new EventEmitter<any>();
  @Output() updateSuccessEmitter = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private eService: EnumService,
    private driveService: DrivesService,
    private dialogService: DialogService,
    private customerService: CustomerService,
    private vehicleService: VehicleService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.setup();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  get formCustomer() {
    return this.form.get('customer_form').value;
  }

  get formVehicle() {
    return this.form.get('vehicle_form').value;
  }

  setup() {
    this.form = DriveForm.getFormGroup(this.formBuilder);
    if(this.form.get('drive_typ').value == null){
      this.form.get('drive_typ').setValue(this.typ == 'customer' ? 1 : 2);
      DriveForm.updateTypValidity(this.form);
    }
    this.form.get('customer').valueChanges.subscribe((change) => {
      if(change){
        if(this.formCustomer){
          this.formCustomer.get('authorization_number').setValidators(Validators.required);
          this.formCustomer.get('authorization_authority').setValidators(Validators.required);
          this.formCustomer.get('authorization_date').setValidators(Validators.required);
          this.formCustomer.get('authorization_groups').setValidators(Validators.required);
        }
      }
    });
    this.employeeValues = this.getDropdownValuesEmployee(this.employees);
    this.platesValues = this.getEnumValues('license_plate_id');
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.form.controls['license_plate_id'].value);
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
    if(this.authService.accessVia('test_drive_create_vehicle')) {
      this.vehicleValues.unshift({
        id: 0,
        name: 'Neues Fahrzeug anlegen'
      });
    }
    this.customerValues = this.getDropdownValuesCustomer(this.customers);
    this.form.controls['start_date'].setValue(DateHelper.getConvertedDateForInputFull(new Date().getTime()));
    this.form.controls['start_time'].setValue(DateHelper.getConvertedTimeForInputFull(new Date().getTime()));
    setTimeout(() => {
      this.form.valueChanges.subscribe((changes) => {
        if(changes){
          this.formValuesChanged = true;
        }
      })
    }, 200);
    this.setDialogSubscription();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_edit') this.selectionCancelEmitter.emit();
      }
    });
  }

  selectionCancel() {
    if(this.formValuesChanged){
      this.dialogService.openQuery(
        {
          title: 'Bearbeitung abbrechen',
          message: 'Sind sie sicher, dass Sie die Bearbeitung abbrechen möchten? Alle Änderungen gehen dadurch verloren.',
          btn_cancel_txt: 'Weiter bearbeiten',
          btn_submit_txt: 'Beenden',
          typ: 'cancel_edit',
          submit_value: null,
        }
      );
    } else {
      this.selectionCancelEmitter.emit();
    }
  }

  getDropdownValuesEmployee(employees: Employee[]): any[] {
    let values = [];
    for(let value of employees){
      values.push({
        id: value.id,
        name: value.firstName + ' ' + value.lastName
      })
    }
    return values;
  }

  setPlate(value) {
    this.form.controls['license_plate_id'].setValue(value);
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.form.controls['license_plate_id'].value);
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'vehicle'){
      if(id == 0){
        this.form.get('vehicle_to_create').setValue(true);
        this.form.get('vehicle').removeValidators(Validators.required)
        this.form.get('vehicle').updateValueAndValidity();
        this.formVehicle.get('created').setValue(new Date().getTime());
      } else {
        let index = this.vehicles.findIndex(obj => obj.id == id);
        if(index > -1){
          this.form.controls.vehicle.setValue(this.vehicles[index]);
          this.form.get('vehicle_to_create').setValue(false);
          this.form.get('vehicle').addValidators(Validators.required)
          this.form.get('vehicle').updateValueAndValidity();
          this.form.get('vehicle_form').setValue(VehicleForm.getVehicleForm(this.formBuilder));
        }
      }
    } else if(value === 'customer'){
      let index = this.customers.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['customer'].setValue(this.customers[index]);
      }
    } else if(value === 'employee'){
      let index = this.employees.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['employee'].setValue(this.employees[index]);
      }
    }
  }

  isInvalidForm(): boolean {
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return true;
    }
    if(this.formCustomer && this.formCustomer.invalid){
      ErrorHelper.showFormError(this.formCustomer);
      return true;
    }
    if(this.form.get('vehicle_to_create').value == true && this.formVehicle.invalid){
      ErrorHelper.showFormError(this.formVehicle);
      return true;
    }
    return false;
  }

  async selectionCreate() {
    this.submitted = true;
    if(this.isInvalidForm()) return;
    let drive = DriveForm.getObject(this.form);
    if(this.typ == 'customer'){
      const customer = CustomerForm.createObject(this.formCustomer);
      if(this.form.get('customer_changed').value == true){
        await this.updateCustomer(customer).toPromise();
      }
      drive.customer_id = customer.id;
      drive.customer = customer;
    }
    let newVehicle;
    if(this.form.get('vehicle_to_create').value == true){
      newVehicle = VehicleForm.createObject(this.formVehicle);
      newVehicle = await this.createVehicle(newVehicle).toPromise();
    }
    if(newVehicle){
      drive.vehicle_id = newVehicle.id;
      drive.vehicle = newVehicle;
    } else {
      drive.vehicle_id = drive.vehicle.id;
      drive.vehicle = drive.vehicle;
    }
    let createDrive = await this.createDrive(drive).toPromise();
    createDrive.status_signiert = 0;
    createDrive.customer = drive.customer
    createDrive.employee = drive.employee;
    if(newVehicle){
      createDrive.vehicle = newVehicle;
    } else {
      createDrive.vehicle = drive.vehicle;
    }
    this.createSuccessEmitter.emit(createDrive);
  }

  createDrive(drive: Drive): Observable<any> {
    return this.driveService.createDrive(drive).pipe(
      map((result) => {
        if(result){
          return result;
        }
      }), catchError((error) => {
        return null;
      })
    );
  }

  updateCustomer(customer: Customer): Observable<any> {
    return this.customerService.updateCustomer(customer).pipe(
      map((result) => {
        if(result){
          return result;
        }
      }), catchError((error) => {
        return null;
      })
    );
  }

  createVehicle(vehicle: Vehicle): Observable<any> {
    return this.vehicleService.createVehicle(vehicle).pipe(
      map((result) => {
        if(result){
          return result;
        }
      }), catchError((error) => {
        return null;
      })
    );
  }
}
