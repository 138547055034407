export class Transaction {
  public icon: string;
  public dataTypStr: string;
  public color: string;
  constructor(
      public transactionId: number,
      public transactionTyp: number,
      public employeeId: number,
      public dataTyp: number,
      public referenceId: number,
      public text: string,
      public timestamp: number,
      public username: string,
      public changes: TransactionChange[]
  ){}
}

export class TransactionChange {
  constructor(
      public tranaction_change_id: number,
      public transaction_id: number,
      public property_name: string,
      public property_type: string,
      public display_name: string,
      public display_format: string,
      public old_value: string,
      public new_value: string
  ){}
}

