export class CloneObject {
  public static deepCopy<T>(source: T): T {
    return Array.isArray(source)
    ? source.map(item => this.deepCopy(item))
    : source instanceof Date
    ? new Date(source.getTime())
    : source && typeof source === 'object'
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
             Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
             o[prop] = this.deepCopy((source as { [key: string]: any })[prop]);
             return o;
          }, Object.create(Object.getPrototypeOf(source)))
    : source as T;
  }
}

// export class CloneObject {

//   public static deepCopy<T>(source: T): T {
//     // Handle arrays
//     if (Array.isArray(source)) {
//       return source.map(item => this.deepCopy(item)) as any;
//     }

//     // Handle Date objects
//     if (source instanceof Date) {
//       return new Date(source.getTime()) as any;
//     }

//     // Handle plain objects
//     if (source && typeof source === 'object') {
//       // Create a new object with the same prototype as the source
//       const copiedObj = Object.create(Object.getPrototypeOf(source));

//       // Copy over all properties, including non-enumerable ones
//       Object.getOwnPropertyNames(source).forEach(prop => {
//         const descriptor = Object.getOwnPropertyDescriptor(source, prop);
//         if (descriptor) {
//           Object.defineProperty(copiedObj, prop, descriptor);

//           // Recursively deep copy if the property is an object
//           if (typeof source[prop] === 'object' && source[prop] !== null) {
//             copiedObj[prop] = this.deepCopy(source[prop]);
//           } else {
//             // Copy primitive values directly
//             copiedObj[prop] = source[prop];
//           }
//         }
//       });

//       return copiedObj;
//     }

//     // Return primitive values as is
//     return source as T;
//   }
// }
