<div class="view">
    <div id="calendar-header" class="header">
        <div *ngIf="selectedVehicle" id="header_vehicle_details">
            <div class="img_container">
                <img *ngIf="selectedVehicle.pictures && selectedVehicle.pictures.length != 0" class="img" [src]="selectedVehicle.thumbnail ? selectedVehicle.thumbnail : ''">
                <div *ngIf="selectedVehicle.pictures.length == 0" class="img_empty">Kein Bild</div>
            </div>
            <div class="vehicle_data_flex">
                <label class="lbl_regular_italic_light_color">Fahrzeug-ID</label>
                <div class="lbl_list_data">{{selectedVehicle.id}}</div>
            </div>
            <div class="vehicle_data_flex">
                <label class="lbl_regular_italic_light_color">Bezeichnung</label>
                <div class="lbl_list_data">{{selectedVehicle.getDescription(75)}}</div>
            </div>
        </div>
        <div *ngIf="!selectedVehicle" class="no_vehicle_selected_wrapper">
            <label class="lbl_regular_italic_light_color">Wählen Sie ein Fahrzeug</label>
        </div>
        <div id="filter-wrapper">
          <app-dropdown-multiple
            [title]="'Kennzeichen'"
            [formcontrol]="filterForm.controls['license_plate_id']"
            [headerText]="selectedPlateValue"
            [values]="platesValues"
            [isOptional]="false"
            (submitValueEmitter)="setPlate($event);"
          ></app-dropdown-multiple>
          <app-dropdown-objects-search
            [title]="'Fahrzeug'"
            [placeholder]="'Fahrzeug'"
            [headervalue]="filterForm.controls['vehicle'].value ? filterForm.controls.vehicle.value.getVehicleName() : ''"
            [formcontrol]="filterForm.controls['vehicle']"
            [values]="vehicleValues"
            (submitValueEmitter)="setValueFromDropdown('vehicle', $event)"
          ></app-dropdown-objects-search>
        </div>
    </div>
    <div id="calender-body" class="body" style="height: 100%;     display: flex;
    flex-direction: column;">
        <app-calendar *ngIf="heightCalender" style="height: 100%;     display: flex;
    flex-direction: column;"
            [entries]="toShowEntries"
            [showTimeValue]="false"
            [disabled]="false"
            [showWeek]="true"
            [isCreate]="false"
            [tempScreenHeight]="heightCalender"
            [plate]="selectedPlate == 1 ? 'KR-ESJ4' : 'KR-13WR'"
            (selectionEntryEmitter)="selectionDrive($event)"
        ></app-calendar>
    </div>
</div>



        <!-- <div #dropdownTwo class="dropdown_wrapper" >
            <div class="lbl_regular_small">Kennzeichen</div>
            <div class="dropdown_header_data" (click)=" showDropdownTwo = !showDropdownTwo">
                <div class="dropdown_header_data_lbl">{{getSelectedPlate()}}</div>
                <img class="dropdown_header_icon" [ngClass]="showDropdownTwo ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg" [class.drop-arrow-rotated]="showDropdownTwo">
            </div>
            <div class="dropdown_data_body" *ngIf="showDropdownTwo">
                <div class="dropdown_data_body_element" (click)="filterPlate(0); showDropdownTwo = false">ALLE</div>
                <div *ngIf="authService.getLocationID() === 13" class="dropdown_data_body_element" (click)="filterPlate(1); showDropdownTwo = false">KR-ESJ4</div>
                <div *ngIf="authService.getLocationID() === 13" class="dropdown_data_body_element" (click)="filterPlate(2); showDropdownTwo = false">KR-13WR</div>
                <div *ngIf="authService.getLocationID() != 13" class="dropdown_data_body_element" (click)="filterPlate(3); showDropdownTwo = false">BEISPIELKENNZEICHEN</div>
            </div>
        </div> -->
        <!-- <div #dropdownOne class="dropdown_input_wrapper">
          <div class="lbl_regular_small" [ngStyle]="{'opacity': selectedVehicle ? '1' : '0'}">Fahrzeug wählen</div>
          <div class="dropdown_input_header" (click)="showDropdownOne = !showDropdownOne">
              <input #input_vehicle class="dropdown_input" type="text" placeholder="Fahrzeug wählen" (input)="filterVehicles(input_vehicle.value)">
              <img class="dropdown_input_header_icon" [ngClass]="showDropdownOne ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
          </div>
          <div class="dropdown_input_body" *ngIf="showDropdownOne && vehiclesFiltered.length != 0">
              <div *ngFor="let vehicle of vehiclesFiltered" class="dropdown_input_body_element" (click)="setVehicle(vehicle); showDropdownOne = false">
                {{vehicle.brand.makeName}}
                {{vehicle.model.name}}
                {{vehicle.model.version}}
              </div>
          </div>
       </div>
       <button *ngIf="selectedVehicle" class="btn_single_icon" (click)="removeCar(car)">
         <img class="btn_single_icon_icon" src="../../../../../assets/deletedark.svg">
       </button> -->
