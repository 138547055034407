<div id="overview_container">
  <div class="data_container">
      <div class="lbl_bold_light_color">Mitarbeiter</div>
      <div class="details_header">
        <div class="details_header_inner_flex">
          <div id="shorthand">{{getInitials()}}</div>
          <div class="details_header_inner">
            <div id="lbl_name">{{employee.firstName + ' ' + employee.lastName}}</div>
            <div class="lbl_italic">{{employee.username}}</div>
          </div>
        </div>
        <div >
          <div class="state-container" [ngClass]="employee.active ? 'state_active' : 'state_inactive'">{{employee.active ? 'Aktiv' : 'Inaktiv'}}</div>
          <!-- <div class="inner_title">Status</div> -->
        </div>
      </div>
      <div id="details_body">
        <div class="body_column">
          <div class="overview_data_box_lbl_title">Daten</div>
          <div class="info_container" >
            <img class="info_icon" src="../../../../assets/pin.svg">
            <div class="info_inner_container">
                <div class="lbl_title">Standort</div>
                <div class="lbl_data">{{employee.location.city}} {{employee.location.postCode}}</div>
            </div>
          </div>
          <div class="info_container">
            <img class="info_icon" src="../../../../assets/icon-user.png">
            <div class="info_inner_container">
                <div class="lbl_title">Rolle</div>
                <div class="lbl_data">{{employee.permission_group.name}}</div>
            </div>
          </div>
        </div>
        <div class="body_column">
          <div class="overview_data_box_lbl_title">Verwaltung</div>
          <div id="list_transactions">
            <div class="list_transactions_element" *ngFor="let trans of transactions; let last = last" [ngStyle]="{'border': last ? 'none' : ''}">
              <div class="history_icon" [ngStyle]="{'background-color': getTransactionColor(trans.dataTyp)}">
                <img [src]="'./assets/'+getTransactionIcon(trans.dataTyp)">
              </div>
              <div class="info_inner_container">
                  <div class="list_transactions_element_inner">
                    <label>{{trans.text}}</label>
                    <label>{{trans.timestamp |  date:'dd MMM yyyy, HH:mm'}}</label>
                  </div>
              </div>
            </div>
            <div *ngIf="transactions.length == 0">Keine Transaktionen.</div>
          </div>
        </div>
      </div>
  </div>
</div>



<!-- <div id="left_container" class="column">
  <div id="img_container">
      <div id="user_initials" class="text">{{getInitials()}}</div>
      <div>
          <div id="lbl_name">{{employee.firstName}} {{employee.lastName}}</div>
          <div class="lbl_info">{{employee.username}}</div>
      </div>
  </div>
  <div [ngStyle]="{'margin-left': '27%'}">
      <div class="inner_title">Daten</div>
      <div class="info_container">
          <img class="info_icon_column1" src="../../../../assets/pin.svg">
          <div>
              <div class="lbl_info">Standort</div>
              <div class="lbl_data">{{employee.location.city}} {{employee.location.postCode}}</div>
          </div>
      </div>
      <div class="info_container">
          <img class="info_icon_column1" src="../../../../assets/icon-user.png">
          <div>
              <div class="lbl_info">Rolle</div>
              <div>{{authGuard.getRoleName(employee)}}</div>
          </div>
      </div>
  </div>
</div>
<div id="right_container" class="column">
  <div class="status_container">
      <div class="state-container" [ngClass]="employee.active ? 'state_active' : 'state_inactive'">{{employee.active ? 'Aktiv' : 'Inaktiv'}}</div>
  </div>
  <div class="inner_title">Aktivität</div>
  <div class="history_container" *ngFor="let trans of transactions">
    <img class="history_icon" src="../../../../assets/mail.svg">
    <div class="info_inner_container">
        <div class="lbl_date">{{trans.timestamp |  date:'dd MMM yyyy &nbsp;HH:mm'}}</div>
        <div class="lbl_regular_italic_light_color">{{trans.dataTyp == 1 ? 'Kunden' : (trans.dataTyp == 2 ? 'Fahrzeuge' : 'Dokumente')}}</div>
        <div class="lbl_regular_italic_light_color">{{trans.text}}</div>
    </div>
  </div>
  <div *ngIf="transactions.length == 0">Keine Transaktionen.</div>
</div> -->
