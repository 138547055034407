import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-insert-container',
  templateUrl: './insert-container.component.html',
  styleUrls: ['./insert-container.component.css']
})
export class InsertContainerComponent implements OnInit {
  @Input() pagesTotalAry: number[];
  @Input() currentPageCreate: number;
  @Output() changePageEmitter = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  getDotIcon(page: number): string {
    if(page == (this.currentPageCreate - 1)){
      return '../../../../../assets/dot_full.png';
    }
    return '../../../../../assets/dot_empty.png';
  }

  nextPage() {
    this.changePageEmitter.emit(this.currentPageCreate += 1);
  }

  previousPage() {
    this.changePageEmitter.emit(this.currentPageCreate -= 1);
  }
}
