<div class="car_create_page_wrapper">
    <div class="title">Identifikation</div>
    <form id="input-container" [formGroup]="formGroup" autocomplete="off">
        <div id="container1">
            <div class="block">
                <h3 class="inner_title">Identifikation</h3>
                <div class="input_wrapper">
                  <div class="overview_record_lbl">ID-Nummer</div>
                  <input type="text" placeholder="ID-Nummer" formControlName="idNummer"  [class.input_invalid]="submitted && f['idNummer'].errors">
                </div>
                <div class="input_wrapper">
                  <div class="overview_record_lbl">Fahrzeug-Identifizierungsnummer (VIN)</div>
                  <input type="text" placeholder="Fahrzeug-Identifizierungsnummer (VIN)" formControlName="fin_number"  [class.input_invalid]="submitted && f['fin_number'].errors">
                </div>
                <div class="input_wrapper" *ngIf="authService.showCreateValue('engineNumber')">
                  <div class="overview_record_lbl">Motor-Nummer</div>
                  <input type="text" placeholder="Motor-Nummer" formControlName="engineNummer"  [class.input_invalid]="submitted && f['engineNummer'].errors">
                </div>
                <div class="input_wrapper" *ngIf="authService.showCreateValue('license_plate')">
                  <div class="overview_record_lbl">Kennzeichen</div>
                  <input type="text" placeholder="Kennzeichen" formControlName="license_plate"  [class.input_invalid]="submitted && f['license_plate'].errors">
                </div>
                <div class="input_wrapper">
                  <div class="overview_record_lbl">Ursprünglicher Markt</div>
                  <input type="text" placeholder="Ursprünglicher Markt" formControlName="originalMarket"  [class.input_invalid]="submitted && f['originalMarket'].errors">
                </div>
            </div>
        </div>
        <div id="container2">
            <div class="block">
              <h3 class="inner_title">Service</h3>
              <div class="input_wrapper">
                <div class="overview_record_lbl">Nächste Inspektion</div>
                <input #inputDate1 type="date" placeholder="date" [min]="maxDate" formControlName="nextInspection" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && f.nextInspection.errors">
              </div>
              <div class="input_wrapper">
                <div class="overview_record_lbl">Letzter Zahnriemenwechsel</div>
                <input #inputDate2 type="date" placeholder="date" [max]="maxDate" formControlName="lastBeltService" [class.date-input--has-value]="inputDate2.value != null" [class.input_invalid]="submitted && f.lastBeltService.errors">
              </div>
              <div class="input_wrapper">
                <div class="overview_record_lbl">Letztes Service</div>
                <input #inputDate3 type="date" placeholder="date" [max]="maxDate" formControlName="lastService" [class.date-input--has-value]="inputDate3.value != null" [class.input_invalid]="submitted && f.lastService.errors">
              </div>
              <app-dropdown-simple
                [title]="'Serviceheft vorhanden'"
                [formcontrol]="f['serviceHistory']"
                (submitValueEmitter)="f['serviceHistory'].setValue($event)"
              ></app-dropdown-simple>
            </div>
        </div>
        <div id="container3">
            <div class="block">
                <h3 class="inner_title">Garantie</h3>
                <app-dropdown-simple
                  [title]="'Gewährleistung'"
                  [formcontrol]="f['guarantee']"
                  (submitValueEmitter)="f['guarantee'].setValue($event)"
                ></app-dropdown-simple>
                <app-dropdown-simple
                  [title]="'Garantie enthalten'"
                  [formcontrol]="f['warrantyExists']"
                  (submitValueEmitter)="f['warrantyExists'].setValue($event)"
                ></app-dropdown-simple>
                <div class="input_wrapper" *ngIf="f.warrantyExists.value == true">
                  <div class="overview_record_lbl">Garantiedauer in Monaten</div>
                  <input type="number" placeholder="Garantiedauer in Monaten" formControlName="warrantyDuration" [class.input_invalid]="submitted && f['warrantyDuration'].errors">
                </div>
            </div>
        </div>
    </form>
</div>
