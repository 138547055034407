<app-collapsible [isCollapsible]="!editMode && viewAuth.view.collapsible" [isContentVisible]="collapsed">
  <h3 collapsible-header class="overview_data_box_lbl_title">Beschreibung</h3>
  <div collapsible-content>
    <div *ngIf="!editMode">
      <div class="description_container" *ngIf="showValue('description')">
        <div class="overview_data_box_lbl_inner_title">Fahrzeugbeschreibung</div>
        <div #description class="overview_record_value_description"></div>
      </div>
      <div class="description_container" *ngIf="showValue('addEquipment')">
        <div class="overview_data_box_lbl_inner_title">Sonderausstattung</div>
        <div #description_4 class="overview_record_value_description"></div>
      </div>
      <div class="description_container" *ngIf="showValue('internal_description')">
        <div class="overview_data_box_lbl_inner_title">Interne Bemerkung</div>
        <div #description_2 class="overview_record_value_description"></div>
      </div>
      <div class="description_container" *ngIf="showValue('accounting_description')">
        <div class="overview_data_box_lbl_inner_title">Buchhaltungsbeschreibung</div>
        <div #description_3 class="overview_record_value_description"></div>
      </div>
    </div>
    <form *ngIf="editMode && viewAuth.edit.name" [formGroup]="formGroupVehicle">
      <div class="description_container_edit">
        <div class="overview_data_box_lbl_inner_title">Fahrzeugbeschreibung</div>
        <div #textField id="textField" style="white-space: pre-line" class="message_box" contenteditable (input)="this.formGroupVehicle.controls.description.setValue(textField.innerText)"></div>
      </div>
      <div class="description_container_edit">
        <div class="overview_data_box_lbl_inner_title">Sonderausstattung</div>
        <div #textField_4 id="textField_2" style="white-space: pre-line" class="message_box" contenteditable (input)="this.formGroupVehicle.controls.addEquipmentsetValue(textField_4.innerText)"></div>
      </div>
      <div class="description_container_edit">
        <div class="overview_data_box_lbl_inner_title">Interne Bemerkung</div>
        <div #textField_2 id="textField_2" style="white-space: pre-line" class="message_box" contenteditable (input)="this.formGroupVehicle.controls.internal_description.setValue(textField_2.innerText)"></div>
      </div>
      <div class="description_container_edit">
        <div class="overview_data_box_lbl_inner_title">Buchhaltungsbeschreibung</div>
        <div #textField_3 id="textField_2" style="white-space: pre-line" class="message_box" contenteditable (input)="this.formGroupVehicle.controls.accounting_description.setValue(textField_3.innerText)"></div>
      </div>
    </form>
  </div>
</app-collapsible>
