import { Vehicle } from './../../../../buisness-object/vehicle/Vehicle';
import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, Renderer2 } from '@angular/core';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { BaseComponent } from 'src/app/view/BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-contract-page-two',
  templateUrl: './contract-page-two.component.html',
  styleUrls: ['./contract-page-two.component.css']
})
export class ContractPageTwoComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() vehicles: Vehicle[];

  public showDropdownOne = false;
  public vehiclesFiltered: Vehicle[] = [];
  public selectedVehicle: Vehicle;
  public maxDate = new Date();
  public vehicleValues = [];
  private dataLoaded = false;

  @ViewChild('input_vehicle') input_vehicle: ElementRef;
  public listener: () => void;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
    if(this.f.vehicle.value){
      this.selectedVehicle = this.f.vehicle.value;
    }
  }

  ngAfterViewInit(): void {
    if(this.form.controls.vehicleId.value){
      for(let v of this.vehicles){
        if(v.id == this.form.controls.vehicleId.value){
          this.setVehicle(v);
          break;
        }
      }
    }
    // this.listener = this.renderer.listen('window','click',(e:Event) => {
    //   if(this.showDropdownOne && !this.input_vehicle.nativeElement.contains(e.target)) this.showDropdownOne = false;
    // })
  }

  ngOnDestroy(): void {
    // if(this.listener) this.listener();
  }

  selectionValueFromDropdown(value: string, id: number) {
    this.setVehicle(this.getValueFromDropdown(value, id, this.vehicles));
  }

  get f() {
    return this.form.controls;
  }

  // filterVehicles(value: string) {
  //   if(value && value.length > 0){
  //     this.selectedVehicle = null;
  //     this.form.controls.vehicle.setValue(null);
  //     this.vehiclesFiltered = [];
  //     this.vehiclesFiltered = this.vehicles.filter((vehicle) => {
  //       return vehicle.brand.makeName.toLowerCase().includes(value.toLowerCase()) ||
  //              vehicle.model.name.toLowerCase().includes(value.toLowerCase()) ||
  //              vehicle.model.version.toLowerCase().includes(value.toLowerCase()) ||
  //              (vehicle.brand.makeName.toLowerCase() + ' ' + vehicle.model.name.toLowerCase() + ' ' + vehicle.model.version.toLowerCase()).includes(value.toLowerCase());
  //     })
  //   } else {
  //     this.vehiclesFiltered = this.vehicles;
  //   }
  // }

  setVehicle(vehicle: Vehicle) {
    this.selectedVehicle = vehicle;
    this.form.controls.vehicle.setValue(vehicle);
    if(vehicle.price) this.form.controls.price.setValue(vehicle.price);
    //this.input_vehicle.nativeElement.value = vehicle.brand.makeName + ' ' + vehicle.model.name + ' ' + vehicle.model.version
  }

  goToVehicle() {

  }
}
