import { formatDate, formatNumber } from '@angular/common';
import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appFormatValue]',
})
export class FormatValueDirective  implements OnChanges {

  @Input() appFormatValue: any;
  @Input() value: any;

  constructor(
    private el: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['appFormatValue'] || changes['value']) {
      this.formatValue();
    }
  }

  private formatValue() {
    let formattedValue = '---';
    if (this.appFormatValue !== null && (this.value !== undefined && this.value !== null && this.value !== '')) {
      switch (this.appFormatValue) {
        case 'string':
          formattedValue = this.value;
          break;
        case 'number':
          formattedValue = formatNumber(this.value, "de", "1.2-2");
          break;
        case 'number3Decimals':
          formattedValue = formatNumber(this.value, "de", "1.3-3");
          break;
        case 'numberNoDecimals':
          formattedValue = formatNumber(this.value, "de", "1.0-0");
          break;
        case 'date':
          if(!isNaN(this.value)){
            formattedValue = formatDate(new Date(this.value), 'dd.MM.yyyy', 'de');
          }
          break;
        case 'time':
          formattedValue = formatDate(new Date(this.value), 'HH:mm', 'de');
          break;
        case 'dateTime':
          formattedValue = formatDate(new Date(this.value), 'dd.MM.yyyy, HH:mm', 'de');
          break;
        case 'dateMonth':
          formattedValue = formatDate(new Date(this.value), 'MM.yyyy', 'de');
          break;
        case 'kg':
          formattedValue = formatNumber(this.value, "de", "1.0-0") + ' kg';
          break;
        case 'km':
          formattedValue = formatNumber(this.value, "de", "1.0-0") + ' km';
          break;
        case 'currency':
          formattedValue = formatNumber(this.value, "de", "1.2-2") + ' €';
          break;
        case 'boolean':
          formattedValue = (this.value == true || this.value == 'true') ? 'Ja' : 'Nein';
          break;
      }
    }
    this.el.nativeElement.textContent = formattedValue;
  }
}
