<div class="car_create_page_wrapper">
  <div class="title">Dokumentupload</div>
  <div class="body">
    <div class="column_1">
      <div id="upload_box" class="hover_change" appDnd (fileDroppedEmitter)="onFileDropped($event)">
        <div class="upload_box_info">
            <img class="upload_box_info_img" src="../../../../../assets/upload.svg">
            <div class="upload_box_info_lbl">Ziehen Sie Dateien hier her, um sie hochzuladen.</div>
        </div>
      </div>
      <input #selectFile_dokumente type="file" accept="image/x-png,image/jpeg, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="uploadFile($event)" multiple="" style="display: none;">
      <div class="btn_dark_light" (click)="selectFile_dokumente.click()" style="align-self: center;margin-bottom: 20px;">Dateien auswählen</div>
    </div>
    <div [ngClass]="sources.length == 0 ? 'column_1' : 'column_2'">
      <div class="lbl_regular" *ngIf="sources.length == 0" style="align-self: center;">Hochgeladene Dokumente erscheinen hier.</div>
      <div *ngIf="sources.length > 0" class="docs_container">
        <div *ngFor="let source of sources; let i = index" class="view_document">
          <img class="doc_icon" src="../../../../../assets/documentcolor.svg">
          <div class="doc_txt">{{source.name}}.{{source.format}}</div>
          <div class="doc_remove_icon" (click)="removeAttachement(i)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
    </div>
  </div>
</div>
