import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-move',
  templateUrl: './dialog-move.component.html',
  styleUrls: ['./dialog-move.component.css']
})
export class DialogMoveComponent implements OnInit {
  public show = false;
  public data: any;
  public subscription: Subscription;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openDialogMove$.subscribe((data: any) => {
      if(data){
        this.show = true;
        this.data = data.customer
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  submit(typ: number) {
    this.dialogService.closeMove(
      {
        submit_value: typ,
        data: this.data
      }
    );
    this.show = false;
  }
}
