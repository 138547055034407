import { VehicleEquipmentFactory } from './../optional/factory/VehicleEquipmentFactory';
import { VehicleFuelFactory } from "../basic/factory/VehicleFuelFactory";
import { VehicleBodyColor } from "../basic/VehicleBodyColor";
import { VehicleBodyTyp } from "../basic/VehicleBodyTyp";
import { VehicleBrand } from "../basic/VehicleBrand";
import { VehicleCategory } from "../basic/VehicleCategory";
import { VehicleDrive } from "../basic/VehicleDrive";
import { VehicleModel } from "../basic/VehicleModel";
import { VehicleTransmission } from "../basic/VehicleTransmission";
import { VehiclePictureFactory } from "../optional/factory/VehiclePictureFactory";
import { Vehicle } from "../Vehicle";
import { VehicleConsumption } from "../VehicleConsumption";
import { VehicleMaintenance } from "../VehicleMaintenance";
import { VehicleEnvironmentLabelFactory } from '../optional/factory/VehicleEnvironmentLabelFactory';
import { VehicleUpholsteryFactory } from '../optional/factory/VehicleUpholsteryFactory';
import { VehicleUpholsteryColorFactory } from '../optional/factory/VehicleUpholsteryColorFactory';
import { AttachmentFactory } from '../../attachment/Attachment';
import { PriceSheetFactory } from '../../pricesheet/factory/PriceSheetFactory';
import { RentPriceFactory } from './RentPriceFactory';
import { PictureFactory } from '../../picture/factory/PictureFactory';
export class VehicleFactory {
    static jsonFactory(rawData: any, chatgpt_flag_allowed: boolean): Vehicle[] {
        let dataAry: Vehicle[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data, chatgpt_flag_allowed));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any, chatgpt_flag_allowed: boolean): Vehicle {
        let object = new Vehicle(
            rawData.vehicle_id,
            rawData.location_id,
            new VehicleBrand(rawData.brand.make.make_id, rawData.brand.make.make_name, [new VehicleModel(rawData.brand.model.model_id, rawData.brand.model.model_name)]),
            new VehicleModel(rawData.brand.model.model_id, rawData.brand.model.model_name),
            rawData.price ? rawData.price : null,
            rawData.fuel ? VehicleFuelFactory.jsonFactoryOne(rawData.fuel) : null,
            rawData.power_ps ? rawData.power_ps : null,
            rawData.power_kw ? rawData.power_kw : null,
            rawData.body_color ? new VehicleBodyColor(rawData.body_color.color_id, rawData.body_color.name): null,
            rawData.body_typ ? new VehicleBodyTyp(rawData.body_typ.body_typ_id, rawData.body_typ.name) : null,
            rawData.category ? new VehicleCategory(rawData.category.category_id, rawData.category.name): null,
            rawData.mileage_in_km ? rawData.mileage_in_km : null,
            !this.getBooleanValue(rawData.accident_free),
            rawData.number_of_doors == 0 ? null : rawData.number_of_doors,
            rawData.number_of_seats == 0 ? null : rawData.number_of_seats,
            rawData.transmission ? new VehicleTransmission(rawData.transmission.transmission_id, rawData.transmission.name) : null,
            rawData.drive ? new VehicleDrive(rawData.drive.drive_id, rawData.drive.name) : null,
            rawData.first_registration ? rawData.first_registration : null,
            rawData.first_registration ? rawData.first_registration : null,
            rawData.previous_owners ? rawData.previous_owners : null,
            rawData.status ? rawData.status : null,
            rawData.ccm ? rawData.ccm : null,
            rawData.co2_emission ? rawData.co2_emission : null,
            rawData.number_of_gears == 0 ? null : rawData.number_of_gears,
            rawData.number_of_cylinders ? rawData.number_of_cylinders : null,
            rawData.empty_weight ? rawData.empty_weight : null,
            rawData.total_weight ? rawData.total_weight : null,
            rawData.last_updated ? rawData.last_updated : null,
            rawData.created ? rawData.created : null,
            rawData.external_id ? rawData.external_id : null,
            this.getBooleanValue(rawData.warranty_exists),
            rawData.warranty_duration ? rawData.warranty_duration : null,
            rawData.upholstery ? VehicleUpholsteryFactory.jsonFactoryOne(rawData.upholstery) : null,
            rawData.upholstery_color ? VehicleUpholsteryColorFactory.jsonFactoryOne(rawData.upholstery_color) : null,
            rawData.paint_type ? rawData.paint_type : null,
            rawData.alloy_wheel_inches ? rawData.alloy_wheel_inches : null,
            rawData.original_market ? rawData.original_market : null,
            rawData.non_smoking ? rawData.non_smoking : null,
            rawData.consumption ? new VehicleConsumption(
              rawData.consumption.combined ? rawData.consumption.combined : null,
              rawData.consumption.urban ? rawData.consumption.urban : null,
              rawData.consumption.extra_urban ? rawData.consumption.extra_urban : null
            ) : null,
            new VehicleMaintenance(
              rawData.maintenance?.next_inspection ? rawData.maintenance.next_inspection : null,
              rawData.maintenance?.last_belt_service ? rawData.maintenance.last_belt_service : null,
              rawData.maintenance?.last_service ? rawData.maintenance.last_service : null,
              this.getBooleanValue(rawData.maintenance?.service_history)
            ),
            rawData.environment_label ? VehicleEnvironmentLabelFactory.jsonFactoryOne(rawData.environment_label) : null,
            rawData.dealer_price ? rawData.dealer_price : null,
            rawData.offer_ref ? rawData.offer_ref : null,
            rawData.add_equipment ? rawData.add_equipment : null,
            rawData.description ? rawData.description : null,
            rawData.motor_nr ? rawData.motor_nr : null,
            VehicleEquipmentFactory.jsonFactory(rawData.equipments),
            rawData.fin_nr  ? rawData.fin_nr : null,
            this.getBooleanValue(rawData.guarantee),
            rawData.nova ? rawData.nova : null,
            this.getBooleanValue(rawData.vat_reportable),
            this.getBooleanValue(rawData.leasable),
            rawData.rent_price ? rawData.rent_price : null,
            rawData.luggage_space ? rawData.luggage_space : null,
            rawData.license_plate ? rawData.license_plate : null,
            rawData.internal_description ? rawData.internal_description : null,
            rawData.color_code ? rawData.color_code : null,
            rawData.color_designation ? rawData.color_designation : null,
            this.getBooleanValue(rawData.winter_tyres),
            this.getBooleanValue(rawData.summer_tyres),
            rawData.keys_available ? rawData.keys_available : null,
            rawData.keys_number ? rawData.keys_number : null,
            rawData.damages ? rawData.damages : null,
            rawData.repair_status,
            rawData.paper_status,
            this.getBooleanValue(rawData.website_online),
            this.getBooleanValue(rawData.platform_online),
            rawData.standing_time,
            rawData.last_movement,
            rawData.handing_over_date,
            rawData.customer_id,
            rawData.accounting_description,
            this.getBooleanValue(rawData.winter_diesel),
            rawData.winter_tyres_description,
            rawData.summer_tyres_description,
            //rawData.purchase_date,
            null, //altes purchase date nicht benötigt
            rawData.is_ersatzauto,
            rawData.issued_ersatzauto,
            rawData.supplier,
            rawData.delivery_date,
            rawData.planed_repair_date,
            rawData.type_certificate_angefordert,
            rawData.type_certificate_eingestockt,
            rawData.type_certificate_ausgestockt,
            rawData.type_certificate_versendet,
            rawData.type_certificate_an_bank,
            rawData.type_certifcate_bank_erhalten,
            rawData.finanzsperrauskunft,

            rawData.garantie_bis,
            rawData.garantie_km,
            rawData.garantie_description,
            rawData.freigeschaltet_am_web,
            rawData.freigeschaltet_am_plattform ? rawData.freigeschaltet_am_plattform : null,

            rawData.lackierung_notwendig,
            rawData.aufbereitung,
            rawData.aufbereitung_zustaendigkeit,
            rawData.ankaufstest,
            rawData.ankaufstest_durchfuehrung,
            rawData.eurotax_code,

            rawData.sonderausstattung_beschreibung,
            rawData.ersatzteile,
            rawData.naechstes_service,
            rawData.eingangsdatum,
            rawData.endreinigungsdatum,

            rawData.vehicle_serialnumber,
            rawData.datenblatt_angefordert_am,
            rawData.fahrzeug_eingestockt_am,
            rawData.co2_checked,
            rawData.first_registration_checked,
            rawData.ankaufstest_info_description,
            rawData.werkstatt_info_description,
            rawData.inserat_checked,
            rawData.inserat_info,
            rawData.mobile_online,
            rawData.export_price,
            rawData.mileage_internal,
            rawData.felgen_aufpreis,
            rawData.price_checked,
            rawData.aufbereitung_info,
            rawData.dellen_info,
            rawData.letzte_inventur,
            rawData.is_vermittlung,
            rawData.transport_beantragt,
            rawData.transport_date,
            rawData.spedition,
            rawData.envkv_class,
            rawData.pickerl_valid,
            rawData.finanzsperrauskunft_beantragt,
            rawData.display_keys_number,
            rawData.digital_keys_number,
            rawData.fahrwerk_aufpreis,
            rawData.electrical_distance,
            rawData.electrical_distance_checked,
            rawData.einkauf_vertragsnummer,
            rawData.verkauf_vertragsnummer,
            rawData.trailer_load_braked,
            rawData.hail_damage,
            rawData.zubau,
            rawData.abwertung,
            rawData.type_certificate_is_sent,
            rawData.is_camper,
            rawData.finanzsperrauskunft_status,
            rawData.bca_import,
            rawData.photo_aufbereitung
        );

        object.export_price_checked = rawData.export_price_checked;
        object.export_price_enabled = rawData.export_price_enabled;
        object.as_id = rawData.as_id;
        object.mobile_id = rawData.mobile_id;
        object.pictures_count = rawData.pictures.length;
        object.model.version = rawData.brand.version;
        object.pictures = PictureFactory.jsonFactory(rawData.pictures, object.id);
        object.attachments = AttachmentFactory.jsonFactory(rawData.attachments);
        object.priceSheet = rawData.preisblatt ? PriceSheetFactory.jsonFactoryOne(rawData.preisblatt) : null;
        object.sinhuber_standing_time = object.getSinhuberStandingTime();
        //if(object.customer_id) object.status = 4;
        object.chatgpt_flag = chatgpt_flag_allowed ? rawData.chatgpt_flag : true;
        object.vehicle_supplier = object.priceSheet?.vehicle_supplier;
        object.performance = object.getStatus();
        object.rent_typ = rawData.rent_typ;
        if(rawData.rents) object.rents = RentPriceFactory.jsonFactory(rawData.rents);
        if(object.rent_typ == 2 || object.rent_typ == 3){
          object.rent_price_pro_tag = object.rents[0]?.rent_price;
        }
        object.coc = rawData.coc;
        object.austrian_data_extract = rawData.austrian_data_extract;
        object.last_price_change = rawData.last_price_change;

        //Daten die im Preisblatt mitbearbeitet werden.
        if(object.priceSheet != null){
          object.priceSheet.transport_beantragt = object.transport_beantragt;
          object.priceSheet.transport_date = object.transport_date;
          object.priceSheet.spedition = object.spedition;
        }
        return object;
    }

    static getBooleanValue(data: any): boolean {
      if(data == 0) return false;
      else if(data == 1) return true;
      else return null;
    }
}

