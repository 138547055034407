<div id="dialog_window">
    <div id="dialog_container" appDropdown>
        <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
        <h1>Lagerplatz korrigieren</h1>
        <div id="dialog_conainter_body" class="body">
          <label>Bitte geben Sie den aktuellen und korrekten Lagerplatz der Reifen an:</label>
          <div class="btn-tab-wrapper">
              <button *ngFor="let location of locations; let i = index"
                class="btn-tab-2"
                [class.btn-tab-2-first]="i == 0"
                [class.btn-tab-2-last]="i == locations.length-1"
                [class.btn-tab-2-active]="form.get('location').value?.storage_location_id == location.storage_location_id"
                (click)="selectionLocation(location)">
                  {{location.location_name}}
              </button>
          </div>
          <app-dropdown-objects-search
            [placeholder]="'Platz'"
            [headervalue]="form.get('place').value?.place_name"
            [formcontrol]="form.get('place')"
            [values]="placeValues"
            [submitted]="false"
            [showLabel]="false"
            (submitValueEmitter)="selectionPlace($event)"
          ></app-dropdown-objects-search>
        </div>
        <div class="dialog_btn_container">
            <button class="btn_label" (click)="selectionCloseEmitter.emit()">Abbrechen</button>
            <button class="btn_submit_dark" [disabled]="form.invalid" (click)="selectionSubmit()">Speichern</button>
        </div>
    </div>
</div>
