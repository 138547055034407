import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { TireService } from 'src/app/service/tire/tire.service';

@Component({
  selector: 'app-dialog-tyres-store-outsource',
  templateUrl: './dialog-tyres-store-outsource.component.html',
  styleUrl: './dialog-tyres-store-outsource.component.css'
})
export class DialogTyresStoreOutsourceComponent implements OnInit {
  @Input() obj: Tire;
  @Input() vehicles: Vehicle[] = [];
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() successChangeStorageEmitter = new EventEmitter<Tire>();
  @Output() successInstallationEmitter = new EventEmitter<Tire>();
  public form: FormGroup;
  public vehicleValues = [];

  constructor(
    private tireService: TireService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService
  ){}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      vehicle: [this.obj.purchased_with_vehicle, Validators.required],
    });
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
  }

  getDropdownValuesVehicles(vehicles: Vehicle[]): any[] {
    let values = [];
    for(let value of vehicles){
      values.push({
        id: value.id,
        name: value.getVehicleName()
      })
    }
    return values;
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'vehicle'){
      let index = this.vehicles.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.get('vehicle').setValue(this.vehicles[index]);
      }
    }
  }

  submit() {
    this.obj.vehicle_id = this.form.get('vehicle').value.id;
    this.obj.storage_place = null;
    this.obj.storage_place_id = null;
    this.tireService.updateTire(this.obj).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Reifen wurden ausgelagert.',
          success: true
        });
        this.successInstallationEmitter.emit(result);
      }
    })
  }
}
