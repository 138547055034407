<form id="price_sheets_create_page" [formGroup]="form">
  <div class="price_sheets_create_page_column">
    <div class="data_box" [class.data_box_disabled]="form.controls['invoice_checked'].value == true">
      <h3>Berechnungsgrundlage</h3>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Einkaufspreis (brutto)</label>
        <input id="inputEinkaufspreis" type="number" placeholder="---" formControlName="einkaufspreis_brutto" [class.input_invalid]="submitted && form.controls['einkaufspreis_brutto'].errors">
      </div>
      <app-dropdown-multiple *ngIf="form.controls['steuersatz'].value == null"
        [title]="'Steuersatz'"
        [formcontrol]="form.controls['steuersatz']"
        [headerText]="getSteuersatz(form.controls['steuersatz'].value)"
        [values]="steuerseatze"
        [submitted]="submitted"
        (submitValueEmitter)="setSteuersatz($event)"
      ></app-dropdown-multiple>
      <div class="input_wrapper" *ngIf="form.controls['steuersatz'].value">
        <label>Steuersatz</label>
        <label class="input_no_input">{{getSteuersatz(form.controls['steuersatz'].value)}} ({{getSteueratzValue(form.controls['steuersatz'].value)}}%) <img (click)="selectionEditSteuersatz()" src="assets/edit_blue.svg"></label>
      </div>
      <div class="input_wrapper" *ngIf="form.controls['steuersatz'].value">
        <label>Differenz</label>
        <label class="input_no_input">{{getInputValueNumber(form.controls['vorsteuer'].value)}}</label>
      </div>
    </div>
    <div class="data_box">
      <h3>Fahrzeugkosten</h3>
      <div class="input_wrapper">
        <label>{{changeValueName('guv_vorkalkulation')}}</label>
        <label class="input_no_input">{{getInputValueNumber(form.controls['guv_vorkalkulation'].value)}}</label>
      </div>
      <div class="input_wrapper">
        <label>{{changeValueName('guv_nachkalkulation')}}</label>
        <label class="input_no_input">{{form.controls['nachkalkulation'].value ? getInputValueNumber(form.controls['guv_nachkalkulation'].value) : '---'}}</label>
      </div>
      <div class="input_wrapper" *ngIf="showValuePricesheet('aufschlag')">
        <label>Aufschlag bei Verkauf (Vorkalkulation)</label>
        <label class="input_no_input">{{getInputValueNumber(form.controls['aufschlag'].value)}}</label>
      </div>
    </div>
    <div class="data_box">
      <h3>Verkauf</h3>
      <div class="input_wrapper">
        <label>Verkaufspreis (brutto)</label>
        <input [class.input_disabled]="form.controls['vk_vor_checked'].value == true" type="number" placeholder="---" formControlName="preis_anschreiben">
      </div>
      <div class="input_wrapper">
        <label>Händlerpreis (brutto)</label>
        <input [class.input_disabled]="form.controls['vk_vor_checked'].value == true" type="number" placeholder="---" formControlName="minimaler_verkaufpreis">
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('vk_vor_checked')">
        <label class="checkbox_lbl">Verkaufspreise geprüft</label>
        <input type="checkbox" class="checkbox" [checked]="form.controls['vk_vor_checked'].value" (click)="form.controls['vk_vor_checked'].setValue(!form.controls['vk_vor_checked'].value)">
      </div>
      <!-- <div class="input_wrapper" *ngIf="false">
        <label>Zugaben</label>
        <input type="number" placeholder="---" formControlName="zugaben">
      </div>
      <div class="input_wrapper" *ngIf="false">
        <label>Zubehör</label>
        <input type="number" placeholder="---" formControlName="zubehoer">
      </div> -->
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Rubrik Transport')">
      <h3>Rubrik Transport</h3>
      <app-dropdown-simple
        [title]="'Beauftragt'"
        [formcontrol]="form.controls['transport_beantragt']"
        [isOptional]="false"
        (submitValueEmitter)="form.controls['transport_beantragt'].setValue($event)"
      ></app-dropdown-simple>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Datum</label>
        <input #inputDate11 type="date" [min]="maxDate" formControlName="transport_date" [class.date-input--has-value]="inputDate11.value != null" [class.input_invalid]="submitted && form.controls['transport_date'].errors">
      </div>
      <app-dropdown-objects-search Spedition
        [title]="'Spedition'"
        [placeholder]="'Spedition'"
        [headervalue]="form.controls['spedition'].value"
        [formcontrol]="form.controls['spedition']"
        [values]="speditionValues"
        [stringPossible]="true"
        [return]="'STRING'"
        [submitted]="submitted"
        (submitValueEmitter)="form.controls['spedition'].setValue($event)"
        (submitValueStringEmitter)="form.controls['spedition'].setValue($event)"
      ></app-dropdown-objects-search>
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Typenschein')">
      <h3>Typenschein</h3>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Datenblatt angefordert am</label>
        <input #inputDate6 type="date" [max]="maxDate" formControlName="datenblatt_angefordert_am" [class.date-input--has-value]="inputDate6.value != null" [class.input_invalid]="submitted && form.controls['datenblatt_angefordert_am'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Fahrzeug eingestockt am</label>
        <input #inputDate7 type="date" [max]="maxDate" formControlName="fahrzeug_eingestockt_am" [class.date-input--has-value]="inputDate7.value != null" [class.input_invalid]="submitted && form.controls['fahrzeug_eingestockt_am'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Typenschein eingestockt am</label>
        <input #inputDate8 type="date" [max]="maxDate" formControlName="type_certificate_eingestockt" [class.date-input--has-value]="inputDate8.value != null" [class.input_invalid]="submitted && form.controls['type_certificate_eingestockt'].errors">
      </div>
      <app-dropdown-multiple
        [title]="'Standort Typenschein'"
        [formcontrol]="form.controls['paper_status']"
        [headerText]="paperStatusValue"
        [values]="paperStatusValues"
        [isOptional]="true"
        (submitValueEmitter)="form.controls['paper_status'].setValue($event); getEnumDataOnChange();"
      ></app-dropdown-multiple>
      <app-dropdown-multiple
        [title]="'Finanzsperrauskunft'"
        [formcontrol]="form.controls['finanzsperrauskunft']"
        [headerText]="finanzsperrauskunftValue"
        [values]="finanzsperrauskunftValues"
        [isOptional]="true"
        (submitValueEmitter)="form.controls['finanzsperrauskunft'].setValue($event); getEnumDataOnChange();"
      ></app-dropdown-multiple>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Finanzsperrauskunft beantragt</div>
        <input #inputDate9 type="date" formControlName="finanzsperrauskunft_beantragt" [class.date-input--has-value]="inputDate9.value != null" [class.input_invalid]="submitted && form.controls['finanzsperrauskunft_beantragt'].errors">
      </div>
      <!-- <app-dropdown-objects
        [title]="'Finanzsperrauskunft'"
        [placeholder]="'Finanzsperrauskunft'"
        [headervalue]="form.controls['finanzsperrauskunft'].value"
        [formcontrol]="form.controls['finanzsperrauskunft']"
        [values]="finanzsperrauskunftValues"
        [submitted]="submitted"
        (submitValueEmitter)="form.controls['finanzsperrauskunft'].setValue($event)"
      ></app-dropdown-objects> -->
      <!-- <app-dropdown-simple
        [title]="'Finanzsperrauskunft'"
        [formcontrol]="form.controls['finanzsperrauskunft']"
        [values]="['freigeschalten','gesperrt']"
        [isOptional]="true"
        (submitValueEmitter)="form.controls['finanzsperrauskunft'].setValue($event)"
      ></app-dropdown-simple> -->
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Neupreis')">
      <h3>Neupreis</h3>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Fahrzeugpreis</div>
        <input type="number" placeholder="---" formControlName="neu_preis">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Sonderausstattung</div>
        <input type="number" placeholder="---" formControlName="sonderaustattung">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Gesamt Neupreis</div>
        <label class="input_no_input">{{getInputValue(form.controls['neu_preis'].value+form.controls['sonderaustattung'].value)}}</label>
      </div>
    </div>
  </div>
  <div class="price_sheets_create_page_column" id="column_2">
    <div class="column_horizontal_flex">
      <div class="data_box">
        <h3>Einkauf</h3>
        <div class="input_wrapper" *ngIf="form.controls['steuersatz'].value != 2">
          <div class="overview_record_lbl">Einkaufspreis (netto)</div>
          <label class="input_no_input">{{getInputValueNumber(form.controls['einkaufspreis_netto'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Einkaufsdatum</div>
          <input #inputDate1 type="date" [max]="maxDate" formControlName="einkaufsdatum" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls['einkaufsdatum'].errors">
        </div>
        <div class="input_wrapper" *ngIf="showValuePricesheet('rechnungsdatum_vor')">
          <div class="overview_record_lbl">Rechnungsdatum</div>
          <input #inputDateRe type="date" [max]="maxDate" formControlName="rechnungsdatum_vor" [class.date-input--has-value]="inputDateRe.value != null" [class.input_invalid]="submitted && form.controls['rechnungsdatum_vor'].errors">
        </div>
        <div class="input_wrapper" *ngIf="showValuePricesheet('eurotax')">
          <div class="overview_record_lbl">Nationaler Code</div>
          <label class="input_no_input">{{getInputValue(form.controls['eurotax'].value)}}</label>
        </div>
        <div class="input_wrapper" *ngIf="showValuePricesheet('vehicle_serialnumber')">
          <div class="overview_record_lbl">Fahrzeug-Laufnummer</div>
          <input type="text" placeholder="---" formControlName="vehicle_serialnumber" [class.input_invalid]="submitted && form.controls['vehicle_serialnumber'].errors">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Zahldatum</div>
          <input #inputDate3 type="date" [max]="maxDate" formControlName="zahldatum" [class.date-input--has-value]="inputDate3.value != null" [class.input_invalid]="submitted && form.controls['zahldatum'].errors">
        </div>
        <div class="input_wrapper" *ngIf="showValuePricesheet('anpreisungsdatum')">
          <div class="overview_record_lbl">Auspreisungsdatum</div>
          <input #inputDate4 type="date" [max]="maxDate" formControlName="anpreisungsdatum" [class.date-input--has-value]="inputDate4.value != null" [class.input_invalid]="submitted && form.controls['anpreisungsdatum'].errors">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Standzeit Plan-Tage</div>
          <input type="number" step="1" placeholder="---" formControlName="standzeit_plan_tage">
        </div>
        <app-dropdown-simple *ngIf="showValuePricesheet('vehicle_imported')"
          [title]="'Import'"
          [formcontrol]="form.controls['vehicle_imported']"
          [isOptional]="false"
          (submitValueEmitter)="form.controls['vehicle_imported'].setValue($event)"
        ></app-dropdown-simple>
        <app-dropdown-strings *ngIf="showValuePricesheet('vehicle_supplier')"
          [title]="'Fahrzeuglieferant'"
          [placeholder]="'---'"
          [formcontrol]="form.controls['vehicle_supplier']"
          [headerText]="form.controls['vehicle_supplier'].value"
          [values]="fahrzeuglieferantenValues"
          [isOptional]="true"
          (submitValueEmitter)="form.controls['vehicle_supplier'].setValue($event);"
        ></app-dropdown-strings>
        <div class="input_wrapper" *ngIf="showValuePricesheet('einstockung_bei')">
          <div class="overview_record_lbl">Einstockung bei</div>
          <input type="string" placeholder="---" formControlName="einstockung_bei">
        </div>
        <div class="data_wrapper" *ngIf="showValuePricesheet('invoice_checked')">
          <label class="checkbox_lbl">Eingangsrechnung kontrolliert</label>
          <input type="checkbox" class="checkbox" [checked]="form.controls['invoice_checked'].value" (click)="form.controls['invoice_checked'].setValue(!form.controls['invoice_checked'].value)">
        </div>
      </div>
      <div class="column_vertical_flex">
        <div class="data_box">
          <h3>NoVA</h3>
          <div class="input_wrapper">
            <div class="overview_record_lbl">CO&#8322; Emissionen (g/km)</div>
            <input type="number" [class.input_disabled]="form.controls['co2_checked'].value == true" placeholder="---" formControlName="co2_emission" [class.input_invalid]="submitted && form.controls['co2_emission'].errors">
          </div>
          <div class="data_wrapper">
            <label class="checkbox_lbl">CO&#8322; Emissionen geprüft</label>
            <input type="checkbox" class="checkbox" [checked]="form.controls['co2_checked'].value" (click)="form.controls['co2_checked'].setValue(!form.controls['co2_checked'].value)">
          </div>
          <div class="input_wrapper" *ngIf="showValuePricesheet('electrical_distance')">
            <div class="overview_record_lbl">Elektr. Reichweite km</div>
            <input type="number" min="0" [class.input_disabled]="form.controls['electrical_distance_checked'].value == true" placeholder="---" formControlName="electrical_distance" [class.input_invalid]="submitted && form.controls['electrical_distance'].errors">
          </div>
          <div class="data_wrapper" *ngIf="showValuePricesheet('electrical_distance_checked')">
            <label class="checkbox_lbl">Elektr. Reichweite geprüft</label>
            <input type="checkbox" class="checkbox" [checked]="form.controls['electrical_distance_checked'].value" (click)="form.controls['electrical_distance_checked'].setValue(!form.controls['electrical_distance_checked'].value)">
          </div>
          <div class="input_wrapper" *ngIf="showValuePricesheet('entgelt_ohne_nova_vor')">
            <div class="overview_record_lbl">Entgelt ohne NoVA</div>
            <label class="input_no_input">{{getInputValueNumber(form.controls['entgelt_ohne_nova_vor'].value)}}</label>
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">NoVA-Satz</div>
            <label class="input_no_input">{{form.controls['nova_satz'].value ? getInputValueNumber(form.controls['nova_satz'].value) + "%" : '---'}}</label>
          </div>
          <div class="input_wrapper" *ngIf="showValuePricesheet('nova_grundbetrag')">
            <div class="overview_record_lbl">NoVA Grundbetrag</div>
            <label class="input_no_input">{{form.controls['nova_grundbetrag'].value ? getInputValueNumber(form.controls['nova_grundbetrag'].value) + " €" : '---'}}</label>
          </div>
          <div class="data_wrapper" *ngIf="showValuePricesheet('abzugsposten')">
            <div class="overview_record_lbl">anteiliger Abzugsposten</div>
            <label class="input_no_input">{{form.controls['abzugsposten'].value ? getInputValueNumber(form.controls['abzugsposten'].value) + " €" : '---'}}</label>
          </div>
          <div class="data_wrapper" *ngIf="showValuePricesheet('malus')">
            <div class="overview_record_lbl">anteiliger CO2-Malus</div>
            <label class="input_no_input">{{form.controls['malus'].value ? getInputValueNumber(form.controls['malus'].value) + " €" : '---'}}</label>
          </div>
          <div class="data_wrapper" *ngIf="showValuePricesheet('summe_nova_standard')">
            <div class="overview_record_lbl">Summe NoVA</div>
            <label class="input_no_input">{{form.controls['summe_nova_vor'].value ? getInputValueNumber(form.controls['summe_nova_vor'].value) + " €" : '---'}}</label>
          </div>
          <div class="input_wrapper" *ngIf="showValuePricesheet('nova_vor')">
            <div class="overview_record_lbl">NoVa</div>
            <label class="input_no_input">{{getInputValueNumber(form.controls['nova_vor'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="showValuePricesheet('malus_bonus')">
            <div class="overview_record_lbl">Malus/Bonus</div>
            <label class="input_no_input">-{{getInputValueNumber(form.controls['malus_bonus'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="showValuePricesheet('summe_nova_vor')">
            <div class="overview_record_lbl">Summe NoVA</div>
            <label class="input_no_input">{{getInputValueNumber(form.controls['summe_nova_vor'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="!isVomAusland() && showValuePricesheet('ust_vor')">
            <div class="overview_record_lbl">UST</div>
            <label class="input_no_input">{{getInputValueNumber(form.controls['ust_vor'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="isVomAusland() && showValuePricesheet('ohne_nova_inkl_ust_vor')">
            <div class="overview_record_lbl">Ohne NoVA inkl. UST</div>
            <label class="input_no_input">{{getInputValueNumber(form.controls['ohne_nova_inkl_ust_vor'].value)}}</label>
          </div>
        </div>
        <div class="data_box" [class.data_box_disabled]="form.controls['steuersatz'].value == null">
          <h3>Beschaffung</h3>
          <div class="input_wrapper">
            <div class="overview_record_lbl">{{changeValueName('sprit')}}</div>
            <input type="number" placeholder="---" formControlName="sprit">
          </div>
          <div class="input_wrapper" *ngIf="showValuePricesheet('flug_bahn')">
            <div class="overview_record_lbl">Flug/Bahn</div>
            <input type="number" placeholder="---" formControlName="flug_bahn">
          </div>
          <div class="input_wrapper" *ngIf="showValuePricesheet('gebuehren')">
            <div class="overview_record_lbl">Gebühren</div>
            <input type="number" placeholder="---" formControlName="gebuehren">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">{{changeValueName('lieferant_extern')}}</div>
            <input type="number" placeholder="---" formControlName="lieferant_extern">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Beschaffung gesamt</div>
            <label class="input_no_input">{{getInputValue(form.controls['beschaffungskosten_gesamt'].value)}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="column_vertical_flex">
      <div class="data_box" *ngIf="showBoxPricesheet('Reparatur und Fahrzeugkosten')" [class.data_box_disabled]="form.controls['steuersatz'].value == null">
        <h3>Reparatur und Fahrzeugkosten</h3>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Aufbereitung</div>
          <input type="number" placeholder="---" formControlName="aufbereitung">
        </div>
        <div class="input_wrapper" *ngIf="showValuePricesheet('reparatur_dauer_tage')">
          <div class="overview_record_lbl">Reparaturdauer in Tagen</div>
          <input type="number" placeholder="---" formControlName="reparatur_dauer_tage">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Reparatur Summe Gesamt</div>
          <label class="input_no_input">{{getInputValue(form.controls['reparatur_summe_gesamt'].value)}}</label>
        </div>
      </div>
      <div *ngIf="showBoxPricesheet('Reparatur-Details')" class="data_box" id="reparatur_details_box" [class.data_box_disabled]="form.controls['steuersatz'].value == null">
        <div id="reparatur_details_box_header">
          <h3>Reparatur-Details</h3>
          <h3>Renr.</h3>
          <h3>AZ</h3>
          <h3>MAT</h3>
          <h3>Reifen</h3>
          <h3>Lackierung</h3>
          <h3>Typisierung</h3>
          <div></div>
        </div>
        <div id="reparatur_details_box_form_body">
          <div *ngFor="let repairForm of form.controls['reparaturen'].value; let i = index">
            <form id="reparatur_details_form" [formGroup]="repairForm">
              <h3>{{repairForm?.controls['name'].value}}</h3>
              <input type="number" placeholder="---" formControlName="renr">
              <input type="number" placeholder="---" formControlName="az">
              <input type="number" placeholder="---" formControlName="mat">
              <input type="number" placeholder="---" formControlName="reifen">
              <input type="number" placeholder="---" formControlName="lackierung">
              <input type="number" placeholder="---" formControlName="typisierung">
              <button class="btn_lbl_action" (click)="removeReparaturEmitter.emit(i)"><span>&#8722;</span></button>
            </form>
          </div>
        </div>
        <div id="reparatur_details_box_button">
          <button id="btn_add" class="btn_lbl_action" (click)="addReparaturEmitter.emit()"><span>&#43;</span> Reparatur hinzufügen</button>
        </div>
        <div id="reparatur_details_box_footer">
          <h3>Gesamt</h3>
          <div></div>
          <label>{{getInputValueNumber(form.controls['reparaturen_column_gesamt'].value?.az)}}</label>
          <label>{{getInputValueNumber(form.controls['reparaturen_column_gesamt'].value?.mat)}}</label>
          <label>{{getInputValueNumber(form.controls['reparaturen_column_gesamt'].value?.reifen)}}</label>
          <label>{{getInputValueNumber(form.controls['reparaturen_column_gesamt'].value?.lack)}}</label>
          <label>{{getInputValueNumber(form.controls['reparaturen_column_gesamt'].value?.typ)}}</label>
        </div>
      </div>
      <div class="data_box" id="zusatzkosten_box" [class.data_box_disabled]="form.controls['steuersatz'].value == null">
        <h3>Zusatzkosten</h3>
        <div class="input_wrapper">
          <label>Zinsen</label>
          <label class="data_lbl_edit">
            <p>Zinssatz</p>
            {{form.controls['zinssatz_vor'].value}}
            <img (click)="selectionEditValue(form.controls['zinssatz_vor'].value, 'zinssatz_vor')" src="assets/edit_blue.svg">
            <span>{{getInputValueNumber(form.controls['zinsen_vor'].value)}}</span>
          </label>
        </div>
        <div class="input_wrapper">
          <label>Rückstellung Gewährleistung</label>
          <label class="data_lbl_edit">
            <p>% vom Brutto Verkaufspreis</p>
            {{form.controls['garantie_von_einkaufspreis'].value}}
            <img (click)="selectionEditValue(form.controls['garantie_von_einkaufspreis'].value, 'garantie_von_einkaufspreis')" src="assets/edit_blue.svg">
            <span>{{getInputValueNumber(form.controls['garantie_vorkalkulation'].value)}}</span>
          </label>
        </div>
        <div class="input_wrapper">
          <label>Standzeitkosten</label>
          <label class="data_lbl_edit">
            <p>Kosten je Tag</p>
            {{getInputValueNumber(form.controls['standzeitkosten_je_tag_vor'].value)}}
            <img (click)="selectionEditValue(form.controls['standzeitkosten_je_tag_vor'].value, 'standzeitkosten_je_tag_vor')" src="assets/edit_blue.svg">
            <span>{{getInputValueNumber(form.controls['standzeitkosten_vor'].value)}}</span>
          </label>
        </div>
        <div class="input_wrapper" *ngIf="showValuePricesheet('fixkosten_pro_fahrzeug_bei_70')">
          <div class="overview_record_lbl">Fixkosten pro FZG</div>
          <input type="number" placeholder="---" formControlName="fixkosten_pro_fahrzeug_bei_70">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">GW-Aufschlag</div>
          <input type="number" placeholder="---" formControlName="gw_aufschlag">
        </div>
        <div class="input_wrapper" *ngIf="showValuePricesheet('garantie_vor')">
          <div class="overview_record_lbl">Garantie</div>
          <input type="number" placeholder="---" formControlName="garantie_vor">
        </div>
      </div>
    </div>
  </div>
</form>
