<form class="drive_create_page_wrapper" [formGroup]="form">
  <div class="lbl_bold_light_color">Probefahrt erstellen</div>
  <div class="page_body">
    <div>
      <div class="list_title_lbl" style="margin-bottom: 20px;">Fahrzeug</div>
      <div #dropdownOne class="dropdown_input_wrapper">
        <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.vehicle.value ? '1' : '0'}">Fahrzeug</div>
        <div class="dropdown_input_header" [ngClass]="submitted && form.controls.vehicle.invalid ? 'dropdown_input_header_invalid' : ''" (click)="showDropdownOne = !showDropdownOne">
            <input #input_vehicle class="dropdown_input" type="text" placeholder="Fahrzeug wählen *" (input)="filterVehicles(input_vehicle.value)">
            <img class="dropdown_input_header_icon" [ngClass]="showDropdownOne ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
        </div>
        <div class="dropdown_input_body" *ngIf="showDropdownOne && vehiclesFiltered.length != 0">
            <div *ngFor="let vehicle of vehiclesFiltered" class="dropdown_input_body_element" (click)="setVehicle(vehicle); showDropdownOne = false">
              <div class="img_container_dropdown">
                <img *ngIf="vehicle.thumbnail" class="img_dropdown" [src]="vehicle.thumbnail ? vehicle.thumbnail : ''">
                <div *ngIf="!vehicle.thumbnail" class="img_empty_dropdown">Kein Bild</div>
              </div>
              <div>
                {{vehicle.brand.makeName}}
                {{vehicle.model.name}}
                {{vehicle.model.version}}
              </div>
            </div>
        </div>
      </div>
    </div>
    <div>
      <div class="list_title_lbl" style="margin-bottom: 20px;">Kennzeichen</div>
      <div class="radio_container" (click)="form.controls.license_plate_id.setValue(1)">
        <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="form.controls.license_plate_id.value == 1 ? 'radio_icon_inner_active' : ''"></div></div>
        <div class="lbl_info">KR-ESJ4</div>
      </div>
      <div class="radio_container" (click)="form.controls.license_plate_id.setValue(2)">
        <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="form.controls.license_plate_id.value == 2 ? 'radio_icon_inner_active' : ''"></div></div>
        <div class="lbl_info">KR-13WR</div>
      </div>
    </div>
  </div>
</form>
