<div id="dialog_window">
    <div id="dialog_container" appDropdown>
        <div id="dialog_close_icon" (click)="dialogCloseEmitter.emit()">&#10005;</div>
        <h1>{{isCreate ? "Dokument hochladen" : "Dokument bearbeiten"}}</h1>
        <div class="document-name">Dateiname: {{ documentTitle + (documentMimeTyp ? ("." + documentMimeTyp) : "") }}</div>
        <form [formGroup]="form" id="dialog_conainter_body" class="body">

            <app-dropdown-enum *ngIf="showCategories"
                [name]="'Kategorie'"
                [placeholder]="'---'"
                [control]="form.get('category')"
                [enumName]="categoryName"
                [submitted]="submitted"
            ></app-dropdown-enum>

            <div class="input_wrapper">
                <label class="overview_record_lbl" for="title">Titel</label>
                <input id="title" class="overview_input" formControlName="title" [class.input_invalid]="submitted && form.get('title').invalid"/>
            </div>
        </form>
        <div class="dialog_btn_container">
            <button class="btn_label" (click)="dialogCloseEmitter.emit()">Abbrechen</button>
            <button *ngIf="isCreate" class="btn_submit_dark" (click)="selectionSubmit()">Hochladen</button>
            <button *ngIf="!isCreate" class="btn_submit_dark" (click)="updateAttachment()">Speichern</button>
        </div>
    </div>
</div>
