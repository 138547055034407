import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { environment } from 'src/environments/environment';
import { DriveTab } from '../drives.component';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-list-drives',
  templateUrl: './list-drives.component.html',
  styleUrls: ['./list-drives.component.css']
})
export class ListDrivesComponent implements OnInit {
  @Input() drives: Drive[]
  @Input() pageInfo: string;
  @Input() sliceFrom: number;
  @Input() sliceTo: number;
  @Input() amount: number;
  @Input() filter: number;
  @Input() selectedTab: DriveTab;

  public driveTab = DriveTab;

  @Output() pageEmitter = new EventEmitter<boolean>();
  @Output() openDetailsEmitter = new EventEmitter<any>();
  @Output() openEditEmitter = new EventEmitter<any>();
  @Output() deleteEmitter = new EventEmitter<Drive>();
  @Output() openCreatePdfEmitter = new EventEmitter<Drive>();
  @Output() selectionPDFEmitter = new EventEmitter<Drive>();
  @Output() selectionTabEmitter = new EventEmitter<DriveTab>();
  @Output() selectionPseudoSignatureEmitter = new EventEmitter<Drive>();

  public maxAmountListPage = environment.max_amount_list_page;

  constructor(
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }
}
