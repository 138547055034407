import { Customer } from "../buisness-object/customer/Customer";

export class OutputHelper {
  static getCustomerName(customer: Customer): string {
    if(customer.typ == 1) return customer.companyName;
    else if(customer.typ == 2) return customer.contactPerson.firstName + ' ' + customer.contactPerson.lastName;
    else return '---';
  }
  static getTransactionIcon(typ: number): string {
    switch (typ) {
      case 1: return 'user-transaction.svg'; break;
      case 2: return 'car-transaction.svg'; break;
      case 3: return 'document-transaction.svg';  break;
      case 5: return 'tiredetails.svg';  break; //reifen
      default: return 'car-transaction.svg'; break;
    }
  }
  static getTransactionColor(typ: number): string {
    switch (typ) {
      case 1: return '#6A9E18'; break;
      case 2: return '#597CF1'; break;
      case 3: return '#D2B836';  break;
      case 5: return '#6A9E18';  break; //reifen
      default: return '#ffffff'; break;
    }
  }
}

