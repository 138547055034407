import { DropdownIconArrowAnimation } from './../../../helpers/Animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SalesContract } from 'src/app/buisness-object/document/SalesContract';

@Component({
  selector: 'app-topbar-documents',
  templateUrl: './topbar-documents.component.html',
  styleUrls: ['./topbar-documents.component.css'],
  animations: [ DropdownIconArrowAnimation ]
})
export class TopbarDocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() surface: number;
  @Input() tab: number;
  @Input() selectedDocument: number;
  @Input() filter: any;
  @Input() sortObject: any;
  @Input() documents: any[];
  @Input() contract: SalesContract;

  public showFilter = false;
  public showSort = false;
  public showDropdown = false;
  @ViewChild('documentsFilterBtn') excludedBtn: ElementRef;

  @HostListener('document:keydown.escape', ['$event'])onEscHandler(event: KeyboardEvent) {
    if(this.surface == 22) this.backEmitter.emit();
    else if(this.surface == 2 || this.surface == 3 ||this.surface == 4 || this.surface == 5) this.cancelEmitter.emit();
  }

  @Output() changeTabEmitter = new EventEmitter<number>();
  @Output() openCreateEmitter = new EventEmitter<any>();
  @Output() cancelEmitter = new EventEmitter<undefined>();
  @Output() saveEmitter = new EventEmitter<undefined>();
  @Output() backEmitter = new EventEmitter<undefined>();
  @Output() editEmitter = new EventEmitter<undefined>();
  @Output() saveAsDraftEmitter = new EventEmitter<undefined>();
  @Output() sendDocumentEmitter = new EventEmitter<undefined>();
  @Output() searchEmitter = new EventEmitter<string>();
  @Output() pdfExportEmitter = new EventEmitter<undefined>();
  @Output() applyFilterEmitter = new EventEmitter<undefined>();
  @Output() resetFilterEmitter = new EventEmitter<undefined>();
  @Output() applySortEmitter = new EventEmitter<undefined>();

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }
}
