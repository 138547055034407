<form class="drive_create_page_wrapper" [formGroup]="form">
    <div class="lbl_bold_light_color">Probefahrt erstellen</div>
    <div class="page_body">
      <div class="column">
        <div class="list_title_lbl" style="margin-bottom: 20px;">Kunde</div>
        <div #dropdownOne class="dropdown_input_wrapper">
          <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.customer.value ? '1' : '0'}">Kunde</div>
          <div class="dropdown_input_header" [ngClass]="submitted && form.controls.customer.invalid ? 'dropdown_input_header_invalid' : ''" (click)="showDropdownOne = !showDropdownOne">
              <input #input_customer class="dropdown_input" type="text" placeholder="Kunde *" (input)="filterCustomers(input_customer.value)">
              <img class="dropdown_input_header_icon" [ngClass]="showDropdownOne ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
          </div>
          <div class="dropdown_input_body" *ngIf="showDropdownOne && customersFiltered.length != 0">
              <div *ngFor="let customer of customersFiltered" class="dropdown_input_body_element" (click)="setCustomer(customer); showDropdownOne = false">
                {{customer.getName()}}
              </div>
          </div>
        </div>
        <!-- <div class="btn_dark_light" style="margin-top: 15px;" (click)="gotoCustomerCreate()">Neuen Kunden anlegen</div> -->
      </div>
      <div class="column">
        <div class="list_title_lbl" style="margin-bottom: 20px;">Führerschein</div>
        <div id="upload_box" class="hover_change" appDnd (fileDroppedEmitter)="onFileDropped($event)">
            <div class="upload_box_info">
                <img class="upload_box_info_img" src="../../../../../assets/upload.svg">
                <div class="upload_box_info_lbl">Ziehen Sie Dateien hier her, um sie hochzuladen.</div>
            </div>
        </div>
        <!-- <input #selectFile type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event)" multiple="" style="display: none;"> -->
        <input #selectFile_dokumente type="file" capture="environment" accept="image/*, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="uploadFile($event)" multiple="" style="display: none;">
        <div class="btn_dark_light" (click)="selectFile_dokumente.click()" style="align-self: center;margin-bottom: 20px;">Dateien auswählen</div>
        <div class="lbl_regular" *ngIf="sources.length == 0" style="align-self: center;">Hochgeladene Dokumente erscheinen hier.</div>
          <div class="docs_container">
            <div *ngFor="let source of sources; let i = index" class="view_document">
              <img class="doc_icon" src="../../../../../assets/documentcolor.svg">
              <div class="doc_txt">{{source.name}}.{{source.format}}</div>
              <div class="doc_remove_icon" (click)="removeAttachement(i)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                    <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                    <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        <!-- <div class="images_wrapper" *ngIf="f.attachments.value">

          <div *ngFor="let pic of this.form.controls.attachments.value">
            <div class="single_image_wrapper">
              <img class="image" class="img-car" [src]="pic.src">
              <div class="image_remove_icon" style="left: 92%" (click)="removeImage(pic)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                    <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                    <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
            <div *ngFor="let img of this.f.images_driving_license.value">
                <div class="single_image_wrapper">
                    <img class="image" [src]="img">
                    <img class="remove_icon" (click)="removeImage(img)" src="../../../../../assets/cancle.svg">
                </div>
            </div>
        </div> -->
      </div>
    </div>
  </form>

