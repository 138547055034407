import { AttachmentFactory } from "../../attachment/Attachment";
import { OverviewConfigurationFactory } from "../../overview/factory/OverviewConfigurationFactory";
import { OverviewConfiguration } from "../../overview/OverviewConfiguration";
import { Customer } from "../Customer";
import { AddressFactory } from "./AddressFactory";
import { ContactPersonFactory } from "./ContactPersonFactory";

export class CustomerFactory {
    static jsonFactory(rawBody: any): Customer[] {
        const transactions: Customer[] = [];
        for(let customer of rawBody){
            transactions.push(this.jsonFactoryOne(customer));
        }
        return transactions;
    }

    static jsonFactoryOne(rawCustomer: any): Customer {
        let object = new Customer(
            rawCustomer.customer_id,
            rawCustomer.location_id,
            ContactPersonFactory.jsonFactoryOne(rawCustomer.contact_person),
            AddressFactory.jsonFactoryOne(rawCustomer.address),
            rawCustomer.dataprivacy_accepted,
            rawCustomer.advertisment_accepted,
            rawCustomer.typ,
            rawCustomer.status,
            rawCustomer.atu_number,
            rawCustomer.company_name,
            rawCustomer.last_updated,
            [new OverviewConfiguration(1, 'Standard'),new OverviewConfiguration(2, 'Verkauf'),new OverviewConfiguration(3, 'Nachrichten')],
            rawCustomer.employee_id,
        );
        object.contactPerson.is_pep = rawCustomer.is_pep;
        if(rawCustomer.attachments) object.attachments = AttachmentFactory.jsonFactory(rawCustomer.attachments);
        object.authorization_number = rawCustomer.authorization_number;
        object.authorization_authority = rawCustomer.authorization_authority;
        object.authorization_date = rawCustomer.authorization_date;
        object.authorization_groups = rawCustomer.authorization_groups;
        return object;
    }
}
