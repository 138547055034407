<div id="document-view-container">
    <div class="grid-container">
       <div class="tile-column">
        <app-attachment 
            *ngFor="let file_category of file_categories_column_1; index"
            [title]="file_category.value" 
            [category]="file_category.raw_value" 
            [collapsible]="false" 
            [obj]="obj" class="grid-item"
        ></app-attachment>
       </div>
       <div class="tile-column">
        <app-attachment 
            *ngFor="let file_category of file_categories_column_2; index"
            [title]="file_category.value" 
            [category]="file_category.raw_value" 
            [collapsible]="false" 
            [obj]="obj" class="grid-item"
        ></app-attachment>
       </div>
       <div class="tile-column">
        <app-attachment 
            *ngFor="let file_category of file_categories_column_3; index"
            [title]="file_category.value" 
            [category]="file_category.raw_value" 
            [collapsible]="false" 
            [obj]="obj" class="grid-item"
        ></app-attachment>
       </div>
       <!-- <app-attachment 
       *ngFor="let file_category of file_categories; index"
       [attr.id]="'attachment-tile_'+index" 
       [title]="file_category.value" 
       [category]="file_category.raw_value" 
       [collapsible]="false" 
       [vehicle]="selectedVehicle" class="grid-item"
       [style]="getTilePlacement('attachment-tile_'+index)"
   ></app-attachment> -->
        <!-- <app-attachment [vehicle]="selectedVehicle" class="grid-item"></app-attachment> -->
    </div>
    <div id="footer"></div>
</div>
