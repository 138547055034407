<!-- <div class="overview_data_box">
  <form [formGroup]="form" *ngIf="form" id="customer-box-form">
    <div id="customer-box-title-wrapper">
      <h3>Fahrzeug</h3>
      <button *ngIf="this.form.get('vehicle').value" class="btn_label_blue" (click)="selectionChange()">{{buttonText}}</button>
    </div>
    <app-dropdown-objects-search *ngIf="!showEdit && form"
      [title]="'Kunde *'"
      [placeholder]="'Kunde'"
      [headervalue]="form.get('customer').value?.getName()"
      [formcontrol]="form.get('customer')"
      [values]="customerValues"
      [submitted]="submitted"
      [showLabel]="false"
      (submitValueEmitter)="setValueFromDropdown('customer', $event)"
    ></app-dropdown-objects-search>
  </form>
  <form [formGroup]="formVehicle" *ngIf="showEdit" id="customer-box-form">
    <app-dropdown-objects-search
      [title]="'Marke *'"
      [placeholder]="'Marke'"
      [headervalue]="formVehicle.controls['brand'].value ? formVehicle.controls['brand'].value?.makeName : ''"
      [formcontrol]="formVehicle.controls['brand']"
      [values]="brandValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('brand', $event)"
    ></app-dropdown-objects-search>
    <app-dropdown-objects-search
        [title]="'Model *'"
        [placeholder]="'Model'"
        [headervalue]="formVehicle.controls['model'].value ? formVehicle.controls['model'].value?.name : ''"
        [formcontrol]="formVehicle.controls['model']"
        [values]="modelValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('model', $event)"
    ></app-dropdown-objects-search>
    <div class="input_wrapper">
      <label class="overview_record_lbl" style="align-self: flex-start;">Version *</label>
      <div class="version_container">
          <input type="text"
            maxlength="80"
            placeholder="Version"
            formControlName="version"
            (input)="changeVersionCounter()"
            [class.input_invalid]="submitted && formVehicle.get('version').invalid">
          <span class="counter_lbl lbl_regular_italic_light_color">({{counterVersion}} Zeichen übrig)</span>
      </div>
    </div>
    <app-dropdown-objects
      [title]="'Treibstoff *'"
      [placeholder]="'Treibstoff'"
      [headervalue]="formVehicle.controls.fuel.value?.name"
      [formcontrol]="formVehicle.controls.fuel"
      [values]="fuelsValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('fuel', $event)"
    ></app-dropdown-objects>
    <app-dropdown-objects
      [title]="'Antriebsart *'"
      [placeholder]="'Antriebsart'"
      [headervalue]="formVehicle.controls.drive.value?.name"
      [formcontrol]="formVehicle.controls.drive"
      [values]="drivesValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('drive', $event)"
    ></app-dropdown-objects>
    <app-dropdown-objects
      [title]="'Getriebeart *'"
      [placeholder]="'Getriebeart'"
      [headervalue]="formVehicle.controls.transmission.value?.name"
      [formcontrol]="formVehicle.controls.transmission"
      [values]="transmissionsValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('transmission', $event)"
    ></app-dropdown-objects>
    <app-dropdown-objects
      [title]="'KFZ-Typ *'"
      [placeholder]="'KFZ-Typ'"
      [headervalue]="formVehicle.controls.bodyTyp.value?.name"
      [formcontrol]="formVehicle.controls.bodyTyp"
      [values]="bodytypValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('bodytyp', $event)"
    ></app-dropdown-objects>
    <app-dropdown-objects
      [title]="'Fahrzeugart *'"
      [placeholder]="'Fahrzeugart'"
      [headervalue]="formVehicle.controls.category.value?.name"
      [formcontrol]="formVehicle.controls.category"
      [values]="categorieValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('category', $event)"
    ></app-dropdown-objects>
    <div class="input_wrapper" *ngIf="formVehicle.controls.category.value && formVehicle.controls.category.value.id != 1">
      <div class="overview_record_lbl">Erstzulassung *</div>
      <input #inputDate1 type="date" placeholder="date" [min]="minDate" [max]="maxDate" formControlName="firstRegistrationDate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && formVehicle.controls.firstRegistrationDate.errors">
    </div>
    <div class="input_wrapper">
      <label class="overview_record_lbl">Türen *</label>
      <input type="number" placeholder="Türen" formControlName="numOfDoors" [class.input_invalid]="submitted && this.formVehicle.get('numOfDoors').invalid">
    </div>
    <div class="input_wrapper">
      <div class="overview_record_lbl">Sitze *</div>
      <input type="number" placeholder="Sitze" formControlName="numOfSeats" [class.input_invalid]="submitted && this.formVehicle.get('numOfSeats').invalid">
    </div>
    <app-dropdown-objects
      [title]="'Hauptfarbe *'"
      [placeholder]="'Hauptfarbe'"
      [headervalue]="formVehicle.controls.bodyColor.value?.name"
      [formcontrol]="formVehicle.controls.bodyColor"
      [values]="bodyColorValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('bodyColor', $event)"
    ></app-dropdown-objects>
    <div class="input_wrapper">
      <div class="overview_record_lbl">Kilometerstand *</div>
      <input type="number" placeholder="---" formControlName="mileageKm" [class.input_invalid]="submitted && formVehicle.controls['mileageKm'].errors">
    </div>
    <div class="input_container">
      <div *ngIf="formVehicle.controls.powerTyp.value == 1" class="input_wrapper">
        <label class="overview_record_lbl">Leistung (PS) *</label>
        <input type="number" placeholder="Leistung" formControlName="power" [class.input_invalid]="submitted && this.formVehicle.get('power').invalid">
      </div>
      <div *ngIf="formVehicle.controls.powerTyp.value == 2" class="input_wrapper">
        <label class="overview_record_lbl">Leistung (KW) *</label>
        <input type="number" placeholder="Leistung" formControlName="power" [class.input_invalid]="submitted && this.formVehicle.get('power').invalid">
      </div>
   </div>
    <div class="input_wrapper">
      <div class="overview_record_lbl">VIN-Nummer</div>
      <input type="text" placeholder="---" formControlName="fin_number" [class.input_invalid]="submitted && formVehicle.get('fin_number').errors">
    </div>
  </form>
</div> -->


<form [formGroup]="form" class="overview_data_box">
  <label class="overview_data_box_lbl_title">Fahrzeug anlegen</label>
  <div class="overview_data_box_inner">
    <app-dropdown-objects-search
      [title]="'Marke *'"
      [placeholder]="'Marke'"
      [headervalue]="form.controls['brand'].value ? form.controls['brand'].value?.makeName : ''"
      [formcontrol]="form.controls['brand']"
      [values]="brandValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('brand', $event)"
    ></app-dropdown-objects-search>
    <app-dropdown-objects-search
        [title]="'Model *'"
        [placeholder]="'Model'"
        [headervalue]="form.controls['model'].value ? form.controls['model'].value?.name : ''"
        [formcontrol]="form.controls['model']"
        [values]="modelValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('model', $event)"
    ></app-dropdown-objects-search>
    <div class="input_wrapper">
      <label class="overview_record_lbl" style="align-self: flex-start;">Version *</label>
      <div class="version_container">
          <input type="text"
            maxlength="80"
            placeholder="Version"
            formControlName="version"
            (input)="changeVersionCounter()"
            [class.input_invalid]="submitted && form.get('version').invalid">
          <span class="counter_lbl lbl_regular_italic_light_color">({{counterVersion}} Zeichen übrig)</span>
      </div>
    </div>
    <app-dropdown-objects
      [title]="'Treibstoff *'"
      [placeholder]="'Treibstoff'"
      [headervalue]="form.controls.fuel.value?.name"
      [formcontrol]="form.controls.fuel"
      [values]="fuelsValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('fuel', $event)"
    ></app-dropdown-objects>
    <app-dropdown-objects
      [title]="'Antriebsart *'"
      [placeholder]="'Antriebsart'"
      [headervalue]="form.controls.drive.value?.name"
      [formcontrol]="form.controls.drive"
      [values]="drivesValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('drive', $event)"
    ></app-dropdown-objects>
    <app-dropdown-objects
      [title]="'Getriebeart *'"
      [placeholder]="'Getriebeart'"
      [headervalue]="form.controls.transmission.value?.name"
      [formcontrol]="form.controls.transmission"
      [values]="transmissionsValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('transmission', $event)"
    ></app-dropdown-objects>
    <app-dropdown-objects
      [title]="'KFZ-Typ *'"
      [placeholder]="'KFZ-Typ'"
      [headervalue]="form.controls.bodyTyp.value?.name"
      [formcontrol]="form.controls.bodyTyp"
      [values]="bodytypValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('bodytyp', $event)"
    ></app-dropdown-objects>
    <app-dropdown-objects
      [title]="'Fahrzeugart *'"
      [placeholder]="'Fahrzeugart'"
      [headervalue]="form.controls.category.value?.name"
      [formcontrol]="form.controls.category"
      [values]="categorieValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('category', $event)"
    ></app-dropdown-objects>
    <div class="input_wrapper" *ngIf="form.controls.category.value && form.controls.category.value.id != 1">
      <div class="overview_record_lbl">Erstzulassung *</div>
      <input #inputDate1 type="date" placeholder="date" [min]="minDate" [max]="maxDate" formControlName="firstRegistrationDate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls.firstRegistrationDate.errors">
    </div>
    <div class="input_wrapper">
      <label class="overview_record_lbl">Türen *</label>
      <input type="number" placeholder="Türen" formControlName="numOfDoors" [class.input_invalid]="submitted && this.form.get('numOfDoors').invalid">
    </div>
    <div class="input_wrapper">
      <div class="overview_record_lbl">Sitze *</div>
      <input type="number" placeholder="Sitze" formControlName="numOfSeats" [class.input_invalid]="submitted && this.form.get('numOfSeats').invalid">
    </div>
    <app-dropdown-objects
      [title]="'Hauptfarbe *'"
      [placeholder]="'Hauptfarbe'"
      [headervalue]="form.controls.bodyColor.value?.name"
      [formcontrol]="form.controls.bodyColor"
      [values]="bodyColorValues"
      [submitted]="submitted"
      (submitValueEmitter)="setValueFromDropdown('bodyColor', $event)"
    ></app-dropdown-objects>
    <div class="input_wrapper">
      <div class="overview_record_lbl">Kilometerstand *</div>
      <input type="number" placeholder="---" formControlName="mileageKm" [class.input_invalid]="submitted && form.controls['mileageKm'].errors">
    </div>
    <div class="input_container">
      <div *ngIf="form.controls.powerTyp.value == 1" class="input_wrapper">
        <label class="overview_record_lbl">Leistung (PS) *</label>
        <input type="number" placeholder="Leistung" formControlName="power" [class.input_invalid]="submitted && this.form.get('power').invalid">
      </div>
      <div *ngIf="form.controls.powerTyp.value == 2" class="input_wrapper">
        <label class="overview_record_lbl">Leistung (KW) *</label>
        <input type="number" placeholder="Leistung" formControlName="power" [class.input_invalid]="submitted && this.form.get('power').invalid">
      </div>
   </div>
    <div class="input_wrapper">
      <div class="overview_record_lbl">VIN-Nummer</div>
      <input type="text" placeholder="---" formControlName="fin_number" [class.input_invalid]="submitted && form.get('fin_number').errors">
    </div>
  </div>
</form>
