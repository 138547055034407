import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-dialog-change-password',
  templateUrl: './dialog-change-password.component.html',
  styleUrls: ['./dialog-change-password.component.css']
})
export class DialogChangePasswordComponent implements OnInit {
  public show = false;
  public submitted = false;
  public notIdent = false;
  public showPassword_1 = false;
  public showPassword_2 = false;
  public access = false;
  public accessDenied = false;
  public submitValue: string;
  public subscription: Subscription;
  public form: UntypedFormGroup;

  // @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {

  // }

  constructor(
    private dialogService: DialogService,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService
  ) {
    this.subscription = this.dialogService.openDialogPassword$.subscribe((data: any) => {
      this.show = true;
    })
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      current_password: [null,[Validators.required]],
      new_password: [null,[Validators.required]],
      new_password_confirm: [null,[Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updatePassword(){
    this.submitted = true;
    if(this.form.invalid){
      return;
    } else if(this.form.controls.new_password.value != this.form.controls.new_password_confirm.value){
      this.notIdent = true;
      return;
    } else {
      this.submitted = false;
      this.loginService.updatePassword(this.form.controls.current_password.value, this.form.controls.new_password.value).subscribe((response) => {
        if(response){
          this.access = true;
          this.cancel();
          this.dialogService.showNotification({
            title: 'Erfolgreich',
            message: 'Passwort geändert.',
            success: true
          });
        } else {
          this.access = false;
          this.form.reset();
          this.accessDenied = true;
        }
      })
    }
  }

  next() {
    this.submitted = true;
    if(this.form.controls.current_password.invalid){
      return;
    } else {
      this.submitted = false;
      this.access = true;
    }
  }

  cancel() {
    this.form.reset();
    this.submitted = false;
    this.access = false;
    this.accessDenied = false;
    this.show = false;
  }
}
