<form class="contract_create_page_wrapper" [formGroup]="form">
  <div class="lbl_bold_light_color">Neuer Kaufvertag</div>
  <div class="page_body">
    <div class="column">
      <div class="list_title_lbl">Inzahlungsnahme (optional)</div>
      <app-dropdown-objects-search
          [title]="'Marke'"
          [placeholder]="'Marke'"
          [headervalue]="form.controls.old_brand.value ? form.controls.old_brand.value?.makeName : ''"
          [formcontrol]="form.controls.old_brand"
          [values]="brandValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('brand', $event)"
      ></app-dropdown-objects-search>
      <app-dropdown-objects-search
          [title]="'Model'"
          [placeholder]="'Model'"
          [headervalue]="form.controls.old_model.value ? form.controls.old_model.value?.name : ''"
          [formcontrol]="form.controls.old_model"
          [values]="modelValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('model', $event)"
      ></app-dropdown-objects-search>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Erstzulassung</div>
        <input #inputDate1 type="date" placeholder="date" [max]="maxDate" formControlName="old_registration_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && f.old_registration_date.errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Anzahl Vorbesitzer</div>
        <input type="number" placeholder="---" formControlName="old_previous_owner" [class.input_invalid]="submitted && f.old_previous_owner.errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">VIN-Nummer</div>
        <input type="text" placeholder="---" formControlName="old_vin_number" [class.input_invalid]="submitted && f.old_vin_number.errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Kilometerstand</div>
        <input type="number" placeholder="---" formControlName="old_mileage" [class.input_invalid]="submitted && f.old_mileage.errors">
      </div>
    </div>
    <div class="column">
      <div class="list_title_lbl">Fahrzeugwert</div>
      <div class="radio_container" (click)="f.old_registration_document.setValue(1)">
        <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="f.old_registration_document.value == 1 ? 'radio_icon_inner_active' : ''"></div></div>
        <div class="lbl_info">Typenschein</div>
      </div>
      <div class="radio_container" (click)="f.old_registration_document.setValue(2)">
        <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="f.old_registration_document.value == 2 ? 'radio_icon_inner_active' : ''"></div></div>
        <div class="lbl_info">Genehmigung für Zulassung</div>
      </div>
      <div class="radio_container" (click)="f.old_registration_document.setValue(3)">
        <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="f.old_registration_document.value == 3 ? 'radio_icon_inner_active' : ''"></div></div>
        <div class="lbl_info">Einzelgenehmigung des Landeshauptmannes</div>
      </div>
      <div class="column">
        <div class="input_wrapper">
          <div class="overview_record_lbl">Wert in €</div>
          <input #old_price type="number" placeholder="---" formControlName="old_price" [class.input_invalid]="submitted && f.old_price.errors" (input)="calcRestPrice(old_price.value)">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Benutzung bis Übergabe (km)</div>
          <input type="number" placeholder="---" formControlName="old_mileage_to_drive" [class.input_invalid]="submitted && f.old_mileage_to_drive.errors">
        </div>
      </div>
      <div class="lbl_regular_bold" style="margin-top: 15px; font-size: 20px;">Restkaufpreis</div>
      <div class="lbl_regular_italic_light_color">{{restPrice | currency: 'EUR'}}</div>
    </div>
  </div>
</form>
