<div class="overview_container">
  <div class="data_container">
    <div class="lbl_bold_light_color">Neues Ticket</div>
    <div class="row_1">
        <div class="user_initials">{{getInitials()}}</div>
        <div class="lbl_regular_thick">{{currentUser.firstName}} {{currentUser.lastName}}</div>
        <div #dropdown1 class="dropdown_container">
            <div class="lbl_regular_thick">Tickettyp</div>
            <div class="dropdown_header" [ngClass]="submitted && selectedTyp == null ? 'dropdown_header_invalid' : ''" (click)="showDropdown1 = !showDropdown1">
                <div class="dropdown_header_lbl" [ngClass]="selectedTyp == null ? 'dropdown_header_lbl_placeholder' : ''">{{selectedTyp != null ? (selectedTyp == 0 ? 'Intern' : (selectedTyp == 1 ? 'Fahrzeug' : 'Kunde')) : 'Auswählen...'}}</div>
                <img class="dropdown_header_icon" [ngClass]="showDropdown1 ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg" [class.drop-arrow-rotated]="showDropdown1">
            </div>
            <div class="dropdown_element_container" *ngIf="showDropdown1">
                <div class="dropdown_element" (click)="setTyp(0); showDropdown1 = false">Intern</div>
                <div class="dropdown_element" (click)="setTyp(1); showDropdown1 = false">Fahrzeug</div>
                <div class="dropdown_element" (click)="setTyp(2); showDropdown1 = false">Kunde</div>
            </div>
        </div>
        <div #dropdown2 class="dropdown_input_wrapper" *ngIf="selectedTyp == 1">
          <div class="lbl_regular_thick">{{getTyp(selectedTyp)}}</div>
          <div class="dropdown_input_header" [ngClass]="submitted && !threadForm.controls.typ_object.value ? 'input_invalid' : ''" (click)="showDropdown2 = !showDropdown2">
              <input #input_vehicle class="dropdown_input" type="text" [placeholder]="selectedTyp == 1 ? 'Fahrzeug auswählen..' : ''" (input)="filterVehicles(input_vehicle.value)">
              <img class="dropdown_input_header_icon" [ngClass]="showDropdown2 ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
          </div>
          <div class="dropdown_input_body" *ngIf="showDropdown2">
              <div *ngFor="let vehicle of vehiclesFiltered" class="dropdown_input_body_element" (click)="setVehicle(vehicle); showDropdown2 = false">
                <!-- <div class="img_container_dropdown">
                  <img *ngIf="vehicle.thumbnail" class="img_dropdown" [src]="vehicle.thumbnail ? vehicle.thumbnail : ''">
                  <div *ngIf="!vehicle.thumbnail" class="img_empty">Kein Bild</div>
                </div> -->
                <div>
                  {{authenticationService.getLocationID() == 13 && vehicle.externalId != null ? "("+vehicle.externalId+")" : ""}}
                  {{vehicle.brand.makeName}}
                  {{vehicle.model.name}}
                  {{vehicle.model.version}}
                </div>
              </div>
          </div>
        </div>
        <div #dropdown2 class="dropdown_input_wrapper" *ngIf="selectedTyp == 2">
          <div class="lbl_regular_thick">{{getTyp(selectedTyp)}}</div>
          <div class="dropdown_input_header" [ngClass]="submitted && threadForm.controls.typ_object.value == null ? 'input_invalid' : ''" (click)="showDropdown2 = !showDropdown2">
              <input #input_customer class="dropdown_input" type="text" [placeholder]="selectedTyp == 2 ? 'Kunde auswählen..' : ''" (input)="filterCustomers(input_customer.value)">
              <img class="dropdown_input_header_icon" [ngClass]="showDropdown2 ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
          </div>
          <div class="dropdown_input_body" *ngIf="showDropdown2">
              <div *ngFor="let customer of customersFiltered" class="dropdown_input_body_element" (click)="setCustomer(customer); showDropdown2 = false">
                {{customer.typ == 1 ? customer.companyName : (customer.typ == 2 ? customer.contactPerson.firstName + ' ' + customer.contactPerson.lastName : '---')}}
              </div>
          </div>
        </div>
    </div>
      <div class="row_2">
        <app-custom-mark-input class="mark_input"
          [data]="employeesFiltered"
          [btn_name]="'Erstellen'"
          (selectionCreateEmitter)="submit($event)"
        ></app-custom-mark-input>
        <!-- <app-textinput-add-function
          [users]="employeesFiltered"
          (inputEmitter)="inputAction($event)"
        ></app-textinput-add-function> -->
          <!-- <div class="btn_submit" (click)="submit()">Ticket erstellen<img class="icon_send" src="../../../../assets/send.svg"></div> -->
      </div>
  </div>
</div>
