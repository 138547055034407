import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { BaseComponent } from 'src/app/view/BaseComponent';
import { formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-contract-page-four',
  templateUrl: './contract-page-four.component.html',
  styleUrls: ['./contract-page-four.component.css']
})
export class ContractPageFourComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  public minDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public finanzierungsartenValues = [];
  public finanzierungsartenValue;
  public finanzierungsgesellschaftValues = [];
  public finanzierungsgesellschaftValue;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.finanzierungsartenValues = this.getEnumValues('finanzierungsart');
    this.finanzierungsartenValue = this.getEnumValue('finanzierungsart', this.form.controls['finanzierungsart'].value);
    this.finanzierungsgesellschaftValues = this.getEnumValues('finanzierungsgesellschaft');
    this.finanzierungsgesellschaftValue = this.getEnumValue('finanzierungsgesellschaft', this.form.controls['finanzierungsgesellschaft'].value);
  }

  getEnumDataOnChange() {
    this.finanzierungsartenValue = this.getEnumValue('finanzierungsart', this.form.controls['finanzierungsart'].value);
    this.finanzierungsgesellschaftValue = this.getEnumValue('finanzierungsgesellschaft', this.form.controls['finanzierungsgesellschaft'].value);
  }

  ngAfterViewInit(): void {
    if(this.form.controls.special_conditions.value){
      let doc =  document.getElementById('textField');
      if(doc) document.getElementById('textField').innerHTML = this.form.controls.special_conditions.value;
    }
  }

  get f() {
    return this.form.controls;
  }
}
