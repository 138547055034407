import { Address } from "../customer/Adress";
import { Location } from "../location/Location";
import { PermissionGroup } from "../permission/PermissionGroup";

export class Employee {
    public location: Location;
    public address: Address;
    public permission_group: PermissionGroup;
    constructor(
        public id: number,
        public username: string,
        public firstName: string,
        public lastName: string,
        public email: string,
        public locationId: number,
        public mandantId: number,
        public active: boolean,
        public password?: string,
        public salutation?: number,
        public title?: string,
        public birthdate?: number,
        public phone?: number,
        public authorization_number?: string,
        public authorization_authority?: string,
        public authorization_date?: number,
        public authorization_groups?: number,
        public planning_color?: string,
        public permission_group_id?: number
    ){

    }

    getInitials(): string {
        return this.firstName.substring(0,1) + this.lastName.substring(0,1);
    }

    getFullName(): string {
      return this.firstName + ' ' + this.lastName
    }

    getAdress(): string {
      return this.location ? `${this.location.street}, ${this.location.postCode} ${this.location.city}` : '---';
    }

    getStatus(): string {
      return this.active ? 'Aktiv' : 'Inaktiv';
    }

    getJSON(): any {
      let address;
      if(this.address){
        address = {
          "address_id": this.address.addressId,
          "street": this.address.street,
          "postal_code": this.address.postalCode,
          "city": this.address.city,
          "country": this.address.country
        }
      }
      let body = {
          employee_id: this.id,
          username: this.username,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          location_id: this.locationId,
          active: this.active,
          roles: [],
          planning_color: '',
          birthdate: this.birthdate,
          phone: this.phone,
          salutation: this.salutation,
          title: this.title,
          mandant_id: this.mandantId,
          authorization_number: this.authorization_number,
          authorization_authority: this.authorization_authority,
          authorization_date: this.authorization_date,
          authorization_groups: this.authorization_groups,
          address: address,
          permission_group_id: this.permission_group.permission_group_id
      }
      if (this.id == null) {
        body['password'] = this.password;
      }
      return body;
    }
}
