import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Employee } from "../Employee";
import { Address } from "../../customer/Adress";
import { DateHelper } from "src/app/utils/DateHelper";


export class EmployeeForm {

  public static getForm(formBuilder: FormBuilder, obj?: Employee): FormGroup {
    let form = formBuilder.group({
      employee_id: [obj?.id],
      firstName: [this.getFormValue(obj, 'firstName'), Validators.required],
      lastName: [this.getFormValue(obj, 'lastName'), Validators.required],
      email: [this.getFormValue(obj, 'email'), [Validators.required, Validators.email]],
      location_id: [this.getFormValue(obj, 'locationId', Number(localStorage.getItem('location_id'))), Validators.required],
      mandant_id: [this.getFormValue(obj, 'mandantId', Number(localStorage.getItem('mandant_id'))), Validators.required],
      username: [this.getFormValue(obj, 'username'), Validators.required],
      password: [this.getFormValue(obj, 'password'), [Validators.pattern('^.{8,}$')]],
      create_password: [obj?false:true],
      active: [obj ? obj.active : true],
      role: [this.getFormValue(obj, 'role')],
      salutation: [this.getFormValue(obj, 'salutation'), Validators.required],
      title: [this.getFormValue(obj, 'title')],
      birthdate: [obj ? DateHelper.getConvertedDateForInputFull(obj.birthdate) : null],
      phone: [this.getFormValue(obj, 'phone')],
      authorization_number: [this.getFormValue(obj, 'authorization_number')],
      authorization_authority: [this.getFormValue(obj, 'authorization_authority')],
      authorization_date: [obj ? DateHelper.getConvertedDateForInputFull(obj.authorization_date) : null],
      authorization_groups: [this.getFormValue(obj, 'authorization_groups')],
      planning_color: [this.getFormValue(obj, 'planning_color')],
      address_id: [obj && obj.address ? obj.address.addressId : null],
      city: [obj && obj.address ? obj.address.city : null],
      postal_code: [obj && obj.address ? obj.address.postalCode : null],
      street: [obj && obj.address ? obj.address.street : null],
      country: [obj && obj.address ? obj.address.country : null],
      permission_group: [obj?.permission_group??obj?.permission_group, Validators.required],
    });
    form.get('create_password').valueChanges.subscribe((change) => {
      if(change){
        form.get('password').addValidators([Validators.required, Validators.pattern('^.{8,}$')]);
      } else {
        form.get('password').setValidators(null);
      }
      form.get('password').updateValueAndValidity();
    })
    return form;
  }

  static getFormValue(obj: any, key: string, defaultValue?: any) {
    if(obj && key){
      if(obj[key] && obj[key] != null) return obj[key];
    }
    return defaultValue != undefined ? defaultValue : null;
  }

  public static getObject(form: FormGroup): Employee {
    let obj = new Employee(
      form.get('employee_id').value,
      form.get('username').value,
      form.get('firstName').value,
      form.get('lastName').value,
      form.get('email').value,
      form.get('location_id').value,
      form.get('mandant_id').value,
      form.get('active').value,
      form.get('password').value,
      form.get('salutation').value,
      form.get('title').value,
      DateHelper.convertDateStringToTimestamp(form.get('birthdate').value),
      form.get('phone').value,
      form.get('authorization_number').value,
      form.get('authorization_authority').value,
      DateHelper.convertDateStringToTimestamp(form.get('authorization_date').value),
      form.get('authorization_groups').value,
      form.get('planning_color').value,
      form.get('permission_group').value.permission_group_id,
    );
    obj.permission_group = form.get('permission_group').value;
    if(form.get('address_id').value != null){
      obj.address = new Address(
        form.get('address_id').value,
        form.get('street').value,
        form.get('postal_code').value,
        form.get('city').value,
        form.get('country').value,
      );
    }
    return obj;
  }
}
