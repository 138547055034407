<div id="overview_container">
  <div class="drive_container_column">
    <div class="lbl_bold_light_color">Detailansicht</div>

    <div class="drive-details-body">
      <div class="drive-details-column">
        <h3>Informationen</h3>
        <div class="overview_record" >
          <label class="overview_record_lbl">Status</label>
          <label class="overview_record_value" [ngClass]="drive.getStatusColor()">{{drive.getStatus()}}</label>
        </div>
        <div class="overview_record" >
          <label class="overview_record_lbl">Datum</label>
          <label class="overview_record_value">{{getInputValueDate(drive.timestamp)}}</label>
        </div>
        <div class="overview_record" >
          <label class="overview_record_lbl">Uhrzeit</label>
          <label class="overview_record_value">{{getInputValueTime(drive.timestamp)}} - {{getInputValueTime(drive.timestamp+drive.duration)}}</label>
        </div>
        <div class="overview_record" >
          <label class="overview_record_lbl">Dauer</label>
          <label class="overview_record_value">{{drive.getDuration()+' min'}}</label>
        </div>
        <div class="overview_record" >
          <label class="overview_record_lbl">Fahrzeug</label>
          <label class="overview_record_value">{{drive.vehicle.getDescription(80)}}</label>
        </div>
        <div class="overview_record" >
          <label class="overview_record_lbl">Kennzeichen</label>
          <label class="overview_record_value">{{getSelectedPlate()}}</label>
        </div>
        <div class="overview_record" *ngIf="!drive.customer_id">
          <label class="overview_record_lbl">Mitarbeiter</label>
          <label class="overview_record_value">{{drive.employee.getFullName()}}</label>
        </div>
        <div class="overview_record" *ngIf="drive.customer_id">
          <label class="overview_record_lbl">Kunde</label>
          <label class="overview_record_value">{{drive.customer?.getName()}}</label>
        </div>
        <div class="overview_record" *ngIf="drive.trip_description">
          <label class="overview_record_lbl">Fahrtangaben</label>
          <label class="overview_record_value">{{drive.trip_description}}</label>
        </div>
      </div>
      <div class="drive-details-column">
        <div class="overview_record">
          <h3>Anhänge</h3>
          <button *ngIf="!showFileDrop" class="btn_list_details" style="justify-self: flex-end;" (click)="showFileDrop = true">hinzufügen</button>
        </div>
        <label *ngIf="sources.length == 0" class="lbl_regular_italic_light_color">Keine Dateien hinterlegt</label>
        <app-attachment-element *ngFor="let source of sources"
          [source]="source"
          [showDelete]="!drive.status_signiert"
          (deleteEmitter)="deleteAttachment($event)"
          (signPDFEmitter)="signPDF($event)"
        ></app-attachment-element>
        <div *ngIf="showFileDrop">
          <div id="upload_container_attachments">
            <div id="upload_box" class="hover_change" appDnd (fileDroppedEmitter)="onFileDropped($event)">
              <div class="upload_box_info">
                  <img class="upload_box_info_img" src="./assets/upload.svg">
                  <div class="upload_box_info_lbl">Ziehen Sie Dateien hier her, um sie hochzuladen.</div>
              </div>
            </div>
            <input #selectFile_dokumente type="file" accept="image/x-png,image/jpeg, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="uploadFile($event)" multiple="" style="display: none;">
            <div class="btn_dark_light" (click)="selectFile_dokumente.click()" style="align-self: center;">Dateien auswählen</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="drive_details_body">
      <div class="body_column">
        <div class="drive_details_status_wrapper">
          <label class="lbl_data_value_title">Status</label>
          <div [ngClass]="drive.getStatusColor()">{{drive.getStatus()}}</div>
        </div>
        <div class="drive_details_time_wrapper">
          <div class="drive_details_data_wrapper">
            <label class="lbl_data_value_title">Datum</label>
            <label class="list_title_lbl">{{drive.timestamp | date: 'dd.MM.yyyy'}}</label>
            <label class="lbl_regular_light">{{drive.timestamp | date: 'MMMM'}}</label>
          </div>
          <div class="drive_details_data_wrapper">
            <label class="lbl_data_value_title">Uhrzeit</label>
            <label class="list_title_lbl">{{drive.timestamp | date: 'HH:mm'}} - {{drive.timestamp + drive.duration | date: 'HH:mm'}}</label>
          </div>
        </div>
        <div class="drive_details_data_wrapper">
          <label class="lbl_data_value_title">Fahrzeug</label>
          <label class="list_title_lbl">{{drive.vehicle.getDescription(80)}}</label>
          <label class="lbl_regular_light">Fahrzeug-ID: {{drive.vehicle.id}}</label>
        </div>
      </div>
      <div class="body_column">
        <div class="drive_details_status_wrapper">
          <label class="lbl_data_value_title">Kennzeichen</label>
          <label class="list_title_lbl">{{getSelectedPlate()}}</label>
        </div>
        <div class="drive_details_data_wrapper" *ngIf="!drive.customer">
          <label class="lbl_data_value_title">Mitarbeiter</label>
          <label class="list_title_lbl">{{Mitarbeiter}}</label>
          <label class="lbl_regular_light">Mitarbeiter-ID: {{drive.employee_id}}</label>
        </div>
        <div class="drive_details_data_wrapper" *ngIf="drive.customer">
          <label class="lbl_data_value_title">Kunde</label>
          <label class="list_title_lbl">{{drive.customer.getName()}}</label>
          <label class="lbl_regular_light">Kunden-ID: {{drive.customer.id}}</label>
        </div>
        <div class="drive_details_data_wrapper">
          <div class="lbl_data_value_title attachments_title">
            Anhänge
            <button *ngIf="!showFileDrop" class="btn_list_details" (click)="showFileDrop = true">hinzufügen</button>
          </div>
          <label *ngIf="sources.length == 0" class="lbl_regular_italic_light_color">Keine Dateien hinterlegt</label>
          <app-attachment-element *ngFor="let source of sources"
            [source]="source"
            (deleteEmitter)="deleteAttachment($event)"
            (signPDFEmitter)="signPDF($event)"
          ></app-attachment-element>
          <div *ngIf="showFileDrop">
            <div id="upload_container_attachments">
              <div id="upload_box" class="hover_change" appDnd (fileDroppedEmitter)="onFileDropped($event)">
                <div class="upload_box_info">
                    <img class="upload_box_info_img" src="../../../../../assets/upload.svg">
                    <div class="upload_box_info_lbl">Ziehen Sie Dateien hier her, um sie hochzuladen.</div>
                </div>
              </div>
              <input #selectFile_dokumente type="file" accept="image/x-png,image/jpeg, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="uploadFile($event)" multiple="" style="display: none;">
              <div class="btn_dark_light" (click)="selectFile_dokumente.click()" style="align-self: center;">Dateien auswählen</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!-- <div class="drive_container_column">
    <div class="lbl_bold_light_color">Anmerkungen</div>
    <div class="message_element">

    </div>
  </div> -->
</div>
