<div class="header-base-style">
    <div class="header-inner-wrapper">
        <button class="button-icon-start" (click)="selectionCancel()">
        <img class="button-icon-start-img" src="./assets/cancle.svg"/>
        <span class="button-icon-start-span">Abbrechen</span>
        </button>
        <h2>Kunde {{obj!=null ? ('#'+ obj.id +' bearbeiten') : 'anlegen'}}</h2>
    </div>
    <div class="header-button-wrapper">
        <button *ngIf="!obj" class="btn_submit" (click)="selectionCreate()">Anlegen</button>
        <button *ngIf="obj" class="btn_submit" (click)="selectionSave()">Speichern</button>
    </div>
</div>

<form id="drive-create-body" class="body-base-style" [formGroup]="form">
    <div class="column">
        <div class="overview_data_box">
            <h3>Kunde</h3>
            <app-dropdown-enum
              [name]="'Anrede*'"
              [placeholder]="'---'"
              [control]="form.get('salutation')"
              [enumName]="'salutation'"
              [submitted]="submitted"
            ></app-dropdown-enum>
            <app-dropdown-strings
              [title]="'Titel'"
              [formcontrol]="form.get('title')"
              [headerText]="form.get('title').value"
              [values]="titleValues"
              (submitValueEmitter)="form.get('title').setValue($event)"
            ></app-dropdown-strings>
            <div class="input_wrapper">
                <label class="overview_record_lbl">Vorname*</label>
                <input type="text" placeholder="---" formControlName="firstName" [class.input_invalid]="submitted && form.get('firstName').invalid">
            </div>
            <div class="input_wrapper">
                <label class="overview_record_lbl">Nachname*</label>
                <input type="text" placeholder="---" formControlName="lastName" [class.input_invalid]="submitted && form.get('lastName').invalid">
            </div>
            <div class="input_wrapper">
                <label class="overview_record_lbl">Geburtsdatum</label>
                <input #inputDate1 type="date" placeholder="date" formControlName="birthdate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls.birthdate.errors">
            </div>
        </div>

        <div class="overview_data_box">
            <h3>Kontakt und Anschrift</h3>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Straße, Nr.*</label>
              <input type="text" placeholder="Straße, Nr." formControlName="street" [class.input_invalid]="submitted && form.get('street').errors">
            </div>
            <div class="input_wrapper">
             <label class="overview_record_lbl">PLZ*</label>
             <input type="text" placeholder="PLZ" formControlName="postalCode" [class.input_invalid]="submitted && form.controls.postalCode.errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Ort*</label>
              <input type="text" placeholder="Ort" formControlName="city" [class.input_invalid]="submitted && form.controls.city.errors">
            </div>
            <!-- <div class="input_wrapper">
              <label class="overview_record_lbl">Land</label>
              <input type="text" placeholder="Land" formControlName="country" [class.input_invalid]="submitted && form.controls.country.errors">
            </div> -->
            <app-dropdown-strings
              [title]="'Land'"
              [placeholder]="'Land'"
              [formcontrol]="form.controls['country']"
              [headerText]="form.controls['country'].value"
              [values]="countries"
              [isOptional]="true"
              (submitValueEmitter)="form.controls['country'].setValue($event);"
            ></app-dropdown-strings>
            <div class="input_wrapper">
              <label class="overview_record_lbl">E-Mail*</label>
              <input type="text" placeholder="E-Mail" formControlName="email" [class.input_invalid]="submitted && form.controls.email.errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Telefon*</label>
              <input type="text" placeholder="Telefon" formControlName="phone" [class.input_invalid]="submitted && form.controls.phone.errors">
            </div>
        </div>

          <div class="overview_data_box">
            <h3>Weitere Informationen</h3>
            <app-dropdown-multiple
                [title]="'Kundentyp'"
                [formcontrol]="form.get('typ')"
                [headerText]="typValues[form.get('typ').value-1]"
                [values]="typValues"
                (submitValueEmitter)="form.get('typ').setValue($event)"
            ></app-dropdown-multiple>
            <app-dropdown-multiple
                [title]="'Kundenstatus'"
                [formcontrol]="form.get('status')"
                [headerText]="statusValues[form.get('status').value-1]"
                [values]="statusValues"
                (submitValueEmitter)="form.get('status').setValue($event)"
            ></app-dropdown-multiple>
            <div class="input_wrapper" *ngIf="form.get('typ').value == 1">
              <label class="overview_record_lbl">Firmenname</label>
              <input type="text" placeholder="---" formControlName="companyName" [class.input_invalid]="submitted && form.get('companyName').invalid">
            </div>
            <div class="input_wrapper" *ngIf="form.get('typ').value == 1">
              <label class="overview_record_lbl">ATU</label>
              <input type="text" placeholder="---" formControlName="atuNum">
            </div>
            <app-dropdown-objects-search
              [title]="'Mitarbeiter zuordnen'"
              [headervalue]="form.controls['employee'].value ? form.controls['employee'].value?.getFullName() : null"
              [formcontrol]="form.controls['employee']"
              [values]="employeeValues"
              [submitted]="submitted"
              (submitValueEmitter)="setValueEmployeeFromDropdown($event)"
            ></app-dropdown-objects-search>
        </div>
    </div>

    <div class="column">
      <div class="overview_data_box">
        <h3>Informationen zum Führerschein</h3>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Nummer</label>
            <input type="text" placeholder="Führerschein-Nummer" formControlName="authorization_number" [class.input_invalid]="submitted && form.controls.authorization_number.errors">
        </div>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Behörde</label>
            <input type="text" placeholder="Führerschein-Nummer" formControlName="authorization_authority" [class.input_invalid]="submitted && form.controls.authorization_authority.errors">
        </div>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Datum</label>
            <input #inputDate1 type="date" placeholder="Führerschein-Datum" formControlName="authorization_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls.authorization_date.errors">
        </div>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Gruppen</label>
            <input type="text" placeholder="Führerschein-Gruppen" formControlName="authorization_groups" [class.input_invalid]="submitted && form.controls.authorization_groups.errors">
        </div>
      </div>
    </div>

</form>
