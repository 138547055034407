import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { CustomerService } from './../../service/customer/customer.service';
import { Vehicle } from './../../buisness-object/vehicle/Vehicle';
import { forkJoin, Subscription } from 'rxjs';
import { DialogService } from './../../service/dialog/dialog.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DrivesService } from 'src/app/service/drives/drives.service';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Attachment } from 'src/app/buisness-object/attachment/Attachment';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { DocumentService } from 'src/app/service/document/document.service';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { DateHelper } from 'src/app/utils/DateHelper';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { DokumentTyp } from '../dokumente/dokumente.component';
import { ActivatedRoute, Router } from '@angular/router';

export enum DriveTab {
  Current = 1,
  Past = 2
}

@Component({
  selector: 'app-drives',
  templateUrl: './drives.component.html',
  styleUrls: ['./drives.component.css']
})
export class DrivesComponent implements OnInit, OnDestroy {
  public surface = 1;
  public tab = 1;
  public pageDrive = 1;
  public drives: Drive[] = [];
  public drivesFiltered: Drive[] = [];
  public vehicles: Vehicle[] = [];
  public customers: Customer[] = [];
  public employees: Employee[] = [];
  public dialogQuerySubsription: Subscription;
  public signatureLoaderSubscription: Subscription;
  public pageHandler: PageHandler;
  public pageInfo: string;
  public sliceTo: number;
  public sliceFrom: number;
  public submitted = false;
  public form: UntypedFormGroup;
  public formNew: UntypedFormGroup;
  public driveTyp = 1;
  public selectedDrive: Drive;
  public lastViewCalender = false;
  public showPdfDialog = false;
  public default = "Bezirk Krems & Umgebung";
  public selectedTab = DriveTab.Current;

  constructor(
    private driveService: DrivesService,
    private customerService: CustomerService,
    private vehicleService: VehicleService,
    private dialogService: DialogService,
    private formBuilder: UntypedFormBuilder,
    private attachmentService: AttachmentService,
    private documentService: DocumentService,
    private employeeService: EmployeeService,
    public authService: AuthenticationService,
    public activateRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.signatureLoaderSubscription) this.signatureLoaderSubscription.unsubscribe();
  }

  getDataServerside() {
    forkJoin({
      drives: this.driveService.getTestDrives(),
      customers: this.customerService.getCustomer(),
      vehicles: this.vehicleService.getVehicle(),
      employees: this.employeeService.getEmployees(),
    }).subscribe((result) => {
      if(result){
        this.drives = result.drives;
        this.vehicles = result.vehicles;
        this.customers = result.customers;
        this.employees = result.employees;
        this.mergeData();
        this.selectionTab(DriveTab.Current);
        this.routListener();
      }
    });
  }

  routListener() {
    let params = this.activateRoute.snapshot.queryParams;
    const route = this.activateRoute.snapshot.url[1]?.path;
    const vehicleId = params['vehicleId'];
    const typ = params['typ'];
    if(route == 'create' && vehicleId && typ){
      let vehicle = this.vehicles.find((v) => v.id == vehicleId);
      if(vehicle){
        if(typ == 1) this.openCreate();
        else if(typ == 2) this.selectionCreateInternDrive();
        this.form.get('vehicle').setValue(vehicle);
      }
    }
  }

  mergeData() {
    for(let drive of this.drives){
      for(let customer of this.customers){
        if(drive.customer_id == customer.id){
          drive.customer = customer;
          break;
        }
      }
      for(let vehicle of this.vehicles){
        if(vehicle.id == drive.vehicle_id){
          drive.vehicle = vehicle;
          drive.subject = vehicle.brand.makeName + ' ' +  vehicle.model.name;
          break;
        }
      }
      for(let employee of this.employees){
        if(employee.id == drive.employee_id){
          drive.employee = employee;
          break;
        }
      }
    }
    this.drivesFiltered = this.drives;
  }

  sort() {
    this.drivesFiltered.sort((a,b) => {
      if(a.start_time > b.start_time) return 1;
      if(a.start_time < b.start_time) return -1;
      return 0;
    })
  }

  setPages() {
    this.pageHandler = new PageHandler(this.drivesFiltered.length);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }
  pageHandling(next: boolean) {
    this.pageHandler.action(next);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }

  createForm(drive?: Drive) {
    this.form = this.formBuilder.group({
      test_drive_id: [drive ? drive.test_drive_id : 0, Validators.required],
      vehicle: [drive ? drive.vehicle : null, Validators.required],
      start_time: [drive ? drive.start_time : null, Validators.required],
      end_time: [drive ? drive.end_time : null, Validators.required],
      customer: [drive ? drive.customer : null, Validators.required],
      employee: [drive ? drive.employee : null],
      attachments: [drive ? drive.attachments : [], Validators.required],
      license_plate_id: [drive ? drive.license_plate_id : null, Validators.required],
      trip_description: [drive ? drive.trip_description : (this.authService.isSinhuber() ? this.default : (this.authService.isHoedl() ? "Hönigtal" : null)), Validators.required],
      drive_typ: [drive ? drive.customer_id ? 1 : 2 : this.driveTyp, Validators.required],
    })
    if(this.form.get('drive_typ').value == 2){
      this.form.get('employee').setValidators(Validators.required);
      this.form.get('employee').updateValueAndValidity();
    }
  }

  createFormNew(drive?: Drive) {
    this.formNew = this.formBuilder.group({
      test_drive_id: [drive ? drive.test_drive_id : 0, Validators.required],
      vehicle: [drive ? drive.vehicle : null, Validators.required],
      start_date: [null, Validators.required],
      start_time: [null, Validators.required],
      duration: [drive ? Math.round((drive.start_time - drive.end_time) / 1000 / 60) : 25, Validators.required],
      customer: [drive ? drive.customer : null],
      employee: [drive ? drive.employee : null],
      license_plate_id: [drive ? drive.license_plate_id : null, Validators.required],
      trip_description: [drive ? drive.trip_description : (this.authService.isSinhuber() ? this.default : (this.authService.isHoedl() ? "Hönigtal" : "Test")), Validators.required],
      drive_typ: [drive ? drive.customer_id ? 1 : 2 : this.driveTyp, Validators.required],
    })
    if(this.formNew.get('drive_typ').value == 2){
      this.formNew.get('employee').setValidators(Validators.required);
      this.formNew.get('employee').updateValueAndValidity();
    } else {
      this.formNew.get('customer').setValidators(Validators.required);
      this.formNew.get('customer').updateValueAndValidity();
    }
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'storno_drive') this.deleteDrive(value.submit_value);
      }
    });
    this.signatureLoaderSubscription = this.dialogService.closeDialogLoaderSignature$.subscribe((value) => {
      if(value){
        this.getDataServerside();
      }
    })
  }

  changeTab(tab: number) {
    this.tab = tab;
    if(tab == 1) this.surface = 1;
    if(tab == 2) this.surface = 2;
  }

  openCreate() {
    this.driveTyp = 1;
    this.submitted = false
    this.createForm();
    this.createFormNew();
    this.surface = 3;
  }

  nextPage(next: boolean) {
    if(next){
      this.submitted = true;
      if(this.pageDrive == 1){
        if(this.form.controls.vehicle.invalid || this.form.controls.start_time.invalid || this.form.controls.end_time.invalid){
          ErrorHelper.showFormError(this.form);
          return;
        }
      }
      if(this.driveTyp == 2){
        this.createDrive();
        return;
      } if(this.pageDrive == 2){
        if(this.form.controls.customer.invalid) return;
        this.createDrive();
      }
      if(this.pageDrive < 2) this.pageDrive++;
      this.submitted = false;
    } else {
      this.pageDrive--;
    }
  }

  async createDriveNew(drive: Drive) {
    let promises: any[] = [];
    this.driveService.createDrive(drive).subscribe((result) => {
      if(result){
        result.status_signiert = 0;
        if(drive.customer_id == null){
          this.getDrivePdf(result);
          this.submitted = false;
          this.pageDrive = 1;
          this.surface = 1;
        } else {
          if(this.form.controls.attachments.value?.length > 0){
            result.attachments = [];
            for(let a of this.form.controls.attachments.value){
              let attachment = new Attachment(
                0,
                3,
                result.test_drive_id,
                a.format,
                a.name,
                a.data
              );
              promises.push(this.createAttachement(attachment).then((att) => {
                result.attachments.push(att);
              }));
            }
          }
          Promise.all(promises).then(() => {
            result.vehicle = drive.vehicle;
            if(result.employee_id){
              for(let e of this.employees){
                if(e.id == result.employee_id){
                   result.employee = e;
                   break;
                }
              }
            } else {
              result.customer = drive.customer;
            }
            this.drives.push(result);
            this.drivesFiltered = this.drives;
            this.selectionTab(DriveTab.Current);
            this.submitted = false;
            this.pageDrive = 1;
            this.surface = 1;
            this.dialogService.showNotification({
              title: 'Erfolgreich',
              message: 'Probefahrt wurde erstellt.',
              success: true
            });
          })
        }
      }
    });
  }

  saveDriveNew() {
    this.submitted = true;
    if(this.formNew.invalid){
      ErrorHelper.showFormError(this.formNew);
      return;
    }
    let startDate = DateHelper.convertDateStringToTimestamp(this.formNew.controls['start_date'].value + ' ' + this.formNew.controls['start_time'].value);
    let duration = this.formNew.controls['duration'].value * 60 * 1000;
    let endDate = startDate + duration;
    if(this.formNew.controls['drive_typ'].value == 1){
      let driveToCreate = new Drive(
        0,
        this.authService.getLocationID(),
        startDate,
        duration,
        this.formNew.controls['vehicle'].value.id,
        this.formNew.controls['customer'].value.id,
        this.formNew.controls['license_plate_id'].value,
        [],
        startDate,
        endDate
      );
      driveToCreate.trip_description = this.formNew.controls['trip_description'].value;
      driveToCreate.vehicle = this.formNew.controls['vehicle'].value;
      driveToCreate.customer = this.formNew.controls['customer'].value;
      this.createDriveNew(driveToCreate);
    } else {
      let driveToCreate = new Drive(
        0,
        this.authService.getLocationID(),
        startDate,
        duration,
        this.formNew.controls['vehicle'].value.id,
        null,
        this.formNew.controls['license_plate_id'].value,
        [],
        startDate,
        endDate
      );
      driveToCreate.employee_id = this.formNew.controls['employee'].value.id;
      driveToCreate.trip_description = this.formNew.controls['trip_description'].value;
      driveToCreate.vehicle = this.formNew.controls['vehicle'].value;
      driveToCreate.customer = this.formNew.controls['customer'].value;
      this.createDriveNew(driveToCreate);
    }


  }

  async createDrive() {
    let drive = new Drive(
      0,
      Number(localStorage.getItem('location_id')),
      this.form.controls.start_time.value,
      this.form.controls.end_time.value - this.form.controls.start_time.value,
      this.form.controls.vehicle.value.id,
      this.form.controls.customer.value?.id,
      this.form.controls.license_plate_id.value,
    )
    if(this.form.get('employee').value) drive.employee_id = this.form.get('employee').value.id;
    drive.vehicle = this.form.controls.vehicle.value;
    drive.customer = this.form.controls.customer.value;
    drive.trip_description = this.form.controls['trip_description'].value;
    if(this.driveTyp == 3){
      drive.customer_id = null;
      drive.customer = null;
    }
    let promises: any[] = [];
    await this.driveService.createDrive(drive).subscribe((result) => {
      if(result){
        result.status_signiert = 0;
        if(drive.customer_id == null){
          this.getDrivePdf(result);
          this.submitted = false;
          this.pageDrive = 1;
          this.surface = 1;
          this.router.navigate(['/drives']);
        } else {
          if(this.form.controls.attachments.value?.length > 0){
            result.attachments = [];
            for(let a of this.form.controls.attachments.value){
              let attachment = new Attachment(
                0,
                3,
                result.test_drive_id,
                a.format,
                a.name,
                a.data
              );
              promises.push(this.createAttachement(attachment).then((att) => {
                result.attachments.push(att);
              }));
            }
          }
          Promise.all(promises).then(() => {
            result.vehicle = drive.vehicle;
            if(result.employee_id){
              for(let e of this.employees){
                if(e.id == result.employee_id){
                   result.employee = e;
                   break;
                }
              }
            } else {
              result.customer = drive.customer;
            }
            this.drives.push(result);
            this.drivesFiltered = this.drives;
            this.selectionTab(DriveTab.Current);
            this.submitted = false;
            this.pageDrive = 1;
            this.surface = 1;
            this.router.navigate(['/drives']);
            this.dialogService.showNotification({
              title: 'Erfolgreich',
              message: 'Probefahrt wurde erstellt.',
              success: true
            });
          })
        }
      }
    });
  }

  async createAttachement(attachement: Attachment): Promise<any> {
    return new Promise<Attachment>((resolve, reject) => {
      this.attachmentService.createAttachment(attachement).subscribe((success) => {
        if(success){
          resolve(attachement);
        }
      })
    }).catch((error) => {
      console.log(error);
    });
  }

  cancelCreate() {
    this.router.navigate(['/drives']);
    this.pageDrive = 1;
    this.createForm();
    this.surface = 1;
  }

  async selectionSignedPDF(drive: Drive) {
    if(drive.customer_id == null){
      let attachment = drive.attachments.find((a) => a.filename == 'Zusatzblatt_zum_Probefahrtschein');
      if(attachment == null){
        this.getDrivePdf(drive);
      } else {
        let data = await this.getAttachment(attachment.attachment_id);
        this.dialogService.openAttachementViewer({ pdf: data.data, isArrayBuffer: false });
      }
    } else {
      let attachment = drive.attachments.find((a) => a.filename == 'Probefahrtsformular_signiert');
      if(attachment != null){
        let data = await this.getAttachment(attachment.attachment_id);
        this.dialogService.openAttachementViewer({ pdf: data.data, isArrayBuffer: false });
      }
    }
  }

  async openDetails(drive: Drive) {
    this.selectedDrive = drive;
    let a = [];
    for(let attachment of drive.attachments){
      if(attachment.attachment_id && attachment.attachment_id != 0){
        a.push(await this.getAttachment(attachment.attachment_id));
      } else {
        a.push(attachment)
      }
    }
    drive.attachments = a;
    if(this.surface == 2) this.lastViewCalender = true;
    this.surface = 4;
  }

  backAction() {
    if(this.lastViewCalender){
      this.surface = 2;
      this.lastViewCalender = false;
    } else {
      this.selectedDrive = null;
      this.surface = 1;
    }
  }

  deleteAction(drive: Drive) {
    this.dialogService.openQuery(
      {
        title: 'Probefahrt stornieren?',
        message: 'Sind sie sicher, dass Sie die Probefahrt stonieren möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Stornieren',
        typ: 'storno_drive',
        submit_value: drive.test_drive_id,
      }
    );
  }

  deleteDrive(id: number) {
    this.driveService.deleteDrive(id).subscribe((suucess) => {
      if(suucess){
        let index = this.drives.findIndex(d => d.test_drive_id == id);
        if(index > -1) this.drives.splice(index, 1);
        this.drivesFiltered = this.drives;
        this.selectionTab(this.selectedTab);
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Probefahrt wurde storniert.',
          success: true
        });
      }
    })
  }

  searchAction(value: string) {
    let temp = [];
    if(this.selectedTab == DriveTab.Past){
      temp = this.drives.filter((d) => {
        let date = new Date(d.start_time);
        let today = new Date();
        return !(date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()) && d.start_time < new Date().getTime();
      });
    } else if(this.selectedTab == DriveTab.Current){
      temp = this.drives.filter((d) => {
        let date = new Date(d.start_time);
        let today = new Date();
        return (date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()) || d.start_time >= new Date().getTime();
      });
    }
    this.drivesFiltered = temp.filter((drive) => {
      if(value && value.length > 0){
        this.drivesFiltered = [];
        return drive.vehicle.brand.makeName.toLowerCase().includes(value.toLowerCase()) ||
        drive.vehicle.model.name?.toLowerCase().includes(value.toLowerCase()) ||
        drive.vehicle.model.version?.toLowerCase().includes(value.toLowerCase()) ||
        drive.vehicle?.fin_number?.toLowerCase().includes(value.toLowerCase()) ||
        drive.customer?.contactPerson.firstName.toLowerCase().includes(value.toLowerCase()) ||
        drive.customer?.contactPerson.lastName.toLowerCase().includes(value.toLowerCase()) ||
        drive.employee?.getFullName()?.toLowerCase().includes(value.toLowerCase()) ||
        drive.employee?.getFullName().toLowerCase().includes(value.toLowerCase());
      } else {
        return temp;
      }
    });
    this.setPages();
  }

  openCreatePdf(drive: Drive) {
    if(drive.customer_id == null){
      this.getDrivePdf(drive);
    } else {
      this.selectedDrive = drive;
      this.showPdfDialog = true;
    }
  }

  cancelPdfCreate() {
    this.selectedDrive = null;
    this.showPdfDialog = false;
  }

  createPdfCreate(data: any) {
    let isSign = data.sign;
    let form: FormGroup = data.form;
    this.selectedDrive.authorization_number = form.get('authorization_number').value;
    this.selectedDrive.authorization_authority = form.get('authorization_authority').value;
    this.selectedDrive.authorization_date = DateHelper.convertDateStringToTimestamp(form.get('authorization_date').value);
    this.selectedDrive.authorization_groups = form.get('authorization_groups').value;
    this.selectedDrive.trip_description = form.get('trip_description').value;
    this.driveService.updateDrive(this.selectedDrive).subscribe((result) => {
      if(result){
        result.customer = this.selectedDrive.customer
        result.authorization_number = this.selectedDrive.authorization_number
        result.authorization_authority = this.selectedDrive.authorization_authority
        result.authorization_date = this.selectedDrive.authorization_date
        result.authorization_groups = this.selectedDrive.authorization_groups
        result.trip_description = this.selectedDrive.trip_description
        this.selectedDrive = result;
        if(isSign){
          this.signPDF(this.selectedDrive);
        } else {
          this.getDrivePdf(result);
        }
      }
    });
  }

  getDrivePdf(drive: Drive) {
    this.driveService.getDrivePDF(drive.test_drive_id).subscribe((result) => {
      if(result){
        if(drive.status_signiert == 0) {
          this.addAttachement(drive, result);
        } else if(drive.customer_id == null){
          this.addAttachement(drive, result);
        }
      }
    });
  }

  addAttachement(drive: Drive, result: any) {
    let attachment = new Attachment(
      0,
      3,
      drive.test_drive_id,
      'pdf',
      'Zusatzblatt_zum_Probefahrtschein',
      result
    );
    this.attachmentService.createAttachment(attachment).subscribe((successDrive) => {
      if(successDrive){
        drive.attachments.push(attachment);
        if(drive.customer_id != null){
          attachment.typ = 2;
          attachment.typ_id = this.selectedDrive?.customer?.id;
          this.attachmentService.createAttachment(attachment).subscribe((successKunde) => {
            if(successKunde){
              // this.updateListLocal(drive);
              this.getDataServerside();
              this.selectedDrive = null;
              this.showPdfDialog = false;
            }
          })
        } else {
          this.getDataServerside();
          this.selectedDrive = null;
          this.showPdfDialog = false;
        }
      }
    })
  }

  updateListLocal(drive: Drive) {
    let index = this.drives.findIndex(d => d.test_drive_id == drive.test_drive_id);
    if(index > -1){
      this.drives[index] = drive;
    }
    let indexFiltered = this.drives.findIndex(d => d.test_drive_id == drive.test_drive_id);
    if(indexFiltered > -1){
      this.drivesFiltered[index] = drive;
    }
    this.selectionTab(this.selectedTab);
  }

  getAttachment(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.attachmentService.getAttachment(id).subscribe((result) => {
        if(result){
          resolve(result);
        }
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  signPDF(drive: Drive){
    this.documentService.createSignatureDocument(drive).subscribe((result) => {
      if(result){
        this.dialogService.openLoaderSignature({document_typ: DokumentTyp.Probefahrt});
        this.showPdfDialog = false;
      }
    })
  }

  selectionCreateInternDrive() {
    this.driveTyp = 2;
    this.submitted = false;
    this.createForm();
    this.createFormNew();
    this.surface = 3;
  }

  selectionTab(tab: DriveTab) {
    this.selectedTab = tab;
    if(this.selectedTab == DriveTab.Past){
      this.drivesFiltered = this.drives.filter((d) => {
        let date = new Date(d.start_time);
        let today = new Date();
        return !(date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()) && d.start_time < new Date().getTime();
      });
    } else if(this.selectedTab == DriveTab.Current){
      this.drivesFiltered = this.drives.filter((d) => {
        let date = new Date(d.start_time);
        let today = new Date();
        return (date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()) || d.start_time >= new Date().getTime();
      });
    }
    this.setPages();
    this.sort();
  }

  selectionPseudoSignature(object: Drive) {
    this.documentService.uploadPDF(4, object.test_drive_id, null).subscribe((success) => {
      if(success){
        this.getDataServerside();
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Probefahrt wurde signiert.',
          success: true
        });
      }
    });
  }
}
