import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VehicleError } from 'src/app/buisness-object/vehicle/VehicleError';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-dialog-bca-import-errors',
  templateUrl: './dialog-bca-import-errors.component.html',
  styleUrl: './dialog-bca-import-errors.component.css'
})
export class DialogBcaImportErrorsComponent implements OnInit {
  @Input() vehicleBCAErrors: VehicleError[] = [];
  @Input() vehicleBCASTotalNumber: number;
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  public totalNumber;

  constructor(
    private clip: Clipboard
  ){}

  ngOnInit(): void {
    this.totalNumber = this.vehicleBCASTotalNumber > 0 ? (this.vehicleBCASTotalNumber + this.vehicleBCAErrors.length) : 0;
  }

  copyVIN(error: VehicleError){
    this.clip.copy(error.vin);
    error.copy_text = 'VIN wurde kopiert';
    this.vehicleBCAErrors.forEach(element => {
      if(element.vin != error.vin){
        element.copy_text = 'VIN kopieren';
      }
    });
  }
}
