import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drag-n-drop',
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.css']
})
export class DragNDropComponent implements OnInit {
  public vehicleImgs: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      let img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        this.vehicleImgs.push(img.src);
      }
    }
    reader.readAsDataURL(file);
  }
}
