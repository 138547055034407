import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { ActivatedRoute } from '@angular/router';
import { RentService } from 'src/app/service/rent/rent.service';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { Attachment, AttachmentCategory, AttachmentHelper, AttachmentType } from 'src/app/buisness-object/attachment/Attachment';

@Component({
  selector: 'app-miete-form',
  templateUrl: './miete-form.component.html',
  styleUrl: './miete-form.component.css'
})

export class MieteFormComponent implements OnInit {

  public form: FormGroup;
  public obj: Customer;
  public submitted = false;
  public token: string;
  public tokenExpired = false;
  public validToken = true;
  public filesAmount;
  public onloadSrc = [];
  public imageOne: Attachment;
  public flowSuccess = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private rentService: RentService,
  ){}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      authorization_number: [ null, Validators.required],
      authorization_authority: [ null, Validators.required],
      authorization_date: [null, Validators.required],
      authorization_groups: [null, Validators.required],
      image_one: [ null, Validators.required]
    })

    this.route.queryParams.subscribe(params => {
      this.token = params['token']; 
      if(!this.token || this.token?.length < 10){
        this.validToken = false;
      }
    });
  }

  invalidForm(): boolean {
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return true;
    }
    if(this.form.get('image_one').value == null){
      return true;
    }
    return false;
  }

  async submit() {
    this.submitted = true;
    if(this.invalidForm()){
      return
    }
    let json = {
      "authorization_number": this.form.get('authorization_number').value,
      "authorization_authority": this.form.get('authorization_authority').value,
      "authorization_date": new Date(this.form.get('authorization_date').value).getTime(),
      "authorization_groups": this.form.get('authorization_groups').value,
      "image": this.form.get('image_one').value,
    }
    this.createRentOrderCustomerWeb(json);
  }

  createRentOrderCustomerWeb(json: any): void {
    this.rentService.updateRentCustomerWeb(this.token, json).subscribe((result) => {
      if(result) {
        this.flowSuccess = true;
      } else {
        this.validToken = false;
      }
    })
  }

  async onFileDropped(data: any) {
    if(!data) return;
    for(let file of data.data){
      const src = file.content;
      this.form.get('image_one').setValue(src.split(',')[1].trim());
      return;
    }
  }

  async uploadFile(event: any) {
    this.onloadSrc = [];
    if(event.target.files && event.target.files.length != 0){
      let attachment =  await this.readFile(event.target.files[0]);
      this.form.get('image_one').setValue(attachment.data);
      event.target.value = '';
    }
  }

  async readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        const src = event.target.result as string;
        let attachment = new Attachment(
          0,
          AttachmentType.CUSTOMER,
          AttachmentHelper.getAttachmentTypID(this.obj),
          file.name.split('.')[1],
          file.name.split('.')[0],
          src.split(',')[1].trim(),
          AttachmentCategory.NONE
        )
        resolve(attachment)
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  selectionDeleteImageOne() {
    this.form.get('image_one').setValue(null);
  }
}
