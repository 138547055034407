import { Component, Input, OnInit } from '@angular/core';
import { BillItem } from 'src/app/buisness-object/document/BillItem';

@Component({
  selector: 'app-bill-details-position-element',
  templateUrl: './bill-details-position-element.component.html',
  styleUrl: './bill-details-position-element.component.css'
})
export class BillDetailsPositionElementComponent implements OnInit {
  @Input() position: BillItem;
  @Input() index: number;

  constructor(){}

  ngOnInit(): void {
  }
}
