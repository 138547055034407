export class DropdownHighlighter {
    public keyDownItem: HTMLElement;

    constructor(){}

    highlightElement(dropdownValues: any[], key: string) {
        for(let value of dropdownValues){
          if(value.name[0].toLowerCase() == key){
            if(this.keyDownItem){
              this.keyDownItem.className = 'dropdown_data_body_element';
            }
            this.keyDownItem = document.getElementById('dropdown_value_' + value.id);
            if(this.keyDownItem){
              this.keyDownItem.className ='dropdown_data_body_element_hover_status';
              this.keyDownItem.scrollIntoView({block: "start"});
            }
            break;
          }
        }
      }

      onMouseEnterDropdown() {
        if(this.keyDownItem){
          this.keyDownItem.className = 'dropdown_data_body_element';
          this.keyDownItem = null;
        }
      }
}
