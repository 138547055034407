<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button type="button" class="button-icon-start" (click)="selectionCancel()">
      <img class="button-icon-start-img" src="./assets/cancle.svg"/>
      <span class="button-icon-start-span">Abbrechen</span>
    </button>
    <h2>Rechnung {{obj!=null ? 'bearbeiten' : 'erstellen'}}</h2>
  </div>
  <div class="header-button-wrapper" *ngIf="!showCreateChoise">
    <button *ngIf="!obj" type="button" class="btn_submit" [disabled]="form == null" (click)="selectionCreate()">Erstellen</button>
    <button *ngIf="obj" type="button" class="btn_submit" [disabled]="form == null" (click)="selectionSave()">Speichern</button>
  </div>
</div>

<div class="body-base-style choise-wrapper" *ngIf="form && showCreateChoise">
  <div class="choise-container" [class.choise_container_disabled]="false">
    <h1>Kunden wählen</h1>
    <form [formGroup]="form" class="form">
      <app-dropdown-objects-search
        [title]="'Kunde*'"
        [placeholder]="'Kunde'"
        [headervalue]="form.controls['customer']?.value?.getName()"
        [formcontrol]="form.controls['customer']"
        [values]="customerValues"
        [submitted]="submitted"
        [showLabel]="false"
        (submitValueEmitter)="setValueFromDropdown('customer', $event)"
      ></app-dropdown-objects-search>
    </form>
    <label>Wählen Sie zuerst einen Kunden, um den <br>Kaufvertrag zu erstellen.</label>
    <button type="button" [disabled]="form.controls['customer'].value == null" class="btn_submit_dark" (click)="submitCustomer()">Weiter</button>
  </div>
  <label id="choise-split-label">ODER</label>
  <div class="choise-container">
      <h1>Neuer Kunde</h1>
      <label>Legen Sie einen neuen Kunden an, um den <br>Kaufvertrag zu erstellen.</label>
      <button type="button" class="btn_submit_dark" (click)="goToCustomer()">Weiter</button>
  </div>
</div>

<form [formGroup]="form" id="contract-create-body" class="body-base-style" *ngIf="form && !showCreateChoise">
  <div class="column">
    <form class="overview_data_box" [formGroup]="formCustomer">
      <label class="overview_data_box_lbl_title">Kunde</label>
      <app-dropdown-multiple
        [title]="'Anrede*'"
        [formcontrol]="formCustomer.controls['salutation']"
        [headerText]="salutationValues[formCustomer.controls['salutation']?.value-1]"
        [values]="salutationValues"
        (submitValueEmitter)="formCustomer.controls['salutation'].setValue($event)"
      ></app-dropdown-multiple>
      <app-dropdown-strings
        [title]="'Titel'"
        [placeholder]="'---'"
        [formcontrol]="formCustomer.controls['title']"
        [headerText]="formCustomer.controls['title'].value"
        [values]="titleValues"
        (submitValueEmitter)="formCustomer.controls['title'].setValue($event)"
      ></app-dropdown-strings>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Vorname*</div>
        <input type="text" placeholder="Vorname" formControlName="firstName" [class.input_invalid]="submitted && formCustomer.controls['firstName'].errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Nachname*</div>
        <input type="text" placeholder="Nachname" formControlName="lastName" [class.input_invalid]="submitted && formCustomer.controls['lastName'].errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Geburtsdatum</div>
        <input #inputDate1 type="date" placeholder="date" [max]="today" formControlName="birthdate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && formCustomer.controls['birthdate'].errors">
      </div>
      <app-dropdown-multiple
        [title]="'Kundentyp'"
        [formcontrol]="formCustomer.get('typ')"
        [headerText]="typValues[formCustomer.get('typ').value-1]"
        [values]="typValues"
        (submitValueEmitter)="formCustomer.get('typ').setValue($event)"
      ></app-dropdown-multiple>
      <div class="input_wrapper" *ngIf="formCustomer.controls['typ'].value == 1">
        <label class="overview_record_lbl">Firmenname *</label>
        <input type="text" placeholder="Firmenname" formControlName="companyName" [class.input_invalid]="submitted && formCustomer.controls['companyName'].errors">
      </div>
      <div class="input_wrapper" *ngIf="formCustomer.controls['typ'].value == 1">
        <label class="overview_record_lbl">ATU</label>
        <input type="text" placeholder="ATU" formControlName="atuNum" [class.input_invalid]="submitted && formCustomer.controls['atuNum'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Straße, Nr*</label>
        <input type="text" placeholder="Straße" formControlName="street" [class.input_invalid]="submitted && formCustomer.controls['street'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">PLZ*</label>
        <input type="number" placeholder="PLZ" formControlName="postalCode" [class.input_invalid]="submitted && formCustomer.controls['postalCode'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Ort*</label>
        <input type="text" placeholder="Ort" formControlName="city" [class.input_invalid]="submitted && formCustomer.controls['city'].errors">
      </div>
      <app-dropdown-strings
        [title]="'Land'"
        [placeholder]="'Land'"
        [formcontrol]="formCustomer.controls['country']"
        [headerText]="formCustomer.controls['country'].value"
        [values]="countries"
        [isOptional]="true"
        (submitValueEmitter)="formCustomer.controls['country'].setValue($event);"
      ></app-dropdown-strings>
      <div class="input_wrapper">
        <label class="overview_record_lbl">E-Mail*</label>
        <input type="text" placeholder="E-Mail" formControlName="email" [class.input_invalid]="submitted && formCustomer.controls['email'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Telefon*</label>
        <input type="text" placeholder="Telefon" formControlName="phone" [class.input_invalid]="submitted && formCustomer.controls['phone'].errors">
      </div>
      <app-dropdown-simple *ngIf="authService.isHoedl()"
        [title]="'Politisch Exponierte Person*'"
        [formcontrol]="formCustomer.controls['is_pep']"
        (submitValueEmitter)="formCustomer.controls['is_pep'].setValue($event)"
      ></app-dropdown-simple>
    </form>

    <!--Sonderkonditionen-->
    <div class="overview_data_box">
      <label class="overview_data_box_lbl_title">Sonderkonditionen</label>
      <textarea
        appTextfieldAutoresize
        style="height: 23px;"
        class="textField"
        type="text"
        maxlength="400"
        placeholder="---"
        formControlName="custom_text"
        [class.input_invalid]="submitted && form.controls['custom_text'].errors"></textarea>
      <label class="overview_record_lbl">({{400 - (form.controls['custom_text'].value ? form.controls['custom_text'].value.length : 0)}} Zeichen übrig)</label>
    </div>
  </div>

  <div id="column-two" class="column">
    <div id="bill-column-2-flex">
      <div class="overview_data_box">
        <h3>Rechnungsdetails</h3>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Betreff der Rechnung *</label>
          <input type="text" placeholder="---" [readonly]="isCreateCredit" formControlName="subject" [class.input_invalid]="submitted && form.get('subject').invalid">
        </div>
        <app-dropdown-standard
          [name]="'Zahlungsart*'"
          [formControl]="form.get('payment_method')"
          [selectedValue]="selectedPaymentMethod"
          [values]="paymentMethods"
          [submitted]="submitted"
          (submitValueEmitter)="setPaymentMethod($event)"
        ></app-dropdown-standard>
      </div>
      <div class="overview_data_box">
        <h3>Rechnungsübersicht</h3>
        <div class="overview_record">
          <label>Gesamtbetrag (Netto)</label>
          <label [appFormatValue]="'currency'" [value]="totalNetto"></label>
        </div>
        <div class="overview_record" *ngIf="totalTax10">
          <label>Steuer 10%</label>
          <label [appFormatValue]="'currency'" [value]="totalTax10"></label>
        </div>
        <div class="overview_record">
          <label>Steuer 20%</label>
          <label [appFormatValue]="'currency'" [value]="totalTax20"></label>
        </div>
        <div class="overview_record">
          <label>Gesamtbetrag (Brutto)</label>
          <label [appFormatValue]="'currency'" [value]="totalBrutto"></label>
        </div>
      </div>
    </div>
    <div id="bill-positions" class="overview_data_box">
      <h3>Angebotspositionen</h3>
      <app-bill-create-position-element *ngFor="let positionForm of form.get('positions').value; let i = index"
        [form]="positionForm"
        [index]="i"
        [submitted]="submitted"
        (removeElementEmitter)="removePosition($event)"
      ></app-bill-create-position-element>
      <div id="bill-position-pseudo-grid">
        <div></div>
        <button id="btn_add" type="button" class="btn_lbl_action" (click)="addPosition(this.formBuilder)"><span>&#43;</span> Weitere Position hinzufügen</button>
      </div>
    </div>
    <!-- <div id="bill-discounts" class="overview_data_box">
      <h3>Rabatte</h3>
    </div> -->
  </div>

  <div class="column">

  </div>
</form>
