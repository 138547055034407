<div class="scroll_container">
  <div class="data_container">
    <div class="body_wrapper">
      <div class="lbl_bold_light_color">Detailsansicht Angebot</div>
      <div class="body">
        <div class="column">
          <div class="flex_info">
            <div class="flex_info_inner">
              <div class="">
                <div class="list_title_lbl">Betreff</div>
                <div class="lbl_regular_bold">{{document.title}}</div>
                <div class="lbl_regular_italic_light_color">Angebotsnummer: {{document.offer_id}}</div>
              </div>
              <div class="">
                <div class="list_title_lbl">Kunde</div>
                <div class="lbl_regular_bold">{{document.customer.typ == 1 ? document.customer.companyName : (document.contact_person.lastName + ' ' + document.contact_person.lastName + (document.contact_person.title == 'Kein Titel' ? '' : ', ' + document.contact_person.title))}}</div>
                <div class="lbl_regular_italic_light_color">Kunden-ID: {{document.customer.id}}</div>
              </div>
              <div class="">
                <div class="list_title_lbl">Gültigkeit</div>
                <div class="lbl_regular_bold">{{document.offer_validity | date: 'dd.MMM yyyy'}}</div>
              </div>
            </div>
            <div class=" data_box_grandtotal">
              <div class="list_title_lbl">Gesamtsumme</div>
              <div class="lbl_regular_bold">{{grandTotal | currency: 'EUR'}}</div>
            </div>
          </div>
          <div class="list_title_lbl">Angebotsgegenstände</div>
          <div class="header_item">
            <div class="lbl_regular_italic_light_color">POS</div>
            <div class="lbl_regular_italic_light_color">Beschreibung</div>
            <div class="lbl_regular_italic_light_color">Menge</div>
            <div class="lbl_regular_italic_light_color">Preis</div>
            <div class="lbl_regular_italic_light_color">Rabatt</div>
          </div>
         <div id="item_list_body">
          <div *ngFor="let item of document.offer_items" class="item">
            <div class="lbl_list_data">{{item.item_id}}</div>
            <div class="lbl_list_data" style="font-weight: 600;">{{item.offer_descriptions[0].title}}</div>
            <div class="lbl_list_data">{{item.base_value}}</div>
            <div class="lbl_list_data">{{item.price | currency: 'EUR': '€': '1.2-2'}}</div>
            <div class="lbl_list_data">{{item.total_discount ? (item.total_discount | currency: 'EUR': '€': '1.2-2') : '---'}}</div>
          </div>
         </div>
          <!-- <div class="item_footer">
            <div></div>
            <div class="lbl_list_data" style="font-weight: 600;">Gesamtsumme</div>
            <div></div>
            <div class="lbl_list_data">{{grandTotal | currency: 'EUR'}}</div>
            <div></div>
          </div> -->
        </div>
        <div class="column">
          <div id="history_container" class="column">
            <div class="list_title_lbl">Historie</div>
            <div class="list_transactions_element" *ngFor="let trans of document.transactions">
              <div class="history_icon" [ngStyle]="{'background-color': getTransactionColor(trans.dataTyp)}"><img [src]="'../../../../assets/'+getTransactionIcon(trans.dataTyp)"></div>
                <div class="info_inner_container">
                  <div class="info_inner_container">
                    <div class="list_transactions_element_inner">
                      <label class="lbl_regular_thick">{{trans.text}}</label>
                      <label class="lbl_regular">{{trans.timestamp |  date:'dd MMM yyyy, HH:mm'}}</label>
                    </div>
                    <div class="lbl_regular_italic_light_color">von {{trans.username}}</div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button class="btn_submit" (click)="sendDocumentEmitter.emit()" *ngIf="document.status == 0">Absenden</button>
    </div>
  </div>
</div>
