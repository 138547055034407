<div>
  <app-topbar-interface></app-topbar-interface>
  <div class="list_container">
    <div class="lbl_regular_italic_light_color info_text">Sie haben 4 Schnittstellen.</div>
    <div class="body">
      <div id="interface_list">
        <app-element-interface *ngFor="let interface of interfaces"
          [interface]="interface"
        ></app-element-interface>
      </div>
      <app-chat-interface></app-chat-interface>
    </div>
  </div>
</div>
