import { VehicleBodyTyp } from "../VehicleBodyTyp";

export class VehicleBodyTypFactory {
    static jsonFactory(rawData: any): VehicleBodyTyp[] {
        let dataAry: VehicleBodyTyp[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehicleBodyTyp {
        let object = new VehicleBodyTyp(
            rawData.body_typ_id,
            rawData.body_typ_name,
        );
        return object;
    }
}