import { Address } from "../Adress";

export class AddressFactory {
    static jsonFactory(rawBody: any): Address[] {
        const transactions: Address[] = [];
        for(let address of rawBody){
            transactions.push(this.jsonFactoryOne(address));
        }
        return transactions;
    }

    static jsonFactoryOne(rawAddress: any): Address {
        return new Address(
            rawAddress.address_id,
            rawAddress.street,
            rawAddress.postal_code,
            rawAddress.city,
            rawAddress.country
        );
    }
}
