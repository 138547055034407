<div>
    <app-topbar-admin
        [surface]="surface"
        [settingsChanged]="settingsChanged"
        [isEdit]="selectedEmployee ? true : false"
        (changeSurfaceEmitter)="changeSurface($event)"
        (openCreateEmitter)="openCreate()"
        (cancleCreateEmitter)="cancelCreate()"
        (createEmployeeEmitter)="createEmployee()"
        (editEmployeeEmitter)="openEdit()"
        (searchEmitter)="searchAction($event)"
        (settingsVerwerfenEmitter)="settingsVerwerfenAction()"
        (settingsSaveEmitter)="settingsSave()"
    ></app-topbar-admin>
    <app-list-admin-container *ngIf="surface == 1 || surface == 6 && isLoaded"
        [employees]="employeesFiltered"
        [sliceTo]="sliceTo"
        [sliceFrom]="sliceFrom"
        [pageInfo]="pageInfo"
        [surface]="surface"
        (pageEmitter)="pageHandling($event, 1)"
        (openDetailsEmitter)="openDetails($event)"
        (openEditEmitter)="openEdit($event)"
        (deleteEmitter)="deleteAction($event)"
    ></app-list-admin-container>
    <app-list-locations-container *ngIf="surface == 4"
      [locations]="locationsFiltered"
      [sliceTo]="sliceToLoc"
      [sliceFrom]="sliceFromLoc"
      [pageInfo]="pageInfoLoc"
      (pageEmitter)="pageHandling($event, 2)"
    ></app-list-locations-container>
    <app-overview-user-container *ngIf="surface == 3"
      [employee]="selectedEmployee"
    ></app-overview-user-container>
    <app-create-employee *ngIf="surface == 5"
      [isEdit]="selectedEmployee ? true : false"
      [form]="form"
      [submitted]="submitted"
      [locations]="locations"
      [roles]="roles"
    ></app-create-employee>
    <app-admin-settings *ngIf="surface == 7"
      [settings]="settings"
      [form]="formSettings"
      [submitted]="submitted"
    ></app-admin-settings>
</div>
