<div class="image-web-element"
    [class.image-web-element-1]="design == 1"
    [class.image-web-element-2]="design == 2">
  <img *ngIf="!showImageLoading  && picture.src != null"
    class="thumbnail"
    [src]="picture.src"
    (click)="openImageEmitter.emit(picture)">
  <div *ngIf="showImageLoading"
    class="thumbnail-loader-wrapper">
    <!-- <img class="thumbnail-laoder" src="./assets/logomenu.svg"> -->
  </div>
  <div *ngIf="!showImageLoading  && picture.src == null"
    class="thumbnail-wrapper">
    <img class="thumbnail-laoder" src="./assets/logomenu.svg">
  </div>
  <div *ngIf="showImageLoading" class="image-web-loader-wrapper">
    <div class="image-web-loader"></div>
  </div>
</div>
