import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-create-contract',
  templateUrl: './dialog-create-contract.component.html',
  styleUrls: ['./dialog-create-contract.component.css']
})
export class DialogCreateContractComponent implements OnInit {
  public show = false;
  public isEdit = false;
  public submitValue: any;
  public subscription: Subscription;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openDialogCreateContract$.subscribe((data: any) => {
      this.show = true;
      this.isEdit = data.is_edit;
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit(sign: boolean){
    this.show = false;
    this.dialogService.closeCreateContract(
      {
        submit_value: this.submitValue,
        sign: sign,
      }
    );
  }
}
