export class DynamicListHelper {

  static getScrollPosition(): number {
    let scrollbar = document.getElementById('ab-item-list');
    if(scrollbar){
      return scrollbar.scrollTop;
    }
    return 0;
  }

  static scrollToPosition(position: number) {
    setTimeout(() => {
      let scrollbar = document.getElementById('ab-item-list');
      if(scrollbar && position){
        scrollbar.scrollTop = position;
      }
    }, 10);
  }
}
