<form class="element" [formGroup]="form">
    <div>
      <div class="lbl_regular_small">POS</div>
      <input type="number"
        class="inputfield"
        [ngClass]="!f.item_id?.value || (submitted && f.item_id.invalid) ? 'inputfield_invalid' : ''"
        [value]="f.item_id?.value"
        disabled="true">
    </div>
    <div class="flex">
      <img class="element_icon_car" [src]="form.controls.vehicle_selected.value == false ? '../../../../assets/carmenu-light.svg' : '../../../../assets/carmenu-light-color.svg'" (click)="showCar()">
      <div *ngIf="form.controls.vehicle_selected.value" #dropdownOne class="dropdown_input_wrapper">
        <div class="lbl_regular_small">Fahrzeug</div>
        <div class="dropdown_input_header" [ngClass]="submitted && form.controls.vehicle_selected.value && !form.controls.vehicle.value ? 'input_invalid' : ''" (click)="showDropdownOne = !showDropdownOne">
            <input #input_vehicle class="dropdown_input" type="text" placeholder="Fahrzeug auswählen..." (input)="filterVehicles(input_vehicle.value)">
            <img class="dropdown_input_header_icon" [ngClass]="showDropdownOne ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
        </div>
        <div class="dropdown_input_body" *ngIf="showDropdownOne">
            <div *ngFor="let vehicle of vehiclesFiltered" class="dropdown_input_body_element" (click)="setVehicle(vehicle); showDropdownOne = false">
              <!-- <div class="img_container_dropdown">
                <img *ngIf="vehicle.thumbnail" class="img_dropdown" [src]="vehicle.thumbnail ? vehicle.thumbnail : ''">
                <div *ngIf="!vehicle.thumbnail" class="img_empty_dropdown">Kein Bild</div>
              </div> -->
              <div>
                {{authService.getLocationID() == 13 && vehicle.externalId != null ? "("+vehicle.externalId+")" : ""}}
                {{vehicle.brand.makeName}}
                {{vehicle.model.name}}
                {{vehicle.model.version}}
              </div>
            </div>
        </div>
      </div>
      <form *ngIf="!form.controls.vehicle_selected.value" style="width: 100%;" [formGroup]="f.offer_descriptions.value[0]">
        <div class="lbl_regular_small">Beschreibung</div>
          <textarea appTextfieldAutoresize style="height: 23px;"
          class="textfield"
          [ngClass]="(submitted && descriptionFrom.title.invalid) ? 'textfield_invalid' : ''"
          placeholder="Beschreibung *"
          formControlName="title"
          ></textarea>
      </form>
    </div>
    <div>
      <div class="lbl_regular_small">Preis in €</div>
      <input type="number"
      onkeypress="CheckNumeric(event)"
      min="1"
      step="1"
      class="inputfield"
      [ngClass]="(submitted && f.price.invalid) ? 'inputfield_invalid' : ''"
      placeholder="Preis in € *"
      formControlName="price">
    </div>
    <div>
        <div class="lbl_regular_small">Menge in Stk.</div>
        <input type="number"
        onkeypress="CheckNumeric(event)"
        min="1"
        step="1"
        class="inputfield"
        [ngClass]="(submitted && f.base_value.invalid) ? 'inputfield_invalid' : ''"
        placeholder="Stk. *"
        formControlName="base_value">
    </div>
    <div>
      <div class="lbl_regular_small">Rabatt in €</div>
      <input type="number"
        onkeypress="CheckNumeric(event)"
        step="1"
        class="inputfield"
        [ngClass]="(submitted && f.total_discount.invalid) ? 'inputfield_invalid' : ''"
        placeholder="€"
        formControlName="total_discount">
    </div>
    <div *ngIf="amount > 1" class="btn_action" (click)="removeEmitter.emit(index)">&#8722;</div>
  </form>
