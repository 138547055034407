import { VehicleEquipment } from "../VehicleEquipment";

export class VehicleEquipmentFactory {
    static jsonFactory(rawData: any): VehicleEquipment[] {
        let dataAry: VehicleEquipment[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehicleEquipment {
        return new VehicleEquipment(
            rawData.eq_id,
            rawData.category_id,
            rawData.eq_name,
            rawData.category_name,
            rawData.plattform,
        );
    }
}
