<div>
  <div class="header-base-style">
    <div class="header-inner-wrapper">
      <div id="main-menu-wrapper">
        <button *ngFor="let mainMenu of mainMenus"
          [class.main-menu-btn-selected]="mainMenu == selectedMainMenu"
          (click)="selectionMainMenu(mainMenu)">
          {{mainMenu.menu}}
        </button>
      </div>
    </div>
    <div class="button-wrapper">
      <button class="btn_white" [disabled]="!formValueChanged" (click)="settingsVerwerfenAction()">Verwerfen</button>
      <button class="btn_submit" [disabled]="!formValueChanged" (click)="settingsSave()">Änderungen speichern</button>
    </div>
  </div>
  <div class="page-container">
    <div class="page-header">
      <div class="info_btn_wrapper">
        <div *ngFor="let subMenu of selectedMainMenu.sub_menus">
          <button *ngIf="!subMenu.is_hidden"
            class="sub-menu-btn"
            [class.sub-menu-btn-active]="subMenu == selectedSubMenu"
            (click)="selectionSubMenu(subMenu)">
            {{subMenu.sub_menu}}
          </button>
        </div>
      </div>
    </div>
    <div class="page-body">
      <form [formGroup]="form" *ngIf="form?.controls != null">
        <div class="overview_data_box_lbl_title">{{settingTitle}}</div>
        <div class="page-body-package">
          <div *ngFor="let setting of selectedSettings">
            <div class="overview_data_form">
              <div class="input_wrapper" *ngIf="!isCondition(setting.name)">
                <label class="overview_record_lbl">{{setting.label}}</label>
                <input
                  type="number"
                  class="overview_input"
                  placeholder="---"
                  min="0"
                  step="1"
                  formControlName="{{setting.name}}"
                  [class.input_invalid]="submitted && form.controls[setting.name].errors">
              </div>
              <app-dropdown-multiple *ngIf="isDropdownMultiple(setting)"
                [title]="setting.label"
                [formcontrol]="form.controls[setting.name]"
                [headerText]="setting.calculated_value"
                [values]="conditionValues"
                [submitted]="submitted"
                (submitValueEmitter)="setDropdownMultipleValue(setting, $event)"
              ></app-dropdown-multiple>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
