import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from 'src/app/buisness-object/user/User';

@Component({
  selector: 'app-textinput-add-function',
  templateUrl: './textinput-add-function.component.html',
  styleUrls: ['./textinput-add-function.component.css']
})
export class TextinputAddFunctionComponent implements OnInit, AfterViewInit {
  @Input() users: any[] = [];
  @Input() previousText: string;
  @Input() inputHeight: string;
  @Output() inputEmitter = new EventEmitter<string>();

  @ViewChild('textinput', {static: false}) textInput: ElementRef;
  public filterdUsers: any[] = [];
  public showDropDownUser = false;
  public posTopUserBox = 0;
  public posLeftUserBox = 0;
  public lastInputPos = 0;
  public userArrowIndex = 0;
  public amountOfAddedUser = 0;
  public placeholderTextInput = 'Schreiben Sie Ihr Anliegen...';

  @HostListener('document:keydown', ['$event'])onEnterHandler(event: KeyboardEvent) {
    if(this.showDropDownUser){
      if(event.key == 'ArrowUp' && this.userArrowIndex != 0){
        this.userArrowIndex--;
        this.scrollIntoView(this.userArrowIndex);
      } else if(event.key == 'ArrowDown' && this.userArrowIndex < (this.filterdUsers.length - 1)){
        this.userArrowIndex++;
        this.scrollIntoView(this.userArrowIndex);
      } else if(event.key == 'Enter'){
        this.userSelected(this.filterdUsers[this.userArrowIndex]);
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    window.addEventListener('keydown',(e: KeyboardEvent) => {
      if(e.key=='Enter'){
        if(this.showDropDownUser && e.target == document.getElementById('textinput')){
          e.preventDefault();
          return false;
        }
      }
    },true);
    const input = document.getElementById('textinput');
    if(input && this.inputHeight) input.style.height = this.inputHeight;
  }

  scrollIntoView(index: number) {
    const elementList = document.querySelectorAll('#drop_user_'+index);
    const element = elementList[0] as HTMLElement;
    if(element){
      element.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    var text = '';
    if (event.clipboardData) {
      text = event.clipboardData.getData('text/plain');
    }
    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, text);
    } else {
      document.execCommand('paste', false, text);
    }
  }

  getTextWidth(target): number {
    let c = document.createElement('canvas');
    let ctx = c.getContext('2d');
    ctx.font = '400 16px proxima-nova, sans-serif';
    let text = this.getTextInLine(target);
    let width = ctx.measureText(text).width;
    return width;
  }

  public indexOfDifference(cs1: string, cs2: string): number {
    if (cs1 == cs2) {
        return -1;
    }
    if (cs1 == null || cs2 == null) {
        return -1;
    }
    let i = 0;
    for (i = 0; i < cs1.length && i < cs2.length; ++i) {
        if (cs1.charAt(i) != cs2.charAt(i)) {
            break;
        }
    }
    if (i < cs2.length || i < cs1.length) {
        return i;
    }
    return -1;
  }

  //https://javascript.plainenglish.io/how-to-find-the-caret-inside-a-contenteditable-element-955a5ad9bf81
  getCaretIndex(element) {
    let position = 0;
    const isSupported = typeof window.getSelection !== "undefined";
    if (isSupported) {
      const selection = window.getSelection();
      if (selection.rangeCount !== 0) {
        const range = window.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        position = preCaretRange.toString().length;
      }
    }
    return position;
  }

  lockBoldNames(value, event) {
    if(event.inputType == 'deleteContentBackward'){
      let startValue = this.previousText;
      let deletedValue = this.indexOfDifference(this.previousText, value.innerHTML);
      let started = false;
      let i =  0;
      while(this.previousText.length > 0) {
        if(this.previousText.indexOf('<strong') == 0) {
          started = true;
        } else if(this.previousText.indexOf('</strong>') == 0) {
          started = false;
        }
        if(i == deletedValue) {
          if(started) {
            for(let k = 0; k < startValue.length; k++){
              if(value.innerHTML[k] != startValue[k]){
                let cutFrom = this.getPreviousStart(value.innerHTML, k);
                let cutTo = this.getNextEnd(value.innerHTML, k);
                value.innerHTML = startValue.substring(0,cutFrom) + startValue.substring(cutTo,startValue.length);
                this.amountOfAddedUser--;
                this.setCursorPosition(cutFrom - 1, startValue, cutTo);
                break;
              }
            }
          } else {
            let caretIndex = this.getCaretIndex(document.getElementById('textinput'));
            let txtRipped = this.cleanString(startValue);
            if(caretIndex + 1 == txtRipped.length){
              let startWord = '';
              let startAdding = false;
              let j = 0;
              let cutEnd = 0;
              for(let cutStart = startValue.length - 1; cutStart >= 0; cutStart--){
                if(cutEnd == 0 && startValue.substring(cutStart-9,cutStart) == '</strong>'){
                  cutEnd = cutStart;
                }
                if(startValue[cutStart] == 'g'){
                  startAdding = true;
                }
                if(startAdding){
                  startWord = startValue[cutStart] + startWord;
                  j++;
                  if(j > 6){
                    if(startWord == '<strong'){
                      if(startValue.substring(cutEnd, startValue.length) == '&nbsp;' || startValue.substring(cutEnd, startValue.length) == '&nbsp;</div>'){
                        value.innerHTML = startValue.substring(0,cutStart);
                        this.amountOfAddedUser--;
                        this.setEndOfContenteditable(value);
                      }
                      break;
                    } else {
                      startAdding = false;
                      startWord = '';
                      j = 0;
                    }
                  }
                }
              }
            }
          }
          break;
        }
        this.previousText = this.previousText.substring(1);
        this.inputEmitter.emit(this.previousText);
        i++;
      }
    }
  }

  getPreviousStart(str: string, pos: number): number {
    let startSignal = '';
    let startWriting = false;
    let counter = 0;
    for(let i = pos; i >= 0; i--){
      if(str[i] == 'g'){
        startWriting = true;
      }
      if(startWriting){
        startSignal = str[i] + startSignal;
        counter++;
      }
      if(startSignal == '<strong'){
        return i;
      } else if(counter == 7){
        startSignal = '';
        startWriting = false;
        counter = 0;
      }
    }
    return 0;
  }

  cleanString(str: string): string {
    let gotStart = false;
    let startPos = 0;
    let endPos = 0;
    for(let i = 0; i < str.length; i++){
      if(str[i] == '<'){
        startPos = i;
        gotStart = true;
      }
      if(gotStart && str[i] == '>'){
        endPos = i;
        str = str.slice(0,startPos) + str.slice(endPos + 1,str.length);
        i -= (endPos - startPos);
      }
    }
    return str.split('&nbsp;').join(' ');
  }

  getNextEnd(str: string, pos: number): number {
    let endSignal = '';
    let startWriting = false;
    let counter = 0;
    for(let i = pos; i < str.length; i++){
      if(str[i] == '<'){
        startWriting = true;
      }
      if(startWriting){
        endSignal = endSignal + str[i];
        counter++;
      }
      if(endSignal == '</strong>'){
        return i + 8;
      } else if(counter == 9){
        endSignal = '';
        startWriting = false;
        counter = 0;
      }
    }
    return 0;
  }

  setEndOfContenteditable(contentEditableElement)
  {
      var range,selection;
      if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
      {
          range = document.createRange();//Create a range (a range is a like the selection but invisible)
          range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
          range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
          selection = window.getSelection();//get the selection object (allows you to change selection)
          selection.removeAllRanges();//remove any selections already made
          selection.addRange(range);//make the range you have just created the visible selection
      }
  }

  valueChanges(value, event) {
    this.lockBoldNames(value, event);
    this.lastInputPos = this.getCaretCharacterOffsetWithin(value);
    if(value.innerHTML.indexOf('<b>') != -1) {
      value.innerHTML = value.innerHTML.replace('<b>', '');
      value.innerHTML = value.innerHTML.replace('</b>', '');
      this.setEndOfContenteditable(value);
    }
    let lineText = this.getTextInLine(value);
    if(lineText.length > 0) {
      let insertedChar = lineText.substring(lineText.length - 1);
      if(insertedChar == '@') {
        let line = this.getCurrentLine(value);
        let scrollTop = document.querySelector('#textinput').scrollTop;
        this.posTopUserBox = (line * 23) + 30 - scrollTop;
        this.posLeftUserBox = this.getTextWidth(value) + 10;
        this.showDropDownUser = true;
        this.filterdUsers = this.users;
      } else if(this.showDropDownUser) {
        let textAfter = lineText.substring(lineText.lastIndexOf('@'), lineText.length);
        let searchTerm = textAfter.substring(1, textAfter.length);
        this.filterdUsers = this.users.filter((user) => {
          return (user.firstName + " " + user.lastName).toLowerCase().includes(searchTerm.toLowerCase());
        });
        this.showDropDownUser = this.filterdUsers.length > 0;
        this.userArrowIndex = 0;
      }
    }else {
      this.showDropDownUser = false;
    }
    this.previousText = value.innerHTML;
    this.inputEmitter.emit(this.previousText);
  }

  userSelected(user) {
    this.showDropDownUser = false;
    this.userArrowIndex = 0;
    this.filterdUsers = [];
    this.amountOfAddedUser++;
    this.setNewTextValue(user);
  }

  setNewTextValue(user: any) {
    let getDivBefore = this.getDivBefore();
    let getDivAfter = this.getDivAfter();
    this.textInput.nativeElement.innerHTML = getDivBefore + "<strong id='"+user.id+"'>@" + user.firstName + ' ' + user.lastName + "</strong>&nbsp;" + getDivAfter;
    this.previousText = this.textInput.nativeElement.innerHTML;
    this.inputEmitter.emit(this.previousText);
    this.setEndOfContenteditable(this.textInput.nativeElement);
  }

  getDivBefore(): string {
    let textHtml: string = this.textInput.nativeElement.innerHTML;
    let getDivBefore = "";
    let textToGo = this.lastInputPos;
    let elementsToEscape = ['<div>', '</div>', '<br>', '</strong>', '<span>', '</span>', '<p>', '</p>'];
    let elementsToMerge = ['&nbsp;','&amp;']
    while(textToGo > 0) {
      let nextChar = textHtml.substring(0, 1);
      if(nextChar == '<') {
        let included = false;
        for(let element of elementsToEscape) {
          if(textHtml.indexOf(element) == 0) {
            getDivBefore = getDivBefore + element;
            textHtml = textHtml.substring(element.length, textHtml.length);
            included = true;
            break;
          }
        }
        if(textHtml.indexOf('<strong') == 0) {
          getDivBefore = getDivBefore + textHtml.substring(0, textHtml.indexOf('>') + 1);
          textHtml = textHtml.substring(textHtml.indexOf('>') + 1, textHtml.length);
          included = true;
        }
        if(!included) {
          getDivBefore = getDivBefore + nextChar;
          textHtml = textHtml.substring(1, textHtml.length);
          textToGo = textToGo - 1;
        }
      } else if(nextChar == '&') {
        let included = false;
        for(let element of elementsToMerge) {
          if(textHtml.indexOf(element) == 0) {
            getDivBefore = getDivBefore + element;
            textHtml = textHtml.substring(element.length, textHtml.length);
            textToGo = textToGo - 1;
            included = true;
            break;
          }
        }
        if(!included) {
          getDivBefore = getDivBefore + nextChar;
          textHtml = textHtml.substring(1, textHtml.length);
          textToGo = textToGo - 1;
        }
      } else {
        getDivBefore = getDivBefore + nextChar;
        textHtml = textHtml.substring(1, textHtml.length);
        textToGo = textToGo - 1;
      }
    }
    return getDivBefore.substring(0,getDivBefore.lastIndexOf("@"));
  }

  getDivAfter(): string {
    let textHtml: string = this.textInput.nativeElement.innerHTML;
    let textToGo = this.lastInputPos;
    let elementsToEscape = ['<div>', '</div>', '<br>', '</strong>', '<span>', '</span>', '<p>', '</p>'];
    let elementsToMerge = ['&nbsp;','&amp;']
    while(textToGo > 0) {
      let nextChar = textHtml.substring(0, 1);
      if(nextChar == '<') {
        let included = false;
        for(let element of elementsToEscape) {
          if(textHtml.indexOf(element) == 0) {
            textHtml = textHtml.substring(element.length, textHtml.length);
            included = true;
            break;
          }
        }
        if(textHtml.indexOf('<strong') == 0) {
          textHtml = textHtml.substring(textHtml.indexOf('>') + 1, textHtml.length);
          included = true;
        }
        if(!included) {
          textHtml = textHtml.substring(1, textHtml.length);
          textToGo = textToGo - 1;
        }
      } else if(nextChar == '&') {
        let included = false;
        for(let element of elementsToMerge) {
          if(textHtml.indexOf(element) == 0) {
            textHtml = textHtml.substring(element.length, textHtml.length);
            textToGo = textToGo - 1;
            included = true;
            break;
          }
        }
        if(!included) {
          textHtml = textHtml.substring(1, textHtml.length);
          textToGo = textToGo - 1;
        }
      } else {
        textHtml = textHtml.substring(1, textHtml.length);
        textToGo = textToGo - 1;
      }
    }
    return textHtml;
  }

  getCaretCharacterOffsetWithin(element) {
    var caretOffset = 0;
    var doc = element.ownerDocument || element.document;
    var win = doc.defaultView || doc.parentWindow;
    var sel;
    if (typeof win.getSelection != "undefined") {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
            var range = win.getSelection().getRangeAt(0);
            var preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            caretOffset = preCaretRange.toString().length;
        }
    } else if ( (sel = doc.selection) && sel.type != "Control") {
        var textRange = sel.createRange();
        var preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;
    }
    return caretOffset;
  }

  getAmountOfLines(target): number {
    let textContent: string = target.innerText;
    let lines = 1;
    let lastWasNewLine = false;
    let amountOfNs = 0;
    while(textContent.length > 0) {
      textContent = textContent.substring(1, textContent.length);
      if(textContent.charAt(0) == '\n' && !lastWasNewLine) {
        amountOfNs++;
        lastWasNewLine = true;
      } else if(textContent.charAt(0) == '\n' && lastWasNewLine) {
        amountOfNs++;
      } else {
        if(amountOfNs % 2 == 0) {
          lines += amountOfNs/2;
          amountOfNs = 0;
        } else {
          lines += (amountOfNs + 1)/2;
          amountOfNs = 0;
        }
        lastWasNewLine = false;
      }
    }
    return lines;
  }

  getTextInLine(target): string {
    let currentPos = this.getCaretCharacterOffsetWithin(target);
    let currentLine = this.getCurrentLine(target);
    let textContent: string = target.innerText;
    let lines = 1;
    let lastWasNewLine = false;
    let amountOfNs = 0;
    let lineText = "";
    while(textContent.length > 0) {
      if(currentLine == lines && currentPos > 0) {
        lineText += textContent.substring(0, 1);
      }
      let currentChar = textContent.substring(0, 1);
      textContent = textContent.substring(1, textContent.length);
      if(currentChar == '\n' && !lastWasNewLine) {
        amountOfNs++;
        lastWasNewLine = true;
      } else if(currentChar == '\n' && lastWasNewLine) {
        amountOfNs++;
      } else {
        currentPos--;
        if(amountOfNs % 2 == 0) {
          lines += amountOfNs/2;
          amountOfNs = 0;
        } else {
          lines += (amountOfNs + 1)/2;
          amountOfNs = 0;
        }
        lastWasNewLine = false;
      }
    }
    return lineText;
  }

  getPositionInLine(target): number {
    let currentPos = this.getCaretCharacterOffsetWithin(target);
    let currentLine = this.getCurrentLine(target);
    let textContent: string = target.innerText;
    let lines = 1;
    let lastWasNewLine = false;
    let amountOfNs = 0;
    let linePos = 0;
    while(textContent.length > 0) {
      if(currentLine == lines && currentPos > 0) {
        linePos++;
      }
      let currentChar = textContent.substring(0, 1);
      textContent = textContent.substring(1, textContent.length);
      if(currentChar == '\n' && !lastWasNewLine) {
        amountOfNs++;
        lastWasNewLine = true;
      } else if(currentChar == '\n' && lastWasNewLine) {
        amountOfNs++;
      } else {
        currentPos--;
        if(amountOfNs % 2 == 0) {
          lines += amountOfNs/2;
          amountOfNs = 0;
        } else {
          lines += (amountOfNs + 1)/2;
          amountOfNs = 0;
        }
        lastWasNewLine = false;
      }
    }
    return linePos + (currentLine > 1 ? 1 :0);
  }

  getCurrentLine(target): number {
    let currentPos = this.getCaretCharacterOffsetWithin(target);
    let textContent: string = target.innerText;
    let lines = 1;
    let lastWasNewLine = false;
    let amountOfNs = 0;
    while(textContent.length > 0) {
      if(currentPos == 0) {
        break;
      }
      let currentChar = textContent.substring(0, 1);
      textContent = textContent.substring(1, textContent.length);
      if(currentChar == '\n' && !lastWasNewLine) {
        amountOfNs++;
        lastWasNewLine = true;
      } else if(currentChar == '\n' && lastWasNewLine) {
        amountOfNs++;
      } else {
        currentPos--;
        if(amountOfNs % 2 == 0) {
          lines += amountOfNs/2;
          amountOfNs = 0;
        } else {
          lines += (amountOfNs + 1)/2;
          amountOfNs = 0;
        }
        lastWasNewLine = false;
      }
    }
    return lines;
  }

  setCursorPosition(selectionStart: number, txt: string, cutTo: number) {
    let selection = window.getSelection();
    let focus = selection.focusNode;
    let parentNode = focus.parentNode;
    let childNodes = parentNode.childNodes;
    let users = this.amountOfAddedUser * 31;
    let offset = selectionStart - users;
    let l = 0;
    for(let i = 0; i < childNodes.length; i++){
      l += childNodes[i].textContent.length;
      if(l > offset){
        let range = document.createRange();
        let restLenght = this.getRestLength(txt, cutTo);
        range.setStart(childNodes[i],childNodes[i].textContent.length - restLenght);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        return;
      }
    }
  }

  getRestLength(txt: string, start: number): number {
    let nextNodeTxtLength = 0;
    let startWriting = false;
    let targetTxt = '';
    for(let j = start; j < txt.length; j++){
      nextNodeTxtLength++;
      if(txt[j] == '<'){
        startWriting = true;
      }
      if(startWriting){
        targetTxt += txt[j];
      }
      if(targetTxt == '<strong>'){
        return nextNodeTxtLength;
      } else if(targetTxt.length >= 8){
        targetTxt = '';
        startWriting = false;
      }
    }
    return nextNodeTxtLength;
  }
}
