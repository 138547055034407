import { NumberFormatPipe } from "src/app/helpers/NumberFormatPipe.pipe";
import { SubMenuColumnTag } from "./SubMenuColumnTag";
import { formatDate } from "@angular/common";

export class SubMenuColumn {
  public sortingActive: boolean = false;
  public ascend: boolean = false;

  constructor(
    public sub_menu_column_id: number,
    public title: string,
    public sort_value: number,
    public data_object: string,
    public calculated: number,
    public data_key: string,
    public is_sortable: boolean,
    public is_fixed: boolean,
    public width: number,
    public sum_typ: string,
    public prefix: string,
    public suffix: string,
    public format: string,
    public data_typ: string,
    public column_tag: SubMenuColumnTag,
    public sort_function?: string
  ){}

  setSortActive() {
    if(!this.sortingActive && !this.ascend){
      this.sortingActive = true;
      this.ascend = false;
    } else if(this.sortingActive && !this.ascend){
      this.ascend = true;
    } else {
      this.sortingActive = false;
      this.ascend = false;
    }
  }

  getSortActive() {
    return this.sortingActive;
  }

  getSortIndicator(): string {
    if(this.sortingActive && !this.ascend){
      return './assets/sortasc.svg';
    } else if(this.sortingActive && this.ascend){
      return './assets/sortdesc.svg';
    }
    return './assets/sortinactive.svg';
  }

  getFixedClass(): string {
    if(this.is_fixed){
      return 'column-data-is-fixed';
    }
    return '';
  }

  getSum(column: SubMenuColumn, objects: any[]): any {
    let sum = 0;
    if(column.sum_typ == "SUM"){
      for(let i = 0; i < objects.length; i++){
        let data;
        if(column.data_key.includes('.')){
          let keys = column.data_key.split('.');
          let value = objects[i];
          for(let j = 0; j < keys.length; j++){
            value = value[keys[j]];
            if(value == undefined || value == null){
              break;
            }
          }
          data = value;
        } else {
          data = objects[i][column.data_key];
        }
        if(data != null && !isNaN(data)){
          sum = sum + Number(data);
        }
      }
    } else if(column.sum_typ == "COUNT"){
      for(let i = 0; i < objects.length; i++){
        let data;
        if(column.data_key.includes('.')){
          let keys = column.data_key.split('.');
          let value = objects[i];
          for(let j = 0; j < keys.length; j++){
            value = value[keys[j]];
            if(value == undefined || value == null){
              break;
            }
          }
          data = value;
        } else {
          data = objects[i][column.data_key];
        }
        if(data != null && data != ''){
          sum++;
        }
      }
    } else if(column.sum_typ == "LAST"){
      if(objects.length > 0){
        let lastObj = objects[objects.length-1];
        sum = lastObj[column.data_key];
      } else {
        sum = 0;
      }
    }
    return this.getFormat(column, sum?.toString());
  }

  public getFormat(column: SubMenuColumn, value: string): string {
    if(column.data_typ === 'STRING'){
      return value;
    }
    if(column.data_typ === 'DATE'){
      if(value == null || value == '') return '---';
      return formatDate(value, column.format, 'de');
    }
    if(column.data_typ === 'NUMBER'){
      if(value == null || value == '') return '---';
      let num;
      switch(column.format){
        case '0.00': num = new NumberFormatPipe().transform(parseFloat(value).toFixed(2)); break;
        case '0.000': num = new NumberFormatPipe().transform(parseFloat(value).toFixed(3)); break;
        default: num = new NumberFormatPipe().transform(parseInt(value)); break;
      }
      return (column.prefix ? column.prefix : "") + num + (column.suffix ? column.suffix : "");
    }
    if(column.data_typ === 'BOOLEAN'){
      return value ? 'Ja' : 'Nein';
    }
    return (value != null && value != '') ? value : '---';
  }
}
