import { Drive } from 'src/app/buisness-object/drives/Drive';
import { UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Employee } from 'src/app/buisness-object/employee/Employee';

@Component({
  selector: 'app-create-drive',
  templateUrl: './create-drive.component.html',
  styleUrls: ['./create-drive.component.css']
})
export class CreateDriveComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() pageDrive: number;
  @Input() vehicles: Vehicle[];
  @Input() employees: Employee[];
  @Input() drives: Drive[];
  @Input() customers: Customer[];
  @Input() driveTyp: number; // Über Formgroup

  @Output() changePageEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

}
