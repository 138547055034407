<div class="body">
  <div class="list_title">
    <div>Position</div>
    <div>Preis/Stück & Rabatte</div>
  </div>
  <div id="price_list">
    <app-create-bill-document-element *ngFor="let item of form.controls.bill_items.value; let i = index"
    [form]="item"
    [index]="i"
    [submitted]="submittedBillPrice"
    (removeEmitter)="removePriceEmitter.emit($event)"
    ></app-create-bill-document-element>
  </div>
  <div class="btn_action" style="margin-top: 35px;" (click)="addPrice()">&#43;</div>
</div>
