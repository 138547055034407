import { VehicleBrand } from './../../../../buisness-object/vehicle/basic/VehicleBrand';
import { Component, Input, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-contract-page-five',
  templateUrl: './contract-page-five.component.html',
  styleUrls: ['./contract-page-five.component.css']
})
export class ContractPageFiveComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;

  public selectedBrand: VehicleBrand;
  public brands: VehicleBrand[] = [];
  public filteredBrands: VehicleBrand[] = [];
  public models: VehicleModel[] = [];
  public filteredModels: VehicleModel[] = [];
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public restPrice;
  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public brandValues = [];
  public modelValues = [];

  @ViewChild('input_brand') input_brand: ElementRef;
  @ViewChild('input_model') input_model: ElementRef;
  @ViewChild('dropdown_1') dropdown_1: ElementRef;
  @ViewChild('dropdown_2') dropdown_2: ElementRef;
  public listener: () => void;

  constructor(
    private vService: VehicleService,
  ) { }

  ngOnInit(): void {
    this.getDataserverside();
    this.restPrice = this.f.price.value ? this.f.price.value : 0;
  }

  ngAfterViewInit(): void {
    if(this.form.controls.old_brand.value){
      this.selectedBrand = this.form.controls.old_brand.value;
      //this.input_brand.nativeElement.value = this.form.controls.old_brand.value.makeName;
      let index = this.brands.findIndex(b => b.id == this.form.controls.old_brand.value.id);
      if(index > -1) {
        this.models = this.brands[index].models;
        if(this.f.old_brand.value != null){
          this.modelValues = [];
          for(let v of this.models){
            this.modelValues.push({
              id: v.id,
              name: v.name
            });
          }
        }
      }
    }
  }

  ngOnDestroy(): void {

  }

  getDataserverside() {
    this.vService.getBrands().subscribe((brands) => {
      if(brands){
        this.brands = brands;
        this.sort();
        this.filteredBrands = this.brands;
        this.sortValues();
        this.setDropdownValues();
      }
    })
  }

  get f() {
    return this.form.controls;
  }

  sort() {
    this.brands.sort((a,b) => {
      if(a.makeName > b.makeName) return 1;
      if(a.makeName < b.makeName) return -1;
      return 0;
    })
  }

  filterBrands(value: string) {
    if(value && value.length > 0){
      this.filteredBrands = [];
      this.filteredBrands = this.brands.filter((brand) => {
        return brand.makeName.toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.filteredBrands = this.brands;
    }
  }
  filterModels(value: string) {
    if(value && value.length > 0){
      this.filteredModels = [];
      this.filteredModels = this.models.filter((model) => {
        return model.name.toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.filteredModels = this.models;
    }
  }


  sortValues() {
    this.brands.sort((a,b) => {
      if(a.makeName.toLowerCase() < b.makeName.toLowerCase()) return -1;
      if(a.makeName.toLowerCase() > b.makeName.toLowerCase()) return 1;
      return 0;
    })
    let iBrand = this.brands.findIndex(m => m.makeName.toLowerCase() == "sonstige");
    if(iBrand){
      let temp = this.brands[iBrand];
      this.brands.splice(iBrand,1);
      this.brands.push(temp);
    }
    this.models.sort((a,b) => {
      if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    })
    let iModel = this.models.findIndex(m => m.name.toLowerCase() == "sonstige");
    if(iModel){
      let temp = this.models[iModel];
      this.models.splice(iModel,1);
      this.models.push(temp);
    }
  }

  setDropdownValues() {
    for(let v of this.brands){
      this.brandValues.push({
        id: v.id,
        name: v.makeName
      })
    }
    if(this.f.old_brand.value != null){
      this.modelValues = [];
      for(let v of this.f.old_brand.value.models){
        this.modelValues.push({
          id: v.id,
          name: v.name
        })
      }
    }
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'brand'){
      let index = this.brands.findIndex(v => v.id == id);
      if(index > -1) this.setBrand(this.brands[index]);
      else this.setBrand(null);
    } else if(value === 'model'){
      let index = this.f.old_brand.value.models.findIndex(v => v.id == id);
      if(index > -1) this.setModel(this.f.old_brand.value.models[index]);
    }
  }

  setBrand(brand: VehicleBrand) {
    this.selectedBrand = brand;
    this.form.controls.old_brand.setValue(brand);
    this.form.controls.old_model.setValue(null);
    if(this.f.old_brand.value != null){
      this.modelValues = [];
      for(let v of this.f.old_brand.value.models){
        this.modelValues.push({
          id: v.id,
          name: v.name
        })
      }
    }
  }

  setModel(model: VehicleModel) {
    this.form.controls.old_model.setValue(model);
  }

  calcRestPrice(value: number) {
    this.restPrice = this.f.price.value - value;
  }
}
