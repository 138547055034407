<div class="data_container">
    <div class="lbl_bold_light_color">Rechnung erstellen</div>
    <app-create-bill-page-one *ngIf="page == 1"
      [form]="form"
      [submitted]="submitted"
      [customers]="customers"
    ></app-create-bill-page-one>
    <app-create-bill-page-two *ngIf="page == 2"
      [form]="form"
      [submitted]="submitted"
    ></app-create-bill-page-two>
    <app-create-bill-navigation
      [currentPageCreate]="page"
      (changePageEmitter)="changePageEmitter.emit($event)"
    ></app-create-bill-navigation>
</div>
