import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor() { }

  private loading = false;
  loadingStatus = new Subject<boolean>();

  startLoading(){
    this.setLoading(true);
  }

  stopLoading(){
    this.setLoading(false);
  }

  isLoading(){
    return this.loading;
  }

  setLoading(value){
    this.loading = value;
    this.loadingStatus.next(value);
  }
}
