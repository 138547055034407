import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Setting } from "./Setting";
import { SettingSubMenu } from "src/app/view/settings/settings.component";

export class SettingFormHelper {

  static getForm(formBuilder: FormBuilder, category: string, settings?: Setting[]){
    let form = formBuilder.group({});
    for(let setting of settings){
      if(setting.category == category){
        let formControl = formBuilder.control(setting.value);
        formControl.addValidators(Validators.required);
        form.addControl(setting.name, formControl);
      }
    }
    return form;
  }

  static createObject(form: FormGroup, settings: Setting[]): Setting[] {
    let objects: Setting[] = [];
    for(let key of Object.keys(form.controls)){
      let index = settings.findIndex((setting) => setting.name == key);
      if(index != -1){
        let setting = settings[index];
        let obj = new Setting(
          setting.setting_default_value_id,
          setting.category,
          setting.category_name,
          setting.name,
          setting.label,
          form.controls[key].value,
          setting.typ
         );
         objects.push(obj);
      }
    }
    return objects;
  }


    //ändern Steuersatz
    // Object.keys(this.pricesheetForm.controls).forEach((key) => {
    //   if(this.isEditableKey(key)) {
    //     this.pricesheetForm.controls[key].valueChanges.subscribe((changes) => {
    //       PriceSheetForm.calculate(this.pricesheetForm);
    //     });
    //   }

}
