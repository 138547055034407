import { Component, Input, OnInit } from '@angular/core';
import { Picture } from 'src/app/buisness-object/picture/Picture';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';

@Component({
  selector: 'app-images-element',
  templateUrl: './images-element.component.html',
  styleUrl: './images-element.component.css'
})
export class ImagesElementComponent implements OnInit {
  @Input() picture: Picture;
  public showImageLoading = true;

  constructor(
    private vehicleService: VehicleService
  ){}

  ngOnInit(): void {
    if(this.picture) this.getPicture();
  }

  getPicture() {
    this.vehicleService.getVehiclePicture(this.picture.picture_id).subscribe((data) => {
      if(data){
        this.picture.src = data;
        this.showImageLoading = false;
      }
    });
  }
}
