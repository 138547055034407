<div id="overview-images-wrapper">

  <!-- HANOVER PICTURES -->
  <div class="pic_container" *ngIf="isHandover">
    <div *ngFor="let handover_pic of handoverPics" class="pic_item">
      <img class="img_car" [src]="handover_pic.data">
    </div>
  </div>

  <!-- RETURN PICTURES -->
  <div class="pic_container" *ngIf="!isHandover">
    <div *ngFor="let return_pic of returnPics" class="pic_item">
      <img class="img_car" [src]="return_pic.data">
    </div>
  </div>

</div>

