import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { EnumData, EnumValue } from 'src/app/buisness-object/menu/EnumData';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';


@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrl: './document-view.component.css'
})

export class DocumentViewComponent extends BaseComponent implements OnInit {
  @Input() obj: any;

  public file_categories: EnumValue[] = [];
  public file_categories_column_1: EnumValue[] = [];
  public file_categories_column_2: EnumValue[] = [];
  public file_categories_column_3: EnumValue[] = [];

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    if(this.obj instanceof Vehicle) {
      this.file_categories = this.enumService.getEnums("attachment_category_vehicle");
    }
    
    this.file_categories.sort((a, b) => {
      if (a.sort_value < b.sort_value) {
        return -1;
      }
      if (a.sort_value > b.sort_value) {
        return 1;
      }
      return 0;
    });
    for(let i = 0; i <= this.file_categories.length-1; i++){
      if (i % 3 === 0) {
        this.file_categories_column_1.push(this.file_categories[i]); // Every 1st element in the group of 3
      } else if (i % 3 === 1) {
        this.file_categories_column_2.push(this.file_categories[i]); // Every 2nd element in the group of 3
      } else if (i % 3 === 2) {
        this.file_categories_column_3.push(this.file_categories[i]); // Every 3rd element in the group of 3
      }
    }
  }

  // getTilePlacement(id: string, index: number) {
  //   const tile = document.getElementById(id) as HTMLElement;
  //   if(tile){

  //     if(){
  //       return "grid-column-start: 1; grid-column-end: 2;"
  //     } else if(){
  //        return "grid-column-start: 2; grid-column-end: 3;"
  //     } else {
  //        return "grid-column-start: 3; grid-column-end: 4;"
  //     }
  //   }
  // }
}
