<div id="dialog_window">
  <div id="dialog_container" appDropdown>
      <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
      <h1>Welches PDF soll gedruckt werden?</h1>
      <div id="dialog_conainter_body" class="body">
        <div id="vehicle-error-list">
          <div class="vehicle-error-list-element">
            <label>Preisblatt Web</label>
            <button class="btn_label_blue" (click)="selectionPrintWeb.emit(vehicleId)">PDF Drucken</button>
          </div>
          <div class="vehicle-error-list-element">
            <label>Preisblatt</label>
            <button class="btn_label_blue" (click)="selectionPrint.emit(vehicleId)">PDF Drucken</button>
          </div>
        </div>
      </div>
      <div class="dialog_btn_container">
          <button class="btn_submit_dark" (click)="selectionCloseEmitter.emit()">Abbrechen</button>
      </div>
  </div>
</div>
