<app-collapsible [isCollapsible]="!editMode && viewAuth.view.collapsible" [isContentVisible]="collapsed" [class.data_box_disabled]="editMode && inseratDisable">
  <h3 collapsible-header class="overview_data_box_lbl_title">Fahrgestell</h3>
  <div collapsible-content>
    <div *ngIf="!editMode" class="overview_data_box_vehicle">
      <div class="overview_record" *ngIf="showValue('bodyColor')">
          <div class="overview_record_lbl">Hauptfarbe</div>
          <div class="overview_record_value">{{vehicle.bodyColor.name}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('color_designation')">
        <div class="overview_record_lbl">Farbbenennung</div>
        <div class="overview_record_value">{{vehicle.color_designation ? vehicle.color_designation : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('color_code')">
        <div class="overview_record_lbl">Farbcode</div>
        <div class="overview_record_value">{{vehicle.color_code ? vehicle.color_code : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('upholstery')">
          <div class="overview_record_lbl">Interieur Material</div>
          <div class="overview_record_value">{{vehicle.upholstery ? vehicle.upholstery.name : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('upholsteryColor')">
          <div class="overview_record_lbl">Interieur Farbe</div>
          <div class="overview_record_value">{{vehicle.upholsteryColor ? vehicle.upholsteryColor.name : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('numOfSeats')">
          <div class="overview_record_lbl">Türen / Sitze</div>
          <div class="overview_record_value">{{vehicle.numOfDoors}} / {{vehicle.numOfSeats}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('luggage_space')">
        <div class="overview_record_lbl">Kofferraumvolumen (in l)</div>
        <div class="overview_record_value">{{vehicle.luggage_space ? (vehicle.luggage_space + ' l') : '---'}}</div>
      </div>
    </div>
    <form *ngIf="editMode && viewAuth.edit.name" [formGroup]="formGroupVehicle" class="overview_data_form">
      <app-dropdown-objects *ngIf="showValue('bodyColor')"
        [title]="'Hauptfarbe'"
        [placeholder]="'Hauptfarbe'"
        [headervalue]="f.bodyColor.value?.name"
        [formcontrol]="f.bodyColor"
        [values]="bodyColorValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('bodyColor', $event)"
      ></app-dropdown-objects>
      <div class="input_wrapper" *ngIf="showValue('color_designation')">
        <label class="overview_record_lbl">Farbbenennung</label>
        <input type="text" class="overview_input" placeholder="Farbbenennung" formControlName="color_designation" (ngModel)="f.color_designation.value">
      </div>
      <div class="input_wrapper"  *ngIf="showValue('color_code')">
        <label class="overview_record_lbl">Farbcode</label>
        <input type="text" class="overview_input" placeholder="Farbcode" formControlName="color_code" (ngModel)="f.color_code.value">
      </div>
      <app-dropdown-objects *ngIf="showValue('upholstery')"
        [title]="'Interieur Material'"
        [placeholder]="'Interieur Material'"
        [headervalue]="f.upholstery.value?.name"
        [formcontrol]="f.upholstery"
        [values]="upholsteryValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('upholstery', $event)"
      ></app-dropdown-objects>
      <app-dropdown-objects *ngIf="showValue('upholsteryColor')"
        [title]="'Interieur Farbe'"
        [placeholder]="'Interieur Farbe'"
        [headervalue]="f.upholsteryColor.value?.name"
        [formcontrol]="f.upholsteryColor"
        [values]="upholsteryColorValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('upholsteryColor', $event)"
      ></app-dropdown-objects>
      <div class="input_wrapper" *ngIf="showValue('numOfDoors')">
        <label class="overview_record_lbl">Türen</label>
        <input type="number" class="overview_input" placeholder="Türen" formControlName="numOfDoors" (ngModel)="f.numOfDoors.value" [ngClass]="submitted && f.numOfDoors.errors ? 'inputfield_invalid_overview' : ''">
      </div>
      <div class="input_wrapper" *ngIf="showValue('numOfSeats')">
          <div class="overview_record_lbl">Sitze</div>
          <input type="number" class="overview_input" placeholder="Sitze" formControlName="numOfSeats" (ngModel)="f.numOfSeats.value" [ngClass]="submitted && f.numOfSeats.errors ? 'inputfield_invalid_overview' : ''">
      </div>
      <div class="input_wrapper" *ngIf="showValue('luggage_space')">
        <label class="overview_record_lbl">Kofferraumvolumen (in l)</label>
        <input type="number" class="overview_input" placeholder="Kofferraumvolumen (in l)" formControlName="luggage_space" (ngModel)="f.luggage_space.value" [ngClass]="submitted && f.luggage_space.errors ? 'inputfield_invalid_overview' : ''">
      </div>
    </form>
  </div>
</app-collapsible>
