import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from 'src/app/buisness-object/location/Location';
import { PageHandler } from 'src/app/helpers/PagerHandler';

@Component({
  selector: 'app-list-locations-container',
  templateUrl: './list-locations-container.component.html',
  styleUrls: ['./list-locations-container.component.css']
})
export class ListLocationsContainerComponent implements OnInit {
  public testNum = [1,2,3,4,5,6];
  @Input() locations: Location[];
  @Input() pageInfo: string;
  @Input() sliceFrom: number;
  @Input() sliceTo: number;
  @Output() openDetailsEmitter = new EventEmitter<undefined>();
  @Output() pageEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {}

}
