import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PriceSheet, Reparatur } from "../PriceSheet";
import { validDatePast } from "src/app/validators/CustomValidators";
import { PriceSheetCalculater } from "../PriceSheetCalculater";
import { formatDate } from "@angular/common";
import { Setting } from "../../settings/Setting";
import { NovaCalculator, NovaFahrzeugtyp } from "../NovaCalculator";
import { VehicleFuel } from "../../vehicle/basic/VehicleFuel";
import { PriceSheetCalculaterNew } from "../PriceSheetCalculaterNew";

export const STARTSTANDARDVALUES = {
  standzeit_plan_tage: 30,
  zinsen: 4.00,
  garantie_von_einkaufspreis: 1.01,
  standzeitkosten: 1,
  fixkosten_pro_fahrzeug_bei_70: 0,
  gw_aufschlag: 0,
  garantie_vor: 0,
  garantie_intern: 0
}

export class PriceSheetForm {
  static getPriceSheetForm(formBuilder: FormBuilder, settings: Setting[], isStandard: boolean, priceSheet?: PriceSheet, verkaufspreis_brutto?: number) {
      let form = formBuilder.group({
        price_sheet_id: [priceSheet ? priceSheet.price_sheet_id : null],
        vehicle_id: [priceSheet ? priceSheet.vehicle_id : null],

        guv_vorkalkulation: [priceSheet ? priceSheet.guv_vorkalkulation : null],
        guv_export_vorkalkulation: [priceSheet ? priceSheet.guv_export_vorkalkulation : null],
        guv_export_ust_vor: [priceSheet ? priceSheet.guv_export_ust_vor : null],
        guv_nachkalkulation: [priceSheet ? priceSheet.guv_nachkalkulation : null],
        aufschlag: [priceSheet ? priceSheet.aufschlag : null],

        steuersatz: [priceSheet ? priceSheet.steuersatz : null, Validators.required],
        steuersatz_nach: [priceSheet ? priceSheet.steuersatz_nach : null],
        einkaufspreis_brutto: [priceSheet ? priceSheet.einkaufspreis_brutto : null, Validators.required],
        vorsteuer: [null],
        vorsteuer_nach: [null],
        verkaufspreis_brutto: [priceSheet ? priceSheet.verkaufspreis_brutto : null],
        ohne_nova_inkl_ust: [priceSheet ? priceSheet.ohne_nova_inkl_ust : null],
        ohne_nova_inkl_ust_vor: [null],
        ust: [null],
        ust_vor: [null],

        kosten_lt_vorkalkulation: [priceSheet ? priceSheet.kosten_lt_vorkalkulation : null],
        preis_anschreiben: [isStandard ? (verkaufspreis_brutto ? verkaufspreis_brutto : null) : (priceSheet ? priceSheet.preis_anschreiben : null)],
        minimaler_verkaufpreis: [priceSheet ? priceSheet.minimaler_verkaufpreis : null],
        zugaben: [priceSheet ? priceSheet.zugaben : null],
        zubehoer: [priceSheet ? priceSheet.zubehoer : null],

        neu_preis: [priceSheet ? priceSheet.neu_preis : null],
        sonderaustattung: [priceSheet ? priceSheet.sonderaustattung : null],
        gesamt_neupreis: [priceSheet ? priceSheet.gesamt_neupreis : null],

        einkaufspreis_netto: [priceSheet ? priceSheet.einkaufspreis_netto : null],
        einkaufsdatum: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.einkaufsdatum) : null, validDatePast],
        eurotax: [priceSheet ? priceSheet.eurotax : null],
        hereinnahmedatum: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.hereinnahmedatum) : null, validDatePast],
        zahldatum: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.zahldatum) : null, validDatePast],
        anpreisungsdatum: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.anpreisungsdatum) : null, validDatePast],
        standzeit_plan_tage: [priceSheet ? (priceSheet.zinssatz_vor ? priceSheet.standzeit_plan_tage : STARTSTANDARDVALUES.standzeit_plan_tage) : STARTSTANDARDVALUES.standzeit_plan_tage],

        beschaffungskosten: [priceSheet ? priceSheet.beschaffungskosten : null],
        sprit: [priceSheet ? priceSheet.sprit : null],
        flug_bahn: [priceSheet ? priceSheet.flug_bahn : null],
        gebuehren: [priceSheet ? priceSheet.gebuehren : null],
        lieferant_extern: [priceSheet ? priceSheet.lieferant_extern : null],
        uebernachtung: [priceSheet ? priceSheet.uebernachtung : null],
        beschaffungskosten_gesamt: [priceSheet ? priceSheet.beschaffungskosten_gesamt : null],

        aufbereitung: [priceSheet ? priceSheet.aufbereitung : null],
        aufbereitung_nachkalkulation: [priceSheet ? priceSheet.aufbereitung_nachkalkulation : null],
        reparatur_dauer_tage: [priceSheet ? priceSheet.reparatur_dauer_tage : null],
        reparatur_summe_gesamt: [priceSheet ? priceSheet.reparatur_summe_gesamt : null],
        reparaturen: [priceSheet ? this.getPriceSheetsRepairForms(formBuilder, priceSheet.reparaturen) : []],
        reparaturen_vorjahr: [priceSheet ? this.getPriceSheetsRepairForms(formBuilder, priceSheet.reparaturen_vorjahr) : []],
        reparatur_summe_gesamt_vorjahr: [priceSheet ? priceSheet.reparatur_summe_gesamt_vorjahr : []],
        gw_gar_reparatur: [priceSheet ? this.getPriceSheetsRepairForms(formBuilder, [priceSheet.gw_gar_reparatur]) : [this.getPriceSheetsRepairForm(formBuilder)]],
        reparaturen_column_gesamt: [null],
        reparaturen_column_gesamt_vorjahr: [null],

        zinssatz_vor: [priceSheet ? (priceSheet.zinssatz_vor ? priceSheet.zinssatz_vor : null) : STARTSTANDARDVALUES.zinsen],
        zinsen_vor: [priceSheet ? priceSheet.zinsen_vor : null],
        zinssatz_nach: [priceSheet ? (priceSheet.zinssatz_nach ? priceSheet.zinssatz_nach : null) : STARTSTANDARDVALUES.zinsen],
        zinsen_nach: [priceSheet ? priceSheet.zinsen_nach : null],
        garantie_von_einkaufspreis: [priceSheet ? (priceSheet.garantie_von_einkaufspreis ? priceSheet.garantie_von_einkaufspreis : null) : STARTSTANDARDVALUES.garantie_von_einkaufspreis],
        garantie: [priceSheet ? priceSheet.garantie : null],
        standzeitkosten_je_tag_vor: [priceSheet ? (priceSheet.standzeitkosten_je_tag_vor ? priceSheet.standzeitkosten_je_tag_vor : null) : STARTSTANDARDVALUES.standzeitkosten],
        standzeitkosten_vor: [priceSheet ? priceSheet.standzeitkosten_vor : null],
        fixkosten_pro_fahrzeug_bei_70: [priceSheet ? (priceSheet.fixkosten_pro_fahrzeug_bei_70 ? priceSheet.fixkosten_pro_fahrzeug_bei_70 : null) : STARTSTANDARDVALUES.fixkosten_pro_fahrzeug_bei_70],
        gw_aufschlag: [priceSheet ? (priceSheet.zinssatz_nach ? priceSheet.gw_aufschlag : null) : STARTSTANDARDVALUES.gw_aufschlag],

        verkaufspreis_netto: [priceSheet ? priceSheet.verkaufspreis_netto : null],
        verkaufspreis_netto_vor: [priceSheet ? priceSheet.verkaufspreis_netto_vor : null],
        verkaufsdatum: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.verkaufsdatum) : null],
        al_re_datum: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.al_re_datum) : null],
        geldeingang: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.geldeingang) : null],
        standzeit: [priceSheet ? priceSheet.standzeit : null],
        standzeit_platz: [priceSheet ? priceSheet.standzeit_platz : null],
        standzeit_geld: [priceSheet ? priceSheet.standzeit_geld : null],

        entgelt_ohne_nova: [priceSheet ? priceSheet.entgelt_ohne_nova : null],
        entgelt_ohne_nova_vor: [null],
        nova: [priceSheet ? priceSheet.nova : null],
        nova_vor: [priceSheet ? priceSheet.nova_vor : null],
        co2_emission: [priceSheet ? priceSheet.co2_emission : null],
        malus_bonus: [priceSheet ? priceSheet.malus_bonus : null],
        summe_nova: [priceSheet ? priceSheet.summe_nova : null],
        summe_nova_vor: [priceSheet ? priceSheet.summe_nova_vor : null],

        reparaturen_anzahl: [null],

        zinsen_effektiv: [priceSheet ? priceSheet.zinsen_effektiv : null],
        garantie_intern: [priceSheet ? (priceSheet.garantie_intern ? priceSheet.garantie_intern : null) : STARTSTANDARDVALUES.garantie_intern],
        garantie_extern: [priceSheet ? priceSheet.garantie_extern : null],
        standzeitkosten_je_tag_nach: [priceSheet ? (priceSheet.standzeitkosten_je_tag_nach ? priceSheet.standzeitkosten_je_tag_nach : null) : STARTSTANDARDVALUES.standzeitkosten],
        standzeitkosten_nach: [priceSheet ? priceSheet.standzeitkosten_nach : null],
        gewaehrleistung_garantie_reparaturen: [priceSheet ? priceSheet.gewaehrleistung_garantie_reparaturen : null],
        fahrzeug_provision: [priceSheet ? priceSheet.fahrzeug_provision : null],
        finanzierung: [priceSheet ? priceSheet.finanzierung : null],

        fahrzeug_typ: [priceSheet ? priceSheet.fahrzeug_typ : null],
        nachkalkulation: [priceSheet ? priceSheet.nachkalkulation : null],
        ez: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.ez) : null],
        fuel_typ: [priceSheet ? priceSheet.fuel_typ : null],
        nova_satz: [priceSheet ? priceSheet.nova_satz : null],
        finanzierungsgesellschaft: [priceSheet ? priceSheet.finanzierungsgesellschaft : null],
        finanzierungsart: [priceSheet ? priceSheet.finanzierungsart : null],
        export_value: [priceSheet ? priceSheet.export_value : false],
        finanzierungszusage: [priceSheet ? priceSheet.finanzierungszusage : null],
        fahrzeuginzahlungnahme: [priceSheet ? priceSheet.fahrzeuginzahlungnahme : false],
        eingang_anzahlung: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.eingang_anzahlung) : null],
        eingang_anzahlung_description: [priceSheet ? priceSheet.eingang_anzahlung_description : null],
        customer: [priceSheet ? priceSheet.customer : null],
        customer_name: [priceSheet ? priceSheet.customer_name : null],
        employee: [priceSheet ? priceSheet.employee : null],
        employee_id: [priceSheet ? priceSheet.employee_id : null],

        vehicle_serialnumber: [priceSheet ? priceSheet.vehicle_serialnumber : null],
        invoice_checked: [priceSheet ? priceSheet.invoice_checked : false],
        vehicle_supplier: [priceSheet ? priceSheet.vehicle_supplier : null],
        garantie_vor: [priceSheet ? (priceSheet.garantie_vor ? priceSheet.garantie_vor : STARTSTANDARDVALUES.garantie_vor) : STARTSTANDARDVALUES.garantie_vor],

        abzug_fahrzeug_provision: [priceSheet ? priceSheet.abzug_fahrzeug_provision : null],
        abzug_finanzierung: [priceSheet ? priceSheet.abzug_finanzierung : null],

        vk_nachkalkulation: [priceSheet ? priceSheet.vk_nachkalkulation : null],
        vk_nachkalkulation_haendler: [priceSheet ? priceSheet.vk_nachkalkulation_haendler : null],
        garantie_vorkalkulation: [null],

        paper_status: [priceSheet ? priceSheet.paper_status : null],
        finanzsperrauskunft: [priceSheet ? priceSheet.finanzsperrauskunft : null],
        type_certificate_versendet: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.type_certificate_versendet) : null],

        co2_checked: [priceSheet ? priceSheet.co2_checked : null],
        rechnungsdatum_vor: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.rechnungsdatum_vor) : null],
        sent_to_bank: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.sent_to_bank) : null],
        gf_info: [priceSheet ? priceSheet.gf_info : null],
        einstockung_bei: [priceSheet ? priceSheet.einstockung_bei : null],
        vk_vor_checked: [priceSheet ? priceSheet.vk_vor_checked : null],
        datenblatt_angefordert_am: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.datenblatt_angefordert_am) : null],
        fahrzeug_eingestockt_am: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.fahrzeug_eingestockt_am) : null],
        type_certificate_eingestockt: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.type_certificate_eingestockt) : null],
        type_certificate_ausgestockt: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.type_certificate_ausgestockt) : null],

        aufschlag_nach: [null],
        malus_bonus_nach : [priceSheet ? priceSheet.malus_bonus_nach : null],
        malus_bonus_changed: [false],

        vehicle_imported: [priceSheet ? priceSheet.vehicle_imported : null],

        transport_beantragt: [priceSheet ? priceSheet.transport_beantragt : null],
        transport_date: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.transport_date) : null],
        spedition: [priceSheet ? priceSheet.spedition : null],

        finanzsperrauskunft_beantragt: [priceSheet ? this.getConvertedDateForInputFull(priceSheet.finanzsperrauskunft_beantragt) : null],
        nova_grundbetrag: [priceSheet ? priceSheet.nova_grundbetrag : null],
        nova_grundbetrag_nach : [priceSheet ? priceSheet.nova_grundbetrag_nach : null],
        nox_bonus: [priceSheet ? priceSheet.nox_bonus : null],
        nox_malus: [priceSheet ? priceSheet.nox_malus : null],
        malus: [priceSheet ? priceSheet.malus : null],
        malus_nach: [priceSheet ? priceSheet.malus_nach : null],
        erhoehungsbetrag: [priceSheet ? priceSheet.erhoehungsbetrag : null],
        abzugsposten: [priceSheet ? priceSheet.abzugsposten : null],

        electrical_distance: [priceSheet ? priceSheet.electrical_distance : null],
        electrical_distance_checked: [priceSheet ? priceSheet.electrical_distance_checked : null],

        bodyTypId: [priceSheet ? priceSheet.bodyTypId : null],
        consumptionCombined: [priceSheet ? priceSheet.consumptionCombined : null],
        powerKw: [priceSheet ? priceSheet.powerKw : null],

        einkauf_vertragsnummer: [priceSheet ? priceSheet.einkauf_vertragsnummer : null],
        verkauf_vertragsnummer: [priceSheet ? priceSheet.verkauf_vertragsnummer : null],

        zubau: [priceSheet ? priceSheet.zubau : null],
        abwertung: [priceSheet ? priceSheet.abwertung : null],
        verkaufspreis_break_even: [null],
        nova_verguetung: [priceSheet ? priceSheet.nova_verguetung : null],
        nova_verguetung_satz: [priceSheet ? priceSheet.nova_verguetung_satz : null],
        nova_verguetung_grundbetrag: [priceSheet ? priceSheet.nova_verguetung_grundbetrag : null],
        nova_verguetung_malus: [priceSheet ? priceSheet.nova_verguetung_malus : null],
        nova_verguetung_abzugsposten: [priceSheet ? priceSheet.nova_verguetung_abzugsposten : null],
        export_price_calculated: [priceSheet ? priceSheet.export_price_calculated : null],
        export_price: [priceSheet ? priceSheet.export_price : null],
        export_price_checked: [priceSheet ? priceSheet.export_price_checked : null],
        export_price_enabled: [priceSheet ? priceSheet.export_price_enabled : null],
      })
      if(priceSheet == null){
        for(let key of Object.keys(form.controls)){
          let index = settings.findIndex((setting) => setting.name == key);
          if(index > -1){
            form.controls[key].setValue(settings[index].value);
          }
        }
      }
      if(priceSheet){
        form = this.calculate(form, isStandard);
      }
      return form;
  }

  static getConvertedDateForInputFull(timestamp: number): string {
    if(timestamp){
      return formatDate(new Date(timestamp),'yyyy-MM-dd', 'de')
    }
    return null;
  }

  static getTimestampFromInputDate(date: string): number {
    if(date){
      let d = new Date(date);
      return d.getTime();
    } else return null;
  }

  static getPriceSheetsRepairForms(formBuilder: FormBuilder, repairs: Reparatur[]): FormGroup[] {
    let forms = [];
    for(let repair of repairs){
      forms.push(formBuilder.group({
        name: [repair ? repair.name : null],
        renr: [repair ? repair.renr : null],
        az: [repair ? repair.az : null],
        mat: [repair ? repair.mat : null],
        reifen: [repair ? repair.reifen : null],
        lackierung: [repair ? repair.lackierung : null],
        typisierung: [repair ? repair.typisierung : null],
      }));
    }
    return forms;
  }

  static getPriceSheetsRepairForm(formBuilder: FormBuilder, repair?: Reparatur): FormGroup {
    let form = formBuilder.group({
      name: [repair ? repair.name : null],
      renr: [repair ? repair.renr : null],
      az: [repair ? repair.az : null],
      mat: [repair ? repair.mat : null],
      reifen: [repair ? repair.reifen : null],
      lackierung: [repair ? repair.lackierung : null],
      typisierung: [repair ? repair.typisierung : null],
    });
    return form;
  }

  static getNovaCalculatorVor(form: FormGroup): NovaCalculator {
    let novaTatbestand = form.controls["verkaufsdatum"].value ? this.getTimestampFromInputDate(form.controls["verkaufsdatum"].value) : new Date().getTime();
    return new NovaCalculator(
      form.controls["preis_anschreiben"].value,
      20,
      novaTatbestand,

      form.controls["bodyTypId"].value == 7 ? NovaFahrzeugtyp.N1 : NovaFahrzeugtyp.PKW,
      form.controls["co2_emission"].value,
      new VehicleFuel(form.controls["fuel_typ"].value ? form.controls["fuel_typ"].value.id : 0, ""),
      form.controls["consumptionCombined"].value,
      form.controls["powerKw"].value,
      undefined,
      undefined,
      this.getTimestampFromInputDate(form.controls["ez"].value)
    );
  }

  static getNovaCalculatorNach(form: FormGroup): NovaCalculator {
    let novaTatbestand = form.controls["verkaufsdatum"].value ? this.getTimestampFromInputDate(form.controls["verkaufsdatum"].value) : new Date().getTime();
    return new NovaCalculator(
      form.controls["verkaufspreis_brutto"].value,
      20,
      novaTatbestand,
      form.controls["bodyTypId"].value == 7 ? NovaFahrzeugtyp.N1 : NovaFahrzeugtyp.PKW,
      form.controls["co2_emission"].value,
      new VehicleFuel(form.controls["fuel_typ"].value ? form.controls["fuel_typ"].value.id : 0, ""),
      form.controls["consumptionCombined"].value,
      form.controls["powerKw"].value,
      undefined,
      undefined,
      this.getTimestampFromInputDate(form.controls["ez"].value)
    );
  }

  static getNovaCalculatorVerguetung(form: FormGroup): NovaCalculator|undefined {
    let novaTatbestand = form.controls["verkaufsdatum"].value ? this.getTimestampFromInputDate(form.controls["verkaufsdatum"].value) : new Date().getTime();
    if(form.controls["einkaufspreis_brutto"].value != undefined && form.controls["einkaufspreis_brutto"].value > 0) {
      return new NovaCalculator(
        form.controls["steuersatz"].value == 1 ? form.controls["einkaufspreis_netto"].value : form.controls["einkaufspreis_brutto"].value,
        form.controls["steuersatz"].value == 1 ? 0 : 20,
        novaTatbestand,
        form.controls["bodyTypId"].value == 7 ? NovaFahrzeugtyp.N1 : NovaFahrzeugtyp.PKW,
        form.controls["co2_emission"].value,
        new VehicleFuel(form.controls["fuel_typ"].value ? form.controls["fuel_typ"].value.id : 0, ""),
        form.controls["consumptionCombined"].value,
        form.controls["powerKw"].value,
        undefined,
        undefined,
        this.getTimestampFromInputDate(form.controls["ez"].value)
      );
    } else {
      return undefined;
    }
  }

  static getBreakEvenVK(form: FormGroup, calculator: NovaCalculator): number|undefined {
    if(form.controls["einkaufspreis_brutto"].value != undefined && form.controls["einkaufspreis_brutto"].value > 0) {
      let novaRelevant = form.controls["vehicle_imported"].value == 1 || (form.controls["ez"].value == undefined);
        let vkBruttoBreakEven = form.controls["einkaufspreis_brutto"].value;
        if(novaRelevant) {
          let transportCosts = 0;
          if(form.controls["sprit"].value != undefined && form.controls["sprit"].value  > 0) {
            transportCosts = transportCosts + form.controls["sprit"].value;
          }
          if(form.controls["lieferant_extern"].value != undefined && form.controls["lieferant_extern"].value > 0) {
            transportCosts = transportCosts + form.controls["lieferant_extern"].value;
          }
          if(form.controls["zubau"].value != undefined && form.controls["zubau"].value > 0) {
            transportCosts = transportCosts + form.controls["zubau"].value;
          }
          transportCosts = transportCosts * 1.2;
          vkBruttoBreakEven = calculator.calcBreakEvenVK(form.controls["einkaufspreis_brutto"].value + transportCosts)
        } else {
          if(form.controls["sprit"].value != undefined && form.controls["sprit"].value  > 0) {
            vkBruttoBreakEven = vkBruttoBreakEven + form.controls["sprit"].value;
          }
          if(form.controls["lieferant_extern"].value != undefined && form.controls["lieferant_extern"].value > 0) {
            vkBruttoBreakEven = vkBruttoBreakEven + form.controls["lieferant_extern"].value;
          }
          if(form.controls["zubau"].value != undefined && form.controls["zubau"].value > 0) {
            vkBruttoBreakEven = vkBruttoBreakEven + form.controls["zubau"].value;
          }
        }
        return vkBruttoBreakEven;
      } else {
        return undefined;
      }
  }

  static getNovaVerguetung(form: FormGroup): number|undefined {
    let calculator = this.getNovaCalculatorVerguetung(form);
    if(calculator) {
      //TODO neuer Datensatz NOVA freigegeben
      if(form.controls["vehicle_imported"].value == 0) {
        return calculator.calcNovaTotal();
      } else {
        return 0;
      }
    } else {
      return undefined;
    }
  }

  static getNovaVerguetungGrundbetrag(form: FormGroup): number|undefined {
    let calculator = this.getNovaCalculatorVerguetung(form);
    if(calculator) {
      //TODO neuer Datensatz NOVA freigegeben
      if(form.controls["vehicle_imported"].value == 0) {
        return calculator.calcNovaGrundbetrag();
      } else {
        return 0;
      }
    } else {
      return undefined;
    }
  }

  static getNovaVerguetungMalus(form: FormGroup): number|undefined {
    let calculator = this.getNovaCalculatorVerguetung(form);
    if(calculator) {
      //TODO neuer Datensatz NOVA freigegeben
      if(form.controls["vehicle_imported"].value == 0) {
        return calculator.calcMalus();
      } else {
        return 0;
      }
    } else {
      return undefined;
    }
  }

  static getNovaVerguetungAbzugsposten(form: FormGroup): number|undefined {
    let calculator = this.getNovaCalculatorVerguetung(form);
    if(calculator) {
      //TODO neuer Datensatz NOVA freigegeben
      if(form.controls["vehicle_imported"].value == 0) {
        return calculator.calcAbzugsposten();
      } else {
        return 0;
      }
    } else {
      return undefined;
    }
  }


  static getNovaVerguetungSatz(form: FormGroup): number|undefined {
    let calculator = this.getNovaCalculatorVerguetung(form);
    if(calculator) {
      //TODO neuer Datensatz NOVA freigegeben
      if(form.controls["vehicle_imported"].value == 0) {
        return calculator.calcNovaSatz();
      } else {
        return 0;
      }
    } else {
      return undefined;
    }
  }

  static getExportPriceCalculated(form: FormGroup): number|undefined {
    if(form.controls["preis_anschreiben"].value == undefined || form.controls["preis_anschreiben"].value == 0) {
      return 0;
    }
    if(form.controls["vehicle_imported"].value == 0) {
      let price = form.controls["preis_anschreiben"].value - form.controls["nova_verguetung"].value;
      if(form.controls["nova_verguetung"].value == 0) {
        return price;
      }
      if(form.controls["steuersatz"].value == 2 && form.controls["preis_anschreiben"].value < form.controls["einkaufspreis_netto"].value) {
        let value = form.controls["preis_anschreiben"].value - form.controls["nova_verguetung"].value;
        price = value;
      } else if(form.controls["steuersatz"].value == 2) {
        let value = form.controls["preis_anschreiben"].value - (form.controls["preis_anschreiben"].value - form.controls["einkaufspreis_netto"].value)/6 - form.controls["nova_verguetung"].value;
        if(6*(value - form.controls["einkaufspreis_netto"].value/ 6) / 5 > form.controls["einkaufspreis_netto"].value) {
          price = 6*(value - form.controls["einkaufspreis_netto"].value/ 6) / 5;
        } else {
          price = value;
        }
      } else {
        price = form.controls["preis_anschreiben"].value - 1.2 * form.controls["nova_verguetung"].value;
      }
      return this.calcExportPriceHoedl(price);
    } else {
      let price =  form.controls["preis_anschreiben"].value - form.controls["summe_nova_vor"].value;
      if(form.controls["summe_nova_vor"].value == 0) {
        return price;
      }
      return this.calcExportPriceHoedl(price);
    }
  }

  static calcExportPriceHoedl(price: number) {
    if(price <= 30000) {
      price = price - price % 100 + 80;
    } else {
      if(price % 1000 < 980 && price % 1000 >= 480) {
        price = price - price % 1000 + 480;
      } else if(price % 1000 >= 980) {
        price = price - price % 1000 + 980;
      } else {
        price = price - price % 1000 - 20;
      }
    }
    return price;
  }

  static calculateNew(pricesheet: PriceSheet, isStandard: boolean): PriceSheet {
    pricesheet.einkaufspreis_netto = PriceSheetCalculaterNew.getEKNetto(pricesheet);
    pricesheet.vorsteuer = PriceSheetCalculaterNew.getVorSteuerDifferenz(pricesheet);
    pricesheet.zinsen_vor = PriceSheetCalculaterNew.getZinsenVor(pricesheet);
    pricesheet.garantie_vorkalkulation = PriceSheetCalculaterNew.getGarantieVor(pricesheet, isStandard);
    pricesheet.standzeitkosten_vor = PriceSheetCalculaterNew.getStandzeitkostenVor(pricesheet);
    pricesheet.gesamt_neupreis = PriceSheetCalculaterNew.getGesamtNeupreis(pricesheet);
    pricesheet.beschaffungskosten_gesamt = PriceSheetCalculaterNew.getBeschaffungskostenGesamt(pricesheet);
    pricesheet.reparaturen_column_gesamt = PriceSheetCalculaterNew.getReparaturColumnGesamt(pricesheet.reparaturen);
    pricesheet.reparatur_summe_gesamt = PriceSheetCalculaterNew.getReparaturGesamt(pricesheet.reparaturen);
    pricesheet.novaRelevant = pricesheet.vehicle_imported == 1 || (pricesheet.ez == undefined);

    if(isStandard){
      let calculator = PriceSheetCalculaterNew.getNovaCalculatorVor(pricesheet);
      pricesheet.verkaufspreis_break_even = PriceSheetCalculaterNew.getBreakEvenVK(pricesheet, calculator);
      pricesheet.nova_verguetung = PriceSheetCalculaterNew.getNovaVerguetung(pricesheet);
      pricesheet.nova_verguetung_satz = PriceSheetCalculaterNew.getNovaVerguetungSatz(pricesheet);
      pricesheet.nova_verguetung_grundbetrag = PriceSheetCalculaterNew.getNovaVerguetungGrundbetrag(pricesheet);
      pricesheet.nova_verguetung_malus = PriceSheetCalculaterNew.getNovaVerguetungMalus(pricesheet);
      pricesheet.nova_verguetung_abzugsposten = PriceSheetCalculaterNew.getNovaVerguetungAbzugsposten(pricesheet);

      let calculator_nach = PriceSheetCalculaterNew.getNovaCalculatorNach(pricesheet);
      pricesheet.novaRelevant = pricesheet.vehicle_imported == 1 || (pricesheet.ez == undefined);
      pricesheet.nova_satz = pricesheet.novaRelevant?calculator.calcNovaSatz():0;
      pricesheet.malus = pricesheet.novaRelevant?calculator.calcMalus():0;
      pricesheet.malus_nach = pricesheet.novaRelevant?calculator_nach.calcMalus():0;
      pricesheet.abzugsposten = pricesheet.novaRelevant?calculator.calcAbzugsposten():0;
      pricesheet.nova_grundbetrag = pricesheet.novaRelevant?calculator.calcNovaGrundbetrag():0;
      pricesheet.nova_grundbetrag_nach = pricesheet.novaRelevant?calculator_nach.calcNovaGrundbetrag():0;
      pricesheet.summe_nova_vor = pricesheet.novaRelevant?calculator.calcNovaTotal():0;
      pricesheet.summe_nova = pricesheet.novaRelevant?calculator_nach.calcNovaTotal():0;
      pricesheet.ust_vor = PriceSheetCalculaterNew.getUSTVorStandard(pricesheet);
      pricesheet.verkaufspreis_netto_vor = PriceSheetCalculaterNew.getVKNettoVorStandard(pricesheet);
      pricesheet.export_price_calculated = PriceSheetCalculaterNew.getExportPriceCalculated(pricesheet);
    } else {
      pricesheet.nova_satz = PriceSheetCalculaterNew.getNovaSatzValue(pricesheet, isStandard);
      pricesheet.malus_bonus = PriceSheetCalculaterNew.getMalusBonus(pricesheet, isStandard);
      if(pricesheet.malus_bonus_nach == null && PriceSheetCalculaterNew.malusBonusIsCalculatable(pricesheet, isStandard)) {
        pricesheet.malus_bonus_nach = PriceSheetCalculaterNew.getMalusBonusNach(pricesheet, isStandard);
      }
      if(pricesheet.nova_satz == 0 || pricesheet.nova_satz == null) {
        pricesheet.malus_bonus = null;
      }
      pricesheet.verkaufspreis_netto_vor = PriceSheetCalculaterNew.getVKNettoVor(pricesheet, isStandard);
      if(this.isVomAusland(pricesheet.steuersatz, pricesheet.vehicle_imported, isStandard)){
        pricesheet.verkaufspreis_netto_vor = PriceSheetCalculaterNew.getVKNettoSpecificumAuslandVor(pricesheet);
      }
      pricesheet.nova_vor = PriceSheetCalculaterNew.getNovaVor(pricesheet, isStandard);
      pricesheet.ust_vor = PriceSheetCalculaterNew.getUSTVor(pricesheet, isStandard);
    }

    pricesheet.entgelt_ohne_nova_vor = PriceSheetCalculaterNew.getEntGeltOhneNovaVor(pricesheet, isStandard);
    pricesheet.ohne_nova_inkl_ust_vor = PriceSheetCalculaterNew.getOhneNovaUSTVor(pricesheet);
    if(!isStandard) pricesheet.summe_nova_vor = PriceSheetCalculaterNew.getSummeNovaVor(pricesheet);
    pricesheet.standzeit_geld = PriceSheetCalculaterNew.getStandzeitGeld(pricesheet);
    if(!isStandard) pricesheet.standzeit_platz = PriceSheetCalculaterNew.getStandzeitPlatz(pricesheet);
    else pricesheet.standzeit_platz = PriceSheetCalculaterNew.getStandzeitPlatzStandard(pricesheet);
    pricesheet.standzeitkosten_nach = PriceSheetCalculaterNew.getStandzeitkostenNach(pricesheet);
    pricesheet.aufschlag = PriceSheetCalculaterNew.getAufschlageVerkaufVor(pricesheet);
    pricesheet.garantie = PriceSheetCalculaterNew.getGarantieNach(pricesheet);
    pricesheet.zinsen_nach = PriceSheetCalculaterNew.getZinsenNach(pricesheet);
    pricesheet.reparaturen_column_gesamt_vorjahr = PriceSheetCalculaterNew.getReparaturColumnGesamt(pricesheet.reparaturen_vorjahr);
    pricesheet.reparatur_summe_gesamt_vorjahr = PriceSheetCalculaterNew.getReparaturGesamt(pricesheet.reparaturen_vorjahr);
    pricesheet.guv_vorkalkulation = PriceSheetCalculaterNew.getGuVVor(pricesheet, isStandard);
    pricesheet.guv_export_ust_vor = PriceSheetCalculaterNew.getGuVExportVorUst(pricesheet);
    pricesheet.guv_export_vorkalkulation = PriceSheetCalculaterNew.getGuVExportVor(pricesheet, isStandard);
    if(isStandard) pricesheet.ust = PriceSheetCalculaterNew.getUSTNachStandard(pricesheet);
    else pricesheet.ust = PriceSheetCalculaterNew.getUSTNach(pricesheet);
    if(!isStandard) {
      pricesheet.verkaufspreis_netto = PriceSheetCalculaterNew.getVKNettoNach(pricesheet);
      if(this.isVomAusland(pricesheet.steuersatz, pricesheet.vehicle_imported, isStandard)){
        pricesheet.verkaufspreis_netto = PriceSheetCalculaterNew.getVKNettoSpecificumAuslandNach(pricesheet);
      }
    } else {
      pricesheet.verkaufspreis_netto = PriceSheetCalculaterNew.getVKNettoNachStandard(pricesheet);
    }
    pricesheet.nova = PriceSheetCalculaterNew.getNovaNach(pricesheet, isStandard);
    if(isStandard) pricesheet.ust = PriceSheetCalculaterNew.getUSTNachStandard(pricesheet);
    else pricesheet.ust = PriceSheetCalculaterNew.getUSTNach(pricesheet);
    pricesheet.entgelt_ohne_nova = PriceSheetCalculaterNew.getEntGeltOhneNovaNach(pricesheet, isStandard);
    pricesheet.ohne_nova_inkl_ust = PriceSheetCalculaterNew.getOhneNovaUSTNach(pricesheet);
    if(!isStandard) pricesheet.summe_nova = PriceSheetCalculaterNew.getSummeNovaNach(pricesheet);
    if(this.isVomAusland(pricesheet.steuersatz, pricesheet.vehicle_imported, isStandard)){
      pricesheet.guv_nachkalkulation = PriceSheetCalculaterNew.getGUVNachAusland(pricesheet, isStandard);
    } else {
      pricesheet.guv_nachkalkulation = PriceSheetCalculaterNew.getGuVNach(pricesheet, isStandard);
    }
    pricesheet.aufschlag_nach = PriceSheetCalculaterNew.getAufschlageVerkaufNach(pricesheet);
    return pricesheet;
  }

  //original
  static calculate(form: FormGroup, isStandard: boolean): FormGroup {
    form.controls["einkaufspreis_netto"].setValue(PriceSheetCalculater.getEKNetto(form));
    form.controls["vorsteuer"].setValue(PriceSheetCalculater.getVorSteuerDifferenz(form));
    form.controls["zinsen_vor"].setValue(PriceSheetCalculater.getZinsenVor(form));
    form.controls["garantie_vorkalkulation"].setValue(PriceSheetCalculater.getGarantieVor(form, isStandard));
    form.controls["standzeitkosten_vor"].setValue(PriceSheetCalculater.getStandzeitkostenVor(form));
    form.controls["gesamt_neupreis"].setValue(PriceSheetCalculater.getGesamtNeupreis(form));
    form.controls["beschaffungskosten_gesamt"].setValue(PriceSheetCalculater.getBeschaffungskostenGesamt(form));
    form.controls["reparaturen_column_gesamt"].setValue(PriceSheetCalculater.getReparaturColumnGesamt(form.controls["reparaturen"].value));
    form.controls["reparatur_summe_gesamt"].setValue(PriceSheetCalculater.getReparaturGesamt(form.controls["reparaturen"].value));

    if(isStandard) {
      let calculator = this.getNovaCalculatorVor(form);
      form.controls["verkaufspreis_break_even"].setValue(this.getBreakEvenVK(form, calculator));
      form.controls["nova_verguetung"].setValue(this.getNovaVerguetung(form));
      form.controls["nova_verguetung_satz"].setValue(this.getNovaVerguetungSatz(form));
      form.controls["nova_verguetung_grundbetrag"].setValue(this.getNovaVerguetungGrundbetrag(form));
      form.controls["nova_verguetung_malus"].setValue(this.getNovaVerguetungMalus(form));
      form.controls["nova_verguetung_abzugsposten"].setValue(this.getNovaVerguetungAbzugsposten(form));

      let calculator_nach = this.getNovaCalculatorNach(form);
      let novaRelevant = form.controls["vehicle_imported"].value == 1 || (form.controls["ez"].value == undefined);
      form.controls["nova_satz"].setValue(novaRelevant?calculator.calcNovaSatz():0);
      form.controls["malus"].setValue(novaRelevant?calculator.calcMalus():0);
      form.controls["malus_nach"].setValue(novaRelevant?calculator_nach.calcMalus():0);
      form.controls["abzugsposten"].setValue(novaRelevant?calculator.calcAbzugsposten():0);
      form.controls["nova_grundbetrag"].setValue(novaRelevant?calculator.calcNovaGrundbetrag():0);
      form.controls["nova_grundbetrag_nach"].setValue(novaRelevant?calculator_nach.calcNovaGrundbetrag():0);
      form.controls["summe_nova_vor"].setValue(novaRelevant?calculator.calcNovaTotal():0);
      form.controls["summe_nova"].setValue(novaRelevant?calculator_nach.calcNovaTotal():0);
      form.controls["ust_vor"].setValue(PriceSheetCalculater.getUSTVorStandard(form));
      form.controls["verkaufspreis_netto_vor"].setValue(PriceSheetCalculater.getVKNettoVorStandard(form));
      form.controls["export_price_calculated"].setValue(this.getExportPriceCalculated(form));
    } else {
      form.controls["nova_satz"].setValue(PriceSheetCalculater.getNovaSatzValue(form, isStandard));
      form.controls["malus_bonus"].setValue(PriceSheetCalculater.getMalusBonus(form, isStandard));
      if(form.controls["malus_bonus_nach"].value == null && PriceSheetCalculater.malusBonusIsCalculatable(form, isStandard)) {
        form.controls["malus_bonus_nach"].setValue(PriceSheetCalculater.getMalusBonusNach(form, isStandard));
      }
      if(form.controls["nova_satz"].value == 0 || form.controls["nova_satz"].value == null) {
        form.controls["malus_bonus"].setValue(null);
      }
      form.controls["verkaufspreis_netto_vor"].setValue(PriceSheetCalculater.getVKNettoVor(form, isStandard))
      if(this.isVomAusland(form.controls["steuersatz"].value, form.controls["vehicle_imported"].value, isStandard)){
        form.controls["verkaufspreis_netto_vor"].setValue(PriceSheetCalculater.getVKNettoSpecificumAuslandVor(form))
      }
      form.controls["nova_vor"].setValue(PriceSheetCalculater.getNovaVor(form, isStandard));
      form.controls["ust_vor"].setValue(PriceSheetCalculater.getUSTVor(form, isStandard));
    }
    form.controls["entgelt_ohne_nova_vor"].setValue(PriceSheetCalculater.getEntGeltOhneNovaVor(form, isStandard));
    form.controls["ohne_nova_inkl_ust_vor"].setValue(PriceSheetCalculater.getOhneNovaUSTVor(form));
    if(!isStandard) {
      form.controls["summe_nova_vor"].setValue(PriceSheetCalculater.getSummeNovaVor(form));
    }

    form.controls["standzeit_geld"].setValue(PriceSheetCalculater.getStandzeitGeld(form));
    if(!isStandard) {
      form.controls["standzeit_platz"].setValue(PriceSheetCalculater.getStandzeitPlatz(form));
    } else {
      form.controls["standzeit_platz"].setValue(PriceSheetCalculater.getStandzeitPlatzStandard(form));
    }

    form.controls["standzeitkosten_nach"].setValue(PriceSheetCalculater.getStandzeitkostenNach(form));
    form.controls["aufschlag"].setValue(PriceSheetCalculater.getAufschlageVerkaufVor(form));
    form.controls["garantie"].setValue(PriceSheetCalculater.getGarantieNach(form));
    form.controls["zinsen_nach"].setValue(PriceSheetCalculater.getZinsenNach(form));
    form.controls["reparaturen_column_gesamt_vorjahr"].setValue(PriceSheetCalculater.getReparaturColumnGesamt(form.controls["reparaturen_vorjahr"].value));

    form.controls["reparatur_summe_gesamt_vorjahr"].setValue(PriceSheetCalculater.getReparaturGesamt(form.controls["reparaturen_vorjahr"].value));
    form.controls["guv_vorkalkulation"].setValue(PriceSheetCalculater.getGuVVor(form, isStandard));
    form.controls["guv_export_ust_vor"].setValue(PriceSheetCalculater.getGuVExportVorUst(form));
    form.controls["guv_export_vorkalkulation"].setValue(PriceSheetCalculater.getGuVExportVor(form, isStandard));
    if(!isStandard) {
      form.controls["verkaufspreis_netto"].setValue(PriceSheetCalculater.getVKNettoNach(form))
      if(this.isVomAusland(form.controls["steuersatz"].value, form.controls["vehicle_imported"].value, isStandard)){
        form.controls["verkaufspreis_netto"].setValue(PriceSheetCalculater.getVKNettoSpecificumAuslandNach(form))
      }
    } else {
      form.controls["verkaufspreis_netto"].setValue(PriceSheetCalculater.getVKNettoNachStandard(form))
    }

    form.controls["nova"].setValue(PriceSheetCalculater.getNovaNach(form, isStandard));
    if(isStandard) {
      form.controls["ust"].setValue(PriceSheetCalculater.getUSTNachStandard(form));
    } else {
      form.controls["ust"].setValue(PriceSheetCalculater.getUSTNach(form));
    }

    form.controls["entgelt_ohne_nova"].setValue(PriceSheetCalculater.getEntGeltOhneNovaNach(form, isStandard));
    form.controls["ohne_nova_inkl_ust"].setValue(PriceSheetCalculater.getOhneNovaUSTNach(form));
    if(!isStandard) {
      form.controls["summe_nova"].setValue(PriceSheetCalculater.getSummeNovaNach(form));
    }
    if(this.isVomAusland(form.controls["steuersatz"].value, form.controls["vehicle_imported"].value, isStandard)){
      form.controls["guv_nachkalkulation"].setValue(PriceSheetCalculater.getGUVNachAusland(form, isStandard));
    } else {
      form.controls["guv_nachkalkulation"].setValue(PriceSheetCalculater.getGuVNach(form, isStandard));
    }
    form.controls["aufschlag_nach"].setValue(PriceSheetCalculater.getAufschlageVerkaufNach(form));
    return form;
  }

  static isVomAusland(steuersatz: number, imported: number, isStandard: boolean): boolean {
    if(isStandard) {
      return imported == 1;
    } else {
      if(steuersatz == 3 || steuersatz == 4) return true;
      else return false;
    }
  }


  static resetFormNachkalkulation(form: FormGroup): FormGroup {
    form.controls["steuersatz_nach"].setValue(null);
    form.controls["verkaufspreis_brutto"].setValue(null);
    form.controls["verkaufspreis_netto"].setValue(null);
    form.controls["vorsteuer_nach"].setValue(null);
    form.controls["verkaufsdatum"].setValue(null);
    form.controls["malus_bonus"].setValue(null);
    form.controls["al_re_datum"].setValue(null);
    form.controls["geldeingang"].setValue(null);
    form.controls["zinsen_nach"].setValue(null);
    form.controls["garantie_intern"].setValue(null);
    form.controls["garantie_extern"].setValue(null);
    form.controls["standzeitkosten_nach"].setValue(null);
    form.controls["entgelt_ohne_nova"].setValue(null);
    form.controls["nova"].setValue(null);
    form.controls["summe_nova"].setValue(null);
    form.controls["ohne_nova_inkl_ust"].setValue(null);
    form.controls["ust"].setValue(null);
    return form;
  }

  static createObject(form: FormGroup): PriceSheet {
    let reparaturen = [];
    for(let rep of form.controls["reparaturen"].value){
      reparaturen.push(new Reparatur(
        rep.controls["name"].value,
        rep.controls["renr"].value,
        rep.controls["az"].value,
        rep.controls["mat"].value,
        rep.controls["reifen"].value,
        rep.controls["lackierung"].value,
        rep.controls["typisierung"].value,
      ))
    }
    let reparaturen_vorjahr = [];
    for(let rep of form.controls["reparaturen_vorjahr"].value){
      reparaturen_vorjahr.push(new Reparatur(
        rep.controls["name"].value,
        rep.controls["renr"].value,
        rep.controls["az"].value,
        rep.controls["mat"].value,
        rep.controls["reifen"].value,
        rep.controls["lackierung"].value,
        rep.controls["typisierung"].value,
      ))
    }
    let gw_gar_reparatur = null;

    // gw_gar_reparatur = new Reparatur(
    //   form.controls["gw_gar_reparatur"].value?.controls["name"].value,
    //   form.controls["gw_gar_reparatur"].value?.controls["renr"].value,
    //   form.controls["gw_gar_reparatur"].value?.controls["az"].value,
    //   form.controls["gw_gar_reparatur"].value?.controls["mat"].value,
    //   form.controls["gw_gar_reparatur"].value?.controls["reifen"].value,
    //   form.controls["gw_gar_reparatur"].value?.controls["lackierung"].value,
    //   form.controls["gw_gar_reparatur"].value?.controls["typisierung"].value,
    // )
    let obj = new PriceSheet(
      form.controls["price_sheet_id"].value,
      form.controls["vehicle_id"].value,
      form.controls["guv_vorkalkulation"].value,
      form.controls["guv_nachkalkulation"].value,
      form.controls["aufschlag"].value,
      form.controls["steuersatz"].value,
      form.controls["steuersatz_nach"].value,
      form.controls["einkaufspreis_brutto"].value,
      form.controls["vorsteuer"].value,
      form.controls["vorsteuer_nach"].value,
      form.controls["verkaufspreis_brutto"].value,
      form.controls["ohne_nova_inkl_ust"].value,
      form.controls["ust"].value,
      form.controls["kosten_lt_vorkalkulation"].value,
      form.controls["preis_anschreiben"].value,
      form.controls["minimaler_verkaufpreis"].value,
      form.controls["zugaben"].value,
      form.controls["zubehoer"].value,
      form.controls["neu_preis"].value,
      form.controls["sonderaustattung"].value,
      form.controls["gesamt_neupreis"].value,
      form.controls["einkaufspreis_netto"].value,
      this.getTimestampFromInputDate(form.controls["einkaufsdatum"].value),
      form.controls["eurotax"].value,
      this.getTimestampFromInputDate(form.controls["hereinnahmedatum"].value),
      this.getTimestampFromInputDate(form.controls["zahldatum"].value),
      this.getTimestampFromInputDate(form.controls["anpreisungsdatum"].value),
      form.controls["standzeit_plan_tage"].value,
      form.controls["beschaffungskosten"].value,
      form.controls["sprit"].value,
      form.controls["flug_bahn"].value,
      form.controls["gebuehren"].value,
      form.controls["lieferant_extern"].value,
      form.controls["uebernachtung"].value,
      form.controls["beschaffungskosten_gesamt"].value,
      form.controls["aufbereitung"].value,
      form.controls["reparatur_dauer_tage"].value,
      form.controls["reparatur_summe_gesamt"].value,
      reparaturen,
      form.controls["reparatur_summe_gesamt_vorjahr"].value,
      reparaturen_vorjahr,
      gw_gar_reparatur,
      form.controls["zinssatz_vor"].value,
      form.controls["zinsen_vor"].value,
      form.controls["zinssatz_nach"].value,
      form.controls["zinsen_nach"].value,
      form.controls["garantie_von_einkaufspreis"].value,
      form.controls["garantie"].value,
      form.controls["standzeitkosten_je_tag_vor"].value,
      form.controls["standzeitkosten_vor"].value,
      form.controls["fixkosten_pro_fahrzeug_bei_70"].value,
      form.controls["gw_aufschlag"].value,
      form.controls["verkaufspreis_netto"].value,
      this.getTimestampFromInputDate(form.controls["verkaufsdatum"].value),
      this.getTimestampFromInputDate(form.controls["al_re_datum"].value),
      this.getTimestampFromInputDate(form.controls["geldeingang"].value),
      form.controls["standzeit"].value,
      form.controls["standzeit_platz"].value,
      form.controls["standzeit_geld"].value,
      form.controls["entgelt_ohne_nova"].value,
      form.controls["nova"].value,
      form.controls["co2_emission"].value,
      form.controls["malus_bonus"].value,
      form.controls["summe_nova"].value,
      form.controls["reparaturen_anzahl"].value,
      form.controls["zinsen_effektiv"].value,
      form.controls["garantie_intern"].value,
      form.controls["garantie_extern"].value,
      form.controls["standzeitkosten_je_tag_nach"].value,
      form.controls["standzeitkosten_nach"].value,
      form.controls["gewaehrleistung_garantie_reparaturen"].value,
      form.controls["fahrzeug_provision"].value,
      form.controls["finanzierung"].value,
      form.controls["fahrzeug_typ"].value,
      form.controls["ez"].value,
      form.controls["fuel_typ"].value,
      form.controls["nova_satz"].value,
      form.controls["finanzierungsgesellschaft"].value,
      form.controls["finanzierungsart"].value,
      form.controls["export_value"].value,
      form.controls["finanzierungszusage"].value,
      form.controls["fahrzeuginzahlungnahme"].value,
      this.getTimestampFromInputDate(form.controls["eingang_anzahlung"].value),
      form.controls["eingang_anzahlung_description"].value,
      form.controls["customer"].value?.id,
      form.controls["customer_name"].value,
      form.controls["employee"].value?.id,
      form.controls["vehicle_serialnumber"].value,
      form.controls["invoice_checked"].value,
      form.controls["vehicle_supplier"].value,
      form.controls["garantie_vor"].value,
      form.controls["abzug_fahrzeug_provision"].value,
      form.controls["abzug_finanzierung"].value,
      form.controls["vk_nachkalkulation"].value,
      form.controls["vk_nachkalkulation_haendler"].value,
      form.controls["verkaufspreis_netto_vor"].value,
      form.controls["nova_vor"].value,
      form.controls["entgelt_ohne_nova_vor"].value,
      form.controls["summe_nova_vor"].value,
      form.controls["ust_vor"].value,
      form.controls["garantie_vorkalkulation"].value,
      form.controls["aufbereitung_nachkalkulation"].value,
      form.controls["paper_status"].value,
      form.controls["finanzsperrauskunft"].value,
      this.getTimestampFromInputDate(form.controls["type_certificate_versendet"].value),
      form.controls["co2_checked"].value,
      this.getTimestampFromInputDate(form.controls["rechnungsdatum_vor"].value),
      this.getTimestampFromInputDate(form.controls["sent_to_bank"].value),
      form.controls["gf_info"].value,
      form.controls["einstockung_bei"].value,
      form.controls["vk_vor_checked"].value,
      this.getTimestampFromInputDate(form.controls["datenblatt_angefordert_am"].value),
      this.getTimestampFromInputDate(form.controls["fahrzeug_eingestockt_am"].value),
      this.getTimestampFromInputDate(form.controls["type_certificate_eingestockt"].value),
      this.getTimestampFromInputDate(form.controls["type_certificate_ausgestockt"].value),
      form.controls["aufschlag_nach"].value,
      form.controls["malus_bonus_nach"].value,
      form.controls["vehicle_imported"].value,
      form.controls["transport_beantragt"].value,
      this.getTimestampFromInputDate(form.controls["transport_date"].value),
      form.controls["spedition"].value,
      this.getTimestampFromInputDate(form.controls["finanzsperrauskunft_beantragt"].value),
      form.controls["nova_grundbetrag"].value,
      form.controls["nova_grundbetrag_nach"].value,
      form.controls["nox_bonus"].value,
      form.controls["nox_malus"].value,
      form.controls["malus"].value,
      form.controls["malus_nach"].value,
      form.controls["erhoehungsbetrag"].value,
      form.controls["abzugsposten"].value,
      form.controls["electrical_distance"].value,
      form.controls["electrical_distance_checked"].value,
      form.controls["einkauf_vertragsnummer"].value,
      form.controls["verkauf_vertragsnummer"].value,
      form.controls["zubau"].value,
      form.controls["abwertung"].value,
    );
    obj.export_price_enabled = form.controls["export_price_enabled"].value;
    if(!form.controls["export_price_enabled"].value) {
      obj.export_price = null;
      obj.export_price_checked = false;
    } else {
      obj.export_price_checked = form.controls["export_price_checked"].value;
      if(form.controls["export_price_checked"].value) {
        obj.export_price = form.controls["export_price"].value;
      } else {
        obj.export_price = form.controls["export_price_calculated"].value;
      }
    }
    obj.guv_export_vorkalkulation = form.controls["guv_export_vorkalkulation"].value;
    obj.guv_export_ust_vor = form.controls["guv_export_ust_vor"].value;
    if(obj.customer_id){
      obj.customer = form.controls["customer"].value;
      obj.customer_name = obj.customer.getFullName();
    }
    if(obj.employee_id){
      obj.employee = form.controls["employee"].value;
      obj.employee_name = obj.employee.getFullName();
    }
    if(obj.verkaufspreis_brutto != null) obj.nachkalkulation = true;
    obj.reparaturen_column_gesamt_vorjahr = PriceSheetCalculater.getReparaturColumnGesamt(form.controls["reparaturen_vorjahr"].value);
    obj.verkaufspreis_break_even = form.controls["verkaufspreis_break_even"].value;
    obj.nova_verguetung = form.controls["nova_verguetung"].value;
    obj.nova_verguetung_satz = form.controls["nova_verguetung_satz"].value;
    obj.nova_verguetung_grundbetrag = form.controls["nova_verguetung_grundbetrag"].value;
    obj.nova_verguetung_abzugsposten = form.controls["nova_verguetung_abzugsposten"].value;
    obj.nova_verguetung_malus = form.controls["nova_verguetung_malus"].value;
    obj.export_price_calculated = form.controls["export_price_calculated"].value;
    obj.bodyTypId = form.controls["bodyTypId"].value;
    obj.consumptionCombined = form.controls["consumptionCombined"].value;
    obj.powerKw = form.controls["powerKw"].value;
    return obj;
  }

  static getProvision(form: FormGroup): number {
    let value = null;
    if(form.controls['employee_id'].value != null){

    }
    if(form.controls['verkaufsdatum'].value != null && form.controls['anpreisungsdatum'].value != null){
      let date1 = this.getTimestampFromInputDate(form.controls['verkaufsdatum'].value);
      let date2 = this.getTimestampFromInputDate(form.controls['anpreisungsdatum'].value);
      const days = Math.ceil((date1 - date2) / (1000 * 3600 * 24));
      if(days > 365){
        value += 100;
      }
    }
    if(form.controls['verkaufspreis_brutto'].value != null){
      if(form.controls['verkaufspreis_brutto'].value < 75000) value += 100;
      else if(form.controls['verkaufspreis_brutto'].value >= 75000 && form.controls['verkaufspreis_brutto'].value < 100000) value += 150;
      else value += 200;
    }
    return value;
  }
}
