import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-topbar-drives',
  templateUrl: './topbar-drives.component.html',
  styleUrls: ['./topbar-drives.component.css']
})
export class TopbarDrivesComponent implements OnInit {
  @Input() surface: number;
  @Input() tab: number;
  @Input() driveTyp: number;
  @Output() openCreateEmitter = new EventEmitter<number>();
  @Output() changeTabEmitter = new EventEmitter<number>();
  @Output() cancelEmitter = new EventEmitter<undefined>();
  @Output() backEmitter = new EventEmitter<undefined>();
  @Output() searchEmitter = new EventEmitter<string>();
  @Output() saveEmitter = new EventEmitter<void>();
  @Output() selectionCreateInternDriveEmitter = new EventEmitter<undefined>();

  constructor(
    public authSerive: AuthenticationService
  ) { }

  ngOnInit(): void {
  }
}
