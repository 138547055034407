import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attachment, AttachmentHelper } from 'src/app/buisness-object/attachment/Attachment';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrl: './dropzone.component.css'
})
export class DropzoneComponent implements OnInit {
  @Input() object: any;
  @Input() category: number = -1;
  @Output() successCreateEmitter = new EventEmitter<any>();
  public onloadSrc = [];
  public sources: {
    name: string,
    format: string,
    data: string
  }[] = [];
  public filesAmount;
  public showUploadDialog: boolean = false;
  public attachmentToUpload: Attachment = null;

  constructor(
    private attachmentService: AttachmentService
  ){}

  ngOnInit(): void {

  }

  async onFileDropped(data: any) {
    if(!data) return;
    for(let file of data.data){
      const lastDot = file.name.lastIndexOf('.');
      const name = file.name.slice(0, lastDot);
      const extension = file.name.slice(lastDot + 1);
      let a = new Attachment(
        0,
        AttachmentHelper.getAttachmentTyp(this.object),
        AttachmentHelper.getAttachmentTypID(this.object),
        extension,
        name,
        file.content.split(',')[1]
      )
      if (this.category != -1){
        a.category = this.category;
      }
      this.attachmentToUpload = a;
      this.showUploadDialog = true;
      return; //only allow one file uploaded per drag and drop for now
    }
  }

  async uploadFile(event: any) {
    this.onloadSrc = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
         await this.readFile(event.target.files[i])
      }
      event.target.value = '';
    }
  }
  async readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        const lastDot = file.name.lastIndexOf('.');
        const name = file.name.slice(0, lastDot);
        const extension = file.name.slice(lastDot + 1);
        let a = new Attachment(
          0,
          AttachmentHelper.getAttachmentTyp(this.object),
          AttachmentHelper.getAttachmentTypID(this.object),
          extension,
          name,
          event.target.result.split(',')[1]
        )
        if (this.category != -1){
          a.category = this.category;
          this.attachmentToUpload = a;
          this.showUploadDialog = true;
          resolve(true);
        }
        else {
          this.uploadAttachment(a);
          resolve(true)
        }
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  uploadAttachment(attachment: Attachment) {
    this.attachmentService.createAttachment(attachment).subscribe((result) => {
      if(result){
        const creatorName: string = localStorage.getItem("email");
        result.creator_name = result.creator_name ? result.creator_name : creatorName;
        this.successCreateEmitter.emit(result);
      }
    })
  }
}
