import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LedgerEntryForm } from 'src/app/buisness-object/document/form/LedgerEntryForm';
import { LedgerEntry } from 'src/app/buisness-object/document/LedgerEntry';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { DocumentService } from 'src/app/service/document/document.service';

export enum LedgerEntrySubject {
  BANKFABIRAN = 'Bank Fabi ra',
  BANKEBEHEBUNG = 'Bank Behebung',
  INDIVIDUELL = 'Individuelle Beschreibung'
}
export enum LedgerEntryTyp {
  EINZAHLUNG = 'Einzahlung',
  AUSZAHLUNG = 'Auszahlung'
}

@Component({
  selector: 'app-dialog-kassabuch-create',
  templateUrl: './dialog-kassabuch-create.component.html',
  styleUrl: './dialog-kassabuch-create.component.css'
})
export class DialogKassabuchCreateComponent implements OnInit {
  @Input() ledgeEntry: LedgerEntry;

  public form: FormGroup;
  public typ: string = LedgerEntryTyp.EINZAHLUNG;
  public today = formatDate(new Date(),'yyyy-MM-dd','de');
  public ledgerEntrySubject = LedgerEntrySubject;
  public subjectValues = [LedgerEntrySubject.BANKFABIRAN,LedgerEntrySubject.BANKEBEHEBUNG,LedgerEntrySubject.INDIVIDUELL];
  public typValues = [LedgerEntryTyp.EINZAHLUNG,LedgerEntryTyp.AUSZAHLUNG];

  @Output() cancelEmitter = new EventEmitter<any>();
  @Output() createSuccessEmitter = new EventEmitter<LedgerEntry>();
  @Output() updateSuccessEmitter = new EventEmitter<LedgerEntry>();

  constructor(
    private formBuilder: FormBuilder,
    private documentService: DocumentService,
    private dialogService: DialogService
  ){}

  ngOnInit(): void {
    this.form = LedgerEntryForm.getForm(this.formBuilder, this.ledgeEntry);
  }

  cancel() {
    this.cancelEmitter.emit();
  }

  submit() {
    if(this.ledgeEntry) this.update();
    else this.create();
  }

  create() {
    let ledgerEntry = LedgerEntryForm.getObject(this.form);
    this.documentService.createLedgerEntry(ledgerEntry).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Kassabuch Eintrag wurde erstellt.',
          success: true
        });
        this.createSuccessEmitter.emit(result);
      }
    })
  }

  update() {
    let ledgerEntry = LedgerEntryForm.getObject(this.form);
    this.documentService.updateLedgerEntry(ledgerEntry).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Kassabuch Eintrag wurde aktualisiert.',
          success: true
        });
        this.updateSuccessEmitter.emit(result);
      }
    })
  }
}
