import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { Vehicle } from './../vehicle/Vehicle';
import { Customer } from '../customer/Customer';
import { VehicleStatus } from './Kaufvertrag';

export class SalesContract {
  public customer: Customer;
  public vehicle: Vehicle;
  constructor(
    public purchase_contract_id: number,
    public customer_id: number,
    public vehicle_id: number,
    public purchase_date: number,
    public handing_over_date: number,
    public price: number,
    public registration_document: number,
    public is_original_document: boolean,
    public vehicle_status: VehicleStatus,
    public payment_condition: number,
    public status: number,
    public foreign_registration_date?: number,
    public purchase_test_date?:number,
    public purchase_tester?: string,
    public warranty_period?: number,
    public deposit?: number,
    public old_vehicle?: OldVehicle,
    public special_conditions?: string,
    public finanzierungsgesellschaft?: number,
    public finanzierungsart ?: number
  ){}

  public getCreateSignatureJSON(): any {
    return {
      "signature_document" : {
        "typ" : 3,
        "reference_id" : this.purchase_contract_id,
        "authorization_number": "---",
        "authorization_authority": "---",
        "authorization_date": new Date().getTime(),
        "authorization_groups" : "---",
        "trip_description": "Bezirk Krems & Umgebung"
     }
    }
  }

  getJSON(): any {
    let json = {
      "purchase_contract_id" : this.purchase_contract_id == 0 ? null : this.purchase_contract_id,
      "customer_id": this.customer_id,
      "vehicle_id" : this.vehicle_id,
      "purchase_date" : this.purchase_date,
      "handing_over_date" : this.handing_over_date,
      "price" : this.price,
      "registration_document" : this.registration_document,
      "is_original_document" : this.is_original_document,
      "vehicle_status" : {
          "mechanical_condition" : this.vehicle_status.mechanical_condition,
          "body_condition" : this.vehicle_status.body_condition,
          "paint_condition": this.vehicle_status.paint_condition,
          "interior_condition" : this.vehicle_status.interior_condition,
          "electronic_condition": this.vehicle_status.electronic_condition
      },
      "payment_condition" : this.payment_condition,
      "foreign_registration_date": this.foreign_registration_date,
      "purchase_test_date": this.purchase_test_date,
      "purchase_tester": this.purchase_tester,
      "warranty_period": this.warranty_period,
      "deposit": this.deposit,
      "old_vehicle": {
          "brand" : {
              "make" : {
                  "make_id" : this.old_vehicle?.brand?.id,
                  "make_name" : this.old_vehicle?.brand?.makeName
              },
              "model" : {
                  "model_id" : this.old_vehicle?.model?.id,
                  "model_name" : this.old_vehicle?.model?.name
              }
          },
          "registration_document" : this.old_vehicle.registration_document,
          "mileage" : this.old_vehicle.mileage,
          "price" : this.old_vehicle.price,
          "registration_date": this.old_vehicle.registration_date,
          "previous_owner" : this.old_vehicle.previous_owner,
          "fin_number": this.old_vehicle.vin_number,
          "mileage_to_drive" : this.old_vehicle.mileage_to_drive
      },
      "special_conditions": this.special_conditions,
      "finanzierungsgesellschaft": this.finanzierungsgesellschaft,
      "finanzierungsart": this.finanzierungsart,
    };
    return json;
  }
}

// export class TradeInVehicle {
//   constructor(
//     public brand: VehicleBrand,
//     public model: VehicleModel,
//     public registration_document: number,
//     public mileage: number,
//     public price: number,
//     public registration_date: number,
//     public previous_owner: number,
//     public vin_number: string,
//     public mileage_to_drive: number
//   ){}
// }

export class OldVehicle {
  constructor(
    public brand: VehicleBrand,
    public model: VehicleModel,
    public registration_document: number,
    public mileage: number,
    public price: number,
    public registration_date: number,
    public previous_owner: number,
    public vin_number: string,
    public mileage_to_drive: number
  ){}
}
