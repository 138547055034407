import { EnumHelper } from "src/app/helpers/EnumHelper";
import { VehicleCategory } from "../vehicle/basic/VehicleCategory";
import { STEUERSATZVALUES, SteuersatzValue } from "src/app/helpers/Enums";
import { VehicleFuel } from "../vehicle/basic/VehicleFuel";
import { Customer } from "../customer/Customer";
import { Employee } from "../employee/Employee";
import { Vehicle } from "../vehicle/Vehicle";

export class PriceSheet {
  public nachkalkulation = false;
  public customer: Customer;
  public employee: Employee;
  public employee_name: string;
  public reparaturen_column_gesamt_vorjahr: any;
  public verkaufspreis_break_even: number;
  public nova_verguetung: number;
  public nova_verguetung_satz: number;
  public nova_verguetung_grundbetrag: number;
  public nova_verguetung_malus: number;
  public nova_verguetung_abzugsposten: number;
  public export_price_calculated: number;
  public export_price: number;
  public export_price_checked: boolean;
  public export_price_enabled: boolean;
  public guv_export_vorkalkulation: number;
  public guv_export_ust_vor: number;
  public bodyTypId: number;
  public consumptionCombined: number;
  public powerKw: number;
  public novaRelevant;
  public reparaturen_column_gesamt;
  public ohne_nova_inkl_ust_vor: number;

  constructor(
    public price_sheet_id: number,
    public vehicle_id: number,

    public guv_vorkalkulation: number,
    public guv_nachkalkulation: number,
    public aufschlag: number,

    public steuersatz: number,
    public steuersatz_nach: number,
    public einkaufspreis_brutto: number,
    public vorsteuer: number,
    public vorsteuer_nach: number,
    public verkaufspreis_brutto: number,
    public ohne_nova_inkl_ust: number,
    public ust: number,

    public kosten_lt_vorkalkulation: number,
    public preis_anschreiben: number,
    public minimaler_verkaufpreis: number,
    public zugaben: number,
    public zubehoer: number,

    public neu_preis: number,
    public sonderaustattung: number,
    public gesamt_neupreis: number,

    public einkaufspreis_netto: number,
    public einkaufsdatum: number,
    public eurotax: string,
    public hereinnahmedatum: number,
    public zahldatum: number,
    public anpreisungsdatum: number,
    public standzeit_plan_tage: number,

    public beschaffungskosten: number,
    public sprit: number,
    public flug_bahn: number,
    public gebuehren: number,
    public lieferant_extern: number,
    public uebernachtung: number,
    public beschaffungskosten_gesamt: number,

    public aufbereitung: number,
    public reparatur_dauer_tage: number,
    public reparatur_summe_gesamt: number,
    public reparaturen: Reparatur[],
    public reparatur_summe_gesamt_vorjahr: number,
    public reparaturen_vorjahr: Reparatur[],
    public gw_gar_reparatur: Reparatur,

    public zinssatz_vor: number,
    public zinsen_vor: number,
    public zinssatz_nach: number,
    public zinsen_nach: number,
    public garantie_von_einkaufspreis: number,
    public garantie: number,
    public standzeitkosten_je_tag_vor: number,
    public standzeitkosten_vor: number,
    public fixkosten_pro_fahrzeug_bei_70: number,
    public gw_aufschlag: number,

    public verkaufspreis_netto: number,
    public verkaufsdatum: number,
    public al_re_datum: number,
    public geldeingang: number,
    public standzeit: number,
    public standzeit_platz: number,
    public standzeit_geld: number,

    public entgelt_ohne_nova: number,
    public nova: number,
    public co2_emission: number,
    public malus_bonus: number,
    public summe_nova: number,

    public reparaturen_anzahl: number,

    public zinsen_effektiv: number,
    public garantie_intern: number,
    public garantie_extern: number,
    public standzeitkosten_je_tag_nach: number,
    public standzeitkosten_nach: number,
    public gewaehrleistung_garantie_reparaturen: number,

    public fahrzeug_provision: number,
    public finanzierung: number,

    public fahrzeug_typ: VehicleCategory,
    public ez: number,
    public fuel_typ: VehicleFuel,
    public nova_satz: number,
    public finanzierungsgesellschaft: number,
    public finanzierungsart: number,
    public export_value: boolean,
    public finanzierungszusage: number,
    public fahrzeuginzahlungnahme: boolean,
    public eingang_anzahlung: number,
    public eingang_anzahlung_description: string,
    public customer_id: number,
    public customer_name: string,
    public employee_id: number,

    public vehicle_serialnumber: string,
    public invoice_checked: boolean,
    public vehicle_supplier: string,
    public garantie_vor: number,

    public abzug_fahrzeug_provision: number,
    public abzug_finanzierung: number,

    public vk_nachkalkulation: number,
    public vk_nachkalkulation_haendler: number,
    public verkaufspreis_netto_vor: number,
    public nova_vor: number,
    public entgelt_ohne_nova_vor: number,
    public summe_nova_vor: number,
    public ust_vor: number,
    public garantie_vorkalkulation: number,
    public aufbereitung_nachkalkulation: number,

    public paper_status: number,
    public finanzsperrauskunft: number,
    public type_certificate_versendet: number,

    public co2_checked: boolean,
    public rechnungsdatum_vor: number,
    public sent_to_bank: number,
    public gf_info: string,
    public einstockung_bei: string,
    public vk_vor_checked: boolean,

    public datenblatt_angefordert_am: number,
    public fahrzeug_eingestockt_am: number,
    public type_certificate_eingestockt: number,
    public type_certificate_ausgestockt: number,

    public aufschlag_nach: number,
    public malus_bonus_nach: number,

    public vehicle_imported: number,

    public transport_beantragt: boolean,
    public transport_date: number,
    public spedition: string,

    public finanzsperrauskunft_beantragt: number,
    public nova_grundbetrag: number,
    public nova_grundbetrag_nach: number,
    public nox_bonus: number,
    public nox_malus: number,
    public malus: number,
    public malus_nach: number,
    public erhoehungsbetrag: number,
    public abzugsposten: number,

    public electrical_distance?: number,
    public electrical_distance_checked?: boolean,
    public einkauf_vertragsnummer?: string,
    public verkauf_vertragsnummer?: string,

    public zubau?: number,
    public abwertung?: number,
  ){ }

  public setVerkaufspreisBrutto() {
    const steuer = STEUERSATZVALUES[new EnumHelper().getEnumValue(SteuersatzValue,this.steuersatz_nach-1)];
    const vk_netto = this.verkaufspreis_netto;
    let brutto = vk_netto * ((steuer/100)+1)
    this.verkaufspreis_brutto = Number(brutto.toFixed(2));
  }

  public setBeschaffungskostenGesamt() {
    const sprit = this.sprit;
    const flug_bahn = this.flug_bahn;
    const gebuehren = this.gebuehren;
    const lieferant_extern = this.lieferant_extern;
    const uebernachtung = this.uebernachtung;
    let gesamt =
      sprit +
      flug_bahn +
      gebuehren +
      lieferant_extern +
      uebernachtung;
    this.beschaffungskosten_gesamt = gesamt;
  }

  public getIstStandzeit(): number {
    if(this.hereinnahmedatum){
      if(this.verkaufsdatum){
        const days = Math.ceil((this.verkaufsdatum - this.hereinnahmedatum) / (1000 * 3600 * 24));
        return days;
      } else {
        const days = Math.ceil((new Date().getTime() - this.hereinnahmedatum) / (1000 * 3600 * 24));
        return days;
      }
    }
  }

  public setDataFromVehicle(vehicle: Vehicle) {
    this.co2_emission = vehicle.co2Emission;
    this.fahrzeug_typ = vehicle.category;
    this.eurotax = vehicle.eurotax_code;
    this.ez = vehicle.firstRegistrationDate;
    this.fuel_typ = vehicle.fuel;
    this.minimaler_verkaufpreis = vehicle.dealerPrice;
    this.paper_status = vehicle.paper_status;
    this.finanzsperrauskunft = vehicle.finanzsperrauskunft;
    this.finanzsperrauskunft_beantragt = vehicle.finanzsperrauskunft_beantragt;
    this.type_certificate_versendet = vehicle.type_certificate_versendet;
    this.co2_emission = vehicle.co2Emission;
    this.co2_checked = vehicle.co2_checked;
    this.datenblatt_angefordert_am = vehicle.datenblatt_angefordert_am;
    this.fahrzeug_eingestockt_am = vehicle.fahrzeug_eingestockt_am;
    this.type_certificate_eingestockt = vehicle.type_certificate_eingestockt;
    this.type_certificate_ausgestockt = vehicle.type_certificate_ausgestockt;
    this.hereinnahmedatum = vehicle.eingangsdatum;
    this.electrical_distance = vehicle.electrical_distance;
    this.electrical_distance_checked = vehicle.electrical_distance_checked;
    this.einkauf_vertragsnummer = vehicle.einkauf_vertragsnummer;
    this.verkauf_vertragsnummer = vehicle.verkauf_vertragsnummer;
    this.export_price = vehicle.export_price;
    this.export_price_checked = vehicle.export_price_checked;
    this.export_price_enabled = vehicle.export_price_enabled;
    this.zubau = vehicle.zubau;
    this.abwertung = vehicle.abwertung;

    this.bodyTypId = vehicle.bodyTyp.id;
    this.consumptionCombined = vehicle.consumption?.combined;
    this.powerKw = vehicle.powerKw;
  }

  public showNovaValues(): boolean {
    return true;
  }

  public getJSON() {
    let reparaturen = [];
    if(this.reparaturen){
      this.reparaturen.forEach(el => {
        reparaturen.push({
          "name": el.name,
          "renr": el.renr,
          "az" : el.az,
          "mat" : el.mat,
          "reifen" : el.reifen,
          "lackierung" : el.lackierung,
          "typisierung" : el.typisierung,
        })
      });
    }
    let reparaturen_vorjahr = [];
    if(this.reparaturen_vorjahr){
      this.reparaturen_vorjahr.forEach(el => {
        reparaturen_vorjahr.push({
          "name": el.name,
          "renr": el.renr,
          "az" : el.az,
          "mat" : el.mat,
          "reifen" : el.reifen,
          "lackierung" : el.lackierung,
          "typisierung" : el.typisierung,
        })
      });
    }
    let gw_gar_reparatur = [];
    if(this.gw_gar_reparatur){
      gw_gar_reparatur.push({
        "name": this.gw_gar_reparatur.name,
        "renr": this.gw_gar_reparatur.renr,
        "az" : this.gw_gar_reparatur.az,
        "mat" : this.gw_gar_reparatur.mat,
        "reifen" : this.gw_gar_reparatur.reifen,
        "lackierung" : this.gw_gar_reparatur.lackierung,
        "typisierung" : this.gw_gar_reparatur.typisierung,
      });
    }
    let body = {
      "price_sheet_id": this.price_sheet_id,
      "vehicle_id": this.vehicle_id,
      "guv_vorkalkulation" : this.guv_vorkalkulation,
      "guv_nachkalkulation" : this.guv_nachkalkulation,
      "aufschlag" : this.aufschlag,

      "steuersatz" : this.steuersatz,
      "steuersatz_nach" : this.steuersatz_nach,
      "einkaufspreis_brutto" : this.einkaufspreis_brutto,
      "vorsteuer" : this.vorsteuer,
      "verkaufspreis_brutto" : this.verkaufspreis_brutto,
      "ohne_nova_inkl_ust" : this.ohne_nova_inkl_ust,

      "kosten_lt_vorkalkulation" : this.kosten_lt_vorkalkulation,
      "preis_anschreiben" : this.preis_anschreiben,
      "minimaler_verkaufpreis" : this.minimaler_verkaufpreis,
      "zugaben" : this.zugaben,
      "zubehoer" : this.zubehoer,

      "neu_preis" : this.neu_preis,
      "sonderaustattung" : this.sonderaustattung,
      // "gesamt_neupreis" : this.gesamt_neupreis,

      "einkaufspreis_netto" : this.einkaufspreis_netto,
      "einkaufsdatum" : this.einkaufsdatum,
      "eurotax" : this.eurotax,
      "hereinnahmedatum" : this.hereinnahmedatum,
      "zahldatum" : this.zahldatum,
      "anpreisungsdatum" : this.anpreisungsdatum,
      "standzeit_plan_tage" : this.standzeit_plan_tage,

      "beschaffungskosten" : this.beschaffungskosten,
      "sprit" : this.sprit,
      "flug_bahn" : this.flug_bahn,
      "gebuehren" : this.gebuehren,
      "lieferant_extern" : this.lieferant_extern,
      "uebernachtung" : this.uebernachtung,

      "aufbereitung" : this.aufbereitung,
      "reparatur_dauer_tage" : this.reparatur_dauer_tage,
      "reparatur_summe_gesamt" : this.reparatur_summe_gesamt,
      "reparaturen" : reparaturen,
      "reparaturen_vorjahr" : reparaturen_vorjahr,
      "gw_gar_reparatur" : gw_gar_reparatur,

      "zinssatz_vor" : this.zinssatz_vor,
      "zinsen_vor" : this.zinsen_vor,
      "zinssatz_nach" : this.zinssatz_nach,
      "zinsen_nach" : this.zinsen_nach,
      "garantie_von_einkaufspreis" : this.garantie_von_einkaufspreis,
      "garantie" : this.garantie,
      "standzeitkosten_je_tag_vor" : this.standzeitkosten_je_tag_vor,
      "standzeitkosten_vor" : this.standzeitkosten_vor,
      "fixkosten_pro_fahrzeug_bei_70" : this.fixkosten_pro_fahrzeug_bei_70,
      "gw_aufschlag" : this.gw_aufschlag,

      "verkaufspreis_netto" : this.verkaufspreis_netto,
      "verkaufsdatum" : this.verkaufsdatum,
      "al_re_datum" : this.al_re_datum,
      "geldeingang" : this.geldeingang,
      "standzeit" : this.standzeit,
      "standzeit_platz" : this.standzeit_platz,
      "standzeit_geld" : this.standzeit_geld,

      "zinsen_effektiv" : this.zinsen_effektiv,
      "garantie_intern" : this.garantie_intern,
      "garantie_extern" : this.garantie_extern,
      "standzeitkosten_je_tag_nach" : this.standzeitkosten_je_tag_nach,
      "standzeitkosten_nach" : this.standzeitkosten_nach,
      "gewaehrleistung_garantie_reparaturen" : this.gewaehrleistung_garantie_reparaturen,

      "fahrzeug_provision" : this.fahrzeug_provision,
      "finanzierung" : this.finanzierung,
      "finanzierungsgesellschaft": this.finanzierungsgesellschaft,
      "finanzierungsart": this.finanzierungsart,
      "fzg_export": this.export_value,
      "finanzierungszusage": this.finanzierungszusage,
      "fahrzeuginzahlungnahme": this.fahrzeuginzahlungnahme,
      "eingang_anzahlung": this.eingang_anzahlung,
      "eingang_anzahlung_description": this.eingang_anzahlung_description,
      "customer_id": this.customer_id,
      "customer_name": this.customer_name,
      "employee_id": this.employee_id,

      "vehicle_serialnumber": this.vehicle_serialnumber,
      "invoice_checked": this.invoice_checked,
      "vehicle_supplier": this.vehicle_supplier,
      "garantie_vor": this.garantie_vor,
      "abzug_fahrzeug_provision": this.abzug_fahrzeug_provision,
      "abzug_finanzierung": this.abzug_finanzierung,

      "vk_nachkalkulation": this.vk_nachkalkulation,
      "vk_nachkalkulation_haendler": this.vk_nachkalkulation_haendler,
      "aufbereitung_nachkalkulation": this.aufbereitung_nachkalkulation,
      "rechnungsdatum_vor": this.rechnungsdatum_vor,
      "sent_to_bank": this.sent_to_bank,
      "gf_info": this.gf_info,
      "einstockung_bei": this.einstockung_bei,
      "vk_vor_checked": this.vk_vor_checked,
      "malus_bonus_nach": this.malus_bonus_nach,
      "vehicle_imported": this.vehicle_imported,

      "finanzsperrauskunft_beantragt": this.finanzsperrauskunft_beantragt,

      "zubau": this.zubau,
      "abwertung": this.abwertung,
    }
    return body;
  }

  public getJSON2() {
    let reparaturen = [];
    if(this.reparaturen){
      this.reparaturen.forEach(el => {
        reparaturen.push({
          "name": el.name,
          "renr": el.renr,
          "az" : el.az,
          "mat" : el.mat,
          "reifen" : el.reifen,
          "lackierung" : el.lackierung,
          "typisierung" : el.typisierung,
        })
      });
    }
    let reparaturen_vorjahr = [];
    if(this.reparaturen_vorjahr){
      this.reparaturen_vorjahr.forEach(el => {
        reparaturen_vorjahr.push({
          "name": el.name,
          "renr": el.renr,
          "az" : el.az,
          "mat" : el.mat,
          "reifen" : el.reifen,
          "lackierung" : el.lackierung,
          "typisierung" : el.typisierung,
        })
      });
    }
    let gw_gar_reparatur = [];
    if(this.gw_gar_reparatur){
      gw_gar_reparatur.push({
        "name": this.gw_gar_reparatur.name,
        "renr": this.gw_gar_reparatur.renr,
        "az" : this.gw_gar_reparatur.az,
        "mat" : this.gw_gar_reparatur.mat,
        "reifen" : this.gw_gar_reparatur.reifen,
        "lackierung" : this.gw_gar_reparatur.lackierung,
        "typisierung" : this.gw_gar_reparatur.typisierung,
      });
    }
    return {
      "price_sheet_id": this.price_sheet_id,
      "vehicle_id": this.vehicle_id,
      "guv_vorkalkulation" : this.guv_vorkalkulation,
      "guv_nachkalkulation" : this.guv_nachkalkulation,
      "aufschlag" : this.aufschlag,

      "steuersatz" : this.steuersatz,
      "steuersatz_nach" : this.steuersatz_nach,
      "einkaufspreis_brutto" : this.einkaufspreis_brutto,
      "vorsteuer" : this.vorsteuer,
      "verkaufspreis_brutto" : this.verkaufspreis_brutto,
      "ohne_nova_inkl_ust" : this.ohne_nova_inkl_ust,

      "kosten_lt_vorkalkulation" : this.kosten_lt_vorkalkulation,
      "preis_anschreiben" : this.preis_anschreiben,
      "minimaler_verkaufpreis" : this.minimaler_verkaufpreis,
      "zugaben" : this.zugaben,
      "zubehoer" : this.zubehoer,

      "neu_preis" : this.neu_preis,
      "sonderaustattung" : this.sonderaustattung,
      // "gesamt_neupreis" : this.gesamt_neupreis,

      "einkaufspreis_netto" : this.einkaufspreis_netto,
      "einkaufsdatum" : this.einkaufsdatum,
      "eurotax" : this.eurotax,
      "hereinnahmedatum" : this.hereinnahmedatum,
      "zahldatum" : this.zahldatum,
      "anpreisungsdatum" : this.anpreisungsdatum,
      "standzeit_plan_tage" : this.standzeit_plan_tage,

      "beschaffungskosten" : this.beschaffungskosten,
      "sprit" : this.sprit,
      "flug_bahn" : this.flug_bahn,
      "gebuehren" : this.gebuehren,
      "lieferant_extern" : this.lieferant_extern,
      "uebernachtung" : this.uebernachtung,

      "aufbereitung" : this.aufbereitung,
      "reparatur_dauer_tage" : this.reparatur_dauer_tage,
      "reparatur_summe_gesamt" : this.reparatur_summe_gesamt,
      "reparaturen" : reparaturen,
      "reparaturen_vorjahr" : reparaturen_vorjahr,
      "gw_gar_reparatur" : gw_gar_reparatur,

      "zinssatz_vor" : this.zinssatz_vor,
      "zinsen_vor" : this.zinsen_vor,
      "zinssatz_nach" : this.zinssatz_nach,
      "zinsen_nach" : this.zinsen_nach,
      "garantie_von_einkaufspreis" : this.garantie_von_einkaufspreis,
      "garantie" : this.garantie,
      "standzeitkosten_je_tag_vor" : this.standzeitkosten_je_tag_vor,
      "standzeitkosten_vor" : this.standzeitkosten_vor,
      "fixkosten_pro_fahrzeug_bei_70" : this.fixkosten_pro_fahrzeug_bei_70,
      "gw_aufschlag" : this.gw_aufschlag,

      "verkaufspreis_netto" : this.verkaufspreis_netto,
      "verkaufsdatum" : this.verkaufsdatum,
      "al_re_datum" : this.al_re_datum,
      "geldeingang" : this.geldeingang,
      "standzeit" : this.standzeit,
      "standzeit_platz" : this.standzeit_platz,
      "standzeit_geld" : this.standzeit_geld,

      "zinsen_effektiv" : this.zinsen_effektiv,
      "garantie_intern" : this.garantie_intern,
      "garantie_extern" : this.garantie_extern,
      "standzeitkosten_je_tag_nach" : this.standzeitkosten_je_tag_nach,
      "standzeitkosten_nach" : this.standzeitkosten_nach,
      "gewaehrleistung_garantie_reparaturen" : this.gewaehrleistung_garantie_reparaturen,

      "fahrzeug_provision" : this.fahrzeug_provision,
      "finanzierung" : this.finanzierung,
      "finanzierungsgesellschaft": this.finanzierungsgesellschaft,
      "finanzierungsart": this.finanzierungsart,
      "fzg_export": this.export_value,
      "finanzierungszusage": this.finanzierungszusage,
      "fahrzeuginzahlungnahme": this.fahrzeuginzahlungnahme,
      "eingang_anzahlung": this.eingang_anzahlung,
      "eingang_anzahlung_description": this.eingang_anzahlung_description,
      "customer_id": this.customer_id,
      "customer_name": this.customer_name,
      "employee_id": this.employee_id,

      "vehicle_serialnumber": this.vehicle_serialnumber,
      "invoice_checked": this.invoice_checked,
      "vehicle_supplier": this.vehicle_supplier,
      "garantie_vor": this.garantie_vor,
      "abzug_fahrzeug_provision": this.abzug_fahrzeug_provision,
      "abzug_finanzierung": this.abzug_finanzierung,

      "vk_nachkalkulation": this.vk_nachkalkulation,
      "vk_nachkalkulation_haendler": this.vk_nachkalkulation_haendler,
      "aufbereitung_nachkalkulation": this.aufbereitung_nachkalkulation,
      "rechnungsdatum_vor": this.rechnungsdatum_vor,
      "sent_to_bank": this.sent_to_bank,
      "gf_info": this.gf_info,
      "einstockung_bei": this.einstockung_bei,
      "vk_vor_checked": this.vk_vor_checked,
      "malus_bonus_nach": this.malus_bonus_nach,
      "vehicle_imported": this.vehicle_imported,

      "finanzsperrauskunft_beantragt": this.finanzsperrauskunft_beantragt,

      "zubau": this.zubau,
      "abwertung": this.abwertung,
    };
  }
}


export class Reparatur {
  constructor(
    public name: string,
    public renr: string,
    public az: number,
    public mat: number,
    public reifen: number,
    public lackierung: number,
    public typisierung: number
  ){}
}
