export class SearchHelper {

  static isMatch(value: any, search_value: string, start_with?: boolean): boolean {
    if(value == undefined || value == null) return false;
    value = value.toString().toLowerCase();
    return start_with ? value.startsWith(search_value.toString().toLowerCase()) : value.includes(search_value.toString().toLowerCase());
  }

  // static isMatch(obj: any, obj_key: any, search_value: string, start_with?: boolean): boolean {
  //   if(obj[obj_key] == null || obj[obj_key] == 'function') return false;
  //   let value = obj[obj_key].toString().toLowerCase();
  //   return start_with ? value.startsWith(search_value.toString().toLowerCase()) : value.includes(search_value.toString().toLowerCase());
  // }
}
