export class Address {
    constructor(
        public addressId: number,
        public street: string,
        public postalCode: string,
        public city: string,
        public country: string
    ){}

    getFullAddress(): string {
      return this.street + ', ' + this.postalCode + ' ' + this.city + (this.country != null ? (', ' + this.country) : '');
    }
}
