<app-collapsible [isCollapsible]="!editMode && viewAuth.view.collapsible" [isContentVisible]="collapsed" *ngIf="!editMode">
  <h3 collapsible-header class="overview_data_box_lbl_title">Zusatzinformationen</h3>
  <div collapsible-content>
    <div class="overview_record" *ngIf="showValue('eingangsdatum')">
      <label class="overview_record_lbl">Fahrzeug-Eingangsdatum</label>
      <label class="overview_record_value">{{vehicle.eingangsdatum | timestampFormat}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('keys_number')">
      <label class="overview_record_lbl">Standard Schlüssel</label>
      <label class="overview_record_value">{{getInputValue(vehicle.keys_number)}}</label>
    </div> 
    <div class="overview_record" *ngIf="showValue('display_keys_number')">
      <label class="overview_record_lbl">Displaykey/Standheizung</label>
      <label class="overview_record_value">{{getInputValue(vehicle.display_keys_number)}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('digital_keys_number')">
      <label class="overview_record_lbl">Digital Schlüssel</label>
      <label class="overview_record_value">{{getInputValue(vehicle.digital_keys_number)}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('pickerl_valid')">
      <label class="overview_record_lbl">§57a fällig am</label>
      <div class="overview_record_value">{{getInputValueDateMonth(vehicle.pickerl_valid)}}</div>
    </div>
    <div class="overview_record" *ngIf="showValue('is_ersatzauto')">
      <label class="overview_record_lbl">Ersatzauto</label>
      <label class="overview_record_value">{{getBooleanStringValue(vehicle.is_ersatzauto)}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('issued_ersatzauto')">
      <label class="overview_record_lbl">Ersatzauto bis</label>
      <label class="overview_record_value">{{getInputValueDate(vehicle.issued_ersatzauto)}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('endreinigungsdatum')">
      <label class="overview_record_lbl">Endreinigungsdatum</label>
      <label class="overview_record_value">{{getInputValueDate(vehicle.endreinigungsdatum)}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('handing_over_date')">
      <label class="overview_record_lbl">Auslieferungsdatum</label>
      <label class="overview_record_value">{{getInputValueDate(vehicle.handing_over_date)}}</label>
    </div>
    <div class="overview_record" *ngIf="vehicle.priceSheet != null && showValue('priceSheet.geldeingang')">
      <label class="overview_record_lbl">Geldeingang</label>
      <label class="overview_record_value">{{getInputValueDate(vehicle.priceSheet?.geldeingang)}}</label>
    </div>
    <div class="overview_record" *ngIf="vehicle.priceSheet != null && showValue('priceSheet.eingang_anzahlung')">
      <label class="overview_record_lbl">Eingang Anzahlung</label>
      <label class="overview_record_value">{{getInputValueDate(vehicle.priceSheet?.eingang_anzahlung)}}</label>
    </div>
    <div class="overview_record" *ngIf="vehicle.priceSheet != null && showValue('priceSheet.eingang_anzahlung_description')">
      <label class="overview_record_lbl">Eingang Anzahlung Info</label>
      <label class="overview_record_value">{{getInputValue(vehicle.priceSheet?.eingang_anzahlung_description)}}</label>
    </div>
  </div>
</app-collapsible>

<form *ngIf="editMode  && viewAuth.edit.name" [formGroup]="formGroupVehicle" class="overview_data_box">
  <h3>Zusatzinformationen</h3>
  <div class="overview_data_form">
    <div class="input_wrapper" *ngIf="showValue('eingangsdatum')">
      <label class="overview_record_lbl">Fahrzeug-Eingangsdatum</label>
      <input #inputDate3 type="date" formControlName="eingangsdatum" [max]="maxDate" [class.date-input--has-value]="inputDate3.value != null" [class.input_invalid]="submitted && f.eingangsdatum.errors">
    </div>
    <div class="input_wrapper" *ngIf="showValue('keys_number')">
      <label class="overview_record_lbl">Standard Schlüssel</label>
      <input type="number" class="overview_input" placeholder="Standard Schlüssel" min="0" step="1" formControlName="keys_number">
    </div>
    <div class="input_wrapper" *ngIf="showValue('display_keys_number')">
      <label class="overview_record_lbl">Displaykey/Standheizung</label>
      <input type="number" class="overview_input" placeholder="Displaykey/Standheizung" min="0" step="1" formControlName="display_keys_number">
    </div>
    <div class="input_wrapper" *ngIf="showValue('digital_keys_number')">
      <label class="overview_record_lbl">Digital Schlüssel</label>
      <input type="number" class="overview_input" placeholder="Digital Schlüssel" min="0" step="1" formControlName="digital_keys_number">
    </div>
    <div class="input_wrapper" *ngIf="showValue('pickerl_valid')">
      <label class="overview_record_lbl">§57a fällig am</label>
      <input #inputDate7 type="month" formControlName="pickerl_valid" [class.date-input--has-value]="inputDate7.value != null" [class.input_invalid]="submitted && f.pickerl_valid.errors">
    </div>
    <div *ngIf="showValue('is_ersatzauto')">
      <app-dropdown-simple
        [title]="'Ersatzauto'"
        [formcontrol]="f.is_ersatzauto"
        [isOptional]="true"
        (submitValueEmitter)="f.is_ersatzauto.setValue($event)"
      ></app-dropdown-simple>
    </div>
    <div class="input_wrapper" *ngIf="f.is_ersatzauto.value == true && showValue('issued_ersatzauto')">
      <label class="overview_record_lbl">Ersatzauto bis</label>
      <input #inputDate1 type="date" [min]="minDate" formControlName="issued_ersatzauto" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && f.issued_ersatzauto.errors">
    </div>
    <div class="input_wrapper" *ngIf="showValue('endreinigungsdatum')">
      <label class="overview_record_lbl">Endreinigungsdatum</label>
      <input #inputDate4 type="date" formControlName="endreinigungsdatum" [class.date-input--has-value]="inputDate4.value != null" [class.input_invalid]="submitted && f.endreinigungsdatum.errors">
    </div>
    <div class="input_wrapper"  *ngIf="showValue('handing_over_date')">
      <label class="overview_record_lbl">Auslieferungsdatum</label>
      <input #inputDate2 type="date" [min]="minDate" formControlName="handing_over_date" [class.date-input--has-value]="inputDate2.value != null" [class.input_invalid]="submitted && f.handing_over_date.errors">
    </div>
  </div>
</form>
