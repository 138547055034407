<div id="dialog_window" *ngIf="show">
  <div id="dialog_container" appDropdown [show]="show" (changedValueEmitter)="show = $event">
    <div id="dialog_close_icon" (click)="show = false">&#10005;</div>
    <h1>{{title}}</h1>
      <form id="dialog_conainter_body" [formGroup]="form">
        <label>{{message}}</label>
        <div id="dialog_slider_wrapper">
          <div class="flex_slider">
            <label>Export Plattformen & Website</label>
            <div class="slider_container" (click)="form.controls.export_web.setValue(!form.controls.export_web.value)">
              <div class="slider_still" [ngClass]="form.controls.export_web.value ? 'slider_still_on' : ''"></div>
              <div class="slider_move" [ngClass]="form.controls.export_web.value ? 'slider_on' : 'slider_off'"></div>
            </div>
          </div>
          <!--<div class="flex_slider">
            <label>Export Platform</label>
            <div class="slider_container" (click)="form.controls.export_plattform.setValue(!form.controls.export_plattform.value)">
              <div class="slider_still" [ngClass]="form.controls.export_plattform.value ? 'slider_still_on' : ''"></div>
              <div class="slider_move" [ngClass]="form.controls.export_plattform.value ? 'slider_on' : 'slider_off'"></div>
            </div>
          </div>-->
          <div class="flex_slider">
            <label>Export Mobile.de</label>
            <div class="slider_container" (click)="form.controls.export_mobile.setValue(!form.controls.export_mobile.value)">
              <div class="slider_still" [ngClass]="form.controls.export_mobile.value ? 'slider_still_on' : ''"></div>
              <div class="slider_move" [ngClass]="form.controls.export_mobile.value ? 'slider_on' : 'slider_off'"></div>
            </div>
          </div>
          <div class="input_wrapper" *ngIf="!price">
            <label>Verkaufspreis in €</label>
            <input type="number" class="overview_input" placeholder="Verkaufspreis in € *" formControlName="price" (ngModel)="form.controls.price.value" [ngClass]="submitted && form.controls.price.errors ? 'inputfield_invalid_overview' : ''">
          </div>
        </div>
      </form>
      <div class="dialog_btn_container">
        <button class="btn_label" (click)="show=false">{{btnCancelTxt}}</button>
        <button class="btn_submit_dark" [disabled]="disabled()" (click)="onSubmit()">{{btnSubmitTxt}}</button>
      </div>
  </div>
</div>
