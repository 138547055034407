import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { DateHelper } from 'src/app/utils/DateHelper';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-create-drive-new',
  templateUrl: './create-drive-new.component.html',
  styleUrl: './create-drive-new.component.css'
})
export class CreateDriveNewComponent extends BaseComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() pageDrive: number;
  @Input() vehicles: Vehicle[];
  @Input() employees: Employee[];
  @Input() drives: Drive[];
  @Input() customers: Customer[];
  @Input() driveTyp: number;
  @Output() createDriveEmitter = new EventEmitter<Drive>();
  public vehicleValues = [];
  @ViewChild('input_vehicle') input_vehicle: ElementRef;
  public platesValues = [];
  public selectedPlateValue = [];
  public selectedVehicle: Vehicle;
  public customerValues = [];
  public employeeValues = [];

  constructor(public authService: AuthenticationService, private eService: EnumService) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.employeeValues = this.getDropdownValuesEmployee(this.employees);
    this.platesValues = this.getEnumValues('license_plate_id');
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.form.controls['license_plate_id'].value);
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
    this.customerValues = this.getDropdownValuesCustomer(this.customers);
    this.form.controls['start_date'].setValue(DateHelper.getConvertedDateForInputFull(new Date().getTime()));
    this.form.controls['start_time'].setValue(DateHelper.getConvertedTimeForInputFull(new Date().getTime()));
  }

  getDropdownValuesEmployee(employees: Employee[]): any[] {
    let values = [];
    for(let value of employees){
      values.push({
        id: value.id,
        name: value.firstName + ' ' + value.lastName
      })
    }
    return values;
  }

  setPlate(value) {
    this.form.controls['license_plate_id'].setValue(value);
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.form.controls['license_plate_id'].value);
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'vehicle'){
      let index = this.vehicles.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['vehicle'].setValue(this.vehicles[index]);
        this.setVehicle(this.vehicles[index]);
      }
    } else if(value === 'customer'){
      let index = this.customers.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['customer'].setValue(this.customers[index]);
      }
    }
    else if(value === 'employee'){
      let index = this.employees.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['employee'].setValue(this.employees[index]);
      }
    }
  }

  setVehicle(vehicle: Vehicle) {
    this.selectedVehicle = vehicle;
    this.form.controls.vehicle.setValue(vehicle);
    if(this.input_vehicle) this.input_vehicle.nativeElement.value = this.selectedVehicle.getDescription(25);
  }

  selectionSave() {
    this.submitted = true;

    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    let startDate = DateHelper.convertDateStringToTimestamp(this.form.controls['start_date'].value + ' ' + this.form.controls['start_time'].value);
    let duration = this.form.controls['duration'].value * 60 * 1000;
    let endDate = startDate + duration;
    let driveToCreate = new Drive(
      0,
      this.authService.getLocationID(),
      startDate,
      duration,
      this.form.controls['vehicle'].value.id,
      this.form.controls['customer'].value.id,
      this.form.controls['license_plate_id'].value,
      [],
      startDate,
      endDate
    );
    driveToCreate.trip_description = this.form.controls['trip_description'].value;
    driveToCreate.vehicle = this.form.controls['vehicle'].value;
    driveToCreate.customer = this.form.controls['customer'].value;
    this.createDriveEmitter.emit(driveToCreate);
  }
}
