import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, forwardRef, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'app-dropdown-standard',
  templateUrl: './dropdown-standard.component.html',
  styleUrl: './dropdown-standard.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownStandardComponent),
      multi: true
    }
  ]
})
export class DropdownStandardComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() placeholder: string = '---';
  @Input() selectedValue: string;
  @Input() suffix: string;
  @Input() submitted: boolean;
  @Input() formControl: FormControl;
  @Input() values: string[];
  @Input() isOptional: boolean = false;
  @Input() isHighlighted: boolean;
  @Output() submitValueEmitter = new EventEmitter<string>();
  public show = false;
  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(
    private cdk: ChangeDetectorRef
  ) {}

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes &&
      changes['selectedValue'] &&
      changes['selectedValue'].currentValue != changes['selectedValue'].currentValue){
        this.cdk.detectChanges();
    }
  }

  selectionValue(value) {
    this.selectedValue = value;
    this.submitValueEmitter.emit(value);
    this.show = false;
  }

  writeValue(value: any): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Handle the disabled state if necessary
  }

  toggleDropdown(): void {
    this.show = !this.show;
  }
}
