import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../../BaseComponent';
import { PriceSheet } from 'src/app/buisness-object/pricesheet/PriceSheet';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-reparatur-dialog',
  templateUrl: './reparatur-dialog.component.html',
  styleUrls: ['./reparatur-dialog.component.css']
})
export class ReparaturDialogComponent extends BaseComponent implements OnInit {
  public show = false;
  public title: string;
  public message: string;
  public btnCancelTxt: string;
  public btnSubmitTxt: string;
  public submitValue: any;
  public typ: any;
  public subscription: Subscription;
  public priceSheet: PriceSheet;
  public price;
  public sum;
  public is_nachkalkulation = false;

  constructor(
    private dialogService: DialogService,
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
    this.subscription = this.dialogService.openDialogReparatur$.subscribe((data: any) => {
      this.show = true;
      this.priceSheet = data.price_sheet;
      this.is_nachkalkulation = data.is_nachkalkulation;
      this.getSumm();
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getSumm() {
    let az = 0;
    let mat = 0;
    let reifen = 0;
    let lack = 0;
    let typ = 0;
    if(!this.is_nachkalkulation){
      for(let rep of this.priceSheet.reparaturen){
        az += rep.az;
        mat += rep.mat;
        reifen += rep.reifen;
        lack += rep.lackierung;
        typ += rep.typisierung;
      }
    } else {
      for(let rep of this.priceSheet.reparaturen_vorjahr){
        az += rep.az;
        mat += rep.mat;
        reifen += rep.reifen;
        lack += rep.lackierung;
      }
    }
    this.sum = {
      az: az,
      mat: mat,
      reifen: reifen,
      lack: lack,
      typ: typ,
    }
  }
}
