import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Attachment, AttachmentHelper } from 'src/app/buisness-object/attachment/Attachment';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrl: './files.component.css'
})
export class FilesComponent implements OnInit {
  @Input() obj: any;
  @Input() category: number = -1;
  @Output() successDeleteEmitter = new EventEmitter<any>();
  private subscription: any;
  public attachments: Attachment[] = [];
  public firstInit = true;
  

  constructor(
    private attachmentService: AttachmentService,
  ){}

  ngOnInit(): void {
    this.setUp();
    this.subscription = this.attachmentService.categoryChange$.subscribe((event) => {
      if(event && (event.newCategory === this.category || event.oldCategory === this.category || this.category === -1)) {
        this.setUp();
      }
    })
  }

  ngOnChanges(changes: SimpleChange): void {
    if(changes['obj'] &&
      changes['obj'].currentValue != changes['obj'].previousValue
    ){
      this.setUp();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setUp() {
    if(this.obj){
      let typ =  AttachmentHelper.getAttachmentTyp(this.obj);
      let typID =  AttachmentHelper.getAttachmentTypID(this.obj);
      if(typ && typID){
        if(this.category !== -1){
          this.attachmentService.getAttachmentsByTypIdCategory(typ, typID, this.category).subscribe((result) => {
            if(result){
              this.attachments = result;
            }
          })
        } else {
            this.attachmentService.getAttachmentsByTypId(typ, typID).subscribe((result) => {
              if(result){
                this.attachments = result;
              }
            })
        }
      }
    }
    this.firstInit = false;
  }

  successCreate(attachment: Attachment) {
    this.attachments.push(attachment);
  }

  successDelete(attachment: Attachment) {
    let index = this.attachments.findIndex((a) => a.attachment_id == attachment.attachment_id);
    if(index > -1){
      this.attachments.splice(index,1);
    }
  }
}
