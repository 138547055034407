<div class="car_create_page_wrapper">
    <div class="title">Fotoupload</div>
    <div id="main_container">
        <div id="container1">
            <div *ngIf="false" id="name-container">
                <div class="name">remove.bg</div>
                <label class="switch">
                    <input type="checkbox">
                    <span class="slider round"></span>
                </label>
            </div>
            <div id="upload_box" class="hover_change" appDnd (fileDroppedEmitter)="onFileDropped($event)">
                <div *ngIf="!titlePicture" class="upload_box_info">
                    <img class="upload_box_info_img" src="../../../../../assets/upload.svg">
                    <div class="upload_box_info_lbl">Ziehen Sie Dateien hier her, um sie hochzuladen.</div>
                </div>
                <!-- <div *ngIf="!titlePicture"  class="progress">
                    <div class="progressbar" role="progressbar">
                        <div class="progressbar_inner" [style.width.%]="uploadProgress"></div>
                </div> -->
                <!-- <div *ngIf="titlePicture" id="help-container">
                    <img class="img-help" src="../../../../../assets/icon-info-white.png">
                    <div class="help-txt">Wenn Sie mit remove.bg ein Titelbild wählen, wird dieses als konvertiert gezählt.</div>
                </div> -->
                <img *ngIf="titlePicture" id="selected_img" [src]="titlePicture.src">
                <div *ngIf="titlePicture" id="button-container">
                    <button id="choose">Titelbild</button>
                    <!-- <button id="delete" (click)="removeImage(titlePicture)">Löschen</button> -->
                </div>
            </div>
            <button class="btn_dark_light" (click)="selectFile.click()">Dateien auswählen</button>
            <input #selectFile type="file" accept="image/*" capture="environment" (change)="uploadFile($event)" multiple="" style="display: none;">
        </div>
        <div *ngIf="gallery.length == 0" class="uploaded_info_box">
            <div class="upload_box_info_lbl">Hochgeladene Fotos erscheinen hier.</div>
            <div class="upload_box_info_lbl">Sie können dann Ihr Titelbild wählen.</div>
        </div>
        <div id="container2">
          <div *ngFor="let pic of gallery">
            <div class="single_image_wrapper">
              <img class="image" class="img-car" [src]="pic.src" (click)="setAsTitle(pic)">
              <div class="image_remove_icon" style="left: 92%" (click)="removeImage(pic)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                    <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                    <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>

<!-- <div id="loader" *ngIf="uploadProgress > 0">
  <div id="loader_dialog">
    <div id="loader_lbl">{{uploadProgress}}%</div>
    <div id="loader_bar" [ngStyle]="{'width': getProgress()}"></div>
  </div>
</div> -->
