<form id="login-page-form-wrapper" [formGroup]="loginForm">
  <img id="login-page-logo" src="./assets/logomenu.svg"/>
  <div id="login-page-form-title">{{showForgotPasswordForm ? 'Passwort vergessen ?' : 'Jetzt einloggen und loslegen'}}</div>
  <div id="login-page-form">
      <div class="login-page-form-input-wrapper" *ngIf="!showForgotPasswordForm" >
        <input type="text" placeholder="Username" formControlName="username" [class.input_invalid]="submitted && loginForm.controls['username'].errors">
        <div class="input-icon-right-wrapper">
          <input [type]="showPassword ? 'text' : 'password'" placeholder="Passwort" formControlName="password" [class.input_invalid]="submitted && loginForm.controls['password'].errors">
          <img (click)="showPassword = !showPassword" class='eye-img' [src]="showPassword ? '/assets/eye-show.png' : '/assets/eye-hide.png'">
        </div>
        <button class="btn_label_blue" type="button" (click)="forgotPasswordAction()">Passwort vergessen</button>
      </div>
      <div class="login-page-form-input-wrapper" *ngIf="showForgotPasswordForm && !sendMailSuccess">
        <input type="text" placeholder="Username" formControlName="username_reset" [class.input_invalid]="submitted && loginForm.controls['username_reset'].errors">
      </div>
      <div class="login-page-form-input-wrapper" *ngIf="showForgotPasswordForm && sendMailSuccess">
        <label>Ihnen wurde eine E-Mail zugesendet.<br>Klicken Sie auf den beigelegten Link um Ihr Kennwort zurückzusetzen.</label>
      </div>
  </div>
  <div class="btn-login-container" *ngIf="!showForgotPasswordForm && !sendMailSuccess">
    <button class="btn_submit_dark" type="button" (click)="onSubmit()">Login</button>
    <label class="lbl_error" [ngStyle]="{'opacity': submitted && accessDenied ? 1 : 0}">Anmeldung fehlgeschlagen.</label>
  </div>
  <div class="btn-login-container" *ngIf="showForgotPasswordForm && !sendMailSuccess">
    <button class="btn_submit_dark" type="button" (click)="sendMail()">Absenden</button>
    <button class="btn_label" type="button" (click)="cancel()">zurück</button>
  </div>
  <div class="btn-login-container" *ngIf="showForgotPasswordForm && sendMailSuccess">
    <button class="btn_submit_dark" (click)="goToLogin()">Zum Login</button>
  </div>
  <label id="version">{{version}}</label>
</form>
