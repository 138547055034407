<div id="edit_description_page">
  <div class="data_box">
    <h3>Fahrzeugbeschreibung</h3>
    <app-richtext-editor
      [editorId]="1"
      [activateBold]="true"
      [activateItalic]="false"
      [activateStrikeThrougth]="false"
      [activateUnderline]="false"
      [control]="this.form.controls.description"
    ></app-richtext-editor>
  </div>
  <div class="data_box">
    <h3>Sonderausstattung</h3>
    <app-richtext-editor
      [editorId]="2"
      [activateBold]="true"
      [activateItalic]="false"
      [activateStrikeThrougth]="false"
      [activateUnderline]="false"
      [control]="this.form.controls.addEquipment"
    ></app-richtext-editor>
  </div>
  <!-- <div class="data_box">
    <h3>Sonderausstattung</h3>
    <div #textField_2 style="white-space: pre-line" class="message_box" contenteditable maxlength="5" (paste)="onPaste($event, 2)" (input)="this.form.controls.addEquipment.setValue(textField_2.innerText)"></div>
    <div class="pull_tab"></div>
  </div> -->
  <div class="data_box">
    <h3>Interne Bemerkung</h3>
    <div #textField_3 style="white-space: pre-line" class="message_box" contenteditable (paste)="onPaste($event, 3)" (input)="this.form.controls.internal_description.setValue(textField_3.innerText)"></div>
    <div class="pull_tab"></div>
  </div>
  <div class="data_box" *ngIf="authService.isSinhuber()">
    <h3>Buchhaltungsbeschreibung</h3>
    <div #textField_4 style="white-space: pre-line" class="message_box" contenteditable (paste)="onPaste($event, 4)" (input)="this.form.controls.accounting_description.setValue(textField_4.innerText)"></div>
    <div class="pull_tab"></div>
  </div>
  <!-- <div class="data_box">
    <h3>Sonderausstattung</h3>
    <app-richtext-editor
      [editorId]="2"
      [activateBold]="true"
      [activateItalic]="false"
      [activateStrikeThrougth]="false"
      [activateUnderline]="false"
      [control]="this.form.controls.addEquipment"
    ></app-richtext-editor>
  </div>
  <div class="data_box">
    <h3>Interne Bemerkung</h3>
    <app-richtext-editor
      [editorId]="3"
      [activateBold]="true"
      [activateItalic]="false"
      [activateStrikeThrougth]="false"
      [activateUnderline]="false"
      [control]="this.form.controls.internal_description"
    ></app-richtext-editor>
  </div>
  <div class="data_box">
    <h3>Buchhaltungsbeschreibung</h3>
    <app-richtext-editor
      [editorId]="3"
      [activateBold]="true"
      [activateItalic]="false"
      [activateStrikeThrougth]="false"
      [activateUnderline]="false"
      [control]="this.form.controls.internal_description"
    ></app-richtext-editor>
  </div> -->
  <!-- <div class="data_box">
    <h3>Fahrzeugbeschreibung</h3>
    <button id="btn_bold" type="button" class="btn_textfield_action" [class.btn_textfield_action_active]="textFormat?.style == textStyle.BOLD" >F</button>
    <div #textField_1 id="contenteditable_one" style="white-space: pre-line" class="message_box" contenteditable (paste)="onPaste($event, 1)" (input)="onInput($event, 1)"></div>
    <div class="pull_tab"></div>
  </div> -->
</div>
