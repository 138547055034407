import { Component, OnInit } from '@angular/core';
import { DropdownElement } from 'src/app/buisness-object/overview/layout/DropdownElement';

@Component({
  selector: 'app-element-dropdown',
  templateUrl: './element-dropdown.component.html',
  styleUrls: ['./element-dropdown.component.css']
})
export class ElementDropdownComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }


}
