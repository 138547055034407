<div *ngIf="showView == view.LIST">
    <div class="header-base-style">
      <div class="header-inner-wrapper">
        <button class="button_type_1" (click)="showDropdownMainButton = !showDropdownMainButton" appDropdown [show]="showDropdownMainButton" (changedValueEmitter)="showDropdownMainButton = $event">
          Neu
          <div *ngIf="showDropdownMainButton">        
            <app-restricted-button
              [name]="'Kurzzeitmiete'"
              [access]="'rent_edit'"
              [isPrimary]="true"
              [isDropdownButton]="true"
              (clickEmitter)="showView = view.CREATE; showDropdownMainButton = false; rentType = 2">
            </app-restricted-button>
            <app-restricted-button
              [name]="'Miet-Abo'"
              [access]="'rent_edit'"
              [isPrimary]="true"
              [isDropdownButton]="true"
              (clickEmitter)="showView = view.CREATE; showDropdownMainButton = false; rentType = 1">
            </app-restricted-button>
          </div>
        </button>

        <div id="main-menu-wrapper">
          <button *ngFor="let mainMenu of mainMenus"
            [class.main-menu-btn-selected]="mainMenu.getActive()"
            (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive())">
            {{mainMenu.name}}
          </button>
        </div>
      </div>
      <div>
      </div>
    </div>
    <app-list-container *ngIf="_activeSubMenu.getValue()"
        [objectTyp]="'rent'"
        [objects]="_objectsFiltered.getValue()"
        [pageHandler]="pageHandler"
        [filter]="filter"
        [mainMenus]="mainMenus"
        [activeSubMenu$]="activeSubMenu$"
        [activeSortingColumn]="activeSortingColumn"
        (pageEmitter)="pageHandling($event)"
        (applySortingEmitter)="applySorting($event)"
        (openDetailsEmitter)="selectionDetails($event)"
        (openEditEmitter)="selectionEdit($event)"
        (deleteEmitter)="selectionDelete($event)"
    ></app-list-container>
  </div>

  <app-miete-create *ngIf="showView == view.CREATE"
    [obj]="selectedObject"
    [vehicles]="vehicles"
    [type]="rentType"
    (selectionCancelEmitter)="selectionCancelCreate()"
    (updateSuccessEmitter)="updateSuccess($event)"
    (createSuccessEmitter)="createSuccess($event)"
  ></app-miete-create>

  <app-miete-details *ngIf="showView == view.DETAILS"
    [obj]="selectedObject"
    (selectionCloseEmitter)="selectionCloseDetails()"
    (selectionEditEmitter)="selectionEdit($event)"
    (selectionReceiveEmitter)="selectionReceive($event)"
    (selectionReturnEmitter)="selectionReturn($event)"
    (slectionDocumentEmitter)="selectionDocument()"
    (selectionCancelRentEmitter)="selectionCancelRent()"
    (selectionSendEmailEmitter)="selectionSendEmail()"
    (updateStatusEmitter)="updateRent($event)"
  ></app-miete-details>


  <app-dialog-miete-return *ngIf="showDialogReturn"
    [obj]="selectedObject"
    (selectionCloseEmitter)="selectionCloseReturn()"
    (updateSuccessEmitter)="updateSuccess($event)"
    (selectionSubmitImageEmitter)="selectionSubmitImages($event)"
  ></app-dialog-miete-return>

  <app-dialog-miete-receive *ngIf="showDialogReceive"
    [obj]="selectedObject"
    (selectionCloseEmitter)="selectionCloseReceive()"
    (updateSuccessEmitter)="updateSuccess($event)"
    (selectionSubmitImageEmitter)="selectionSubmitImages($event)"
  ></app-dialog-miete-receive>

  <app-dialog-miete-cancel *ngIf="showDialogCancel"
    (selectionCloseEmitter)="selectionCloseCancelRent()"
    (selectionCancelEmitter)="selectionStornoRent($event)"
  ></app-dialog-miete-cancel>

  <app-dialog-miete-send-email *ngIf="showDialogEmail"
  [obj]="selectedObject"
  (selectionCloseEmitter)="selectionCloseSendEmail()"
  (selectionSubmitEmailEmitter)="selectionSubmitEmail()"
  ></app-dialog-miete-send-email>