<div [attr.id]="'file_'+attachment.attachment_id" class="file" [class.file-border]="!isLast">
  <div class="file-content-wrapper" (click)="selectionDetails()">
    <div [ngClass]="colorClass">
      <img src="./assets/document-grey.svg">
    </div>
    <div class="file-info-wrapper">
      <label>{{attachment.filename}}</label>
      <label class="italic-small" *ngIf="attachment.creator_name">{{attachment.creator_name ? "Hochgeladen von: " + attachment.creator_name : attachment.creator_name}}</label>
    </div>
  </div>
  <app-list-action-container
    [actions]="['details','edit','download','delete']"
    [index]="index"
    [maxIndex]="100"
    [object]="attachment"
    [isListView]="false"
    [listItemId]="identifier"
    (selectionOpenDetailsEmitter)="selectionDetails()"
    (selectionDownloadEmitter)="selectionDownload()"
    (selectionDeleteEmitter)="selectionDelete()"
    (selectionOpenDetailsEditEmitter)="selectionEdit()"
  ></app-list-action-container>
</div>

<app-dialog-doc-upload 
  *ngIf="showUploadDialog" 
  [isCreate]="false"
  [showCategories]="parentCategory !== -1 ? true : false"
  [attachment]="attachment" 
  (dialogCloseEmitter)="showUploadDialog = false" 
  (uploadDocumentEmitter)="showUploadDialog = false"
></app-dialog-doc-upload>
