<div class="car_create_page_wrapper">
    <div class="title">Basisdaten</div>
    <div class="info">Der Titel Ihres Fahrzeuges ergibt sich aus Marke, Modell & Ausführung.</div>
    <form id="input-container" [formGroup]="formGroup" autocomplete="off">
        <div id="container1">
            <div class="block">
                <h3 class="inner_title">Model</h3>
                <app-dropdown-objects-search
                  [title]="'Marke *'"
                  [placeholder]="'Marke'"
                  [headervalue]="formGroup.controls.brand.value?.makeName"
                  [formcontrol]="formGroup.controls.brand"
                  [values]="brandValues"
                  [submitted]="submitted"
                  (submitValueEmitter)="setValueFromDropdown('brand', $event)"
                ></app-dropdown-objects-search>
                <app-dropdown-objects-search
                  [title]="'Model *'"
                  [placeholder]="'Model'"
                  [headervalue]="formGroup.controls.model.value?.name"
                  [formcontrol]="formGroup.controls.model"
                  [values]="modelValues"
                  [submitted]="submitted"
                  [disabled]="formGroup.controls.brand.value == null"
                  (submitValueEmitter)="setValueFromDropdown('model', $event)"
                ></app-dropdown-objects-search>
                <div class="input_wrapper">
                  <label class="overview_record_lbl" style="align-self: flex-start;">Version *</label>
                  <div class="version_container">
                      <input type="text" maxlength="80" placeholder="Version" formControlName="version" [class.input_invalid]="submitted && f['version'].errors" (input)="setVersionTitle()">
                      <span class="counter_lbl lbl_regular_italic_light_color">({{counterVersion}} Zeichen übrig)</span>
                  </div>
                </div>
                <app-dropdown-simple
                  [title]="'Unfallfahrzeug *'"
                  [formcontrol]="formGroup.controls.unfall"
                  (submitValueEmitter)="formGroup.controls.unfall.setValue($event)"
                ></app-dropdown-simple>
            </div>
        </div>
        <div id="container2">
            <div class="block">
                <h3 class="inner_title">Antrieb</h3>
                <app-dropdown-objects
                  [title]="'Treibstoff *'"
                  [placeholder]="'Treibstoff'"
                  [headervalue]="f.fuel.value?.name"
                  [formcontrol]="f.fuel"
                  [values]="fuelsValues"
                  [submitted]="submitted"
                  (submitValueEmitter)="setValueFromDropdown('fuel', $event)"
                ></app-dropdown-objects>
                <app-dropdown-objects
                  [title]="'Antriebsart *'"
                  [placeholder]="'Antriebsart'"
                  [headervalue]="f.drive.value?.name"
                  [formcontrol]="f.drive"
                  [values]="drivesValues"
                  [submitted]="submitted"
                  (submitValueEmitter)="setValueFromDropdown('drive', $event)"
                ></app-dropdown-objects>
                <app-dropdown-objects
                  [title]="'Getriebeart *'"
                  [placeholder]="'Getriebeart'"
                  [headervalue]="f.transmission.value?.name"
                  [formcontrol]="f.transmission"
                  [values]="transmissionsValues"
                  [submitted]="submitted"
                  (submitValueEmitter)="setValueFromDropdown('transmission', $event)"
                ></app-dropdown-objects>
            </div>
        </div>
        <div id="container3">
            <div class="block" style="margin-bottom: 0;">
                <h3 class="inner_title">Merkmale</h3>
                <app-dropdown-objects
                  [title]="'KFZ-Typ *'"
                  [placeholder]="'KFZ-Typ'"
                  [headervalue]="f.bodyTyp.value?.name"
                  [formcontrol]="f.bodyTyp"
                  [values]="bodytypValues"
                  [submitted]="submitted"
                  (submitValueEmitter)="setValueFromDropdown('bodytyp', $event)"
                ></app-dropdown-objects>
                <app-dropdown-objects
                  [title]="'Fahrzeugart *'"
                  [placeholder]="'Fahrzeugart'"
                  [headervalue]="f.category.value?.name"
                  [formcontrol]="f.category"
                  [values]="categorieValues"
                  [submitted]="submitted"
                  (submitValueEmitter)="setValueFromDropdown('category', $event)"
                ></app-dropdown-objects>
                <div class="input_wrapper" *ngIf="f.category.value && f.category.value.id != 1">
                  <div class="overview_record_lbl">Erstzulassung *</div>
                  <input #inputDate1 type="date" placeholder="date" [min]="minDate" [max]="maxDate" formControlName="firstRegistrationDate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && f.firstRegistrationDate.errors">
                </div>
                <div class="input_wrapper">
                  <label class="overview_record_lbl">Türen *</label>
                  <input type="number" placeholder="Türen" formControlName="numOfDoors" [class.input_invalid]="submitted && f['numOfDoors'].errors">
                </div>
                <div class="input_wrapper">
                  <div class="overview_record_lbl">Sitze *</div>
                  <input type="number" placeholder="Sitze" formControlName="numOfSeats" [class.input_invalid]="submitted && f['numOfSeats'].errors">
                </div>
            </div>
        </div>
        <div id="container4">
          <div class="block">
            <h3 class="inner_title">Farbe</h3>
            <app-dropdown-objects
              [title]="'Hauptfarbe *'"
              [placeholder]="'Hauptfarbe'"
              [headervalue]="f.bodyColor.value?.name"
              [formcontrol]="f.bodyColor"
              [values]="bodyColorValues"
              [submitted]="submitted"
              (submitValueEmitter)="setValueFromDropdown('bodyColor', $event)"
            ></app-dropdown-objects>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Farbbezeichnung</div>
              <input type="text" placeholder="Farbbezeichnung" formControlName="color_designation"  [class.input_invalid]="submitted && f['color_designation'].errors">
            </div>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Farbcode</div>
              <input type="text" placeholder="Farbcode" formControlName="color_code"  [class.input_invalid]="submitted && f['color_code'].errors">
            </div>
          </div>
        </div>
    </form>
</div>
