<div class="attachment">
  <div class="attachment_innder_flex" (click)="openFile()" [ngStyle]="{'cursor': source.format == 'pdf' || source.format == 'jpg' || source.format == 'JPG' || source.format == 'png' || source.format == 'PNG' || source.format == 'jpng' ? 'pointer' : ''}">
    <img class="attachment_icon" src="../../../../../assets/documentcolor.svg">
    <div class="overview_record_value">{{source.name}}.{{source.format}}</div>
  </div>
  <div #dropdown class="btn_action_wrapper">
    <img class="btn_actions" src="../../../../../assets/navdots.svg"
    (click)="this.showDropdown = !this.showDropdown"
    [ngClass]="showDropdown ? 'btn_actions_active' : ''">
    <div class="dropdown_actions_container" *ngIf="showDropdown">
        <div *ngIf="source.format == 'pdf'" class="option" (click)="openViewer(); this.showDropdown = false">
            <div class="dropdown_txt">Öffnen</div>
            <img class="option_icon" src="../../../../../assets/eye-white.svg" >
        </div>
        <div *ngIf="source.format == 'jpg' || source.format == 'JPG' || source.format == 'png' || source.format == 'PNG' || source.format == 'jpng'" class="option" (click)="openImageSlider(); this.showDropdown = false">
          <div class="dropdown_txt">Öffnen</div>
          <img class="option_icon" src="../../../../../assets/eye-white.svg" >
       </div>
        <div class="option" (click)="getFile(); this.showDropdown = false">
            <div class="dropdown_txt">Herunterladen</div>
            <img class="option_icon" style="transform: rotate(90deg);" src="../../../../../assets/direction.svg" >
        </div>
        <!-- <div class="option" (click)="signPDFEmitter.emit(source)">
          <div class="list_action_value_lbl">App Signatur</div>
          <img class="list_action_value_icon" src="../../../../../assets/edit.svg" >
        </div> -->
        <div *ngIf="showDelete" class="option" (click)="deleteAttachment(); this.showDropdown = false">
          <div class="dropdown_txt">Löschen</div>
          <img class="option_icon" src="../../../../../assets/delete.svg" >
        </div>
    </div>
  </div>
</div>
