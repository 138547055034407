import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Thread } from 'src/app/buisness-object/message/Thread';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';

@Component({
  selector: 'app-overview-messages',
  templateUrl: './overview-messages.component.html',
  styleUrls: ['./overview-messages.component.css']
})
export class OverviewMessagesComponent implements OnInit {
  @Input() vehicle: Vehicle;
  public userId = localStorage.getItem('employee_id')

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  goToThread(thread: Thread) {
    this.router.navigate(['messages'], { queryParams: { ticketId: thread.threadId, vehicleId: this.vehicle.id } });
  }
}
