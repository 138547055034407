<div class="data_container">
    <div class="lbl_bold_light_color">Detailsansicht Rechnung</div>
    <div class="body">
      <div class="column">
        <div class="list_title_lbl">Betreff</div>
        <div class="lbl_regular_bold">{{document.title}}</div>
        <div class="lbl_regular_italic_light_color" style="margin-bottom: 40px;">Rechnungsnummer: {{document.bill_id}}</div>
        <div class="list_title_lbl">Kunde</div>
        <div class="lbl_regular_bold">{{document.contact_person.lastName}} {{document.contact_person.lastName}}, {{document.contact_person.title}}</div>
        <div class="lbl_regular_italic_light_color">Kunden-ID: {{document.customer.id}}</div>
      </div>
      <div class="column">
        <div class="list_title_lbl">Rechnungsgegenstände</div>
        <div class="header_item">
          <div class="lbl_regular_italic_light_color">POS</div>
          <div class="lbl_regular_italic_light_color">Beschreibung</div>
          <div class="lbl_regular_italic_light_color">Menge</div>
          <div class="lbl_regular_italic_light_color">Preis</div>
          <div class="lbl_regular_italic_light_color">Rabatt</div>
        </div>
        <div *ngFor="let item of document.bill_items" class="item">
          <div class="lbl_list_data">{{item.item_id}}</div>
          <div class="lbl_list_data" style="font-weight: 600;">{{item.title}}</div>
          <div class="lbl_list_data">{{item.base_value}}</div>
          <div class="lbl_list_data">{{item.price | currency: 'EUR'}}</div>
          <div class="lbl_list_data">{{item.total_discount ? (item.total_discount | currency: 'EUR') : ('0' | currency: 'EUR')}}</div>
        </div>
        <div>
          <div></div>
          <div class="lbl_list_data" style="font-weight: 600;">Gesamtsumme</div>
          <div></div>
          <div class="lbl_list_data">{{grandTotal | currency: 'EUR'}}</div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
