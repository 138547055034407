<form class="page_body" [formGroup]="form">
  <div class="inputfield_wrapper">
    <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.title?.value ? '1' : '0'}">Betreff</div>
    <input type="text" class="inputfield" [ngClass]="submitted && form.controls.title.invalid ? 'inputfield_invalid' : ''" placeholder="Betreff *" formControlName="title">
  </div>
  <div #dropdown class="dropdown_wrapper">
    <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.customer.value ? '1' : '0'}">Kunde</div>
    <div class="dropdown_header_data" [ngClass]="submitted && form.controls.customer.invalid ? 'dropdown_header_data_invalid' : ''" (click)="showDropdown_1 = !showDropdown_1">
        <div class="dropdown_header_data_lbl" [ngClass]="!form.controls.customer.value ? 'dropdown_header_lbl_placeholder' : ''">{{form.controls.customer.value ? form.controls.customer.value.contactPerson.firstName + ' ' + form.controls.customer.value.contactPerson.lastName : 'Kunde *'}}</div>
        <img class="dropdown_header_icon" [ngClass]="showDropdown_1 ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
    </div>
    <div class="dropdown_data_body" *ngIf="showDropdown_1">
        <div *ngFor="let customer of customers" class="dropdown_data_body_element" (click)="setCustomer(customer); showDropdown_1 = false">{{customer.contactPerson.firstName + ' ' + customer.contactPerson.lastName}}</div>
    </div>
  </div>
</form>
