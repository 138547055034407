import { formatDate } from '@angular/common';
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { VehicleBodyColor } from 'src/app/buisness-object/vehicle/basic/VehicleBodyColor';
import { VehicleBodyTyp } from 'src/app/buisness-object/vehicle/basic/VehicleBodyTyp';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { VehicleDrive } from 'src/app/buisness-object/vehicle/basic/VehicleDrive';
import { VehicleFuel } from 'src/app/buisness-object/vehicle/basic/VehicleFuel';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { VehicleTransmission } from 'src/app/buisness-object/vehicle/basic/VehicleTransmission';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-vin-missing-data-container',
  templateUrl: './vin-missing-data-container.component.html',
  styleUrls: ['./vin-missing-data-container.component.css']
})
export class VinMissingDataContainerComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() brands: VehicleBrand[];
  @Input() drives: VehicleDrive[];
  @Input() fuels: VehicleFuel[];
  @Input() transmissions: VehicleTransmission[];
  @Input() bodyColors: VehicleBodyColor[];
  @Input() bodyTyps: VehicleBodyTyp[];
  @Input() categories: VehicleCategory[];
  @Output() saveAsDraftEmitter = new EventEmitter<boolean>();

  public models: VehicleModel[] = [];

  public brandValues = [];
  public modelValues = [];
  public bodytypValues = [];
  public categorieValues = [];
  public transmissionsValues = [];
  public fuelsValues = [];
  public drivesValues = [];
  public bodyColorValues = [];

  public minDate: string;
  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');

  public valuesInvalid;

  constructor(
    public authService: AuthenticationService,
    public eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    for(let categorie of this.categories){
      if(categorie.id == 2){
        this.form.controls["category"].setValue(categorie);
        break;
      }
    }
    for(let brand of this.brands){
      if(brand.id == this.form.controls["brand"].value?.id){
        this.models = brand.models;
      }
    }
    this.valuesInvalid = {
      bodyTyp : this.form.controls['bodyTyp'].errors,
      category : this.form.controls['category'].errors,
      bodyColor : this.form.controls['bodyColor'].errors,
      fuel : this.form.controls['fuel'].errors,
      drive : this.form.controls['drive'].errors,
      transmission : this.form.controls['transmission'].errors,
      mileageKm : this.form.controls['mileageKm'].errors,
      firstRegistrationDate : this.form.controls['firstRegistrationDate'].errors,
      numOfDoors : this.form.controls['numOfDoors'].errors,
      numOfSeats : this.form.controls['numOfSeats'].errors,
      power : this.form.controls['power'].errors,
      sprit : this.form.controls['sprit'].errors,
      lieferant_extern : this.form.controls['lieferant_extern'].errors,
    }
    this.requireControl("vehicle_imported");
    this.form.controls["vehicle_imported"].setValue(false);
    this.requireControl("steuersatz_pricesheet");
    let possibleValues = ["einkaufsdatum", "einkaufspreis_brutto", "sprit", "lieferant_extern", "vehicle_supplier"];
    for(let value of possibleValues){
      if(this.authService.showCreateValue(value)){
        this.requireControl(value);
      }
    }
    this.setDropdownValues();

  }


  requireControl(controlName: string) {
    if(controlName == "einkaufspreis_brutto") {
      controlName = "einkaufspreis_pricesheet";
    }
    this.form.controls[controlName].clearValidators();
    this.form.controls[controlName].updateValueAndValidity();
    this.form.controls[controlName].setValidators(Validators.required);
  }

  ngAfterViewInit(): void {
    let dataBlock = document.getElementById('data-package');
    if(dataBlock){
      if(dataBlock.offsetHeight > 350){
        dataBlock.setAttribute('style',
          'display: grid;'+
          'grid-template-columns: 1fr 1fr;'+
          'column-gap: 38px;'+
          'row-gap: 5px;'
        );
      }
    }
  }

  ngOnChanges(): void {

  }

  setDropdownValues() {
    this.bodytypValues = [];
    this.fuelsValues = [];
    this.drivesValues = [];
    this.transmissionsValues = [];
    this.bodyColorValues = [];
    for(let v of this.bodyTyps){
      this.bodytypValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.categories){
      this.categorieValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.fuels){
      this.fuelsValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.drives){
      this.drivesValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.transmissions){
      this.transmissionsValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.bodyColors){
      this.bodyColorValues.push({
        id: v.id,
        name: v.name
      })
    }
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'bodytyp'){
      let index = this.bodyTyps.findIndex(v => v.id == id);
      if(index > -1) this.form.controls["bodyTyp"].setValue(this.bodyTyps[index]);
    } else if(value === 'category'){
      let index = this.categories.findIndex(v => v.id == id);
      if(index > -1){
        this.form.controls["category"].setValue(this.categories[index]);
        this.form.controls["firstRegistrationDate"].setValue(null);
        this.calcRegistrationDate();
      }
    } else if(value === 'bodyColor'){
      let index = this.bodyColors.findIndex(v => v.id == id);
      if(index > -1) this.form.controls["bodyColor"].setValue(this.bodyColors[index]);
    } else if(value === 'fuel'){
      let index = this.fuels.findIndex(v => v.id == id);
      if(index > -1) this.form.controls["fuel"].setValue(this.fuels[index]);
    } else if(value === 'drive'){
      let index = this.drives.findIndex(v => v.id == id);
      if(index > -1) this.form.controls["drive"].setValue(this.drives[index]);
    } else if(value === 'transmission'){
      let index = this.transmissions.findIndex(v => v.id == id);
      if(index > -1) this.form.controls["transmission"].setValue(this.transmissions[index]);
    }
  }

  calcRegistrationDate() {
    if(this.form.controls["category"].value.id == 1){
      this.form.controls["firstRegistrationDate"].setValue(null);
    } else if(this.form.controls["category"].value.id == 6){
      let t = new Date();
      t.setMonth(t.getMonth() - 12);
      this.minDate = formatDate(t,'yyyy-MM-dd','de');
    } else if(this.form.controls["category"].value.id == 3) {
      let t = new Date();
      t.setMonth(t.getMonth() - 24);
      this.minDate = formatDate(t,'yyyy-MM-dd','de');
    } else {
      this.minDate = formatDate(new Date(0),'yyyy-MM-dd','de');
    }
  }

  setSteuersatz(value: number) {
    this.form.controls['steuersatz_pricesheet'].setValue(value);
  }

  submit() {
    this.saveAsDraftEmitter.emit();
  }
}
