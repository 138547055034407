<div id="dialog_window" *ngIf="show">
  <div id="dialog_container" appDropdown [show]="show" (changedValueEmitter)="show = $event" *ngIf="!is_nachkalkulation">
    <div id="dialog_close_icon" (click)="show = false">&#10005;</div>
    <div class="data_box" id="reparatur_details_box">
      <div id="reparatur_details_box_header">
        <div></div>
        <h3>Renr.</h3>
        <h3>AZ</h3>
        <h3>MAT</h3>
        <h3>Reifen</h3>
        <h3>Lackierung</h3>
        <h3>Typisierung</h3>
        <div></div>
      </div>
      <div id="reparatur_details_box_form_body">
        <div *ngFor="let rep of priceSheet.reparaturen; let i = index">
          <div id="reparatur_details_form">
            <h3>{{rep.name}}</h3>
            <label>{{rep.renr}}</label>
            <label>{{getInputValueNumber(rep.az)}}</label>
            <label>{{getInputValueNumber(rep.mat)}}</label>
            <label>{{getInputValueNumber(rep.reifen)}}</label>
            <label>{{getInputValueNumber(rep.lackierung)}}</label>
            <label>{{getInputValueNumber(rep.typisierung)}}</label>
          </div>
        </div>
      </div>
      <div id="reparatur_details_box_footer">
        <div></div>
        <div></div>
        <label>{{getInputValueNumber(sum.az)}}</label>
        <label>{{getInputValueNumber(sum.mat)}}</label>
        <label>{{getInputValueNumber(sum.reifen)}}</label>
        <label>{{getInputValueNumber(sum.lack)}}</label>
        <label>{{getInputValueNumber(sum.typ)}}</label>
      </div>
    </div>
  </div>
  <div id="dialog_container" appDropdown [show]="show" (changedValueEmitter)="show = $event" *ngIf="is_nachkalkulation">
    <div id="dialog_close_icon" (click)="show = false">&#10005;</div>
    <div class="data_box" id="reparatur_details_box">
      <div id="reparatur_details_box_header">
        <div></div>
        <h3>Renr.</h3>
        <h3>AZ</h3>
        <h3>MAT</h3>
        <h3>Reifen</h3>
        <h3>Lackierung</h3>
        <div></div>
      </div>
      <div id="reparatur_details_box_form_body">
        <div *ngFor="let rep of priceSheet.reparaturen_vorjahr; let i = index">
          <div id="reparatur_details_form">
            <h3>{{rep.name}}</h3>
            <label>{{rep.renr}}</label>
            <label>{{getInputValueNumber(rep.az)}}</label>
            <label>{{getInputValueNumber(rep.mat)}}</label>
            <label>{{getInputValueNumber(rep.reifen)}}</label>
            <label>{{getInputValueNumber(rep.lackierung)}}</label>
          </div>
        </div>
      </div>
      <div id="reparatur_details_box_footer">
        <div></div>
        <div></div>
        <label>{{getInputValueNumber(sum.az)}}</label>
        <label>{{getInputValueNumber(sum.mat)}}</label>
        <label>{{getInputValueNumber(sum.reifen)}}</label>
        <label>{{getInputValueNumber(sum.lack)}}</label>
      </div>
    </div>
  </div>
</div>
