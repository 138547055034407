<app-collapsible [isCollapsible]="!editMode && viewAuth.view.collapsible" [isContentVisible]="collapsed">
  <h3 collapsible-header class="overview_data_box_lbl_title">API</h3>
  <div collapsible-content>
    <div *ngIf="!editMode">
      <div class="overview_record" *ngIf="showValue('website_online')">
        <div class="overview_record_lbl">Export Website</div>
        <div class="overview_record_value" *ngIf="shop_link">
          <a href='https://{{shop_link}}{{vehicle.id}}' class="overview_record_link" target="_blank" *ngIf="vehicle.freigeschaltet_am_web && vehicle.website_online">
            {{(vehicle.website_online && vehicle.freigeschaltet_am_web) ? (vehicle.freigeschaltet_am_web | date: 'dd.MM.yyyy') : "Nein"}}
            <img src="./assets/linksymbol.svg" class="link-icon">
          </a>
          <div class="overview_record_value" *ngIf="!vehicle.freigeschaltet_am_web || !vehicle.website_online">Nein</div>
        </div>
        <div class="overview_record_value" *ngIf="!shop_link">{{(vehicle.website_online && vehicle.freigeschaltet_am_web) ? (vehicle.freigeschaltet_am_web | date: 'dd.MM.yyyy') : "Nein"}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('platform_online')">
        <div class="overview_record_lbl">Export AS24</div>
        <div class="overview_record_value" *ngIf="vehicle.platform_online && vehicle.as_id">
          <a href='https://autoscout24.at/angebote/{{vehicle.as_id}}' class="overview_record_link" target="_blank">
            {{(vehicle.platform_online && vehicle.freigeschaltet_am_plattform) ? (vehicle.freigeschaltet_am_plattform | date: 'dd.MM.yyyy') : "Nein"}}
            <img src="./assets/linksymbol.svg" class="link-icon">
          </a>
        </div>
        <div class="overview_record_value"  *ngIf="!vehicle.as_id">{{(vehicle.platform_online && vehicle.freigeschaltet_am_plattform) ? (vehicle.freigeschaltet_am_plattform | date: 'dd.MM.yyyy') : "Nein"}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('platform_online')">
        <div class="overview_record_lbl">Export WH</div>
        <div class="overview_record_value">{{(vehicle.platform_online && vehicle.freigeschaltet_am_plattform) ? (vehicle.freigeschaltet_am_plattform | date: 'dd.MM.yyyy') : "Nein"}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('mobile_online')">
        <div class="overview_record_lbl">Export Mobile</div>
        <div class="overview_record_value" *ngIf="vehicle.mobile_online && vehicle.mobile_id">
          <a href='https://suchen.mobile.de/fahrzeuge/details.html?id={{vehicle.mobile_id}}' class="overview_record_link" target="_blank">{{getBooleanStringValue(vehicle.mobile_online)}}</a>
        </div>
        <div class="overview_record_value" *ngIf="!vehicle.mobile_online || !vehicle.mobile_id">{{getBooleanStringValue(vehicle.mobile_online)}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('inserat_checked')">
        <div class="overview_record_lbl">Inserat kontrolliert</div>
        <div class="overview_record_value">{{getBooleanStringValue(vehicle.inserat_checked)}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('inserat_info')">
        <div class="overview_record_lbl">Inserat Info</div>
        <div class="overview_record_value">{{vehicle.inserat_info ? vehicle.inserat_info : '---'}}</div>
      </div>
    </div>
    <form [formGroup]="formGroupVehicle" class="overview_data_form" *ngIf="editMode && viewAuth.edit.name">
      <app-dropdown-simple
        *ngIf="showValue('website_online')"
        [title]="'Export Website'"
        [formcontrol]="f.website_online"
        (submitValueEmitter)="f.website_online.setValue($event)"
      ></app-dropdown-simple>
      <app-dropdown-simple
        *ngIf="showValue('platform_online')"
        [title]="'Export Plattformen'"
        [formcontrol]="f.platform_online"
        (submitValueEmitter)="f.platform_online.setValue($event)"
      ></app-dropdown-simple>
      <app-dropdown-simple 
        *ngIf="showValue('mobile_online')"
        [title]="'Export Mobile'"
        [formcontrol]="f.mobile_online"
        (submitValueEmitter)="f.mobile_online.setValue($event)"
      ></app-dropdown-simple>
      <div class="input_wrapper slider_grid"  *ngIf="showValue('inserat_checked')">
        <div class="checkbox_lbl">Inserat kontrolliert</div>
        <label class="switch" [ngStyle]="{'cursor': 'unset'}">
            <input type="checkbox" [checked]="formGroupVehicle.controls['inserat_checked'].value" (click)="formGroupVehicle.controls['inserat_checked'].setValue(!formGroupVehicle.controls['inserat_checked'].value)">
            <span class="slider round"></span>
        </label>
      </div>
      <div class="input_wrapper" *ngIf="showValue('inserat_info')">
        <label class="overview_record_lbl">Inserat Info</label>
        <input type="text" class="overview_input" placeholder="Inserat Info" formControlName="inserat_info">
      </div>
      <div>
        <div class="overview_record"></div>
        <div class="overview_record"></div>
        <div class="overview_record" *ngIf="showValue('website_online')">
          <div class="overview_record_lbl">Export Website</div>
          <div class="overview_record_value" *ngIf="shop_link">
            <a href='https://{{shop_link}}{{vehicle.id}}' class="overview_record_link" target="_blank" *ngIf="vehicle.freigeschaltet_am_web && vehicle.website_online">
              {{(vehicle.website_online && vehicle.freigeschaltet_am_web) ? (vehicle.freigeschaltet_am_web | date: 'dd.MM.yyyy') : "Nein"}}
              <img src="./assets/linksymbol.svg" class="link-icon">
            </a>
            <div class="overview_record_value" *ngIf="!vehicle.freigeschaltet_am_web || !vehicle.website_online">Nein</div>
          </div>
          <div class="overview_record_value" *ngIf="!shop_link">{{(vehicle.website_online && vehicle.freigeschaltet_am_web) ? (vehicle.freigeschaltet_am_web | date: 'dd.MM.yyyy') : "Nein"}}</div>
        </div>
        <div class="overview_record" *ngIf="showValue('platform_online')">
          <div class="overview_record_lbl">Export AS24</div>
          <div class="overview_record_value" *ngIf="vehicle.platform_online && vehicle.as_id">
            <a href='https://autoscout24.at/angebote/{{vehicle.as_id}}' class="overview_record_link" target="_blank">
              {{(vehicle.platform_online && vehicle.freigeschaltet_am_plattform) ? (vehicle.freigeschaltet_am_plattform | date: 'dd.MM.yyyy') : "Nein"}}
              <img src="./assets/linksymbol.svg" class="link-icon">
            </a>
          </div>
          <div class="overview_record_value"  *ngIf="!vehicle.as_id">{{(vehicle.platform_online && vehicle.freigeschaltet_am_plattform) ? (vehicle.freigeschaltet_am_plattform | date: 'dd.MM.yyyy') : "Nein"}}</div>
        </div>
      </div>
    </form>
  </div>
</app-collapsible>
