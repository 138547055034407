<div class="list_container">
  <div class="list_header">
    <div class="header_list_row_1">
      <div></div>
      <div class="page_wrapper">
        <div id="count">{{pageInfo}}</div>
        <div id="arrows" *ngIf="amount > maxAmountListPage">
          <img *ngIf="sliceFrom == 0" class="icon_arrow_left" src="../../../../../assets/arrowlight.svg">
          <img *ngIf="sliceFrom != 0" class="icon_arrow_left list_arrow_active" src="../../../../../assets/arrow.svg" (click)="pageEmitter.emit(false)">
          <img *ngIf="sliceTo == amount" class="icon_arrow_right" src="../../../../../assets/arrowlight.svg">
          <img *ngIf="sliceTo != amount"  class="icon_arrow_right list_arrow_active" src="../../../../../assets/arrow.svg" (click)="pageEmitter.emit(true)">
        </div>
      </div>
    </div>
    <div class="header_list_row_2">
      <div class="header_list_row_2_inner">
        <label *ngIf="filter.customer" class="lbl_filter_value"><button (click)="removeFilterEmitter.emit('customer')">&#10005;</button>{{filter.customer.getName()}}</label>
      </div>
      <div></div>
      <div class="header_list_row_2_inner">
        <label *ngIf="sortObject.sort_after_value == 1" class="lbl_filter_value">Bearbeitet<img (click)="sortObject.sort_asc = !sortObject.sort_asc; applySortEmitter.emit()" [src]="!sortObject.sort_asc ? '../../../../../assets/sortup.svg' : '../../../../../assets/sortdown.svg'"></label>
        <label *ngIf="sortObject.sort_after_value == 2" class="lbl_filter_value">Status<img (click)="sortObject.sort_asc = !sortObject.sort_asc; applySortEmitter.emit()" [src]="!sortObject.sort_asc ? '../../../../../assets/sortup.svg' : '../../../../../assets/sortdown.svg'"></label>
      </div>
    </div>
  </div>
  <div *ngIf="tab == 1 && documents.length > 0" class="list_title">
    <div class="lbl_list_title">Nr.</div>
    <div class="lbl_list_title">Kunde</div>
    <div class="lbl_list_title">Betreff</div>
    <div class="lbl_list_title">Typ</div>
    <div class="lbl_list_title">Status</div>
    <div class="lbl_list_title">Bearbeitet</div>
  </div>
  <div *ngIf="tab == 2 && documents.length > 0" class="list_title_2">
    <div class="lbl_list_title">Nr.</div>
    <div class="lbl_list_title">Kunde</div>
    <div class="lbl_list_title">Fahrzeug</div>
    <div class="lbl_list_title">Preis</div>
    <div class="lbl_list_title">Status</div>
    <div class="lbl_list_title">Bearbeitet</div>
  </div>
  <div *ngIf="tab == 1" id="listcontainer" class="list_element_container" [class.list_element_conatiner_empty]="documents.length == 0">
    <div class="list_no_elements lbl_regular_italic_light_color" *ngIf="documents.length == 0">Keine Dokumente vorhanden.</div>
    <div *ngFor="let document of documents | slice:sliceFrom:sliceTo">
      <app-list-documents-element *ngIf="document.customer"
        [document]="document"
        (openDetailsEmitter)="openDetailsEmitter.emit($event)"
        (openEditEmitter)="openEditEmitter.emit($event)"
        (deleteEmitter)="deleteEmitter.emit($event)"
        (openPdfEmitter)="openPdfEmitter.emit($event)"
        (moveToEmitter)="moveToEmitter.emit($event)"
        (duplicateEmitter)="duplicateEmitter.emit($event)"
        (signPDFEmitter)="signPDFEmitter.emit($event)"
      ></app-list-documents-element>
    </div>
  </div>
  <div *ngIf="tab == 2" id="listcontainer" class="list_element_container" [class.list_element_conatiner_empty]="documents.length == 0">
    <div class="list_no_elements lbl_regular_italic_light_color" *ngIf="documents.length == 0">Keine Dokumente vorhanden.</div>
    <div *ngFor="let document of documents | slice:sliceFrom:sliceTo">
      <app-list-document-element-contract *ngIf="document.customer"
        [document]="document"
        (openContractEditEmitter)="openContractEditEmitter.emit($event)"
        (deleteContractEmitter)="deleteContractEmitter.emit($event)"
        (openPdfEmitter)="openContractPdfEmitter.emit($event)"
        (signPDFEmitter)="signPDFEmitter.emit($event)"
        (openContractUploadEmitter)="openContractUploadEmitter.emit($event)"
      ></app-list-document-element-contract>
    </div>
  </div>
  <div class="list_footer"></div>
</div>
