import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public permissions$ = new BehaviorSubject<any>([]);
  public mandantId$ = new BehaviorSubject<any>(null);

  constructor(
    private router: Router
  ) {
    this.mandantId$.next(Number(localStorage.getItem('mandant_id')));
    try {
      this.permissions$.next(JSON.parse(localStorage.getItem('permissions')));
    } catch(e) {
      this.permissions$.next([]);
    }
  }

  setPermissions(data) {
    this.permissions$.next(data);
  }

  setTenantId(data) {
    this.mandantId$.next(data);
  }

  isWaymarkUser():  boolean {
    let mail = localStorage.getItem('email');
    return mail == 'tech@waymark.at' || mail == 'sebastian@waymark.at' || mail == 'benjamin.waymark1@gmail.com';
  }

  isHoedlAdmin():  boolean {
    let adminUsers = ['office@kfz-hoedl.at','laurastrommer@hotmail.com','tech@waymark.at']
    let mail = localStorage.getItem('email');
    return adminUsers.findIndex((m) => m == mail) > -1;
  }

  isWaymark(): boolean {
    return (this.mandantId$.getValue() == 1 ||
      this.mandantId$.getValue() == 2 ||
      this.mandantId$.getValue() == 7 ) &&
      (environment.api.includes('test'));
  }

  isAutobase(): boolean {
    return (this.mandantId$.getValue() == 1 && environment.api.includes('/api.auto-base'));
  }

  isSinhuber(): boolean {
    return (this.mandantId$.getValue() == 2 && environment.api.includes('sinhuber')) ||
        (this.mandantId$.getValue() == 2 && environment.api.includes('test-api')) ||
        (this.mandantId$.getValue() == 1 && environment.api.includes('test-api')) ||
        environment.api.includes('localhost');
  }

  isHoedl(): boolean {
    return (this.mandantId$.getValue() == 7 && (environment.api.includes('hoedl') || environment.api.includes('test'))) || environment.api.includes('localhost');
  }


  isHofbauer(): boolean {
    return (this.mandantId$.getValue() == 1 && environment.api.includes('hofbauer'));
  }

  isStandard(): boolean {
    return !this.isSinhuber();
  }

  permissionPricecalculation(): boolean {
    return false;
    // if(this.ADMIN()) return true; //TODO Euromobile, Zweispurig, Hofbauer ausblenden
    // return false;
  }

  getHoedlVerkauf(): any {
    return {
      "view" : [
        {
          "name": "images",
          "rows" : []
        },
        {
          "collapsible": true,
          "collapsed": true,
          "name" : "price",
          "rows" : ["price", "steuersatz", "guarantee", "vat_reportable", "leasable", "felgen_aufpreis", "fahrwerk_aufpreis"]
        },
        {
          "collapsible": true,
          "collapsed": true,
          "name" : "basic-data",
          "rows" : []
        },
        {
          "collapsible": true,
          "collapsed": false,
          "name" : "vehicle",
          "rows" : [
            "bodyColor", "color_designation",
            "upholstery", "upholsteryColor",
            "numOfSeats", "luggage_space", "numOfDoors"
          ],
        },
        {
          "collapsible": true,
          "collapsed": false,
          "name" : "drive",
          "rows" : [
            "fuel", "drive",
            "numOfGears", "transmission",
            "powerPs", "ccm",
            "numOfCylinders", "consumption.combined",
            "consumption.urban", "consumption.extraUrban",
            "co2Emission",
            "priceSheet.nova_satz", "enviromentLbl",
            "trailer_load_braked"
          ],
        },
        {
          "collapsible": true,
          "collapsed": false,
          "name" : "equipment",
          "collapsable": true,
          "rows" : []
        },
      ],
      "edit": []
    };
  }

  getSinhuberVerkauf(): any {
    return {
      "view" : [
        {
          "name": "images",
          "rows" : []
        },
        {
          "collapsible": true,
          "collapsed": true,
          "name" : "price",
          "rows" : [
            "price", "dealerPrice",
            "steuersatz", "guarantee", "vat_reportable", "leasable",
            "steuersatz", "guarantee", "vat_reportable", "leasable",
            "rent_price", "neu_preis"]
        },
        {
          "collapsible": true,
          "collapsed": true,
          "name" : "basic-data",
          "rows" : [
            "make_name", "model_name",
            "version", "bodyTyp",
            "category", "unfall",
            "previousOwners", "mileageKm",
            "firstRegistrationDate",
            "first_registration_checked"
          ],
        },
        {
          "collapsible": true,
          "collapsed": false,
          "name" : "vehicle",
          "rows" : [
            "bodyColor", "color_designation",
            "upholstery", "upholsteryColor",
            "numOfSeats", "luggage_space", "numOfDoors"
          ],
        },
        {
          "collapsible": true,
          "collapsed": false,
          "name" : "drive",
          "rows" : [
            "fuel", "drive",
            "numOfGears", "transmission",
            "powerPs", "ccm",
            "numOfCylinders", "consumption.combined",
            "consumption.urban", "consumption.extraUrban",
            "co2Emission", "co2_checked",
            "priceSheet.nova_satz", "enviromentLbl",
            "trailer_load_braked"
          ],
        },
        {
          "collapsible": true,
          "collapsed": false,
          "name" : "equipment",
          "collapsable": true,
          "rows" : []
        },
      ],
      "edit": []
    };
  }

  getStandardVerkauf(): any {
    return {
      "view" : [
        {
          "name": "images",
          "rows" : []
        },
        {
          "collapsible": true,
          "collapsed": true,
          "name" : "price",
          "rows" : [
            "price", "dealerPrice",
            "steuersatz", "guarantee", "vat_reportable", "leasable"]
        },
        {
          "collapsible": true,
          "collapsed": true,
          "name" : "basic-data",
          "rows" : [
            "make_name", "model_name",
            "version", "bodyTyp",
            "category", "unfall",
            "previousOwners", "mileageKm",
            "firstRegistrationDate",
            "first_registration_checked"
          ],
        },
        {
          "collapsible": true,
          "collapsed": false,
          "name" : "vehicle",
          "rows" : [
            "bodyColor", "color_designation",
            "upholstery", "upholsteryColor",
            "numOfSeats", "luggage_space", "numOfDoors"
          ],
        },
        {
          "collapsible": true,
          "collapsed": false,
          "name" : "drive",
          "rows" : [
            "fuel", "drive",
            "numOfGears", "transmission",
            "powerPs", "ccm",
            "numOfCylinders", "consumption.combined",
            "consumption.urban", "consumption.extraUrban",
            "co2Emission", "co2_checked",
            "priceSheet.nova_satz", "enviromentLbl",
            "trailer_load_braked"
          ],
        },
        {
          "collapsible": true,
          "collapsed": false,
          "name" : "equipment",
          "collapsable": true,
          "rows" : []
        },
      ],
      "edit": []
    };
  }

  getFallbackViewAuth(): any {
    if(this.isSinhuber()) {
      return {
        "screens": [
          {
            "view": [
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "basic-data",
                "rows" : [
                  "make_name", "model_name",
                  "version", "bodyTyp",
                  "category", "unfall",
                  "previousOwners", "mileageKm",
                  "firstRegistrationDate",
                  "first_registration_checked"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "price",
                "rows" : [
                  "price", "dealerPrice",
                  "steuersatz", "rent_price",
                  "guarantee",
                  "vat_reportable", "leasable",
                  "neu_preis"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "additional",
                "rows" : [
                  "eingangsdatum", "keys_number",
                  "display_keys_number", "digital_keys_number",
                  "pickerl_valid", "is_ersatzauto", "issued_ersatzauto",
                  "endreinigungsdatum", "handing_over_date",
                  "priceSheet.geldeingang", "priceSheet.eingang_anzahlung",
                  "priceSheet.eingang_anzahlung_description"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "service",
                "rows" : [
                  "maintenance.nextInspection", "maintenance.lastBeltService",
                  "maintenance.lastService", "maintenance.naechstes_service",
                  "maintenance.serviceHistory", "planed_repair_date"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "condition",
                "rows" : [
                  "paper_status", "finanzsperrauskunft",
                  "finanzsperrauskunft_beantragt", "type_certificate_versendet",
                  "type_certificate_eingestockt"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "identification",
                "rows" : [
                  "externalId", "fin_number",
                  "engineNumber", "eurotax_code",
                  "originalMarket", "emptyWeight",
                  "fullWeight"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "vehicle",
                "rows" : [
                  "bodyColor", "color_designation", "color_code",
                  "upholstery", "upholsteryColor",
                  "numOfSeats", "luggage_space", "numOfDoors"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "drive",
                "rows" : [
                  "fuel", "drive",
                  "numOfGears", "transmission",
                  "powerPs", "ccm",
                  "numOfCylinders", "consumption.combined",
                  "consumption.urban", "consumption.extraUrban",
                  "co2Emission", "co2_checked",
                  "priceSheet.nova_satz", "enviromentLbl",
                  "trailer_load_braked"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "interface-overview",
                "rows" : [
                  "website_online", "platform_online",
                  "inserat_checked", "inserat_info"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "description",
                "rows" : [
                  "description", "addEquipment",
                  "internal_description", "accounting_description"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "werkstatt",
                "rows" : [
                  "ankaufstest", "ankaufstest_info_description",
                  "werkstatt_info_description", "aufbereitung",
                  "aufbereitung_info", "aufbereitung_zustaendigkeit",
                  "damageValue", "dellen_info",
                  "last_movement", "repair_status",
                  "ersatzteile", "lackierung_notwendig",
                  "pickerl_valid", "photo_aufbereitung"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "seasonal",
                "rows" : [
                  "winter_diesel", "winter_tyres",
                  "winter_tyres_description", "summer_tyres",
                  "summer_tyres_description"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "garantie",
                "rows" : [
                  "warrantyExists", "garantie_description",
                  "warrantyDuration", "garantie_bis",
                  "garantie_km"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "images",
                "rows" : []
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "equipment",
                "rows" : []
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "attachment",
                "rows" : []
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name" : "basic-data",
                "rows" : [
                  "make_name", "model_name",
                  "version", "bodyTyp",
                  "category", "unfall",
                  "previousOwners", "mileageKm",
                  "firstRegistrationDate",
                  "first_registration_checked"
                ],
              },
              {
                "name" : "price",
                "rows" : [
                  "rent_price",
                  "guarantee",
                  "vat_reportable", "leasable"
                ],
              },
              {
                "name" : "additional",
                "rows" : [
                  "eingangsdatum", "keys_number",
                  "display_keys_number", "digital_keys_number",
                  "is_ersatzauto", "issued_ersatzauto",
                  "endreinigungsdatum", "handing_over_date"
                ]
              },
              {
                "name" : "service",
                "rows" : [
                  "maintenance.nextInspection", "maintenance.lastBeltService",
                  "maintenance.lastService", "maintenance.naechstes_service",
                  "maintenance.serviceHistory", "planed_repair_date"
                ],
              },
              {
                "name" : "identification",
                "rows" : [
                  "externalId", "fin_number",
                  "engineNumber", "eurotax_code",
                  "originalMarket", "emptyWeight",
                  "fullWeight"
                ],
              },
              {
                "name" : "vehicle",
                "rows" : [
                  "bodyColor", "color_designation", "color_code",
                  "upholstery", "upholsteryColor",
                  "numOfSeats", "luggage_space", "numOfDoors"
                ],
              },
              {
                "name" : "drive",
                "rows" : [
                  "fuel", "drive",
                  "numOfGears", "transmission",
                  "powerPs", "ccm",
                  "numOfCylinders", "consumption.combined",
                  "consumption.urban", "consumption.extraUrban",
                  "enviromentLbl",
                  "trailer_load_braked"
                ],
              },
              {
                "name" : "interface-overview",
                "rows" : [
                  "website_online", "platform_online",
                  "inserat_checked", "inserat_info"
                ],
              },
              {
                "name" : "werkstatt",
                "rows" : [
                  "ankaufstest", "ankaufstest_info_description",
                  "werkstatt_info_description", "aufbereitung",
                  "aufbereitung_info", "aufbereitung_zustaendigkeit",
                  "damageValue", "dellen_info",
                  "last_movement", "repair_status",
                  "ersatzteile", "lackierung_notwendig",
                  "pickerl_valid", "photo_aufbereitung"
                ],
              },
              {
                "name" : "seasonal",
                "rows" : [
                  "winter_diesel", "winter_tyres",
                  "winter_tyres_description", "summer_tyres",
                  "summer_tyres_description"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "garantie",
                "rows" : [
                  "warrantyExists", "garantie_description",
                  "warrantyDuration", "garantie_bis",
                  "garantie_km"
                ],
              },
              {
                "name" : "images",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
            ]
          },
          this.getSinhuberVerkauf()
          /*{
            "view": [
              {
                name: "price",
                rows: []
              },
              {
                name: "basic-data",
                rows: []
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "drive",
                "rows" : [
                  "fuel", "drive",
                  "numOfGears", "transmission",
                  "powerPs", "ccm",
                  "numOfCylinders", "consumption.combined",
                  "consumption.urban", "consumption.extraUrban",
                  "co2Emission", "co2_checked",
                  "priceSheet.nova_satz", "enviromentLbl",
                  "trailer_load_braked"
                ],
              },
              {
                name: "vehicle",
                rows: []
              },
            ],
            "edit": []
          }*/
        ]
      };
    }else if(this.isHoedl()) {
      return {
        "screens": [
          {
            "view": [
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "basic-data",
                "rows" : [
                  "make_name", "model_name",
                  "version", "bodyTyp",
                  "category", "unfall",
                  "previousOwners", "mileageKm",
                  "mileage_internal", "firstRegistrationDate",
                  "first_registration_checked",
                  "hail_damage"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "price",
                "rows" : [
                  "price_checked", "price",
                  "price_ohne_ust",
                  "export_price", "export_price_ohne_ust",
                  "steuersatz", "guarantee",
                  "vat_reportable", "leasable",
                  "felgen_aufpreis", "fahrwerk_aufpreis",
                  "sprit", "lieferant_extern",
                  "zubau", "einkaufspreis_brutto",
                  "gesamtkosten", "is_vermittlung",
                  "neu_preis", "einkaufsdatum"
                ],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "additional",
                "rows" : ["keys_number", "pickerl_valid"],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "service",
                "rows" : [
                  "maintenance.nextInspection", "maintenance.lastBeltService",
                  "maintenance.lastService", "maintenance.naechstes_service",
                  "maintenance.serviceHistory"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "condition",
                "rows" : [
                  "paper_status", "type_certificate_eingestockt",
                  "type_certificate_ausgestockt", "finanzsperrauskunft",
                  "type_certificate_is_sent", "coc",
                  "austrian_data_extract", "finanzsperrauskunft_status"
                ],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "identification",
                "rows" : [
                  "externalId", "fin_number",
                  "vehicle_supplier", "eurotax_code",
                  "originalMarket", "emptyWeight",
                  "fullWeight"
                ],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "vehicle",
                "rows" : [
                  "bodyColor", "color_designation",
                  "upholstery", "upholsteryColor",
                  "numOfSeats", "luggage_space", "numOfDoors"
                ],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "drive",
                "rows" : [
                  "fuel", "drive",
                  "numOfGears", "transmission",
                  "powerPs", "ccm",
                  "numOfCylinders", "consumption.combined",
                  "consumption.urban", "consumption.extraUrban",
                  "co2Emission",
                  "priceSheet.nova_satz", "enviromentLbl",
                  "envkv_class", "trailer_load_braked"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "interface-overview",
                "rows" : [
                  "website_online", "platform_online",
                  "mobile_online", "inserat_checked"
                ],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "description",
                "rows" : [
                  "description", "addEquipment",
                  "internal_description"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "images",
                "rows" : []
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "equipment",
                "rows" : []
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "attachment",
                "rows" : []
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name" : "basic-data",
                "rows" : [
                  "make_name", "model_name",
                  "version", "bodyTyp",
                  "category", "unfall",
                  "previousOwners", "mileageKm",
                  "mileage_internal", "firstRegistrationDate",
                  "first_registration_checked",
                  "hail_damage"
                ],
              },
              {
                "name" : "price",
                "rows" : [
                  "price_checked", "price",
                  "steuersatz",
                  "felgen_aufpreis", "fahrwerk_aufpreis",
                  "sprit", "lieferant_extern",
                  "zubau", "einkaufspreis_brutto",
                  "is_vermittlung","neu_preis", "guarantee",
                  "leasable", "vat_reportable",
                  "einkaufsdatum"
                ],
              },
              {
                "name" : "additional",
                "rows" : ["keys_number", "pickerl_valid"]
              },
              {
                "name" : "service",
                "rows" : [
                  "maintenance.nextInspection", "maintenance.lastBeltService",
                  "maintenance.lastService", "maintenance.naechstes_service",
                  "maintenance.serviceHistory"
                ],
              },
              {
                "name" : "condition",
                "rows" : [
                  "paper_status", "type_certificate_eingestockt",
                  "type_certificate_ausgestockt", "finanzsperrauskunft",
                  "type_certificate_is_sent", "coc",
                  "austrian_data_extract"
                ],
              },
              {
                "name" : "identification",
                "rows" : [
                  "externalId", "fin_number",
                  "vehicle_supplier", "eurotax_code",
                  "originalMarket", "emptyWeight",
                  "fullWeight"
                ],
              },
              {
                "name" : "vehicle",
                "rows" : [
                  "bodyColor", "color_designation",
                  "upholstery", "upholsteryColor",
                  "numOfSeats", "luggage_space", "numOfDoors"
                ],
              },
              {
                "name" : "drive",
                "rows" : [
                  "fuel", "drive",
                  "numOfGears", "transmission",
                  "powerPs", "ccm",
                  "numOfCylinders", "consumption.combined",
                  "consumption.urban", "consumption.extraUrban",
                  "co2Emission",
                  "priceSheet.nova_satz", "enviromentLbl",
                  "envkv_class", "trailer_load_braked"
                ],
              },
              {
                "name" : "interface-overview",
                "rows" : [
                  "website_online", "platform_online",
                  "mobile_online", "inserat_checked"
                ],
              },
              {
                "name" : "images",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      } ;
    } else { //Standard
      return {
        "screens": [
          {
            "view": [
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "basic-data",
                "rows" : [
                  "make_name", "model_name",
                  "version", "bodyTyp",
                  "category", "unfall",
                  "previousOwners", "mileageKm",
                  "firstRegistrationDate",
                  "first_registration_checked"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "price",
                "rows" : [
                  "price", "dealerPrice",
                  "steuersatz", "rent_price",
                  "guarantee",
                  "vat_reportable", "leasable",
                  "neu_preis"
                ],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "additional",
                "rows" : ["keys_number", "pickerl_valid"],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "service",
                "rows" : [
                  "maintenance.nextInspection", "maintenance.lastBeltService",
                  "maintenance.lastService", "maintenance.naechstes_service",
                  "maintenance.serviceHistory"
                ],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "identification",
                "rows" : [
                  "externalId", "fin_number",
                  "engineNumber", "eurotax_code",
                  "originalMarket", "emptyWeight",
                  "fullWeight"
                ],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "vehicle",
                "rows" : [
                  "bodyColor", "color_designation",
                  "upholstery", "upholsteryColor",
                  "numOfSeats", "luggage_space", "numOfDoors"
                ],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "drive",
                "rows" : [
                  "fuel", "drive",
                  "numOfGears", "transmission",
                  "powerPs", "ccm",
                  "numOfCylinders", "consumption.combined",
                  "consumption.urban", "consumption.extraUrban",
                  "co2Emission",
                  "priceSheet.nova_satz", "enviromentLbl",
                  "trailer_load_braked"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "interface-overview",
                "rows" : [
                  "website_online", "platform_online",
                  "inserat_checked", "inserat_info"
                ],
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "description",
                "rows" : [
                  "description", "addEquipment",
                  "internal_description"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "images",
                "rows" : []
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "equipment",
                "rows" : []
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "attachment",
                "rows" : []
              },
              {
                "collapsible": true,
                "collapsed": false,
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name" : "basic-data",
                "rows" : [
                  "make_name", "model_name",
                  "version", "bodyTyp",
                  "category", "unfall",
                  "previousOwners", "mileageKm",
                  "firstRegistrationDate",
                  "first_registration_checked"
                ],
              },
              {
                "name" : "price",
                "rows" : [
                  "price", "dealerPrice",
                  "rent_price",
                  "guarantee",
                  "vat_reportable", "leasable"
                ],
              },
              {
                "name" : "additional",
                "rows" : ["keys_number", "pickerl_valid"]
              },
              {
                "name" : "service",
                "rows" : [
                  "maintenance.nextInspection", "maintenance.lastBeltService",
                  "maintenance.lastService", "maintenance.naechstes_service",
                  "maintenance.serviceHistory"
                ],
              },
              {
                "name" : "identification",
                "rows" : [
                  "externalId", "fin_number",
                  "engineNumber", "eurotax_code",
                  "originalMarket", "emptyWeight",
                  "fullWeight"
                ],
              },
              {
                "name" : "vehicle",
                "rows" : [
                  "bodyColor", "color_designation",
                  "upholstery", "upholsteryColor",
                  "numOfSeats", "luggage_space", "numOfDoors"
                ],
              },
              {
                "name" : "drive",
                "rows" : [
                  "fuel", "drive",
                  "numOfGears", "transmission",
                  "powerPs", "ccm",
                  "numOfCylinders", "consumption.combined",
                  "consumption.urban", "consumption.extraUrban",
                  "co2Emission", "enviromentLbl",
                  "trailer_load_braked"
                ],
              },
              {
                "collapsible": true,
                "collapsed": true,
                "name" : "interface-overview",
                "rows" : [
                  "website_online", "platform_online",
                  "inserat_checked", "inserat_info"
                ],
              },
              {
                "name" : "images",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
            ]
          },
          this.getStandardVerkauf()
        ]
      };
    }
  }

  showCreateValue(value: string): any {
    let viewAuth = this.getFallbackViewAuth();
    let screens = viewAuth.screens;
    if(screens.length > 0) {
      let screen = screens[0];
      if(screen.edit) {
        let edit = screen.edit;
        for(let box of edit) {
          if(box.rows && box.rows.find((r) => r == value)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getViewAuth(): any {
    return [
      //Sinhuber
      {
        "id": 10,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "werkstatt",
                "rows" : [
                  "ankaufstest", "ankaufstest_info_description", "werkstatt_info_description", "aufbereitung",
                  "ankaufstest", "ankaufstest_info_description", "werkstatt_info_description", "aufbereitung",
                  "damageValue", "dellen_info","last_movement",
                  "lackierung_notwendig", "aufbereitung_info", "aufbereitung_zustaendigkeit"]
              },
              {
                "name" : "seasonal",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum", "pickerl_valid"]
              },
              {
                "name" : "vehicle",
                "rows" : []
              }
            ],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : ["ankaufstest", "werkstatt_info_description", "aufbereitung", "ankaufstest_info_description", "lackierung_notwendig", "aufbereitung_info", "aufbereitung_zustaendigkeit"]
            },
            {
              "name" : "seasonal",
              "rows" : []
            },
            {
              "name" : "additional",
              "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum", "pickerl_valid"]
            }
          ]
          },
          {
            "view" : [],
            "edit": []
          }
        ]
      },
      {
        "id": 11,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "werkstatt",
                "rows" : [
                  "ersatzteile", "damageValue", "dellen_info",
                  "last_movement",
                ]
              },
              {
                "name" : "seasonal",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "garantie",
                "rows" : []
              },
              ],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : ["ersatzteile"]
            }
          ]
          },
          {
            "view" : [
              /*{
                "name": "price",
                "rows" : []
              }, {
                "name": "images",
                "rows" : []
              }, {
                "name": "basic-data",
                "rows" : []
              },
              {
                "name": "vehicle",
                "rows" : []
              },
              {
                "name": "equipment",
                "rows" : []
              },
              {
                "name": "transactions",
                "rows" : []
              }*/
            ],
            "edit": [
              /*{
                "name" : "werkstatt",
                "rows" : []
              }*/
            ]
          }
        ]
      },
      {
        "id": 15,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "werkstatt",
                "rows" : [
                  "ersatzteile", "damageValue", "dellen_info",
                  "last_movement",
                ]
              },
              {
                "name" : "seasonal",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              }],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : ["ersatzteile"]
            }
          ]
          },
          {
            "view" : [
              /*{
                "name": "price",
                "rows" : []
              }, {
                "name": "images",
                "rows" : []
              }, {
                "name": "basic-data",
                "rows" : []
              },
              {
                "name": "vehicle",
                "rows" : []
              },
              {
                "name": "equipment",
                "rows" : []
              },
              {
                "name": "transactions",
                "rows" : []
              }*/
            ],
            "edit": [
              /*{
                "name" : "werkstatt",
                "rows" : []
              }*/
            ]
          }
        ]
      },
      {
        "id": 14,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "werkstatt",
                "rows" : []
              },
              {
                "name" : "seasonal",
                "rows" : []
              }],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : [
                "ankaufstest",
                "ankaufstest_info_description",
                "photo_aufbereitung",
                "repair_status",
                "werkstatt_info_description",
                "lackierung_notwendig"
              ]
            }
          ]
          },
          {
            "view" : [
              /*{
                "name": "price",
                "rows" : []
              }, {
                "name": "images",
                "rows" : []
              }, {
                "name": "basic-data",
                "rows" : []
              },
              {
                "name": "vehicle",
                "rows" : []
              },
              {
                "name": "equipment",
                "rows" : []
              },
              {
                "name": "transactions",
                "rows" : []
              }*/
            ],
            "edit": [
            ]
          }
        ]
      },
      {
        "id": 16,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "werkstatt",
                "rows" : [
                  "ankaufstest", "werkstatt_info_description", "aufbereitung", "aufbereitung_info", "photo_aufbereitung",
                   "aufbereitung_zustaendigkeit", "repair_status", "damageValue", "dellen_info",
                  "last_movement"]
              },
              {
                "name" : "seasonal",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : ["color_designation", "color_code", "bodyColor"]
              },
              {
                "name" : "additional",
                "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum",  "pickerl_valid"]
              }
            ],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : ["ankaufstest", "werkstatt_info_description", "aufbereitung", "aufbereitung_info","photo_aufbereitung", "aufbereitung_zustaendigkeit", "repair_status"]
            },
            {
              "name" : "seasonal",
              "rows" : []
            },
            {
              "name" : "vehicle",
              "rows" : ["color_designation", "color_code", "bodyColor"]
            },
            {
              "name" : "additional",
              "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum", "pickerl_valid"]
            }
          ]
          },
          {
            "view" : [],
            "edit": []
          }
        ]
      },
      {
        "id": 17,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "werkstatt",
                "rows" : [
                  "ankaufstest", "werkstatt_info_description", "aufbereitung", "photo_aufbereitung",
                  "ankaufstest", "werkstatt_info_description", "aufbereitung", "photo_aufbereitung",
                  "damageValue", "dellen_info",
                  "last_movement",
                  "aufbereitung_zustaendigkeit", "repair_status"]
              },
              {
                "name" : "seasonal",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum",  "pickerl_valid"]
              }],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : ["ankaufstest", "werkstatt_info_description", "aufbereitung", "photo_aufbereitung", "aufbereitung_zustaendigkeit","repair_status"]
            },
            {
              "name" : "seasonal",
              "rows" : []
            },
            {
              "name" : "additional",
              "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum", "pickerl_valid"]
            }
          ]
          },
          {
            "view" : [],
            "edit": []
          }
        ]
      },
      //Hödl
      {
        "id": 212,
        "screens": [
          {
            "view": [
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ],
            "edit": [
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 213,
        "screens": [
          {
            "view": [
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ],
            "edit": [
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 214,
        "screens": [
          {
            "view": [
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ],
            "edit": [
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 215,
        "screens": [
          {
            "view": [
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ],
            "edit": [
              {
                "name" : "condition",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
    ]
  }

  getViewRepresentations(): any {
    return [
      [
        ["images", "price", "customercar", "condition", "additional", "interface-overview", "attachment"],
        ["basic-data", "identification", "vehicle", "drive", "service", "werkstatt", "seasonal"],
        ["equipment", "description", "transactions"]
      ],
      [
        ["price", "images"],
        ["basic-data", "drive", "vehicle"],
        ["equipment", "transactions"]
      ]
    ];
  }

  getLocationID(): number {
    return Number(localStorage.getItem("location_id"));
  }

  isSuperAdmin() :boolean {
    const userId = Number(localStorage.getItem('employee_id'));
    return userId == 38 || userId == 43;
  }

  accessVehicleMenu(): boolean {
    return this.permissions$.getValue()?.find((p) => p == "vehicle_view") != undefined;
  }

  accessVehicleDetails(): boolean {
    return this.accessVehicleMenu() && this.permissions$.getValue().find((p) => p == "vehicle_view") != undefined;
  }

  accessVehicleEdit(): boolean {
    return this.accessVehicleDetails() && this.permissions$.getValue().find((p) => p == "vehicle_edit") != undefined;
  }

  accessTicketView(): boolean {
    return this.permissions$.getValue().find((p) => p == "ticket_view") != undefined;
  }

  accessTicketEdit(): boolean {
    return this.accessTicketView() && this.permissions$.getValue().find((p) => p == "ticket_edit") != undefined;
  }

  accessAccountingMenu(): boolean {
    return this.permissions$.getValue().find((p) => (p == "bill_view" || p == "ledger_view")) != undefined;
  }

  accessCustomBillEdit(): boolean {
    return this.permissions$.getValue().find((p) => p == "custom_bill_edit") != undefined;
  }

  accessVia(name: string|string[]): boolean {
    const permissions = this.permissions$.getValue();
    if (!permissions || !Array.isArray(permissions)) {
      console.error("Permissions data is not available or is not an array");
      return false;
    }
    if(Array.isArray(name)) {
      return name.some(n => permissions.includes(n));
    } else {
      return permissions.includes(name);
    }
  }

  accessViaPermissionGroup(): boolean {
    return true;
  }

  //access route path
  canActivate(next: ActivatedRouteSnapshot): boolean {
    const path = next.routeConfig.path;
    if(localStorage.getItem('token') && localStorage.getItem('permissions')){
      if(path === 'cars') {
        return this.accessVehicleMenu();
      } else if(path === 'cars/:carId/:subMenuId') {
        return this.accessVehicleDetails();
      } else if(path === 'cars/create') {
        return this.accessVehicleEdit();
      }
      else if(path === 'cars/:carId/:subMenuId') {
        return this.accessVehicleEdit();
      } else if(path === 'cars/:vehicleId/:threadId') {
        return this.accessVehicleDetails() && this.accessTicketView();
      } else if(path === 'accounting') {
        return this.accessVia(['bill_view','ledger_view'])
      } else if(path === 'customers') {
        return this.accessVia('customer_view');
      } else if(path === 'customers/:customerId') {
        return this.accessVia('customer_view');
      } else if(path === 'customers/create/:typ') {
        return this.accessVia('customer_edit');
      } else if(path === 'customers/edit/:customerId') {
        return this.accessVia('customer_edit');
      } else if(path === 'messages') {
        return this.accessVia('ticket_view');
      } else if(path === 'messages/create') {
        return this.accessVia('ticket_edit');
      } else if(path === 'messages/:threadId') {
        return this.accessVia('ticket_view');
      } else if(path === 'messages/:threadId/:vehicleId') {
        return this.accessVia(['ticket_view', 'vehicle_view']);
      } else if(path === 'documents') {
        return this.accessVia('purchase_contract_view')
      } else if(path === 'documents/create/:typ/:customerId') {
        return this.accessVia('purchase_contract_view');
      } else if(path === 'documents/edit/:documentId') {
        return this.accessVia('purchase_contract_view');
      } else if(path === 'documents/:documentId') {
        return this.accessVia('purchase_contract_view');
      } else if(path === 'drives') {
        return this.accessVia('test_drive_view');
      } else if(path === 'drives/create'){
        return this.accessVia('test_drive_edit');
      } else if(path === 'interfaces'){
        return false;
      } else if(path === 'dashboard') {
        return false;
      } else if(path === 'material') {
        return false;
      } else if(path === 'tires') {
        return this.accessVia('tire_view');
      } else if(path === 'settings') {
        return this.accessVia('settings_view');
      } else if(path === 'users') {
        return this.accessVia('user_view');
      } else if(path === 'locations') {
        return this.accessVia('location_view');
      } else if(path === 'rent') {
        return this.accessVia('rent_view');
      } else {
        return false;
      }
    } else {
      this.logout();
      return false;
    }
  }

  logout() {
    this.mandantId$.next(-1);
    localStorage.removeItem('token');
    localStorage.removeItem('auth');
    localStorage.removeItem('employee_id');
    localStorage.removeItem('location_id');
    localStorage.removeItem('mandant_id');
    localStorage.removeItem('roles');
    localStorage.removeItem('user');
    localStorage.removeItem('email');
    localStorage.removeItem('menus');
    localStorage.removeItem('enums');
    localStorage.removeItem('permissions');
    localStorage.removeItem('permission_group_name');
    this.router.navigate(['login']);
  }
}
