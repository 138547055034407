export class TransactionHelper {

  public static getTransactionIcon(typ: number): string {
    switch (typ) {
      case 1: return './assets/user-transaction.svg'; break;
      case 2: return './assets/car-transaction.svg'; break;
      case 3: return './assets/document-transaction.svg';  break;
      case 5: return './assets/tiredetails.svg';  break;
      default: return './assets/car-transaction.svg'; break;
    }
  }

  public static getTransactionColor(typ: number): string {
    switch (typ) {
      case 1: return '#6A9E18'; break;
      case 2: return '#597CF1'; break;
      case 3: return '#D2B836';  break;
      case 5: return '#6A9E18';  break;
      default: return '#ffffff'; break;
    }
  }
}
