import { Drive } from "../Drive";

export class DriveFactory {
  static jsonFactory(rawBody: any): Drive[] {
    const data: Drive[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): Drive {
      const obj = new Drive(
        rawPart.test_drive_id,
        rawPart.location_id,
        rawPart.timestamp,
        rawPart.duration,
        rawPart.vehicle_id,
        rawPart.customer_id,
        rawPart.license_plate_id,
        rawPart.attachments,
      )
      //obj.status = obj.customer_id == null ? 0 : ((obj.timestamp + obj.duration) > new Date().getTime() ? 1 : 2);
      obj.status = (obj.timestamp + obj.duration) > new Date().getTime() ? 1 : 2;
      obj.start_time = obj.timestamp;
      obj.end_time = obj.timestamp + obj.duration;
      obj.employee_id = rawPart.employee_id;
      obj.authorization_authority = rawPart.authorization_authority;
      obj.authorization_back_image = rawPart.authorization_back_image;
      obj.authorization_front_image = rawPart.authorization_front_image;
      obj.authorization_date = rawPart.authorization_date;
      obj.authorization_groups = rawPart.authorization_groups;
      obj.authorization_number = rawPart.authorization_number;
      obj.trip_description = rawPart.trip_description;
      obj.status_signiert = rawPart.status == null ? 0 : rawPart.status;
      return obj;
  }
}
