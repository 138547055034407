import { SortTyp } from "../sorttyp/SortTyp";

export class MenuLoader {
  public static carMenu1: any[] = [
    {
      "title" : "Bild",
      "is_sort" : false,
      "is_end" : false,
      "sort_value" : null
    },
    {
      "title" : "ID-Nr",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.ID
    },
    {
      "title" : "Marke",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.MARKE
    },
    {
      "title" : "Modell",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.MODELL
    },
    {
      "title" : "Daten",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.DATEN
    },
    {
      "title" : "Preis",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.PREIS
    },
    {
      "title" : "Farbe",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.FARBE
    },
    {
      "title" : "Km",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.KM
    },
    {
      "title" : "EZ",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.EZ
    },
    {
      "title" : "Bearbeitet",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.BEARBEITET
    },
  ];
  public static carMenu2: any[] = [
    {
      "title" : "ID-Nr",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.ID
    },
    {
      "title" : "Marke",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.MARKE
    },
    {
      "title" : "Modell",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.MODELL
    },
    {
      "title" : "Reifen W/S",
      "is_sort" : false,
      "is_end" : true,
      "sort_value" : null
    },
    {
      "title" : "Schlüssel",
      "is_sort" : false,
      "is_end" : true,
      "sort_value" : null
    },
    {
      "title" : "Dellen",
      "is_sort" : false,
      "is_end" : true,
      "sort_value" : null
    },
    {
      "title" : "Papiere",
      "is_sort" : false,
      "is_end" : true,
      "sort_value" : null
    },
    {
      "title" : "Letzte Bewegung",
      "is_sort" : false,
      "is_end" : true,
      "sort_value" : null
    },
    {
      "title" : "Aufbereitung",
      "is_sort" : false,
      "is_end" : true,
      "sort_value" : null
    },
    {
      "title" : "Ersatzteile",
      "is_sort" : false,
      "is_end" : true,
      "sort_value" : null
    },
    {
      "title" : "Reparatur",
      "is_sort" : false,
      "is_end" : true,
      "sort_value" : null
    },
    {
      "title" : "Ankaufstest",
      "is_sort" : false,
      "is_end" : true,
      "sort_value" : null
    }
  ];
  public static carMenu3: any[] = [
    {
      "title" : "Bild",
      "is_sort" : false,
      "is_end" : false,
      "sort_value" : null
    },
    {
      "title" : "ID-Nr",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.ID
    },
    {
      "title" : "Marke",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.MARKE
    },
    {
      "title" : "Modell",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.MODELL
    },
    {
      "title" : "Treibstoff",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.TREIBSTOFF
    },
    {
      "title" : "Antriebsart",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.ANTRIEB
    },
    {
      "title" : "Türen/Sitze",
      "is_sort" : false,
      "is_end" : true,
      "sort_value" : null
    },
    {
      "title" : "Leistung PS/KW",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.PS
    }
  ];
  public static carMenu4: any[] = [
    {
      "title" : "Bild",
      "is_sort" : false,
      "is_end" : false,
      "sort_value" : null
    },
    {
      "title" : "ID-Nr",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.ID
    },
    {
      "title" : "Marke",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.MARKE
    },
    {
      "title" : "Modell",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.MODELL
    },
    {
      "title" : "Kauf am",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.KAUFDATUM
    },
    {
      "title" : "Verkauf am",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.VERKAUFSDATUM
    },
    {
      "title" : "Einkaufspreis",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.EINKAUFSPREIS
    },
    {
      "title" : "Verkaufspreis",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.VERKAUFSPREIS
    },
    {
      "title" : "Beschaffung",
      "is_sort" : false,
      "is_end" : true,
      "sort_value" : null
    },
    {
      "title" : "Standzeit",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.STANDZEIT
    },
  ];
  public static carMenu5: any[] = [
    {
      "title" : "Bild",
      "is_sort" : false,
      "is_end" : false,
      "sort_value" : null
    },
    {
      "title" : "ID-Nr",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.ID
    },
    {
      "title" : "Marke",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.MARKE
    },
    {
      "title" : "Modell",
      "is_sort" : true,
      "is_end" : false,
      "sort_value" : SortTyp.MODELL
    },
    {
      "title" : "Kauf am",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.KAUFDATUM
    },
    {
      "title" : "Verkauf am",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.VERKAUFSDATUM
    },
    {
      "title" : "Einkaufspreis",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.EINKAUFSPREIS
    },
    {
      "title" : "Verkaufspreis",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.VERKAUFSPREIS
    },
    {
      "title" : "Standzeit",
      "is_sort" : true,
      "is_end" : true,
      "sort_value" : SortTyp.STANDZEIT
    }
  ];
}
