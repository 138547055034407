import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-pdf-print',
  templateUrl: './dialog-pdf-print.component.html',
  styleUrl: './dialog-pdf-print.component.css'
})
export class DialogPdfPrintComponent implements OnInit {
  @Input() vehicleId;
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() selectionPrintWeb = new EventEmitter<any>();
  @Output() selectionPrint = new EventEmitter<any>();

  constructor(){}

  ngOnInit(): void {

  }
}
