<div [attr.id]="columnId" class="column-data-wrapper">
  <label *ngIf="typ == 'label'"  class="submenu-column-label">{{data}}</label>
  <div *ngIf="typ == 'thumbnail'" [ngClass]="authService.getLocationID() === 18 ? 'column-data-img' : 'column-data-img-small'" >
    <img *ngIf="!showImageLoading && data" class="thumbnail" [src]="data">
    <div class="thumbnail-loader-wrapper" *ngIf="showImageLoading">
      <img class="thumbnail-laoder" src="./assets/logomenu.svg">
    </div>
    <div class="thumbnail-wrapper" *ngIf="!showImageLoading && data == null">
      <img class="thumbnail-laoder" src="./assets/logomenu.svg">
    </div>
    <div *ngIf="showImageLoading" class="loader"></div>
  </div>
</div>
