import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy, ViewChild, ElementRef, Renderer2, AfterContentChecked, SimpleChanges } from '@angular/core';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { User } from 'src/app/buisness-object/user/User';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';

@Component({
  selector: 'app-messages-filter',
  templateUrl: './messages-filter.component.html',
  styleUrls: ['./messages-filter.component.css']
})
export class MessagesFilterComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {
  @Input() showFilter: boolean;
  @Input() filter: any;
  @Input() users: User[];
  @Output() submitFilterEmitter = new EventEmitter<undefined>();
  @Output() resetFilterEmitter = new EventEmitter<undefined>();
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public showDropdownThree = false;
  @ViewChild('dropdown_1') dropdown_1: ElementRef;
  @ViewChild('dropdown_2') dropdown_2: ElementRef;
  @ViewChild('dropdown_3') dropdown_3: ElementRef;
  public listener: () => void;
  public filteredUsers: Employee[] = [];
  public filteredVehicles: Vehicle[] = [];
  public filteredCustomers: Customer[] = [];
  public tempFilter;

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.copyFilter();
    this.filteredUsers = this.filter.users;
    this.filteredVehicles = this.filter.vehicles;
    this.filteredCustomers = this.filter.customers;
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropdownOne && !this.dropdown_1.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.dropdown_2.nativeElement.contains(e.target)) this.showDropdownTwo = false;
      if(this.showDropdownThree && !this.dropdown_3.nativeElement.contains(e.target)) this.showDropdownThree = false;
    })
  }

  ngAfterContentChecked(): void {
    this.filteredUsers = this.filter.users;
    this.filteredVehicles = this.filter.vehicles;
    this.filteredCustomers = this.filter.customers;
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['showFilter']){
      this.copyFilter();
    }
  }

  copyFilter() {
    this.tempFilter = {
      users: this.filter.users,
      user: this.filter.user,
      vehicles: this.filter.vehicles,
      vehicle: this.filter.vehicle,
      customers: this.filter.customers,
      customer: this.filter.customer,
      urgency: this.filter.urgency,
      typ: this.filter.typ,
      is_set: this.filter.is_set,
      filter: this.filter.filter,
    }
  }

  applyFilter() {
    this.filter.user = this.tempFilter.user;
    this.filter.vehicle = this.tempFilter.vehicle;
    this.filter.customer = this.tempFilter.customer;
    this.filter.urgency = this.tempFilter.urgency;
    this.filter.typ = this.tempFilter.typ;
  }


  filterUser(value: string) {
    if(value && value.length > 0){
      this.filter.user = undefined;
      this.filteredUsers = [];
      this.filteredUsers = this.filter.users.filter((u) => {
        return u.firstName.toLowerCase().includes(value.toLowerCase()) ||
               u.lastName.toLowerCase().includes(value.toLowerCase()) ||
               (u.firstName.toLowerCase() + ' ' + u.lastName.toLowerCase()).includes(value.toLowerCase());
      })
    } else {
      this.filteredUsers = this.filter.users;
    }
  }

  filterCustomer(value: string) {
    if(value && value.length > 0){
      this.filter.customer = undefined;
      this.filteredCustomers = [];
      this.filteredCustomers = this.filter.customers.filter((c) => {
        return c.getName().toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.filteredCustomers = this.filter.customers;
    }
  }

  filterVehicle(value: string) {
    if(value && value.length > 0){
      this.filter.vehicle = undefined;
      this.filteredVehicles = [];
      this.filteredVehicles = this.filter.vehicles.filter((v) => {
        return v.getDescription().toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.filteredVehicles = this.filter.vehicles;
    }
  }

  submitFilter() {
    this.applyFilter();
    this.submitFilterEmitter.emit();
  }
  resetFilter() {
    this.resetFilterEmitter.emit();
  }

  filterIsSet() {
    if(this.filter.user || this.filter.customer || this.filter.vehicle || this.filter.urgency || this.filter.typ) return true;
  }
}
