<div id="dialog_window">
  <div id="dialog_container" appDropdown>
      <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
      <h1>Finanzierungsgesellschaft definieren</h1>
      <div id="dialog_conainter_body" class="body">
        <app-dropdown-multiple
          [title]="'Finanzierungsgesellschaft'"
          [placeholder]="'---'"
          [headerText]="finanzierungsgesellschaftValue"
          [formcontrol]="form.controls['finanzierungsgesellschaft']"
          [values]="finanzierungsgesellschaftValues"
          (submitValueEmitter)="selectionFinanzierungsgesellschaft($event)"
        ></app-dropdown-multiple>
      </div>
      <div class="dialog_btn_container">
          <button class="btn_label" (click)="selectionCloseEmitter.emit()">Abbrechen</button>
          <button class="btn_submit_dark" [disabled]="form.invalid" (click)="selectionSubmit()">Speichern</button>
      </div>
  </div>
</div>
