import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OutputHelper } from 'src/app/helpers/OutputHelper';

@Component({
  selector: 'app-details-offer-document',
  templateUrl: './details-offer-document.component.html',
  styleUrls: ['./details-offer-document.component.css']
})
export class DetailsOfferDocumentComponent implements OnInit {
  @Input() document: any;
  @Output() sendDocumentEmitter = new EventEmitter<any>();
  public getTransactionIcon = OutputHelper.getTransactionIcon;
  public getTransactionColor = OutputHelper.getTransactionColor;
  public grandTotal = 0;

  constructor() { }

  ngOnInit(): void {
    for(let item of this.document.offer_items){
      this.grandTotal += item.price;
      if(item.total_discount) this.grandTotal -= item.total_discount;
    }
  }

}
