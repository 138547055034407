<div class="data_container">
    <div class="lbl_bold_light_color">Dokument hochladen</div>
    <div class="body">
      <div class="column">
        <div id="upload_box" class="hover_change" appDnd (fileDroppedEmitter)="onFileDropped($event)">
            <div class="upload_box_info">
                <img class="upload_box_info_img" src="../../../../../assets/upload.svg">
                <div class="upload_box_info_lbl">Ziehen Sie Dateien hier her, um sie hochzuladen.</div>
            </div>
        </div>
        <button id="search-documents" (click)="selectFile.click()">Dateien auswählen</button>
        <input #selectFile type="file" accept="image/*" capture="environment" (change)="uploadFile($event)" multiple="" style="display: none;">
      </div>
      <div class="column">
        <div class="list_title_lbl" style="margin-bottom: 20px;">Art des Dokkuments</div>
        <div class="radio_container">
            <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="true ? 'radio_icon_inner_active' : ''"></div></div>
            <div class="lbl_info">Kaufvertrag</div>
        </div>
        <div class="radio_container">
            <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="false ? 'radio_icon_inner_active' : ''"></div></div>
            <div class="lbl_info">Probefahrt-Formular</div>
        </div>
        <div class="radio_container">
            <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="true ? 'radio_icon_inner_active' : ''"></div></div>
            <div class="lbl_info">Datenschutz-Formular</div>
        </div>
      </div>
    </div>
  </div>
