<div class="dropdown_component_wrapper">
  <div class="overview_record_lbl">{{title}}</div>
  <div #dropdown_2 class="dropdown_button">
      <div class="dropdown_button_header" (click)="show = !show" appDropdown [show]="show" (changedValueEmitter)="show = $event">
          <div class="dropdown_header_data_lbl_overview" [ngClass]="formcontrol.value == null ? 'dropdown_header_lbl_placeholder' : ''">{{getHeaderValue()}}</div>
          <img class="dropdown_button_header_img" [ngClass]="show ? 'dropdown_button_header_img_active' : ''" src="../../../../assets/arrow-dark.svg">
      </div>
      <div class="dropdown_button_box" *ngIf="show && !values">
          <label *ngIf="isOptional" (click)="submitValueEmitter.emit(null); show = false">--</label>
          <label (click)="submitValueEmitter.emit(true); show = false">Ja</label>
          <label (click)="submitValueEmitter.emit(false); show = false">Nein</label>
      </div>
      <div class="dropdown_button_box" *ngIf="show && values">
        <label *ngIf="isOptional" (click)="submitValueEmitter.emit(null); show = false">--</label>
        <label (click)="submitValueEmitter.emit(true); show = false">{{values[0]}}</label>
        <label (click)="submitValueEmitter.emit(false); show = false">{{values[1]}}</label>
    </div>
  </div>
</div>
