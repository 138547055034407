import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-create-offer-navigation',
  templateUrl: './create-offer-navigation.component.html',
  styleUrls: ['./create-offer-navigation.component.css']
})
export class CreateOfferNavigationComponent implements OnInit {
  @Input() currentPageCreate: number;
  @Input() isEdit: boolean;
  public pagesTotalAry = [1,2];
  @Output() changePageEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  getDotIcon(page: number): string {
    if(page == this.currentPageCreate){
      return '../../../../../assets/dot_full.png';
    }
    return '../../../../../assets/dot_empty.png';
  }

  nextPage() {
    if(this.currentPageCreate <= this.pagesTotalAry.length){
      this.changePageEmitter.emit(true);
    }
  }

  previousPage() {
    if(this.currentPageCreate > 1){
      this.changePageEmitter.emit(false);
    }
  }
}
