import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Bill, BillStatus, BillStatusLabels, getBillStatusLabelStyle } from 'src/app/buisness-object/document/Bill';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { BaseComponent } from '../../BaseComponent';
import { EnumService } from 'src/app/service/enum/enum.service';
import { VehicleStatus } from 'src/app/buisness-object/document/Kaufvertrag';
import { getLabelFromEnumValue } from 'src/app/utils/EnumHandler';
import { BillItem } from 'src/app/buisness-object/document/BillItem';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { DocumentService } from 'src/app/service/document/document.service';
import { CloneObject } from 'src/app/helpers/CloneObject';

export enum BillDetailsTab {
  Details = 1,
  Tickets = 2,
}

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrl: './bill-details.component.css'
})
export class BillDetailsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() obj: Bill;
  @Input() isPreviousObjAvailable: boolean;
  @Input() isNextObjAvailable: boolean;
  @Output() selectionBackEmitter = new EventEmitter<undefined>();
  @Output() selectionEditEmitter = new EventEmitter<any>();
  @Output() successCancelEmitter = new EventEmitter<any>();
  @Output() successRenewEmitter = new EventEmitter<any>();
  @Output() successPaidEmitter = new EventEmitter<any>();
  @Output() selectionPreviousObjEmitter = new EventEmitter<any>();
  @Output() selectionNextObjEmitter = new EventEmitter<any>();
  public tabView = BillDetailsTab;
  public showTab: BillDetailsTab = BillDetailsTab.Details;
  public BillStatus = BillStatus;
  public shwoActions = false;
  public paymentConditionValue
  public downPaymentConditionValue;
  public finanzierungsartenValue;
  public finanzierungsgesellschaftValue;
  public mechanical_conditionValue;
  public body_conditionValue;
  public paint_conditionValue;
  public interior_conditionValue;
  public electronic_conditionValue;
  public total_condition;
  public registrationDocumentValue;
  public billStatus;
  public billStatusStyle;
  public totalNetto;
  public totalBrutto;
  public totalTax10;
  public totalTax20;
  public dialogQuerySubsription: Subscription;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService,
    private dialogService: DialogService,
    private documentService: DocumentService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.setUp();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes &&
      changes['obj'] &&
      (changes['obj'].currentValue?.bill_id != changes['obj'].previousValue?.bill_id) ||
      changes['obj'].currentValue?.status != changes['obj'].previousValue?.status){
        this.setUp();
    }
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setUp() {
    this.paymentConditionValue = this.getEnumValue('payment_condition', this.obj.payment_method);
    this.billStatus = this.obj.getStatus();
    this.billStatusStyle = this.obj.getStatusStyle();
    // this.billStatus = getLabelFromEnumValue(this.obj.status, BillStatusLabels);
    // this.billStatusStyle = getBillStatusLabelStyle(this.obj.status);
    this.getTotalNetto();
    this.getTotalBrutto();
    this.getTotalTaxes();
    this.setDialogSubscription();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_bill') this.cancelBill();
        else if(value.typ == 'renew_bill') this.renewBill();
      }
    });
  }

  selectionBack() {
    this.selectionBackEmitter.emit();
  }

  getConditionColor(value: string) {
    return VehicleStatus.getConditionColor(value);
  }

  getTotalNetto() {
    let tn = 0;
    for(let position of this.obj.bill_items){
      const price = position.price;
      if(price){
        const quantity = position.quantity;
        tn += (price * (quantity ? quantity : 1));
      }
    }
    this.totalNetto = tn;
  }

  getTotalBrutto() {
    let tb = 0;
    for(let position of this.obj.bill_items){
      const price = position.price;
      if(price){
        const quantity = position.quantity;
        let tax = 0;
        switch(position.tax_rate){
          case 10: tax = 1.1; break;
          case 20: tax = 1.2; break;
          default: tax = 1; break;
        }
        tb += ((price * (quantity ? quantity : 1))*tax);
      }
    }
    this.totalBrutto = tb;
  }

  getTotalTaxes() {
    let t10 = 0;
    let t20 = 0;
    for(let position of this.obj.bill_items){
      const tax = position.tax_rate;
      const netto = this.getPosNetto(position);
      const brutto = this.getPosBrutto(position);
      if(tax == 10) t10 += (brutto - netto);
      else if(tax == 20) t20 += (brutto - netto);
    }
    if(t10 != 0) this.totalTax10 = t10;
    else this.totalTax10 = null;
    this.totalTax20 = t20;
  }

  getPosNetto(position: BillItem): number {
    let netto = 0;
    const price = position.price;
    if(price){
      const quantity = position.quantity;
      netto = ((price * (quantity ? quantity : 1)));
    }
    return netto;
  }

  getPosBrutto(position: BillItem): number {
    let brutto = 0;
    const price = position.price;
    if(price){
      let tax = 0;
      switch(position.tax_rate){
        case 10: tax = 1.1; break;
        case 20: tax = 1.2; break;
        default: tax = 1; break;
      }
      const quantity = position.quantity;
      brutto = ((price * (quantity ? quantity : 1)) * tax);
    }
    return brutto;
  }

  selectionCancelBill(obj: Bill) {
    this.dialogService.openQuery(
      {
        title: 'Rechnung stornieren',
        message: 'Sind sie sicher, dass Sie diese Rechnung stornieren möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Stornieren',
        typ: 'cancel_bill',
      }
    );
  }

  cancelBill() {
    let copy = CloneObject.deepCopy(this.obj);
    copy.status = BillStatus.STORNO;
    this.documentService.updateBill(copy).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Rechnung storniert.',
          success: true
        });
        this.successCancelEmitter.emit(result);
      }
    });
  }

  selectionRenewBill(obj: Bill) {
    this.dialogService.openQuery(
      {
        title: 'Rechnung neu ausstellen',
        message: 'Sind sie sicher, dass Sie diese Rechnung neu ausstellen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Neu ausstellen',
        typ: 'renew_bill',
      }
    );
  }

  renewBill() {
    let copy = CloneObject.deepCopy(this.obj);
    copy.status = BillStatus.STORNO;
    this.documentService.updateBill(copy).subscribe((resultCancel) => {
      if(resultCancel){
        resultCancel.bill_id = null;
        resultCancel.status = BillStatus.DRAFT;
        this.documentService.createBill(resultCancel).subscribe((resultNew) => {
          if(resultNew){
            this.dialogService.showNotification({
              title: 'Erfolgreich',
              message: 'Rechnung neu ausgestellt.',
              success: true
            });
            this.successRenewEmitter.emit({canceled: copy, created: resultNew});
          }
        })
      }
    });
  }

  selectionPaid() {
    let copy = CloneObject.deepCopy(this.obj);
    copy.status = BillStatus.PAID;
    this.documentService.updateBill(copy).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Ausgezeichnet',
          message: 'Rechnung Bezahlt.',
          success: true
        });
        this.successPaidEmitter.emit(result);
      }
    });
  }

  selectionPDFPrint() {
    this.documentService.getBillPdf(this.obj.bill_id).subscribe(() => {});
  }

  selectionPrevious() {
    this.setPagingDisabled();
    this.selectionPreviousObjEmitter.emit(this.obj);
  }

  selectionNext() {
    this.setPagingDisabled();
    this.selectionNextObjEmitter.emit(this.obj);
  }

  setPagingDisabled() {
    const btnPrevious = document.getElementById('btn-previous') as HTMLButtonElement;
    const btnNext = document.getElementById('btn-next') as HTMLButtonElement;
    if(btnPrevious && btnNext){
      btnPrevious.disabled = true;
      btnNext.disabled = true;
      setTimeout(() => {
        btnPrevious.disabled = false;
        btnNext.disabled = false;
      }, 500);
    }
  }
}
