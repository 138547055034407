<div id="dialog_window" *ngIf="show">
  <div id="dialog_container" appDropdown [show]="show" (changedValueEmitter)="show = $event">
      <div id="dialog_close_icon" (click)="show = false">&#10005;</div>
      <h1>Dokument erstellen</h1>
      <div id="dialog_conainter_body">
        <button class="btn_grey" (click)="submit(1)">Angebot</button>
        <button class="btn_grey" (click)="submit(2)">Vetrag</button>
        <button *ngIf="dataTyp == 2" class="btn_grey" (click)="submit(3)">Datenschutz</button>
      </div>
  </div>
</div>
