import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-dropdown-objects-search',
  templateUrl: './dropdown-objects-search.component.html',
  styleUrls: ['./dropdown-objects-search.component.css']
})
export class DropdownObjectsSearchComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() placeholder: string = '---';
  @Input() headervalue: string;
  @Input() enumvalue: number;
  @Input() formcontrol: FormControl;
  @Input() formcontrolTwo: FormControl;
  @Input() values: any[];
  @Input() submitted: boolean;
  @Input() disabled: boolean;
  @Input() stringPossible: boolean = false;
  @Input() return: string = 'ID';
  @Input() showLabel: boolean = true;
  @Input() inputId: string;
  @Output() submitValueEmitter = new EventEmitter<boolean>();
  @Output() submitValueStringEmitter = new EventEmitter<string>();
  public show = false;
  public searchActiveValue = null;
  public valuesFiltered = [];
  @ViewChild('input') input: ElementRef;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.valuesFiltered = this.values;
  }

  ngAfterViewInit(): void {
    if(this.headervalue){
      this.input.nativeElement.value = this.headervalue;
    } else if(this.enumvalue) {
      var enums = this.values.find(value => value.id == this.enumvalue);
      if(enums) {
        this.input.nativeElement.value = enums.name;
      } else {
        this.input.nativeElement.value = this.enumvalue;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.values && changes.values.currentValue){
      this.valuesFiltered = changes.values.currentValue;
      if(this.searchActiveValue != null){
        this.searchValues(this.searchActiveValue);
      }
    }
  }

  submit(value) {
    this.input.nativeElement.value = value.name;
    if(this.formcontrolTwo != null) this.formcontrolTwo.setValue(null);
    if(this.return == 'ID') this.submitValueEmitter.emit(value.id);
    else if(this.return == 'STRING') this.submitValueStringEmitter.emit(value.name);
  }

  isValidObjectId(): boolean {
    const id = Object.values(this.formcontrol.value)[0];
    if(id && !isNaN(Number(id))) return true
    else return false
  }

  searchValues(str: string) {
    this.valuesFiltered = [];
    if(str && str.length > 0){
      this.searchActiveValue = str;
      this.valuesFiltered = this.values.filter((value) => {
        return value.name.toLowerCase().includes(str.toLowerCase());
      });
    } else {
      this.searchActiveValue = null;
      this.valuesFiltered = this.values;
    }
  }

  setInputValue(str: string) {
    if(this.stringPossible && this.formcontrolTwo != null){
      this.formcontrol.setValue(null);
      this.formcontrolTwo.setValue(str);
    } else if(this.stringPossible && this.formcontrolTwo == null){
      this.formcontrol.setValue(null);
      this.submitValueStringEmitter.emit(str);
    } else if(str?.length == 0 && this.formcontrolTwo != null){
      this.formcontrol.setValue(null);
      this.formcontrolTwo.setValue(null);
    } else if(str?.length == 0){
      this.formcontrol.setValue(null);
      this.submitValueEmitter.emit(null);
    }
  }
}
