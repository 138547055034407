import { VehicleDrive } from "../VehicleDrive";

export class VehicleDriveFactory {
    static jsonFactory(rawData: any): VehicleDrive[] {
        let dataAry: VehicleDrive[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehicleDrive {
        let object = new VehicleDrive(
            rawData.drive_id,
            rawData.drive_name,
        );
        return object;
    }
}