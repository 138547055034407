import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Customer } from 'src/app/buisness-object/customer/Customer';

@Component({
  selector: 'app-create-bill-page-one',
  templateUrl: './create-bill-page-one.component.html',
  styleUrls: ['./create-bill-page-one.component.css'],
})
export class CreateBillPageOneComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() customers: Customer[];

  public showDropdown_1 = false;
  public isEdit = false;

  @ViewChild('dropdown') dropdown: ElementRef;
  public listener: () => void;

  constructor() { }

  ngOnInit(): void {

  }

  setCustomer(customer: Customer) {
    this.form.controls.customer.setValue(customer);
    this.form.controls.contact_person.setValue(customer.contactPerson);
  }
}
