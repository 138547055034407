import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ThreadFactory } from 'src/app/buisness-object/message/factory/ThreadFactory';
import { Message } from 'src/app/buisness-object/message/Message';
import { Thread } from 'src/app/buisness-object/message/Thread';
import { User } from 'src/app/buisness-object/user/User';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ThreadService {
  public notify_request$  = new BehaviorSubject<any>(0);

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) { }

  getOpenThreads(): Observable<Thread[]>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.get(environment.api + '/back/thread/me', { headers });
    return observable.pipe(
      map((rawThread: any) => {
        const threads = ThreadFactory.jsonFactory(rawThread.threads);
        this.notify_request$.next(threads.length);
        return threads;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    )
  }

  getArchived(): Observable<Thread[]>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.get(environment.api + '/back/thread/me/archive', { headers });
    return observable.pipe(
      map((rawThread: any) => {
        const threads = ThreadFactory.jsonFactory(rawThread.threads, true);
        return threads;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    )
  }

  getOpenThreadsByTyp(typ: string, id: number): Observable<Thread[]>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.get(environment.api + '/back/thread/'+typ+'/' + id, { headers });
    return observable.pipe(
      map((rawThread: any) => {
        const threads = ThreadFactory.jsonFactory(rawThread.threads);
        return threads;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    )
  }

  createThread(thread: Thread): Observable<Thread>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    let messages = [];
    thread.messages.forEach(msg => {
      messages.push({message: msg.message, timestamp: msg.timestamp});
    });
    const observable = this.http.post(environment.api + '/back/thread',
    {
      thread: {
        typ: thread.typ,
        typ_id: thread.typId,
        messages: messages,
      }
    }, { headers });
    return observable.pipe(
      map((rawThread: any) => {
        const thread = ThreadFactory.jsonFactoryOne(rawThread.thread);
        return thread;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    )
  }

  createMesaage(msg: string, threadId: number): Observable<Thread>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.post(environment.api + '/back/thread/' + threadId + '/message',
    {
      message: msg
    }, { headers });
    return observable.pipe(
      map((rawThread: any) => {
        const thread = ThreadFactory.jsonFactoryOne(rawThread.thread);
        return thread;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    )
  }

  resolveThread(threadId: number): Observable<boolean>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.put(environment.api + '/back/thread/' + threadId + '/resolve', {},{ headers });
    return observable.pipe(
      map((rawThread: any) => {
        return true;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    )
  }

  deleteThread(threadId: number): Observable<boolean>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.delete(environment.api + '/back/thread/' + threadId, { headers });
    return observable.pipe(
      map((rawThread: any) => {
        return true;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    )
  }

  clearNotification(threadId: number): Observable<boolean>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.delete(environment.api + '/back/thread/' + threadId + '/clear', { headers });
    return observable.pipe(
      map((rawThread: any) => {
        return true;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    )
  }

  getNotifications(): Observable<Thread[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.get(environment.api + '/back/thread/notification', { headers });
    return observable.pipe(
      map((rawThreads: any) => {
        const threads = ThreadFactory.jsonFactory(rawThreads.threads);
        return threads;
      }),
      catchError(error => {
        if(error.status = 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    )
  }
}
