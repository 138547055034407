<div id="overview_container">
    <form id="grid" [formGroup]="editFormGroup">
        <div class="title">User bearbeiten</div>
        <div id="left_container" class="column">
            <div class="flex">
                <div class="inner_title">Status</div>
                <div class="state-container" [ngClass]="f.active.value ? 'state_active' : 'state_inactive'" (click)="setActive()">{{f.active.value ? 'Aktiv' : 'Inaktiv'}}</div>
            </div>
            <div class="inner_title" [ngStyle]="{'margin-top':'10%'}">Userdaten</div>

            <div>
                <div class="lbl_regular" [ngStyle]="{'opacity': f.firstName.value ? '1' : '0'}">Vorname *</div>
                <input type="text" class="inputfield" [ngClass]="submitted && f.firstName.errors ? 'inputfield_invalid' : ''" placeholder="Vorname *" formControlName="firstName" (ngModel)="f.firstName.value" (ngModelChange)="submitted = false">
            </div>
            <div>
                <div class="lbl_regular" [ngStyle]="{'opacity': f.lastName.value ? '1' : '0'}">Nachname *</div>
                <input type="text" class="inputfield" [ngClass]="submitted && f.lastName.errors ? 'inputfield_invalid' : ''" placeholder="Nachname *" formControlName="lastName" (ngModel)="f.lastName.value" (ngModelChange)="submitted = false">
            </div>
            <!-- <div class="input_lbl" [ngStyle]="{'margin-top':'5%'}">Vorname *</div>
            <input type="text" class="inputfield" [ngClass]="submitted && f.firstName.errors ? 'inputfield_invalid' : ''" placeholder="Vorname *" formControlName="firstName" (ngModel)="f.firstName.value" (ngModelChange)="submitted = false">
            <div  class="input_lbl">Nachname *</div>
            <input type="text" class="inputfield" [ngClass]="submitted && f.lastName.errors ? 'inputfield_invalid' : ''" placeholder="Nachname *" formControlName="lastName" (ngModel)="f.lastName.value" (ngModelChange)="submitted = false"> -->
            <div  class="input_lbl">Standort *</div>
            <div #dropdownInputContainer id="dropdown_outer_container">
                <div class="input_dropdown_container" [class]="dropLocationSelected ? 'input_dropdown_container_active' : ''" (click)="dropLocationSelected = !dropLocationSelected" >
                    <div class="dropdown_input" [class]="dropLocationSelected ? 'dropdown_input_active' : ''">{{locationTxt}}</div>
                    <img class="dropdown_arrow" src="../../../../assets/arrow-dark.svg" [class.drop-arrow-rotate]="dropLocationSelected">
                </div>
                <div class="dropdown_container" [ngStyle]="{'display': dropLocationSelected ? 'block' : 'none'}">
                    <div class="dropbox_value" *ngFor="let loc of locations" (click)="addLocation(loc.locationId); dropLocationSelected = false;">{{loc.city}} {{loc.postCode}}</div>
                </div>
            </div>
            <div>
                <div class="inner_title" [ngStyle]="{'margin-top': '10%'}">Rollen</div>
                <div class="radio_container" *ngFor="let role of roles" (click)="administerRole(role)">
                    <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="hasRole(role.roleId) ? 'radio_icon_inner_active' : ''"></div></div>
                    <div class="lbl_info">{{role.role}}</div>
                </div>
            </div>
        </div>
        <div id="right_container" class="column">
            <div id="img_container">
                <div id="user_initials" class="text">{{getInitials()}}</div>
            </div>
            <div>
                <div class="lbl_regular" [ngStyle]="{'opacity': f.username.value ? '1' : '0'}">Benutzername *</div>
                <input type="text" class="inputfield" [ngClass]="submitted && f.username.errors ? 'inputfield_invalid' : ''" placeholder="Benutzername *" formControlName="username" (ngModel)="f.username.value" (ngModelChange)="submitted = false">
            </div>
            <!-- <div  class="input_lbl">Passwort *</div>
            <input type="text" class="inputfield" placeholder="Passwort *">
            <div class="lbl_change">ändern</div> -->
        </div>
    </form>
</div>
