import { Thread } from "../Thread";
import { MessageFactory } from "./MessageFactory";

export class ThreadFactory {
    static jsonFactory(rawBody: any, archived?: boolean): Thread[] {
        const threads: Thread[] = [];
        for(let rawThread of rawBody){
          if(archived) threads.push(this.jsonFactoryOneArchived(rawThread));
          else threads.push(this.jsonFactoryOne(rawThread));
        }
        return threads;
    }

    static jsonFactoryOne(rawThread: any): Thread {
        const thread = new Thread(
            rawThread.thread_id,
            rawThread.location_id,
            rawThread.creator,
            rawThread.typ,
            rawThread.status,
            rawThread.urgency,
            rawThread.notify_open,
            rawThread.created,
            rawThread.typ_id,
            rawThread.resolved,
            MessageFactory.jsonFactory(rawThread.messages)
        );
        return thread;
    }

    static jsonFactoryOneArchived(rawThread: any): Thread {
      const thread = new Thread(
          rawThread.thread_id,
          rawThread.location_id,
          rawThread.creator,
          rawThread.typ,
          rawThread.status,
          rawThread.urgency,
          rawThread.notify_open,
          rawThread.created,
          rawThread.typ_id,
          rawThread.resolved,
          MessageFactory.jsonFactory(rawThread.messages)
      );
      thread.archived = true;
      return thread;
  }
}
