import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2, SimpleChange, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { NumberFormatPipe } from 'src/app/helpers/NumberFormatPipe.pipe';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BaseBoxComponent } from 'src/app/view/BaseBoxComponent';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.css'],
  providers: [NumberFormatPipe],
  animations: [ DropdownIconArrowAnimation ]
})
export class IdentificationComponent extends BaseBoxComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() vehicle: Vehicle;
  @Input() formGroupVehicle: UntypedFormGroup;
  @Input() submitted: boolean;
  public showDropdownOne = false;
  public inseratDisable = false;
  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  public listener: () => void;

  constructor(
    private renderer: Renderer2,
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.viewTitle = "identification";
    super.init();
    if(this.formGroupVehicle){
      this.inseratDisable = this.authService.isHoedlAdmin() ? false : this.formGroupVehicle.get('inserat_checked').value;
    }
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window', 'click', (e: Event) => {
      if(this.showDropdownOne && !this.dropdownOne.nativeElement.contains(e.target)) this.showDropdownOne = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.editMode || changes.formGroupVehicle){
      if(this.editMode && this.formGroupVehicle != null){
        this.formGroupVehicle.get('inserat_checked').valueChanges.subscribe(value => {
          this.inseratDisable = this.authService.isHoedlAdmin() ? false : value;
        });
      }
    }
  }

  get f() {
    return this.formGroupVehicle.controls;
  }
}
