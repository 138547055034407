import { Attachment, AttachmentFactory } from "../../attachment/Attachment";
import { CustomerFactory } from "../../customer/factory/CustomerFactory";
import { VehicleBrand } from "../../vehicle/basic/VehicleBrand";
import { VehicleModel } from "../../vehicle/basic/VehicleModel";
import { VehicleBrandFactory } from "../../vehicle/basic/factory/VehicleBrandFactory";
import { VehicleModelFactoy } from "../../vehicle/basic/factory/VehicleModelFactoy";
import { VehicleFactory } from "../../vehicle/factory/VehicleFactory";
import { SelektiertesZusatzprodukt } from "../../zusatzprodukt/SelektiertesZusatzprodukt";
import { SelektiertesZusatzproduktFactory } from "../../zusatzprodukt/factory/SelektiertesZusatzproduktFactory";
import { Kaufvertrag, TradeInVehicle, VehicleStatus } from "../Kaufvertrag";

export class KaufvertragFactory {
  static jsonFactory(rawBody: any): Kaufvertrag[] {
    const data: Kaufvertrag[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): Kaufvertrag {
        const obj = new Kaufvertrag(
          rawPart.purchase_contract_id,
          rawPart.customer_id,
          rawPart.vehicle_id,
          rawPart.purchase_date,
          rawPart.handing_over_date,
          rawPart.price,
          rawPart.registration_document,
          rawPart.is_original_document,
          VehicleStatusFactory.jsonFactoryOne(rawPart.vehicle_status),
          rawPart.payment_condition,
          rawPart.status,
          rawPart.foreign_registration_date,
          rawPart.purchase_test_date,
          rawPart.purchase_tester,
          rawPart.warranty_period,
          rawPart.deposit,
          (rawPart.old_vehicle && rawPart.old_vehicle?.brand?.make.make_name != null) ? TradeInVehicleFactory.jsonFactoryOne(rawPart.old_vehicle) : null,
          rawPart.special_conditions,
          rawPart.finanzierungsgesellschaft,
          rawPart.finanzierungsart,
          rawPart.ready_for_operation,
          rawPart.test_drive,
          rawPart.spare_wheel,
          rawPart.honey_comb_lifter,
          rawPart.remote_control,
          rawPart.card_material,
          rawPart.trailer_hitch,
          rawPart.rim_lock,
          rawPart.down_payment,
          rawPart.valid_date,
          rawPart.employee_id,
          rawPart.finanzierungsart_anzahlung,
          rawPart.down_payment_condition,
          rawPart.payment_status, //0 offen / 1 anzahlung erfolgt /2 anzahlung und finale
          rawPart.handed_over_date,
          rawPart.sign_date,
          rawPart.creator_employee_id,
          rawPart.kv_nr_month,
        )
        obj.saved_vehicle_id = rawPart.saved_vehicle_id;
        obj.selektierteZusatzprodukte = SelektiertesZusatzproduktFactory.jsonFactory(rawPart);
        if(rawPart.customer) obj.customer = CustomerFactory.jsonFactoryOne(rawPart.customer);
        if(rawPart.vehicle) obj.vehicle = VehicleFactory.jsonFactoryOne(rawPart.vehicle, false);
        if(rawPart.attachments) obj.attachments = AttachmentFactory.jsonFactory(rawPart.attachments);
        if(rawPart.bill_recipient) obj.bill_recipient = CustomerFactory.jsonFactoryOne(rawPart.bill_recipient);
        obj.custom_bill_ids = rawPart.custom_bill_ids ? rawPart.custom_bill_ids : [];
        return obj;
  }

}

export class VehicleStatusFactory {
  static jsonFactoryOne(rawPart: any): VehicleStatus {
        return new VehicleStatus(
          rawPart.mechanical_condition,
          rawPart.body_condition,
          rawPart.paint_condition,
          rawPart.interior_condition,
          rawPart.electronic_condition,
        )
  }
}

export class TradeInVehicleFactory {
  static jsonFactoryOne(rawPart: any): TradeInVehicle {
        return new TradeInVehicle(
          rawPart.brand ? VehicleBrandFactory.jsonFactoryOne(rawPart?.brand.make) : null,
          rawPart.brand ? VehicleModelFactoy.jsonFactoryOne(rawPart?.brand.model) : null,
          rawPart.registration_document,
          rawPart.mileage,
          rawPart.price,
          rawPart.registration_date,
          rawPart.previous_owner,
          rawPart.fin_number,
          rawPart.mileage_to_drive,
          rawPart.color,
          rawPart.fuel,
          rawPart.nat,
          rawPart.includes_tax,
          rawPart.reduce_price
        )
  }
}
