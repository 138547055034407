<div class="dialog-screen">
  <form class="dialog-box" [formGroup]="form">
    <h2>Neuer Kassabuch Eintrag</h2>
    <div class="dialog-box-body">
      <app-dropdown-strings
        [title]="'Ein- bzw. Auszahlung'"
        [formcontrol]="form.get('typ')"
        [headerText]="form.get('typ').value"
        [values]="typValues"
        [inputReadonly]="true"
        (submitValueEmitter)="form.get('typ').setValue($event)"
      ></app-dropdown-strings>
      <!-- <app-dropdown-multiple
        [title]="'Ein- bzw. Auszahlung'"
        [formcontrol]="form.get('typ')"
        [headerText]="typ"
        [values]="['Einzahlung', 'Auszahlung']"
        [isOptional]="false"
        (submitValueEmitter)="form.controls['typ'].setValue($event); typ = form.get('typ').value == 1 ? 'Einzahlung':'Auszahlung'"
      ></app-dropdown-multiple> -->
      <div class="input_wrapper">
        <label class="overview_record_lbl">Datum</label>
        <input #inputDate type="date" placeholder="date" [max]="today" formControlName="date" [class.date-input--has-value]="inputDate.value != null">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Betrag</label>
        <input type="number" placeholder="---" formControlName="amount">
      </div>
      <app-dropdown-strings
        [title]="'Beschreibung'"
        [formcontrol]="form.get('subject')"
        [headerText]="form.get('subject').value"
        [values]="subjectValues"
        [inputReadonly]="true"
        (submitValueEmitter)="form.get('subject').setValue($event)"
      ></app-dropdown-strings>
      <div class="input_wrapper" *ngIf="form.get('subject').value == ledgerEntrySubject.INDIVIDUELL">
        <label class="overview_record_lbl"></label>
        <input type="text" placeholder="---" formControlName="individuell_subject">
      </div>
    </div>
    <div class="dialog-box-button-wrapper">
        <button class="btn_label" type="button" (click)="cancel()">Abbrechen</button>
        <button class="btn_submit_dark" type="button" [disabled]="form.invalid" (click)="submit()">Hinzufügen</button>
    </div>
    <img (click)="cancel()" class="dialog-box-button-close" src="./assets/closecross-white.svg">
  </form>
</div>
