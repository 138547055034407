import { VehicleTransmission } from "../VehicleTransmission";

export class VehicleTransmissionFactory {
    static jsonFactory(rawData: any): VehicleTransmission[] {
        let dataAry: VehicleTransmission[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehicleTransmission {
        let object = new VehicleTransmission(
            rawData.transmission_id,
            rawData.transmission_name,
        );
        return object;
    }
}