import { Employee } from "../employee/Employee";
import { User } from "../user/User";

export class Message {
    // public user: User;
    public employee: Employee
    constructor(
        public messageId: number,
        public employeeId: number,
        public message: string,
        public timestamp: number
    ){}


    getMessageText(employees: Employee[], len?: number): string {
        let newMessage = this.message;
        let splitted = this.message.split("@employee_id=");
        let employeesIncluded: Employee[] = [];
        if(splitted.length > 1) {
            for(let i = 1; i < splitted.length; i++) {
                let subSplitted = splitted[i].split("@");
                if(subSplitted.length > 1) {
                    let isIncluded = false;
                    for(let employee of employeesIncluded) {
                        if(employee.id == Number(subSplitted[0])) {
                            isIncluded = true;
                            break;
                        }
                    }
                    if(!isIncluded) {
                        employeesIncluded.push(new Employee(Number(subSplitted[0]), "", "", "", "", 1, 1, true));
                        for(let employee of employees) {
                            if(employee.id == Number(subSplitted[0])) {
                                newMessage = this.replaceAll(newMessage, "@employee_id="+employee.id+"@", "<b>@" + employee.firstName + " " + employee.lastName +"</b>");
                                break;
                            }
                        }
                    }
                }
            }
            return newMessage;
        } else {
            return this.message;
        }
    }

  private cutMessage(str: string, len: number): string {
    if(str){
        if(str.length > len){
            return str.slice(0,len) + ' ...'
        } else {
            return str;
        }
    } else {
        return '';
    }
  }

    private replaceAll(searchString: string, find: string, replace: string): string {
        return searchString.replace(new RegExp(find, 'g'), replace);
    }
}
