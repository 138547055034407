import { Component, Input, OnInit } from '@angular/core';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  @Input() vehicle: Vehicle;

  constructor() { }

  ngOnInit(): void {
  }

}
