<div class="list_container" *ngIf="activeSubMenu != null">
    <app-list-title
        [isTitle]="true"
        [pageInfo]="activeSubMenu?.pagination ? pageHandler?.getLabel() : objects.length"
        [showInformation]="showInformation"
        [sliceTo]="pageHandler?.sliceTo"
        [sliceFrom]="pageHandler?.sliceFrom"
        [amount]="objects.length"
        [objects]="objects"
        [objectTyp]="objectTyp"
        [sortObject]="sortObject"
        [filterForm]="filterForm"
        [mainMenus]="mainMenus"
        [activeSubMenu]="activeSubMenu"
        [activeSubMenu$]="activeSubMenu$"
        [activeFilter]="activeFilter"
        [employees]="employees"
        (pageEmitter)="pageEmitter.emit($event)"
        (changeShownDataEmitter)="changeListTabEmitter.emit($event)"
        (applySortEmitter)="sortEmitter.emit()"
        (removeFilterValueEmitter)="removeFilterValueEmitter.emit($event)"
        (applySpecialFilter)="applySpecialFilter.emit($event)"
        (customFilterEmitter)="customFilterEmitter.emit($event)"
        (customFilterOutsideEmitter)="customFilterOutsideEmitter.emit($event)"
    ></app-list-title>
    <div id="ab-item-list" class="list-item ab-list-item app-list-no-scroll">
      <div id="submenu-column-wrapper-title" [style]="activeSubMenu?.getColumnWrapperStyleHeaderFooter('ab-item-list', 'submenu-column-wrapper-title')">
        <div *ngFor="let column of activeSubMenu?.columns; let i = index"
          [class.column-data-is-fixed]="column.is_fixed"
          [ngStyle]="{'left': column.is_fixed ? activeSubMenu?.getColumnFixedLeftPos(i)+'em' : 0}"
          [class.column-data-first-item]="i == 0 ? true : false">
          <button class="submenu-column-title-btn" [class.submenu-column-title-btn-disabled]="!column?.is_sortable" (click)="column?.is_sortable ? applySortingEmitter.emit(column) : null">
            {{column.title}}
            <img *ngIf="column?.is_sortable" [src]="column?.data_key == activeSortingColumn?.data_key ? activeSortingColumn.getSortIndicator() : column.getSortIndicator()">
          </button>
        </div>
      </div>
      <div class="item-list-wrapper">
        <div id="item-list">
          <app-list-element class="app-list-element"
            *ngFor="let object of objects | slice:(activeSubMenu?.pagination?pageHandler?.sliceFrom:0):(activeSubMenu?.pagination?pageHandler?.sliceTo:objects.length); let i = index; let l = last"
            [callback]="updateListLoader"
            [callback-condition]="l"
            [index]="i"
            [maxIndex]="pageHandler?.sliceTo"
            [object]="object"
            [accessEdit]="accessEdit"
            [activeSubMenu]="activeSubMenu"
            [activeSubMenu$]="activeSubMenu$"
            [showInformation]="showInformation"
            (openDetailsEmitter)="openDetailsEmitter.emit($event)"
            (openEditEmitter)="openEditEmitter.emit($event)"
            (deleteEmitter)="deleteEmitter.emit($event)"
            (moveEmitter)="moveEmitter.emit($event)"
            (printPdfEmitter)="printPdfEmitter.emit($event)"
            (printEtikettEmitter)="printEtikettEmitter.emit($event)"
            (sellEmitter)="sellEmitter.emit($event)"
            (selectionCreateDokumentEmitter)="selectionCreateDokumentEmitter.emit($event)"
            (selectionCopyEmitter)="selectionCopyEmitter.emit($event)"
            (selectionUploadDocumentEmitter)="selectionUploadDocumentEmitter.emit($event)"
            (selectionPseudoSignatureEmitter)="selectionPseudoSignatureEmitter.emit($event)"
            (selectionCreateKaufvertragEmitter)="selectionCreateKaufvertragEmitter.emit($event)"
            (calculatePricesheetEmitter)="calculatePricesheetEmitter.emit($event)"
            (selectionArchivEmitter)="selectionArchivEmitter.emit($event)"
            (selectionPDFCreateEmitter)="selectionPDFCreateEmitter.emit($event)"
            (selectionPDFOpenEmitter)="selectionPDFOpenEmitter.emit($event)"
            (selectionTestDrivePDFSignEmitter)="selectionTestDrivePDFSignEmitter.emit($event)"
            (selectionContractPDFUnsigendSignedEmitter)="selectionContractPDFUnsigendSignedEmitter.emit($event)"
            (selectionRestoreEmitter)="selectionRestoreEmitter.emit($event)"
            (selectionChangeStatusEmitter)="selectionChangeStatusEmitter.emit($event)"
            (selectionChangePaymentStatusEmitter)="selectionChangePaymentStatusEmitter.emit($event)"
            (selectionAppSignatureEmitter)="selectionAppSignatureEmitter.emit($event)"
            (receiveFundingEmitter)="receiveFundingEmitter.emit($event)"
            (createCreditEmitter)="createCreditEmitter.emit($event)"
            (changeFinanzsperrauskunftEmitter)="changeFinanzsperrauskunftEmitter.emit($event)"
            (changeFinanzsperrauskunftStatusEmitter)="changeFinanzsperrauskunftStatusEmitter.emit($event)"
            (changeAustrianDataExtractEmitter)="changeAustrianDataExtractEmitter.emit($event)"
          ></app-list-element>
        </div>
        <div id="ab-item-list-loader-wrapper" *ngIf="showListIsRendering">
          <div class="ab-item-list-loader"></div>
        </div>
      </div>
      <div *ngIf="objects.length > 0" id="submenu-column-wrapper-footer" [style]="activeSubMenu?.getColumnWrapperStyleHeaderFooter('ab-item-list', 'submenu-column-wrapper-footer')">
        <div *ngFor="let column of activeSubMenu?.columns; let i = index"
          [class.column-data-is-fixed]="column.is_fixed"
          [ngStyle]="{'left': column.is_fixed ? activeSubMenu?.getColumnFixedLeftPos(i)+'em' : 0}"
          [class.column-data-first-item]="i == 0 ? true : false">
          <label *ngIf="column.sum_typ" class="submenu-column-label-footer">{{getSum(column)}}</label>
        </div>
      </div>
      <div id="ab-empty-list-wrapper" *ngIf="objects.length == 0">
        <img *ngIf="objectTyp == 'vehicle'" class="ab-empty-list-icon" src="./assets/empty-fahrzeuge.svg">
        <img *ngIf="objectTyp == 'document'" class="ab-empty-list-icon" src="./assets/empty-dokumente.svg">
        <img *ngIf="objectTyp == 'employee'" class="ab-empty-list-icon" src="./assets/empty-kunden.svg">
        <img *ngIf="objectTyp == 'customer'" class="ab-empty-list-icon" src="./assets/empty-kunden.svg">
        <img *ngIf="objectTyp == 'ledgerentry'" class="ab-empty-list-icon" src="./assets/empty-buchhaltung.svg">
        <img *ngIf="objectTyp == 'bill'" class="ab-empty-list-icon" src="./assets/empty-buchhaltung.svg">
        <img *ngIf="objectTyp == 'tire'" class="ab-empty-list-icon" src="./assets/empty-reifen.svg">
        <img *ngIf="objectTyp == 'test_drive'" class="ab-empty-list-icon" src="./assets/emtpy-planung.svg">
        <img *ngIf="objectTyp == 'rent'" class="ab-empty-list-icon" src="./assets/emtpy-planung.svg">
        <img *ngIf="objectTyp == 'location'" class="ab-empty-list-icon" src="./assets/empty.svg">
        <label id="ab-item-list-empty-lbl">{{emptyListText}}</label>
      </div>
    </div>
</div>

<!-- <div *ngIf="showListIsRendering" id="menu-loading">
  <div id="menu-loader"></div>
</div> -->
