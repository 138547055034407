import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-contract-vehicle-condition',
  templateUrl: './contract-vehicle-condition.component.html',
  styleUrls: ['./contract-vehicle-condition.component.css']
})
export class ContractVehicleConditionComponent implements OnInit {
  public subscription: Subscription;
  public show = false;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openDialogContractCarCondition$.subscribe((data: any) => {
      this.show = true;
    })
  }

  ngOnInit(): void {
  }

  close() {
    this.show = false;
  }
}
