import { Component, EventEmitter, OnInit, Output } from '@angular/core';

export enum TyreSellTyp {
  SELL = 'sell',
  SELLANDINSTALLATION = 'sell_and_installation'
}

@Component({
  selector: 'app-dialog-tyres-sell',
  templateUrl: './dialog-tyres-sell.component.html',
  styleUrl: './dialog-tyres-sell.component.css'
})
export class DialogTyresSellComponent  implements OnInit {
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() selectionSubmitEmitter = new EventEmitter<any>();
  public selectedTyp = TyreSellTyp.SELL;
  public sellTyp = TyreSellTyp;

  constructor(){}

  ngOnInit(): void {

  }

  selectionSubmit() {
    this.selectionSubmitEmitter.emit(this.selectedTyp);
  }
}
