import { Directive, Input, OnInit } from "@angular/core";
import { BaseComponent } from "./BaseComponent";
import { SubMenu } from "../buisness-object/menu/SubMenu";
import { AuthenticationService } from "../service/authentication/authentication.service";
import { EnumService } from "../service/enum/enum.service";

@Directive()
export class BaseBoxComponent extends BaseComponent {
    @Input() activeSubMenu: SubMenu;
    @Input() superGroup: number;
    @Input() editMode: boolean;
    public viewTitle = "";
    public viewAuth: any;
    public collapsed: boolean = true;

    constructor(
        authSerivceBase: AuthenticationService,
        enumService: EnumService
      ){
        super(authSerivceBase, enumService);
      }

    init(): void {
        this.viewAuth = this.getViewAuth(this.activeSubMenu, this.superGroup, this.viewTitle);
        if(this.viewAuth.view.collapsed === false){
            this.collapsed = false;
        }
    }

    showValue(value: string): boolean {
        return this.showValueBased(this.viewAuth, this.editMode, value);
    }

}