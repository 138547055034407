<form class="drive_create_page_wrapper" [formGroup]="form">
  <div id="page-container">
          <div class="overview_data_box">
              <div class="overview_data_box_lbl_title">Kennzeichen</div>
              <app-dropdown-multiple
              [title]="'Kennzeichen *'"
              [formcontrol]="form.controls['license_plate_id']"
              [headerText]="selectedPlateValue"
              [values]="platesValues"
              [submitted]="submitted"
              (submitValueEmitter)="setPlate($event);"
          ></app-dropdown-multiple>
          </div>
          <div class="overview_data_box">
              <div class="overview_data_box_lbl_title">Fahrzeug</div>
              <app-dropdown-objects-search
                  [title]="'Fahrzeug *'"
                  [placeholder]="'Fahrzeug'"
                  [headervalue]="form.controls['vehicle'].value ? form.controls.vehicle.value.getVehicleName() : ''"
                  [formcontrol]="form.controls['vehicle']"
                  [values]="vehicleValues"
                  [submitted]="submitted"
                  (submitValueEmitter)="setValueFromDropdown('vehicle', $event)"
              ></app-dropdown-objects-search>
          </div>
          <div class="overview_data_box" *ngIf="form.controls['drive_typ'].value == 1">
              <div class="overview_data_box_lbl_title">Kunde</div>
              <app-dropdown-objects-search
                  [title]="'Kunde*'"
                  [placeholder]="'Kunde'"
                  [headervalue]="form.controls['customer']?.value?.getName()"
                  [formcontrol]="form.controls['customer']"
                  [values]="customerValues"
                  [submitted]="submitted"
                  [showLabel]="false"
                  (submitValueEmitter)="setValueFromDropdown('customer', $event)"
              ></app-dropdown-objects-search>
          </div>
          <div class="overview_data_box" *ngIf="form.controls['drive_typ'].value == 2">
              <div class="overview_data_box_lbl_title">Mitarbeiter</div>
              <app-dropdown-objects-search
                  [title]="'Mitarbeiter *'"
                  [placeholder]="'Mitarbeiter'"
                  [headervalue]="form.controls['employee'].value ? form.controls['employee'].value.getFullName() : ''"
                  [formcontrol]="form.controls['employee']"
                  [values]="employeeValues"
                  [submitted]="submitted"
                  (submitValueEmitter)="setValueFromDropdown('employee', $event)"
              ></app-dropdown-objects-search>
          </div>
          <div class="overview_data_box">
              <div class="overview_data_box_lbl_title">Details zur Probefahrt</div>
              <div class="input_wrapper">
                  <div class="overview_record_lbl">Datum *</div>
                  <input #inputDate1 type="date" placeholder="date"  formControlName="start_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls['start_date'].errors">

              </div>
              <div class="input_wrapper">
                  <div class="overview_record_lbl">Uhrzeit *</div>
                  <input #inputDate2 type="time" placeholder="time"  formControlName="start_time" [class.date-input--has-value]="inputDate2.value != null" [class.input_invalid]="submitted && form.controls['start_time'].errors">
              </div>
              <div class="input_wrapper">
                  <div class="overview_record_lbl">Dauer in Min. *</div>
                  <input #inputDate3 type="number" placeholder="Dauer"  formControlName="duration" [class.date-input--has-value]="inputDate3.value != null" [class.input_invalid]="submitted && form.controls['duration'].errors">
              </div>
              <div class="input_wrapper">
                  <div class="overview_record_lbl">Ziel der Probefahrt *</div>
                  <input #inputDate4 type="text" placeholder="Von - Nach"  formControlName="trip_description" [class.date-input--has-value]="inputDate4.value != null" [class.input_invalid]="submitted && form.controls['trip_description'].errors">
              </div>
          </div>
  </div>
</form>
