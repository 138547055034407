import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Role } from 'src/app/buisness-object/employee/Role';
import { DialogService } from '../dialog/dialog.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService  {
  public roles: Role[] = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private dService: DialogService,
    private authService: AuthenticationService
  ) { }

  public login(username: string, password: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(username + ':' + password)
    });
    const observable =  this.http.post(environment.api + '/employeesession', JSON.stringify([]), { headers });
    return observable.pipe(
      map((result: any) => {
        let name = {
          f: result.user.first_name,
          l: result.user.last_name,
        }
        if(result.location?.shop_link) {
          localStorage.setItem('shop_link', result.location?.shop_link);
        } else {
          localStorage.removeItem('shop_link');
        }
        for(let mainMenu of result.menus) {
          for(let subMenu of mainMenu.sub_menus) {
            subMenu.columns = subMenu.columns.sort((c1, c2) => c1.sort_value - c2.sort_value);
          }
          mainMenu.sub_menus = mainMenu.sub_menus.sort((s1, s2) => s1.sort_value - s2.sort_value);
        }
        localStorage.setItem('token', result.token);
        localStorage.setItem('employee_id', result.user.employee_id);
        localStorage.setItem('location_id', result.user.location_id);
        localStorage.setItem('mandant_id', result.user.mandant_id);
        localStorage.setItem('permission_group_id', result.user.permission_group_id);
        localStorage.setItem('enums', JSON.stringify(result.enums));
        localStorage.setItem('roles', JSON.stringify(result.user.roles));
        localStorage.setItem('permissions', JSON.stringify(result.user.permissions));
        localStorage.setItem('user', JSON.stringify(name));
        localStorage.setItem('email', result.user.email);
        localStorage.setItem('location_name', result.location.name);
        this.authService.setPermissions(result.user.permissions);
        this.authService.setTenantId(result.user.mandant_id);
        let menu_documents_standard = [
          {
            'main_menu_id': 100,
            'name': 'Kaufverträge',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 1001,
                "name": "Offen",
                "actions": ['details','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "Nr.",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "Kunde",
                    "data_object": "purchase_contract",
                    "data_key": "getCustomerName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Betreff",
                    "data_object": "purchase_contract",
                    "data_key": "getReferenz",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 30,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "ENUM",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#D2B836"
                                    },
                                    {
                                       "value": "Unterzeichnet",
                                       "color": "#6A9E18"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#D84949"
                                    },
                                    {
                                      "value": "Archiviert",
                                      "color": "#ABABAB"
                                    },
                                ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 0,
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1001,
                "name": "Abgeschlossen",
                "actions": ['details','pseudo-singature-kaufvertrag','upload-kaufvertrag'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "Nr.",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "Kunde",
                    "data_object": "purchase_contract",
                    "data_key": "getCustomerName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Betreff",
                    "data_object": "purchase_contract",
                    "data_key": "getReferenz",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10015,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "ENUM",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#D2B836"
                                    },
                                    {
                                       "value": "Unterzeichnet",
                                       "color": "#6A9E18"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#D84949"
                                    },
                                    {
                                      "value": "Archiviert",
                                      "color": "#ABABAB"
                                    },
                                ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 70,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 1,
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1001,
                "name": "Storniert",
                "actions": ['details','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "Nr.",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "Kunde",
                    "data_object": "purchase_contract",
                    "data_key": "getCustomerName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Betreff",
                    "data_object": "purchase_contract",
                    "data_key": "getReferenz",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 30,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 2,
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1001,
                "name": "Archiviert",
                "actions": ['details','restore','delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "Nr.",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "Kunde",
                    "data_object": "purchase_contract",
                    "data_key": "getCustomerName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Betreff",
                    "data_object": "purchase_contract",
                    "data_key": "getReferenz",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 30,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 10,
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
            ],
          }
        ]
        let menu_documents_sinhuber = [
          {
            'main_menu_id': 100,
            'name': 'Kaufverträge',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 1001,
                "name": "Offen",
                "actions": ['details','app-signature','upload-kaufvertrag', 'archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "Nr.",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "Kunde",
                    "data_object": "purchase_contract",
                    "data_key": "getCustomerName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Betreff",
                    "data_object": "purchase_contract",
                    "data_key": "getReferenz",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 30,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 0,
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1001,
                "name": "Abgeschlossen",
                "actions": ['details','pseudo-singature-kaufvertrag','upload-kaufvertrag'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "Nr.",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "Kunde",
                    "data_object": "purchase_contract",
                    "data_key": "getCustomerName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Betreff",
                    "data_object": "purchase_contract",
                    "data_key": "getReferenz",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10015,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 70,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 1,
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1001,
                "name": "Storniert",
                "actions": ['details','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "Nr.",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "Kunde",
                    "data_object": "purchase_contract",
                    "data_key": "getCustomerName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Betreff",
                    "data_object": "purchase_contract",
                    "data_key": "getReferenz",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 30,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 2,
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1001,
                "name": "Archiviert",
                "actions": ['details','restore','delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "Nr.",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "Kunde",
                    "data_object": "purchase_contract",
                    "data_key": "getCustomerName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Betreff",
                    "data_object": "purchase_contract",
                    "data_key": "getReferenz",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 30,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 10,
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
            ],
          }
        ]
        let menu_documents_hoedl = [
          {
            'main_menu_id': 100,
            'name': 'Kaufverträge',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 1001,
                "name": "Offen",
                "actions": ['details','pseudo-singature-kaufvertrag','app-signature','upload-kaufvertrag','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "KV-Nr.",
                    "sort_function" : null,
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "ID",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.externalId",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Standtage",
                    "data_object": "purchase_contract",
                    "data_key": "getStandtage",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": " Tagen",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10004,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Name",
                    "data_object": "purchase_contract",
                    "data_key": "customer.getLastName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "PLZ",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.postalCode",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Ort",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.city",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10008,
                    "title": "GV",
                    "data_object": "purchase_contract",
                    "data_key": "getGV",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 80,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10009,
                    "title": "SERV",
                    "data_object": "purchase_contract",
                    "data_key": "getServ",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 90,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10010,
                    "title": "Reifen",
                    "data_object": "purchase_contract",
                    "data_key": "getReifen",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 100,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10011,
                    "title": "Lack",
                    "data_object": "purchase_contract",
                    "data_key": "getLack",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 110,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10012,
                    "title": "AZ Prov.",
                    "data_object": "purchase_contract",
                    "data_key": "getAZProv",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 120,
                    "suffix": 0,
                    "width": 2
                  },
                  {
                    "sub_menu_column_id": 10013,
                    "title": "Marke",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.brand.makeName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 130,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10014,
                    "title": "Modell",
                    "data_object": "purchase_contract",
                    "data_key": "model_and_version",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 140,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 10015,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10016,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 160,
                    "suffix": 0,
                    "width": 3,
                  },
                ],
                "default_sort_column_id": null,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 0,
                  }
                ],
                "filter_id": null,
                "filterable": [
                  {
                    "data_object": "purchase_contract",
                    "calculated": 0,
                    "data_key": "handed_over_date",
                    "operator": "=",
                    "data_typ": "DATE",
                    "format": "MM.yyyy",
                    "placeholder": null,
                    "label": "Verkaufsdatum",
                    "sort_value": 0,
                    "position": "OUTSIDE"
                  }
                ],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1001,
                "name": "Vorb. Finanzierung",
                "actions": ['details','receive-funding','pseudo-singature-kaufvertrag','app-signature','upload-kaufvertrag','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "KV-Nr.",
                    "sort_function" : null,
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "ID",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.externalId",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Standtage",
                    "data_object": "purchase_contract",
                    "data_key": "getStandtage",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": " Tagen",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10004,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Name",
                    "data_object": "purchase_contract",
                    "data_key": "customer.getLastName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "PLZ",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.postalCode",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Ort",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.city",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10008,
                    "title": "GV",
                    "data_object": "purchase_contract",
                    "data_key": "getGV",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 80,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10009,
                    "title": "SERV",
                    "data_object": "purchase_contract",
                    "data_key": "getServ",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 90,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10010,
                    "title": "Reifen",
                    "data_object": "purchase_contract",
                    "data_key": "getReifen",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 100,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10011,
                    "title": "Lack",
                    "data_object": "purchase_contract",
                    "data_key": "getLack",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 110,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10012,
                    "title": "AZ Prov.",
                    "data_object": "purchase_contract",
                    "data_key": "getAZProv",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 120,
                    "suffix": 0,
                    "width": 2
                  },
                  {
                    "sub_menu_column_id": 10013,
                    "title": "Marke",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.brand.makeName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 130,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10014,
                    "title": "Modell",
                    "data_object": "purchase_contract",
                    "data_key": "model_and_version",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 140,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 10015,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10016,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 160,
                    "suffix": 0,
                    "width": 3,
                  },
                ],
                "default_sort_column_id": null,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "finanzierungsart",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 3,
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_key": "finanzierungsgesellschaft",
                    "data_object": "purchase_contract",
                    "data_typ": "STRING",
                    "operator": "=",
                    "raw_value": "NULL",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 1,
                  },
                ],
                "filter_id": null,
                "filterable": [
                  {
                    "data_object": "purchase_contract",
                    "calculated": 0,
                    "data_key": "handed_over_date",
                    "operator": "=",
                    "data_typ": "DATE",
                    "format": "MM.yyyy",
                    "placeholder": null,
                    "label": "Verkaufsdatum",
                    "sort_value": 0,
                    "position": "OUTSIDE"
                  }
                ],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1002,
                "name": "Abgeschlossen",
                "actions": ['details','pseudo-singature-kaufvertrag','upload-kaufvertrag','popup-change-status'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "KV-Nr.",
                    "sort_function" : null,
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "ID",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.externalId",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Standtage",
                    "data_object": "purchase_contract",
                    "data_key": "getStandtage",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": " Tagen",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10004,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10009,
                    "title": "Bezahlung",
                    "data_object": "purchase_contract",
                    "data_key": "payment_status",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "STRING",
                                "possible_values": [
                                    {
                                      "value": "Nicht bezahlt",
                                      "color": "#D84949"
                                    },
                                    {
                                       "value": "Anzahlung bezahlt",
                                       "color": "#D2B836"
                                    },
                                    {
                                      "value": "Bezahlt",
                                      "color": "#6A9E18"
                                    },
                                ]
                            },
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Name",
                    "data_object": "purchase_contract",
                    "data_key": "customer.getLastName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "PLZ",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.postalCode",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Ort",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.city",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10008,
                    "title": "GV",
                    "data_object": "purchase_contract",
                    "data_key": "getGV",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 80,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10009,
                    "title": "SERV",
                    "data_object": "purchase_contract",
                    "data_key": "getServ",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 90,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10010,
                    "title": "Reifen",
                    "data_object": "purchase_contract",
                    "data_key": "getReifen",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 100,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10011,
                    "title": "Lack",
                    "data_object": "purchase_contract",
                    "data_key": "getLack",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 110,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10012,
                    "title": "AZ Prov.",
                    "data_object": "purchase_contract",
                    "data_key": "getAZProv",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 120,
                    "suffix": 0,
                    "width": 2
                  },
                  {
                    "sub_menu_column_id": 10013,
                    "title": "Marke",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.brand.makeName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 130,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10014,
                    "title": "Modell",
                    "data_object": "purchase_contract",
                    "data_key": "model_and_version",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 140,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 10015,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10016,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 160,
                    "suffix": 0,
                    "width": 3,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": "DESC",
                "filter": [
                  // {
                  //   "calculated": 0,
                  //   "data_key": "vertrag_status",
                  //   "data_object": "purchase_contract",
                  //   "data_typ": "NUMBER",
                  //   "operator": "=",
                  //   "raw_value": 1,
                  // },
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "1",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "filter": [
                    {
                        "calculated": 0,
                        "data_key": "finanzierungsart",
                        "data_object": "purchase_contract",
                        "data_typ": "NUMBER",
                        "operator": "<>",
                        "raw_value": 3,
                    },
                    {
                        "filter_operator": "||"
                    },
                    {
                      "filter": [
                       {
                        "calculated": 0,
                        "data_key": "finanzierungsart",
                        "data_object": "purchase_contract",
                        "data_typ": "NUMBER",
                        "operator": "=",
                        "raw_value": 3,
                      },
                      {
                        "filter_operator": "&&"
                      },
                      {
                        "calculated": 0,
                        "data_key": "finanzierungsgesellschaft",
                        "data_object": "purchase_contract",
                        "data_typ": "STRING",
                        "operator": "<>",
                        "raw_value": "NULL",
                      },
                    ]}
                  ]}

                ],
                "filter_id": null,
                "filterable": [
                  {
                    "data_object": "purchase_contract",
                    "calculated": 0,
                    "data_key": "handed_over_date",
                    "operator": "=",
                    "data_typ": "DATE",
                    "format": "MM.yyyy",
                    "placeholder": null,
                    "label": "Verkaufsdatum",
                    "sort_value": 0,
                    "position": "OUTSIDE"
                  }
                ],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1003,
                "name": "Storniert",
                "actions": ['details','pseudo-singature-kaufvertrag','upload-kaufvertrag'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "KV-Nr.",
                    "sort_function" : null,
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },                  {
                    "sub_menu_column_id": 10002,
                    "title": "ID",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.externalId",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Standtage",
                    "data_object": "purchase_contract",
                    "data_key": "getStandtage",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": " Tagen",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10004,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Name",
                    "data_object": "purchase_contract",
                    "data_key": "customer.getLastName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "PLZ",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.postalCode",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Ort",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.city",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10008,
                    "title": "GV",
                    "data_object": "purchase_contract",
                    "data_key": "getGV",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 80,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10009,
                    "title": "SERV",
                    "data_object": "purchase_contract",
                    "data_key": "getServ",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 90,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10010,
                    "title": "Reifen",
                    "data_object": "purchase_contract",
                    "data_key": "getReifen",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 100,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10011,
                    "title": "Lack",
                    "data_object": "purchase_contract",
                    "data_key": "getLack",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 110,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10012,
                    "title": "AZ Prov.",
                    "data_object": "purchase_contract",
                    "data_key": "getAZProv",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 120,
                    "suffix": 0,
                    "width": 2
                  },
                  {
                    "sub_menu_column_id": 10013,
                    "title": "Marke",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.brand.makeName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 130,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10014,
                    "title": "Modell",
                    "data_object": "purchase_contract",
                    "data_key": "model_and_version",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 140,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 10015,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10016,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 160,
                    "suffix": 0,
                    "width": 3,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 2,
                  }
                ],
                "filter_id": null,
                "filterable": [
                  {
                    "data_object": "purchase_contract",
                    "calculated": 0,
                    "data_key": "handed_over_date",
                    "operator": "=",
                    "data_typ": "DATE",
                    "format": "MM.yyyy",
                    "placeholder": null,
                    "label": "Verkaufsdatum",
                    "sort_value": 0,
                    "position": "OUTSIDE"
                  }
                ],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1004,
                "name": "Archiviert",
                "actions": ['details', 'restore', 'delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "KV-Nr.",
                    "sort_function" : null,
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "ID",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.externalId",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Standtage",
                    "data_object": "purchase_contract",
                    "data_key": "getStandtage",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": " Tagen",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10004,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                  "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Name",
                    "data_object": "purchase_contract",
                    "data_key": "customer.getLastName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "PLZ",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.postalCode",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Ort",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.city",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10008,
                    "title": "GV",
                    "data_object": "purchase_contract",
                    "data_key": "getGV",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 80,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10009,
                    "title": "SERV",
                    "data_object": "purchase_contract",
                    "data_key": "getServ",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 90,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10010,
                    "title": "Reifen",
                    "data_object": "purchase_contract",
                    "data_key": "getReifen",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 100,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10011,
                    "title": "Lack",
                    "data_object": "purchase_contract",
                    "data_key": "getLack",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 110,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10012,
                    "title": "AZ Prov.",
                    "data_object": "purchase_contract",
                    "data_key": "getAZProv",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 120,
                    "suffix": 0,
                    "width": 2
                  },
                  {
                    "sub_menu_column_id": 10013,
                    "title": "Marke",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.brand.makeName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 130,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10014,
                    "title": "Modell",
                    "data_object": "purchase_contract",
                    "data_key": "model_and_version",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 140,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 10015,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10016,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 160,
                    "suffix": 0,
                    "width": 3,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 10,
                  }
                ],
                "filter_id": null,
                "filterable": [
                  {
                    "data_object": "purchase_contract",
                    "calculated": 0,
                    "data_key": "handed_over_date",
                    "operator": "=",
                    "data_typ": "DATE",
                    "format": "MM.yyyy",
                    "placeholder": null,
                    "label": "Verkaufsdatum",
                    "sort_value": 0,
                    "position": "OUTSIDE"
                  }
                ],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
            ],
          },
          {
            'main_menu_id': 101,
            'name': 'Provision',
            'sort_value': 1,
            'sub_menus': [
              {
                "sub_menu_id": 1002,
                "name": "Fabian",
                "actions": ['details','pseudo-singature-kaufvertrag','upload-kaufvertrag'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "KV-Nr.",
                    "sort_function" : null,
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                    "sum_typ": "COUNT",
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "ID",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.externalId",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Standtage",
                    "data_object": "purchase_contract",
                    "data_key": "getStandtage",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": " Tagen",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10004,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Name",
                    "data_object": "purchase_contract",
                    "data_key": "customer.getLastName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "Übergabe",
                    "data_object": "purchase_contract",
                    "data_key": "handed_over_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "PLZ",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.postalCode",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Ort",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.city",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10008,
                    "title": "GV",
                    "data_object": "purchase_contract",
                    "data_key": "getGV",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 80,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10009,
                    "title": "SERV",
                    "data_object": "purchase_contract",
                    "data_key": "getServ",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 90,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10010,
                    "title": "Reifen",
                    "data_object": "purchase_contract",
                    "data_key": "getReifen",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 100,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10011,
                    "title": "Lack",
                    "data_object": "purchase_contract",
                    "data_key": "getLack",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 110,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10012,
                    "title": "AZ Prov.",
                    "data_object": "purchase_contract",
                    "data_key": "getAZProv",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 120,
                    "suffix": 0,
                    "width": 2
                  },
                  {
                    "sub_menu_column_id": 10013,
                    "title": "Marke",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.brand.makeName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 130,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10014,
                    "title": "Modell",
                    "data_object": "purchase_contract",
                    "data_key": "model_and_version",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 140,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 10015,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10016,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 160,
                    "suffix": 0,
                    "width": 3,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": "DESC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 1,
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_key": "employee_id",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 5,
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_key": "handed_over_date",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "NULL",
                  },
                ],
                "filter_id": null,
                "filterable": [
                  {
                    "data_object": "purchase_contract",
                    "calculated": 0,
                    "data_key": "handed_over_date",
                    "operator": "=",
                    "data_typ": "DATE",
                    "format": "MM.yyyy",
                    "placeholder": null,
                    "label": "Verkaufsdatum",
                    "sort_value": 0,
                    "position": "OUTSIDE"
                  }
                ],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1002,
                "name": "Patrick",
                "actions": ['details','pseudo-singature-kaufvertrag','upload-kaufvertrag'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "KV-Nr.",
                    "sort_function" : null,
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                    "sum_typ": "COUNT",
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "ID",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.externalId",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Standtage",
                    "data_object": "purchase_contract",
                    "data_key": "getStandtage",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": " Tagen",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10004,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Name",
                    "data_object": "purchase_contract",
                    "data_key": "customer.getLastName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "Übergabe",
                    "data_object": "purchase_contract",
                    "data_key": "handed_over_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "PLZ",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.postalCode",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Ort",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.city",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10008,
                    "title": "GV",
                    "data_object": "purchase_contract",
                    "data_key": "getGV",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 80,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10009,
                    "title": "SERV",
                    "data_object": "purchase_contract",
                    "data_key": "getServ",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 90,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10010,
                    "title": "Reifen",
                    "data_object": "purchase_contract",
                    "data_key": "getReifen",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 100,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10011,
                    "title": "Lack",
                    "data_object": "purchase_contract",
                    "data_key": "getLack",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 110,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10012,
                    "title": "AZ Prov.",
                    "data_object": "purchase_contract",
                    "data_key": "getAZProv",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 120,
                    "suffix": 0,
                    "width": 2
                  },
                  {
                    "sub_menu_column_id": 10013,
                    "title": "Marke",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.brand.makeName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 130,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10014,
                    "title": "Modell",
                    "data_object": "purchase_contract",
                    "data_key": "model_and_version",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 140,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 10015,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10016,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 160,
                    "suffix": 0,
                    "width": 3,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": "DESC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 1,
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_key": "employee_id",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 7,
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_key": "handed_over_date",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "NULL",
                  },
                ],
                "filter_id": null,
                "filterable": [
                  {
                    "data_object": "purchase_contract",
                    "calculated": 0,
                    "data_key": "handed_over_date",
                    "operator": "=",
                    "data_typ": "DATE",
                    "format": "MM.yyyy",
                    "placeholder": null,
                    "label": "Verkaufsdatum",
                    "sort_value": 0,
                    "position": "OUTSIDE"
                  }
                ],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 1002,
                "name": "Mario",
                "actions": ['details','pseudo-singature-kaufvertrag','upload-kaufvertrag'],
                "columns": [
                  {
                    "sub_menu_column_id": 10001,
                    "title": "KV-Nr.",
                    "sort_function" : null,
                    "data_object": "purchase_contract",
                    "data_key": "purchase_contract_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                    "sum_typ": "COUNT",
                  },
                  {
                    "sub_menu_column_id": 10002,
                    "title": "ID",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.externalId",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10003,
                    "title": "Standtage",
                    "data_object": "purchase_contract",
                    "data_key": "getStandtage",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": " Tagen",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10004,
                    "title": "Status",
                    "data_object": "purchase_contract",
                    "data_key": "vertrag_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Offen",
                            "color": "#D2B836"
                          },
                          {
                             "value": "Unterzeichnet",
                             "color": "#6A9E18"
                          },
                          {
                            "value": "Storniert",
                            "color": "#D84949"
                          },
                          {
                            "value": "Archiviert",
                            "color": "#ABABAB"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 10005,
                    "title": "Name",
                    "data_object": "purchase_contract",
                    "data_key": "customer.getLastName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "Übergabe",
                    "data_object": "purchase_contract",
                    "data_key": "handed_over_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10006,
                    "title": "PLZ",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.postalCode",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10007,
                    "title": "Ort",
                    "data_object": "purchase_contract",
                    "data_key": "customer.address.city",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10008,
                    "title": "GV",
                    "data_object": "purchase_contract",
                    "data_key": "getGV",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 80,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10009,
                    "title": "SERV",
                    "data_object": "purchase_contract",
                    "data_key": "getServ",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 90,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 10010,
                    "title": "Reifen",
                    "data_object": "purchase_contract",
                    "data_key": "getReifen",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 100,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10011,
                    "title": "Lack",
                    "data_object": "purchase_contract",
                    "data_key": "getLack",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 110,
                    "suffix": 0,
                    "width": 1
                  },
                  {
                    "sub_menu_column_id": 10012,
                    "title": "AZ Prov.",
                    "data_object": "purchase_contract",
                    "data_key": "getAZProv",
                    "sum_typ": "SUM",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 120,
                    "suffix": 0,
                    "width": 2
                  },
                  {
                    "sub_menu_column_id": 10013,
                    "title": "Marke",
                    "data_object": "purchase_contract",
                    "data_key": "vehicle.brand.makeName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 130,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 10014,
                    "title": "Modell",
                    "data_object": "purchase_contract",
                    "data_key": "model_and_version",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 140,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 10015,
                    "title": "Vertragssumme",
                    "data_object": "purchase_contract",
                    "data_key": "getVetragssumme",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 10016,
                    "title": "Bearbeitet",
                    "data_object": "purchase_contract",
                    "data_key": "purchase_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": '€',
                    "sort_value": 160,
                    "suffix": 0,
                    "width": 3,
                  },
                ],
                "default_sort_column_id": 10001,
                "default_sort_direction": "DESC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "vertrag_status",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 1,
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_key": "employee_id",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": 22,
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_key": "handed_over_date",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "NULL",
                  },
                ],
                "filter_id": null,
                "filterable": [
                  {
                    "data_object": "purchase_contract",
                    "calculated": 0,
                    "data_key": "handed_over_date",
                    "operator": "=",
                    "data_typ": "DATE",
                    "format": "MM.yyyy",
                    "placeholder": null,
                    "label": "Verkaufsdatum",
                    "sort_value": 0,
                    "position": "OUTSIDE"
                  }
                ],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              }
            ]
          },
        ]
        let menu_accounting = [
          {
            'main_menu_id': 200,
            'name': 'Kassabuch',
            'sort_value': 2,
            'sub_menus': [
              {
                "sub_menu_id": 2001,
                "disabled_elements": true,
                "name": "Gesamt",
                "actions": ['edit','delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 20001,
                    "title": "Eingang",
                    "data_object": "ledger_entry",
                    "data_key": "date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 20002,
                    "title": "Beschreibung",
                    "data_object": "ledger_entry",
                    "data_key": "subject",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 8,
                  },
                  {
                    "sub_menu_column_id": 20003,
                    "title": "Beleg-Nr.",
                    "data_object": "ledger_entry",
                    "data_key": "ledger_entry_number",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 25,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 20004,
                    "title": "Einnahmen",
                    "data_object": "ledger_entry",
                    "data_key": "getEinnahmen",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": "€ ",
                    "sort_value": 30,
                    "suffix": null,
                    "width": 3,
                    "sum_typ": "SUM",
                  },
                  {
                    "sub_menu_column_id": 20004,
                    "title": "Ausgaben",
                    "data_object": "ledger_entry",
                    "data_key": "getAusgaben",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": "€ ",
                    "sort_value": 40,
                    "suffix": null,
                    "width": 3,
                    "sum_typ": "SUM",
                  },
                  {
                    "sub_menu_column_id": 20004,
                    "title": "Saldo",
                    "data_object": "ledger_entry",
                    "data_key": "saldo",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": "€ ",
                    "sort_value": 50,
                    "suffix": null,
                    "width": 3,
                    "sum_typ": "LAST",
                  },
                  {
                    "sub_menu_column_id": 20005,
                    "title": "Status",
                    "data_object": "ledger_entry",
                    "data_key": "ledger_entry_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                        {
                          "value": "Systemeintrag",
                          "color": "#2C2C2C"
                        },
                        {
                          "value": "Offen",
                          "color": "#597CF1"
                        },
                        {
                          "value": "Geprüft",
                          "color": "#2C2C2C"
                        },
                      ]
                    },
                  }
                ],
                "default_sort_column_id": 20001,
                "default_sort_direction": "ASC",
                "filter": [
                ],
                "filter_id": null,
                "filterable": [
                  {
                    "filterable_value_id": 3,
                    "data_object": "ledger_entry",
                    "calculated": 0,
                    "data_key": "date",
                    "operator": "=",
                    "data_typ": "DATE",
                    "format": "MM.yyyy",
                    "placeholder": null,
                    "label": null,
                    "sort_value": 0,
                    "position": "OUTSIDE",
                    "default_value": null,
                    "include_default": false,
                    "input_filterable_value_id": null
                  },
                ],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              }
            ],
          }
        ]
        let menu_accounting_erweitert = [
          {
            'main_menu_id': 800,
            'name': 'Kaufvertrags-Rechnungen',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 8001,
                "name": "Monatsansicht",
                "actions": ['edit'],
                "columns": [
                  {
                    "sub_menu_column_id": 80000,
                    "title": "Re-Nr.",
                    "data_object": "bill",
                    "data_key": "custom_bill_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 80001,
                    "title": "VIN",
                    "data_object": "bill",
                    "data_key": "purchase_contract.vehicle.fin_number",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 11,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 80002,
                    "title": "Datum",
                    "data_object": "bill",
                    "data_key": "bill_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 80003,
                    "title": "Kunde",
                    "data_object": "bill",
                    "data_key": "customer.getFullName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 80004,
                    "title": "RE-Empfänger",
                    "data_object": "bill",
                    "data_key": "bill_recipient.getFullName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 80005,
                    "title": "Betreff",
                    "data_object": "bill",
                    "data_key": "subject",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 80007,
                    "title": "Gesamt (Brutto)",
                    "data_object": "bill",
                    "data_key": "total_brutto",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": "€ ",
                    "sort_value": 70,
                    "suffix": null,
                    "width": 3,
                    "sum_typ": "SUM",
                  },
                  {
                    "sub_menu_column_id": 80009,
                    "title": "Status",
                    "data_object": "bill",
                    "data_key": "getStatus",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 80,
                    "suffix": null,
                    "width": 3,
                    "sum_typ": null,
                        "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "STRING",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#D84949"
                                    },
                                    {
                                      "value": "Bezahlt",
                                      "color": "#6A9E18"
                                    },
                                    {
                                      "value": "Storno-RE",
                                      "color": "#2C2C2C"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#2C2C2C"
                                    },
                                ]
                            },
                  },
                  {
                    "sub_menu_column_id": 80008,
                    "title": "Buchhaltung",
                    "data_object": "bill",
                    "data_key": "in_accounting",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "BOOLEAN",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 90,
                    "suffix": null,
                    "width": 2,
                    "column_tag": {
                        "tag_typ": "SQUARE",
                        "tag_data_typ": "BOOLEAN",
                        "possible_values": [
                          {
                            "value": true,
                            "color": "#6A9E18"
                          },
                          {
                            "value": false,
                            "color": "#2C2C2C"
                          },
                        ]
                      },
                  },
                  // {
                  //   "sub_menu_column_id": 80009,
                  //   "title": "Status",
                  //   "data_object": "bill",
                  //   "data_key": "status",
                  //   "calculated": 0,
                  //   "format": null,
                  //   "data_typ": "ENUM",
                  //   "is_fixed": 0,
                  //   "is_sortable": 1,
                  //   "prefix": null,
                  //   "sort_value": 90,
                  //   "suffix": null,
                  //   "width": 2,
                  //   "column_tag": {
                  //               "tag_typ": "SQUARE",
                  //               "tag_data_typ": "ENUM",
                  //               "possible_values": [
                  //                   {
                  //                     "value": 0,
                  //                     "color": "#ABABAB"
                  //                   },
                  //                   {
                  //                     "value": 1,
                  //                     "color": "#1C7C87"
                  //                   },
                  //                   {
                  //                     "value": 2,
                  //                     "color": "#D2B836"
                  //                   },
                  //                   {
                  //                     "value": 3,
                  //                     "color": "#ABABAB"
                  //                   },
                  //                   {
                  //                     "value": 4,
                  //                     "color": "#6A9E18"
                  //                   },
                  //                   {
                  //                     "value": 5,
                  //                     "color": "#D84949"
                  //                   },
                  //                   {
                  //                     "value": 6,
                  //                     "color": "#D84949"
                  //                   },
                  //               ]
                  //           },
                  // }
                ],
                "default_sort_column_id": 80000,
                "default_sort_direction": "DESC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "bill",
                    "data_key": "subject",
                    "data_typ": "STRING",
                    "operator": "<>",
                    "raw_value": "Gutschrift",
                  }
                ],
                "filter_id": null,
                "filterable": [
                  {
                    "data_object": "bill",
                    "calculated": 0,
                    "data_key": "bill_date",
                    "operator": "=",
                    "data_typ": "DATE",
                    "format": "MM.yyyy",
                    "placeholder": null,
                    "label": "Verkaufsdatum",
                    "sort_value": 0,
                    "position": "OUTSIDE"
                  }
                ],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              }
            ],
          },
          {
            'main_menu_id': 1300,
            'name': 'Gutschriften',
            'sort_value': 1,
            'sub_menus': [
              {
                "sub_menu_id": 13001,
                "name": "Monatsansicht",
                "actions": ['edit'],
                "columns": [
                  {
                    "sub_menu_column_id": 130001,
                    "title": "Re-Nr.",
                    "data_object": "bill",
                    "data_key": "custom_bill_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 130002,
                    "title": "Datum",
                    "data_object": "bill",
                    "data_key": "bill_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 130003,
                    "title": "Kunde",
                    "data_object": "bill",
                    "data_key": "customer.getFullName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 130004,
                    "title": "RE-Empfänger",
                    "data_object": "bill",
                    "data_key": "bill_recipient.getFullName",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 130005,
                    "title": "Betreff",
                    "data_object": "bill",
                    "data_key": "subject",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 130007,
                    "title": "Gesamt (Brutto)",
                    "data_object": "bill",
                    "data_key": "total_brutto",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": "€ ",
                    "sort_value": 70,
                    "suffix": null,
                    "width": 3,
                    "sum_typ": "SUM",
                  },
                  {
                    "sub_menu_column_id": 130009,
                    "title": "Status",
                    "data_object": "bill",
                    "data_key": "getStatus",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 80,
                    "suffix": null,
                    "width": 3,
                    "sum_typ": null,
                        "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "STRING",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#D84949"
                                    },
                                    {
                                      "value": "Bezahlt",
                                      "color": "#6A9E18"
                                    },
                                    {
                                      "value": "Storno-RE",
                                      "color": "#2C2C2C"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#2C2C2C"
                                    },
                                ]
                            },
                  },
                  {
                    "sub_menu_column_id": 130008,
                    "title": "Buchhaltung",
                    "data_object": "bill",
                    "data_key": "in_accounting",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "BOOLEAN",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 90,
                    "suffix": null,
                    "width": 2,
                    "column_tag": {
                        "tag_typ": "SQUARE",
                        "tag_data_typ": "BOOLEAN",
                        "possible_values": [
                          {
                            "value": true,
                            "color": "#6A9E18"
                          },
                          {
                            "value": false,
                            "color": "#2C2C2C"
                          },
                        ]
                      },
                  },
                ],
                "default_sort_column_id": 130001,
                "default_sort_direction": "DESC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "bill",
                    "data_key": "subject",
                    "data_typ": "STRING",
                    "operator": "=",
                    "raw_value": "Gutschrift",
                  }
                ],
                "filter_id": null,
                "filterable": [
                  {
                    "data_object": "bill",
                    "calculated": 0,
                    "data_key": "bill_date",
                    "operator": "=",
                    "data_typ": "DATE",
                    "format": "MM.yyyy",
                    "placeholder": null,
                    "label": "Verkaufsdatum",
                    "sort_value": 0,
                    "position": "OUTSIDE"
                  }
                ],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              }
            ],
          }
        ]
        let menu_test_drives = [
          {
            'main_menu_id': 300,
            'name': 'Probefahrten',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 3001,
                "name": "Kunde",
                "actions": ['pdf-create','pdf-open','delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 30001,
                    "title": "Nr.",
                    "data_object": "drive",
                    "data_key": "test_drive_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 30002,
                    "title": "Kunden",
                    "data_object": "drive",
                    "data_key": "getDriver",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 30003,
                    "title": "Fahrzeug",
                    "data_object": "drive",
                    "data_key": "vehicle.getVehicleName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 30,
                    "suffix": null,
                    "width": 11,
                  },
                  {
                    "sub_menu_column_id": 30004,
                    "title": "Kennzeichen",
                    "data_object": "drive",
                    "data_key": "license_plate_id",
                    "calculated": 0,
                    "format": "0",
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 30005,
                    "title": "Start",
                    "data_object": "drive",
                    "data_key": "start_time",
                    "calculated": 0,
                    "format": "dd.MM.yyyy, HH:mm",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 30006,
                    "title": "Ende",
                    "data_object": "drive",
                    "data_key": "end_time",
                    "calculated": 0,
                    "format": "dd.MM.yyyy, HH:mm",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 60,
                    "suffix": null,
                    "width": 3,
                  }
                ],
                "default_sort_column_id": 30005,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "customer_id",
                    "data_object": "drive",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "NULL",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 4001,
                "name": "Intern",
                "actions": ['pdf-create','pdf-open','delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 40001,
                    "title": "Nr.",
                    "data_object": "drive",
                    "data_key": "test_drive_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 40002,
                    "title": "Mitarbeiter",
                    "data_object": "drive",
                    "data_key": "getDriver",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 40003,
                    "title": "Fahrzeug",
                    "data_object": "drive",
                    "data_key": "vehicle.getVehicleName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 30,
                    "suffix": null,
                    "width": 11,
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Kennzeichen",
                    "data_object": "drive",
                    "data_key": "license_plate_id",
                    "calculated": 0,
                    "format": "0",
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40005,
                    "title": "Start",
                    "data_object": "drive",
                    "data_key": "start_time",
                    "calculated": 0,
                    "format": "dd.MM.yyyy, HH:mm",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Ende",
                    "data_object": "drive",
                    "data_key": "end_time",
                    "calculated": 0,
                    "format": "dd.MM.yyyy, HH:mm",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 60,
                    "suffix": null,
                    "width": 3,
                  }
                ],
                "default_sort_column_id": 40005,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "customer_id",
                    "data_object": "drive",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "NULL",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
            ],
          }
        ]
        let menu_tires = [
          {
            'main_menu_id': 400,
            'name': 'Alle Reifen',
            'sort_value': 0,
            'sub_menus': [
            {
                "sub_menu_id": 4000,
                "name": "Veröffentlicht",
                "actions": ['details','tire-change-storage','tire-installation','edit','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 40000,
                    "title": "Bild",
                    "data_object": "tire",
                    "data_key": "thumbnail",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40001,
                    "title": "ID",
                    "data_object": "tire",
                    "data_key": "tire_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40002,
                    "title": "Lagerplatz",
                    "data_object": "tire",
                    "data_key": "storage_place.place_name",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40008,
                    "title": "B",
                    "data_object": "tire",
                    "data_key": "dimensions_b",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40009,
                    "title": "H",
                    "data_object": "tire",
                    "data_key": "dimensions_t",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40010,
                    "title": "Z",
                    "data_object": "tire",
                    "data_key": "dimensions_z",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40003,
                    "title": "Reifen Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_tire",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Ausführung",
                    "data_object": "tire",
                    "data_key": "getExecution",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Profil",
                    "data_object": "tire",
                    "data_key": "tire_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40005,
                    "title": "Preis",
                    "data_object": "tire",
                    "data_key": "price",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge",
                    "data_object": "tire",
                    "data_key": "rim_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40007,
                    "title": "Felge Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_rim",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": null,
                    "width": 5,
                  },
                ],
                "default_sort_column_id": 40001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "not_for_sale",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "0",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "platform_online",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "1",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 4001,
                "name": "Entwurf",
                "actions": ['details','tire-change-storage','tire-installation','edit','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 40000,
                    "title": "Bild",
                    "data_object": "tire",
                    "data_key": "thumbnail",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40001,
                    "title": "ID",
                    "data_object": "tire",
                    "data_key": "tire_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40002,
                    "title": "Lagerplatz",
                    "sort_function" : "getStoragePlaceSortValue",
                    "data_object": "tire",
                    "data_key": "storage_place.place_name",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40008,
                    "title": "B",
                    "data_object": "tire",
                    "data_key": "dimensions_b",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40009,
                    "title": "H",
                    "data_object": "tire",
                    "data_key": "dimensions_t",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40010,
                    "title": "Z",
                    "data_object": "tire",
                    "data_key": "dimensions_z",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40003,
                    "title": "Reifen Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_tire",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Ausführung",
                    "data_object": "tire",
                    "data_key": "getExecution",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Profil",
                    "data_object": "tire",
                    "data_key": "tire_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40005,
                    "title": "Preis",
                    "data_object": "tire",
                    "data_key": "price",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge",
                    "data_object": "tire",
                    "data_key": "rim_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_rim",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": null,
                    "width": 5,
                  },
                ],
                "default_sort_column_id": 40001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "platform_online",
                    "data_typ": "BOOLEAN",
                    "operator": "=",
                    "raw_value": "false",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "10",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 4010,
                "name": "Archiviert",
                "actions": ['details','tire-change-storage','tire-installation','edit','restore'],
                "columns": [
                  {
                    "sub_menu_column_id": 40000,
                    "title": "Bild",
                    "data_object": "tire",
                    "data_key": "thumbnail",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40001,
                    "title": "ID",
                    "data_object": "tire",
                    "data_key": "tire_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40002,
                    "title": "Vorheriger Lagerplatz",
                    "sort_function" : "getStoragePlaceSortValue",
                    "data_object": "tire",
                    "data_key": "storage_place.place_name",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 40008,
                    "title": "B",
                    "data_object": "tire",
                    "data_key": "dimensions_b",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40009,
                    "title": "H",
                    "data_object": "tire",
                    "data_key": "dimensions_t",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40010,
                    "title": "Z",
                    "data_object": "tire",
                    "data_key": "dimensions_z",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40003,
                    "title": "Reifen Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_tire",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Ausführung",
                    "data_object": "tire",
                    "data_key": "getExecution",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Profil",
                    "data_object": "tire",
                    "data_key": "tire_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40005,
                    "title": "Preis",
                    "data_object": "tire",
                    "data_key": "price",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge",
                    "data_object": "tire",
                    "data_key": "rim_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_rim",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": null,
                    "width": 5,
                  },
                ],
                "default_sort_column_id": 40001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "10",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              }
            ],
          },
          {
            'main_menu_id': 401,
            'name': 'Reifenlager',
            'sort_value': 0,
            'sub_menus': [
            {
                "sub_menu_id": 4000,
                "name": "Alle",
                "actions": ['details','tire-change-storage','tire-installation','edit','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 40000,
                    "title": "Bild",
                    "data_object": "tire",
                    "data_key": "thumbnail",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40001,
                    "title": "ID",
                    "data_object": "tire",
                    "data_key": "tire_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40002,
                    "title": "Lagerplatz",
                    "data_object": "tire",
                    "data_key": "storage_place.place_name",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40008,
                    "title": "B",
                    "data_object": "tire",
                    "data_key": "dimensions_b",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40009,
                    "title": "H",
                    "data_object": "tire",
                    "data_key": "dimensions_t",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40010,
                    "title": "Z",
                    "data_object": "tire",
                    "data_key": "dimensions_z",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40003,
                    "title": "Reifen Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_tire",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Ausführung",
                    "data_object": "tire",
                    "data_key": "getExecution",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Profil",
                    "data_object": "tire",
                    "data_key": "tire_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40005,
                    "title": "Preis",
                    "data_object": "tire",
                    "data_key": "price",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge",
                    "data_object": "tire",
                    "data_key": "rim_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_rim",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": null,
                    "width": 5,
                  },
                ],
                "default_sort_column_id": 40001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "vehicle_id",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "NULL",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "10",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 4001,
                "name": "Lassnitzhöhe",
                "actions": ['details','tire-change-storage','tire-installation','edit','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 40000,
                    "title": "Bild",
                    "data_object": "tire",
                    "data_key": "thumbnail",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40001,
                    "title": "ID",
                    "data_object": "tire",
                    "data_key": "tire_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40002,
                    "title": "Lagerplatz",
                    "sort_function" : "getStoragePlaceSortValue",
                    "data_object": "tire",
                    "data_key": "storage_place.place_name",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40008,
                    "title": "B",
                    "data_object": "tire",
                    "data_key": "dimensions_b",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40009,
                    "title": "H",
                    "data_object": "tire",
                    "data_key": "dimensions_t",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40010,
                    "title": "Z",
                    "data_object": "tire",
                    "data_key": "dimensions_z",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40003,
                    "title": "Reifen Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_tire",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Ausführung",
                    "data_object": "tire",
                    "data_key": "getExecution",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Profil",
                    "data_object": "tire",
                    "data_key": "tire_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40005,
                    "title": "Preis",
                    "data_object": "tire",
                    "data_key": "price",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge",
                    "data_object": "tire",
                    "data_key": "rim_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_rim",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": null,
                    "width": 5,
                  },
                ],
                "default_sort_column_id": 40001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "storage_place.storage_location",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "1",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "vehicle_id",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "NULL",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "10",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 4002,
                "name": "Gleisdorf",
                "actions": ['details','tire-change-storage','tire-installation','edit','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 40000,
                    "title": "Bild",
                    "data_object": "tire",
                    "data_key": "thumbnail",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40001,
                    "title": "ID",
                    "data_object": "tire",
                    "data_key": "tire_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40002,
                    "title": "Lagerplatz",
                    "sort_function" : "getStoragePlaceSortValue",
                    "data_object": "tire",
                    "data_key": "storage_place.place_name",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40008,
                    "title": "B",
                    "data_object": "tire",
                    "data_key": "dimensions_b",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40009,
                    "title": "H",
                    "data_object": "tire",
                    "data_key": "dimensions_t",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40010,
                    "title": "Z",
                    "data_object": "tire",
                    "data_key": "dimensions_z",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40003,
                    "title": "Reifen Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_tire",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Ausführung",
                    "data_object": "tire",
                    "data_key": "getExecution",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Profil",
                    "data_object": "tire",
                    "data_key": "tire_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40005,
                    "title": "Preis",
                    "data_object": "tire",
                    "data_key": "price",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge",
                    "data_object": "tire",
                    "data_key": "rim_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_rim",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": null,
                    "width": 5,
                  },
                ],
                "default_sort_column_id": 40001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "storage_place.storage_location",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "2",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "vehicle_id",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "NULL",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "10",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 4005,
                "name": "Gleisdorf - Container",
                "actions": ['details','tire-change-storage','tire-installation','edit','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 40000,
                    "title": "Bild",
                    "data_object": "tire",
                    "data_key": "thumbnail",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40001,
                    "title": "ID",
                    "data_object": "tire",
                    "data_key": "tire_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40002,
                    "title": "Lagerplatz",
                    "sort_function" : "getStoragePlaceSortValue",
                    "data_object": "tire",
                    "data_key": "storage_place.place_name",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40008,
                    "title": "B",
                    "data_object": "tire",
                    "data_key": "dimensions_b",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40009,
                    "title": "H",
                    "data_object": "tire",
                    "data_key": "dimensions_t",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40010,
                    "title": "Z",
                    "data_object": "tire",
                    "data_key": "dimensions_z",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40003,
                    "title": "Reifen Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_tire",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Ausführung",
                    "data_object": "tire",
                    "data_key": "getExecution",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Profil",
                    "data_object": "tire",
                    "data_key": "tire_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40005,
                    "title": "Preis",
                    "data_object": "tire",
                    "data_key": "price",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge",
                    "data_object": "tire",
                    "data_key": "rim_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_rim",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": null,
                    "width": 5,
                  },
                ],
                "default_sort_column_id": 40001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "storage_place.storage_location",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "4",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "vehicle_id",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "NULL",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "10",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 4003,
                "name": "Sonstige",
                "actions": ['details','tire-change-storage','tire-installation','edit','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 40000,
                    "title": "Bild",
                    "data_object": "tire",
                    "data_key": "thumbnail",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40001,
                    "title": "ID",
                    "data_object": "tire",
                    "data_key": "tire_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40002,
                    "title": "Lagerplatz",
                    "sort_function" : "getStoragePlaceSortValue",
                    "data_object": "tire",
                    "data_key": "storage_place.place_name",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40008,
                    "title": "B",
                    "data_object": "tire",
                    "data_key": "dimensions_b",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40009,
                    "title": "H",
                    "data_object": "tire",
                    "data_key": "dimensions_t",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40010,
                    "title": "Z",
                    "data_object": "tire",
                    "data_key": "dimensions_z",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40003,
                    "title": "Reifen Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_tire",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Ausführung",
                    "data_object": "tire",
                    "data_key": "getExecution",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Profil",
                    "data_object": "tire",
                    "data_key": "tire_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40005,
                    "title": "Preis",
                    "data_object": "tire",
                    "data_key": "price",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge",
                    "data_object": "tire",
                    "data_key": "rim_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_rim",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": null,
                    "width": 5,
                  },
                ],
                "default_sort_column_id": 40001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "storage_place.storage_location",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "1",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "storage_place.storage_location",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "2",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "vehicle_id",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "NULL",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "10",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
            ],
          },
          {
            'main_menu_id': 402,
            'name': 'Montiert',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 4001,
                "name": "Montiert",
                "actions": ['details','tire-change-storage','tire-installation','edit','archiv'],
                "columns": [
                  {
                    "sub_menu_column_id": 40000,
                    "title": "Bild",
                    "data_object": "tire",
                    "data_key": "thumbnail",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 0,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40001,
                    "title": "ID",
                    "data_object": "tire",
                    "data_key": "tire_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40002,
                    "title": "Fahrzeug",
                    "data_object": "tire",
                    "data_key": "vehicle.externalId",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40008,
                    "title": "B",
                    "data_object": "tire",
                    "data_key": "dimensions_b",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40009,
                    "title": "H",
                    "data_object": "tire",
                    "data_key": "dimensions_t",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40010,
                    "title": "Z",
                    "data_object": "tire",
                    "data_key": "dimensions_z",
                    "sort_function" : "getStoragePlaceSortValue",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 40003,
                    "title": "Reifen Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_tire",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Ausführung",
                    "data_object": "tire",
                    "data_key": "getExecution",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40004,
                    "title": "Profil",
                    "data_object": "tire",
                    "data_key": "tire_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 3
                  },
                  {
                    "sub_menu_column_id": 40005,
                    "title": "Preis",
                    "data_object": "tire",
                    "data_key": "price",
                    "calculated": 0,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": " €",
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge",
                    "data_object": "tire",
                    "data_key": "rim_type",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 60,
                    "suffix": null,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 40006,
                    "title": "Felge Bezeichnung",
                    "data_object": "tire",
                    "data_key": "designation_rim",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 70,
                    "suffix": null,
                    "width": 5,
                  },
                ],
                "default_sort_column_id": 40001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "vehicle_id",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "NULL",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "tire",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "10",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 1,
                "pagination": 1,
              }
            ],
          },
        ]
        let menu_users = [
          {
            'main_menu_id': 500,
            'name': 'Aktive User',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 5001,
                "name": "Aktive User",
                "actions": ['edit'],
                "columns": [
                  {
                    "sub_menu_column_id": 50001,
                    "title": "ID",
                    "data_object": "employee",
                    "data_key": "id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 50002,
                    "title": "Name",
                    "data_object": "employee",
                    "data_key": "getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 50003,
                    "title": "Username",
                    "data_object": "employee",
                    "data_key": "username",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 50004,
                    "title": "Standort",
                    "data_object": "employee",
                    "data_key": "getAdress",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 8
                  },
                  {
                    "sub_menu_column_id": 50005,
                    "title": "Rolle",
                    "data_object": "employee",
                    "data_key": "permission_group.name",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "STRING",
                                "possible_values": [
                                    {
                                      "value": "Admin",
                                      "color": "#597CF1"
                                    },
                                    {
                                      "value": "Buchhaltung",
                                      "color": "#3D2CD4"
                                    },
                                    {
                                      "value": "Verkauf",
                                      "color": "#1C7C87"
                                    },
                                    {
                                      "value": "Werkstatt",
                                      "color": "#D84949"
                                    },
                                    {
                                      "value": "Ersatzteile",
                                      "color": "#D84949"
                                    },
                                    {
                                      "value": "Fahrzeuganlieferung",
                                      "color": "#D84949"
                                    },
                                    {
                                      "value": "Probefahrt",
                                      "color": "#6A9E18"
                                    },
                                    {
                                      "value": "Empfang",
                                      "color": "#6A9E18"
                                    }
                                ]
                            },
                  },
                  {
                    "sub_menu_column_id": 50006,
                    "title": "Status",
                    "data_object": "employee",
                    "data_key": "getStatus",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "sort_function": null,
                    "prefix": null,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 1,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "STRING",
                                "possible_values": [
                                    {
                                      "value": "Aktiv",
                                      "color": "#6a9e18"
                                    },
                                    {
                                       "value": "Inaktiv",
                                       "color": "#D84949"
                                    }
                                ]
                            },
                  },
                ],
                "default_sort_column_id": 50001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "employee",
                    "data_key": "active",
                    "data_typ": "BOOLEAN",
                    "operator": "=",
                    "raw_value": "true",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 1,
                "pagination": 1,
              }
            ],
          },
          {
            'main_menu_id': 600,
            'name': 'Archiv',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 6001,
                "name": "Aktive User",
                "actions": ['edit'],
                "columns": [
                  {
                    "sub_menu_column_id": 60001,
                    "title": "ID",
                    "data_object": "employee",
                    "data_key": "id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 60002,
                    "title": "Name",
                    "data_object": "employee",
                    "data_key": "getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 60003,
                    "title": "Username",
                    "data_object": "employee",
                    "data_key": "username",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 5,
                  },
                  {
                    "sub_menu_column_id": 60004,
                    "title": "Standort",
                    "data_object": "employee",
                    "data_key": "getAdress",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 8
                  },
                  {
                    "sub_menu_column_id": 60005,
                    "title": "Rolle",
                    "data_object": "employee",
                    "data_key": "permission_group.name",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "STRING",
                                "possible_values": [
                                    {
                                      "value": "Admin",
                                      "color": "#597CF1"
                                    },
                                    {
                                      "value": "Buchhaltung",
                                      "color": "#3D2CD4"
                                    },
                                    {
                                      "value": "Verkauf",
                                      "color": "#1C7C87"
                                    },
                                    {
                                      "value": "Werkstatt",
                                      "color": "#D84949"
                                    },
                                    {
                                      "value": "Ersatzteile",
                                      "color": "#D84949"
                                    },
                                    {
                                      "value": "Fahrzeuganlieferung",
                                      "color": "#D84949"
                                    },
                                    {
                                      "value": "Probefahrt",
                                      "color": "#6A9E18"
                                    },
                                    {
                                      "value": "Empfang",
                                      "color": "#6A9E18"
                                    }
                                ]
                            },
                  },
                  {
                    "sub_menu_column_id": 60006,
                    "title": "Status",
                    "data_object": "employee",
                    "data_key": "getStatus",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 60,
                    "suffix": 0,
                    "width": 1,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "STRING",
                                "possible_values": [
                                    {
                                      "value": "Aktiv",
                                      "color": "#6a9e18"
                                    },
                                    {
                                       "value": "Inaktiv",
                                       "color": "#D84949"
                                    }
                                ]
                            },
                  },
                ],
                "default_sort_column_id": 60001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "employee",
                    "data_key": "active",
                    "data_typ": "BOOLEAN",
                    "operator": "=",
                    "raw_value": "false",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 1,
                "pagination": 1,
              }
            ],
          },
        ]
        let menu_locations = [
          {
            'main_menu_id': 600,
            'name': 'Standorte',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 6001,
                "name": "Standorte",
                "actions": [],
                "columns": [
                  {
                    "sub_menu_column_id": 60001,
                    "title": "Bezeichnung",
                    "data_object": "location",
                    "data_key": "name",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 60002,
                    "title": "Straße",
                    "data_object": "location",
                    "data_key": "street",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 3,
                  },
                  {
                    "sub_menu_column_id": 60003,
                    "title": "PLZ",
                    "data_object": "location",
                    "data_key": "postCode",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 60004,
                    "title": "Ort",
                    "data_object": "location",
                    "data_key": "city",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": null,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 2
                  },
                ],
                "default_sort_column_id": 60001,
                "default_sort_direction": "ASC",
                "filter": [],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 1,
                "pagination": 1,
              }
            ],
          }
        ]
        let menu_customer = [
          {
            'main_menu_id': 700,
            'name': 'Kunden',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 7001,
                "name": "Kunden",
                "actions": ['details','edit','create-purchase-contract','archiv','delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 70001,
                    "title": "ID-Nr",
                    "data_object": "customer",
                    "data_key": "id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 70002,
                    "title": "Name",
                    "data_object": "customer",
                    "data_key": "getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 6,
                  },
                  {
                    "sub_menu_column_id": 70003,
                    "title": "Typ",
                    "data_object": "customer",
                    "data_key": "typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 2,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Privat",
                            "color": "#3D2CD4"
                          },
                          {
                             "value": "Unternehmen",
                             "color": "#95550C"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 70004,
                    "title": "Telefon-Nr.",
                    "data_object": "customer",
                    "data_key": "contactPerson.phone",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 70005,
                    "title": "Bearbeitet am",
                    "data_object": "customer",
                    "data_key": "lastUpdated",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 70001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "customer",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "1",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 6001,
                "name": "Interessenten",
                "actions": ['details','edit','create-purchase-contract','archiv','delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 70001,
                    "title": "ID-Nr",
                    "data_object": "customer",
                    "data_key": "id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 70002,
                    "title": "Name",
                    "data_object": "customer",
                    "data_key": "getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 6,
                  },
                  {
                    "sub_menu_column_id": 70003,
                    "title": "Typ",
                    "data_object": "customer",
                    "data_key": "typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 2,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Privat",
                            "color": "#3D2CD4"
                          },
                          {
                             "value": "Unternehmen",
                             "color": "#95550C"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 70004,
                    "title": "Telefon-Nr.",
                    "data_object": "customer",
                    "data_key": "contactPerson.phone",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 70005,
                    "title": "Bearbeitet am",
                    "data_object": "customer",
                    "data_key": "lastUpdated",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 60001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "customer",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "2",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 6001,
                "name": "Archiviert",
                "actions": ['details','edit','restore','delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 70001,
                    "title": "ID-Nr",
                    "data_object": "customer",
                    "data_key": "id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 70002,
                    "title": "Name",
                    "data_object": "customer",
                    "data_key": "getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 6,
                  },
                  {
                    "sub_menu_column_id": 70003,
                    "title": "Typ",
                    "data_object": "customer",
                    "data_key": "typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 2,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Privat",
                            "color": "#3D2CD4"
                          },
                          {
                             "value": "Unternehmen",
                             "color": "#95550C"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 70004,
                    "title": "Telefon-Nr.",
                    "data_object": "customer",
                    "data_key": "contactPerson.phone",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 70005,
                    "title": "Bearbeitet am",
                    "data_object": "customer",
                    "data_key": "lastUpdated",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 60001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "customer",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "3",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              }
            ],
          }
        ]
        let menu_customer_standard = [
          {
            'main_menu_id': 700,
            'name': 'Kunden',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 7001,
                "name": "Kunden",
                "actions": ['details','edit','archiv','delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 70001,
                    "title": "ID-Nr",
                    "data_object": "customer",
                    "data_key": "id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 70002,
                    "title": "Name",
                    "data_object": "customer",
                    "data_key": "getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 6,
                  },
                  {
                    "sub_menu_column_id": 70003,
                    "title": "Typ",
                    "data_object": "customer",
                    "data_key": "typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 2,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Privat",
                            "color": "#3D2CD4"
                          },
                          {
                             "value": "Unternehmen",
                             "color": "#95550C"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 70004,
                    "title": "Telefon-Nr.",
                    "data_object": "customer",
                    "data_key": "contactPerson.phone",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 70005,
                    "title": "Bearbeitet am",
                    "data_object": "customer",
                    "data_key": "lastUpdated",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 70001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "customer",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "1",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 6001,
                "name": "Interessenten",
                "actions": ['details','edit','archiv','delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 70001,
                    "title": "ID-Nr",
                    "data_object": "customer",
                    "data_key": "id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 70002,
                    "title": "Name",
                    "data_object": "customer",
                    "data_key": "getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 6,
                  },
                  {
                    "sub_menu_column_id": 70003,
                    "title": "Typ",
                    "data_object": "customer",
                    "data_key": "typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 2,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Privat",
                            "color": "#3D2CD4"
                          },
                          {
                             "value": "Unternehmen",
                             "color": "#95550C"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 70004,
                    "title": "Telefon-Nr.",
                    "data_object": "customer",
                    "data_key": "contactPerson.phone",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 70005,
                    "title": "Bearbeitet am",
                    "data_object": "customer",
                    "data_key": "lastUpdated",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 60001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "customer",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "2",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 6001,
                "name": "Archiviert",
                "actions": ['details','edit','restore','delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 70001,
                    "title": "ID-Nr",
                    "data_object": "customer",
                    "data_key": "id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 1,
                  },
                  {
                    "sub_menu_column_id": 70002,
                    "title": "Name",
                    "data_object": "customer",
                    "data_key": "getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 6,
                  },
                  {
                    "sub_menu_column_id": 70003,
                    "title": "Typ",
                    "data_object": "customer",
                    "data_key": "typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 30,
                    "suffix": 0,
                    "width": 2,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Privat",
                            "color": "#3D2CD4"
                          },
                          {
                             "value": "Unternehmen",
                             "color": "#95550C"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 70004,
                    "title": "Telefon-Nr.",
                    "data_object": "customer",
                    "data_key": "contactPerson.phone",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 0,
                    "prefix": 0,
                    "sort_value": 40,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 70005,
                    "title": "Bearbeitet am",
                    "data_object": "customer",
                    "data_key": "lastUpdated",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 2,
                  },
                ],
                "default_sort_column_id": 60001,
                "default_sort_direction": "ASC",
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "customer",
                    "data_key": "status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "3",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              }
            ],
          }
        ];
        let menu_purchase_contract_uebergabe_hoedl =  [
          {
            "main_menu_id": 7,
            "super_menu_id": 1,
            "name": "Übergaben",
            "sort_value": 6,
            "sub_menus": [
                {
                    "sub_menu_id": 250,
                    "name": "Vorbereitung",
                    "sort_value": 1,
                    "filter": [
                      {
                        "calculated": 0,
                        "data_key": "getContractStatus",
                        "data_object": "purchase_contract",
                        "data_typ": "NUMBER",
                        "operator": "=",
                        "raw_value": "3",
                      },
                    ],
                    "filterable": [
                    ],
                    "columns": [
                      {
                        "sub_menu_column_id": 25001,
                        "title": "ID",
                        "sort_value": 10,
                        "data_object": "purchase_contract",
                        "calculated": 0,
                        "data_key": "purchase_contract_id",
                        "is_sortable": 1,
                        "sort_function": null,
                        "is_fixed": 1,
                        "width": 1,
                        "prefix": null,
                        "suffix": null,
                        "format": null,
                        "data_typ": "STRING"
                      },
                      {
                        "sub_menu_column_id": 25002,
                        "title": "Modell",
                        "data_object": "purchase_contract",
                        "data_key": "vehicle.getDescription",
                        "calculated": 0,
                        "format": null,
                        "data_typ": "STRING",
                        "is_fixed": 0,
                        "is_sortable": 0,
                        "prefix": 0,
                        "sort_value": 30,
                        "suffix": 0,
                        "width": 8,
                      },
                      {
                        "sub_menu_column_id": 25003,
                        "title": "Typenschein",
                        "sort_value": 40,
                        "data_object": "purchase_contract",
                        "calculated": 0,
                        "data_key": "vehicle.finanzsperrauskunft",
                        "is_sortable": 1,
                        "sort_function": null,
                        "is_fixed": 0,
                        "width": 3,
                        "prefix": null,
                        "suffix": null,
                        "format": null,
                        "data_typ": "ENUM",
                        "column_tag": {
                          "tag_typ": "SQUARE",
                          "tag_data_typ": "ENUM",
                          "possible_values": [
                            {
                              "value": "Bank",
                              "color": "#D84949"
                            },
                            {
                              "value": "Angefordert",
                              "color": "#D2B836"
                            },
                            {
                              "value": "Lagernd",
                              "color": "#6A9E18"
                            },
                            {
                              "value": null,
                              "color": "#D84949"
                            },
                          ]
                        }
                      },
                      {
                          "sub_menu_column_id": 25004,
                          "title": "Finanzsperrauskunft",
                          "sort_value": 50,
                          "data_object": "purchase_contract",
                          "calculated": 0,
                          "data_key": "vehicle.finanzsperrauskunft_status",
                          "is_sortable": 1,
                          "sort_function": null,
                          "is_fixed": 0,
                          "width": 4,
                          "prefix": null,
                          "suffix": null,
                          "format": null,
                          "data_typ": "ENUM",
                          "column_tag": {
                              "tag_typ": "SQUARE",
                              "tag_data_typ": "ENUM",
                              "possible_values": [
                                  {
                                    "value": "Gesperrt",
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": "Nicht gefunden",
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": "Unbekannt",
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": null,
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": "Freigegeben",
                                    "color": "#6A9E18"
                                  },
                                  {
                                    "value": "Erstauslieferung Österreich",
                                    "color": "#6A9E18"
                                  },
                              ]
                          },
                      },
                      {
                        "sub_menu_column_id": 25005,
                        "title": "Datenauszug",
                        "sort_value": 60,
                        "data_object": "purchase_contract",
                        "calculated": 1,
                        "data_key": "getAustriaDataExtractStatus",
                        "is_sortable": 1,
                        "sort_function": "getAustriaDataExtractStatus",
                        "is_fixed": 0,
                        "width": 3,
                        "prefix": null,
                        "suffix": null,
                        "format": null,
                        "data_typ": "ENUM",
                        "column_tag": {
                          "tag_typ": "SQUARE",
                          "tag_data_typ": "ENUM",
                          "possible_values": [
                            {
                              "value": "nicht notwendig",
                              "color": "#6A9E18"
                            },
                            {
                              "value": "angefordert",
                              "color": "#D2B836"
                            },
                            {
                              "value": "lagernd",
                              "color": "#6A9E18"
                            },
                            {
                              "value": null,
                              "color": "#D84949"
                            },
                          ]
                        }
                      },
                  ],
                    "actions": ['details','change_finanzsperrauskunft','change_austrian_data_extract'],
                    "is_hidden": 0,
                    "pagination": 1,
                    "filter_id": 8,
                    "filterable_id": 7,
                    "default_sort_column_id": 25001,
                    "default_sort_direction": null
                },
                {
                  "sub_menu_id": 11,
                  "name": "Bereit zur Übergabe",
                  "sort_value": 1,
                  "filter": [
                    {
                      "calculated": 0,
                      "data_key": "getContractStatus",
                      "data_object": "purchase_contract",
                      "data_typ": "NUMBER",
                      "operator": "=",
                      "raw_value": "4",
                    },
                  ],
                  "filterable": [
                  ],
                  "columns": [
                      {
                        "sub_menu_column_id": 2,
                        "title": "ID",
                        "sort_value": 10,
                        "data_object": "purchase_contract",
                        "calculated": 0,
                        "data_key": "purchase_contract_id",
                        "is_sortable": 1,
                        "sort_function": null,
                        "is_fixed": 1,
                        "width": 1,
                        "prefix": null,
                        "suffix": null,
                        "format": null,
                        "data_typ": "STRING"
                      },
                      {
                        "sub_menu_column_id": 10014,
                        "title": "Modell",
                        "data_object": "purchase_contract",
                        "data_key": "vehicle.getDescription",
                        "calculated": 0,
                        "format": null,
                        "data_typ": "STRING",
                        "is_fixed": 0,
                        "is_sortable": 0,
                        "prefix": 0,
                        "sort_value": 30,
                        "suffix": 0,
                        "width": 8,
                      },
                      {
                        "sub_menu_column_id": 208,
                        "title": "Typenschein",
                        "sort_value": 40,
                        "data_object": "purchase_contract",
                        "calculated": 0,
                        "data_key": "vehicle.finanzsperrauskunft",
                        "is_sortable": 1,
                        "sort_function": null,
                        "is_fixed": 0,
                        "width": 3,
                        "prefix": null,
                        "suffix": null,
                        "format": null,
                        "data_typ": "ENUM",
                        "column_tag": {
                          "tag_typ": "SQUARE",
                          "tag_data_typ": "ENUM",
                          "possible_values": [
                            {
                              "value": "Bank",
                              "color": "#D84949"
                            },
                            {
                              "value": "Angefordert",
                              "color": "#D2B836"
                            },
                            {
                              "value": "Lagernd",
                              "color": "#6A9E18"
                            },
                            {
                              "value": null,
                              "color": "#D84949"
                            },
                          ]
                        }
                      },
                      {
                          "sub_menu_column_id": 210,
                          "title": "Finanzsperrauskunft",
                          "sort_value": 50,
                          "data_object": "purchase_contract",
                          "calculated": 0,
                          "data_key": "vehicle.finanzsperrauskunft_status",
                          "is_sortable": 1,
                          "sort_function": null,
                          "is_fixed": 0,
                          "width": 4,
                          "prefix": null,
                          "suffix": null,
                          "format": null,
                          "data_typ": "ENUM",
                          "column_tag": {
                              "tag_typ": "SQUARE",
                              "tag_data_typ": "ENUM",
                              "possible_values": [
                                  {
                                    "value": "Gesperrt",
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": "Nicht gefunden",
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": "Unbekannt",
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": null,
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": "Freigegeben",
                                    "color": "#6A9E18"
                                  },
                                  {
                                    "value": "Erstauslieferung Österreich",
                                    "color": "#6A9E18"
                                  },
                              ]
                          },
                      },
                      {
                        "sub_menu_column_id": 209,
                        "title": "Datenauszug",
                        "sort_value": 60,
                        "data_object": "purchase_contract",
                        "calculated": 1,
                        "data_key": "getAustriaDataExtractStatus",
                        "is_sortable": 1,
                        "sort_function": "getAustriaDataExtractStatus",
                        "is_fixed": 0,
                        "width": 3,
                        "prefix": null,
                        "suffix": null,
                        "format": null,
                        "data_typ": "ENUM",
                        "column_tag": {
                          "tag_typ": "SQUARE",
                          "tag_data_typ": "ENUM",
                          "possible_values": [
                            {
                              "value": "nicht notwendig",
                              "color": "#6A9E18"
                            },
                            {
                              "value": "angefordert",
                              "color": "#D2B836"
                            },
                            {
                              "value": "lagernd",
                              "color": "#6A9E18"
                            },
                            {
                              "value": null,
                              "color": "#D84949"
                            },
                          ]
                        }
                      },
                  ],
                  "actions": ['details','change_finanzsperrauskunft','change_austrian_data_extract'],
                  "is_hidden": 0,
                  "pagination": 1,
                  "filter_id": 8,
                  "filterable_id": 7,
                  "default_sort_column_id": 2,
                  "default_sort_direction": null
              },
              {
                "sub_menu_id": 2200,
                "name": "Abgeschlossen",
                "sort_value": 1,
                "filter": [
                  {
                    "calculated": 0,
                    "data_key": "getContractStatus",
                    "data_object": "purchase_contract",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "5",
                  },
                ],
                "filterable": [
                ],
                "columns": [
                      {
                        "sub_menu_column_id": 22001,
                        "title": "ID",
                        "sort_value": 10,
                        "data_object": "purchase_contract",
                        "calculated": 0,
                        "data_key": "purchase_contract_id",
                        "is_sortable": 1,
                        "sort_function": null,
                        "is_fixed": 1,
                        "width": 1,
                        "prefix": null,
                        "suffix": null,
                        "format": null,
                        "data_typ": "STRING"
                      },
                      {
                        "sub_menu_column_id": 22002,
                        "title": "Modell",
                        "data_object": "purchase_contract",
                        "data_key": "vehicle.getDescription",
                        "calculated": 0,
                        "format": null,
                        "data_typ": "STRING",
                        "is_fixed": 0,
                        "is_sortable": 0,
                        "prefix": 0,
                        "sort_value": 30,
                        "suffix": 0,
                        "width": 8,
                      },
                      {
                        "sub_menu_column_id": 22003,
                        "title": "Typenschein",
                        "sort_value": 40,
                        "data_object": "purchase_contract",
                        "calculated": 0,
                        "data_key": "vehicle.finanzsperrauskunft",
                        "is_sortable": 1,
                        "sort_function": null,
                        "is_fixed": 0,
                        "width": 3,
                        "prefix": null,
                        "suffix": null,
                        "format": null,
                        "data_typ": "ENUM",
                        "column_tag": {
                          "tag_typ": "SQUARE",
                          "tag_data_typ": "ENUM",
                          "possible_values": [
                            {
                              "value": "Bank",
                              "color": "#D84949"
                            },
                            {
                              "value": "Angefordert",
                              "color": "#D2B836"
                            },
                            {
                              "value": "Lagernd",
                              "color": "#6A9E18"
                            },
                            {
                              "value": null,
                              "color": "#D84949"
                            },
                          ]
                        }
                      },
                      {
                          "sub_menu_column_id": 22004,
                          "title": "Finanzsperrauskunft",
                          "sort_value": 50,
                          "data_object": "purchase_contract",
                          "calculated": 0,
                          "data_key": "vehicle.finanzsperrauskunft_status",
                          "is_sortable": 1,
                          "sort_function": null,
                          "is_fixed": 0,
                          "width": 4,
                          "prefix": null,
                          "suffix": null,
                          "format": null,
                          "data_typ": "ENUM",
                          "column_tag": {
                              "tag_typ": "SQUARE",
                              "tag_data_typ": "ENUM",
                              "possible_values": [
                                  {
                                    "value": "Gesperrt",
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": "Nicht gefunden",
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": "Unbekannt",
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": null,
                                    "color": "#D84949"
                                  },
                                  {
                                    "value": "Freigegeben",
                                    "color": "#6A9E18"
                                  },
                                  {
                                    "value": "Erstauslieferung Österreich",
                                    "color": "#6A9E18"
                                  },
                              ]
                          },
                      },
                      {
                        "sub_menu_column_id": 22005,
                        "title": "Datenauszug",
                        "sort_value": 60,
                        "data_object": "purchase_contract",
                        "calculated": 1,
                        "data_key": "getAustriaDataExtractStatus",
                        "is_sortable": 1,
                        "sort_function": "getAustriaDataExtractStatus",
                        "is_fixed": 0,
                        "width": 3,
                        "prefix": null,
                        "suffix": null,
                        "format": null,
                        "data_typ": "ENUM",
                        "column_tag": {
                          "tag_typ": "SQUARE",
                          "tag_data_typ": "ENUM",
                          "possible_values": [
                            {
                              "value": "nicht notwendig",
                              "color": "#6A9E18"
                            },
                            {
                              "value": "angefordert",
                              "color": "#D2B836"
                            },
                            {
                              "value": "lagernd",
                              "color": "#6A9E18"
                            },
                            {
                              "value": null,
                              "color": "#D84949"
                            },
                          ]
                        }
                      },
                ],
                "actions": ['details'],
                "is_hidden": 0,
                "pagination": 1,
                "filter_id": 8,
                "filterable_id": 7,
                "default_sort_column_id": 22001,
                "default_sort_direction": null
              }
            ]
        }
        ]
        let menu_rent = [
          {
            'main_menu_id': 800,
            'name': 'Miet-Abo',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 8001,
                "name": "Offen",
                "actions": ['details', 'edit', 'delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 80001,
                    "title": "Miet-ID",
                    "data_object": "rent",
                    "data_key": "rent_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 80002,
                    "title": "Kunde",
                    "data_object": "rent",
                    "data_key": "main_driver.getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 80003,
                    "title": "Fahrzeug",
                    "data_object": "rent",
                    "data_key": "vehicle.getVehicleName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 7,
                  },
                  {
                    "sub_menu_column_id": 80004,
                    "title": "Miet-Typ",
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Miet-Abo",
                            "color": "#E26DC9"
                          },
                          {
                            "value": "Kurzzeit",
                            "color": "#3D2CD4"
                          },
                          {
                            "value": "Spezial",
                            "color": "#1C7C87"
                          }
                      ]
                    }
                  },
                  {
                    "sub_menu_column_id": 80005,
                    "title": "Miete in €",
                    "data_object": "rent",
                    "data_key": "getPriceInEUR",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 20,
                    "suffix": " €",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 80006,
                    "title": "Mietdauer",
                    "data_object": "rent",
                    "data_key": "rent_duration",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 80007,
                    "title": "Vertrag bis",
                    "data_object": "rent",
                    "data_key": "rental_end_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 140008,
                    "title": "Status",
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "ENUM",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Übergabe",
                                      "color": "#D2B836"
                                    },
                                    {
                                      "value": "Läuft",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Rücknahme",
                                      "color": "#95550C"
                                    },
                                    {
                                      "value": "Abgeschlossen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Archiviert",
                                      "color": "#95550C"
                                    },
                                ]
                            },
                  },
                ],
                "default_sort_column_id": 80001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "1",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "1",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 8002,
                "name": "Übergeben",
                "actions": ['details', 'edit', 'delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 80001,
                    "title": "Miet-ID",
                    "data_object": "rent",
                    "data_key": "rent_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 80002,
                    "title": "Kunde",
                    "data_object": "rent",
                    "data_key": "main_driver.getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 80003,
                    "title": "Fahrzeug",
                    "data_object": "rent",
                    "data_key": "vehicle.getVehicleName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 7,
                  },
                  {
                    "sub_menu_column_id": 80004,
                    "title": "Miet-Typ",
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                        {
                          "value": "Miet-Abo",
                          "color": "#E26DC9"
                        },
                        {
                          "value": "Kurzzeit",
                          "color": "#3D2CD4"
                        },
                        {
                          "value": "Spezial",
                          "color": "#1C7C87"
                        }
                      ]
                    }
                  },
                  {
                    "sub_menu_column_id": 80005,
                    "title": "Miete in €",
                    "data_object": "rent",
                    "data_key": "getPriceInEUR",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 20,
                    "suffix": " €",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 80006,
                    "title": "Mietdauer",
                    "data_object": "rent",
                    "data_key": "rent_duration",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 80007,
                    "title": "Vertrag bis",
                    "data_object": "rent",
                    "data_key": "rental_end_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 150008,
                    "title": "Status",
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "ENUM",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Übergabe",
                                      "color": "#D2B836"
                                    },
                                    {
                                      "value": "Läuft",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Rücknahme",
                                      "color": "#95550C"
                                    },
                                    {
                                      "value": "Abgeschlossen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Archiviert",
                                      "color": "#95550C"
                                    },
                                ]
                            },
                  },
                ],
                "default_sort_column_id": 80001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "1",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "1",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "4",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "5",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 8003,
                "name": "Abgeschlossen",
                "actions": ['details', 'edit', 'delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 80001,
                    "title": "Miet-ID",
                    "data_object": "rent",
                    "data_key": "rent_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 80002,
                    "title": "Kunde",
                    "data_object": "rent",
                    "data_key": "main_driver.getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 80003,
                    "title": "Fahrzeug",
                    "data_object": "rent",
                    "data_key": "vehicle.getVehicleName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 7,
                  },
                  {
                    "sub_menu_column_id": 80004,
                    "title": "Miet-Typ",
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                        {
                          "value": "Miet-Abo",
                          "color": "#E26DC9"
                        },
                        {
                          "value": "Kurzzeit",
                          "color": "#3D2CD4"
                        },
                        {
                          "value": "Spezial",
                          "color": "#1C7C87"
                        }
                    ]
                    }
                  },
                  {
                    "sub_menu_column_id": 80005,
                    "title": "Miete in €",
                    "data_object": "rent",
                    "data_key": "getPriceInEUR",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 20,
                    "suffix": " €",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 80006,
                    "title": "Mietdauer",
                    "data_object": "rent",
                    "data_key": "rent_duration",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 80007,
                    "title": "Vertrag bis",
                    "data_object": "rent",
                    "data_key": "rental_end_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 160008,
                    "title": "Status",
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "ENUM",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Übergabe",
                                      "color": "#D2B836"
                                    },
                                    {
                                      "value": "Läuft",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Rücknahme",
                                      "color": "#95550C"
                                    },
                                    {
                                      "value": "Abgeschlossen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Archiviert",
                                      "color": "#95550C"
                                    },
                                ]
                            },
                  },
                ],
                "default_sort_column_id": 80001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "1",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "4",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
            ],
          },
          {
            'main_menu_id': 900,
            'name': 'Kurzzeitmiete',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 9001,
                "name": "Offen",
                "actions": ['details', 'edit', 'delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 90001,
                    "title": "Miet-ID",
                    "data_object": "rent",
                    "data_key": "rent_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 90002,
                    "title": "Kunde",
                    "data_object": "rent",
                    "data_key": "main_driver.getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 90003,
                    "title": "Fahrzeug",
                    "data_object": "rent",
                    "data_key": "vehicle.getVehicleName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 7,
                  },
                  {
                    "sub_menu_column_id": 90004,
                    "title": "Miet-Typ",
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                        {
                          "value": "Miet-Abo",
                          "color": "#E26DC9"
                        },
                        {
                          "value": "Kurzzeit",
                          "color": "#3D2CD4"
                        },
                        {
                          "value": "Spezial",
                          "color": "#1C7C87"
                        }
                      ]
                    }
                  },
                  {
                    "sub_menu_column_id": 90005,
                    "title": "Miete in €",
                    "data_object": "rent",
                    "data_key": "getPriceInEUR",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 20,
                    "suffix": " €",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 90006,
                    "title": "Mietdauer",
                    "data_object": "rent",
                    "data_key": "rent_duration",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 90007,
                    "title": "Vertrag bis",
                    "data_object": "rent",
                    "data_key": "rental_end_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 170008,
                    "title": "Status",
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "ENUM",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Übergabe",
                                      "color": "#D2B836"
                                    },
                                    {
                                      "value": "Läuft",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Rücknahme",
                                      "color": "#95550C"
                                    },
                                    {
                                      "value": "Abgeschlossen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Archiviert",
                                      "color": "#95550C"
                                    },
                                ]
                            },
                  },
                ],
                "default_sort_column_id": 90001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "2",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "1",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 9002,
                "name": "Übergeben",
                "actions": ['details', 'edit', 'delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 90001,
                    "title": "Miet-ID",
                    "data_object": "rent",
                    "data_key": "rent_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 90002,
                    "title": "Kunde",
                    "data_object": "rent",
                    "data_key": "main_driver.getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 90003,
                    "title": "Fahrzeug",
                    "data_object": "rent",
                    "data_key": "vehicle.getVehicleName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 7,
                  },
                  {
                    "sub_menu_column_id": 90004,
                    "title": "Miet-Typ",
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                        {
                          "value": "Miet-Abo",
                          "color": "#E26DC9"
                        },
                        {
                          "value": "Kurzzeit",
                          "color": "#3D2CD4"
                        },
                        {
                          "value": "Spezial",
                          "color": "#1C7C87"
                        }
                      ]
                    }
                  },
                  {
                    "sub_menu_column_id": 90005,
                    "title": "Miete in €",
                    "data_object": "rent",
                    "data_key": "getPriceInEUR",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 20,
                    "suffix": " €",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 90006,
                    "title": "Mietdauer",
                    "data_object": "rent",
                    "data_key": "rent_duration",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 90007,
                    "title": "Vertrag bis",
                    "data_object": "rent",
                    "data_key": "rental_end_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 180008,
                    "title": "Status",
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "ENUM",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Übergabe",
                                      "color": "#D2B836"
                                    },
                                    {
                                      "value": "Läuft",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Rücknahme",
                                      "color": "#95550C"
                                    },
                                    {
                                      "value": "Abgeschlossen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Archiviert",
                                      "color": "#95550C"
                                    },
                                ]
                            },
                  },
                ],
                "default_sort_column_id": 90001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "2",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "1",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "4",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "5",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 9003,
                "name": "Abgeschlossen",
                "actions": ['details', 'edit', 'delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 90001,
                    "title": "Miet-ID",
                    "data_object": "rent",
                    "data_key": "rent_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 90002,
                    "title": "Kunde",
                    "data_object": "rent",
                    "data_key": "main_driver.getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 90003,
                    "title": "Fahrzeug",
                    "data_object": "rent",
                    "data_key": "vehicle.getVehicleName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 7,
                  },
                  {
                    "sub_menu_column_id": 90004,
                    "title": "Miet-Typ",
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                        {
                          "value": "Miet-Abo",
                          "color": "#E26DC9"
                        },
                        {
                          "value": "Kurzzeit",
                          "color": "#3D2CD4"
                        },
                        {
                          "value": "Spezial",
                          "color": "#1C7C87"
                        }
                      ]
                    }
                  },
                  {
                    "sub_menu_column_id": 90005,
                    "title": "Miete in €",
                    "data_object": "rent",
                    "data_key": "getPriceInEUR",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 20,
                    "suffix": " €",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 90006,
                    "title": "Mietdauer",
                    "data_object": "rent",
                    "data_key": "rent_duration",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 90007,
                    "title": "Vertrag bis",
                    "data_object": "rent",
                    "data_key": "rental_end_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 190008,
                    "title": "Status",
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "ENUM",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Übergabe",
                                      "color": "#D2B836"
                                    },
                                    {
                                      "value": "Läuft",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Rücknahme",
                                      "color": "#95550C"
                                    },
                                    {
                                      "value": "Abgeschlossen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Archiviert",
                                      "color": "#95550C"
                                    },
                                ]
                            },
                  },
                ],
                "default_sort_column_id": 90001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "2",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "4",
                  },
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
            ],
          },
          {
            'main_menu_id': 1000,
            'name': 'Spezialmiete',
            'sort_value': 0,
            'sub_menus': [
              {
                "sub_menu_id": 10001,
                "name": "Offen",
                "actions": ['details', 'edit', 'delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 100001,
                    "title": "Miet-ID",
                    "data_object": "rent",
                    "data_key": "rent_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 100002,
                    "title": "Kunde",
                    "data_object": "rent",
                    "data_key": "main_driver.getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 100003,
                    "title": "Fahrzeug",
                    "data_object": "rent",
                    "data_key": "vehicle.getVehicleName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 7,
                  },
                  {
                    "sub_menu_column_id": 100004,
                    "title": "Miet-Typ",
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Miet-Abo",
                            "color": "#E26DC9"
                          },
                          {
                            "value": "Kurzzeit",
                            "color": "#3D2CD4"
                          },
                          {
                            "value": "Spezial",
                            "color": "#1C7C87"
                          }
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 100005,
                    "title": "Miete in €",
                    "data_object": "rent",
                    "data_key": "getPriceInEUR",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 20,
                    "suffix": " €",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 100006,
                    "title": "Mietdauer",
                    "data_object": "rent",
                    "data_key": "rent_duration",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 100007,
                    "title": "Vertrag bis",
                    "data_object": "rent",
                    "data_key": "rental_end_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 120008,
                    "title": "Status",
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "ENUM",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Übergabe",
                                      "color": "#D2B836"
                                    },
                                    {
                                      "value": "Läuft",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Rücknahme",
                                      "color": "#95550C"
                                    },
                                    {
                                      "value": "Abgeschlossen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Archiviert",
                                      "color": "#95550C"
                                    },
                                ]
                            },
                  },
                ],
                "default_sort_column_id": 100001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "3",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "1",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 10002,
                "name": "Übergeben",
                "actions": ['details', 'edit', 'delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 100001,
                    "title": "Miet-ID",
                    "data_object": "rent",
                    "data_key": "rent_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 100002,
                    "title": "Kunde",
                    "data_object": "rent",
                    "data_key": "main_driver.getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 100003,
                    "title": "Fahrzeug",
                    "data_object": "rent",
                    "data_key": "vehicle.getVehicleName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 7,
                  },
                  {
                    "sub_menu_column_id": 100004,
                    "title": "Miet-Typ",
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Privat",
                            "color": "#3D2CD4"
                          },
                          {
                             "value": "Unternehmen",
                             "color": "#95550C"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 100005,
                    "title": "Miete in €",
                    "data_object": "rent",
                    "data_key": "getPriceInEUR",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 20,
                    "suffix": " €",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 100006,
                    "title": "Mietdauer",
                    "data_object": "rent",
                    "data_key": "rent_duration",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 100007,
                    "title": "Vertrag bis",
                    "data_object": "rent",
                    "data_key": "rental_end_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 100008,
                    "title": "Status",
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "ENUM",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Übergabe",
                                      "color": "#D2B836"
                                    },
                                    {
                                      "value": "Läuft",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Rücknahme",
                                      "color": "#95550C"
                                    },
                                    {
                                      "value": "Abgeschlossen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Archiviert",
                                      "color": "#95550C"
                                    },
                                ]
                            },
                  },
                ],
                "default_sort_column_id": 100001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "3",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "1",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "4",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "<>",
                    "raw_value": "5",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              },
              {
                "sub_menu_id": 10003,
                "name": "Abgeschlossen",
                "actions": ['details', 'edit', 'delete'],
                "columns": [
                  {
                    "sub_menu_column_id": 100001,
                    "title": "Miet-ID",
                    "data_object": "rent",
                    "data_key": "rent_id",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 10,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 100002,
                    "title": "Kunde",
                    "data_object": "rent",
                    "data_key": "main_driver.getFullName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                  },
                  {
                    "sub_menu_column_id": 100003,
                    "title": "Fahrzeug",
                    "data_object": "rent",
                    "data_key": "vehicle.getVehicleName",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 7,
                  },
                  {
                    "sub_menu_column_id": 100004,
                    "title": "Miet-Typ",
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 50,
                    "suffix": 0,
                    "width": 3,
                    "column_tag": {
                      "tag_typ": "SQUARE",
                      "tag_data_typ": "ENUM",
                      "possible_values": [
                          {
                            "value": "Privat",
                            "color": "#3D2CD4"
                          },
                          {
                             "value": "Unternehmen",
                             "color": "#95550C"
                          },
                      ]
                    },
                  },
                  {
                    "sub_menu_column_id": 100005,
                    "title": "Miete in €",
                    "data_object": "rent",
                    "data_key": "getPriceInEUR",
                    "calculated": 1,
                    "format": "0.00",
                    "data_typ": "NUMBER",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": null,
                    "sort_value": 20,
                    "suffix": " €",
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 100006,
                    "title": "Mietdauer",
                    "data_object": "rent",
                    "data_key": "rent_duration",
                    "calculated": 1,
                    "format": null,
                    "data_typ": "STRING",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 100007,
                    "title": "Vertrag bis",
                    "data_object": "rent",
                    "data_key": "rental_end_date",
                    "calculated": 0,
                    "format": "dd.MM.yyyy",
                    "data_typ": "DATE",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 2,
                  },
                  {
                    "sub_menu_column_id": 130008,
                    "title": "Status",
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "calculated": 0,
                    "format": null,
                    "data_typ": "ENUM",
                    "is_fixed": 0,
                    "is_sortable": 1,
                    "prefix": 0,
                    "sort_value": 20,
                    "suffix": 0,
                    "width": 4,
                    "column_tag": {
                                "tag_typ": "SQUARE",
                                "tag_data_typ": "ENUM",
                                "possible_values": [
                                    {
                                      "value": "Offen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Übergabe",
                                      "color": "#D2B836"
                                    },
                                    {
                                      "value": "Läuft",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Rücknahme",
                                      "color": "#95550C"
                                    },
                                    {
                                      "value": "Abgeschlossen",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Storniert",
                                      "color": "#ABABAB"
                                    },
                                    {
                                      "value": "Archiviert",
                                      "color": "#95550C"
                                    },
                                ]
                            },
                  },
                ],
                "default_sort_column_id": 100001,
                "default_sort_direction": null,
                "filter": [
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_typ",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "3",
                  },
                  {
                    "filter_operator": "&&"
                  },
                  {
                    "calculated": 0,
                    "data_object": "rent",
                    "data_key": "rent_status",
                    "data_typ": "NUMBER",
                    "operator": "=",
                    "raw_value": "4",
                  }
                ],
                "filter_id": null,
                "filterable": [],
                "filterable_id": null,
                "is_hidden": 0,
                "pagination": 1,
              }
            ],
          }
        ];
        let menuComesFromServer = false;
        for(let menu of result.menus){
          for(let submenu of menu.sub_menus){
            for(let column of submenu.columns){
              if(column.data_object != 'vehicle'){
                menuComesFromServer = true;
              }
            }
          }
        }
        let isHoedl = (result.user.mandant_id == 7 && environment.api.includes('hoedl')) || environment.api.includes('localhost:8877/api/v1') || (result.user.mandant_id == 7 && (environment.api.includes('test') || environment.api.includes('192.168.0.24:8797')));
        let isSinhuber = (result.user.mandant_id == 2 && environment.api.includes('sinhuber'));
        let menusForAPP = [];
        menusForAPP = [...result.menus];
        if(!menuComesFromServer){
          if(environment.api.includes('hoedl') || environment.api.includes('test')) {
            menusForAPP.push(...menu_accounting);
          }
          menusForAPP.push(...menu_test_drives);
          menusForAPP.push(...menu_tires);
          menusForAPP.push(...menu_users);
          menusForAPP.push(...menu_locations);
          // Raiffeisen Leasing. Wieso? Weil man noch nicht die Kundenlistenanischt-Action entfernen kann (Kaufvetrag erstellen).
          // Somit muss ein eigenes Menu für ihm hier definiert werden...
          // if(environment.api.includes('//api.') && result.user.mandant_id == 8) menusForAPP.push(...menu_customer_standard);
          // else
          menusForAPP.push(...menu_customer);
          menusForAPP.push(...menu_rent);
          if(isHoedl && (username.includes("waymark.at") ||  username.includes("laurastrommer@hotmail.com"))) {
            menusForAPP.push(...menu_accounting_erweitert);
          }
          else if(!isHoedl) {
            menusForAPP.push(...menu_accounting_erweitert);
          }

          if(isHoedl) {
            menusForAPP.push(...menu_documents_hoedl);
            menusForAPP.push(...menu_purchase_contract_uebergabe_hoedl);
          }
          else if(isSinhuber) menusForAPP.push(...menu_documents_sinhuber);
          else menusForAPP.push(...menu_documents_standard);
        }
        localStorage.setItem('menus', JSON.stringify(menusForAPP));
        // localStorage.setItem('menu_accounting', JSON.stringify(menu_accounting));
        // localStorage.setItem('menu_test_drives', JSON.stringify(menu_test_drives));
        // localStorage.setItem('menu_users', JSON.stringify(menu_users));
        // localStorage.setItem('menu_locations', JSON.stringify(menu_locations));
        // localStorage.setItem('menu_customer', JSON.stringify(menu_customer));
        // localStorage.setItem('menu_rent', JSON.stringify(menu_rent));
        document.documentElement.style.setProperty('--heigth-scroll-thumb', (this.authService.isSinhuber() ? 16 : 8) + 'px');
        return true;
      }),
      catchError(error => {
        console.log(error);
        return of(false);
      })
    );
  }

  logout() {
    this.authService.logout();
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let auth = Number(localStorage.getItem('auth'));
    if(localStorage.getItem('token')){
      if(state.url.includes('admin')){
        if(auth == 1 || auth == 2) return true; return false;
      }
      if(state.url.includes('customers')){
        if(auth == 1 || auth == 2 || auth == 3 || auth == 4) return true; return false;
      } else if(state.url.includes('cars')){
        if(auth == 1 || auth == 2 || auth == 3 || auth == 4) return true; return false;
      } else if(state.url.includes('dashboard')){
        if(auth == 1 || auth == 2 || auth == 3 || auth == 4) return true; return false;
      } else if(state.url.includes('interfaces')){
        if(auth == 1 || auth == 2 || auth == 3 || auth == 4 || auth == 10) return true; return false;
      } else if(state.url.includes('advertising')){
        if(auth == 1 || auth == 2 || auth == 3 || auth == 4) return true; return false;
      } else if(state.url.includes('material')){
        if(auth == 1 || auth == 2 || auth == 3 || auth == 4) return true; return false;
      } else if(state.url.includes('messages')){
        if(auth == 1 || auth == 2 || auth == 3 || auth == 4) return true; return false;
      } else if(state.url.includes('ticket')){
        if(auth == 1 || auth == 2 || auth == 3 || auth == 4) return true; return false;
      } else if(state.url.includes('documents')){
        if(auth == 1 || auth == 2 || auth == 3 || auth == 4) return true; return false;
      } else if(state.url.includes('drives')){
        if(auth == 1 || auth == 2 || auth == 6) return true; return false;
      }
      return false;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

  public sendResetPassword(username: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable =  this.http.put(environment.api + '/employee/password',
    {
      username: username
    },{ headers });
    return observable.pipe(
      map((result: any) => {
        return true;
      }),
      catchError(error => {
        if(error.status == 403){
          this.logout();
        } else {
          console.log(error)
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }

  public resetPassword(token: string, password: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const observable = this.http.put(environment.api + '/employee/resetpassword', JSON.stringify({token: token, password: password}), {headers});
    return observable.pipe(
      map((result: any)=> {
        return true;
      }),
      catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.logout();
        } else if(error.status == 402){
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }

  public updatePassword(passwordOld: string, passwordNew: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.put(environment.api + '/back/employee/password',
    {
      old_password: passwordOld,
      new_password: passwordNew
    }, {headers});
    return observable.pipe(
      map((result: any)=> {
        return true;
      }),
      catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.logout();
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }
}

