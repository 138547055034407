<div id="main_container" *ngIf="isLoaded">
    <app-messages-topbar
        [filter]="filter"
        [surface]="surface"
        [sortObject]="sortObject"
        (filterEmitter)="filterThreads($event)"
        (searchEmitter)="searchThreads($event)"
        (openCreateEmitter)="openCreate()"
        (cancelCreateEmitter)="cancelCreateAction()"
        (backEmitter)="closeDetails()"
        (applyFilterEmitter)="applyFilter()"
        (resetFilterEmitter)="resetFilter()"
    ></app-messages-topbar>
    <app-messages-list *ngIf="surface == 1 && isLoaded"
        [threads]="threadsFiltered"
        [sliceTo]="sliceTo"
        [sliceFrom]="sliceFrom"
        [pageInfo]="pageInfo"
        [employees]="employees"
        [filter]="filter"
        [sortObject]="sortObject"
        [userId]="userId"
        (pageEmitter)="pageHandling($event)"
        (resolveEmitter)="resolveThread($event)"
        (openDetailsEmitter)="openDetails($event)"
        (removeFilterEmitter)="removeFilterValue($event)"
        (applySortEmitter)="applySorting($event)"
    ></app-messages-list>
    <app-messages-create *ngIf="surface == 2 && isLoaded"
        [employees]="employees"
        [currentUser]="currentUser"
        [threadForm]="threadForm"
        [customers]="customers"
        [vehicles]="vehicles"
        [customerId]="customerId"
        [submitted]="submitted"
        (submitEmitter)="createThread()"
    ></app-messages-create>
    <app-messages-detail *ngIf="surface == 3 && isLoaded"
        [thread]="selectedThread"
        [employees]="employees"
        [userId]="userId"
        (sendEmitter)="createMessage($event)"
        (resolveEmitter)="resolveThread($event)"
    ></app-messages-detail>
</div>
