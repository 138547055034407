import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Location } from 'src/app/buisness-object/location/Location';

@Component({
  selector: 'app-list-locations-element',
  templateUrl: './list-locations-element.component.html',
  styleUrls: ['./list-locations-element.component.css']
})
export class ListLocationsElementComponent implements OnInit {
  public showDropdown = false;
  @ViewChild('dropdownImg') dropdownImg: ElementRef;
  @ViewChild('dropdownOptions') dropdownOptions: ElementRef;
  @Input() location: Location;
  @Output() openDetailsEmitter = new EventEmitter<undefined>();
  @Output() changeSurfaceEmitter = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }
}
