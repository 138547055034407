<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button class="button-icon-start" (click)="selectionBack()">
      <img class="button-icon-start-img" src="./assets/back.svg"/>
      <span class="button-icon-start-span">Zurück</span>
    </button>
    <h2>Rechnung #{{obj.bill_id}}</h2>
  </div>
  <div class="header-button-wrapper">
    <!-- <button *ngIf="obj.status != BillStatus.PAID && obj.status != BillStatus.STORNO" class="button_type_2" (click)="shwoActions = !shwoActions" appDropdown [show]="shwoActions" (changedValueEmitter)="shwoActions = $event">
      Aktionen
      <img src="./assets/dropdown-arrow-blue.svg">
      <div *ngIf="shwoActions">
        <button *ngIf="!obj.in_accounting && (obj.status == BillStatus.DRAFT || obj.status == BillStatus.READY)" (click)="selectionEditEmitter.emit(obj)">Bearbeiten</button>
        <button *ngIf="(obj.status == BillStatus.SENT || obj.status == BillStatus.WAITING || obj.status == BillStatus.PAID)" (click)="selectionCancelBill()">Stornieren</button>
        <button *ngIf="(obj.status == BillStatus.SENT || obj.status == BillStatus.WAITING || obj.status == BillStatus.PAID)" (click)="selectionRenewBill()">Neu erstellen</button>
      </div>
    </button> -->
    <button *ngIf="obj.status != BillStatus.PAID && obj.status != BillStatus.STORNO"
      class="button_type_2"
      (click)="shwoActions = !shwoActions"
      appDropdown
      [show]="shwoActions"
      (changedValueEmitter)="shwoActions = $event">
      Aktionen
      <img src="./assets/dropdown-arrow-blue.svg">
      <div *ngIf="shwoActions">
        <app-restricted-button
          *ngIf="!obj.in_accounting && (obj.status == BillStatus.DRAFT || obj.status == BillStatus.READY)"
          [name]="'Bearbeiten'"
          [access]="'bill_edit'"
          [isSecondary]="true"
          [isDropdownButton]="true"
          (clickEmitter)="selectionEditEmitter.emit(obj)">
        </app-restricted-button>
        <app-restricted-button
          *ngIf="(obj.status == BillStatus.SENT || obj.status == BillStatus.WAITING || obj.status == BillStatus.PAID)"
          [name]="'Stornieren'"
          [access]="'bill_edit'"
          [isSecondary]="true"
          (clickEmitter)="selectionCancelBill()">
        </app-restricted-button>
        <app-restricted-button
          *ngIf="(obj.status == BillStatus.SENT || obj.status == BillStatus.WAITING || obj.status == BillStatus.PAID)"
          [name]="'Neu erstellen'"
          [access]="'bill_edit'"
          [isSecondary]="true"
          (clickEmitter)="selectionRenewBill()">
        </app-restricted-button>
      </div>
    </button>
    <app-restricted-button
      *ngIf="obj.status != BillStatus.PAID && obj.status != BillStatus.STORNO"
      [name]="'Bezahlt'"
      [access]="'bill_edit'"
      [isSecondary]="true"
      (clickEmitter)="selectionPaid()">
    </app-restricted-button>
    <app-restricted-button
      [name]="'PDF Drucken'"
      [access]="'bill_view'"
      [isPrimary]="true"
      (clickEmitter)="selectionPDFPrint()">
    </app-restricted-button>
    <!-- <button *ngIf="obj.status != BillStatus.PAID && obj.status != BillStatus.STORNO" class="btn_white" (click)="selectionPaid()">Bezahlt</button>
    <button class="btn_submit" (click)="selectionPDFPrint()">PDF Drucken</button> -->
  </div>
</div>

<div class="body-base-style">
  <div class="btn-tab-wrapper">
    <!-- <button class="btn-tab btn-tab-first" [class.btn-tab-active]="showTab == tabView.Details" (click)="null">Details</button>
    <button *ngIf="false" class="btn-tab btn-last" [class.btn-tab-active]="showTab == tabView.Tickets" (click)="null">Tickets</button> -->
    <button id="btn-previous" class="btn-tab btn-tab-first" [disabled]="!isPreviousObjAvailable" (click)="selectionPrevious()">Vorherige Rechnung</button>
    <button id="btn-next" class="btn-tab btn-tab-last" [disabled]="!isNextObjAvailable" (click)="selectionNext()">Nächste Rechnung</button>
  </div>
  <div id="kaufvertrag-details-body" *ngIf="showTab == tabView.Details">
    <div id="header-column" class="overview_data_box_vertical">
      <div class="overview_record_vertical">
        <h3>Betreff</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="obj.subject"></label>
          <label class="lbl_italic">RE-Nr. {{obj.bill_id}}</label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Rechnungsempfänger</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="obj.bill_recipient?.getFullName()"></label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Austellungsdatum</h3>
        <div>
          <label [appFormatValue]="'date'" [value]="obj.bill_date"></label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Bezahl-Status</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="billStatus" [ngClass]="billStatusStyle"></label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Buchhaltung</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="obj.in_accounting ? 'Übermittelt' : 'Nicht übermittelt'" [ngClass]="obj.in_accounting ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_gray'"></label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Gesamtsumme</h3>
        <div>
          <label [appFormatValue]="'currency'" [value]="totalBrutto"></label>
          <label class="lbl_italic">inkl. MwSt.</label>
        </div>
      </div>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible] id="bill-overview">
        <h3 collapsible-header>Rechnungsübersicht</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label>Gesamtbetrag (Netto)</label>
            <label [appFormatValue]="'currency'" [value]="totalNetto"></label>
          </div>
          <div class="overview_record" *ngIf="totalTax10">
            <label>Steuer 10%</label>
            <label [appFormatValue]="'currency'" [value]="totalTax10"></label>
          </div>
          <div class="overview_record" *ngIf="totalTax20">
            <label>Steuer 20%</label>
            <label [appFormatValue]="'currency'" [value]="totalTax20"></label>
          </div>
          <div class="overview_record">
            <label>Gesamtbetrag (Brutto)</label>
            <label [appFormatValue]="'currency'" [value]="totalBrutto"></label>
          </div>
          <div class="overview_record">
            <label>Zahlungsart</label>
            <label [appFormatValue]="'string'" [value]="paymentConditionValue"></label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Rechnungsempfänger</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label>Vorname</label>
            <label [appFormatValue]="'string'" [value]="obj.bill_recipient?.contactPerson.firstName"></label>
          </div>
          <div class="overview_record">
            <label>Nachname</label>
            <label [appFormatValue]="'string'" [value]="obj.bill_recipient?.contactPerson.lastName"></label>
          </div>
          <div class="overview_record" *ngIf="obj.bill_recipient?.typ == 1">
            <label>Unternehmen</label>
            <label [appFormatValue]="'string'" [value]="obj.bill_recipient?.companyName"></label>
          </div>
          <div class="overview_record">
            <label>Anschrift</label>
            <label [appFormatValue]="'string'" [value]="obj.bill_recipient?.address?.getFullAddress()"></label>
          </div>
          <div class="overview_record">
            <label>E-Mail</label>
            <label [appFormatValue]="'string'" [value]="obj.bill_recipient?.contactPerson.email"></label>
          </div>
          <div class="overview_record">
            <label>Tel.</label>
            <label [appFormatValue]="'string'" [value]="obj.bill_recipient?.contactPerson.phone"></label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Sonderkonditionen</h3>
        <div collapsible-content>
          <label [appFormatValue]="'string'" [value]="obj.custom_text"></label>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Rabatte</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label>Marke</label>
            <label [appFormatValue]="'string'" [value]=""></label>
          </div>
        </div>
      </app-collapsible>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible] id="bill-positions">
        <h3 collapsible-header>Rechnungspositionen (Netto)</h3>
        <div collapsible-content>
          <app-bill-details-position-element *ngFor="let pos of obj.bill_items; let i = index"
            [position]="pos"
            [index]="i"
          ></app-bill-details-position-element>
        </div>
      </app-collapsible>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Zugehörige Dokumente/Anhänge</h3>
        <div collapsible-content>
          <app-files
            [obj]="obj"
          ></app-files>
        </div>
      </app-collapsible>
    </div>
  </div>
</div>

<!--
<app-collapsible [isCollapsible] id="bill-overview">
  <h3 collapsible-header>Rechnungsübersicht</h3>
  <div collapsible-content>
    <div class="overview_record">
      <label>Gesamtbetrag (Netto)</label>
      <label [appFormatValue]="'currency'" [value]="totalNetto"></label>
    </div>
    <div class="overview_record" *ngIf="totalTax10">
      <label>Steuer 10%</label>
      <label [appFormatValue]="'currency'" [value]="totalTax10"></label>
    </div>
    <div class="overview_record" *ngIf="totalTax20">
      <label>Steuer 20%</label>
      <label [appFormatValue]="'currency'" [value]="totalTax20"></label>
    </div>
    <div class="overview_record">
      <label>Gesamtbetrag (Brutto)</label>
      <label [appFormatValue]="'currency'" [value]="totalBrutto"></label>
    </div>
    <div class="overview_record">
      <label>Zahlungsart</label>
      <label [appFormatValue]="'string'" [value]="paymentConditionValue"></label>
    </div>
  </div>
</app-collapsible>
<app-collapsible [isCollapsible] *ngIf="false">
  <h3 collapsible-header>Rabatte</h3>
  <div collapsible-content>
    <div class="overview_record">
      <label>Marke</label>
      <label [appFormatValue]="'string'" [value]=""></label>
    </div>
  </div>
</app-collapsible>
<app-collapsible [isCollapsible] id="bill-positions">
  <h3 collapsible-header>Rechnungspositionen (Netto)</h3>
  <div collapsible-content>
    <app-bill-details-position-element *ngFor="let pos of obj.bill_items; let i = index"
      [position]="pos"
      [index]="i"
    ></app-bill-details-position-element>
  </div>
</app-collapsible>
<app-collapsible [isCollapsible] id="bill-documents">
  <h3 collapsible-header>Zugehörige Dokumente/Anhänge</h3>
  <div collapsible-content>
    <app-files
      [obj]="obj"
    ></app-files>
  </div>
</app-collapsible> -->
