<div id="dialog_window" *ngIf="show">
  <div id="dialog_container">
    <div id="dialog_close_icon" (click)="show = false">&#10005;</div>
    <h1>{{title}}</h1>
      <div id="dialog_conainter_body">
        <input type="number" placeholder="---" [(ngModel)]="formValue">
      </div>
      <div class="dialog_btn_container">
        <button class="btn_label" (click)="show=false">{{btnCancelTxt}}</button>
        <button class="btn_submit_dark" (click)="onSubmit()">{{btnSubmitTxt}}</button>
      </div>
  </div>
</div>
