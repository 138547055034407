import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { PriceSheetCalculater } from 'src/app/buisness-object/pricesheet/PriceSheetCalculater';
import { VehicleDrive } from 'src/app/buisness-object/vehicle/basic/VehicleDrive';
import { VehicleFuel } from 'src/app/buisness-object/vehicle/basic/VehicleFuel';
import { VehicleTransmission } from 'src/app/buisness-object/vehicle/basic/VehicleTransmission';
import { VehicleEnviromentLabel } from 'src/app/buisness-object/vehicle/optional/VehicleEnviromentLabel';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { BaseBoxComponent } from 'src/app/view/BaseBoxComponent';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-drive',
  templateUrl: './drive.component.html',
  styleUrls: ['./drive.component.css'],
  animations: [DropdownIconArrowAnimation],
})
export class DriveComponent extends BaseBoxComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public drives: VehicleDrive[] = [];
  public fuels: VehicleFuel[] = [];
  public transmissions: VehicleTransmission[] = [];
  public environmentLabels: VehicleEnviromentLabel[] = [];

  public fuelsValues = [];
  public drivesValues = [];
  public transmissionsValues = [];
  public environmentLabelValues = [];

  public showDropdownOne = false;
  public showDropdownTwo = false;
  public showDropdownThree = false;
  public showDropdownFour = false;
  public inseratDisable = false;

  public novaValue;

  @ViewChild('dropdown_1') dropdown_1: ElementRef;
  @ViewChild('dropdown_2') dropdown_2: ElementRef;
  @ViewChild('dropdown_3') dropdown_3: ElementRef;
  @ViewChild('dropdown_4') dropdown_4: ElementRef;
  public listener: () => void;

  @Input() vehicle: Vehicle;
  @Input() formGroupVehicle: FormGroup;
  @Input() formGroupPriceSheet: FormGroup;
  @Input() submitted: boolean;

  constructor(
    private vService: VehicleService,
    private renderer: Renderer2,
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.viewTitle = "drive";
    super.init();
    if(this.formGroupVehicle){
      this.inseratDisable = this.authService.isHoedlAdmin() ? false : this.formGroupVehicle.get('inserat_checked').value;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.editMode || changes.formGroupVehicle){
      if(this.editMode && this.formGroupVehicle != null){
        this.formGroupVehicle.get('inserat_checked').valueChanges.subscribe(value => {
          this.inseratDisable = this.authService.isHoedlAdmin() ? false : value;
        });
      }
    }
    if(this.editMode){
      this.requestDataServerside();
    }
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropdownOne){
        let isInside = this.dropdown_1.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropdownOne = false;
        }
      }
      if(this.showDropdownTwo){
        let isInside = this.dropdown_2.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropdownTwo = false;
        }
      }
      if(this.showDropdownThree){
        let isInside = this.dropdown_3.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropdownThree = false;
        }
      }
      if(this.showDropdownFour){
        if(!this.dropdown_4.nativeElement.contains(e.target)) this.showDropdownFour = false;
      }
    })
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  get f() {
    return this.formGroupVehicle.controls;
  }

  requestDataServerside() {
    forkJoin([
      this.vService.getFuels(),
      this.vService.getDrives(),
      this.vService.getTransmissions(),
      this.vService.getEnvironmentLabels(),
    ]).subscribe(([fuels, drives, transmissions, labels]:[VehicleFuel[], VehicleDrive[], VehicleTransmission[], VehicleEnviromentLabel[]]) => {
      if(fuels && drives && transmissions && labels){
        this.fuels = this.sortArray(fuels);
        this.drives = drives;
        this.transmissions = transmissions;
        this.environmentLabels = this.sortArray(labels);
        this.setDropdownValues();
      }
    });
  }

  setDropdownValues() {
    this.fuelsValues = [];
    this.drivesValues = [];
    this.transmissionsValues = [];
    this.environmentLabelValues = [];
    for(let v of this.fuels){
      this.fuelsValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.drives){
      this.drivesValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.transmissions){
      this.transmissionsValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.environmentLabels){
      this.environmentLabelValues.push({
        id: v.id,
        name: v.name
      })
    }
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'fuel'){
      let index = this.fuels.findIndex(v => v.id == id);
      if(index > -1) this.f.fuel.setValue(this.fuels[index]);
    } else if(value === 'drive'){
      let index = this.drives.findIndex(v => v.id == id);
      if(index > -1) this.f.drive.setValue(this.drives[index]);
    } else if(value === 'transmission'){
      let index = this.transmissions.findIndex(v => v.id == id);
      if(index > -1) this.f.transmission.setValue(this.transmissions[index]);
    } else if(value === 'environmentLabels'){
      let index = this.environmentLabels.findIndex(v => v.id == id);
      if(index > -1) this.f.enviromentLbl.setValue(this.environmentLabels[index]);
    }
  }

  sortArray(data: any[]): any[] {
    data.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    for(let i = 0; i < data.length; i++){
      if(data[i].name == 'Sonstige'){
        data.push(data[i]);
        data.splice((i),1);
      }
    }
    return data;
  }

  getUrbanText() {
    return 'Verbrauch innerorts (l/100km)';
  }

  getLandText() {
    return 'Verbrauch außerorts (l/100km)';
  }
}
