import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Attachment, AttachmentCategory, AttachmentHelper, AttachmentType } from 'src/app/buisness-object/attachment/Attachment';
import { RentForm } from 'src/app/buisness-object/rent/form/RentForm';
import { Rent, RentOrderStatus } from 'src/app/buisness-object/rent/Rent';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { RentService } from 'src/app/service/rent/rent.service';

@Component({
  selector: 'app-dialog-miete-receive',
  templateUrl: './dialog-miete-receive.component.html',
  styleUrl: './dialog-miete-receive.component.css'
})
export class DialogMieteReceiveComponent implements OnInit{

  
  @Input() obj: Rent;
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() updateSuccessEmitter = new EventEmitter<any>();
  @Output() selectionSubmitImageEmitter = new EventEmitter<any>();



  public form: FormGroup;
  public submitted = false;
  public attachments: Attachment[] = [];
  public filesAmount;
  public onloadSrc = [];
  public toUploadAttachment: Attachment[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private rentService: RentService,
    private dialogService: DialogService,
    private attachmentService: AttachmentService
  ){}

  ngOnInit(): void {
     this.form = RentForm.getFormExchange(this.formBuilder);
  }

  invalidForm(): boolean {
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return true;
    }
    return false;
  }

  async selectionUpdateRent() {
    this.submitted = true;
    if(this.invalidForm() || this.toUploadAttachment.length == 0) return;
    for(let img of this.toUploadAttachment){
      await this.createAttachment(img);
    }
    this.updateRent(this.obj);
  }

  updateRent(rent: Rent) {
    rent.handover_mileage = this.form.get('mileage').value;
    rent.handover_damage = this.form.get('damage').value;
    rent.rent_status = RentOrderStatus.RECEIVED;
    this.rentService.updateRent(rent).subscribe((result) => {
      if(result){
        result.vehicle = rent.vehicle;
        result.main_driver = rent.main_driver;
        result.second_driver = rent.second_driver;
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Miete wurde aktualisiert.',
          success: true
        });
        this.updateSuccessEmitter.emit(result);
      }
    })
  }

  async onFileDropped(data: any) {
    if(!data) return;
    for(let file of data.data){
      let attachment = new Attachment(
        0,
        AttachmentType.RENT_ORDER,
        AttachmentHelper.getAttachmentTypID(this.obj),
        file.name.split('.')[1],
        file.name.split('.')[0],
        file.content,
        AttachmentCategory.RENT_ORDER_RECEIVE_PICTURE
      )
      this.toUploadAttachment.push(attachment);
      }
  }

  async uploadFile(event: any) {
    this.onloadSrc = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
         let attachment =  await this.readFile(event.target.files[i]);
         this.toUploadAttachment.push(attachment);
      }
      event.target.value = '';
    }
  }


  async readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
          let a = new Attachment(
            0,
            AttachmentType.RENT_ORDER,
            AttachmentHelper.getAttachmentTypID(this.obj),
            file.name.split('.')[1],
            file.name.split('.')[0],
            event.target.result,
            AttachmentCategory.RENT_ORDER_RECEIVE_PICTURE
          )
          resolve(a)
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  selectionDeleteAttachment(a: Attachment) {
    let index = this.toUploadAttachment.findIndex((attachment) => attachment.data == a.data);
    if(index > -1) this.toUploadAttachment.splice(index,1)
  }

  createAttachment(attachment: Attachment): Promise<Attachment> {
    return new Promise<Attachment>((resolve) => {
      this.attachmentService.createAttachment(attachment).subscribe((result) => {
        if(result){
          resolve(attachment);
        } 
        else{
          resolve(null);
        }
      })
    });
  }

}
