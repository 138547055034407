<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button class="button-icon-start" (click)="selectionCancel()">
      <img class="button-icon-start-img" src="./assets/cancle.svg"/>
      <span class="button-icon-start-span">Abbrechen</span>
    </button>
    <h2>Probefahrten-Formular {{object!=null ? 'bearbeiten' : 'erstellen'}} ({{typ == 'customer' ? 'Kunde' : 'Mitarbeiter'}})</h2>
  </div>
  <div class="header-button-wrapper">
    <button class="btn_submit" (click)="selectionCreate()">Einplanen</button>
  </div>
</div>

<form [formGroup]="form" id="drive-create-body" class="body-base-style">
  <div class="column">
    <div class="overview_data_box">
      <h3>Kennzeichen</h3>
      <app-dropdown-multiple
        [title]="'Kennzeichen *'"
        [formcontrol]="form.controls['license_plate_id']"
        [headerText]="selectedPlateValue"
        [values]="platesValues"
        [submitted]="submitted"
        (submitValueEmitter)="setPlate($event);"
      ></app-dropdown-multiple>
    </div>
    <div class="overview_data_box">
      <h3>Details zur Probefahrt</h3>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Datum *</label>
        <input #inputDate1 type="date" placeholder="date"  formControlName="start_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls['start_date'].errors">
      </div>
      <div class="input_wrapper">
          <label class="overview_record_lbl">Uhrzeit *</label>
          <input #inputDate2 type="time" placeholder="time"  formControlName="start_time" [class.date-input--has-value]="inputDate2.value != null" [class.input_invalid]="submitted && form.controls['start_time'].errors">
      </div>
      <div class="input_wrapper">
          <label class="overview_record_lbl">Dauer in Min. *</label>
          <input #inputDate3 type="number" placeholder="Dauer"  formControlName="duration" [class.date-input--has-value]="inputDate3.value != null" [class.input_invalid]="submitted && form.controls['duration'].errors">
      </div>
      <div class="input_wrapper">
          <label class="overview_record_lbl">Ziel der Probefahrt *</label>
          <input #inputDate4 type="text" placeholder="Von - Nach"  formControlName="trip_description" [class.date-input--has-value]="inputDate4.value != null" [class.input_invalid]="submitted && form.controls['trip_description'].errors">
      </div>
    </div>
  </div>

  <div class="column">
    <div class="overview_data_box">
      <h3>Fahrzeug</h3>
      <app-dropdown-objects-search
        [title]="'Fahrzeug *'"
        [placeholder]="'Fahrzeug'"
        [headervalue]="form.controls['vehicle'].value ? form.controls.vehicle.value.getVehicleName() : ''"
        [formcontrol]="form.controls['vehicle']"
        [values]="vehicleValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('vehicle', $event)"
      ></app-dropdown-objects-search>
    </div>
    <app-fast-and-furious *ngIf="form.get('vehicle_to_create').value == true"
      [form]="formVehicle"
      [submitted]="submitted"
    ></app-fast-and-furious>
  </div>

  <div class="column">
    <app-basic-customer-create *ngIf="typ == 'customer' && form"
      [form]="form"
      [submitted]="submitted"
      [customers]="customers"
      [additionalRequiredValues]="['firstName','lastName','salutation']"
    ></app-basic-customer-create>
    <!-- <div class="overview_data_box" *ngIf="typ == 'customer'">
      <h3>Kunde</h3>
      <app-dropdown-objects-search
        [title]="'Kunde*'"
        [placeholder]="'Kunde'"
        [headervalue]="form.controls['customer']?.value?.getName()"
        [formcontrol]="form.controls['customer']"
        [values]="customerValues"
        [submitted]="submitted"
        [showLabel]="false"
        (submitValueEmitter)="setValueFromDropdown('customer', $event)"
      ></app-dropdown-objects-search>
    </div> -->
    <form [formGroup]="formCustomer" class="column" *ngIf="typ == 'customer' && form.get('customer_form').value">
      <div class="overview_data_box">
        <h3>Informationen zum Führerschein</h3>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Nummer</label>
            <input
              type="text"
              placeholder="Führerschein-Nummer"
              formControlName="authorization_number"
              [class.input_invalid]="submitted && formCustomer.controls.authorization_number.errors">
        </div>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Behörde</label>
            <input
              type="text"
              placeholder="Führerschein-Nummer"
              formControlName="authorization_authority"
              [class.input_invalid]="submitted && formCustomer.controls.authorization_authority.errors">
        </div>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Datum</label>
            <input
              #inputDate1
              type="date"
              placeholder="Führerschein-Datum"
              formControlName="authorization_date"
              [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && formCustomer.controls.authorization_date.errors">
        </div>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Gruppen</label>
            <input
              type="text"
              placeholder="Führerschein-Gruppen"
              formControlName="authorization_groups"
              [class.input_invalid]="submitted && formCustomer.controls.authorization_groups.errors">
        </div>
      </div>
    </form>
    <div class="overview_data_box" *ngIf="typ == 'intern'">
      <h3>Mitarbeiter</h3>
      <app-dropdown-objects-search
        [title]="'Mitarbeiter *'"
        [placeholder]="'Mitarbeiter'"
        [headervalue]="form.controls['employee'].value ? form.controls['employee'].value.getFullName() : ''"
        [formcontrol]="form.controls['employee']"
        [values]="employeeValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('employee', $event)"
    ></app-dropdown-objects-search>
    </div>
  </div>
</form>
