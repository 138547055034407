import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Vehicle } from '../Vehicle';
import { validDateFuture, validDateFutureMonth, validDatePast, validateNumberOnlyInteger } from 'src/app/validators/CustomValidators';
import { validateDateInput } from 'src/app/validators/CustomValidators';
import * as moment from 'moment';
import { VehicleUpholstery } from '../optional/VehicleUpholstery';
import { VehicleUpholsteryColor } from '../optional/VehicleUpholsteryColor';
import { VehicleConsumption } from '../VehicleConsumption';
import { VehicleMaintenance } from '../VehicleMaintenance';
import { VehicleEnviromentLabel } from '../optional/VehicleEnviromentLabel';
import { KW_MULTIPLICATOR, PS_MULTIPLICATOR } from 'src/app/values/ConstValues';
import { formatDate } from '@angular/common';
import { RentForm } from './RentForm';

export class VehicleForm {
  static getVehicleForm(formBuilder: FormBuilder, vehicle?: Vehicle): FormGroup {
    let form;
    form = formBuilder.group({
      vehicle_id: [vehicle ? vehicle.id : 0,Validators.required],
      created: [vehicle ? vehicle.created : null, Validators.required],
      brand: [vehicle ? vehicle.brand : '',Validators.required],
      model: [vehicle ? vehicle.model : '',Validators.required],
      version: [vehicle ? vehicle.model.version : '',[Validators.required, Validators.maxLength(80)]],
      drive: [vehicle ? vehicle.drive : '',Validators.required],
      transmission: [vehicle ? vehicle.transmission : '',Validators.required],
      fuel: [vehicle ? vehicle.fuel : '',Validators.required],
      power: [vehicle ? (vehicle.powerPs ? vehicle.powerPs : vehicle.powerKw) : null,Validators.required],
      powerTyp: [vehicle ? (vehicle.powerPs ? 1 : 2) : 1],
      bodyTyp: [vehicle ? vehicle.bodyTyp : '',Validators.required],
      bodyColor: [vehicle ? vehicle.bodyColor : null,Validators.required],
      category: [vehicle ? vehicle.category : '',Validators.required],
      numOfDoors: [vehicle ? vehicle.numOfDoors : null,Validators.required],
      numOfSeats: [vehicle ? vehicle.numOfSeats : null,Validators.required],
      price: [vehicle ? vehicle.price : null,[validateNumberOnlyInteger]],
      unfall: [vehicle ? vehicle.unfall : false,Validators.required],
      mileageKm: [vehicle ? vehicle.mileageKm : null,Validators.required],
      firstRegistrationDate: [vehicle && vehicle.firstRegistrationDate ? this.getConvertedDateForInputFull(vehicle.firstRegistrationDate) : null, [validDatePast]],
      preOwners: [vehicle ? vehicle.previousOwners : null,[Validators.min(1)]],
      gears: [vehicle ? vehicle.numOfGears : null],
      cylinder: [vehicle ? vehicle.numOfCylinders : null],
      ccm: [vehicle ? vehicle.ccm : null,[Validators.min(1)]],
      co2: [vehicle ? vehicle.co2Emission : null,Validators.min(0.001)],
      consumption: [vehicle ? vehicle.consumption?.combined : null,Validators.min(0.001)],
      consumptionUrban: [vehicle ? vehicle.consumption?.urban : null,Validators.min(0.001)],
      consumptionExtraUrban: [vehicle ? vehicle.consumption?.extraUrban : null,Validators.min(0.001)],
      ownWeight: [vehicle ? vehicle.emptyWeight : null,[Validators.min(1)]],
      totalWeight: [vehicle ? vehicle.fullWeight : null,[Validators.min(1)]],
      upholstery: [vehicle ? vehicle.upholstery : null],
      upholsteryColor: [vehicle ? vehicle.upholsteryColor : null],
      paintType: [vehicle ? vehicle.paintType : null],
      nonSmoking: [vehicle ? vehicle.nonSmoking : null],
      idNummer: [vehicle ? vehicle.externalId : null],
      engineNummer: [vehicle ? vehicle.engineNumber : null],
      equipments: [vehicle ? vehicle.equipments : []],
      warrantyExists: [vehicle ? vehicle.warrantyExists : null],
      warrantyDuration: [vehicle ? vehicle.warrantyDuration : null],
      originalMarket: [vehicle ? vehicle.originalMarket : null],
      nextInspection: [vehicle && vehicle.maintenance?.nextInspection ? this.getConvertedDateForInputFull(vehicle.maintenance.nextInspection) : null],
      lastBeltService: [vehicle && vehicle.maintenance?.lastBeltService ? this.getConvertedDateForInputFull(vehicle.maintenance.lastBeltService) : null],
      lastService: [vehicle && vehicle.maintenance?.lastService ? this.getConvertedDateForInputFull(vehicle.maintenance.lastService) : null],
      serviceHistory: [vehicle ? vehicle.maintenance?.serviceHistory : null],
      enviromentLbl: [vehicle ? vehicle.enviromentLbl : null],
      dealerPrice: [vehicle ? vehicle.dealerPrice : null,validateNumberOnlyInteger],
      offerRef: [vehicle ? vehicle.offerRef : null],
      addEquipment: [vehicle ? vehicle.addEquipment : null],
      description: [vehicle ? vehicle.description : null],
      internal_description: [vehicle ? vehicle.internal_description : null],
      pictures: [vehicle ? vehicle.pictures : []],
      pictures_changed: [false],
      update_vehicle_success: [false],
      status: [vehicle ? vehicle.status : 2],
      fin_number: [vehicle ? vehicle.fin_number : null],
      guarantee: [vehicle ? vehicle.guarantee : null],
      nova: [vehicle ? vehicle.nova : null,Validators.min(0.001)],
      leasable: [vehicle ? vehicle.leasable : null],
      rent_price: [vehicle ? vehicle.rent_price : null,validateNumberOnlyInteger],
      vat_reportable: [vehicle ? vehicle.vat_reportable : null],
      luggage_space: [vehicle ? vehicle.luggage_space : null,[Validators.min(1)]],
      license_plate: [vehicle ? vehicle.license_plate : null],
      color_code: [vehicle ? vehicle.color_code : null],
      color_designation: [vehicle ? vehicle.color_designation : null],
      winter_tyres: [vehicle ? vehicle.winter_tyres : null],
      summer_tyres: [vehicle ? vehicle.summer_tyres : null],
      paper_status: [vehicle ? vehicle.paper_status : null],
      keys_available: [vehicle ? vehicle.keys_available : null],
      keys_number: [vehicle ? vehicle.keys_number : null],
      display_keys_number: [vehicle ? vehicle.display_keys_number : null],
      digital_keys_number: [vehicle ? vehicle.digital_keys_number : null],
      damages: [vehicle ? vehicle.damages : null],
      repair_status: [vehicle ? vehicle.repair_status : null],
      standing_time: [vehicle ? vehicle.standing_time : null],
      last_movement: [vehicle && vehicle.last_movement ?  this.getConvertedDateForInputFull(vehicle.last_movement) : null],
      attachments: [vehicle ? vehicle.attachments : []],
      website_online: [vehicle ? vehicle.website_online : false],
      platform_online: [vehicle ? vehicle.platform_online : false],
      handing_over_date: [vehicle  && vehicle.handing_over_date ? this.getConvertedDateForInputFull(vehicle.handing_over_date) : null],
      customer_id: [vehicle ? vehicle.customer_id : null],

      accounting_description: [vehicle ? vehicle.accounting_description : null],
      winter_diesel: [vehicle ? vehicle.winter_diesel : false],
      winter_tyres_description: [vehicle ? vehicle.winter_tyres_description : false],
      summer_tyres_description: [vehicle ? vehicle.summer_tyres_description : false],
      // purchase_date: [vehicle  && vehicle.purchase_date ? this.getConvertedDateForInputFull(vehicle.purchase_date) : null],
      is_ersatzauto: [vehicle ? vehicle.is_ersatzauto : false],
      issued_ersatzauto: [vehicle  && vehicle.issued_ersatzauto ? this.getConvertedDateForInputFull(vehicle.issued_ersatzauto) : null],
      supplier: [vehicle ? vehicle.supplier : null],
      delivery_date: [vehicle  && vehicle.delivery_date ? this.getConvertedDateForInputFull(vehicle.delivery_date) : null],
      planed_repair_date: [vehicle  && vehicle.planed_repair_date ? this.getConvertedDateForInputFull(vehicle.planed_repair_date) : null],
      type_certificate_angefordert: [vehicle  && vehicle.type_certificate_angefordert ? this.getConvertedDateForInputFull(vehicle.type_certificate_angefordert) : null],
      type_certificate_eingestockt: [vehicle  && vehicle.type_certificate_eingestockt ? this.getConvertedDateForInputFull(vehicle.type_certificate_eingestockt) : null],
      type_certificate_ausgestockt: [vehicle  && vehicle.type_certificate_ausgestockt ? this.getConvertedDateForInputFull(vehicle.type_certificate_ausgestockt) : null],
      type_certificate_versendet: [vehicle  && vehicle.type_certificate_versendet ? this.getConvertedDateForInputFull(vehicle.type_certificate_versendet) : null],
      type_certificate_an_bank: [vehicle ? vehicle.type_certificate_an_bank : false],
      type_certifcate_bank_erhalten: [vehicle ? vehicle.type_certifcate_bank_erhalten : null],
      type_certificate_is_sent: [vehicle ? vehicle.type_certificate_is_sent : false],
      finanzsperrauskunft: [vehicle ? vehicle.finanzsperrauskunft : false],

      garantie_bis: [vehicle && vehicle.garantie_bis ? this.getConvertedDateForInputFull(vehicle.garantie_bis) : null],
      garantie_km: [vehicle ? vehicle.garantie_km : null],
      garantie_description: [vehicle ? vehicle.garantie_description : null],
      freigeschaltet_am_web: [vehicle && vehicle.freigeschaltet_am_web ? moment(new Date(vehicle.freigeschaltet_am_web)) : null],
      freigeschaltet_am_plattform: [vehicle && vehicle.freigeschaltet_am_plattform ? moment(new Date(vehicle.freigeschaltet_am_plattform)) : null],

      lackierung_notwendig: [vehicle ? vehicle.lackierung_notwendig : false],
      aufbereitung: [vehicle ? vehicle.aufbereitung : null],
      aufbereitung_zustaendigkeit: [vehicle ? vehicle.aufbereitung_zustaendigkeit : null],
      ankaufstest: [vehicle ? vehicle.ankaufstest : null],
      ankaufstest_durchfuehrung: [vehicle ? vehicle.ankaufstest_durchfuehrung : null],
      ersatzteile: [vehicle ? vehicle.ersatzteile : null],

      eurotax_code: [vehicle ? vehicle.eurotax_code : null],
      sonderausstattung_beschreibung: [vehicle ? vehicle.sonderausstattung_beschreibung : null],
      thumbnail: [vehicle ? vehicle.thumbnail : null],

      naechstes_service: [vehicle && vehicle.naechstes_service ? this.getConvertedDateForInputFull(vehicle.naechstes_service) : null],
      eingangsdatum: [vehicle && vehicle.eingangsdatum ? this.getConvertedDateForInputFull(vehicle.eingangsdatum) : null, validDatePast],
      endreinigungsdatum: [vehicle && vehicle.endreinigungsdatum ? this.getConvertedDateForInputFull(vehicle.endreinigungsdatum) : null],
      datenblatt_angefordert_am: [vehicle && vehicle.datenblatt_angefordert_am ? this.getConvertedDateForInputFull(vehicle.datenblatt_angefordert_am) : null],
      fahrzeug_eingestockt_am: [vehicle && vehicle.fahrzeug_eingestockt_am ? this.getConvertedDateForInputFull(vehicle.fahrzeug_eingestockt_am) : null],

      chatgpt_flag: [vehicle ? vehicle.chatgpt_flag : undefined],
      vehicle_serialnumber: [vehicle ? vehicle.vehicle_serialnumber : null],

      steuersatz_pricesheet: [vehicle ? vehicle.steuersatz_pricesheet : null],
      einkaufspreis_pricesheet: [vehicle ? vehicle.einkaufspreis_pricesheet : null],
      co2_checked: [vehicle ? vehicle.co2_checked : false],
      first_registration_checked: [vehicle ? vehicle.first_registration_checked : false],
      ankaufstest_info_description: [vehicle ? vehicle.ankaufstest_info_description : null],
      werkstatt_info_description: [vehicle ? vehicle.werkstatt_info_description : null],

      inserat_checked: [vehicle ? vehicle.inserat_checked : false],
      inserat_info: [vehicle ? vehicle.inserat_info : null],

      sprit: [vehicle ? vehicle.sprit : null],
      lieferant_extern: [vehicle ? vehicle.lieferant_extern : null],
      einkaufsdatum: [vehicle ? vehicle.einkaufsdatum : null],
      vehicle_imported: [vehicle ? vehicle.vehicle_imported : null],
      vehicle_supplier: [vehicle ? vehicle.vehicle_supplier : null],
      mobile_online: [vehicle ? vehicle.mobile_online : false],
      export_price: [vehicle ? vehicle.export_price : null],
      export_price_checked: [vehicle ? vehicle.export_price_checked : false],
      export_price_enabled: [vehicle ? vehicle.export_price_enabled : false],
      mileage_internal: [vehicle ? vehicle.mileage_internal : null],
      felgen_aufpreis: [vehicle ? vehicle.felgen_aufpreis : null],
      fahrwerk_aufpreis: [vehicle ? vehicle.fahrwerk_aufpreis : null],

      rent_typ: [vehicle ? vehicle.rent_typ : null],
      rent_forms: [[]],

      price_checked: [vehicle ? vehicle.price_checked : false],
      aufbereitung_info: [vehicle ? vehicle.aufbereitung_info : null],
      dellen_info: [vehicle ? vehicle.dellen_info : null],
      letzte_inventur: [vehicle ? vehicle.letzte_inventur : null],
      is_vermittlung: [vehicle ? vehicle.is_vermittlung : null],
      transport_beantragt: [vehicle ? vehicle.transport_beantragt : null],
      transport_date: [vehicle ? vehicle.transport_date : null],
      spedition: [vehicle ? vehicle.spedition : null],
      envkv_class: [vehicle ? vehicle.envkv_class : null],
      pickerl_valid: [vehicle ? this.getConvertedDateForInputMonth(vehicle.pickerl_valid) : null],
      finanzsperrauskunft_beantragt: [vehicle ? this.getConvertedDateForInputFull(vehicle.finanzsperrauskunft_beantragt) : null],

      trailer_load_braked: [vehicle ? vehicle.trailer_load_braked : null],
      hail_damage: [vehicle ? vehicle.hail_damage : null],
      zubau: [vehicle ? vehicle.zubau : null],

      coc: [vehicle ? vehicle.coc : null],
      austrian_data_extract: [vehicle ? vehicle.austrian_data_extract : null],
      rent_price_pro_tag: [vehicle ? vehicle.rent_price_pro_tag : null],

      is_camper: [vehicle ? vehicle.is_camper : false],

      photo_aufbereitung: [vehicle ? vehicle.photo_aufbereitung : false],
    });
    if(vehicle?.platform_online || vehicle?.website_online || vehicle?.mobile_online){
      form.get('price').setValidators(Validators.required)
      form.get('price').updateValueAndValidity();
    }
    return form;
  }


  static getConvertedDateForInputFull(timestamp: number): string {
    if(timestamp){
      return formatDate(new Date(timestamp),'yyyy-MM-dd', 'de')
    }
    return null;
  }
  static getConvertedDateForInputMonth(timestamp: number): string {
    if(timestamp){
      return formatDate(new Date(timestamp),'yyyy-MM', 'de')
    }
    return null;
  }
  static getTimestampFromInputDate(date: string): number {
    if(date){
      let d = new Date(date);
      return d.getTime();
    } else return null;
  }

  static createObject(form: FormGroup): Vehicle {
    let model = form.controls.model.value;
    model.version = form.controls.version.value;
    let hp = 0;
    let kw = 0;
    if(form.controls.powerTyp.value == 1){
      hp = form.controls.power.value;
      kw = form.controls.power.value * KW_MULTIPLICATOR;
    } else {
      kw = form.controls.power.value;
      hp = form.controls.power.value * PS_MULTIPLICATOR;
    }
    let obj = new Vehicle(
      form.controls.vehicle_id.value,
      Number(localStorage.getItem('location_id')),
      form.controls.brand.value,
      model,
      form.controls.price.value,
      form.controls.fuel.value,
      hp,
      kw,
      form.controls.bodyColor.value,
      form.controls.bodyTyp.value,
      form.controls.category.value,
      form.controls.mileageKm.value,
      form.controls.unfall.value,
      form.controls.numOfDoors.value,
      form.controls.numOfSeats.value,
      form.controls.transmission.value,
      form.controls.drive.value,
      null, //manufature date,
      form.controls.firstRegistrationDate.value ? new Date(form.controls.firstRegistrationDate.value).getTime() : null,
      form.controls.preOwners.value,
      form.controls.status.value,
      form.controls.ccm.value,
      form.controls.co2.value,
      form.controls.gears.value,
      form.controls.cylinder.value,
      form.controls.ownWeight.value,
      form.controls.totalWeight.value,
      new Date().getTime(),
      form.controls.vehicle_id.value ? form.controls.vehicle_id.value : new Date().getTime(),
      form.controls.idNummer.value,
      form.controls.warrantyExists.value,
      form.controls.warrantyDuration.value,
      form.controls.upholstery.value ? new VehicleUpholstery(form.controls.upholstery.value.id,form.controls.upholstery.value.name) : null,
      form.controls.upholsteryColor.value ? new VehicleUpholsteryColor(form.controls.upholsteryColor.value.id, form.controls.upholsteryColor.value.name) : null,
      form.controls.paintType.value,
      null, //wheelinches
      form.controls.originalMarket.value,
      form.controls.nonSmoking.value,
      new VehicleConsumption(
        form.controls.consumption.value,
        form.controls.consumptionUrban.value,
        form.controls.consumptionExtraUrban.value
      ),
      new VehicleMaintenance(
        form.controls.nextInspection.value ? new Date(form.controls.nextInspection.value).getTime() : null,
        form.controls.lastBeltService.value ? new Date(form.controls.lastBeltService.value).getTime() : null,
        form.controls.lastService.value ? new Date(form.controls.lastService.value).getTime() : null,
        form.controls.serviceHistory.value
      ),
      form.controls.enviromentLbl.value ? new VehicleEnviromentLabel(form.controls.enviromentLbl.value.id, form.controls.enviromentLbl.value.name) : null,
      form.controls.dealerPrice.value,
      form.controls.offerRef.value,
      this.getConvertEditorText(form.controls.addEquipment.value),
      this.getConvertEditorText(form.controls.description.value),
      form.controls.engineNummer.value,
      form.controls.equipments.value,
      form.controls.fin_number.value,
      form.controls.guarantee.value,
      form.controls.nova.value,
      form.controls.vat_reportable.value,
      form.controls.leasable.value,
      form.controls.rent_price.value,
      form.controls.luggage_space.value,
      form.controls.license_plate.value,
      form.controls.internal_description.value,
      form.controls.color_code.value,
      form.controls.color_designation.value,
      form.controls.winter_tyres.value,
      form.controls.summer_tyres.value,
      form.controls.keys_available.value,
      form.controls.keys_number.value,
      form.controls.damages.value,
      form.controls.repair_status.value,
      form.controls.paper_status.value,
      form.controls.website_online.value,
      form.controls.platform_online.value,
      form.controls.standing_time.value,
      form.controls.last_movement.value ? this.getTimestampFromInputDate(form.controls.last_movement.value) : null,
      form.controls.handing_over_date.value ? this.getTimestampFromInputDate(form.controls.handing_over_date.value) : null,
      form.controls.customer_id.value,

      form.controls.accounting_description.value,
      form.controls.winter_diesel.value,
      form.controls.winter_tyres_description.value,
      form.controls.summer_tyres_description.value,
      // form.controls.purchase_date.value ? this.getTimestampFromInputDate(form.controls.purchase_date.value) : null,
      null,//altes purchase_date
      form.controls.is_ersatzauto.value,
      form.controls.issued_ersatzauto.value ? this.getTimestampFromInputDate(form.controls.issued_ersatzauto.value) : null,
      form.controls.supplier.value,
      form.controls.delivery_date.value ? this.getTimestampFromInputDate(form.controls.delivery_date.value) : null,
      form.controls.planed_repair_date.value ? this.getTimestampFromInputDate(form.controls.planed_repair_date.value) : null,
      form.controls.type_certificate_angefordert.value ? this.getTimestampFromInputDate(form.controls.type_certificate_angefordert.value) : null,
      form.controls.type_certificate_eingestockt.value ? this.getTimestampFromInputDate(form.controls.type_certificate_eingestockt.value) : null,
      form.controls.type_certificate_ausgestockt.value ? this.getTimestampFromInputDate(form.controls.type_certificate_ausgestockt.value) : null,
      form.controls.type_certificate_versendet.value ? this.getTimestampFromInputDate(form.controls.type_certificate_versendet.value) : null,
      form.controls.type_certificate_an_bank.value,
      form.controls.type_certifcate_bank_erhalten.value,
      form.controls.finanzsperrauskunft.value,

      form.controls.garantie_bis.value ? this.getTimestampFromInputDate(form.controls.garantie_bis.value) : null,
      form.controls.garantie_km.value,
      form.controls.garantie_description.value,
      form.controls.freigeschaltet_am_web.value ? form.controls.freigeschaltet_am_web.value?.valueOf() : null,
      form.controls.freigeschaltet_am_plattform.value ? form.controls.freigeschaltet_am_plattform.value?.valueOf() : null,

      form.controls.lackierung_notwendig.value,
      form.controls.aufbereitung.value,
      form.controls.aufbereitung_zustaendigkeit.value,
      form.controls.ankaufstest.value,
      form.controls.ankaufstest_durchfuehrung.value,

      form.controls.eurotax_code.value,

      form.controls.sonderausstattung_beschreibung.value,
      form.controls.ersatzteile.value,
      form.controls.naechstes_service.value ? this.getTimestampFromInputDate(form.controls.naechstes_service.value) : null,
      form.controls.eingangsdatum.value ? this.getTimestampFromInputDate(form.controls.eingangsdatum.value) : null,
      form.controls.endreinigungsdatum.value ? this.getTimestampFromInputDate(form.controls.endreinigungsdatum.value) : null,
      form.controls.vehicle_serialnumber.value,
      form.controls.datenblatt_angefordert_am.value ? this.getTimestampFromInputDate(form.controls.datenblatt_angefordert_am.value) : null,
      form.controls.fahrzeug_eingestockt_am.value ? this.getTimestampFromInputDate(form.controls.fahrzeug_eingestockt_am.value) : null,
      form.controls.co2_checked.value,
      form.controls.first_registration_checked.value,
      form.controls.ankaufstest_info_description.value,
      form.controls.werkstatt_info_description.value,
      form.controls['inserat_checked'].value,
      form.controls['inserat_info'].value,
      form.controls['mobile_online'].value,
      form.controls['export_price'].value,
      form.controls['mileage_internal'].value,
      form.controls['felgen_aufpreis'].value,
      form.controls['price_checked'].value,
      form.controls['aufbereitung_info'].value,
      form.controls['dellen_info'].value,
      form.controls['letzte_inventur'].value,
      form.controls['is_vermittlung'].value,
      form.controls['transport_beantragt'].value,
      form.controls['transport_date'].value,
      form.controls['spedition'].value,
      form.controls['envkv_class'].value,
      form.controls['pickerl_valid'].value ? this.getTimestampFromInputDate(form.controls['pickerl_valid'].value + "-01") : null,
      form.controls['finanzsperrauskunft_beantragt'].value ? this.getTimestampFromInputDate(form.controls['finanzsperrauskunft_beantragt'].value) : null,
      form.controls['display_keys_number'].value,
      form.controls['digital_keys_number'].value,
      form.controls['fahrwerk_aufpreis'].value,
      undefined,
      undefined,
      undefined,
      undefined,
      form.controls['trailer_load_braked'].value,
      form.controls['hail_damage'].value,
      undefined,
      undefined,
      undefined,
      form.get('is_camper').value,
    );
    obj.photo_aufbereitung = form.controls.photo_aufbereitung.value;
    obj.export_price_checked = form.controls.export_price_checked.value;
    obj.export_price_enabled = form.controls.export_price_enabled.value;
    obj.thumbnail = form.controls.thumbnail.value;
    obj.chatgpt_flag = form.controls.chatgpt_flag.value;
    obj.steuersatz_pricesheet = form.controls.steuersatz_pricesheet.value;
    obj.einkaufspreis_pricesheet = form.controls.einkaufspreis_pricesheet.value;
    obj.sprit = form.controls.sprit.value;
    obj.lieferant_extern = form.controls.lieferant_extern.value;
    obj.einkaufsdatum = form.controls.einkaufsdatum.value;
    obj.vehicle_supplier = form.controls.vehicle_supplier.value;
    obj.vehicle_imported = form.controls.vehicle_imported.value;
    obj.type_certificate_is_sent = form.get('type_certificate_is_sent').value;
    obj.rent_typ = form.get('rent_typ').value;
    obj.rents = RentForm.getObjects(form.get('rent_forms').value);
    obj.coc = form.get('coc').value;
    obj.austrian_data_extract = form.get('austrian_data_extract').value;
    obj.rent_price_pro_tag = form.get('rent_price_pro_tag').value;
    return obj;
  }

  static getVehicleFilterForm(formBuilder: FormBuilder, filter?: any): FormGroup {
    let form;
    form = formBuilder.group({
      brand: [null],
      model: [null],
      color: [null],
      mileage_from: [null, validateNumberOnlyInteger],
      mileage_to: [null, validateNumberOnlyInteger],
      firstregistration_date_from: [null, validateDateInput],
      firstregistration_date_to: [null, validateDateInput],
      percent_typ: [null],
      is_set: [false],
    })
    return form;
  }

  static getConvertEditorText(txt: string): string {
    //return txt?.replace(/<br\s*\/?>/gi, '');
    return txt?.replace(/\n/g, '');
  }
}
