import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Picture } from 'src/app/buisness-object/picture/Picture';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { TireService } from 'src/app/service/tire/tire.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';

@Component({
  selector: 'app-images-web-element',
  templateUrl: './images-web-element.component.html',
  styleUrl: './images-web-element.component.css'
})
export class ImagesWebElementComponent implements OnInit {
  @Input() picture: Picture;
  @Input() obj: any;
  @Input() design: number;
  @Output() addPictureToSliderEmitter = new EventEmitter<Picture>();
  @Output() openImageEmitter = new EventEmitter<Picture>();
  public showImageLoading = true;

  constructor(
    private vehicleService: VehicleService,
    private tireService: TireService
  ){}

  ngOnInit(): void {
    if(this.picture && this.picture.src == null) this.getPicture();
    else {
      this.picture.src = this.picture.src;
      this.showImageLoading = false;
      this.addPictureToSliderEmitter.emit(this.picture);
    }
  }

  getPicture() {
   if(this.obj instanceof Vehicle){
    this.vehicleService.getVehiclePicture(this.picture.picture_id).subscribe((data) => {
      if(data){
        this.picture.src = data;
        this.showImageLoading = false;
        this.addPictureToSliderEmitter.emit(this.picture);
      }
    });
   } else if(this.obj instanceof Tire) {
    this.tireService.getTirePicture(this.picture.picture_id).subscribe((data) => {
      if(data){
        this.picture.src = data;
        this.showImageLoading = false;
        this.addPictureToSliderEmitter.emit(this.picture);
      }
    });
   }
  }
}
