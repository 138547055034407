import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';


export const DropdownIconArrowAnimation = 
[
  trigger('rotate', [
    state('default', style({
      transform: 'rotate(0deg)'
    })),
    state('rotated', style({
      transform: 'rotate(180deg)'
    })),
    transition('default => rotated', [
      animate('0.2s'),
    ]),
    transition('rotated => default', [
      animate('0.2s')
    ]),
  ]),
  trigger('open', [
    state('opened', style({
      height: '100%'
    })),
    state('closed', style({
      height: '0%'
    })),
    transition('opened => closed', [
      animate('0.2s'),
    ]),
    transition('closed => opened', [
      animate('0.2s')
    ]),
  ])
]

export const RouterAnimation =
  trigger('routeAnimation', [
    transition('AdminPage => CustomerPage, CustomerPage => CarPage, AdminPage => CarPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh'
        })
      ]),
      query(':enter', [
        style({ top: '300vh' })
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('100ms ease-out', style({ top: '100vh' }))
        ]),
        query(':enter', [
          animate('500ms ease-out', style({ top: '0vh' }))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('CarPage => CustomerPage, CustomerPage => AdminPage, CarPage => AdminPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh'
        })
      ]),
      query(':enter', [
        style({ top: '300vh' })
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('100ms ease-out', style({ top: '100vh' }))
        ]),
        query(':enter', [
          animate('500ms ease-out', style({ top: '0vh' }))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
  ]);

export const OpenVehicleFilterAnimation = [
  trigger('dropDownFilter', [
    transition('open => close', [
      query(':out, :in', [
        style({
          height: '300px'
        })
      ]),
      query(':in', [
        style({ height: '0px' })
      ]),
      query(':out', animateChild()),
      group([
        query(':out', [
          animate('100ms ease-out', style({ height: '300px' }))
        ]),
        query(':in', [
          animate('500ms ease-out', style({ height: '0px' }))
        ])
      ]),
      query(':in', animateChild()),
    ]),
    transition('close => open', [
      query(':out, :in', [
        style({
          height: '300px'
        })
      ]),
      query(':in', [
        style({ height: '0px' })
      ]),
      query(':out', animateChild()),
      group([
        query(':out', [
          animate('100ms ease-out', style({ height: '300px' }))
        ]),
        query(':in', [
          animate('500ms ease-out', style({ height: '0px' }))
        ])
      ]),
      query(':in', animateChild()),
    ]),
  ]),

  trigger('openDropFilter',
  [
    state('openFilter', style({
        'max-height': '500px',  'visibility': 'visible'
    })),
    state('closeFilter', style({
        'max-height': '0px',  'visibility': 'hidden'
    })),
    transition('closeFilter => openFilter', [
      group([
        animate('1100ms ease-in-out', style({
            'visibility': 'visible'
        })),
        animate('1000ms ease-in-out', style({
            'max-height': '500px'
        })),
        animate('1100ms ease-in-out', style({
            'opacity': '1'
        }))
      ])
    ]),
    transition('openFilter => closeFilter', [
      group([
        animate('100ms ease-in-out', style({
            'opacity': '0'
        })),
        animate('1000ms ease-in-out', style({
            'height': '0px'
        })),
        animate('100ms ease-in-out', style({
            'visibility': 'hidden'
        }))
      ])
    ]),
  ]),
  trigger('rotate', [
    state('default', style({
      transform: 'rotate(0deg)'
    })),
    state('rotated', style({
      transform: 'rotate(180deg)'
    })),
    transition('default => rotated', [
      animate('0.2s'),
    ]),
    transition('rotated => default', [
      animate('0.2s')
    ]),
  ]),
]

export const OpenCloseDropDown = [
    trigger('rotate', [
        state('default', style({
          transform: 'rotate(0deg)'
        })),
        state('rotated', style({
          transform: 'rotate(180deg)'
        })),
        transition('default => rotated', [
          animate('0.2s'),
        ]),
        transition('rotated => default', [
          animate('0.2s')
        ]),
      ]),
      trigger('drop', [
        state('close', style({
          'max-height': '0px'
        })),
        state('open', style({
          'max-height': '500px'
        })),
        transition('close => open', [
          animate('400ms ease-in-out', style({ 'max-height': '200px'}))
        ]),
        transition('open => close', [
          animate('400ms ease-in-out', style({ 'max-height': '0px'}))
        ]),
      ]),
      trigger('openDrop', [
        state('open', style({
            'max-height': '200px',  'visibility': 'visible'
        })),
        state('close', style({
            'max-height': '0px',  'visibility': 'hidden'
        })),
        transition('close => open', [group([
            animate('250ms ease-in-out', style({
                'visibility': 'visible'
            })),
            animate('150ms ease-in-out', style({
                'max-height': '200px'
            })),
            animate('100ms ease-in-out', style({
                'opacity': '1'
            }))
        ]
        )]),
        transition('open => close', [group([
            animate('100ms ease-in-out', style({
                'opacity': '0'
            })),
            animate('200ms ease-in-out', style({
                'height': '0px'
            })),
            animate('100ms ease-in-out', style({
                'visibility': 'hidden'
            }))
          ]
        )]),
    ]),

    trigger('inner', [
      transition('0 <=> 1', [group([
        animate('100ms ease-in-out', style({
          'opacity': '1'
        })),
        animate('100ms ease-in-out', style({
          'visibility': 'visible'
        })),
      ]
      )]),
  ]),
]
