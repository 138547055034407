<div id="dialog_container" *ngIf="!validToken">
    <h1>403 Keine Berechtigung</h1>
</div>

<div id="dialog_container" *ngIf="tokenExpired">
    <h1>403 Token abgelaufen</h1>
</div>


<div id="dialog_window" *ngIf="!flowSuccess">
  <div id="dialog_container" *ngIf="validToken">
    <h1>Bitte laden Sie Ihren Führerschein hoch, um Ihre Buchung abzuschließen</h1>
      <div id="dialog_conainter_body">
        <form [formGroup]="form">
          <div class="form-wrapper">
            <div class="input_wrapper">
              <label class="overview_record_lbl">Führerscheinnummer</label>
              <input type="text" placeholder="---" formControlName="authorization_number" [class.input_invalid]="submitted && form.get('authorization_number').errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Führerschein-Behörde</label>
              <input type="text" placeholder="---" formControlName="authorization_authority" [class.input_invalid]="submitted && form.get('authorization_authority').errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Führerschein-Datum</label>
              <input #inputDate1 type="date" placeholder="date" formControlName="authorization_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.get('authorization_date').errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Führerschein-Gruppen</label>
              <input type="text" placeholder="---" formControlName="authorization_groups" [class.input_invalid]="submitted && form.get('authorization_groups').errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl" >Führerschein Foto</label>
            
            <div>

              <!-- HERE IS THE IMAGE OVERVIEW -->
              <div class="pic_container">
                <div class="pic_item"  *ngIf="this.form.get('image_one').value">
                  <img [src]="'data:image/png;base64,'+this.form.get('image_one').value" class="img_car">
                  
                  <!-- TRASH ICON -->
                  <div class="image_remove_icon" (click)="selectionDeleteImageOne()">
                    <label class="lbl_hover">Löschen</label>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 11.959 15.159">
                      <path id="Linie_2" d="M122.766,591.4l-4.176-.022v-.38a.9.9,0,0,0-1.808,0v.37l-4.167-.022h-.005a.9.9,0,0,0,0,1.808h.042l.465,10.954a1.114,1.114,0,0,0,1.08,1.145h6.991a1.115,1.115,0,0,0,1.08-1.145l.462-10.9h.037a.9.9,0,0,0,0-1.808Zm-6.575,11.161h-.026a.543.543,0,0,1-.542-.516l-.332-6.931a.542.542,0,1,1,1.083-.052l.332,6.931A.542.542,0,0,1,116.191,602.558Zm3.58-.56a.542.542,0,0,1-.541.516H119.2a.542.542,0,0,1-.516-.568l.332-6.931a.542.542,0,1,1,1.083.052Z" transform="translate(-111.706 -590.09)" fill="#2c2c2c"/>
                    </svg>
                  </div>
                </div>
              </div>
              
              <!-- HERE IS THE DROP ZONE -->
              <div *ngIf="!this.form.get('image_one').value" id="drop-zone" [class.input_invalid]="submitted && this.form.get('image_one').invalid"
                appDnd (fileDroppedEmitter)="onFileDropped($event)"
                (click)="selectFileDropZone.click()">
                <img src="./assets/upload.svg">
                <label>Klicken Sie oder ziehen Sie Dateien hier her, um sie hochzuladen</label>
                <input #selectFileDropZone
                    type="file"
                    accept="image/*"
                    (change)="uploadFile($event)"
                    multiple="false"
                    style="display: none;"
                    capture="environment">
              </div>
            </div>
          </div>
        </div>

        </form>
      </div>
    <div class="dialog_btn_container">
      <button class="btn_submit_dark" (click)="submit()">Absenden</button>
    </div>
  </div>
</div>

<div id="dialog_container" *ngIf="flowSuccess">
  <h1>Ihre Daten wurden übermittelt. Danke.</h1>
</div>



