import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Location } from 'src/app/buisness-object/location/Location';
import { PageHandler } from 'src/app/helpers/PagerHandler';

@Component({
  selector: 'app-list-admin-container',
  templateUrl: './list-admin-container.component.html',
  styleUrls: ['./list-admin-container.component.css']
})
export class ListAdminContainerComponent implements OnInit {
  @Input() employees: Employee[];
  @Input() locations: Location[];
  @Input() pageInfo: string;
  @Input() pageHandler: PageHandler;
  @Input() sliceFrom: number;
  @Input() sliceTo: number;
  @Input() surface: number;
  @Output() openDetailsEmitter = new EventEmitter<Employee>();
  @Output() openEditEmitter = new EventEmitter<Employee>();
  @Output() pageEmitter = new EventEmitter<boolean>();
  @Output() deleteEmitter = new EventEmitter<Employee>();

  constructor() { }

  ngOnInit(): void {
  }
}
