import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { validDateFuture } from 'src/app/validators/CustomValidators';
import { formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { DateHelper } from 'src/app/utils/DateHelper';

@Component({
  selector: 'app-dialog-pdf-drive',
  templateUrl: './dialog-pdf-drive.component.html',
  styleUrls: ['./dialog-pdf-drive.component.css']
})
export class DialogPdfDriveComponent implements OnInit {
  @Input() drive: Drive;
  @Output() cancelEmitter = new EventEmitter<undefined>();
  @Output() createEmitter = new EventEmitter<{form: FormGroup, sign: boolean}>();
  public form: FormGroup;
  public submitted = false;
  public minDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public default = "Bezirk Krems & Umgebung";

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      test_drive_id: [this.drive ? this.drive.test_drive_id : 0,Validators.required],
      typ: [1,Validators.required],
      authorization_number: [this.drive && this.drive.authorization_number != null ? this.drive.authorization_number : null,Validators.required],
      authorization_authority: [this.drive && this.drive.authorization_authority != null ? this.drive.authorization_authority : null,Validators.required],
      authorization_date: [this.drive && this.drive.authorization_date != null ? DateHelper.getConvertedDateForInputFull(this.drive.authorization_date) : null],
      authorization_groups: [this.drive && this.drive.authorization_groups != null ? this.drive.authorization_groups : null,Validators.required],
      trip_description: [this.drive && this.drive.trip_description != null && this.drive.trip_description != "" ? this.drive.trip_description : (this.authService.isSinhuber() ? this.default : null),Validators.required],
    });
  }

  onSubmit(sign: boolean) {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form)
      return;
    } else {
      this.createEmitter.emit({form: this.form, sign: sign})
    }
  }
}
