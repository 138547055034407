import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-info',
  templateUrl: './dialog-info.component.html',
  styleUrl: './dialog-info.component.css'
})
export class DialogInfoComponent  implements OnInit {
  public show = false;
  public title: string;
  public message: string;
  public btnCancelTxt: string;
  public btnSubmitTxt: string;
  public submitValue: any;
  public typ: any;
  public subscription: Subscription;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openDialogInfo$.subscribe((data: any) => {
      this.show = true;
      this.title = data.title;
      this.message = data.message;
      this.btnSubmitTxt = data.btn_submit_txt;
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
