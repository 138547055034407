import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { OutputHelper } from 'src/app/helpers/OutputHelper';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-create-offer-page-one',
  templateUrl: './create-offer-page-one.component.html',
  styleUrls: ['./create-offer-page-one.component.css'],
  animations: [DropdownIconArrowAnimation]
})
export class CreateOfferPageOneComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() isEdit: boolean;
  @Input() customers: Customer[];

  public customerValues = [];
  public statusValues = ["Kunde","Interessent"];

  public minDate = new Date();
  public customersFiltered: Customer[] = [];
  public dialogQuerySubsription: Subscription;

  public showDropdown_1 = false;
  public showDropdown_2 = false;
  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('dropdown_2') dropdown_2: ElementRef;
  @ViewChild('input_customer') input_customer: ElementRef;
  public listener: () => void;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private dialogService: DialogService
  ) {
  }

  ngOnInit(): void {
    if(this.isEdit){
      this.minDate = this.form.controls.offer_validity.value;
    }
    for(let customer of this.customers){
      if(customer.status < 3){
        this.customersFiltered.push(customer);
      }
    }
    this.setDialogSubscription();
  }

  ngAfterViewInit(): void {
    // this.listener = this.renderer.listen('window','click', (e:Event) => {
    //   if(this.showDropdown_1 && !this.dropdown.nativeElement.contains(e.target)) this.showDropdown_1 = false;
    //   if(this.showDropdown_2 && !this.dropdown_2.nativeElement.contains(e.target)) this.showDropdown_2 = false;
    // });
    this.setDropdownValues();
    //this.setInputValue();
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'go_to_customer_create') this.gotoCustomerCreate();
        if(value.typ == 'go_to_customer_edit') this.gotoCustomerEdit();
      }
    });
  }

  setInputValue() {
    if(this.input_customer && this.form.controls.customer.value) this.input_customer.nativeElement.value = OutputHelper.getCustomerName(this.form.controls.customer.value);
  }

  setDropdownValues() {
    for(let value of this.customersFiltered){
      this.customerValues.push({
        id: value.id,
        name: value.contactPerson.firstName + ' ' + value.contactPerson.lastName + (value.companyName ? ' - ' + value.companyName : '')
      })
    }
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'customer'){
      let index = this.customers.findIndex(obj => obj.id == id);
      if(index > -1) this.setCustomer(this.customers[index]);
    }
  }

  setCustomer(customer: Customer) {
    this.form.controls.customer.setValue(customer);
    this.form.controls.contact_person.setValue(customer.contactPerson);
    //this.setInputValue();
  }

  filterCustomers(value: string) {
    this.form.controls.customer.setValue(null);
    if(value && value.length > 0){
      this.customersFiltered = [];
      this.customersFiltered = this.customers.filter((customer) => {
        return customer.contactPerson.firstName.toLowerCase().includes(value.toLowerCase()) ||
               customer.contactPerson.lastName.toLowerCase().includes(value.toLowerCase()) ||
               (customer.contactPerson.firstName.toLowerCase() + ' ' + customer.contactPerson.lastName.toLowerCase()).includes(value.toLowerCase()) ||
               customer.companyName.toLowerCase().includes(value.toLowerCase());
      });
    } else {
      this.customersFiltered = this.customers;
    }
  }

  goToCustomerCreateAction() {
    this.dialogService.openQuery(
      {
        title: 'Zu "Kunden anlegen" navigieren',
        message: 'Der Vorang wird abgebrochen und die Daten gehen verloren.',
        btn_cancel_txt: 'Nein',
        btn_submit_txt: 'Ja',
        typ: 'go_to_customer_create',
        submit_value: null,
      }
    );
  }
  gotoCustomerCreate() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['customers/create']));
    // this.router.navigate(['customers/create'])
  }
  goToCustomerEditAction() {
    this.dialogService.openQuery(
      {
        title: 'Zu "Kunden bearbeiten" navigieren',
        message: 'Der Vorang wird abgebrochen und die Daten gehen verloren.',
        btn_cancel_txt: 'Nein',
        btn_submit_txt: 'Ja',
        typ: 'go_to_customer_edit',
        submit_value: null,
      }
    );
  }
  gotoCustomerEdit() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['customers/edit'], { queryParams: { customerId: this.form.controls.customer.value.id } }));
  }
}
