import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, AfterViewInit, Renderer2, EventEmitter, Output } from '@angular/core';
import { Attachment } from 'src/app/buisness-object/attachment/Attachment';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-attachment-element',
  templateUrl: './attachment-element.component.html',
  styleUrls: ['./attachment-element.component.css']
})
export class AttachmentElementComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() source: any;
  @Input() showDelete: boolean = true;
  @Output() deleteEmitter = new EventEmitter<any>()
  @Output() signPDFEmitter = new EventEmitter<any>()
  public showDropdown = false;
  @ViewChild('dropdown') dropdown: ElementRef;
  public listener: () => void;

  constructor(
    private renderer: Renderer2,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click', (e:Event) => {
      if(this.showDropdown && !this.dropdown.nativeElement.contains(e.target)) this.showDropdown = false;
    });
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  getFile() {
    const byteCharacters = atob(this.source.data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    if(this.source.format == 'pdf'){
      this.openNewTab(byteArray)
    } else {
      this.download(byteArray);
    }
  }

  download(data: any) {
    const blob = new Blob([data], { type: 'application/' + this.source.format });
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', this.source.name +'.' + this.source.format);
    a.click();
  }

  openNewTab(data: any) {
    const blob = new Blob([data], { type: 'application/' + this.source.format });
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.click();
  }

  print(pdf: any) {
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const objectUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.src = objectUrl;
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.onload = function() {
      setTimeout(function() {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  deleteAttachment() {
    if(this.source.attachment_id){
      this.deleteEmitter.emit(this.source);
    }
  }

  openViewer() {
    this.dialogService.openAttachementViewer({ pdf: this.source.data, isArrayBuffer: false });
  }

  openImageSlider() {
    if(this.source.data){
      this.dialogService.OpenImageSlider({base64Array: ['data:image/png;base64,'+this.source.data.trim()], startIndex: 0});
    }
  }

  openFile() {
    if(this.source.format == 'jpg' || this.source.format == 'JPG' || this.source.format == 'png' || this.source.format == 'PNG' || this.source.format == 'jpng'){
      this.openImageSlider();
    } else if(this.source.format == 'pdf'){
      this.openViewer();
    }
  }
}
