import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { TireFactory } from 'src/app/buisness-object/tires/factory/TireFactory';
import { StorageLocation } from 'src/app/buisness-object/tires/StorageLocation';
import { StorageLocationFactory } from 'src/app/buisness-object/tires/factory/StorageLocationFactory';
import { Picture } from 'src/app/buisness-object/picture/Picture';
import { EnumService } from '../enum/enum.service';

@Injectable({
  providedIn: 'root'
})
export class TireService {
  public tires$  = new BehaviorSubject<any>(0);
  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService,
    private eService: EnumService
  ) {}

  public getTires(): Observable<Tire[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/tire', { headers });
    return observable.pipe(
      map((data: any) => {
        const objects = TireFactory.jsonFactory(data.tires);
        objects.sort((a, b) => (a.rim_external_id > b.rim_external_id) ? 1 : -1);
        return objects;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createTire(tire: Tire): Observable<Tire> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = tire.getJSON();
    const observable = this.http.post(environment.api + '/back/tire', body, { headers });
    return observable.pipe(
      map((result: any) => {
        let obj = TireFactory.jsonFactoryOne(result.tire);
        if(obj.vehicle_id != null) obj.vehicle = tire.vehicle;
        obj.designation_tire = (obj.vehicle_make?(obj.vehicle_make.makeName+' '):'')+(obj.tire_model?obj.tire_model:'');
        obj.designation_rim = (obj.tire_brand_name?(obj.tire_brand_name+' '):'')+(obj.rim_model?obj.rim_model:'');
        obj.vehicle = tire.vehicle;
        obj.purchased_with_vehicle = tire.purchased_with_vehicle;
        return obj;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateTirePicture(pic: Picture): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = pic.getJSON();
    let observable = this.http.put(environment.api + '/back/tire/picture', body, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return rawData;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }

  public deleteTire(tire: Tire): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.delete(environment.api + '/back/tire/' + tire.tire_id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return true;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }

  public updateTire(tire: Tire): Observable<Tire> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = tire.getJSON();
    const observable = this.http.put(environment.api + '/back/tire', body, { headers });
    return observable.pipe(
      map((result: any) => {
        let obj = TireFactory.jsonFactoryOne(result.tire);
        obj.designation_tire = (obj.vehicle_make?(obj.vehicle_make.makeName+' '):'')+(obj.tire_model?obj.tire_model:'');
        obj.designation_rim = (obj.tire_brand_name?(obj.tire_brand_name+' '):'')+(obj.rim_model?obj.rim_model:'');
        obj.vehicle = tire.vehicle;
        obj.purchased_with_vehicle = tire.purchased_with_vehicle;
        return obj;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getStorageLocations(): Observable<StorageLocation[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/storage_location', { headers });
    return observable.pipe(
      map((data: any) => {
        const objects = StorageLocationFactory.jsonFactory(data.storage_locations);
        objects.sort((a, b) => (a.location_name < b.location_name) ? 1 : -1);
        return objects;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getTirePicture(id: number): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/tire/picture/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return 'data:image/png;base64,'+rawData.data.trim();
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getTireThumbnail(id: number): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
      'skip-loading-animation': 'true'
    });
    let observable = this.http.get(environment.api + '/back/tire/thumbnail/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        /*let tires = this.tires$.getValue();
        let index = tires.findIndex(v => v.pictures[0]?.id == id);
        if(index > -1){
          tires[index].thumbnail = 'data:image/png;base64,'+rawData;
          this.tires$.next(tires);
        }*/
        return rawData.data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createTirePicture(picture: Picture): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const body = {
      tire_id: picture.obj_id,
      picture: picture.src.split(',')[1],
    }
    let observable = this.http.post(environment.api + '/back/tire/picture', body, {headers, reportProgress: true, observe: 'events'});
    return observable.pipe(
      map((rawData: any) => {
        return rawData;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public deletePicture(picture: Picture): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.delete(environment.api + '/back/tire/picture/' + picture.obj_id + '/' + picture.picture_id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return true;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }

  public getThumbnail(id: number): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
      'skip-loading-animation': 'true'
    });
    let observable = this.http.get(environment.api + '/back/tire/thumbnail/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return rawData.data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }
}
