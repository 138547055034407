import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { MainMenu } from 'src/app/buisness-object/menu/MainMenu';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { BaseComponent } from '../../BaseComponent';
import { SubMenuFilter, SubMenuOperator } from 'src/app/buisness-object/menu/SubMenuFilter';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { EnumService } from 'src/app/service/enum/enum.service';


@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrl: './custom-filter.component.css'
})
export class CustomFilterComponent extends BaseComponent implements OnInit  {
  @Input() activeSubMenu$: Observable<SubMenu>;
  @Output() customFilterEmitter = new EventEmitter<any>();
  @Output() closeCustomFilterEmitter = new EventEmitter<void>();
  @Input() showCustomFilter: boolean;
  @Input() employees: Employee[];
  @Input() activeFilter: any[] = [];
  public activeSubMenu: SubMenu;
  public hasLabel: boolean = true;
  private customFilterForm: any;
  private inputRows: any[][] = [];
  public labels: SafeHtml[] = [];
  constructor(
    public authService: AuthenticationService,
    private eService: EnumService,
    private formBuilder: FormBuilder,
    public sanitizer: DomSanitizer
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.activeSubMenu$.subscribe((active) => {
      if(active) {
        this.activeSubMenu = active;
        this.initFilter();
      }
    });
  }

  initFilter() {
    if(this.activeSubMenu) {
      this.hasLabel = this.activeSubMenu.filterable.filter((f) => { return f.label != null}).length > 0;
      this.initFilterForm();
      this.setChangeListener();
    }
  }

  possibleValues(filter: any): any[] {
    let possibleValues = this.activeFilter.filter((f) => {
      if(Array.isArray(f) && f.length > 0) {
        for(let subValue of f) {
          if(subValue.data_key == filter.data_key && filter.operator == subValue.operator) {
            return true;
          }
        }
      } else {
        return f.data_key == filter.data_key && filter.operator == f.operator;

      }
    });
    return possibleValues;
  }

  getRawValueInPossibleValues(filter: any, possibleValues: any[]): any {
    let value: any = null;
    if(Array.isArray(possibleValues[0])) {
      for(let subValue of possibleValues[0]) {
        if(subValue.data_key == filter.data_key && filter.operator == subValue.operator && subValue.raw_value != null) {
          value = subValue.raw_value;
          break;
        }
      }
    } else {
      let subValue = possibleValues[0];
      if(subValue.data_key == filter.data_key && filter.operator == subValue.operator && subValue.raw_value != null) {
        value = subValue.raw_value;
      }
    }
    return value;
  }

  initFilterForm() {
    let formGroupContext: any = {};
    let i = 0;
    this.inputRows = [];
    this.labels = [];
    for(let filter of this.activeSubMenu.filterable) {
      if(filter.position != "INSIDE") {
        continue;
      }
      if(filter.sort_value == i) {
        if(this.inputRows.length == 0) {
          this.inputRows.push([]);
        }
        this.labels.push(this.sanitizer.bypassSecurityTrustHtml(filter.label));
        this.inputRows[i].push(this.getInputRow(filter));
      } else {
        this.inputRows.push([]);
        this.labels.push(this.sanitizer.bypassSecurityTrustHtml(filter.label));
        i++;
        this.inputRows[i].push(this.getInputRow(filter));
      }
      if(filter.data_typ == "BOOLEAN" || filter.data_typ == "BOOLEAN_NULL") {
        formGroupContext[filter.data_key+"_0"] = [null];
        formGroupContext[filter.data_key+"_1"] = [null];
        formGroupContext[filter.data_key+"_2"] = [null];
      } else if(filter.data_typ == "ENUM_TAG") {
        var enumNames = this.getFilterEnumValueNames(filter.data_key);
        formGroupContext[filter.data_key+"_0"] = [null];
        let arrays: any[] = this.activeFilter.filter((f) => Array.isArray(f) && f.length > 0 && f[0].data_key == filter.data_key);
        let setValues = 0;
        for(let i = 0; i < enumNames.length; i++) {
          if(arrays.length > 0 && arrays[0].filter((a) => { return a.raw_value == enumNames[i].id } ).length > 0) {
            formGroupContext[filter.data_key+"_"+(i + 1)] = [true];
            setValues++;
          } else {
            formGroupContext[filter.data_key+"_"+(i + 1)] = [null];
          }
        }
        if(setValues == 0) {
          formGroupContext[filter.data_key+"_0"] = [true];
          for(let i = 0; i< enumNames.length; i++) {
            formGroupContext[filter.data_key+"_"+(i+1)] = [true];
          }
        } else if(setValues == enumNames.length) {
          formGroupContext[filter.data_key+"_0"] = [true];
        }

      } else if(filter.data_typ == "NUMBER" || filter.data_typ == "DATE") {
        let value: any = null;
        let possibleValues = this.possibleValues(filter);
        if(possibleValues.length > 0) {
          value = this.getRawValueInPossibleValues(filter, possibleValues);
          if(filter.data_typ == "DATE") {
            value = new Date(value).toISOString().split('T')[0];
          } else if(filter.data_typ == "NUMBER") {
            value = value;
          }
        } else if(filter.default_value != null) {
          value = filter.default_value;
          if(filter.data_typ == "DATE") {
            if(value == "NOW") {
              value = new Date().toISOString().split('T')[0];
            } else if(value == "NULL") {
              value = null;
            } else {
              value = new Date(value).toISOString().split('T')[0];
            }
          } else if(filter.data_typ == "NUMBER") {
            if(value == "NULL") {
              value = null;
            } else {
              value = value;
            }
          }
        }
        formGroupContext[filter.data_key+"_"+this.getOperatorName(filter.operator)] = [value];
      } else if(filter.data_typ == "ENUM"){
        let value: any = null;
        let possibleValues = this.possibleValues(filter);
        if(possibleValues.length > 0) {
          value = this.getRawValueInPossibleValues(filter, possibleValues);
        }
        formGroupContext[filter.data_key] = [value];
      } else {
        formGroupContext[filter.data_key] = [null];
      }
    }
    this.customFilterForm = this.formBuilder.group(formGroupContext);
  }

  getOperatorName(operator: string): string {
    switch(operator) {
      case ">" : return "greater";
      case "<" : return "less";
      case "=" : return "equal";
      case "<>" : return "notequal";
      case ">=" : return "greaterequal";
      case "<=" : return "lessequal";
    }
  }


  getInputRow(filter: any): any {
    let inputRow: any = {data_key: filter.data_key, label: filter.label, data_typ: filter.data_typ, placeholder: filter.placeholder, format: filter.format };
    if(filter.data_typ == "ENUM_TAG") {
      inputRow["enum"] = this.getFilterEnumValueNames(filter.data_key);
    } else if(filter.data_typ == "NUMBER" || filter.data_typ == "DATE") {
      inputRow["operator_name"] = this.getOperatorName(filter.operator);
    }
    return inputRow;
  }

  setChangeListener() {
    for(let filter of this.activeSubMenu.filterable) {
      if(filter.position != "INSIDE") {
        continue;
      }
      if(filter.data_typ == "BOOLEAN" || filter.data_typ == "BOOLEAN_NULL") {
        let value: any = null;
        let possibleValues = this.possibleValues(filter);
        if(possibleValues.length > 0) {
          value = this.getRawValueInPossibleValues(filter, possibleValues);
        }
        this.customFilterForm.controls[filter.data_key+"_0"].setValue(value == 0 || value == 1 ? false : true);
        this.customFilterForm.controls[filter.data_key+"_1"].setValue(value == null || value == 1 ? true : false);
        this.customFilterForm.controls[filter.data_key+"_2"].setValue(value == null || value == 0 ? true : false);
        this.customFilterForm.controls[filter.data_key+"_0"].valueChanges.subscribe((value) => {
          if(value) {
            this.customFilterForm.controls[filter.data_key+"_1"].setValue(true);
            this.customFilterForm.controls[filter.data_key+"_2"].setValue(true);
          } else {
            if(this.customFilterForm.controls[filter.data_key+"_1"].value
                && this.customFilterForm.controls[filter.data_key+"_2"].value) {
              this.customFilterForm.controls[filter.data_key+"_0"].setValue(true);
            }
          }
        });
        this.customFilterForm.controls[filter.data_key+"_1"].valueChanges.subscribe((value) => {
          if(!value) {
            this.customFilterForm.controls[filter.data_key+"_0"].setValue(false);
            if(this.customFilterForm.controls[filter.data_key+"_2"].value) {
              this.customFilterForm.controls[filter.data_key+"_2"].setValue(false);
              this.customFilterForm.controls[filter.data_key+"_1"].setValue(true);

            }
          } else if(value && this.customFilterForm.controls[filter.data_key+"_2"].value
            && !this.customFilterForm.controls[filter.data_key+"_0"].value) {
            this.customFilterForm.controls[filter.data_key+"_0"].setValue(true);
          }
        });
        this.customFilterForm.controls[filter.data_key+"_2"].valueChanges.subscribe((value) => {
          if(!value) {
            this.customFilterForm.controls[filter.data_key+"_0"].setValue(false);
            if(this.customFilterForm.controls[filter.data_key+"_1"].value) {
              this.customFilterForm.controls[filter.data_key+"_1"].setValue(false);
              this.customFilterForm.controls[filter.data_key+"_2"].setValue(true);
            }
          } else if(value && this.customFilterForm.controls[filter.data_key+"_1"].value &&
                     !this.customFilterForm.controls[filter.data_key+"_0"].value) {
            this.customFilterForm.controls[filter.data_key+"_0"].setValue(true);
          }
        });
      } else if(filter.data_typ == "ENUM_TAG") {
        var enumNames = this.getFilterEnumValueNames(filter.data_key);
        this.customFilterForm.controls[filter.data_key+"_0"].valueChanges.subscribe((value) => {
          var enumNames = this.getFilterEnumValueNames(filter.data_key);
          if(value) {
            for(let i = 0; i< enumNames.length; i++) {
              if(!this.customFilterForm.controls[filter.data_key+"_"+(i+1)].value) {
                this.customFilterForm.controls[filter.data_key+"_"+(i+1)].setValue(true);
              }
            }
          } else {
            for(let i = 0; i< enumNames.length; i++) {
              if(this.customFilterForm.controls[filter.data_key+"_"+(i+1)].value) {
                this.customFilterForm.controls[filter.data_key+"_"+(i+1)].setValue(false);
              }
            }
          }
        });
        for(let i = 0; i< enumNames.length; i++) {
          this.customFilterForm.controls[filter.data_key+"_" + (i +1)].valueChanges.subscribe((value) => {
              var enumNames = this.getFilterEnumValueNames(filter.data_key);
              var allChecked = true
              let tempValues: boolean[] = [];
              for(let j = 0; j< enumNames.length; j++) {
                if(!this.customFilterForm.controls[filter.data_key+"_" + (j + 1)].value) {
                   allChecked = false;
                }
                tempValues.push(this.customFilterForm.controls[filter.data_key+"_" + (j + 1)].value);
              }
              if(!allChecked) {
                if(this.customFilterForm.controls[filter.data_key+"_0"].value) {
                  this.customFilterForm.controls[filter.data_key+"_0"].setValue(false);
                  for(let j = 0; j< enumNames.length; j++) {
                    if(this.customFilterForm.controls[filter.data_key+"_" + (j + 1)].value != tempValues[j]) {
                      this.customFilterForm.controls[filter.data_key+"_" + (j + 1)].setValue(tempValues[j]);
                    }
                  }
                }
              } else {
                if(!this.customFilterForm.controls[filter.data_key+"_0"].value) {
                  this.customFilterForm.controls[filter.data_key+"_0"].setValue(true);
                }
              }
          });
        }
      }
    }
  }

  getFilterEnumValueNames(data_key: string): any[] {
    if(data_key == "priceSheet.employee") {
      return this.employees.map<any>((e) => { return {name: e.lastName, id: e.id}}).concat([{name: "Keine Angabe", id: "NULL"}]);
    } else {
      return this.getEnumValueNames(data_key).concat([{name: "Keine Angabe", id: "NULL"}]);
    }
  }

  setValueFromDropdown(value: any, event: any) {
    if(event) {
      this.customFilterForm.controls[value].setValue(event);
    }
  }

  reset() {
    for(let filter of this.activeSubMenu.filterable) {
      if(filter.position != "INSIDE") {
        continue;
      }
      if(filter.data_typ == "BOOLEAN" || filter.data_typ == "BOOLEAN_NULL") {
        this.customFilterForm.controls[filter.data_key+"_0"].setValue(true);
        this.customFilterForm.controls[filter.data_key+"_1"].setValue(true);
        this.customFilterForm.controls[filter.data_key+"_2"].setValue(true);
      } else if(filter.data_typ == "ENUM_TAG") {
        this.customFilterForm.controls[filter.data_key+"_0"].setValue(true);
        let enumValues = this.getFilterEnumValueNames(filter.data_key);
        for(let i = 0; i < enumValues.length; i++) {
          this.customFilterForm.controls[filter.data_key+"_"+(i+1)].setValue(true);
        }
      } else if(filter.data_typ == "NUMBER" || filter.data_typ == "DATE") {
        this.customFilterForm.controls[filter.data_key+"_"+this.getOperatorName(filter.operator)].setValue(null);
      } else {
        this.customFilterForm.controls[filter.data_key].setValue(null);

      }
    }
    this.filter();
  }

  filter() {
    let filteredValues: any[] = [];
    for(let filter of this.activeSubMenu.filterable) {
      if(filter.data_typ == "BOOLEAN" || filter.data_typ == "BOOLEAN_NULL") {
        let allTrues = this.customFilterForm.value[filter.data_key+"_1"];
        let allFalses = this.customFilterForm.value[filter.data_key+"_2"];
        if(allTrues && !allFalses) {
          if(filteredValues.length != 0) {
            filteredValues.push(new SubMenuOperator("&&"));
          }
          filteredValues.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, filter.operator, "BOOLEAN", "1"));
        } else if (!allTrues && allFalses) {
          if(filter.data_typ == "BOOLEAN_NULL") {
            if(filteredValues.length != 0) {
              filteredValues.push(new SubMenuOperator("&&"));
            }
            let subFilter: any[] = [];
            subFilter.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, filter.operator, "BOOLEAN", "0"));
            subFilter.push(new SubMenuOperator("||"));
            subFilter.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, filter.operator, "BOOLEAN", "NULL"));
            filteredValues.push(subFilter);
          } else {
            if(filteredValues.length != 0) {
              filteredValues.push(new SubMenuOperator("&&"));
            }
            filteredValues.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, filter.operator, "BOOLEAN", "0"));
          }
        }
      } else if(filter.data_typ == "ENUM_TAG") {
        let allSet = this.customFilterForm.value[filter.data_key+"_0"];
        if(!allSet) {
          let enumValues = this.getFilterEnumValueNames(filter.data_key);
          let selectedValues = [];
          for(let i = 0; i < enumValues.length; i++) {
            if(this.customFilterForm.value[filter.data_key+"_"+(i+1)]) {
              selectedValues.push(enumValues[i].id);
            }
          }
          let subFilter: any[] = [];
          for(let j = 0; j < selectedValues.length; j++) {
            if(j != 0) {
              subFilter.push(new SubMenuOperator("||"));
            }
            subFilter.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, filter.operator, filter.data_typ, selectedValues[j]));
          }
          if(subFilter.length != 0) {
            if(filteredValues.length != 0) {
              filteredValues.push(new SubMenuOperator("&&"));
            }
            filteredValues.push(subFilter);
          }
        }
      } else if(filter.data_typ == "ENUM_STRING") {
        let value = this.customFilterForm.controls[filter.data_key]?.value;
        if(value){
          let enumValues = this.getFilterEnumValueNames(filter.data_key);
          if(!isNaN(value)){
            let v;;
            for(let i = 0; i < enumValues.length; i++) {
              if(Number(value) == enumValues[i].id) {
                value = enumValues[i].name;
                break;
              }
            }
            filteredValues.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, filter.operator, filter.data_typ, value));
          } else {
            filteredValues.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, filter.operator, filter.data_typ, value));
          }
        }
      } else if(filter.data_typ == "NUMBER") {
        let rawValue = this.customFilterForm.value[filter.data_key + "_" + this.getOperatorName(filter.operator)];
        if(rawValue != null) {
          if(filteredValues.length != 0) {
            filteredValues.push(new SubMenuOperator("&&"));
          }
          filteredValues.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, filter.operator, filter.data_typ, rawValue));
        }
      } else if(filter.data_typ == "DATE") {
        let rawValue = this.customFilterForm.value[filter.data_key + "_" + this.getOperatorName(filter.operator)];

        if(filter.format != "MM.yyyy" && (rawValue != null && rawValue != "")) {
          rawValue = new Date(rawValue).getTime();
          if(filteredValues.length != 0) {
            filteredValues.push(new SubMenuOperator("&&"));
          }
          filteredValues.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, filter.operator, filter.data_typ, rawValue));
        } else if(filter.format == "MM.yyyy" && filter.operator == "=" && (rawValue != null && rawValue != "")) {
          if(filteredValues.length != 0) {
            filteredValues.push(new SubMenuOperator("&&"));
          }
          let rawDate = new Date(rawValue+"-01");
          rawDate.setHours(0, 0, 0, 0);
          rawValue = rawDate.getTime();
          rawDate.setMonth(rawDate.getMonth() + 1);
          rawDate.setDate(rawDate.getDate() - 1);
          rawDate.setHours(23, 59, 59, 999);
          let rawValue2: any = rawDate.getTime();
          filteredValues.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, ">=", filter.data_typ, rawValue));
          filteredValues.push(new SubMenuOperator("&&"));
          filteredValues.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, "<=", filter.data_typ, rawValue2));
        }
      } else {
        let rawValue = this.customFilterForm.value[filter.data_key];
        if(rawValue != null && rawValue != "") {
          if(filteredValues.length != 0) {
            filteredValues.push(new SubMenuOperator("&&"));
          }
          filteredValues.push(new SubMenuFilter(filter.data_object, filter.calculated, filter.data_key, filter.operator, filter.data_typ, rawValue));
        }
      }
    }
    this.customFilterEmitter.emit(filteredValues);
  }

  clickOutside() {
    this.closeCustomFilterEmitter.emit();
  }
}
