export enum MasterMenu {
  VEHICLE = 'vehicle',
  DOCUMENTS = 'documents',
  ACCOUNTING = 'accounting',
  TESTDRIVES = 'drive',
  LEDGERENTRY = 'ledger_entry',
  BILL = 'bill',
  PURCHASECONTRACT = 'purchase_contract',
  TIRE = 'tire',
  USER = 'employee',
  LOCATION = 'location',
  CUSTOMER = 'customer',
  RENT = 'rent',
}
