<div class="overview_container">
  <div class="data_container">
    <div class="header" [ngClass]="thread.resolved != 0? 'header_done' : ''">
        <div class="user_initials">{{thread.employee ? thread.employee.getInitials() : '--'}}</div>
        <div class="column">
            <div class="lbl_regular_thick">{{thread.employee ? thread.employee.firstName : '---'}} {{thread.employee ? thread.employee.lastName : '---'}}</div>
            <div class="lbl_regular_light_color">{{thread.createdDate | date: 'dd.MM.YYYY, HH:mm'}}</div>
        </div>
        <div class="column">
            <div class="lbl_regular_thick">Tickettyp</div>
            <div class="lbl_regular_light_color">{{thread.getTypName()}}</div>
        </div>
        <div class="column">
            <div class="lbl_regular_thick">{{thread.getTypName()}}</div>
            <div class="lbl_regular_light_color">{{thread.typ == 1 ? thread.vehicle.getDescription(40) : (thread.typ == 2 ? thread.customer.getName() : '---')}}</div>
        </div>
        <div *ngIf="thread.resolved == 0" class="priority_container">
            <div class="circle" [ngStyle]="{'background-color': thread.urgency == 0 ? 'var(--color-green)' : (thread.urgency == 1 ? 'var(--color-orange)' : 'var(--color-red)')}"></div>
            <div class="lbl_regular_thick">{{getUrgency(thread.urgency)}}</div>
        </div>
        <div class="state_container" [ngClass]="thread.resolved == 0 ? 'state_open' : 'state_done'">{{thread.resolved == 0 ? 'Offen' : 'Erledigt'}}</div>
        <div class="action_container" *ngIf="thread.resolved == 0 && thread.creator == userId">
            <div class="action" (click)="resolve(thread)">
                <img class="icon" src="../../../../../assets/lock-blue.svg">
                <div class="action_lbl">Auflösen</div>
            </div>
        </div>
        <div *ngIf="thread.resolved != 0" class="column">
            <div class="lbl_regular_thick">Aufgelöst am</div>
            <div class="lbl_regular_light_color">{{thread.resolved | date: 'dd.MM.YYYY, HH.mm'}}</div>
        </div>
      </div>
      <div class="body">
          <div class="ticket_title_container">
              <div class="lbl_ticket" [innerHTML]="getMessage(thread.messages[0])"></div>
          </div>
          <div [attr.id]="'msg_' + msg.messageId" *ngFor="let msg of thread.messages | slice:1" class="ticket_message_container">
              <div class="user_initials_message">{{msg.employee ? msg.employee.getInitials() : '--'}}</div>
              <div>
                  <div class="lbl_regular_light_color msg_credentials">{{msg.employee.firstName}} {{msg.employee.lastName}}</div>
                  <div class="lbl_regular_light_color msg_credentials">{{msg.timestamp | date: 'dd.MM.YYYY, HH:mm'}}</div>
                  <div class="message" [innerHTML]="getMessage(msg)"></div>
              </div>
          </div>
      </div>
      <div *ngIf="thread.resolved == 0" class="footer">
        <app-custom-mark-input class="mark_input"
          [data]="employees"
          [btn_name]="'Antworten'"
          (selectionCreateEmitter)="submit($event)"
        ></app-custom-mark-input>
          <!-- <app-textinput-add-function
              [users]="employees"
              [inputHeight]="'70px'"
              (inputEmitter)="inputAction($event)"
          ></app-textinput-add-function>
          <div class="btn_submit" (click)="submit()">Antworten<img class="icon_send" src="../../../../assets/send.svg"></div> -->
      </div>
  </div>
</div>
