import { debounceTime } from 'rxjs/operators';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Subscription, Subject } from 'rxjs';
import { Component, HostListener, Input, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-calendar-time-picker',
  templateUrl: './calendar-time-picker.component.html',
  styleUrls: ['./calendar-time-picker.component.css']
})
export class CalendarTimePickerComponent implements OnInit, AfterViewInit {
  @Input() selectedEntrieDay: any;
  @Input() timePickerHour: number;
  @Input() timePickerError: boolean;
  @Output() submitEmitter = new EventEmitter<any>();
  @Output() cancelEmitter = new EventEmitter<undefined>();
  @Output() timeErrorResetEmitter = new EventEmitter<undefined>();

  public hhArray: number[] = [];
  public mmArray: number[] = [];
  public hhArray_2: number[] = [];
  public mmArray_2: number[] = [];
  public hhScroll$ = new Subject<number>();
  public mmScroll$ = new Subject<number>();
  public hhScroll_2$ = new Subject<number>();
  public mmScroll_2$ = new Subject<number>();
  public hhValue;
  public mmValue;
  public hhValue_2;
  public mmValue_2;
  public itemHeightInPx = 40;
  public lastHHItem: any;
  public lastMMItem: any;
  public lastHHItem_2: any;
  public lastMMItem_2: any;
  public selectedDay: Date;
  public indexHH = 0;
  public indexMM = 0;
  public indexHH_2 = 0;
  public indexMM_2 = 0;
  public selectedHour = 0;
  public selectedMin = 0;
  public selectedHour_2 = 0;
  public selectedMin_2 = 0;
  public stepsMin = 15;
  public minShownHour = 8;
  public maxShownHour = 20;
  public selectedStartTime = '08:00';
  public selectedEndTime = '08:00';

  public fontSizeSmall = 18;
  public fontSizeBig = 24;
  public screenSize;


  @ViewChild('timeContainer') timeContainer: ElementRef;
  @ViewChild('hContainer') hhContainerRef: ElementRef;
  @ViewChild('mContainer') mmContainerRef: ElementRef;
  @ViewChild('hContainer_2') hhContainerRef_2: ElementRef;
  @ViewChild('mContainer_2') mmContainerRef_2: ElementRef;

  @HostListener("window:scroll", ['$event'])
  handleHHScroll(event, picker) {
    if(picker == 1){
      this.hhValue = this.getValueByPosition(event.target.scrollTop, 'hh', picker);
      this.hhScroll$.next(this.hhValue);
    } else {
      this.hhValue_2 = this.getValueByPosition(event.target.scrollTop, 'hh', picker);
      this.hhScroll_2$.next(this.hhValue_2);
    }
    this.timeErrorResetEmitter.emit();
  }
  @HostListener("window:scroll", ['$event'])
  handleMMScroll(event, picker) {
    if(picker == 1){
      this.mmValue = this.getValueByPosition(event.target.scrollTop, 'mm', picker);
      this.mmScroll$.next(this.mmValue);
    } else {
      this.mmValue_2 = this.getValueByPosition(event.target.scrollTop, 'mm', picker);
      this.mmScroll_2$.next(this.mmValue_2);
    }
    this.timeErrorResetEmitter.emit();
  }

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    this.onSubmit();
  }

  constructor() {}

  ngOnInit(): void {
    this.handleScreenSize();
    this.prepareView();
  }

  ngAfterViewInit() {
    this.handleScroll();
    this.setStart();
  }

  handleScreenSize() {
    this.screenSize = window.innerWidth;
    if(this.screenSize <= 1420){
      this.itemHeightInPx = 30;
      this.fontSizeSmall = 14;
      this.fontSizeBig = 18;
    } else {
      this.itemHeightInPx = 40;
      this.fontSizeSmall = 18;
      this.fontSizeBig = 24;
    }
  }

  prepareView() {
    this.hhArray = [];
    this.mmArray = [];
    this.hhArray_2 = [];
    this.mmArray_2 = [];
    this.selectedEntrieDay.day.setHours(this.timePickerHour);
    this.hhArray = this.setTimeHour();
    this.mmArray = this.setTimeMin();
    this.hhArray_2 = this.setTimeHour();
    this.mmArray_2 = this.setTimeMin();
  }

  async onScreenSizeChange() {
    this.handleScreenSize();
    this.prepareView();
    //this.handleScroll();
    this.setStart();
  }

  setStart() {
    this.selectedHour = this.selectedEntrieDay.day.getHours();
    let indexHour = this.hhArray.findIndex(h => h == this.selectedHour);
    if(indexHour > -1){
      this.scrollTo(indexHour,'hh');
      setTimeout(() => {
        this.scrollTo(indexHour + 1,'hh_2');
      }, 250);
    }
    setTimeout(() => {
      this.setStartColor(indexHour);
    }, 100);
  }

  setTimeHour(): number[] {
    let hhArray = [];
    for(let i = this.minShownHour; i <= this.maxShownHour; i++){
      hhArray.push(i);
    }
    return hhArray;
  }

  setTimeMin(): number[] {
    let mmArray = [];
    let stepsBegin = false;
    for(let i = -this.stepsMin; i < 60; i){
      let num = i / this.stepsMin;
      if(!stepsBegin && num % 1 == 0 ){
        stepsBegin = true;
      }
      if(stepsBegin){
        i += this.stepsMin;
        if(i != 60){
          mmArray.push(i);
        }
      } else {
        i++;
      }
    }
    return mmArray;
  }

  getValueByPosition(y, type, picker) {
    let timeArray = type === 'hh' ? (picker == 1 ? this.hhArray : this.hhArray_2) : (picker == 1 ? this.mmArray : this.mmArray_2);
    let index = Math.floor((y+17)/this.itemHeightInPx);
    if(index < 0){
      index = 0;
    }
    if(index >= timeArray.length){
      index = timeArray.length -1;
    }
    if(type === 'hh'){
      if(picker == 1){
        this.selectedHour = this.hhArray[index];
        this.indexHH = index;
      }
      if(picker == 2){
        this.selectedHour_2 = this.hhArray_2[index];
        this.indexHH_2 = index;
      }
    }
    if(type === 'mm'){
      if(picker == 1){
        this.selectedMin = this.mmArray[index];
        this.indexMM = index;
      }
      if(picker == 2){
        this.selectedMin_2 = this.mmArray_2[index];
        this.indexMM_2 = index;
      }
    }
    //this.setColor(index, type);
    return timeArray[index];
  }

  calculateMinsInHour(hour: number) {
    let today = new Date();
    if(hour == today.getHours() && this.selectedEntrieDay.day.getDate() == today.getDate()){
    } else {
      if(this.mmArray.length < 12){
        this.mmArray = [];
        let index;
        for(let i = 0; i < 60; i+=this.stepsMin){
          this.mmArray.push(i);
          if(i == this.selectedMin){
            index = i;
          }
        }
      }
    }
  }

  setMin(min: number) {
    this.selectedMin = min;
  }

  scrollTo(index, type) {
    let containerRef;
    if(type == 'hh') containerRef = this.hhContainerRef;
    if(type == 'hh_2') containerRef = this.hhContainerRef_2;
    if(type == 'mm') containerRef = this.mmContainerRef;
    if(type == 'mm_2') containerRef = this.mmContainerRef_2;
    containerRef.nativeElement.scrollTo({
        top: index * this.itemHeightInPx,
        behavior: 'smooth'
    });
    if(type === 'hh'){
      this.selectedHour = this.hhArray[index];
    }
    if(type === 'hh_2'){
      this.selectedHour_2 = this.hhArray[index];
    }
    if(type === 'mm'){
      this.selectedMin = this.mmArray[index];
    }
    if(type === 'mm_2'){
      this.selectedMin_2 = this.mmArray[index];
    }
    this.setColor(index, type);
    this.timeErrorResetEmitter.emit();
  }

  handleScroll() {
    this.hhScroll$.asObservable().pipe(
      debounceTime(50)
    ).subscribe((v) => {
        let index = this.hhArray.indexOf(v);
        this.hhContainerRef.nativeElement.scrollTo({
            top: index * this.itemHeightInPx,
            behavior: 'smooth'
        });
        this.setColor(index, 'hh');
    })
    this.mmScroll$.asObservable().pipe(
      debounceTime(50)
    ).subscribe((v) => {
        let index = this.mmArray.indexOf(v);
        this.mmContainerRef.nativeElement.scrollTo({
            top: index * this.itemHeightInPx,
            behavior: 'smooth'
        });
        this.setColor(index, 'mm');
    })
    this.hhScroll_2$.asObservable().pipe(
      debounceTime(50)
    ).subscribe((v) => {
        let index = this.hhArray_2.indexOf(v);
        this.hhContainerRef_2.nativeElement.scrollTo({
            top: index * this.itemHeightInPx,
            behavior: 'smooth'
        });
        this.setColor(index, 'hh_2');
    })
    this.mmScroll_2$.asObservable().pipe(
      debounceTime(50)
    ).subscribe((v) => {
        let index = this.mmArray_2.indexOf(v);
        this.mmContainerRef_2.nativeElement.scrollTo({
            top: index * this.itemHeightInPx,
            behavior: 'smooth'
        });
        this.setColor(index, 'mm_2');
    })
  }

  setColor(index, type) {
    if(type == 'hh'){
      if(this.lastHHItem){
        this.lastHHItem.style.color = 'lightgrey';
        this.lastHHItem.style.fontSize = this.fontSizeSmall+'px';
      }
    }
    if(type == 'hh_2'){
      if(this.lastHHItem_2){
        this.lastHHItem_2.style.color = 'lightgrey';
        this.lastHHItem_2.style.fontSize = this.fontSizeSmall+'px';
      }
    }
    if(type == 'mm'){
      if(this.lastMMItem){
        this.lastMMItem.style.color = 'lightgrey';
        this.lastMMItem.style.fontSize = this.fontSizeSmall+'px';
      }
    }
    if(type == 'mm_2'){
      if(this.lastMMItem_2){
        this.lastMMItem_2.style.color = 'lightgrey';
        this.lastMMItem_2.style.fontSize = this.fontSizeSmall+'px';
      }
    }
    let item = document.getElementById(type + index);
    if(item){
      if(type == 'hh'){
        this.lastHHItem = item;
        this.lastHHItem.style.color = 'black';
        this.lastHHItem.style.fontSize = this.fontSizeBig+'px';
        this.lastHHItem.style.fontWeight = '600';
      }
      if(type == 'hh_2'){
        this.lastHHItem_2 = item;
        this.lastHHItem_2.style.color = 'black';
        this.lastHHItem_2.style.fontSize = this.fontSizeBig+'px';
        this.lastHHItem_2.style.fontWeight = '600';
      }
      if(type == 'mm'){
        this.lastMMItem = item;
        this.lastMMItem.style.color = 'black';
        this.lastMMItem.style.fontSize = this.fontSizeBig+'px';
        this.lastMMItem.style.fontWeight = '600';
      }
      if(type == 'mm_2'){
        this.lastMMItem_2 = item;
        this.lastMMItem_2.style.color = 'black';
        this.lastMMItem_2.style.fontSize = this.fontSizeBig+'px';
        this.lastMMItem_2.style.fontWeight = '600';
      }
    }
  }

  setStartColor(indexHour: number) {
    this.lastHHItem = document.getElementById('hh'+indexHour);
    this.lastMMItem = document.getElementById('mm0');
    this.lastHHItem_2 = document.getElementById('hh_2'+indexHour);
    this.lastMMItem_2 = document.getElementById('mm_20');
    if(this.lastHHItem){
      this.lastHHItem.style.color = 'black';
      this.lastHHItem.style.fontSize = this.fontSizeBig+'px';
      this.lastHHItem.style.fontWeight = '600'
    }
    if(this.lastMMItem){
      this.lastMMItem.style.color = 'black';
      this.lastMMItem.style.fontSize = this.fontSizeBig+'px';
      this.lastMMItem.style.fontWeight = '600'
    }
    if(this.lastHHItem_2){
      this.lastHHItem_2.style.color = 'black';
      this.lastHHItem_2.style.fontSize = this.fontSizeBig+'px';
      this.lastHHItem_2.style.fontWeight = '600'
    }
    if(this.lastMMItem_2){
      this.lastMMItem_2.style.color = 'black';
      this.lastMMItem_2.style.fontSize = this.fontSizeBig+'px';
      this.lastMMItem_2.style.fontWeight = '600'
    }
  }

  getTime(): any {
    this.selectedStartTime = (this.selectedHour < 10 ? '0' : '') +  this.selectedHour + ':' + (this.selectedMin < 10 && this.selectedMin > 0 ? '0' : '') + this.selectedMin + (this.selectedMin == 0 ? '0' : '');
    this.selectedEndTime = (this.selectedHour_2 < 10 ? '0' : '') +  this.selectedHour_2 + ':' + (this.selectedMin_2 < 10 && this.selectedMin_2 > 0 ? '0' : '') + this.selectedMin_2 + (this.selectedMin_2 == 0 ? '0' : '');
  }

  onSubmit() {
    let start = new Date(this.selectedEntrieDay.day.setHours(this.selectedHour));
    start.setMinutes(this.selectedMin);
    start.setSeconds(0);
    start.setMilliseconds(0);
    let end = new Date(this.selectedEntrieDay.day.setHours(this.selectedHour_2));
    end.setMinutes(this.selectedMin_2);
    end.setSeconds(0);
    end.setMilliseconds(0);
    // if(start.getTime() < end.getTime()){
    //   this.submitEmitter.emit(({
    //     start_time: start.getTime(),
    //     end_time: end.getTime(),
    //   }))
    // }
    this.submitEmitter.emit(({
      start_time: start.getTime(),
      end_time: end.getTime(),
    }))
  }
}
