export class FileHandler {

  //image/x-png,image/jpeg, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document
  public static acceptedFormats = [
    'image/x-png,image/jpeg',
    'application/pdf',
    '.csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  public static triggerFileUpload(type: 'single' | 'multiple', accept: string[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let element = document.createElement('input');
      element.type = 'file';
      element.multiple = type == 'multiple' ? true : false;
      if(accept.length == 0) accept = this.acceptedFormats;
      element.accept = accept.join(',');
      element.addEventListener('change', (event: any) => {
        resolve(this.uploadFile(event));
      });
      setTimeout(() => {
        element.click();
      }, 200);
    });
  }

  public static uploadFile(event: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let promises = [];
      let sources = [];
      if(event.target.files && event.target.files.length != 0){
        let files = event.target.files;
        for(let i = 0; i < files.length; i++){
          promises.push(this.readFile(files[i]).then((source) => {
            sources.push(source);
          }).catch((error) => {
            console.log(error);
            reject([]);
          }));
        }
        if(event.target != null) event.target.value = '';
        Promise.all(promises).then(() => {
          resolve(sources);
        }).catch((error) => {
          console.log(error);
          reject([]);
        });
      }
    });
  }

  public static readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        let source = {
          name: file.name.split('.')[0],
          format: file.name.split('.')[1],
          data: event.target.result.split(',')[1]
        }
        resolve(source);
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    });
  }
}
