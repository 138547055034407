import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-bill-create-position-element',
  templateUrl: './bill-create-position-element.component.html',
  styleUrl: './bill-create-position-element.component.css'
})
export class BillCreatePositionElementComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() index: number;
  @Input() submitted: boolean;
  @Output() removeElementEmitter = new EventEmitter<number>();
  public taxes = [20,10,0];
  public selectedTax;

  constructor(){}

  ngOnInit(): void {
    if(this.form.get('tax_rate').value != null){
      this.selectedTax = this.form.get('tax_rate').value;
    } else {
      this.selectedTax = this.taxes[0];
      this.form.get('tax_rate').setValue(20);
    }
  }

  selectionTax(value) {
    this.selectedTax = value;
    this.form.get('tax_rate').setValue(value);
  }
}
