import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-dropdown-enum',
  templateUrl: './dropdown-enum.component.html',
  styleUrl: './dropdown-enum.component.css'
})
export class DropdownEnumComponent implements OnInit, AfterViewInit {
  @Input() enumName: string;
  @Input() control: FormControl;
  @Input() name: String;
  @Input() placeholder: String = '---';
  @Input() submitted: boolean = false;
  @Input() disabled: boolean = false;
  public showValues: boolean = false;
  public enums = [];
  public enumsFiltered = [];
  @Output() submitValueEmitter = new EventEmitter<any>();
  @ViewChild('inputEnum') inputEnum: ElementRef;

  constructor(
    private enumService: EnumService
  ){}

  ngOnInit(): void {
    this.enums = this.enumService.getEnums(this.enumName);
    this.enums.sort((a,b) => a.sort_value - b.sort_value);
    this.enumsFiltered = this.enums;
  }

  ngAfterViewInit(): void {
    if(this.control.value != null){
      this.inputEnum.nativeElement.value = this.enumService.getEnumValue(this.enumName, this.control.value)
    }
  }

  searchValues(str: string) {
    this.enumsFiltered = [];
    if(str && str.length > 0){
      this.control.setValue(null);
      this.enumsFiltered = this.enums.filter((value) => {
        return value.value.toLowerCase().includes(str.toLowerCase());
      });
    } else {
      this.enumsFiltered = this.enums;
      this.control.setValue(null);
    }
  }

  submit(enumValue) {
    this.control.setValue(enumValue.raw_value);
    this.inputEnum.nativeElement.value = enumValue.value;
    this.submitValueEmitter.emit(enumValue.raw_value);
  }
}
