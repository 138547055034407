<div id="vehicle-container">
    <div class="title">Fuhrwerk</div>
    <div class="info">Audi A4 Avant 2,0 TDI ***AKTION*** Virtual Cockpit Kombi</div>
    <div id="input-container">
        <div id="container1">
            <div id="color">
                <select class="dropdown" id="main-color" required>
                    <option disabled selected>Hauptfarbe*</option>
                    <option>Rot</option>
                    <option>Blau</option>
                    <option>Grün</option>
                </select>
                <input type="text" class="inputfield" placeholder="Farbcode">
            </div>
            <select class="dropdown">
                <option disabled selected>Sonderfarbe</option>
                <option>Farbe 1</option>
                <option>Farbe 2</option>
            </select><br>
            <select class="dropdown">
                <option disabled selected>Interieur</option>
                <option>Interieur 1</option>
                <option>Interieur 2</option>
            </select><br>
            <select class="dropdown">
                <option disabled selected>Getriebeart</option>
                <option>Getriebe 1</option>
                <option>Getriebe 2</option>
            </select>
        </div>
        <div id="container2">
            <select class="dropdown" required>
                <option disabled selected>Aufbauart*</option>
                <option>Aufbau 1</option>
                <option>Aufbau 2</option>
                <option>Aufbau 3</option>
            </select>
            <input type="text" class="inputfield" placeholder="Türen / Sitze">
            <div id="checkbox-container">
                <input type="checkbox" class="checkbox" name="service">
                <label for="service" class="label-for-checkbox">Serviceheft</label>
            </div>
        </div>
    </div>
</div>