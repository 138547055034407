import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Attachment, AttachmentCategory, AttachmentType } from 'src/app/buisness-object/attachment/Attachment';
import { Rent } from 'src/app/buisness-object/rent/Rent';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { RentService } from 'src/app/service/rent/rent.service';

@Component({
  selector: 'app-miete-images',
  templateUrl: './miete-images.component.html',
  styleUrl: './miete-images.component.css'
})
export class MieteImagesComponent implements OnInit {

  @Input() obj: Rent;
  @Input() isHandover: boolean;

  public objects: any[] = [];
  public handoverPics: Attachment[] = [];
  public returnPics: Attachment[] = [];

  constructor(
    private attachmentService: AttachmentService
  ){}

  ngOnInit(): void {
    this.setUp();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['obj']) {
      this.setUp();
    }
  }

  async setUp() {
    await this.getDataServerside().toPromise();
  }

  getDataServerside(): Observable<any> {
    return forkJoin({
      attachments: this.attachmentService.getAttachmentsByTypId(AttachmentType.RENT_ORDER, this.obj.rent_id)
    }).pipe(
      tap((result) => {
        if(result){    
         this.objects = result.attachments;

         for(let o of this.objects){
          if(o.attachment_category == AttachmentCategory.RENT_ORDER_RECEIVE_PICTURE){
            if(!this.handoverPics.some(pic => pic.attachment_id === o.attachment_id)) {
              this.handoverPics.push(o);
            }
          } else if(o.attachment_category == AttachmentCategory.RENT_ORDER_RETURN_PICTURE){
            if (!this.returnPics.some(pic => pic.attachment_id === o.attachment_id)) {
              this.returnPics.push(o);
            }
          }
         }
        }
      }),catchError((error) => {
        console.error(error);
        return of([]);
      })
    );
  }

}
