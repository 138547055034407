export class VehicleEquipment {
    public isSelected = false;
    constructor(
        public eq_id: number,
        public category_id: number,
        public eq_name: string,
        public category_name: string,
        public plattform: number,
    ){}
}
