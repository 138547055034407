<div id="calendar" (window:resize)="onScreenSizeChange()">
  <div id="calendar_header">
    <div class="btn_container" (click)="showWeek ? previousWeek() : null">
      <img src="../../../../assets/arrow-dark.svg" style="margin-right: 10px; transform: rotate(90deg);">
      <div style="color: black" class="lbl_regular_italic_light_color">{{showWeek ? 'letzte Woche' : 'letzter Tag'}}</div>
    </div>
    <input #inputDate id="input-date" type="date" placeholder="date" style="width: fit-content !important;" [class.date-input--has-value]="inputDate.value != null" (change)="changeWeek(inputDate.value)">
    <div class="btn_container" (click)="showWeek ? nextWeek() : null">
      <div style="color: black" class="lbl_regular_italic_light_color">{{showWeek ? 'nächste Woche' : 'nächster Tag'}}</div>
      <img src="../../../../assets/arrow-dark.svg"  style="margin-left: 10px; transform: rotate(-90deg);">
    </div>
  </div>
  <div id="calendar_body" *ngIf="showWeek && filledCalenderWeek.length > 0">
    <div id="title_bar">
      <div id="y_achse" class="lbl_regular_italic_light_color">
        <div id="kw_container">
          <div>KW</div>
          <div>{{weekNumber}}</div>
        </div>
        <div id="time_container">
          <div *ngFor="let hour of hours" class="hour_row">{{hour}}:00</div>
        </div>
      </div>
      <div *ngFor="let calendarDay of filledCalenderWeek; let d = index;" class="title_day_column" [ngClass]="calendarDay.is_today ? 'column_today' : (isOdd(d) ? 'odd' : '')">
        <div class="day_header">
          <div class="lbl_regular_italic_light_color">{{calendarDay.day | date: 'EEEE'}}</div>
          <div style="font-weight: bold;">{{calendarDay.day | date: 'dd. MMM'}}</div>
        </div>
        <div class="hour_row_day_container">
          <div *ngFor="let hour of hours" class="hour_row_day">
            <div *ngIf="isCreate && isFreePlace(calendarDay, hour)" class="add_button" (click)="addEntrie(calendarDay, hour)">+</div>
            <!-- <div *ngIf="isCreate && !isPastDate(calendarDay.day, hour) && isFreePlace(calendarDay, hour)" class="add_button" (click)="addEntrie(calendarDay, hour)">+</div> -->
          </div>
        </div>
        <div class="grid_entries" [ngStyle]="{'z-index': calendarDay.z_index}">
          <div *ngFor="let entry of calendarDay.entries; let i = index"
            [class.grid_entrie]="isCreate"
            [class.grid-entry-button-violet]="entry.is_customer"
            [class.grid-entry-button-teal]="!entry.is_customer"
            [attr.id]="'entrie_'+i" (click)="entry.is_new ? removeEntrie(entry, d) : null"
            [ngStyle]="{
              'display': entry.position.column + 1 == 7 ? 'none' : '',
              'grid-column': entry.position.column,
              'grid-row': (entry.position.row + 1) + '/' + entry.position.row_span,
              'background-color': entry.is_new ? '#F2F5FD' : (entry.is_customer ? '#3D2CD4' : '#1C7C87'),
              'color': entry.is_new ? 'var(--color-blue)' : 'white',
              'opacity': isBuild ? '1' : '0',
              'width': 'calc(100% - 4px)',
              'margin': '0 2px',
              'z-index': entry.position.z_index}"
              (click)="selectionEntry(entry)">
           <label class="entrie_lbl_hover">{{entry.is_new ? 'Neuer Termin' : (isCreate ? ('Belegt: '+ entry.subject) : ((entry.start_time | date: 'HH:mm' ) + (', ' + entry.subject)))}}</label>
           <div *ngIf="!isCreate"
            [class.entry-hover-violet]="entry.is_customer"
            [class.entry-hover-teal]="!entry.is_customer"
            (click)="selectionEntry(entry)"
           ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="calendar_body" *ngIf="!showWeek">
    <div id="title_bar">
      <div id="y_achse" class="lbl_regular_italic_light_color">
        <div id="kw_container">
          <div>KW</div>
          <div>{{weekNumber}}</div>
        </div>
        <div id="time_container">
          <div *ngFor="let hour of hours" class="hour_row">{{hour}}:00</div>
        </div>
      </div>
      <div class="title_day_single">
        <div class="day_header">
          <div class="lbl_regular_italic_light_color">{{selectedEntrieDay.day | date: 'EEEE'}}</div>
          <div style="font-weight: bold;">{{selectedEntrieDay.day | date: 'dd. MMM'}}</div>
        </div>
        <div class="hour_row_day_container">
          <div *ngFor="let hour of hours" class="hour_row_day">
            <div class="add_button" (click)="addEntrie(selectedEntrieDay, hour)">+</div>
          </div>
        </div>
        <div *ngFor="let entrie of selectedEntrieDay?.entries; let i = index" [ngClass]="entrie.is_new == false ? 'entrie_occupied' : 'entrie_new'" [attr.id]="'entrie_'+i"
        [ngStyle]="{'top': getTopPosition(entrie.start_time, i), 'height': getEntrieHeight(entrie.start_time, entrie.end_time), 'width': getEntrieWidthDayView(entrie), 'transform': getEntrieTransformDayView(entrie)}">
          <div *ngIf="entrie.is_new == false" class="entrie_lbl">{{getEntrieLbl(entrie)}}</div>
          <div *ngIf="entrie.is_new == true" class="entrie_lbl_new">
            <div *ngIf="entrie.position.width_typ == 1 && heightAvailable(entrie)" style="margin-right: 5px;">Neuer Termin</div>
            <div class="entrie_new_icon_remove" (click)="removeEntrie(selectedEntrieDay.entries)">&#x2716;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="disabled" id="calender_disabled"></div>
</div>
<app-calendar-time-picker *ngIf="showTimePicker"
  [selectedEntrieDay]="selectedEntrieDay"
  [timePickerHour]="timePickerHour"
  [timePickerError]="timePickerError"
  (cancelEmitter)="cancelPicker()"
  (timeErrorResetEmitter)="timePickerError = false"
  (submitEmitter)="submitTimePicker($event)"
></app-calendar-time-picker>




<!-- <div id="calendar">
  <div id="calendar_header">
    <div class="btn_container">
      <img src="../../../../assets/arrow-dark.svg" style="margin-right: 10px; transform: rotate(90deg);">
      <div (click)="showWeek ? previousWeek() : null" style="color: black" class="lbl_regular_italic_light_color">{{showWeek ? 'letzte Woche' : 'letzter Tag'}}</div>
    </div>
    <div appCustomDateFormat2 style="width: 190px;">
      <input #dateInput1 matInput readonly (click)="picker.open()" class="inputfield" [ngStyle]="{'width': '60%'}" type="datetime" [min]="minDate" [matDatepicker]="picker" [(ngModel)]="selectedDate" (ngModelChange)="changeWeek()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <div class="btn_container">
      <div (click)="showWeek ? nextWeek() : null" style="color: black" class="lbl_regular_italic_light_color">{{showWeek ? 'nächste Woche' : 'nächster Tag'}}</div>
      <img src="../../../../assets/arrow-dark.svg"  style="margin-left: 10px; transform: rotate(-90deg);">
    </div>
  </div>
  <div id="calendar_body" *ngIf="showWeek && filledCalenderWeek.length > 0">
    <div id="title_bar">
      <div id="y_achse" class="lbl_regular_italic_light_color">
        <div id="kw_container">
          <div>KW</div>
          <div>{{weekNumber}}</div>
        </div>
        <div id="time_container">
          <div *ngFor="let hour of hours" class="hour_row">{{hour}}:00</div>
        </div>
      </div>
      <div *ngFor="let calendarDay of filledCalenderWeek; let d = index;" class="title_day_column" [ngClass]="calendarDay.is_today ? 'column_today' : (isOdd(d) ? 'odd' : '')">
        <div class="day_header">
          <div class="lbl_regular_italic_light_color">{{calendarDay.day | date: 'EEEE'}}</div>
          <div style="font-weight: bold;">{{calendarDay.day | date: 'dd. MMM'}}</div>
        </div>
        <div class="hour_row_day_container">
          <div *ngFor="let hour of hours" class="hour_row_day">
            <div *ngIf="isCreate && !isPastDate(calendarDay.day, hour) && isFreePlace(calendarDay, hour)" class="add_button" (click)="addEntrie(calendarDay, hour)">+</div>
          </div>
        </div>
        <div class="grid_entries">
          <div *ngFor="let entry of calendarDay.entries; let i = index" [ngClass]="isCreate ? 'grid_entrie' : 'grid_entrie_button'" [attr.id]="'entrie_'+i" (click)="entry.is_new ? removeEntrie(entry, d) : null"
            [ngStyle]="{
              'display': entry.position.column + 1 == 5 ? 'none' : '',
              'grid-column': entry.position.column + '/' + 4,
              'grid-row': (entry.position.row + 1) + '/' + entry.position.row_span,
              'background-color': entry.is_new ? '#F2F5FD' : 'var(--color-blue)',
              'color': entry.is_new ? 'var(--color-blue)' : 'white',
              'opacity': isBuild ? '1' : '0'}">
           <label *ngIf="isCreate">{{entry.is_new ? 'Neuer Termin' : (isCreate ? 'belegt' : entry.subject)}}</label>
           <label *ngIf="!isCreate">{{entry.subject}}</label>
            <div *ngIf="!isCreate" class="hover_details_wrapper" (click)="selectionEntry(entry)">
              <label style="cursor: pointer;">Details...</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="calendar_body" *ngIf="!showWeek">
    <div id="title_bar">
      <div id="y_achse" class="lbl_regular_italic_light_color">
        <div id="kw_container">
          <div>KW</div>
          <div>{{weekNumber}}</div>
        </div>
        <div id="time_container">
          <div *ngFor="let hour of hours" class="hour_row">{{hour}}:00</div>
        </div>
      </div>
      <div class="title_day_single">
        <div class="day_header">
          <div class="lbl_regular_italic_light_color">{{selectedEntrieDay.day | date: 'EEEE'}}</div>
          <div style="font-weight: bold;">{{selectedEntrieDay.day | date: 'dd. MMM'}}</div>
        </div>
        <div class="hour_row_day_container">
          <div *ngFor="let hour of hours" class="hour_row_day">
            <div class="add_button" (click)="addEntrie(selectedEntrieDay, hour)">+</div>
          </div>
        </div>
        <div *ngFor="let entrie of selectedEntrieDay?.entries; let i = index" [ngClass]="entrie.is_new == false ? 'entrie_occupied' : 'entrie_new'" [attr.id]="'entrie_'+i"
        [ngStyle]="{'top': getTopPosition(entrie.start_time, i), 'height': getEntrieHeight(entrie.start_time, entrie.end_time), 'width': getEntrieWidthDayView(entrie), 'transform': getEntrieTransformDayView(entrie)}">
          <div *ngIf="entrie.is_new == false" class="entrie_lbl">{{getEntrieLbl(entrie)}}</div>
          <div *ngIf="entrie.is_new == true" class="entrie_lbl_new">
            <div *ngIf="entrie.position.width_typ == 1 && heightAvailable(entrie)" style="margin-right: 5px;">Neuer Termin</div>
            <div class="entrie_new_icon_remove" (click)="removeEntrie(selectedEntrieDay.entries)">&#x2716;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="disabled" id="calender_disabled"></div>
</div>
<app-calendar-time-picker *ngIf="showTimePicker"
  [selectedEntrieDay]="selectedEntrieDay"
  [timePickerHour]="timePickerHour"
  [timePickerError]="timePickerError"
  (cancelEmitter)="cancelPicker()"
  (timeErrorResetEmitter)="timePickerError = false"
  (submitEmitter)="submitTimePicker($event)"
></app-calendar-time-picker> -->



<!-- <div id="calendar">
  <div id="calendar_header">
    <div class="btn_container">
      <img src="../../../../assets/arrow-dark.svg" style="margin-right: 10px; transform: rotate(90deg);">
      <div (click)="showWeek ? previousWeek() : previousDay()">{{showWeek ? 'letzte Woche' : 'letzter Tag'}}</div>
    </div>
    <div appCustomDateFormat2 style="width: 150px;">
      <input #dateInput1 matInput readonly (click)="picker.open()" class="inputfield" [ngStyle]="{'width': '60%'}" type="datetime" [min]="minDate" [matDatepicker]="picker" [(ngModel)]="selectedDate" (ngModelChange)="changeWeek()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <div class="btn_container">
      <div (click)="showWeek ? nextWeek() : nextDay()">{{showWeek ? 'nächste Woche' : 'nächster Tag'}}</div>
      <img src="../../../../assets/arrow-dark.svg"  style="margin-left: 10px; transform: rotate(-90deg);">
    </div>
  </div>
  <div id="calendar_body" *ngIf="showWeek">
    <div id="title_bar">
      <div id="y_achse" class="lbl_regular_italic_light_color">
        <div id="kw_container">
          <div>KW</div>
          <div>{{weekNumber}}</div>
        </div>
        <div id="time_container">
          <div *ngFor="let hour of hours" class="hour_row">{{hour}}:00</div>
        </div>
      </div>
      <div *ngFor="let calendarDay of calendarWeek; let d = index;" class="title_day_column" [ngClass]="isOdd(d) ? 'odd' : ''">
        <div class="day_header">
          <div class="lbl_regular_italic_light_color">{{calendarDay.day | date: 'EEEE'}}</div>
          <div style="font-weight: bold;">{{calendarDay.day | date: 'dd. MMM'}}</div>
        </div>
        <div class="hour_row_day_container">
          <div *ngFor="let hour of hours" class="hour_row_day">
            <div class="add_button" (click)="addEntrie(calendarDay, hour)">+</div>
          </div>
        </div>
        <div class="grid_entries">
          <div *ngFor="let entrie of calendarDay.entries; let i = index" class="grid_entrie" [attr.id]="'entrie_'+i"
          [ngStyle]="{'display': entrie.position.column + 1 == 5 ? 'none' : '', 'grid-column': entrie.position.column + '/' + (entrie.position.column + 1), 'grid-row': (entrie.position.row + 1) + '/' + entrie.position.row_span}">

          </div>
        </div>
        <div *ngFor="let entrie of calendarDay.entries; let i = index" [ngClass]="entrie.is_new == false ? 'entrie_occupied' : 'entrie_new'" [attr.id]="'entrie_'+i"
        [ngStyle]="{'top': getTopPosition(entrie.start_time, i)+'px', 'height': getEntrieHeight(entrie.start_time, entrie.end_time)+'px', 'width': getEntrieWidth(entrie), 'transform': getEntrieTransform(entrie)}">
          <div *ngIf="entrie.is_new == false" class="entrie_lbl">{{getEntrieLbl(entrie)}}</div>
          <div *ngIf="entrie.is_new == true" class="entrie_lbl_new">
            <div *ngIf="entrie.position.width_typ == 1 && heightAvailable(entrie)" style="margin-right: 5px;">Neuer Termin</div>
            <div class="entrie_new_icon_remove" (click)="removeEntrie(calendarDay.entries)">&#x2716;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="calendar_body" *ngIf="!showWeek">
    <div id="title_bar">
      <div id="y_achse" class="lbl_regular_italic_light_color">
        <div id="kw_container">
          <div>KW</div>
          <div>{{weekNumber}}</div>
        </div>
        <div id="time_container">
          <div *ngFor="let hour of hours" class="hour_row">{{hour}}:00</div>
        </div>
      </div>
      <div class="title_day_single">
        <div class="day_header">
          <div class="lbl_regular_italic_light_color">{{selectedEntrieDay.day | date: 'EEEE'}}</div>
          <div style="font-weight: bold;">{{selectedEntrieDay.day | date: 'dd. MMM'}}</div>
        </div>
        <div class="hour_row_day_container">
          <div *ngFor="let hour of hours" class="hour_row_day">
            <div class="add_button" (click)="addEntrie(selectedEntrieDay, hour)">+</div>
          </div>
        </div>
        <div *ngFor="let entrie of selectedEntrieDay?.entries; let i = index" [ngClass]="entrie.is_new == false ? 'entrie_occupied' : 'entrie_new'" [attr.id]="'entrie_'+i"
        [ngStyle]="{'top': getTopPosition(entrie.start_time, i), 'height': getEntrieHeight(entrie.start_time, entrie.end_time), 'width': getEntrieWidthDayView(entrie), 'transform': getEntrieTransformDayView(entrie)}">
          <div *ngIf="entrie.is_new == false" class="entrie_lbl">{{getEntrieLbl(entrie)}}</div>
          <div *ngIf="entrie.is_new == true" class="entrie_lbl_new">
            <div *ngIf="entrie.position.width_typ == 1 && heightAvailable(entrie)" style="margin-right: 5px;">Neuer Termin</div>
            <div class="entrie_new_icon_remove" (click)="removeEntrie(selectedEntrieDay.entries)">&#x2716;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-calendar-time-picker *ngIf="showTimePicker"
  [selectedEntrieDay]="selectedEntrieDay"
  (cancelEmitter)="cancelPicker()"
  (submitEmitter)="submitTimePicker($event)"
></app-calendar-time-picker> -->
