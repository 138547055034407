import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-drive-navigation',
  templateUrl: './drive-navigation.component.html',
  styleUrls: ['./drive-navigation.component.css']
})
export class DriveNavigationComponent implements OnInit {
  @Input() currentPageCreate: number;
  @Input() form: FormGroup;
  public pagesTotalAry = [1,2];
  @Output() changePageEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  getDotIcon(page: number): string {
    if(page == this.currentPageCreate){
      return '../../../../../assets/dot_full.png';
    }
    return '../../../../../assets/dot_empty.png';
  }

  nextPage() {
    if(this.currentPageCreate <= 2){
      this.changePageEmitter.emit(true);
    }
  }

  disabled(): boolean {
    if(this.form.get('drive_typ').value == 1){
      return this.form.controls.vehicle.invalid || this.form.controls.start_time.invalid || this.form.controls.end_time.invalid;
    } else if(this.form.get('drive_typ').value == 2) {
      return this.form.controls.vehicle.invalid || this.form.controls.start_time.invalid || this.form.controls.end_time.invalid || this.form.get('trip_description').invalid || this.form.get('employee').invalid;
    }
  }

  previousPage() {
    if(this.currentPageCreate > 1){
      this.changePageEmitter.emit(false);
    }
  }
}
