<div class="header-base-style">
    <div class="header-inner-wrapper">
        <button class="button-icon-start" (click)="selectionCancel()">
        <img class="button-icon-start-img" src="./assets/cancle.svg"/>
        <span class="button-icon-start-span">Abbrechen</span>
        </button>
        <h2>Reifen {{obj ? 'bearbeiten' : 'anlegen'}}</h2>
    </div>
    <div class="header-button-wrapper" *ngIf="!showCreateChoise">
      <app-restricted-button 
        *ngIf="!obj"
        [name]="'Anlegen und montieren'" 
        [access]="'tire_edit'" 
        [isSecondary]="true" 
        (clickEmitter)="selectionCreateAndInstallation()">
      </app-restricted-button>
      <app-restricted-button 
        *ngIf="!obj"
        [name]="'Anlegen und einlagern'" 
        [access]="'tire_edit'" 
        [isPrimary]="true" 
        (clickEmitter)="selectionCreateAndStore()">
      </app-restricted-button>
      <app-restricted-button 
        *ngIf="obj"
        [name]="'Speichern'" 
        [access]="'tire_edit'" 
        [isPrimary]="true" 
        (clickEmitter)="selectionSave()">
      </app-restricted-button>
    </div>
</div>
<form id="drive-create-body" class="body-base-style" [formGroup]="form" *ngIf="form && !showCreateChoise">
    <div class="column">
        <div class="overview_data_box">
            <h3>Allgemein</h3>
            <app-dropdown-multiple
                [title]="'Ausführung'"
                [formcontrol]="form.controls['execution']"
                [headerText]="form.controls['execution'].value ? form.controls['execution'].value : '---'"
                [values]="executionValues"
                [isOptional]="false"
                [submitted]="submitted"
                [isHighlighted]="true"
                (submitValueEmitter)="form.get('execution').setValue(executionValues[$event-1])"
            ></app-dropdown-multiple>
            <div class="input_wrapper">
                <label class="overview_record_lbl">Anzahl</label>
                <input type="number" min="1" max="8" placeholder="---" formControlName="amount">
            </div>
            <app-dropdown-objects-search
              [title]="'Stammfahrzeug'"
              [placeholder]="'---'"
              [headervalue]="form.controls['purchased_with_vehicle']?.value?.getVehicleName()"
              [formcontrol]="form.controls['purchased_with_vehicle']"
              [values]="vehicleValues"
              (submitValueEmitter)="setValuePurchaseVehicle($event)"
            ></app-dropdown-objects-search>
            <app-dropdown-objects-search
                  [title]="'Marke'"
                  [headervalue]="form.get('vehicle_make').value?.makeName"
                  [formcontrol]="form.get('vehicle_make')"
                  [values]="brandValues"
                  [submitted]="submitted"
                  (submitValueEmitter)="setValueFromDropdown('vehicle_make', $event)"
            ></app-dropdown-objects-search>


            <!-- <app-dropdown-enum
              [name]="'Marke'"
              [placeholder]="'---'"
              [control]="form.get('tire_brand')"
              [enumName]="'tire_brand'"
              [submitted]="submitted"
              (submitValueEmitter)="form.get('tire_brand').setValue($event);"
            ></app-dropdown-enum> -->
            <div class="input_wrapper">
              <label class="overview_record_lbl">Modell</label>
              <input type="text" placeholder="---" formControlName="tire_model">
            </div>
        </div>

        <div class="overview_data_box">
            <h3>Verkauf</h3>
            <app-dropdown-simple
              [title]="'Für den Verkauf gesperrt'"
              [formcontrol]="form.get('not_for_sale')"
              (submitValueEmitter)="form.get('not_for_sale').setValue($event)"
            ></app-dropdown-simple>
            <div class="input_wrapper" [class.input_disabled]="form.get('not_for_sale').value == true">
              <label class="overview_record_lbl">Preis (brutto)</label>
              <input type="number" min="0" placeholder="---" formControlName="tire_price">
            </div>
            <app-dropdown-simple [class.input_disabled]="form.get('not_for_sale').value == true"
              [title]="'Export Plattformen'"
              [formcontrol]="form.get('platform_online')"
              (submitValueEmitter)="form.get('platform_online').setValue($event)"
            ></app-dropdown-simple>
        </div>

        <div class="overview_data_box">
            <h3>Beschreibung</h3>
            <textarea appTextfieldAutoresize
              class="textfield textarea_description"
              placeholder="---"
              formControlName="description"
            ></textarea>
        </div>
        <app-images-web
          [design]="1"
          [obj]="obj"
          [showEdit]="true"
          [form]="form"
          [updateSuccess]="null"
        ></app-images-web>
    </div>

    <div class="column">
        <div class="overview_data_box" [class.data_box_disabled]="form.get('execution').value == null || form.get('execution').value == execution.FELGEN">
            <h3>Reifen</h3>
            <app-dropdown-multiple
                [title]="'Typ'"
                [formcontrol]="form.get('tire_type')"
                [headerText]="tireTypValue"
                [values]="tireTypValues"
                [isOptional]="false"
                [submitted]="submitted"
                (submitValueEmitter)="form.get('tire_type').setValue($event); getEnumData();"
            ></app-dropdown-multiple>
            <app-dropdown-strings
              [title]="'Marke'"
              [placeholder]="'---'"
              [formcontrol]="form.get('tire_brand_name')"
              [headerText]="rimBrandValue"
              [values]="rimBrandValues"
              [isOptional]="false"
              [submitted]="submitted"
              (submitValueEmitter)="form.get('tire_brand_name').setValue($event); getEnumDataOnChange();"
            ></app-dropdown-strings>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Modell</label>
              <input type="text" placeholder="---" formControlName= "rim_model">
            </div>
            <div class="input_wrapper">
                <label class="overview_record_lbl">Breite</label>
                <div class="input_wrapper_multiple">
                  <input type="number" placeholder="---" formControlName="tire_width_1">
                  <input type="number" placeholder="---" formControlName="tire_width_2">
                </div>
            </div>
            <div class="input_wrapper">
                <label class="overview_record_lbl">Höhe</label>
                <div class="input_wrapper_multiple">
                  <input type="number" placeholder="---" formControlName="tire_height_1">
                  <input type="number" placeholder="---" formControlName="tire_height_2">
                </div>
            </div>
            <div class="input_wrapper">
                <label class="overview_record_lbl">Zoll</label>
                <div class="input_wrapper_multiple">
                  <input type="number" placeholder="---" formControlName="tire_inches_1">
                  <input type="number" placeholder="---" formControlName="tire_inches_2">
                </div>
            </div>
            <div class="input_wrapper">
                <label class="overview_record_lbl">Index</label>
                <div class="input_wrapper_multiple">
                  <input type="text" placeholder="---" formControlName="tire_index_1">
                  <input type="text" placeholder="---" formControlName="tire_index_2">
                </div>
            </div>
            <app-dropdown-simple
              [title]="'Reifendrucksensor'"
              [formcontrol]="form.get('tire_pressure_sensor')"
              (submitValueEmitter)="form.get('tire_pressure_sensor').setValue($event)"
            ></app-dropdown-simple>
        </div>

        <div class="overview_data_box" [class.data_box_disabled]="form.get('execution').value == null || form.get('execution').value == execution.FELGEN">
            <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
              <h3>Reifen Details</h3>
            </div>
            <div class="input_wrapper" *ngIf="form.get('details').value.length == 4">
              <label>Vorder- und Hintereifen ident</label>
              <div class="slider_container" style="margin-right: 10px;" (click)="changeDetails()">
                <div class="slider_still" [ngClass]="selectedCheckbox ? 'slider_still_on' : ''"></div>
                <div class="slider_move" [ngClass]="selectedCheckbox ? 'slider_on' : 'slider_off'"></div>
              </div>
            </div>
            <form class="input_wrapper" *ngFor="let formDetail of form.get('details').value; let i = index"
              [formGroup]="formDetail"
              [ngStyle]="{'display': (form.get('details').value.length == 4 && formDetail.get('tyres_equal').value && i%2!=0) ? 'none': 'grid'}">
                <label class="overview_record_lbl">{{getFormDetailsTitle(formDetail, i)}}</label>
                <div class="input_wrapper_multiple">
                    <input type="number" placeholder="---" formControlName="depth" [class.input_invalid]="(form.get('execution').value != null && form.get('execution').value != execution.FELGEN) && submitted && formDetail.get('depth').invalid">
                    <input type="text" placeholder="---" formControlName="year" [class.input_invalid]="(form.get('execution').value != null && form.get('execution').value != execution.FELGEN) && submitted && formDetail.get('year').invalid">
                </div>
            </form>
        </div>
    </div>

    <div class="column">
        <div class="overview_data_box" [class.data_box_disabled]="form.get('execution').value == null || form.get('execution').value == execution.REIFEN">
            <h3>Felgen</h3>
            <app-dropdown-multiple
                [title]="'Typ'"
                [formcontrol]="form.get('rim_type')"
                [headerText]="rimTypValue"
                [values]="rimTypValues"
                [isOptional]="false"
                [submitted]="(form.get('execution').value != null && form.get('execution').value != execution.REIFEN) && submitted"
                (submitValueEmitter)="form.get('rim_type').setValue($event); getEnumData();"
            ></app-dropdown-multiple>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Breite</label>
              <div class="input_wrapper_multiple">
                <input type="number" placeholder="---" formControlName="rim_width_1">
                <input type="number" placeholder="---" formControlName="rim_width_2">
              </div>
          </div>
          <div class="input_wrapper">
              <label class="overview_record_lbl">Zoll</label>
              <div class="input_wrapper_multiple">
                <input type="number" placeholder="---" formControlName="rim_inches_1">
                <input type="number" placeholder="---" formControlName="rim_inches_2">
              </div>
          </div>
          <div class="input_wrapper">
              <label class="overview_record_lbl">ET</label>
              <div class="input_wrapper_multiple">
                <input type="number" placeholder="---" formControlName="rim_et_1">
                <input type="number" placeholder="---" formControlName="rim_et_2">
              </div>
          </div>
            <div class="input_wrapper">
                <label class="overview_record_lbl">Schrauben</label>
                <input type="text" placeholder="---" formControlName="rim_screws">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Lochkreis</label>
              <input type="number" placeholder="---" formControlName="rim_bolt_circle">
            </div>
            <div class="input_wrapper">
                <label class="overview_record_lbl">Herst. Art. Nr.</label>
                <input type="text" placeholder="---" formControlName="rim_external_id">
            </div>
        </div>
    </div>

</form>

<app-dialog-tyres-vehicle-installation *ngIf="showDialogInstallation"
  [form]="form"
  [vehicles]="freeVehicles"
  [autoCompleteVehicle]="form.get('purchased_with_vehicle').value"
  (selectionCloseEmitter)="selectionCloseInstallation()"
  (selectionSubmitEmitter)="selectionSubmit($event)"
></app-dialog-tyres-vehicle-installation>

<app-dialog-tyres-choose-location *ngIf="showDialogStore"
  [locations]="locationsChoose"
  (selectionCloseEmitter)="selectionCloseChooseLocation()"
  (submitLocationEmitter)="submitLocation($event)"
></app-dialog-tyres-choose-location>
