<div class="list_container">
    <app-list-admin-title
      [isTitle]="true"
      [pageInfo]="pageInfo"
      [sliceTo]="sliceTo"
      [sliceFrom]="sliceFrom"
      [amount]="employees.length"
      (pageEmitter)="pageEmitter.emit($event)"
    ></app-list-admin-title>
    <div class="list_element_container">
      <div *ngFor="let employee of employees | slice:sliceFrom:sliceTo">
        <app-list-admin-element *ngIf="surface == 1 && employee.active || surface == 6 && !employee.active"
          [employee]="employee"
          (openDetailsEmitter)="openDetailsEmitter.emit($event)"
          (openEditEmitter)="openEditEmitter.emit($event)"
          (deleteEmitter)="deleteEmitter.emit($event)"
      ></app-list-admin-element>
      </div>
    </div>
    <div class="list_footer"></div>
</div>
