import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RentForm } from 'src/app/buisness-object/rent/form/RentForm';
import { Rent } from 'src/app/buisness-object/rent/Rent';


@Component({
  selector: 'app-dialog-miete-cancel',
  templateUrl: './dialog-miete-cancel.component.html',
  styleUrl: './dialog-miete-cancel.component.css'
})
export class DialogMieteCancelComponent implements OnInit {

  @Output() selectionCloseEmitter = new EventEmitter();
  @Output() selectionCancelEmitter = new EventEmitter<any>();

  ngOnInit(): void {
  }

}
