import { RecordContainer } from "./RecordContainer";

export class OverviewConfiguration {
    public recordContainer: RecordContainer[] = [];
    public isSelected = false;

    constructor(
        public id: number,
        public configuration_name: string,
    ){}

    render() {
        if(this.recordContainer.length > 0 ){
            this.recordContainer.sort((a,b) => a.position - b.position);
        }
    }
}