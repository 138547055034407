import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-create-document',
  templateUrl: './dialog-create-document.component.html',
  styleUrls: ['./dialog-create-document.component.css']
})
export class DialogCreateDocumentComponent implements OnInit, OnDestroy {
  public show = false;
  public data: any;
  public dataTyp = 0;
  public subscription: Subscription;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openDialogCreateDoc$.subscribe((data: any) => {
      if(data){
        this.show = true;
        this.data = data.data;
        if(this.data instanceof Vehicle) this.dataTyp = 1;
        else if(this.data instanceof Customer) this.dataTyp = 2;
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  submit(typ: number) {
    this.dialogService.closeCreateDoc(
      {
        submit_value: typ,
        data: this.data
      }
    );
    this.show = false;
  }
}
