import { Component, OnInit, Input, OnDestroy,Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from '../../BaseComponent';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { formatDate } from '@angular/common';
import { PriceSheetCalculater } from 'src/app/buisness-object/pricesheet/PriceSheetCalculater';
import { Subscription } from 'rxjs';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

const INPUTTIMEOUT = 800;

@Component({
  selector: 'app-price-sheets-create',
  templateUrl: './price-sheets-create.component.html',
  styleUrls: ['./price-sheets-create.component.css']
})
export class PriceSheetsCreateComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Output() onPriceSheetChangesEmitter = new EventEmitter<undefined>();
  @Output() selectionEditSteuersatzAcceptedEmitter = new EventEmitter<undefined>();
  @Output() addReparaturEmitter = new EventEmitter<undefined>();
  @Output() removeReparaturEmitter = new EventEmitter<number>();
  public dialogQuerySubsription;
  public dialogValueSubscription;
  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public inputTimeout;
  public formSub: Subscription;
  public paperStatusValues = []
  public paperStatusValue;
  public fahrzeuglieferantenValues = [];
  public speditionValues = [];
  public finanzsperrauskunftValues = [];
  public finanzsperrauskunftValue = [];

  constructor(
    private dService: DialogService,
    public authService: AuthenticationService,
    private eService: EnumService 
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.paperStatusValues = this.getEnumValues('paper_status');
    this.paperStatusValue = this.getEnumValue('paper_status', this.vehicle.paper_status);
    this.fahrzeuglieferantenValues = this.getEnumValues('vehicle_supplier');
    this.fahrzeuglieferantenValues?.sort((a,b) => a.localeCompare(b));
    this.finanzsperrauskunftValues = this.getEnumValues('finanzsperrauskunft');
    this.finanzsperrauskunftValue = this.getEnumValue('finanzsperrauskunft', this.vehicle.finanzsperrauskunft);
    this.speditionValues = this.getEnumValueNames('spedition');
    this.dialogSubscription();
    this.form.controls["vorsteuer"].setValue(PriceSheetCalculater.getVorSteuerDifferenz(this.form));
    this.formSub = this.form.valueChanges.subscribe((changes) => {
      this.onPriceSheetChangesEmitter.emit();
      this.formSub.unsubscribe();
    })
  }

  getEnumDataOnChange() {
    this.paperStatusValue = this.getEnumValue('paper_status', this.form.controls['paper_status'].value);
    this.finanzsperrauskunftValue = this.getEnumValue('finanzsperrauskunft', this.form.controls['finanzsperrauskunft'].value);
  }

  isVomAusland(): boolean {
    return PriceSheetCalculater.isVomAusland(this.form, this.authService.isStandard());
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.dialogValueSubscription) this.dialogValueSubscription.unsubscribe();
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    if(this.formSub) this.formSub.unsubscribe();
  }

  dialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'change_steuersatz') this.selectionEditSteuersatzAcceptedEmitter.emit();
      }
    });
    this.dialogValueSubscription = this.dService.closeDialogValue$.subscribe((value) => {
      if(value){
        if(value.identifier == 'zinssatz_vor') this.form.controls['zinssatz_vor'].setValue(value.submit_value);
        else if(value.identifier == 'garantie_von_einkaufspreis') this.form.controls['garantie_von_einkaufspreis'].setValue(value.submit_value);
        else if(value.identifier == 'standzeitkosten_je_tag_vor') this.form.controls['standzeitkosten_je_tag_vor'].setValue(value.submit_value);
      }
    })
  }

  selectionEditSteuersatz() {
    this.dService.openQuery(
      {
        title: 'Steuersatz tauschen?',
        message: 'Sind Sie sicher, dass Sie den Steuersatz tauschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Steuersatz tauschen',
        typ: 'change_steuersatz',
      }
    );
  }

  selectionEditValue(data: any, identifier: string) {
    let title = "";
    if(identifier == "zinssatz_vor") title = "Zinssatz tauschen?";
    else if(identifier == "garantie_von_einkaufspreis") title = "Garantie in % vom Einkaufspreis tauschen?";
    else if(identifier == "standzeitkosten_je_tag_vor") title = "Standzeitkosten je Tag tauschen?";
    this.dService.openValue(
      {
        title: title,
        form_value: data,
        btn_cancel_txt: "Abbrechen",
        btn_submit_txt: "Speichern",
        identifier: identifier
      }
    );
  }

  setSteuersatz(value: number) {
    this.form.controls['steuersatz'].setValue(value);
    if(this.form.controls['steuersatz_nach'].value == null) this.form.controls['steuersatz_nach'].setValue(value)
    else {
      if(this.form.controls['steuersatz_nach'].value != 2 || this.form.controls['steuersatz_nach'].value != 4) {
        this.form.controls['finanzsperrauskunft'].setValue(false);
      }
    }
  }
}
