import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit, OnDestroy, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Attachment } from 'src/app/buisness-object/attachment/Attachment';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';

@Component({
  selector: 'app-drive-page-three',
  templateUrl: './drive-page-three.component.html',
  styleUrls: ['./drive-page-three.component.css']
})
export class DrivePageThreeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() customers: Customer[];
  @Input() submitted: boolean;

  public customersFiltered: Customer[] = [];
  public selectedCustomer: Customer;
  public showDropdownOne = false;
  @ViewChild('input_customer') input_customer: ElementRef;
  public listener: () => void;

  public onloadSrc: any[] = [];
  public uploadProgress = 0;
  public currentFile = 0;
  public filesAmount = 0;

  public sources: {
    name: string,
    format: string,
    data: string
  }[] = [];

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private aService: AttachmentService
  ) { }

  ngOnInit(): void {
    this.customersFiltered = this.customers;
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showDropdownOne && !this.input_customer.nativeElement.contains(e.target)) this.showDropdownOne = false;
    })
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  get f() {
    return this.form.controls;
  }

  filterCustomers(value: string) {
    this.selectedCustomer = null;
    if(value && value.length > 0){
      this.selectedCustomer = null;
      this.form.controls.customer.setValue(null);
      this.customersFiltered = [];
      this.customersFiltered = this.customers.filter((customer) => {
        return customer.contactPerson.firstName.toLowerCase().includes(value.toLowerCase()) ||
               customer.contactPerson.lastName.toLowerCase().includes(value.toLowerCase()) ||
               (customer.contactPerson.firstName.toLowerCase() + ' ' + customer.contactPerson.lastName.toLowerCase()).includes(value.toLowerCase()) ||
               customer.companyName?.toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.customersFiltered = this.customers;
    }
  }

  setCustomer(customer: Customer) {
    this.selectedCustomer = customer;
    this.form.controls.customer.setValue(customer);
    this.input_customer.nativeElement.value = customer.contactPerson.firstName + ' ' + customer.contactPerson.lastName;
  }

  gotoCustomerCreate() {
    this.router.navigate(['customers/create'])
  }

  async uploadFile(event: any) {
    this.onloadSrc = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
        await this.readFile(event.target.files[i])
      }
      event.target.value = '';
    }
  }
  async readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.sources.push({
          name: file.name.split('.')[0],
          format: file.name.split('.')[1],
          data: event.target.result.split(',')[1]
        })
        this.form.controls.attachments.setValue(this.sources)
        resolve(true)
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  async onFileDropped(data: any) {
    if(data && data.data && data.data[0]){
      this.sources.push({
        name: data.data[0].name.split('.')[0],
        format: data.data[0].name.split('.')[1],
        data: data.data[0].content.split(',')[1]
      })
      this.form.controls.attachments.setValue(this.sources)
    }
  }


  removeAttachement(index: number) {
    this.sources.splice(index, 1);
    this.form.controls.attachments.setValue(this.sources);
  }

  openDialogRemoveAttachement(soso) {

  }
}
