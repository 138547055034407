import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { OpenCloseDropDown } from 'src/app/helpers/Animations';

@Component({
  selector: 'app-dropdown-select-container',
  templateUrl: './dropdown-select-container.component.html',
  styleUrls: ['./dropdown-select-container.component.css'],
  animations: [OpenCloseDropDown]
})
export class DropdownSelectContainerComponent implements OnInit, OnChanges, OnDestroy {
  public openDropdown = false;
  public valueSelected: boolean;
  public title = '';
  public placeholderTemp = '';

  @Input() placeholder: string;
  @Input() placeholderItalic: boolean;
  @Input() textTransform: string;
  @Input() options: string[];
  @Input() borderBottomColor: string;
  @Input() dropOptionHover: string;
  @Input() invalid: boolean;
  @Input() value: string;
  @Input() changedOptionValues: boolean;
  @Input() disabled: boolean;
  @Input() dropDownWith: string;

  @Output() selectEmitter = new EventEmitter<string>()

  @ViewChild('dropdownInputContainer') dropdownInputContainer: ElementRef;
  @ViewChild('dropdownInput') dropdownInput: ElementRef;
  @ViewChild('dropdownImg') dropdownImg: ElementRef;
  @ViewChild('dropdownOptionsContainer') dropdownOptionsContainer: ElementRef;

  public listenerFn: () => void;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    this.placeholderTemp = this.placeholder;
    this.title = this.placeholder;
    if(this.value){
      this.placeholder = this.value;
      this.valueSelected = true;
      this.disabled = false;
    }
    this.setCloseListener();
  }

  ngOnChanges() {
    if(this.changedOptionValues){
      this.placeholder = this.placeholderTemp;
    }
  }

  ngOnDestroy() {
    if(this.listenerFn){
      this.listenerFn();
    }
  }

  setCloseListener() {
    this.listenerFn = this.renderer.listen('window','click', (e:Event) => {
      if(this.openDropdown){
        if(e.target != this.dropdownInputContainer.nativeElement && 
          e.target != this.dropdownImg.nativeElement && 
          e.target != this.dropdownInput.nativeElement && 
          e.target != this.dropdownOptionsContainer.nativeElement){
          this.openDropdown = false;
        }
      }
    });
  }

  setOption(opt: string) {
    this.placeholder = opt;
    this.valueSelected = true;
    this.selectEmitter.emit(opt);
  }
}
