import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BaseBoxComponent } from 'src/app/view/BaseBoxComponent';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-seasonal',
  templateUrl: './seasonal.component.html',
  styleUrls: ['./seasonal.component.css'],
  animations: [DropdownIconArrowAnimation],
})
export class SeasonalComponent extends BaseBoxComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  constructor(public authService: AuthenticationService, private eService: EnumService) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.viewTitle = "seasonal";
    this.init();
  }


  get f() {
    return this.form.controls;
  }
}
