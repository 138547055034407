<div class="car_create_page_wrapper">
    <div class="title">Zusatzinformationen</div>
    <form id="input-container" [formGroup]="formGroup" autocomplete="off">
        <div id="container1">
            <div class="block">
                <h3 class="inner_title">Interieur</h3>
                <app-dropdown-objects
                  [title]="'Interieur Material'"
                  [placeholder]="'Interieur Material'"
                  [headervalue]="f['upholstery'].value?.name"
                  [formcontrol]="f['upholstery']"
                  [values]="upholsteryValues"
                  [submitted]="submitted"
                  (submitValueEmitter)="setValueFromDropdown('upholstery', $event)"
                ></app-dropdown-objects>
                <app-dropdown-objects
                  [title]="'Interieur Farbe'"
                  [placeholder]="'Interieur Farbe'"
                  [headervalue]="f['upholsteryColor'].value?.name"
                  [formcontrol]="f['upholsteryColor']"
                  [values]="upholsteryColorValues"
                  [submitted]="submitted"
                  (submitValueEmitter)="setValueFromDropdown('upholsteryColor', $event)"
                ></app-dropdown-objects>
                <app-dropdown-simple
                  [title]="'Raucherfahrzeug'"
                  [formcontrol]="f['nonSmoking']"
                  (submitValueEmitter)="f['nonSmoking'].setValue($event)"
                ></app-dropdown-simple>
            </div>
        </div>
        <div id="container2">
          <div class="block">
            <h3 class="inner_title">Gewicht</h3>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Eigengewicht in kg</label>
              <input type="number" class="overview_input" placeholder="Eigengewicht in kg" formControlName="ownWeight">
            </div>
            <div class="input_wrapper">
                <label class="overview_record_lbl">Gesamtgewicht in kg</label>
                <input type="number" class="overview_input" placeholder="Gesamtgewicht in kg" formControlName="totalWeight">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Kofferraumvolumen (in l)</label>
              <input type="number" class="overview_input" placeholder="Kofferraumvolumen (in l)" formControlName="luggage_space">
            </div>
          </div>
        </div>
        <div id="container3">
          <div class="block">
            <h3 class="inner_title">Leistungen</h3>
            <app-dropdown-simple
              [title]="'MwSt. ausweisbar'"
              [formcontrol]="f['vat_reportable']"
              (submitValueEmitter)="f['vat_reportable'].setValue($event)"
            ></app-dropdown-simple>
            <app-dropdown-simple
              [title]="'Leasingfähig'"
              [formcontrol]="f['leasable']"
              (submitValueEmitter)="f['leasable'].setValue($event)"
            ></app-dropdown-simple>
          </div>
        </div>
    </form>
</div>
