<div id="dialog_window">
  <div id="dialog_container" appDropdown>
      <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
      <h1>Reifen #{{obj.tire_id}} einlagern</h1>
      <div id="dialog_conainter_body">
        <label>Bitte geben Sie den Reifen {{name}} auf den folgenden Lagerplatz:</label>
        <label class="dialog_storage_number">{{obj.storage_place?.place_name}}</label>
      </div>
      <div class="dialog_btn_container">
          <button class="btn_submit_dark" (click)="selectionCloseEmitter.emit()">Erledigt</button>
      </div>
  </div>
</div>
