export class Picture {
  constructor(
      public picture_id: number,
      public position: number,
      public obj_id: number,
      public src: string
  ){}

  getJSON(): any {
    return {
      tire_id: this.obj_id,
      picture_id: this.picture_id,
      position: this.position
    };
  }
}
