// export function getEnumLabels<T extends { [key: string]: string | number }>(enumObj: T, labels: { [key in T[keyof T]]: string }
// ): { [key in T[keyof T]]: string } {
//   return Object.keys(enumObj)
//     .filter((key) => isNaN(Number(key)))
//     .reduce((acc, key) => {
//       const enumValue = enumObj[key as keyof T];
//       acc[enumValue as keyof typeof acc] = labels[enumValue as keyof typeof labels];
//       return acc;
//     }, {} as { [key in T[keyof T]]: string });
// }

// export function getLabelToEnum<T extends { [key: string]: string | number }>(
//   enumObj: T,
//   enumLabels: { [key: string]: string }
// ): { [key: string]: T[keyof T] } {
//   return Object.entries(enumLabels).reduce(
//     (acc, [key, label]) => {
//       if (key in enumObj) {
//         acc[label] = enumObj[key as keyof T];
//       }
//       return acc;
//     },
//     {} as { [key: string]: T[keyof T] }
//   );
// }

export function getEnumFromLabel<T extends number | string>(
  label: string,
  enumLabels: { [key in T]: string }
): T {
  const foundKey = (Object.keys(enumLabels) as Array<keyof typeof enumLabels>).find(
    key => enumLabels[key] === label
  );
  if(foundKey === undefined) {
    throw new Error(`Label "${label}" missing in enum`);
  }
  return Number(foundKey) as T;
}

export function getLabelFromEnumValue<T extends number | string>(
  enumValue: T,
  enumLabels: { [key in T]: string }
): string {
  return enumLabels[enumValue];
}

// export function getEnumLabel<T extends { [key: string]: string | number }>(status: T[keyof T], labels: { [key in T[keyof T]]: string }): string {
//   return labels[status];
// }

export function getEnumLabelValues<T extends number | string>(
  enumObj: { [key: string]: T },
  enumLabels: { [key in T]: string }
): string[] {
  return Object.values(enumObj)
    .filter(value => typeof value === 'number')
    .map(value => enumLabels[value as T]);
}
