export class Location {
    constructor(
        public locationId: number,
        public street: string,
        public postCode: string,
        public city: string,
        public mandantId: number,
        public active: boolean,
        public name: string,
    ){}
}
