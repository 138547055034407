import { Observable, of } from 'rxjs';
import { DialogService } from './../dialog/dialog.service';
import { LoginService } from 'src/app/service/login/login.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { environment } from 'src/environments/environment';
import { DriveFactory } from 'src/app/buisness-object/drives/factory/DriveFactory';
import { catchError, map } from 'rxjs/operators';
import { DrivePDF, DrivePFDFactory } from 'src/app/buisness-object/drives/DrivePdf';

@Injectable({
  providedIn: 'root'
})
export class DrivesService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) {}

  public getTestDrives(): Observable<Drive[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/testDrive', { headers });
    return observable.pipe(
      map((result: any) => {
        return DriveFactory.jsonFactory(result.test_drives);
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createDrive(drive: Drive): Observable<Drive> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = drive.getJSON();
    const observable = this.http.post(environment.api + '/back/testdrive', body, { headers });
    return observable.pipe(
      map((result: any) => {
        return DriveFactory.jsonFactoryOne(result.test_drive);
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateDrive(drive: Drive): Observable<Drive> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = {
      "test_drive_id": drive.test_drive_id,
      "customer_id": drive.customer_id,
      "vehicle_id": drive.vehicle_id,
      "duration": drive.duration,
      "timestamp" : drive.timestamp,
      "license_plate_id" : drive.license_plate_id,
      "trip_description" : drive.trip_description,
      "authorization_authority" : drive.authorization_authority,
      "authorization_back_image" : drive.authorization_back_image,
      "authorization_date" : drive.authorization_date,
      "authorization_front_image" : drive.authorization_front_image,
      "authorization_groups" : drive.authorization_groups,
      "authorization_number" : drive.authorization_number,
    }
    const observable = this.http.put(environment.api + '/back/testdrive', body, { headers });
    return observable.pipe(
      map((result: any) => {
        return DriveFactory.jsonFactoryOne(result.test_drive);
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getDrivePDF(drive_id: number): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/testdrive/pdf/'+drive_id, { headers, responseType: 'arraybuffer' });
    return observable.pipe(
      map((result: any) => {
        let data = this.arrayBufferToBase64(result)
        this.openNewTab(result)
        return data;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  base64ToArrayBuffer(base64) {
    var binary_string =  window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  print(pdf: string) {
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const objectUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.src = objectUrl;
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.onload = function() {
      setTimeout(function() {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  openNewTab(data: any) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.click();
  }

  public deleteDrive(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.delete(environment.api + '/back/testdrive/' + id, { headers });
    return observable.pipe(
      map((rawCustomer: any) => {
        return rawCustomer;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }
}
