import { ContactPersonFactory } from "../../customer/factory/ContactPersonFactory";
import { CustomerFactory } from "../../customer/factory/CustomerFactory";
import { Offer, OfferDescription, OfferItem } from "../Offer";

export class OfferFactory {
    static jsonFactory(rawBody: any): Offer[] {
        const data: Offer[] = [];
        for(let rawPart of rawBody){
          data.push(this.jsonFactoryOne(rawPart));
        }
        return data;
      }

    static jsonFactoryOne(rawPart: any): Offer {
          const obj = new Offer(
            rawPart.offer_id,
            CustomerFactory.jsonFactoryOne(rawPart.customer),
            ContactPersonFactory.jsonFactoryOne(rawPart.contact_person),
            rawPart.title,
            rawPart.offer_date,
            rawPart.offer_validity,
            rawPart.status,
            rawPart.show_price_overview,
            rawPart.show_brutto_price,
            this.jsonFactoryItem(rawPart.offer_items),
            rawPart.special_conditions,
            rawPart.payment_conditions,
            rawPart.hour_rate,
            rawPart.performance_period,
          )
          return obj;
    }

    static jsonFactoryItem(rawBody: any): OfferItem[] {
        const data: OfferItem[] = [];
        for(let rawPart of rawBody){
          data.push(this.jsonFactoryOneItem(rawPart));
        }
        return data;
    }

    static jsonFactoryOneItem(rawPart: any): OfferItem {
          const obj = new OfferItem(
            rawPart.item_id,
            this.jsonFactoryDescriptions(rawPart.offer_descriptions),
            rawPart.is_total_price,
            rawPart.price,
            rawPart.total_discount ? (rawPart.total_discount === 0 ? null : rawPart.total_discount) : null,
            rawPart.price_based,
            rawPart.base_value,
          )
          return obj;
    }

    static jsonFactoryDescriptions(rawBody: any): OfferDescription[] {
        const data: OfferDescription[] = [];
        for(let rawPart of rawBody){
          data.push(this.jsonFactoryOneDescription(rawPart));
        }
        return data;
    }

    static jsonFactoryOneDescription(rawPart: any): OfferDescription {
        const obj = new OfferDescription(
          rawPart.title,
          rawPart.sort_value,
          rawPart.description
        )
        return obj;
    }
}
