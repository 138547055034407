<app-sidebar *ngIf="showSidebar(outlet)"></app-sidebar>
<div id="router-outlet-wrapper" [class.main_container]="showSidebar(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>
<app-dialog-query></app-dialog-query>
<app-dialog-car-online></app-dialog-car-online>
<app-dialog-notification></app-dialog-notification>
<app-dialog-move></app-dialog-move>
<app-dialog-create-document></app-dialog-create-document>
<app-contract-vehicle-condition></app-contract-vehicle-condition>
<app-dialog-number></app-dialog-number>
<app-reparatur-dialog></app-reparatur-dialog>
<app-dialog-create-contract></app-dialog-create-contract>
<app-image-slider></app-image-slider>
<app-attachment-viewer></app-attachment-viewer>
<app-dialog-pdf-download></app-dialog-pdf-download>
<app-dialog-enums></app-dialog-enums>
<app-dialog-info></app-dialog-info>
<app-loading></app-loading>
<app-open-signature-loader></app-open-signature-loader>
<div *ngIf="isTest" id="test_identifier">
  <h3>Test</h3>
  <label>{{version}}</label>
</div>