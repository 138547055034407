import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ReloadDataHandler } from 'src/app/utils/ReloadDataHandler';
import { VehicleService } from '../vehicle/vehicle.service';

@Injectable({
  providedIn: 'root'
})
export class ReloadServiceService implements OnDestroy {

  private subscriptionActivate: Subscription;
  private subscriptionDeactivate: Subscription;

  private _deactivateReloadLoop = new Subject<any>();
  _deactivateReloadLoop$ = this._deactivateReloadLoop.asObservable();

  private _activateReloadLoop = new Subject<any>();
  _activateReloadLoop$ = this._activateReloadLoop.asObservable();

  private reloadLoop: any;
  private RELOAD_TIME: number = 1000*60*20;

  constructor() {
    this.subscriptionActivate = this._activateReloadLoop$.subscribe((services: any[]) => {
      if(services != null) this.handleServices(services);
    });
    this.subscriptionDeactivate = this._deactivateReloadLoop$.subscribe((services: any[]) => {
      if(services != null) this.removeLoops(services);
    });
  }

  public activateReloadLoop(services: any[]) {
    this._activateReloadLoop.next(services);
  }

  public deactivateReloadLoop(services: any[]) {
    this._deactivateReloadLoop.next(services);
  }

  ngOnDestroy(): void {
    if(this.subscriptionActivate) this.subscriptionActivate.unsubscribe();
    if(this.subscriptionDeactivate) this.subscriptionDeactivate.unsubscribe();
  }

  private handleServices(services: any[]) {
    for(let service of services){
      if(service instanceof VehicleService){
        service.startReloadLoop(this.RELOAD_TIME);
      }
    }
  }

  private removeLoops(services: any[]) {
    for(let service of services){
      if(service instanceof VehicleService){
        service.stopReloadLoop();
      }
    }
  }
}
