import { environment } from 'src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-locations-title',
  templateUrl: './list-locations-title.component.html',
  styleUrls: ['./list-locations-title.component.css']
})
export class ListLocationsTitleComponent implements OnInit {
  @Input() isTitle: boolean;
  @Input() pageInfo: string;
  @Input() sliceFrom: number;
  @Input() sliceTo: number;
  @Input() amount: number;
  @Output() pageEmitter = new EventEmitter<boolean>();
  public maxAmountListPage = environment.max_amount_list_page;

  constructor() { }

  ngOnInit(): void {}
}
