import { Customer } from './../customer/Customer';
import { Vehicle } from "../vehicle/Vehicle";
import { Employee } from '../employee/Employee';

export enum DriveStatus {
  GESPERRT = 0,
  AUSSTEHEND = 1,
  ABGSCHLOSSEN = 2
}

export class Drive {
  public vehicle: Vehicle;
  public customer: Customer;
  public employee: Employee;
  public status: number;
  public is_new = false;
  public isPast = false;
  public trip_description: string;
  public authorization_authority: string;
  public authorization_back_image: string;
  public authorization_date: number;
  public authorization_front_image: string;
  public authorization_groups: string;
  public authorization_number: string;
  public status_signiert: number;
  public plate_name: string;
  constructor(
    public test_drive_id: number,
    public location_id: number,
    public timestamp: number,
    public duration: number,
    public vehicle_id: number,
    public customer_id: number,
    public license_plate_id: number,
    public attachments?: any[],
    public start_time?: number,
    public end_time?: number,
    public subject?: string,
    public employee_id?: number,
  ){
    if((this.timestamp + this.duration) <= new Date().getTime()) this.isPast = true;
  }

  getDriver(): string {
    return this.customer ? this.customer?.getName() : (this.employee ? this.employee?.getFullName() : '---');
  }

  getStatus(): string {
    if(this.status == 0) return 'Gesperrt';
    if(this.status == 1) return 'Ausstehend';
    if(this.status == 2) return 'Abgeschlossen';
    return '---';
  }

  getStatusColor(): string {
    if(this.status == 0) return 'tag-red';
    if(this.status == 1) return 'tag-orange';
    if(this.status == 2) return 'tag-green';
  }

  getSignatureStatus(): string {
    if(this.status_signiert == 0) return 'Offen';
    if(this.status_signiert == 1) return 'Signiert';
    return '---';
  }

  getStatusSignatureColor(): string {
    if(this.status_signiert == null || this.status_signiert == 0) return 'tag-red';
    if(this.status_signiert == 1) return 'tag-green';
  }

  getDuration(): number {
    return Math.round(this.duration / (1000 * 60));
  }

  driveDataExits(): boolean {
    if(this.authorization_authority &&
      this.authorization_groups &&
      this.authorization_number &&
      this.trip_description
    ) return true;
    return false;
  }


  getDriveStatus(): string {
    if(this.status == DriveStatus.GESPERRT) this.setTagStyle('#1D1D1B');
    else if(this.status == DriveStatus.AUSSTEHEND) this.setTagStyle('#D2B836');
    else if(this.status == DriveStatus.ABGSCHLOSSEN) this.setTagStyle('#6A9E18');
    switch (this.status) {
      case DriveStatus.GESPERRT: return 'Gesperrt';
      case DriveStatus.AUSSTEHEND: return 'Ausstehend';
      case DriveStatus.ABGSCHLOSSEN: return 'Abgeschlossen';
      default: return '---';
    }
  }

    setTagStyle(color: string) {
    let label = document.getElementById("label-drive-status") as HTMLElement;
    if(label != null){
      label.style.padding = '2px 6px';
      label.style.borderRadius = '4px';
      label.style.color = color;
      label.style.background = color.trim()+'50';
    }
  }

  getJSON(): any {
    return {
      "customer_id": this.customer_id,
      "vehicle_id": this.vehicle_id,
      "employee_id": this.employee_id,
      "duration": this.duration,
      "timestamp" : this.timestamp,
      "license_plate_id" : this.license_plate_id,
      "trip_description" : this.trip_description,
      "authorization_authority" : this.authorization_authority,
      "authorization_back_image" : this.authorization_back_image,
      "authorization_date" : this.authorization_date,
      "authorization_front_image" : this.authorization_front_image,
      "authorization_groups" : this.authorization_groups,
      "authorization_number" : this.authorization_number,
    }
  }
}
