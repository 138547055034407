import { PictureFactory } from "../../picture/factory/PictureFactory";
import { VehicleBrandFactory } from "../../vehicle/basic/factory/VehicleBrandFactory";
import { VehicleModelFactoy } from "../../vehicle/basic/factory/VehicleModelFactoy";
import { Execution, Tire, TireDetail } from "../Tire";
import { StoragePlaceFactory } from "./StoragePlaceFactory";

export class TireFactory {

  static jsonFactory(rawBody: any): Tire[] {
    const data: Tire[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): Tire {
      const obj = new Tire(
        rawPart.tire_id,
        rawPart.location_id,
        rawPart.vehicle_id,
        rawPart.storage_place_id,
        rawPart.amount,
        rawPart.price,
        rawPart.platform_online,
        rawPart.freigeschaltet_am_plattform,
        rawPart.description,
        rawPart.status,
        rawPart.created,
        rawPart.last_updated,
        rawPart.tire_type,
        rawPart.tire_brand,
        rawPart.tire_model,
        rawPart.tire_width_1,
        rawPart.tire_width_2,
        rawPart.tire_height_1,
        rawPart.tire_height_2,
        rawPart.tire_inches_1,
        rawPart.tire_inches_2,
        rawPart.tire_index_1,
        rawPart.tire_index_2,
        rawPart.rim_type,
        rawPart.rim_brand,
        rawPart.rim_model,
        rawPart.rim_width_1,
        rawPart.rim_width_2,
        rawPart.rim_inches_1,
        rawPart.rim_inches_2,
        rawPart.rim_et_1,
        rawPart.rim_et_2,
        rawPart.rim_screws,
        rawPart.rim_bolt_circle,
        rawPart.rim_external_id,
        TireDetailsFactory.jsonFactory(rawPart.details),
        PictureFactory.jsonFactory(rawPart.pictures, rawPart.tire_id),
        rawPart.purchased_with_vehicle_id,
        rawPart.not_for_sale,
        rawPart.tire_pressure_sensor,
        rawPart.vehicle_make ? VehicleBrandFactory.jsonFactoryOne(rawPart.vehicle_make) : null,
        rawPart.tire_brand_name
        //rawPart.vehicle_model ? VehicleModelFactoy.jsonFactoryOne(rawPart.vehicle_model) : null,
      )
      if(rawPart.storage_place) obj.storage_place = StoragePlaceFactory.jsonFactoryOne(rawPart.storage_place);
      if(obj.tire_type != null && obj.rim_type != null) obj.execution = Execution.REIFENUNDFELGEN;
      else if(obj.tire_type != null) obj.execution = Execution.REIFEN;
      else if(obj.rim_type != null) obj.execution = Execution.FELGEN;
      obj.storage_time = Math.floor(((((new Date().getTime()-1000) - obj.created) / 1000) / 60) / 60 / 24);
      return obj;
  }
}

export class TireDetailsFactory {

  static jsonFactory(rawBody: any): TireDetail[] {
    const data: TireDetail[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): TireDetail {
      const obj = new TireDetail(
        rawPart.tire_detail_id,
        rawPart.tire_id,
        rawPart.depth,
        rawPart.year,
        rawPart.tyres_equal
      )
      return obj;
  }
}
