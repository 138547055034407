import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';

@Component({
  selector: 'app-create-offer-page-two',
  templateUrl: './create-offer-page-two.component.html',
  styleUrls: ['./create-offer-page-two.component.css']
})
export class CreateOfferPageTwoComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() submittedItem: boolean;

  @Output() addPriceEmitter = new EventEmitter<undefined>();
  @Output() removeEmitter = new EventEmitter<number>();
  @Output() submitEmitter = new EventEmitter<boolean>();

  public vehicles: Vehicle[] = [];
  public isLoaded = false;

  constructor(
    private vService: VehicleService
  ) { }

  ngOnInit(): void {
    this.vService.getVehicle().subscribe((result) => {
      if(result){
        this.vehicles = result;
        this.isLoaded = true;
      }
    })
  }

  get f() {
    return this.form.controls;
  }

  addPrice() {
    this.addPriceEmitter.emit();
  }
}
