<div class="topbar">
  <div class="topbar_inner">
      <div id="menu">
        <div class="topbar_tab_wrapper">
          <button [class.button_tap_active]="true">Meine Übertragungen<span *ngIf="true"></span></button>
        </div>
        <!-- <div class="btn_label_container">
          <div class="btn_label btn_label_activ">Meine Übertragungen</div>
          <div class="btn_label_underline btn_label_underline_activ" [ngStyle]="{'width': '80%'}"></div>
        </div> -->
      </div>
  </div>
</div>
