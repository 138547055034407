import { Setting } from "./Setting";

export class SettingFactory {

  static jsonFactory(rawBody: any): Setting[] {
    const objects: Setting[] = [];
    for(let raw of rawBody){
      objects.push(this.jsonFactoryOne(raw));
    }
    return objects;
  }

  static jsonFactoryOne(raw: any): Setting {
      return new Setting(
        raw.setting_default_value_id,
        raw.category,
        raw.category_name,
        raw.name,
        raw.label,
        raw.value,
        raw.typ
      );
  }
}
