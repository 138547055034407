import { StorageLocation, StorageLocationPlace } from "../StorageLocation";
import { StoragePlace } from "../StoragePlace";
import { StoragePlaceFactory } from "./StoragePlaceFactory";

export class StorageLocationFactory {

  static jsonFactory(rawBody: any): StorageLocation[] {
    const data: StorageLocation[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): StorageLocation {
      const obj = new StorageLocation(
        rawPart.storage_location_id,
        rawPart.location_id,
        rawPart.location_name,
        StoragePlaceFactory.jsonFactory(rawPart.places)
      )
      return obj;
  }
}

export class StorageLocationPlaceFactory {

  static jsonFactory(rawBody: any): StorageLocationPlace[] {
    const data: StorageLocationPlace[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): StorageLocationPlace {
      const obj = new StorageLocationPlace(
        rawPart.storage_location_place_id,
        rawPart.storage_location_place_name,
      )
      return obj;
  }
}
