import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { Offer } from 'src/app/buisness-object/document/Offer';
import { SalesContract } from 'src/app/buisness-object/document/SalesContract';

@Component({
  selector: 'app-list-documents-element',
  templateUrl: './list-documents-element.component.html',
  styleUrls: ['./list-documents-element.component.css']
})
export class ListDocumentsElementComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() document: any;

  public showActions = false;
  public showActionsMoveDeep = false;
  @ViewChild('action_container') action_container: ElementRef;
  public listener: () => void;

  @Output() openDetailsEmitter = new EventEmitter<any>();
  @Output() pageEmitter = new EventEmitter<boolean>();
  @Output() openEditEmitter = new EventEmitter<any>();
  @Output() deleteEmitter = new EventEmitter<any>();
  @Output() openPdfEmitter = new EventEmitter<any>();
  @Output() moveToEmitter = new EventEmitter<any>();
  @Output() signPDFEmitter = new EventEmitter<any>();
  @Output() duplicateEmitter = new EventEmitter<any>();

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showActions){
        if(!this.action_container.nativeElement.contains(e.target)){
          this.showActionsMoveDeep = false;
          this.showActions = false;
        }
      }
    })
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  getTyp(document: any): string {
    if(document.offer_id) return 'Angebot';
    if(document.bill_id) return 'Rechnung';
    // if(typ == 3) return 'Kaufvertrag';
    // if(typ == 4) return 'Hochladen';
  }

  getStatus(document: any): string {
    if(document.bill_id){
      switch (document.status) {
        case 0: return 'OK';
        case 1: return 'Abgesendet';
        case 2: return 'Bezahlt';
        case 3: return 'Überfällig';
        default: return '';
      }
    }
    if(document.offer_id){
      switch (document.status) {
        case 0: return 'Entwurf';
        case 1: return 'Abgesendet';
        case 2: return 'Akzeptiert';
        case 3: return 'Abgelehnt';
        case 4: return 'Abgelaufen';
        default: return '';
      }
    }
  }

  getStatusColor(document: any): string {
    if(document.bill_id){
      switch (document.status) {
        case 0: return 'lbl_color_wrapper_gray';
        case 1: return 'lbl_color_wrapper_orange';
        case 2: return 'lbl_color_wrapper_green';
        case 3: return 'lbl_color_wrapper_red';
        default: return '';
      }
    }
    if(document.offer_id){
      switch (document.status) {
        case 0: return 'lbl_color_wrapper_orange';
        case 1: return 'lbl_color_wrapper_cyan';
        case 2: return 'lbl_color_wrapper_green';
        case 3: return 'lbl_color_wrapper_red';
        case 4: return 'lbl_color_wrapper_red';
        default: return '';
      }
    }
  }

  setIcon():string {
    if(this.showActions){
      return '../../../../../assets/icon-more-white.png';
    }
    return '../../../../../assets/icon-more.png'
  }

  moveToAction(document: any, status: number) {
    document.status = status;
    this.showActionsMoveDeep = false;
    this.showActions = false;
    this.moveToEmitter.emit(document)
  }

  getDocCustomerName(): string {
    if(this.document instanceof Offer){
      return this.document.customer.companyName?.length > 0 ? this.document.customer.companyName : (this.document.contact_person.firstName + ' ' + this.document.contact_person.lastName);
    } else if(this.document instanceof SalesContract){
      return this.document.customer.companyName?.length > 0 ? this.document.customer.companyName : (this.document.customer.contactPerson.firstName + ' ' + this.document.customer.contactPerson.lastName);
    }
    //document.customer.companyName?.length > 0 ? document.customer.companyName : (document.contact_person.firstName + ' ' + document.contact_person.lastName
  }
}
