export class VehicleDrive {
    constructor(
        public id: number,
        public name: string,
        // public typeOfDrive: string,
        // public gears: number,
        // public gearType: string,
        // public powerHs: number,
        // public displacement: number,
        // public consumption: number,
        // public noVa: string,
        // public nox: string,
        // public co2: number,
        // public ndex: string,
        // public wltp: string,
        // public efficiency: string,
        // public emissionStandard: string
    ){}
}