import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from '../login/login.service';
import { DialogService } from '../dialog/dialog.service';
import { PermissionGroup, PermissionGroupFactory } from 'src/app/buisness-object/permission/PermissionGroup';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) {}

  public getPermissionGroups(): Observable<PermissionGroup[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/permission_group', { headers });
    return observable.pipe(
      map((data: any) => {
        const objects = PermissionGroupFactory.jsonFactory(data.permission_groups);
        return objects;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }
}
