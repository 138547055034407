import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BaseBoxComponent } from 'src/app/view/BaseBoxComponent';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-werkstatt',
  templateUrl: './werkstatt.component.html',
  styleUrls: ['./werkstatt.component.css'],
  animations: [DropdownIconArrowAnimation],
})
export class WerkstattComponent extends BaseBoxComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  public repairStatenValues = [];
  public repairStatenValue;
  public ersatzTeileValues = [];
  public ersatzTeileValue;
  public aufbereitungValue;
  public aufbereitungValues = [];
  public aufbereitungInfoValue;
  public aufbereitungInfoValues = [];
  public lackierungValues = [];
  public lackierungValue;
  public aufbereitungZustValues = [];
  public aufbereitungZustValue;
  public ankaufstestValues = [];
  public ankaufstestValue;
  public damagesValues = [];
  public damageValue;

  constructor(public authService: AuthenticationService, private eService: EnumService) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.viewTitle = "werkstatt";
    this.init();
    this.repairStatenValues = this.getEnumValues('repair_status');
    this.repairStatenValue = this.getEnumValue('repair_status', this.vehicle.repair_status);
    this.ersatzTeileValues = this.getEnumValues('ersatzteile');
    this.ersatzTeileValue = this.getEnumValue('ersatzteile', this.vehicle.ersatzteile);
    this.aufbereitungValues = this.getEnumValues('aufbereitung');
    this.aufbereitungValue = this.getEnumValue('aufbereitung', this.vehicle.aufbereitung);
    this.aufbereitungInfoValues = this.getEnumValues('aufbereitung_info');
    this.lackierungValues = this.getEnumValues('lackierung');
    this.lackierungValue = this.getEnumValue('lackierung', this.vehicle.lackierung_notwendig);
    this.aufbereitungZustValues = this.getEnumValues('aufbereitung_zustaendigkeit');
    this.aufbereitungZustValue = this.getEnumValue('aufbereitung_zustaendigkeit', this.vehicle.aufbereitung_zustaendigkeit);
    this.ankaufstestValues = this.getEnumValues('ankaufstest');
    this.ankaufstestValue = this.getEnumValue('ankaufstest', this.vehicle.ankaufstest);
    this.damagesValues = this.getEnumValues('damages');
    this.damageValue = this.getEnumValue('damages', this.vehicle.damages);
  }

  getEnumDataOnChange() {
    this.repairStatenValue = this.getEnumValue('repair_status', this.form.controls['repair_status'].value);
    this.ersatzTeileValue = this.getEnumValue('ersatzteile', this.form.controls['ersatzteile'].value);
    //this.aufbereitungInfoValue = this.getEnumValue('aufbereitung', this.form.controls['aufbereitung'].value);
    this.lackierungValue = this.getEnumValue('lackierung', this.form.controls['lackierung_notwendig'].value);
    this.aufbereitungZustValue = this.getEnumValue('aufbereitung_zustaendigkeit', this.form.controls['aufbereitung_zustaendigkeit'].value);
    this.ankaufstestValue = this.getEnumValue('ankaufstest', this.form.controls['ankaufstest'].value);
    this.damageValue = this.getEnumValue('damages', this.form.controls['damages'].value);
    this.aufbereitungValue = this.getEnumValue('aufbereitung', this.form.controls['aufbereitung'].value);
  }

  get f() {
    return this.form.controls;
  }
}
