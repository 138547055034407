<div id="dialog_window">
  <div id="dialog_container" appDropdown>
      <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
      <h1>Reifen verkaufen</h1>
      <div id="dialog_conainter_body" class="body">
        <div class="btn-tab-wrapper">
            <button
              class="btn-tab-gray"
              [class.btn-tab-first]="true"
              [class.btn-tab-last]="false"
              [class.btn-tab-active]="selectedTyp == sellTyp.SELL"
              (click)="selectedTyp = sellTyp.SELL">
                Verkaufen
            </button>
            <button
              class="btn-tab-gray"
              [class.btn-tab-first]="false"
              [class.btn-tab-last]="true"
              [class.btn-tab-active]="selectedTyp == sellTyp.SELLANDINSTALLATION"
              (click)="selectedTyp = sellTyp.SELLANDINSTALLATION">
                Verkaufen und montieren
            </button>
        </div>
      </div>
      <div class="dialog_btn_container">
          <button class="btn_label" (click)="selectionCloseEmitter.emit()">Abbrechen</button>
          <button class="btn_submit_dark" [disabled]="!selectedTyp" (click)="selectionSubmit()">Verkaufen</button>
      </div>
  </div>
</div>
