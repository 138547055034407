import { Zusatzprodukt } from "./Zusatzprodukt";

export class SelektiertesZusatzprodukt extends Zusatzprodukt {
    constructor(
        public zusatzprodukt_id: number,
        public kategorie: string,
        public name: string,
        public name_editierbar: boolean,
        public preis_selektiert: number,
        public name_selektiert?: string,
        public preis?: number,
    ) {
        super(zusatzprodukt_id, kategorie, name, name_editierbar, preis);
    }
}