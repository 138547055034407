import { BehaviorSubject } from "rxjs";
import { SubMenu } from "./SubMenu";

export class MainMenu {

  public _isActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public active$ = this._isActive.asObservable();

  constructor(
    public main_menu_id: number,
    public name: String,
    public sort_value: number,
    public sub_menus: SubMenu[],
    public master_menu: string
  ) {

  }

  setActive(active: boolean) {
    this._isActive.next(active);
  }
  getActive() {
    return this._isActive.getValue();
  }
}
