import { Location, formatDate, formatNumber } from '@angular/common';
import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, defer, firstValueFrom, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { OverviewConfiguration } from 'src/app/buisness-object/overview/OverviewConfiguration';
import { VehicleBodyColor } from 'src/app/buisness-object/vehicle/basic/VehicleBodyColor';
import { VehicleBodyTyp } from 'src/app/buisness-object/vehicle/basic/VehicleBodyTyp';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { VehicleDrive } from 'src/app/buisness-object/vehicle/basic/VehicleDrive';
import { VehicleFuel } from 'src/app/buisness-object/vehicle/basic/VehicleFuel';
import { VehicleTransmission } from 'src/app/buisness-object/vehicle/basic/VehicleTransmission';
import { VehiclePicture } from 'src/app/buisness-object/vehicle/optional/VehiclePicture';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import * as moment from 'moment';
import { TransactionService } from 'src/app/service/transaction/transaction.service';
import { FilterVehicle } from 'src/app/helpers/FilterVehicle';
import { VinService } from 'src/app/service/vin/vin.service';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { Attachment } from 'src/app/buisness-object/attachment/Attachment';
import { VehicleForm } from 'src/app/buisness-object/vehicle/form/VehicleForm';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';
import { BaseComponent } from '../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { PriceSheetForm, STARTSTANDARDVALUES } from 'src/app/buisness-object/pricesheet/form/PriceSheetForm';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { PriceSheetCalculater } from 'src/app/buisness-object/pricesheet/PriceSheetCalculater';
import { SortTyp } from 'src/app/buisness-object/sorttyp/SortTyp';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { SettingsService } from 'src/app/service/settings/settings.service';
import { Setting } from 'src/app/buisness-object/settings/Setting';
import { MainMenu } from 'src/app/buisness-object/menu/MainMenu';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { SubMenuColumn } from 'src/app/buisness-object/menu/SubMenuColumn';
import { SubMenuFilter, SubMenuOperator } from 'src/app/buisness-object/menu/SubMenuFilter';
import { MainMenuFactory } from 'src/app/buisness-object/menu/MainMenuFactory';
import { LoginService } from 'src/app/service/login/login.service';
import { LoadingService } from 'src/app/service/loading/loading.service';
import { CloneObject } from 'src/app/helpers/CloneObject';
import { PriceSheet } from 'src/app/buisness-object/pricesheet/PriceSheet';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { VehiclePictureFactory } from 'src/app/buisness-object/vehicle/optional/factory/VehiclePictureFactory';
import { ReloadDataHandler } from 'src/app/utils/ReloadDataHandler';
import { MasterMenu } from 'src/app/buisness-object/menu/EnumMasterMenu';
import { DokumentTyp } from '../dokumente/dokumente.component';
import { rejects } from 'assert';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EnumService } from 'src/app/service/enum/enum.service';
import { PriceSheetCalculaterNew } from 'src/app/buisness-object/pricesheet/PriceSheetCalculaterNew';
import { VehicleStatus } from '../list-action-container/btn-action/btn-action.component';
import { Picture } from 'src/app/buisness-object/picture/Picture';
import { SteuersatzValue } from 'src/app/helpers/Enums';
import { consumerDestroy } from '@angular/core/primitives/signals';
import { VehicleError } from 'src/app/buisness-object/vehicle/VehicleError';

const INPUTTIMEOUT = 800;

export enum VehicleView {
  LIST = 1,
  CREATE = 2,
  DETAIL = 3,
  TICKET = 4,
  KALKULATION = 5,
  DOCUMENT = 6

}

export enum VehicleCreatePage {

}


@Component({
  selector: 'app-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.css']
})
export class CarsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  public allSurfaces = VehicleView;
  public surface: VehicleView = VehicleView.LIST;
  public currentPageCreate = 1;
  public selectedVehicle: Vehicle;
  public editMode = false;
  public submitted = false;
  public vehicleDataInWork = false;
  public formGroupVehicle: FormGroup;
  public filterForm: FormGroup;
  public vehicleTitle: string;
  public vehicleSubTitle: string;
  public pageHandler: PageHandler;
  public pageInfo: string;
  public sliceTo: number;
  public sliceFrom: number;
  public filter = 1;
  public roles: any;
  public searchValue;
  public imgProgress = 0;
  public showInformation = 1;
  public vehicles: Vehicle[] = [];
  public _vehiclesFiltered: BehaviorSubject<Vehicle[]> = new BehaviorSubject<Vehicle[]>([]);
  public vehiclesFiltered$ = this._vehiclesFiltered.asObservable();
  public brands: VehicleBrand[] = [];
  public drives: VehicleDrive[] = [];
  public fuels: VehicleFuel[] = [];
  public bodyColors: VehicleBodyColor[] = [];
  public bodyTyps: VehicleBodyTyp[] = [];
  public categories: VehicleCategory[] = [];
  public transmissions: VehicleTransmission[] = [];
  public toUploadImgs: VehiclePicture[] = [];
  public dialogQuerySubsription: Subscription;
  public routeSubscription: Subscription;
  public dialogCreateDocSubscription: Subscription;
  public dialogCarOnlineSubsription: Subscription;
  public dialogChoosePdfSubscription: Subscription;
  public formSubscription: Subscription;
  public employees: Employee[] = [];
  public filterDetail = new FilterVehicle();
  public accessEdit = false;
  public sortObject = {
    sort_after_value: 3,
    sort_asc: false,
    price_des: false,
    km_des: false,
    sorting_is_set: true,
  }
  public viewIsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public testVehicles: BehaviorSubject<Vehicle[]> = new BehaviorSubject<Vehicle[]>([]);
  public routeParams = 'list';
  public surfacePriceSheet = 3;
  public pricesheetForm: FormGroup;
  public editTyp = 1;
  public priceSheetFormChanges = false;
  public inputTimeout;
  public formSubscriptionPricesheet: Subscription;
  public vinFlag = false;
  private chatgptHandler: any;
  public customers: Customer[] = [];
  public settings: Setting[] = [];
  public createViaVin = false;
  public selectedDetailsTab = 1;
  public showPreviousBtnDetails = true;
  public showNextBtnDetails = false;
  public isNewPriceSheet = false;
  public mainMenus: MainMenu[] = [];
  public _activeSubMenu: BehaviorSubject<SubMenu> = new BehaviorSubject<SubMenu>(null);
  public activeSubMenu$ = this._activeSubMenu.asObservable();
  public activeSubMenu: SubMenu;
  public _activeSortingColumn: BehaviorSubject<SubMenuColumn> = new BehaviorSubject<SubMenuColumn>(null);
  public activeSortingColumn$ = this._activeSortingColumn.asObservable();
  public activeSortingColumn: SubMenuColumn;
  public listLoading = false;
  public activeFilter: any[] = [];
  public activeFilterOutside: any[] = [];
  public activeFilterInside: any[] = [];
  public routeParamsSub: Subscription;
  public updateConflicts: any[];
  public searchTimeout: any;
  public reloadIsRunning = false;
  public scrollbarLastPos;
  public resumePagination = false;
  public isListLoading = false;
  public dataReady = false;
  public dataRequested: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public initialMenuName;
  public updateSuccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showDialogBCAImport = false;
  public vehicleBCAErrors: VehicleError[] = [];
  public vehicleBCASTotalNumber;
  public showDialogPrint = false;
  public dialogPrintPDFVehicleId;

  constructor(
    private vService: VehicleService,
    private transactionService: TransactionService,
    private formBuilder: FormBuilder,
    private activateRoute: ActivatedRoute,
    private dService: DialogService,
    private locationRoute: Location,
    private vinService: VinService,
    private tService: ThreadService,
    private eService: EmployeeService,
    private attachmentService: AttachmentService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private customerService: CustomerService,
    private setttingsService: SettingsService,
    private loginService: LoginService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private enService: EnumService
  ) {
    super(authenticationService, enService);
  }

  ngOnInit(): void {
    this.setUp();
    this.filterForm = VehicleForm.getVehicleFilterForm(this.formBuilder);
    this.setDialogSubscription();
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    if(this.chatgptHandler) clearInterval(this.chatgptHandler);
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
    if(this.dialogCreateDocSubscription) this.dialogCreateDocSubscription.unsubscribe();
    if(this.dialogCarOnlineSubsription) this.dialogCarOnlineSubsription.unsubscribe();
    if(this.formSubscription) this.formSubscription.unsubscribe();
    if(this.formSubscriptionPricesheet) this.formSubscriptionPricesheet.unsubscribe();
    if(this.dialogChoosePdfSubscription) this.dialogChoosePdfSubscription.unsubscribe();
    // window.removeEventListener("beforeunload", this.beforeUnloadListerner, {capture:true})
    if(this.searchTimeout) clearTimeout(this.searchTimeout);
  }

  // requestVehicleDataServerside(): Observable<any> {
  //   return this.vService.getVehicle(true).pipe(
  //     tap((result) => {
  //       if(result) {
  //         this.vehicles = result;
  //         return true;
  //       } else {
  //         return null;
  //       }
  //     }),catchError((error) => {
  //       console.error(error);
  //       return of(null);
  //     })
  //   );
  // }

  requestRestDataServerside(): Observable<any> {
    return forkJoin({
      brands: this.vService.getBrands(),
      drives: this.vService.getDrives(),
      bodyColors: this.vService.getBodyColor(),
      fuels: this.vService.getFuels(),
      categories: this.vService.getCategories(),
      transmissions: this.vService.getTransmissions(),
      bodyTyps: this.vService.getBodyTyps(),
      customers: this.customerService.getCustomer(),
      employees: this.eService.getEmployees(),
      settings: this.setttingsService.getSettings()
    }).pipe(
      tap((result) => {
        if(result) {
          this.brands = result.brands;
          this.drives = result.drives;
          this.bodyColors = result.bodyColors;
          this.fuels = result.fuels;
          this.categories = result.categories;
          this.transmissions = result.transmissions;
          this.bodyTyps = result.bodyTyps;
          this.customers = result.customers;
          this.employees = result.employees;
          this.settings = result.settings;
        }
      }),
      catchError((error) => {
        console.error(error);
        return of(null);
      })
    );
  }

  calculatePricesheetListItem(vehicle: Vehicle) {
    // this.getPricesheet(vehicle).then((v) => {
    //   let i = this._vehiclesFiltered.getValue().findIndex((v) => v?.id == vehicle.id)
    //   if(i > -1) this._vehiclesFiltered.getValue()[i] = v;
    //   let k = this.vehicles.findIndex((v) => v?.id == vehicle.id)
    //   if(k > -1) this._vehiclesFiltered.getValue()[k] = v;
    // })
  }

  async setUp() {
    let start = new Date();
    this.vehicles = await firstValueFrom(this.vService.getVehicle(true, false));
    console.log(`End server request: ${(new Date().getTime()-start.getTime())/1000}`);
    let startCalc = new Date();
    await firstValueFrom(this.requestRestDataServerside());
    await this.setUpPriceSheetNew();
    this.mergeCustomerToVehicle();
    console.log(`End calculation: ${(new Date().getTime()-startCalc.getTime())/1000}`);
    this.setUpMenu();
    this.activeSubMenu$.subscribe((active) => {
      if(active){
        this.activeSubMenu = active;
        this.activeFilter = [];
        this.activeFilterInside = [];
        this.activeFilterOutside = [];
        this.activeSortingColumn = null;
        this.rootFilterVehicles();
      }
    });
    this.routeListener();
    this.setUpSearch();
    this.dataReady = true;
    this.viewIsLoaded.next(true);
    this.vService.reload$.subscribe((change) => {
      if(change == true && !this.reloadIsRunning) this.handleReload();
    });
  }

  mergeCustomerToVehicle() {
    const employeeMap = new Map(this.employees.map(employee => [employee.id, employee]));
    for(let vehicle of this.vehicles) {
      if(vehicle.priceSheet && vehicle.priceSheet?.employee_name){
        vehicle.priceSheet.employee_name = employeeMap.get(vehicle.priceSheet.employee_id).getFullName() || null;
      }
    }
  }

  handleReload() {
    this.reloadIsRunning = true;
    this.vehicles = this.vService.vehicles$.getValue();
    this.setttingsService.getSettings().subscribe((settings) => {
      this.setUpPriceSheetNew();
      if(this.selectedVehicle != null && !this.editMode && this.surface != VehicleView.CREATE){
        let index = this.vehicles.findIndex(v => v.id == this.selectedVehicle.id);
        if(index > -1){
          this.openDetails(this.vehicles[index]);
        } else {
          this.closeDetails();
        }
      }
      this.rootFilterVehicles();
      this.reloadIsRunning = false;
      this.vService.reload$.next(false);
    });
  }

  setUpSearch(filterVehicles = true) {
    setTimeout(() => {
      const searchInput = document.getElementById('ab-search-list-input') as HTMLInputElement;
      if(searchInput != null){
        if(this.searchValue != null){
          searchInput.value = this.searchValue;
          if(filterVehicles) this.rootFilterVehicles();
        }
        searchInput.addEventListener('input', (event: InputEvent) => {
          if(this.searchTimeout) clearTimeout(this.searchTimeout);
          this.searchTimeout = setTimeout(() => {
            this.searchValue = searchInput.value;
            this.rootFilterVehicles();
          }, 500);
        });
      }
      const searchInputReset = document.getElementById('ab-search-list-input-reset') as HTMLInputElement;
      if(searchInputReset){
        searchInputReset.addEventListener('click', (event: InputEvent) => {
          if(this.searchValue != null){
            const searchInput = document.getElementById('ab-search-list-input') as HTMLInputElement;
            if(searchInput){
              searchInput.value = null;
              this.searchValue = null;
              this.rootFilterVehicles();
            }
          }
        });
      }
    }, 200);
  }

  applySpecialFilter(filterValue: any){
    let activeSubMenu = this.mainMenus.filter((m) => m.getActive())[0].sub_menus.filter((s) => s.getActive())[0];
    if(activeSubMenu?.filter && activeSubMenu.filter.length > 0) {
      this._vehiclesFiltered.next(this.vehicles.filter((vehicle) => this.solveFilter(activeSubMenu.filter, vehicle)));
    } else {
      this._vehiclesFiltered.next(this.vehicles.filter((vehicle) => true));
    }
    this._vehiclesFiltered.next(this.vehicles.filter((vehicle) => {
      if(vehicle.priceSheet == null || vehicle.priceSheet.al_re_datum == null){
        return false;
      }
      let value1 = new Date(vehicle.priceSheet.al_re_datum);
      let valueToCheck = new Date(filterValue);
      return value1.getFullYear() == valueToCheck.getFullYear() && value1.getMonth() == valueToCheck.getMonth();
    }));
    this.applySearch(this._vehiclesFiltered.getValue());
    this.rootSorting();
    this.setPages();
  }

  applySearch(objects: any[]) {
    if(this.searchValue && this.searchValue.length > 0){
       let temp = [];
       temp = objects.filter((vehicle) => {
       return (vehicle.brand.makeName.toLowerCase().includes(this.searchValue.toLowerCase())) ||
                 (vehicle.model.name.toLowerCase().includes(this.searchValue.toLowerCase())) ||
                 (vehicle.model.version.toLowerCase().includes(this.searchValue.toLowerCase())) ||
                 (vehicle.price?.toString().toLowerCase().includes(this.searchValue.toLowerCase())) ||
                 (vehicle.mileageKm?.toString().toLowerCase().includes(this.searchValue.toLowerCase())) ||
                 (vehicle.id.toString().toLowerCase().includes(this.searchValue.toLowerCase())) ||
                 (vehicle.fin_number?.toLowerCase().includes(this.searchValue.toLowerCase())) ||
                 (vehicle.vehicle_serialnumber?.toLowerCase().includes(this.searchValue.toLowerCase())) ||
                 (vehicle.externalId?.toString().toLowerCase().includes(this.searchValue.toLowerCase())) ||
                 (vehicle.priceSheet?.vehicle_supplier?.toString().toLowerCase().includes(this.searchValue.toLowerCase())) ||
                 (vehicle.brand.makeName.toLowerCase() + ' ' + vehicle.model.name.toLowerCase() + ' ' + vehicle.model.version.toLowerCase()).includes(this.searchValue.toLowerCase());
      });
      this._vehiclesFiltered.next(temp);
    }
  }

  applySorting(column: SubMenuColumn) {
    //for reference
    if(this.activeSortingColumn.sub_menu_column_id != column.sub_menu_column_id){
      this.activeSortingColumn.sortingActive = false;
      this.activeSortingColumn.ascend = false;
    }
    this.activeSortingColumn = column;
    this.activeSortingColumn.setSortActive();
    this.rootSorting();
  }

  setUpMenu() {
    let raw: any = JSON.parse(localStorage.getItem('menus'));
    if(raw == undefined || raw == null ){
      this.loginService.logout();
      return;
    }
    this.mainMenus = MainMenuFactory.jsonFactoryMainMenus(raw, MasterMenu.VEHICLE);
    let initialMenu;
    //TODO: her war zusätzlich diese Abfrage. this.authenticationService.accessViaPermissionGroup('test_drive_edit')
    //wie sollte dies gehandelt werden.
    if(this.authenticationService.isSinhuber() && Number(localStorage.getItem('permission_group_id')) == 7){
      for(let i = 0; i < this.mainMenus.length; i++){
        if(this.mainMenus[i].name == 'Probefahrt') initialMenu = this.mainMenus[i];
        this.mainMenus[i].sub_menus[0]?.setActive(true);
      }
    } else {
      for(let i = 0; i < this.mainMenus.length; i++){
        if(i == 0) initialMenu = this.mainMenus[i];
        this.mainMenus[i].sub_menus[0]?.setActive(true);
      }
    }
    for(let mainMenu of this.mainMenus){
      mainMenu.active$.subscribe((active) => {
        if(active){
          for(let otherMainMenu of this.mainMenus){
            if(otherMainMenu.main_menu_id != mainMenu.main_menu_id){
              otherMainMenu.setActive(false);
            }
          }
          if(mainMenu.sub_menus.length > 0){
            for(let otherSubMenu of mainMenu.sub_menus){
              if(otherSubMenu.getActive()){
                this._activeSubMenu.next(otherSubMenu);
              }
            }
          }
          //this.goToMenu();
        }
      });
      for(let subMenu of mainMenu.sub_menus){
        subMenu.active$.subscribe((active) => {
          if(active){
            for(let otherSubMenu of mainMenu.sub_menus){
              if(otherSubMenu != subMenu){
                otherSubMenu.setActive(false);
              }
            }
            this._activeSubMenu.next(subMenu);
          }
        });
      }
    }
    initialMenu.setActive(true)
    initialMenu.sub_menus[0].setActive(true);
    this.initialMenuName = initialMenu.name;
    this._activeSubMenu.next(initialMenu?.sub_menus[0]);
  }

  goToMenu() {
    var url = this.router.createUrlTree([], { relativeTo: this.activateRoute, queryParams: { subMenuId: this.activeSubMenu.sub_menu_id }}).toString();
    this.router.navigateByUrl(url);
  }

  allowToSeeSuperGroup(group: number): boolean {
    if(!this.activeSubMenu) return false;
    let auths = this.authenticationService.getViewAuth().filter((auth) => auth.id == this.activeSubMenu.sub_menu_id);
    if(auths.length > 0){
      let auth = auths[0];
      if(auth.screens.length > group && this.authenticationService.getViewRepresentations().length > group) {
        let views = auth.screens[group].view;
        return views.length > 0;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  rootFilterVehicles(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let activeSubMenu = this.mainMenus.filter((m) => m.getActive())[0].sub_menus.filter((s) => s.getActive())[0];
      if(activeSubMenu?.filter && activeSubMenu.filter.length > 0) {
        let sumFilter: any[] = activeSubMenu.filter;
        if(this.activeFilter.length > 0) {
          sumFilter = [sumFilter].concat([new SubMenuOperator("&&")]).concat(this.activeFilter);
        }
        this._vehiclesFiltered.next(this.vehicles.filter((vehicle) => this.solveFilter(sumFilter, vehicle)));
      } else if(this.activeFilter.length > 0) {
        this._vehiclesFiltered.next(this.vehicles.filter((vehicle) => this.solveFilter(this.activeFilter, vehicle)));
      } else {
        this._vehiclesFiltered.next(this.vehicles.filter((vehicle) => true));
      }
      this.applySearch(this._vehiclesFiltered.getValue());
      this.rootSorting();
      this.setPages();
      resolve(true);
    });
  }

  solveFilter(originalFilters: any[], object: any): boolean {
    let filters = JSON.parse(JSON.stringify(originalFilters));
    for(let i = 0; i < filters.length; i++) {
      if(filters[i].data_key) {
        filters[i] = this.vehicleIsValidForFilter(filters[i], object);
      } else if(Array.isArray(filters[i])) {
        filters[i] = this.solveFilter(filters[i], object);
      }
    }
    for(let i = 0; i < filters.length; i++) {
      if(filters[i].operator && filters[i].operator == '&&') {
        filters[i + 1] = filters[i -1] && filters[i + 1];
        filters.splice(i - 1, 2);
        i = i - 1;
      }
    }
    for(let i = 0; i < filters.length; i++) {
      if(filters[i].operator && filters[i].operator == '||') {
        filters[i + 1] = filters[i -1] || filters[i + 1];
        filters.splice(i - 1, 2);
        i = i - 1;
      }
    }
    return filters[0];
  }

  customFilterOutside(filterValue: any) {
    this.activeFilterOutside = filterValue;
    if(this.activeFilterInside.length > 0 && this.activeFilterOutside.length > 0){
      this.activeFilter = this.activeFilterInside.concat([new SubMenuOperator("&&")]).concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    } else {
      this.activeFilter = this.activeFilterInside.concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    }
    this.rootFilterVehicles();
  }

  customFilter(filterValue: any) {
    this.activeFilterInside = filterValue;
    if(this.activeFilterInside.length > 0 && this.activeFilterOutside.length > 0){
      this.activeFilter = this.activeFilterInside.concat([new SubMenuOperator("&&")]).concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    } else {
      this.activeFilter = this.activeFilterInside.concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    }
    this.rootFilterVehicles();
  }

  vehicleIsValidForFilter(filter: SubMenuFilter, object: any): boolean {
    let value: any = this.getVehicleValueFromDataKey(filter.data_key, object);
    if(filter.data_typ == "NUMBER" || filter.data_typ == "DATE") {
      return this.compareNumber(value, filter);
    } else if(filter.data_typ == "BOOLEAN" || filter.data_typ == "BOOLEAN_NULL") {
      return this.compareBoolean(value, filter);
    } else if(filter.data_typ == "ENUM" || filter.data_typ == "ENUM_TAG") {
      return this.compareEnum(value, filter);
    } else {
      return this.compareString(value, filter);
    }
  }

  compareEnum(value: any, filter: SubMenuFilter): boolean {
    if(filter.raw_value == "NULL") {
      if(filter.operator == "<>") {
        return value != undefined && value != null;
      } else if(filter.operator == "=") {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }
    let id: any = null;
    if(typeof value === 'object') {
      id = value == null ? null: value.id;
    } else {
      id = value;
    }
    let rawValue = filter.raw_value;
    if(filter.operator == "<>") {
      return id != rawValue;
    } else if(filter.operator == "=") {
      return id == rawValue;
    } else {
      return false;
    }
  }

  compareBoolean(value: any, filter: SubMenuFilter): boolean {
    if(filter.raw_value == "NULL") {
      if(filter.operator == "<>") {
        return value != undefined && value != null;
      } else if(filter.operator == "=") {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }
    let numberValue = value == null ? null: (value == "true" || value == "1" || value == true || value == 1);
    let rawValue = filter.raw_value == null ? null: (filter.raw_value == "true" || filter.raw_value == "1");
    if(filter.operator == "<>") {
      return numberValue != rawValue;
    } else if(filter.operator == "=") {
      return numberValue == rawValue;
    } else {
      return false;
    }
  }

  compareNumber(value: any, filter: SubMenuFilter): boolean {
    if(filter.raw_value == "NULL") {
      if(filter.operator == "<>") {
        return value != undefined && value != null;
      } else if(filter.operator == "=") {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }

    let numberValue = Number(value);
    let rawValue;

    //Special date condition
    if(filter.raw_value && String(filter.raw_value).includes("NOW-")){
      let miliseconds = Number(filter.raw_value.split("-")[1]);
      if(!isNaN(miliseconds)){
        rawValue = new Date().getTime()-miliseconds;
      }
    }

    if(rawValue == undefined || rawValue == null) rawValue = Number(filter.raw_value);

    if(Number.isNaN(numberValue)) {
      return false;
    }
    if(filter.operator == "<>") {
      return numberValue!= rawValue;
    } else if(filter.operator == "=") {
      return numberValue == rawValue;
    } else if (filter.operator == ">") {
      return numberValue > rawValue;
    } else if (filter.operator == ">=") {
      return numberValue >= rawValue;
    } else if (filter.operator == "<") {
      return numberValue < rawValue;
    } else if(filter.operator == "<=") {
      return numberValue <= rawValue;
    } else {
      return false;
    }
  }

  compareString(value: any, filter: SubMenuFilter): boolean {
    if(filter.operator == '<>') {
      if(filter.raw_value == "NULL") {
        return value != undefined && value != null;
      } else {
        return String(value) != filter.raw_value;
      }
    } else if(filter.operator == '=') {
      if(filter.raw_value == "NULL") {
        return value == undefined || value == null;
      } else {
        return String(value).toLowerCase().includes(filter.raw_value.toLowerCase());
      }
    } else {
      console.log("Warning: Wrong operator in filter");
      return false;
    }
  }

  getVehicleValueFromDataKey(data_key: string, obj: any): any {
    let keys = data_key.split('.');
    let value = obj;
    for(let i = 0; i < keys.length; i++){
      value = value[keys[i]];
      if(value == undefined || value == null){
        break;
      }
    }
    return value;
  }

  rootSorting() {
    let activeSub = this._activeSubMenu.getValue();
    if(this.activeSortingColumn == null && activeSub != null){
      for(let c of this._activeSubMenu.getValue().columns){
        if(c.sub_menu_column_id == activeSub.default_sort_column_id){
          this.activeSortingColumn = c;
          this.activeSortingColumn.sortingActive = true;
          this.activeSortingColumn.ascend = activeSub.default_sort_direction == 'ASC' ? true : false;
          break;
        }
      }
      if(this.activeSortingColumn == null){
        let index = this.activeSubMenu.columns.findIndex((c) => c.data_key == 'externalId');
        if(index > -1) this.activeSortingColumn = this.activeSubMenu.columns[index];
        else {
          this.activeSortingColumn = new SubMenuColumn(0,"id",0,"vehicle",0,"id",false,false,1,null,null,null,null,"NUMBER",null);
        }
        this.activeSortingColumn.sortingActive = true;
        this.activeSortingColumn.ascend = false;
      }
    }
    if(this.activeSortingColumn != null && this.activeSortingColumn.sortingActive){
      this._vehiclesFiltered.getValue().sort((a,b) => {
        if (this.activeSortingColumn.sort_function) {
          if(this.activeSortingColumn.ascend) {
            return a[this.activeSortingColumn.sort_function](b) * -1;
          } else {
            return a[this.activeSortingColumn.sort_function](b);
          }
        }
        let value1;
        let value2;
        if(this.activeSortingColumn.data_key === 'make_and_model'){
          value1 = a.brand.makeName + ' ' + a.model.name;
          value2 = b.brand.makeName + ' ' + b.model.name;
        } else if(this.activeSortingColumn.data_key === 'make_and_version'){
          value1 = a.brand.makeName + ' ' + a.model.version;
          value2 = b.brand.makeName + ' ' + b.model.version;
        } else if(this.activeSortingColumn.data_key === 'model_and_version'){
          if(a.model.version.startsWith(a.model.name)) {
            value1 = a.model.version;
          }
          value1 = a.model.name + ' ' + a.model.version;
          if(b.model.version.startsWith(b.model.name)) {
            value2 = b.model.version;
          }
          value2 = b.model.name + ' ' + b.model.version;
        } else if(this.activeSortingColumn.data_key == "thumbnail") {
          value1 = a.pictures.length;
          value2 = b.pictures.length;
        } else if(this.activeSortingColumn.data_key.includes('.')) {
          let deepObjKey = this.activeSortingColumn.data_key.split('.')[0];
          let deepFunctionORPara = this.activeSortingColumn.data_key.split('.')[1];
          let deepValueA = a[deepObjKey];
          let deepValueB = b[deepObjKey];
          if(deepValueA && typeof deepValueA[deepFunctionORPara] == 'function'){
            value1 = deepValueA[deepFunctionORPara]();
            value2 = deepValueB[deepFunctionORPara]();
          } else {
            value1 = deepValueA[deepFunctionORPara];
            value2 = deepValueB[deepFunctionORPara];
          }
        } else if(this.activeSortingColumn.calculated == 1) {
          if(typeof a[this.activeSortingColumn.data_key] === 'function') value1 = a[this.activeSortingColumn.data_key]();
          if(typeof a[this.activeSortingColumn.data_key] === 'function') value2 = b[this.activeSortingColumn.data_key]();
        } else if(typeof a[this.activeSortingColumn.data_key] == 'function'){
          value1 = a[this.activeSortingColumn.data_key]();
          value2 = b[this.activeSortingColumn.data_key]();
        } else {
          value1 = this.getVehicleValueFromDataKey(this.activeSortingColumn.data_key, a);
          value2 = this.getVehicleValueFromDataKey(this.activeSortingColumn.data_key, b);
        }
        if(value1 == null) return this.activeSortingColumn.ascend ? -1 : 1;
        if(value2 == null) return this.activeSortingColumn.ascend ? 1 : -1;
        if(this.activeSortingColumn.data_typ == "STRING"){
          if(value1 != null && value2 != null && isNaN(value1) && isNaN(value2)){
            return this.activeSortingColumn.ascend ? value1?.localeCompare(value2 ? value2 : '') : value2?.localeCompare(value1 ? value1 : '');
          }
        }
        return this.activeSortingColumn.ascend ? (value1 - value2) : (value2 - value1);
      });
    }
  }

  async routeListener() {
    this.routeParamsSub = this.activateRoute.queryParams.subscribe((params) => {
      const carId = Number(params['carId']);
      const subMenuId = Number(params['subMenuId']);
      if(carId && !subMenuId){
        let indexCar = this.vehicles.findIndex(v => v.id == carId);
        if(indexCar > -1){
          const vehicle = this.vehicles[indexCar];
          this.openDetails(vehicle);
        }
      } if(carId && subMenuId){
        let indexCar = this.vehicles.findIndex(v => v.id == carId);
        let indexMainMenu = -1;
        let indexSubMenu = -1;
        for(let i = 0; i < this.mainMenus.length; i++){
          indexSubMenu = this.mainMenus[i].sub_menus.findIndex(s => s.sub_menu_id == subMenuId);
          if(indexSubMenu > -1){
            indexMainMenu = i;
            break;
          }
        }
        if(indexCar > -1 && indexMainMenu > -1 && indexSubMenu > -1){
          if(this.activeSortingColumn == null){
            //TODO
            this.mainMenus[indexMainMenu].setActive(true);
          }
          const vehicle = this.vehicles[indexCar];
          this.openDetails(vehicle);
        }
      } else if(carId){
        const vehicle = this.vehicles.find(v => v.id == carId);
        if(vehicle){
          this.openDetails(vehicle);
        } else {
          this.dService.openInfo(
            {
              title: 'Fahrzeug nicht gefunden',
              message: 'Dieses Fahrzeug existiert nicht mehr.',
              btn_submit_txt: 'Ok',
            }
          );
        }
      }
    });
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete'){
          this.deleteVehicle(value.submit_value);
        } else if(value.typ == 'cancel'){
          this.cancelCreate();
        } else if(value.typ == "cancel_pricsheet_edit"){
          this.cancelActionPricesheetedit();
        } else if(value && value.typ == 'draft_customer_vehicle'){
          this.moveVehicle(value.submit_value, 4, null, null, null);
        } else if(value && value.typ == "sell") {
          this.sellVehicle(value.submit_value);
        } else if(value.typ == 'cancel_vehicle_edit'){
          this.cancelEdit();
        }
      }
    });
    this.dialogCreateDocSubscription = this.dService.closeDialogCreateDoc$.subscribe((value) => {
      if(value) this.createDocument(value.data, value.submit_value)
    })
    this.dialogCarOnlineSubsription = this.dService.closeDialogQueryCarOnline$.subscribe((value) => {
      if(value && value.typ == 'publish_vehicle') this.moveVehicle(value.submit_value, 1, value.export_web, value.export_platform, value.price, value.export_mobile);
      else if(value && value.typ == 'publish_vehicle_create'){
        this.formGroupVehicle.controls['website_online'].setValue(value.export_web);
        this.formGroupVehicle.controls['platform_online'].setValue(value.export_platform);
        this.formGroupVehicle.controls['price'].setValue(value.price);
        this.formGroupVehicle.controls['status'].setValue(1);
        this.handleNextPage(10, 2);
      }
    })
  }

  sortVehicles(after: string) {
    this._vehiclesFiltered.getValue().sort((a,b) => {
      if(a.externalId < b.externalId) return 1;
      if(a.externalId > b.externalId) return -1;
      return 0;
    });
  }

  setPages() {
    let lastSliceFrom;
    let lastSliceto;
    if(this.pageHandler != null){
      lastSliceFrom = this.pageHandler.sliceFrom;
      lastSliceto = this.pageHandler.sliceTo;
    }
    this.pageHandler = new PageHandler(this._vehiclesFiltered.getValue().length);
    if(this.resumePagination && lastSliceFrom && lastSliceto){
      this.pageHandler.sliceFrom = lastSliceFrom;
      this.pageHandler.sliceTo = lastSliceto;
    }
    this.resumePagination = false;
  }

  pageHandling(next: boolean) {
    this.pageHandler.action(next);
  }

  get f() {
    return this.formGroupVehicle.controls;
  }

  openCreate() {
    new ReloadDataHandler().deactivateReloadLoop([this.vService]);
    this.prepareForm()
    this.selectedVehicle = null;
    this.editMode = false;
    this.vehicleTitle = null;
    this.vehicleSubTitle = null;
    this.currentPageCreate = 1;
    this.surface = VehicleView.CREATE;
    this.locationRoute.replaceState('/cars/create');
  }

  // beforeUnloadListerner = (event) => {
  //   event.preventDefault();
  //   return (event.returnValue = "");
  // }

  handleSurface(show: number) {
    this.surface = show;
    this.prepareForm();
    this.locationRoute.replaceState('/cars');
    this.selectedVehicle = null;
    this.editMode = false;
    this.vehicleTitle = null;
    this.vehicleSubTitle = null;
    this.currentPageCreate = 1;
  }

  async validateInput(pageNum: number) {
    if(pageNum == 1){
      this.currentPageCreate = 2;
      return;
    }
    if(pageNum > this.currentPageCreate){
      this.vehicleDataInWork = true;
      this.submitted = true;
      let typ = this.selectedVehicle ? 2 : 1;
      switch (pageNum - 1) {
        case 2: if(this.checkValidPageOne()){ await this.handleNextPage(pageNum, undefined) } break;
        case 3: if(this.checkValidPageTwo()){ await this.handleNextPage(pageNum, typ); } break;
        case 4: await this.handleNextPage(pageNum, typ); break;
        case 5: if(this.checkValidPageThree()){ await this.handleNextPage(pageNum, typ); } break;
        case 6: await this.handleNextPage(pageNum, typ); break;
        case 7: await this.handleNextPage(pageNum, typ); break;
        case 8: await this.handleNextPage(pageNum, typ); break;
        case 9: this.openPublishDialogCreate(); break;
        case 10: this.surface = 3; break;
        default: break;
      }
    } else {
      this.currentPageCreate = pageNum;
    }
  }

  openPublishDialogCreate() {
    let vehicle = VehicleForm.createObject(this.formGroupVehicle);
    this.dService.openQueryCarOnline(
      {
        title: 'Fahrzeug online stellen?',
        message: 'Sind sie sicher, dass Sie das Fahrzeug ' + vehicle.brand.makeName + ', ' + vehicle.model.name + ', ' + vehicle.model.version + ' veröffentlichen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Veröffentlichen',
        typ: 'publish_vehicle_create',
        submit_value: vehicle,
      }
    );
  }

  checkValidPageVINMissingData(): boolean {
    let valid = false;
    valid = this.f.model.valid && this.f.version.valid &&
      this.f.drive.valid && this.f.transmission.valid &&
      this.f.fuel.valid && this.f.bodyTyp.valid &&
      this.f.bodyColor.valid && this.f.category.valid &&
      this.f.numOfDoors.valid && this.f.numOfSeats.valid &&
      this.f.unfall.valid && this.f.mileageKm.valid && this.f.power.valid &&
      this.f.steuersatz_pricesheet.valid && this.f.einkaufspreis_pricesheet.valid;
    let values = ["sprit", "lieferant_extern", "einkaufsdatum", "vehicle_supplier"];
    for(let value of values) {
      if(this.authenticationService.showCreateValue(value)){
        valid = valid && this.f[value].valid;
      }
    }
    valid = valid && this.f["vehicle_imported"].valid &&
    this.f["steuersatz_pricesheet"].valid;
    if(valid){
        if(this.f.category.value.id == 1){
          return true;
        } else if(this.f.category.value.id != 1 && this.f.firstRegistrationDate.invalid){
          return false;
        } else return true;
      }
    return false;
  }

  checkValidPageOne(): boolean {
    if(this.f.model.valid && this.f.version.valid &&
      this.f.drive.valid && this.f.transmission.valid &&
      this.f.fuel.valid && this.f.bodyTyp.valid &&
      this.f.bodyColor.valid && this.f.category.valid &&
      this.f.numOfDoors.valid && this.f.numOfSeats.valid &&
      this.f.unfall.valid
      ){
        if(this.f.category.value.id == 1){
          return true;
        } else if(this.f.category.value.id != 1 && this.f.firstRegistrationDate.invalid){
          return false;
        } else return true;
      }
    return false;
  }

  checkValidPageTwo(): boolean {
    if(this.f.mileageKm.valid && this.f.power.valid && this.f.price.valid &&
      this.f.ccm.valid && this.f.gears.valid && this.f.cylinder.valid &&
      this.f.co2.valid && this.f.consumption.valid && this.f.consumptionUrban.valid &&
      this.f.consumptionExtraUrban.valid && this.f.nova.valid && this.f.preOwners.valid){
        let valid = true;
        let values = ["sprit", "lieferant_extern", "einkaufsdatum", "vehicle_supplier"];
        for(let value of values) {
          if(this.authenticationService.showCreateValue(value)){
            valid = valid && this.f[value].valid;
          }
        }
        valid = valid && this.f["vehicle_imported"].valid &&
                this.f["steuersatz_pricesheet"].valid;
        return valid;
    }
    return false;
  }

  checkValidPageThree(): boolean {
    if(this.f.ownWeight.valid && this.f.totalWeight.valid && this.f.luggage_space.valid) return true;
    else return false;
  }

  async handleNextPage(pageNr: number, typ: number|undefined): Promise<boolean> {
    if(typ){
      let success = await this.handleCreatAndUpdateVehicle(typ);
      if(success) {
        this.submitted = false;
        this.currentPageCreate = pageNr;
        return true;
      } else {
        return false;
      }
    } else {
      this.submitted = false;
      this.currentPageCreate = pageNr;
      return true;
    }
  }

  async showPreisblatt() {
    this.submitted = true;
    if(this.formGroupVehicle.invalid){
      ErrorHelper.showFormError(this.formGroupVehicle);
      return;
    }
    let success = await this.actionUpdateVehicle(false).toPromise();
    if(success) this.selectionPrintPricesheet();
  }

  async validateInputUpdate() {
    this.submitted = true;
    if(this.formGroupVehicle.invalid){
      ErrorHelper.showFormError(this.formGroupVehicle);
      return;
    }
    if(this.formGroupVehicle.get('rent_typ').value == 1){ //'Abo'
      for(let rentForm of this.formGroupVehicle.get('rent_forms').value){
        if(rentForm.invalid){
          ErrorHelper.showFormError(rentForm);
          return;
        }
      }
    }
    let success = await this.actionUpdateVehicle().toPromise();
    if(success) this.closeEditAfterUpdate();

    // this.handleCreatAndUpdateVehicle(2).then((success) => {
    //   if(success){
    //     this.closeEditAfterUpdate();
    //   }
    // });
  }

  presetGuarantee(vehicle: Vehicle): Vehicle {
    vehicle.guarantee = true;
    return vehicle;
  }

  actionUpdateVehicle(showSuccess: boolean = true): Observable<any> {
    return defer(async () => {

      // Create the vehicle object from the form
      let vehicle: Vehicle = VehicleForm.createObject(this.formGroupVehicle);
      vehicle.priceSheet = this.selectedVehicle?.priceSheet;

      // Handle the logic for setting vehicle properties based on priceSheet.steuersatz
      if(vehicle.priceSheet){
        vehicle = this.presetGuarantee(vehicle);
        if(vehicle.priceSheet.steuersatz == SteuersatzValue.DIFFERENZSTEUER){
          vehicle.vat_reportable = false;
          vehicle.leasable = false;
        } else if(vehicle.priceSheet.steuersatz == SteuersatzValue.UMSATZSTEUER){
          vehicle.vat_reportable = true;
          vehicle.leasable = true;
        }
      }

      // Set up the price sheet form
      this.pricesheetForm = PriceSheetForm.getPriceSheetForm(this.formBuilder, this.settings, this.authenticationService.isStandard(), vehicle.priceSheet, vehicle.price);
      this.pricesheetForm.controls['vehicle_supplier'].setValue(vehicle.vehicle_supplier);
      this.pricesheetForm.controls['sprit'].setValue(vehicle.sprit);
      this.pricesheetForm.controls['lieferant_extern'].setValue(vehicle.lieferant_extern);
      this.pricesheetForm.controls['einkaufspreis_brutto'].setValue(vehicle.einkaufspreis_pricesheet);
      this.pricesheetForm.controls['steuersatz'].setValue(vehicle.steuersatz_pricesheet);
      this.pricesheetForm.controls['steuersatz_nach'].setValue(vehicle.steuersatz_pricesheet);
      let pricesheet = PriceSheetForm.createObject(this.pricesheetForm);
      vehicle.priceSheet = pricesheet;

      //Datensätze die zum Fahrzeug gehören aber im Preisblatt bearbeitet werden.
      vehicle.electrical_distance = pricesheet.electrical_distance;
      vehicle.electrical_distance_checked = pricesheet.electrical_distance_checked;
      vehicle.einkauf_vertragsnummer = pricesheet.einkauf_vertragsnummer;
      vehicle.verkauf_vertragsnummer = pricesheet.verkauf_vertragsnummer;
      vehicle.export_price = pricesheet.export_price;
      vehicle.export_price_checked = pricesheet.export_price_checked;
      vehicle.export_price_enabled = pricesheet.export_price_enabled;

      clearInterval(this.chatgptHandler);
      // If chatgpt_flag is false, fetch vehicle details and update form controls
      if(vehicle.chatgpt_flag != undefined && vehicle.chatgpt_flag == false){
        let result = await this.vService.getSingleVehicle(vehicle.id).toPromise();
        if(result && result.chatgpt_flag == true){
          vehicle.equipments = result.mergeChatgbtEquipments(this.formGroupVehicle);
          this.formGroupVehicle.controls["equipments"].setValue(vehicle.equipments);
          this.vinFlag = false;
        }
      }

      // Update the vehicle and set up the search and page state if necessary
      let success = await this.updateVehicle(vehicle, false, showSuccess);
      if(success && (this.surface == VehicleView.DETAIL || this.currentPageCreate == 9)) {
        this.setUpSearch(false);
        this.currentPageCreate = 1;
        this.surface = VehicleView.DETAIL;
      }

      return success;
    });
  }

  actionCreateVehicle() {
    let vehicle: Vehicle = VehicleForm.createObject(this.formGroupVehicle);
    vehicle.priceSheet = this.selectedVehicle?.priceSheet;

  }

  async handleCreatAndUpdateVehicle(typ: Number): Promise<boolean> {
    let vehicle: Vehicle = VehicleForm.createObject(this.formGroupVehicle);
    vehicle.priceSheet = this.selectedVehicle?.priceSheet;
    if(typ == 1){
      vehicle = this.presetGuarantee(vehicle);
      if(this.formGroupVehicle.controls['steuersatz_pricesheet'].value == 2){
        vehicle.vat_reportable = false;
        vehicle.leasable = false;
      } else if(this.formGroupVehicle.controls['steuersatz_pricesheet'].value == 1){
        vehicle.vat_reportable = true;
        vehicle.leasable = true;
      }
      let success = await this.createVehicle(vehicle);
      this._vehiclesFiltered.next(this.vehicles);
      if(success){
        this.selectedVehicle.einkaufspreis_pricesheet = this.formGroupVehicle.controls['einkaufspreis_pricesheet'].value;
        this.selectedVehicle.steuersatz_pricesheet = this.formGroupVehicle.controls['steuersatz_pricesheet'].value;
        this.selectedVehicle.sprit = this.formGroupVehicle.controls['sprit'].value;
        this.selectedVehicle.lieferant_extern = this.formGroupVehicle.controls['lieferant_extern'].value;
        this.selectedVehicle.einkaufsdatum = this.formGroupVehicle.controls['einkaufsdatum'].value;
        this.selectedVehicle.vehicle_supplier = this.formGroupVehicle.controls['vehicle_supplier'].value;
        this.selectedVehicle.vehicle_imported = this.formGroupVehicle.controls['vehicle_imported'].value;
        this.formGroupVehicle.controls['description'].setValue(this.selectedVehicle.description);
        this.setUpNewPriceSheetForm();
        let obj = PriceSheetForm.createObject(this.pricesheetForm);
        obj.steuersatz_nach = obj.steuersatz;
        this.vService.createPriceSheet(obj).subscribe((result) => {
          if(result){
            obj.price_sheet_id = result.price_sheet_id;
            this.selectedVehicle.priceSheet = obj;
            this.selectedVehicle.dealerPrice = this.selectedVehicle.priceSheet.minimaler_verkaufpreis;
            this.selectedVehicle.price = this.selectedVehicle.priceSheet.preis_anschreiben;
          }
        });
      }
      return success;
    } else if(typ == 2) {
      if(vehicle.priceSheet){
        vehicle = this.presetGuarantee(vehicle);
        if(vehicle.priceSheet.steuersatz == 2){
          vehicle.vat_reportable = false;
          vehicle.leasable = false;
        } else if(vehicle.priceSheet.steuersatz == 1){
          vehicle.vat_reportable = true;
          vehicle.leasable = true;
        }
      }
      this.pricesheetForm = PriceSheetForm.getPriceSheetForm(this.formBuilder, this.settings, this.authenticationService.isStandard(), vehicle.priceSheet, vehicle.price);
      this.pricesheetForm.controls['vehicle_supplier'].setValue(vehicle.vehicle_supplier);
      this.pricesheetForm.controls['sprit'].setValue(vehicle.sprit);
      this.pricesheetForm.controls['lieferant_extern'].setValue(vehicle.lieferant_extern);
      this.pricesheetForm.controls['einkaufspreis_brutto'].setValue(vehicle.einkaufspreis_pricesheet);
      this.pricesheetForm.controls['steuersatz'].setValue(vehicle.steuersatz_pricesheet);
      this.pricesheetForm.controls['steuersatz_nach'].setValue(vehicle.steuersatz_pricesheet);
      let pricesheet = PriceSheetForm.createObject(this.pricesheetForm);
      vehicle.priceSheet = pricesheet;
      //Datensätze die zum Fahrzeug gehören aber im Preisblatt bearbeitet werden.
      vehicle.electrical_distance = pricesheet.electrical_distance;
      vehicle.electrical_distance_checked = pricesheet.electrical_distance_checked;
      vehicle.einkauf_vertragsnummer = pricesheet.einkauf_vertragsnummer;
      vehicle.verkauf_vertragsnummer = pricesheet.verkauf_vertragsnummer;
      vehicle.export_price = pricesheet.export_price;
      vehicle.export_price_checked = pricesheet.export_price_checked;
      vehicle.export_price_enabled = pricesheet.export_price_enabled;
      let success = await this.updateVehicleCreate(vehicle);
      return success;
    } else {
      return false;
    }
  }

  async updateVehicleCreate(vehicle): Promise<boolean> {
    clearInterval(this.chatgptHandler);
    if(vehicle.chatgpt_flag != undefined && vehicle.chatgpt_flag == false){
      let result = await this.vService.getSingleVehicle(vehicle.id).toPromise();
      if(result && result.chatgpt_flag == true){
        vehicle.equipments = result.mergeChatgbtEquipments(this.formGroupVehicle);
        this.formGroupVehicle.controls["equipments"].setValue(vehicle.equipments);
        this.vinFlag = false;
      }
    }
    let success = await this.updateVehicle(vehicle, false);
    if(success && (this.surface == VehicleView.DETAIL || this.currentPageCreate == 9)) {
      this.setUpSearch(false);
      this.currentPageCreate = 1;
      this.surface = VehicleView.DETAIL;
    }
    return success;
  }

  createVehicle(vehicle: Vehicle): Promise<boolean> {
    return new Promise<boolean>((resolve,reject) => {
      this.vService.createVehicle(vehicle).subscribe((createV) => {
        if(createV){
          this.submitted = false;
          this.selectedVehicle = createV;
          this.vehicles.unshift(createV);
          this.formGroupVehicle.controls['vehicle_id'].setValue(createV.id);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  startGetVehicleLoop() {
    this.chatgptHandler = setInterval((): void => {
      console.log("checking chatgpt flag...");
      this.vService.getSingleVehicle(this.selectedVehicle.id).subscribe((result) => {
        if(result){
          if(result.chatgpt_flag == true){
            result.equipments = result.mergeChatgbtEquipments(this.formGroupVehicle);
            this.formGroupVehicle.controls["equipments"].setValue(result.equipments);
            this.selectedVehicle = result;
            clearInterval(this.chatgptHandler);
            this.vinFlag = false;
          }
        }
      });
    }, 10000);
  }

  createAttachment(attachment: Attachment): Promise<Attachment> {
    return new Promise<Attachment>((resolve,reject) => {
      this.attachmentService.createAttachment(attachment).subscribe((result) => {
        if(result){
          resolve(attachment);
        } else {
          resolve(null);
        }
      })
    });
  }

  updateVehicle(vehicle: Vehicle, force: boolean, showSuccess: boolean = true): any {
    return new Promise<boolean>((resolve,reject) => {
      let promises: any[] = [];
      this.vService.updateVehicleSafe(vehicle, force).subscribe((result) => {
        if(result){
          if(result?.conflicts != null){
            this.updateConflicts = result.conflicts;
            resolve(false);
          } else {
            result.thumbnail = vehicle.thumbnail;
            result.steuersatz_pricesheet = vehicle.steuersatz_pricesheet;
            vehicle = result;
            if((this.currentPageCreate == 9 && this.f.attachments.value) || this.editMode){
              for(let attachment of this.f.attachments.value){
                if(!attachment.attachment_id){
                  let a = new Attachment(
                    0,
                    1,
                    vehicle.id,
                    attachment.format,
                    attachment.name,
                    attachment.data,
                  )
                  promises.push(this.attachmentService.createAttachment(a).subscribe((result_a) => {
                    vehicle.attachments.push(a);
                  }));
                }
              }
              Promise.all(promises).then(() => {
                this.updatePictures(vehicle);
                this.endUpUpdateVehicle(vehicle, showSuccess);
                resolve(true);
              })
            } else {
              this.endUpUpdateVehicle(vehicle, showSuccess);
              resolve(true);
            }
          }
        } else {
          resolve(false);
        }
      })
    });
  }

  async updatePictures(vehicle: Vehicle) {
    if(this.formGroupVehicle.get('pictures_changed').value == true){
      let toHandlePictures = this.comparePictureArrays(
        vehicle.pictures,
        this.formGroupVehicle.get('pictures').value
      );
      //create
      const createdPictures = [];
      for (let p of toHandlePictures.toCreate) {
        let createdPicture = await this.createVehiclePicture(p).toPromise();
        createdPictures.push(createdPicture);
      }
      //update (pos)
      for(let p of toHandlePictures.toUpdate){
        await this.updateVehiclePicture(p).toPromise();
      }
      //delete
      for(let p of toHandlePictures.toDelete){
        await this.deleteVehiclePicture(p).toPromise()
      }
      vehicle.pictures = CloneObject.deepCopy(toHandlePictures.toIgnore);
      if(createdPictures.length > 0) {
        vehicle.pictures.push(...createdPictures);
      }
      if(toHandlePictures.toUpdate.length > 0) {
        vehicle.pictures.push(...toHandlePictures.toUpdate);
      }
      if(toHandlePictures.toDelete.length > 0) {
        for(let p of toHandlePictures.toDelete) {
          let index = vehicle.pictures.findIndex((pic) => pic.picture_id == p.picture_id);
          if (index > -1) {
            vehicle.pictures.splice(index, 1);
          }
        }
      }
      vehicle.pictures.sort((a,b) => a.position - b.position);
    }
    // for image-web component
    this.updateSuccess.next(true);
  }

  createVehiclePicture(picture: Picture): Observable<Picture> {
    return this.vService.createVehiclePicture(picture).pipe(
      map((event: HttpEvent<any>) => {
        if(event){
          const response = event as HttpResponse<any>;
          const body = response.body;
          if(body){
            let newPic = new Picture(
              body.pictures[body.pictures.length - 1].picture_id,
              body.pictures[body.pictures.length - 1].position,
              picture.obj_id,
              picture.src
            );
            return newPic;
          } else {
            return null;
          }
        }
      }),
      catchError(() => of(null))
    );
  }

  deleteVehiclePicture(pic): Observable<boolean> {
    return this.vService.deletePicture(pic).pipe(
      map(result => !!result),
      catchError(() => of(false))
    );
  }

  updateVehiclePicture(picture: Picture): Observable<any> {
    return this.vService.updateVehiclePicture(picture).pipe(
      map(result => !!result),
      catchError(() => of(false))
    );
  }

  comparePictureArrays(oldPictures: Picture[], newPictures: Picture[]) {
    const toDelete = [];
    const toCreate = [];
    const toUpdate = [];
    const toIgnore = [];

    const oldMap = new Map(oldPictures.map(pic => [pic.picture_id, pic]));
    const newMap = new Map(newPictures.map(pic => [pic.picture_id, pic]));

    // Find missing pictures
    for(const oldPic of oldPictures) {
      if(!newMap.has(oldPic.picture_id)) {
        toDelete.push(oldPic);
      }
    }

    // Find added and updated pictures
    for(const newPic of newPictures) {
      if(!newPic.picture_id) {
        toCreate.push(newPic);
        continue;
      }

      const oldPic = oldMap.get(newPic.picture_id);
      if(!oldPic) {
        // New picture added
        toCreate.push(newPic);
      } else if(oldPic.position !== newPic.position) {
        // Picture exists but has different properties (updated)
        toUpdate.push(newPic);
      } else {
        // Picture is identical
        toIgnore.push(newPic);
      }
    }

    return { toDelete, toCreate, toUpdate, toIgnore };
  }

  async submitConflicts(takeOver: boolean) {
    if(takeOver){
      let vehicle: Vehicle = VehicleForm.createObject(this.formGroupVehicle);
      if(this.surface != VehicleView.KALKULATION) vehicle.priceSheet = this.selectedVehicle.priceSheet;
      else {
        let pricesheet = PriceSheetForm.createObject(this.pricesheetForm);
        vehicle = this.fillVehicleObjectWithCorrespondingPriceSheetData(vehicle, pricesheet);
      }
      let success = await this.updateVehicle(vehicle, true);
      if(success){
        if(this.surface == VehicleView.DETAIL || this.currentPageCreate == 9) {
          this.currentPageCreate = 1;
          this.surface = VehicleView.DETAIL;
          this.closeEditAfterUpdate();
        } else if(this.surface ==VehicleView.KALKULATION){
          this.priceSheetFormChanges = false;
          this.surfacePriceSheet = VehicleView.DETAIL;
        }
      }
    }
    this.updateConflicts = null;
  }

  endUpUpdateVehicle(vehicle: Vehicle, showSuccess: boolean = true) {
    this.transactionService.getTransactions('vehicle', vehicle.id).subscribe((response) => {
      if(response){
        vehicle.transactions = response;
        if(this.formGroupVehicle != null){
          if(this.f.pictures.value){
            vehicle.pictures = this.f.pictures.value;
          }
        }
        let index = this.vehicles.findIndex(v => v.id == vehicle.id);
        if(index > -1){
          this.vehicles[index] = vehicle;
          this._vehiclesFiltered.next(this.vehicles);
        }
        this.resumePagination = true;
        this.rootFilterVehicles();
        setTimeout(() => {
          let scrollbar = document.getElementById('ab-item-list');
          if(scrollbar && this.scrollbarLastPos){
            scrollbar.scrollTop = this.scrollbarLastPos;
          }
        }, 200);
        this.submitted = false;
        this.selectedVehicle = vehicle;
        this.filter = this.selectedVehicle.status;
        this.vehicleTitle = this.getVehicleTitle(this.selectedVehicle);
        this.vehicleSubTitle = this.getVehicleSubTitle(this.selectedVehicle);
        this.locationRoute.replaceState('/cars?subMenuId='+this._activeSubMenu.getValue().sub_menu_id+'&carId='+this.selectedVehicle.id);
        this.readyUpPricesheet();
        if(showSuccess) {
          if(this.currentPageCreate == 10){
            this.dService.showNotification({
              title: 'Erfolgreich',
              message: 'Fahrzeug wurde erstellt und veröffentlicht.',
              success: true
            });
          } else if(this.surface != VehicleView.CREATE) {
            this.dService.showNotification({
              title: 'Erfolgreich',
              message: 'Fahrzeug wurde aktualisiert.',
              success: true
            });
          }
        }
      }
    });
  }

  getVehicleTitle(vehicle: Vehicle): string {
    if(this.authenticationService.isSinhuber()){
      var title = (vehicle.externalId ? vehicle.externalId + " | " : "");
      title += vehicle.brand.makeName + ", " + vehicle.model.version;
      return title;
    }
    var title = vehicle.brand.makeName + ', ' +vehicle.model.name;
    return title;
  }

  getVehicleSubTitle(vehicle: Vehicle): string {
    if(this.authenticationService.isSinhuber()){
      let subtitle = vehicle.priceSheet ? (vehicle.priceSheet?.vehicle_serialnumber ? vehicle.priceSheet.vehicle_serialnumber:"---"):"---";
      subtitle += " | " + (vehicle.category?.id == 1 ? "---" : formatDate(new Date(vehicle.firstRegistrationDate), 'dd.MM.yyyy', 'de'));
      subtitle += " | " + vehicle.mileageKm + " km | " + (vehicle.fin_number ? vehicle.fin_number : "---");
      return subtitle;
    }
    let subtitle = vehicle.bodyTyp?.name + ' | ' +
      vehicle.fuel?.name + ' | ' +
      (vehicle.category?.id == 1 ? "---" : formatDate(new Date(vehicle.firstRegistrationDate), 'dd.MM.yyyy', 'de')) + ' | ' +
      formatNumber(vehicle.mileageKm,"de","1.0-0") + ' km | ' +
      vehicle.powerPs + ' PS | ' +
      vehicle.transmission?.name + ' | ' +
      vehicle.numOfSeats + ' Sitze | ' +
      vehicle.drive?.name
    return subtitle;
  }

  getThumbnails(): Promise<boolean> {
    let promises: any[] = [];
    return new Promise<boolean>((resolve, reject) => {
      for(let v of this.vehicles){
        promises.push(this.getThumbnail(v).then().catch());
      }
      Promise.all(promises).then(() => {
        resolve(true);
      }).catch((error) => {
        console.log(error);
        reject(false);
      });
    })
  }

  getThumbnail(vehicle: Vehicle): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if(!vehicle.thumbnail && vehicle.pictures.length != 0){
        this.vService.getVehicleThumbnail(vehicle.pictures[0].picture_id).subscribe((result) => {
          if(result){
            vehicle.thumbnail = 'data:image/png;base64,'+result.trim();
            resolve(true);
          }
        })
      } else {
        resolve(null);
      }
    })
  }

  deleteAction(vehicle: Vehicle) {
    this.dService.openQuery(
      {
        title: 'Fahrzeug wirklich löschen?',
        message: 'Sind sie sicher, dass Sie das Fahrzeug ' + vehicle.brand.makeName + ', ' + vehicle.model.name + ', ' + vehicle.model.version + ' unwiderruflich löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete',
        submit_value: vehicle.id,
      }
    );
  }

  deleteVehicle(id: number){
    this.vService.deleteVehicle(id).subscribe((success) => {
      if(success){
        let index1 = this.vehicles.findIndex(v => v.id == id);
        if(index1 != -1){
          this.vehicles.splice(index1, 1);
        }
        let index2 = this._vehiclesFiltered.getValue().findIndex(v => v.id == id);
        if(index2 != -1){
          this._vehiclesFiltered.getValue().splice(index2, 1);
        }
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Fahrzeug gelöscht',
          success: true
        });
      }
    })
  }

  async openDetails(vehicle: any, openEdit?: boolean) {
    this.loadingService.startLoading();
    let scrollbar = document.getElementById('ab-item-list');
    if(scrollbar){
      this.scrollbarLastPos = scrollbar.scrollTop;
    }
    this.formGroupVehicle = null;
    this.submitted = false;
    this.isNewPriceSheet = false;
    this.surfacePriceSheet = VehicleView.DETAIL;
    if(!openEdit){
      let a = [];
      for(let attachment of vehicle.attachments){
        if(attachment.attachment_id && attachment.attachment_id != 0){
          a.push(await this.getAttachment(attachment.attachment_id));
        }
      }
      vehicle.attachments = a;
    }
    this.transactionService.getTransactions('vehicle', vehicle.id).subscribe((response) => {
      if(response){
        vehicle.transactions = response;
        this.selectedVehicle = vehicle;
        this.prepareForm(vehicle);
        this.vehicleTitle = this.getVehicleTitle(this.selectedVehicle);
        this.vehicleSubTitle = this.getVehicleSubTitle(this.selectedVehicle);
        if(openEdit){
          this.editMode = true;
          this.selectedDetailsTab = 1;
          this.locationRoute.replaceState('/cars?subMenuId='+this._activeSubMenu.getValue().sub_menu_id+'&carId='+this.selectedVehicle.id);
          if(this.selectedVehicle.chatgpt_flag != undefined && this.selectedVehicle.chatgpt_flag == false) this.startGetVehicleLoop();
        } else {
          this.editMode = false;
          this.locationRoute.replaceState('/cars?subMenuId='+this._activeSubMenu.getValue().sub_menu_id+'&carId='+this.selectedVehicle.id);
          if(this.chatgptHandler) clearInterval(this.chatgptHandler);
        }
        if(this.selectedVehicle.priceSheet != null && this.selectedVehicle.priceSheet.customer_id > 0){
          let index = this.customers.findIndex(c => c.id == this.selectedVehicle.priceSheet.customer_id);
          if(index > -1) this.selectedVehicle.priceSheet.customer = this.customers[index];
        }
        this.changeDetailsTab(this.selectedDetailsTab);
        this.setPrevoiusNextBtns();
        if(this.selectedVehicle.priceSheet){
          this.formGroupVehicle.controls['sprit'].setValue(this.selectedVehicle.priceSheet.sprit);
          this.formGroupVehicle.controls['lieferant_extern'].setValue(this.selectedVehicle.priceSheet.lieferant_extern);
          this.formGroupVehicle.controls['einkaufspreis_pricesheet'].setValue(this.selectedVehicle.priceSheet.einkaufspreis_brutto);
          this.formGroupVehicle.controls['steuersatz_pricesheet'].setValue(this.selectedVehicle.priceSheet.steuersatz); //Hoedl
          //Hier Daten für Preisblatt wenn in der Fahrzeug detailsansicht geupdated werden hinzufügen
        }
        this.loadingService.stopLoading();
      } else {
        this.loadingService.stopLoading();
      }
    });
  }

  getAttachment(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.attachmentService.getAttachment(id).subscribe((result) => {
        if(result){
          resolve(result);
        }
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  closeEditAfterUpdate() {
    if(this.chatgptHandler) clearInterval(this.chatgptHandler);
    this.editMode = false;
    this.editTyp = 1;
    this.surface = VehicleView.DETAIL;
    this.surfacePriceSheet = 3;
    this.submitted = false;
    this.vinFlag = false;
    new ReloadDataHandler().activateReloadLoop([this.vService]);
  }

  closeDetails() {
    if(this.chatgptHandler) clearInterval(this.chatgptHandler);
    this.formGroupVehicle = null;
    this.selectedVehicle = null;
    this.editMode = false;
    this.editTyp = 1;
    this.surface = VehicleView.LIST;
    this.surfacePriceSheet = 3;
    this.submitted = false;
    this.vinFlag = false;
    var url = this.router.createUrlTree([], { relativeTo: this.activateRoute}).toString();
    this.router.navigateByUrl(url);
    this.setUpSearch(false);
    //this.applySearch(this._vehiclesFiltered.getValue());
    //this.rootFilterVehicles();
    setTimeout(() => {
      let scrollbar = document.getElementById('ab-item-list');
      if(scrollbar && this.scrollbarLastPos){
        scrollbar.scrollTop = this.scrollbarLastPos;
      }
    }, 200);
  }

  cancelCreateAction(){
    if(this.currentPageCreate != 1){
      this.dService.openQuery(
        {
          title: 'Vorgang abbrechen',
          message: 'Sind Sie sicher, dass Sie den Vorgang abbrechen möchten? Ihr Fahrzeug wird dann nicht gespeichert.',
          btn_cancel_txt: 'zurück',
          btn_submit_txt: 'Ja, abbrechen',
          typ: 'cancel',
          submit_value: 0,
        }
      );
    } else {
      this.surface = VehicleView.LIST;
      this.locationRoute.replaceState('/cars');
      this.submitted = false;
      new ReloadDataHandler().activateReloadLoop([this.vService]);
    }
  }

  cancelCreate() {
    if(this.selectedVehicle){
      this.vService.deleteVehicle(this.selectedVehicle.id).subscribe((success) => {
        if(success){
          let index = this.vehicles.findIndex(v => v.id == this.selectedVehicle.id);
          if(index > -1){
            this.vehicles.splice(index, 1);
          }
          this._vehiclesFiltered.next(this.vehicles);
          this.selectedVehicle = null;
          this.sortVehicles('date');
        }
      })
    }
    this.setUpSearch(false);
    this.formGroupVehicle = null;
    this.currentPageCreate = 1;
    this.vehicleTitle = '';
    this.vehicleSubTitle = '';
    this.surface = VehicleView.LIST;
    this.submitted = false;
    this.locationRoute.replaceState('/cars');
    new ReloadDataHandler().activateReloadLoop([this.vService]);
  }

  getTicketsFromVehicle() {
    this.tService.getOpenThreadsByTyp('vehicle', this.selectedVehicle.id).subscribe((result) => {
      if(result){
        if(this.employees.length == 0){
          this.eService.getEmployees().subscribe((employeesData) => {
            if(employeesData){
              this.employees = employeesData;
              for(let thread of result){
                for(let employee of this.employees){
                  if(thread.creator == employee.id){
                    thread.employee = employee;
                  }
                }
              }
              this.selectedVehicle.threads = result;
            }
          })
        } else {
          for(let thread of result){
            for(let employee of this.employees){
              if(thread.creator == employee.id){
                thread.employee = employee;
              }
            }
          }
          this.selectedVehicle.threads = result;
        }
        this.surface = VehicleView.TICKET;
      }
    })
  }

  openEdit(edit: boolean) {
    this.editTyp = 1;
    this.prepareForm();
    if(edit){
      new ReloadDataHandler().deactivateReloadLoop([this.vService]);
      if(this.chatgptHandler) clearInterval(this.chatgptHandler);
      this.openDetails(this.selectedVehicle, true);
    }
  }

  prepareForm(vehicle?: Vehicle) {
    this.formGroupVehicle = VehicleForm.getVehicleForm(this.formBuilder, vehicle);
    this.formGroupVehicle.controls["category"].valueChanges.subscribe((changes) => {
      if(this.formGroupVehicle.controls["category"].value?.id != 1){
          this.formGroupVehicle.controls['firstRegistrationDate'].setValidators(Validators.required);
      } else {
          this.formGroupVehicle.controls['firstRegistrationDate'].removeValidators(Validators.required);
          this.formGroupVehicle.controls['firstRegistrationDate'].setErrors(null);
      }
    })
    this.readyUpPricesheet();
  }

  openEditFromList(vehicle: Vehicle) {
    if(vehicle){
      this.selectedVehicle = vehicle;
      this.openDetails(this.selectedVehicle, true);
    }
  }

  selectionCancelEditVehicle() {
    this.dService.openQuery(
      {
        title: 'Bearbeitung abbrechen',
        message: 'Sind sie sicher, dass Sie die Bearbeitung abbrechen möchten? Alle Änderungen gehen dadurch verloren.',
        btn_cancel_txt: 'Weiter bearbeiten',
        btn_submit_txt: 'Beenden',
        typ: 'cancel_vehicle_edit',
        submit_value: null,
      }
    );
  }

  cancelEdit() {
    if(this.chatgptHandler) clearInterval(this.chatgptHandler);
    this.vinFlag = false;
    this.locationRoute.replaceState('/cars?subMenuId='+this._activeSubMenu.getValue().sub_menu_id+'&carId='+this.selectedVehicle.id);
    // window.removeEventListener("beforeunload", this.beforeUnloadListerner, {capture:true});
    this.editTyp = 1;
    this.editMode = false;
    this.createViaVin = false;
    this.updateSuccess.next(false);
    new ReloadDataHandler().activateReloadLoop([this.vService]);
  }

  async saveAsDraft() {
    if(this.selectedVehicle){
      this.f.status.setValue(VehicleStatus.Draft);
      let successUpdate = await this.actionUpdateVehicle();
      if(successUpdate){
        this.currentPageCreate = 1;
        this.surface = VehicleView.LIST;
        new ReloadDataHandler().activateReloadLoop([this.vService]);

      }
    } else if(this.checkValidPageVINMissingData()){
      this.formGroupVehicle.controls['status'].setValue(2);
      let done = await this.handleCreatAndUpdateVehicle(1);
      if(done){
        this.selectedVehicle.dealerPrice = this.selectedVehicle.priceSheet?.minimaler_verkaufpreis;
        this.selectedVehicle.price = this.selectedVehicle.priceSheet?.preis_anschreiben;
        this.createViaVin = false;
        this.openEdit(true);
        this.dService.showNotification({
          title: 'Fahrzeug erstellt',
          message: 'Fahrzeug wurde als Entwurf gespeichert.',
          success: true
        });
      }
    } else {
      this.submitted = true;
      ErrorHelper.showFormError(this.formGroupVehicle);
    }
  }

  openSellDialog(data: any) {
    let vehicle = data;
    this.dService.openQuery(
      {
        title: 'Fahrzeug verkauft?',
        message: 'Sind sie sicher, dass Sie das Fahrzeug ' + vehicle.brand.makeName + ', ' + vehicle.model.name + ', ' + vehicle.model.version + ' auf verkauft setzen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Verschieben',
        typ: 'sell',
        submit_value: vehicle,
      }
    );
  }

  moveVehicleAction(data: any) {
    let vehicle = data.vehicle;
    let status = data.status;
    if(status == 1){
      this.dService.openQueryCarOnline(
        {
          title: 'Fahrzeug online stellen?',
          message: 'Sind sie sicher, dass Sie das Fahrzeug ' + vehicle.brand.makeName + ', ' + vehicle.model.name + ', ' + vehicle.model.version + ' veröffentlichen möchten?',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Veröffentlichen',
          typ: 'publish_vehicle',
          submit_value: vehicle,
        }
      );
    } else if(status == 2) {
      this.dService.openQuery(
        {
          title: 'Fahrzeug auf Entwurf setzen?',
          message: 'Sind sie sicher, dass Sie das Fahrzeug ' + vehicle.brand.makeName + ', ' + vehicle.model.name + ', ' + vehicle.model.version + ' auf Entwurf setzen möchten?',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Verschieben',
          typ: 'draft_customer_vehicle',
          submit_value: vehicle,
        }
      );
    } else {
      this.moveVehicle(vehicle, status);
    }
    // } else if(status == 4) { //Kundenfahrzeug
    //   this.dService.openQuery(
    //     {
    //       title: 'Kundenfahrzeug als Entwurf setzen?',
    //       message: 'Sind sie sicher, dass Sie das Fahrzeug ' + vehicle.brand.makeName + ', ' + vehicle.model.name + ', ' + vehicle.model.version + ' als Entwurf setzen möchten? Somit wird der Kunde vom Fahrzeug entfernt.',
    //       btn_cancel_txt: 'Abbrechen',
    //       btn_submit_txt: 'Verschieben',
    //       typ: 'draft_customer_vehicle',
    //       submit_value: vehicle,
    //     }
    //   );
  }

  sellVehicle(vehicle: Vehicle) {
    vehicle.customer_id = null;
    vehicle.website_online = false;
    vehicle.freigeschaltet_am_web = undefined;
    vehicle.freigeschaltet_am_plattform = undefined;
    vehicle.platform_online = false;
    vehicle.mobile_online = false;
    vehicle.status = 3;
    this.vService.updateVehicleSafe(vehicle).subscribe((response) => {
      if(response){
        if(response.pictures.length != 0){
          this.vService.getVehicleThumbnail(response.pictures[0].id).subscribe((result) => {
            if(result){
              response.thumbnail = 'data:image/png;base64,'+result.trim();
              let index = this.vehicles.findIndex(v => v.id == response.id);
              if(index > -1) this.vehicles[index] = response;
              let temp = this._vehiclesFiltered.getValue();
              let index2 = temp.findIndex(v => v.id == response.id);
              if(index2 > -1) temp[index] = response;
              this._vehiclesFiltered.next(temp);
              this.rootFilterVehicles();
            }
          })
        } else {
          let index = this.vehicles.findIndex(v => v.id == response.id);
          if(index > -1) this.vehicles[index] = response;
          let temp = this._vehiclesFiltered.getValue();
          let index2 = temp.findIndex(v => v.id == response.id);
          if(index2 > -1) temp[index] = response;
          this._vehiclesFiltered.next(temp);
          this.rootFilterVehicles();
        }
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Fahrzeug verkauft.',
            success: true
          });
      }
    })
  }

  moveVehicle(vehicle: Vehicle, status: number, export_web?: boolean, export_platform?: boolean, price?: number, export_mobile?: boolean) {
    vehicle.status = status;
    if(status === 1){
      vehicle.website_online = export_web;
      vehicle.platform_online = export_platform;
      vehicle.mobile_online = export_mobile;
      vehicle.price = price;
    }
    this.vService.updateVehicleSafe(vehicle, true).subscribe((response) => {
      if(response){
        if(response.pictures.length != 0){
          this.vService.getVehicleThumbnail(response.pictures[0].id).subscribe((result) => {
            if(result){
              response.thumbnail = 'data:image/png;base64,'+result.trim();
              let index = this.vehicles.findIndex(v => v.id == response.id);
              if(index > -1) this.vehicles[index] = response;
              let temp = this._vehiclesFiltered.getValue();
              let index2 = temp.findIndex(v => v.id == response.id);
              if(index2 > -1) temp[index] = response;
              this._vehiclesFiltered.next(temp);
              this.rootFilterVehicles();
            }
          })
        } else {
          let index = this.vehicles.findIndex(v => v.id == response.id);
          if(index > -1) this.vehicles[index] = response;
          let temp = this._vehiclesFiltered.getValue();
          let index2 = temp.findIndex(v => v.id == response.id);
          if(index2 > -1) temp[index] = response;
          this._vehiclesFiltered.next(temp);
          this.rootFilterVehicles();
        }
        if(status == 1){
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Fahrzeug wurde veröffentlicht.',
            success: true
          });
        } else if(status == 2){
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Fahrzeug wurde wiederhergestellt.',
            success: true
          });
        } else if(status == 3) {
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Fahrzeug wurde archiviert.',
            success: true
          });
        }
      }
    })
  }

  validateNumberOnlyInteger(c: UntypedFormControl) {
    return c.value % 1 == 0 ? null : {
      validateNumberOnlyInteger: {
        valid: false
      }
    };
  }
  validateDateInput(c: UntypedFormControl): any {
    const date_regex = /^\d{2}\/\d{4}$/;
    if(c.value && c.value._f == 'MM/YYYY'){
      if(date_regex.test(c.value._i)){
        let today = new Date();
        let values = c.value._i.split('/');
        let input = new Date(values[1],values[0]);
        if(input.getTime() < today.getTime()){
          return null;
        }
      }
    } else if(c.value && moment.isMoment(c.value)){
      return null;
    } else if(c.value == null){
      return null;
    }
    return {
      validateDateInput: {
        valid: false
      }
    }
  }
  validateDateInputNotPast(c: UntypedFormControl): any {
    const date_regex = /^\d{2}\/\d{4}$/;
    if(c.value && c.value._f == 'MM/YYYY'){
      if(date_regex.test(c.value._i)){
        let today = new Date();
        let values = c.value._i.split('/');
        let input = new Date(values[1],values[0]);
        if(input.getTime() > today.getTime()){
          return null;
        }
      }
    } else if(c.value && moment.isMoment(c.value)){
      return null;
    } else if(c.value == null){
      return null;
    }
    return {
      validateDateInput: {
        valid: false
      }
    }
  }
  validateDateInputLong(c: UntypedFormControl): any {
    const pattern = /^(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}/;
    // const date_regex = /^\d{2}\.\d{2}\.\d{4}$/;
    if(c.value && c.value._f == 'DD.MM.YYYY'){
      if(pattern.test(c.value._i)){
        let today = new Date();
        let values = c.value._i.split('.');
        let input = new Date(values[2],(Number(values[1])-1),Number(values[0]));
        if(input.getTime() < today.getTime()){
          return null;
        }
      } else {
        return {
          validateDateInput: {
            valid: false
          }
        }
      }
    } else if(c.value && moment.isMoment(c.value)){
      return null;
    } else if(c.value == null){
      return null;
    }
    return {
      validateDateInput: {
        valid: false
      }
    }
  }

  removeFilterValue(value: string) {
    this.filterForm.controls[value].setValue(null);
    this.filterDetails();
  }

  filterDetails() {
    let query = {
      brand: this.filterForm.controls.brand.value,
      model: this.filterForm.controls.model.value,
      bodyColor: this.filterForm.controls.color.value,
      mileageKm: this.filterForm.controls.mileage_from.value || this.filterForm.controls.mileage_to.value ? true : false,
      firstRegistrationDate: this.filterForm.controls.firstregistration_date_from.value || this.filterForm.controls.firstregistration_date_to.value ? true : false,
      percent_typ: this.filterForm.controls.percent_typ.value
    }
    if(this.filterForm.controls.brand.value == null &&
      this.filterForm.controls.model.value == null &&
      this.filterForm.controls.color.value == null &&
      this.filterForm.controls.mileage_from.value == null &&
      this.filterForm.controls.mileage_to.value == null &&
      this.filterForm.controls.firstregistration_date_from.value == null &&
      this.filterForm.controls.percent_typ.value == null){
      this.filterForm.controls.is_set.setValue(false);
    } else this.filterForm.controls.is_set.setValue(true);
    this._vehiclesFiltered.next(this.vehicles);
    let result = this._vehiclesFiltered.getValue().filter((item) => {
      for(let key in query){
        if(key == 'percent_typ') {
          let status = item.getStatus();
          if(query[key] && query[key] == 1 && status < 90) return false;
          if(query[key] && query[key] == 2 && (status > 89 || status < 60)) return false;
          if(query[key] && query[key] == 3 && status > 59) return false;
        }
        if(item[key] === undefined && key != 'percent_typ'){
          return false;
        } else {
          if(query[key] && key == 'brand' && item[key].id != query[key].id){
            return false;
          } else if(query[key] && key == 'model' && item[key].id != query[key].id) {
            return false;
          } else if(query[key] && key == 'bodyColor' && item[key].id != query[key].id) {
            return false;
          } else if(query[key] && key == 'mileageKm' && query[key] === true) {
            if(this.filterForm.controls.mileage_from.value && this.filterForm.controls.mileage_to.value) {
              if(item[key] < this.filterForm.controls.mileage_from.value || item[key] > this.filterForm.controls.mileage_to.value) return false;
            } else if(this.filterForm.controls.mileage_from.value && item[key] < this.filterForm.controls.mileage_from.value){
              return false;
            } else if(this.filterForm.controls.mileage_to.value && item[key] > this.filterForm.controls.mileage_to.value){
              return false;
            }
          } else if(query[key] && key == 'firstRegistrationDate' && query[key] === true){
            if(this.filterForm.controls.firstregistration_date_from.value && this.filterForm.controls.firstregistration_date_to.value) {
              if(item[key] < this.filterForm.controls.firstregistration_date_from.value || item[key] > this.filterForm.controls.firstregistration_date_to.value) return false;
            } else if(this.filterForm.controls.firstregistration_date_from.value && item[key] < this.filterForm.controls.firstregistration_date_from.value){
              return false;
            } else if(this.filterForm.controls.firstregistration_date_to.value && item[key] > this.filterForm.controls.firstregistration_date_to.value){
              return false;
            }
          }
        }
      }
      return true;
    })
    this._vehiclesFiltered.next(result)
    this.setPages();
  }

  resetFilterEmitter() {
    this.filterForm.reset();
    this.filterDetails();
  }

  sortEmitter() {
    this.sortObject.sorting_is_set = true;
    this._vehiclesFiltered.getValue().sort((a,b) => {
      if(this.sortObject.sort_after_value == SortTyp.PREIS) {
        if(a.price > b.price) return this.sortObject.sort_asc ? 1 : -1;
        if(a.price < b.price) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.KM) {
          if(a.mileageKm > b.mileageKm) return this.sortObject.sort_asc ? 1 : -1;
          if(a.mileageKm < b.mileageKm) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.ID) {
        if(a.externalId > b.externalId) return this.sortObject.sort_asc ? 1 : -1;
        if(a.externalId < b.externalId) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.MARKE) {
        if(a.brand.makeName > b.brand.makeName) return this.sortObject.sort_asc ? 1 : -1;
        if(a.brand.makeName < b.brand.makeName) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.MODELL) {
        if(a.model.version > b.model.version) return this.sortObject.sort_asc ? 1 : -1;
        if(a.model.version < b.model.version) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.DATEN) {
        if(a.getStatus() > b.getStatus()) return this.sortObject.sort_asc ? 1 : -1;
        if(a.getStatus() < b.getStatus()) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.FARBE) {
        if(a.bodyColor.name > b.bodyColor.name) return this.sortObject.sort_asc ? 1 : -1;
        if(a.bodyColor.name < b.bodyColor.name) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.EZ) {
        if(a.firstRegistrationDate > b.firstRegistrationDate) return this.sortObject.sort_asc ? 1 : -1;
        if(a.firstRegistrationDate < b.firstRegistrationDate) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.BEARBEITET) {
        if(a.lastUpdated > b.lastUpdated) return this.sortObject.sort_asc ? 1 : -1;
        if(a.lastUpdated < b.lastUpdated) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.TREIBSTOFF) {
        if(a.fuel.name > b.fuel.name) return this.sortObject.sort_asc ? 1 : -1;
        if(a.fuel.name < b.fuel.name) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.ANTRIEB) {
        if(a.drive.name > b.drive.name) return this.sortObject.sort_asc ? 1 : -1;
        if(a.drive.name < b.drive.name) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.PS) {
        if(a.powerPs > b.powerPs) return this.sortObject.sort_asc ? 1 : -1;
        if(a.powerPs < b.powerPs) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.KAUFDATUM) {
        if(this.getInputValueDate(a.priceSheet?.einkaufsdatum) > this.getInputValueDate(b.priceSheet?.einkaufsdatum)) return this.sortObject.sort_asc ? 1 : -1;
        if(this.getInputValueDate(a.priceSheet?.einkaufsdatum) < this.getInputValueDate(b.priceSheet?.einkaufsdatum)) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.VERKAUFSDATUM) {
        if(this.getInputValueDate(a.priceSheet?.verkaufsdatum) > this.getInputValueDate(b.priceSheet?.verkaufsdatum)) return this.sortObject.sort_asc ? 1 : -1;
        if(this.getInputValueDate(a.priceSheet?.verkaufsdatum) < this.getInputValueDate(b.priceSheet?.verkaufsdatum)) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.EINKAUFSPREIS) {
        if(this.getInputValueCurrency(a.priceSheet?.einkaufspreis_brutto) > this.getInputValueCurrency(b.priceSheet?.einkaufspreis_brutto)) return this.sortObject.sort_asc ? 1 : -1;
        if(this.getInputValueCurrency(a.priceSheet?.einkaufspreis_brutto) < this.getInputValueCurrency(b.priceSheet?.einkaufspreis_brutto)) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.VERKAUFSPREIS) {
        if(this.getInputValueCurrency(a.priceSheet?.verkaufspreis_brutto) > this.getInputValueCurrency(b.priceSheet?.verkaufspreis_brutto)) return this.sortObject.sort_asc ? 1 : -1;
        if(this.getInputValueCurrency(a.priceSheet?.verkaufspreis_brutto) < this.getInputValueCurrency(b.priceSheet?.verkaufspreis_brutto)) return this.sortObject.sort_asc ? -1 : 1;
      } else if(this.sortObject.sort_after_value == SortTyp.STANDZEIT) {
        if(this.getInputValue(a.priceSheet?.getIstStandzeit()) > this.getInputValue(b.priceSheet?.getIstStandzeit())) return this.sortObject.sort_asc ? 1 : -1;
        if(this.getInputValue(a.priceSheet?.getIstStandzeit()) < this.getInputValue(b.priceSheet?.getIstStandzeit())) return this.sortObject.sort_asc ? -1 : 1;
      }
      return 0;
    });
  }

  resetSortEmitter() {
    this.sortObject.sort_after_value = 1;
    this.sortObject.sort_asc = false;
    this.sortObject.sorting_is_set = false;
    this.sortVehicles('date');
  }

  getVehicleByVin(vin: string) {
    this.vinFlag = true;
    this.vinService.getVehicleByVin(vin).subscribe((result) => {
      if(result) {
        result.unfall = false;
        this.createViaVin = true;
        result.firstRegistrationDate = VehicleForm.getTimestampFromInputDate(this.formGroupVehicle.controls["firstRegistrationDate"].value);
        this.selectedVehicle = result;
        this.prepareForm(result);
        this.formGroupVehicle.controls['chatgpt_flag'].setValue(false);
        this.formGroupVehicle.controls["steuersatz_pricesheet"].clearValidators();
        this.formGroupVehicle.controls['steuersatz_pricesheet'].updateValueAndValidity();
        this.formGroupVehicle.controls['steuersatz_pricesheet'].setValidators(Validators.required);
        this.formGroupVehicle.controls["einkaufspreis_pricesheet"].clearValidators();
        this.formGroupVehicle.controls['einkaufspreis_pricesheet'].updateValueAndValidity();
        this.formGroupVehicle.controls['einkaufspreis_pricesheet'].setValidators(Validators.required);
        this.vehicleTitle = this.getVehicleTitle(this.selectedVehicle);// this.selectedVehicle.brand?.makeName + ', ' + this.selectedVehicle.model.name + ', ' + this.selectedVehicle.model.version;
        this.vehicleSubTitle = this.getVehicleSubTitle(this.selectedVehicle);
        this.selectedVehicle = null;
        if(!this.checkValidPageVINMissingData()){
          this.currentPageCreate = 10;
        } else {

        }
      }
    });
  }
  sortAction() {
    if(this.sortObject.sort_after_value == 1){
      this._vehiclesFiltered.getValue().sort((a,b) => {
        if(a.price > b.price) return this.sortObject.sort_asc ? 1 : -1;
        if(a.price < b.price) return this.sortObject.sort_asc ? 1 : -1;
        return 0;
      })
    } else if(this.sortObject.sort_after_value == 2){
      this._vehiclesFiltered.getValue().sort((a,b) => {
        if(a.price > b.price) return this.sortObject.price_des == true ? -1 : 1;
        if(a.price < b.price) return this.sortObject.price_des == true ? 1 : -1;
        return 0;
      })
    }
  }

  selectionCreateDocument(vehicle: Vehicle) {
    this.dService.openCreateDoc(
      { data : vehicle }
    );
  }
  createDocument(vehicle: Vehicle, typ: number) {
    if(typ == 1) this.goToDocumentCreate(vehicle, 2);
    else if(typ == 2) this.goToDocumentCreate(vehicle, 4);
  }
  goToDocumentCreate(vehicle: Vehicle, docTyp: number) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['documents/create'], { queryParams: { typ: DokumentTyp.Kaufvertrag, vehicleId: vehicle.id } }));
  }

  getPricesheet(v: Vehicle): Promise<Vehicle> {
    return new Promise<Vehicle>((resolve, reject) => {
      if(v.priceSheet != null){
        v.steuersatz_pricesheet = v.priceSheet.steuersatz;
        v.priceSheet.setDataFromVehicle(v);
        let tempForm = PriceSheetForm.getPriceSheetForm(this.formBuilder, this.settings, this.authenticationService.isStandard(), v.priceSheet, v.price);
        if(v.priceSheet.employee_id != null){
          let index = this.employees.findIndex(obj => obj.id == v.priceSheet.employee_id);
          if(index > -1){
            v.priceSheet.employee = this.employees[index];
            tempForm.controls["employee"].setValue(this.employees[index]);
          }
        }
        if(v.priceSheet.nachkalkulation){
          tempForm.controls["reparatur_summe_gesamt_vorjahr"].setValue(PriceSheetCalculater.getReparaturGesamt(tempForm.controls["reparaturen_vorjahr"].value));
          tempForm.controls["standzeit_platz"].setValue(PriceSheetCalculater.getStandzeitPlatz(tempForm));
          tempForm.controls["standzeit_geld"].setValue(PriceSheetCalculater.getStandzeitGeld(tempForm));
          tempForm.controls["zinsen_vor"].setValue(PriceSheetCalculater.getZinsenVor(tempForm));
          tempForm.controls["garantie"].setValue(PriceSheetCalculater.getGarantieVor(tempForm, this.authenticationService.isStandard()));
          tempForm.controls["standzeitkosten_vor"].setValue(PriceSheetCalculater.getStandzeitkostenVor(tempForm));
        } else {
          PriceSheetCalculater.setSteuersatzNachkalulation(tempForm);
        }
        PriceSheetForm.calculate(tempForm, this.authenticationService.isStandard());
        v.priceSheet = PriceSheetForm.createObject(tempForm);
        v.isReady = true;
      } else {
        this.setUpNewPriceSheetForm();
        v.isReady = true;
      }
      resolve(v)
    });
  }

  async setUpPriceSheetNew(): Promise<boolean> {
    try {
      const promises = this.vehicles.map(v => {
        this.setUpSinglePriceSheet(v).then(vehicleCalculated => {
          Object.assign(v, vehicleCalculated);
        })
      });
      await Promise.all(promises);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  setUpSinglePriceSheet(vehicle: Vehicle): Promise<Vehicle> {
    return new Promise<Vehicle>((resolve, reject) => {
      if(vehicle.priceSheet != null){
        vehicle.steuersatz_pricesheet = vehicle.priceSheet.steuersatz;
        vehicle.priceSheet.setDataFromVehicle(vehicle);
        vehicle.priceSheet = PriceSheetForm.calculateNew(vehicle.priceSheet, this.authenticationService.isStandard());
        if(vehicle.priceSheet.employee_id != null){
          let index = this.employees.findIndex(obj => obj.id == vehicle.priceSheet.employee_id);
          if(index > -1){
            vehicle.priceSheet.employee = this.employees[index];
          }
        }
        if(vehicle.priceSheet.nachkalkulation){
          vehicle.priceSheet.reparatur_summe_gesamt_vorjahr = PriceSheetCalculaterNew.getReparaturGesamt(vehicle.priceSheet.reparaturen_vorjahr);
          vehicle.priceSheet.standzeit_platz = PriceSheetCalculaterNew.getStandzeitPlatz(vehicle.priceSheet);
          vehicle.priceSheet.standzeit_geld = PriceSheetCalculaterNew.getStandzeitGeld(vehicle.priceSheet);
          vehicle.priceSheet.zinsen_vor = PriceSheetCalculaterNew.getZinsenVor(vehicle.priceSheet);
          vehicle.priceSheet.garantie = PriceSheetCalculaterNew.getGarantieVor(vehicle.priceSheet, this.authenticationService.isStandard());
          vehicle.priceSheet.standzeitkosten_vor = PriceSheetCalculaterNew.getStandzeitkostenVor(vehicle.priceSheet);
        } else {
          vehicle.priceSheet.steuersatz_nach = PriceSheetCalculaterNew.setSteuersatzNachkalulation(vehicle.priceSheet);
        }
      } else {
        let priceSheetForm = PriceSheetForm.getPriceSheetForm(this.formBuilder, this.settings, this.authenticationService.isStandard());
        vehicle.priceSheet = PriceSheetForm.createObject(priceSheetForm);
      }
      resolve(vehicle);
    })
  }

  // setUpSinglePriceSheet(vehicle: Vehicle): Promise<Vehicle> {
  //   return new Promise<Vehicle>((resolve, reject) => {
  //     if(vehicle.priceSheet != null){
  //       vehicle.steuersatz_pricesheet = vehicle.priceSheet.steuersatz;
  //       vehicle.priceSheet.setDataFromVehicle(vehicle);
  //       let tempForm = PriceSheetForm.getPriceSheetForm(this.formBuilder, this.settings, this.authenticationService.isStandard(), vehicle.priceSheet, vehicle.price);
  //       //vehicle.priceSheet = PriceSheetForm.calculateNew(tempForm, this.authenticationService.isStandard());
  //       //vehicle.priceSheet = PriceSheetForm.calculate(tempForm, this.authenticationService.isStandard());
  //       if(vehicle.priceSheet.employee_id != null){
  //         let index = this.employees.findIndex(obj => obj.id == vehicle.priceSheet.employee_id);
  //         if(index > -1){
  //           vehicle.priceSheet.employee = this.employees[index];
  //           tempForm.controls["employee"].setValue(this.employees[index]);
  //         }
  //       }
  //       if(vehicle.priceSheet.nachkalkulation){
  //         tempForm.controls["reparatur_summe_gesamt_vorjahr"].setValue(PriceSheetCalculater.getReparaturGesamt(tempForm.controls["reparaturen_vorjahr"].value));
  //         tempForm.controls["standzeit_platz"].setValue(PriceSheetCalculater.getStandzeitPlatz(tempForm));
  //         tempForm.controls["standzeit_geld"].setValue(PriceSheetCalculater.getStandzeitGeld(tempForm));
  //         tempForm.controls["zinsen_vor"].setValue(PriceSheetCalculater.getZinsenVor(tempForm));
  //         tempForm.controls["garantie"].setValue(PriceSheetCalculater.getGarantieVor(tempForm, this.authenticationService.isStandard()));
  //         tempForm.controls["standzeitkosten_vor"].setValue(PriceSheetCalculater.getStandzeitkostenVor(tempForm));
  //       } else {
  //         PriceSheetCalculater.setSteuersatzNachkalulation(tempForm);
  //       }
  //       PriceSheetForm.calculate(tempForm, this.authenticationService.isStandard());
  //       vehicle.priceSheet = PriceSheetForm.createObject(tempForm);
  //     } else {
  //       this.setUpNewPriceSheetForm();
  //       vehicle.isReady = true;
  //     }
  //     resolve(vehicle);
  //   })
  // }

  //not in use
  // setUpPriceSheets() {
  //   for(let v of this.vehicles){
  //     if(v.priceSheet != null){
  //       v.steuersatz_pricesheet = v.priceSheet.steuersatz;
  //       v.priceSheet.setDataFromVehicle(v);
  //       let tempForm = PriceSheetForm.getPriceSheetForm(this.formBuilder, this.settings, this.authenticationService.isStandard(), v.priceSheet, v.price);
  //       if(v.priceSheet.employee_id != null){
  //         let index = this.employees.findIndex(obj => obj.id == v.priceSheet.employee_id);
  //         if(index > -1){
  //           v.priceSheet.employee = this.employees[index];
  //           tempForm.controls["employee"].setValue(this.employees[index]);
  //         }
  //       }
  //       if(v.priceSheet.nachkalkulation){
  //         tempForm.controls["reparatur_summe_gesamt_vorjahr"].setValue(PriceSheetCalculater.getReparaturGesamt(tempForm.controls["reparaturen_vorjahr"].value));
  //         tempForm.controls["standzeit_platz"].setValue(PriceSheetCalculater.getStandzeitPlatz(tempForm));
  //         tempForm.controls["standzeit_geld"].setValue(PriceSheetCalculater.getStandzeitGeld(tempForm));
  //         tempForm.controls["zinsen_vor"].setValue(PriceSheetCalculater.getZinsenVor(tempForm));
  //         tempForm.controls["garantie"].setValue(PriceSheetCalculater.getGarantieVor(tempForm, this.authenticationService.isStandard()));
  //         tempForm.controls["standzeitkosten_vor"].setValue(PriceSheetCalculater.getStandzeitkostenVor(tempForm));
  //       } else {
  //         PriceSheetCalculater.setSteuersatzNachkalulation(tempForm);
  //       }
  //       PriceSheetForm.calculate(tempForm, this.authenticationService.isStandard());
  //       v.priceSheet = PriceSheetForm.createObject(tempForm);
  //       v.isReady = true;
  //       let items = this._vehiclesFiltered.getValue();
  //       let index = items.findIndex((vehicle) => vehicle.id == v.id);
  //       if(index > -1) {
  //         items[index] = v;
  //         this._vehiclesFiltered.next([...items]);
  //         this.cdr.detectChanges();
  //       }
  //     } else {
  //       this.setUpNewPriceSheetForm();
  //       v.isReady = true;
  //     }
  //   }
  // }

  readyUpPricesheet() {
    if(this.selectedVehicle?.priceSheet != null){
      this.selectedVehicle.steuersatz_pricesheet = this.selectedVehicle.priceSheet.steuersatz;
      this.selectedVehicle.priceSheet.setDataFromVehicle(this.selectedVehicle);
      this.pricesheetForm = PriceSheetForm.getPriceSheetForm(this.formBuilder, this.settings, this.authenticationService.isStandard(), this.selectedVehicle.priceSheet, this.selectedVehicle.price);
      if(this.selectedVehicle.priceSheet.nachkalkulation){
        this.setStartValuesNach();
      } else {
        PriceSheetCalculater.setSteuersatzNachkalulation(this.pricesheetForm);
      }
      PriceSheetForm.calculate(this.pricesheetForm, this.authenticationService.isStandard());
      if(this.selectedVehicle.priceSheet.employee_id != null){
        let index = this.employees.findIndex(obj => obj.id == this.selectedVehicle.priceSheet.employee_id);
        if(index > -1){
          this.pricesheetForm.controls["employee"].setValue(this.employees[index]);
        }
      }
      if(this.selectedVehicle.priceSheet.customer_id != null){
        let index = this.customers.findIndex(obj => obj.id == this.selectedVehicle.priceSheet.customer_id);
        if(index > -1){
          this.pricesheetForm.controls["customer"].setValue(this.customers[index]);
        }
      }
      this.selectedVehicle.priceSheet = PriceSheetForm.createObject(this.pricesheetForm);
      this.setFormValueChangesSubscriptions();
    } else {
      this.setUpNewPriceSheetForm();
      this.setFormValueChangesSubscriptions();
    }
  }

  showPriceSheet() {
    if(this.selectedVehicle.priceSheet){
      this.surfacePriceSheet = 3;
      this.isNewPriceSheet = false;
    } else {
      this.surfacePriceSheet = 1;
      this.isNewPriceSheet = true;
    }
  }

  setUpNewPriceSheetForm() {
    this.pricesheetForm = PriceSheetForm.getPriceSheetForm(this.formBuilder, this.settings, this.authenticationService.isStandard());
    if(this.selectedVehicle != null){
      this.pricesheetForm.controls["vehicle_id"].setValue(this.selectedVehicle.id);
      this.pricesheetForm.controls["fahrzeug_typ"].setValue(this.selectedVehicle.category);
      this.pricesheetForm.controls["eurotax"].setValue(this.selectedVehicle.eurotax_code);
      this.pricesheetForm.controls["preis_anschreiben"].setValue(this.selectedVehicle.price);
      this.pricesheetForm.controls["ez"].setValue(PriceSheetForm.getConvertedDateForInputFull(this.selectedVehicle.firstRegistrationDate));
      this.pricesheetForm.controls["fuel_typ"].setValue(this.selectedVehicle.fuel);
      this.pricesheetForm.controls["minimaler_verkaufpreis"].setValue(this.selectedVehicle.dealerPrice);
      if(this.selectedVehicle.steuersatz_pricesheet){
        this.pricesheetForm.controls["steuersatz"].setValue(this.selectedVehicle.steuersatz_pricesheet);
        this.pricesheetForm.controls["steuersatz_nach"].setValue(this.selectedVehicle.steuersatz_pricesheet);
      }
      if(this.selectedVehicle.einkaufspreis_pricesheet) this.pricesheetForm.controls["einkaufspreis_brutto"].setValue(this.selectedVehicle.einkaufspreis_pricesheet);
      this.pricesheetForm.controls["hereinnahmedatum"].setValue(PriceSheetForm.getConvertedDateForInputFull(this.selectedVehicle.eingangsdatum));
      this.pricesheetForm.controls["paper_status"].setValue(this.selectedVehicle.paper_status);
      this.pricesheetForm.controls["finanzsperrauskunft"].setValue(this.selectedVehicle.finanzsperrauskunft);
      this.pricesheetForm.controls["finanzsperrauskunft_beantragt"].setValue(this.selectedVehicle.finanzsperrauskunft_beantragt);
      this.pricesheetForm.controls["type_certificate_versendet"].setValue(PriceSheetForm.getConvertedDateForInputFull(this.selectedVehicle.type_certificate_versendet));
      this.pricesheetForm.controls["co2_emission"].setValue(this.selectedVehicle.co2Emission);
      this.pricesheetForm.controls["co2_checked"].setValue(this.selectedVehicle.co2_checked);
      this.pricesheetForm.controls["sprit"].setValue(this.selectedVehicle.sprit);
      this.pricesheetForm.controls["lieferant_extern"].setValue(this.selectedVehicle.lieferant_extern);
      this.pricesheetForm.controls["einkaufsdatum"].setValue(this.selectedVehicle.einkaufsdatum);
      this.pricesheetForm.controls["vehicle_imported"].setValue(this.selectedVehicle.vehicle_imported);
      this.pricesheetForm.controls["vehicle_supplier"].setValue(this.selectedVehicle.vehicle_supplier);
      this.pricesheetForm.controls["bodyTypId"].setValue(this.selectedVehicle.bodyTyp?.id);
      this.pricesheetForm.controls["consumptionCombined"].setValue(this.selectedVehicle.consumption?.combined);
      this.pricesheetForm.controls["powerKw"].setValue(this.selectedVehicle.powerKw);
      this.pricesheetForm.controls["zubau"].setValue(this.selectedVehicle.zubau);
      this.pricesheetForm.controls["abwertung"].setValue(this.selectedVehicle.abwertung);
    }
  }

  selectionKalkulation(page: number) {
    new ReloadDataHandler().deactivateReloadLoop([this.vService]);
    this.showPriceSheet();
    this.surfacePriceSheet = page;
  }

  onPriceSheetChanges() {
    this.priceSheetFormChanges = true;
  }

  selectionPriceSheetCancel() {
    this.dService.openQuery(
      {
        title: 'Bearbeitung abbrechen',
        message: 'Sind sie sicher, dass Sie die Bearbeitung abbrechen möchten? Alle Änderungen gehen dadurch verloren.',
        btn_cancel_txt: 'Weiter bearbeiten',
        btn_submit_txt: 'Beenden',
        typ: 'cancel_pricsheet_edit',
        submit_value: null,
      }
    );
    // if(this.priceSheetFormChanges){
    //   this.dService.openQuery(
    //     {
    //       title: 'Bearbeitung abbrechen',
    //       message: 'Sind sie sicher, dass Sie die Bearbeitung abbrechen möchten? Alle Änderungen gehen dadurch verloren.',
    //       btn_cancel_txt: 'Weiter bearbeiten',
    //       btn_submit_txt: 'Beenden',
    //       typ: 'cancel_pricsheet_edit',
    //       submit_value: null,
    //     }
    //   );
    // } else {
    //   this.cancelActionPricesheetedit();
    // }
  }
  cancelActionPricesheetedit() {
    if(this.selectedVehicle.priceSheet == null){
      this.changeDetailsTab(1);
    }
    this.pricesheetForm = PriceSheetForm.getPriceSheetForm(this.formBuilder, this.settings, this.authenticationService.isStandard(), this.selectedVehicle.priceSheet, this.selectedVehicle.price);
    this.surfacePriceSheet = 3;
    this.submitted = false;
    this.priceSheetFormChanges = false;
    new ReloadDataHandler().activateReloadLoop([this.vService]);
  }

  selectionPriceSheetSave() {
    this.submitted = true;
    if(this.pricesheetForm.valid){
      if(this.selectedVehicle.priceSheet?.price_sheet_id == null){
        let obj = PriceSheetForm.createObject(this.pricesheetForm);
        obj.steuersatz_nach = obj.steuersatz;
        this.selectedVehicle = this.presetGuarantee(this.selectedVehicle);
        if(obj.steuersatz == 2){
          this.selectedVehicle.vat_reportable = false;
          this.selectedVehicle.leasable = false;
        } else if(obj.steuersatz == 1){
          this.selectedVehicle.vat_reportable = true;
          this.selectedVehicle.leasable = true;
        }
        this.vService.createPriceSheet(obj).subscribe((result) => {
          if(result){
            obj.price_sheet_id = result.price_sheet_id;
            this.selectedVehicle.priceSheet = obj;
            this.selectedVehicle.setDataFromPricesheet(this.selectedVehicle.priceSheet);
            this.vService.updateVehicleSafe(this.selectedVehicle).subscribe((response) => {
              if(response){
                let index = this.vehicles.findIndex(v => v.id == this.selectedVehicle.id);
                if(index > -1){
                  this.vehicles[index] = this.selectedVehicle;
                  this._vehiclesFiltered.next(this.vehicles);
                }
                this.resumePagination = true;
                this.rootFilterVehicles();
                setTimeout(() => {
                  let scrollbar = document.getElementById('ab-item-list');
                  if(scrollbar && this.scrollbarLastPos){
                    scrollbar.scrollTop = this.scrollbarLastPos;
                  }
                }, 200);
                this.priceSheetFormChanges = false;
                this.surfacePriceSheet = 3;
                this.dService.showNotification({
                  title: 'Erfolgreich',
                  message: 'Preisblatt wurde gespeichert.',
                  success: true
                });
                new ReloadDataHandler().activateReloadLoop([this.vService]);
              }
            });
          }
        })
      } else {
        let vehicle = CloneObject.deepCopy(this.selectedVehicle);
        let pricesheet = PriceSheetForm.createObject(this.pricesheetForm);
        vehicle = this.fillVehicleObjectWithCorrespondingPriceSheetData(vehicle, pricesheet);
        this.vService.updateVehicleSafe(vehicle).subscribe((result) => {
          if(result){
            if(result?.conflicts != null){
              this.updateConflicts = result.conflicts;
            } else {
              //result.priceSheet.spedition = result.spedition;
              this.transactionService.getTransactions('vehicle', vehicle.id).subscribe((response) => {
                if(response){
                  result.attachments = this.selectedVehicle.attachments;
                  result.transactions = response;
                  let index = this.vehicles.findIndex(v => v.id == result.id);
                  if(index > -1){
                    this.vehicles[index] = result;
                    this._vehiclesFiltered.next(this.vehicles);
                  }
                  this.selectedVehicle = result;
                  this.submitted = false;
                  this.readyUpPricesheet();
                  this.resumePagination = true;
                  this.rootFilterVehicles();
                  setTimeout(() => {
                    let scrollbar = document.getElementById('ab-item-list');
                    if(scrollbar && this.scrollbarLastPos){
                      scrollbar.scrollTop = this.scrollbarLastPos;
                    }
                  }, 200);
                  this.priceSheetFormChanges = false;
                  this.surfacePriceSheet = 3;
                  this.dService.showNotification({
                    title: 'Erfolgreich',
                    message: 'Preisblatt wurde gespeichert.',
                    success: true
                  });
                  new ReloadDataHandler().activateReloadLoop([this.vService]);
                } else {

                }
              });
            }
          }
        });
      }
    } else {
      ErrorHelper.showFormError(this.pricesheetForm);
    }
  }

  fillVehicleObjectWithCorrespondingPriceSheetData(vehicle: Vehicle, priceSheet: PriceSheet): Vehicle {
    vehicle.priceSheet = priceSheet;
    vehicle.dealerPrice = priceSheet.minimaler_verkaufpreis;
    vehicle.price = priceSheet.preis_anschreiben;
    vehicle.paper_status = priceSheet.paper_status;
    vehicle.finanzsperrauskunft = priceSheet.finanzsperrauskunft;
    vehicle.finanzsperrauskunft_beantragt = priceSheet.finanzsperrauskunft_beantragt;
    vehicle.type_certificate_versendet = priceSheet.type_certificate_versendet;
    vehicle.co2Emission = priceSheet.co2_emission;
    vehicle.co2_checked = priceSheet.co2_checked;
    vehicle.datenblatt_angefordert_am = priceSheet.datenblatt_angefordert_am;
    vehicle.fahrzeug_eingestockt_am = priceSheet.fahrzeug_eingestockt_am;
    vehicle.type_certificate_eingestockt = priceSheet.type_certificate_eingestockt;
    vehicle.type_certificate_ausgestockt = priceSheet.type_certificate_ausgestockt;
    vehicle.vehicle_supplier = priceSheet.vehicle_supplier;
    vehicle.sprit = priceSheet.sprit;
    vehicle.lieferant_extern = priceSheet.lieferant_extern;
    vehicle.einkaufspreis_pricesheet = priceSheet.einkaufspreis_brutto;
    vehicle.transport_beantragt = priceSheet.transport_beantragt;
    vehicle.transport_date = priceSheet.transport_date;
    vehicle.spedition = priceSheet.spedition;
    vehicle.electrical_distance = priceSheet.electrical_distance;
    vehicle.electrical_distance_checked = priceSheet.electrical_distance_checked;
    vehicle.einkauf_vertragsnummer = priceSheet.einkauf_vertragsnummer;
    vehicle.verkauf_vertragsnummer = priceSheet.verkauf_vertragsnummer;
    vehicle.export_price = priceSheet.export_price;
    vehicle.export_price_checked = priceSheet.export_price_checked;
    vehicle.export_price_enabled = priceSheet.export_price_enabled;
    vehicle.zubau = priceSheet.zubau;
    vehicle.abwertung = priceSheet.abwertung;
    vehicle = this.presetGuarantee(vehicle);
    if(priceSheet.steuersatz == 2){
      vehicle.vat_reportable = false;
      vehicle.leasable = false;
    } else if(priceSheet.steuersatz == 1){
      vehicle.vat_reportable = true;
      vehicle.leasable = true;
    }
    return vehicle
  }

  //Vorkalkulation
  async selectionEditSteuersatzAcceptedVor() {
    this.pricesheetForm.controls["steuersatz"].setValue(null);
  }

  isEditableKey(key: string): boolean {
    return [
      "einkaufspreis_brutto",
      "steuersatz",
      "steuersatz_nach",
      "minimaler_verkaufpreis",
      "preis_anschreiben",
      "vehicle_imported",
      "hereinnahmedatum",
      "zahldatum",
      "standzeit_plan_tage",
      "aufbereitung",
      "aufbereitung_nachkalkulation",
      "fixkosten_pro_fahrzeug_bei_70",
      "gw_aufschlag",
      "sprit",
      "flug_bahn",
      "gebuehren",
      "lieferant_extern",
      "uebernachtung",
      "verkaufsdatum",
      "geldeingang",
      "fahrzeug_provision",
      "finanzierung",
      "abzug_fahrzeug_provision",
      "abzug_finanzierung",
      "garantie_intern",
      "garantie_extern",
      "verkaufspreis_brutto",
      "zinssatz_vor",
      "garantie_von_einkaufspreis",
      "standzeitkosten_je_tag_vor",
      "zinssatz_nach",
      "standzeitkosten_je_tag_nach",
      "garantie_vor",
      "co2_emission",
      "malus_bonus_nach"
    ].includes(key);
  }

  setFormValueChangesSubscriptions() {
    if(this.pricesheetForm != null){
      Object.keys(this.pricesheetForm.controls).forEach((key) => {
        if(this.isEditableKey(key)) {
          this.pricesheetForm.controls[key].valueChanges.subscribe((changes) => {
            PriceSheetForm.calculate(this.pricesheetForm, this.authenticationService.isStandard());
          });
        }
      });
    }
  }

  addReparaturVor() {
    let amount = this.pricesheetForm.controls["reparaturen"].value.length;
    let form = PriceSheetForm.getPriceSheetsRepairForm(this.formBuilder);
    form.controls["name"].setValue("Reparatur "+(amount+1));
    this.pricesheetForm.controls["reparaturen"].value.push(form);
    this.calculateReaparturenSummenVor("reparaturen");
  }
  removeReparaturVor(index: number) {
    this.pricesheetForm.controls["reparaturen"].value.splice(index, 1);
    this.calculateReaparturenSummenVor("reparaturen");
    if(this.pricesheetForm.controls["reparaturen"].value.length == 0){
      this.pricesheetForm.controls["reparaturen_column_gesamt"].setValue(null);
      this.pricesheetForm.controls["reparatur_summe_gesamt"].setValue(null);
    } else {
      for(let i = 0; i < this.pricesheetForm.controls["reparaturen"].value.length; i++){
        this.pricesheetForm.controls["reparaturen"].value[i].controls["name"].setValue("Reparatur "+(i+1));
      }
    }
  }
  calculateReaparturenSummenVor(controlName: string) {
    for(let form of this.pricesheetForm.controls[controlName].value){
      form.valueChanges.subscribe((changes) => {
        if(this.inputTimeout) clearTimeout(this.inputTimeout);
        this.inputTimeout = setTimeout(() => {
          this.pricesheetForm.controls["reparaturen_column_gesamt"].setValue(PriceSheetCalculater.getReparaturColumnGesamt(this.pricesheetForm.controls[controlName].value));
          this.pricesheetForm.controls["reparatur_summe_gesamt"].setValue(PriceSheetCalculater.getReparaturGesamt(this.pricesheetForm.controls[controlName].value));
          PriceSheetForm.calculate(this.pricesheetForm, this.authenticationService.isStandard());
        }, INPUTTIMEOUT);
      })
    }
  }

  //Nachkalkulation
  async selectionEditSteuersatzAcceptedNach() {
    this.pricesheetForm.controls["steuersatz_nach"].setValue(null);
  }
  setStartValuesNach() {
    this.pricesheetForm.controls["reparaturen_column_gesamt_vorjahr"].setValue(PriceSheetCalculater.getReparaturColumnGesamt(this.pricesheetForm.controls["reparaturen_vorjahr"].value));
    this.pricesheetForm.controls["reparatur_summe_gesamt_vorjahr"].setValue(PriceSheetCalculater.getReparaturGesamt(this.pricesheetForm.controls["reparaturen_vorjahr"].value));
    this.pricesheetForm.controls["standzeit_platz"].setValue(PriceSheetCalculater.getStandzeitPlatz(this.pricesheetForm));
    this.pricesheetForm.controls["standzeit_geld"].setValue(PriceSheetCalculater.getStandzeitGeld(this.pricesheetForm));
    this.pricesheetForm.controls["zinsen_vor"].setValue(PriceSheetCalculater.getZinsenVor(this.pricesheetForm));
    this.pricesheetForm.controls["garantie"].setValue(PriceSheetCalculater.getGarantieVor(this.pricesheetForm, this.authenticationService.isStandard()));
    this.pricesheetForm.controls["standzeitkosten_vor"].setValue(PriceSheetCalculater.getStandzeitkostenVor(this.pricesheetForm));
    // if(PriceSheetCalculater.isVomAusland(this.pricesheetForm, this.authenticationService.isStandard())){ //Vom Ausland
    //   if(this.pricesheetForm.controls["verkaufspreis_netto"].value && this.pricesheetForm.controls["ez"].value){
    //     PriceSheetForm.calculate(this.pricesheetForm, this.authenticationService.isStandard());
    //   }
    // } else {
    //   if(this.pricesheetForm.controls["verkaufspreis_brutto"].value && this.pricesheetForm.controls["hereinnahmedatum"].value){
    //     PriceSheetForm.calculate(this.pricesheetForm, this.authenticationService.isStandard());
    //   }
    // }
    PriceSheetForm.calculate(this.pricesheetForm, this.authenticationService.isStandard());
    this.calculateReaparturenSummenNach("reparaturen_vorjahr");
    setTimeout(() => {
      this.formSubscriptionPricesheet = this.pricesheetForm.valueChanges.subscribe((changes) => {
        if(changes){
          this.onPriceSheetChanges();
          this.formSubscriptionPricesheet.unsubscribe();
        }
      })
    }, 500);
  }
  setFormValueChangesSubscriptionsNach() {
    PriceSheetForm.calculate(this.pricesheetForm, this.authenticationService.isStandard());
  }

  addReparaturNach() {
    let amount = this.pricesheetForm.controls["reparaturen_vorjahr"].value.length;
    let form = PriceSheetForm.getPriceSheetsRepairForm(this.formBuilder)
    form.controls["name"].setValue("Reparatur "+(amount+1));
    this.pricesheetForm.controls["reparaturen_vorjahr"].value.push(form);
    this.calculateReaparturenSummenNach("reparaturen_vorjahr");
  }
  removeReparaturNach(index: number) {
    this.pricesheetForm.controls["reparaturen_vorjahr"].value.splice(index, 1);
    this.calculateReaparturenSummenNach("reparaturen_vorjahr");
    if(this.pricesheetForm.controls["reparaturen_vorjahr"].value.length == 0){
      this.pricesheetForm.controls["reparaturen_column_gesamt_vorjahr"].setValue(null);
      this.pricesheetForm.controls["reparatur_summe_gesamt_vorjahr"].setValue(null);
    } else {
      for(let i = 0; i < this.pricesheetForm.controls["reparaturen_vorjahr"].value.length; i++){
        this.pricesheetForm.controls["reparaturen_vorjahr"].value[i].controls["name"].setValue("Reparatur "+(i+1));
      }
    }
  }
  calculateReaparturenSummenNach(controlName: string) {
    for(let form of this.pricesheetForm.controls[controlName].value){
      form.valueChanges.subscribe((changes) => {
        if(this.inputTimeout) clearTimeout(this.inputTimeout);
        this.inputTimeout = setTimeout(() => {
          this.pricesheetForm.controls["reparaturen_column_gesamt_vorjahr"].setValue(PriceSheetCalculater.getReparaturColumnGesamt(this.pricesheetForm.controls[controlName].value));
          this.pricesheetForm.controls["reparatur_summe_gesamt_vorjahr"].setValue(PriceSheetCalculater.getReparaturGesamt(this.pricesheetForm.controls[controlName].value));
          PriceSheetForm.calculate(this.pricesheetForm, this.authenticationService.isStandard());
        }, INPUTTIMEOUT);
      })
    }
  }

  selectionEditDescrtiption() {
    new ReloadDataHandler().deactivateReloadLoop([this.vService]);
    this.editTyp = 2;
    this.openDetails(this.selectedVehicle, true);
  }

  printEtikett(vehicle) {
    this.vService.getEtikettenDruck(vehicle.id).subscribe((result) => {
      if(result){
        this.print(result);
      }
    })
  }

  selectionEtikettenDruck() {
    this.vService.getEtikettenDruck(this.selectedVehicle.id).subscribe((result) => {
      if(result){
        this.print(result);
      }
    })
  }

  changeDetailsTab(tab: number) {
    this.selectedDetailsTab = tab;
    if(this.selectedDetailsTab == 4){
      this.getTicketsFromVehicle();
      this.surface = VehicleView.TICKET;
    } else if(this.selectedDetailsTab == 3){
      this.showPriceSheet();
      this.surface = VehicleView.KALKULATION;
    } else if(this.selectedDetailsTab == 5){
      this.surface = VehicleView.DOCUMENT;

    } else {
      this.surface = VehicleView.DETAIL;
    }
    this.setPrevoiusNextBtns();
  }

  setPagingDisabled() {
    const btnPrevious = document.getElementById('btn-previous') as HTMLButtonElement;
    const btnNext = document.getElementById('btn-next') as HTMLButtonElement;
    if(btnPrevious && btnNext){
      btnPrevious.disabled = true;
      btnNext.disabled = true;
      setTimeout(() => {
        btnPrevious.disabled = false;
        btnNext.disabled = false;
      }, 500);
    }
  }

  selectionPreviousVehicle() {
    this.setPagingDisabled();
    let index = this._vehiclesFiltered.getValue().findIndex(obj => obj.id == this.selectedVehicle.id) - 1;
    if(index > -1){
      this.openDetails(this._vehiclesFiltered.getValue()[index] as Vehicle, false);
    }
  }

  selectionNextVehicle() {
    this.setPagingDisabled();
    let index = this._vehiclesFiltered.getValue().findIndex(obj => obj.id == this.selectedVehicle.id) + 1;
    if(index > 0 && index < this._vehiclesFiltered.getValue().length){
      this.openDetails(this._vehiclesFiltered.getValue()[index] as Vehicle, false);
    }
  }

  setPrevoiusNextBtns() {
    let listTemp = [];
    let index;
    for(let i = 0; i < this._vehiclesFiltered.getValue().length; i++){
      listTemp.push(this._vehiclesFiltered.getValue()[i]);
    }
    index = listTemp.findIndex(obj => obj?.id == this.selectedVehicle?.id);
    if(index == 0) this.showPreviousBtnDetails = false;
    else this.showPreviousBtnDetails = true;
    if(index == listTemp.length-1) this.showNextBtnDetails = false;
    else this.showNextBtnDetails = true;
  }

  async selectionCopyVehicle(vehicle: Vehicle) {
    this.loadingService.startLoading();
    let vehicleToCopy = CloneObject.deepCopy(vehicle);
    this.prepareForm();
    vehicleToCopy.id = null;
    vehicleToCopy.customer_id = null;
    vehicleToCopy.status = VehicleStatus.Draft;
    let success = await this.createVehicle(vehicleToCopy);
    if(!this.loadingService.isLoading()) this.loadingService.startLoading();
    let picturesToCopy = [];
    for(let pic of vehicleToCopy.pictures){
      picturesToCopy.push(await this.getVehiclePicturesForCopy(pic));
    }
    if(!this.loadingService.isLoading()) this.loadingService.startLoading();
    let picturesNew = [];
    for(let pic of picturesToCopy){
      picturesNew = await this.createVehiclePictureForCopy(pic);
    }
    if(!this.loadingService.isLoading()) this.loadingService.startLoading();
    this.selectedVehicle.pictures = picturesNew;
    this.selectedVehicle.pictures_count = picturesNew.length;
    this.selectedDetailsTab = 1;
    if(success){
      this.selectedVehicle.performance = this.selectedVehicle.getStatus();
      this.selectedVehicle.einkaufspreis_pricesheet = this.formGroupVehicle.controls['einkaufspreis_pricesheet'].value;
      this.selectedVehicle.steuersatz_pricesheet = this.formGroupVehicle.controls['steuersatz_pricesheet'].value;
      this.selectedVehicle.sprit = this.formGroupVehicle.controls['sprit'].value;
      this.selectedVehicle.lieferant_extern = this.formGroupVehicle.controls['lieferant_extern'].value;
      this.selectedVehicle.einkaufsdatum = this.formGroupVehicle.controls['einkaufsdatum'].value;
      this.selectedVehicle.vehicle_supplier = this.formGroupVehicle.controls['vehicle_supplier'].value;
      this.selectedVehicle.vehicle_imported = this.formGroupVehicle.controls['vehicle_imported'].value;
      this.formGroupVehicle.controls['description'].setValue(this.selectedVehicle.description);
      this.pricesheetForm = PriceSheetForm.getPriceSheetForm(this.formBuilder, this.settings, this.authenticationService.isStandard(), vehicleToCopy.priceSheet, vehicleToCopy.price);
      let pricesheet = PriceSheetForm.createObject(this.pricesheetForm);
      pricesheet.steuersatz_nach = pricesheet.steuersatz;
      pricesheet.price_sheet_id = null;
      pricesheet.vehicle_id = this.selectedVehicle.id;
      this.vService.createPriceSheet(pricesheet).subscribe((result) => {
        if(result){
          pricesheet.price_sheet_id = result.price_sheet_id;
          this.selectedVehicle.priceSheet = pricesheet;
          this.selectedVehicle.dealerPrice = this.selectedVehicle.priceSheet.minimaler_verkaufpreis;
          this.selectedVehicle.price = this.selectedVehicle.priceSheet.preis_anschreiben;
          this.editTyp = 1;
          if(this.chatgptHandler) clearInterval(this.chatgptHandler);
          this.openDetails(this.selectedVehicle, true);
          this.rootFilterVehicles();
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Fahrzeug wurde kopiert.',
            success: true
          });
        }
        this.loadingService.stopLoading();
      });
    }
  }

  getVehiclePicturesForCopy(picture: Picture): Promise<VehiclePicture> {
    return new Promise<any>((resolve, reject) => {
      this.vService.getVehiclePicture(picture.picture_id).subscribe((data) => {
        if(data){
          let src = data;
          let pic = new Picture(picture.picture_id, picture.position, picture.obj_id, src);
          resolve(pic);
        }
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  createVehiclePictureForCopy(pic: Picture): Promise<VehiclePicture[]> {
    return new Promise<any>((resolve, reject) => {
      this.vService.createVehiclePicture(pic).subscribe((event: HttpEvent<any>) => {
        let response = event as HttpResponse<any>;
        if(response.body){
          let pictures = VehiclePictureFactory.jsonFactory(response.body.pictures);
          resolve(pictures);
        }
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  selectionCreateKaufvertrag(vehicle: Vehicle){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['documents/create'], { queryParams: { typ: DokumentTyp.Kaufvertrag, vehicleId: vehicle.id } }));
  }

  selectionArchiv(vehicle: Vehicle) {
    vehicle.status = 10;
    this.updateVehicle(vehicle, false);
  }

  selectionBillImport(file: any) {
    this.vService.createPriceSheet
  }

  selectionBCAImport(file: any) {
    this.vService.createVehicleFromBCA(file).subscribe((result) => {
      if(result){
        this.vehicles.push(...result.vehicles);
        this.rootFilterVehicles();
        if(result.errors.length > 0) {
          this.vehicleBCAErrors = result.errors;
          this.vehicleBCASTotalNumber = result.vehicles.length;
          this.showDialogBCAImport = true;
        } else if(result.vehicles.length == 0) {
          this.vehicleBCAErrors = [new VehicleError(0, "Keine Fahrzeuge gefunden.", "")]
          this.vehicleBCASTotalNumber = 0;
          this.showDialogBCAImport = true;
        } else {
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Import Erfolgreich',
            success: true
          });
        }
        this.currentPageCreate = 1;
        this.surface = VehicleView.LIST;
        for(let mainMenu of this.mainMenus) {
          for(let subMenu of mainMenu.sub_menus) {
            if(subMenu.name == "Import") {
              mainMenu.setActive(true);
              subMenu.setActive(true)
              break;
            }
          }
        }
      }
    })
  }

  selectionCloseBCAImportErrorsDialog() {
    this.vehicleBCAErrors = [];
    this.vehicleBCASTotalNumber = null;
    this.showDialogBCAImport = false;
  }

  openPdfDialog(vehicle: Vehicle) {
    this.dialogPrintPDFVehicleId = vehicle.id;
    this.showDialogPrint = true;
  }

  selectionPrintWebPricesheet(id?: number) {
    let vehicleId = id;
    if(this.selectedVehicle != null){
      vehicleId = this.selectedVehicle.id;
    }
    this.showDialogPrint = false;
    this.vService.getPDFPreisblattWeb(vehicleId).subscribe((result) => {
      if(result){
        this.print(result);
        this.dialogPrintPDFVehicleId = null;
      }
    })
  }

  selectionPrintPricesheet(id?: number) {
    let vehicleId = id;
    if(this.selectedVehicle != null){
      vehicleId = this.selectedVehicle.id;
    }
    this.showDialogPrint = false;
    this.vService.getPDFPreisblatt(vehicleId).subscribe((result) => {
      if(result){
        this.print(result);
        this.dialogPrintPDFVehicleId = null;
      }
    })
  }

  print(pdf: string) {
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const objectUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.src = objectUrl;
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.onload = function() {
      setTimeout(function() {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  selectionCreateCredit(obj: Vehicle) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['accounting/create'], { queryParams: { vehicleId: obj.id } }));
  }
}
