<div id="dialog_window" *ngIf="show">
  <div id="dialog_container" appDropdown [show]="show" (changedValueEmitter)="show = $event">
      <div id="dialog_close_icon" (click)="show = false">&#10005;</div>
      <h1>Verschieben nach</h1>
      <div id="dialog_conainter_body">
        <button *ngIf="data.status != 1" class="btn_grey" (click)="submit(1)">Kunden</button>
        <button *ngIf="data.status != 2" class="btn_grey" (click)="submit(2)">Interessenten</button>
        <button *ngIf="data.status != 3" class="btn_grey" (click)="submit(3)">Archiv</button>
      </div>
  </div>
</div>
