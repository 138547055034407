<div id="dialog_window">
  <form id="dialog_container" (ngSubmit)="onSubmit()">
      <div class="lbl_bold" style="margin-bottom: 15px;">Geschäftsbedingungen</div>
      <div class="lbl_regular" style="margin-bottom: 0px;">Nachstehende Geschäftsbedingungen sind Vertragsinhalt.</div>
      <div class="body">
        <div class="column">
          <div class="lbl_list_title">I. GEWÄHRLEISTUNG</div>
          <div class="lbl_regular">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sollicitudin pellentesque augue id lacinia. Vivamus tincidunt tempus lorem sit amet posuere. Proin condimentum lorem arcu, accumsan sodales quam rutrum ut. Aenean at fringilla risus. Nulla sollicitudin eu erat eget ultricies. Suspendisse potenti. In vitae tristique massa, ac iaculis massa. Fusce in enim sed ante vulputate pretium vitae id lacus. Suspendisse eu ultricies tortor. Nam ultricies fringilla elit, in fermentum risus suscipit non. In vel ultricies leo. Aenean commodo posuere elit, et faucibus nisi.</div>
          <div class="lbl_list_title">II. GARANTIE</div>
          <div class="lbl_regular">Lorem ipsum doln pellentesquarcu, accumsan sodales quam rutrum ut. Aenean get ultricies. Suspendisse potenti. In vitae tristique massa, ac iaculis massa. Fusce in enim sed ante vulputate pretium vitae id lacus. Suspendisse eu ultricies tortor. Nam ultricies fringilla elit, in fermentum risus suscipit non. In vel ultricies leo. Aenean commodo posuere elit, et faucibus nisi.</div>
          <div class="lbl_list_title">III. ERFÜLLUNG</div>
          <div class="lbl_regular">Lorem ipsum dolor sit amet, conseivamus tincidunt tempus lorem sit amet posuere. Proin condimentum lorem arcu, accumsan sodales quam rutrum ut. Aenean at fringilla risus. Nulla sollicitudin eu erat eget ultricies. Suspendisse potenti. In vitae tristique massa, ac iaculis massa. Fusce in enim sed ante vulputate pretium vitae id lacus. Suspendisse eu ultricies tortor. Nam ultricies fringilla elit, in fermentum risus suscipit non. In vel ultricies leo. Aenean commodo posuere elit, et faucibus nisi.</div>
        </div>
        <div class="column">
          <div class="lbl_list_title">IV. EIGENTUMSVORBEHALT</div>
          <div class="lbl_regular">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sollicitudin pellentesque augue id lacinia. Vivamus tincidunt tempus lorem sit amet posuere. Proin condimentum lorem arcu, accumsan sodales quam rutrum ut. Aenean at fringilla risus. Nulla sollicitudin eu erat eget ultricies. Suspendisse potenti. In vitae tristique massa, ac iaculis massa. Fusce in enim sed ante vulputate pretium vitae id lacus. Suspendisse eu ultricies tortor. Nam ultricies fringilla elit, in fermentum risus suscipit non. In vel ultricies leo. Aenean commodo posuere elit, et faucibus nisi.</div>
          <div class="lbl_list_title">V. RÜCKTRITT</div>
          <div class="lbl_regular">Lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem sit amet posuere. Proin condimentum lorem arcu, accumsan sodales quam rutrum ut. Aenean at fringilla risus. Nulla sollicitudin eu erat eget ultricies. Suspendisse potenti. In vitae tristique massa, ac iaculis massa. Fusce in enim sed ante vulputate pretium vitae id lacus. Suspendisse eu ultricies tortor. Nam ultricies fringilla elit, in fermentum risus suscipit non. In vel ultricies leo. Aenean commodo posuere elit, et faucibus nisi.</div>
        </div>
      </div>
      <div class="footer">
        <!-- <div class="checkbox_container">
          <input type="checkbox" class="checkbox" name="showTerms">
          <label for="showTerms" class="checkbox_lbl">Nicht mehr anzeigen</label>
        </div> -->
        <div class="btn_container">
            <div class="btn_label" (click)="cancelEmitter.emit()">zurück</div>
            <input class="btn_submit_dark" type="submit" value="Zur Kenntnis genommen">
        </div>
      </div>
  </form>
</div>
