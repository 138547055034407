import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-richtext-editor',
  templateUrl: './richtext-editor.component.html',
  styleUrls: ['./richtext-editor.component.css']
})
export class RichtextEditorComponent implements OnInit, AfterViewInit {

  @Input() editorId: number = 1;
  @Input() activateBold: boolean = true;
  @Input() activateItalic: boolean = true;
  @Input() activateUnderline: boolean = true;
  @Input() activateStrikeThrougth: boolean = true;
  @Input() control: FormControl;
  @Input() label: string;
  @Input() maxChar: number = 4500;
  @Input() fieldHeight: number = 250;
  @ViewChild("richtText") richtText;

  public richTextFormatButtons = [];
  public recordCommands: string[] = [];
  public isSelection: boolean = false;
  public inputTimeout: any;
  public noEditor = false;

  constructor() { }

  ngOnInit(): void {
    this.noEditor = !this.activateBold && !this.activateItalic && !this.activateUnderline && !this.activateStrikeThrougth;
  }

  ngAfterViewInit(): void {
    let unsorted = document.querySelectorAll(".richtext-format-button");
    unsorted.forEach(element => {
      if(this.isCurrentEditor(element)) this.richTextFormatButtons.push(element);
    });

    //Mac character limit
    let element = document.getElementById("text-input-"+this.editorId);
    if(element){
      element.addEventListener("keydown", (event: any) => {
        this.onInput(event);
      });
      // element.addEventListener("onpaste", (event: any) => {
      //   this.onPaste(event);
      // });
      element.addEventListener("mouseup", (event: any) => {
        this.isSelection = this.isTextSelected();
      });
    }

    //Rich Text Editor
    this.intializer();
    this.addListeners();
    if(this.control.value && this.control.value.length > 0){
      this.setContentWithFormatting(this.control.value);
      //this.richtText.nativeElement.innerHTML = this.control.value;
    }
  }

  intializer() {
    this.highlighter(this.richTextFormatButtons, true);
  }

  addListeners() {
    if(this.richTextFormatButtons){
      this.richTextFormatButtons.forEach(button => {
        if(this.isCurrentEditor(button)) {
          button.addEventListener("click", () => {
            this.modifyText(button.id,false,null);
            if(this.isSelection){
              setTimeout(() => {
                window.getSelection().removeAllRanges();
                this.modifyText(button.id,false,null);
              }, 100);
            }
            const input = document.getElementById("text-input-"+this.editorId);
            if(input) this.control.setValue(input.innerHTML);
          });
        }
      });
    }
  }

  highlighter(classNameArray, needsRemoval) {
    if(classNameArray){
      classNameArray.forEach(button => {
        button.addEventListener("click", () => {
          if(needsRemoval){
            let active = false;
            if(this.isCurrentEditor(button) && button.classList.contains("richtext-format-button-active")){
              active = true;
            }
            this.highlighterRemover(classNameArray);
            if(!active && !this.isSelection){
              if(this.isCurrentEditor(button)) button.classList.add("richtext-format-button-active");
            }
          } else {
            if(this.isCurrentEditor(button)) button.classList.toggle("richtext-format-button-active");
          }
        });
      });
    }
  }

  isCurrentEditor(button): boolean {
    return button.id.split("-")[1] == this.editorId
  }

  highlighterRemover(className) {
    className.forEach(button => {
      if(this.isCurrentEditor(button)) {
        button.classList.remove("richtext-format-button-active");
      }
    });
  }

  modifyText(command, defaultUi, value) {
    document.execCommand(command.split("-")[0], defaultUi, value);
  }

  onInput(event) {
    if(event.target.innerText.length > this.maxChar && event.keyCode != 8){
      event.preventDefault();
      event.stopPropagation()
    } else {
      if(this.inputTimeout) clearTimeout(this.inputTimeout);
      this.inputTimeout = setTimeout(() => {
        this.addTextToControls();
      }, 200);
    }
  }

  addTextToControls() {
    let element = document.getElementById("text-input-"+this.editorId);
    if(element){
      this.control.setValue(element.innerHTML);
    }
  }

  async onPaste() {
    event.stopPropagation();
    event.preventDefault();

    var pastedData;
    pastedData = await navigator.clipboard.readText();

    let allText = '';
    let inputText = document.getElementById("text-input-"+this.editorId);
    if(inputText){
      allText = inputText.innerText;
    }

    allText += pastedData;
    // if(allText.length > this.maxChar){
    //   let allowedLength = this.maxChar - (allText.length - pastedData.length);
    //   pastedData = pastedData.substring(0, allowedLength);
    // }
    let formattedText = pastedData.replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    this.insertHtmlAtCursor(formattedText);
    if(inputText){
      this.control?.setValue(inputText.innerText);
    }
    // event.stopPropagation();
    // event.preventDefault();

    // var pastedData;
    // pastedData = await navigator.clipboard.readText();

    // let allText = '';
    // let inputText = document.getElementById("text-input-"+this.editorId);
    // if(inputText){
    //   allText = inputText.innerText;
    // }

    // allText += pastedData;
    // if(allText.length > this.maxChar){
    //   let allowedLength = this.maxChar - (allText.length - pastedData.length);
    //   pastedData = pastedData.substring(0, allowedLength);
    // }
    // this.insertTextAtCursor(pastedData);
    // if(inputText){
    //   this.control?.setValue(inputText.innerText);
    // }
  }

  insertTextAtCursor(text) {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    selection.deleteFromDocument();

    const range = selection.getRangeAt(0);
    range.deleteContents();
    const textNode = document.createTextNode(text);
    range.insertNode(textNode);

    range.setStartAfter(textNode);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  setContentWithFormatting(content) {
    let formattedContent = content.replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    let inputText = document.getElementById("text-input-" + this.editorId);
    if (inputText) {
        inputText.innerHTML = formattedContent;
    }
  }

  insertHtmlAtCursor(html) {
    let sel, range;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();

            // Create a temporary div to insert the HTML
            var el = document.createElement("div");
            el.innerHTML = html;
            var frag = document.createDocumentFragment(), node, lastNode;
            while ((node = el.firstChild)) {
                lastNode = frag.appendChild(node);
            }
            range.insertNode(frag);

            // Preserve the selection point
            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    }

}

  // onPaste(event) {
  //   // Stop data actually being pasted into div
  //   // event.stopPropagation();
  //   // event.preventDefault();

  //   var clipboardData, pastedData;
  //   // Get pasted data via clipboard API
  //   clipboardData = event.clipboardData;
  //   pastedData = clipboardData.getData('text');

  //   if(pastedData.length <= this.maxChar){
  //     pastedData = pastedData.substring(0,this.maxChar);
  //   }
  //   while(pastedData.includes("\r\n\r\n")){
  //     pastedData = pastedData.replace("\r\n\r\n","\r\n");
  //   }
  //   // let inputText = document.getElementById("text-input-"+this.editorId);
  //   // if(inputText){
  //   //   inputText.innerText = inputText.innerText + pastedData;
  //   //   this.control.setValue(pastedData);
  //   // }
  // }


  isTextSelected(): boolean {
    return window.getSelection().type == "Range";
  }
}
