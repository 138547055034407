<form class="dropdown_filter_container" [ngClass]="showFilter ? 'dropdown_filter_container_open' : ''" [formGroup]="tempForm">
  <div class="lbl_list_title">FILTER</div>
  <div #dropdownOne class="dropdown_wrapper" >
    <div class="lbl_regular_small" [ngStyle]="{'opacity': tempForm.controls.brand.value ? '1' : '0'}">Marke</div>
    <div class="dropdown_input_header" (click)="showDropdownOne = !showDropdownOne" appDropdown [show]="showDropdownOne" (changedValueEmitter)="showDropdownOne = $event">
      <input #input_1 class="dropdown_input" type="text" [placeholder]="!tempForm.controls.brand.value ? 'Marken' : ''" [value]="tempForm.controls.brand.value ? tempForm.controls.brand.value.makeName : ''" (input)="filterBrands(input_1.value)">
      <img class="dropdown_input_header_icon" [ngClass]="showDropdownOne ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
    </div>
    <div class="dropdown_input_body" *ngIf="showDropdownOne">
      <div *ngFor="let brand of brandsFiltered" [id]="'dropdown_value_'+brand.id" class="dropdown_data_body_element" (click)="setBrand(brand)">{{brand.makeName}}</div>
    </div>
  </div>
  <div #dropdownThree class="dropdown_wrapper" >
    <div class="lbl_regular_small" [ngStyle]="{'opacity': tempForm.controls.model.value ? '1' : '0'}">Modelgruppe</div>
    <div class="dropdown_input_header" (click)="tempForm.controls.brand?.value ? showDropdownThree = !showDropdownThree : null" [ngClass]="!tempForm.controls.brand?.value ? 'dropdown_header_data_disabled' : ''" appDropdown [show]="showDropdownThree" (changedValueEmitter)="showDropdownThree = $event">
      <input #input_3 class="dropdown_input" type="text" [placeholder]="!tempForm.controls.model.value ? 'Modelgruppe' : ''" [value]="tempForm.controls.model.value ? tempForm.controls.model.value.name : ''" [ngStyle]="{'background-color': tempForm.controls.brand?.value ? '' : 'var(--color-gray-lighter)'}" (input)="filterModels(input_3.value)">
      <img class="dropdown_input_header_icon" [ngClass]="showDropdownThree ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
    </div>
    <div class="dropdown_input_body" *ngIf="showDropdownThree">
      <div *ngFor="let model of modelsFiltered" [id]="'dropdown_value_'+model.id" class="dropdown_data_body_element" (click)="tempForm.controls.model.setValue(model)">{{model.name}}</div>
    </div>
  </div>
  <div #dropdownTwo class="dropdown_wrapper" >
    <div class="lbl_regular_small" [ngStyle]="{'opacity': tempForm.controls.color.value ? '1' : '0'}">Farbe</div>
    <div class="dropdown_input_header" (click)="showDropdownTwo = !showDropdownTwo" appDropdown [show]="showDropdownTwo" (changedValueEmitter)="showDropdownTwo = $event">
      <input #input_2 class="dropdown_input" type="text" placeholder="Farbe" [ngClass]="!tempForm.controls.color.value ? 'dropdown_input_placeholder' : ''" [value]="tempForm.controls.color.value ? tempForm.controls.color.value.name : ''" (input)="filterColors(input_2.value)">
      <img class="dropdown_input_header_icon" [ngClass]="showDropdownTwo ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
    </div>
    <div class="dropdown_input_body" *ngIf="showDropdownTwo">
      <div *ngFor="let color of colorsFiltered" [id]="'dropdown_value_'+color.id" class="dropdown_data_body_element" (click)="tempForm.controls.color.setValue(color)">{{color.name}}</div>
    </div>
  </div>
  <div class="mileage_container" style="column-gap: 4%;">
    <div class="input_wrapper" style="margin-bottom: 10px;">
      <div class="lbl_inputfield_container">
        <div class="lbl_regular_small" [ngStyle]="{'opacity': tempForm.controls.mileage_from.value ? '1' : '0'}">Kilometer von</div>
      </div>
      <input type="number" class="inputfield" placeholder="Kilometer von" min="0" step="1" formControlName="mileage_from">
    </div>
    <div class="input_wrapper" style="margin-bottom: 10px;">
      <div class="lbl_inputfield_container">
        <div class="lbl_regular_small" [ngStyle]="{'opacity': tempForm.controls.mileage_to.value ? '1' : '0'}">Kilometer bis</div>
      </div>
      <input type="number" class="inputfield" placeholder="Kilometer bis" min="0" step="1" formControlName="mileage_to">
    </div>
  </div>
  <div class="mileage_container">
    <!-- <div style="width: 100%;">
      <div class="lbl_regular" [ngStyle]="{'opacity': tempForm.controls.firstregistration_date_from.value != null ? '1' : '0'}">EZ ab MM/JJJJ</div>
      <div appCustomDateFormat1 class="date_input_wrapper">
          <input #dateInput1 class="inputfield" [ngStyle]="{'width': '100%'}" placeholder="EZ ab MM/JJJJ" type="text" maxlength="7" [max]="maxDate_1" [matDatepicker]="picker1" formControlName="firstregistration_date_from" [ngClass]="tempForm.controls.firstregistration_date_from.errors?.validateDateInput?.valid == false ? 'inputfield_invalid' : ''">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1
                          id="datepicker_filter"
                          startView="multi-year"
                          (monthSelected)="closeDatePickerOne($event, picker1)"
          ></mat-datepicker>
      </div>
    </div> -->
    <!-- <div style="width: 100%;">
      <div class="lbl_regular" [ngStyle]="{'opacity': tempForm.controls.firstregistration_date_to.value != null ? '1' : '0'}">EZ bis MM/JJJJ</div>
      <div appCustomDateFormat1 class="date_input_wrapper">
          <input #dateInput2 class="inputfield" [ngStyle]="{'width': '100%'}" placeholder="EZ bis MM/JJJJ" type="text" maxlength="7" [max]="maxDate_2" [matDatepicker]="picker2" formControlName="firstregistration_date_to" [ngClass]="tempForm.controls.firstregistration_date_to.errors?.validateDateInput?.valid == false ? 'inputfield_invalid' : ''">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2
                          id="datepicker_filter"
                          startView="multi-year"
                          (monthSelected)="closeDatePickerTwo($event, picker2)"
          ></mat-datepicker>
      </div>
    </div> -->
  </div>
  <div class="lbl_regular_small" style="margin-top: 20px;">Datenqualität</div>
  <div class="percent_wrapper">
      <div class="lbl_color_wrapper_green" [ngClass]="tempForm.controls.percent_typ.value == 1 ? 'lbl_color_wrapper_green_active' : ''" (click)="tempForm.controls.percent_typ.setValue(1)">90-100%</div>
      <div class="lbl_color_wrapper_orange" [ngClass]="tempForm.controls.percent_typ.value == 2 ? 'lbl_color_wrapper_orange_active' : ''" (click)="tempForm.controls.percent_typ.setValue(2)">60-89%</div>
      <div class="lbl_color_wrapper_red" [ngClass]="tempForm.controls.percent_typ.value == 3 ? 'lbl_color_wrapper_red_active' : ''" (click)="tempForm.controls.percent_typ.setValue(3)">44-59%</div>
  </div>
  <div class="footer" [ngStyle]="{'justify-content': !filteringSet ? 'flex-end' : 'space-between'}">
    <div *ngIf="filteringSet" class="btn_menu_information" (click)="resetFilter()">Zurücksetzen</div>
    <div class="btn_submit_dark" (click)="submitFilter()">Filtern</div>
  </div>
</form>

