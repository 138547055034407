import { BehaviorSubject } from "rxjs";
import { SubMenuColumn } from "./SubMenuColumn";
import { SubMenuFilter } from "./SubMenuFilter";

export const EM_MULTIPLIER = 3;

export class SubMenu {
  public sub_menu_column_id: number;
  public _isActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public active$ = this._isActive.asObservable();

  public to_hide_sub_menu_column_id: number = 12;
  public to_hide_value: any = new Date();

  constructor(
    public sub_menu_id: number,
    public name: String,
    public sort_value: number,
    public filter: SubMenuFilter[],
    public columns: SubMenuColumn[],
    public actions: any[],
    public filter_id: number,
    public is_hidden: number,
    public pagination: boolean,
    public filterable: any[],
    public default_sort_column_id: number,
    public default_sort_direction: string = 'DESC',
    public disabled_elements = false
  ) {}

  setActive(active: boolean) {
    this._isActive.next(active);
  }

  getActive() {
    return this._isActive.getValue();
  }

    // setStyle() {
  //   let templateColumns = '';
  //   const list = document.getElementById('ab-item-list');
  //   let maxWidth = this.pxToEm(list.offsetWidth);
  //   let sumEm = 0;
  //   for(let i = 0; i < this.columns.length; i++){
  //     sumEm += (this.columns[i].width*EM_MULTIPLIER);
  //     templateColumns += (this.columns[i].width*EM_MULTIPLIER)+'em ';
  //   }
  //   let ZIndex = index;
  //   if(sumEm <= maxWidth){
  //     templateColumns = '';
  //     for(let i = 0; i < this.columns.length; i++){
  //       templateColumns += this.columns[i].width+'fr ';
  //     }
  //     // for(let i = 0; i < temp.length; i++){
  //     //   const backgroundColor = i == 0 || i%2 == 0 ? "#f5f5f5" : "#ffffff"; //TODO: farbe ändern
  //     //   // temp[i].setAttribute('style', 'position: relative; grid-template-columns: '+templateColumns+' 50px; width: auto; height: 100%; ' + '; z-index: '+(ZIndex-i) + '; text-decoration: none');
  //     //   let test = temp[i] as HTMLDivElement;
  //     //   test.style.backgroundColor = backgroundColor+' !important';
  //     //   test.style.gridTemplateColumns = templateColumns + '50px';
  //     //   test.style.position = 'relative';
  //     //   test.style.width = 'auto';
  //     //   test.style.height = '100%;';
  //     //   test.style.zIndex = (ZIndex-i).toString();
  //     //   test.style.textDecoration = 'none';
  //     // }
  //     const backgroundColor = index == 0 || index%2 == 0 ? "#f5f5f5" : "#ffffff";
  //     this.style = 'position: relative; ' +
  //       'grid-template-columns: '+ templateColumns + '50px; ' +
  //       'width: auto;' +
  //       'height: 100%' +
  //       'z-index: '+(ZIndex-index) +'; '+
  //       'text-decoration: none'+
  //       'background-color: '+ backgroundColor +' !important;';
  //   } else {
  //     const backgroundColor = index == 0 || index%2 == 0 ? "#f5f5f5" : "#ffffff";
  //     this.style = 'position: relative; ' +
  //       'grid-template-columns: '+ templateColumns + '50px; ' +
  //       'width: max-content;' +
  //       'height: 100%' +
  //       'z-index: '+(ZIndex-index) +'; '+
  //       'text-decoration: none'+
  //       'background-color: '+ backgroundColor +' !important;';
  //   }
  //   return 'submenu-column-wrapper';
  // }

  getColumnWrapperStyle(index: number) {
    let templateColumns = '';
    const list = document.getElementById('ab-item-list');
    let maxWidth = this.pxToEm(list.offsetWidth);
    let sumEm = 0;
    for(let i = 0; i < this.columns.length; i++){
      sumEm += (this.columns[i].width*EM_MULTIPLIER);
      templateColumns += (this.columns[i].width*EM_MULTIPLIER)+'em ';
    }
    let temp = document.getElementsByClassName('submenu-column-wrapper');
    let ZIndex = temp.length;
    if(sumEm <= maxWidth){
      templateColumns = '';
      for(let i = 0; i < this.columns.length; i++){
        templateColumns += this.columns[i].width+'fr ';
      }
      for(let i = 0; i < temp.length; i++){
        const backgroundColor = i == 0 || i%2 == 0 ? "#f5f5f5" : "#ffffff"; //TODO: farbe
        // temp[i].setAttribute('style', 'position: relative; grid-template-columns: '+templateColumns+' 50px; width: auto; height: 100%; ' + '; z-index: '+(ZIndex-i) + '; text-decoration: none');
        let test = temp[i] as HTMLDivElement;
        test.style.backgroundColor = backgroundColor;
        test.style.gridTemplateColumns = templateColumns + '50px';
        test.style.position = 'relative';
        test.style.width = 'auto';
        test.style.height = '100%;';
        test.style.zIndex = (ZIndex-i).toString();
        test.style.textDecoration = 'none';
      }
    } else {
      for(let i = 0; i < temp.length; i++){
        const backgroundColor = i == 0 || i%2 == 0 ? "#f5f5f5" : "#ffffff";
        //temp[i].setAttribute('style', 'position: relative; grid-template-columns: '+templateColumns+' 50px; width: max-content; height: 100%; ' + '; z-index: '+(ZIndex-i) + '; text-decoration: none');
        let test = temp[i] as HTMLDivElement;
        test.style.backgroundColor = backgroundColor;
        test.style.gridTemplateColumns = templateColumns + '50px';
        test.style.position = 'relative';
        test.style.width = 'max-content';
        test.style.height = '100%;';
        test.style.zIndex = (ZIndex-i).toString();
        test.style.textDecoration = 'none';
      }
    }
    return 'submenu-column-wrapper';
  }

  getColumnWrapperStyleHeaderFooter(listContainerTagId: string, columnWrapperTagId: string) {
    const listHeaderRow = document.getElementById(columnWrapperTagId);
    if(listHeaderRow != null){
      let templateColumns = '';
      const list = document.getElementById(listContainerTagId);
      let maxWidth = this.pxToEm(list.offsetWidth);
      let sumEm = 0;
      for(let i = 0; i < this.columns.length; i++){
        sumEm += (this.columns[i].width*EM_MULTIPLIER);
        templateColumns += (this.columns[i].width*EM_MULTIPLIER)+'em ';
      }
      if(sumEm <= maxWidth){
        templateColumns = '';
        for(let i = 0; i < this.columns.length; i++){
          templateColumns += this.columns[i].width+'fr ';
        }
      }
      const width = sumEm <= maxWidth ? 'auto' : ''
      listHeaderRow.setAttribute('style', 'grid-template-columns: '+templateColumns+' 50px; ' + 'width: '+ width + '; background-color: #ffffff; z-index: 51;');
    }
  }

  pxToEm(px: number){
    const baseFontSize = 16;
    return px/baseFontSize;
  }

  getColumnFixedLeftPos(stopIndex: number): number {
    let value = 0;
    for(let i = 1; i <= stopIndex; i++){
      if(this.columns[i-1]?.is_fixed){
        value += (this.columns[i-1].width*EM_MULTIPLIER);
      }
    }
    return value;
  }
}
