<div class="page_container">
  <app-contract-page-one *ngIf="pageContract == 1"
     [form]="formContract"
     [customers]="customers"
     [submitted]="submitted"
     (formCustomerValueChangedEmitter)="formCustomerValueChangedEmitter.emit()"
  ></app-contract-page-one>
  <app-contract-page-two *ngIf="pageContract == 2"
     [form]="formContract"
     [vehicles]="vehicles"
     [submitted]="submitted"
  ></app-contract-page-two>
  <app-contract-page-three *ngIf="pageContract == 3"
     [form]="formContract"
     [submitted]="submitted"
  ></app-contract-page-three>
  <app-contract-page-four *ngIf="pageContract == 4"
     [form]="formContract"
     [submitted]="submitted"
  ></app-contract-page-four>
  <app-contract-page-five *ngIf="pageContract == 5"
     [form]="formContract"
     [submitted]="submitted"
  ></app-contract-page-five>
  <app-contract-navigation
    [isEdit]="isEdit"
    [currentPageCreate]="pageContract"
    (changePageEmitter)="changePage($event)"
  ></app-contract-navigation>
</div>

<app-terms *ngIf="showTerms"
   [form]="formContract"
   (acceptEmitter)="acceptTerms()"
   (cancelEmitter)="cancel()"
></app-terms>
