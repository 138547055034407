<div id="overview_container">
  <form id="create_employee_container" [formGroup]="form">
    <div class="lbl_bold_light_color">{{isEdit ? 'Mitarbeiter bearbeiten':'Mitarbeiter anlegen'}}</div>
    <div id="create_employee_body">
      <div class="body_column">
        <div class="body_column_block">
          <div class="overview_data_box_lbl_title">Userdaten</div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Vorname *</div>
            <input type="text" placeholder="Vorname" formControlName="firstName" [class.input_invalid]="submitted && form.controls.firstName.errors">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Nachname *</div>
            <input type="text" placeholder="Nachname" formControlName="lastName" [class.input_invalid]="submitted && form.controls.lastName.errors">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">E-Mail *</div>
            <input type="text" placeholder="E-Mail" formControlName="email" [class.input_invalid]="submitted && form.controls.email.errors">
          </div>
        </div>
        <div class="body_column_block">
          <div class="overview_data_box_lbl_title">Zugangsdaten</div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Username *</div>
            <input type="text" placeholder="Username" formControlName="username" [class.input_invalid]="submitted && form.controls.username.errors">
          </div>
          <div class="input_wrapper" *ngIf="!isEdit">
            <div class="overview_record_lbl">Passwort *</div>
            <input type="text" placeholder="Passwort" formControlName="password" [class.input_invalid]="submitted && form.controls['password'].errors">
          </div>
          <label class="overview_record_lbl lbl-info" *ngIf="!isEdit">Anforderungen für das Passwort:
            <ul>
              <li>Das Passwort muss mindestens 8 Zeichen lang sein.</li>
              <li>Sonderzeichen sind erlaubt, aber nicht zwingend erforderlich.</li>
            </ul>
          </label>
          <button class="btn_label_blue" *ngIf="isEdit" (click)="form.controls['create_new_password'].setValue(!form.controls['create_new_password'].value)">Neues Passwort festlegen</button>
          <div class="input_wrapper" *ngIf="isEdit && form.controls['create_new_password'].value">
            <div class="overview_record_lbl">Neues Passwort festlegen</div>
            <input type="text" placeholder="Passwort" formControlName="password_new" [class.input_invalid]="submitted && (form.controls['password_new'].errors || form.controls['password_new'].value == null)">
          </div>
          <label class="overview_record_lbl lbl-info" *ngIf="isEdit && form.controls['create_new_password'].value">Anforderungen für das Passwort:
            <ul>
              <li>Das Passwort muss mindestens 8 Zeichen lang sein.</li>
              <li>Sonderzeichen sind erlaubt, aber nicht zwingend erforderlich.</li>
            </ul>
          </label>
        </div>
      </div>
      <div class="body_column">
        <div class="body_column_block">
          <div class="overview_data_box_lbl_title">Beaufauftragung</div>
          <app-dropdown-objects class="do"
            [title]="'Standort'"
            [placeholder]="'Auswählen...'"
            [headervalue]="form.controls['location'].value?.name"
            [formcontrol]="form.controls.location"
            [values]="locationValues"
            [submitted]="submitted"
            (submitValueEmitter)="setValueFromDropdown('location', $event)"
          ></app-dropdown-objects>
        </div>
        <div class="body_column_block">
          <div class="inner_title_wrapper">
            <div class="overview_data_box_lbl_title" >Berechtigungen</div>
            <label class="lbl_error" *ngIf="submitted && !form.controls.role.value">Wählen Sie eine Berechtigung aus</label>
          </div>
          <div class="radio_container" *ngFor="let role of roles" (click)="form.controls.role.setValue(role)">
            <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="form.controls.role.value == role  ? 'radio_icon_inner_active' : ''"></div></div>
            <div class="lbl_info">{{role}}</div>
          </div>
        </div>
        <div class="body_column_block">
          <div class="status_wrapper">
            <div class="overview_data_box_lbl_title">Status</div>
            <div class="state-container" [ngClass]="form.controls.active.value ? 'state_active' : 'state_inactive'" (click)="form.controls.active.setValue(!form.controls.active.value)">{{form.controls.active.value ? 'Aktiv' : 'Inaktiv'}}</div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
