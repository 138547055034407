<div class="overview_data_box">
    <div class="overview_data_box_lbl_title">Standzeit</div>
    <div class="overview_record">
        <div class="overview_record_lbl">Standort</div>
        <div class="overview_record_value">---</div>
    </div>
    <div class="overview_record">
        <div class="overview_record_lbl">Am Standort seit</div>
        <div class="overview_record_value">---</div>
    </div>
    <div class="overview_record">
        <div class="overview_record_lbl">Standzeit</div>
        <div class="overview_record_value">---</div>
    </div>
</div>