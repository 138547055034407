import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { Bill, BillStatus, BillStatusLabels, PaymentMethod, PaymentMethodLabels } from 'src/app/buisness-object/document/Bill';
import { BillForm } from 'src/app/buisness-object/document/form/BillForm';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { countries } from '../../BaseComponent';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { getEnumFromLabel, getEnumLabelValues, getLabelFromEnumValue } from 'src/app/utils/EnumHandler';
import { DocumentService } from 'src/app/service/document/document.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { DateHelper } from 'src/app/utils/DateHelper';
import { Location } from '@angular/common';

@Component({
  selector: 'app-bill-create',
  templateUrl: './bill-create.component.html',
  styleUrl: './bill-create.component.css'
})
export class BillCreateComponent implements OnInit {
  @Input() obj;
  @Input() isCreateCredit: boolean = false;
  @Output() cancelCreateEmitter = new EventEmitter<undefined>();
  @Output() successCreateEmitter = new EventEmitter<any>();
  @Output() successUpdateEmitter = new EventEmitter<any>();
  public showCreateChoise = true;
  public customerChanged = true;
  public form: FormGroup;
  public customerValues = [];
  public customers: Customer[] = [];
  public billForm = BillForm;
  public salutationValues = [];
  public salutationValue;
  public titleValues = [];
  public titleValue;
  public paymentMethods = getEnumLabelValues(PaymentMethod, PaymentMethodLabels);
  public selectedPaymentMethod;
  public countries = countries;
  public formValuesChanged = false;
  public dialogQuerySubsription: Subscription;
  public submitted = false;
  public totalNetto;
  public totalBrutto;
  public totalTax10;
  public totalTax20;
  public typValues = ["Firma","Privat"];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private customerService: CustomerService,
    private enumService: EnumService,
    private cdr: ChangeDetectorRef,
    private documentService: DocumentService,
    private dialogService: DialogService,
    public authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private vehicleService: VehicleService,
  ){}

  ngOnInit(): void {
    this.setUp();
  }

  setUp() {
    forkJoin({
      customers: this.customerService.getCustomer(),
    }).subscribe((result) => {
      if(result){
        this.customers = result.customers;
        this.initView();
      }
    });
  }

  initView() {
    if(this.obj) this.showCreateChoise = false;
    this.form = BillForm.getForm(this.formBuilder, this.obj);
    this.checkRouteParameter();
    if(this.isCreateCredit){
      let index = this.paymentMethods.findIndex((p) => p == getLabelFromEnumValue(PaymentMethod.FINANCING, PaymentMethodLabels));
      if(index > -1){
        this.paymentMethods.splice(index, 1);
      }
      this.form.get('subject').setValue('Gutschrift');
      this.form.get('subject').disable();
    }
    if(this.obj) {
      this.submitCustomer();
      this.getTotalNetto();
      this.getTotalBrutto();
      this.getTotalTaxes();
      setTimeout(() => {
        this.setPaymentMethod(getLabelFromEnumValue(this.obj.payment_method, PaymentMethodLabels));
      }, 10);
    }
    setTimeout(() => {
      this.form.valueChanges.subscribe((changes) => {
        if(changes){
          this.formValuesChanged = true;
        }
      })
    }, 200);
    this.customerValues = this.getDropdownValuesCustomer(this.customers);
  }

  checkRouteParameter() {
    const queryParams = this.activatedRoute.snapshot.queryParamMap;
    const vehicleId = Number(queryParams.get('vehicleId'));
    if(vehicleId){
      this.vehicleService.getSingleVehicle(vehicleId).subscribe((vehicle) => {
        if(vehicle){
          const title = vehicle.getDescription() + '\n' + 'VIN: '
            + (vehicle.fin_number??'---') + '\n' + 'Erstzulassung: '
            + (DateHelper.getConvertedDateForInputFull(vehicle.firstRegistrationDate)??'---');
          this.form.get('positions').value[0].get('title').setValue(title);
          this.form.get('positions').value[0].get('quantity').setValue(1);
          this.form.get('positions').value[0].get('price').setValue(-Math.abs(vehicle.price));
          const tax = vehicle.priceSheet?.steuersatz*10;
          this.form.get('positions').value[0].get('tax_rate').setValue(tax);
        }
      })
    }
  }


  selectionCancel() {
    this.cancelCreateEmitter.emit();
  }

  goToCustomer() {
    this.router.navigate(['customers'])
  }

  getDropdownValuesCustomer(customers: Customer[]): any[] {
    let values = [];
    for(let value of customers){
      values.push({
        id: value.id,
        name: value.contactPerson.firstName + ' ' + value.contactPerson.lastName + (value.companyName ? ' - ' + value.companyName : '')
      })
    }
    return values;
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'customer'){
      let index = this.customers.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['customer'].setValue(this.customers[index]);
      }
    }
  }

  get formCustomer() {
    return this.form?.controls['customer_form'].value;
  }

  submitCustomer() {
    this.form.get('customer_form').setValue(CustomerForm.getCustomerForm(this.formBuilder, this.form.get('customer').value));
    this.formCustomer.get('dataprivacyAccepted').setValue(true);
    this.salutationValue = this.enumService.getEnumValue('salutation', this.formCustomer.controls['salutation'].value);
    this.salutationValues = this.enumService.getEnumValues('salutation');
    this.titleValues = this.enumService.getEnumValues('title');
    this.form.get('customer_form').value.valueChanges.subscribe((value) => {
      this.customerChanged = true;
    })
    for(let posForm of this.form.get('positions').value){
      posForm.valueChanges.subscribe((change) => {
        if(change){
          this.getTotalNetto();
          this.getTotalBrutto();
          this.getTotalTaxes();
          this.cdr.detectChanges();
        }
      })
    }
    this.showCreateChoise = false;
    this.customerChanged = false;
  }

  setPaymentMethod(value: string) {
    this.selectedPaymentMethod = value;
    this.form.get('payment_method').setValue(getEnumFromLabel(value, PaymentMethodLabels));
  }

  selectionSave() {
    this.submitted = true;
    if(this.invalid()) return;
    const obj = BillForm.getObject(this.form);
    const savedCustomer = CustomerForm.createObject(this.form.get('customer_form').value);
    obj.bill_recipient = savedCustomer;
    obj.saved_bill_recipient_id = this.obj.saved_bill_recipient_id;
    this.documentService.updateBill(obj).subscribe((result) => {
      if(result){
          this.dialogService.showNotification({
            title: 'Erfolgreich',
            message: 'Rechnung aktualisiert.',
            success: true
          });
          this.successUpdateEmitter.emit(result);
      }
    })
  }

  selectionCreate() {
    this.submitted = true;
    if(this.invalid()) return;
    const savedCustomer = CustomerForm.createObject(this.form.get('customer_form').value);
    const obj = BillForm.getObject(this.form);
    obj.bill_recipient = savedCustomer;
    this.documentService.createBill(obj).subscribe((result) => {
      if(result){
          this.dialogService.showNotification({
            title: 'Erfolgreich',
            message: 'Rechnung erstellt.',
            success: true
          });
          this.successCreateEmitter.emit(result);
      }
    })
  }

  invalid(): boolean {
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return true;
    }
    if(this.form.get('customer_form').value.invalid){
      ErrorHelper.showFormError(this.form.get('customer_form').value);
      return true;
    }
    for(let posForm of this.form.get('positions').value){
      if(posForm.invalid){
        ErrorHelper.showFormError(posForm);
        return true;
      }
    }
    return false;
  }

  addPosition() {
    let posForm = BillForm.getPositionForm(this.formBuilder);
    posForm.valueChanges.subscribe((change) => {
      if(change){
        this.getTotalNetto();
        this.getTotalBrutto();
        this.getTotalTaxes();
      }
    });
    this.form.get('positions').value.push(posForm);
  }

  removePosition(index: number) {
    this.form.get('positions').value.splice(index, 1);
    this.getTotalNetto();
    this.getTotalBrutto();
    this.getTotalTaxes();
  }

  getTotalNetto() {
    let tn = 0;
    for(let posForm of this.form.get('positions').value){
      const price = posForm.get('price').value;
      if(price){
        const quantity = posForm.get('quantity').value;
        tn += (price * (quantity ? quantity : 1));
      }
    }
    this.totalNetto = tn;
  }

  getTotalBrutto() {
    let tb = 0;
    for(let posForm of this.form.get('positions').value){
      const price = posForm.get('price').value;
      if(price){
        const quantity = posForm.get('quantity').value;0
        let tax = 0;
        switch(posForm.get('tax_rate').value){
          case 10: tax = 1.1; break;
          case 20: tax = 1.2; break;
          default: tax = 1; break;
        }
        tb += ((price * (quantity ? quantity : 1))*tax);
      }
    }
    this.totalBrutto = tb;
  }

  getTotalTaxes() {
    let t10 = 0;
    let t20 = 0;
    for(let posForm of this.form.get('positions').value){
      const tax = posForm.get('tax_rate').value;
      const netto = this.getPosNetto(posForm);
      const brutto = this.getPosBrutto(posForm);
      if(tax == 10) t10 += (brutto - netto);
      else if(tax == 20) t20 += (brutto - netto);
    }
    if(t10 != 0) this.totalTax10 = t10;
    else this.totalTax10 = null;
    this.totalTax20 = t20;
  }

  getPosNetto(form: FormGroup): number {
    let netto = 0;
    const price = form.get('price').value;
    if(price){
      const quantity = form.get('quantity').value;
      netto = ((price * (quantity ? quantity : 1)));
    }
    return netto;
  }

  getPosBrutto(form: FormGroup): number {
    let brutto = 0;
    const price = form.get('price').value;
    if(price){
      let tax = 0;
      switch(form.get('tax_rate').value){
        case 10: tax = 1.1; break;
        case 20: tax = 1.2; break;
        default: tax = 1; break;
      }
      const quantity = form.get('quantity').value;
      brutto = ((price * (quantity ? quantity : 1)) * tax);
    }
    return brutto;
  }
}
