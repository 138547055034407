import { Vehicle } from './../../../../buisness-object/vehicle/Vehicle';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { VehicleStatus } from 'src/app/view/list-action-container/btn-action/btn-action.component';

@Component({
  selector: 'app-drive-page-one',
  templateUrl: './drive-page-one.component.html',
  styleUrls: ['./drive-page-one.component.css']
})
export class DrivePageOneComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() vehicles: Vehicle[];

  public showDropdownOne = false;
  public vehiclesFiltered: Vehicle[] = [];
  public selectedVehicle: Vehicle;

  @ViewChild('input_vehicle') input_vehicle: ElementRef;
  public listener: () => void;

  constructor(
    private renderer: Renderer2,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    if(this.f.vehicle.value){
      this.selectedVehicle = this.f.vehicle.value;
    }
    this.vehiclesFiltered = this.vehicles;
  }

  ngAfterViewInit(): void {
    if(this.selectedVehicle) this.input_vehicle.nativeElement.value = this.selectedVehicle.brand.makeName + ' ' + this.selectedVehicle.model.name + ' ' + this.selectedVehicle.model.version
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showDropdownOne && !this.input_vehicle.nativeElement.contains(e.target)) this.showDropdownOne = false;
    })
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  get f() {
    return this.form.controls;
  }

  sort() {
    this.vehicles.sort((a,b) => {
      if(a.brand.makeName > b.brand.makeName) return 1;
      if(a.brand.makeName < b.brand.makeName) return -1;
      return 0;
    })
  }

  filterVehicles(value: string) {
    if(value && value.length > 0){
      this.selectedVehicle = null;
      this.form.controls.vehicle.setValue(null);
      this.vehiclesFiltered = [];
      this.vehiclesFiltered = this.vehicles.filter((vehicle) => {
        return vehicle.brand.makeName.toLowerCase().includes(value.toLowerCase()) ||
               vehicle.model.name.toLowerCase().includes(value.toLowerCase()) ||
               vehicle.model.version.toLowerCase().includes(value.toLowerCase()) ||
               vehicle.fin_number?.toLowerCase().includes(value.toLowerCase()) ||
               this.authService.isSinhuber() ? vehicle.vehicle_serialnumber?.toLowerCase().includes(value.toLowerCase()) : vehicle.fin_number?.toLowerCase().includes(value.toLowerCase()) ||
               vehicle.externalId ? vehicle.externalId.toLowerCase().includes(value.toLowerCase()) : vehicle.id.toString().toLowerCase().includes(value.toLowerCase()) ||
               (vehicle.brand.makeName.toLowerCase() + ' ' + vehicle.model.name.toLowerCase() + ' ' + vehicle.model.version.toLowerCase()).includes(value.toLowerCase());
      })
    } else {
      this.vehiclesFiltered = this.vehicles;
    }
  }

  setVehicle(vehicle: Vehicle) {
    this.selectedVehicle = vehicle;
    this.form.controls.vehicle.setValue(vehicle);
    this.input_vehicle.nativeElement.value = vehicle.brand.makeName + ' ' + vehicle.model.name + ' ' + vehicle.model.version
  }
}
