import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Bill, BillStatus, BillStatusLabels, getBillStatusLabelStyle } from 'src/app/buisness-object/document/Bill';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { BaseComponent } from '../../BaseComponent';
import { EnumService } from 'src/app/service/enum/enum.service';
import { VehicleStatus } from 'src/app/buisness-object/document/Kaufvertrag';
import { getLabelFromEnumValue } from 'src/app/utils/EnumHandler';
import { DocumentService } from 'src/app/service/document/document.service';
import { CloneObject } from 'src/app/helpers/CloneObject';
import { DialogService } from 'src/app/service/dialog/dialog.service';

export enum BillContractDetailsTab {
  Details = 1,
  Tickets = 1,
}

@Component({
  selector: 'app-bill-details-purchase-contract',
  templateUrl: './bill-details-purchase-contract.component.html',
  styleUrl: './bill-details-purchase-contract.component.css'
})
export class BillDetailsPurchaseContractComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() obj: Bill;
  @Input() isPreviousObjAvailable: boolean;
  @Input() isNextObjAvailable: boolean;
  @Output() selectionBackEmitter = new EventEmitter<undefined>();
  @Output() selectionEditEmitter = new EventEmitter<any>();
  @Output() successPaidEmitter = new EventEmitter<any>();
  @Output() selectionPreviousObjEmitter = new EventEmitter<Bill>();
  @Output() selectionNextObjEmitter = new EventEmitter<Bill>();
  public tabView = BillContractDetailsTab;
  public showTab: BillContractDetailsTab = BillContractDetailsTab.Details;
  public shwoActions = false;
  public paymentConditionValue
  public downPaymentConditionValue;
  public finanzierungsartenValue;
  public finanzierungsgesellschaftValue;
  public mechanical_conditionValue;
  public body_conditionValue;
  public paint_conditionValue;
  public interior_conditionValue;
  public electronic_conditionValue;
  public total_condition;
  public registrationDocumentValue;
  public billStatus;
  public billStatusStyle;
  public BillStatus = BillStatus;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService,
    private documentService: DocumentService,
    private dialogService: DialogService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.setUp();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes &&
      changes['obj'] &&
      (changes['obj'].currentValue?.bill_id != changes['obj'].previousValue?.bill_id) ||
      changes['obj'].currentValue?.status != changes['obj'].previousValue?.status){
        this.setUp();
    }
  }

  setUp() {
    this.paymentConditionValue = this.getEnumValue('payment_condition', this.obj.purchase_contract.payment_condition);
    this.downPaymentConditionValue = this.getEnumValue('payment_condition', this.obj.purchase_contract.down_payment_condition);
    this.finanzierungsartenValue = this.getEnumValue('finanzierungsart', this.obj.purchase_contract.finanzierungsart);
    this.finanzierungsgesellschaftValue = this.getEnumValue('finanzierungsgesellschaft', this.obj.purchase_contract.registration_document);
    this.mechanical_conditionValue = this.getEnumValue('mechanical_condition', this.obj.purchase_contract.vehicle_status.mechanical_condition);
    this.body_conditionValue = this.getEnumValue('mechanical_condition', this.obj.purchase_contract.vehicle_status.body_condition);
    this.paint_conditionValue = this.getEnumValue('mechanical_condition', this.obj.purchase_contract.vehicle_status.paint_condition);
    this.interior_conditionValue = this.getEnumValue('mechanical_condition', this.obj.purchase_contract.vehicle_status.interior_condition);
    this.electronic_conditionValue = this.getEnumValue('mechanical_condition', this.obj.purchase_contract.vehicle_status.electronic_condition);
    this.total_condition = this.obj.purchase_contract.vehicle_status.getOverallCondition();
    this.registrationDocumentValue = this.getEnumValue('registration_document', this.obj.purchase_contract.registration_document);
    this.billStatus = this.obj.getStatus();
    this.billStatusStyle = this.obj.getStatusStyle();
  }

  showPaidButton(): boolean {
    if(this.obj?.is_storno_bill) {
      return false;
    }
    if(this.obj?.status == BillStatus.CANCELED){
      return false;
    }
    if(this.obj?.status == BillStatus.STORNO){
      return false;
    }
    if(this.obj?.status == BillStatus.PAID){
      return false;
    }
    return true;
  }

  selectionBack() {
    this.selectionBackEmitter.emit();
  }

  getConditionColor(value: string) {
    return VehicleStatus.getConditionColor(value);
  }

  selectionPDFPrint() {
    this.documentService.getBillPdf(this.obj.bill_id).subscribe(() => {});
  }

  selectionPaid() {
    let copy = CloneObject.deepCopy(this.obj);
    copy.status = BillStatus.PAID;
    this.documentService.updateBill(copy).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Ausgezeichnet',
          message: 'Rechnung Bezahlt.',
          success: true
        });
        this.successPaidEmitter.emit(result);
      }
    });
  }

  selectionPrevious() {
    this.setPagingDisabled();
    this.selectionPreviousObjEmitter.emit(this.obj);
  }

  selectionNext() {
    this.setPagingDisabled();
    this.selectionNextObjEmitter.emit(this.obj);
  }

  setPagingDisabled() {
    const btnPrevious = document.getElementById('btn-previous') as HTMLButtonElement;
    const btnNext = document.getElementById('btn-next') as HTMLButtonElement;
    if(btnPrevious && btnNext){
      btnPrevious.disabled = true;
      btnNext.disabled = true;
      setTimeout(() => {
        btnPrevious.disabled = false;
        btnNext.disabled = false;
      }, 500);
    }
  }
}
