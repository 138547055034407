import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Setting } from 'src/app/buisness-object/settings/Setting';
import { SettingFormHelper } from 'src/app/buisness-object/settings/SettingFormHelper';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.css']
})
export class AdminSettingsComponent extends BaseComponent implements OnInit {
  @Input() settings: Setting[];
  @Input() form: FormGroup;
  @Input() submitted: boolean;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
  }
}
