<div class="page_container">
  <!-- <app-drive-page-one *ngIf="pageDrive == 1"
    [form]="form"
    [vehicles]="vehicles"
    [submitted]="submitted"
  ></app-drive-page-one> -->
  <app-drive-page-two *ngIf="pageDrive == 1"
    [form]="form"
    [drives]="drives"
    [vehicles]="vehicles"
    [employees]="employees"
    [submitted]="submitted"
  ></app-drive-page-two>
  <app-drive-page-three *ngIf="pageDrive == 2"
    [form]="form"
    [customers]="customers"
    [submitted]="submitted"
  ></app-drive-page-three>
  <app-drive-navigation
    [currentPageCreate]="pageDrive"
    [form]="form"
    (changePageEmitter)="changePageEmitter.emit($event)"
  ></app-drive-navigation>
</div>
