import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { VehicleBodyColor } from 'src/app/buisness-object/vehicle/basic/VehicleBodyColor';
import { VehicleBodyTyp } from 'src/app/buisness-object/vehicle/basic/VehicleBodyTyp';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { VehicleDrive } from 'src/app/buisness-object/vehicle/basic/VehicleDrive';
import { VehicleFuel } from 'src/app/buisness-object/vehicle/basic/VehicleFuel';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { VehicleTransmission } from 'src/app/buisness-object/vehicle/basic/VehicleTransmission';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';

@Component({
  selector: 'app-fast-and-furious',
  templateUrl: './fast-and-furious.component.html',
  styleUrl: './fast-and-furious.component.css'
})
export class FastAndFuriousComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() submitted: boolean;

  public brands: VehicleBrand[];
  public fuels: VehicleFuel[];
  public transmissions: VehicleTransmission[];
  public bodyColors: VehicleBodyColor[];
  public bodyTyps: VehicleBodyTyp[];
  public categories: VehicleCategory[];
  public drives: VehicleDrive[];
  public brandValues = [];
  public modelValues = [];
  public bodytypValues = [];
  public categorieValues = [];
  public fuelsValues = [];
  public drivesValues = [];
  public transmissionsValues = [];
  public bodyColorValues = [];
  public models: VehicleModel[] = [];
  public maxDate;
  public minDate;
  public counterVersion = 80;

  constructor(
    private vehicleService: VehicleService
  ){}

  ngOnInit(): void {
    let date = new Date();
    date.setMonth(date.getMonth() - 24);
    this.maxDate = formatDate(date,'yyyy-MM-dd','de');
    this.getDataServerside();
  }

  getDataServerside() {
    forkJoin({
      brands: this.vehicleService.getBrands(),
      drives: this.vehicleService.getDrives(),
      bodyColors: this.vehicleService.getBodyColor(),
      fuels: this.vehicleService.getFuels(),
      categories: this.vehicleService.getCategories(),
      transmissions: this.vehicleService.getTransmissions(),
      bodyTyps: this.vehicleService.getBodyTyps(),
    }).subscribe((result) => {
      if(result){
        this.brands = result.brands.sort((a, b) => a.makeName.localeCompare(b.makeName));
        this.drives = result.drives.sort((a, b) => a.name.localeCompare(b.name));
        this.bodyColors = result.bodyColors.sort((a, b) => a.name.localeCompare(b.name));
        this.fuels = result.fuels.sort((a, b) => a.name.localeCompare(b.name));
        this.categories = result.categories.sort((a, b) => a.name.localeCompare(b.name));
        this.transmissions = result.transmissions.sort((a, b) => a.name.localeCompare(b.name));
        this.bodyTyps = result.bodyTyps.sort((a, b) => a.name.localeCompare(b.name));
        this.setDropdownValues();
      }
    })
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'brand'){
      let index = this.brands.findIndex(v => v.id == id);
      if(index > -1) this.setBrand(this.brands[index]);
    } else if(value === 'model'){
      let index = this.models.findIndex(v => v.id == id);
      if(index > -1) this.setModel(this.models[index]);
    } else if(value === 'bodytyp'){
      let index = this.bodyTyps.findIndex(v => v.id == id);
      if(index > -1) this.form.controls.bodyTyp.setValue(this.bodyTyps[index]);
    } else if(value === 'category'){
      let index = this.categories.findIndex(v => v.id == id);
      if(index > -1){
        this.form.controls.category.setValue(this.categories[index]);
        this.form.controls.firstRegistrationDate.setValue(null);
        this.calcRegistrationDate();
      }
    } else if(value === 'fuel'){
      let index = this.fuels.findIndex(v => v.id == id);
      if(index > -1) this.form.controls.fuel.setValue(this.fuels[index]);
    } else if(value === 'drive'){
      let index = this.drives.findIndex(v => v.id == id);
      if(index > -1) this.form.controls.drive.setValue(this.drives[index]);
    } else if(value === 'transmission'){
      let index = this.transmissions.findIndex(v => v.id == id);
      if(index > -1) this.form.controls.transmission.setValue(this.transmissions[index]);
    } else if(value === 'bodyColor'){
      let index = this.bodyColors.findIndex(v => v.id == id);
      if(index > -1) this.form.controls.bodyColor.setValue(this.bodyColors[index]);
    }
  }

  setDropdownValues() {
    for(let v of this.fuels){
      this.fuelsValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.drives){
      this.drivesValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.transmissions){
      this.transmissionsValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.brands){
      this.brandValues.push({
        id: v.id,
        name: v.makeName
      })
    }
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.bodyTyps){
      this.bodytypValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.categories){
      this.categorieValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.bodyColors){
      this.bodyColorValues.push({
        id: v.id,
        name: v.name
      })
    }
  }

  setBrand(brand: VehicleBrand) {
    this.form.get('brand').setValue(brand);
    this.models = brand.models;
    this.modelValues = [];
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
    this.form.get('model').setValue(null);
  }

  setModel(model: VehicleModel) {
    this.form.get('model').setValue(model);
  }

  setCategory(cat: VehicleCategory) {
    this.form.get('category').setValue(cat);
    this.calcRegistrationDate()
  }

  calcRegistrationDate() {
    let date = new Date();
    if(this.form.get('category').value.id == 1){
      this.form.get('firstRegistrationDate').setValue(null);
    } else if(this.form.get('category').value.id == 6){
      date.setMonth(date.getMonth() - 12);
      this.maxDate = formatDate(date,'yyyy-MM-dd','de');
    } else if(this.form.get('category').value.id == 3) {
      date.setMonth(date.getMonth() - 24);
      this.maxDate = formatDate(date,'yyyy-MM-dd','de');
    } else {
      date = new Date(0);
      this.minDate = formatDate(date,'yyyy-MM-dd','de');
    }
  }

  changeVersionCounter() {
    let count = 80 - this.form.controls.version?.value.length;
    this.counterVersion = count >= 0 ? count : 0;
    if(this.counterVersion >= 0){
      this.counterVersion = 80 - this.form.controls.version?.value.length;
    }
  }
}
