<div class="dropdown_component_wrapper">
    <label class="overview_record_lbl">{{headerText}}</label>
    <div class="dropdown_button">
        <div class="dropdown_button_header" 
            [class.dropdown_button_invalid]="submitted && formcontrol.invalid"
            appDropdown 
            [show]="show" 
            (click)="show = !show" 
            (changedValueEmitter)="show = $event">
            <label type="text" 
                class="dropdown_header_data_lbl_overview"
                [ngClass]="selectedValue == null ? 'dropdown_header_lbl_placeholder_overview' : ''">
                {{selectedValue != null ? selectedValue : placeholder}}
            </label>
            <img class="dropdown_button_header_img"
                [ngClass]="show ? 'dropdown_button_header_img_active' : ''"
                src="./assets/arrow-dark.svg">
        </div>
        <div #dropdownBox class="dropdown_button_box" *ngIf="show">
            <label *ngIf="isOptional" 
                (click)="submitValueEmitter.emit(null); show = false">
                ---
            </label>
            <label *ngFor="let value of values; let i = index" 
                (click)="submitValueEmitter.emit(value); show = false">
                {{value}}
            </label>
        </div>
    </div>
</div>
  