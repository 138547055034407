<form class="contract_create_page_wrapper" [formGroup]="form">
  <div class="lbl_bold_light_color">Neuer Kaufvertag</div>
  <div class="page_body">
    <div class="column">
      <div class="list_title_lbl">Zahlungsbedingungen</div>
      <app-dropdown-strings
        [title]="'Gewährleistungsfrist (in Monaten)'"
        [placeholder]="'---'"
        [formcontrol]="form.controls['warranty_period']"
        [headerText]="form.controls['warranty_period'].value"
        [values]="periods"
        (submitValueEmitter)="form.controls['warranty_period'].setValue($event)"
      ></app-dropdown-strings>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Kaufpreis in €</div>
        <input type="number" placeholder="---" formControlName="price" [class.input_invalid]="submitted && f.price.errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Übergabe</div>
        <input #inputDate1 type="date" placeholder="date" [min]="minDate" formControlName="handing_over_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && f.handing_over_date.errors">
      </div>
      <div class="input_wrapper" style="margin-bottom: 12px;">
        <div class="overview_record_lbl">Kaution in €</div>
        <input type="number" placeholder="---" formControlName="deposit" [class.input_invalid]="submitted && f.deposit.errors">
      </div>
      <app-dropdown-strings
        [title]="'Finanzierungsgesellschaft'"
        [placeholder]="'---'"
        [headerText]="finanzierungsgesellschaftValue"
        [formcontrol]="form.controls['finanzierungsgesellschaft']"
        [values]="finanzierungsgesellschaftValues"
        (submitValueEmitter)="form.controls['finanzierungsgesellschaft'].setValue($event); getEnumDataOnChange();"
      ></app-dropdown-strings>
      <app-dropdown-multiple
        [title]="'Finanzierungsart'"
        [placeholder]="'---'"
        [headerText]="finanzierungsartenValue"
        [formcontrol]="form.controls['finanzierungsart']"
        [values]="finanzierungsartenValues"
        (submitValueEmitter)="form.controls['finanzierungsart'].setValue($event); getEnumDataOnChange();"
      ></app-dropdown-multiple>
      <div class="radio_container" style="margin-top: 12px;" (click)="f.payment_condition.setValue(1)" >
        <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="f.payment_condition.value == 1 ? 'radio_icon_inner_active' : ''"></div></div>
        <div class="lbl_info">Überweisung</div>
      </div>
      <div class="radio_container" (click)="f.payment_condition.setValue(2)">
        <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="f.payment_condition.value == 2 ? 'radio_icon_inner_active' : ''"></div></div>
        <div class="lbl_info">Anzahlung bei Vertragsabschluss; Überweisung Restkaufpreis vor Übergabe</div>
      </div>
      <div class="radio_container" (click)="f.payment_condition.setValue(3)">
        <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="f.payment_condition.value == 3 ? 'radio_icon_inner_active' : ''"></div></div>
        <div class="lbl_info">Teilzahlung mit Bankkredit</div>
      </div>
    </div>
    <div class="column">
      <div class="list_title_lbl">Besondere Bedingungen</div>
      <div class="input_wrapper">
        <input type="text" style="grid-column-start: 1; grid-column-end: 3" placeholder="Text eingeben" formControlName="special_conditions" [class.input_invalid]="submitted && form.controls.special_conditions.errors">
      </div>
    </div>
  </div>
  <div class="footer">
    <img src="../../../../../assets/icon-info.png">
    <div class="lbl_regular_italic_light_color">Dem/der Käufer:in steht für die Dauer von 2 Jahren ab Übergabe des Fahrzeuges die gesetzliche Gewährleistung zur Verfügung. Diese Frist kann bis auf 1 Jahr verkürzt werden, wenn seit dem Tag der Erstzulassung mehr als ein Jahr verstrichen ist und dies im Einzelnen ausgehandelt wird.</div>
  </div>
</form>
