export class VehicleError {
  copy_text = 'VIN kopieren';
  constructor(
    public error_code: number,
    public error_message: string,
    public vin: string
  ){}
}

export class VehicleErrorFactory {

  public static jsonFactory(raw: any[]): VehicleError[] {
    let objs: VehicleError[] = [];
    for(let obj of raw){
      objs.push(this.jsonFactoryOne(obj));
    }
    return objs;
  }

  public static jsonFactoryOne(raw: any): VehicleError {
    return new VehicleError(
      raw.error_code,
      raw.error_message,
      raw.vin
    );
  }
}
