import { Zusatzprodukt } from "../Zusatzprodukt";

export class ZusatzproduktFactory {
    static jsonFactory(rawZusatzprodukteObject: any): Zusatzprodukt[] {
        let zusatzprodukte: Zusatzprodukt[] = [];
        let rawZusatzprodukte = rawZusatzprodukteObject.vehicle_services;
        for(let zusatzprodukt of rawZusatzprodukte){
            zusatzprodukte.push(this.jsonFactoryOne(zusatzprodukt));
        }
        return zusatzprodukte;
    }

    static jsonFactoryOne(rawZusatzprodukt: any): Zusatzprodukt {
        return new Zusatzprodukt(
            rawZusatzprodukt.service_id, 
            rawZusatzprodukt.category,
            rawZusatzprodukt.description ? (rawZusatzprodukt.category + " - " + rawZusatzprodukt.description) : rawZusatzprodukt.category,
            rawZusatzprodukt.editable,
            rawZusatzprodukt.price
        )
    }
}