import { ContactPerson } from "../ContactPerson";

export class ContactPersonFactory {
    static jsonFactory(rawBody: any): ContactPerson[] {
        const contacts: ContactPerson[] = [];
        for(let contact of rawBody){
            contacts.push(this.jsonFactoryOne(contact));
        }
        return contacts;
    }

    static jsonFactoryOne(rawContact: any): ContactPerson {
        return new ContactPerson(
            rawContact.contact_person_id,
            rawContact.first_name,
            rawContact.last_name,
            rawContact.email,
            rawContact.phone,
            rawContact.title,
            rawContact.salutation,
            rawContact.birthdate,
            rawContact.is_pep,
        );
    }
}
