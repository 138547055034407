<app-collapsible [isCollapsible]="!editMode && viewAuth.view.collapsible" [isContentVisible]="collapsed" [class.data_box_disabled]="editMode && inseratDisable">
  <h3 collapsible-header class="overview_data_box_lbl_title">Basisdaten</h3>
  <div collapsible-content>
    <div *ngIf="!editMode" class="overview_data_box_vehicle">
        <div class="overview_record" *ngIf="showValue('make_name')">
            <div class="overview_record_lbl">Marke</div>
            <div class="overview_record_value">{{vehicle.brand.makeName}}</div>
        </div>
        <div class="overview_record" *ngIf="showValue('model_name')">
            <div class="overview_record_lbl">Model</div>
            <div class="overview_record_value">{{vehicle.model.name}}</div>
        </div>
        <div class="overview_record" *ngIf="showValue('version')">
            <div class="overview_record_lbl" style="align-self: flex-start;">Version</div>
            <div class="overview_record_value" style="max-width: 270px; text-align: end;">{{vehicle.model.version}}</div>
        </div>
        <div class="overview_record" *ngIf="showValue('bodyTyp')">
            <div class="overview_record_lbl">KFZ-Typ</div>
            <div class="overview_record_value">{{vehicle.bodyTyp.name}}</div>
        </div>
        <div class="overview_record" *ngIf="showValue('category')">
            <div class="overview_record_lbl">Fahrzeugart</div>
            <div class="overview_record_value">{{vehicle.category.name}}</div>
        </div>
        <div class="overview_record" *ngIf="showValue('unfall')">
            <div class="overview_record_lbl">Unfallfahrzeug</div>
            <div class="overview_record_value">{{getBooleanStringValue(vehicle.unfall)}}</div>
        </div>
        <div class="overview_record" *ngIf="showValue('previousOwners')">
            <div class="overview_record_lbl">Vorbesitzer</div>
            <div class="overview_record_value">{{vehicle.previousOwners ? vehicle.previousOwners : '-'}}</div>
        </div>
        <div class="overview_record" *ngIf="showValue('mileageKm')">
            <div class="overview_record_lbl">Kilometerstand</div>
            <label class="overview_record_value" [appFormatValue]="'km'" [value]="vehicle.mileageKm"></label>
        </div>
        <div class="overview_record" *ngIf="showValue('mileage_internal')">
          <div class="overview_record_lbl">Kilometerstand intern</div>
          <!-- <div class="overview_record_value">{{vehicle.mileage_internal ? (vehicle.mileage_internal | numberFormat) + ' km' : '---'}}</div> -->
          <label class="overview_record_value" [appFormatValue]="'km'" [value]="vehicle.mileage_internal"></label>
        </div>
        <div class="overview_record" *ngIf="vehicle.category.id != 1 && showValue('firstRegistrationDate')">
            <div class="overview_record_lbl">Erstzulassung</div>
            <div class="overview_record_value">{{vehicle.firstRegistrationDate  | date: 'dd.MM.yyyy'}}</div>
        </div>
        <div class="overview_record" *ngIf="showValue('first_registration_checked')">
          <div class="overview_record_lbl">Erstzulassung geprüft</div>
          <div class="overview_record_value">{{vehicle.first_registration_checked ? "Ja" : "Nein"}}</div>
        </div>
        <div class="overview_record" *ngIf="showValue('is_camper')">
          <label class="overview_record_lbl">Camper</label>
          <label class="overview_record_value" [appFormatValue]="'boolean'" [value]="vehicle.is_camper"></label>
        </div>
        <div class="overview_record" *ngIf="showValue('hail_damage')">
          <label class="overview_record_lbl">Hagelschaden</label>
          <label class="overview_record_value"  [class.lbl_color_wrapper_red]="vehicle.hail_damage >= 2">{{hailDamageValue}}</label>
        </div>
    </div>
    <form *ngIf="editMode && viewAuth.edit.name" [formGroup]="form" class="overview_data_form">
      <app-dropdown-objects-search
        *ngIf="showValue('make_name')"
        [title]="'Marke'"
        [placeholder]="'Marke'"
        [headervalue]="f.brand.value ? f.brand.value.makeName : ''"
        [formcontrol]="f.brand"
        [values]="brandValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('brand', $event)"
      ></app-dropdown-objects-search>
      <app-dropdown-objects-search
        *ngIf="showValue('model_name')"
        [title]="'Model'"
        [placeholder]="'Model'"
        [headervalue]="f.model.value ? f.model.value.name : ''"
        [formcontrol]="f.model"
        [values]="modelValues"
        [submitted]="submitted"
        [disabled]="f.brand.value == null"
        (submitValueEmitter)="setValueFromDropdown('model', $event)"
      ></app-dropdown-objects-search>
      <div class="input_wrapper" *ngIf="showValue('version')">
        <label class="overview_record_lbl" style="align-self: flex-start;">Version</label>
        <div class="version_container">
            <input type="text" class="inputfield_overview" style="width: calc(100% - 10px);" maxlength="80" placeholder="Version*" formControlName="version" (ngModel)="f.version.value" [ngClass]="submitted && f.version.errors ? 'inputfield_invalid_overview' : ''" (input)="setVersionTitle()">
            <span class="counter_lbl lbl_regular_italic_light_color">({{counterVersion}} Zeichen übrig)</span>
        </div>
      </div>
      <app-dropdown-objects
        *ngIf="showValue('bodyTyp')"
        [title]="'KFZ-Typ'"
        [placeholder]="'KFZ-Typ'"
        [headervalue]="f.bodyTyp.value?.name"
        [formcontrol]="f.bodyTyp"
        [values]="bodytypValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('bodytyp', $event)"
      ></app-dropdown-objects>
      <app-dropdown-objects
        *ngIf="showValue('category')"
        [title]="'Fahrzeugart'"
        [placeholder]="'Fahrzeugart'"
        [headervalue]="f.category.value?.name"
        [formcontrol]="f.category"
        [values]="categorieValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('category', $event)"
      ></app-dropdown-objects>
      <app-dropdown-simple
        *ngIf="showValue('unfall')"
        [title]="'Unfallfahrzeug'"
        [formcontrol]="f.unfall"
        (submitValueEmitter)="f.unfall.setValue($event)"
      ></app-dropdown-simple>
      <div class="input_wrapper" *ngIf="showValue('previousOwners')">
        <div class="overview_record_lbl">Vorbesitzer</div>
        <input type="number" placeholder="Vorbesitzer" formControlName="preOwners" [class.input_invalid]="submitted && f.preOwners.errors">
      </div>
      <div class="input_wrapper" *ngIf="showValue('mileageKm')">
          <div class="overview_record_lbl">Kilometerstand</div>
          <input type="number" placeholder="Kilometerstand" formControlName="mileageKm" [class.input_invalid]="submitted && f.mileageKm.errors">
      </div>
      <div class="input_wrapper" *ngIf="showValue('mileage_internal')">
        <div class="overview_record_lbl">Kilometerstand intern</div>
        <input type="number" placeholder="Kilometerstand intern" formControlName="mileage_internal" [class.input_invalid]="submitted && f.mileage_internal.errors">
      </div>
      <div class="input_wrapper" *ngIf="showValue('firstRegistrationDate') && f.category.value?.id != 1">
        <div class="overview_record_lbl">Erstzulassung</div>
        <input #inputDate1 type="date" placeholder="date" [class.input_disabled]="f.first_registration_checked.value == true" [min]="minDate" [max]="maxDate" formControlName="firstRegistrationDate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && f.firstRegistrationDate.errors">
      </div>
      <app-dropdown-simple
        *ngIf="showValue('first_registration_checked')"
        [title]="'Erstzulassung geprüft'"
        [formcontrol]="f.first_registration_checked"
        [isOptional]="true"
        (submitValueEmitter)="f.first_registration_checked.setValue($event)"
      ></app-dropdown-simple>
      <app-dropdown-simple *ngIf="showValue('is_camper')"
        [title]="'Camper'"
        [formcontrol]="f.is_camper"
        [isOptional]="true"
        (submitValueEmitter)="f.is_camper.setValue($event)"
      ></app-dropdown-simple>
      <app-dropdown-multiple *ngIf="showValue('hail_damage')"
        [title]="'Hagelschaden'"
        [formcontrol]="f.hail_damage"
        [headerText]="hailDamageValue"
        [values]="hailDamageValues"
        [isOptional]="true"
        (submitValueEmitter)="f.hail_damage.setValue($event); getEnumDataOnChange();"
      ></app-dropdown-multiple>
    </form>
  </div>
</app-collapsible>

