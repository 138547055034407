import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-restricted-button',
  templateUrl: './restricted-button.component.html',
  styleUrl: './restricted-button.component.css'
})
export class RestrictedButtonComponent implements OnInit {
  @Input() name: string = 'Not defined';
  @Input() isPrimary: boolean = false;
  @Input() isSecondary: boolean = false;
  @Input() isTabButton: boolean = false;
  @Input() isTabButtonActive: boolean = false;
  @Input() isListAction: boolean = false;
  @Input() isListActionIcon: string;
  @Input() isDropdownButton: boolean = false;
  @Input() disabled: boolean = false;
  @Input() access: string|string[];
  @Output() clickEmitter = new EventEmitter<any>();
  public permitted = false;

  @HostBinding('style.display') get display() {
    return this.permitted ? (this.disabled ? 'none' : 'block') : 'none';
  }
  @HostBinding('style.width') get width() {
    return (this.isListAction || this.isDropdownButton) ? '100%' : 'max-content';
  }

  constructor(){}

  ngOnInit(): void {
    this.permitted = this.checkPermission();
    if(!this.permitted && this.isTabButton) {
      setTimeout(() => {
        this.setStyleInCaseUnpermitted();
      }, 50);
    }
  }

  setStyleInCaseUnpermitted() {
    document.querySelectorAll('.tab-buttons-wrapper').forEach(wrapper => {
      const visibleButtons = Array.from(wrapper.querySelectorAll('.restricted-button-tab'))
        .filter(button => getComputedStyle(button).display !== 'none');
      if(visibleButtons && visibleButtons.length > 0) {
        const lastVisibleButton = visibleButtons[visibleButtons.length - 1] as HTMLElement;
        lastVisibleButton.style.borderTopRightRadius = '5px';
        lastVisibleButton.style.borderBottomRightRadius = '5px';
      }
    });
  }

  selection() {
    this.clickEmitter.emit()
  }

  checkPermission(): boolean {
    if(this.access !== undefined && this.access !== null){
      const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
      if(Array.isArray(this.access)) {
        return this.access.some(n => permissions.includes(n));
      } else {
        return permissions.includes(this.access);
      }
    }
  }
}
