import { VehicleModel } from "../VehicleModel";

export class VehicleModelFactoy {
    static jsonFactory(rawData: any): VehicleModel[] {
        let dataAry: VehicleModel[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehicleModel {
        let object = new VehicleModel(
            rawData.model_id,
            rawData.name ? rawData.name : rawData.model_name,
        );
        return object;
    }
}
