<!-- <div id="start-container">
    <div class="choise_container" [class.choise_container_disabled]="false">
        <div class="lbl_regular_thick">Automatisch</div>
        <form [formGroup]="form">
            <div class="input_wrapper">
              <label class="overview_record_lbl">VIN *</label>
              <input #vin_input type="text" placeholder="VIN" (input)="submitted = false" [class.input_invalid]="submitted && vin_input.value.length < 8">
            </div>
            <div class="info">Durch Ausfüllen dieses Feldes wird Ihr Fahrzeug automatisch angelegt.</div>
        </form>
        <button [disabled]="false" class="btn_submit_dark" (click)="vinRequest(vin_input.value)">Erstellen</button>
    </div>
    <div id="text">Oder</div>
    <div class="choise_container">
        <div class="lbl_regular_thick">Manuell</div>
        <div class="info manualinfo">Wenn Sie die den Eurotax Code/die VIN Nummer Ihres Fahrzeugs nicht kennen, können Sie hier die Daten manuell eingeben.</div>
        <div class="btn_submit_dark" (click)="createManuell()">Manuell anlegen</div>
    </div>
</div> -->

<div id="start-container">
  <div id="choise-grid">
    <div class="choise-column">
      <div class="choise-box">
        <div class="choise-box-content" [class.choise_container_disabled]="false">
            <h1>Automatisch</h1>
            <form [formGroup]="form" id="form-vin">
                <div class="input_wrapper">
                  <label>VIN *</label>
                  <input #vin_input type="text" placeholder="VIN" (input)="submitted = false" [class.input_invalid]="submitted && vin_input.value.length < 8">
                </div>
                <label class="choise-info">Durch Ausfüllen dieses Feldes wird Ihr Fahrzeug automatisch angelegt.</label>
            </form>
            <button [disabled]="false" class="btn_submit_dark" (click)="vinRequest(vin_input.value)">Erstellen</button>
        </div>
      </div>
    </div>
    <label id="choise-middle-text">Oder</label>
    <div class="choise-column">
      <div [ngClass]="authService.accessVia('vehicle_bill_import') ? 'choise-column-wrapper' : 'choise-column-single-row-wrapper'">
        <div *ngIf="authService.accessVia('vehicle_bill_import')" class="choise-box">
          <div class="choise-box-content">
            <h1>Rechnungs-Import</h1>
            <label class="choise-info">Importieren Sie die Einkaufsrechnungen Ihrer Fahrzeuge und erstellen daraus die gekauften Fahrzeuge.</label>
            <div class="drop-zone"
              appDnd (fileDroppedEmitter)="onFileDropped($event)"
              (click)="selectFileDropZone.click()">
              <img src="./assets/upload.svg">
              <label>Klicken Sie oder ziehen Sie Dateien hier her, um sie hochzuladen</label>
              <input #selectFileDropZone
                type="file"
                accept="application/pdf"
                (change)="uploadFile($event)"
                multiple="false"
                style="display: none;"
                capture="environment">
            </div>
            <button *ngIf="file" type="button" class="btn_label_blue" [disabled]="true" style="pointer-events: none;" (click)="null">{{file.name+'.'+file.format}}</button>
            <button type="button" class="btn_submit_dark" [disabled]="!file" (click)="selectionBCAImport()">Rechnungen importieren</button>
          </div>
        </div>
        <div class="choise-box">
          <div class="choise-box-content">
            <h1>Manuell</h1>
            <label class="choise-info">Wenn Sie die den Eurotax Code/die VIN Nummer Ihres Fahrzeugs nicht kennen, können Sie hier die Daten manuell eingeben.</label>
            <button type="button" class="btn_submit_dark" (click)="createManuell()">Manuell anlegen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
