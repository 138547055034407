<div class="overview_data_box" [class.data_box_disabled]="!form.controls['vehicle'].value">
    <label class="overview_data_box_lbl_title">Zusatzverkäufe</label>
    <div *ngFor="let selektierteZusatzprodukt of form.controls['selektierte_zusatzprodukte'].value; let i = index">
        <form class="remove-wrapper" [formGroup]="selektierteZusatzprodukt">
        <div class="dropdown_button">
            <div class="select_additional_data">
                <div class="dropdown_button_header" [class.dropdown_button_invalid]="selektierteZusatzprodukt.controls['zusatzprodukt_id'].invalid && submitted" (click)="show = (show==i?-1:i)" appDropdown>
                    <div class="dropdown_header_data_lbl_overview" [ngClass]="selektierteZusatzprodukt.controls['zusatzprodukt_id'].value == null ? 'dropdown_header_lbl_placeholder_overview' : ''">{{selektierteZusatzprodukt.controls['name'].value?selektierteZusatzprodukt.controls['name'].value:'Zusatzprodukt'}}</div>
                    <img class="dropdown_button_header_img" [ngClass]="show==i ? 'dropdown_button_header_img_active' : ''" src="../../../../assets/arrow-dark.svg">
                </div>
                <input type="number" placeholder="---" *ngIf="selektierteZusatzprodukt.controls['name'].value" formControlName="preis_selektiert" [class.input_invalid]="submitted && selektierteZusatzprodukt.controls['preis_selektiert'].errors" /> 
            </div>
            <input type="text" placeholder="Bezeichnung" *ngIf="selektierteZusatzprodukt.controls['name_editierbar'].value" formControlName="name_selektiert" [class.input_invalid]="submitted && selektierteZusatzprodukt.controls['name_selektiert'].errors" class="select_additional_data_info" /> 
            <div #dropdownBox class="dropdown_button_box" *ngIf="show==i">
                <label *ngFor="let zusatzprodukt of form.controls['verfuegbare_zusatzprodukte'].value; let j = index" (click)="submit(i, j); show = -1">{{zusatzprodukt.controls['name'].value}}</label>
            </div>
        </div>

        <button class="button-square button-square-top" style="margin-bottom: 4px;" (click)="removeAdditionalService(selektierteZusatzprodukt)"><span>&#8722;</span></button>
        </form>
    </div>
    <button *ngIf="form.controls['selektierte_zusatzprodukte'].value.length < zusatzprodukte.length" class="button-square button-square-add" (click)="addAdditionalService()"><span>&#43;</span> Zusatzleistungen bzw. -produkt hinzufügen</button>
</div>