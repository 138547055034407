import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  @Output() acceptEmitter = new EventEmitter<boolean>();
  @Output() cancelEmitter = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.acceptEmitter.emit(true);
  }
}
