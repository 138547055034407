<div id="list-title" [ngClass]="isTitle ? 'top' : 'bottom'">
    <div id="container1" [ngClass]="isTitle ? 'border_top' : ''">
        <div id="count">{{pageInfo}}</div>
        <div id="arrows"  *ngIf="amount > maxAmountListPage">
          <img *ngIf="sliceFrom == 0" class="icon_arrow_left" src="../../../../../assets/arrowlight.svg">
          <img *ngIf="sliceFrom != 0" class="icon_arrow_left list_arrow_active" src="../../../../../assets/arrow.svg" (click)="pageEmitter.emit(false)">
          <img *ngIf="sliceTo == amount" class="icon_arrow_right" src="../../../../../assets/arrowlight.svg">
          <img *ngIf="sliceTo != amount"  class="icon_arrow_right list_arrow_active" src="../../../../../assets/arrow.svg" (click)="pageEmitter.emit(true)">
        </div>
    </div>
    <div id="container2" *ngIf="isTitle">
      <div></div>
        <div class="lbl_list_title">Bezeichnung</div>
        <div class="lbl_list_title">Straße</div>
        <div class="lbl_list_title">PLZ</div>
        <div class="lbl_list_title">Ort</div>
    </div>
</div>
