import { Location } from "../Location";


export class LocationFactory {
    static jsonFactory(rawBody: any): Location[] {
        const locations: Location[] = [];
        for(let location of rawBody){
            locations.push(this.jsonFactoryOne(location));
        }
        return locations;
    }

    static jsonFactoryOne(rawLocation: any): Location {
        const location = new Location(
            rawLocation.location_id,
            rawLocation.street,
            rawLocation.postal_code,
            rawLocation.city,
            rawLocation.mandant_id,
            rawLocation.active,
            rawLocation.location_name
        );
        return location;
    }
}