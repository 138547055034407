<div id="price_sheets_details_page" *ngIf="priceSheet">
  <div class="price_sheets_details_page_column" *ngIf="authService.accessVia('price_calc_new')">
    <div class="data_box">
      <h3>Planrechnung</h3>
      <div class="data_wrapper" *ngIf="priceSheet.preis_anschreiben">
        <label>Verkaufspreis (brutto)</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.preis_anschreiben) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="priceSheet.preis_anschreiben == 0 || priceSheet.preis_anschreiben == undefined">
        <label>Verkaufspreis (brutto)</label>
        <label class="lbl_color_wrapper_red">ausständig</label>
      </div>
      <div class="data_wrapper">
        <label>Einkaufspreis (brutto)</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.einkaufspreis_brutto) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Vorsteuer{{priceSheet ? (priceSheet.steuersatz == 1 ? " (20%)" : " (0%)") : "" }}</label>
        <label>{{priceSheet ? (getInputValueCurrency(priceSheet.einkaufspreis_brutto - priceSheet.einkaufspreis_netto)) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Einkaufspreis (netto)</label>
        <label>{{priceSheet ? (getInputValueCurrency(priceSheet.einkaufspreis_netto)) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Summe NoVA</label>
        <label>{{priceSheet.showNovaValues() ? getInputValueCurrency(priceSheet.summe_nova_vor) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Umsatzsteuer</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.ust_vor) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Beschaffungskosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.beschaffungskosten_gesamt) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="false">
        <label>Reparaturkosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.reparatur_summe_gesamt_vorjahr) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Zusatzkosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.zinsen_vor+priceSheet.garantie_vorkalkulation+priceSheet.standzeitkosten_vor) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Deckungsbeitrag</label>
        <label [ngClass]="priceSheet.preis_anschreiben ? (priceSheet.guv_vorkalkulation >= 0 ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_red') : ''">{{priceSheet && priceSheet.preis_anschreiben ? getInputValueCurrency(priceSheet.guv_vorkalkulation) : "---"}}</label>
      </div>
    </div>
    <div class="data_box">
      <h3>Fahrzeugkosten</h3>
      <h3 style="margin-top: 0;">Geplante Beschaffungskosten</h3>
      <div class="data_wrapper">
        <label>Import</label>
        <label>{{getBooleanStringValue(priceSheet.vehicle_imported)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('sprit')}}</label>
        <label>{{getInputValueCurrency(priceSheet.sprit)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('lieferant_extern')}}</label>
        <label>{{getInputValueCurrency(priceSheet.lieferant_extern)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Beschaffungskosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.beschaffungskosten_gesamt) : "---"}}</label>
      </div>
      <h3 style="margin-top: 0;">Geplante Zusatzkosten</h3>
      <div class="data_wrapper">
        <label>Zinsen ({{getInputValueNumber3Dicis(priceSheet.zinssatz_vor)}}%)</label>
        <label>{{getInputValueCurrency(priceSheet.zinsen_vor)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Rückstellung Gewährleistung ({{getInputValueNumber3Dicis(priceSheet.garantie_von_einkaufspreis)}})</label>
        <label>{{getInputValueCurrency(priceSheet.garantie_vorkalkulation)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standzeitkosten (€{{getInputValueNumber(priceSheet.standzeitkosten_je_tag_vor)}} / Tag)</label>
        <label>{{getInputValueCurrency(priceSheet.standzeitkosten_vor)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Zusatzkosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.zinsen_vor+priceSheet.garantie_vorkalkulation+priceSheet.standzeitkosten_vor) : "---"}}</label>
      </div>
    </div>
    <div class="data_box" *ngIf="priceSheet.summe_nova_vor != undefined && priceSheet.summe_nova_vor > 0">
      <h3>NoVA</h3>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.nova_satz)">
        <label>Bemessungsgrundlage</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.preis_anschreiben - priceSheet.summe_nova_vor - priceSheet.ust_vor) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.nova_grundbetrag)">
        <label>NoVA Grundbetrag{{priceSheet ? (" (" +getInputValueNumberNoDicis(priceSheet.nova_satz)+"%)") : ""}}</label>
        <label>{{(priceSheet && priceSheet.nova_grundbetrag) ? getInputValueNumber(priceSheet.nova_grundbetrag)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.abzugsposten)">
        <label>anteiliger Abzugsposten</label>
        <label>{{(priceSheet && priceSheet.abzugsposten) ? getInputValueNumber(priceSheet.abzugsposten)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.malus)">
        <label>anteiliger CO2-Malus</label>
        <label>{{(priceSheet && priceSheet.malus) ? getInputValueNumber(priceSheet.malus)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.summe_nova_vor)">
        <label>Summe NoVA</label>
        <label>{{(priceSheet && priceSheet.summe_nova_vor) ? getInputValueNumber(priceSheet.summe_nova_vor)+" €" : "---"}}</label>
      </div>
    </div>
  </div>
  <div class="price_sheets_details_page_column" *ngIf="authService.accessVia('price_calc_new')">
    <div class="data_box" [ngClass]="priceSheet.export_price_enabled ? '' : 'data_box_disabled'">
      <h3>Planrechnung Export</h3>
      <div class="data_wrapper" *ngIf="priceSheet.export_price">
        <label>Exportpreis (brutto)</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.export_price) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="priceSheet.export_price == 0 || priceSheet.export_price == undefined">
        <label>Exportpreis (brutto)</label>
        <label class="lbl_color_wrapper_red">ausständig</label>
      </div>
      <div class="data_wrapper">
        <label>Einkaufspreis (brutto)</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.einkaufspreis_brutto) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Vorsteuer{{priceSheet ? (priceSheet.steuersatz == 1 ? " (20%)" : " (0%)") : "" }}</label>
        <label>{{priceSheet ? (getInputValueCurrency(priceSheet.einkaufspreis_brutto - priceSheet.einkaufspreis_netto)) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Einkaufspreis (netto)</label>
        <label>{{priceSheet ? (getInputValueCurrency(priceSheet.einkaufspreis_netto)) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Summe NoVA Rückvergütung</label>
        <label>{{priceSheet.nova_verguetung ? getInputValueCurrency(priceSheet.nova_verguetung) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Umsatzsteuer</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.guv_export_ust_vor) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Beschaffungskosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.beschaffungskosten_gesamt) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="false">
        <label>Reparaturkosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.reparatur_summe_gesamt_vorjahr) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Zusatzkosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.zinsen_vor+priceSheet.garantie_vorkalkulation+priceSheet.standzeitkosten_vor) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Deckungsbeitrag</label>
        <label [ngClass]="priceSheet.export_price ? (priceSheet.guv_export_vorkalkulation >= 0 ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_red') : ''">{{priceSheet && priceSheet.export_price ? getInputValueCurrency(priceSheet.guv_export_vorkalkulation) : "---"}}</label>
      </div>
    </div>
    <div class="data_box" [ngClass]="priceSheet.export_price_enabled ? '' : 'data_box_disabled'">
      <h3>Fahrzeugkosten</h3>
      <h3 style="margin-top: 0;">Geplante Beschaffungskosten</h3>
      <div class="data_wrapper">
        <label>Import</label>
        <label>{{getBooleanStringValue(priceSheet.vehicle_imported)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('sprit')}}</label>
        <label>{{getInputValueCurrency(priceSheet.sprit)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('lieferant_extern')}}</label>
        <label>{{getInputValueCurrency(priceSheet.lieferant_extern)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Beschaffungskosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.beschaffungskosten_gesamt) : "---"}}</label>
      </div>
      <h3 style="margin-top: 0;">Geplante Zusatzkosten</h3>
      <div class="data_wrapper">
        <label>Zinsen ({{getInputValueNumber3Dicis(priceSheet.zinssatz_vor)}}%)</label>
        <label>{{getInputValueCurrency(priceSheet.zinsen_vor)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Rückstellung Gewährleistung ({{getInputValueNumber3Dicis(priceSheet.garantie_von_einkaufspreis)}})</label>
        <label>{{getInputValueCurrency(priceSheet.garantie_vorkalkulation)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standzeitkosten (€{{getInputValueNumber(priceSheet.standzeitkosten_je_tag_vor)}} / Tag)</label>
        <label>{{getInputValueCurrency(priceSheet.standzeitkosten_vor)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Zusatzkosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.zinsen_vor+priceSheet.garantie_vorkalkulation+priceSheet.standzeitkosten_vor) : "---"}}</label>
      </div>
    </div>
    <div class="data_box" *ngIf="priceSheet.nova_verguetung != undefined && priceSheet.nova_verguetung > 0" [ngClass]="priceSheet.export_price_enabled ? '' : 'data_box_disabled'">
      <h3>NoVA Rückvergütung</h3>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.nova_verguetung_grundbetrag)">
        <label>NoVA Grundbetrag{{priceSheet ? (" (" +getInputValueNumberNoDicis(priceSheet.nova_verguetung_satz)+"%)") : ""}}</label>
        <label>{{(priceSheet && priceSheet.nova_verguetung_grundbetrag) ? getInputValueNumber(priceSheet.nova_verguetung_grundbetrag)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>anteiliger Abzugsposten</label>
        <label>{{(priceSheet && priceSheet.nova_verguetung_abzugsposten) ? getInputValueNumber(priceSheet.nova_verguetung_abzugsposten)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>anteiliger CO2-Malus</label>
        <label>{{(priceSheet && priceSheet.nova_verguetung_malus) ? getInputValueNumber(priceSheet.nova_verguetung_malus)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.nova_verguetung)">
        <label>Summe NoVA Rückvergütung</label>
        <label>{{(priceSheet && priceSheet.nova_verguetung) ? getInputValueNumber(priceSheet.nova_verguetung)+" €" : "---"}}</label>
      </div>
    </div>
  </div>
  <div class="price_sheets_details_page_column" *ngIf="authService.accessVia('price_calc_new') && false">
    <div class="data_box">
      <h3>Nachkalkulation</h3>
      <div class="data_wrapper">
        <label>Verkaufspreis (brutto)</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.verkaufspreis_brutto) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Einkaufspreis (brutto)</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.einkaufspreis_brutto) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Vorsteuer{{priceSheet ? (priceSheet.steuersatz == 1 ? " (20%)" : " (0%)") : "" }}</label>
        <label>{{priceSheet ? (getInputValueCurrency(priceSheet.einkaufspreis_brutto - priceSheet.einkaufspreis_netto)) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Einkaufspreis (netto)</label>
        <label>{{priceSheet ? (getInputValueCurrency(priceSheet.einkaufspreis_netto)) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Summe NoVA</label>
        <label>{{priceSheet.showNovaValues() ? getInputValueCurrency(priceSheet.summe_nova) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Umsatzsteuer</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.ust) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Beschaffungskosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.beschaffungskosten_gesamt) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="false">
        <label>Reparaturkosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.reparatur_summe_gesamt_vorjahr) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Zusatzkosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.zinsen_nach+priceSheet.standzeitkosten_nach+priceSheet.abzug_fahrzeug_provision) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Deckungsbeitrag</label>
        <label [ngClass]="priceSheet.guv_nachkalkulation >= 0 ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_red'">{{priceSheet ? getInputValueCurrency(priceSheet.guv_nachkalkulation) : "---"}}</label>
      </div>
    </div>
    <div class="data_box" *ngIf="priceSheet.summe_nova != undefined && priceSheet.summe_nova > 0">
      <h3>NoVA</h3>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.nova_satz)">
        <label>Bemessungsgrundlage</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.verkaufspreis_brutto - priceSheet.summe_nova - priceSheet.ust) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.nova_grundbetrag)">
        <label>NoVA Grundbetrag{{priceSheet ? (" (" +getInputValueNumberNoDicis(priceSheet.nova_satz)+"%)") : ""}}</label>
        <label>{{(priceSheet && priceSheet.nova_grundbetrag_nach) ? getInputValueNumber(priceSheet.nova_grundbetrag_nach)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.abzugsposten)">
        <label>anteiliger Abzugsposten</label>
        <label>{{(priceSheet && priceSheet.abzugsposten) ? getInputValueNumber(priceSheet.abzugsposten)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.malus)">
        <label>anteiliger CO2-Malus</label>
        <label>{{(priceSheet && priceSheet.malus) ? getInputValueNumber(priceSheet.malus)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.summe_nova)">
        <label>Summe NoVA</label>
        <label>{{(priceSheet && priceSheet.summe_nova) ? getInputValueNumber(priceSheet.summe_nova)+" €" : "---"}}</label>
      </div>
    </div>
    <div class="data_box">
      <h3>Fahrzeugkosten</h3>
      <h3 style="margin-top: 0;">Geplante Beschaffungskosten</h3>
      <div class="data_wrapper">
        <label>Import</label>
        <label>{{getBooleanStringValue(priceSheet.vehicle_imported)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('sprit')}}</label>
        <label>{{getInputValueCurrency(priceSheet.sprit)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('lieferant_extern')}}</label>
        <label>{{getInputValueCurrency(priceSheet.lieferant_extern)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Beschaffungskosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.beschaffungskosten_gesamt) : "---"}}</label>
      </div>
      <h3 style="margin-top: 0;">Geplante Zusatzkosten</h3>
      <div class="data_wrapper">
        <label>Zinsen ({{getInputValueNumber3Dicis(priceSheet.zinssatz_nach)}}%)</label>
        <label>{{getInputValueCurrency(priceSheet.zinsen_nach)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standzeitkosten (€{{getInputValueNumber(priceSheet.standzeitkosten_je_tag_nach)}} / Tag)</label>
        <label>{{getInputValueCurrency(priceSheet.standzeitkosten_nach)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Provision</label>
        <label>{{getInputValueCurrency(priceSheet.abzug_fahrzeug_provision)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Zusatzkosten gesamt</label>
        <label>{{priceSheet ? getInputValueCurrency(priceSheet.zinsen_nach+priceSheet.standzeitkosten_nach+priceSheet.abzug_fahrzeug_provision) : "---"}}</label>
      </div>
    </div>
    <div class="data_box">
      <h3>Informationen zum Verkauf</h3>
      <div class="data_wrapper">
        <label>Verkaufsdatum</label>
        <label>{{getInputValueDate(priceSheet.verkaufsdatum)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Rechnungsdatum</label>
        <label>{{getInputValueDate(priceSheet.al_re_datum)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Geldeingang</label>
        <label>{{getInputValueDate(priceSheet.geldeingang)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standzeit Platz</label>
        <label>{{getInputValue(priceSheet.standzeit_platz)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standzeit Geld</label>
        <label>{{getInputValue(priceSheet.standzeit_geld)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Kunde</label>
        <label>{{getInputValue(priceSheet.customer_name)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Mitarbeiter</label>
        <label>{{getInputValue(priceSheet.employee_name)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Einkauf Vertragsnr.</label>
        <label>{{getInputValue(priceSheet.einkauf_vertragsnummer)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Verkauf Vertragsnr.</label>
        <label>{{getInputValue(priceSheet.verkauf_vertragsnummer)}}</label>
      </div>
    </div>
  </div>
  <div class="price_sheets_details_page_column" *ngIf="authService.accessVia('price_calc_old')">
    <div class="data_box">
      <h3>Fahrzeugkosten</h3>
      <div class="data_wrapper" *ngIf="showValuePricesheet('aufschlag')">
        <label>Aufschlag bei Verkauf (Vorkalkulation)</label>
        <label [ngClass]="priceSheet.aufschlag >= 0 ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_red'">{{getInputValueCurrency(priceSheet.aufschlag)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('guv_vorkalkulation')}}</label>
        <label [ngClass]="priceSheet.guv_vorkalkulation >= 0 ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_red'">{{getInputValueCurrency(priceSheet.guv_vorkalkulation)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('aufschlag_nach')">
        <label>{{changeValueName('aufschlag_nach')}}</label>
        <label [ngClass]="priceSheet.aufschlag_nach >= 0 ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_red'">{{getInputValueCurrency(priceSheet.aufschlag_nach)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('guv_nachkalkulation')}}</label>
        <label [ngClass]="priceSheet.guv_nachkalkulation >= 0 ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_red'">{{priceSheet.nachkalkulation ? getInputValueCurrency(priceSheet.guv_nachkalkulation) : '---'}}</label>
      </div>
    </div>
    <div class="data_box">
      <h3>Berechnungsgrundlage</h3>
      <div class="data_wrapper">
        <label>Steuersatz</label>
        <label>{{getSteuersatz(priceSheet.steuersatz)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Einkaufspreis (brutto)</label>
        <label>{{getInputValueCurrency(priceSheet.einkaufspreis_brutto)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Vorsteuer</label>
        <label>{{getInputValueCurrency(priceSheet.einkaufspreis_brutto - priceSheet.einkaufspreis_netto)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('preis_anschreiben')">
        <label>Verkaufspreis (brutto)</label>
        <label>{{getInputValueNumber(priceSheet.preis_anschreiben)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('verkaufspreis_brutto')">
        <label>Verkaufspreis (brutto)</label>
        <label>{{getInputValueCurrency(priceSheet.verkaufspreis_brutto)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('ust')">
        <label>UST</label>
        <label>{{getInputValueCurrency(priceSheet.ust)}}</label>
      </div>
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Richtlinien Verkauf')">
      <h3>Richtlinien für Verkauf</h3>
      <div class="data_wrapper" *ngIf="showValuePricesheet('kosten_lt_vorkalkulation')">
        <label>Fahrzeugkosten lt. Vorkalkulation</label>
        <label>{{getInputValueCurrency(priceSheet.kosten_lt_vorkalkulation)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('preis_anschreiben')}}</label>
        <label>{{getInputValueCurrency(priceSheet.preis_anschreiben)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('minimaler_verkaufpreis')}}</label>
        <label>{{getInputValueCurrency(priceSheet.minimaler_verkaufpreis)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('vk_nachkalkulation')">
        <label>Verkaufspreis (Nachkalkulation)</label>
        <label>{{getInputValueCurrency(priceSheet.vk_nachkalkulation)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('vk_nachkalkulation_haendler')">
        <label>Händlerpreis (Nachkalkulation)</label>
        <label>{{getInputValueCurrency(priceSheet.vk_nachkalkulation_haendler)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="false">
        <label>Zuagaben</label>
        <label>{{getInputValueCurrency(priceSheet.zugaben)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="false">
        <label>Zubehör</label>
        <label>{{getInputValueCurrency(priceSheet.zubehoer)}}</label>
      </div>
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Typenschein')">
      <h3>Typenschein</h3>
      <div class="data_wrapper">
        <label>Datenblatt angefordert am</label>
        <label>{{getInputValueDate(priceSheet.datenblatt_angefordert_am)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Fahrzeug eingestockt am</label>
        <label>{{getInputValueDate(priceSheet.fahrzeug_eingestockt_am)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Typenschein eingestockt am</label>
        <label>{{priceSheet.type_certificate_eingestockt ? (priceSheet.type_certificate_eingestockt | date: 'dd.MM.yyyy') : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Typenschein ausgestockt am</label>
        <label>{{priceSheet.type_certificate_ausgestockt ? (priceSheet.type_certificate_ausgestockt | date: 'dd.MM.yyyy') : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standort Typenschein</label>
        <label>{{paperStatusValue}}</label>
      </div>
      <div class="data_wrapper">
        <label>Finanzsperrauskunft</label>
        <label>{{finanzsperrauskunftValue}}</label>
      </div>
      <div class="data_wrapper">
        <label>Finanzsperrauskunft beantragt</label>
        <label>{{getInputValueDate(vehicle.finanzsperrauskunft_beantragt)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Typenschein versendet an Kunden</label>
        <label>{{priceSheet.type_certificate_versendet ? (priceSheet.type_certificate_versendet | date: 'dd.MM.yyyy') : "---"}}</label>
      </div>
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Rubrik Transport')">
      <h3>Rubrik Transport</h3>
      <div class="data_wrapper">
        <label>Beauftragt</label>
        <label>{{getBooleanStringValue(priceSheet.transport_beantragt)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Datum</label>
        <label>{{getInputValueDate(priceSheet.transport_date)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Spedition</label>
        <label>{{getInputValue(priceSheet.spedition)}}</label>
      </div>
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Rubrik Transport')">
      <h3>Neupreis</h3>
      <div class="data_wrapper">
        <label>Fahrzeugneupreis</label>
        <label>{{getInputValueCurrency(priceSheet.neu_preis)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Sonderausstattung</label>
        <label>{{getInputValueCurrency(priceSheet.sonderaustattung)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Nationaler Code</label>
        <label>{{getInputValue(priceSheet.eurotax)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Gesamt Neupreis</label>
        <label>{{getInputValueCurrency(priceSheet.neu_preis + priceSheet.sonderaustattung)}}</label>
      </div>
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Notizfeld Geschäftsführung intern')">
      <h3>Notizfeld Geschäftsführung intern</h3>
      <div #gf_info></div>
    </div>
  </div>
  <div class="price_sheets_details_page_column" *ngIf="authService.accessVia('price_calc_old')">
    <div class="data_box" *ngIf="showBoxPricesheet('Vorkalkulation')">
      <h3>Vorkalkulation</h3>
      <h3 style="margin-top: 0;">Einkauf</h3>
      <div class="data_wrapper" *ngIf="priceSheet.steuersatz != 2">
        <label>Einkaufspreis (netto)</label>
        <label>{{getInputValueCurrency(priceSheet.einkaufspreis_netto)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="priceSheet.steuersatz == 2">
        <label>Einkaufspreis (brutto)</label>
        <label>{{getInputValueCurrency(priceSheet.einkaufspreis_brutto)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Einkaufsdatum</label>
        <label>{{getInputValueDate(priceSheet.einkaufsdatum)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('rechnungsdatum_vor')">
        <label>Rechnungsdatum</label>
        <label>{{getInputValueDate(priceSheet.rechnungsdatum_vor)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('eurotax')">
        <label>Nationaler Code</label>
        <label>{{getInputValue(priceSheet.eurotax)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('vehicle_serialnumber')">
        <label>Fahrzeug-Laufnummer</label>
        <label>{{getInputValue(priceSheet.vehicle_serialnumber)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('hereinnahmedatum')}}</label>
        <label>{{getInputValueDate(priceSheet.hereinnahmedatum)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Zahldatum</label>
        <label>{{getInputValueDate(priceSheet.zahldatum)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('anpreisungsdatum')">
        <label>Auspreisungsdatum</label>
        <label>{{getInputValueDate(priceSheet.anpreisungsdatum)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standzeit Plan-Tage</label>
        <label>{{getInputValue(priceSheet.standzeit_plan_tage)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('vehicle_supplier')">
        <label>Fahrzeuglieferant</label>
        <label>{{getInputValue(priceSheet.vehicle_supplier)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('einstockung_bei')">
        <label>Einstockung bei</label>
        <label>{{getInputValue(priceSheet.einstockung_bei)}}</label>
      </div>
      <div class="data_wrapper" style="pointer-events: none;" *ngIf="showValuePricesheet('invoice_checked')">
        <label class="checkbox_lbl">Eingangsrechnung kontrolliert</label>
        <input type="checkbox" [disabled]="true" class="checkbox" [checked]="priceSheet.invoice_checked">
      </div>
      <div *ngIf="showBoxPricesheet('NoVA vor')" class="data_box_inner">
        <h3>NoVA</h3>
        <div class="data_wrapper">
          <label>CO&#8322; Emissionen (g/km)</label>
          <label>{{priceSheet.co2_emission ? (priceSheet.co2_emission + ' g') : '---'}}</label>
        </div>
        <div class="data_wrapper" style="pointer-events: none;">
          <label class="checkbox_lbl">CO&#8322; Emissionen geprüft</label>
          <input type="checkbox" [disabled]="true" class="checkbox" [checked]="priceSheet.co2_checked">
        </div>
        <div class="data_wrapper">
          <label>Elektr. Reichweite km</label>
          <label>{{priceSheet.electrical_distance ? (priceSheet.electrical_distance + ' km') : '---'}}</label>
        </div>
        <div class="data_wrapper" style="pointer-events: none;">
          <label class="checkbox_lbl">Elektr. Reichweite geprüft</label>
          <input type="checkbox" [disabled]="true" class="checkbox" [checked]="priceSheet.electrical_distance_checked">
        </div>
        <div class="data_wrapper">
          <label>Entgelt ohne NoVA</label>
          <label>{{priceSheet.showNovaValues() ? getInputValueCurrency(priceSheet.entgelt_ohne_nova_vor) : "---"}}</label>
        </div>
        <div class="data_wrapper">
          <label>NoVA-Satz</label>
          <label>{{priceSheet.showNovaValues() ? (getInputValueNumber(priceSheet.nova_satz) + (getInputValueNumber(priceSheet.nova_satz) != "---"?"%":"")) : "---"}}</label>
        </div>
        <div class="data_wrapper">
          <label>NoVA</label>
          <label>{{priceSheet.showNovaValues() ? getInputValueNumber(priceSheet.nova_vor) : "---"}}</label>
        </div>
        <div class="data_wrapper">
          <label>Malus/Bonus</label>
          <label>{{priceSheet.showNovaValues() ? getInputValueNumber(priceSheet.malus_bonus) : "---"}}</label>
        </div>
        <div class="data_wrapper">
          <label>Summe NoVA</label>
          <label>{{getInputValueNumber(priceSheet.summe_nova_vor)}}</label>
        </div>
        <div class="data_wrapper" *ngIf="priceSheet.steuersatz_nach == 1">
          <label>UST</label>
          <label>{{priceSheet.nachkalkulation ? getInputValueNumber(priceSheet.ust_vor) : "---"}}</label>
        </div>
        <div class="data_wrapper" *ngIf="priceSheet.steuersatz_nach != 1">
          <label>Ohne NoVA inkl. UST</label>
          <label>{{priceSheet.showNovaValues() ? getInputValueNumber(priceSheet.ohne_nova_inkl_ust_vor) : "---"}}</label>
        </div>
      </div>
      <h3>Beschaffung</h3>
      <div class="data_wrapper">
        <label>Import</label>
        <label>{{getBooleanStringValue(priceSheet.vehicle_imported)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('sprit')}}</label>
        <label>{{getInputValueNumber(priceSheet.sprit)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('flug_bahn')">
        <label>Flug/Bahn</label>
        <label>{{getInputValueNumber(priceSheet.flug_bahn)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('gebuehren')">
        <label>Gebühren</label>
        <label>{{getInputValueNumber(priceSheet.gebuehren)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Lieferant extern</label>
        <label>{{getInputValueNumber(priceSheet.lieferant_extern)}}</label>
      </div>
      <!-- <div class="data_wrapper">
        <label>Übernachtung</label>
        <label>{{getInputValueNumber(priceSheet.uebernachtung)}}</label>
      </div> -->
      <div class="data_wrapper">
        <label>Beschaffungskosten gesamt</label>
        <label>{{getInputValueCurrency(priceSheet.beschaffungskosten_gesamt)}}</label>
      </div>
      <h3>Reparatur & Fahrzeugkosten</h3>
      <div class="data_wrapper">
        <label>Aufbereitung</label>
        <label>{{getInputValueCurrency(priceSheet.aufbereitung)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('reparatur_dauer_tage')">
        <label>Reparatur-Dauer in Tagen</label>
        <label>{{getInputValue(priceSheet.reparatur_dauer_tage)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Reparatur-Summe gesamt</label>
        <label>{{getInputValueCurrency(priceSheet.reparatur_summe_gesamt)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Reparaturen</label>
        <label>{{priceSheet.reparaturen.length}}</label>
      </div>
      <button *ngIf="priceSheet.reparaturen?.length > 0" class="btn_label_blue" (click)="openReparaturDialog()">Details anzeigen</button>
      <h3>Zusatzkosten</h3>
      <div class="data_wrapper">
        <label>Zinsen ({{getInputValueNumber3Dicis(priceSheet.zinssatz_vor)}}%)</label>
        <label>{{getInputValueCurrency(priceSheet.zinsen_vor)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Rückstellung Gewährleistung ({{getInputValueNumber3Dicis(priceSheet.garantie_von_einkaufspreis)}})</label>
        <label>{{getInputValueCurrency(priceSheet.garantie_vorkalkulation)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standzeitkosten (€{{getInputValueNumber(priceSheet.standzeitkosten_je_tag_vor)}} / Tag)</label>
        <label>{{getInputValueCurrency(priceSheet.standzeitkosten_vor)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('fixkosten_pro_fahrzeug_bei_70')">
        <label>Fixkosten pro Fahrzeug</label>
        <label>{{getInputValueCurrency(priceSheet.fixkosten_pro_fahrzeug_bei_70)}}</label>
      </div>
      <div class="data_wrapper">
        <label>GW-Aufschlag</label>
        <label>{{getInputValueCurrency(priceSheet.gw_aufschlag)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('garantie_vor')">
        <label>Garantie</label>
        <label>{{getInputValueCurrency(priceSheet.garantie_vor)}}</label>
      </div>
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Vorkalkulation Standard')">
      <h3>Vorkalkulation</h3>
      <div class="data_wrapper">
        <label>Verkaufspreis Brutto</label>
        <label>{{priceSheet ? getInputValueNumber(priceSheet.preis_anschreiben)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Einkaufspreis Netto</label>
        <label>{{priceSheet ? getInputValueNumber(priceSheet.einkaufspreis_netto)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Vorsteuer</label>
        <label>{{priceSheet ? getInputValueNumber(priceSheet.vorsteuer)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Umsatzsteuer</label>
        <label>{{priceSheet ? getInputValueNumber(priceSheet.ust_vor)+" €" : "---"}}</label>
      </div>
      <h3>NoVA</h3>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.nova_satz)">
        <label>Bemessungsgrundlage</label>
        <label>{{priceSheet ? getInputValueNumberNoDicis(priceSheet.nova_satz)+"%" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.nova_grundbetrag)">
        <label>NoVA Grundbetrag</label>
        <label>{{(priceSheet && priceSheet.nova_grundbetrag) ? getInputValueNumber(priceSheet.nova_grundbetrag)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.abzugsposten)">
        <label>anteiliger Abzugsposten</label>
        <label>{{(priceSheet && priceSheet.abzugsposten) ? getInputValueNumber(priceSheet.abzugsposten)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.malus)">
        <label>anteiliger CO2-Malus</label>
        <label>{{(priceSheet && priceSheet.malus) ? getInputValueNumber(priceSheet.malus)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.summe_nova_vor)">
        <label>Summe NoVA</label>
        <label>{{(priceSheet && priceSheet.summe_nova_vor) ? getInputValueNumber(priceSheet.summe_nova_vor)+" €" : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="(priceSheet && priceSheet.verkaufspreis_netto_vor)">
        <label>Verkaufspreis Netto (ohne NoVA & UST)</label>
        <label>{{(priceSheet && priceSheet.verkaufspreis_netto_vor) ? getInputValueNumber(priceSheet.verkaufspreis_netto_vor)+" €" : "---"}}</label>
      </div>
      <h3>Beschaffung</h3>
      <div class="data_wrapper">
        <label>Import</label>
        <label>{{getBooleanStringValue(priceSheet.vehicle_imported)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('sprit')}}</label>
        <label>{{getInputValueNumber(priceSheet.sprit)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('flug_bahn')">
        <label>Flug/Bahn</label>
        <label>{{getInputValueNumber(priceSheet.flug_bahn)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('gebuehren')">
        <label>Gebühren</label>
        <label>{{getInputValueNumber(priceSheet.gebuehren)}}</label>
      </div>
      <div class="data_wrapper">
        <label>{{changeValueName('lieferant_extern')}}</label>
        <label>{{getInputValueNumber(priceSheet.lieferant_extern)}}</label>
      </div>
      <!-- <div class="data_wrapper">
        <label>Übernachtung</label>
        <label>{{getInputValueNumber(priceSheet.uebernachtung)}}</label>
      </div> -->
      <div class="data_wrapper">
        <label>Beschaffungskosten gesamt</label>
        <label>{{getInputValueNumber(priceSheet.beschaffungskosten_gesamt)}}</label>
      </div>
      <h3 *ngIf="showRepUndFahrzeugkosten()">Reparatur & Fahrzeugkosten</h3>
      <div class="data_wrapper" *ngIf="showValuePricesheet('aufbereitung')">
        <label>Aufbereitung</label>
        <label>{{getInputValueNumber(priceSheet.aufbereitung)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('reparatur_dauer_tage')">
        <label>Reparatur-Dauer in Tagen</label>
        <label>{{getInputValue(priceSheet.reparatur_dauer_tage)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('reparatur_summe_gesamt')">
        <label>Reparatur-Summe gesamt</label>
        <label>{{getInputValueNumber(priceSheet.reparatur_summe_gesamt)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('reparaturen')">
        <label>Reparaturen</label>
        <label>{{priceSheet.reparaturen.length}}</label>
      </div>
      <button *ngIf="showValuePricesheet('reparaturen') && priceSheet.reparaturen?.length > 0" class="btn_label_blue" (click)="openReparaturDialog()">Details anzeigen</button>
      <h3>Zusatzkosten</h3>
      <div class="data_wrapper">
        <label>Zinsen ({{getInputValueNumber3Dicis(priceSheet.zinssatz_vor)}}%)</label>
        <label>{{getInputValueNumber(priceSheet.zinsen_vor)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Rückstellung Gewährleistung ({{getInputValueNumber3Dicis(priceSheet.garantie_von_einkaufspreis)}})</label>
        <label>{{getInputValueNumber(priceSheet.garantie_vorkalkulation)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standzeitkosten (€{{getInputValueNumber(priceSheet.standzeitkosten_je_tag_vor)}} / Tag)</label>
        <label>{{getInputValueCurrency(priceSheet.standzeitkosten_vor)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('fixkosten_pro_fahrzeug_bei_70')">
        <label>Fixkosten pro Fahrzeug</label>
        <label>{{getInputValueCurrency(priceSheet.fixkosten_pro_fahrzeug_bei_70)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('gw_aufschlag')">
        <label>GW-Aufschlag</label>
        <label>{{getInputValueCurrency(priceSheet.gw_aufschlag)}}</label>
      </div>
      <div class="data_wrapper"  *ngIf="showValuePricesheet('garantie_vor')">
        <label>Garantie</label>
        <label>{{getInputValueCurrency(priceSheet.garantie_vor)}}</label>
      </div>
    </div>
  </div>
  <div class="price_sheets_details_page_column"  *ngIf="authService.accessVia('price_calc_old')">
    <div class="data_box">
      <h3>Nachkalkulation</h3>
      <h3 style="margin-top: 0;">Verkauf</h3>
      <div class="data_wrapper" *ngIf="priceSheet.steuersatz != 2 && showValuePricesheet('verkaufspreis_netto')">
        <label>Verkaufspreis (netto)</label>
        <label>{{priceSheet.nachkalkulation ? getInputValueCurrency(priceSheet.verkaufspreis_netto) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="priceSheet.steuersatz == 2 || showValuePricesheet('verkaufspreis_brutto_standard')">
        <label>Verkaufspreis (brutto)</label>
        <label>{{priceSheet.nachkalkulation ? getInputValueCurrency(priceSheet.verkaufspreis_brutto) : "---"}}</label>
      </div>
      <div class="data_wrapper">
        <label>Verkaufsdatum</label>
        <label>{{getInputValueDate(priceSheet.verkaufsdatum)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('al_re_datum')">
        <label>AL/RE Datum</label>
        <label>{{getInputValueDate(priceSheet.al_re_datum)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="false">
        <label>Rechnungsdatum</label>
        <label>{{getInputValueDate(priceSheet.rechnungsdatum_vor)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Geldeingang</label>
        <label>{{getInputValueDate(priceSheet.geldeingang)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standzeit Platz</label>
        <label>{{getInputValue(priceSheet.standzeit_platz)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standzeit Geld</label>
        <label>{{getInputValue(priceSheet.standzeit_geld)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('garantie_vor')">
        <label>Finanzierungsgesellschaft</label>
        <label>{{finanzierungsgesellschaftValue}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('finanzierungszusageValue')">
        <label>Finanzierungszusage</label>
        <label>{{finanzierungszusageValue}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('sent_to_bank')">
        <label>Unterlagen an Bank versendet</label>
        <label>{{getInputValueDate(priceSheet.sent_to_bank)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('eingang_anzahlung')">
        <label>Eingang Anzahlung</label>
        <label>{{getInputValueDate(priceSheet.eingang_anzahlung)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('eingang_anzahlung_description')">
        <label>Eingang Anzahlung Info</label>
        <label>{{getInputValue(priceSheet.eingang_anzahlung_description)}}</label>
      </div>
      <div class="data_wrapper" style="pointer-events: none;" *ngIf="showValuePricesheet('fahrzeuginzahlungnahme')">
        <label class="checkbox_lbl">Fahrzeuginzahlungnahme</label>
        <input type="checkbox" [disabled]="true" class="checkbox" [checked]="priceSheet.fahrzeuginzahlungnahme">
      </div>
      <div class="data_wrapper" style="pointer-events: none;" *ngIf="showValuePricesheet('export_value')">
        <label class="checkbox_lbl">Export</label>
        <input type="checkbox" [disabled]="true" class="checkbox" [checked]="priceSheet.export_value">
      </div>
      <div class="data_wrapper">
        <label>Kunde</label>
        <label>{{getInputValue(priceSheet.customer_name)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Mitarbeiter</label>
        <label>{{getInputValue(priceSheet.employee_name)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="false">
        <label>Einkauf Vertragsnr.</label>
        <label>{{getInputValue(priceSheet.einkauf_vertragsnummer)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="false">
        <label>Verkauf Vertragsnr.</label>
        <label>{{getInputValue(priceSheet.verkauf_vertragsnummer)}}</label>
      </div>
      <h3>NoVA</h3>
      <div class="data_wrapper" *ngIf="showValuePricesheet('entgelt_ohne_nova')">
        <label>Entgelt ohne NoVA</label>
        <label>{{priceSheet.nachkalkulation ? getInputValueNumber(priceSheet.entgelt_ohne_nova) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('nova_satz')">
        <label>NoVA-Satz</label>
        <label>{{priceSheet.nachkalkulation ? (getInputValueNumber(priceSheet.nova_satz) + (getInputValueNumber(priceSheet.nova_satz) != "---"?"%":"")) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('nova_grundbetrag_nach') && (priceSheet && priceSheet.nova_grundbetrag_nach)">
        <label>NoVA Grundbetrag</label>
        <label>{{(priceSheet && priceSheet.nova_grundbetrag_nach && priceSheet.verkaufspreis_brutto) ? getInputValueCurrency(priceSheet.nova_grundbetrag_nach) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('abzugsposten') && (priceSheet && priceSheet.abzugsposten)">
        <label>anteiliger Abzugsposten</label>
        <label>{{(priceSheet && priceSheet.abzugsposten && priceSheet.verkaufspreis_brutto) ? getInputValueCurrency(priceSheet.abzugsposten) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('malus') && (priceSheet && priceSheet.malus)">
        <label>anteiliger CO2-Malus</label>
        <label>{{(priceSheet && priceSheet.malus && priceSheet.verkaufspreis_brutto) ? getInputValueCurrency(priceSheet.malus) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('summe_nova_standard') && (priceSheet && priceSheet.summe_nova)">
        <label>Summe NoVA</label>
        <label>{{(priceSheet && priceSheet.summe_nova && priceSheet.verkaufspreis_brutto) ? getInputValueCurrency(priceSheet.summe_nova) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('verkaufspreis_netto_standard') && (priceSheet && priceSheet.verkaufspreis_netto)">
        <label>Verkaufspreis Netto (ohne NoVA & UST)</label>
        <label>{{(priceSheet && priceSheet.verkaufspreis_netto && priceSheet.verkaufspreis_brutto) ? getInputValueCurrency(priceSheet.verkaufspreis_netto) : "---"}}</label>
      </div>
      <div class="data_wrapper"  *ngIf="showValuePricesheet('nova')">
        <label>NoVA</label>
        <label>{{priceSheet.nachkalkulation ? getInputValueCurrency(priceSheet.nova) : "---"}}</label>
      </div>
      <div class="data_wrapper"  *ngIf="showValuePricesheet('malus_bonus_nach')">
        <label>Malus/Bonus</label>
        <label>{{priceSheet.nachkalkulation ? getInputValueCurrency(priceSheet.malus_bonus_nach) : "---"}}</label>
      </div>
      <div class="data_wrapper"  *ngIf="showValuePricesheet('summe_nova')">
        <label>Summe NoVA</label>
        <label>{{priceSheet.nachkalkulation ? getInputValueCurrency(priceSheet.summe_nova) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="priceSheet.steuersatz_nach == 1">
        <label>UST</label>
        <label>{{priceSheet.nachkalkulation ? getInputValueCurrency(priceSheet.ust) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="priceSheet.steuersatz_nach != 1 && showValuePricesheet('ohne_nova_inkl_ust')">
        <label>Ohne NoVA inkl. UST</label>
        <label>{{priceSheet.nachkalkulation ? getInputValueCurrency(priceSheet.ohne_nova_inkl_ust) : "---"}}</label>
      </div>
      <h3 *ngIf="showValuePricesheet('reparaturen_vorjahr')">Vorjahr Reparaturkosten</h3>
      <div class="data_wrapper" *ngIf="false">
        <label>Aufbereitung</label>
        <label>{{getInputValueCurrency(priceSheet.aufbereitung_nachkalkulation)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="false">
        <label>Reparatur-Summe gesamt</label>
        <label>{{getInputValueCurrency(priceSheet.reparatur_summe_gesamt_vorjahr)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('reparaturen_vorjahr')">
        <label>Reparaturen</label>
        <label>{{getInputValueCurrency(priceSheet.reparaturen_vorjahr.length)}}</label>
      </div>
      <button *ngIf="showValuePricesheet('reparaturen_vorjahr') && priceSheet.reparaturen_vorjahr?.length > 0" class="btn_label_blue" (click)="openReparaturNachDialog()">Details anzeigen</button>
      <h3>Zusatzkosten</h3>
      <div class="data_wrapper" *ngIf="showValuePricesheet('aufbereitung_nachkalkulation')">
        <label>Aufbereitung</label>
        <label>{{getInputValueCurrency(priceSheet.aufbereitung_nachkalkulation)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Zinsen effektiv ({{getInputValueNumber(priceSheet.zinssatz_nach)}}%)</label>
        <label>{{getInputValueCurrency(priceSheet.zinsen_nach)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('garantie_intern')">
        <label>Garantie intern</label>
        <label>{{priceSheet.nachkalkulation ? getInputValueCurrency(priceSheet.garantie_intern) : "---"}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('garantie_extern')">
        <label>Garantie extern</label>
        <label>{{getInputValueCurrency(priceSheet.garantie_extern)}}</label>
      </div>
      <div class="data_wrapper">
        <label>Standzeitkosten (€{{getInputValueNumber(priceSheet.standzeitkosten_je_tag_nach)}} / Tag)</label>
        <label>{{getInputValueCurrency(priceSheet.standzeitkosten_nach)}}</label>
      </div>
      <div class="data_wrapper" *ngIf="showValuePricesheet('gewaehrleistung_garantie_reparaturen')">
        <label>Gewährleistung/Garantie Reparaturen</label>
        <label>{{getInputValueNumber(priceSheet.gewaehrleistung_garantie_reparaturen)}}</label>
      </div>
      <div *ngIf="showBoxPricesheet('Provision-Eingang')">
        <h3>Provision-Eingang</h3>
        <div class="data_wrapper">
          <label>Fahrzeug</label>
          <label>{{getInputValueCurrency(priceSheet.fahrzeug_provision)}}</label>
        </div>
        <div class="data_wrapper">
          <label>Finanzierung</label>
          <label>{{getInputValueCurrency(priceSheet.finanzierung)}}</label>
        </div>
      </div>
      <div *ngIf="showBoxPricesheet('Provision-Ausgang')">
        <h3>Provision-Ausgang</h3>
        <div class="data_wrapper">
          <label>Fahrzeug</label>
          <label>{{getInputValueCurrency(priceSheet.abzug_fahrzeug_provision)}}</label>
        </div>
        <div class="data_wrapper">
          <label>Finanzierung</label>
          <label>{{getInputValueCurrency(priceSheet.abzug_finanzierung)}}</label>
        </div>
      </div>
      <div *ngIf="showBoxPricesheet('Provision-Mitarbeiter')">
        <h3>Provision</h3>
        <div class="data_wrapper">
          <label>Fahrzeug</label>
          <label>{{getInputValue(priceSheet.abzug_fahrzeug_provision)}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
