import { TransactionDataTyp } from "src/app/view/cars/overview-container/transactions/transactions.component";
import { Transaction, TransactionChange } from "../Transaction";

export class TransactionFactory {
    static jsonFactory(rawBody: any): Transaction[] {
        const transactions: Transaction[] = [];
        for(let trans of rawBody){
            transactions.push(this.jsonFactoryOne(trans));
        }
        return transactions;
    }

    static jsonFactoryOne(raw: any): Transaction {
        let obj = new Transaction(
          raw.transaction_id,
          raw.transaction_typ,
          raw.employee_id,
          raw.data_typ,
          raw.reference_id,
          raw.text,
          raw.timestamp,
          raw.username,
          TransactionChangeFactory.jsonFactory(raw.changes)
        );
        return obj;
    }
}

export class TransactionChangeFactory {
  static jsonFactory(rawBody: any): TransactionChange[] {
      const transactions: TransactionChange[] = [];
      for(let trans of rawBody){
          transactions.push(this.jsonFactoryOne(trans));
      }
      return transactions;
  }

  static jsonFactoryOne(raw: any): TransactionChange {
      return new TransactionChange(
        raw.transaction_change_id,
        raw.transaction_id,
        raw.property_name,
        raw.property_type,
        raw.display_name,
        raw.display_format,
        raw.old_value,
        raw.new_value
      );
  }
}
