<form class="element" [formGroup]="form">
    <div>
      <div class="lbl_regular_small">POS</div>
      <input type="number" 
        class="inputfield" 
        [ngClass]="!f.item_id?.value || (submitted && f.item_id.invalid) ? 'inputfield_invalid' : ''" 
        [value]="f.item_id.value"
        disabled="true">
    </div>
    <div class="flex">
      <img class="element_icon_car" src="../../../../assets/carmenu-light.svg">
      <div style="width: 100%;">
        <div class="lbl_regular_small">Beschreibung</div>
          <textarea appTextfieldAutoresize style="height: 23px;"
          class="textfield"
          [ngClass]="(submitted && f.title.invalid) ? 'textfield_invalid' : ''" 
          placeholder="Beschreibung *" 
          formControlName="title"
          ></textarea>
      </div>
    </div>
    <div>
      <div class="lbl_regular_small">Menge</div>
      <input type="number" 
      class="inputfield" 
      [ngClass]="(submitted && f.base_value.invalid) ? 'inputfield_invalid' : ''" 
      placeholder="Menge *" 
      formControlName="base_value">
    </div>
    <div>
      <div class="lbl_regular_small">Preis in €</div>
      <input type="number" 
      class="inputfield"
      [ngClass]="(submitted && f.price.invalid) ? 'inputfield_invalid' : ''" 
      placeholder="Preis in € *" 
      formControlName="price">
    </div>
    <div>
      <div class="lbl_regular_small">Rabatt</div>
      <input type="number" 
      class="inputfield" 
      placeholder="0%" 
      formControlName="total_discount">
    </div>
    <div class="btn_action" (click)="removeEmitter.emit(index)">&#8722;</div>
  </form>
  