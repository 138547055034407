<div class="topbar">
    <div class="topbar_inner" *ngIf="surface == 1">
      <button class="button_type_1" (click)="openCreateEmitter.emit()">Neu</button>
      <div class="topbar_tab_wrapper">
        <button [class.button_tap_active]="filter.tab == 1" (click)="filter.tab = 1; applyFilterEmitter.emit(); showFilterDetail = false">Zum Bearbeiten<span *ngIf="filter.tab == 1"></span></button>
        <button [class.button_tap_active]="filter.tab == 2" (click)="filter.tab = 2; applyFilterEmitter.emit(); showFilterDetail = false">Von mir erstellt<span *ngIf="filter.tab == 2"></span></button>
        <button [class.button_tap_active]="filter.tab == 3" (click)="filter.tab = 3; applyFilterEmitter.emit(); showFilterDetail = false">Gelöst<span *ngIf="filter.tab == 3"></span></button>
      </div>
        <div class="sort_filter_search_wrapper">
          <div class="dropdown_wrapper_header_filter">
            <div id="filter_btn" class="filter_btn_wrapper" (click)="showFilterDetail = !showFilterDetail">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19" height="19" viewBox="0 0 19 19">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rechteck_928" data-name="Rechteck 928" width="19" height="19" fill="#2c2c2c"/>
                  </clipPath>
                </defs>
                <g id="Gruppe_1668" data-name="Gruppe 1668" clip-path="url(#clip-path)">
                  <path class="filter_icon_path" data-name="Pfad 1070" d="M12.852,16.065a.8.8,0,0,1-.482-.161l-3.213-2.41a.8.8,0,0,1-.321-.643V9.972L4.113,5.25A3.076,3.076,0,0,1,6.288,0H16.2a3.076,3.076,0,0,1,2.174,5.25L13.655,9.972v5.29a.8.8,0,0,1-.8.8ZM8.193,14.78a2.418,2.418,0,0,1-.964-1.928V10.637L2.978,6.386A4.634,4.634,0,0,1,1.656,3.577,3.06,3.06,0,0,0,.9,8.463l4.722,4.722v2.88a.8.8,0,0,0,.321.643l3.213,2.41a.8.8,0,0,0,1.285-.643V16.467Z" fill="#2c2c2c"/>
                </g>
              </svg>
            </div>
            <app-messages-filter appDropdown [show]="showFilterDetail" [excludingElementIds]="['filter_btn']" (changedValueEmitter)="showFilterDetail = $event"
              [showFilter]="showFilterDetail"
              [filter]="filter"
              (submitFilterEmitter)="applyFilterEmitter.emit(); showFilterDetail = false"
              (resetFilterEmitter)="resetFilterEmitter.emit(); showFilterDetail = false"
            ></app-messages-filter>
          </div>
          <div class="input_dropdown_container">
            <input #search class="input_search" type="text" placeholder="Suche" (input)="searchEmitter.emit(search.value);">
          </div>
        </div>
    </div>
    <div class="topbar_inner" *ngIf="surface == 2">
        <div class="btn_cancel_container" (click)="cancelCreateEmitter.emit()">
            <img class="icon_cancel" src="../../../../assets/cancle.svg">
            <button class="btn_cancel">Abbrechen</button>
        </div>
    </div>
    <div class="topbar_inner" *ngIf="surface == 3">
        <div class="btn_cancel_container" (click)="backEmitter.emit()">
            <img class="icon_cancel" src="../../../../assets/back.svg">
            <button class="btn_cancel">Zurück</button>
        </div>
    </div>
</div>
