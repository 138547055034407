import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';
import { Setting } from 'src/app/buisness-object/settings/Setting';
import { SettingFactory } from 'src/app/buisness-object/settings/SettingFactory';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) {}

  public getSettings(): Observable<Setting[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/settings', { headers });
    return observable.pipe(
      map((data: any) => {
        const objects = SettingFactory.jsonFactory(data.settings.default_values);
        objects.sort((a, b) => (a.label > b.label) ? 1 : -1);
        return objects;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  updateSettings(settings: Setting[]): Observable<Setting[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body;
    let default_values = [];
    for(let setting of settings){{
      default_values.push({
        "setting_default_value_id": setting.setting_default_value_id,
        "category": setting.category,
        "category_name": setting.category_name,
        "name": setting.name,
        "label": setting.label,
        "value": setting.value,
        "typ": setting.typ
      });
    }}
    body = {
      "default_values": default_values
    }
    const observable = this.http.put(environment.api + '/back/settings', body, { headers });
    return observable.pipe(
      map((data: any) => {
        const objects = SettingFactory.jsonFactory(data.settings.default_values);
        objects.sort((a, b) => (a.label > b.label) ? 1 : -1);
        return objects;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }
}
