import { Picture } from "../Picture";

export class PictureFactory {

  static jsonFactory(rawBody: any, obj_id?: number): Picture[] {
    const data: Picture[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart, obj_id));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any, obj_id?: number): Picture {
    const obj = new Picture(
      rawPart.picture_id,
      rawPart.position,
      obj_id,
      rawPart.src,
    )
    return obj;
  }
}
