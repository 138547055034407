import { VehicleUpholsteryColor } from "../VehicleUpholsteryColor";

export class VehicleUpholsteryColorFactory {
    static jsonFactory(rawData: any): VehicleUpholsteryColor[] {
        let dataAry: VehicleUpholsteryColor[] = [];
        for(let data of rawData){
            dataAry.push(this.jsonFactoryOne(data));
        }
        return dataAry;
    }

    static jsonFactoryOne(rawData: any): VehicleUpholsteryColor {
        let data = new VehicleUpholsteryColor(
            rawData.upholstery_color_id,
            rawData.name ? rawData.name : rawData.upholstery_color_name,
        );
        return data;
    }
}
