<div class="dropdown_component_wrapper" *ngIf="control">
  <label class="overview_record_lbl">{{name}}</label>
  <div class="dropdown_button">
      <div class="dropdown_button_header"
      [class.dropdown_disabled]="disabled"
      [class.dropdown_button_invalid]="submitted && control.invalid"
      (click)="showValues = !showValues"
      appDropdown [show]="showValues"
      (changedValueEmitter)="showValues = $event">
        <input #inputEnum class="dropdown_button_header_input" [placeholder]="placeholder" (input)="searchValues(inputEnum.value);"/>
        <img class="dropdown_button_header_img" [ngClass]="showValues ? 'dropdown_button_header_img_active' : ''" src="./assets/arrow-dark.svg">
     </div>
      <div #dropdownBox class="dropdown_button_box" *ngIf="showValues">
          <label *ngFor="let enum of enumsFiltered; let i = index" (click)="submit(enum); showValues = false">{{enum.value}}</label>
      </div>
  </div>
</div>
