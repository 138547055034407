import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-dropdown-multiple',
  templateUrl: './dropdown-multiple.component.html',
  styleUrls: ['./dropdown-multiple.component.css']
})
export class DropdownMultipleComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() headerText: string;
  @Input() submitted: boolean;
  @Input() formcontrol: FormControl;
  @Input() values: string[];
  @Input() isOptional: boolean;
  @Input() isHighlighted: boolean;
  @Output() submitValueEmitter = new EventEmitter<number>();
  public show = false;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  submit(i: number) {
    this.submitValueEmitter.emit(i);
  }
}
