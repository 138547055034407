import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { Drive, DriveStatus } from 'src/app/buisness-object/drives/Drive';
import { EnumService } from 'src/app/service/enum/enum.service';

export enum TestDriveDetailsTab {
  Details = 1,
  Tickets = 2
}

@Component({
  selector: 'app-test-drive-details',
  templateUrl: './test-drive-details.component.html',
  styleUrl: './test-drive-details.component.css'
})
export class TestDriveDetailsComponent extends BaseComponent implements OnInit {
  @Input() obj: Drive;
  @Output() selectionEditEmitter = new EventEmitter<any>();
  @Output() selectionBackEmitter = new EventEmitter<any>();

  public tabView = TestDriveDetailsTab;
  public showTab: TestDriveDetailsTab = TestDriveDetailsTab.Details;
  public shwoActions = false;
  public plateValue;
  public salutationValue;
  public DriveStatus: DriveStatus;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.plateValue = this.getEnumValue('license_plate_id', this.obj?.license_plate_id);
    this.salutationValue = this.getEnumValue('salutation', this.obj?.customer?.contactPerson.salutation);
  }

  selectionBack() {
    this.selectionBackEmitter.emit();
  }

  openAttachment() {

  }
}
