import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { Tire, TireDetail, TireStatus, TireStatusLabels } from 'src/app/buisness-object/tires/Tire';
import { EnumService } from 'src/app/service/enum/enum.service';
import { getLabelFromEnumValue } from 'src/app/utils/EnumHandler';

@Component({
  selector: 'app-tyres-details',
  templateUrl: './tyres-details.component.html',
  styleUrl: './tyres-details.component.css'
})
export class TyresDetailsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() obj: Tire;
  @Input() isPreviousObjAvailable: boolean;
  @Input() isNextObjAvailable: boolean;
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() selectionEditEmitter = new EventEmitter<Tire>();
  @Output() selectionChangeStorageEmitter = new EventEmitter<Tire>();
  @Output() selectionInstallationEmitter = new EventEmitter<Tire>();
  @Output() selectionChangeStorageAutoEmitter = new EventEmitter<Tire>();
  @Output() selectionSellEmitter = new EventEmitter<Tire>();
  @Output() selectionPreviousObjEmitter = new EventEmitter<Tire>();
  @Output() selectionNextObjEmitter = new EventEmitter<Tire>();
  @Output() selectionRestoreEmitter = new EventEmitter<Tire>();
  public tireTypValue;
  public tireBrandValue;
  public rimTypValue;
  public rimBrandValue;
  public shwoActions = false;
  public TireStatus = TireStatus;
  public tireStatus;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.tireStatus = getLabelFromEnumValue(this.obj.status, TireStatusLabels);
    this.tireTypValue = this.getEnumValue('tire_type', this.obj.tire_type);
    this.tireBrandValue = this.getEnumValue('tire_brand', this.obj.tire_brand);
    this.rimTypValue = this.getEnumValue('rim_type', this.obj.rim_type);
    this.rimBrandValue = this.getEnumValue('rim_brand', this.obj.rim_brand);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes){
      this.tireStatus = getLabelFromEnumValue(this.obj.status, TireStatusLabels);
    }
  }

  getFormDetailsTitle(detail: TireDetail, index: number): string {
    if(this.obj.details.length == 4 && detail.tyres_equal){
      if(index == 0) return 'Vorderreifen (Tiefe / Baujahr)';
      if(index == 2) return 'Hinterreifen (Tiefe / Baujahr)';
    } else {
      return `Reifen ${index+1} (Tiefe / Baujahr)`;
    }
    return '---';
  }

  selectionPrevious() {
    this.setPagingDisabled();
    this.selectionPreviousObjEmitter.emit(this.obj);
  }

  selectionNext() {
    this.setPagingDisabled();
    this.selectionNextObjEmitter.emit(this.obj);
  }

  setPagingDisabled() {
    const btnPrevious = document.getElementById('btn-previous') as HTMLButtonElement;
    const btnNext = document.getElementById('btn-next') as HTMLButtonElement;
    if(btnPrevious && btnNext){
      btnPrevious.disabled = true;
      btnNext.disabled = true;
      setTimeout(() => {
        btnPrevious.disabled = false;
        btnNext.disabled = false;
      }, 500);
    }
  }
}
