import { Attachment } from "../attachment/Attachment";
import { Employee } from "../employee/Employee";
import { OverviewConfiguration } from "../overview/OverviewConfiguration";
import { Vehicle } from "../vehicle/Vehicle";
import { Address } from "./Adress";
import { ContactPerson } from "./ContactPerson";

export enum CustomerTyp {
  COMPANY = 1,
  PRIVATE = 2,
}

export const CustomerTypLabels: { [key in CustomerTyp]: string } = {
  [CustomerTyp.PRIVATE]: 'Privat',
  [CustomerTyp.COMPANY]: 'Unternehmen',
};

export function getCustomerTypLabelStyle(status: CustomerTyp): string {
  switch (status) {
    case CustomerTyp.PRIVATE: return 'lbl_color_wrapper_violet'; break;
    case CustomerTyp.COMPANY: return 'lbl_color_wrapper_brown'; break;
    default: return ''; break;
  }
}

export enum CustomerStatus {
  CUSTOMER = 1,
  INTERESTED = 2,
  ARCHIVED = 3,
}

export const CustomerStatusLabels: { [key in CustomerStatus]: string } = {
  [CustomerStatus.CUSTOMER]: 'Kunde',
  [CustomerStatus.INTERESTED]: 'Interessent',
  [CustomerStatus.ARCHIVED]: 'Archiviert',
};

export function getCustomerStatusLabelStyle(status: CustomerStatus): string {
  switch (status) {
    case CustomerStatus.CUSTOMER: return 'lbl_color_wrapper_orange'; break;
    case CustomerStatus.INTERESTED: return 'lbl_color_wrapper_cyan'; break;
    case CustomerStatus.ARCHIVED: return 'lbl_color_wrapper_gray'; break;
    default: return ''; break;
  }
}

export class Customer {
  public employee: Employee;
  public attachments: Attachment[] = [];
  public vehicles: Vehicle[] = [];
    constructor(
        public id: number,
        public locationId: number,
        public contactPerson: ContactPerson,
        public address: Address,
        public dataprivacyAccepted: boolean,
        public advertismentAccepted: boolean,
        public typ: number,
        public status: number,
        public atuNum: string,
        public companyName: string,
        public lastUpdated?: number,
        public overviewConfig?: OverviewConfiguration[],
        public employee_id?: number,
        public authorization_number?: string,
        public authorization_authority?: string,
        public authorization_date?: number,
        public authorization_groups?: number
    ){}

    public cutString(str: string, len: number): string {
      if(str){
          if(str.length > len){
              return str.slice(0,len) + ' ...'
          } else {
              return str;
          }
      } else {
          return '';
      }
    }

    public getName(): string {
      if(this.typ == 1) return this.companyName;
      else if(this.typ == 2) return this.contactPerson.firstName + ' ' + this.contactPerson.lastName;
    }

    public getFullName(): string {
      return this.contactPerson.firstName + ' ' + this.contactPerson.lastName + (this.companyName ? ' - ' + this.companyName : '')
    }

    public getLastName(): string {
      return this.contactPerson.lastName + (this.companyName ? ' - ' + this.companyName : '')
    }

    getJSON(): any {
      return {
        customer_id: this.id,
        location_id: this.locationId,
        contact_person: {
          contact_person_id: this.contactPerson.id,
          first_name: this.contactPerson.firstName,
          last_name: this.contactPerson.lastName,
          email: this.contactPerson.email,
          phone: this.contactPerson.phone,
          title: this.contactPerson.title,
          salutation: this.contactPerson.salutation,
          birthdate: this.contactPerson.birthdate
        },
        address: {
          address_id: this.address.addressId,
          street: this.address.street,
          postal_code: this.address.postalCode,
          city: this.address.city,
          country: this.address.country?.trim()
        },
        dataprivacy_accepted: this.dataprivacyAccepted,
        advertisment_accepted: this.advertismentAccepted,
        typ: this.typ,
        status: this.status,
        atu_number: this.typ == 1 ? this.atuNum : undefined,
        company_name: this.typ == 1 ? this.companyName  : undefined,
        employee_id: this.employee_id,
        is_pep: this.contactPerson.is_pep,
        authorization_number: this.authorization_number,
        authorization_authority: this.authorization_authority,
        authorization_date: this.authorization_date,
        authorization_groups: this.authorization_groups
     }
    }
}
