export class RentPrice {
  constructor(
    public rent_price_id: number,
    public vehicle_id: number,
    public rent_price: number,
    public duration: number,
    public mileage: number
  ){}

  getJSON(): any {
    return {
      "rent_price_id": this.rent_price_id,
      "vehicle_id": this.vehicle_id,
      "rent_price": this.rent_price,
      "duration": this.duration,
      "mileage": this.mileage,
    };
  }
}
