<app-collapsible [isCollapsible]="!editMode && viewAuth.view.collapsible" [isContentVisible]="collapsed">
  <h3 collapsible-header class="overview_data_box_lbl_title">Saisonale</h3>
  <div collapsible-content>
    <div *ngIf="!editMode" class="overview_data_box_vehicle">
      <div class="overview_record" *ngIf="showValue('winter_diesel')">
        <div class="overview_record_lbl">Winterdiesel</div>
        <div class="overview_record_value">{{getBooleanStringValue(vehicle.winter_diesel)}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('winter_tyres')">
        <div class="overview_record_lbl">Winterreifen</div>
        <div class="overview_record_value">{{getBooleanStringValue(vehicle.winter_tyres)}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('winter_tyres_description')">
        <div class="overview_record_lbl">Winterreifen-Info</div>
        <div class="overview_record_value">{{vehicle.winter_tyres_description ? vehicle.winter_tyres_description : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('summer_tyres')">
        <div class="overview_record_lbl">Sommerreifen</div>
        <div class="overview_record_value">{{getBooleanStringValue(vehicle.summer_tyres)}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('summer_tyres_description')">
        <div class="overview_record_lbl">Sommerreifen-Info</div>
        <div class="overview_record_value">{{vehicle.summer_tyres_description ? vehicle.summer_tyres_description : '---'}}</div>
      </div>
    </div>
    <form *ngIf="editMode && viewAuth.edit.name" [formGroup]="form" class="overview_data_form">
      <div *ngIf="showValue('winter_diesel')">
      <app-dropdown-simple
        [title]="'Winterdiesel'"
        [formcontrol]="f.winter_diesel"
        [isOptional]="true"
        (submitValueEmitter)="f.winter_diesel.setValue($event)"
      ></app-dropdown-simple>
      </div>
      <div *ngIf="showValue('winter_tyres')">
      <app-dropdown-simple
        [title]="'Winterreifen'"
        [formcontrol]="f.winter_tyres"
        [isOptional]="true"
        (submitValueEmitter)="f.winter_tyres.setValue($event)"
      ></app-dropdown-simple>
      </div>
      <div class="input_wrapper" *ngIf="showValue('winter_tyres_description')">
        <label class="overview_record_lbl">Winterreifen-Info</label>
        <input type="text" class="overview_input" placeholder="Winterreifen-Info" formControlName="winter_tyres_description">
      </div>
      <div *ngIf="showValue('summer_tyres')">
        <app-dropdown-simple
          [title]="'Sommerreifen'"
          [formcontrol]="f.summer_tyres"
          [isOptional]="true"
          (submitValueEmitter)="f.summer_tyres.setValue($event)"
        ></app-dropdown-simple>
      </div>
      <div class="input_wrapper" *ngIf="showValue('summer_tyres_description')">
        <label class="overview_record_lbl">Sommerreifen-Info</label>
        <input type="text" class="overview_input" placeholder="Sommerreifen-Info" formControlName="summer_tyres_description">
      </div>
    </form>
  </div>
</app-collapsible>