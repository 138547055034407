export class BillItem {
  constructor(
    public item_id: number,
    public title: string,
    public quantity: number,
    public price: number,
    public tax_rate: number,
    public discount: number,
    public base_value: number,
  ){}

  public getJSON(): any {
    const brutto = this.price * (1+(this.tax_rate/100))
    return {
      "item_id": this.item_id,
      "title": this.title,
      "quantity": this.quantity,
      "price": brutto,
      "tax_rate": this.tax_rate,
      "discount": this.discount,
      "base_value": this.base_value,
    }
  }
}
