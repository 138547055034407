import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { Offer } from 'src/app/buisness-object/document/Offer';
import { SalesContract } from 'src/app/buisness-object/document/SalesContract';

@Component({
  selector: 'app-list-document-element-contract',
  templateUrl: './list-document-element-contract.component.html',
  styleUrls: ['./list-document-element-contract.component.css']
})
export class ListDocumentElementContractComponent implements OnInit {
  @Input() document: SalesContract;

  public showActions = false;
  public showActionsMoveDeep = false;
  @ViewChild('action_container') action_container: ElementRef;
  public listener: () => void;

  // @Output() openDetailsEmitter = new EventEmitter<SalesContract>();
  // @Output() pageEmitter = new EventEmitter<boolean>();
  // @Output() openEditEmitter = new EventEmitter<any>();
  // @Output() deleteEmitter = new EventEmitter<any>();
  // @Output() moveToEmitter = new EventEmitter<any>();

  @Output() openPdfEmitter = new EventEmitter<SalesContract>();
  @Output() openContractEditEmitter = new EventEmitter<SalesContract>();
  @Output() deleteContractEmitter = new EventEmitter<SalesContract>();
  @Output() signPDFEmitter = new EventEmitter<any>();
  @Output() openContractUploadEmitter = new EventEmitter<any>();

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showActions){
        if(!this.action_container.nativeElement.contains(e.target)){
          this.showActionsMoveDeep = false;
          this.showActions = false;
        }
      }
    })
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  getStatus(document: any): string {
    switch (document.status) {
      case -1: return 'Archiviert';
      case 0: return 'Offen';
      case 1: return 'Unterzeichnet';
      case 2: return 'Storniert';
      // case 2: return 'Abgesendet';
      // case 3: return 'Bezahlt';
      // case 4: return 'Überfällig';
      default: return '';
    }
  }

  getStatusColor(document: any): string {
    switch (document.status) {
      case -1: return 'lbl_color_wrapper_gray';
      case 0: return 'lbl_color_wrapper_red';
      case 1: return 'lbl_color_wrapper_green';
      // case 2: return 'lbl_color_wrapper_green';
      // case 3: return 'lbl_color_wrapper_orange';
      default: return '';
    }
  }

  setIcon():string {
    if(this.showActions){
      return '../../../../../assets/icon-more-white.png';
    }
    return '../../../../../assets/icon-more.png'
  }

  getDocCustomerName(): string {
    if(this.document instanceof Offer){
      return this.document.customer.companyName?.length > 0 ? this.document.customer.companyName : (this.document.contact_person.firstName + ' ' + this.document.contact_person.lastName);
    } else if(this.document instanceof SalesContract){
      return this.document.customer.companyName?.length > 0 ? this.document.customer.companyName : (this.document.customer.contactPerson.firstName + ' ' + this.document.customer.contactPerson.lastName);
    }
  }
}
