import { TireService } from "../service/tire/tire.service";
import { VehicleService } from "../service/vehicle/vehicle.service";

export class ImageHandler {

  public static getThumbnailVehicle(pictureId: number, service: VehicleService): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      service.getVehicleThumbnail(pictureId).subscribe((base64) => {
        if(base64){
          resolve('data:image/png;base64,'+base64.trim());
        }
        reject(null);
      });
    });
  }

  public static getThumbnailTire(pictureId: number, service: TireService): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      service.getThumbnail(pictureId).subscribe((base64) => {
        if(base64){
          resolve('data:image/png;base64,'+base64.trim());
        }
        reject(null);
      });
    });
  }
}
