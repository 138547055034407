import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dropdown-string-selection',
  templateUrl: './dropdown-string-selection.component.html',
  styleUrl: './dropdown-string-selection.component.css'
})
export class DropdownStringSelectionComponent implements OnInit {

  @Input() headerText: string;
  @Input() values: string[];
  @Input() selectedValue: string;
  @Input() placeholder: string = '---';
  @Input() submitted: boolean;
  @Input() formcontrol: FormControl;
  @Input() isOptional: boolean = false;
  @Output() submitValueEmitter = new EventEmitter<string>();
  public show = false;

  constructor() {}

  ngOnInit(): void {
    
  }
}

