import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';
import { SalesContract } from 'src/app/buisness-object/document/SalesContract';
import { Offer } from 'src/app/buisness-object/document/Offer';
import { OfferFactory } from 'src/app/buisness-object/document/factory/OfferFactory';
import { SalesContractFactory } from 'src/app/buisness-object/document/factory/SalesContractFactory';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { Kaufvertrag } from 'src/app/buisness-object/document/Kaufvertrag';
import { Zusatzprodukt } from 'src/app/buisness-object/zusatzprodukt/Zusatzprodukt';
import { ZusatzproduktFactory } from 'src/app/buisness-object/zusatzprodukt/factory/ZusatzproduktFactory';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { DriveFactory } from 'src/app/buisness-object/drives/factory/DriveFactory';
import { LedgerEntry } from 'src/app/buisness-object/document/LedgerEntry';
import { LedgerEntryFactory } from 'src/app/buisness-object/document/factory/LedgerEntryFactory';
import { Bill } from 'src/app/buisness-object/document/Bill';
import { BillFactory } from 'src/app/buisness-object/document/factory/BillFactory';
import { KaufvertragFactory } from 'src/app/buisness-object/document/factory/KaufvertragFactory';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService,
    private authService: AuthenticationService
  ) {}

  public getOfferDocuments(): Observable<Offer[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/document', { headers });
    return observable.pipe(
      map((result: any) => {
        let offer = OfferFactory.jsonFactory(result.offers);
        return offer;
      }),catchError(error => {
        if(error.status == 403){
          console.log(error)
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getKaufvertraege(): Observable<Kaufvertrag[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/contract', { headers });
    return observable.pipe(
      map((result: any) => {
        return KaufvertragFactory.jsonFactory(result)
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createOffer(doc: Offer): Observable<Offer> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = doc.getJSON();
    const observable = this.http.post(environment.api + '/back/offer',body, { headers });
    return observable.pipe(
      map((result: any) => {
        const obj = OfferFactory.jsonFactoryOne(result.offer);
        return obj;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateOffer(doc: Offer): Observable<Offer> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = doc.getJSON();
    const observable = this.http.put(environment.api + '/back/offer',body, { headers });
    return observable.pipe(
      map((result: any) => {
        const obj = OfferFactory.jsonFactoryOne(result.offer);
        return obj;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public deleteOffer(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.delete(environment.api + '/back/offer/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return true;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }

  public getOfferPdf(id: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/offer/' + id, {headers, responseType: 'arraybuffer'});
    return observable.pipe(
      map((result: any) => {
        return result;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        }  else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  getPdf(endpoint: string, id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable =  this.http.get(environment.api + '/back'+endpoint+id, { headers, responseType: 'arraybuffer' });
    return observable.pipe(
      map((result: any) => {
        //this.openPdfNewTab(result);
        return result;
      }),
      catchError(error => {
        console.log(error)
        return of([]);
      })
    );
  }

  getBillPdf(id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable =  this.http.get(environment.api + '/back/bill/pdf/'+id, { headers, responseType: 'arraybuffer' });
    return observable.pipe(
      map((result: any) => {
        this.print(result);
        return result;
      }),
      catchError(error => {
        console.log(error)
        return of([]);
      })
    );
  }

  getBillPdfByContract(id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable =  this.http.get(environment.api + '/back/bill/contract/pdf/'+id, { headers, responseType: 'arraybuffer' });
    return observable.pipe(
      map((result: any) => {
        return result;
      }),
      catchError(error => {
        console.log(error)
        return of([]);
      })
    );
  }

  getRePdf(id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable =  this.http.get(environment.api + '/back/bill/pdf/'+id, { headers, responseType: 'arraybuffer' });
    return observable.pipe(
      map((result: any) => {
        return result;
      }),
      catchError(error => {
        console.log(error)
        return of([]);
      })
    );
  }

  getContractPDF(id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/contract/pdf/'+id ,{ headers, responseType: 'arraybuffer' });
    return observable.pipe(
      map((result: any) => {
        return result;
      }),
      catchError(error => {
        console.log(error)
        return of([]);
      })
    );
  }

  getContractPDFUnsignedSigned(unsigned: boolean, id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.post(environment.api + '/back/contract/pdf/'+id , { 'unsigned': unsigned }, { headers, responseType: 'arraybuffer' });
    return observable.pipe(
      map((result: any) => {
        return result;
      }),
      catchError(error => {
        console.log(error)
        return of([]);
      })
    );
  }

  openPdfNewTab(data: any) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.click();
  }

  arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  base64ToArrayBuffer(base64) {
    var binary_string =  window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public getSalesContracts(): Observable<SalesContract[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/contract', { headers });
    return observable.pipe(
      map((result: any) => {
        return SalesContractFactory.jsonFactory(result, this.authService.mandantId$.getValue() != 2);
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createBill(bill: Bill): Observable<Bill> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const body = bill.getJSON();
    const observable = this.http.post(environment.api + '/back/bill', body, { headers });
    return observable.pipe(
      map((result: any) => {
        const obj = BillFactory.jsonFactoryOne(result.bill);
        return obj;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateBill(bill: Bill): Observable<Bill> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = bill.getJSON();
    const observable = this.http.put(environment.api + '/back/bill', body, { headers });
    return observable.pipe(
      map((result: any) => {
        const customer = BillFactory.jsonFactoryOne(result.bill);
        return customer;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public deleteBill(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.delete(environment.api + '/back/bill/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return true;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }

  public setBillInAccounting(bills: Bill[]): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let ids = [];
    for(let bill of bills){
      ids.push(bill.bill_id);
    }
    let body = {
      "bill_ids": ids
    }
    const observable = this.http.put(environment.api + '/back/bill/accounting', body, { headers });
    return observable.pipe(
      map((result: any) => {
        return true;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public cancelBill(bills: Bill[]): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let ids = [];
    for(let bill of bills){
      ids.push(bill.bill_id);
    }
    let body = {
      "bill_ids": ids
    }
    const observable = this.http.put(environment.api + '/back/bill/cancel', body, { headers });
    return observable.pipe(
      map((result: any) => {
        return true;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public uploadPDF(typ: number, reference_id: number, pdf: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = {
      "signature_document": {
        "typ": typ,
        "reference_id": reference_id,
        "pdf": pdf,
      }
    }
    const observable = this.http.put(environment.api + '/back/signature/document',body, { headers });
    return observable.pipe(
      map((result: any) => {
        return true;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
    //return of(true);
  }

  public createKaufvertrag(contract: Kaufvertrag, zusatzprodukte: any[] = []): Observable<Kaufvertrag> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = contract.getJSON();
    const observable = this.http.post(environment.api + '/back/contract',body , { headers });
    return observable.pipe(
      map((result: any) => {
        let contract =  KaufvertragFactory.jsonFactoryOne(result);
        contract.verfuegbareZusatzprodukte = zusatzprodukte.filter((z) => {
          let selectedIds = contract.selektierteZusatzprodukte.map((sZ) => sZ.zusatzprodukt_id);
          return !selectedIds.includes(z.zusatzprodukt_id)
        });
        return contract;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public setKaufvertragPaymentStatus(contract: Kaufvertrag): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = {
      "purchase_contract_id": contract.purchase_contract_id,
      "payment_status": contract.payment_status
    }
    const observable = this.http.put(environment.api + '/back/contract/payment_status', body , { headers });
    return observable.pipe(
      map((result: any) => {
        return true;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public archiveContract(contract: Kaufvertrag): Observable<Kaufvertrag> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.post(environment.api + '/back/contract/archive/'+contract.purchase_contract_id, {} , { headers });
    return observable.pipe(
      map((result: any) => {
        return contract;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public restoreContract(contract: Kaufvertrag): Observable<Kaufvertrag> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.post(environment.api + '/back/contract/restore/'+contract.purchase_contract_id, {} , { headers });
    return observable.pipe(
      map((result: any) => {
        return contract;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createContract(contract: SalesContract): Observable<SalesContract> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = contract.getJSON();
    const observable = this.http.post(environment.api + '/back/contract',body , { headers });
    return observable.pipe(
      map((result: any) => {
        return SalesContractFactory.jsonFactoryOne(result, this.authService.mandantId$.getValue() != 2);
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
    // return of(contract)
  }

  public updateKaufvertrag(contract: Kaufvertrag, zusatzprodukte: Zusatzprodukt[] = []): Observable<Kaufvertrag> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    })
    let body = contract.getJSON();
    const observable = this.http.put(environment.api + '/back/contract', body , { headers });
    return observable.pipe(
      map((result: any) => {
        let contract = KaufvertragFactory.jsonFactoryOne(result);
        contract.verfuegbareZusatzprodukte = zusatzprodukte.filter((z) => {
          let selectedIds = contract.selektierteZusatzprodukte.map((sZ) => sZ.zusatzprodukt_id);
          return !selectedIds.includes(z.zusatzprodukt_id)
        });
        return contract;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateContract(contract: SalesContract): Observable<SalesContract> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    })
    let body = contract.getJSON();
    const observable = this.http.put(environment.api + '/back/contract',body , { headers });
    return observable.pipe(
      map((result: any) => {
        return SalesContractFactory.jsonFactoryOne(result, this.authService.mandantId$.getValue() != 2);
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
    return of(contract);
  }

  public deleteContract(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.delete(environment.api + '/back/contract/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return true;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }

  public cancelKaufvertrag(document: Kaufvertrag): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.post(environment.api + '/back/contract/cancel/' + document.purchase_contract_id, undefined, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return true;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }

  public getLinkedObjects(typ: number, id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/data/'+typ+"/"+id, { headers });
    return observable.pipe(
      map((rawData: any) => {
        const bills = BillFactory.jsonFactory(rawData.bills);
        const testDrives = DriveFactory.jsonFactory(rawData.test_drives);
        return {
          bills: bills,
          test_drives: testDrives
        };
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }

  public deleteDocument(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    // let observable = this.http.delete(environment.api + '/back/dokument/' + id, {headers});
    // return observable.pipe(
    //   map((rawData: any) => {
    //     return true;
    //   }),catchError((error) => {
    //     if(error.status == 403){
    //       this.lService.logout();
    //     } else {
    //       this.dService.showNotification({
    //         title: 'Fehler',
    //         message: 'Fehlermeldung: ' + error.error.error_message,
    //         success: false
    //       });
    //     }
    //     return [false];
    //   })
    // );
    return of(true);
  }

  public appSignaturePurchaseContract(contract: Kaufvertrag): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = contract.getCreateSignatureJSON();
    const observable = this.http.post(environment.api + '/back/signature',body, { headers });
    return observable.pipe(
      map((result: any) => {
        return true;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createSignatureDocument(contract: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body;
    if(contract instanceof SalesContract || contract instanceof Offer){
      body = contract.getCreateSignatureJSON();
    } else if(contract instanceof Drive) {
      body = {
        "signature_document" : {
          "test_drive_id": contract.test_drive_id,
          "customer_id": contract.customer_id,
          "vehicle_id": contract.vehicle_id,
          "duration": contract.duration,
          "timestamp" : contract.timestamp,
          "license_plate_id" : contract.license_plate_id,
          "trip_description" : contract.trip_description,
          "authorization_authority" : contract.authorization_authority,
          "authorization_back_image" : contract.authorization_back_image,
          "authorization_date" : contract.authorization_date,
          "authorization_front_image" : contract.authorization_front_image,
          "authorization_groups" : contract.authorization_groups,
          "authorization_number" : contract.authorization_number,
          "typ" : 4,
          "reference_id" : contract.test_drive_id,
       }
      }
    }
    const observable = this.http.post(environment.api + '/back/signature',body, { headers });
    return observable.pipe(
      map((result: any) => {
        return true;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getHasOpenSignature(): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
      'skip-loading-animation': 'true'
    });
    const observable = this.http.get(environment.api + '/back/signature', { headers });
    return observable.pipe(
      map((result: any) => {
        return result;
      }),catchError(error => {
        console.log(error)
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }

  public abortOpenSignature(signature_id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.put(environment.api + '/back/signature/abort/'+signature_id, {}, { headers });
    return observable.pipe(
      map((result: any) => {
        return result;
      }),catchError(error => {
        console.log(error)
        if(error.status == 403){
          //this.lService.logout();
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }

  getAdditionalProducts(): Observable<Zusatzprodukt[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/vehicle_service', { headers });
    return observable.pipe(
      map((result: any) => {
        return ZusatzproduktFactory.jsonFactory(result);
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
    return of([
      new Zusatzprodukt(1, "Garantievertrag", "Garantievertrag", false, 500),
      new Zusatzprodukt(2, "Service", "Service", false, 300),
      new Zusatzprodukt(3, "Sonstiges", "Sonstiges", true),
      new Zusatzprodukt(4, "Werkstatt", "Werkstatt", true),
      new Zusatzprodukt(5, "Lackierarbeiten", "Lackierung Kotflügel", false, 500)
    ]);
  }

  public getLedgerEntries(): Observable<LedgerEntry[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/ledger', { headers });
    return observable.pipe(
      map((result: any) => {
        return LedgerEntryFactory.jsonFactory(result.ledger_entries)
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createLedgerEntry(ledger: LedgerEntry): Observable<LedgerEntry> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = ledger.getJSON();
    const observable = this.http.post(environment.api + '/back/ledger', body, { headers });
    return observable.pipe(
      map((result: any) => {
        return LedgerEntryFactory.jsonFactoryOne(result.ledger_entry)
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateLedgerEntry(ledger: LedgerEntry): Observable<LedgerEntry> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = ledger.getJSON();
    const observable = this.http.put(environment.api + '/back/ledger', body, { headers });
    return observable.pipe(
      map((result: any) => {
        return LedgerEntryFactory.jsonFactoryOne(result.ledger_entry)
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public deleteLedgerEntry(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.delete(environment.api + '/back/ledger/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return true;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }

  public createPurchaseContractPayment(id: number, amount?: number): Observable<LedgerEntry> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.post(environment.api + '/back/ledger/contract',
    {
      "purchase_contract_id": id
    }, { headers });
    return observable.pipe(
      map((result: any) => {
        return KaufvertragFactory.jsonFactoryOne(result.purchase_contract)
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getBills(): Observable<Bill[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/bill', { headers });
    return observable.pipe(
      map((result: any) => {
        return BillFactory.jsonFactory(result.bills)
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  print(pdf: string) {
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const objectUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.src = objectUrl;
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.onload = function() {
      setTimeout(function() {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }
}
