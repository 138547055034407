<div class="list_element list_hover_element">
  <div class="lbl_list_data list_item_pointer_event" (click)="openPdfEmitter.emit(document)">{{document.purchase_contract_id}}</div>
  <div class="lbl_list_data list_item_pointer_event" (click)="openPdfEmitter.emit(document)">{{getDocCustomerName()}}</div>
  <div class="lbl_list_data list_item_pointer_event" (click)="openPdfEmitter.emit(document)">{{document.vehicle ? document.vehicle.getVehicleName() : '---'}}</div>
  <div class="lbl_list_data">{{document.price | currency: 'EUR': '€': '1.0-0'}}</div>
  <div [ngClass]="getStatusColor(document)">{{getStatus(document)}}</div>
  <div class="lbl_list_data">{{document.purchase_date | date: 'dd.MMM yyyy'}}</div>
  <div class="list_element_action_container">
    <!-- <button class="btn_list_details" (click)="openPdfEmitter.emit(document)">
        <img class="btn_list_details_icon" src="../../../../../assets/eye.svg">
        <span>Details</span>
    </button> -->
    <div #action_container class="btn_action_wrapper">
      <img #dropdownImg class="btn_actions" src="../../../../../assets/navdots.svg" (click)="showActions = !this.showActions" appDropdown [show]="showActions" (changedValueEmitter)="showActions = $event"  [ngClass]="showActions ? 'btn_actions_active' : ''">
        <div class="dropdown_actions_container" *ngIf="showActions">
            <div class="option" (click)="openPdfEmitter.emit(document)">
              <div class="list_action_value_lbl">Öffnen</div>
              <img class="list_action_value_icon" src="../../../../../assets/eye-white.svg" >
            </div>
            <div *ngIf="document.status != 1" class="option" (click)="openContractEditEmitter.emit(document)">
                <div class="list_action_value_lbl">Bearbeiten</div>
                <img class="list_action_value_icon" src="../../../../../assets/edit.svg" >
            </div>
            <!-- <div class="option" (click)="openContractUploadEmitter.emit()">
              <div class="list_action_value_lbl">Hochladen</div>
              <img class="list_action_value_icon" src="../../../../../assets/edit.svg" >
            </div> -->
            <div *ngIf="document.status != 1"  class="option" (click)="signPDFEmitter.emit(document)">
              <div class="list_action_value_lbl">App Signatur</div>
              <img class="list_action_value_icon" src="../../../../../assets/edit.svg" >
            </div>
            <div *ngIf="document.status != 1" class="option" (click)="deleteContractEmitter.emit(document)">
                <div class="list_action_value_lbl" >Löschen</div>
                <img class="list_action_value_icon" src="../../../../../assets/delete.svg">
            </div>
        </div>
        <div class="list_action_value_container_deep" *ngIf="showActionsMoveDeep">
          <div *ngIf="document.status != 0" class="option" (click)="moveToAction(document, 0)">
              <div class="list_action_value_lbl">Entwürfe</div>
              <img class="list_action_value_icon" src="../../../../../assets/edit.svg" >
          </div>
        </div>
      </div>
  </div>
</div>
