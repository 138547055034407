<div class="list_container">
    <div class="list_header">
      <div class="header_list_row_1">
        <div></div>
        <div class="page_wrapper">
          <div id="count">{{pageInfo}}</div>
          <div id="arrows" *ngIf="threads.length > maxAmountListPage">
            <img *ngIf="sliceFrom == 0" class="icon_arrow_left" src="../../../../../assets/arrowlight.svg">
            <img *ngIf="sliceFrom != 0" class="icon_arrow_left list_arrow_active" src="../../../../../assets/arrow.svg" (click)="pageEmitter.emit(false)">
            <img *ngIf="sliceTo == amount" class="icon_arrow_right" src="../../../../../assets/arrowlight.svg">
            <img *ngIf="sliceTo != amount"  class="icon_arrow_right list_arrow_active" src="../../../../../assets/arrow.svg" (click)="pageEmitter.emit(true)">
          </div>
        </div>
      </div>
      <div class="header_list_row_2">
        <div class="header_list_row_2_inner">
          <label *ngIf="filter.user" class="lbl_filter_value"><button (click)="removeFilterEmitter.emit('user')">&#10005;</button>{{filter.user.firstName + ' ' + filter.user.lastName}}</label>
          <label *ngIf="filter.vehicle" class="lbl_filter_value"><button (click)="removeFilterEmitter.emit('vehicle')">&#10005;</button>{{filter.vehicle.getShortDescription(40)}}</label>
          <label *ngIf="filter.customer" class="lbl_filter_value"><button (click)="removeFilterEmitter.emit('customer')">&#10005;</button>{{filter.customer.getName()}}</label>
          <label *ngIf="filter.urgency" class="lbl_filter_value"><button (click)="removeFilterEmitter.emit('urgency')">&#10005;</button>{{getEnumValueUr(filter.urgency)}}</label>
          <label *ngIf="filter.typ" class="lbl_filter_value"><button (click)="removeFilterEmitter.emit('typ')">&#10005;</button>{{getEnumValueTyp(filter.typ)}}</label>
        </div>
        <div></div>
        <div class="header_list_row_2_inner">
          <label *ngIf="sortObject.sort_after_value == 1" class="lbl_filter_value">Erstellt<img (click)="sortObject.sort_asc = !sortObject.sort_asc; applySortEmitter.emit()" [src]="sortObject.sort_asc ? '../../../../../assets/sortup.svg' : '../../../../../assets/sortdown.svg'"></label>
        </div>
      </div>
    </div>
    <div id="listcontainer" class="list_element_container">
        <div class="list_no_elements lbl_regular_italic_light_color" *ngIf="threads?.length == 0">Keine Nachrichten</div>
        <app-messages-list-element *ngFor="let thread of threads | slice:sliceFrom:sliceTo; let last = last"
            [attr.id]="'thread_' + thread.threadId"
            [thread]="thread"
            [last]="last"
            [employees]="employees"
            [filter]="filter"
            [userId]="userId"
            (resolveEmitter)="resolveEmitter.emit($event)"
            (openDetailsEmitter)="openDetailsEmitter.emit($event)"
        ></app-messages-list-element>
    </div>
    <div class="list_footer"></div>
</div>
